import { createStyles, Text, Tooltip, TooltipProps } from '@mantine/core';
import React from 'react';

import { ReactComponent as InfoCircle } from '@portals/icons/linear/info-circle.svg';
import { formatDateTime } from '@portals/utils';

interface OfflineDeviceStateTooltipProps {
  lastUpdateTimestamp: string | undefined;
  children?: React.ReactNode;

  tooltipProps?: Partial<TooltipProps>;
}

export function OfflineDeviceStateTooltip({
  lastUpdateTimestamp,
  tooltipProps = {},
  children,
}: OfflineDeviceStateTooltipProps) {
  const { classes } = useStyles();

  if (!lastUpdateTimestamp) return null;

  return (
    <Tooltip
      multiline
      withArrow
      color="blue_gray.9"
      fz="xs"
      classNames={{
        tooltip: classes.tooltip,
      }}
      label={
        <Text>
          This data is based on the last known data from{' '}
          <b>{`${formatDateTime(lastUpdateTimestamp)} `}</b> and may not reflect
          the current real-time state.
        </Text>
      }
      {...tooltipProps}
    >
      {children || <InfoCircle className={classes.infoIcon} />}
    </Tooltip>
  );
}

const useStyles = createStyles((theme) => ({
  tooltip: {
    padding: theme.spacing.sm,
  },
  infoIcon: {
    color: theme.colors.orange[5],
    cursor: 'pointer',
    width: 18,
    height: 18,
    flexShrink: 0,
  },
}));
