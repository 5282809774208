import { AuthType } from '@portals/types';

export enum StepIdEnum {
  Authentication = 'authentication',
  SetUpAccount = 'setupAccount',
  OrganizationDoesntExists = 'OrganizationDoesntExists',
  OrganizationExists = 'organizationExists',
  InviteCoWorkers = 'inviteCoWorkers',
}

export interface SignupWizardContextDataType {
  email: string;
  name: string;
  password: string;
  org?: string;
  googleToken?: string;
  auth?: AuthType | null | boolean;
  organizationExists?: boolean;
  sessionToken?: string;
}
