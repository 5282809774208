import { createStyles, Paper, Stepper, StepperProps } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React, { useMemo } from 'react';

import { FulfillmentStatusType as OrgsFulfillmentStatusType } from '@portals/api/organizations';
import { FulfillmentStatusType as PartnersFulfillmentStatusType } from '@portals/api/partners';
import { ReactComponent as CloseX } from '@portals/icons/linear/close-x.svg';

type FulfillmentStatusType =
  | PartnersFulfillmentStatusType
  | OrgsFulfillmentStatusType;

export const STATUS_TO_INDEX_MAP: Record<FulfillmentStatusType, number> = {
  order_placed: 0,
  missing_information: 0,

  cancelled: 1,
  processing: 1,

  ready: 2,
  cancelled_during_ready: 2,

  shipped: 3,

  delivered: 5,
  available: 5,
};

export interface OrderShipmentProgressProps {
  fulfillmentStatus: PartnersFulfillmentStatusType | OrgsFulfillmentStatusType;
}

export function OrderShipmentProgress({
  fulfillmentStatus,
}: OrderShipmentProgressProps) {
  const { classes, theme, cx } = useStyles();
  const isSmallerThanMd = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

  const stepIcon = useMemo(() => {
    if (
      fulfillmentStatus === 'cancelled' ||
      fulfillmentStatus === 'cancelled_during_ready'
    ) {
      return <CloseX data-testid="close-icon" width={12} />;
    }

    return null;
  }, [fulfillmentStatus]);

  return (
    <Paper className={classes.container} withBorder radius="md">
      <Stepper
        active={STATUS_TO_INDEX_MAP[fulfillmentStatus]}
        orientation={isSmallerThanMd ? 'vertical' : 'horizontal'}
        styles={
          isSmallerThanMd ? stepperVerticalStyles : stepperHorizontalStyles
        }
      >
        <Stepper.Step
          label="Order placed"
          data-testid="order-placed-progress-processing-step"
        />

        <Stepper.Step
          label="Processing"
          data-testid="order-shipment-progress-processing-step"
          icon={stepIcon}
          className={cx({
            [classes.disabledStep]:
              fulfillmentStatus === 'cancelled' ||
              fulfillmentStatus === 'cancelled_during_ready',
          })}
        />

        <Stepper.Step
          label="Shipment Ready"
          data-testid="order-shipment-progress-ready-for-shipment-step"
          icon={stepIcon}
          className={cx({
            [classes.disabledStep]:
              fulfillmentStatus === 'cancelled' ||
              fulfillmentStatus === 'cancelled_during_ready',
          })}
        />

        <Stepper.Step
          label="Shipped"
          data-testid="order-shipment-progress-ready-shipped-step"
          icon={stepIcon}
          className={cx({
            [classes.disabledStep]:
              fulfillmentStatus === 'cancelled' ||
              fulfillmentStatus === 'cancelled_during_ready',
          })}
        />

        <Stepper.Step
          label="Delivered"
          data-testid="order-shipment-progress-delivered-step"
          icon={stepIcon}
          className={cx({
            [classes.disabledStep]:
              fulfillmentStatus === 'cancelled' ||
              fulfillmentStatus === 'cancelled_during_ready',
          })}
        />
      </Stepper>
    </Paper>
  );
}

const stepperHorizontalStyles: StepperProps['styles'] = (theme) => ({
  steps: {
    alignItems: 'flex-start',
  },
  step: {
    flexDirection: 'column',
    cursor: 'default !important',
    gap: theme.spacing.xs,
  },
  separator: {
    margin: 0,
    // stepIcon height is 34px, so we need to move separator to the middle of the icon to make it look centered vertically
    transform: 'translateY(17px)',
  },
  stepBody: {
    margin: 0,
  },
});

const stepperVerticalStyles: StepperProps['styles'] = () => ({
  stepBody: {
    transform: 'translateY(12px)',
  },
});

const useStyles = createStyles((theme) => ({
  container: {
    paddingInline: 100,
    paddingBlock: theme.spacing.xl,

    [theme.fn.smallerThan('md')]: {
      paddingInline: theme.spacing.xl,
    },
  },

  disabledStep: {
    '&[data-progress="true"] .mantine-Stepper-stepIcon': {
      backgroundColor: `${theme.colors.blue_gray[0]} !important`,
      border: `1px solid ${theme.colors.blue_gray[3]} !important`,
      color: `${theme.colors.blue_gray[8]} !important`,
    },

    '&[data-progress="true"] .mantine-Stepper-stepLabel': {
      color: `${theme.colors.blue_gray[3]} !important`,
    },
  },
}));
