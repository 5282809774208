import { SWITCH_TENANT } from '../constants';

const routing = () => (next) => (action) => {
  if (action.type === SWITCH_TENANT) {
    next(action);

    window.location.assign(action.meta?.redirectTo ?? window.location.pathname);
  } else {
    return next(action);
  }
};

export default routing;
