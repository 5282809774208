import { Anchor } from '@mantine/core';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import styled from 'styled-components';

type TableProps = {
  closeMe: () => void;
};

const NoLicense: FC<TableProps> = ({ closeMe }) => {
  const handleClick = () => {
    closeMe();
  };

  return (
    <StyledCard className="mb-0">
      <CardBody className="p-4">
        <div className="text-center">
          <h3 className="mt-3 mb-3">
            There are no available device monitoring licenses
          </h3>
          <p>
            Please subscribe to additional "Device monitoring" licenses via the{' '}
            <Anchor component={Link} to="/store" onClick={handleClick}>
              Store
            </Anchor>
          </p>
        </div>
      </CardBody>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
`;

export default NoLicense;
