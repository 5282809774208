import { noop } from 'lodash/fp';
import React, {
  Dispatch,
  FC,
  MutableRefObject,
  ReactNode,
  SetStateAction,
  useMemo,
} from 'react';
import {
  ScrollbarProps as CustomScrollBarProps,
  Scrollbars as CustomScrollBar,
} from 'react-custom-scrollbars';

export type ScrollStateType = {
  top: number;
  left: number;
  clientWidth: number;
  clientHeight: number;
  scrollWidth: number;
  scrollHeight: number;
  scrollLeft: number;
  scrollTop: number;
};

export type ScrollBarProps = {
  children: ReactNode;
  className?: string;
  onScrollFrame?:
    | ((scrollState: ScrollStateType) => void)
    | Dispatch<SetStateAction<ScrollStateType>>;
};

export const ScrollBar: FC<
  ScrollBarProps &
    CustomScrollBarProps & {
      innerRef?: MutableRefObject<CustomScrollBar | null>;
    }
> = (props) => {
  const {
    children,
    innerRef,
    className = '',
    onScrollFrame = noop,
    ...rest
  } = props;

  return (
    <CustomScrollBar
      ref={innerRef}
      style={{ width: '100%' }}
      className={className}
      onScrollFrame={onScrollFrame}
      {...rest}
    >
      {children}
    </CustomScrollBar>
  );
};

export const VerticalScrollBar: FC<
  ScrollBarProps &
    CustomScrollBarProps & {
      innerRef?: MutableRefObject<CustomScrollBar | null>;
    }
> = ({ children, innerRef, ...props }) => {
  const [horizontalTrack, horizontalThumb] = useMemo<
    [
      CustomScrollBarProps['renderTrackHorizontal'],
      CustomScrollBarProps['renderThumbHorizontal']
    ]
  >(
    () => [
      (props) => (
        <div
          {...props}
          className="track-horizontal"
          style={{ display: 'none' }}
        />
      ),
      (props) => (
        <div
          {...props}
          className="thumb-horizontal"
          style={{ display: 'none' }}
        />
      ),
    ],
    []
  );

  return (
    <ScrollBar
      {...props}
      innerRef={innerRef}
      renderTrackHorizontal={horizontalTrack}
      renderThumbHorizontal={horizontalThumb}
    >
      {children}
    </ScrollBar>
  );
};

export const HorizontalScrollBar: FC<
  ScrollBarProps & CustomScrollBarProps & { innerRef?: any }
> = ({ children, innerRef, ...props }) => {
  const [verticalTrack, verticalThumb] = useMemo<
    [
      CustomScrollBarProps['renderTrackVertical'],
      CustomScrollBarProps['renderThumbVertical']
    ]
  >(
    () => [
      (props) => (
        <div
          {...props}
          className="track-vertical"
          style={{ display: 'none' }}
        />
      ),
      (props) => (
        <div
          {...props}
          className="thumb-vertical"
          style={{ display: 'none' }}
        />
      ),
    ],
    []
  );

  return (
    <ScrollBar
      {...props}
      innerRef={innerRef}
      autoHeight
      autoHeightMax="100%"
      renderTrackVertical={verticalTrack}
      renderThumbVertical={verticalThumb}
    >
      {children}
    </ScrollBar>
  );
};
