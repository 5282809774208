import { BadgeProps } from '@mantine/core';
import React from 'react';

import { BadgeWithDot } from '@portals/core';
import { SubscriptionStatus } from '@portals/types';

interface SubscriptionStatusBadgeProps extends BadgeProps {
  status?: SubscriptionStatus;
}

export function SubscriptionStatusBadge({
  status,
  ...badgeProps
}: SubscriptionStatusBadgeProps) {
  if (!status) return null;

  const { color, label } = getColorAndLabel(status);

  return (
    <BadgeWithDot
      color={color}
      size="lg"
      radius="xl"
      data-testid={`badge-${label}`}
      {...badgeProps}
    >
      {label}
    </BadgeWithDot>
  );
}

function getColorAndLabel(status: SubscriptionStatus) {
  switch (status) {
    case 'active': {
      return {
        label: 'Active',
        color: 'teal',
      };
    }
    case 'pending_cancellation': {
      return {
        label: 'Pending Cancellation',
        color: 'amber',
      };
    }
    case 'suspended': {
      return {
        label: 'Suspended',
        color: 'gray',
      };
    }
    case 'canceled': {
      return {
        label: 'Canceled',
        color: 'red',
      };
    }
  }
}
