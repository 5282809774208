import { AnimatePresence, motion } from 'framer-motion';
import React, { FC } from 'react';
import styled from 'styled-components';

import { ReactComponent as CloseCircle } from '@portals/icons/linear/close-circle.svg';
import { getStyledThemeColor } from '@portals/utils';

type NotificationProps = {
  error?: string;
  onClear: () => void;
};

const Notification: FC<NotificationProps> = ({ error, onClear }) => (
  <AnimatePresence>
    {error ? (
      <Container
        whileTap={{ opacity: 0.8 }}
        onClick={onClear}
        initial={{ y: '100%', opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: '100%', opacity: 0 }}
      >
        <div>{error}</div>

        <CloseCircle />
      </Container>
    ) : null}
  </AnimatePresence>
);

const Container = styled(motion.div)`
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: ${getStyledThemeColor('danger')};
  width: 100%;
  color: ${getStyledThemeColor('white')} !important;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
`;

export default Notification;
