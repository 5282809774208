import React, { FC } from 'react';
import { Button } from 'reactstrap';
import styled from 'styled-components';

import { getStyledThemeColor } from '@portals/utils';

export type EmptyStateProps = {
  title: string;
  action?: {
    label: string;
    onAction: () => void;
  };
};

const EmptyState: FC<EmptyStateProps> = ({ title, action }) => (
  <Container>
    <div className="d-flex align-items-center flex-column">
      <img src="empty-state.svg" alt="" className="mb-4" />
      {title}
    </div>

    {action ? (
      <Button
        className="btn-branding mt-3 w-75 justify-content-center d-flex align-items-center pr-3 pl-3"
        onClick={action.onAction}
      >
        {action.label}
      </Button>
    ) : null}
  </Container>
);

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  color: ${getStyledThemeColor('gray600')};
  flex-direction: column;

  img {
    margin-bottom: 50px;
    height: 200px;
  }
`;

export default EmptyState;
