import { createStyles, Group, Stack, Text } from '@mantine/core';
import React from 'react';

import { NameAbbreviationAvatar } from '@portals/core';

interface UserCardProps {
  email: string;
  name: string | undefined;
  isInvitationSent?: boolean;
}

export function UserCard({ name, email, isInvitationSent }: UserCardProps) {
  const { classes } = useStyles();

  return (
    <Group noWrap className={classes.userWrapper} position="apart">
      <Group>
        <NameAbbreviationAvatar
          name={name || email}
          radius="xl"
          color="dark"
          variant="filled"
        />

        <Stack spacing={2}>
          <Text size="sm" color="gray.7" title={name} truncate>
            {name}
          </Text>

          <Text size="xs" color="gray.5" truncate>
            {email}
          </Text>
        </Stack>
      </Group>

      {isInvitationSent && (
        <Text size="xs" color="gray.9" truncate>
          Invitation sent
        </Text>
      )}
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  userWrapper: {
    width: '100%',
    height: 75,
    borderRadius: 8,
    border: `1px solid ${theme.colors.gray[2]}`,
    backgroundColor: theme.colors.gray[1],
    padding: 15,
  },
}));
