import { Button, createStyles, Group, Image, Stack, Text } from '@mantine/core';
import React from 'react';

import { useOpenModal } from '@portals/redux';

import { ContactServiceProviderModalProps } from '../../modals';

interface ServiceProviderCardProps {
  logo: string;
  name: string;
  id: string;
  description: string;
}

export function ServiceProviderCard({
  name,
  logo,
  id,
  description,
}: ServiceProviderCardProps) {
  const { classes } = useStyles();

  const openModal = useOpenModal();

  return (
    <Stack spacing="xl" className={classes.container}>
      <Group spacing="xl" position="apart">
        <Image src={logo} height={96} width={96} withPlaceholder />

        <Button
          className={classes.button}
          variant="default"
          onClick={() =>
            openModal<ContactServiceProviderModalProps['data']>(
              'ContactServiceProviderModal',
              {
                logo,
                name,
                id,
              }
            )
          }
        >
          Contact
        </Button>
      </Group>

      <Text
        className={classes.title}
        data-testid="service-providers-card-title"
      >
        {name}
      </Text>

      <Text color="gray.5" lineClamp={3}>
        {description}
      </Text>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    padding: 32,
    borderRadius: theme.radius.lg,
    boxShadow: `0 0 0 1px ${theme.fn.rgba(theme.colors.gray[2], 1)}`,
    background: theme.white,
    transition: 'box-shadow 0.15s ease-in-out',
    height: '100%',
  },

  button: {
    alignSelf: 'flex-start',
  },

  title: {
    fontSize: theme.fontSizes.lg,
    color: theme.colors.gray[9],
    fontWeight: 600,
  },
}));
