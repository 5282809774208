import { get } from 'lodash/fp';
import React, { FC } from 'react';
import { connect } from 'react-redux';

import { AutoFormik } from '@portals/autoformik';
import { FieldType, FieldTypeEnum, IntegrationType } from '@portals/types';

import ServiceDisabled from './ServiceDisabled';
import { ServiceCardFormProps } from './types';

const EditGChat: FC<
  ServiceCardFormProps & { integration: IntegrationType }
> = ({ integration, data, readOnly, onSubmit }) => {
  if (!integration || integration.state !== 'active') {
    return (
      <ServiceDisabled
        name="Google Chat"
        url="/settings/integrations/messaging/gchat"
      />
    );
  }

  const FIELDS: Array<FieldType> = [
    {
      name: 'space',
      title: 'Space',
      required: true,
      type: FieldTypeEnum.Select,
      options: integration?.extra?.spaces,
    },
  ];

  return (
    <AutoFormik
      fields={FIELDS}
      initialValues={data}
      handleSubmit={onSubmit}
      inProgress={false}
      readOnly={readOnly}
      submitTitle="Update"
    />
  );
};

const mapStateToProps = (state) => ({
  integration: get(['data', 'integrations', 'gchat'], state),
});

export default connect(mapStateToProps)(EditGChat);
