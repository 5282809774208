import React, { ReactNode, useState } from 'react';
import { ReferenceArea, ScatterChart } from 'recharts';
import { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart';

interface SelectionArea {
  x1: number | null;
  y1: number | null;
  x2: number | null;
  y2: number | null;
}

interface ZoomDomain {
  x: [number, number];
  y: [number, number];
}

interface ZoomableScatterChartProps {
  children: ReactNode;
  width?: number;
  height?: number;
  onZoomChange: (domain: ZoomDomain | null) => void;
  withZoom?: boolean;
}

export function ZoomableScatterChart({
  children,
  width,
  height,
  onZoomChange,
  withZoom = true,
}: ZoomableScatterChartProps) {
  const [selectionArea, setSelectionArea] = useState<SelectionArea>({
    x1: null,
    y1: null,
    x2: null,
    y2: null,
  });

  const handleMouseDown = (e: CategoricalChartState) => {
    if (!withZoom) return;

    if (e && e.xValue && e.yValue) {
      const x = new Date(e.xValue).getTime();
      const y = e.yValue as number;
      setSelectionArea({ x1: x, y1: y, x2: null, y2: null });
    }
  };

  const handleMouseMove = (e: CategoricalChartState) => {
    if (!withZoom) return;

    if (
      e &&
      e.xValue &&
      e.yValue &&
      selectionArea.x1 !== null &&
      selectionArea.y1 !== null
    ) {
      const x = new Date(e.xValue).getTime();
      const y = e.yValue as number;
      setSelectionArea((prev) => ({ ...prev, x2: x, y2: y }));
    }
  };

  const handleMouseUp = () => {
    if (!withZoom) return;

    if (
      selectionArea.x1 === null ||
      selectionArea.y1 === null ||
      selectionArea.x2 === null ||
      selectionArea.y2 === null
    ) {
      setSelectionArea({ x1: null, y1: null, x2: null, y2: null });
      return;
    }

    const { x1, y1, x2, y2 } = selectionArea;
    const xMin = Math.min(x1, x2);
    const xMax = Math.max(x1, x2);
    const yMin = Math.min(y1, y2);
    const yMax = Math.max(y1, y2);

    // Only zoom if the selection area is significant
    if (Math.abs(xMax - xMin) > 1000 && Math.abs(yMax - yMin) > 0.1) {
      const xCenter = (xMin + xMax) / 2;
      const yCenter = (yMin + yMax) / 2;
      const xRange = xMax - xMin;
      const yRange = yMax - yMin;

      // Expand the range slightly to center the points better
      const expandFactor = 1;
      const newXMin = xCenter - (xRange * expandFactor) / 2;
      const newXMax = xCenter + (xRange * expandFactor) / 2;
      const newYMin = yCenter - (yRange * expandFactor) / 2;
      const newYMax = yCenter + (yRange * expandFactor) / 2;

      onZoomChange({
        x: [newXMin, newXMax],
        y: [newYMin, newYMax],
      });
    }

    // Reset selection area
    setSelectionArea({ x1: null, y1: null, x2: null, y2: null });
  };

  return (
    <ScatterChart
      width={width || 0}
      height={height || 0}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      style={{
        cursor: withZoom ? 'crosshair' : 'default',
      }}
      margin={{
        top: 10,
        right: 25,
        bottom: 10,
        left: 0,
      }}
    >
      {children}

      {withZoom &&
        selectionArea.x1 !== null &&
        selectionArea.y1 !== null &&
        selectionArea.x2 !== null &&
        selectionArea.y2 !== null && (
          <ReferenceArea
            x1={Math.min(selectionArea.x1, selectionArea.x2)}
            x2={Math.max(selectionArea.x1, selectionArea.x2)}
            y1={Math.min(selectionArea.y1, selectionArea.y2)}
            y2={Math.max(selectionArea.y1, selectionArea.y2)}
            strokeOpacity={0.3}
            fill="black"
            fillOpacity={0.25}
          />
        )}
    </ScatterChart>
  );
}
