import { Box, createStyles, Group } from '@mantine/core';
import { motion } from 'framer-motion';
import React, { useMemo } from 'react';

import {
  FeedbackButton,
  Notifications,
  OrganizationGlobalSubscription,
  Page404,
  ProductUpdates,
  QuickNavProvider,
  QuickSearch,
  useHasSupportSeat,
  usePermissionAccess,
} from '@portals/framework';
import { useAppConfig } from '@portals/framework/context';

import { OrganizationAuthenticationGuard } from './OrganizationAuthenticationGuard';
import { OrganizationsSidebar } from './organizations-sidebar/OrganizationsSidebar';
import { SuccessCenter } from './SuccessCenter';
import { UserSettings } from './UserSettings';

interface DashboardProps {
  children: React.ReactNode;
}

export function DashboardLayout({ children }: DashboardProps) {
  const { classes, cx } = useStyles();
  const { extraLayout = {} } = useAppConfig();
  const hasSupportSeat = useHasSupportSeat();
  const { isMissingAllPermissions, isSuperAdmin } = usePermissionAccess();

  const {
    navbarLayout,
    dashboardDataLayout: DataLayout,
    globalLayout,
    options = {},
  } = extraLayout;

  const hasNoPermissions =
    isMissingAllPermissions && !isSuperAdmin && !hasSupportSeat;

  const content = useMemo(
    () => (
      <>
        {options.isMobile ? null : <OrganizationsSidebar />}

        <motion.div layout className={cx(classes.main, 'page-main')}>
          <Group
            position="right"
            align="center"
            spacing="xs"
            className={classes.navbarItemsContainer}
            bg="white"
          >
            <FeedbackButton />

            {options.isMobile ? null : <QuickSearch />}

            {navbarLayout ? navbarLayout() : null}

            <ProductUpdates />

            <Notifications />

            <SuccessCenter />

            <UserSettings />
          </Group>
          <div
            id="page-content"
            className={cx(classes.content, 'page-content')}
          >
            {hasNoPermissions ? (
              <Page404
                hideActionButton
                hideImage
                title="Missing permissions"
                description="We're sorry, it looks like you don't have the necessary permissions to access this page. Please reach out to your administrator for assistance."
              />
            ) : (
              children
            )}
          </div>
          {globalLayout ? globalLayout() : null}
          <OrganizationGlobalSubscription />
        </motion.div>
      </>
    ),
    [
      navbarLayout,
      children,
      classes.content,
      classes.main,
      classes.navbarItemsContainer,
      cx,
      globalLayout,
      hasNoPermissions,
      options.isMobile,
    ]
  );

  return (
    <OrganizationAuthenticationGuard>
      <QuickNavProvider>
        <Box className={cx(classes.wrapper, 'page-wrapper')}>
          {DataLayout ? <DataLayout>{content}</DataLayout> : <>{content}</>}
        </Box>
      </QuickNavProvider>
    </OrganizationAuthenticationGuard>
  );
}

const useStyles = createStyles((theme) => ({
  navbarItemsContainer: {
    height: '100%',
    paddingBlock: theme.spacing.md,
    paddingInline: theme.spacing.md,
    borderBottom: `2px solid ${theme.colors.gray[2]}`,

    li: {
      listStyle: 'none',
    },
  },

  main: {
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
    backgroundColor: theme.white,
    letterSpacing: '0.02em',

    display: 'grid',
    gridTemplateRows: `${theme.other.navbarHeight}px 1fr`,
  },
  wrapper: {
    height: '100vh',
    overflow: 'hidden',
    display: 'grid',
    gridTemplateColumns: `100px 1fr`,
  },
  content: {
    flex: 1,
    overflow: 'auto',
    position: 'relative',
  },
}));
