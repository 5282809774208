import { LoadingOverlay } from '@mantine/core';
import { QueryFunctionContext, useInfiniteQuery } from '@tanstack/react-query';
import { get, last, reduce } from 'lodash/fp';
import React, {
  CSSProperties,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useSelector } from 'react-redux';
import { useVirtual } from 'react-virtual';

import {
  buildUrlFromFilters,
  fetchApiRequest,
  getRequestOptions,
} from '@portals/api';
import { getAuth } from '@portals/redux';
import { PaginatedQueryParamsType } from '@portals/types';

export function useItemsVirtualization<Entity extends object>(
  itemsParams: PaginatedQueryParamsType<Entity>,
  endpointUrl: string,
  key: string,
  itemRenderer: (item: Entity) => ReactNode,
  loaderRenderer?: () => ReactNode
) {
  const auth = useSelector(getAuth);

  const loader = useMemo(
    () => (loaderRenderer ? loaderRenderer() : <LoadingOverlay visible />),
    [loaderRenderer]
  );

  const queryFn = useCallback(
    async ({ pageParam }: QueryFunctionContext) => {
      const { url, options } = getRequestOptions({ url: endpointUrl }, auth);

      const requestUrl = buildUrlFromFilters({
        url: url,
        ...itemsParams,
        pagination: {
          ...itemsParams.pagination,
          page: pageParam || itemsParams?.pagination?.page,
        },
      });

      return fetchApiRequest(requestUrl, options);
    },
    [auth, endpointUrl, itemsParams]
  );

  const query = useInfiniteQuery([key, JSON.stringify(itemsParams)], queryFn, {
    getNextPageParam: (lastPage, pages) => pages.length,
  });

  const items = useMemo<Array<Entity>>(
    () => reduce((acc, curr) => acc.concat(curr.data), [], query?.data?.pages),
    [query.data]
  );

  const hasNextPage = useMemo(() => {
    const lastPageQuery = last(query?.data?.pages);

    return get(['page_info', 'has_next'], lastPageQuery);
  }, [query?.data?.pages]);

  const parentRef = useRef(null);

  const rowVirtualizer = useVirtual({
    size: hasNextPage ? items.length + 1 : items.length,
    parentRef,
    overscan: 0,
    estimateSize: useCallback(() => 120, []),
  });

  const virtualItems = useMemo(
    () =>
      rowVirtualizer.virtualItems.map((virtualRow) => {
        const isLoaderRow = virtualRow.index > items.length - 1;
        const row: Entity = items[virtualRow.index];

        return (
          <div
            key={virtualRow.index}
            style={{
              width: 'calc(100% - 15px)',
              position: 'absolute',
              transform: `translateY(${virtualRow.start}px)`,
              height: `${virtualRow.size}px`,
            }}
          >
            {isLoaderRow ? loader : itemRenderer(row)}
          </div>
        );
      }),
    [rowVirtualizer.virtualItems, items, loader, itemRenderer]
  );

  useEffect(() => {
    const [lastItem] = [...rowVirtualizer.virtualItems].reverse();

    if (!lastItem) {
      return;
    }

    if (
      lastItem.index >= items.length - 1 &&
      hasNextPage &&
      !query.isFetchingNextPage
    ) {
      query.fetchNextPage();
    }
  }, [hasNextPage, query, items.length, rowVirtualizer.virtualItems]);

  const containerStyle = useMemo<CSSProperties>(
    () => ({
      height: `${rowVirtualizer.totalSize}px`,
      width: '100%',
      position: 'relative',
    }),
    [rowVirtualizer.totalSize]
  );

  return {
    flattenItems: items,
    containerStyle,
    virtualItems,
    isFetching: query.isFetching,
    ScrollContainerRef: parentRef,
  };
}
