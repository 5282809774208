import { useQueryClient } from '@tanstack/react-query';
import { size, takeRight } from 'lodash/fp';
import { useCallback, useMemo } from 'react';

import {
  DeviceDetailsType,
  devicesQueryKeys,
  DeviceType,
  useFeatureFlags,
  useOrganizationConfig,
  usePortalCapabilities,
} from '@portals/api/organizations';
import {
  useDeviceCommandsChannel,
  useOrgDeviceStateChannel,
  usePermissionAccess,
} from '@portals/framework';

import { TABS } from './device-tabs.constants';
import { DeviceStateType } from './device-tabs.types';

export const useVisibleTabs = (device?: DeviceDetailsType): typeof TABS => {
  const featureFlags = useFeatureFlags();
  const portalCapabilities = usePortalCapabilities();
  const { isAdmin, canView, canEdit } = usePermissionAccess();

  const hasChildren = size(device?.child_devices) > 0;

  return useMemo(() => {
    if (!device) return [];

    return TABS.filter(({ visible }) => {
      return visible
        ? visible({
            device,
            featureFlags,
            portalCapabilities,
            hasChildren,
            isAdmin,
            canView,
            canEdit,
          })
        : true;
    });
  }, [
    device,
    featureFlags,
    portalCapabilities,
    hasChildren,
    isAdmin,
    canView,
    canEdit,
  ]);
};

const MAX_NUM_OF_DATA_POINTS = 100;

export const useDeviceStateSubscription = (device: DeviceType) => {
  const config = useOrganizationConfig();
  const queryClient = useQueryClient();

  const messageHandler = useCallback(
    (
      oldData: Array<DeviceStateType> = [],
      newData: { data: DeviceStateType }
    ) => {
      // Invalidate device telemetries data so we'd refetch them
      queryClient.invalidateQueries(
        devicesQueryKeys.telemetries.all(device.id)
      );

      return takeRight(MAX_NUM_OF_DATA_POINTS, [
        ...oldData,
        { timestamp: new Date().getTime(), data: newData.data },
      ]);
    },
    [device.id, queryClient]
  );

  const subscriptionParams = useMemo(
    () => ({ org_id: config?.id, device_id: device.id }),
    [config?.id, device.id]
  );

  // Subscribe to device state changes
  const { data = [] } = useOrgDeviceStateChannel({
    subscriptionParams,
    messageHandler,
  });

  const lastDeviceState = useMemo(() => {
    if (!device.state) {
      return [];
    }

    return [
      {
        timestamp: null,
        data: device.state,
      },
    ];
  }, [device.state]);

  return [...lastDeviceState, ...data];
};

export const useDeviceCommandsSubscription = (deviceId: string) => {
  const queryClient = useQueryClient();

  const messageHandler = useCallback(() => {
    queryClient.invalidateQueries(devicesQueryKeys.commands.all(deviceId));
  }, [deviceId, queryClient]);

  const subscriptionParams = useMemo(
    () => ({ device_id: deviceId }),
    [deviceId]
  );

  useDeviceCommandsChannel({
    subscriptionParams,
    messageHandler,
  });
};

export function useDeviceSubscriptions(device: DeviceType) {
  const deviceState = useDeviceStateSubscription(device);
  useDeviceCommandsSubscription(device.id);

  return deviceState;
}
