import { isNumber, isUndefined } from 'lodash/fp';

import { NumberFormatType } from '../widgets';

export type NumberFormatterType = (
  value: number | undefined,
  decimals: number
) => string | undefined;

function checkFormattedValue(value: string) {
  if (isUndefined(value) || value === 'NaN') return 'N/A';

  return value;
}

export const FORMATTERS: Record<NumberFormatType, NumberFormatterType> = {
  none: (value: number | undefined) => {
    if (!isNumber(value)) return;

    const formattedValue = new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 1,
      minimumFractionDigits: 0,
    }).format(value || 0);

    return checkFormattedValue(formattedValue);
  },
  decimal: (value: number | undefined, decimals: number) => {
    if (!isNumber(value)) return;

    const formattedValue = new Intl.NumberFormat('en-US', {
      maximumFractionDigits: decimals,
      minimumFractionDigits: decimals,
    }).format(value || 0);

    return checkFormattedValue(formattedValue);
  },
  scientific: (value: number | undefined, decimals: number) => {
    if (!isNumber(value)) return;

    const formattedValue = Number(value).toExponential(decimals);

    return checkFormattedValue(formattedValue);
  },
};
