import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';
import { OrganizationFileResponseType } from '@portals/types';

import {
  getSpaceApiUrl,
  SPACES_API_URL,
  spacesQueryKeys,
} from './spaces.constants';
import { CreateSpaceFileMutationParams } from './spaces.types';
import { useApiQuery } from '../../hooks';
import { ServerError } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils';

function getApiUrl(spaceId: number) {
  return `${getSpaceApiUrl(spaceId)}/file_infos`;
}

export function useSpaceFiles(spaceId: number) {
  return useApiQuery<OrganizationFileResponseType[]>(
    getApiUrl(spaceId),
    spacesQueryKeys.files.all(spaceId),
    {
      enabled: !!spaceId,
    }
  );
}

export function useCreateSpaceFile() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: '',
    method: 'POST',
  });

  return useMutation({
    mutationFn: ({ spaceId, fileData }: CreateSpaceFileMutationParams) => {
      return fetchApiRequest(`${url}/${getApiUrl(spaceId)}`, {
        ...options,
        body: JSON.stringify(fileData),
      });
    },
    onSuccess: () => {
      dispatch(toastrSuccess('File saved successfully'));

      queryClient.invalidateQueries(spacesQueryKeys.files.global);
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useCreateSpaceFile',
      baseUrl: `${SPACES_API_URL}/:id/file_infos`,
      method: 'POST',
    },
  });
}

interface UseUpdateSpaceFileParams {
  spaceId: number;
  fileId: string;
  fileData: OrganizationFileResponseType;
}

export function useUpdateSpaceFile() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: '',
    method: 'PUT',
  });

  return useMutation<
    OrganizationFileResponseType,
    ServerError,
    UseUpdateSpaceFileParams
  >({
    mutationFn: ({ spaceId, fileId, fileData }) => {
      return fetchApiRequest(`${url}/${getApiUrl(spaceId)}/${fileId}`, {
        ...options,
        body: JSON.stringify(fileData),
      });
    },
    onSuccess: () => {
      dispatch(toastrSuccess('File updated successfully'));

      return queryClient.invalidateQueries(spacesQueryKeys.files.global);
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
  });
}

export function useDeleteSpaceFile() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: '',
    method: 'DELETE',
  });

  return useMutation({
    mutationFn: ({ spaceId, fileId }: { spaceId: number; fileId: string }) => {
      return fetchApiRequest(`${url}/${getApiUrl(spaceId)}/${fileId}`, options);
    },
    onSuccess: () => {
      dispatch(toastrSuccess('File deleted successfully'));

      queryClient.invalidateQueries(spacesQueryKeys.files.global);
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useDeleteSpaceFile',
      baseUrl: `${SPACES_API_URL}/:id/file_infos/:fileId`,
      method: 'DELETE',
    },
  });
}
