import { Box, Button, createStyles, Grid, Group, Modal } from '@mantine/core';
import moment from 'moment';
import React, { useState } from 'react';

import { DateInputGroup } from './DateInputGroup';
import { DateRangePresets } from './DateRangePresets';
import { RangeCalendar } from './RangeCalendar';
import { DateRangeType } from './types';
import { ModalProps } from '../../components/Modals';

export interface DateRangeProps
  extends ModalProps<{
    startDate?: Date | null;
    endDate?: Date | null;
    onClear: () => void;
    onSubmit: (dateRange: DateRangeType) => void;
  }> {}

export function DateRange({ closeMe, data }: DateRangeProps) {
  const { classes } = useStyles();

  const { startDate, endDate, onClear, onSubmit } = data;

  const [localDateRange, setLocalDateRange] = useState<DateRangeType>({
    startDate: startDate || moment(new Date()).startOf('day').toDate(),
    endDate: endDate || moment(new Date()).endOf('day').toDate(),
  });

  const onClearFilter = () => {
    onClear();
    closeMe();
  };

  const onSubmitFilter = () => {
    const gteStartOfDayDate = localDateRange.startDate
      ? moment(localDateRange.startDate).startOf('day').toDate()
      : null;
    const lteEndOfDayDate = localDateRange.endDate
      ? moment(localDateRange.endDate).endOf('day').toDate()
      : null;

    onSubmit({ startDate: gteStartOfDayDate, endDate: lteEndOfDayDate });
    closeMe();
  };

  return (
    <Modal
      opened
      onClose={closeMe}
      closeOnEscape
      size={830}
      padding={0}
      withCloseButton={false}
      styles={{
        body: { height: 420 },
      }}
    >
      <Grid gutter={0} h="100%">
        <Grid.Col span={3}>
          <DateRangePresets onChange={setLocalDateRange} />
        </Grid.Col>

        <Grid.Col span={9}>
          <Box className={classes.calendarColumn}>
            <RangeCalendar
              value={localDateRange}
              onChange={setLocalDateRange}
            />

            <Group className={classes.calendarFooter} position="apart" noWrap>
              <DateInputGroup
                dateRange={localDateRange}
                setDateRange={setLocalDateRange}
              />

              <Group noWrap>
                <Button size="md" variant="default" onClick={onClearFilter}>
                  Clear Filters
                </Button>

                <Button size="md" onClick={onSubmitFilter}>
                  Apply
                </Button>
              </Group>
            </Group>
          </Box>
        </Grid.Col>
      </Grid>
    </Modal>
  );
}

const useStyles = createStyles((theme) => ({
  calendarColumn: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: '1fr max-content',
  },
  calendarFooter: {
    paddingBlock: theme.spacing.md,
    paddingInline: theme.spacing.xl,
    borderTop: `1px solid ${theme.colors.gray[3]}`,
  },
}));
