import { LoadingOverlay } from '@mantine/core';
import { filter, isEmpty, map, reduce } from 'lodash/fp';
import moment from 'moment';
import React, { FC, useCallback, useMemo } from 'react';
import { AutoSizer } from 'react-virtualized';
import { Spinner } from 'reactstrap';
import {
  CartesianGrid,
  Dot,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import styled from 'styled-components';

import { EmptyState } from '@portals/table';
import { useTheme } from '@portals/ui';
import { getStyledThemeColor } from '@portals/utils';

import { WidgetProps } from './types';

const LineChartWidget: FC<WidgetProps> = ({
  deviceStates = [],
  dataFields,
  isLoading = false,
}) => {
  const { color } = useTheme();

  const data = useMemo(() => {
    if (isEmpty(dataFields)) return [];

    // Initial data displayed in the dashboard is taken from device's `state` value
    // State has no timestamp, as it's an aggregation of various telemetries
    // We will not display the initial state, but only the states that have a timestamp
    const filteredStates = filter(
      ({ timestamp }) => Boolean(timestamp),
      deviceStates
    );

    return map(
      ({ timestamp, data }) => ({
        timestamp,
        ...reduce(
          (acc, fieldKey) => {
            const value = data?.[fieldKey];

            return {
              ...acc,
              [fieldKey]: value || null,
            };
          },
          {},
          dataFields
        ),
      }),
      filteredStates
    );
  }, [dataFields, deviceStates]);

  const lines = useMemo(() => {
    const colors = [color.green, color.orange, color.pink, color.cyan];

    return (dataFields || []).map((fieldKey, index) => (
      <Line
        key={fieldKey}
        dataKey={fieldKey}
        isAnimationActive={false}
        strokeWidth={2}
        stroke={colors[index]}
        dot={false}
        activeDot={(props) => (
          <Dot
            {...props}
            fill={colors[index]}
            r={6}
            stroke={color.white}
            strokeWidth={0}
          />
        )}
      />
    ));
  }, [color, dataFields]);

  const tickFormatter = useCallback(
    (tick: string) => moment(tick).format('MM/DD HH:mm:ss'),
    []
  );

  if (isEmpty(data) && isLoading)
    return (
      <Container>
        <Spinner color="primary" />
      </Container>
    );

  if (isEmpty(data)) return <EmptyState label="No data available" />;

  return (
    <Container>
      <div className="chart">
        <LoadingOverlay visible={isLoading} />

        <AutoSizer>
          {({ width, height }) => (
            <LineChart
              data={data}
              width={width}
              height={height}
              margin={{ left: 25, right: 25, bottom: 10, top: 10 }}
            >
              <CartesianGrid stroke={color.gray200} />
              <Tooltip
                labelFormatter={(timestamp) =>
                  moment(timestamp).format('MM/DD HH:mm:ss')
                }
              />

              <XAxis
                dataKey="timestamp"
                axisLine={false}
                tickLine={false}
                tickMargin={10}
                tickFormatter={tickFormatter}
                height={20}
                minTickGap={20}
              />

              <YAxis
                offset={0}
                axisLine={false}
                width={30}
                tickLine={false}
                tickMargin={15}
                allowDecimals={false}
              />

              {lines}
            </LineChart>
          )}
        </AutoSizer>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr;

  .chart {
    padding: 15px;
    position: relative;

    g.recharts-line {
      path {
        filter: drop-shadow(0px 2px 2px ${getStyledThemeColor('gray400')});
      }
    }
    g.recharts-line-dots {
      circle {
        filter: drop-shadow(0px 2px 2px ${getStyledThemeColor('gray400')});
      }
    }
  }

  .empty-state-container {
    font-size: 14px;

    img {
      height: 80px;
    }
  }

  .spinner-border {
    position: absolute;
    left: 50%;
    bottom: 50%;
    z-index: 1;
  }
`;

export default LineChartWidget;
