import { Descope } from '@descope/react-sdk';
import { noop } from 'lodash/fp';
import React from 'react';
import { generatePath, useMatch } from 'react-router-dom';

import {
  captureDescopeError,
  sendDescopeErrorReport,
} from '../../utils/descope.utils';

export function ChangePassword() {
  const match = useMatch('/auth/change-password/:partner_name?');

  function onSuccess() {
    const pathname = generatePath('/auth/sign-in/:partner_name?', {
      partner_name: match?.params?.partner_name || null,
    });

    window.open(pathname);
  }

  return (
    <Descope
      flowId="change-password"
      onError={captureDescopeError}
      onSuccess={onSuccess}
      logger={{
        debug: noop,
        warn: noop,
        error: noop,
        info: (_title, _description, state) => {
          sendDescopeErrorReport(state, 'Framework > ChangePassword');
        },
      }}
    />
  );
}
