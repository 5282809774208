import { createStyles, Paper, SimpleGrid, Stack, Text } from '@mantine/core';
import React from 'react';

import { FileTypeIcon } from '@portals/core';
import { EmptyState } from '@portals/table';
import { downloadFile } from '@portals/utils/files';

import { OrderType } from './order.types';
import { useGenericContext } from '../../../components/GenericContext';

export function OrderAttachmentsCard<TOrder extends OrderType>() {
  const { classes } = useStyles();
  const order = useGenericContext<TOrder>();

  const { invoice, price_quote_file_url, purchase_order_file_url } =
    order || {};

  const hasNoAttachments =
    !invoice?.file_url && !price_quote_file_url && !purchase_order_file_url;

  return (
    <Paper withBorder p="xl" radius="lg">
      <Stack spacing="lg">
        <Text size="md" color="gray.8">
          Attachments
        </Text>

        {hasNoAttachments ? (
          <EmptyState src="empty-state-pdf" label="">
            <Text sx={(theme) => ({ color: theme.colors.gray[5] })} size="xs">
              No Attachments
            </Text>
          </EmptyState>
        ) : (
          <SimpleGrid cols={3}>
            {invoice?.file_url ? (
              <Stack
                align="center"
                spacing="sm"
                className={classes.attachment}
                onClick={() =>
                  downloadFile(invoice.file_url, `invoice_${order.id}`)
                }
              >
                <FileTypeIcon fileUrl={invoice.file_url} />

                <Text className={classes.fileLabel}>Invoice</Text>
              </Stack>
            ) : null}

            {price_quote_file_url ? (
              <Stack
                align="center"
                spacing="sm"
                className={classes.attachment}
                onClick={() =>
                  downloadFile(price_quote_file_url, 'pricing_quote')
                }
              >
                <FileTypeIcon fileUrl={price_quote_file_url} />

                <Text className={classes.fileLabel}>Pricing Quote</Text>
              </Stack>
            ) : null}

            {purchase_order_file_url ? (
              <Stack
                align="center"
                spacing="sm"
                className={classes.attachment}
                onClick={() =>
                  downloadFile(purchase_order_file_url, 'purchase_order')
                }
              >
                <FileTypeIcon fileUrl={purchase_order_file_url} />

                <Text className={classes.fileLabel}>Purchase Order</Text>
              </Stack>
            ) : null}
          </SimpleGrid>
        )}
      </Stack>
    </Paper>
  );
}

const useStyles = createStyles((theme) => ({
  attachment: {
    cursor: 'pointer',
  },
  fileLabel: {
    fontSize: theme.fontSizes.xs,
    color: theme.colors.blue_gray[8],
    fontWeight: 500,
  },
}));
