import {
  Button,
  ButtonProps,
  createStyles,
  Group,
  Modal,
  Stack,
  Text,
} from '@mantine/core';
import React, { ReactNode } from 'react';

import { ModalProps } from '../components/Modals';

export interface ConfirmationModalProps
  extends ModalProps<{
    onApprove: () => void;
    onCancel: () => void;
    description: ReactNode;
    title?: ReactNode;
    confirmationLabel?: string;
    cancelLabel?: string;
    confirmButtonProps?: Omit<ButtonProps, 'children'> & {
      'data-testid'?: string;
    };
    cancelButtonProps?: Omit<ButtonProps, 'children'> & {
      'data-testid'?: string;
    };
  }> {}

export function ConfirmationModal({ data, closeMe }: ConfirmationModalProps) {
  const { classes } = useStyles();

  const {
    onApprove,
    onCancel,
    description,
    title = 'Please confirm',
    confirmationLabel = 'Confirm',
    cancelLabel = 'Cancel',
    confirmButtonProps,
    cancelButtonProps,
  } = data;

  const onToggle = () => {
    onCancel();
    closeMe();
  };

  return (
    <Modal
      opened
      onClose={onToggle}
      title={title}
      padding={32}
      radius="md"
      size={560}
    >
      <Stack>
        <Text size="sm">{description}</Text>

        <Group position="right" pt={32} className={classes.footer}>
          <Button
            variant="default"
            onClick={onToggle}
            data-testid="confirmation-modal-cancel-button"
            {...cancelButtonProps}
          >
            {cancelLabel}
          </Button>

          <Button
            color="red.4"
            onClick={onApprove}
            data-testid="confirmation-modal-confirm-button"
            {...confirmButtonProps}
          >
            {confirmationLabel}
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
}

const useStyles = createStyles((theme) => ({
  footer: {
    [theme.fn.smallerThan('md')]: {
      '> *': {
        flexGrow: 1,
      },
    },
  },
}));
