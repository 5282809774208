import { Badge, Box, createStyles, Group, Text } from '@mantine/core';
import React, { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { usePaginatedAuditLogs } from '@portals/api/ui';
import { ReactComponent as UserOctagon } from '@portals/icons/bold/user-octagon.svg';
import { DateCell, PaginatedTable } from '@portals/table';
import {
  AuditLogTemplates,
  AuditLogType,
  TableColumn,
  TableFilterTypeEnum,
} from '@portals/types';

export interface AuditLogsProps {
  messagesList: Partial<
    Record<AuditLogTemplates, (auditLog: AuditLogType) => ReactNode>
  >;
  getUserRoute: (userId: string) => string;
}

export const AuditLogs: FC<AuditLogsProps> = ({
  messagesList,
  getUserRoute,
}) => {
  const { classes } = useStyles();

  const columns: TableColumn<AuditLogType>[] = [
    {
      dataField: 'user_name',
      text: 'Name',
      sort: true,
      filter: { type: TableFilterTypeEnum.Text },
      maxWidth: 150,
      minWidth: 150,
      formatter: (_, { user, ...lol }) => {
        if (!user) return '--';

        if (user.super_admin) {
          return (
            <Box className={classes.nameRow}>
              <Text size="sm" truncate title={user.name}>
                {user.name}
              </Text>

              <Group>
                <Badge
                  color="gray.8"
                  bg="indigo.0"
                  px="sm"
                  size="md"
                  fw={300}
                  leftSection={<UserOctagon width={14} height={14} />}
                >
                  System Admin
                </Badge>
              </Group>
            </Box>
          );
        }

        return (
          <Text<typeof Link>
            component={Link}
            to={getUserRoute(user.id)}
            size="sm"
            color="blue"
            underline
          >
            {user.name}
          </Text>
        );
      },
    },
    {
      dataField: 'full_message',
      text: 'Message',
      sort: true,
      filter: { type: TableFilterTypeEnum.Text },
      formatter: (cell, row) => {
        const messageRenderer = messagesList[row.template];

        if (messageRenderer && typeof messageRenderer === 'function') {
          return messageRenderer(row);
        } else {
          return row.full_message;
        }
      },
    },
    {
      dataField: 'created_at',
      text: 'Date',
      sort: true,
      maxWidth: 50,
      filter: { type: TableFilterTypeEnum.Date },
      formatter: (cell, { created_at }) => <DateCell date={created_at} />,
    },
  ];

  return (
    <PaginatedTable<AuditLogType>
      keyField="id"
      noHeader
      name="auditLogs"
      columns={columns}
      dataHook={usePaginatedAuditLogs}
      noDataIndication={{ title: 'No audit logs to display' }}
      defaultSortBy={[{ id: 'created_at', desc: true }]}
    />
  );
};

const useStyles = createStyles((theme) => ({
  nameRow: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gap: theme.spacing.xs,
    alignItems: 'center',
  },
}));
