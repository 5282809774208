import { noop } from 'lodash/fp';
import React from 'react';
import { useSelector } from 'react-redux';

import { ChangePassword, ResetPassword } from '@portals/framework';
import { getErrorByActionName } from '@portals/redux';

import { AuthWrapper } from './common';
import Notification from '../../components/layout/Notification';

export const ResetPasswordPage = () => {
  const resetPasswordLinkError = useSelector(
    getErrorByActionName('resetPasswordLink')
  );

  return (
    <AuthWrapper>
      <ResetPassword />

      <Notification error={resetPasswordLinkError} onClear={noop} />
    </AuthWrapper>
  );
};

export const ChangePasswordPage = () => {
  const changePasswordTokenError = useSelector(
    getErrorByActionName('changePasswordToken')
  );

  return (
    <AuthWrapper>
      <ChangePassword />

      <Notification error={changePasswordTokenError} onClear={noop} />
    </AuthWrapper>
  );
};
