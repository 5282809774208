import {
  Group,
  Paper,
  PaperProps,
  Stack,
  Switch,
  Text,
  Textarea,
} from '@mantine/core';
import { usePrevious } from '@mantine/hooks';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setNotesSwitchStatus } from '../../../redux/actions/store';
import { getNotesSwitchStatus } from '../../../redux/selectors/store';

interface NoteProps {
  label: string;
  paperProps?: Partial<PaperProps>;
  onChange: (notes: string) => void;
  value?: string | null;
}

const VARIANTS = {
  open: {
    opacity: 1,
    height: 'auto',
  },
  collapsed: {
    opacity: 0,
    height: 0,
  },
};

export function Note({ label, paperProps, onChange, value = null }: NoteProps) {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const initialSwitchValue = useSelector(getNotesSwitchStatus);

  const dispatch = useDispatch();

  const [checked, setChecked] = useState(initialSwitchValue);
  const prevIsChecked = usePrevious(checked);

  const onTextChange = (notes: string) => {
    onChange(notes);
  };

  const handleClick = (status: boolean) => {
    setChecked(status);
    dispatch(setNotesSwitchStatus(status));
  };

  useEffect(
    function focusTextareaWhenSwitchTurnedOn() {
      if (prevIsChecked === false && checked) {
        textareaRef.current?.focus();
      }
    },
    [checked, prevIsChecked]
  );

  return (
    <Paper {...paperProps} radius="md">
      <Stack>
        <Group position="apart">
          <Text size="md">{label} </Text>
          <Switch
            checked={checked}
            onClick={(event) => handleClick(event.currentTarget.checked)}
            data-testid="note-switch"
          />
        </Group>

        <AnimatePresence>
          {checked ? (
            <motion.section
              initial={checked ? 'open' : 'collapsed'}
              animate="open"
              exit={checked ? 'open' : 'collapsed'}
              variants={VARIANTS}
            >
              <Textarea
                ref={textareaRef}
                onChange={(e) => onTextChange(e.currentTarget.value)}
                minRows={5}
                placeholder="Add your note..."
                defaultValue={value}
                data-testid="note-textarea"
              />
            </motion.section>
          ) : (
            <motion.section
              initial="open"
              animate="collapsed"
              exit="open"
              variants={VARIANTS}
            ></motion.section>
          )}
        </AnimatePresence>
      </Stack>
    </Paper>
  );
}
