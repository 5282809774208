import { getOr } from 'lodash/fp';
import { connect } from 'react-redux';

import { StateType } from '@portals/types';

import { NetworkButton } from './NetworkButton';

import type { NetworkButtonProps } from './NetworkButton';

interface ConnectedProps {
  reloading: boolean;
}

const mapStateToProps = (state: StateType, ownProps: NetworkButtonProps) => ({
  reloading:
    ownProps.reloading ||
    getOr(false, ['ui', 'network', ownProps.request], state),
});

export default connect<ConnectedProps>(mapStateToProps)(NetworkButton);
