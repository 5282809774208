import { find } from 'lodash/fp';
import { useCallback } from 'react';
import { NavigateOptions, useLocation, useNavigate } from 'react-router-dom';

import { RouteModalParams } from './route-modals.types';
import { useAppConfig } from '../context';
import {
  getBackgroundPath,
  RouteOverlayLocationState,
  useRouteOverlays,
} from '../route-overlays';

export function useRouteModals() {
  return useRouteOverlays();
}

export function useRouteModalConfig(routeModalId: string) {
  const { routes } = useAppConfig();

  const routeModalConfig = find({ id: routeModalId }, routes?.routeModals);

  if (!routeModalConfig) {
    throw new Error(`Modal with ID ${routeModalId} not found`);
  }

  return routeModalConfig;
}

export function useOpenRouteModal() {
  const { routes } = useAppConfig();

  const location = useLocation();
  const navigate = useNavigate();

  return useCallback(
    ({
      modalId,
      pathParams = [],
      backgroundPath,
      ...navigateOptions
    }: RouteModalParams & Omit<NavigateOptions, 'state'>) => {
      const route = find((route) => route.id === modalId, routes?.routeModals);

      if (!route) {
        console.error(`Modal with ID ${modalId} not found`);

        return;
      }

      const calculatedBackgroundPath =
        backgroundPath || getBackgroundPath(location, route.backgroundPath);

      return navigate(route.generatePath(pathParams), {
        state: {
          routeOverlay: {
            entityId: modalId,
            backgroundPath: calculatedBackgroundPath,
            path: route.path as string,
          },
        } satisfies RouteOverlayLocationState,
        ...navigateOptions,
      });
    },
    [location, navigate, routes?.routeModals]
  );
}
