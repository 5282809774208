import { globalQueryKeys } from '../global-query-keys';

export const SPACES_API_URL = 'ui/organization/spaces';

export function getSpaceApiUrl(spaceId: number | string) {
  return `${SPACES_API_URL}/${spaceId}`;
}

export const spacesQueryKeys = {
  base: [SPACES_API_URL],
  detail: (spaceId: number) => [...spacesQueryKeys.base, String(spaceId)],
  stats: {
    base: ['space-stats'],
    stats_history: (spaceId: number) => [
      ...spacesQueryKeys.stats.base,
      ...spacesQueryKeys.detail(spaceId),
    ],
  },
  incident_routing: {
    base: ['incident_routing'],
    all: (spaceId: number) => [
      ...spacesQueryKeys.detail(spaceId),
      ...spacesQueryKeys.incident_routing.base,
    ],
  },
  devices: {
    base: [...globalQueryKeys.devices, 'space-devices'],
    all: (spaceId: number) => [
      ...spacesQueryKeys.devices.base,
      ...spacesQueryKeys.detail(spaceId),
    ],
  },
  files: {
    global: [...globalQueryKeys.files],
    all: (spaceId: number) => [
      ...spacesQueryKeys.files.global,
      ...spacesQueryKeys.detail(spaceId),
    ],
  },
};
