import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';
import { StateDumpType, UsePaginatedTableApiQuery } from '@portals/types';

import {
  DEVICES_API_URL,
  devicesQueryKeys,
  getDeviceApiUrl,
} from './devices.constants';
import { fetchApiRequest, useRequestOptions } from '../../utils/common';
import { usePaginatedTableApiQuery } from '../../utils/paginated-table';

function getApiUrl(deviceId: string) {
  return `${getDeviceApiUrl(deviceId)}/state_dumps`;
}

export function useStateDumps(
  tableState: UsePaginatedTableApiQuery<StateDumpType>['tableState'],
  columns: UsePaginatedTableApiQuery<StateDumpType>['columns'],
  baseUrl: string = DEVICES_API_URL,
  queryKey = devicesQueryKeys.stateDumps.base
) {
  return usePaginatedTableApiQuery({
    queryKey: [...queryKey, tableState],
    baseUrl,
    columns,
    tableState,
    queryOptions: {
      staleTime: 0,
    },
  });
}

export function useResetStateDumpAccess(deviceId: string) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: getApiUrl(deviceId),
    method: 'POST',
  });

  return useMutation({
    mutationFn: (stateDumpId: string) => {
      return fetchApiRequest(`${url}/${stateDumpId}/reset_access`, options);
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Reset access link successful'));

      queryClient.invalidateQueries(devicesQueryKeys.stateDumps.all(deviceId));
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useResetStateDumpAccess',
      baseUrl: `${getApiUrl(':id')}/:id/reset_access`,
      method: 'POST',
    },
  });
}

export function useDeleteStateDump(deviceId: string) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: getApiUrl(deviceId),
    method: 'DELETE',
  });

  return useMutation({
    mutationFn: (stateDumpId: string) => {
      return fetchApiRequest(`${url}/${stateDumpId}`, options);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(devicesQueryKeys.stateDumps.all(deviceId));

      dispatch(toastrSuccess('Deleted state dump successfully'));
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useDeleteStateDump',
      baseUrl: `${getApiUrl(':id')}/:id`,
      method: 'DELETE',
    },
  });
}
