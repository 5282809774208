import { Box, createStyles, ScrollArea, Stack } from '@mantine/core';
import { every } from 'lodash/fp';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffectOnce, useSearchParam } from 'react-use';

import { OrderType, useStoreSettings } from '@portals/api/organizations';
import { useGenericContext } from '@portals/framework';
import {
  OrderAttachmentsCard,
  OrderCustomerCard,
  OrderNotesToSellerCard,
  OrderShipmentProgress,
  OrderSummaryCard,
} from '@portals/framework/route-modals';
import { useOpenModal } from '@portals/redux';
import { ProductTypeEnum } from '@portals/types';

import {
  PostPurchaseParametersAlert,
  PriceQuoteAlert,
  PurchaseOrderAlert,
} from './Alerts';
import { OrderProductsCard } from './order-products-card/OrderProductsCard';
import { OrderPaymentMethodCard } from './OrderPaymentMethodCard';

export function OrderContent() {
  const isDisplayPriceQuote = useSearchParam('price_quote');
  const isDisplayPPP = useSearchParam('post_purchase_parameters');
  const openModal = useOpenModal();
  const { classes } = useStyles();

  const navigate = useNavigate();
  const order = useGenericContext<OrderType>();
  const storeSettings = useStoreSettings();

  const isInactiveOrder = ['expired', 'archived', 'failed'].includes(
    order.payment_status
  );
  const isDeviceLicenseOnly = every(
    (item) => item.product.product_type === ProductTypeEnum.DeviceLicense,
    order.items
  );

  const isMissingPPP =
    !isInactiveOrder && order.fulfillment_status === 'missing_information';
  const isMissingPriceQuote =
    !isInactiveOrder &&
    order.payment_method === 'purchase_order' &&
    !order.price_quote_file_url;
  const isRequiresPO =
    !isMissingPriceQuote && order.payment_status === 'awaiting_po_upload';

  useEffectOnce(() => {
    if (isDisplayPriceQuote) {
      navigate(`/m/order/${order.id}`, { replace: true });

      openModal('PriceQuotePreview', { order });
    } else if (isDisplayPPP) {
      navigate(`/m/order/${order.id}`, { replace: true });

      openModal('PostPurchaseParametersModal', {
        orderId: order.id,
      });
    }
  });

  return (
    <ScrollArea styles={{ viewport: { '> div': { height: '100%' } } }}>
      <Box className={classes.wrapper}>
        <Stack className={classes.container} spacing="xl">
          {isRequiresPO ? <PurchaseOrderAlert /> : null}
          {isMissingPriceQuote ? <PriceQuoteAlert /> : null}
          {isMissingPPP ? (
            <PostPurchaseParametersAlert orderId={order.id} />
          ) : null}

          {isInactiveOrder || isDeviceLicenseOnly ? null : (
            <OrderShipmentProgress
              fulfillmentStatus={order.fulfillment_status}
            />
          )}

          <div className={classes.grid}>
            <Stack spacing="xl">
              <OrderProductsCard />
              <OrderSummaryCard
                isTaxEnabled={Boolean(storeSettings.data?.tax_enabled)}
              />
              <OrderPaymentMethodCard />
            </Stack>

            <Stack spacing="xl">
              <OrderNotesToSellerCard />
              <OrderCustomerCard />
              <OrderAttachmentsCard />
            </Stack>
          </div>
        </Stack>
      </Box>
    </ScrollArea>
  );
}

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: 'grid',
    background: theme.white,
    width: '100%',
    height: '100%',
    gridTemplateColumns: '1fr',
    justifyItems: 'center',
    backgroundColor: theme.colors.gray[0],
    overflow: 'hidden',
    position: 'relative',
  },

  container: {
    maxWidth: 1600,
    width: '100%',
    padding: 30,
    overflow: 'hidden',

    [theme.fn.smallerThan('md')]: {
      paddingBlock: theme.spacing.xl,
      paddingInline: theme.spacing.md,
    },
  },

  grid: {
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    gap: theme.spacing.xl,

    [theme.fn.smallerThan('md')]: {
      gridTemplateColumns: '1fr',
    },
  },
}));
