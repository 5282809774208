import { Badge } from '@mantine/core';
import { Prism } from '@mantine/prism';
import React from 'react';

import {
  DeviceDetailsType,
  useCancelDeviceCommand,
} from '@portals/api/organizations';
import { DetailsPanel } from '@portals/core';
import {
  DetailsList,
  DetailsListProps,
  useConfirmationModal,
} from '@portals/framework';
import { useHasEntityAccess } from '@portals/framework/EntityAccess';
import { ReactComponent as CloseSquare } from '@portals/icons/linear/close-square.svg';
import {
  AccessLevelEnum,
  CommandStatusType,
  CommandType,
} from '@portals/types';
import { getCommandStatusInfo, prettyTime } from '@portals/utils';

interface CommandDetailsPanelProps {
  command: CommandType;
  device: DeviceDetailsType;
  onClose: () => void;
}

export function CommandDetailsPanel({
  command,
  device,
  onClose,
}: CommandDetailsPanelProps) {
  const hasEntityAccess = useHasEntityAccess(device, AccessLevelEnum.Edit);
  const asyncConfirmation = useConfirmationModal();
  const cancelDeviceCommand = useCancelDeviceCommand();
  const commandStatusInfo = getCommandStatusInfo(command.status);

  const onCancelCommand = async () => {
    const isConfirm = await asyncConfirmation({
      description: 'Are you sure you want to cancel this command?',
    });

    if (isConfirm) {
      try {
        await cancelDeviceCommand.mutateAsync({
          deviceId: device.id,
          commandId: command.id,
        });

        onClose();
      } catch (e) {
        console.error(e);
      }
    }
  };

  const detailsList: DetailsListProps['items'] = [
    { label: 'Created', value: prettyTime(command.created_at) },
    {
      label: 'Status',
      value: (
        <Badge color={commandStatusInfo.color} radius="sm">
          {commandStatusInfo.label}
        </Badge>
      ),
    },
  ];

  const canCancelCommand =
    hasEntityAccess &&
    (command.status === CommandStatusType.InProgress ||
      command.status === CommandStatusType.Pending);

  return (
    <DetailsPanel>
      <DetailsPanel.Header onClose={onClose} title={command.friendly_name}>
        {canCancelCommand ? (
          <DetailsPanel.Actions>
            <DetailsPanel.ActionButton
              loading={cancelDeviceCommand.isLoading}
              leftIcon={<CloseSquare width={16} height={16} />}
              onClick={onCancelCommand}
            >
              Cancel
            </DetailsPanel.ActionButton>
          </DetailsPanel.Actions>
        ) : null}
      </DetailsPanel.Header>

      <DetailsPanel.Body>
        <DetailsList items={detailsList} />

        <DetailsPanel.Section
          title="Message"
          content={command.message || '--'}
        />

        <DetailsPanel.Section title="Params">
          {command.params ? (
            <Prism
              styles={{ code: { whiteSpace: 'pre-wrap' } }}
              language="json"
              withLineNumbers
            >
              {JSON.stringify(command.params, null, 2)}
            </Prism>
          ) : (
            '--'
          )}
        </DetailsPanel.Section>
      </DetailsPanel.Body>
    </DetailsPanel>
  );
}
