import {
  Box,
  CloseButton,
  createStyles,
  Group,
  LoadingOverlay,
  Modal,
  Text,
} from '@mantine/core';
import React, { useState } from 'react';

import { useCurrentUser } from '@portals/api/ui';
import { ReactComponent as Profile } from '@portals/icons/linear/profile-circle.svg';
import { ReactComponent as ShieldSecurity } from '@portals/icons/linear/shield-security.svg';

import { MyAccount } from './MyAccount';
import { Security } from './Security';
import { ModalProps } from '../../components/Modals';

type Category = 'my-account' | 'security';

export interface PersonalSettingsModalProps
  extends ModalProps<{
    preSelectedCategory?: Category;
  }> {}

export function PersonalSettingsModal({
  closeMe,
  data: { preSelectedCategory },
}: PersonalSettingsModalProps) {
  const { classes, cx } = useStyles();

  const currentUser = useCurrentUser();

  const [category, setCategory] = useState<Category>(
    preSelectedCategory ?? 'my-account'
  );

  return (
    <Modal
      opened
      onClose={closeMe}
      radius="lg"
      padding={0}
      size="clamp(1200px, 90%, 1400px)"
      withCloseButton={false}
    >
      <LoadingOverlay
        visible={currentUser.isInitialLoading || !currentUser.data}
      />

      <Box className={classes.content}>
        <Box className={classes.body}>
          <aside className={classes.sidebar}>
            <Text size="xl" data-testid="personal-settings-title">
              Personal Settings
            </Text>

            <Box>
              <Group
                className={cx(classes.setting, {
                  [classes.selected]: category === 'my-account',
                })}
                onClick={() => setCategory('my-account')}
                p="sm"
              >
                <Profile />

                <Text>My account</Text>
              </Group>

              <Group
                className={cx(classes.setting, {
                  [classes.selected]: category === 'security',
                })}
                onClick={() => setCategory('security')}
                p="sm"
              >
                <ShieldSecurity />

                <Text>Security</Text>
              </Group>
            </Box>
          </aside>

          <main className={classes.main}>
            <CloseButton
              onClick={closeMe}
              size="md"
              className={classes.closeButton}
              data-testid="close-button"
            />

            <Box className={classes.container}>
              {category === 'my-account' ? <MyAccount /> : <Security />}
            </Box>
          </main>
        </Box>
      </Box>
    </Modal>
  );
}

const useStyles = createStyles((theme) => ({
  body: {
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '280px 1fr',
    gridAutoRows: '1fr auto',
  },

  content: {
    overflow: 'hidden',
  },

  sidebar: {
    padding: theme.spacing.xxl,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.xl,
  },

  main: {
    padding: `${theme.spacing.xxl} 64px`,
    backgroundColor: theme.colors.gray[0],
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.lg,
  },

  closeButton: {
    alignSelf: 'flex-end',
  },

  setting: {
    borderRadius: theme.radius.md,
    cursor: 'pointer',
  },

  selected: {
    backgroundColor: theme.colors.gray[2],
  },

  container: {
    minHeight: '65vh',
    overflow: 'auto',
  },
}));
