import { Box, createStyles, Paper, Popover, Stack, Text } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import React, {
  KeyboardEventHandler,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useKey } from 'react-use';

import {
  DeviceModelSearchResultType,
  PartnerSearchResultType,
  useClaimDeviceSearch,
} from '@portals/api/organizations';
import { SearchInput } from '@portals/core';
import { RouteModalLink } from '@portals/framework/route-modals';

import { BrandSearchResult } from './BrandSearchResult';
import { DeviceModelSearchResult } from './DeviceModelSearchResult';
import { OrgC2CIntegrationItem } from '../../../../hooks/c2c-integrations';

interface ClaimDeviceSearchInputProps {
  handleSelectedPartner: (searchResultId: string) => void;
  handleSelectedDeviceModel: (modelId: string) => void;
  handleSelectedC2cIntegration: (href?: string) => void;

  activeIntegrations: Array<OrgC2CIntegrationItem>;
}

export function ClaimDeviceSearchInput({
  handleSelectedPartner,
  handleSelectedDeviceModel,
  handleSelectedC2cIntegration,
  activeIntegrations,
}: ClaimDeviceSearchInputProps) {
  const { classes } = useStyles();

  const searchResultsElementRefs = useRef<Record<string, HTMLDivElement>>({});

  const [shouldDisplayResults, setShouldDisplayResults] = useState(false);
  const [hoveredSearchResultId, setHoveredSearchResultId] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 300);

  const searchResults = useClaimDeviceSearch(debouncedSearchTerm);

  const filteredC2cIntegrations = useMemo(() => {
    const debouncedLowerCaseSearchTerm = debouncedSearchTerm.toLowerCase();

    if (debouncedLowerCaseSearchTerm === '') return activeIntegrations;

    return activeIntegrations.filter((integration) => {
      return integration.name
        .toLowerCase()
        .includes(debouncedLowerCaseSearchTerm);
    });
  }, [activeIntegrations, debouncedSearchTerm]);

  const searchResultsWithC2cIntegrations = useMemo(() => {
    if (!searchResults.data || searchResults.data.length === 0) {
      return filteredC2cIntegrations;
    }
    return [...searchResults.data, ...filteredC2cIntegrations];
  }, [searchResults.data, filteredC2cIntegrations]);

  const { partners, deviceModels } = useMemo(() => {
    const deviceModels: Array<DeviceModelSearchResultType> = [];
    const partners: Array<PartnerSearchResultType> = [];

    searchResults.data?.forEach((result) => {
      if (result.type === 'device_model') {
        deviceModels.push(result);
      } else if (result.type === 'partner') {
        partners.push(result);
      }
    });
    return { partners, deviceModels };
  }, [searchResults.data]);

  useEffect(
    function updateShouldDisplayResults() {
      setShouldDisplayResults(
        deviceModels.length > 0 ||
          partners.length > 0 ||
          filteredC2cIntegrations.length > 0
      );
    },
    [deviceModels.length, partners.length, filteredC2cIntegrations.length]
  );

  const scrollSearchResultElementIntoView = (searchResultId: string) => {
    const element = searchResultsElementRefs.current[searchResultId];

    if (element) {
      element.scrollIntoView({ block: 'nearest' });
    }
  };

  const onArrowDown = () => {
    // Clear the `hoveredSearchResultId` if the searchResult list is empty
    if (
      !searchResultsWithC2cIntegrations ||
      searchResultsWithC2cIntegrations.length === 0
    ) {
      setHoveredSearchResultId('');
      return;
    }

    let searchResultIdToHover: string;

    // If the `hoveredSearchResultId` is not set yet, set it to the first searchResult in the list
    if (!hoveredSearchResultId) {
      searchResultIdToHover = searchResultsWithC2cIntegrations[0].id;

      setHoveredSearchResultId(searchResultIdToHover);
      scrollSearchResultElementIntoView(searchResultIdToHover);

      return;
    }

    const currentHoveredSearchResultIndex =
      searchResultsWithC2cIntegrations.findIndex(
        (searchResult) => searchResult.id === hoveredSearchResultId
      );

    if (
      currentHoveredSearchResultIndex === -1 ||
      currentHoveredSearchResultIndex ===
        searchResultsWithC2cIntegrations.length - 1
    ) {
      // Set the `hoveredSearchResultId` to the first search result in the list
      searchResultIdToHover = searchResultsWithC2cIntegrations[0].id;
    } else {
      // Set the `hoveredSearchResultId` to the next search result in `the list
      searchResultIdToHover =
        searchResultsWithC2cIntegrations[currentHoveredSearchResultIndex + 1]
          .id;
    }

    setHoveredSearchResultId(searchResultIdToHover);
    scrollSearchResultElementIntoView(searchResultIdToHover);
  };

  const onArrowUp = () => {
    // Clear the `hoveredSearchResultId` if the search results list is empty
    if (
      !searchResultsWithC2cIntegrations ||
      searchResultsWithC2cIntegrations.length === 0
    ) {
      setHoveredSearchResultId('');
      return;
    }

    let searchResultIdToHover: string;

    // If the `hoveredSearchResultId` is not set yet, set it to the last search result in the list
    if (!hoveredSearchResultId) {
      searchResultIdToHover =
        searchResultsWithC2cIntegrations[
          searchResultsWithC2cIntegrations.length - 1
        ].id;

      setHoveredSearchResultId(searchResultIdToHover);
      scrollSearchResultElementIntoView(searchResultIdToHover);

      return;
    }

    const currentHoveredSearchResultIdToHoverIndex =
      searchResultsWithC2cIntegrations.findIndex(
        (searchResult) => searchResult.id === hoveredSearchResultId
      );

    if (
      currentHoveredSearchResultIdToHoverIndex === -1 ||
      currentHoveredSearchResultIdToHoverIndex === 0
    ) {
      searchResultIdToHover =
        searchResultsWithC2cIntegrations[
          searchResultsWithC2cIntegrations.length - 1
        ].id;
    } else {
      // Set the `hoveredSearchResultId` to the prev search result in the list
      searchResultIdToHover =
        searchResultsWithC2cIntegrations[
          currentHoveredSearchResultIdToHoverIndex - 1
        ].id;
    }

    setHoveredSearchResultId(searchResultIdToHover);
    scrollSearchResultElementIntoView(searchResultIdToHover);
  };

  const onEnter = () => {
    if (!hoveredSearchResultId) return;

    searchResultsElementRefs.current?.[hoveredSearchResultId]?.click();
  };

  const clearSearchInput = () => {
    setSearchTerm('');
    setHoveredSearchResultId('');
  };

  useKey('ArrowDown', onArrowDown, {}, [
    searchResultsWithC2cIntegrations,
    hoveredSearchResultId,
  ]);
  useKey('ArrowUp', onArrowUp, {}, [
    searchResultsWithC2cIntegrations,
    hoveredSearchResultId,
  ]);
  useKey('Enter', onEnter, {}, [hoveredSearchResultId]);

  const onSearchEscape: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key !== 'Escape') return;

    if (searchTerm === '') return;

    event.stopPropagation();
    setShouldDisplayResults(false);
  };

  return (
    <Popover opened={searchTerm !== ''} width="target" shadow="lg">
      <Popover.Target>
        <SearchInput
          w="100%"
          maw={400}
          size="md"
          data-autofocus
          value={searchTerm}
          placeholder="Search brand or model name..."
          onChange={(event) => setSearchTerm(event.target.value)}
          onClear={clearSearchInput}
          onKeyDown={onSearchEscape}
          loading={searchResults.isFetching}
        />
      </Popover.Target>

      <Popover.Dropdown p={0}>
        <Paper p="xs">
          {shouldDisplayResults && (
            <Box className={classes.results}>
              {deviceModels.length > 0 && (
                <Stack spacing="xs">
                  <Text color="gray.5" weight={500} p="xs">
                    Models
                  </Text>

                  <Stack spacing="xs">
                    {deviceModels.map((deviceModel) => (
                      <DeviceModelSearchResult
                        key={deviceModel.id}
                        ref={(el: HTMLDivElement) =>
                          (searchResultsElementRefs.current[deviceModel.id] =
                            el)
                        }
                        deviceModel={deviceModel}
                        isHovered={hoveredSearchResultId === deviceModel.id}
                        handleSelectedDeviceModel={() =>
                          handleSelectedDeviceModel(deviceModel.id)
                        }
                      />
                    ))}
                  </Stack>
                </Stack>
              )}

              {(partners.length > 0 || filteredC2cIntegrations.length > 0) && (
                <Stack spacing="xs">
                  <Text color="gray.5" weight={500} p="xs">
                    Providers
                  </Text>

                  <Stack spacing="xs">
                    {partners.map((partner) => (
                      <BrandSearchResult
                        key={partner.id}
                        ref={(el: HTMLDivElement) =>
                          (searchResultsElementRefs.current[partner.id] = el)
                        }
                        name={partner.display_name}
                        logoUrl={partner.logo}
                        isHovered={hoveredSearchResultId === partner.id}
                        handleSelected={() => handleSelectedPartner(partner.id)}
                      />
                    ))}

                    {filteredC2cIntegrations.map((integration) => {
                      return (
                        <BrandSearchResult
                          key={integration.id}
                          ref={(el: HTMLDivElement) =>
                            (searchResultsElementRefs.current[integration.id] =
                              el)
                          }
                          name={integration.name}
                          logoUrl={integration.logo}
                          isHovered={hoveredSearchResultId === integration.id}
                          handleSelected={() =>
                            handleSelectedC2cIntegration(integration.href)
                          }
                        />
                      );
                    })}
                  </Stack>
                </Stack>
              )}
            </Box>
          )}

          <Stack spacing={4} align="center" p="sm">
            <Text size="xs" weight={600}>
              Can't find your brand?
            </Text>
            <Text size="xs">Connect them now to add your devices!</Text>

            <RouteModalLink modalId="connect" size="xs" weight={600}>
              Connect
            </RouteModalLink>
          </Stack>
        </Paper>
      </Popover.Dropdown>
    </Popover>
  );
}

const useStyles = createStyles((theme) => ({
  results: {
    overflow: 'auto',
    maxHeight: 350,
    paddingBottom: theme.spacing.xs,
    borderBottom: `1px solid ${theme.colors.gray[2]} `,
  },
}));
