import {
  Center,
  createStyles,
  Divider,
  Group,
  HoverCard,
  Text,
} from '@mantine/core';
import React from 'react';

import { ReactComponent as InfoCircleIcon } from '@portals/icons/linear/info-circle.svg';
import { CurrencyCode } from '@portals/types';
import { formatDateTime, formatPrice } from '@portals/utils';

import { DetailsList, DetailsListProps } from './details-list/DetailsList';

interface UsageBasedPriceBreakdownPreviewProps {
  currencyCode: CurrencyCode;
  basePriceInScu: number | null;
  usageChargeInScu: number | null;
  currentUsageBasedMeter: number | undefined;
  usageBasedUnitName: string | null;
  deviceLastSeenAt: string;
}

export function UsageBasedPriceBreakdownPreview({
  currentUsageBasedMeter,
  basePriceInScu,
  usageBasedUnitName,
  usageChargeInScu,
  deviceLastSeenAt,
  currencyCode,
}: UsageBasedPriceBreakdownPreviewProps) {
  const { classes } = useStyles();

  const detailsList: DetailsListProps['items'] = [];

  if (basePriceInScu) {
    detailsList.push({
      label: 'Base price',
      value: formatPrice({
        value: basePriceInScu,
        currencyCode,
      }),
    });
  }

  detailsList.push({
    label: `Usage cost (${currentUsageBasedMeter} ${usageBasedUnitName})`,
    value: formatPrice({
      value: usageChargeInScu,
      currencyCode,
    }),
  });

  const getTotalPrice = () => {
    let totalPrice = usageChargeInScu ?? 0;

    if (basePriceInScu) {
      totalPrice += basePriceInScu;
    }

    return formatPrice({ value: totalPrice, currencyCode });
  };

  return (
    <HoverCard
      withinPortal
      withArrow
      arrowSize={20}
      shadow="lg"
      radius="md"
      width={350}
      position="top"
    >
      <HoverCard.Target>
        <InfoCircleIcon className={classes.infoIcon} />
      </HoverCard.Target>

      <HoverCard.Dropdown p="xl">
        <DetailsList items={detailsList} />

        <Divider my="lg" />

        <Group position="apart" mb="xxl">
          <Text color="gray.8" weight={600}>
            Total
          </Text>

          <Text size="xl" weight={600}>
            {getTotalPrice()}
          </Text>
        </Group>

        <Center className={classes.dateTime}>
          as of {formatDateTime(deviceLastSeenAt)}
        </Center>
      </HoverCard.Dropdown>
    </HoverCard>
  );
}

const useStyles = createStyles((theme) => ({
  infoIcon: {
    width: 16,
    height: 16,
    color: theme.colors.gray[8],
  },
  dateTime: {
    padding: theme.spacing.xs,
    borderRadius: theme.radius.md,
    fontSize: theme.fontSizes.xs,
    fontWeight: 600,
    color: theme.colors.gray[9],
    backgroundColor: theme.fn.rgba(theme.colors.blue_gray[0], 0.4),
  },
}));
