import dayjs from 'dayjs';
import { v4 as uuid } from 'uuid';

import { ScatterChartWidgetEventsConfig } from './scatter-chart.types';
import { ScatterChartWidgetProps } from './ScatterChartWidget';
import { ScatterChartWidgetFormType } from '../form';

function generateBaseMockData() {
  const now = dayjs();

  return Array.from({ length: 10 }, () => {
    const randomTimestamp = now
      .subtract((Math.random() * 10) % 7, 'days')
      .toISOString();

    return {
      id: uuid(),
      timestamp: randomTimestamp,
      additional_info: {
        int_key_1: Math.floor(Math.random() * 10),
      },
    };
  });
}

// Mock data of max 8 different events
const DEVICE_EVENTS_MOCK_DATA = Array.from({ length: 8 }, () =>
  generateBaseMockData()
);

export function getScatterChartMockData(
  events: ScatterChartWidgetFormType['events']
): ScatterChartWidgetProps['data'] {
  let result: ScatterChartWidgetProps['data'] = [];

  (events || []).forEach((event, index) => {
    const currentMockDataCollection = DEVICE_EVENTS_MOCK_DATA[index];

    const formatted = currentMockDataCollection.map((mockEvent) => ({
      value: mockEvent.additional_info.int_key_1,
      timestamp: new Date(mockEvent.timestamp).getTime(),
      eventId: event.id,
    }));

    result = result.concat(formatted);
  });

  return result;
}

export function getScatterChartEventsConfig(
  events: ScatterChartWidgetFormType['events']
) {
  const eventsConfig: ScatterChartWidgetEventsConfig = {};

  (events || []).forEach((event) => {
    eventsConfig[event.id] = { ...event };
  });

  return eventsConfig;
}

export function getXAxisTicksForLast24Hours() {
  return [dayjs().endOf('hour').valueOf()].concat(
    Array.from({ length: 23 }, (_, i) =>
      dayjs()
        .subtract(i + 1, 'hour')
        .startOf('hour')
        .valueOf()
    )
  );
}

export function getXAxisTicksForLast7Days() {
  return [
    dayjs().endOf('hour').valueOf(),
    dayjs().startOf('day').valueOf(),
  ].concat(
    Array.from({ length: 6 }, (_, i) =>
      dayjs()
        .subtract(i + 1, 'day')
        .startOf('day')
        .valueOf()
    )
  );
}

export function getXAxisTicksForLast30Days() {
  return [
    dayjs().endOf('hour').valueOf(),
    dayjs().startOf('day').valueOf(),
  ].concat(
    Array.from({ length: 29 }, (_, i) =>
      dayjs()
        .subtract(i + 1, 'day')
        .startOf('day')
        .valueOf()
    )
  );
}
