import { Button, Group, Stack, Tooltip } from '@mantine/core';
import { find, isEmpty, sortBy } from 'lodash/fp';
import React from 'react';

import {
  DeviceDetailsType,
  DeviceType,
  useActiveDeviceCommands,
} from '@portals/api/organizations';
import { EntityAccess } from '@portals/framework/EntityAccess';
import { ReactComponent as Crown2 } from '@portals/icons/bold/crown-2.svg';
import { useOpenModal } from '@portals/redux';
import { AccessLevelEnum, SupportedCommandType } from '@portals/types';

interface DeviceCommandsProps {
  device: DeviceDetailsType;
}

export function DeviceCommands({ device }: DeviceCommandsProps) {
  const openModal = useOpenModal();
  const activeCommands = useActiveDeviceCommands(device.id, {
    refetchInterval: 10000,
  });

  if (isEmpty((device as DeviceType)?.supported_commands)) return null;

  const visibleCommands = sortBy(
    (command) => (command.premium && !command.has_license ? 1 : 0),
    (device as DeviceType)?.supported_commands.filter(
      (command: SupportedCommandType) => command.is_visible_in_panel
    )
  );

  if (isEmpty(visibleCommands)) {
    return null;
  }

  const onCommandSend = (command: SupportedCommandType) => {
    if (command.premium && !command.has_license) {
      openModal('PremiumCommandOnMobile');
    } else {
      openModal('RunCommand', {
        deviceType: device.partner.type_id,
        command,
        deviceIds: [device.id],
      });
    }
  };

  const getIsCommandActive = (commandName: string) =>
    !!find({ name: commandName }, activeCommands.data);

  return (
    <Stack bg="gray.0" p="md">
      {visibleCommands.map((command) => {
        const isDisabled =
          !command.allow_duplicate_commands && getIsCommandActive(command.name);

        return (
          <EntityAccess
            key={command.id}
            id={command.id}
            minLevel={AccessLevelEnum.Edit}
            entity={device}
          >
            <Tooltip
              label="Once the queued or in progress command is sent you can send the command again"
              key={command.id}
              withinPortal
              withArrow
              multiline
              width={210}
              disabled={!isDisabled}
            >
              <Button
                w="100%"
                size="md"
                variant="default"
                loading={isDisabled}
                data-testid={`run-command-${command.friendly_name}`}
                data-analyticsid={
                  command.premium && !command.has_license
                    ? 'locked'
                    : 'unlocked'
                }
                onClick={() => onCommandSend(command)}
              >
                <Group spacing={4}>
                  {command.premium && !command.has_license ? (
                    <Crown2 width={18} height={18} />
                  ) : null}
                  {command.friendly_name}
                </Group>
              </Button>
            </Tooltip>
          </EntityAccess>
        );
      })}
    </Stack>
  );
}
