import React, {
  ReactElement,
  ReactNode,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useObserveChildrenIntersection } from './hooks';
import { OverflowRenderer } from './types';
import { getChildrenListWithRef, getOverflowComponent } from './utils';

interface OverflowGroupProps {
  overflowRenderer: OverflowRenderer;
  containerStyle?: Record<string, any>;
  isAlwaysRenderOverflow?: boolean;
  children: ReactNode;
}

/*
 * How it works:
 *
 * 1. It renders a "shadow" copy of the given component, assigning it custom refs. When all refs
 *  are assigned, an IntersectionObserver is created on the container element & all children
 *  elements are observed for intersection
 *
 * 2. When a child is intersected, another shadow component is rendered, but with the additional
 *  `overflowRenderer` - we need it to calculate whether additional items should be hidden,
 *  after the overflow renderer is added
 *
 * 3. After the 2nd shadow component is rendered & its children are observed, the original
 *  component is rendered w/ omitted hidden children & the overflow renderer at its end
 *
 * 4. Both shadow components are persisted in DOM (invisible, no pointer events, -1 zIndex) to
 *  observe any changes if needed & re-calculate then num of hidden items
 *
 * Important:
 * - Items must be wrapped with forwardRef to work
 * - Items container must have `overflow: hidden` CSS property assigned
 *
 * Example usage:
 *   <OverflowGroup overflowRenderer={({ amount, hiddenIndexes }) => <Badge>+ {amount}</Badge>}>
 *     <div>
 *       <Item/>
 *       <Item/>
 *       <Item/>
 *       <Item/>
 *     </div>
 *   </OverflowGroup>
 * */

export function OverflowGroup({
  children,
  overflowRenderer,
  containerStyle = {},
  isAlwaysRenderOverflow = false,
}: OverflowGroupProps) {
  const [isRefsSet, setIsRefsSet] = useState(false);
  const [shadowHiddenAmount, setShadowHiddenAmount] = useState(0);
  const [shadowWithOverflowHiddenAmount, setShadowWithOverflowHiddenAmount] =
    useState(0);

  const shadowRefsList = useRef<Array<HTMLElement>>([]);
  const shadowRefsListWithOverflow = useRef<Array<HTMLElement>>([]);

  const shadowComponentHiddenIndexes = useRef<Array<number>>([]);
  const shadowComponentWithOverflowHiddenIndexes = useRef<Array<number>>([]);

  const { adjustedComponent, shadowComponent, shadowComponentWithOverflow } =
    useMemo(() => {
      let shadowComponent = null;
      let shadowComponentWithOverflow = null;
      let adjustedComponent = children;

      React.Children.forEach(
        children as ReactElement,
        (element: ReactElement) => {
          const numOfChildren = React.Children.count(element.props.children);

          const shadowComponentChildren = getChildrenListWithRef({
            children: element.props.children,
            numOfChildren,
            refsList: shadowRefsList,
            onAllRefsSet: () => setIsRefsSet(true),
          });

          const Overflow = getOverflowComponent({
            overflowRenderer,
            amount: shadowComponentWithOverflowHiddenIndexes.current?.length,
            hiddenIndexes: shadowComponentWithOverflowHiddenIndexes.current,
          });

          // Create initial shadow component to check whether any of the children are overflowing
          shadowComponent = React.cloneElement(element, {
            ...element.props,
            style: {
              borderColor: 'blue',
            },
            key: `${element.props.key}-shadow`,
            children: isAlwaysRenderOverflow
              ? [
                  React.cloneElement(Overflow, {
                    key: 'overflow-element',
                  }),
                  ...shadowComponentChildren,
                ]
              : shadowComponentChildren,
          });

          // When one of the shadow component children is overflowing, `shadowHiddenAmount` is set
          if (shadowHiddenAmount || isAlwaysRenderOverflow) {
            // Create a shadow component with the overflow renderer, to calculate how many
            // children should be hidden w/ overflow renderer present
            const shadowComponentWithOverflowChildren = [
              React.cloneElement(Overflow, {
                key: 'overflow-element',
              }),
              ...getChildrenListWithRef({
                refsList: shadowRefsListWithOverflow,
                children: element.props.children,
              }),
            ];

            shadowComponentWithOverflow = React.cloneElement(element, {
              ...element.props,
              style: {
                borderColor: 'green',
              },
              key: `${element.props.key}-shadow-with-overflow`,
              children: shadowComponentWithOverflowChildren,
            });
          } else {
            shadowRefsListWithOverflow.current = [];
            shadowComponentWithOverflowHiddenIndexes.current = [];
          }

          // `shadowHiddenAmount` is calculated from the `shadowComponent`
          // `shadowWithOverflowHiddenAmount` is calculated from the
          // `shadowComponentWithOverflow` component
          if (shadowHiddenAmount || isAlwaysRenderOverflow) {
            if (shadowWithOverflowHiddenAmount || isAlwaysRenderOverflow) {
              // Create final adjusted component, omitting the hidden children calculated from
              // both components above & adding the overflow renderer at the end
              const adjustedComponentChildren = [
                ...React.Children.map(
                  element.props.children,
                  (childElement, index) => {
                    if (index >= numOfChildren - shadowWithOverflowHiddenAmount)
                      return null;

                    return childElement;
                  }
                ),
                React.cloneElement(Overflow, {
                  key: `overflow-element-${shadowComponentWithOverflowHiddenIndexes.current?.length}`,
                }),
              ];

              adjustedComponent = React.cloneElement(element, {
                ...element.props,
                key: `${element.props.key}`,
                children: adjustedComponentChildren,
              });
            }
          }
        }
      );

      return {
        adjustedComponent,
        shadowComponent,
        shadowComponentWithOverflow,
      };
    }, [
      children,
      isAlwaysRenderOverflow,
      overflowRenderer,
      shadowHiddenAmount,
      shadowWithOverflowHiddenAmount,
    ]);

  useObserveChildrenIntersection({
    isActive: isRefsSet,
    hiddenIndexesRef: shadowComponentHiddenIndexes,
    childrenNodesRef: shadowRefsList,
    onUpdate: setShadowHiddenAmount,
  });

  useObserveChildrenIntersection({
    isActive: !!shadowHiddenAmount,
    hiddenIndexesRef: shadowComponentWithOverflowHiddenIndexes,
    childrenNodesRef: shadowRefsListWithOverflow,
    onUpdate: setShadowWithOverflowHiddenAmount,
    indexOffset: 1,
  });

  return (
    <div
      style={{
        position: 'relative',
        width: 'auto',
        overflow: 'hidden',
        ...containerStyle,
      }}
    >
      {adjustedComponent}

      {/* Hidden elements, used only for calculations of hidden elements */}
      <div
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          opacity: 0,
          pointerEvents: 'none',
          visibility: 'hidden',
          zIndex: -1,
        }}
      >
        {shadowComponent}

        {shadowComponentWithOverflow}
      </div>
    </div>
  );
}
