import {
  Box,
  Button,
  Center,
  createStyles,
  Group,
  Stack,
  Text,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React from 'react';

import {
  DevicesPerSpaceFormType,
  DevicesPerSpaceViewEnum,
  useConfig,
} from '@portals/api/organizations';
import { useConfirmationModal } from '@portals/framework';

import { DevicesPerSpaceForm } from './DevicesPerSpaceForm';
import { DevicesPerSpaceWidget } from './DevicesPerSpaceWidget';
import { WidgetPreviewComponentProps } from '../../../../../modals/WidgetPreview/widget-preview.types';
import { DataLevelEnum } from '../../../overview.types';

export function DevicesPerSpaceWidgetWrapper({
  onClose,
  space,
  onSave,
  type,
  widgetId,
  dataLevel,
}: WidgetPreviewComponentProps & { onClose: () => void }) {
  const { classes } = useStyles();

  const config = useConfig();

  const deviceModelBreakdownWidgetExtraParams =
    config.data?.space_dashboard?.list.find(
      (item) => item.id === 'devices_per_space' && item.i === widgetId
    )?.extraParams as DevicesPerSpaceFormType | undefined;

  const getInitialValues = () => {
    const initialValues: DevicesPerSpaceFormType = {
      title: 'Devices per space',
      devicesPerSpaceView: DevicesPerSpaceViewEnum.OnlineDevices,
    };

    if (deviceModelBreakdownWidgetExtraParams?.title) {
      initialValues.title = deviceModelBreakdownWidgetExtraParams.title;
    }

    if (deviceModelBreakdownWidgetExtraParams?.devicesPerSpaceView) {
      initialValues.devicesPerSpaceView =
        deviceModelBreakdownWidgetExtraParams.devicesPerSpaceView;
    }

    return initialValues;
  };

  const form = useForm<DevicesPerSpaceFormType>({
    initialValues: getInitialValues(),
  });

  const asyncConfirmationCheck = useConfirmationModal();

  const onCloseWrapper = async () => {
    if (!form.isDirty()) {
      onClose();
      return;
    }

    const isConfirmed = await asyncConfirmationCheck({
      title: 'Are you sure?',
      description: 'All your changes will be lost',
      confirmationLabel: 'Confirm',
      cancelLabel: 'Cancel',
    });

    if (isConfirmed) {
      onClose();
    }
  };

  const onSubmit = ({ devicesPerSpaceView, title }: typeof form.values) => {
    onSave?.({
      devicesPerSpaceView,
      title,
    });

    onClose();
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Box className={classes.container}>
        <Stack className={classes.preview} p="xxl" pb={0} spacing="xxl">
          <Text
            data-testid="devices-per-space-modal-title"
            size={24}
            color="blue_gray.9"
          >
            Devices per space
          </Text>

          <DevicesPerSpaceForm form={form} />
        </Stack>

        <Center className={classes.config}>
          <DevicesPerSpaceWidget
            space={space}
            devicesPerSpaceView={form.values.devicesPerSpaceView}
            dataLevel={dataLevel ?? DataLevelEnum.All}
            widgetTitle={form.values.title}
          />
        </Center>

        <Group className={classes.footer} p="xl" position="right">
          <Button variant="default" onClick={onCloseWrapper}>
            Close
          </Button>

          <Button type="submit" data-testid="modal-add-widget-button">
            {type === 'edit' ? 'Save' : 'Add'}
          </Button>
        </Group>
      </Box>
    </form>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr min-content',
    gridTemplateAreas: `
      "preview config"
      "footer footer"
    `,
    gap: 0,
    height: 790,
  },

  preview: {
    gridArea: 'preview',
  },

  config: {
    gridArea: 'config',
    backgroundColor: theme.colors.gray[0],
  },

  footer: {
    gridArea: 'footer',
    borderTop: `1px solid ${theme.colors.gray[2]}`,
  },
}));
