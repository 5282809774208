import { Button, ButtonProps } from '@mantine/core';
import React, { forwardRef, useCallback } from 'react';

import { useOpenModal } from '@portals/redux';

import { useEntityAccessContext } from './EntityAccess/context';

type ModalButtonType = {
  modalName: string;
  data?: any;
  label: string;
} & ButtonProps;

export const ModalButton = forwardRef<HTMLButtonElement, ModalButtonType>(
  ({ modalName, data, label, ...buttonProps }, ref) => {
    const { isDisabled } = useEntityAccessContext();
    const openModal = useOpenModal();

    const onOpenModal = useCallback(() => {
      if (isDisabled) return;

      openModal(modalName, data);
    }, [data, isDisabled, modalName, openModal]);

    return (
      <Button
        ref={ref}
        size="xs"
        disabled={isDisabled}
        onClick={onOpenModal}
        styles={{
          leftIcon: {
            width: '16px',
            height: '16px',
          },
        }}
        {...buttonProps}
      >
        {label}
      </Button>
    );
  }
);
