import React from 'react';

import { useOverviewContext } from '../../../overview.context';
import { useCurrentSpace } from '../../../overview.hooks';
import { KpiWidget } from '../common/KPIWidget';

export function ClaimedDevicesWidget() {
  const space = useCurrentSpace();
  const overviewContext = useOverviewContext();

  const label =
    space?.state?.[
      overviewContext.isLocalDataLevel ? 'local_devices' : 'devices'
    ] || 0;

  return (
    <KpiWidget
      kpiWidget={{
        title: 'Claimed Devices',
        label,
      }}
    />
  );
}

export function OnlineDevicesWidget() {
  const space = useCurrentSpace();
  const overviewContext = useOverviewContext();

  const label =
    space?.state?.[
      overviewContext.isLocalDataLevel
        ? 'local_online_devices'
        : 'online_devices'
    ] || 0;

  return (
    <KpiWidget
      kpiWidget={{
        title: 'Online Devices',
        label,
      }}
    />
  );
}

export function ActiveIncidentsWidget() {
  const space = useCurrentSpace();
  const overviewContext = useOverviewContext();

  const label =
    space?.state?.[
      overviewContext.isLocalDataLevel ? 'local_incidents' : 'incidents'
    ]?.total || 0;

  return (
    <KpiWidget
      kpiWidget={{
        title: 'Active Incidents',
        label,
      }}
    />
  );
}
