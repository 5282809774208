import { DatePickerInput } from '@mantine/dates';
import React, { FC } from 'react';

import { FieldRendererProps } from '@portals/types';

const DatetimeField: FC<FieldRendererProps> = ({
  error,
  field,
  value,
  handleBlur,
  clearable,
  disabled,
  setFieldValue,
  readOnly,
  required,
}) => {
  const { name, title, placeholder, inputProps = {} } = field;
  const { startAdornment, endAdornment, ...restInputProps } = inputProps || {};

  return (
    <DatePickerInput
      popoverProps={{ withinPortal: true }}
      label={title}
      data-testid={'date-picker-' + name}
      clearable={clearable}
      required={required}
      placeholder={placeholder}
      value={value}
      error={error}
      disabled={disabled}
      readOnly={readOnly}
      onChange={(value) => setFieldValue(name, value)}
      icon={startAdornment}
      rightSection={endAdornment}
      onBlur={handleBlur}
      {...restInputProps}
    />
  );
};

export default DatetimeField;
