import React, { useContext } from 'react';
import { Button } from 'reactstrap';

import { AutoFormik } from '@portals/autoformik';
import { upcaseFirst } from '@portals/utils';

import { IntegrationsContext } from './Integrations';

const TestConnectionMessage = ({ msg }) =>
  msg ? (
    <div className="mt-2">
      <i>{msg}</i>
    </div>
  ) : null;

const TestConnection = (id, config, testConnection, values, valid) => (
  <>
    <Button
      className="ml-2"
      onClick={() => testConnection(id, values)}
      disabled={!valid}
    >
      Send test message
    </Button>

    <TestConnectionMessage msg={config.testConnectionMessage} />
  </>
);

const ShowError = ({ data, field }) => {
  const error = data[field + '_error'];

  return error ? (
    <p className="text-danger">
      Error using
      {upcaseFirst(field)} URL :{error}
    </p>
  ) : null;
};

const ShowErrors = ({ config, data }) => (
  <div className="mt-3">
    {config.fields.map(({ name }) => (
      <ShowError key={name} data={data} field={name} />
    ))}
  </div>
);

const extraButtons =
  (data, config, id, updateIntegration, testConnection) => (values, valid) => {
    const toggleIntegration = (direction) => () =>
      updateIntegration(id, { ...data.params, enabled: direction });

    return (
      <>
        {data.state === 'active' && (
          <Button
            color="danger"
            onClick={toggleIntegration(false)}
            className="ml-1"
          >
            Disable
          </Button>
        )}
        {data.state === 'disabled' && data.params.webhook && (
          <Button
            color="primary"
            onClick={toggleIntegration(true)}
            className="ml-1"
          >
            Enable
          </Button>
        )}

        {config.canTest &&
          TestConnection(id, config, testConnection, values, valid)}
      </>
    );
  };

const EditIntegration = ({ id, config }) => {
  const { integrations, testConnection, updateIntegration } =
    useContext(IntegrationsContext);
  const data = integrations[id] || {};
  const Message = config.message;

  return (
    <div>
      <AutoFormik
        fields={config.fields}
        initialValues={data.params}
        inProgress={false} // For now we choose not to connect the Component only to get the traffic. 22.9.21 Dor (approved by boris)
        handleSubmit={(values) => updateIntegration(id, values)}
        wrapped={extraButtons(
          data,
          config,
          id,
          updateIntegration,
          testConnection
        )}
      />

      <ShowErrors config={config} data={data} />

      {Message && <Message />}
    </div>
  );
};

export default EditIntegration;
