import { Alert, Anchor, Button, Modal, Stack, Text } from '@mantine/core';
import React, { useState } from 'react';

import {
  SubscriptionDetailsType,
  useCancelSubscription,
  useStoreSettings,
} from '@portals/api/organizations';
import { ModalBody, ModalFooter } from '@portals/core';
import {
  ModalProps,
  SubscriptionCancellationForm,
  SubscriptionCancellationFormProps,
  useOpenPolicyModal,
  useSubscriptionCancellationForm,
} from '@portals/framework';
import { useOpenModal } from '@portals/redux';
import { ProductPricingModel } from '@portals/types';
import { mediumDateFormatter } from '@portals/utils';

import { SubscriptionCancellationSuccessModalProps } from '../SubscriptionCancellationSuccessModal';

export interface SubscriptionCancellationFlowModalProps
  extends ModalProps<{ subscription: SubscriptionDetailsType }> {}

export function SubscriptionCancellationFlowModal({
  closeMe,
  data: { subscription },
}: SubscriptionCancellationFlowModalProps) {
  const [isCancellationFormModalOpen, setIsCancellationFormModalOpen] =
    useState(subscription.require_cancellation_reason);

  const { reason, setReason, freeText, setFreeText } =
    useSubscriptionCancellationForm();

  const storeSettings = useStoreSettings();
  const cancelSubscription = useCancelSubscription();

  const openModal = useOpenModal();
  const openPolicyModal = useOpenPolicyModal();

  const onOpenCancellationsAndReturnsModal = () => {
    openPolicyModal({
      policyKey: 'cancellations_and_returns_policy',
      isEditable: false,
      editorContent: storeSettings.data?.cancellations_and_returns_policy,
    });
  };

  const onConfirmCancellation = async () => {
    try {
      await cancelSubscription.mutateAsync({
        subscriptionId: subscription.id,
        reason,
        freeText,
      });

      openModal<SubscriptionCancellationSuccessModalProps['data']>(
        'SubscriptionCancellationSuccessModal',
        {
          nextChargeAt: subscription.next_charge_at,
          isCancelledImmediately:
            subscription.product?.pricing_model ===
            ProductPricingModel.UsageBased,
          alert: (
            <SubscriptionCancellationAlert
              openPoliciesModal={onOpenCancellationsAndReturnsModal}
            />
          ),
        }
      );

      closeMe();
    } catch (e) {
      console.error(e);
    }
  };

  if (isCancellationFormModalOpen) {
    return (
      <SubscriptionCancellationFormModal
        onConfirm={() => setIsCancellationFormModalOpen(false)}
        onClose={closeMe}
        reason={reason}
        onChangeReason={setReason}
        freeText={freeText}
        onChangeFreeText={setFreeText}
      />
    );
  }

  return (
    <SubscriptionCancellationConfirmModal
      subscription={subscription}
      onCancel={closeMe}
      onConfirm={onConfirmCancellation}
      onOpenCancellationsAndReturnsModal={onOpenCancellationsAndReturnsModal}
    />
  );
}

interface SubscriptionCancellationFormModalProps
  extends SubscriptionCancellationFormProps {
  onClose: () => void;
  onConfirm: () => void;
}
function SubscriptionCancellationFormModal({
  onClose,
  onConfirm,
  reason,
  onChangeReason,
  freeText,
  onChangeFreeText,
}: SubscriptionCancellationFormModalProps) {
  return (
    <Modal
      opened
      padding="xxl"
      onClose={onClose}
      title="Subscription Cancellation Feedback"
    >
      <ModalBody>
        <Stack spacing="xl">
          <Text weight={500}>
            We're sorry to see you go. To help us better understand our
            customers’ needs and improve the service, we would love to hear your
            feedback. Could you please spend a few minutes to answer the
            following questions?
          </Text>

          <SubscriptionCancellationForm
            reason={reason}
            onChangeReason={onChangeReason}
            freeText={freeText}
            onChangeFreeText={onChangeFreeText}
          />
        </Stack>
      </ModalBody>

      <ModalFooter position="right">
        <Button
          variant="default"
          onClick={onClose}
          data-testid="keep-subscription-button"
        >
          Keep Subscription
        </Button>
        <Button onClick={onConfirm} data-testid="proceed-to-cancel-button">
          Proceed to Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

interface SubscriptionCancellationConfirmModalProps {
  subscription: SubscriptionDetailsType;
  onOpenCancellationsAndReturnsModal: () => void;
  onCancel: () => void;
  onConfirm: () => void;
}
function SubscriptionCancellationConfirmModal({
  subscription,
  onCancel,
  onConfirm,
  onOpenCancellationsAndReturnsModal,
}: SubscriptionCancellationConfirmModalProps) {
  let descriptionMessage;

  if (subscription.product.pricing_model === ProductPricingModel.UsageBased) {
    descriptionMessage = (
      <Text>
        You are about to proceed with canceling your subscription for this
        product. By confirming this action, you will lose access to its features
        and benefits immediately.
      </Text>
    );
  } else {
    descriptionMessage = (
      <Text>
        You are about to proceed with canceling your subscription for this
        product. By confirming this action, you will lose access to its features
        and benefits when your current billing period expires on{' '}
        <strong>{mediumDateFormatter(subscription.next_charge_at)}.</strong>
      </Text>
    );
  }

  return (
    <Modal
      opened
      onClose={onCancel}
      title="Confirm Subscription Cancellation"
      padding="xxl"
    >
      <ModalBody>
        <Stack>
          {descriptionMessage}

          <SubscriptionCancellationAlert
            openPoliciesModal={onOpenCancellationsAndReturnsModal}
          />
        </Stack>
      </ModalBody>

      <ModalFooter position="right">
        <Button
          variant="default"
          onClick={onCancel}
          data-testid="keep-subscription-button"
        >
          Keep Subscription
        </Button>
        <Button
          color="red"
          onClick={onConfirm}
          data-testid="cancel-subscription-button"
        >
          Cancel Subscription
        </Button>
      </ModalFooter>
    </Modal>
  );
}

interface SubscriptionCancellationAlertProps {
  openPoliciesModal: () => void;
}

function SubscriptionCancellationAlert({
  openPoliciesModal,
}: SubscriptionCancellationAlertProps) {
  return (
    <Alert
      color="red"
      radius="md"
      data-testid="review-cancellation-terms-alert"
    >
      Please review the seller's{' '}
      <Anchor onClick={openPoliciesModal}>cancellation terms</Anchor> for
      important details.
    </Alert>
  );
}
