import { Alert, Loader, Text } from '@mantine/core';
import React from 'react';

export function DeviceReplacementEvent() {
  return (
    <Alert icon={<Loader />} color="blue_accent" p="xl" radius="lg">
      <Text color="gray.8">Waiting for device replacement....</Text>
    </Alert>
  );
}
