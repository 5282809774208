import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';

import {
  DEVICES_API_URL,
  devicesQueryKeys,
  getDeviceApiUrl,
} from './devices.constants';
import { ServerError } from '../../types/common';
import { fetchApiRequest, useRequestOptions } from '../../utils/common';

interface UseUpdateDeviceConfigurationParams {
  device_id: string;
  configuration: string;
}

export function useUpdateDeviceConfiguration() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: '',
    method: 'POST',
  });

  return useMutation<void, ServerError, UseUpdateDeviceConfigurationParams>({
    mutationFn: ({ device_id, configuration }) =>
      fetchApiRequest(`${url}/${getDeviceApiUrl(device_id)}/configuration`, {
        ...options,
        body: JSON.stringify(configuration),
      }),
    onSuccess: (_, { device_id }) => {
      queryClient.invalidateQueries(devicesQueryKeys.details(device_id));
      dispatch(toastrSuccess('Configuration updated successfully'));
    },
    onError: ({ error }) => {
      dispatch(toastrError('Failed to update configuration', error));
    },
    meta: {
      mutationName: 'useUpdateDeviceConfiguration',
      baseUrl: `${DEVICES_API_URL}/:id/configuration`,
      method: 'POST',
    },
  });
}
