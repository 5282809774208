import { get } from 'lodash/fp';
import React, { FC } from 'react';
import { connect } from 'react-redux';

import { AutoFormik } from '@portals/autoformik';
import { FieldType, FieldTypeEnum, IntegrationType } from '@portals/types';

import ServiceDisabled from './ServiceDisabled';
import { ServiceCardFormProps } from './types';

const EditSlack: FC<
  ServiceCardFormProps & { integration: IntegrationType }
> = ({ integration, data, readOnly, onSubmit }) => {
  if (!integration || integration.state !== 'active') {
    return (
      <ServiceDisabled
        name="Slack"
        url="/settings/integrations/messaging/slack"
      />
    );
  }

  const FIELDS: Array<FieldType> = [
    {
      name: 'channel',
      title: 'Channel',
      required: true,
      type: FieldTypeEnum.VirtualizedSelect,
      options: integration?.extra?.channels,
      inputProps: {
        menuPortalTarget: document.querySelector('#root'),
      },
    },
  ];

  return (
    <AutoFormik
      fields={FIELDS}
      initialValues={data}
      handleSubmit={onSubmit}
      readOnly={readOnly}
      inProgress={false}
      submitTitle="Update"
    />
  );
};

const mapStateToProps = (state) => ({
  integration: get(['data', 'integrations', 'slack'], state),
});

export default connect(mapStateToProps)(EditSlack);
