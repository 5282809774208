import { noop } from 'lodash/fp';
import { createContext, Dispatch, SetStateAction } from 'react';

import { Locale, LOCALES } from '../constants';

import type { Locales } from '../types';

export const LocalizationContext = createContext<{
  locale: Locale;
  setLocale: Dispatch<SetStateAction<Locale>>;
  locales: Locales;
}>({ locale: Locale.en, setLocale: noop, locales: LOCALES });
