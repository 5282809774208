import { Divider, Group, Paper, Stack, Text } from '@mantine/core';
import dayjs from 'dayjs';
import React from 'react';
import { TooltipProps } from 'recharts';

import { getScatterChartEventsConfig } from './scatter-chart.utils';

interface TooltipContentProps extends TooltipProps<number, string> {
  eventsConfig: ReturnType<typeof getScatterChartEventsConfig>;
  customFormatter?: (value: number | undefined) => string | undefined;
}

export function TooltipContent({
  active,
  payload,
  customFormatter,
  eventsConfig,
}: TooltipContentProps) {
  if (!active) return null;

  const timestamp = payload?.find((p) => p.dataKey === 'timestamp')?.value;
  const value = payload?.find((p) => p.dataKey === 'value')?.value;

  const eventId = payload?.[0]?.payload?.eventId;
  const eventConfig = eventsConfig[eventId];

  return (
    <Paper p="md" radius="lg" shadow="md" withBorder>
      <Stack spacing="xs" align="center">
        <Text size="xs" color="gray.9">
          {dayjs(timestamp).format('MMMM Do YYYY, HH:mm:ss')}
        </Text>

        <Divider color="gray.2" orientation="horizontal" w="100%" />

        <Group align="center" spacing={4}>
          <Text color="gray.9" size="xs" weight="bold">
            {eventConfig?.event_display_name}:
          </Text>

          <Text color="gray.6" weight="bold" size="xs">
            {`${customFormatter ? customFormatter(value) : value}${
              eventConfig?.units || ''
            }`}
          </Text>
        </Group>
      </Stack>
    </Paper>
  );
}
