import {
  incidentsAgeQueryKeys,
  INCIDENTS_AGE_API_URL,
} from './active-incidentes-age.constants';
import { useApiQuery } from '../../../hooks';

interface UseIncidentsAgeParams {
  spaceId: number;
  includeSubSpaces: boolean;
}

interface UseIncidentsAgeResponse {
  median_incident_age_in_seconds: number;
}

export function useIncidentsAge({
  spaceId,
  includeSubSpaces,
}: UseIncidentsAgeParams) {
  return useApiQuery<UseIncidentsAgeResponse>(
    `${INCIDENTS_AGE_API_URL}/?space_id=${spaceId}&include_sub_spaces=${includeSubSpaces}`,
    incidentsAgeQueryKeys.all
  );
}
