import { createStyles, Group, Text } from '@mantine/core';
import React from 'react';

import { DeviceDetailsType } from '@portals/api/organizations';

import { DeviceAvatarWithIndicator } from '../../../../components/DeviceAvatarWithIndicator';
import { useOpenRouteModal } from '../../../route-modals.hooks';

interface ChildDevicesProps {
  device: DeviceDetailsType;
}

export function ChildDevices({ device }: ChildDevicesProps) {
  const { classes } = useStyles();

  const openRouteModal = useOpenRouteModal();

  return (
    <>
      {device.child_devices.map((childDevice) => (
        <Group
          key={childDevice.id}
          noWrap
          className={classes.childDeviceContainer}
          data-testid="child-device-container"
          onClick={() =>
            openRouteModal({ modalId: 'device', pathParams: [childDevice.id] })
          }
        >
          <DeviceAvatarWithIndicator
            deviceName={childDevice.name}
            deviceStatus={childDevice.state?.status}
            size="lg"
            icon={childDevice.model_settings.icon}
            src={childDevice.image_url}
          />

          <div className={classes.textsContainer}>
            <Text
              truncate
              color="blue_gray.9"
              weight={600}
              data-testid="child-device-name-display"
            >
              {childDevice.name}
            </Text>
            <Text truncate size="xs" color="gray.6" weight={600}>
              {childDevice.device_model_name}
            </Text>
            <Text truncate size="xs" color="gray.5" mt="xs">
              {childDevice.display_path}
            </Text>
          </div>
        </Group>
      ))}
    </>
  );
}

const useStyles = createStyles((theme) => ({
  childDeviceContainer: {
    padding: theme.spacing.md,
    borderRadius: theme.radius.md,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: theme.colors.gray[1],
    },
  },
  textsContainer: {
    overflow: 'hidden',
  },
}));
