import { isEmpty, keys, map } from 'lodash/fp';
import React, { FC } from 'react';

import { ReactComponent as CloseX } from '@portals/icons/linear/close-x.svg';
import { ReactComponent as TickSimple } from '@portals/icons/linear/tick-simple.svg';
import { EmptyState, InfoTable } from '@portals/table';
import { DeviceType } from '@portals/types';
import { JsonBlob } from '@portals/ui';

const renderValue = (data) => {
  if (typeof data === 'object' || Array.isArray(data)) {
    return <JsonBlob json={data} />;
  }

  if (typeof data === 'boolean') {
    return data ? (
      <TickSimple width={18} height={18} />
    ) : (
      <CloseX width={18} height={18} />
    );
  }

  return data;
};

const DetailsView: FC<{ device: DeviceType }> = ({ device }) => {
  const details = device?.details || {};

  if (isEmpty(details)) {
    return (
      <div className="h-100 d-flex align-items-center justify-content-center">
        <EmptyState label="No additional data" />
      </div>
    );
  }

  return (
    <div className="h-100 p-3">
      <InfoTable.Table>
        {map(
          (id) => (
            <InfoTable.Row
              key={id}
              label={id}
              value={renderValue(details[id])}
            />
          ),
          keys(details)
        )}
      </InfoTable.Table>
    </div>
  );
};

export default DetailsView;
