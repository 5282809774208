import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError } from '@portals/redux/actions/toastr';
import {
  InvoiceTimelineEventType,
  PaginatedQueryParamsType,
  PaginationResponse,
  UsePaginatedTableApiQuery,
} from '@portals/types';

import { PartnerInvoiceType } from './invoices.types';
import { ServerError } from '../../types';
import {
  buildUrlFromFilters,
  fetchApiRequest,
  useRequestOptions,
} from '../../utils';
import { usePaginatedTableApiQuery } from '../../utils/paginated-table';
import { ordersQueryKeys } from '../orders';

export const INVOICES_API_URL = 'ui/partner/payments';

const invoicesQueryKeys = {
  all: [INVOICES_API_URL, 'partnerInvoices'],
  list: () => [...invoicesQueryKeys.all, 'list'],
};

export const useInvoices = (
  params: PaginatedQueryParamsType<PartnerInvoiceType>
) => {
  const { url, options } = useRequestOptions({ url: INVOICES_API_URL });

  const requestUrl = buildUrlFromFilters<PartnerInvoiceType>({
    url,
    ...params,
  });

  return useQuery<PaginationResponse<PartnerInvoiceType>>({
    queryKey: [...invoicesQueryKeys.all, params],
    queryFn: () => fetchApiRequest(requestUrl, options),
    meta: {
      baseUrl: INVOICES_API_URL,
      method: 'GET',
    },
  });
};

export function useInvoicesTableData(
  tableState: UsePaginatedTableApiQuery<PartnerInvoiceType>['tableState'],
  columns: UsePaginatedTableApiQuery<PartnerInvoiceType>['columns'],
  baseUrl = INVOICES_API_URL
) {
  return usePaginatedTableApiQuery<PartnerInvoiceType>({
    baseUrl,
    tableState,
    columns,
    queryKey: [...invoicesQueryKeys.list(), baseUrl, tableState],
  });
}

export const useUpdateInvoicePartnerNote = () => {
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: INVOICES_API_URL,
    method: 'PATCH',
  });

  return useMutation({
    mutationFn: ({ note, invoiceId }: { invoiceId: string; note: string }) =>
      fetchApiRequest(`${url}/${invoiceId}`, {
        ...options,
        body: JSON.stringify({ partner_note: note }),
      }),
    onSuccess: async () => {
      await queryClient.refetchQueries(invoicesQueryKeys.all);
    },
    meta: {
      mutationName: 'useUpdateInvoicePartnerNote',
      baseUrl: `${INVOICES_API_URL}/:id`,
      method: 'PATCH',
    },
  });
};

export const useUploadInvoice = (invoiceId: string) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { url, options } = useRequestOptions({
    url: `${INVOICES_API_URL}/${invoiceId}`,
    method: 'PATCH',
  });

  return useMutation({
    mutationFn: (fileUrl: string) =>
      fetchApiRequest(url, {
        ...options,
        body: JSON.stringify({ invoice_file_url: fileUrl }),
      }),
    onSuccess: async () => {
      queryClient.refetchQueries(invoicesQueryKeys.all);
      queryClient.refetchQueries(ordersQueryKeys.all);
    },
    onError: () =>
      dispatch(toastrError('Failed to upload Receipt file. Please try again')),
    meta: {
      mutationName: 'useUploadInvoice',
      baseUrl: `${INVOICES_API_URL}/:id`,
      method: 'PATCH',
    },
  });
};

interface UseExtendInvoiceExpirationTimeParams {
  invoiceId: string;
  extensionPeriodDays: number;
}

export function useExtendInvoiceExpirationTime() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: INVOICES_API_URL,
    method: 'PATCH',
  });

  return useMutation<void, ServerError, UseExtendInvoiceExpirationTimeParams>({
    mutationFn: ({ invoiceId, extensionPeriodDays }) =>
      fetchApiRequest(`${url}/${invoiceId}/extend_expiration`, {
        ...options,
        body: JSON.stringify({ extension_period_days: extensionPeriodDays }),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(invoicesQueryKeys.all);
    },
    onError: ({ error }) => dispatch(toastrError(error)),
    meta: {
      mutationName: 'useExtendInvoiceExpirationTime',
      baseUrl: `${INVOICES_API_URL}/:id/extend_expiration`,
      method: 'PATCH',
    },
  });
}

interface UseInvoiceRetryChargeParams {
  invoiceId: string;
}

export function useInvoiceRetryCharge() {
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: INVOICES_API_URL,
    method: 'PATCH',
  });

  return useMutation<void, ServerError, UseInvoiceRetryChargeParams>({
    mutationFn: ({ invoiceId }) =>
      fetchApiRequest(`${url}/${invoiceId}/retry_charge`, options),
    onSuccess: async () => {
      queryClient.invalidateQueries(invoicesQueryKeys.all);
    },
    meta: {
      mutationName: 'useInvoiceRetryCharge',
      baseUrl: `${INVOICES_API_URL}/:id/retry_charge`,
      method: 'PATCH',
    },
  });
}

interface UseMarkAsUncollectibleParams {
  invoiceId: string;
}

export function useMarkAsUncollectible() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: INVOICES_API_URL,
    method: 'PATCH',
  });

  return useMutation<void, ServerError, UseMarkAsUncollectibleParams>({
    mutationFn: ({ invoiceId }) =>
      fetchApiRequest(`${url}/${invoiceId}/uncollectible`, options),
    onSuccess: () => {
      queryClient.invalidateQueries(invoicesQueryKeys.all);
    },
    onError: ({ error }) => dispatch(toastrError(error)),
  });
}

export function useInvoiceTimeline(invoiceId: string) {
  const { url, options } = useRequestOptions({
    url: `${INVOICES_API_URL}/${invoiceId}/timeline`,
    method: 'GET',
  });

  return useQuery<InvoiceTimelineEventType[], ServerError>({
    queryKey: invoicesQueryKeys.all,
    queryFn: () => fetchApiRequest(url, options),
    staleTime: 0,
    meta: {
      baseUrl: `${INVOICES_API_URL}/:id/timeline`,
      method: 'GET',
    },
  });
}
