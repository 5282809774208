import React, { useMemo } from 'react';

import { DeviceType } from '@portals/api/organizations';
import { DeviceType as CommonDeviceType } from '@portals/types';

import { WithSchema } from '../../JsonSchemaForm/WithSchema';
import NoSchema from './NoSchema';
import XioConfig from './XioConfig';
import ZoomConfig from './ZoomConfig';

interface ConfigViewProps {
  device: DeviceType | CommonDeviceType;
  updateConfig: (device_id: string, configuration: string) => void;
}

const ConfigView = ({ device, updateConfig }: ConfigViewProps) => {
  const view = useMemo(() => {
    if (device.driver === 'xio') {
      return (
        <div className="p-3">
          <XioConfig device={device} />
        </div>
      );
    }

    if (device.driver === 'zoom') {
      return (
        <div className="p-3">
          <ZoomConfig device={device} />
        </div>
      );
    }

    if (device.config_schema && device.config_schema !== 'null') {
      return (
        <WithSchema
          device={device}
          updateConfig={updateConfig}
          key={device.driver}
        />
      );
    }

    return <NoSchema device={device} updateConfig={updateConfig} />;
  }, [device, updateConfig]);

  return <>{view}</>;
};

export default ConfigView;
