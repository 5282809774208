import React from 'react';
import styled from 'styled-components';

import { getStyledThemeColor } from '@portals/utils';

const Divider = () => (
  <DividerWrapper>
    <div className="divider" />
    OR
    <div className="divider" />
  </DividerWrapper>
);

const DividerWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-column-gap: 12px;
  align-items: center;
  margin: 25px 0;
  color: ${getStyledThemeColor('gray500')};
  width: 100%;

  .divider {
    height: 1px;
    width: 100%;
    background-color: ${getStyledThemeColor('gray300')};
  }
`;

export default Divider;
