import { useMemo } from 'react';

interface UseDeviceIncidentRateWidgetCountAndPercentageProps {
  devicesCount: number;
  devicesWithIncidentsCount: number;
}
export function useDeviceIncidentRateWidgetCountAndPercentage({
  devicesCount,
  devicesWithIncidentsCount,
}: UseDeviceIncidentRateWidgetCountAndPercentageProps) {
  const devicesWithoutIncidentsCount = useMemo(
    () => devicesCount - devicesWithIncidentsCount,
    [devicesCount, devicesWithIncidentsCount]
  );

  const percentageOfDevicesWithIncidents = useMemo(() => {
    const percentage = Math.floor(
      (devicesWithIncidentsCount / devicesCount) * 100
    );

    return isNaN(percentage) ? 0 : percentage;
  }, [devicesWithIncidentsCount, devicesCount]);

  const percentageOfDevicesWithoutIncidents = useMemo(() => {
    const percentage = Math.floor(
      (devicesWithoutIncidentsCount / devicesCount) * 100
    );

    return isNaN(percentage) ? 0 : percentage;
  }, [devicesWithoutIncidentsCount, devicesCount]);

  return {
    devicesWithoutIncidentsCount,
    percentageOfDevicesWithIncidents,
    percentageOfDevicesWithoutIncidents,
  };
}
