import {
  Box,
  Button,
  createStyles,
  Group,
  LoadingOverlay,
  Stack,
} from '@mantine/core';
import { PDFDocumentProxy } from 'pdfjs-dist';
import React, { useState } from 'react';

import { FilePreview } from '@portals/framework';
import { ReactComponent as Export } from '@portals/icons/linear/export.svg';
import { ReactComponent as Printer } from '@portals/icons/linear/printer.svg';
import { VerticalScrollBar } from '@portals/scrollbar';
import { downloadFile } from '@portals/utils/files';

interface PDFPreviewProps {
  url: string;
  onSubmit: () => void;
}

export function PDFPreview({ url, onSubmit }: PDFPreviewProps) {
  const { classes } = useStyles();

  const [PDFDocumentProxy, setPDFDocumentProxy] = useState<PDFDocumentProxy>();
  const [isFailedToLoad, setIsFailedToLoad] = useState(false);
  const [isDownloaded, setIsDownloaded] = useState(false);

  const onDownload = () => downloadFile(url, 'price_quote');

  const onPrint = async () => {
    if (!PDFDocumentProxy) return;

    const data = await PDFDocumentProxy.getData();
    const blob = new Blob([data], { type: 'application/pdf' });

    window.open(URL.createObjectURL(blob));
  };

  return (
    <Stack className={classes.content} spacing={0}>
      <LoadingOverlay visible={!PDFDocumentProxy?.numPages} />

      <Group position="right" className={classes.actions} spacing="xs">
        <Button
          variant="white"
          leftIcon={<Export />}
          color="blue_gray"
          onClick={onDownload}
        >
          Download
        </Button>
        <Button
          variant="white"
          leftIcon={<Printer />}
          color="blue_gray"
          onClick={onPrint}
        >
          Print
        </Button>
      </Group>

      <Box className={classes.documentWrapper}>
        <VerticalScrollBar height="100%">
          <FilePreview
            fileUrl={url}
            fileName="price quote"
            PDFDocumentProxy={PDFDocumentProxy}
            setPDFDocumentProxy={setPDFDocumentProxy}
            setIsDownloaded={setIsDownloaded}
            isDownloaded={isDownloaded}
            isFailedToLoad={isFailedToLoad}
            setIsFailedToLoad={setIsFailedToLoad}
            withAutomaticDownload={false}
          />
        </VerticalScrollBar>
      </Box>

      <Group position="right" className={classes.footer}>
        <Button data-testid="pdf-preview-done-button" onClick={onSubmit}>
          Done
        </Button>
      </Group>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  content: {
    display: 'grid',
    gridTemplateRows: 'min-content 1fr min-content',
    gridTemplateColumns: '1fr',
    height: '90vh',
    maxHeight: 1300,
  },
  actions: {
    padding: theme.spacing.xl,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },
  documentWrapper: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: '100%',
  },
  footer: {
    borderTop: `1px solid ${theme.colors.gray[2]}`,
    padding: theme.spacing.xl,
  },
}));
