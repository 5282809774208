import { useMantineTheme } from '@mantine/core';
import { get, isUndefined, last, toNumber } from 'lodash/fp';
import React, { FC } from 'react';
import ReactSpeedometer, {
  CustomSegmentLabelPosition,
  Transition,
} from 'react-d3-speedometer';
import styled from 'styled-components';

import { EmptyState } from '@portals/table';
import { getStyledThemeColor } from '@portals/utils';

import { WidgetProps } from './types';

const GaugeWidget: FC<WidgetProps> = ({ widget, deviceStates }) => {
  const { colors } = useMantineTheme();

  if (!widget?.config?.fields) return <EmptyState label="No data available" />;

  const {
    min_value,
    max_value,
    low_range_end,
    medium_range_end,
    tracked_measurement,
    tracked_measurement_unit,
    min_low_segment_color = '#FF1744',
    low_mid_segment_color = '#FF9100',
    mid_max_segment_color = '#66BB6A',
    min_low_segment_label = '',
    low_mid_segment_label = '',
    mid_max_segment_label = '',
  } = widget.config.fields;

  const lastDeviceState = last(deviceStates)?.data;
  const value = get([tracked_measurement], lastDeviceState);

  if (isUndefined(value)) return <EmptyState label="No data available" />;

  return (
    <Container className="gauge-widget-container">
      <div className="gauge-wrapper">
        <ReactSpeedometer
          minValue={toNumber(min_value)}
          maxValue={toNumber(max_value)}
          value={toNumber(value)}
          needleColor={colors.gray[8]}
          segmentColors={[
            min_low_segment_color,
            low_mid_segment_color,
            mid_max_segment_color,
          ]}
          segments={3}
          ringWidth={30}
          paddingHorizontal={0}
          paddingVertical={0}
          valueTextFontSize="40px"
          needleTransition={Transition.easeCubicInOut}
          customSegmentLabels={[
            {
              text: min_low_segment_label,
              position: CustomSegmentLabelPosition.Outside,
            },
            {
              text: low_mid_segment_label,
              position: CustomSegmentLabelPosition.Outside,
            },
            {
              text: mid_max_segment_label,
              position: CustomSegmentLabelPosition.Outside,
            },
          ]}
          currentValueText={`${value}`}
          customSegmentStops={[
            toNumber(min_value),
            toNumber(low_range_end),
            toNumber(medium_range_end),
            toNumber(max_value),
          ]}
        />

        {tracked_measurement_unit && (
          <div className="unit">{tracked_measurement_unit}</div>
        )}
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-rows: 240px;
  place-content: center;

  .gauge-wrapper {
    height: 100%;
    position: relative;

    .speedometer {
      .current-value {
        transform: translateY(7%);
      }
    }

    .unit {
      position: absolute;
      bottom: 15px;
      z-index: 1;
      left: 50%;
      transform: translateX(-50%);
      color: ${getStyledThemeColor('gray500')};
      font-size: 17px;
    }
  }
`;

export default GaugeWidget;
