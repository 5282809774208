import { Box, createStyles, Stack } from '@mantine/core';
import React from 'react';
import { Navigate } from 'react-router-dom';

import {
  CurrencyCode,
  OrganizationStoreListing,
  ProductTypeEnum,
} from '@portals/types';

import { ProductActions } from './ProductActions';
import { ProductDescription } from './ProductDescription';
import { ProductDetailsProvider } from './ProductDetailsProvider';
import { ProductGallery } from './ProductGallery';
import { ProductHeader } from './ProductHeader';
import { ProductModelsAndCommands } from './ProductModelsAndCommands';
import { ProductPolicies } from './ProductPolicies';

interface ProductDetailsProps {
  storeListing: OrganizationStoreListing | undefined;
  isLoading?: boolean;
  baseStoreUrl?: string;
  currencyCode: CurrencyCode | null;
  className?: string;
}

export function ProductDetails({
  currencyCode,
  storeListing,
  baseStoreUrl = '/store',
  className,
}: ProductDetailsProps) {
  const { classes, cx } = useStyles();

  if (!storeListing?.product) return <Navigate to={baseStoreUrl} replace />;

  return (
    <ProductDetailsProvider storeListing={storeListing}>
      <Box className={cx(classes.container, className)}>
        <div className={classes.detailsWrapper}>
          <Box>
            <Stack spacing="xl" className={classes.details}>
              <ProductHeader
                storeListing={storeListing}
                currencyCode={currencyCode}
                baseStoreUrl={baseStoreUrl}
              />

              <ProductActions storeListingStatus={storeListing.status} />

              <ProductDescription product={storeListing.product} />
            </Stack>

            <Stack className={classes.details}>
              {storeListing.product.product_type ===
              ProductTypeEnum.PlatformLicense ? (
                <ProductModelsAndCommands
                  supportedCommands={storeListing.product.supported_commands}
                />
              ) : null}

              <ProductPolicies />
            </Stack>
          </Box>
        </div>

        <ProductGallery product={storeListing.product} />
      </Box>
    </ProductDetailsProvider>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    zIndex: 2,

    [theme.fn.largerThan('xl')]: {
      gridTemplateColumns: '1.5fr 1fr',
    },
  },
  detailsWrapper: {
    width: '100%',
    padding: `${theme.spacing.xl} 50px`,
    display: 'flex',
    justifyContent: 'center',
    background: theme.white,
    overflow: 'auto',

    [theme.fn.largerThan('xl')]: {
      padding: `58px 100px`,
    },
  },
  details: {
    width: '100%',
    maxWidth: 500,
  },
}));
