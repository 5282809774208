import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';

import {
  configurationQueryKeys,
  CONTACTS_API_URL,
} from './configuration.constants';
import { ContactType } from './configuration.types';
import { useApiQuery } from '../../hooks';
import { fetchApiRequest, useRequestOptions } from '../../utils';

export const usePartnerContacts = () => {
  return useApiQuery<ContactType>(
    CONTACTS_API_URL,
    configurationQueryKeys.contacts.all
  );
};

/*
 * Mutations
 * */

export const useUpdateContacts = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { url, options } = useRequestOptions({
    url: CONTACTS_API_URL,
    method: 'PUT',
  });

  return useMutation({
    mutationFn: (contacts: Partial<ContactType>) =>
      fetchApiRequest(url, {
        ...options,
        body: JSON.stringify(contacts),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(configurationQueryKeys.contacts.all);

      dispatch(toastrSuccess('Updated contacts successfully'));
    },
    onError: ({ error }: any) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useUpdateContacts',
      baseUrl: CONTACTS_API_URL,
      method: 'PUT',
    },
  });
};
