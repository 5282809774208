import { LoadingOverlay } from '@mantine/core';
import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import {
  useClaimDeviceByQrCode,
  useIsCecEnabled,
  useCheckQrCodeAccess,
} from '@portals/api/organizations';
import { useOpenRouteModal } from '@portals/framework/route-modals';

import { QR_SEARCH_PARAM } from '../../constants/global-search-params.constants';
import { useClaimFirstDeviceTourCompleted } from '../../hooks/claim-first-device-tour-completed';

export function ClaimDeviceByQrCode() {
  const openRouteModal = useOpenRouteModal();
  const navigate = useNavigate();
  const claimDeviceByQrCode = useClaimDeviceByQrCode();

  const isCecEnabled = useIsCecEnabled();
  const checkQrCodeAccess = useCheckQrCodeAccess();

  const params = useParams<{ cloudId: string }>();
  const [searchParams] = useSearchParams();

  const claimFirstDeviceTourCompleted = useClaimFirstDeviceTourCompleted();

  const onClaimDevice = (qrCode: string) => {
    if (isCecEnabled) {
      checkQrCodeAccess.mutate(
        { qr: qrCode },
        {
          onSuccess: (response) => {
            if (response.claim_allowed) {
              onClaimAllowedDevice(qrCode);
            } else {
              openRouteModal({
                modalId: 'connect',
                pathParams: [response.device_partner_name, qrCode],
                backgroundPath: '/',
                replace: true,
              });
            }
          },
          onError: () => {
            navigate('/', { replace: true });
          },
        }
      );
    } else {
      onClaimAllowedDevice(qrCode);
    }
  };

  const onClaimAllowedDevice = (qrCode: string) => {
    claimDeviceByQrCode.mutate(
      { qr: qrCode },
      {
        onSuccess: async () => {
          await claimFirstDeviceTourCompleted();
        },
        onSettled: () => navigate('/'),
      }
    );
  };

  useEffectOnce(function claimDeviceOnQrParam() {
    const qrCode = searchParams.get(QR_SEARCH_PARAM) || params.cloudId;

    if (qrCode) {
      onClaimDevice(qrCode);
    } else {
      navigate('/');
    }
  });

  return <LoadingOverlay visible />;
}
