import { Box, Text } from '@mantine/core';
import React, { FC, ReactNode } from 'react';

interface PriceProps {
  label: ReactNode;
  price: ReactNode;
}

export const Price: FC<PriceProps> = ({ label, price }) => (
  <Box
    sx={{
      display: 'grid',
      gridTemplateColumns: '1fr max-content',
      alignItems: 'center',
    }}
  >
    <Text
      size="sm"
      weight={300}
      sx={(theme) => ({ color: theme.colors.gray[5], alignSelf: 'start' })}
    >
      {label}
    </Text>

    <Text
      size="sm"
      sx={(theme) => ({ color: theme.colors.blue_gray[9] })}
      weight={500}
      align="right"
    >
      {price}
    </Text>
  </Box>
);
