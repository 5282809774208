import { get, identity, isArray, isBoolean, map, some } from 'lodash/fp';
import { createSelector, Selector } from 'reselect';

import { StateType } from '@portals/types';

export const getUi = (state: StateType) => state.ui;

export const getNetwork: Selector<StateType, StateType['ui']['network']> =
  createSelector(getUi, get('network'));

export const getNotifications: Selector<
  StateType,
  StateType['ui']['notifications']
> = createSelector(getUi, get('notifications'));

export const getAuth: Selector<StateType, StateType['ui']['auth']> =
  createSelector(getUi, get('auth'));

export const getAuthError: Selector<StateType, StateType['ui']['authError']> =
  createSelector(getUi, get('authError'));

export const getIsPending = (type: string | Array<string>) =>
  createSelector(getNetwork, (network) => {
    if (isArray(type)) {
      return !!some(
        identity,
        map((currentType) => {
          const currentNetworkType = get(currentType, network);

          if (isBoolean(currentNetworkType)) return currentNetworkType;

          return currentNetworkType > 0;
        }, type)
      );
    }

    return !!get(type, network);
  });
