import { useMantineTheme } from '@mantine/core';
import { keys, map, toString, isEmpty } from 'lodash/fp';
import React, { FC } from 'react';
import styled from 'styled-components';

import { ReactComponent as Refresh2 } from '@portals/icons/linear/refresh-2.svg';
import { EmptyState, InfoTable } from '@portals/table';
import { DeviceType } from '@portals/types';
import { getStyledThemeColor, timeAgo } from '@portals/utils';

const StateView: FC<{ device: DeviceType }> = ({ device }) => {
  const state = device.state;

  const theme = useMantineTheme();

  if (isEmpty(state)) {
    return (
      <div className="h-100 d-flex align-items-center justify-content-center">
        <EmptyState label="No state data" />
      </div>
    );
  }

  const lastSeen = device.last_seen
    ? timeAgo(Date.parse(device.last_seen))
    : 'Unknown';

  return (
    <div className="h-100 p-3">
      <InfoTable.Table>
        {map(
          (id) => (
            <InfoTable.Row key={id} label={id} value={toString(state[id])} />
          ),
          keys(state)
        )}
      </InfoTable.Table>

      <Footer>
        <Refresh2
          width={17}
          height={17}
          style={{ marginRight: theme.spacing.xs }}
        />
        {`Last update: ${lastSeen}`}
      </Footer>
    </div>
  );
};

const Footer = styled.div`
  width: 100%;
  display: flex;
  padding: 15px 0;
  justify-content: flex-end;
  color: ${getStyledThemeColor('gray600')};
  font-size: 15px;
  align-items: center;
`;

export default StateView;
