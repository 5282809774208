import { Box, createStyles, MantineSize, Text, TextProps } from '@mantine/core';
import { PolymorphicComponentProps } from '@mantine/utils';
import React, { FC } from 'react';

import { ComponentRendererType } from '@portals/types';

interface TextWithIconCellProps {
  Icon: ComponentRendererType;
  label: string;
  color?: string;
  shade?: number;
  spacing?: MantineSize;
  textProps?: TextProps &
    PolymorphicComponentProps<TextProps, any> &
    React.ComponentPropsWithoutRef<'div'>;
}

export const TextWithIconCell: FC<TextWithIconCellProps> = ({
  Icon,
  label,
  color = 'gray',
  spacing = 'md',
  shade = 7,
  textProps = {},
}) => {
  const { classes } = useStyles();

  return (
    <Box
      className={classes.container}
      sx={(theme) => ({
        gap: theme.spacing?.[spacing] || theme.spacing.md,
      })}
    >
      <Icon />

      <Text size="sm" className={classes.label} color={color} {...textProps}>
        {label}
      </Text>
    </Box>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'min-content 1fr',
    whiteSpace: 'nowrap',

    svg: {
      flexShrink: 0,
      width: 18,
      height: 18,
    },
  },
  label: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));
