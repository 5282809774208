import { createStyles, Group, Textarea, TextInput } from '@mantine/core';
import React, { useState } from 'react';

import { IncidentType, useUpdateIncident } from '@portals/api/organizations';
import { DetailsPanel } from '@portals/core';
import { DetailsList, PriorityIcon } from '@portals/framework';

import { PriorityLevelSelect } from '../PriorityLevelSelect';

interface IncidentEditDetailsPanelProps<TIncident> {
  incident: TIncident;
  discardEdit: () => void;
  closeDetailsPanel: () => void;
}

export function IncidentEditDetailsPanel<TIncident extends IncidentType>({
  incident,
  discardEdit,
  closeDetailsPanel,
}: IncidentEditDetailsPanelProps<TIncident>) {
  const { classes } = useStyles();

  const updateIncident = useUpdateIncident();

  const [title, setTitle] = useState(incident.title);
  const [description, setDescription] = useState(incident.description ?? '');
  const [priorityLevel, setPriorityLevel] = useState(incident.priority);

  const onSubmit = () => {
    updateIncident.mutate(
      {
        deviceId: incident.device_id,
        incidentId: incident.id,
        incident: {
          title,
          description,
          priority: priorityLevel,
        },
      },
      { onSuccess: closeDetailsPanel }
    );
  };

  return (
    <DetailsPanel>
      <DetailsPanel.Header
        onClose={closeDetailsPanel}
        title={
          <Group noWrap>
            <PriorityIcon priorityLevel={priorityLevel} iconSize={36} />
            <TextInput
              data-testid="incident-title-input"
              size="md"
              className={classes.textInput}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Group>
        }
      />

      <DetailsPanel.Body>
        <DetailsPanel.Section title="Description">
          <Textarea
            data-testid="incident-description-textarea-input"
            minRows={15}
            maxRows={15}
            value={description}
            onChange={(e) => setDescription(e.currentTarget.value)}
          />
        </DetailsPanel.Section>

        <DetailsList
          items={[
            {
              label: 'Priority',
              value: (
                <PriorityLevelSelect
                  value={priorityLevel}
                  onChange={setPriorityLevel}
                />
              ),
            },
          ]}
        />
      </DetailsPanel.Body>

      <DetailsPanel.Footer>
        <DetailsPanel.Actions>
          <DetailsPanel.ActionButton onClick={discardEdit}>
            Discard
          </DetailsPanel.ActionButton>
          <DetailsPanel.ActionButton
            variant="filled"
            loading={updateIncident.isLoading}
            onClick={onSubmit}
            data-testid="save-changes-button"
          >
            Save Changes
          </DetailsPanel.ActionButton>
        </DetailsPanel.Actions>
      </DetailsPanel.Footer>
    </DetailsPanel>
  );
}

const useStyles = createStyles(() => ({
  textInput: {
    flexGrow: 1,
  },
}));
