import { Button, LoadingOverlay, Modal, Text } from '@mantine/core';
import React from 'react';

import {
  useRenewSubscription,
  useSubscription,
} from '@portals/api/organizations';
import { ModalBody, ModalFooter } from '@portals/core';
import { ModalProps } from '@portals/framework';

export interface SubscriptionRenewalModalProps
  extends ModalProps<{ subscriptionId: string; onSuccess: () => void }> {}

export function SubscriptionRenewalModal({
  closeMe,
  data: { subscriptionId, onSuccess },
}: SubscriptionRenewalModalProps) {
  const subscription = useSubscription(subscriptionId);
  const renewSubscription = useRenewSubscription();

  const onRenewSubscription = async () => {
    try {
      await renewSubscription.mutateAsync({ subscriptionId });

      onSuccess();
      closeMe();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal opened onClose={closeMe} title="Confirm subscription renewal">
      <ModalBody>
        <LoadingOverlay visible={subscription.isLoading} />

        <Text>
          You are renewing your subscription to{' '}
          <strong>{subscription.data?.product?.name}</strong>
        </Text>
      </ModalBody>

      <ModalFooter position="right">
        <Button variant="default" onClick={closeMe}>
          Cancel
        </Button>
        <Button
          loading={renewSubscription.isLoading}
          onClick={onRenewSubscription}
          data-testid="subscription-renewal-modal-renew-button"
        >
          Renew Subscription
        </Button>
      </ModalFooter>
    </Modal>
  );
}
