import {
  ActionIcon,
  createStyles,
  Group,
  LoadingOverlay,
  Stack,
  Text,
} from '@mantine/core';
import { AnimatePresence, motion, MotionStyle } from 'framer-motion';
import React, { useEffect, useState } from 'react';

import { ReactComponent as CollapseRightIcon } from '@portals/icons/linear/collapse-right.svg';
import { SmartTable } from '@portals/table';
import {
  CurrencyCode,
  SubscriptionInvoiceItemType,
  TableColumn,
  TenantType,
} from '@portals/types';
import { formatNumber, formatPrice, mediumDateFormatter } from '@portals/utils';

import { OrganizationInvoicePaymentStatusBadge } from './OrganizationInvoicePaymentStatusBadge';
import { PartnerInvoicePaymentStatusBadge } from './PartnerInvoicePaymentStatusBadge';

interface PaymentsHistoryPanelProps {
  isOpen: boolean;
  currencyCode: CurrencyCode;
  usageBasedDisplayName: string;
  usageBasedUnitName: string;
  usageBasedPricePerUnit: number | null | undefined;
  onClose: () => void;
  invoiceItems: SubscriptionInvoiceItemType[];
  isFetching: boolean;
  tenantType: string;
}

export function PaymentsHistoryPanel({
  invoiceItems,
  isFetching,
  isOpen,
  currencyCode,
  usageBasedDisplayName,
  usageBasedUnitName,
  usageBasedPricePerUnit,
  onClose,
  tenantType,
}: PaymentsHistoryPanelProps) {
  const { classes } = useStyles();

  const [wrapperStyles, setWrapperStyles] = useState<MotionStyle>();

  useEffect(function calcWrapperDimensions() {
    function resizeListener() {
      const pageContentElement = document.getElementById('page-content');
      const pageContentRect = pageContentElement?.getBoundingClientRect?.();
      const detailsPanelWidth = document?.getElementsByClassName?.(
        'table-details-panel-container'
      )?.[0]?.clientWidth;

      if (!pageContentElement || !pageContentRect) return;

      setWrapperStyles({
        // +1px for the details panel left border
        right: detailsPanelWidth + 1,
        maxWidth: pageContentRect.width - detailsPanelWidth,
      });
    }

    // Initial calculation
    resizeListener();

    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  const columns: TableColumn<SubscriptionInvoiceItemType>[] = [
    {
      dataField: 'created_at',
      text: 'Date',
      maxWidth: 120,
      minWidth: 120,
      formatter: (_, { created_at }) => mediumDateFormatter(created_at, 'N/A'),
    },
    {
      dataField: 'price_per_unit',
      text: `Price per ${usageBasedUnitName}`,
      formatter: () => {
        const price = formatPrice({
          value: usageBasedPricePerUnit,
          currencyCode,
        });

        return (
          <>
            <Text className={classes.priceValue} span>
              {price} /{' '}
            </Text>
            <Text className={classes.unitName} span>
              {usageBasedUnitName}
            </Text>
          </>
        );
      },
    },
    {
      dataField: 'meter_delta',
      text: usageBasedDisplayName,
      minWidth: 200,
      formatter: (_, { usage_based }) => {
        if (!usage_based) return 'N/A';

        const { meter_start, meter_end } = usage_based;

        if (meter_start === null || meter_end === null) return 'N/A';

        const price = formatNumber(meter_end - meter_start);

        return (
          <>
            <Text className={classes.priceValue} span>
              {price}
            </Text>{' '}
            <Text className={classes.unitName} span>
              {usageBasedUnitName}
            </Text>
          </>
        );
      },
    },
    {
      dataField: 'usage_based_charge',
      text: 'Usage charge',
      minWidth: 160,
      maxWidth: 160,
      formatter: (_, { usage_based }) => {
        const price = formatPrice({
          value: usage_based?.usage_charge_in_scu,
          currencyCode,
        });

        return <Text className={classes.priceValue}>{price}</Text>;
      },
    },
    {
      dataField: 'base_price_in_scu',
      text: 'Base price',
      formatter: (_, { usage_based }) => {
        const price = formatPrice({
          value: usage_based?.base_charge_in_scu,
          currencyCode: currencyCode,
        });

        return <Text className={classes.priceValue}>{price}</Text>;
      },
    },
    {
      dataField: 'price_in_scu',
      text: 'Total paid',
      formatter: (_, { price_in_scu }) => {
        const price = formatPrice({
          value: price_in_scu,
          currencyCode: currencyCode,
        });

        return <Text className={classes.priceValue}>{price}</Text>;
      },
    },
    {
      dataField: 'status',
      text: 'Payment status',
      minWidth: 200,
      formatter: (_, { status }) => {
        return tenantType === TenantType.Partner ? (
          <PartnerInvoicePaymentStatusBadge status={status} />
        ) : (
          <OrganizationInvoicePaymentStatusBadge status={status} />
        );
      },
    },
  ];

  return (
    <AnimatePresence initial={false}>
      {isOpen && (
        <motion.div
          initial={{ x: '100%' }}
          animate={{ x: 0 }}
          exit={{ x: '100%' }}
          transition={{ type: 'linear' }}
          className={classes.paymentHistoryPanel}
          style={wrapperStyles}
        >
          <Stack p="xxl" spacing="xxl" h="100%">
            <LoadingOverlay visible={isFetching} />

            <Group position="apart">
              <Text size="lg">Payments History</Text>

              <ActionIcon onClick={onClose}>
                <CollapseRightIcon />
              </ActionIcon>
            </Group>

            <SmartTable<SubscriptionInvoiceItemType>
              noHeader
              name="subscription.invoice_items"
              keyField="id"
              columns={columns}
              data={invoiceItems}
            />
          </Stack>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

const useStyles = createStyles((theme) => ({
  paymentHistoryPanel: {
    position: 'absolute',
    top: 0,
    width: 1000,
    height: '100%',
    backgroundColor: theme.white,
    boxShadow: '6px 0px 41px 0px rgba(0, 0, 0, 0.13)',
    clipPath: 'inset(0 0 0 -41px)',
  },
  priceValue: {
    color: theme.colors.gray[9],
    fontWeight: 600,
  },
  unitName: {
    color: theme.colors.gray[6],
  },
}));
