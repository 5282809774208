import { truncate } from 'lodash/fp';
import React, { useState } from 'react';
import { Button } from 'reactstrap';

const ShortenedString = ({ str, size = 100 }) => {
  const [open, setOpen] = useState(false);
  const showButton = (
    <Button
      className="link ml-10 pl-3 text-primary float-right"
      onClick={() => setOpen(!open)}
    >
      ({open ? 'hide' : 'show'})
    </Button>
  );

  return (
    <span style={{ wordBreak: 'break-all' }}>
      {open ? str : truncate({ length: size }, str)}
      {str.length > size ? showButton : null}
    </span>
  );
};

export default ShortenedString;
