import { startsWith } from 'lodash/fp';
import { Location } from 'react-router-dom';

import { RouteOverlayLocationState } from './route-overlays.types';

/*
 * There's a few possible scenarios of where the app should navigate, when a route-overlay is closed:
 *
 * 1. Route-overlay was opened from within the app, and there's a stored `backgroundPath` in
 *  location.state, with the page from which the overlay was opened. On close, the page will be
 *  displayed
 * - ['/overview/:space_id/devices' -> '/m/device/:id' -> CLOSE -> '/overview/:space_id/devices']
 *
 * 2. A route-overlay was opened from within the app, and then another modal/panel was opened:
 * - ['/overview/:space_id/devices' -> '/m/device/:id_1' -> '/m/device/:id_2' -> CLOSE ->
 *  '/overview/:space_id/devices']
 * - In this scenario, we need to store in location.state.backgroundPath the path to the original
 *  page from which the modal/panel was opened, and not the path of '/m/device/:id_1'
 * - This is why we check 'isCurrentPathRouteModal' in 'getBackgroundPath'
 *
 * 3. Route-overlay was opened in a new tab as main route, an upon closing, it will navigate to the
 *  'backgroundPath' assigned in its route config
 * - ['/m/devices/:id' -> CLOSE -> '/devices']
 *
 * 4. A route-overlay was opened in a new tab as main route, and from it another modal was opened:
 * - ['/m/device/:id_1' -> '/m/device/:id_2' -> CLOSE -> '/devices']
 * */
export function getBackgroundPath(
  location: Location<RouteOverlayLocationState>,
  defaultBackgroundPath?: string
) {
  const isCurrentPathRouteOverlay =
    startsWith('/m/', location?.pathname) ||
    startsWith('/p/', location?.pathname);

  if (!isCurrentPathRouteOverlay) {
    // Example: ['/overview/:space_id/devices' -> SELECT A DEVICE -> '/m/device/:id']
    // We store '/overview/:space_id/devices' location as backgroundPath, which will be rendered under the
    // route-overlay, and displayed again when the route-overlay is closed
    return location;
  }

  const currentBackgroundPath = (location?.state as RouteOverlayLocationState)
    ?.routeOverlay?.backgroundPath;

  if (currentBackgroundPath) {
    // Example:
    // 1. Navigate to '/devices'
    // 2. Select a device -> '/devices' is stored in `location.state.backgroundPath`
    // 3. Navigate to '/m/device/:id_1'
    // 4. Click on a link to a parent device
    // 5. 'isCurrentPathRouteModal' is true, since we're currently displaying '/m/device/:id_1'
    // 6. We store '/devices' in location.state.backgroundPath (instead of '/m/device/:id_1')
    // 7. Navigate to '/m/device/:id_2' (parent device)
    // 8. Close modal -> we display '/devices'
    return currentBackgroundPath;
  }

  // Example:
  // 1. User navigates directly to '/m/device/:id_1'
  // 2. Click on a link to a parent device
  // 3. 'isCurrentPathRouteModal' is true, since we're currently displaying '/m/device/:id_1'
  // 4. 'currentBackgroundPath' is not defined, since the route-modal wasn't opened from another
  // route
  // 5. Click on a link to a parent device
  // 6. Navigate to '/m/device/:id_2' (parent device)
  // 7. Close modal -> we display the route assigned as 'backgroundPath' in routes.tsx for
  // '/m/device'
  return defaultBackgroundPath || '/';
}
