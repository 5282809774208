import { LoadingOverlay } from '@mantine/core';
import React from 'react';

import {
  ActiveCommandType,
  DeviceDetailsType,
  useActiveDeviceCommands,
} from '@portals/api/organizations';
import { BadgeCell, SmartTable } from '@portals/table';
import {
  CommandStatusType,
  PaginatedFilterTypeEnum,
  TableColumn,
} from '@portals/types';
import { getCommandStatusInfo, prettyTime } from '@portals/utils';

import { CommandDetailsPanel } from './CommandDetailsPanel';

interface AllCommandsProps {
  device: DeviceDetailsType;
}

const COLUMNS: TableColumn<ActiveCommandType>[] = [
  {
    dataField: 'order',
    text: '# In Queue',
    sort: true,
    minWidth: 180,
  },
  {
    dataField: 'friendly_name',
    text: 'Name',
    sort: true,
    minWidth: 300,
    filter: {
      type: PaginatedFilterTypeEnum.Text,
    },
  },
  {
    dataField: 'status',
    text: 'Status',
    sort: true,
    filter: {
      type: PaginatedFilterTypeEnum.Select,
      options: {
        [CommandStatusType.Pending]: getCommandStatusInfo(
          CommandStatusType.Pending
        ).label,
        [CommandStatusType.InProgress]: getCommandStatusInfo(
          CommandStatusType.InProgress
        ).label,
      },
    },
    formatter: (_, { status }) => {
      const { color, label } = getCommandStatusInfo(status);
      return <BadgeCell color={color} label={label} />;
    },
  },
  {
    dataField: 'created_at',
    text: 'Created',
    sort: true,
    filter: {
      type: PaginatedFilterTypeEnum.Date,
    },
    formatter: (_, { created_at }) => prettyTime(created_at),
  },
  {
    dataField: 'message',
    text: 'Message',
    sort: true,
    filter: {
      type: PaginatedFilterTypeEnum.Text,
    },
    minWidth: 400,
  },
];

export function ActiveAndPendingCommands({ device }: AllCommandsProps) {
  const commands = useActiveDeviceCommands(device.id);

  if (!commands.isFetched) return <LoadingOverlay visible />;

  return (
    <SmartTable<ActiveCommandType>
      name="organizations.overview.device.commands"
      keyField="id"
      columns={COLUMNS}
      noHeader
      data={commands.data}
      isUrlSyncEnabled={false}
      defaultSorted={[{ id: 'order', desc: false }]}
      noDataIndication={{ title: 'No commands' }}
      detailsPanel={{
        type: 'inner',
        renderer: ({ row, onClose }) => (
          <CommandDetailsPanel
            command={row.original}
            device={device}
            onClose={onClose}
          />
        ),
      }}
    />
  );
}
