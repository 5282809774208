import {
  ActionIcon,
  Box,
  createStyles,
  Group,
  NumberInput,
  NumberInputProps,
  TextInput,
  TextInputProps,
  Tooltip,
} from '@mantine/core';
import React, { FC, useRef, useState } from 'react';
import { usePrevious, useUpdateEffect } from 'react-use';

import { ReactComponent as Edit3 } from '@portals/icons/linear/edit-3.svg';
import { useOnClickOutside } from '@portals/utils';

interface EditableCellProps<Value = string> {
  value: Value;
  formattedValue?: string;
  onChange: (value: Value) => void;
}

export const EditableTextCell: FC<EditableCellProps & TextInputProps> = ({
  value,
  formattedValue,
  onChange,
  ...inputProps
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const containerRef = useRef(null);
  const inputRef = useRef(null);
  const prevIsEdit = usePrevious(isEdit);

  useOnClickOutside(containerRef, () => setIsEdit(false), isEdit);

  useUpdateEffect(() => {
    setInputValue(value);
  }, [value]);

  useUpdateEffect(() => {
    if (isEdit && !prevIsEdit && inputValue !== value) {
      setInputValue(value);
    } else if (!isEdit && prevIsEdit && inputValue !== value) {
      onChange(inputValue);
    }
  }, [prevIsEdit, isEdit, value, onChange, inputValue]);

  const onKeyDown: TextInputProps['onKeyDown'] = (event) => {
    if (event.key === 'Enter' || event.key === 'Escape') {
      setIsEdit(false);
    }
  };

  return (
    <Group ref={containerRef} sx={{ width: '100%' }} position="apart">
      {isEdit ? (
        <TextInput
          data-testid="table-edit-cell-input"
          autoFocus={true}
          ref={inputRef}
          value={inputValue}
          onChange={(event) => setInputValue(event.target.value)}
          radius={0}
          onKeyDown={onKeyDown}
          onBlur={() => setIsEdit(false)}
          styles={{
            root: {
              width: '100%',
              height: '100%',
            },
            wrapper: {
              height: '100%',
            },
            input: {
              width: '100%',
              height: '100%',
            },
          }}
          {...inputProps}
        />
      ) : (
        <>
          <span>{inputValue !== value ? inputValue : value}</span>

          <Tooltip label="Update name">
            <ActionIcon
              size="xs"
              variant="subtle"
              onClick={() => setIsEdit(true)}
              data-testid="table-edit-cell-button"
            >
              <Edit3 />
            </ActionIcon>
          </Tooltip>
        </>
      )}
    </Group>
  );
};

export const EditableNumberCell: FC<
  EditableCellProps<number> & NumberInputProps
> = ({ value, formattedValue, onChange, ...inputProps }) => {
  const [isEdit, setIsEdit] = useState(false);
  const { cx, classes } = useStyles();
  const containerRef = useRef(null);
  const inputRef = useRef(null);

  useOnClickOutside(containerRef, () => setIsEdit(false), isEdit);

  useUpdateEffect(() => {
    if (isEdit && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [isEdit]);

  return (
    <Box
      className={cx(classes.numberContainer, { edit: isEdit })}
      onClick={() => setIsEdit(true)}
      ref={containerRef}
    >
      {isEdit ? (
        <NumberInput
          ref={inputRef}
          radius={0}
          value={value || undefined}
          hideControls
          onChange={onChange}
          onBlur={() => {
            setIsEdit(false);
          }}
          styles={{
            root: {
              width: '100%',
              height: '100%',
            },
            wrapper: {
              height: '100%',
            },
            input: {
              width: '100%',
              height: '100%',
            },
          }}
          {...inputProps}
        />
      ) : (
        formattedValue || value
      )}
    </Box>
  );
};

const useStyles = createStyles((theme) => ({
  numberContainer: {
    height: '100%',
    width: '100%',
    border: '1px solid transparent',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    padding: `0 ${theme.spacing.md}`,

    '&:not(.edit)': {
      '&:hover': {
        borderColor: theme.colors.gray[5],
      },
    },

    '&.edit': {
      padding: 0,
    },
  },
}));
