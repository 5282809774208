import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { openModal as openModalAction } from '../actions/modal';
import { getIsModalOpen } from '../selectors/modals';

export function useOpenModal() {
  const dispatch = useDispatch();

  const openModal = <TModalData = any>(name: string, params?: TModalData) => {
    dispatch(openModalAction<TModalData>(name, params));
  };

  return useCallback(openModal, [dispatch]);
}

export function useIsModalOpen() {
  return useSelector(getIsModalOpen);
}
