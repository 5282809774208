import React from 'react';
import { useDispatch } from 'react-redux';

import { StorePolicyIcon } from '@portals/assets';
import { useOpenModal } from '@portals/redux';
import { closeModal } from '@portals/redux/actions/modal';

import { useAsyncConfirmation } from './async-confirmation';
import { ConfirmationModalProps, TextEditorModalProps } from '../modals';

export const useConfirmationModal = () => {
  const dispatch = useDispatch();
  const openModal = useOpenModal();

  const { asyncConfirmationCheck, onResolve, toggleOpen } =
    useAsyncConfirmation({
      shouldConfirm: true,
      onOpen: ({
        description,
        title,
        confirmationLabel,
        cancelLabel,
        confirmButtonProps,
        cancelButtonProps,
      }) => {
        openModal<ConfirmationModalProps['data']>('ConfirmationModal', {
          onApprove: () => {
            onResolve(true);
            dispatch(closeModal('ConfirmationModal'));
            toggleOpen();
          },
          onCancel: () => {
            onResolve(false);
            dispatch(closeModal('ConfirmationModal'));
            toggleOpen();
          },
          description,
          title,
          confirmationLabel,
          cancelLabel,
          confirmButtonProps,
          cancelButtonProps,
        });
      },
    });

  return asyncConfirmationCheck;
};

export type PolicyKey =
  | 'privacy_policy'
  | 'terms_of_service'
  | 'cancellations_and_returns_policy';

type OpenPolicyModalParams = { policyKey: PolicyKey } & Pick<
  TextEditorModalProps['data'],
  'editorContent' | 'onSubmit' | 'isEditable'
>;

export function useOpenPolicyModal() {
  const openModal = useOpenModal();

  function openPolicyModal(params: OpenPolicyModalParams) {
    const { policyKey, editorContent, isEditable, onSubmit } = params;

    let title: string;

    switch (policyKey) {
      case 'privacy_policy':
        title = 'Privacy Policy';
        break;
      case 'terms_of_service':
        title = 'Terms of Service';
        break;
      case 'cancellations_and_returns_policy':
        title = 'Cancellations and Returns Policy';
        break;
    }

    openModal<TextEditorModalProps['data']>('TextEditorModal', {
      title,
      editorContent,
      isEditable,
      onSubmit,
      headerIcon: <StorePolicyIcon />,
    });
  }

  return openPolicyModal;
}
