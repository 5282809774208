declare global {
  interface Window {
    pendo: any;
  }
}

interface PendoVisitor {
  visitor: {
    id: string;
    firstName: string;
  };
  account: {
    id: string;
    partner_tenant?: string;
    support_email?: string;
    customer_tenant_type?: string;
    pricing_plan?: string;
    created_at?: string;
  };
}

const DEBUG_MODE = false;

function isProductionOrStaging() {
  return DEBUG_MODE || ['production', 'staging'].includes(process.env.NX_ENV);
}

export const Pendo = {
  isReady: () => {
    if (isProductionOrStaging()) {
      return window.pendo?.isReady?.();
    }
  },

  isAnonymousVisitor: () => {
    if (isProductionOrStaging()) {
      return window.pendo?.isAnonymousVisitor?.();
    }
  },

  initializeAnonymous: () => {
    if (isProductionOrStaging()) {
      window.pendo?.initialize?.({
        visitor: {
          id: 'VISITOR-UNIQUE-ID',
        },
      });

      if (DEBUG_MODE) {
        console.log('Pendo | initializeAnonymous()', {
          visitor: {
            id: 'VISITOR-UNIQUE-ID',
          },
        });
      }
    }
  },

  initializeAuthenticated: ({ visitor, account }: PendoVisitor) => {
    if (isProductionOrStaging()) {
      window.pendo?.initialize?.({
        visitor,
        account,
      });

      if (DEBUG_MODE) {
        console.log('Pendo | initializeAuthenticated()', {
          visitor,
          account,
        });
      }
    }
  },

  identify: ({ visitor, account }: PendoVisitor) => {
    if (isProductionOrStaging()) {
      window.pendo?.identify?.({
        visitor,
        account,
      });

      if (DEBUG_MODE) {
        console.log('Pendo | identify()', {
          visitor,
          account,
        });
      }
    }
  },

  trackPageView: (page: string, user?: string) => {
    if (page.includes('/auth/sign-in')) return;

    if (isProductionOrStaging()) {
      window.pendo?.track?.(page, {
        user: user,
      });

      if (DEBUG_MODE) {
        console.log('Pendo | trackPageView()', {
          page,
          user,
        });
      }
    }
  },

  track: (eventName: string, trackingInformation: Record<string, unknown>) => {
    if (isProductionOrStaging()) {
      window.pendo?.track?.(eventName, trackingInformation);

      if (DEBUG_MODE) {
        console.log('Pendo | track()', {
          eventName,
          trackingInformation,
        });
      }
    }
  },
};
