import { useCurrentUser } from '@portals/api/ui';

export function useHasSupportSeat() {
  const currentUser = useCurrentUser();

  if (!currentUser.isFetched || !currentUser.data) {
    return false;
  }

  const { super_admin, has_support_seat } = currentUser.data;

  return super_admin || has_support_seat;
}
