import {
  Box,
  Button,
  Center,
  createStyles,
  Group,
  Stack,
  Text,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React from 'react';

import {
  IncidentPriorityEnum,
  SpaceIncidentRateFormType,
  SpaceIncidentViewEnum,
  useConfig,
} from '@portals/api/organizations';
import { useConfirmationModal } from '@portals/framework';

import { SpaceIncidentRateForm } from './SpaceIncidentRateForm';
import { SpaceIncidentRateWidget } from './SpaceIncidentRateWidget';
import { WidgetPreviewComponentProps } from '../../../../../modals/WidgetPreview/widget-preview.types';
import { DataLevelEnum } from '../../../overview.types';

export function SpaceIncidentRateWidgetWrapper({
  onClose,
  space,
  onSave,
  type,
  dataLevel,
  widgetId,
}: WidgetPreviewComponentProps & { onClose: () => void }) {
  const { classes } = useStyles();

  const config = useConfig();

  const spaceIncidentRateWidgetExtraParams =
    config.data?.space_dashboard.list.find(
      (item) => item.id === 'space_incidents_rate' && item.i === widgetId
    )?.extraParams as SpaceIncidentRateFormType;

  const getInitialValues = () => {
    const initialValues: SpaceIncidentRateFormType = {
      title: 'Space incident rate',
      spaceIncidentView: SpaceIncidentViewEnum.SpacesWithIncidents,
      incidentPriority: [
        IncidentPriorityEnum.Critical,
        IncidentPriorityEnum.High,
        IncidentPriorityEnum.Moderate,
        IncidentPriorityEnum.Low,
        IncidentPriorityEnum.Planning,
      ],
    };

    if (spaceIncidentRateWidgetExtraParams?.title) {
      initialValues.title = spaceIncidentRateWidgetExtraParams.title;
    }

    if (spaceIncidentRateWidgetExtraParams?.spaceIncidentView) {
      initialValues.spaceIncidentView =
        spaceIncidentRateWidgetExtraParams.spaceIncidentView;
    }

    if (spaceIncidentRateWidgetExtraParams?.incidentPriority) {
      initialValues.incidentPriority =
        spaceIncidentRateWidgetExtraParams.incidentPriority;
    }

    return initialValues;
  };

  const form = useForm<SpaceIncidentRateFormType>({
    initialValues: getInitialValues(),
  });

  const asyncConfirmationCheck = useConfirmationModal();

  const onCloseWrapper = async () => {
    if (!form.isDirty()) {
      onClose();
      return;
    }

    const isConfirmed = await asyncConfirmationCheck({
      title: 'Are you sure?',
      description: 'All your changes will be lost',
      confirmationLabel: 'Confirm',
      cancelLabel: 'Cancel',
    });

    if (isConfirmed) {
      onClose();
    }
  };

  const onSubmit = async ({
    incidentPriority,
    spaceIncidentView,
    title,
  }: typeof form.values) => {
    onSave?.({
      incidentPriority,
      spaceIncidentView,
      title,
    });

    onClose();
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Box className={classes.container}>
        <Stack className={classes.preview} p="xxl" pb={0} spacing="xxl">
          <Text size={24} color="blue_gray.9">
            Space incident rate
          </Text>

          <SpaceIncidentRateForm form={form} />
        </Stack>

        <Center className={classes.config}>
          <SpaceIncidentRateWidget
            title={
              form.values.title ? form.values.title : 'Spaces with incidents'
            }
            incidentsPriorities={form.values.incidentPriority}
            spaceId={space?.id}
            parentSpaceId={space?.parent_id}
            spaceIncidentView={form.values.spaceIncidentView}
            dataLevel={dataLevel ?? DataLevelEnum.All}
          />
        </Center>

        <Group className={classes.footer} p="xl" position="right">
          <Button
            variant="default"
            data-testid="space-rate-modal-close-button"
            onClick={onCloseWrapper}
          >
            Close
          </Button>

          <Button
            type="submit"
            data-testid="modal-add-widget-button"
            disabled={form.values.incidentPriority.length === 0}
          >
            {type === 'edit' ? 'Save' : 'Add'}
          </Button>
        </Group>
      </Box>
    </form>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr min-content',
    gridTemplateAreas: `
      "preview config"
      "footer footer"
    `,
    gap: 0,
    height: 790,
  },

  preview: {
    gridArea: 'preview',
  },

  config: {
    gridArea: 'config',
    backgroundColor: theme.colors.gray[0],
  },

  footer: {
    gridArea: 'footer',
    borderTop: `1px solid ${theme.colors.gray[2]}`,
  },
}));
