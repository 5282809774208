import { StackProps } from '@mantine/core';
import GoogleMapReact, { Coords } from 'google-map-react';
import { find, findIndex, groupBy, keys, reduce } from 'lodash/fp';
import React, { useMemo } from 'react';

import {
  SpaceType,
  useDevice,
  useSpace,
  useSpaces,
} from '@portals/api/organizations';
import {
  DeviceLocationType,
  DeviceLocationWidgetFormType,
  DeviceMapConfig,
  MobileDeviceLocationWidget,
  NewWidgetType,
} from '@portals/device-widgets';
import { jitterPosition, useGetLocation } from '@portals/utils';

import { ChildDeviceMarker } from './map-markers/ChildDeviceMapMarker';
import { DeviceMarker } from './map-markers/DeviceMapMarker';
import {
  childrenToPoints,
  getDeviceCoordinates,
} from '../../../../../../../../desktop/route-modals/Device/tabs/overview/device-widgets/widgets/device-location-widget/DeviceLocationWidgetWrapper';
import { useDeviceOverviewContext } from '../DeviceOverviewContext';

function getCoordinatesKey(coordinates: Coords) {
  return `${coordinates.lat}-${coordinates.lng}`;
}

interface DeviceLocationWidgetWrapperProps {
  widget: NewWidgetType<DeviceLocationWidgetFormType>;
  deviceId: string;

  stackProps?: StackProps;
  onMapClick?: React.ComponentProps<typeof GoogleMapReact>['onClick'];
  mapActionsButtons?: React.ReactNode;
}

export function DeviceLocationWidgetWrapper({
  widget,
  deviceId,

  stackProps = {},
  onMapClick,
  mapActionsButtons,
}: DeviceLocationWidgetWrapperProps) {
  const { selectedDeviceId, isFullscreen, setIsFullscreen } =
    useDeviceOverviewContext();

  const fields = widget?.config?.fields;

  const { color } = fields ?? {};

  const device = useDevice(deviceId);
  const space = useSpace({ spaceId: device.data?.space_id });
  const spaces = useSpaces();

  const baseLocation = useGetLocation(space);

  const mainDeviceCoordinates = getDeviceCoordinates(device.data?.state, space);

  const devicesMapConfig = useMemo<DeviceMapConfig[]>(() => {
    if (!device.data) return [];

    const currentDevice = {
      type: DeviceLocationType.Main,
      id: device.data?.id,
      coordinates: mainDeviceCoordinates,
      name: device.data?.name,
      status: device.data?.state?.status,
      renderSinglePoint: () => (
        <DeviceMarker
          deviceId={device.data.id}
          key={device.data.id}
          color={color}
          status={device.data.state?.status}
          lat={mainDeviceCoordinates.lat}
          lng={mainDeviceCoordinates.lng}
        />
      ),
    };

    // Device has no children, render only the main device marker on map
    if (!device.data.child_devices_count) {
      return [currentDevice];
    }

    const allDevicesCoordinates = [
      // Main device
      {
        id: device.data.id,
        coordinates: mainDeviceCoordinates,
      },

      // Child devices
      ...device.data.child_devices.map((childDevice) => {
        const childSpaceData = find(
          { id: childDevice.space_id },
          spaces.data
        ) as SpaceType;

        return {
          id: childDevice.id,
          coordinates: getDeviceCoordinates(childDevice.state, childSpaceData),
        };
      }),
    ];

    // When number of devices are at exact same coordinates, we need to jitter them
    // The jittering logic depends on number of devices on given coordinate
    const groupedByCoordinates = groupBy(
      ({ coordinates }) => getCoordinatesKey(coordinates),
      allDevicesCoordinates
    );

    // Here we calculate how many devices are at each coordinate:
    // Input: { [coordA]: [device a, device b], [coordB]: [device c, device d] }
    // Output: { [coordA]: 2, [coordB]: 2 }
    const numOfDevicesPerCoord = reduce(
      (acc, coordinate) => ({
        ...acc,
        [coordinate]: groupedByCoordinates[coordinate].length,
      }),
      {},
      keys(groupedByCoordinates)
    );

    const childDevices: DeviceMapConfig[] = device.data?.child_devices.map(
      (childDevice, index) => {
        const childSpaceData = find(
          { id: childDevice.space_id },
          spaces.data
        ) as SpaceType;

        let coordinates = getDeviceCoordinates(
          childDevice?.state,
          childSpaceData
        );

        // If there are multiple devices at the same coordinates, we need to jitter them
        if (numOfDevicesPerCoord[getCoordinatesKey(coordinates)] > 1) {
          const deviceIndexInCoordGroup = findIndex(
            { id: childDevice.id },
            groupedByCoordinates[getCoordinatesKey(coordinates)]
          );

          coordinates = jitterPosition(
            coordinates,
            deviceIndexInCoordGroup,
            numOfDevicesPerCoord[getCoordinatesKey(coordinates)]
          );
        }

        return {
          type: DeviceLocationType.Child,
          id: childDevice.id,
          coordinates,
          name: childDevice?.name,
          status: childDevice?.state?.status,
          renderSinglePoint: () => (
            <ChildDeviceMarker
              key={childDevice.id}
              deviceId={childDevice.id}
              status={childDevice?.state?.status}
              lat={coordinates.lat}
              lng={coordinates.lng}
            />
          ),
        };
      }
    );

    return [currentDevice, ...childDevices];
  }, [color, device.data, mainDeviceCoordinates, spaces.data]);

  const points = useMemo(
    () => childrenToPoints(devicesMapConfig),
    [devicesMapConfig]
  );

  return (
    <MobileDeviceLocationWidget
      selectedChildDeviceId={selectedDeviceId}
      points={points}
      base={baseLocation}
      stackProps={stackProps}
      onMapClick={onMapClick}
      mapActionsButtons={mapActionsButtons}
      isFullscreen={isFullscreen}
      setIsFullscreen={setIsFullscreen}
      mapOptions={{
        disableDefaultUI: true,
        keyboardShortcuts: false,
        gestureHandling: 'greedy',
      }}
    />
  );
}
