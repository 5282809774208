import { Box, createStyles, Group, Input, Text, Tooltip } from '@mantine/core';
import React from 'react';

import { ComponentRendererType } from '@portals/types';

type SelectItemComponentProps = {
  label: string;
  disabled: boolean;
  disabledLabel: string;
  Icon: ComponentRendererType;
  className: string;
} & Record<string, any>;

export function SelectItemComponent({
  label,
  disabled,
  disabledLabel,
  Icon,
  className,
  ...rest
}: SelectItemComponentProps) {
  const { cx, classes } = useStyles(disabled);

  return (
    <Box {...rest} className={cx(classes.container, className)} p="xs">
      <Text truncate>{label}</Text>

      {disabled ? (
        <Tooltip withArrow withinPortal label={disabledLabel} p="md">
          <Text color="amber.4">
            <Icon width={16} height={16} />
          </Text>
        </Tooltip>
      ) : null}
    </Box>
  );
}

interface InputLabelWithTooltipProps {
  label: string;
  Icon: ComponentRendererType;
  tooltipLabel: string;
}

export function InputLabelWithTooltip({
  label,
  Icon,
  tooltipLabel,
}: InputLabelWithTooltipProps) {
  return (
    <Group align="center" spacing="xs">
      <Input.Label required p={0} m={0}>
        {label}
      </Input.Label>

      <Tooltip withArrow withinPortal label={tooltipLabel} p="md">
        <Text color="gray.4">
          <Icon width={16} height={16} />
        </Text>
      </Tooltip>
    </Group>
  );
}

const useStyles = createStyles((theme, disabled: boolean) => ({
  container: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: disabled ? '1fr min-content' : '1fr',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));
