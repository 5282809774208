import {
  IntegrationCategoryEnum,
  IntegrationCategoryType,
} from './integrations.types';

export const CATEGORIES: Array<IntegrationCategoryType> = [
  {
    id: IntegrationCategoryEnum.All,
    label: 'All Integrations',
  },
  {
    id: IntegrationCategoryEnum.Ticketing,
    label: 'Ticketing',
  },
  {
    id: IntegrationCategoryEnum.Messaging,
    label: 'Messaging',
  },
  {
    id: IntegrationCategoryEnum.Enabled,
    label: 'Enabled',
  },
];
