import {
  Button,
  Center,
  createStyles,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import errorSrc from './assets/img/icons/404.svg';

export function RootErrorBoundary() {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('auth');
    navigate('/');
  };

  return (
    <div className={classes.container}>
      <Center>
        <Stack align="flex-start" spacing="xl">
          <Stack spacing="xs">
            <Title order={1} size={40} weight={600} color="dark">
              Oops...
            </Title>

            <Text color="dimmed" size="lg">
              We are sorry, but it seems that something went wrong
            </Text>
          </Stack>

          <Button
            size="xl"
            mt="lg"
            radius="md"
            color="dark"
            onClick={handleLogout}
          >
            Return to main page
          </Button>
        </Stack>
      </Center>

      <Center>
        <img src={errorSrc} alt="404" className={classes.image} />
      </Center>
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 50,
    width: '100%',
    height: '100%',
    padding: 50,
    justifyContent: 'center',
    backgroundColor: theme.colors.gray[2],
  },
  image: {
    maxWidth: '100%',
    maxHeight: 'calc(100vh - 300px)',
  },
}));
