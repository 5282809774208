import { Button, Stack, Text, useMantineTheme } from '@mantine/core';
import React from 'react';

import { ModalCenteredMediaLayout } from '@portals/core';
import { ModalProps } from '@portals/framework';
import { ReactComponent as TickCircle } from '@portals/icons/bulk/tick-circle.svg';
import { mediumDateFormatter } from '@portals/utils';

export interface SubscriptionCancellationSuccessModalProps
  extends ModalProps<{
    nextChargeAt: string;
    isCancelledImmediately: boolean;
    alert: React.ReactNode;
  }> {}

export function SubscriptionCancellationSuccessModal({
  closeMe,
  data: { nextChargeAt, isCancelledImmediately, alert },
}: SubscriptionCancellationSuccessModalProps) {
  const theme = useMantineTheme();

  let title;

  if (isCancelledImmediately) {
    title = <Text size={24}>Your subscription was canceled successfully</Text>;
  } else {
    title = (
      <Stack spacing="sm" data-testid="suscription-cancellation-success-stack">
        <Text size={24}>Your subscription will be canceled on</Text>
        <Text
          size="xl"
          weight={700}
          data-testid="subscription-cancellation-date"
        >
          {mediumDateFormatter(nextChargeAt)}
        </Text>
      </Stack>
    );
  }

  return (
    <ModalCenteredMediaLayout
      opened
      withCloseButton={false}
      onClose={closeMe}
      media={<TickCircle color={theme.colors.blue[2]} />}
      title={title}
      footer={
        <Button
          fullWidth
          onClick={closeMe}
          data-testid="subscription-cancellation-success-done-button"
        >
          Done
        </Button>
      }
    >
      <Stack spacing="xl">
        <Text color="gray.7" ta="center">
          You will receive an email confirming the cancellation.
        </Text>

        {alert}
      </Stack>
    </ModalCenteredMediaLayout>
  );
}
