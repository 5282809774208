import { globalQueryKeys } from '../global-query-keys';

export const CONNECTED_PARTNERS_API_URL = 'ui/partner/connected_partners';

export const connectedPartnersQueryKeys = {
  global: [...globalQueryKeys.partnersConnections, CONNECTED_PARTNERS_API_URL],
  all: () => [...connectedPartnersQueryKeys.global, 'connectedPartners'],
  canceled: () => [...connectedPartnersQueryKeys.all(), 'canceled'],
  active: () => [...connectedPartnersQueryKeys.all(), 'active'],
  relationshipType: () => [
    ...connectedPartnersQueryKeys.all(),
    'relationshipType',
  ],
};
