import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { map, uniq } from 'lodash/fp';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';
import { CommandType } from '@portals/types';

import { COMMANDS_API_URL } from './commands.constants';
import { SendCommandParams } from './commands.types';
import { MutationOptions, ServerError } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils';
import { devicesQueryKeys } from '../devices';

export function useSendCommand(
  mutationOptions: MutationOptions<
    Array<CommandType>,
    { error: string },
    SendCommandParams
  > = {}
) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: COMMANDS_API_URL,
    method: 'POST',
  });

  return useMutation({
    mutationFn: (commandParams): Promise<Array<CommandType>> =>
      fetchApiRequest(url, {
        ...options,
        body: JSON.stringify({
          ...commandParams,
          command: commandParams.command.name,
        }),
      }),
    onSuccess: (commands) => {
      const queriesToInvalidate = map(
        (command) => uniq(devicesQueryKeys.commands.all(command.device_id)),
        commands
      );

      queryClient.invalidateQueries(...queriesToInvalidate);

      dispatch(toastrSuccess('Command successfully sent'));
    },
    onError: ({ error }: any) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useSendCommand',
      baseUrl: COMMANDS_API_URL,
      method: 'POST',
    },
    ...mutationOptions,
  });
}

export interface OpenTunnelParams {
  expiration: number;
  connected: boolean;
  last_check_time: number;
  last_connected_time: number;
}

const MAX_POLLING_ITERATIONS = Infinity;

export function useOpenTunnel({
  tunnelStatusUrl,
  tunnelAuthenticateUrl,
  onOpenTunnel,
}: {
  tunnelStatusUrl: string;
  tunnelAuthenticateUrl: string;
  onOpenTunnel: (tunnelAuthenticateUrl: string) => void;
}) {
  const dispatch = useDispatch();
  const [pollingIteration, setPollingIteration] = useState(0);
  const [stopPolling, setStopPolling] = useState(false);

  const closeTunnel = () => {
    setStopPolling(true);
  };

  const isEnabled = pollingIteration < MAX_POLLING_ITERATIONS && !stopPolling;

  const query = useQuery<void, ServerError, OpenTunnelParams>({
    queryKey: [tunnelStatusUrl],
    queryFn: () => {
      setPollingIteration((prev) => prev + 1);

      return fetchApiRequest(tunnelStatusUrl);
    },
    refetchInterval: (data) => {
      if (data && data.connected && isEnabled) {
        dispatch(toastrSuccess('Tunnel successfully opened'));

        setStopPolling(true);

        onOpenTunnel(tunnelAuthenticateUrl);
        return false;
      }

      if (!isEnabled) {
        setStopPolling(true);
        return false;
      }

      return 1000;
    },
    enabled: isEnabled,
  });

  return {
    ...query,
    closeTunnel,
    pollingIteration,
  };
}
