import PropTypes from 'prop-types';
import React from 'react';

const ZoomConfig = ({ device }) => (
  <div>
    {device.parent && (
      <p>
        This is a child device and can only be configured through its parent
      </p>
    )}

    {!device.parent && (
      <>
        <p>This device can only be configured from the Zoom portal.</p>
        <a
          href={`https://zoom.us/location?resource_id=${device.details['id']}&resource_type=20`}
          rel="noopener noreferrer"
          target="_blank"
        >
          Access Zoom portal for this device
        </a>
      </>
    )}
  </div>
);

ZoomConfig.propTypes = {
  device: PropTypes.object.isRequired,
};

export default ZoomConfig;
