import { Text, ThemeIcon } from '@mantine/core';
import { NotificationProps, notifications } from '@mantine/notifications';
import React from 'react';

import { ReactComponent as InfoCircle } from '@portals/icons/bulk/info-circle-white.svg';
import { ReactComponent as TickCircle } from '@portals/icons/bulk/tick-circle-white.svg';

const createToastr = (
  type: 'success' | 'warning' | 'error',
  title: string,
  message?: string
) => {
  const withTitle = !!message;
  const iconColor = type === 'success' ? 'blue_accent.2' : 'red.4';
  const IconComponent = type === 'success' ? TickCircle : InfoCircle;

  notifications.show({
    id: withTitle ? title : message,
    title: withTitle ? (
      <Text data-testid={`toaster-${type}-title`}>{title}</Text>
    ) : undefined,
    message: withTitle ? (
      <Text data-testid={`toaster-${type}-message`}>{message}</Text>
    ) : (
      <Text data-testid={`toaster-${type}-title`}>{title}</Text>
    ),
    autoClose: 6000,
    icon: (
      <ThemeIcon color={iconColor} variant="outline" sx={{ border: 'none' }}>
        <IconComponent />
      </ThemeIcon>
    ),
    styles: notificationStyle(withTitle),
    bottom: 40,
  });

  return {
    type: `TEMP_NOTIFICATION_PLACEHOLDER_${type.toUpperCase()}`,
    payload: {
      title,
      message,
    },
  };
};

type BaseToastrType = (
  title: string,
  message?: string
) => ReturnType<typeof createToastr>;

export const toastrSuccess: BaseToastrType = (title, message) =>
  createToastr('success', title, message);
export const toastrWarning: BaseToastrType = (title, message) =>
  createToastr('warning', title, message);
export const toastrError: BaseToastrType = (title, message) =>
  createToastr('error', title, message);

function notificationStyle(withTitle: boolean): NotificationProps['styles'] {
  return function (theme) {
    return {
      root: {
        backgroundColor: theme.colors.gray[9],
        border: 'none',
        display: 'grid',
        gridTemplateColumns: 'min-content 1fr min-content',
        padding: `${theme.spacing.md} !important`,
        width: 'auto',
        alignItems: 'center',
        transitionDuration: '350ms !important',
        transitionTimingFunction: 'ease-in-out !important',
      },
      body: {
        borderRadius: theme.radius.xxl,
      },
      title: withTitle
        ? {
            color: theme.colors.gray[3],
            fontSize: theme.fontSizes.xs,
            fontWeight: 'normal',
            marginBottom: theme.spacing.xs,
          }
        : { display: 'none' },
      description: { color: theme.white, fontSize: theme.fontSizes.sm },
      icon: {
        background: 'none',
      },
      loader: {},
      closeButton: {
        color: theme.white,
        '&:hover': { backgroundColor: theme.colors.gray[8] },
      },
    };
  };
}
