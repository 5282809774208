import { createStyles, Divider, Group, Menu, Text } from '@mantine/core';
import React from 'react';

import { ReactComponent as Danger } from '@portals/icons/bold/danger.svg';

interface MapListItemProps {
  color: string;
  title: string;
  description: string;
  onClick: () => void;
}

export function MapListItem({
  title,
  description,
  color,
  onClick,
}: MapListItemProps) {
  const { classes, theme } = useStyles();

  return (
    <Menu.Item p={0} onClick={onClick}>
      <Group className={classes.container} noWrap spacing="xs">
        <Danger height={24} color={theme.colors[color][4]} />

        <div>
          <Text
            size="sm"
            color="gray.7"
            className={classes.label}
            title={title}
          >
            {title}
          </Text>

          <Text
            size="xs"
            color="gray.5"
            className={classes.label}
            title={description}
          >
            {description}
          </Text>
        </div>
      </Group>

      <Divider c="gray.2" />
    </Menu.Item>
  );
}

const useStyles = createStyles(() => ({
  container: {
    cursor: 'pointer',
    height: 52,
    maxHeight: 52,
  },
  label: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: 135,
  },
}));
