import {
  slackSrc,
  emailSrc,
  freshServiceSrc,
  gchatSrc,
  webexSrc,
  msTeamsLogo,
} from '@portals/assets';

import { ServiceSectionType } from './incident-routing.types';
import EditCiscoTeams from './integrations/EditCiscoTeams';
import EditEmail from './integrations/EditEmail';
import EditFreshService from './integrations/EditFreshService';
import EditGChat from './integrations/EditGChat';
import EditMsTeams from './integrations/EditMsTeams';
import EditSlack from './integrations/EditSlack';

export const INCIDENT_ROUTING_SCHEMA: Array<ServiceSectionType> = [
  {
    name: 'Incidents',
    description: 'Get notified about new incidents',
    id: 'incidents',
    children: [
      {
        id: 'email',
        name: 'Email',
        component: EditEmail,
        logoSrc: emailSrc,
      },
      {
        id: 'slack',
        name: 'Slack',
        component: EditSlack,
        logoSrc: slackSrc,
      },
      {
        id: 'gchat',
        name: 'Google Chat',
        component: EditGChat,
        logoSrc: gchatSrc,
      },
      {
        id: 'fresh_service',
        name: 'Freshservice',
        component: EditFreshService,
        logoSrc: freshServiceSrc,
      },
      {
        id: 'msteams',
        name: 'Microsoft Teams',
        component: EditMsTeams,
        logoSrc: msTeamsLogo,
      },
      {
        id: 'cisco_teams',
        name: 'Cisco Webex Teams',
        component: EditCiscoTeams,
        logoSrc: webexSrc,
      },
    ],
  },
];
