import { isEmpty } from 'lodash/fp';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'reactstrap';

import { InfoTable } from '@portals/table';
import { TelemetryType } from '@portals/types';
import { ButtonGroup } from '@portals/ui';
import { prettyTime } from '@portals/utils';

import LineChart from './LineChart';

const getLabels = (telemetries) => {
  return telemetries
    .map(({ device_timestamp }) => device_timestamp.split('T')[1].split('.')[0])
    .reverse();
};

const getMaxErr = (parsedTelemetries, channel) =>
  parsedTelemetries.map((telemetry) => telemetry[channel].max).reverse();

const getMseErr = (parsedTelemetries, channel) =>
  parsedTelemetries.map((telemetry) => telemetry[channel].mse).reverse();

const parseChannels = (telemetry: TelemetryType) => {
  const localKeys = Object.keys(telemetry.common).filter(
    (key) => key.includes('local') && key.includes('_ch_')
  );
  const remoteKeys = Object.keys(telemetry.common).filter(
    (key) => key.includes('remote') && key.includes('_ch_')
  );
  return {
    local: {
      mse: localKeys
        .filter((key) => key.includes('mse'))
        .map((key) => telemetry.common[key]),
      max: localKeys
        .filter((key) => key.includes('max'))
        .map((key) => telemetry.common[key]),
    },
    remote: {
      mse: remoteKeys
        .filter((key) => key.includes('mse'))
        .map((key) => telemetry.common[key]),
      max: remoteKeys
        .filter((key) => key.includes('max'))
        .map((key) => telemetry.common[key]),
    },
  };
};

const parseDate = (telemetry) => {
  if (!telemetry) {
    return 'Date NaN';
  }

  return prettyTime(telemetry.device_timestamp).split(',')[0];
};

const MAX_TELEMETRIES = 20;

const Charts = ({
  telemetries,
  getTelemetries,
  device,
  channel,
  setChannel,
}) => {
  const [live, setLive] = useState(false);
  const parsedTelemetries = useMemo(
    () => telemetries.map(parseChannels),
    [telemetries]
  );

  const liveInterval = useRef(null);

  useEffect(
    function toggleInterval() {
      if (!live && liveInterval.current) {
        clearInterval(liveInterval.current);
        liveInterval.current = null;
      } else {
        liveInterval.current = setInterval(
          () => getTelemetries(device.id, MAX_TELEMETRIES),
          1000
        );
      }
    },
    [device.id, getTelemetries, live]
  );

  useEffect(() => {
    getTelemetries(device.id, MAX_TELEMETRIES);

    return () => {
      if (liveInterval.current) {
        clearInterval(liveInterval.current);
        setLive(null);
      }
    };
  }, [device.id, getTelemetries]);

  return (
    <>
      <InfoTable.Row
        isHeader
        label={
          <div className="d-flex flex-column align-items-center">
            <div className="mb-2">
              <b>{telemetries[0] ? parseDate(telemetries[0]) : ''}</b>
            </div>
            <div className="d-flex align-items-center">
              <Button
                onClick={() => setLive((curr) => !curr)}
                color={live ? 'secondary' : 'primary'}
                className="local_btn"
              >
                {live ? 'Stop' : 'Live'}
              </Button>

              <ButtonGroup
                height={40}
                className="ml-2"
                options={[
                  { value: 'local', label: 'Local' },
                  { value: 'remote', label: 'Remote' },
                ]}
                onChange={setChannel}
                value={channel}
              />
            </div>
          </div>
        }
        value={null}
      />

      <InfoTable.Row
        label="MAX ERR"
        value={
          !isEmpty(telemetries) ? (
            <LineChart
              labels={getLabels(telemetries)}
              data={getMaxErr(parsedTelemetries, channel)}
            />
          ) : (
            'No available data'
          )
        }
      />

      <InfoTable.Row
        label="MSE ERR"
        value={
          !isEmpty(telemetries) ? (
            <LineChart
              labels={getLabels(telemetries)}
              data={getMseErr(parsedTelemetries, channel)}
            />
          ) : (
            'No available data'
          )
        }
      />
    </>
  );
};

export default Charts;
