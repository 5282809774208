import {
  Alert,
  Button,
  createStyles,
  Group,
  LoadingOverlay,
  Stack,
  Text,
} from '@mantine/core';
import React from 'react';

import { DeviceDetailsType } from '@portals/api/organizations';
import { ReactComponent as InfoCircle } from '@portals/icons/linear/info-circle.svg';
import { DeviceType as CommonDeviceType } from '@portals/types';

import { useDeviceContext } from '../context';

interface ReplaceDeviceAlertProps<
  TDevice extends DeviceDetailsType | CommonDeviceType
> {
  device: TDevice;
}

export function ReplaceDeviceAlert<
  TDevice extends DeviceDetailsType | CommonDeviceType
>({ device }: ReplaceDeviceAlertProps<TDevice>) {
  const { classes } = useStyles();
  const { onCancelReplace, onApproveReplace } = useDeviceContext();

  return (
    <Alert bg="amber.0" p="xl" className={classes.container}>
      <Stack>
        <LoadingOverlay
          visible={onCancelReplace.isLoading || onApproveReplace.isLoading}
        />

        <Group align="center" spacing="xs" color="blue_gray.8">
          <InfoCircle width={18} height={18} />
          <Text size="md">Attention</Text>
        </Group>

        <Text size="sm" color="blue_gray.7">
          Another device is attempting to register with the same credentials as
          this device. If this device has been factory reset, please confirm and
          we will replace between them. Otherwise, please contact support
          immediately to prevent unauthorized access.
        </Text>

        <Group p={0} position="right">
          <Button
            variant="subtle"
            color="blue_gray.7"
            size="xs"
            onClick={() => onCancelReplace.mutate({ deviceId: device.id })}
          >
            Dismiss
          </Button>
          <Button
            color="orange"
            size="xs"
            onClick={() => onApproveReplace.mutate({ deviceId: device.id })}
          >
            Replace Device
          </Button>
        </Group>
      </Stack>
    </Alert>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    overflow: 'unset',
  },
}));
