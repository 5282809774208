import { createStyles, Modal } from '@mantine/core';
import { useInterval } from '@mantine/hooks';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  OrderType,
  PriceQuotePDFType,
  useGetPriceQuotePDF,
} from '@portals/api/organizations';
import { ModalProps } from '@portals/framework';
import { useOpenRouteModal } from '@portals/framework/route-modals';

import { clearCart } from '../../../../redux/actions/store';
import { ErrorState, LoadingState } from '../common';

interface PriceQuoteProps {
  order: OrderType;
}

const POLLING_INTERVAL_MS = 1000;

// The PriceQuoteLoader component is a modal that displays a loading spinner while it polls for the
// status of a price quote PDF generation process. If the process is successful, the modal closes
// and the user is redirected to the order page with the price quote modal open. If the process
// fails, an error message is displayed in the modal.
export const PriceQuoteLoader: FC<ModalProps<PriceQuoteProps>> = ({
  closeMe,
  data = {},
}) => {
  const { order } = data;
  const navigate = useNavigate();
  const openRouteModal = useOpenRouteModal();
  const getPriceQuotePDF = useGetPriceQuotePDF();
  const dispatch = useDispatch();

  const [priceQuotePDFMeta, setPriceQuotePDFMeta] =
    useState<PriceQuotePDFType | null>(null);
  const [isError, setIsError] = useState(false);

  const { classes } = useStyles({
    isError: priceQuotePDFMeta?.status === 'error',
  });

  // Using the useInterval hook to poll for the status of the price quote PDF generation process at a set interval
  const interval = useInterval(async () => {
    try {
      const priceQuotePDFMeta = await getPriceQuotePDF.mutateAsync(
        order.price_quote_id
      );

      // Updating the state with the status of the process if it is available
      if (priceQuotePDFMeta.status) {
        setPriceQuotePDFMeta(priceQuotePDFMeta);
      }
    } catch (error) {
      // Setting the error state if an error occurs while getting the status of the process
      setIsError(true);
    }
  }, POLLING_INTERVAL_MS);

  // The useEffect hook is used to perform side effects based on the state of the price quote PDF generation process.
  useEffect(() => {
    // If the process has failed or if an error has occurred...
    if (priceQuotePDFMeta?.status === 'error' || isError) {
      // Stop polling for the status of the process
      interval.stop();

      // If the process has completed successfully...
    } else if (priceQuotePDFMeta?.status === 'done') {
      interval.stop();
      closeMe();

      // Navigate to the order page and open the price quote modal
      openRouteModal({
        modalId: 'order',
        pathParams: [order.id, 'price_quote=true'],
        backgroundPath: '/products/orders',
      });

      // Clear the cart
      dispatch(clearCart());

      // If the process has not yet completed and no errors have occurred...
    } else if (!priceQuotePDFMeta) {
      // Start polling for the status of the process
      interval.start();
    }
  }, [
    closeMe,
    dispatch,
    navigate,
    interval,
    isError,
    openRouteModal,
    order.id,
    priceQuotePDFMeta,
  ]);

  // A function to handle closing the modal when an error occurs
  const onErrorClose = () => {
    closeMe();
    dispatch(clearCart());
    interval.stop();

    // Navigate to the order page and open the order modal
    navigate('/products/orders', { state: true });
    openRouteModal({ modalId: 'order', pathParams: [order.id] });
  };

  return (
    <Modal
      opened
      size="sm"
      closeOnEscape={false}
      closeOnClickOutside={false}
      withCloseButton={false}
      onClose={interval.stop}
      padding={0}
      classNames={{
        root: classes.root,
        content: classes.content,
        header: classes.header,
      }}
    >
      {isError || priceQuotePDFMeta?.status === 'error' ? (
        <ErrorState onClose={onErrorClose} />
      ) : (
        <LoadingState />
      )}
    </Modal>
  );
};

const useStyles = createStyles((theme, params: { isError: boolean }) => ({
  root: {
    overflow: 'hidden',
  },
  content: {
    minWidth: params.isError ? 565 : 477,
    minHeight: 330,
    overflow: 'hidden',
  },
  header: {
    display: 'none',
  },
}));
