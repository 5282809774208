import {
  PrismProps,
  PrismStylesNames,
  PrismStylesParams,
} from '@mantine/prism';

import { ThemeComponentOverrides } from '../common-types';

export const Prism: ThemeComponentOverrides<
  PrismProps,
  PrismStylesNames,
  PrismStylesParams
> = {
  styles: (theme) => ({
    root: {
      borderRadius: theme.radius.md,
      backgroundColor: theme.colors.gray[2],
    },
  }),
};
