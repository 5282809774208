import {
  ActionIcon,
  Button,
  createStyles,
  Group,
  Menu,
  MultiSelect,
  SegmentedControl,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { find, isEmpty } from 'lodash/fp';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { Filters } from 'react-table';
import { useUpdateEffect } from 'react-use';

import { ReactComponent as CloseX } from '@portals/icons/linear/close-x.svg';
import { ReactComponent as Filter } from '@portals/icons/linear/filter.svg';
import { DeviceType, TableState } from '@portals/types';

interface DevicesFilterProps {
  filters: TableState<DeviceType>['filters'];
  setFilters: Dispatch<SetStateAction<TableState<DeviceType>['filters']>>;

  sortBy: TableState<DeviceType>['sortBy'];
  setSortBy: Dispatch<SetStateAction<TableState<DeviceType>['sortBy']>>;
}

const STATUS_OPTIONS = [
  { value: 'online', label: 'Online' },
  { value: 'offline', label: 'Offline' },
  { value: 'error', label: 'Error' },
  { value: 'unavailable', label: 'Unavailable' },
];

export function DevicesFilter({
  filters,
  setFilters,
  sortBy,
  setSortBy,
}: DevicesFilterProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { classes } = useStyles();

  const form = useForm({
    initialValues: {
      name: find({ id: 'name' }, filters)?.value || '',
      device_model_name:
        find({ id: 'device_model_name' }, filters)?.value || '',
      status: find({ id: 'name' }, filters)?.value || [],

      sortBy: sortBy[0]?.id,
    },
  });

  useUpdateEffect(() => {
    if (isOpen) {
      form.setValues({
        name: find({ id: 'name' }, filters)?.value || '',
        device_model_name:
          find({ id: 'device_model_name' }, filters)?.value || '',
        status: find({ id: 'status' }, filters)?.value || [],

        sortBy: sortBy[0]?.id,
      });
    }
  }, [isOpen]);

  const getAssignedFilters = (): Filters<DeviceType> => {
    const assignedFilters = [];

    if (form.values.name) {
      assignedFilters.push({ id: 'name', value: form.values.name });
    }

    if (form.values.device_model_name) {
      assignedFilters.push({
        id: 'device_model_name',
        value: form.values.device_model_name,
      });
    }

    if (!isEmpty(form.values.status)) {
      assignedFilters.push({ id: 'status', value: form.values.status });
    }

    return assignedFilters;
  };

  const onSubmit = () => {
    const assignedFilters = getAssignedFilters();

    setFilters(assignedFilters);
    setSortBy([{ id: form.values.sortBy, desc: false }]);

    setIsOpen(false);
  };

  const onReset = () => {
    setFilters([]);
    setSortBy([{ id: 'name', desc: false }]);

    setIsOpen(false);
  };

  const getToggleColor = () => {
    if (isEmpty(filters)) return isOpen ? 'blue_accent.4' : 'gray.5';

    return 'blue_accent.4';
  };

  return (
    <Menu
      opened={isOpen}
      onClose={() => setIsOpen(false)}
      withArrow
      position="bottom"
      width={450}
      withinPortal
      classNames={{
        dropdown: classes.dropdown,
      }}
    >
      <Menu.Target>
        <ActionIcon color={getToggleColor()} onClick={() => setIsOpen(true)}>
          <Filter />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        <Stack p={32} spacing="xl">
          <Text size="md" color="blue_gray.9" weight={500}>
            Filter Devices
          </Text>

          <form onSubmit={form.onSubmit(onSubmit)}>
            <Stack spacing="xl" p={0}>
              <TextInput
                {...form.getInputProps('name')}
                label="Device Name"
                placeholder="Filter by device name"
                rightSection={
                  form.values.name ? (
                    <ActionIcon onClick={() => form.setFieldValue('name', '')}>
                      <CloseX width={13} height={13} />
                    </ActionIcon>
                  ) : null
                }
              />

              <TextInput
                {...form.getInputProps('device_model_name')}
                label="Model Name"
                placeholder="Filter by device model name"
                rightSection={
                  form.values.device_model_name ? (
                    <ActionIcon
                      onClick={() =>
                        form.setFieldValue('device_model_name', '')
                      }
                    >
                      <CloseX width={13} height={13} />
                    </ActionIcon>
                  ) : null
                }
              />

              <MultiSelect
                {...form.getInputProps('status')}
                label="Status"
                data={STATUS_OPTIONS}
                dropdownPosition="top"
                placeholder="Filter by status"
              />

              <SegmentedControl
                w="100%"
                {...form.getInputProps('sortBy')}
                data={[
                  { label: 'Name', value: 'name' },
                  { label: 'Model', value: 'device_model_name' },
                  { label: 'Status', value: 'status' },
                ]}
              />

              <Group position="right">
                <Button variant="default" onClick={onReset}>
                  Reset Filters
                </Button>

                <Button type="submit">Submit</Button>
              </Group>
            </Stack>
          </form>
        </Stack>
      </Menu.Dropdown>
    </Menu>
  );
}

const useStyles = createStyles((theme) => ({
  dropdown: {
    borderRadius: theme.radius.md,
    boxShadow: theme.shadows.md,
  },
  switchWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr min-content 1fr',
    gap: 36,
  },
}));
