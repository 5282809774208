import { ActionIcon, createStyles } from '@mantine/core';
import React from 'react';

import { useDeviceLocationMap } from '@portals/device-widgets';
import { ReactComponent as ArrowLeft } from '@portals/icons/linear/arrow-left.svg';
import { ReactComponent as Gps } from '@portals/icons/linear/gps.svg';

import { useDeviceOverviewContext } from '../DeviceOverviewContext';

export function DeviceLocationMapActions() {
  const { classes, theme } = useStyles();
  const { isFullscreen, setIsFullscreen, setSelectedDeviceId } =
    useDeviceOverviewContext();

  const { onCenterMap } = useDeviceLocationMap();

  if (!isFullscreen) return null;

  return (
    <>
      <ActionIcon
        color="gray.7"
        size={40}
        onClick={() => setIsFullscreen(false)}
        className={classes.mapActionButton}
        style={{
          left: theme.spacing.md,
        }}
      >
        <ArrowLeft width={24} height={24} />
      </ActionIcon>

      <ActionIcon
        color="gray.7"
        size={40}
        className={classes.mapActionButton}
        onClick={() => {
          setSelectedDeviceId(null);
          onCenterMap();
        }}
        style={{
          right: theme.spacing.md,
        }}
      >
        <Gps width={24} height={24} />
      </ActionIcon>
    </>
  );
}

const useStyles = createStyles((theme) => ({
  mapActionButton: {
    position: 'absolute',
    top: theme.spacing.md,
    borderRadius: '50%',
    background: theme.fn.rgba(theme.white, 0.8),
    boxShadow: '0px 1px 12.3px 0px rgba(0, 0, 0, 0.31)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
