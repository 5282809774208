import { Text } from '@mantine/core';
import React from 'react';

import { DashboardLayoutListItem } from '@portals/types';

import { ActiveIncidentsAgeWidget } from './ActiveIncidentsAgeWidget';
import { useOverviewContext } from '../../../overview.context';
import { useCurrentSpace } from '../../../overview.hooks';
import { TierType, WidgetType } from '../../../overview.types';
import { WidgetCardWrapper } from '../common/WidgetCardWrapper';

interface ActiveIncidentsAgeWidgetExtraParams {
  title: string;
  tiers: TierType[];
  displayIncidentSeverity: boolean;
  median: number;
}

export function ActiveIncidentsAgeDashboardWidgetWrapper({
  widgetId,
}: {
  widgetId: string;
}) {
  const { dashboard } = useOverviewContext();

  return dashboard.list.map((item) => {
    //As there is a type issue with the item, we need to cast it to the correct type
    const widget = item as DashboardLayoutListItem<
      WidgetType,
      ActiveIncidentsAgeWidgetExtraParams
    >;

    return item.id === 'device_incidents_age' && item.i === widgetId ? (
      <ActiveIncidentsAgeDashboardWidget key={item.i} item={widget} />
    ) : null;
  });
}

export function ActiveIncidentsAgeDashboardWidget({
  item,
}: {
  item: DashboardLayoutListItem<
    WidgetType,
    ActiveIncidentsAgeWidgetExtraParams
  >;
}) {
  const space = useCurrentSpace();

  const { dataLevel } = useOverviewContext();
  const title = item?.extraParams?.title;
  const tiers = item?.extraParams?.tiers;
  const displayIncidentSeverity = item?.extraParams?.displayIncidentSeverity;
  const median = item?.extraParams?.median;

  if (
    !title ||
    !tiers ||
    !dataLevel ||
    !space ||
    median === undefined ||
    displayIncidentSeverity === undefined
  ) {
    return null;
  }

  return (
    <WidgetCardWrapper
      paperProps={{
        p: 'xl',
        h: '100%',
        w: '100%',
      }}
      stackProps={{ spacing: 'xs' }}
      header={
        <Text size="md" color="gray.5">
          {title}
        </Text>
      }
    >
      <ActiveIncidentsAgeWidget
        tiers={tiers}
        dataLevel={dataLevel}
        space={space}
        displayIncidentSeverity={displayIncidentSeverity}
        median={median}
      />
    </WidgetCardWrapper>
  );
}
