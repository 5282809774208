import { BadgeProps } from '@mantine/core';
import React from 'react';

import { BadgeWithDot } from '@portals/core';
import { OrderPaymentStatusType } from '@portals/types';

interface OrganizationInvoicePaymentStatusBadgeProps extends BadgeProps {
  status: OrderPaymentStatusType;
}
export function OrganizationInvoicePaymentStatusBadge({
  status,
  ...badgeProps
}: OrganizationInvoicePaymentStatusBadgeProps) {
  const { color, label } = getInvoicePaymentStatusColorAndLabel(status);

  return (
    <BadgeWithDot color={color} size="lg" radius="xl" {...badgeProps}>
      {label}
    </BadgeWithDot>
  );
}

function getInvoicePaymentStatusColorAndLabel(status: OrderPaymentStatusType) {
  switch (status) {
    case 'paid': {
      return {
        color: 'teal',
        label: 'Paid',
      };
    }

    case 'failed': {
      return {
        color: 'red',
        label: 'Payment failed',
      };
    }

    case 'uncollectible': {
      return {
        color: 'gray',
        label: 'Void',
      };
    }

    case 'pending': {
      return {
        color: 'amber',
        label: 'Pending',
      };
    }

    case 'refunded': {
      return {
        color: 'indigo',
        label: 'Refunded',
      };
    }

    default: {
      return {
        color: 'gray',
        label: status,
      };
    }
  }
}
