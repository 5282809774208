import {
  Flex,
  FlexProps,
  MantineColor,
  Select,
  SelectProps,
  Text,
  useMantineTheme,
} from '@mantine/core';
import React, { forwardRef, useMemo } from 'react';

import { ReactComponent as DangerIcon } from '@portals/icons/bold/danger.svg';
import { IncidentPriorityLevel } from '@portals/types';
import { INCIDENT_PRIORITY_LEVEL, suppressPropagation } from '@portals/utils';

interface PriorityLevelSelectProps
  extends Omit<SelectProps, 'data' | 'onChange' | 'value'> {
  value: IncidentPriorityLevel;
  onChange: (value: IncidentPriorityLevel) => void;
}

export function PriorityLevelSelect({
  value,
  onChange,
  ...selectProps
}: PriorityLevelSelectProps) {
  const priorityLevelOptions = useMemo(
    () =>
      Object.values(INCIDENT_PRIORITY_LEVEL).map(({ id, name, color }) => ({
        value: id.toString(),
        label: name,
        color: color,
      })),
    []
  );

  return (
    <Select
      placeholder="Choose priority"
      itemComponent={PriorityLevelSelectItem}
      data={priorityLevelOptions}
      value={value.toString()}
      onChange={(v) => onChange(Number(v) as IncidentPriorityLevel)}
      {...selectProps}
    />
  );
}

interface SelectItemProps extends FlexProps {
  value: IncidentPriorityLevel;
  label: string;
  color: MantineColor;
}

const PriorityLevelSelectItem = forwardRef<HTMLDivElement, SelectItemProps>(
  ({ value, label, color, onMouseDown, ...selectItemProps }, ref) => {
    const mantineTheme = useMantineTheme();

    return (
      <Flex
        onMouseDown={suppressPropagation(onMouseDown)}
        ref={ref}
        align="center"
        gap="xs"
        px="md"
        py="sm"
        {...selectItemProps}
      >
        <DangerIcon
          color={mantineTheme.fn.themeColor(color)}
          width={24}
          height={24}
        />
        <Text>{label}</Text>
      </Flex>
    );
  }
);
