import {
  Button,
  Group,
  LoadingOverlay,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { UseMutationResult } from '@tanstack/react-query';
import React from 'react';

import { ModalCenteredMediaLayout } from '@portals/core';

import { ReactComponent as ReplaceDeviceIcon } from '../assets/img/icons/replace-device.svg';
import { ModalProps } from '../components/Modals';

export interface ReplaceDeviceProps
  extends ModalProps<{
    deviceId: string;
    onReplace: UseMutationResult<
      any,
      any,
      { deviceId: string; sn: string; notes?: string }
    >;
  }> {}

export function ReplaceDevice({ data, closeMe }: ReplaceDeviceProps) {
  const { onReplace, deviceId } = data;

  const form = useForm({
    initialValues: {
      sn: '',
    },
  });

  const onSubmit = async () => {
    await onReplace.mutateAsync({
      deviceId: deviceId,
      sn: form.values.sn,
    });

    closeMe();
  };

  return (
    <ModalCenteredMediaLayout
      media={<ReplaceDeviceIcon />}
      opened
      onClose={closeMe}
    >
      <LoadingOverlay visible={onReplace.isLoading} />

      <Stack align="center" spacing="md">
        <Text size="xl" color="blue_gray.9">
          Replace Device
        </Text>

        <Text size="sm" color="blue_gray.9" align="center">
          Replacing your device will not result in the loss of any of your
          device data, history, or licenses. Your old device's information will
          still be accessible and will be transferred to your new device.
        </Text>

        <form onSubmit={form.onSubmit(onSubmit)} style={{ width: '100%' }}>
          <Stack>
            <TextInput
              {...form.getInputProps('sn')}
              label="Serial Number"
              placeholder="Enter device serial number"
              required
            />

            <Group grow pt="md">
              <Button variant="default" onClick={closeMe}>
                Cancel
              </Button>

              <Button type="submit" disabled={!form.values.sn}>
                Replace
              </Button>
            </Group>
          </Stack>
        </form>
      </Stack>
    </ModalCenteredMediaLayout>
  );
}
