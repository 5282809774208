import {
  Button,
  Group,
  LoadingOverlay,
  Select,
  Stack,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React from 'react';

import { SpaceType, useAddSpaceAccess } from '@portals/api/organizations';
import { MembersSVG } from '@portals/assets';
import { ModalCenteredMediaLayout } from '@portals/core';
import { ModalProps } from '@portals/framework';

export interface AddExternalGroupAccessModalProps
  extends ModalProps<{
    space: SpaceType;
  }> {}

const ACCESS_LEVEL = [
  { value: '1', label: 'View' },
  { value: '2', label: 'Edit' },
  { value: '3', label: 'Admin' },
];

export function AddExternalGroupAccessModal({
  closeMe,
  data: { space },
}: AddExternalGroupAccessModalProps) {
  const addSpaceAccess = useAddSpaceAccess();

  const groupsForm = useForm<{ group_id: string; level: string }>({
    initialValues: {
      group_id: '',
      level: null,
    },
  });

  const onSubmit = async (values: typeof groupsForm.values) => {
    try {
      await addSpaceAccess.mutateAsync({
        spaceId: space.id,
        ...values,
      });

      closeMe();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ModalCenteredMediaLayout
      opened
      onClose={closeMe}
      title="Add External Access"
      media={<MembersSVG />}
    >
      <LoadingOverlay visible={addSpaceAccess.isLoading} />

      <form onSubmit={groupsForm.onSubmit(onSubmit)}>
        <Stack spacing="xl">
          <TextInput
            label="Group ID"
            placeholder="Enter Group ID"
            required
            {...groupsForm.getInputProps('group_id')}
          />

          <Select
            withinPortal
            required
            label="Access Level"
            placeholder="Select access level"
            data={ACCESS_LEVEL}
            {...groupsForm.getInputProps('level')}
          />

          <Group grow>
            <Button variant="default" onClick={closeMe}>
              Cancel
            </Button>
            <Button type="submit">Add Group</Button>
          </Group>
        </Stack>
      </form>
    </ModalCenteredMediaLayout>
  );
}
