import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError } from '@portals/redux/actions/toastr';
import { DashboardLayout, OrganizationConfigurationType } from '@portals/types';

import {
  configurationQueryKeys,
  SPACE_DASHBOARD_API_URL,
} from './configuration.constants';
import { ServerError } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils';

export function useUpdateSpaceDashboard() {
  const queryClient = useQueryClient();

  const dispatch = useDispatch();
  const { url, options } = useRequestOptions({
    url: SPACE_DASHBOARD_API_URL,
    method: 'POST',
  });

  return useMutation<
    OrganizationConfigurationType,
    ServerError,
    DashboardLayout
  >({
    mutationFn: (dashboard) =>
      fetchApiRequest(url, {
        body: JSON.stringify({ space_dashboard: dashboard }),
        ...options,
      }),
    onSuccess: (config) => {
      queryClient.setQueryData(configurationQueryKeys.config, config);
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useUpdateSpaceDashboard',
      baseUrl: SPACE_DASHBOARD_API_URL,
      method: 'POST',
    },
  });
}
