import { createStyles, Stack, Text } from '@mantine/core';
import React from 'react';
import { TooltipProps } from 'recharts';

interface ChartTooltipProps {
  totalDevicesCount: number;
}

export function ChartTooltip({
  payload,
  totalDevicesCount,
}: TooltipProps<number, string> & ChartTooltipProps) {
  const { classes } = useStyles();

  if (payload === undefined || payload[0] === undefined) return null;

  const { name, value } = payload[0];

  const valueInPercentages = Math.round(
    ((value ?? 0) / totalDevicesCount) * 100
  );

  return (
    <Stack className={classes.tooltip} spacing={4}>
      <Text color="white" size="xs">
        {name}
      </Text>
      <Text color="gray.4" size="xs">
        {value} ({valueInPercentages}%)
      </Text>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.black,
    borderRadius: theme.radius.md,
    padding: theme.spacing.md,
  },
}));
