import { includes, noop, toNumber } from 'lodash/fp';
import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
} from 'react';

interface TreeContextProviderProps {
  expandedNodes: Array<number>;
  setExpandedNodes: Dispatch<SetStateAction<number[]>>;
  selectedNode: number;
  children?: ReactNode;
}

const TreeContext = createContext<TreeContextProviderProps>({
  expandedNodes: [],
  selectedNode: null,
  setExpandedNodes: noop,
});

export function TreeContextProvider({
  children,
  expandedNodes,
  setExpandedNodes,
  selectedNode,
}: TreeContextProviderProps) {
  return (
    <TreeContext.Provider
      value={{ expandedNodes, setExpandedNodes, selectedNode }}
    >
      {children}
    </TreeContext.Provider>
  );
}

export const useTreeContext = () => useContext(TreeContext);

export function useIsNodeExpanded(nodeId: number) {
  const treeContext = useTreeContext();

  return includes(nodeId, treeContext.expandedNodes);
}

export function useIsNodeSelected(nodeId: number) {
  const treeContext = useTreeContext();

  return toNumber(nodeId) === treeContext.selectedNode;
}
