import { schema } from 'normalizr';

export const DEVICE_SCHEMA = new schema.Entity('devices');

export const TICKET_SCHEMA = new schema.Entity('tickets');

export const INTEGRATIONS_SCHEMA = new schema.Entity(
  'integrations',
  {},
  {
    idAttribute: 'name',
  }
);

export const ORGANIZATIONS_SCHEMA = new schema.Entity('organizations');

export const RULES_SCHEMA = new schema.Entity('rules');

export const TICKET_DEVICE_SCHEMA = new schema.Entity(
  'ticket_device',
  {},
  {
    idAttribute: 'ticket_id',
  }
);

export const SYSTEM_ISSUES_SCHEMA = new schema.Entity('system_issues');
