import { ActionIcon } from '@mantine/core';
import Slider from 'rc-slider';
import React, { Dispatch, FC, SetStateAction } from 'react';
import styled from 'styled-components';

import { ReactComponent as RotateLeft } from '@portals/icons/linear/rotate-left.svg';
import { useTheme } from '@portals/ui';
import { getStyledThemeColor } from '@portals/utils';

interface ImageCropperActionsProps {
  crop: { scale: number; straighten: number };
  setCrop: Dispatch<SetStateAction<{ scale: number; straighten: number }>>;
}

const ImageCropperActions: FC<ImageCropperActionsProps> = ({
  crop,
  setCrop,
}) => {
  const { color } = useTheme();

  return (
    <Container>
      <ActionWrapper>
        <div className="action-header">
          <div className="d-flex align-items-center">
            Zoom
            {crop.scale !== 1 ? (
              <ActionIcon
                variant="transparent"
                sx={(theme) => ({ color: theme.white })}
                onClick={() =>
                  setCrop((curr) => ({
                    ...curr,
                    scale: 1,
                  }))
                }
              >
                <RotateLeft width={17} height={17} />
              </ActionIcon>
            ) : null}
          </div>

          <div>{crop.scale}</div>
        </div>

        <div className="slider-wrapper">
          <Slider
            value={crop.scale}
            onChange={(value: number) =>
              setCrop((curr) => ({
                ...curr,
                scale: value,
              }))
            }
            min={0.1}
            step={0.01}
            max={4}
            handleStyle={{ backgroundColor: color.primary }}
          />
        </div>
      </ActionWrapper>

      <ActionWrapper>
        <div className="action-header">
          <div className="d-flex align-items-center">
            Straighten
            {crop.straighten !== 0 ? (
              <ActionIcon
                variant="transparent"
                sx={(theme) => ({ color: theme.white })}
                onClick={() =>
                  setCrop((curr) => ({
                    ...curr,
                    straighten: 0,
                  }))
                }
              >
                <RotateLeft width={17} height={17} />
              </ActionIcon>
            ) : null}
          </div>
          <div>{crop.straighten}</div>
        </div>

        <div className="slider-wrapper">
          <Slider
            value={crop.straighten}
            onChange={(value: number) =>
              setCrop((curr) => ({
                ...curr,
                straighten: value,
              }))
            }
            min={-180}
            max={180}
            step={1}
            handleStyle={{ backgroundColor: color.primary }}
          />
        </div>
      </ActionWrapper>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${getStyledThemeColor('dark')};
  height: 100px;
  width: 100%;
  padding: 0 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 50px;
  align-items: center;
`;

const ActionWrapper = styled.div`
  .action-header {
    color: ${getStyledThemeColor('white')};
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export default ImageCropperActions;
