import { useMemo } from 'react';

interface UseSpaceIncidentRateWidgetCountAndPercentageProps {
  spacesCount: number;
  spacesWithIncidentsCount: number;
}
export function useSpaceIncidentRateWidgetCountAndPercentage({
  spacesCount,
  spacesWithIncidentsCount,
}: UseSpaceIncidentRateWidgetCountAndPercentageProps) {
  const spacesWithoutIncidentsCount = useMemo(
    () => spacesCount - spacesWithIncidentsCount,
    [spacesCount, spacesWithIncidentsCount]
  );

  const percentageOfSpacesWithIncidents = useMemo(() => {
    const percentage = Math.floor(
      (spacesWithIncidentsCount / spacesCount) * 100
    );

    return isNaN(percentage) ? 0 : percentage;
  }, [spacesWithIncidentsCount, spacesCount]);

  const percentageOfSpacesWithoutIncidents = useMemo(() => {
    const percentage = Math.floor(
      (spacesWithoutIncidentsCount / spacesCount) * 100
    );

    return isNaN(percentage) ? 0 : percentage;
  }, [spacesWithoutIncidentsCount, spacesCount]);

  return {
    spacesWithoutIncidentsCount,
    percentageOfSpacesWithIncidents,
    percentageOfSpacesWithoutIncidents,
  };
}
