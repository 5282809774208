import {
  Box,
  Button,
  createStyles,
  Group,
  LoadingOverlay,
  ScrollArea,
  Stack,
  Text,
  Textarea,
} from '@mantine/core';
import React, { useState } from 'react';

import { useUpdateSpace } from '@portals/api/organizations';

import { ReactComponent as NoteIcon } from '../../../pages/overview/overview-dashboard/assets/note-icon.svg';
import { NoteEmptyState } from '../../../pages/overview/overview-dashboard/widgets/note/NoteEmptyState';
import { WidgetPreviewComponentProps } from '../widget-preview.types';

export function NoteWidgetPreview({
  onClose,
  space,
  onSave,
  type,
}: WidgetPreviewComponentProps & { onClose: () => void }) {
  const { classes } = useStyles();
  const [note, setNote] = useState(type === 'create' ? '' : space?.note || '');
  const updateSpace = useUpdateSpace();

  const onSubmit = async () => {
    await updateSpace.mutateAsync({
      spaceId: space.id,
      updatedSpace: { note },
    });

    onSave?.();
    onClose();
  };

  return (
    <>
      <LoadingOverlay visible={updateSpace.isLoading} />

      <Box className={classes.container}>
        <Box className={classes.settings}>
          <Group spacing="sm" align="center" pt="xl" pl="xl" mb={32}>
            <NoteIcon width={28} height={28} />

            <Text size="xl" color="blue_gray.9">
              Note Widget
            </Text>
          </Group>

          <Box p="xl" pt={0}>
            <Textarea
              autoFocus
              data-autofocus
              label="Note"
              value={note}
              onChange={(event) => setNote(event.target.value)}
              minRows={20}
            />
          </Box>
        </Box>

        <Box className={classes.preview}>
          <Stack className={classes.noteContainer} p={32}>
            <Text size="md" color="gray.5">
              Note
            </Text>

            {note ? (
              <ScrollArea mah={200}>
                <pre>
                  <Text
                    size="sm"
                    weight={500}
                    color="gray.9"
                    className={classes.noteContent}
                  >
                    {note}
                  </Text>
                </pre>
              </ScrollArea>
            ) : (
              <NoteEmptyState hideAdd />
            )}
          </Stack>
        </Box>
      </Box>

      <Group position="right" className={classes.footer} p="xl">
        <Button onClick={onClose} variant="default">
          Close
        </Button>

        <Button onClick={onSubmit}>{type === 'edit' ? 'Save' : 'Add'}</Button>
      </Group>
    </>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    height: 537,
    gap: 0,
  },
  footer: {
    borderTop: `1px solid ${theme.colors.gray[3]}`,
  },
  settings: {
    height: '100%',
  },
  preview: {
    backgroundColor: theme.colors.gray[1],
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 47px',
    gridTemplateRows: 'auto',
    gridTemplateColumns: '1fr',
  },
  noteContainer: {
    backgroundColor: theme.white,
    borderRadius: 20,
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
    gridTemplateColumns: '1fr',
    width: '100%',
    height: 'fit-content',
    minHeight: 200,
    position: 'relative',
  },
  noteContent: {
    whiteSpace: 'pre-line',
  },
}));
