import { createStyles, Stack, Text } from '@mantine/core';
import { captureException } from '@sentry/react';
import React, { ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { WidgetType } from '@portals/device-widgets';
import { ReactComponent as DangerIcon } from '@portals/icons/bold/danger.svg';

interface DeviceWidgetErrorBoundaryProps {
  children: ReactNode;
  widgetId: WidgetType['id'];
}

export function DeviceWidgetErrorBoundary({
  widgetId,
  children,
}: DeviceWidgetErrorBoundaryProps) {
  const { classes } = useStyles();

  return (
    <ErrorBoundary
      fallbackRender={({ error }) => {
        captureException(
          `Error rendering widget with id: ${widgetId}. Error: ${error}`
        );

        return (
          <Stack spacing={4} className={classes.container}>
            <DangerIcon className={classes.icon} />

            <Text c="gray.5" fz="md" fw={600}>
              Failed to load widget
            </Text>
          </Stack>
        );
      }}
    >
      {children}
    </ErrorBoundary>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.lg,
    backgroundColor: theme.white,
  },
  icon: {
    width: 30,
    height: 30,
    color: theme.colors.red[2],
  },
}));
