import React from 'react';

import { DevicesPerSpaceViewEnum } from '@portals/api/organizations';
import { DashboardLayoutListItem } from '@portals/types';

import { DevicesPerSpaceWidget } from './DevicesPerSpaceWidget';
import { useOverviewContext } from '../../../overview.context';
import { useCurrentSpace } from '../../../overview.hooks';
import { DataLevelEnum, WidgetType } from '../../../overview.types';
import { WidgetCardWrapper } from '../common/WidgetCardWrapper';

interface DevicesPerSpaceDashboardWidgetParams {
  title: string;
  devicesPerSpaceView: DevicesPerSpaceViewEnum;
}

export function DevicesPerSpaceDashboardWidgetWrapper({
  widgetId,
}: {
  widgetId: string;
}) {
  const { dashboard } = useOverviewContext();

  return dashboard.list.map((item) => {
    //As there is a type issue with the item, we need to cast it to the correct type
    const widget = item as DashboardLayoutListItem<
      WidgetType,
      DevicesPerSpaceDashboardWidgetParams
    >;

    return item.id === 'devices_per_space' && item.i === widgetId ? (
      <DevicesPerSpaceDashboardWidget key={item.i} item={widget} />
    ) : null;
  });
}

export function DevicesPerSpaceDashboardWidget({
  item,
}: {
  item: DashboardLayoutListItem<
    WidgetType,
    DevicesPerSpaceDashboardWidgetParams
  >;
}) {
  const space = useCurrentSpace();
  const { dataLevel } = useOverviewContext();

  const title = item.extraParams?.title;
  const devicesPerSpaceView = item.extraParams?.devicesPerSpaceView;

  return (
    <WidgetCardWrapper
      stackProps={{ spacing: 'sm' }}
      paperProps={{ p: 'lg' }}
      header={null}
    >
      <DevicesPerSpaceWidget
        space={space}
        devicesPerSpaceView={devicesPerSpaceView}
        dataLevel={dataLevel ?? DataLevelEnum.All}
        widgetTitle={title}
        isDashboardPreview
      />
    </WidgetCardWrapper>
  );
}
