import { useMantineTheme } from '@mantine/core';
import { find, toString } from 'lodash/fp';
import React from 'react';

import { StatusWidget, StatusWidgetFormType } from '@portals/device-widgets';

import { WidgetProps } from '../device-widgets.types';

export function StatusWidgetWrapper({
  widget,
  deviceState,
  isDeviceOffline,
  lastUpdateTimestamp,
}: WidgetProps<StatusWidgetFormType>) {
  const theme = useMantineTheme();
  const fields = widget?.config?.fields;

  const { name, telemetry, status, telemetry_as_title, title_telemetry } =
    fields ?? {};
  const telemetryValue = toString(deviceState?.[telemetry]);
  const displayedValue = find({ value: telemetryValue }, status);

  const title = telemetry_as_title
    ? deviceState?.[title_telemetry] || 'N/A'
    : name;

  return (
    <StatusWidget
      title={title}
      color={displayedValue?.color || theme.colors.gray[4]}
      value={displayedValue?.display_name || 'N/A'}
      isDeviceOffline={isDeviceOffline}
      lastUpdateTimestamp={lastUpdateTimestamp}
    />
  );
}
