import { find } from 'lodash/fp';

import { useDevice, useDeviceModels } from '@portals/api/organizations';
import { SupportedCommandType } from '@portals/types';

export function useDeviceCommand(
  deviceId: string,
  commandName: string
): SupportedCommandType | undefined {
  const models = useDeviceModels();

  const device = useDevice(deviceId);
  const model = find({ id: device.data?.partner?.type_id }, models.data);

  return find({ name: commandName }, model?.supported_commands);
}
