import { Portal } from '@mantine/core';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useMemo } from 'react';
import { useDragDropManager } from 'react-dnd';

import { DeviceType } from '@portals/api/organizations';

import { DeviceCardLayout } from './DeviceCardLayout';
import { useDeviceDragLayer } from '../../../../../../../hooks/device-cards';

interface CustomDeviceCardDraggedLayer {
  device: DeviceType;
  width: number;
}

export function CustomDeviceCardDraggedLayer({
  device,
  width,
}: CustomDeviceCardDraggedLayer) {
  const { isDragging, initialOffset, currentOffset, item } =
    useDeviceDragLayer();

  const isBeingDragged =
    isDragging &&
    initialOffset &&
    currentOffset &&
    item?.device?.id === device?.id;

  const previewTransform = useMemo(() => {
    if (!isBeingDragged) {
      return null;
    }

    return {
      x: currentOffset.x,
      y: currentOffset.y,
    };
  }, [currentOffset, isBeingDragged]);

  const dragDropManager = useDragDropManager();

  const targetIds = dragDropManager?.getMonitor()?.getTargetIds();
  const isOver = dragDropManager?.getMonitor()?.canDropOnTarget(targetIds[0]);

  return (
    <Portal>
      <AnimatePresence>
        {isBeingDragged ? (
          <motion.div
            style={{
              pointerEvents: 'none',
              zIndex: 9,
              height: 'auto',
              position: 'fixed',
              top: 0,
              left: 0,
              display: 'block',
            }}
            key={item?.device?.id}
            initial={{
              x: initialOffset?.x,
              y: initialOffset?.y,
              width,
              rotate: '0deg',
              opacity: 1,
              scale: 1,
              display: 'none',
            }}
            animate={{
              width,
              x: previewTransform?.x,
              y: previewTransform?.y,
              display: 'inline-block',
              scale: 1.1,
              rotate: isOver ? '0deg' : '-10deg',
              opacity: 0.5,
            }}
            exit={{
              display: 'none',
              scale: 1,
              x: initialOffset?.x,
              y: initialOffset?.y,
              width,
              rotate: '0deg',
              opacity: 0,
            }}
          >
            <DeviceCardLayout
              device={device}
              isDragging={isDragging}
              isPreview
            />
          </motion.div>
        ) : null}
      </AnimatePresence>
    </Portal>
  );
}
