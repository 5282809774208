import React, { useMemo } from 'react';

interface CustomScatterDotProps {
  cx: number;
  cy: number;
  fill: string;
  eventId: string;
  hoveredEventId: string | null;
  onHover: (eventId: string | null) => void;
  index: number;
  dataPoint: {
    eventId: string;
    timestamp: number;
    value: number | null;
    totalOverlapping: number;
    jitterIndex: number;
  };
}

const JITTER_RADIUS = 7; // Adjust this value to control the radius of the jitter circle

export function CustomScatterDot({
  cx,
  cy,
  fill,
  eventId,
  hoveredEventId,
  onHover,

  dataPoint,
}: CustomScatterDotProps) {
  const isHovered = hoveredEventId === eventId;
  const opacity = hoveredEventId === null || isHovered ? 1 : 0.25;

  const jitter = useMemo(() => {
    const totalOverlapping = dataPoint.totalOverlapping ?? 1;

    if (totalOverlapping <= 1) return { x: 0, y: 0 };

    // Calculate position on the circle
    const angle =
      ((dataPoint.jitterIndex ?? 0) / totalOverlapping) * 2 * Math.PI;
    return {
      x: Math.cos(angle) * JITTER_RADIUS,
      y: Math.sin(angle) * JITTER_RADIUS,
    };
  }, [dataPoint]);

  return (
    <circle
      cx={cx + jitter.x}
      cy={cy + jitter.y}
      r={4}
      stroke={fill}
      strokeWidth={2}
      fill={fill}
      fillOpacity={isHovered ? 1 : 0.5}
      opacity={opacity}
      onMouseEnter={() => onHover(eventId)}
      onMouseLeave={() => onHover(null)}
      style={{
        transition: 'opacity 0.15s ease-in-out',
      }}
    />
  );
}
