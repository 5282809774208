import { flow, without, xor } from 'lodash/fp';
import React, {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from 'react';

import {
  GroupTypeEnum,
  IncidentsFilterEnum,
  SortTypeEnum,
  StatusesFilterEnum,
  ViewType,
} from './devices-list.types';

export type FilterState<T, M = T> = {
  value: T;
  setValue: (newValue: M) => void;
};

const DevicesFiltersContext = createContext<{
  searchTerm: FilterState<string>;
  sortTerm: FilterState<SortTypeEnum>;
  groupTerm: FilterState<GroupTypeEnum>;
  incidentsFilter: FilterState<Array<IncidentsFilterEnum>, IncidentsFilterEnum>;
  statusesFilter: FilterState<Array<StatusesFilterEnum>, StatusesFilterEnum>;
  viewType: FilterState<ViewType>;
  collapsedGroups: {
    value: Record<string, boolean>;
    setValue: Dispatch<SetStateAction<Record<string, boolean>>>;
  };
  onClearFilters: () => void;
}>(undefined);

type DevicesFiltersProviderProps = {
  children: ReactNode;
};

const DevicesFiltersProvider: FC<DevicesFiltersProviderProps> = ({
  children,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortByTerm, setSortByTerm] = useState(SortTypeEnum.Name);
  const [groupByTerm, setGroupByTerm] = useState(GroupTypeEnum.Status);
  const [collapsedGroups, setCollapsedGroups] = useState({});
  const [incidentsFilter, setIncidentsFilter] = useState([
    IncidentsFilterEnum.All,
  ]);
  const [statusesFilter, setStatusesFilter] = useState([
    StatusesFilterEnum.All,
  ]);
  const [viewType, setViewType] = useState(ViewType.Grid);

  const onFilterChange = useCallback(
    (setStateCb: Dispatch<SetStateAction<any>>, allOption) =>
      (statusFilter: any) => {
        if (statusFilter === allOption) {
          setStateCb([allOption]);
        } else {
          setStateCb((curr) => {
            const updatedFilters = flow([
              xor([statusFilter]),
              without([allOption]),
            ])(curr);

            return updatedFilters.length === 0 ? [allOption] : updatedFilters;
          });
        }
      },
    []
  );

  const onGroupTermChange = useCallback((groupByTerm: GroupTypeEnum) => {
    setGroupByTerm(groupByTerm);
    setCollapsedGroups({});
  }, []);

  const onClearFilters = useCallback(() => {
    setSearchTerm('');
    setIncidentsFilter([IncidentsFilterEnum.All]);
    setStatusesFilter([StatusesFilterEnum.All]);
  }, []);

  return (
    <DevicesFiltersContext.Provider
      value={{
        onClearFilters,
        collapsedGroups: {
          value: collapsedGroups,
          setValue: setCollapsedGroups,
        },
        searchTerm: { value: searchTerm, setValue: setSearchTerm },
        sortTerm: { value: sortByTerm, setValue: setSortByTerm },
        groupTerm: { value: groupByTerm, setValue: onGroupTermChange },
        statusesFilter: {
          value: statusesFilter,
          setValue: onFilterChange(setStatusesFilter, StatusesFilterEnum.All),
        },
        incidentsFilter: {
          value: incidentsFilter,
          setValue: onFilterChange(setIncidentsFilter, IncidentsFilterEnum.All),
        },
        viewType: {
          value: viewType,
          setValue: setViewType,
        },
      }}
    >
      {children}
    </DevicesFiltersContext.Provider>
  );
};

export const useDevicesFiltersContext = () => useContext(DevicesFiltersContext);

export default DevicesFiltersProvider;
