import {
  createStyles,
  Modal,
  ModalProps as MantineModalProps,
  SimpleGrid,
  Stack,
  Text,
  UnstyledButton,
} from '@mantine/core';
import React from 'react';

import { ModalBody } from '@portals/core';
import { ModalProps } from '@portals/framework';
import { ReactComponent as PersonalCard } from '@portals/icons/bulk/personalcard.svg';

import { ReactComponent as Support } from '../../assets/img/icons/support.svg';

export type InternalOrExternal = 'internal' | 'external';

export interface AddUserTypeModalProps
  extends ModalProps<{
    onSubmit: (userType: InternalOrExternal) => void;
    type?: 'users' | 'groups';
  }> {}

export function AddUserTypeModal({
  closeMe,
  data: { onSubmit, type = 'users' },
}: AddUserTypeModalProps) {
  const { classes, cx } = useStyles();

  const onSubmitWrapper = (selectedType: InternalOrExternal) => {
    onSubmit(selectedType);

    closeMe();
  };

  return (
    <Modal
      opened
      onClose={closeMe}
      padding={32}
      styles={modalStyles}
      title={
        <Text>
          Select the type of {type === 'users' ? 'users' : 'group'} you would
          like <br /> to invite
        </Text>
      }
    >
      <ModalBody>
        <SimpleGrid cols={2}>
          <UnstyledButton
            onClick={() => onSubmitWrapper('internal')}
            className={cx(classes.card)}
          >
            <Stack align="center" spacing={18} p="xl">
              <PersonalCard />
              <Text color="gray.8">
                Internal {type === 'users' ? 'Users' : 'Group'}
              </Text>
              <Text color="gray.6" size="xs" align="center">
                An internal user is someone who already has access to your
                organization's resources, such as employees."
              </Text>
            </Stack>
          </UnstyledButton>

          <UnstyledButton
            onClick={() => onSubmitWrapper('external')}
            className={cx(classes.card)}
          >
            <Stack align="center" spacing={18} p="xl">
              <Support />
              <Text color="gray.8">External Support</Text>
              <Text color="gray.6" size="xs" align="center">
                External support access can be granted to specific support teams
                or individuals assigned by manufacturers or service providers.
              </Text>
            </Stack>
          </UnstyledButton>
        </SimpleGrid>
      </ModalBody>
    </Modal>
  );
}

const modalStyles: MantineModalProps['styles'] = (theme) => ({
  header: {
    alignItems: 'start',
  },
  title: {
    marginRight: 0,
    textAlign: 'center',
  },
  close: {
    position: 'absolute',
    top: theme.spacing.xl,
    right: theme.spacing.xl,
  },
});

const useStyles = createStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing.xs,
    height: 226,
    border: `1px solid ${theme.colors.gray[2]}`,
    borderRadius: theme.radius.md,
    cursor: 'pointer',
    transition: 'box-shadow 200ms ease-in-out',
    userSelect: 'none',

    '&:hover': {
      boxShadow: theme.shadows.sm,
      borderColor: theme.fn.primaryColor(),

      svg: {
        opacity: 1,
        color: theme.fn.primaryColor(),
      },
    },

    '&:nth-of-type(1) svg': {
      transform: 'rotate(-16deg)',
    },

    svg: {
      width: 78,
      height: 56,
      opacity: 0.7,
    },
  },
}));
