import { Box, createStyles, LoadingOverlay } from '@mantine/core';
import React, { useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { OrderType, useOrderById } from '@portals/api/organizations';
import { GenericContextProvider } from '@portals/framework';
import {
  RouteModal,
  useRouteModalConfig,
} from '@portals/framework/route-modals';

import { OrderContent } from './OrderContent';
import { OrderHeader } from './OrderHeader';

export function Order() {
  const contentStyles = useStyles();
  const defaultBackgroundPath = useRouteModalConfig('order').backgroundPath;

  const { order_id } = useParams<{ order_id: string }>();

  const order = useOrderById(order_id);

  const content = useMemo(() => {
    if (!order_id) {
      return <Navigate to={defaultBackgroundPath} replace />;
    }

    if (!order.isFetched) {
      return <LoadingOverlay visible />;
    } else if (!order.data) {
      return <Navigate to={defaultBackgroundPath} replace />;
    }

    return (
      <>
        <OrderHeader />

        <OrderContent />
      </>
    );
  }, [defaultBackgroundPath, order, order_id]);

  return (
    <RouteModal modalId="order">
      <GenericContextProvider<OrderType> value={order?.data}>
        <Box className={contentStyles.classes.container}>{content}</Box>
      </GenericContextProvider>
    </RouteModal>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
    background: theme.colors.gray[0],
  },
}));
