import { PaymentIntervalEnum, PaymentMethodEnum } from '@portals/types';

import { UseOrderPreviewItem } from './orders.types';

export const getOrderItemPaidIntervalUnits = (
  selected_payment_interval: UseOrderPreviewItem['selected_payment_interval'],
  payment_method: PaymentMethodEnum
) => {
  const isPO = payment_method === PaymentMethodEnum.PurchaseOrder;

  if (!isPO || selected_payment_interval === PaymentIntervalEnum.OneTime)
    return null;

  return selected_payment_interval === PaymentIntervalEnum.Monthly ? 12 : 1;
};
