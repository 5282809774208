import React, { useEffect } from 'react';
import { getEmptyImage } from 'react-dnd-html5-backend';

import { DeviceType } from '@portals/api/organizations';

import { DeviceCardLayout } from './DeviceCardLayout';
import { useDeviceDrag } from '../../../../../../../hooks/device-cards';

interface DeviceCardProps {
  device: DeviceType;
}

export function DeviceCard({ device }: DeviceCardProps) {
  const { dragRef, isDragging, preview } = useDeviceDrag(device);

  // This is used to hide the browser's default drag preview
  useEffect(
    function setEmptyDraggedPreview() {
      preview(getEmptyImage(), { captureDraggingState: true });
    },
    [preview]
  );

  return (
    <DeviceCardLayout
      device={device}
      dragRef={dragRef}
      isDragging={isDragging}
    />
  );
}
