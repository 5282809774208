import { createStyles, Stack, Text } from '@mantine/core';
import { groupBy, keys, map, size } from 'lodash/fp';
import React, { Dispatch, SetStateAction } from 'react';

import { OrganizationStoreListing } from '@portals/types';

interface ProductCategoriesProps {
  storeListings: Array<OrganizationStoreListing>;
  selectedCategory: string;
  setSelectedCategory: Dispatch<SetStateAction<string>>;
}

export function ProductCategories({
  storeListings,
  selectedCategory,
  setSelectedCategory,
}: ProductCategoriesProps) {
  const { classes, cx } = useStyles();
  const categories = groupBy(
    ({ product }) => product.category || '',
    storeListings
  );
  const numOfAllProducts = size(storeListings);

  const categoriesList = map((key) => {
    return {
      id: key,
      numOfProducts: size(categories[key]),
    };
  }, keys(categories));

  return (
    <Stack spacing="xs" mt={30}>
      <div
        className={cx(classes.category, { active: !selectedCategory })}
        onClick={() => setSelectedCategory(null)}
      >
        <Text truncate>All Products</Text>

        <div className="amount">{numOfAllProducts}</div>
      </div>

      {categoriesList.map(({ id, numOfProducts }) =>
        !numOfProducts || !id ? null : (
          <div
            key={id}
            onClick={() => setSelectedCategory(id)}
            className={cx(classes.category, {
              active: id === selectedCategory,
            })}
          >
            <Text truncate>{id}</Text>

            <Text className="amount">{numOfProducts}</Text>
          </div>
        )
      )}
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  category: {
    width: '100%',
    paddingBlock: theme.spacing.md,
    paddingInline: theme.spacing.lg,
    color: theme.colors.blue_gray[7],
    fontSize: theme.fontSizes.sm,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 114,
    cursor: 'pointer',
    textTransform: 'capitalize',
    transition: 'all 0.15s ease-in-out',

    '.amount': {
      color: theme.colors.blue_gray[3],
    },

    '&.active': {
      backgroundColor: theme.colors.primary[0],
      color: theme.fn.primaryColor(),

      '.amount': {
        color: theme.fn.primaryColor(),
      },

      '&:hover': {
        backgroundColor: theme.fn.lighten(theme.colors.primary[0], 0.25),
      },
    },

    '&:hover': {
      backgroundColor: theme.colors.gray[0],
    },
  },
}));
