import { Image, ImageProps, useMantineTheme } from '@mantine/core';
import React from 'react';

import { ReactComponent as Gallery } from '@portals/icons/bold/gallery.svg';

interface ProductImageProps extends ImageProps {}

export function ProductImage(props: ProductImageProps) {
  const theme = useMantineTheme();

  return (
    <Image
      withPlaceholder
      styles={imageStyles}
      placeholder={
        <Gallery width="50%" height="50%" color={theme.colors.blue_gray[2]} />
      }
      {...props}
    />
  );
}

const imageStyles: ImageProps['styles'] = {
  root: {
    aspectRatio: '372 / 350',
  },
  figure: { height: '100%' },
  imageWrapper: { height: '100%' },
};
