import { useEffectOnce } from 'react-use';

import { Pendo } from '@portals/analytics';
import { useWizard } from '@portals/framework';

import { SignupWizardContextDataType, StepIdEnum } from './wizard.types';

export function useSignUpWizard() {
  return useWizard<SignupWizardContextDataType, StepIdEnum>();
}

export function useTrackPageView(page: string) {
  useEffectOnce(function trackPageView() {
    Pendo.trackPageView(page);
  });
}
