import {
  ActionIcon,
  ActionIconProps,
  createStyles,
  Group,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import * as React from 'react';
import { useRef, useState } from 'react';

import { useOnClickOutside } from '@portals/utils';

import { IconSelector } from './IconSelector';
import { WIDGET_ICONS } from '../widgets.constants';
import { WidgetColorType, WidgetIconNameType } from '../widgets.types';

export interface PopoverIconSelectorProps {
  selectedIconName: WidgetIconNameType;
  onChange: (iconName: WidgetIconNameType) => void;
  color?: WidgetColorType;
  actionIconProps?: ActionIconProps;
}

export function PopoverIconSelector({
  selectedIconName,
  onChange,
  actionIconProps = {},
}: PopoverIconSelectorProps) {
  const { classes } = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const tooltipContentRef = useRef(null);
  useOnClickOutside(tooltipContentRef, () => setIsOpen(false), isOpen);

  const onChangeWrapper = (icon: WidgetIconNameType) => {
    onChange(icon);

    setIsOpen(false);
  };

  const iconSettings = WIDGET_ICONS.find(
    ({ iconName }) => iconName === selectedIconName
  );

  if (!iconSettings) return null;

  const Icon = iconSettings.Icon;

  return (
    <Tooltip
      opened={isOpen}
      withinPortal
      withArrow
      position="bottom"
      classNames={{
        tooltip: classes.tooltip,
      }}
      arrowSize={10}
      label={
        <Stack p="xl" ref={tooltipContentRef}>
          <Group position="center">
            <Text size="lg" color="gray.8" weight="500">
              Select Icon
            </Text>
          </Group>

          <IconSelector
            selectedIconName={selectedIconName}
            onChange={onChangeWrapper}
            color="blue_accent.4"
          />
        </Stack>
      }
    >
      <ActionIcon
        color="blue_accent.4"
        size={36}
        variant="light"
        onClick={() => setIsOpen((curr) => !curr)}
        data-testid="widget-icon-selector-toggle"
        {...actionIconProps}
      >
        <Icon />
      </ActionIcon>
    </Tooltip>
  );
}

const useStyles = createStyles((theme) => ({
  tooltip: {
    pointerEvents: 'all',
    background: 'white',
    padding: 0,
    boxShadow: theme.shadows.md,
  },
}));
