import { createStyles, LoadingOverlay, Stack, Text } from '@mantine/core';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  useStoreSettings,
  useSubscription,
  useSubscriptionInvoiceItems,
} from '@portals/api/organizations';
import { StorePolicyIcon } from '@portals/assets';
import { DetailsPanel } from '@portals/core';
import {
  PaymentsHistoryPanel,
  ProductImage,
  SubscriptionStatusBadge,
} from '@portals/framework';
import { TextEditorModalProps } from '@portals/framework/modals';
import { useOpenModal } from '@portals/redux';
import { ProductPricingModel, TenantType } from '@portals/types';

import { OverviewTab } from './OverviewTab';
import { SubscriptionDetailsPanelActions } from './SubscriptionDetailsPanelActions';
import { UsageBasedSubscriptionDetailsPanelContent } from './UsageBasedSubscriptionDetailsPanelContent';
import {
  SubscriptionCancellationFlowModalProps,
  SubscriptionRenewalModalProps,
} from '../../../../../modals';

interface SubscriptionDetailsPanelProps {
  subscriptionId: string;
  onClose: () => void;
}

export function SubscriptionDetailsPanel({
  subscriptionId,
  onClose,
}: SubscriptionDetailsPanelProps) {
  const { classes } = useStyles();

  const location = useLocation();
  const navigate = useNavigate();

  const [isPaymentHistoryPanelOpen, setIsPaymentHistoryPanelOpen] =
    useState(false);

  const storeSettings = useStoreSettings();
  const subscription = useSubscription(subscriptionId);
  const subscriptionInvoiceItems = useSubscriptionInvoiceItems(subscriptionId, {
    enabled: isPaymentHistoryPanelOpen,
  });

  const openModal = useOpenModal();

  const isUsageBased =
    subscription.data?.product?.pricing_model ===
    ProductPricingModel.UsageBased;

  const isLoading = subscription.isLoading || storeSettings.isLoading;

  const onOpenCancellationsAndReturnsModal = useCallback(() => {
    openModal<TextEditorModalProps['data']>('TextEditorModal', {
      title: 'Cancellations and Returns Policy',
      editorContent: storeSettings.data?.cancellations_and_returns_policy,
      headerIcon: <StorePolicyIcon />,
      isEditable: false,
    });
  }, [openModal, storeSettings.data?.cancellations_and_returns_policy]);

  const onCancelSubscription = async () => {
    if (!subscription.data) return;

    openModal<SubscriptionCancellationFlowModalProps['data']>(
      'SubscriptionCancellationFlowModal',
      { subscription: subscription.data }
    );
  };

  const openSubscriptionRenewalModal = useCallback(() => {
    if (subscription.data?.status !== 'pending_cancellation') {
      return;
    }

    openModal<SubscriptionRenewalModalProps['data']>(
      'SubscriptionRenewalModal',
      { subscriptionId, onSuccess: onClose }
    );
  }, [onClose, openModal, subscription.data?.status, subscriptionId]);

  useEffect(
    function openModalAccordingToSearchParams() {
      if (!subscription.isFetched) return;

      const searchParams = new URLSearchParams(location.search);
      const modalToOpen = searchParams.get('modal');

      if (!modalToOpen) return;

      if (modalToOpen === 'renew') {
        openSubscriptionRenewalModal();
      } else if (modalToOpen === 'cancellations-policy') {
        onOpenCancellationsAndReturnsModal();
      }

      searchParams.delete('modal');

      navigate({ search: searchParams.toString() }, { replace: true });
    },
    [
      navigate,
      location.pathname,
      location.search,
      onOpenCancellationsAndReturnsModal,
      openSubscriptionRenewalModal,
      subscription.isFetched,
    ]
  );

  if (isLoading || !subscription.data) {
    return <LoadingOverlay visible />;
  }

  return (
    <>
      <PaymentsHistoryPanel
        invoiceItems={subscriptionInvoiceItems.data || []}
        isFetching={subscriptionInvoiceItems.isFetching}
        isOpen={isPaymentHistoryPanelOpen}
        currencyCode={subscription.data?.currency}
        usageBasedDisplayName={
          subscription.data?.product?.usage_based_display_name || ''
        }
        usageBasedUnitName={
          subscription.data?.product?.usage_based_unit_name || ''
        }
        usageBasedPricePerUnit={
          subscription.data.usage_based?.usage_based_price_in_scu
        }
        onClose={() => setIsPaymentHistoryPanelOpen(false)}
        tenantType={TenantType.Organization}
      />

      <DetailsPanel
        enableScrollUiChanges={!isUsageBased}
        className={classes.detailsPanel}
      >
        <DetailsPanel.Header
          onClose={onClose}
          spacing="md"
          className={classes.header}
        >
          <Stack align="center">
            <ProductImage
              src={subscription.data?.product?.image_url}
              width={95}
              radius="md"
            />

            <DetailsPanel.Title>
              {subscription.data?.product?.name}
            </DetailsPanel.Title>

            <SubscriptionStatusBadge status={subscription.data?.status} />

            {subscription.data?.status === 'pending_cancellation' && (
              <TimeLeftUntilCanceled
                canceledScheduledAt={
                  subscription.data?.canceled_scheduled_at as string
                }
              />
            )}
          </Stack>

          <SubscriptionDetailsPanelActions
            subscription={subscription.data}
            onCancelSubscription={onCancelSubscription}
            onRenewSubscription={openSubscriptionRenewalModal}
          />
        </DetailsPanel.Header>

        {isUsageBased ? (
          <UsageBasedSubscriptionDetailsPanelContent
            subscription={subscription.data}
            isPaymentHistoryPanelOpen={isPaymentHistoryPanelOpen}
            togglePaymentHistoryPanel={() =>
              setIsPaymentHistoryPanelOpen((prev) => !prev)
            }
          />
        ) : (
          <DetailsPanel.Body>
            <OverviewTab subscription={subscription.data} />
          </DetailsPanel.Body>
        )}
      </DetailsPanel>
    </>
  );
}

const useStyles = createStyles((theme) => ({
  detailsPanel: {
    position: 'relative',
    zIndex: 1,
    backgroundColor: theme.white,
  },
  header: {
    // For the actions menu to render above body content.
    zIndex: 1,
  },
}));

dayjs.extend(relativeTime);

interface TimeLeftUntilCanceledProps {
  canceledScheduledAt: string;
}

function TimeLeftUntilCanceled({
  canceledScheduledAt,
}: TimeLeftUntilCanceledProps) {
  const timeLeft = dayjs().to(canceledScheduledAt);

  return (
    <Text data-testid="expires-value" color="amber.8">
      Expires {timeLeft} ({dayjs(canceledScheduledAt).format('MMM DD, YYYY')})
    </Text>
  );
}
