import {
  Alert,
  Anchor,
  Button,
  createStyles,
  Group,
  Modal,
  ModalBaseStylesNames,
  Stack,
  Styles,
  Text,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React from 'react';

import { useCreateTicket, useDevice } from '@portals/api/organizations';
import { ModalBody, ModalFooter } from '@portals/core';
import { DeviceAvatar, ModalProps } from '@portals/framework';
import { ReactComponent as InfoCircle } from '@portals/icons/linear/info-circle.svg';
import { TicketType } from '@portals/types';

export interface CreateTicketModalProps
  extends ModalProps<{
    deviceId: string;
    description?: TicketType['description'];
  }> {}

export function CreateTicketModal({
  closeMe,
  data: { deviceId, description },
}: CreateTicketModalProps) {
  const { classes } = useStyles();
  const createTicket = useCreateTicket();

  const device = useDevice(deviceId);

  const form = useForm({
    initialValues: {
      title: '',
      description: description || '',
    },
  });

  const isSubmitDisabled = !form.isValid();

  const handleSubmit = (values: typeof form.values) => {
    createTicket.mutate(
      {
        deviceId,
        title: values.title,
        description: values.description,
      },
      { onSuccess: closeMe }
    );
  };

  return (
    <Modal
      opened
      onClose={closeMe}
      padding="xxl"
      title="Request device support"
      styles={modalStyles}
    >
      <ModalBody>
        <Stack spacing="xl">
          <Group spacing="xl" align="flex-start">
            <DeviceAvatar
              src={device.data?.image_url}
              icon={device.data?.model_settings?.icon}
              size={97}
            />

            <Stack spacing="xs">
              <Title order={6} fw={400} color="blue_gray.6">
                {device.data?.partner.vendor}
              </Title>
              <Title order={4} fw={400}>
                {device.data?.partner.model}
              </Title>
            </Stack>
          </Group>

          <div>
            <Text color="blue_gray.6">
              Tickets are used to send support requests to your MSP or
              manufacturer.
            </Text>
            <Anchor href="https://docs.xyte.io/docs/tickets" target="_blank">
              Learn more
            </Anchor>
          </div>
          <form
            className={classes.createTicketForm}
            onSubmit={form.onSubmit(handleSubmit)}
          >
            <TextInput
              {...form.getInputProps('title')}
              label="Title"
              placeholder="General description"
              required
              data-testid="request-device-support-title"
            />

            <Textarea
              {...form.getInputProps('description')}
              label="Description"
              placeholder="Detailed description of the problem"
              required
              maxRows={7}
              minRows={7}
              data-testid="request-device-support-description"
            />
            <Alert radius="md" icon={<InfoCircle width={18} height={18} />}>
              Creating a ticket allows the service provider / manufacturer full
              access to this device, includes: Access device information,
              Perform commands (reboot, firmware upgrade, etc)
            </Alert>
            {createTicket.error ? (
              <Text color="red">{createTicket.error?.error}</Text>
            ) : null}
            <ModalFooter grow className={classes.buttonsContainer}>
              <Button
                variant="default"
                onClick={closeMe}
                data-testid="close-incident-button"
              >
                Cancel
              </Button>
              <Button
                data-testid="request-device-support-escalate-button"
                disabled={isSubmitDisabled}
                loading={createTicket.isLoading}
                type="submit"
              >
                Escalate
              </Button>
            </ModalFooter>
          </form>
        </Stack>
      </ModalBody>
    </Modal>
  );
}

const useStyles = createStyles((theme) => ({
  createTicketForm: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.md,
  },
  buttonsContainer: {
    marginInline: `-${theme.spacing.xxl}`,
    paddingInline: theme.spacing.xxl,
    borderTop: `1px solid ${theme.colors.gray[2]}`,
    marginTop: theme.spacing.md,
  },
}));

const modalStyles: Styles<ModalBaseStylesNames> = (theme) => ({
  header: {
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
    marginBottom: theme.spacing.xxl,
  },
});
