import { Badge, BadgeProps, createStyles, MantineColor } from '@mantine/core';
import React from 'react';

interface BadgeWithDotProps extends BadgeProps {}

export function BadgeWithDot({
  children,
  color = 'primary',
  ...badgeProps
}: BadgeWithDotProps) {
  const { classes } = useStyles(color);

  return (
    <Badge
      color={color}
      leftSection={<div className={classes.dot} />}
      classNames={{ inner: classes.inner }}
      {...badgeProps}
    >
      {children}
    </Badge>
  );
}

const useStyles = createStyles((theme, color: MantineColor) => ({
  dot: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: theme.colors[color][4],
  },
  root: {
    backgroundColor: theme.fn.themeColor(color, 0),
  },
  inner: {
    color: theme.colors.gray[9],
    fontWeight: 400,
    fontSize: theme.fontSizes.xs,
  },
}));
