export const STORE_LISTINGS_API_URL = 'ui/organization/store_listings';

export const storeListingsQueryKeys = {
  all: [STORE_LISTINGS_API_URL],
  details: () => [...storeListingsQueryKeys.all, 'details'],
  detail: (storeListingId: string) => [
    ...storeListingsQueryKeys.details(),
    storeListingId,
  ],
  availableStores: () => [...storeListingsQueryKeys.all, 'available_stores'],
};
