import { Center, createStyles, Group, Space } from '@mantine/core';
import React from 'react';

interface StoreCategoriesNavbarProps {
  categories: Array<string>;
  selectedCategory: string | null;
  onSelectCategory: (category: string | null) => void;
}

export function StoreCategoriesNavbar({
  categories,
  selectedCategory,
  onSelectCategory,
}: StoreCategoriesNavbarProps) {
  const { classes, cx } = useStyles();

  return (
    <div className={classes.container}>
      <Group spacing="xs" noWrap className={classes.categoriesContainer}>
        <Space w="md" />

        <Center
          onClick={() => onSelectCategory(null)}
          className={cx(classes.categoryBadge, {
            active: selectedCategory === null,
          })}
        >
          all
        </Center>

        {categories.map((category, index) => (
          <Center
            key={index}
            onClick={() => onSelectCategory(category)}
            className={cx(classes.categoryBadge, {
              active: selectedCategory === category,
            })}
          >
            {category}
          </Center>
        ))}

        <Space w="md" />
      </Group>
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    overflow: 'hidden',
  },
  categoriesContainer: {
    overflow: 'auto',
    paddingBlock: theme.spacing.xl,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },
  categoryBadge: {
    height: 30,
    paddingInline: 14,
    border: `1px solid ${theme.colors.gray[3]}`,
    borderRadius: theme.radius.lg,
    color: theme.colors.gray[9],
    backgroundColor: theme.white,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',

    '&.active': {
      color: theme.white,
      backgroundColor: theme.colors.gray[9],
    },
  },
}));
