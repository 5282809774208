import { MantineColor, Progress, Stack, StackProps, Text } from '@mantine/core';
import moment from 'moment';
import React from 'react';

interface TimeProgressBarProps extends StackProps {
  startDate: string;
  endDate: string;
}

export function TimeProgressBar({
  startDate,
  endDate,
  ...stackProps
}: TimeProgressBarProps) {
  const timeElapsed = percentageTimeElapsed(startDate, endDate);
  const timeLeftAsString = moment().to(moment(endDate));
  const color = getProgressColor(timeElapsed);
  const prefix = timeElapsed < 100 ? 'Expires' : 'Expired';

  return (
    <Stack spacing="xs" {...stackProps}>
      <Progress
        color={color}
        sx={{ alignSelf: 'stretch' }}
        value={timeElapsed}
      />

      <Text size="sm">
        {prefix}&nbsp;{timeLeftAsString}
      </Text>
    </Stack>
  );
}

function percentageTimeElapsed(startDate: string, endDate: string) {
  const currentTimestamp = Date.now();
  const startTimestamp = new Date(startDate).getTime();
  const endTimestamp = new Date(endDate).getTime();

  if (currentTimestamp > endTimestamp) {
    return 100;
  }

  const totalDuration = endTimestamp - startTimestamp;
  const durationPassed = endTimestamp - currentTimestamp;

  return 100 - (durationPassed / totalDuration) * 100;
}

function getProgressColor(value: number): MantineColor {
  return value < 100 ? 'primary' : 'red';
}
