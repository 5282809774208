import classNames from 'classnames';
import { toString } from 'lodash/fp';
import React from 'react';
import { useDrop } from 'react-dnd';
import styled from 'styled-components';

import { DeviceType } from '@portals/types';
import { getStyledThemeColor } from '@portals/utils';

import { TreeNode, TreeNodeProps } from './TreeNode';
import { canEdit, canView } from '../../../../../lib/access';

export function DroppableTreeNode({ node, ...props }: TreeNodeProps) {
  const [{ deviceHover, deviceDroppable }, dropRef] = useDrop<
    {
      type: string;
      device: DeviceType;
    },
    any,
    any
  >(() => ({
    accept: 'device',
    drop: () => node,
    canDrop: ({ device }) =>
      toString(device?.space_id) !== toString(node.id) && canEdit(node),
    collect: (monitor) => ({
      deviceHover: monitor.isOver() && monitor.canDrop() && canView(node),
      deviceDroppable: monitor.canDrop() && canEdit(node),
    }),
  }));

  const nodeClass = classNames('tree-item-base', {
    'device-hover': deviceHover,
    'device-droppable': deviceDroppable,
  });

  return (
    <Container className={nodeClass} ref={dropRef}>
      <TreeNode {...props} node={node} />

      <div className="drop-effect" />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  position: relative;

  .drop-effect {
    position: absolute;
    top: 0;
    left: 17px;
    width: 100%;
    height: 100%;
    transform: translateX(-25px) translateY(-2px);
  }

  &.device-droppable {
    .drop-effect {
      padding: 0;
      margin-top: 1px;
      border: 1px dashed ${getStyledThemeColor('secondary')};
    }
  }

  &.device-hover {
    .drop-effect {
      background-color: ${getStyledThemeColor('primary')};
    }
  }
`;
