import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError } from '@portals/redux/actions/toastr';
import { CommandType } from '@portals/types';

import { devicesQueryKeys, getDeviceApiUrl } from './devices.constants';
import { MutationOptions } from '../../types/common';
import { fetchApiRequest, useRequestOptions } from '../../utils/common';

export function getDeviceReplaceApiUrl(deviceId: string) {
  return `${getDeviceApiUrl(deviceId)}/replace`;
}

export function useReplaceDevice(
  mutationOptions: MutationOptions<
    CommandType,
    { error: string },
    { deviceId: string; sn: string; notes?: string }
  > = {}
) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { url: baseUrl, options } = useRequestOptions({
    url: '',
    method: 'POST',
  });

  return useMutation({
    mutationFn: ({ deviceId, sn, notes }) =>
      fetchApiRequest(`${baseUrl}/${getDeviceReplaceApiUrl(deviceId)}`, {
        body: JSON.stringify({ sn, notes }),
        ...options,
      }),
    onSuccess: (_, { deviceId }) => {
      queryClient.invalidateQueries(devicesQueryKeys.details(deviceId));
    },
    onError: ({ error }: any) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useReplaceDevice',
      baseUrl: getDeviceReplaceApiUrl(':id'),
      method: 'POST',
    },
    ...mutationOptions,
  });
}

export function getDeviceCancelReplacementApiUrl(deviceId: string) {
  return `${getDeviceApiUrl(deviceId)}/cancel_replacement`;
}

export function useCancelDeviceReplacement(
  mutationOptions: MutationOptions<
    CommandType,
    { error: string },
    { deviceId: string }
  > = {}
) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { url: baseUrl, options } = useRequestOptions({
    url: '',
    method: 'POST',
  });

  return useMutation({
    mutationFn: ({ deviceId }) =>
      fetchApiRequest(
        `${baseUrl}/${getDeviceCancelReplacementApiUrl(deviceId)}`,
        options
      ),
    onSuccess: (_, { deviceId }) => {
      queryClient.invalidateQueries(devicesQueryKeys.details(deviceId));
    },
    onError: ({ error }: any) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useCancelDeviceReplacement',
      baseUrl: getDeviceCancelReplacementApiUrl(':id'),
      method: 'POST',
    },
    ...mutationOptions,
  });
}

export function getDeviceApproveReplacementApiUrl(deviceId: string) {
  return `${getDeviceApiUrl(deviceId)}/approve_replacement`;
}

export function useApproveDeviceReplacement(
  mutationOptions: MutationOptions<
    CommandType,
    { error: string },
    { deviceId: string }
  > = {}
) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { url: baseUrl, options } = useRequestOptions({
    url: '',
    method: 'POST',
  });

  return useMutation({
    mutationFn: ({ deviceId }) =>
      fetchApiRequest(
        `${baseUrl}/${getDeviceApproveReplacementApiUrl(deviceId)}`,
        options
      ),
    onSuccess: (_, { deviceId }) => {
      queryClient.invalidateQueries(devicesQueryKeys.details(deviceId));
    },
    onError: ({ error }: any) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useApproveDeviceReplacement',
      baseUrl: getDeviceApproveReplacementApiUrl(':id'),
      method: 'POST',
    },
    ...mutationOptions,
  });
}
