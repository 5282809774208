import { useMantineTheme } from '@mantine/core';
import classNames from 'classnames';
import React, { FC } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import styled from 'styled-components';

import { DeviceType } from '@portals/api/organizations';
import { ReactComponent as Health } from '@portals/icons/linear/health.svg';
import { ReactComponent as Link } from '@portals/icons/linear/link.svg';
import { ReactComponent as Snooze } from '@portals/icons/linear/snooze.svg';
import { getStyledThemeColor } from '@portals/utils';

import { DeviceCounters } from './DeviceCounters';

export enum StatusType {
  Incidents = 'incidents',
  Snoozed = 'snoozed',
  Child = 'parent',
}

export const STATUS_ICONS_MAP = {
  [StatusType.Child]: (device: DeviceType) => (
    <ChildIcon deviceId={device.id} key={StatusType.Child} />
  ),
  [StatusType.Snoozed]: (device) => (
    <SnoozeIcon deviceId={device.id} key={StatusType.Snoozed} />
  ),
};

const ChildIcon: FC<{ deviceId: string }> = ({ deviceId }) => (
  <DeviceStatusIconWrapper>
    <Link
      width={15}
      height={15}
      color="purple"
      style={{ marginRight: 4, marginLeft: 4 }}
      id={`child-${deviceId}`}
    />

    <UncontrolledTooltip placement="bottom" target={`child-${deviceId}`}>
      Child device
    </UncontrolledTooltip>
  </DeviceStatusIconWrapper>
);

const SnoozeIcon: FC<{ deviceId: string }> = ({ deviceId }) => (
  <DeviceStatusIconWrapper>
    <Snooze
      width={15}
      height={15}
      color="blue"
      style={{ marginRight: 4, marginLeft: 4 }}
      id={`snoozed-${deviceId}`}
    />

    <UncontrolledTooltip placement="bottom" target={`snoozed-${deviceId}`}>
      Device is snoozed
    </UncontrolledTooltip>
  </DeviceStatusIconWrapper>
);

export const IncidentsIcon: FC<{ device: DeviceType }> = ({ device }) => (
  <DeviceStatusIconWrapper id={`incidents-${device.id}`}>
    <DeviceCounters device={device} />
  </DeviceStatusIconWrapper>
);

const DeviceStatus: FC<{ device: DeviceType }> = ({ device }) => {
  const theme = useMantineTheme();

  return (
    <DeviceState id={`device-status-${device.id}`}>
      {device.state?.status || 'Unknown'}

      <Health
        width={15}
        height={15}
        style={{ marginRight: theme.spacing.xs }}
      />

      <UncontrolledTooltip
        placement="bottom"
        target={`device-status-${device.id}`}
      >
        Device status
      </UncontrolledTooltip>
    </DeviceState>
  );
};

export const DeviceStatusIcons: FC<{
  statusIcons: Array<StatusType>;
  device: DeviceType;
  deviceState: string;
}> = ({ statusIcons, device, deviceState }) => (
  <Container className={classNames('device-status-icons', deviceState)}>
    <IconsWrapper>
      <IncidentsIcon device={device} key={StatusType.Incidents} />

      {statusIcons.map((statusIcon) => STATUS_ICONS_MAP[statusIcon](device))}
    </IconsWrapper>

    <DeviceStatus device={device} />
  </Container>
);

const Container = styled.div`
  display: flex;
  background-color: ${getStyledThemeColor('gray100')};
  border: 1px solid ${getStyledThemeColor('gray300')};
  box-shadow: 0 0.2rem 0.2rem rgb(0 0 0 / 5%);
  height: 30px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  width: 100%;
  justify-content: space-between;

  .pause-icon {
    color: ${getStyledThemeColor('info')};
  }

  &.warning {
    border-color: ${getStyledThemeColor('warning')};
  }

  &.error {
    border-color: ${getStyledThemeColor('danger')};
  }

  &.unknown {
    border-color: ${getStyledThemeColor('gray600')};
  }

  &.success {
    border-color: ${getStyledThemeColor('green')};
  }
`;

const IconsWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const DeviceState = styled.div`
  flex-shrink: 0;
  flex-direction: row-reverse;
  display: flex;
  align-items: center;
  padding-right: 6px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
`;

const DeviceStatusIconWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 3px;
  height: 100%;

  &:not(:last-of-type) {
    border-right: 1px solid ${getStyledThemeColor('gray300')};
    margin-right: 2px;
  }

  .device-incidents {
    display: flex;
    margin-bottom: 0;
    height: 100%;
    align-items: center;
    padding: 0 3px;

    .badge {
      padding: 0;
      border-radius: 50%;
      font-size: 12px;
      width: 20px;
      height: 20px;
      color: ${getStyledThemeColor('black')};
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 !important;

      &.badge-warning,
      &.badge-danger {
        color: ${getStyledThemeColor('white')};
      }

      &:nth-of-type(2) {
        margin-left: 5px !important;
      }
    }
  }
`;
