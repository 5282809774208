import {
  Button,
  createStyles,
  Divider,
  Group,
  Stack,
  Text,
} from '@mantine/core';
import React from 'react';

import {
  OrganizationInvoiceType,
  useInvoiceRetryCharge,
} from '@portals/api/organizations';
import { OrganizationInvoicePaymentStatusBadge } from '@portals/framework';
import { useOpenModal } from '@portals/redux';
import { formatCurrency, formatDateTime } from '@portals/utils';

interface InvoicePaymentProps {
  invoice: OrganizationInvoiceType;
}

export function InvoicePayment({ invoice }: InvoicePaymentProps) {
  const { classes } = useStyles();
  const invoiceRetryCharge = useInvoiceRetryCharge();
  const openModal = useOpenModal();

  const billingAttemptDate = formatDateTime(invoice?.last_charge_attempt_at);

  return (
    <Stack className={classes.container}>
      <Group w="100%" position="apart" align="start">
        <Stack spacing={2}>
          <Text color="gray.9" weight={600}>
            Invoice {invoice.order_short_id} ({invoice.items_count} items)
          </Text>
          <Text color="gray.7">Billing attempt on {billingAttemptDate}</Text>
        </Stack>

        <OrganizationInvoicePaymentStatusBadge status={invoice.status} />
      </Group>

      <Divider />

      <Group position="apart">
        <Group spacing="xs">
          <Text color="gray.7">Total charge</Text>
          <Text size="md" weight={600} color="gray.9">
            {formatCurrency(invoice.amount_in_scu, invoice.currency)}
          </Text>
        </Group>

        {invoice.status === 'failed' ? (
          <Button
            size="md"
            onClick={() =>
              invoiceRetryCharge.mutate(
                { invoiceId: invoice.id },
                {
                  onSuccess: () => openModal('RetryChargeSuccessModal'),
                  onError: () => {
                    openModal('RetryChargeFailureModal', {
                      failureReason: invoice.failure_reason,
                    });
                  },
                }
              )
            }
          >
            Pay now
          </Button>
        ) : null}
      </Group>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    borderRadius: theme.radius.md,
    padding: theme.spacing.md,
    border: `1px solid ${theme.colors.gray[3]}`,
    boxShadow: '0px 1px 5px 0px rgba(38, 50, 56, 0.13)',
  },
}));
