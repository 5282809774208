import { NewWidgetType } from '@portals/device-widgets';

import { useApiQuery } from '../../hooks';
import {
  deviceModelsQueryKeys,
  getDeviceModelApiUrl,
} from './device-models.constants';

function getApiUrl(modelId: string) {
  return `${getDeviceModelApiUrl(modelId)}/new_device_widgets`;
}

export function useNewDeviceWidgets(modelId: string) {
  return useApiQuery<Array<NewWidgetType>>(
    getApiUrl(modelId),
    deviceModelsQueryKeys.widgets.new(modelId),
    {
      enabled: !!modelId,
    }
  );
}
