import { Avatar } from '@mantine/core';
import React, { useMemo } from 'react';

import { GroupType } from '@portals/api/partners';

import { GROUP_ICONS, GroupIconNames } from './group-icons';

interface GroupIconProps {
  iconName: GroupType['icon_name'];
}

export function GroupIcon({ iconName }: GroupIconProps) {
  const icon = useMemo(() => {
    if (!iconName || !(iconName in GROUP_ICONS)) {
      return GROUP_ICONS.userOctagon;
    }

    return GROUP_ICONS[iconName as GroupIconNames];
  }, [iconName]);

  return (
    <Avatar radius="md" color={icon.color}>
      {icon.component}
    </Avatar>
  );
}
