import { ActionIcon, Box } from '@mantine/core';
import React, { FC } from 'react';

import { ReactComponent as RowMenuIcon } from '../../assets/row-menu.svg';

interface RowMenuToggleProps {
  isOpen: boolean;
}

const RowMenuToggle: FC<RowMenuToggleProps> = ({ isOpen }) => (
  <Box
    sx={(theme) => ({
      position: 'absolute',
      transform: 'translateX(-100%) translateY(-50%)',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 10,
      justifyContent: 'center',
      background: theme.fn.linearGradient(
        90,
        'transparent',
        'white',
        'white',
        'white'
      ),
      width: 50,
      height: 56,
    })}
  >
    <ActionIcon
      variant="transparent"
      data-testid="table-row-menu-toggle-button"
      sx={(theme) => ({
        svg: {
          path: {
            fill: isOpen ? theme.colors.gray[9] : theme.colors.gray[6],
          },
        },
      })}
    >
      <RowMenuIcon />
    </ActionIcon>
  </Box>
);

export default RowMenuToggle;
