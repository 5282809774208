import { DeviceType } from '@portals/api/organizations';

export enum DeleteDeviceModalType {
  SingleIndependentDevice = 'SingleIndependentDevice',
  MultipleIndependentDevices = 'MultipleIndependentDevices',
  SingleParentDevice = 'SingleParentDevice',
  MultipleParentDevices = 'MultipleParentDevices',
  ParentAndIndependentDevices = 'ParentAndIndependentDevices',
}

export const DELETE_DEVICE_MODAL_SETTINGS = {
  [DeleteDeviceModalType.SingleIndependentDevice]: {
    initialIsWithChildren: false,
    title: (devices: DeviceType[]) => `Delete ${devices[0].name}?`,
    submitButtonLabel: () => 'Delete device',
  },
  [DeleteDeviceModalType.SingleParentDevice]: {
    initialIsWithChildren: true,
    title: (devices: DeviceType[]) => `Delete ${devices[0].name}?`,
    submitButtonLabel: () => 'Delete device',
  },
  [DeleteDeviceModalType.MultipleIndependentDevices]: {
    initialIsWithChildren: false,
    title: (devices: DeviceType[]) => `Delete ${devices.length} devices?`,
    submitButtonLabel: () => 'Delete devices',
  },
  [DeleteDeviceModalType.MultipleParentDevices]: {
    initialIsWithChildren: true,
    title: () => `Devices delete options`,
    submitButtonLabel: () => 'Delete devices',
  },
  [DeleteDeviceModalType.ParentAndIndependentDevices]: {
    initialIsWithChildren: true,
    title: () => `Devices delete options`,
    submitButtonLabel: () => 'Delete devices',
  },
};
