import {
  ActionIcon,
  Popover,
  PopoverProps,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import React, { useState } from 'react';

import {
  useSendPartnerSupportEmail,
  UseSendSupportEmailParams,
} from '@portals/api/organizations';
import { ContactSupportModalProps } from '@portals/framework/modals';
import { ReactComponent as QuestionCircleIcon } from '@portals/icons/linear/question-circle.svg';
import { useOpenModal } from '@portals/redux';

import { SuccessCenterItems } from './SuccessCenterItems';

export function SuccessCenter() {
  const [isSuccessCenterMenuOpen, setIsSuccessCenterMenuOpen] = useState(false);

  const theme = useMantineTheme();
  const openModal = useOpenModal();

  const sendPartnerSupportEmail = useSendPartnerSupportEmail();

  const onSendSupportEmail = (values: UseSendSupportEmailParams) => {
    sendPartnerSupportEmail.mutate(
      {
        title: values.title,
        message: values.message,
      },
      {
        onSuccess: () => {
          openModal('ContactSupportSuccessModal');
        },
      }
    );
  };

  const onContactSupport = () => {
    setIsSuccessCenterMenuOpen(false);

    openModal<ContactSupportModalProps['data']>('ContactSupportModal', {
      onSubmit: onSendSupportEmail,
    });
  };

  return (
    <Popover
      position="bottom-end"
      offset={20}
      radius="lg"
      shadow="md"
      width={400}
      opened={isSuccessCenterMenuOpen}
      onChange={(isOpen) => setIsSuccessCenterMenuOpen(isOpen)}
      styles={popoverStyles}
    >
      <Popover.Target>
        <Tooltip label="Success center">
          <ActionIcon
            size="lg"
            variant="transparent"
            onClick={() => setIsSuccessCenterMenuOpen((isOpened) => !isOpened)}
            data-testid="success-center-button"
          >
            <QuestionCircleIcon
              width={24}
              height={24}
              color={
                isSuccessCenterMenuOpen
                  ? theme.colors.indigo_accent[2]
                  : theme.colors.blue_gray[6]
              }
            />
          </ActionIcon>
        </Tooltip>
      </Popover.Target>

      <Popover.Dropdown px="xl" h={400}>
        <SuccessCenterItems
          onCloseSuccessCenterMenu={() => setIsSuccessCenterMenuOpen(false)}
          onContactSupport={onContactSupport}
        />
      </Popover.Dropdown>
    </Popover>
  );
}

const popoverStyles: PopoverProps['styles'] = (theme) => ({
  dropdown: {
    overflow: 'hidden',
    paddingBottom: theme.spacing.xl,
  },
});
