import { MantineTheme } from '@mantine/core';

function createColorShades(baseColorHex: string, amount: number) {
  return (
    '#' +
    baseColorHex
      .replace(/^#/, '')
      .replace(/../g, (color: string) =>
        (
          '0' +
          Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
        ).slice(-2)
      )
  );
}

export function generateRelatedColors(
  baseColorHex: string
): MantineTheme['colors']['string'] {
  const relatedColors = Array.from({ length: 9 }, (_, i) => {
    const amount = Math.floor((255 / 10) * (i + 1));

    return createColorShades(baseColorHex, amount);
  });

  relatedColors.reverse().push(baseColorHex);

  return relatedColors as MantineTheme['colors']['string'];
}
