import { Badge, Box, Group, Stack, Text, Tooltip } from '@mantine/core';
import React, { useMemo } from 'react';

import {
  DeviceIncidentViewEnum,
  IncidentPriorityEnum,
  useDevicesBySpaceId,
} from '@portals/api/organizations';
import { DashboardLayoutListItem } from '@portals/types';

import { useDeviceIncidentRateWidgetCountAndPercentage } from './device-incident-rate-widget.hooks';
import { countIncidentsOnDeviceInSpace, getDevicesCount } from './utils';
import { useOverviewContext } from '../../../overview.context';
import { useCurrentSpace } from '../../../overview.hooks';
import { WidgetType } from '../../../overview.types';
import { WidgetCardWrapper } from '../common/WidgetCardWrapper';

interface DeviceIncidentRateWidgetExtraParams {
  title: string;
  incidentPriorities: IncidentPriorityEnum[];
  deviceIncidentView: string;
}

export function DeviceIncidentRateDashboardWidgetWrapper({
  widgetId,
}: {
  widgetId: string;
}) {
  const { dashboard } = useOverviewContext();

  return dashboard.list.map((item) => {
    //As there is a type issue with the item, we need to cast it to the correct type
    const widget = item as DashboardLayoutListItem<
      WidgetType,
      DeviceIncidentRateWidgetExtraParams
    >;

    return item.id === 'device_incidents_rate' && item.i === widgetId ? (
      <DeviceIncidentRateDashboardWidget key={item.i} item={widget} />
    ) : null;
  });
}

export function DeviceIncidentRateDashboardWidget({
  item,
}: {
  item: DashboardLayoutListItem<
    WidgetType,
    DeviceIncidentRateWidgetExtraParams
  >;
}) {
  const space = useCurrentSpace();

  const { dataLevel } = useOverviewContext();

  const title = item?.extraParams?.title;
  const incidentPriorities = item?.extraParams?.incidentPriorities;
  const deviceIncidentView = item?.extraParams?.deviceIncidentView;

  const devices = useDevicesBySpaceId({ spaceId: space?.id });

  const devicesCount = useMemo(() => {
    return getDevicesCount(space, dataLevel);
  }, [space, dataLevel]);

  const devicesWithIncidentsCount = useMemo(() => {
    if (!incidentPriorities || devicesCount === 0) {
      return 0;
    }

    return countIncidentsOnDeviceInSpace(
      incidentPriorities,
      space.id,
      devices.data,
      dataLevel
    );
  }, [devices, incidentPriorities, devicesCount, dataLevel, space]);

  const getPrioritiesLabel = () => {
    if (
      incidentPriorities.length === Object.keys(IncidentPriorityEnum).length
    ) {
      return 'All Priorities';
    } else if (incidentPriorities.length === 1) {
      return incidentPriorities[0];
    }

    return `${incidentPriorities.length} priorities`;
  };

  const {
    devicesWithoutIncidentsCount,
    percentageOfDevicesWithoutIncidents,
    percentageOfDevicesWithIncidents,
  } = useDeviceIncidentRateWidgetCountAndPercentage({
    devicesWithIncidentsCount,
    devicesCount,
  });

  if (!title || !incidentPriorities || !deviceIncidentView) {
    return null;
  }

  return (
    <WidgetCardWrapper
      paperProps={{
        p: 'xl',
      }}
      stackProps={{ spacing: 'xs', p: 0 }}
      header={
        <Text size="md" color="gray.5">
          {title}
        </Text>
      }
    >
      <Stack spacing="xs" justify="center" pos="relative">
        <Tooltip
          label={
            <Group spacing={2}>
              <Text>Incident priorities:</Text>

              <Text transform="capitalize">
                {incidentPriorities.join(', ')}
              </Text>
            </Group>
          }
        >
          <Badge color="gray" variant="outline" px="md" py="sm" w="fit-content">
            {getPrioritiesLabel()}
          </Badge>
        </Tooltip>

        <Box>
          <Group spacing="xs" align="baseline">
            <Text size={28} color="blue_gray.9" weight={700}>
              {deviceIncidentView ===
              DeviceIncidentViewEnum.DevicesWithIncidents
                ? percentageOfDevicesWithIncidents
                : percentageOfDevicesWithoutIncidents}
              %
            </Text>

            <Text size="sm" color="blue_gray.9">
              (
              {`${
                deviceIncidentView ===
                DeviceIncidentViewEnum.DevicesWithIncidents
                  ? devicesWithIncidentsCount
                  : devicesWithoutIncidentsCount
              } of ${devicesCount}`}
              )
            </Text>
          </Group>

          <Text size="sm" color="blue_gray.9">
            {deviceIncidentView === DeviceIncidentViewEnum.DevicesWithIncidents
              ? 'devices with incidents'
              : 'incident-free devices'}
          </Text>
        </Box>
      </Stack>
    </WidgetCardWrapper>
  );
}
