import {
  Badge,
  Box,
  Center,
  Paper,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import React, { useMemo } from 'react';
import { Tooltip, Treemap } from 'recharts';

import { DevicesPerSpaceViewEnum, SpaceType } from '@portals/api/organizations';

import { DevicesPerSpaceEmptyState } from './DevicesPerSpaceEmptyState';
import { TreemapContent, TreemapTooltip } from './treemap-components';
import { getChartData } from './utils';
import { useSpaceTree } from '../../../../../../lib/spaces';
import { DataLevelEnum } from '../../../overview.types';

interface DevicesPerSpaceWidgetProps {
  space: SpaceType;
  widgetTitle: string;
  devicesPerSpaceView: DevicesPerSpaceViewEnum;
  dataLevel: DataLevelEnum;
  isDashboardPreview?: boolean;
}

export function DevicesPerSpaceWidget({
  space,
  devicesPerSpaceView,
  dataLevel,
  widgetTitle,
  isDashboardPreview = false,
}: DevicesPerSpaceWidgetProps) {
  const theme = useMantineTheme();

  const spaceTree = useSpaceTree({
    spaceId: space?.id,
    parentSpaceId: space?.parent_id ?? null,
  });

  const devicesCount = useMemo(() => {
    if (devicesPerSpaceView === DevicesPerSpaceViewEnum.AllDevices) {
      if (dataLevel === DataLevelEnum.Local) {
        return spaceTree.local_device_count ?? 0;
      } else {
        return spaceTree.device_count ?? 0;
      }
    } else {
      if (dataLevel === DataLevelEnum.Local) {
        return spaceTree.local_online_devices ?? 0;
      } else {
        return spaceTree.online_devices ?? 0;
      }
    }
  }, [dataLevel, devicesPerSpaceView, spaceTree]);

  const treemapChartData = useMemo(() => {
    let chartData;

    if (dataLevel === DataLevelEnum.Local) {
      chartData = [
        {
          name: spaceTree.name,
          size: spaceTree.local_device_count ?? 0,
        },
      ];
    } else {
      chartData = [getChartData(spaceTree, devicesPerSpaceView, dataLevel)];
    }

    if (chartData[0]?.children) {
      // In the case of a space with children (the children include the root space)
      return chartData[0].children;
    } else {
      // In the case of a space without children, but with local devices
      return chartData;
    }
  }, [dataLevel, devicesPerSpaceView, spaceTree]);

  return (
    <Paper radius="lg" p="lg" h={454}>
      <Stack spacing="xl" h="100%">
        <Stack spacing="xs">
          <Text
            data-testid="title-input-preview"
            color="gray.5"
            weight={500}
            size="md"
          >
            {widgetTitle}
          </Text>

          <Box>
            <Text
              data-testid="space-name"
              color="blue_gray.9"
              weight={600}
              size={28}
            >
              {spaceTree.name}
            </Text>
            <Text
              data-testid="device-counter"
              color="blue_gray.9"
              weight={500}
              size="md"
            >
              {devicesCount === 1
                ? `${devicesCount} device`
                : `${devicesCount} devices`}
            </Text>
          </Box>

          <Badge
            color="gray"
            variant="outline"
            size="xl"
            w="fit-content"
            styles={{
              inner: { fontWeight: 400, fontSize: theme.fontSizes.xs },
            }}
          >
            {devicesPerSpaceView === DevicesPerSpaceViewEnum.AllDevices
              ? 'All devices'
              : 'Online devices'}
          </Badge>
        </Stack>

        <Center>
          {devicesCount === 0 ? (
            <DevicesPerSpaceEmptyState />
          ) : (
            <Treemap
              width={isDashboardPreview ? 450 : 410}
              height={260}
              isAnimationActive={false}
              data={treemapChartData}
              dataKey="size"
              stroke={theme.white}
              content={<TreemapContent />}
            >
              <Tooltip content={<TreemapTooltip />} />
            </Treemap>
          )}
        </Center>
      </Stack>
    </Paper>
  );
}
