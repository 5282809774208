import {
  ActionIcon,
  Box,
  createStyles,
  Group,
  Text,
  Tooltip,
} from '@mantine/core';
import { find, noop } from 'lodash/fp';
import React, { useMemo } from 'react';

import { ColumnType, PaginatedTableProps } from '@portals/types';
import { suppressPropagation } from '@portals/utils';

import SortCaret from './Filters/SortCaret';
import { useTableInstance } from '../context';

interface THProps<TData extends object, TKeyField extends keyof TData> {
  column: ColumnType<TData> & { isAction: boolean };
  noFilters?: boolean;
  noSort?: boolean;
  columns?: PaginatedTableProps<TData, TKeyField>['columns'];
}

function TH<TData extends object, TKeyField extends keyof TData>({
  column,
  noFilters = false,
  noSort = false,
  columns,
}: THProps<TData, TKeyField>) {
  const { cx, classes } = useStyles();
  const { onClick: onSort = noop } = column?.getSortByToggleProps() || {};
  const instance = useTableInstance();
  const { allColumns } = instance;

  const isSticky = useMemo(() => {
    if (column.id === 'row-selection-column') {
      return !!find({ isSticky: true }, columns);
    }

    return column.isSticky;
  }, [column.id, column.isSticky, columns]);

  const withOffset = useMemo(() => {
    if (!isSticky || column.id === 'row-selection-column') return false;

    return !!find({ id: 'row-selection-column' }, allColumns);
  }, [isSticky, column.id, allColumns]);

  return (
    <Box
      key={column.id}
      className={cx('th', classes.container, {
        sticky: isSticky,
        'with-offset': withOffset,
      })}
      {...column.getHeaderProps()}
    >
      <Group position="apart" pr="md" sx={{ height: '100%' }}>
        <Text size="sm" weight="bold" px="md" fw={600}>
          {column.Header ? column.render('Header') : null}
        </Text>

        {!column.isAction ? (
          <Group>
            {!noFilters && column.filter ? (
              <Tooltip label="Filter" withinPortal>
                <div>{column.render('Filter')}</div>
              </Tooltip>
            ) : null}

            {!noSort && column.canSort ? (
              <Tooltip label="Sort" withinPortal>
                <ActionIcon
                  onClick={suppressPropagation(onSort)}
                  size="xs"
                  data-testid={`table-sort-button-${column.id}`}
                >
                  <SortCaret
                    direction={
                      column.isSorted
                        ? column.isSortedDesc
                          ? 'desc'
                          : 'asc'
                        : null
                    }
                  />
                </ActionIcon>
              </Tooltip>
            ) : null}
          </Group>
        ) : null}
      </Group>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    '&.sticky': {
      height: '100%',
      position: 'sticky',
      left: 0,
      backgroundColor: 'white',
      background: 'white',
      zIndex: 1,
      clipPath: 'inset(0px -15px 0px 0px)',

      [theme.fn.smallerThan('md')]: {
        position: 'static',
      },

      '&.with-offset': {
        left: 60,
      },
    },
  },
}));

export default TH;
