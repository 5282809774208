import { createStyles, Stack } from '@mantine/core';
import { compact } from 'lodash/fp';
import React, { useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useKey } from 'react-use';

import {
  OrganizationAccessibleTenant,
  PartnerAccessibleTenant,
} from '@portals/api/ui';
import { EmptySearchResults } from '@portals/core';
import { switchTenant } from '@portals/redux/actions/auth';

import { TenantItem } from './TenantItem';
import { useAppConfig } from '../../context';
import { useCommonConfig } from '../../hooks/portal-config';
import { useCurrentUserAccessibleTenants } from '../../hooks/users';

interface TransformedTenant {
  id: string;
  name: string;
  displayName: string;
  logoUrl: string | null;
  metadata: string[] | null;
}

interface TenantsListProps {
  deferredSearchTerm: string;
}

export const TenantsList = React.memo<TenantsListProps>(
  ({ deferredSearchTerm }) => {
    const { classes } = useStyles();

    const dispatch = useDispatch();

    const tenantElementRefs = useRef<Record<string, HTMLDivElement>>({});

    const { tenantType } = useAppConfig();
    const config = useCommonConfig();
    const selectedTenantId = config.data?.[tenantType]?.id;

    const [hoveredTenantId, setHoveredTenantId] = useState('');

    const accessibleTenants = useCurrentUserAccessibleTenants();

    const switchToSelectedTenant = (newTenantId: string) => {
      if (!tenantType || !accessibleTenants) return;

      if (newTenantId === selectedTenantId) {
        return;
      }

      const selectedTenant = accessibleTenants.find(
        (tenant) => tenant.id === newTenantId
      );

      if (!selectedTenant) return;

      dispatch(switchTenant(selectedTenant, tenantType, '/'));
    };

    const scrollTenantElementIntoView = (tenantId: string) => {
      const element = tenantElementRefs.current[tenantId];

      if (element) {
        element.scrollIntoView({ block: 'nearest' });
      }
    };

    const transformedTenants = useMemo<TransformedTenant[]>(() => {
      if (!accessibleTenants) return [];

      return accessibleTenants.map((tenant) => {
        return {
          id: tenant.id,
          displayName: tenant.display_name,
          logoUrl: tenant.logo_url,
          name: isPartnerTenant(tenant)
            ? tenant.name
            : tenant.partner_display_name,
          metadata: composeTenantMetadata(tenant),
        };
      });
    }, [accessibleTenants]);

    const filteredTenants = useMemo(() => {
      // Reset the `hoveredTenantId` whenever the filtered list changes,
      // to avoid selecting a hovered tenant which is not visible by clicking `Enter`
      setHoveredTenantId('');

      let result;

      if (deferredSearchTerm === '') {
        result = transformedTenants;
      } else {
        const lowerCasedSearchTerm = deferredSearchTerm.toLowerCase();

        result = transformedTenants.filter((tenant) => {
          const searchableContent =
            `${tenant.name}__@@__${tenant.displayName}`.toLowerCase();

          return searchableContent.includes(lowerCasedSearchTerm);
        });
      }

      // Return only 100 to improve rendering performance.
      return result.slice(0, 100);
    }, [deferredSearchTerm, transformedTenants]);

    const onArrowDown = () => {
      // Clear the `hoveredTenantId` if the tenants list is empty
      if (filteredTenants.length === 0) {
        setHoveredTenantId('');
        return;
      }

      let tenantIdToHover: string;

      // If the `hoveredTenantId` is not set yet, set it to the first tenant in the list
      if (!hoveredTenantId) {
        tenantIdToHover = filteredTenants[0].id;

        setHoveredTenantId(tenantIdToHover);
        scrollTenantElementIntoView(tenantIdToHover);

        return;
      }

      const currentHoveredTenantIndex = filteredTenants.findIndex(
        (tenant) => tenant.id === hoveredTenantId
      );

      if (
        currentHoveredTenantIndex === -1 ||
        currentHoveredTenantIndex === filteredTenants.length - 1
      ) {
        // Set the `hoveredTenantId` to the first tenant in the list
        tenantIdToHover = filteredTenants[0].id;
      } else {
        // Set the `hoveredTenantId` to the next tenant in the list
        tenantIdToHover = filteredTenants[currentHoveredTenantIndex + 1].id;
      }

      setHoveredTenantId(tenantIdToHover);
      scrollTenantElementIntoView(tenantIdToHover);
    };

    const onArrowUp = () => {
      // Clear the `hoveredTenantId` if the tenants list is empty
      if (filteredTenants.length === 0) {
        setHoveredTenantId('');
        return;
      }

      let tenantIdToHover: string;

      // If the `hoveredTenantId` is not set yet, set it to the last tenant in the list
      if (!hoveredTenantId) {
        tenantIdToHover = filteredTenants[filteredTenants.length - 1].id;

        setHoveredTenantId(tenantIdToHover);
        scrollTenantElementIntoView(tenantIdToHover);

        return;
      }

      const currentHoveredTenantIndex = filteredTenants.findIndex(
        (tenant) => tenant.id === hoveredTenantId
      );

      if (currentHoveredTenantIndex === -1 || currentHoveredTenantIndex === 0) {
        tenantIdToHover = filteredTenants[filteredTenants.length - 1].id;
      } else {
        // Set the `hoveredTenantId` to the prev tenant in the list
        tenantIdToHover = filteredTenants[currentHoveredTenantIndex - 1].id;
      }

      setHoveredTenantId(tenantIdToHover);
      scrollTenantElementIntoView(tenantIdToHover);
    };

    const onEnter = () => {
      if (!hoveredTenantId) return;

      switchToSelectedTenant(hoveredTenantId);
    };

    useKey('ArrowDown', onArrowDown, {}, [filteredTenants, hoveredTenantId]);
    useKey('ArrowUp', onArrowUp, {}, [filteredTenants, hoveredTenantId]);
    useKey('Enter', onEnter, {}, [hoveredTenantId]);

    if (filteredTenants.length === 0) {
      return (
        <Stack className={classes.listContainer}>
          <EmptySearchResults searchValue={deferredSearchTerm} />
        </Stack>
      );
    }

    return (
      <Stack spacing="xs" className={classes.listContainer}>
        {filteredTenants.map((tenant) => (
          <TenantItem
            key={tenant.id}
            onClick={() => switchToSelectedTenant(tenant.id)}
            highlightTerm={deferredSearchTerm}
            isHovered={hoveredTenantId === tenant.id}
            isSelected={selectedTenantId === tenant.id}
            displayName={tenant.displayName}
            name={tenant.name}
            logoUrl={tenant.logoUrl}
            metadata={tenant.metadata}
            ref={(el: HTMLDivElement) =>
              (tenantElementRefs.current[tenant.id] = el)
            }
          />
        ))}
      </Stack>
    );
  }
);

const useStyles = createStyles((theme) => ({
  listContainer: {
    position: 'relative',
    overflow: 'auto',
    height: '100%',
    paddingInline: theme.spacing.md,
    paddingBottom: theme.spacing.md,
  },
}));

function composeTenantMetadata(
  tenant: PartnerAccessibleTenant | OrganizationAccessibleTenant
) {
  if (isPartnerTenant(tenant)) {
    return tenant.is_internal ? ['Internal'] : null;
  } else if (isOrganizationTenant(tenant)) {
    const metadata = compact([
      tenant.lab ? 'Lab' : null,
      tenant.is_sandbox ? 'Sandbox' : null,
      tenant.partner_internal ? 'Internal partner' : null,
    ]);

    return metadata.length > 0 ? metadata : null;
  }

  return null;
}

function isPartnerTenant(
  tenant: PartnerAccessibleTenant | OrganizationAccessibleTenant
): tenant is PartnerAccessibleTenant {
  return 'is_internal' in tenant;
}

function isOrganizationTenant(
  tenant: PartnerAccessibleTenant | OrganizationAccessibleTenant
): tenant is OrganizationAccessibleTenant {
  return 'partner_name' in tenant;
}
