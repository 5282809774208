import { Tooltip, useMantineTheme } from '@mantine/core';
import React from 'react';

import { ReactComponent as Danger } from '@portals/icons/bold/danger.svg';
import { IncidentPriorityLevel } from '@portals/types';
import {
  getIncidentPriorityLevelColor,
  getIncidentPriorityLevelName,
} from '@portals/utils';

interface PriorityIconProps {
  priorityLevel: IncidentPriorityLevel;
  iconSize: number;
}

export function PriorityIcon({ priorityLevel, iconSize }: PriorityIconProps) {
  const theme = useMantineTheme();

  return (
    <Tooltip label={`${getIncidentPriorityLevelName(priorityLevel)} priority`}>
      <Danger
        color={theme.fn.themeColor(
          getIncidentPriorityLevelColor(priorityLevel),
          4
        )}
        width={iconSize}
        height={iconSize}
      />
    </Tooltip>
  );
}
