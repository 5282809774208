import { UsePaginatedTableApiQuery } from '@portals/types';

import { OrderSummaryType, UseOrderPreviewParams } from './orders.types';

export const ORDERS_API_URL = 'ui/organization/orders';

export const ordersQueryKeys = {
  all: [ORDERS_API_URL],

  list: (
    tableState: UsePaginatedTableApiQuery<OrderSummaryType>['tableState']
  ) => [...ordersQueryKeys.all, 'list', tableState],

  details: () => [...ordersQueryKeys.all, 'details'],
  detail: (orderId: string) => [...ordersQueryKeys.details(), orderId],

  previews: () => [...ordersQueryKeys.all, 'previews'],
  preview: (order: UseOrderPreviewParams) => [
    ...ordersQueryKeys.previews(),
    JSON.stringify(order),
  ],

  postPurchaseParameters: (orderId: string) => [
    ...ordersQueryKeys.detail(orderId),
    'postPurchaseParams',
  ],

  priceQuotesPreviews: () => [...ordersQueryKeys.all, 'priceQuotesPreview'],
  priceQuotesPreview: (order: UseOrderPreviewParams) => [
    ...ordersQueryKeys.priceQuotesPreviews(),
    JSON.stringify(order),
  ],
};
