import React from 'react';

import {
  DashboardContentLayout,
  RoutedTabs,
  RoutedTabsProps,
} from '@portals/framework';

import { OrdersTable } from './OrdersTable';

const ROUTES: RoutedTabsProps['routes'] = [
  {
    path: 'all',
    tabLabel: 'All',
    element: <OrdersTable status="all" />,
  },
  {
    path: 'pending',
    tabLabel: 'Pending',
    element: <OrdersTable status="pending" />,
  },
  {
    path: 'in_process',
    tabLabel: 'In Process',
    element: <OrdersTable status="in_process" />,
  },
  {
    path: 'fulfilled',
    tabLabel: 'Fulfilled',
    element: <OrdersTable status="fulfilled" />,
  },
];

export function Orders() {
  return (
    <DashboardContentLayout
      pageTitle="Orders"
      bg="gray.0"
      breadcrumbs={[
        { label: 'Products', to: '/products' },
        { label: 'Orders' },
      ]}
    >
      <RoutedTabs routes={ROUTES} basePath="/products/orders" />
    </DashboardContentLayout>
  );
}
