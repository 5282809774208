import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError } from '@portals/redux/actions/toastr';

import { ORDERS_API_URL, ordersQueryKeys } from './orders.constants';
import { PostPurchaseParametersType } from './orders.types';
import { useApiQuery } from '../../hooks';
import { QueryOptions, ServerError } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils';

export function usePostPurchasedParameters({
  orderId,
  queryOptions,
}: {
  orderId: string;
  queryOptions: QueryOptions<PostPurchaseParametersType[]>;
}) {
  return useApiQuery<PostPurchaseParametersType[]>(
    `${ORDERS_API_URL}/${orderId}/post_purchase_parameters`,
    ordersQueryKeys.postPurchaseParameters(orderId),
    queryOptions
  );
}

interface UseUpdatePostPurchaseParametersParams {
  orderId: string;
  postPurchaseParameters: Record<string, Record<string, string | number>>;
}

export function useUpdatePostPurchaseParameters() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { url: baseUrl, options } = useRequestOptions({
    url: ORDERS_API_URL,
    method: 'PUT',
  });

  return useMutation<void, ServerError, UseUpdatePostPurchaseParametersParams>({
    mutationFn: ({ orderId, postPurchaseParameters }) => {
      return fetchApiRequest(`${baseUrl}/${orderId}/post_purchase_parameters`, {
        ...options,
        body: JSON.stringify({
          post_purchase_parameters: postPurchaseParameters,
        }),
      });
    },
    onSuccess: (_, { orderId }) => {
      queryClient.invalidateQueries(
        ordersQueryKeys.postPurchaseParameters(orderId)
      );
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useUpdatePostPurchaseParameters',
      baseUrl: `${ORDERS_API_URL}/:id/post_purchase_parameters`,
      method: 'PUT',
    },
  });
}

interface UseLockPostPurchaseParametersParams {
  orderId: string;
}

export function useLockPostPurchaseParameters() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { url: baseUrl, options } = useRequestOptions({
    url: ORDERS_API_URL,
    method: 'POST',
  });

  return useMutation<void, ServerError, UseLockPostPurchaseParametersParams>({
    mutationFn: ({ orderId }) => {
      return fetchApiRequest(
        `${baseUrl}/${orderId}/post_purchase_parameters/lock`,
        options
      );
    },
    onSuccess: (_, { orderId }) => {
      queryClient.invalidateQueries(ordersQueryKeys.all);
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useLockPostPurchaseParameters',
      baseUrl: `${ORDERS_API_URL}/:id/post_purchase_parameters/lock`,
      method: 'POST',
    },
  });
}
