import { Button } from '@mantine/core';
import React, { FC } from 'react';
import {
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from 'reactstrap';

import { ReactComponent as Edit } from '@portals/icons/linear/edit.svg';
import { ReactComponent as StopCircle } from '@portals/icons/linear/stop-circle.svg';
import { DeviceType } from '@portals/types';
import { prettyTime, suppressPropagation } from '@portals/utils';

const SNOOZE_OPTIONS = {
  60: '1 Hour',
  [60 * 24]: '1 Day',
  [60 * 24 * 7]: '7 Days',
  [60 * 24 * 30]: '30 Days',
};

type IncidentTrackingProps = {
  device: DeviceType;
  setDeviceDetails: (
    spaceId: number,
    deviceId: string,
    device: Partial<DeviceType> & Record<string, any>
  ) => null;
};

type SetTrackingProps = {
  handleClick: (time: string) => null;
  isSnoozed: boolean;
};

const SetTracking: FC<SetTrackingProps> = ({ handleClick, isSnoozed }) => {
  if (isSnoozed) {
    SNOOZE_OPTIONS[0] = 'Re-Activate';
  }

  return (
    <UncontrolledButtonDropdown>
      <DropdownToggle
        color="none"
        onClick={suppressPropagation()}
        className="p-0"
      >
        <Button
          size="xs"
          color={isSnoozed ? 'primary' : 'red_accent'}
          leftIcon={
            isSnoozed ? (
              <Edit width={15} height={15} />
            ) : (
              <StopCircle width={15} height={15} />
            )
          }
        >
          {isSnoozed ? 'Change' : 'Suspend'}
        </Button>
      </DropdownToggle>

      <DropdownMenu>
        {Object.keys(SNOOZE_OPTIONS).map((time) => (
          <DropdownItem key={time} onClick={() => handleClick(time)}>
            {SNOOZE_OPTIONS[time]}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};

const IncidentTracking: FC<IncidentTrackingProps> = ({
  device,
  setDeviceDetails,
}) => {
  const isSnoozed = !!device.snoozed_until;
  const handleClick = (time) =>
    setDeviceDetails(device.space_id, device.id, { snooze_until: time });

  return (
    <div className="justify-content-between d-flex align-items-center">
      <div>
        <Badge
          color={isSnoozed ? 'danger' : 'primary'}
          className="text-uppercase"
        >
          {isSnoozed ? 'disabled' : 'active'}
        </Badge>

        {isSnoozed && (
          <span className="ml-2">
            {`(until ${prettyTime(device.snoozed_until)})`}
          </span>
        )}
      </div>

      {!!setDeviceDetails && (
        <SetTracking handleClick={handleClick} isSnoozed={isSnoozed} />
      )}
    </div>
  );
};

export default IncidentTracking;
