import { createStyles, Divider, Group, Stack, Text } from '@mantine/core';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { contextMenu, Menu } from 'react-contexify';
import { useNavigate } from 'react-router-dom';

import {
  useDeleteSpace,
  useSpace,
  useUpdateSpaceMaintenance,
} from '@portals/api/organizations';
import { ForbiddenErrorSVG } from '@portals/assets';
import { useConfirmationModal, usePermissionAccess } from '@portals/framework';
import { ReactComponent as BoxAdd } from '@portals/icons/linear/box-add.svg';
import { ReactComponent as Edit } from '@portals/icons/linear/edit.svg';
import { ReactComponent as MonitorMobbile } from '@portals/icons/linear/monitor-mobbile.svg';
import { ReactComponent as Settings } from '@portals/icons/linear/setting-2.svg';
import { ReactComponent as Snooze } from '@portals/icons/linear/snooze.svg';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
import { useOpenModal } from '@portals/redux';
import { suppressPropagation } from '@portals/utils';

import { canAdmin } from '../../../../../../lib/access';
import { useOpenClaimDeviceModal } from '../../../../../components/ClaimDeviceButton';
import { SpaceMaintenanceActivationModalProps } from '../../../../../modals';

interface NodeContextMenuProps {
  nodeId: number;
  onEditToggle: () => void;
  onCreateSpace: () => void;
  setIsContextMenuOpen: Dispatch<SetStateAction<boolean>>;
}

const NodeContextMenu: FC<NodeContextMenuProps> = ({
  nodeId,
  onEditToggle,
  onCreateSpace,
  setIsContextMenuOpen,
}) => {
  const { classes } = useStyles();
  const openModal = useOpenModal();
  const asyncConfirmationCheck = useConfirmationModal();
  const navigate = useNavigate();
  const { isAdmin } = usePermissionAccess();
  const openClaimDeviceModal = useOpenClaimDeviceModal();

  const space = useSpace({ spaceId: nodeId });
  const updateSpaceMaintenance = useUpdateSpaceMaintenance();
  const deleteSpace = useDeleteSpace();

  if (!space) return null;

  const isRoot = space.parent_id === null;
  const canRemove = !isRoot;
  const isUnderMaintenance = space.state?.maintenance || false;

  const handleDelete = async () => {
    const isConfirmed = await asyncConfirmationCheck({
      title: `Delete Space "${space.name}"`,
      description: `All contained information will be lost and all assigned devices will be moved to "Unsorted" space. Do you want to continue?`,
      confirmationLabel: 'Delete',
      cancelLabel: 'Cancel',
    });

    if (isConfirmed) {
      await deleteSpace.mutateAsync(space.id);

      if (space.parent_id) {
        navigate(`/overview/${space.parent_id}`);
      }
    } else {
      return;
    }
  };

  const handleSetMaintenance = () => {
    if (!isUnderMaintenance) {
      openModal<SpaceMaintenanceActivationModalProps['data']>(
        'SpaceMaintenanceActivationModal',
        { spaceId: space.id }
      );
    } else {
      updateSpaceMaintenance.mutate({
        spaceId: space.id,
        enabled: false,
      });
    }
  };

  return (
    <Menu
      id={nodeId}
      onVisibilityChange={(isVisible) => setIsContextMenuOpen(isVisible)}
    >
      <>
        <Stack className={classes.container} spacing="xs" pt="xs">
          <Text className={classes.title}>{space.name}</Text>

          <Divider color="gray.3" />

          <Group
            className={classes.item}
            spacing="xs"
            onClick={suppressPropagation(() => {
              handleSetMaintenance();
              contextMenu.hideAll();
            })}
          >
            <Snooze />
            Set maintenance {isUnderMaintenance ? 'OFF' : 'ON'}
          </Group>

          <Group
            className={classes.item}
            spacing="xs"
            onClick={suppressPropagation(() => {
              openClaimDeviceModal({ spaceId: space.id });
              contextMenu.hideAll();
            })}
          >
            <MonitorMobbile />
            Claim device
          </Group>

          <Group
            className={classes.item}
            spacing="xs"
            onClick={suppressPropagation(() => {
              onEditToggle();
              contextMenu.hideAll();
            })}
          >
            <Edit />
            Rename
          </Group>

          <Group
            className={classes.item}
            spacing="xs"
            onClick={suppressPropagation(() => {
              navigate(`/overview/${space.id}/settings/access`);
              contextMenu.hideAll();
            })}
          >
            <ForbiddenErrorSVG />
            Manage Access
          </Group>

          {isAdmin || canAdmin(space) ? (
            <Group
              className={classes.item}
              spacing="xs"
              onClick={suppressPropagation(() => {
                navigate(`/overview/${space.id}/settings/space`);
                contextMenu.hideAll();
              })}
            >
              <Settings />
              Settings
            </Group>
          ) : null}

          <Divider color="gray.3" />

          <Group
            className={classes.item}
            spacing="xs"
            onClick={() => {
              onCreateSpace();
              contextMenu.hideAll();
            }}
          >
            <BoxAdd />
            Add new space
          </Group>

          {canRemove ? (
            <Group
              className={classes.item}
              spacing="xs"
              sx={(theme) => ({
                color: theme.colors.red_accent[4],
              })}
              onClick={suppressPropagation(() => {
                handleDelete();
                contextMenu.hideAll();
              })}
            >
              <Trash />
              Remove
            </Group>
          ) : null}
        </Stack>
      </>
    </Menu>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    zIndex: 999,
    padding: '0 4px',
    width: 224,
  },
  title: {
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[9],
    fontWeight: 600,
    width: '100%',
    textAlign: 'center',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    padding: `0 ${theme.spacing.md}`,
  },
  item: {
    flexShrink: 0,
    height: 33,
    padding: `0 ${theme.spacing.lg}`,
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[9],
    fontWeight: 300,
    transition: 'all 0.15s ease-in-out',
    borderRadius: theme.radius.sm,
    cursor: 'pointer',

    svg: {
      height: 18,
      width: 18,
    },

    ':hover': {
      backgroundColor: theme.colors.gray[1],
    },
  },
}));

export default NodeContextMenu;
