import React, { FC, ReactNode } from 'react';
import {
  ThemeProvider as StyledThemeProvider,
  useTheme as useStyledTheme,
} from 'styled-components';

import THEME from './theme';

export type ThemeType = typeof THEME;

type ThemeProviderProps = {
  children: ReactNode;
};

export const ThemeProvider: FC<ThemeProviderProps> = ({ children }) => (
  <StyledThemeProvider theme={THEME}>{children}</StyledThemeProvider>
);

export const useTheme = () => useStyledTheme() as ThemeType;

export default ThemeProvider;
