import { Box, createStyles, Group, Text } from '@mantine/core';
import React from 'react';

import { MeasurementType } from '../form';

interface LegendLabelProps {
  measurements: Array<MeasurementType>;
  index: number;
}

export function LegendLabel({ measurements, index }: LegendLabelProps) {
  const { classes } = useStyles();
  const measurement = measurements[index];

  return (
    <Group align="center" spacing="xs" noWrap>
      <Box>
        <Box className={classes.indicator} bg={measurement.color} />
      </Box>

      <Text size="xs" inline color={measurement.color} truncate>
        {measurement.label}
      </Text>
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  indicator: {
    width: 14,
    height: 14,
    borderRadius: theme.radius.sm,
  },
}));
