import { Anchor } from '@mantine/core';
import React from 'react';

import { OrganizationInvoiceType } from '@portals/api/organizations';
import { DetailsList, DetailsListProps } from '@portals/framework';

interface PartnerDetailsProps {
  invoicePartner: OrganizationInvoiceType['partner'];
}

export function PartnerDetails({ invoicePartner }: PartnerDetailsProps) {
  const contactName = invoicePartner?.finance_contact_name
    ? invoicePartner?.finance_contact_name
    : invoicePartner?.admin_contact_name;
  const contactEmail = invoicePartner?.finance_contact_email
    ? invoicePartner?.finance_contact_email
    : invoicePartner?.admin_contact_email;

  const detailsList: DetailsListProps['items'] = [
    { label: 'Seller', value: invoicePartner?.display_name },
    { label: 'Finance Contact', value: contactName },
    {
      label: 'Email',
      value: <Anchor href={`mailto:${contactEmail}`}>{contactEmail}</Anchor>,
    },
  ];

  return <DetailsList items={detailsList} />;
}
