import { DashboardLayout } from '@portals/types';

import activeIncidentsAgeThumbnailSrc from './overview-dashboard/assets/active-incidents-age-thumbnail.svg';
import activeIncidentsByAgeIcon from './overview-dashboard/assets/active-incidents-by-age-icon.svg';
import activeIncidentsIconSrc from './overview-dashboard/assets/active-incidents-icon.svg';
import activeIncidentsThumbnailSrc from './overview-dashboard/assets/active-incidents-thumbnail.png';
import claimedDevicesIconSrc from './overview-dashboard/assets/claimed-devices-icon.svg';
import claimedDevicesThumbnailSrc from './overview-dashboard/assets/claimed-devices-thumbnail.png';
import deviceModelBreakdownThumbnailSrc from './overview-dashboard/assets/device-model-breakdown-thumbnail.svg';
import devicesByModelIconSrc from './overview-dashboard/assets/devices-by-model-icon.svg';
import devicesByModelThumbnailSrc from './overview-dashboard/assets/devices-by-model-thumbnail.png';
import devicesIconSrc from './overview-dashboard/assets/devices-icon.svg';
import deviceIncidentsRageThumbnailSrc from './overview-dashboard/assets/devices-incidents-rate-thumbnail.svg';
import devicesListIconSrc from './overview-dashboard/assets/devices-list-icon.svg';
import devicesListThumbnailSrc from './overview-dashboard/assets/devices-list-thumbnail.png';
import devicesMapIconSrc from './overview-dashboard/assets/devices-map-icon.svg';
import devicesMapThumbnailSrc from './overview-dashboard/assets/devices-map-thumbnail.png';
import devicesPerSpaceThumbnailSrc from './overview-dashboard/assets/devices-per-space-thumbnail.svg';
import devicesThumbnailSrc from './overview-dashboard/assets/devices-thumbnail.png';
import filesIconSrc from './overview-dashboard/assets/files-icon.svg';
import filesThumbnailSrc from './overview-dashboard/assets/files-thumbnail.png';
import graphIconSrc from './overview-dashboard/assets/graph-icon.svg';
import incidentsListIconSrc from './overview-dashboard/assets/incidents-list-icon.svg';
import incidentsListThumbnailSrc from './overview-dashboard/assets/incidents-list-thumbnail.png';
import incidentsMapIconSrc from './overview-dashboard/assets/incidents-map-icon.svg';
import incidentsMapThumbnailSrc from './overview-dashboard/assets/incidents-map-thumbnail.png';
import noteIconSrc from './overview-dashboard/assets/note-icon.svg';
import noteThumbnailSrc from './overview-dashboard/assets/note-thumbnail.png';
import onlineDevicesIconSrc from './overview-dashboard/assets/online-devices-icon.svg';
import onlineDevicesThumbnailSrc from './overview-dashboard/assets/online-devices-thumbnail.png';
import spacesIncidentsRankIconSrc from './overview-dashboard/assets/spaces-incidents-rank-icon.svg';
import spacesIncidentsRankThumbnailSrc from './overview-dashboard/assets/spaces-incidents-rank-thumbnail.png';
import spaceIncidentsRageThumbnailSrc from './overview-dashboard/assets/spaces-incidents-rate-thumbnail.svg';
import TreemapIconSrc from './overview-dashboard/assets/treemap-icon.svg';
import { ActiveIncidentsAgeDashboardWidgetWrapper } from './overview-dashboard/widgets/active-incidents-age/ActiveIncidentsAgeDashboardWidget';
import { DeviceModelBreakdownDashboardWidgetWrapper } from './overview-dashboard/widgets/device-model-breakdown/DeviceModelBreakdownDashboardWidget';
import {
  DevicesGridWidget,
  DevicesListWidget,
  DevicesMapWidget,
} from './overview-dashboard/widgets/devices';
import { DevicesByModel } from './overview-dashboard/widgets/devices-by-model/DevicesByModel';
import { DeviceIncidentRateDashboardWidgetWrapper } from './overview-dashboard/widgets/devices-incidents-rank/DeviceIncidentRateDashboardWidget';
import { DevicesPerSpaceDashboardWidgetWrapper } from './overview-dashboard/widgets/devices-per-space/DevicesPerSpaceDashboardWidget';
import { FilesWidget } from './overview-dashboard/widgets/files';
import { IncidentsWidget } from './overview-dashboard/widgets/incidents';
import {
  ActiveIncidentsWidget,
  ClaimedDevicesWidget,
  OnlineDevicesWidget,
} from './overview-dashboard/widgets/kpis';
import { DeviceMap } from './overview-dashboard/widgets/legacy-widgets/DeviceMap';
import { LegacyDevicesBoxes } from './overview-dashboard/widgets/legacy-widgets/DevicesBoxes';
import { LegacyStatsBoxes } from './overview-dashboard/widgets/legacy-widgets/StatsBoxes';
import { IncidentMap } from './overview-dashboard/widgets/maps/IncidentMap';
import { NoteWidgetWrapper } from './overview-dashboard/widgets/note/Note';
import { SpaceIncidentRateDashboardWidgetWrapper } from './overview-dashboard/widgets/space-incident-rate/SpaceIncidentRateDashboardWidget';
import { SpacesIncidentsRank } from './overview-dashboard/widgets/spaces-incidents-rank/SpacesIncidentsRank';
import {
  DashboardComponent,
  LayoutBreakpoint,
  WidgetType,
} from './overview.types';

export const DASHBOARD_BREAKPOINTS_KEYS: Array<LayoutBreakpoint> = [
  'lg',
  'md',
  'sm',
  'xs',
  'xxs',
];

export const DASHBOARD_COLUMNS: Record<LayoutBreakpoint, number> = {
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12,
  xxs: 12,
};

export const WIDGETS_MAP: Record<WidgetType, DashboardComponent> = {
  claimed_devices: {
    id: 'claimed_devices',
    name: 'Claimed Devices',
    description: 'Claimed devices',
    Component: ClaimedDevicesWidget,
    thumbnail: claimedDevicesThumbnailSrc,
    icon: claimedDevicesIconSrc,
    h: 4,
    w: 3,
    minH: 4,
    minW: 2,
    maxH: 4,
  },
  online_devices: {
    id: 'online_devices',
    name: 'Online Devices',
    description: 'Online devices',
    Component: OnlineDevicesWidget,
    thumbnail: onlineDevicesThumbnailSrc,
    icon: onlineDevicesIconSrc,
    h: 4,
    w: 3,
    minH: 4,
    minW: 2,
    maxH: 4,
  },
  active_incidents: {
    id: 'active_incidents',
    name: 'Active Incidents',
    description: 'Active incidents',
    Component: ActiveIncidentsWidget,
    thumbnail: activeIncidentsThumbnailSrc,
    icon: activeIncidentsIconSrc,
    h: 4,
    w: 3,
    minH: 4,
    minW: 2,
    maxH: 4,
  },
  incidents_list: {
    id: 'incidents_list',
    name: 'Incidents List',
    description: 'All space incidents',
    Component: IncidentsWidget,
    thumbnail: incidentsListThumbnailSrc,
    icon: incidentsListIconSrc,
    w: 4,
    h: 23,
    minH: 12,
    minW: 2,
  },
  incident_map: {
    id: 'incident_map',
    name: 'Incidents Map',
    description: 'Incident counts plotted on a map',
    Component: IncidentMap,
    thumbnail: incidentsMapThumbnailSrc,
    icon: incidentsMapIconSrc,
    w: 6,
    h: 22,
    minH: 12,
    minW: 4,
  },
  devices_list: {
    id: 'devices_list',
    name: 'Devices Cards',
    description: 'All space devices',
    Component: DevicesGridWidget,
    thumbnail: devicesThumbnailSrc,
    icon: devicesIconSrc,
    h: 23,
    w: 8,
    minH: 12,
    minW: 3,
  },
  devices_list_map: {
    id: 'devices_list_map',
    name: 'Devices Map',
    description: 'All space devices',
    Component: DevicesMapWidget,
    thumbnail: devicesMapThumbnailSrc,
    icon: devicesMapIconSrc,
    h: 23,
    w: 8,
    minH: 12,
    minW: 3,
  },
  devices_list_table: {
    id: 'devices_list_table',
    name: 'Devices List',
    description: 'All space devices',
    Component: DevicesListWidget,
    thumbnail: devicesListThumbnailSrc,
    icon: devicesListIconSrc,
    h: 23,
    w: 8,
    minH: 12,
    minW: 3,
  },
  files_list: {
    id: 'files_list',
    name: 'Files List',
    description: 'All space files',
    Component: FilesWidget,
    thumbnail: filesThumbnailSrc,
    icon: filesIconSrc,
    w: 6,
    h: 22,
    minH: 12,
    minW: 4,
  },
  devices_by_model_in_space: {
    id: 'devices_by_model_in_space',
    name: 'Devices by Model',
    description:
      'Displays a list of devices that are currently claimed and online, sorted by their model',
    Component: DevicesByModel,
    thumbnail: devicesByModelThumbnailSrc,
    icon: devicesByModelIconSrc,
    w: 8,
    h: 15,
    minW: 4,
    minH: 10,
  },
  top_spaces_with_incidents: {
    id: 'top_spaces_with_incidents',
    name: 'Spaces Incidents Rank',
    description: 'Displays a list of spaces, sorted by their incidents count',
    Component: SpacesIncidentsRank,
    thumbnail: spacesIncidentsRankThumbnailSrc,
    icon: spacesIncidentsRankIconSrc,
    w: 4,
    h: 15,
    minW: 3,
    minH: 6,
  },
  note: {
    id: 'note',
    name: 'Note',
    description: 'Easily write and place free text on your dashboard',
    Component: NoteWidgetWrapper,
    thumbnail: noteThumbnailSrc,
    icon: noteIconSrc,
    withSettings: true,
    w: 6,
    h: 6,
    minH: 6,
    minW: 2,
  },
  space_incidents_rate: {
    id: 'space_incidents_rate',
    name: 'Spaces incidents rate',
    description: 'Percent of spaces experiencing incidents or has no incidents',
    Component: SpaceIncidentRateDashboardWidgetWrapper,
    thumbnail: spaceIncidentsRageThumbnailSrc,
    icon: activeIncidentsIconSrc,
    withSettings: true,
    w: 4,
    h: 5,
    minH: 5,
    minW: 4,
  },
  device_incidents_rate: {
    id: 'device_incidents_rate',
    name: 'Devices incidents rate',
    description:
      'Percent of devices experiencing incidents or has no incidents',
    Component: DeviceIncidentRateDashboardWidgetWrapper,
    thumbnail: deviceIncidentsRageThumbnailSrc,
    icon: activeIncidentsIconSrc,
    withSettings: true,
    w: 4,
    h: 5,
    minH: 5,
    minW: 4,
  },
  device_incidents_age: {
    id: 'device_incidents_age',
    name: 'Active incidents by age',
    description:
      'List of spaces with most active incidents (sorted by incident ',
    Component: ActiveIncidentsAgeDashboardWidgetWrapper,
    thumbnail: activeIncidentsAgeThumbnailSrc,
    icon: activeIncidentsByAgeIcon,
    withSettings: true,
    w: 6,
    h: 13,
    minW: 6,
    minH: 13,
  },
  devices_model_breakdown: {
    id: 'devices_model_breakdown',
    name: 'Device breakdown',
    description: 'Displays a visual breakdown of models by number of devices',
    Component: DeviceModelBreakdownDashboardWidgetWrapper,
    thumbnail: deviceModelBreakdownThumbnailSrc,
    icon: graphIconSrc,
    withSettings: true,
    w: 5,
    h: 9,
    minW: 5,
    minH: 9,
  },
  devices_per_space: {
    id: 'devices_per_space',
    name: 'Devices per space',
    description: 'Compare spaces by the number of devices they contain',
    Component: DevicesPerSpaceDashboardWidgetWrapper,
    thumbnail: devicesPerSpaceThumbnailSrc,
    icon: TreemapIconSrc,
    withSettings: true,
    w: 5.5,
    h: 13,
    minW: 5.5,
    minH: 13,
  },

  // TODO: Legacy widgets - remove when all widgets are migrated
  incident_summary_modern: {
    id: 'incident_summary_modern',
    name: 'Incidents Summary',
    description: 'Short summary of open incidents',
    Component: LegacyStatsBoxes,
    thumbnail: null,
    icon: null,
    isLegacy: true,
    minW: 2,
    minH: 3,
  },
  devices_overview_modern: {
    id: 'devices_overview_modern',
    name: 'Devices Overview',
    description: '',
    Component: LegacyDevicesBoxes,
    thumbnail: null,
    icon: null,
    isLegacy: true,
    minW: 3,
    minH: 4,
  },
  devices_map: {
    id: 'devices_map',
    name: 'Devices Map',
    description: '',
    Component: DeviceMap,
    thumbnail: null,
    icon: null,
    isLegacy: true,
    w: 6,
    h: 10,
  },
  top_incidents: {
    id: 'top_incidents',
    name: 'Incidents List',
    description: 'All space incidents',
    Component: IncidentsWidget,
    thumbnail: null,
    icon: null,
    isLegacy: true,
    minW: 3,
    minH: 6,
  },

  // This is a demo widget, not to be used in production
  // xyte_insights: {
  //   id: 'xyte_insights',
  //   name: 'Xyte Insights',
  //   description: 'AI-Powered Xyte Insights',
  //   Component: XyteInsights,
  //   thumbnail: xyteInsightsThumbnailSrc,
  //   icon: xyteInsightsIconSrc,
  //   isPremium: true,
  //   minW: 4,
  //   minH: 20,
  // },
};

export const DEFAULT_DASHBOARD: DashboardLayout<WidgetType> = {
  list: [
    {
      w: 4,
      h: 4,
      x: 0,
      y: 0,
      i: 'c9d4bfb6-7a7d-4c7d-8ad0-6fd9aea1f999',
      minW: 2,
      minH: 4,
      maxH: 4,
      moved: false,
      static: false,
      id: 'claimed_devices',
      name: 'Claimed Devices',
    },
    {
      w: 4,
      h: 4,
      x: 4,
      y: 0,
      i: '5165d149-ca8a-4af4-8251-31506b3e84d5',
      minW: 2,
      minH: 4,
      maxH: 4,
      moved: false,
      static: false,
      id: 'online_devices',
      name: 'Online Devices',
    },
    {
      w: 4,
      h: 4,
      x: 8,
      y: 0,
      i: '0f32600a-df44-48d7-9869-e7b262ca8b9b',
      minW: 2,
      minH: 4,
      maxH: 4,
      moved: false,
      static: false,
      id: 'active_incidents',
      name: 'Active Incidents',
    },
    {
      w: 6,
      h: 21,
      x: 0,
      y: 27,
      i: '19061eb8-1410-43e3-aa4d-fc5e82fcb86b',
      minW: 4,
      minH: 12,
      moved: false,
      static: false,
      id: 'files_list',
      name: 'Files List',
    },
    {
      w: 6,
      h: 21,
      x: 6,
      y: 27,
      i: '85b82781-8e0d-497f-82d2-5b547cb53514',
      minW: 4,
      minH: 12,
      moved: false,
      static: false,
      id: 'incident_map',
      name: 'Incidents Map',
    },
    {
      w: 4,
      h: 15,
      x: 8,
      y: 48,
      i: 'e78e9a7c-93bd-4926-a7f6-ffd473de3652',
      minW: 3,
      minH: 6,
      moved: false,
      static: false,
      id: 'top_spaces_with_incidents',
      name: 'Spaces Incidents Rank',
    },
    {
      w: 8,
      h: 15,
      x: 0,
      y: 48,
      i: '23dfee83-30c6-4b47-8a82-a004006e4a40',
      minW: 4,
      minH: 10,
      moved: false,
      static: false,
      id: 'devices_by_model_in_space',
      name: 'Devices by Model',
    },
    {
      w: 8,
      h: 23,
      x: 0,
      y: 4,
      i: '72bb8003-e6d9-4ebf-88d4-7841d8b38a4e',
      minW: 3,
      minH: 12,
      moved: false,
      static: false,
      id: 'devices_list',
      name: 'Devices List',
    },
    {
      w: 4,
      h: 23,
      x: 8,
      y: 4,
      i: '3ac987d7-8290-4046-8499-30f8415e416e',
      minW: 2,
      minH: 12,
      moved: false,
      static: false,
      id: 'incidents_list',
      name: 'Incidents List',
    },
  ],
  layouts: {
    lg: [
      {
        w: 4,
        h: 4,
        x: 0,
        y: 0,
        i: 'c9d4bfb6-7a7d-4c7d-8ad0-6fd9aea1f999',
        minW: 2,
        minH: 4,
        maxH: 4,
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 4,
        x: 4,
        y: 0,
        i: '5165d149-ca8a-4af4-8251-31506b3e84d5',
        minW: 2,
        minH: 4,
        maxH: 4,
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 4,
        x: 8,
        y: 0,
        i: '0f32600a-df44-48d7-9869-e7b262ca8b9b',
        minW: 2,
        minH: 4,
        maxH: 4,
        moved: false,
        static: false,
      },
      {
        w: 6,
        h: 21,
        x: 0,
        y: 27,
        i: '19061eb8-1410-43e3-aa4d-fc5e82fcb86b',
        minW: 4,
        minH: 12,
        moved: false,
        static: false,
      },
      {
        w: 6,
        h: 21,
        x: 6,
        y: 27,
        i: '85b82781-8e0d-497f-82d2-5b547cb53514',
        minW: 4,
        minH: 12,
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 15,
        x: 8,
        y: 48,
        i: 'e78e9a7c-93bd-4926-a7f6-ffd473de3652',
        minW: 3,
        minH: 6,
        moved: false,
        static: false,
      },
      {
        w: 8,
        h: 15,
        x: 0,
        y: 48,
        i: '23dfee83-30c6-4b47-8a82-a004006e4a40',
        minW: 4,
        minH: 10,
        moved: false,
        static: false,
      },
      {
        w: 8,
        h: 23,
        x: 0,
        y: 4,
        i: '72bb8003-e6d9-4ebf-88d4-7841d8b38a4e',
        minW: 3,
        minH: 12,
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 23,
        x: 8,
        y: 4,
        i: '3ac987d7-8290-4046-8499-30f8415e416e',
        minW: 2,
        minH: 12,
        moved: false,
        static: false,
      },
    ],
  },
};
