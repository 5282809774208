import React from 'react';
import { Badge } from 'reactstrap';

const IntegrationErrors = ({ title, errors }) => (
  <span>
    {title}
    {errors && errors > 0 ? (
      <Badge color="danger" size={12} className="sidebar-badge ml-1">
        {errors}
      </Badge>
    ) : null}
  </span>
);

export default IntegrationErrors;
