import { BadgeProps, BadgeStylesNames, BadgeStylesParams } from '@mantine/core';

import { ThemeComponentOverrides } from '../common-types';

export const Badge: ThemeComponentOverrides<
  BadgeProps,
  BadgeStylesNames,
  BadgeStylesParams
> = {
  styles: {
    root: {
      textTransform: 'capitalize',
    },
  },
};
