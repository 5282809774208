import { Group, Select, SelectProps, Text } from '@mantine/core';
import React, { forwardRef } from 'react';

import { NameAbbreviationAvatar } from '@portals/core';

export interface UsersSelectProps
  extends Omit<SelectProps, 'styles' | 'itemComponent' | 'data'> {
  data: Array<{ label: string; value: string; email: string }>;
}

export function UsersSelect({ data, ...selectProps }: UsersSelectProps) {
  return (
    <Select
      searchable
      label="User"
      placeholder="Select user"
      data={data}
      itemComponent={SelectItem}
      styles={selectStyles}
      {...selectProps}
    />
  );
}

const selectStyles: SelectProps['styles'] = {
  item: {
    paddingBlock: 10,
  },
};

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string;
  value: string;
  email: string;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, email, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap spacing="xs">
        <NameAbbreviationAvatar name={label} radius="xl" size="md" />

        <div>
          <Text color="blue_gray.9">{label}</Text>
          <Text size="xs" color="blue_gray.5">
            {email}
          </Text>
        </div>
      </Group>
    </div>
  )
);
