import { Box, createStyles, Group } from '@mantine/core';
import React from 'react';

import { WidgetColorType } from '@portals/device-widgets';
import { ReactComponent as LocationIcon } from '@portals/icons/bold/location.svg';
import { DeviceStatusType } from '@portals/types';

import { useDeviceOverviewContext } from '../../DeviceOverviewContext';

export interface DeviceMarkerProps {
  color: WidgetColorType;
  status: DeviceStatusType | undefined;
  deviceId: string;

  lat?: number;
  lng?: number;
}

export function DeviceMarker({ deviceId, color }: DeviceMarkerProps) {
  const { classes, theme } = useStyles();
  const { setSelectedDeviceId, selectedDeviceId, isFullscreen } =
    useDeviceOverviewContext();

  return (
    <Group
      bg={color}
      className={classes.marker}
      grow
      id="device-location-map-marker"
      onClick={() => isFullscreen && setSelectedDeviceId(deviceId)}
    >
      <Box className={classes.markerWrapper} id="device-location-map-marker">
        <Box
          className={classes.selectedMarkerIndicator}
          bg={
            selectedDeviceId === deviceId
              ? theme.fn.rgba(theme.black, 0.25)
              : 'transparent'
          }
          id="device-location-map-marker"
        />

        <LocationIcon
          color="white"
          className={classes.markerIcon}
          id="device-location-map-marker"
        />
      </Box>
    </Group>
  );
}

const useStyles = createStyles(() => ({
  markerWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    pointerEvents: 'none',

    '> *': {
      pointerEvents: 'none',
    },
  },
  marker: {
    width: 32,
    height: 32,
    borderRadius: 16,
    position: 'absolute',
    transform: 'translate(-25%, -25%)',
  },
  markerIcon: {
    flexShrink: 0,
    zIndex: 1,
  },
  selectedMarkerIndicator: {
    width: 42,
    height: 42,
    borderRadius: '50%',
    position: 'absolute',
    transition: 'background-color 0.15s ease-in-out',
    pointerEvents: 'none',
  },
}));
