export enum SortTypeEnum {
  Name = 'Name',
  Incidents = 'Incidents',
  Status = 'Status',
}

export enum GroupTypeEnum {
  None = 'None',
  Model = 'Model',
  Status = 'Status',
}

export enum IncidentsFilterEnum {
  Critical = 'Critical',
  High = 'High',
  Moderate = 'Moderate',
  Low = 'Low',
  All = 'All',
}

export enum StatusesFilterEnum {
  All = 'All',
  Error = 'Error',
  Unavailable = 'Unavailable',
  Online = 'Online',
  Offline = 'Offline',
}

export enum ViewType {
  Grid = 'Grid',
  List = 'List',
}
