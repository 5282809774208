import { createStyles, Group } from '@mantine/core';
import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as ArrowLeft } from '@portals/icons/linear/arrow-left.svg';

import {
  SETTING_PAGE_ID_URL_PARAM_NAME,
  SettingPage,
} from './settings-page.types';
import { SettingsPageGrid } from './SettingsPageGrid';
import { SettingsPageSelectField } from './SettingsPageSelectField';
import { DashboardContentLayout } from '../../components/layout/DashboardContentLayout';
import { Page404 } from '../auth/Page404';

export interface SettingsPageProps {
  basePath: string;
  rootPageTitle: string;
  pages: SettingPage[];
}

export function SettingsPage({
  rootPageTitle,
  basePath,
  pages,
}: SettingsPageProps) {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState('');

  const settingPageId = useGetSettingPageIdFromUrlParams();

  const visiblePages = pages.filter((page) => page.visible !== false);

  const selectedSettingItem = visiblePages.find(
    (page) => page.path === `/${settingPageId}`
  );

  const highlightedPageIds = visiblePages
    .filter((page) => {
      const lowerSearchTerm = searchTerm.toLowerCase();

      return (
        page.title.toLowerCase().includes(lowerSearchTerm) ||
        page.description.toLowerCase().includes(lowerSearchTerm)
      );
    })
    .map((page) => page.id);

  const pageTitle = selectedSettingItem ? (
    <Link to={basePath} className={classes.backLink}>
      <Group display="inline-flex">
        <ArrowLeft />
        <span data-testid="settings-inner-page-title">
          {selectedSettingItem.title}
        </span>
      </Group>
    </Link>
  ) : (
    <Group position="apart" noWrap>
      <div data-testid="settings-root-page-title">{rootPageTitle}</div>

      <SettingsPageSelectField
        pages={visiblePages}
        onSearchChange={setSearchTerm}
        onChange={(value) => {
          if (!value) return;

          return navigate(`${basePath}${value}`);
        }}
      />
    </Group>
  );

  if (
    selectedSettingItem &&
    (selectedSettingItem.visible === false ||
      selectedSettingItem.isRouteBlocked)
  ) {
    return <Page404 />;
  }

  return (
    <DashboardContentLayout
      pageTitle={pageTitle}
      bg="gray.0"
      key={settingPageId || 'settings'}
    >
      {selectedSettingItem ? (
        <>{selectedSettingItem.component}</>
      ) : (
        <SettingsPageGrid
          basePath={basePath}
          pages={visiblePages}
          highlightedPageIds={highlightedPageIds}
          searchTerm={searchTerm}
        />
      )}
    </DashboardContentLayout>
  );
}

const useStyles = createStyles((theme) => ({
  backLink: {
    color: theme.colors.blue_gray[9],

    '&:hover': {
      color: theme.colors.blue_gray[9],
      textDecoration: 'none',
    },
  },
}));

export function useGetSettingPageIdFromUrlParams() {
  const { settingPageId } = useParams<{
    [SETTING_PAGE_ID_URL_PARAM_NAME]: string | undefined;
  }>();

  return settingPageId;
}
