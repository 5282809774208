import { Box, createStyles, Divider, Group, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import React from 'react';

import { CustomerWithControlledType } from '@portals/api/partners';
import { NameAbbreviationAvatar } from '@portals/core';
import { ReactComponent as Danger } from '@portals/icons/linear/danger.svg';

import { CustomerMenu } from '../CustomerMenu';

interface CustomerMarkerProps {
  color: string;
  customer: CustomerWithControlledType;
}

export function CustomerMarker({ color, customer }: CustomerMarkerProps) {
  const { classes, theme } = useStyles();
  const [showMenu, { close, open }] = useDisclosure(false);

  return (
    <Group
      className={classes.container}
      noWrap
      align="center"
      px="sm"
      spacing="xs"
      onMouseEnter={open}
      onMouseLeave={close}
    >
      <NameAbbreviationAvatar name={customer.name} size={30} radius={30} />

      <Text size="xs" className={classes.customerName}>
        {customer.name}
      </Text>

      {customer.incidents_count > 0 ? (
        <>
          <Group>
            <Divider orientation="vertical" className={classes.divider} />
          </Group>

          <Danger width={18} height={18} color={theme.colors[color][4]} />

          <Text size="sm">{customer.incidents_count}</Text>
        </>
      ) : null}

      {showMenu ? (
        <Box ml="xs">
          <CustomerMenu customer={customer} />
        </Box>
      ) : null}
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    height: 46,
    boxShadow: theme.shadows.sm,
    backgroundColor: theme.white,
    width: 'fit-content',
    borderRadius: 58,
    color: theme.colors.gray[7],
    cursor: 'pointer',
  },
  customerName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: 100,
  },
  divider: {
    height: 25,
    borderColor: theme.fn.rgba(theme.colors.blue_gray[2], 0.5),
  },
}));
