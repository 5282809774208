import { useCallback, useState, useRef } from 'react';

import { ConfirmationModalProps } from '../modals';

export interface OnOpenParams
  extends Omit<ConfirmationModalProps['data'], 'onApprove' | 'onCancel'> {}

interface UseAsyncConfirmationParams {
  shouldConfirm: boolean;
  onOpen: (params: OnOpenParams) => void;
}

export const useAsyncConfirmation = ({
  shouldConfirm,
  onOpen,
}: UseAsyncConfirmationParams) => {
  const [isOpen, setIsOpen] = useState(false);

  const resolve = useRef<(isConfirmed: boolean) => void>();
  const setResolveFn = useCallback(
    (resolveFn) => (resolve.current = resolveFn),
    [resolve]
  );

  const asyncConfirmationCheck = useCallback(
    (params: OnOpenParams) => {
      if (shouldConfirm) {
        setIsOpen(true);
        onOpen(params);

        return new Promise<boolean>((resolve) => {
          setResolveFn(resolve);
        });
      }

      return Promise.resolve(true);
    },
    [shouldConfirm, onOpen, setResolveFn]
  );

  const onResolve = useCallback(
    (isConfirmed) => {
      if (!resolve.current) return;

      return resolve.current(isConfirmed);
    },
    [resolve]
  );

  return {
    isOpen,
    toggleOpen: () => setIsOpen((isOpen) => !isOpen),
    onResolve,
    asyncConfirmationCheck,
  };
};
