import {
  Box,
  createStyles,
  NumberInput,
  NumberInputProps,
  Text,
} from '@mantine/core';
import { inRange, isNaN, toNumber } from 'lodash/fp';
import React, { useCallback, useMemo, useRef } from 'react';

import { FORMATTERS } from '../../../../utils/formatters';
import { NumberFormatType } from '../../../widgets.types';
import { relativeToNumMax } from '../segment-range-selector.utils';
import { RangeType } from '../segmented-range-selector.types';

export interface SegmentHandleProps {
  segmentMax: number;
  isDisabled: boolean;
  onChange: (value: number) => void;
  globalRange: RangeType;
  leftSideSegmentMax: number | undefined;
  rightSideSegmentMax: number | undefined;
  numberFormat: NumberFormatType;
  numOfDecimals: number;
  isEditMode: boolean;
  setIsEditMode: (value: boolean) => void;
}

export function SegmentValue({
  segmentMax,
  isDisabled,
  numberFormat,
  numOfDecimals,
  globalRange,
  leftSideSegmentMax,
  rightSideSegmentMax,
  onChange,
  isEditMode,
  setIsEditMode,
}: SegmentHandleProps) {
  const { classes, cx } = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);

  const formatter = useCallback(
    (value: number) => {
      if (numberFormat === 'scientific') {
        return FORMATTERS.decimal(value, numOfDecimals);
      }

      return FORMATTERS[numberFormat](value, numOfDecimals);
    },
    [numberFormat, numOfDecimals]
  );

  const { value, formattedValue } = useMemo(() => {
    const value = relativeToNumMax(segmentMax, globalRange);

    return {
      value,
      formattedValue: formatter ? formatter(value) : value,
    };
  }, [segmentMax, globalRange, formatter]);

  const onBlur = useCallback(() => {
    const currInputValue = toNumber(inputRef.current?.value);
    const valueWithDecimals = toNumber(
      currInputValue?.toFixed(numberFormat === 'none' ? 0 : numOfDecimals)
    );

    if (isNaN(valueWithDecimals)) {
      setIsEditMode(false);
    }

    const leftSideSegmentValue = relativeToNumMax(
      leftSideSegmentMax || 0,
      globalRange
    );
    const rightSideSegmentValue = relativeToNumMax(
      rightSideSegmentMax || 100,
      globalRange
    );

    if (
      inRange(leftSideSegmentValue, rightSideSegmentValue, valueWithDecimals)
    ) {
      const valueRange = globalRange.max - globalRange.min;
      const valueAdjustedToRange = valueWithDecimals - globalRange.min;
      const relativeValuePercentage = valueAdjustedToRange / valueRange;
      const relativeValue = relativeValuePercentage * 100;

      onChange(relativeValue);
    }

    setIsEditMode(false);
  }, [
    numberFormat,
    numOfDecimals,
    leftSideSegmentMax,
    globalRange,
    rightSideSegmentMax,
    setIsEditMode,
    onChange,
  ]);

  const onKeyDown: NumberInputProps['onKeyDown'] = useCallback(
    (event) => {
      if (event.key === 'Enter' || event.key === 'Escape') {
        onBlur();
      }
    },
    [onBlur]
  );

  return isEditMode ? (
    <NumberInput
      className={classes.numberInput}
      ref={inputRef}
      value={value}
      size="xs"
      maw={70}
      hideControls
      onBlur={onBlur}
      autoFocus
      onKeyDown={onKeyDown}
      min={leftSideSegmentMax}
      max={rightSideSegmentMax}
      data-autofocus
      precision={numberFormat === 'none' ? 0 : numOfDecimals}
      p={0}
    />
  ) : (
    <Box
      className={cx(classes.container, {
        'edit-mode': isEditMode,
        disabled: isDisabled,
      })}
      px="md"
      py="xs"
      onClick={() => !isEditMode && !isDisabled && setIsEditMode(true)}
    >
      <Text size="sm" color="gray.5" mb={-5}>
        {formattedValue}
      </Text>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    borderRadius: theme.radius.md,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
    transition: 'all 0.15s ease-in-out',

    '&.disabled': {
      userSelect: 'none',
    },

    '&:not(.edit-mode)&:not(.disabled)': {
      '&:hover': {
        background: theme.white,
        cursor: 'pointer',
        boxShadow: '0px 4px 14px 0px rgba(0, 0, 0, 0.11)',
      },
    },
  },
  numberInput: {
    transform: 'translateY(2px)',

    input: {
      textAlign: 'center',
      fontSize: theme.fontSizes.sm,
    },
  },
}));
