import { Box, createStyles } from '@mantine/core';
import { UseQueryResult } from '@tanstack/react-query';
import { AnimatePresence } from 'framer-motion';
import { compact, isEmpty, map } from 'lodash/fp';
import React, { useMemo, useRef } from 'react';

import { PaginatedTableProps, PaginationResponse } from '@portals/types';

import {
  LoadingState,
  NoDataState,
  NoFiltersResultsState,
} from '../components/data-states';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { TableDetailsPanel } from '../components/TableDetailsPanel';
import TableScrollWrapper from '../components/TableScrollWrapper';
import TBody from '../components/TBody';
import { TGrid } from '../components/TGrid';
import THead from '../components/THead';
import { usePaginatedQueryContext, useTableInstance } from '../context';
import { useOnRowClick } from '../table.hooks';
import { hasStickyColumn } from '../table.utils';

export function PaginatedTable<
  TData extends object,
  TKeyField extends keyof TData
>({
  name,
  keyField,
  noDataIndication,
  additionalActions,
  exportParams,
  noHeader = false,
  noFilters = false,
  isCompact = false,
  noColumnsSelection = false,
  readOnly = false,
  rowMenu,
  expandRow,
  detailsPanel,
  selectedItemsActions,
  viewType,
  gridView,
  columns,
  onRowClick,
}: PaginatedTableProps<TData, TKeyField>) {
  const tableRef = useRef<HTMLDivElement>();

  const { classes, cx } = useStyles(noHeader);
  const instance = useTableInstance<TData>();
  const { data, state, rows } = instance;

  const {
    clickedRowOriginalId,
    onRowClickHandler,
    onCloseDetailsPanel,
    clickedRow,
  } = useOnRowClick<TData, TKeyField>({
    keyField,
    onRowClick,
    detailsPanel,
    rows,
  });

  const query: UseQueryResult<PaginationResponse<TData>> =
    usePaginatedQueryContext();
  const totalCount = query?.data?.page_info?.total_count || 0;
  const isFetching = query.isFetching;

  const hasActiveFilters = !isEmpty(
    compact(map('filterValue', instance.allColumns))
  );

  const content = useMemo(() => {
    if (!state) return null;

    if (isEmpty(data) && (!hasActiveFilters || isCompact)) {
      return (
        <>
          <TableScrollWrapper
            isCompact={isCompact}
            hasStickyColumn={hasStickyColumn(columns)}
          >
            <div />
            <div />
          </TableScrollWrapper>

          {isFetching ? (
            <LoadingState />
          ) : (
            <NoDataState {...noDataIndication} />
          )}

          <Footer isCompact={isCompact} totalCount={0} />
        </>
      );
    }

    if (isEmpty(data)) {
      return (
        <>
          <TableScrollWrapper
            isCompact={isCompact}
            hasStickyColumn={hasStickyColumn(columns)}
          >
            <THead<TData, TKeyField>
              noFilters={noFilters}
              isCompact={isCompact}
              columns={columns}
            />

            <div />
          </TableScrollWrapper>

          {isFetching ? <LoadingState /> : <NoFiltersResultsState />}

          <Footer isCompact={isCompact} totalCount={0} />
        </>
      );
    }

    return (
      <>
        <TableScrollWrapper
          isCompact={isCompact}
          hasStickyColumn={hasStickyColumn(columns)}
        >
          <THead<TData, TKeyField>
            noFilters={noFilters}
            isCompact={isCompact}
            columns={columns}
            viewType={viewType}
          />

          {viewType === 'grid' ? (
            <TGrid<TData, TKeyField>
              data={rows}
              gridView={gridView}
              onRowClick={onRowClickHandler}
            />
          ) : (
            <TBody<TData, TKeyField>
              data={rows}
              isCompact={isCompact}
              rowMenu={rowMenu}
              expandRow={expandRow}
              onRowClick={onRowClickHandler}
              readOnly={readOnly}
              clickedRowOriginalId={clickedRowOriginalId}
              keyField={keyField}
            />
          )}
        </TableScrollWrapper>

        {detailsPanel && (
          <AnimatePresence>
            {clickedRow?.id ? (
              <TableDetailsPanel
                key={clickedRow.id}
                type={detailsPanel.type}
                width={detailsPanel.width}
                className={detailsPanel.className}
                onClose={onCloseDetailsPanel}
              >
                {detailsPanel.renderer({
                  row: clickedRow,
                  onClose: onCloseDetailsPanel,
                  tableRef,
                })}
              </TableDetailsPanel>
            ) : null}
          </AnimatePresence>
        )}

        <Footer isCompact={isCompact} totalCount={totalCount} />

        {isFetching ? <LoadingState /> : null}
      </>
    );
  }, [
    keyField,
    clickedRowOriginalId,
    columns,
    state,
    data,
    hasActiveFilters,
    isCompact,
    noFilters,
    rows,
    onRowClickHandler,
    rowMenu,
    expandRow,
    detailsPanel,
    clickedRow,
    onCloseDetailsPanel,
    viewType,
    gridView,
    totalCount,
    isFetching,
    noDataIndication,
    readOnly,
  ]);

  return (
    <Box
      ref={tableRef}
      className={cx('paginated-table-container', classes.container)}
    >
      {noHeader ? null : (
        <Box className="header-container" mb="md">
          <Header<TData, TKeyField>
            name={name}
            exportParams={exportParams}
            additionalActions={additionalActions}
            isCompact={isCompact}
            selectedItemsActions={selectedItemsActions}
            noColumnsSelection={noColumnsSelection}
          />
        </Box>
      )}

      {content}
    </Box>
  );
}

const useStyles = createStyles((theme, noHeader: boolean) => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'grid',
    gridTemplateRows: noHeader ? '1fr' : 'max-content 1fr',
    position: 'relative',
  },
}));
