import { Button, Stack, Text, Center, CopyButton } from '@mantine/core';
import React from 'react';

import { ModalCenteredMediaLayout } from '@portals/core';
import { ModalProps } from '@portals/framework';
import { ReactComponent as Copy } from '@portals/icons/linear/copy.svg';
import { ReactComponent as TickCircle } from '@portals/icons/linear/tick-circle.svg';

import emptyCartSrc from '../../assets/img/empty-cart-block.svg';

export function PreventProductPurchase({
  closeMe,
}: ModalProps<{ message?: string }>) {
  return (
    <ModalCenteredMediaLayout
      opened
      onClose={closeMe}
      title="Only admins can purchase products"
      media={<img src={emptyCartSrc} />}
    >
      <Stack>
        <Stack px="lg">
          <Text align="center">
            Your current user status does not permit purchases. Our store
            platform currently supports purchases by admin users only.
          </Text>

          <Text align="center">
            Please contact your admin or share the product details with them to
            continue purchasing.
          </Text>
        </Stack>

        <Center>
          <CopyButton value={window.location.href}>
            {({ copied, copy }) => (
              <Button
                onClick={copy}
                variant="light"
                radius="lg"
                leftIcon={copied ? <TickCircle /> : <Copy />}
                color={copied ? 'teal' : 'primary'}
              >
                {copied ? 'Copied to clipboard' : 'Copy product URL'}
              </Button>
            )}
          </CopyButton>
        </Center>
      </Stack>
    </ModalCenteredMediaLayout>
  );
}
