import { createStyles } from '@mantine/core';
import { motion, MotionProps, PanInfo, useDragControls } from 'framer-motion';
import React, { MutableRefObject, useCallback, useMemo, useState } from 'react';

import { Handle } from './Handle';
import { SegmentValue } from './SegmentValue';
import {
  NumberFormatType,
  OnAddCustomColorFn,
  WidgetColorType,
} from '../../../widgets.types';
import {
  calculateAdjustedXPosition,
  computeDragLimit,
  isSegmentValueWithinRange,
  maxValueToXPos,
  relativeToNumMax,
  xPosToMaxValue,
} from '../segment-range-selector.utils';

export interface SegmentHandleProps {
  containerRef: MutableRefObject<HTMLDivElement>;
  segmentMax: number;
  isDisabled: boolean;
  leftSideSegmentMax: number | undefined;
  rightSideSegmentMax: number | undefined;
  index: number;
  color: WidgetColorType;
  onChangeSegmentColor: (index: number, color: WidgetColorType) => void;
  onUpdateSegmentMax: (index: number, max: number) => void;
  onUpdateLocalSegmentMax: (index: number, max: number) => void;
  onRemoveSegment: (index: number) => void;
  containerWidth: number;
  globalRange: { min: number; max: number };
  numberFormat: NumberFormatType;
  numOfDecimals: number;
  colors: Array<WidgetColorType> | undefined;
  onAddCustomColor: OnAddCustomColorFn | undefined;
}

export function SegmentHandle({
  segmentMax,
  isDisabled,
  leftSideSegmentMax,
  rightSideSegmentMax,
  index,
  color,
  onRemoveSegment,
  onChangeSegmentColor,
  onUpdateSegmentMax,
  onUpdateLocalSegmentMax,
  containerWidth,
  containerRef,
  globalRange,
  numberFormat,
  numOfDecimals,
  colors,
  onAddCustomColor,
}: SegmentHandleProps) {
  const isOdd = index % 2 === 1;

  const dragControls = useDragControls();
  const { classes, cx } = useSegmentHandleStyles();

  const [isPristine, setIsPristine] = useState(true);
  const [isDragging, setIsDragging] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isPerformingAction, setIsPerformingAction] = useState(false);
  const [isColorPickerOpen, setIsColorPicketOpen] = useState(false);

  const xPosition = maxValueToXPos(containerWidth, segmentMax);

  const dragConstraints = useMemo(() => {
    return computeDragLimit(
      containerWidth,
      leftSideSegmentMax || 0,
      rightSideSegmentMax || containerWidth
    );
  }, [containerWidth, rightSideSegmentMax, leftSideSegmentMax]);

  const onDrag = useCallback(
    (event: PointerEvent, info: PanInfo | { point: { x: number } }) => {
      if (!(event.target instanceof Element)) return;

      const adjustedXPosition = calculateAdjustedXPosition(
        info.point.x,
        containerRef.current.getBoundingClientRect().x
      );

      const segmentMax = xPosToMaxValue(adjustedXPosition, containerWidth);
      const segmentValue = relativeToNumMax(segmentMax, globalRange);

      const isWithinRange = isSegmentValueWithinRange(
        segmentValue,
        leftSideSegmentMax,
        rightSideSegmentMax,
        globalRange
      );

      if (isWithinRange) {
        onUpdateLocalSegmentMax(index, segmentMax);
      }
    },
    [
      containerRef,
      containerWidth,
      globalRange,
      index,
      leftSideSegmentMax,
      onUpdateLocalSegmentMax,
      rightSideSegmentMax,
    ]
  );

  const snapHandleToInitialDragPosition = useCallback(
    (event: React.PointerEvent<Element>) => {
      if (
        isDisabled ||
        isColorPickerOpen ||
        isPerformingAction ||
        !(event.target instanceof Element)
      ) {
        return;
      }

      const adjustedXPosition = calculateAdjustedXPosition(
        event.clientX,
        containerRef.current.getBoundingClientRect().x
      );

      const segmentMax = xPosToMaxValue(adjustedXPosition, containerWidth);
      const segmentValue = relativeToNumMax(segmentMax, globalRange);

      const isWithinRange = isSegmentValueWithinRange(
        segmentValue,
        leftSideSegmentMax,
        rightSideSegmentMax,
        globalRange
      );

      if (isWithinRange) {
        dragControls.start(event, {
          snapToCursor: true,
        });

        onDrag(event.nativeEvent, { point: { x: event.clientX } });
      }
    },
    [
      containerRef,
      containerWidth,
      dragControls,
      globalRange,
      isColorPickerOpen,
      isDisabled,
      isPerformingAction,
      leftSideSegmentMax,
      onDrag,
      rightSideSegmentMax,
    ]
  );

  const onDragStart = useCallback(
    (event: PointerEvent, info: PanInfo) => {
      if (isPristine) {
        setIsPristine(false);
      }

      setIsDragging(true);
      onDrag(event, info);
    },
    [isPristine, onDrag]
  );

  const onDragEnd: MotionProps['onDragEnd'] = useCallback(() => {
    setIsDragging(false);

    onUpdateSegmentMax(index, segmentMax);
  }, [index, segmentMax, onUpdateSegmentMax]);

  const onValueInputChange = useCallback(
    (max: number) => onUpdateSegmentMax(index, max),
    [index, onUpdateSegmentMax]
  );

  const onRemove = useCallback(
    () => onRemoveSegment(index),
    [index, onRemoveSegment]
  );

  const onChangeColor = useCallback(
    (color: WidgetColorType) => onChangeSegmentColor(index, color),
    [index, onChangeSegmentColor]
  );

  const valueClassName = cx(classes.valueWrapper, {
    'is-odd': isOdd,
  });

  return (
    <>
      <motion.div
        className={cx(classes.container, {
          disabled: isDisabled,
        })}
        dragControls={dragControls}
        drag={isDisabled ? false : 'x'}
        dragConstraints={dragConstraints}
        dragElastic={false}
        dragListener={false}
        dragMomentum={false}
        onPointerDown={snapHandleToInitialDragPosition}
        onDragStart={onDragStart}
        onDrag={onDrag}
        onDragEnd={onDragEnd}
        animate={{
          x: xPosition,
          transition: {
            duration: 0,
          },
        }}
        style={{
          top: isOdd ? undefined : 30,
          bottom: isOdd ? 30 : undefined,
          zIndex: index + 2,
          touchAction: 'none',
        }}
      >
        <Handle
          colors={colors}
          onAddCustomColor={onAddCustomColor}
          isDragging={isDragging}
          position={isOdd ? 'bottom' : 'top'}
          color={color}
          isDisabled={isDisabled}
          setIsPerformingAction={setIsPerformingAction}
          onChangeColor={onChangeColor}
          isColorPickerOpen={isColorPickerOpen}
          setIsColorPicketOpen={setIsColorPicketOpen}
          onRemove={onRemove}
        />
      </motion.div>

      <div
        className={valueClassName}
        style={{
          transform: `translateX(calc(${xPosition}px - 50%))`,
        }}
      >
        <SegmentValue
          segmentMax={segmentMax}
          isDisabled={Boolean(isDisabled)}
          globalRange={globalRange}
          leftSideSegmentMax={leftSideSegmentMax}
          rightSideSegmentMax={rightSideSegmentMax}
          numberFormat={numberFormat}
          numOfDecimals={numOfDecimals}
          onChange={onValueInputChange}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
        />
      </div>
    </>
  );
}

const useSegmentHandleStyles = createStyles(() => ({
  container: {
    userSelect: 'none',
    zIndex: 2,
    position: 'absolute',
    cursor: 'grab',
    overflow: 'visible',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',

    '&:active': {
      cursor: 'grabbing',
    },

    '&.disabled': {
      cursor: 'default',

      '&:active': {
        cursor: 'default',
      },
    },
  },
  valueWrapper: {
    zIndex: 1,
    position: 'absolute',

    '&:not(.is-odd)': {
      top: -5,
    },

    '&.is-odd': {
      bottom: 0,
    },
  },
}));
