import { LoadingOverlay } from '@mantine/core';
import React from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { useStoreListing } from '@portals/api/organizations';

import { useStoreCurrency } from '../../../redux/hooks/store';
import { ProductDetails } from '../../components/store/ProductDetails';

export function StoreListingPage() {
  const { storeListingSlug } = useParams<{
    storeListingSlug: string;
  }>();
  const storeCurrency = useStoreCurrency();

  const storeListing = useStoreListing(storeListingSlug);

  if (storeListing.isLoading) {
    return <LoadingOverlay visible />;
  }

  if (storeListing.data?.status !== 'published') {
    return <Navigate to="/store" replace />;
  }

  return (
    <ProductDetails
      storeListing={storeListing.data}
      currencyCode={storeCurrency.selected}
    />
  );
}
