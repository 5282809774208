import {
  Box,
  createStyles,
  Group,
  Highlight,
  HighlightProps,
  Text,
} from '@mantine/core';
import React, { forwardRef } from 'react';

import { ReactComponent as TickCircleIcon } from '@portals/icons/linear/tick-circle.svg';

import { PartnerAvatar } from '../../components/PartnerAvatar';

interface TenantItemProps {
  onClick: VoidFunction;
  highlightTerm: string;
  isHovered: boolean;
  isSelected: boolean;
  displayName: string;
  name: string;
  logoUrl: string | null;
  metadata: string[] | null;
}

export const TenantItem = forwardRef<HTMLDivElement, TenantItemProps>(
  (
    {
      onClick,
      highlightTerm,
      isHovered,
      isSelected,
      metadata,
      name,
      displayName,
      logoUrl,
    },
    ref
  ) => {
    const { classes, cx } = useStyles();

    return (
      <div
        data-testid={`tenant-${displayName}`}
        onClick={onClick}
        ref={ref}
        className={cx(classes.item, {
          [classes.hoveredItem]: isHovered,
          [classes.selectedItem]: isSelected,
        })}
      >
        <PartnerAvatar
          radius="md"
          size={48}
          src={logoUrl}
          partnerName={displayName}
          imageProps={{ loading: 'lazy' }}
        />

        <Box sx={{ overflow: 'hidden' }}>
          {metadata && (
            <Group spacing={0}>
              {metadata.map((metaItem, index) => (
                <Text
                  key={index}
                  fz="xs"
                  c="gray.5"
                  className={classes.metaItem}
                >
                  {metaItem}
                </Text>
              ))}
            </Group>
          )}

          <Highlight
            truncate
            size="md"
            fw={600}
            highlight={highlightTerm}
            highlightStyles={highlightStyles}
          >
            {displayName}
          </Highlight>

          <Highlight
            truncate
            size="xs"
            fw={500}
            color="gray.7"
            highlight={highlightTerm}
            highlightStyles={highlightStyles}
          >
            {name}
          </Highlight>
        </Box>

        {isSelected && <TickCircleIcon />}
      </div>
    );
  }
);

const useStyles = createStyles((theme) => ({
  item: {
    display: 'grid',
    gridTemplateColumns: '48px 1fr 24px',
    gap: theme.spacing.md,
    alignItems: 'center',
    padding: theme.spacing.md,
    borderRadius: theme.radius.md,
    border: `1px solid transparent`,
    cursor: 'pointer',
    userSelect: 'none',

    ...theme.fn.hover({
      backgroundColor: theme.colors.primary[1],
    }),
  },
  hoveredItem: {
    borderColor: theme.colors.gray[5],
    backgroundColor: theme.colors.primary[1],
  },
  selectedItem: {
    '&, &:hover': {
      backgroundColor: theme.colors.primary[2],
    },
  },
  metaItem: {
    '&:not(:last-child):after': {
      content: "''",
      display: 'inline-block',
      verticalAlign: 'middle',
      width: 3,
      height: 3,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
      marginInline: 6,
    },
  },
}));

const highlightStyles: HighlightProps['highlightStyles'] = {
  padding: 0,
};
