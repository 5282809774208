import { Box, createStyles } from '@mantine/core';
import { isEmpty } from 'lodash/fp';
import React from 'react';

import { DeviceTelemetryType } from '@portals/api/organizations';
import { EmptyState } from '@portals/table';
import { prettyTime } from '@portals/utils';

import { TelemetryIcon } from './TelemetryIcon';
import { TelemetryItem } from './TelemetryItem';

interface TelemetryListProps {
  telemetries: DeviceTelemetryType[];
  selectedTelemetryId: string | undefined;
  onSelectTelemetry: (telemetry: DeviceTelemetryType) => void;
}

export function TelemetryList({
  telemetries,
  selectedTelemetryId,
  onSelectTelemetry,
}: TelemetryListProps) {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      {isEmpty(telemetries) ? (
        <Box sx={{ height: '100%' }}>
          <EmptyState label="No telemetries" />
        </Box>
      ) : (
        telemetries.map((telemetry) => {
          return (
            <TelemetryItem
              key={telemetry.id}
              isActive={selectedTelemetryId === telemetry.id}
              icon={
                <Box sx={{ zIndex: 2 }}>
                  <TelemetryIcon />
                </Box>
              }
              label={prettyTime(telemetry.created_at)}
              onClick={() => onSelectTelemetry(telemetry)}
            />
          );
        })
      )}
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    height: '100%',
    paddingBlock: theme.spacing.xl,
    paddingInline: 32,
  },
}));
