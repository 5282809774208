import { Checkbox } from '@mantine/core';
import React, { FC } from 'react';

import { FieldRendererProps } from '@portals/types';

const CheckboxField: FC<FieldRendererProps> = ({
  field,
  value,
  setFieldValue,
  disabled,
  readOnly,
  inputProps = {},
}) => (
  <Checkbox
    id={field.name}
    data-testid={'checkbox-' + field.name}
    name={field.name}
    label={field.title}
    disabled={disabled || readOnly}
    onChange={(event) => setFieldValue?.(field.name, event.target.checked)}
    checked={value}
    {...inputProps}
  />
);

export default CheckboxField;
