import {
  Button,
  createStyles,
  Group,
  LoadingOverlay,
  Modal,
  ModalProps as MantineModalProps,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  useDeviceModelWidgetsData,
  useMigrateLegacyWidgets,
} from '@portals/api/partners';
import { newDeviceWidgetsSrc } from '@portals/assets';
import { migrateWidgetsAndAdjustLayout } from '@portals/device-widgets';

import { ModalProps } from '../../../components/Modals';

export interface NewDeviceWidgetsProps
  extends ModalProps<{
    modelId: string;
    onDismiss: () => void;
  }> {}

export function NewDeviceWidgets({ closeMe, data }: NewDeviceWidgetsProps) {
  const { classes } = contentStyles();

  const navigate = useNavigate();
  const legacyDeviceWidgets = useDeviceModelWidgetsData(data.modelId);
  const migrateLegacyWidgets = useMigrateLegacyWidgets(data.modelId);

  const onClose = () => {
    data.onDismiss?.();
    closeMe();
  };

  const onMigrate = async () => {
    const migratedWidgets = migrateWidgetsAndAdjustLayout(
      legacyDeviceWidgets.data
    );

    try {
      navigate(`/models/${data.modelId}/monitoring_dashboard`);

      await migrateLegacyWidgets.mutateAsync({
        widgets: migratedWidgets,
      });

      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      opened
      onClose={onClose}
      padding={0}
      size={714}
      radius={15}
      closeOnEscape={false}
      closeOnClickOutside={false}
      withCloseButton={false}
      styles={modalStyles}
    >
      <LoadingOverlay visible={migrateLegacyWidgets.isLoading} />

      <img src={newDeviceWidgetsSrc} alt="New Organization Dashboard" />

      <Stack className={classes.content} align="center" spacing="xl">
        <Title order={4} color="gray.9" weight={400} align="center">
          Introducing Our New Device Widgets!
        </Title>

        <Text size="md" color="gray.9" align="center">
          We have updated the device monitoring widgets. Click to update and
          review the new look of your dashboard.
        </Text>
      </Stack>

      <Group position="center" grow className={classes.footer}>
        <Button variant="default" onClick={onClose}>
          Not Now
        </Button>

        <Button onClick={onMigrate}>Update Dashboard</Button>
      </Group>
    </Modal>
  );
}

const modalStyles: MantineModalProps['styles'] = (theme) => ({
  content: {
    padding: '0 !important',
    borderRadius: theme.radius.md,
    overflow: 'hidden',
    background: 'transparent',
  },
  body: {
    borderRadius: theme.radius.md,
  },
});

const contentStyles = createStyles((theme) => ({
  content: {
    padding: 32,
    background: theme.white,
  },

  footer: {
    padding: theme.spacing.xl,
    borderTop: `1px solid ${theme.colors.gray[3]}`,
    background: theme.white,
  },
}));
