import { Alert, Loader, Text } from '@mantine/core';
import React from 'react';

export function ConfigurationEvent() {
  return (
    <Alert
      title={
        <Text color="black" size="md" weight={600}>
          Updating configuration...
        </Text>
      }
      icon={<Loader />}
      color="blue_accent"
      p="xl"
      radius="lg"
    >
      <Text color="gray.8">
        Your recent configuration update is currently pending. Please note that
        some functionalities may be affected until the update is fully
        processed.
      </Text>
    </Alert>
  );
}
