import { createStyles, Text } from '@mantine/core';
import React from 'react';

import { SpacesIncidentsRankTable } from './SpacesIncidentsRankTable';
import { WidgetCardWrapper } from '../common/WidgetCardWrapper';

export function SpacesIncidentsRank() {
  const { classes } = useStyles();

  return (
    <WidgetCardWrapper
      paperProps={{
        className: classes.container,
      }}
      header={
        <Text
          size="md"
          color="gray.5"
          data-testid="spaces-incidents-rank-title"
        >
          Spaces with Most Open Incidents
        </Text>
      }
    >
      <SpacesIncidentsRankTable />
    </WidgetCardWrapper>
  );
}

const useStyles = createStyles(() => ({
  container: {
    overflow: 'hidden',

    '.smart-table-container': {
      width: 'calc(100% + 30px)',
      transform: 'translateX(-15px)',

      '.thead': {
        backgroundColor: 'transparent',
      },
    },
  },
}));
