import {
  Box,
  Button,
  createStyles,
  Group,
  SimpleGrid,
  Text,
} from '@mantine/core';
import { includes, size } from 'lodash/fp';
import React, { FC } from 'react';

import { useRouteModals } from '@portals/framework/route-modals';
import { useOpenModal } from '@portals/redux';
import { VerticalScrollBar } from '@portals/scrollbar';
import { EmptyState } from '@portals/table';

import { CATEGORY } from './connect.constants';
import { VendorsGroupType } from './connect.types';
import { VendorCard } from './VendorCard';

interface VendorsListProps {
  vendorsGroups: Array<VendorsGroupType>;
  selectedVendors: Array<string>;
  toggleSelectedVendor: (vendorId: string) => void;
}

export const VendorsList: FC<VendorsListProps> = ({
  vendorsGroups = [],
  selectedVendors,
  toggleSelectedVendor,
}) => {
  const { classes } = useStyles();
  const routeModalsContext = useRouteModals();
  const openModal = useOpenModal();

  const numOfSelected = size(selectedVendors);

  return (
    <Box className={classes.container}>
      {size(vendorsGroups) ? (
        <VerticalScrollBar
          renderThumbVertical={() => <div />}
          renderView={(props) => <Box className={classes.list} {...props} />}
        >
          {vendorsGroups.map(({ category, vendors = [] }) => (
            <Box key={category} className={classes.category}>
              <Text className={classes.categoryTitle}>
                {CATEGORY[category]}
              </Text>

              <SimpleGrid cols={4}>
                {vendors.map((vendor) => (
                  <VendorCard
                    key={vendor.id}
                    {...vendor}
                    onClick={() => toggleSelectedVendor(vendor.id)}
                    isSelected={includes(vendor.id, selectedVendors)}
                  />
                ))}
              </SimpleGrid>
            </Box>
          ))}
        </VerticalScrollBar>
      ) : (
        <EmptyState label="No vendors" />
      )}

      <Group position="right" className={classes.footer} spacing="md">
        <Button variant="default" onClick={routeModalsContext.onClose}>
          Cancel
        </Button>
        <Button
          disabled={!numOfSelected}
          onClick={() => {
            routeModalsContext.onClose();
            openModal('Connect');
          }}
        >
          Connect {numOfSelected || ''}
        </Button>
      </Group>
    </Box>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateRows: '1fr 88px',
  },
  list: {
    padding: `${theme.spacing.xl} 30px`,
    width: '100%',
    backgroundColor: theme.colors.gray[0],
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
    gap: theme.spacing.md,
  },
  category: {
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
    gap: theme.spacing.md,
    height: 'fit-content',
  },
  categoryTitle: {
    color: theme.colors.blue_gray[8],
    fontSize: theme.fontSizes.lg,
  },
  footer: {
    borderTop: `1px solid ${theme.colors.gray[2]}`,
    padding: `0 ${theme.spacing.xl}`,
  },
}));
