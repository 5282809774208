import { Badge, createStyles, Group, Text, Tooltip } from '@mantine/core';
import { sum } from 'lodash/fp';
import React from 'react';

import {
  DeviceDetailsType,
  DeviceType,
  useDeviceCommands,
} from '@portals/api/organizations';
import { IncidentsByDeviceTooltip } from '@portals/framework';
import { ReactComponent as Danger } from '@portals/icons/linear/danger.svg';
import { PaginatedFilterTypeEnum } from '@portals/types';
import { getDeviceHighestIncidentPriority } from '@portals/utils';

interface DeviceIncidentsCounterProps {
  device: DeviceType;
}

export function DeviceCounters({ device }: DeviceIncidentsCounterProps) {
  const numOfIncidents = sum(device.incidents);

  if (!numOfIncidents) return null;

  return (
    <IncidentsByDeviceTooltip device={device}>
      <Group align="center" sx={{ height: '100%' }}>
        <Badge
          color={getDeviceHighestIncidentPriority(device.incidents)}
          radius="xl"
          px="xs"
          size="sm"
          variant="filled"
        >
          {numOfIncidents}
        </Badge>
      </Group>
    </IncidentsByDeviceTooltip>
  );
}

export function DeviceIncidentsCounterWithIcon({
  device,
}: DeviceIncidentsCounterProps) {
  const { classes, theme } = incidentsCounterUseStyles();
  const numOfIncidents = sum(device.incidents);

  const dangerIconColor = getDeviceHighestIncidentPriority(device.incidents);

  return (
    <IncidentsByDeviceTooltip device={device}>
      <Group align="center" spacing={4} className={classes.container} noWrap>
        <Danger
          color={theme.fn.themeColor(dangerIconColor)}
          width={16}
          height={16}
        />

        <Text size="xs" color="gray.5">
          {numOfIncidents}
        </Text>
      </Group>
    </IncidentsByDeviceTooltip>
  );
}

const incidentsCounterUseStyles = createStyles((theme) => ({
  container: {
    border: `1px solid ${theme.colors.gray[3]}`,
    borderRadius: 21,
    padding: `5px 12px`,
  },
}));

export function DeviceCommandsCounter({ device }: DeviceIncidentsCounterProps) {
  const deviceCommands = useDeviceCommands(device.id, {
    pagination: {
      page: 0,
      pageSize: 1,
    },
    filters: [
      {
        id: 'status',
        value: 'pending',
        type: PaginatedFilterTypeEnum.Eq,
      },
    ],
  });

  const numOfPendingCommands = deviceCommands.data?.page_info?.total_count || 0;

  if (!numOfPendingCommands) return null;

  return (
    <Tooltip label={`Commands: ${numOfPendingCommands} pending`} withinPortal>
      <Group align="center" sx={{ height: '100%' }}>
        <Badge color="blue_gray" radius="xl" px="xs" size="sm" variant="filled">
          {numOfPendingCommands}
        </Badge>
      </Group>
    </Tooltip>
  );
}

export function DeviceActiveTicketsCounter({
  device,
}: {
  device: DeviceDetailsType;
}) {
  if (!device.unresolved_tickets_count) return null;

  return (
    <Badge color="blue_gray" radius="xl" px="xs" size="sm" variant="filled">
      {device.unresolved_tickets_count}
    </Badge>
  );
}
