import { LoadingOverlay, Stack } from '@mantine/core';
import { isEmpty } from 'lodash/fp';
import React, { FC } from 'react';
import Scrollbars from 'react-custom-scrollbars';

import { IncidentType } from '@portals/api/organizations';
import { useItemsVirtualization } from '@portals/framework';
import { PaginatedQueryParamsType } from '@portals/types';

import { IncidentItem } from './IncidentItem';
import { ReactComponent as EmptyTask } from '../../assets/empty-task.svg';

interface ListProps {
  itemsParams: PaginatedQueryParamsType<IncidentType>;
  endpointUrl: string;
  itemsKey: string;
  emptyStateLabel: string;
}

export const List: FC<ListProps> = ({
  itemsParams,
  endpointUrl,
  itemsKey,
  emptyStateLabel,
}) => {
  const {
    flattenItems,
    isFetching,
    virtualItems,
    ScrollContainerRef,
    containerStyle,
  } = useItemsVirtualization<IncidentType>(
    itemsParams,
    endpointUrl,
    itemsKey,
    (item) => <IncidentItem item={item} />
  );

  if (isEmpty(flattenItems) && isFetching) {
    return <LoadingOverlay visible />;
  }

  if (isEmpty(flattenItems) && !isFetching)
    return (
      <Stack
        sx={{ height: '100%', width: '100%' }}
        align="center"
        justify="center"
      >
        <EmptyTask />

        {emptyStateLabel}
      </Stack>
    );

  return (
    <Scrollbars
      autoHide
      hideTracksWhenNotNeeded
      key="table-scroll-wrapper"
      ref={(node: (Scrollbars & { view: HTMLElement }) | null) => {
        if (!node) return;

        ScrollContainerRef.current = node.view;
      }}
    >
      <div style={containerStyle}>{virtualItems}</div>
    </Scrollbars>
  );
};
