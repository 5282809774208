import {
  Accordion,
  AccordionProps,
  ActionIcon,
  createStyles,
  Group,
  Stack,
  Text,
} from '@mantine/core';
import { AnimatePresence, motion } from 'framer-motion';
import { filter, size } from 'lodash/fp';
import React from 'react';

import { ReactComponent as CollapseRight } from '@portals/icons/linear/collapse-right.svg';
import {
  CurrencyCode,
  InvoiceItem,
  PaymentIntervalEnum,
  PaymentMethodType,
} from '@portals/types';

import { InvoiceItemRow } from './InvoiceItemRow';

interface InvoiceItemsPanelProps {
  invoiceItems: Array<InvoiceItem>;
  itemsCount: number;
  isOpen: boolean;
  currency: CurrencyCode;
  onClose: () => void;
  paymentMethod: PaymentMethodType;
  orderId: string;
}

export function InvoiceItemsPanel({
  invoiceItems,
  isOpen,
  onClose,
  currency,
  itemsCount,
  paymentMethod,
  orderId,
}: InvoiceItemsPanelProps) {
  const { classes } = useStyles();

  const subscriptionItems = filter(
    (item) => item.payment_interval !== PaymentIntervalEnum.OneTime,
    invoiceItems
  );

  const purchasesItems = filter(
    (item) => item.payment_interval === PaymentIntervalEnum.OneTime,
    invoiceItems
  );

  return (
    <>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '100%' }}
            transition={{ type: 'linear' }}
            className={classes.wrapper}
          >
            <Stack spacing="xxl">
              <Group position="apart">
                <Text size="md">Items ({itemsCount})</Text>

                <ActionIcon variant="subtle" onClick={onClose}>
                  <CollapseRight width={18} height={18} />
                </ActionIcon>
              </Group>

              <Stack>
                <Accordion
                  multiple
                  chevronPosition="left"
                  styles={accordionStyles}
                  defaultValue={['subscriptions', 'purchases']}
                >
                  {size(subscriptionItems) > 0 && (
                    <Accordion.Item value="subscriptions">
                      <Accordion.Control>
                        <Text size="sm" color="blue_gray.8">
                          Subscriptions ({size(subscriptionItems)})
                        </Text>
                      </Accordion.Control>
                      <Accordion.Panel>
                        <Stack>
                          {subscriptionItems.map((subscriptionItem) => (
                            <InvoiceItemRow
                              key={subscriptionItem.id}
                              invoiceItem={subscriptionItem}
                              currencyCode={currency}
                              paymentMethod={paymentMethod}
                              orderId={orderId}
                            />
                          ))}
                        </Stack>
                      </Accordion.Panel>
                    </Accordion.Item>
                  )}

                  {size(purchasesItems) > 0 && (
                    <Accordion.Item value="purchases">
                      <Accordion.Control>
                        <Text size="sm" color="blue_gray.8">
                          Purchases ({size(purchasesItems)})
                        </Text>
                      </Accordion.Control>
                      <Accordion.Panel>
                        <Stack>
                          {purchasesItems.map((purchasesItem) => (
                            <InvoiceItemRow
                              key={purchasesItem.id}
                              invoiceItem={purchasesItem}
                              currencyCode={currency}
                              paymentMethod={paymentMethod}
                              orderId={orderId}
                            />
                          ))}
                        </Stack>
                      </Accordion.Panel>
                    </Accordion.Item>
                  )}
                </Accordion>
              </Stack>
            </Stack>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'fixed',
    backgroundColor: theme.white,
    top: theme.other.navbarHeight,
    right: 400,
    width: 900,
    height: `calc(100vh - ${theme.other.navbarHeight}px)`,
    boxShadow: '6px 0px 41px 0px rgba(0, 0, 0, 0.13)',
    clipPath: 'inset(0 0 0 -41px)',
    padding: theme.spacing.xxl,
  },
}));

const accordionStyles: AccordionProps['styles'] = (theme) => ({
  item: {
    borderBottom: 'none',
  },
});
