import { Select, SelectProps } from '@mantine/core';
import React, { forwardRef } from 'react';

import { ReactComponent as ChevronDownIcon } from '@portals/icons/linear/chevron-down.svg';
import { CreditCardType } from '@portals/types';

import { CardDetails } from './CardDetails';

interface CreditCardsSelectProps
  extends Omit<SelectProps, 'styles' | 'itemComponent' | 'data'> {
  data: Array<{ value: string; label: string; card: CreditCardType }>;
}

export function CreditCardsSelect({
  data,
  ...selectProps
}: CreditCardsSelectProps) {
  return (
    <Select
      size="xl"
      withinPortal
      label="Transfer to"
      placeholder="Select card"
      data={data}
      itemComponent={SelectItem}
      styles={selectStyles}
      rightSection={<ChevronDownIcon width={24} height={24} />}
      data-testid="transfer-payment-to-select"
      {...selectProps}
    />
  );
}

const selectStyles: SelectProps['styles'] = (theme) => ({
  input: {
    borderColor: theme.colors.gray[3],
    borderRadius: theme.radius.md,
    fontSize: theme.fontSizes.sm,
  },

  item: {
    paddingBlock: theme.spacing.sm,
  },

  label: {
    color: theme.colors.gray[6],
    fontWeight: 600,
    fontSize: theme.fontSizes.sm,
  },

  rightSection: { pointerEvents: 'none' },
});

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  value: string;
  label: string;
  card: CreditCardType;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ card, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <CardDetails card={card} />
    </div>
  )
);
