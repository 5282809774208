import { useMutation, useQueryClient } from '@tanstack/react-query';
import { find } from 'lodash/fp';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';

import { WEBHOOKS_API_BASE_URL, webhooksQueryKeys } from './webhooks.constants';
import { WebhookEventType, WebhookType } from './webhooks.types';
import { useApiQuery } from '../../hooks';
import { ServerError } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils';

export const useWebhooks = () => {
  return useApiQuery<WebhookType[]>(
    WEBHOOKS_API_BASE_URL,
    webhooksQueryKeys.all,
    {
      staleTime: 0,
    }
  );
};

export const useWebhook = (
  event: WebhookEventType
): WebhookType | undefined => {
  const webhooks = useWebhooks();

  return find({ event }, webhooks.data);
};

export type WebhookUrlParams = Omit<WebhookType, 'last_run_at'>;

export const useCreateWebhookUrl = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: WEBHOOKS_API_BASE_URL,
    method: 'POST',
  });

  return useMutation<void, ServerError, WebhookUrlParams>({
    mutationFn: (webhook) =>
      fetchApiRequest(url, {
        ...options,
        body: JSON.stringify(webhook),
      }),
    onSuccess: async () => {
      queryClient.invalidateQueries(webhooksQueryKeys.all);

      dispatch(toastrSuccess('Webhook successfully created'));
    },
    onError: ({ error }: any) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useCreateWebhookUrl',
      baseUrl: WEBHOOKS_API_BASE_URL,
      method: 'POST',
    },
  });
};

export const useUpdateWebhookUrl = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: WEBHOOKS_API_BASE_URL,
    method: 'PATCH',
  });

  return useMutation<void, ServerError, WebhookUrlParams>({
    mutationFn: (webhook) =>
      fetchApiRequest(url, {
        ...options,
        body: JSON.stringify(webhook),
      }),
    onSuccess: async () => {
      queryClient.invalidateQueries(webhooksQueryKeys.all);

      dispatch(toastrSuccess('Webhook successfully updated'));
    },
    onError: ({ error }: any) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useUpdateWebhookUrl',
      baseUrl: url,
      method: 'PATCH',
    },
  });
};

export const useDeleteWebhookUrl = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: WEBHOOKS_API_BASE_URL,
    method: 'DELETE',
  });

  return useMutation<void, ServerError, Pick<WebhookUrlParams, 'event'>>({
    mutationFn: ({ event }: { event: WebhookEventType }) =>
      fetchApiRequest(url, {
        ...options,
        body: JSON.stringify({ event }),
      }),
    onSuccess: async () => {
      queryClient.invalidateQueries(webhooksQueryKeys.all);

      dispatch(toastrSuccess('Webhook successfully disconnected'));
    },
    onError: ({ error }: any) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useDeleteWebhookUrl',
      baseUrl: url,
      method: 'DELETE',
    },
  });
};

export const useTestWebhook = () => {
  const dispatch = useDispatch();
  const { url, options } = useRequestOptions({
    url: `${WEBHOOKS_API_BASE_URL}/test`,
    method: 'POST',
  });

  return useMutation<void, ServerError, WebhookUrlParams>({
    mutationFn: (webhook) =>
      fetchApiRequest(url, {
        ...options,
        body: JSON.stringify(webhook),
      }),
    onSuccess: () => {
      dispatch(toastrSuccess('Webhook response successful'));
    },
    onError: ({ error }: any) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useTestWebhook',
      baseUrl: url,
      method: 'POST',
    },
  });
};
