export const HDBaseTPartners = {
  0x000000bd: 'Absolute Acoustics',
  0x000000be: 'Acer Incorporated',
  0x00000074: 'ADTECHNO Inc.',
  0x000000b1: 'AJA Video Systems, Inc',
  0x000000d6: 'Analog Way',
  0x00000104: 'A-NeuVideo',
  0x0000007d: 'ANTIFERENCE LIMITED',
  0x00000077: 'APANTAC',
  0x00000122: 'Appotronics Corporation Ltd.',
  0x0000012a: 'APPS Electronics Ltd.',
  0x000000d0: 'Arista Corporation',
  0x000000cf: 'ASEM S.r.l.',
  0x000000eb: 'ASTRODESIGN, Inc.',
  0x0000011d: 'A/V Integration Inc.',
  0x00000108: 'AV Access Technology Ltd.',
  0x00000094: 'Avation',
  0x0000008a: 'B&R Industrial Automation GmbH',
  0x0000003f: 'Barco',
  0x000000dc: 'Beckhoff Automation GmbH & Co. KG',
  0x000000af: 'Beijing AnHeng SecoTech Information Technology Co., Ltd',
  0x000000c2: 'Beijing Donview Digital Technology Co., Ltd.',
  0x000000e4: 'Beijing JS Technology Co., Ltd.',
  0x000000f4: 'Beijing Wincomn Technology Co., Ltd.',
  0x00000039: 'Belden Corp.',
  0x00000098: 'BenQ Corporation',
  0x000000fd: 'Berk-Tek LLC',
  0x00000070: 'Black Box Corp',
  0x0000008c: 'BLUESTREAM',
  0x000000de: 'Bolin Technology Co., Ltd.',
  0x00000102: 'Brightlink AV LTD.',
  0x00000079: 'Broadata Communications, Inc.',
  0x00000091: 'Cable Vision Electronics Co., Ltd',
  0x00000088: 'Canon Inc.',
  0x0000010c: 'Carnival Corporation',
  0x000000e9: 'CASIO COMPUTER CO., LTD.',
  0x00000053: 'CE LINK LIMITED',
  0x00000112: 'CEDIA',
  0x00000066: 'Christie Digital Systems Canada Inc.',
  0x0000007b: 'CommScope, Inc. Of North Carolina',
  0x00000107: 'Comprehensive Connectivity Company',
  0x0000005d: 'Control4',
  0x000000a7: 'Coretronic Corporation',
  0x00000069: 'Covid Inc.',
  0x00000040: 'CREATOR CORPORATION (China)',
  0x000000d5: 'Datavideo Technologies Co., Ltd.',
  0x000000b6: 'DEARBIZ CHINA INTERNATIONAL LIMITED',
  0x00000085: "Delta Electronics Int'l(Singapore) Pte. Ltd.",
  0x00000097: 'DENSAN Co., Ltd.',
  0x00000055: 'DEXON SYSTEMS LTD.',
  0x000000dd: 'DIGIBIRD TECHNOLOGY CO., LTD.',
  0x00000041: 'Digital Projection Limited',
  0x00000123: 'Draegerwerk AG & Co. KGaA',
  0x000000c7: 'Draka Comteq Germany GmbH & Co KG.',
  0x00000072: 'DVIGear, Inc.',
  0x0000009a: 'EIKI Industrial Co., Ltd.',
  0x00000111: 'EIZO Corporation',
  0x000000d3: 'ELECOM CO., LTD.',
  0x0000011c: 'Elo Touch Solutions',
  0x000000fb: 'Evertz Microsystems, Ltd.',
  0x000000ad: 'Fibersystem AB',
  0x00000062: 'FS Cables',
  0x00000083: 'FSR inc.',
  0x0000011a: 'FUJIFILM Corporation',
  0x000000e8: 'FUJITSU COMPONENT LIMITED',
  0x000000f9: 'G. J. Electronics Ltd.',
  0x0000000a: 'GEFEN, LLC',
  0x000000e0: 'General Cable',
  0x0000005c: 'G-MAX TECHNOLOGY CO., LTD',
  0x00000117: 'Gofanco LLC',
  0x00000114: 'Guangzhou Bao Lun Electronics Co., Ltd.',
  0x00000127: 'Guangdong MT-Viki Electronics Technology Co., Ltd.',
  0x0000004b: 'Hall Research',
  0x000000ed: 'Hangzhou Hikvision Digital Technology Co., Ltd.',
  0x000000f7: 'Hangzhou YOYE Electronics CO., LTD.',
  0x0000002b: 'Harman Professional, Inc.',
  0x0000003c: 'HD Connectivity',
  0x00000129: 'HDTV Supply Inc',
  0x00000051: 'HPC Technology Inc.',
  0x00000105: 'Hubbell Incorporated (Delaware)',
  0x0000012c: 'IC BOSS.COM TECH INC (HDFury)',
  0x00000071: 'ICE Cable Systems',
  0x00000050: 'IDK CORPORATION',
  0x0000007e: 'IMAGENICS Co., Ltd.',
  0x000000ce: 'InFocus Corporation',
  0x0000009f: 'ITE Tech. Inc.',
  0x000000f8: 'J-Tech Digital, Inc.',
  0x00000115: 'Kensence (Shenzhen) Technology Development CO., LTD.',
  0x0000003a: 'Keydigital Systems',
  0x00000005: 'KORDZ Group Limited',
  0x00000075: 'Kowa Optical Products Co., Ltd.',
  0x0000002d: 'Kramer Electronics, LTD.',
  0x0000003e: 'Legrand North America (C2G)',
  0x00000096: 'Leviton Manufacturing Company Inc.',
  0x0000004a: 'Liberty AV Solutions',
  0x0000004c: 'Lightware Visual Engineering',
  0x000000c3: 'LINDY Electronics Limited',
  0x0000009e: 'LINKZ INDUSTRIES (SUZHOU) LIMITED',
  0x00000113: 'LS Cable & System Ltd.',
  0x000000ec: 'Marshall Electronics Inc.',
  0x00000052: 'Maxell, Ltd.',
  0x000000a2: 'Meiko Electronics Co., Ltd.',
  0x000000c8: 'MITSUBISHI ELECTRIC CORPORATION',
  0x00000056: 'Monoprice, Inc.',
  0x00000078: 'MuxLab Inc.',
  0x000000ff: 'Nanjing Rebeaks Information Technology Co., Ltd.',
  0x000000d8: 'NDS SURGICAL IMAGING',
  0x00000059: 'NEC Display Solutions, Ltd.',
  0x00000121: 'NEEC AUDIO BARCELONA, S. L.',
  0x000000d4: 'Nienyi Industrial Corp.',
  0x00000120: 'Nippon Seisen Cable, Ltd.',
  0x00000045: 'Onkyo Corporation',
  0x00000103: 'OPTOMA CORPORATION',
  0x00000101: 'OREI, LLC',
  0x0000004d: 'Planar Systems, Inc.',
  0x00000064: 'Polycom, Inc.',
  0x00000110: 'Protech Electronics Technology Ltd.',
  0x000000ac: 'PureLink',
  0x0000011b: 'Qing Cables Limited',
  0x000000ef: 'Qisda Corporation',
  0x00000057: 'Remote Technologies, Inc',
  0x00000036: 'RGB Spectrum Inc.',
  0x000000b8: 'RICOH COMPANY, LTD.',
  0x00000080: 'Roland Corporation',
  0x0000004e: 'SAVANT SYSTEMS, LLC',
  0x000000f6: 'Seura, Inc.',
  0x000000bf: 'Sharp Corporation',
  0x000000a8: 'Shenzhen Lenkeng Technology Co., Ltd',
  0x0000010d: 'SHENZHEN LINK-MI TECHNOLOGY CO., LTD',
  0x00000044: 'Shenzhen Shunxun Electronics Co., Ltd',
  0x000000cb: 'SIEC - Systems Integration Experience Community',
  0x000000fa: 'Siemens Aktiengesellschaft',
  0x0000012b: 'SIEMON',
  0x0000011e: 'SIIG Inc',
  0x000000e1: 'SIGMATEK GmbH & Co KG',
  0x0000006f: 'SL Audio A/S dba Steinway Lyngdorf',
  0x00000012: 'Smart Cabling & Transmission Corp (SC&T)',
  0x00000038: 'SnapAV',
  0x000000fe: 'Sommer cable GmbH',
  0x00000092: 'StarTech.com',
  0x000000f1: 'STMicroelectronics International N.V.',
  0x0000002e: 'SCP - Structured Cable Products',
  0x000000b4: 'Superior Essex',
  0x000000ea: 'Suzhou Keda Technology Co., Ltd.',
  0x00000093: 'SY',
  0x000000d9: 'TECHNO HORIZON HOLDINGS CO., LTD.',
  0x000000b0: 'TEKVOX, Inc',
  0x0000010e: 'Theatrixx Technologies, Inc.',
  0x00000073: 'Tonlyware Technology Co., Ltd',
  0x0000011f: 'Tower Products inc. – DBA Ocean Matrix           ',
  0x00000020: 'Transformative Engineering, Inc.',
  0x0000008d: 'Tri-Net Technology, Inc.',
  0x000000ab: 'Tripp Lite',
  0x0000010f: 'TTL Network GmbH',
  0x0000002f: 'TV One Ltd',
  0x000000a1: 'Vanco International, LLC',
  0x00000089: 'Vertical Cable',
  0x000000fc: 'ViewSonic Corporation',
  0x000000df: 'Webro Limited',
  0x000000ae: 'WolfVision GmbH',
  0x000000a6: 'Wonderful HI-TECH',
  0x000000c1: 'Worlddidac Association',
  0x00000116: 'Wuxi Seemile Laser Display Technology Co., Ltd.',
  0x0000003b: 'WYRESTORM Technologies',
  0x000000ba: 'Xiamen RGBlink Science & Technology Co., LTD.',
  0x00000118: 'Zhuhai Lenscen Electric Co.,Ltd',
  0x000000cd: 'Zhejiang Zhaolong Interconnect Technology Co., Ltd.',
  0x00000076: 'ZIGEN',
  0x000000d7: 'ADLINK Technology Inc.',
  0x000000f3: 'ADVOLI LIMITED',
  0x000000cc: 'Aptiv Services Deutschland GmbH',
  0x00000061: 'ATEN International Co., LTD.',
  0x00000042: 'Aurora Multimedia Corp.',
  0x000000d1: 'AVPro Global Holdings LLC',
  0x00000009: 'CRESTRON ELECTRONICS, INC',
  0x00000030: 'Cypress Technology Co., Ltd.',
  0x00000109: 'DENSO CORPORATION',
  0x00000043: 'Epson',
  0x00000008: 'Extron Electronics',
  0x000000da: 'Fabrimex Systems AG',
  0x0000005e: 'GoMax Electronics, Inc.',
  0x00000125: 'Ly Enterprises (No Hassle AV)',
  0x0000010b: 'Macro Image Technology, Inc',
  0x000000e2: 'Media Solutions',
  0x000000c4: 'Mercedes-Benz Research and Development North America, Inc.',
  0x0000006a: 'Nueteq Technology Inc.',
  0x00000068: 'Panasonic Corporation',
  0x0000002a: 'PANDUIT CORPORATION (Atlona)',
  0x0000004f: 'PULSE-EIGHT LIMITED',
  0x0000005a: 'Shenzhen HDCVT Technology Co., Ltd.',
  0x00000128: 'Shenzhen Proitav Technology Co., Ltd.',
  0x00000067: 'ShenZhen PTN Electronics Limited',
  0x0000000d: 'SILVER TELECOM LTD',
  0x0000007f: 'Sony Corporation',
  0x0000000c: 'TE Connectivity Germany GmbH',
  0x00000124: 'Tektronix',
  0x00000014: 'Teledyne LeCroy, Inc.',
  0x0000009b: 'UL International Ltd.',
  0x000000bb: 'Würth Elektronik eiSos GmbH & Co. KG',
  0x00000003: 'LG Electronics',
  0x00000004: 'SAMSUNG ELECTRONICS Co., LTD.',
  0x00000002: 'SONY PICTURES TECHNOLOGIES INC.',
  0x00000001: 'VALENS SEMICONDUCTOR LTD.',
  0x00000000: 'Valens',
};
