import React, { FC } from 'react';

import { DeviceType } from '@portals/api/organizations';
import { DeviceName as FormattedDeviceName } from '@portals/ui';

type DeviceNameProps = {
  device: DeviceType;
};

const DeviceName: FC<DeviceNameProps> = ({ device }) => (
  <FormattedDeviceName
    name={device.name || device.partner.vendor + ' : ' + device.partner.model}
  />
);

export default DeviceName;
