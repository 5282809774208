import { lazy } from 'react';

import { FeatureNotificationEnum } from '@portals/api/ui';

export const FEATURES_MAP = {
  [FeatureNotificationEnum.NewOrganizationDashboard]: lazy(() =>
    import('./features-modals/NewOrganizationDashboard').then((module) => ({
      default: module.NewOrganizationDashboard,
    }))
  ),
  [FeatureNotificationEnum.NewSettingsDesign]: lazy(() =>
    import('./features-modals/NewSettingsDesign').then((module) => ({
      default: module.NewSettingsDesign,
    }))
  ),
  [FeatureNotificationEnum.NewPartnerToPartner]: lazy(() =>
    import('./features-modals/NewPartnerToPartner').then((module) => ({
      default: module.NewPartnerToPartner,
    }))
  ),
  [FeatureNotificationEnum.NewPartnerHubs]: lazy(() =>
    import('./features-modals/new-partner-hubs/NewPartnerHubs').then(
      (module) => ({
        default: module.NewPartnerHubs,
      })
    )
  ),
} as const;
