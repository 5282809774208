import { CloseButton, createStyles, Text } from '@mantine/core';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';

import { useConfig } from '@portals/api/organizations';
import { useCurrentUser } from '@portals/api/ui';
import { useHasSupportSeat, usePermissionAccess } from '@portals/framework';
import { ReactComponent as Lifebuoy } from '@portals/icons/linear/lifebuoy.svg';
import { TenantType } from '@portals/types';

export function ExternalUserAlert() {
  const { classes, theme } = useStyles();
  const config = useConfig();
  const currentUser = useCurrentUser();

  const [isDisplayed, setIsDisplayed] = useState(true);

  const { isSuperAdmin } = usePermissionAccess();
  const hasSupportSeat = useHasSupportSeat();

  if (
    !config.isFetched ||
    !currentUser.isFetched ||
    isSuperAdmin ||
    !hasSupportSeat
  )
    return null;

  return (
    <AnimatePresence initial={false}>
      <motion.div
        style={{
          x: '-50%',
        }}
        animate={{
          opacity: isDisplayed ? 1 : 0,
          y: isDisplayed ? 0 : -50,
        }}
        className={classes.container}
      >
        <Lifebuoy color={theme.white} />

        <Text color="gray.3">
          {`You are now viewing `}

          <Text span color="white" weight={500}>
            {config.data?.[TenantType.Organization]?.display_name}
          </Text>

          {` portal as `}

          <Text span color="white" weight={500}>
            {currentUser.data?.partner_display_name}
          </Text>

          {` support person`}
        </Text>

        <CloseButton
          onClick={() => setIsDisplayed(false)}
          color="gray.3"
          variant="transparent"
        />
      </motion.div>
    </AnimatePresence>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    zIndex: 999,
    transform: 'translateX(-50%)',
    borderBottomLeftRadius: theme.radius.lg,
    borderBottomRightRadius: theme.radius.lg,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'fit-content',
    height: '50px',
    position: 'fixed',
    left: '50%',
    backgroundColor: theme.black,
    gap: theme.spacing.xs,
    padding: `0 ${theme.spacing.xl}`,
  },
}));
