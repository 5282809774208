import {
  InputProps,
  InputStylesNames,
  InputStylesParams,
  InputWrapperProps,
  InputWrapperStylesNames,
} from '@mantine/core';

import { ThemeComponentOverrides } from '../common-types';

export const InputWrapper: ThemeComponentOverrides<
  InputWrapperProps,
  InputWrapperStylesNames,
  any
> = {
  styles: (theme) => ({
    label: {
      marginBottom: theme.spacing.xs,
      color: theme.colors.gray[7],
      fontWeight: 400,
    },
    required: {
      color: theme.colors.red[4],
    },
  }),
};

export const Input: ThemeComponentOverrides<
  InputProps,
  InputStylesNames,
  InputStylesParams
> = {
  styles: (theme) => ({
    input: {
      '&::placeholder, &[data-invalid]::placeholder': {
        fontWeight: 400,
        fontSize: theme.fontSizes.sm,
        color: theme.colors.gray[4],
      },
    },
  }),
};
