import {
  Box,
  createStyles,
  Menu,
  MenuProps,
  Stack,
  Tooltip,
} from '@mantine/core';
import { compact } from 'lodash/fp';
import React from 'react';
import styled from 'styled-components';

import { useSignOut } from '@portals/api/auth';
import {
  useOrganizationConfig,
  usePortalCapabilities,
  useSendPartnerSupportEmail,
  UseSendSupportEmailParams,
} from '@portals/api/organizations';
import { useCurrentUser } from '@portals/api/ui';
import { NameAbbreviationAvatar } from '@portals/core';
import {
  isCustomerFeatureAll,
  isCustomerFeatureLabOnly,
  useCanAccessPersonalSettings,
  useHubspotConversations,
  usePermissionAccess,
} from '@portals/framework';
import { useAppConfig } from '@portals/framework/context';
import { ContactSupportModalProps } from '@portals/framework/modals';
import { ReactComponent as DocumentText } from '@portals/icons/linear/document-text.svg';
import { ReactComponent as Support } from '@portals/icons/linear/lifebuoy.svg';
import { ReactComponent as Logout1 } from '@portals/icons/linear/logout-1.svg';
import { ReactComponent as Help } from '@portals/icons/linear/question-circle.svg';
import { ReactComponent as SecurityUser } from '@portals/icons/linear/security-user.svg';
import { ReactComponent as PersonalSettings } from '@portals/icons/linear/setting-4.svg';
import { ReactComponent as VideoPlay } from '@portals/icons/linear/video-play.svg';
import { useAuth, useOpenModal } from '@portals/redux';

export function UserSettings() {
  const signOut = useSignOut();
  const { extraLayout = {} } = useAppConfig();
  const hubspotConversations = useHubspotConversations();
  const { classes, theme, cx } = useStyles();
  const openModal = useOpenModal();

  const auth = useAuth();

  const organizationConfig = useOrganizationConfig();
  const portalCapabilities = usePortalCapabilities();

  const { isAdmin } = usePermissionAccess();
  const currentUser = useCurrentUser();

  const sendPartnerSupportEmail = useSendPartnerSupportEmail();

  const showWatchOverviewVideoItem =
    !isAdmin &&
    (isCustomerFeatureAll(portalCapabilities?.onboarding_video) ||
      isCustomerFeatureLabOnly(
        portalCapabilities?.onboarding_video,
        organizationConfig?.lab
      )) &&
    currentUser.data?.settings.is_welcome_videos_played;

  const canAccessPersonalSettings = useCanAccessPersonalSettings();

  const onSendSupportEmail = (values: UseSendSupportEmailParams) => {
    sendPartnerSupportEmail.mutate(
      {
        title: values.title,
        message: values.message,
      },
      {
        onSuccess: () => {
          openModal('ContactSupportSuccessModal');
        },
      }
    );
  };

  const settingsItems = compact([
    canAccessPersonalSettings && {
      Icon: PersonalSettings,
      label: 'Personal settings',
      type: 'action',
      onClick: () => openModal('PersonalSettingsModal'),
    },
    {
      Icon: Help,
      label: 'Help',
      type: 'link',
      href: 'https://docs.xyte.io/',
    },
    organizationConfig?.partner_support_email
      ? {
          Icon: Support,
          label: 'Contact support',
          type: 'action',
          onClick: () =>
            openModal<ContactSupportModalProps['data']>('ContactSupportModal', {
              onSubmit: onSendSupportEmail,
            }),
        }
      : null,
    showWatchOverviewVideoItem && {
      Icon: VideoPlay,
      label: 'Watch overview video',
      type: 'action',
      onClick: () => openModal('WelcomeVideoModal'),
    },
    {
      Icon: DocumentText,
      label: 'Platform Terms of service',
      type: 'link',
      href: 'https://xyte.io/terms-of-service',
    },
    {
      Icon: SecurityUser,
      label: 'Platform Privacy Policy',
      type: 'link',
      href: 'https://xyte.io/privacy-policy',
    },
    {
      Icon: Logout1,
      label: 'Sign out',
      type: 'action',
      onClick: () => {
        hubspotConversations.removeWidget();
        signOut.mutate();
      },
    },
  ]);

  return (
    <Menu width={300} styles={menuStyles}>
      <Menu.Target>
        <Box className={cx(classes.control, 'user-settings-control')}>
          <Tooltip
            withinPortal
            label="User settings"
            disabled={extraLayout?.options?.isMobile}
          >
            <Box>
              <NameAbbreviationAvatar
                name={auth.name}
                withToolTip={false}
                src={currentUser.data?.settings.profile_image}
                variant="filled"
                radius="xl"
                color="dark"
                data-testid="user-settings"
              />
            </Box>
          </Tooltip>
        </Box>
      </Menu.Target>
      <Menu.Dropdown px={theme.spacing.xs} py={theme.spacing.md}>
        <Stack spacing="xs">
          <Box className={classes.header}>
            <NameAbbreviationAvatar
              name={auth.name}
              withToolTip={false}
              src={currentUser.data?.settings.profile_image}
              variant="filled"
              radius="xl"
              color="dark"
            />

            <div className="user-data">
              <UserName className="user-name">{auth.name}</UserName>
              <Email className="user-email">{auth.email}</Email>
            </div>
          </Box>

          <Menu.Divider />

          {settingsItems.map(({ Icon, label, type, href, onClick }) => {
            if (type === 'link') {
              return (
                <Menu.Item
                  icon={<Icon />}
                  onClick={() => window.open(href, '_blank')}
                  key={label}
                  data-testid={`user-settings-${label}`}
                >
                  {label}
                </Menu.Item>
              );
            }

            if (type === 'action') {
              return (
                <Menu.Item
                  icon={<Icon />}
                  onClick={onClick}
                  key={label}
                  data-testid={`user-settings-${label}`}
                >
                  {label}
                </Menu.Item>
              );
            }

            return null;
          })}
        </Stack>
      </Menu.Dropdown>
    </Menu>
  );
}

const useStyles = createStyles((theme) => ({
  header: {
    display: 'grid',
    gridTemplateColumns: '44px 1fr',
    gap: theme.spacing.md,
    alignItems: 'center',
    height: 44,
    padding: `0 ${theme.spacing.sm}`,

    '.user-data': {
      display: 'grid',

      '.user-name': {
        color: theme.colors.blue_gray[8],
      },

      '.user-email': {
        color: theme.fn.rgba(theme.colors.blue_gray[8], 0.5),
      },
    },
  },
  control: {
    height: '100%',
    cursor: 'pointer',
    borderLeft: `1px solid ${theme.fn.rgba(theme.colors.blue_gray[6], 0.5)}`,
    paddingLeft: theme.spacing.xs,
    display: 'flex',
    alignItems: 'center',
  },
}));

const menuStyles: MenuProps['styles'] = (theme) => ({
  dropdown: {
    background: theme.white,
  },
  item: {
    borderRadius: theme.radius.md,

    '&[data-hovered]': {
      '.mantine-Menu-itemIcon, .mantine-Menu-itemLabel': {
        color: 'inherit',
      },
      color: theme.colors.blue_gray[6],
      backgroundColor: theme.colors.gray[1],
    },
  },
  itemIcon: {
    color: theme.colors.blue_gray[6],
  },
  itemLabel: {
    color: theme.colors.blue_gray[6],
  },
});

const Email = styled.div`
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.0015em;
  transition: color 0.15s ease-in-out;
  position: relative;
  text-transform: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const UserName = styled.div`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.0015em;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
