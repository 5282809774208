import { Box, createStyles, Group, GroupProps } from '@mantine/core';
import * as React from 'react';

import { ReactComponent as TickIcon } from '@portals/icons/linear/tick-simple.svg';

import { ColorPickerHex } from './ColorPickerHex';
import { WIDGET_COLORS } from '../widgets.constants';
import { OnAddCustomColorFn, WidgetColorType } from '../widgets.types';

export interface ColorSelectorProps {
  selectedColor: WidgetColorType;
  onPresetColorSelect: (color: WidgetColorType) => void;
  colors: Array<WidgetColorType> | undefined;
  groupProps?: Partial<GroupProps>;
  onAddCustomColor: OnAddCustomColorFn | undefined;
}

export function ColorSelector({
  onPresetColorSelect,
  selectedColor,
  colors = WIDGET_COLORS,
  groupProps = {},
  onAddCustomColor,
}: ColorSelectorProps) {
  const { classes, cx } = useStyles();

  return (
    <Group>
      <Group
        align="center"
        mt="xs"
        px="xs"
        spacing="xs"
        w="100%"
        position="left"
        {...groupProps}
      >
        {colors.map((color) => (
          <Box
            key={color}
            className={classes.iconContainer}
            data-testid={`widget-color-selector-${color}`}
          >
            {selectedColor === color ? (
              <TickIcon color="white" width={12} height={12} />
            ) : null}

            <Box
              bg={color}
              onClick={() => onPresetColorSelect(color)}
              className={cx(classes.indicator, {
                selected: selectedColor === color,
              })}
            />
          </Box>
        ))}

        <ColorPickerHex onAddCustomColor={onAddCustomColor} />
      </Group>
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  iconContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    svg: {
      color: 'white',
      position: 'absolute',
      zIndex: 1,
    },
  },
  indicator: {
    width: 22,
    height: 22,
    borderRadius: '50%',
    transition: 'all 150ms ease-in-out',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.colors.gray[1]}`,

    '&.selected': {
      transform: 'scale(1.5)',
    },

    ':not(&.selected)': {
      ':hover': {
        cursor: 'pointer',
        transform: 'scale(1.25)',
      },
    },
  },
}));
