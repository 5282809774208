import { LoadingOverlay } from '@mantine/core';
import React from 'react';

import { DeviceDetailsType } from '@portals/api/organizations';
import { ModelType } from '@portals/types';

import { LegacyOverview } from './legacy-overview/LegacyOverview';
import { Overview } from './Overview';
import { useIsLegacyWidgets } from './overviw.hooks';

interface OverviewWrapperProps {
  device: DeviceDetailsType;
  model: ModelType;
}

export function OverviewWrapper(props: OverviewWrapperProps) {
  const { isLegacy, isLoading } = useIsLegacyWidgets(props.model.id);

  if (isLoading) return <LoadingOverlay visible={isLoading} />;

  return isLegacy ? <LegacyOverview {...props} /> : <Overview {...props} />;
}
