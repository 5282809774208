import { Text } from '@mantine/core';
import React, { FC } from 'react';

interface EmptyStateProps {
  label: string;
}

export const EmptyState: FC<EmptyStateProps> = ({ label }) => (
  <Text
    size="sm"
    sx={{ position: 'absolute', transform: 'translate(-50%, -50%)' }}
  >
    {label}
  </Text>
);
