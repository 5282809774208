import { Stack, Text } from '@mantine/core';
import React from 'react';

import { PurchasedProductType } from '@portals/api/organizations';

import { CommandsAndModelsList } from '../../../../../components/CommandAndModelsList';

interface FeaturesAndModelsTabProps {
  supportedCommands: PurchasedProductType['product']['supported_commands'];
}

export function FeaturesAndModelsTab({
  supportedCommands,
}: FeaturesAndModelsTabProps) {
  return (
    <Stack>
      <Text fw={600}>
        Commands ({supportedCommands.length ? supportedCommands.length : 0})
      </Text>

      <CommandsAndModelsList supportedCommands={supportedCommands} />
    </Stack>
  );
}
