import {
  Box,
  Button,
  createStyles,
  Group,
  Modal,
  ModalProps as MantineModalProps,
} from '@mantine/core';
import React from 'react';

import { useCreateGroup } from '@portals/api/organizations';
import {
  GroupMembersList,
  GroupMembersListProps,
  GroupNameAndIcon,
  ModalProps,
  useGroupMembersList,
  useGroupNameAndIcon,
} from '@portals/framework';
import { useOpenModal } from '@portals/redux';

import { AddInternalUsersToNewGroupModalProps } from './AddInternalUsersToNewGroupModal';

interface CreateNewGroupModalProps extends ModalProps {}

export function CreateNewGroupModal({ closeMe }: CreateNewGroupModalProps) {
  const { classes } = useStyles();
  const openModal = useOpenModal();

  const createGroup = useCreateGroup();

  const {
    groupName,
    setGroupName,
    iconName,
    setIconName,
    isGroupNameError,
    setIsGroupNameError,
  } = useGroupNameAndIcon();

  const {
    usersList,
    setUsersList,
    onChangeCheckAllUsers,
    onChangeCheckedUser,
  } = useGroupMembersList();

  const onAddUsers = (usersToAdd: GroupMembersListProps['usersList']) => {
    setUsersList((prevUsersList) => [...prevUsersList, ...usersToAdd]);
  };

  const onRemoveUsers = (userIdsToRemove: string[]) => {
    const withoutRemovedUsers = usersList.filter(
      (user) => !userIdsToRemove.includes(user.id)
    );

    setUsersList(withoutRemovedUsers);
  };

  const onAddUsersActionClick = () => {
    openModal<AddInternalUsersToNewGroupModalProps['data']>(
      'AddInternalUsersToNewGroupModal',
      {
        alreadyAddedUserIds: usersList.map((user) => user.id),
        onSubmit: (usersToAdd) =>
          onAddUsers(usersToAdd.map((user) => ({ ...user, isChecked: false }))),
      }
    );
  };

  const onCreateGroupClick = async () => {
    if (groupName.length === 0) {
      setIsGroupNameError(true);
      return;
    }

    setIsGroupNameError(false);

    try {
      await createGroup.mutateAsync({
        name: groupName,
        iconName,
        userIds: usersList.map((user) => user.id),
      });

      closeMe();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      opened
      title="Create New Group"
      data-testid="crate-new-group-modal"
      onClose={closeMe}
      padding={0}
      size="clamp(800px, 55%, 1030px)"
      styles={modalStyles}
    >
      <div className={classes.body}>
        <Box px={30} mb={32}>
          <GroupNameAndIcon
            groupName={groupName}
            onChangeGroupName={setGroupName}
            iconName={iconName}
            onChangeIconName={setIconName}
            error={isGroupNameError}
            disabled={false}
          />
        </Box>

        <GroupMembersList
          usersList={usersList}
          onRemoveUsers={onRemoveUsers}
          onAddUsersActionClick={onAddUsersActionClick}
          onChangeCheckAllUsers={onChangeCheckAllUsers}
          onChangeCheckedUser={onChangeCheckedUser}
        />

        <Group className={classes.footer} position="right">
          <Button variant="default" onClick={closeMe}>
            Cancel
          </Button>
          <Button
            loading={createGroup.isLoading}
            data-testid="create-group-button"
            onClick={onCreateGroupClick}
          >
            Create
          </Button>
        </Group>
      </div>
    </Modal>
  );
}

const modalStyles: MantineModalProps['styles'] = () => ({
  content: {
    display: 'grid',
    gridTemplateRows: 'max-content 1fr',
    minHeight: '85%',
    maxHeight: '85%',
  },
  header: {
    padding: 30,
    marginBottom: 0,
  },
});

const useStyles = createStyles((theme) => ({
  body: {
    position: 'relative',
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'max-content 1fr max-content',
  },
  footer: {
    padding: theme.spacing.xl,
    borderTop: `1px solid ${theme.colors.gray[2]}`,
  },
}));
