import { LoadingOverlay } from '@mantine/core';
import React, { ReactNode } from 'react';

import { useAppConfig } from '../context';
import { useCommonPortalConfig } from '../hooks/portal-config';

type ExternalStoreLayoutProps = {
  children: ReactNode;
};

export function ExternalStoreLayout({ children }: ExternalStoreLayoutProps) {
  const { extraLayout } = useAppConfig();
  const portalConfig = useCommonPortalConfig();

  if (!portalConfig.isFetched) return <LoadingOverlay visible />;

  return (
    <>
      {children}

      {extraLayout?.globalLayout ? extraLayout.globalLayout() : null}
    </>
  );
}
