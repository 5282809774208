import { createStyles, Stack, Title } from '@mantine/core';
import { getOr } from 'lodash/fp';
import React from 'react';

import { SpaceType, useUpdateSpace } from '@portals/api/organizations';

import { SpaceForm } from './SpaceForm';
import { canView, noAccess } from '../../../../../../lib/access';

type SpaceFormProps = {
  space: SpaceType;
};

export function SpaceFormWrapper({ space }: SpaceFormProps) {
  const updateSpace = useUpdateSpace();

  const styles = useStyles();

  const handleSubmit = ({
    name,
    location,
    inherit_location,
    priority_factor,
    inherit_priority_factor,
    temperature_units,
    inherit_temperature_units,
    custom_id,
  }) => {
    const newConfig = {
      ...space.config,
      location,
      inherit_location,
      priority_factor,
      inherit_priority_factor,
      temperature_units,
      inherit_temperature_units,
    };

    updateSpace.mutate({
      spaceId: space.id,
      updatedSpace: { ...space, name, custom_id, config: newConfig },
    });
  };

  const isRoot = space.parent_id === null;

  const initialValues = {
    name: space.name || '',
    location: getOr('', 'config.location', space),
    inherit_location: !isRoot && getOr(true, 'config.inherit_location', space),
    priority_factor: getOr(0, 'config.priority_factor', space),
    inherit_priority_factor:
      !isRoot && getOr(true, 'config.inherit_priority_factor', space),
    temperature_units: getOr('celsius', 'config.temperature_units', space),
    custom_id: space.custom_id || '',
    inherit_temperature_units:
      !isRoot && getOr(true, 'config.inherit_temperature_units', space),
  };

  return (
    <Stack
      className={styles.cx(styles.classes.container, 'area-tab-form-container')}
    >
      {noAccess(space) ? (
        <Title order={5} color="gray">
          No access
        </Title>
      ) : null}

      {canView(space) && (
        <SpaceForm
          handleSubmit={handleSubmit}
          space={space}
          isLoading={updateSpace.isLoading}
          initialValues={initialValues}
        />
      )}
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    height: '100%',

    form: {
      height: '100%',
    },
  },
}));
