import { Button, Modal, Stack } from '@mantine/core';
import { filter, keys } from 'lodash/fp';
import React, { useMemo, useState } from 'react';

import {
  DeviceType,
  useDeviceStateHistoryExportToCsv,
} from '@portals/api/organizations';
import { ModalFooter } from '@portals/core';
import { ModalProps } from '@portals/framework';
import { formatDateTime } from '@portals/utils';

import { ChooseDate } from './ChooseDate';
import { SelectFields } from './SelectFields';

export interface FieldsType {
  id: string;
  isChecked: boolean;
  isCustom: boolean;
}

export interface ExportDeviceStateHistoryModalProps
  extends ModalProps<{
    deviceId: string;
    lastKnownState: DeviceType['state'] | undefined;
  }> {}

export function ExportDeviceStateHistoryModal({
  data,
  closeMe,
}: ExportDeviceStateHistoryModalProps) {
  const { deviceId, lastKnownState } = data;

  const deviceStateHistoryExportToCsv =
    useDeviceStateHistoryExportToCsv(deviceId);

  const [todayDate, setTodayDate] = useState<Date | null>(new Date(Date.now()));

  const [searchTerm, setSearchTerm] = useState('');

  const [isOpened, setIsOpened] = useState(false);

  const [fields, setFields] = useState<FieldsType[]>(
    keys(lastKnownState).map((key) => ({
      id: key,
      isChecked: true,
      isCustom: false,
    }))
  );

  const filteredFields = useMemo(() => {
    if (!searchTerm) return fields;

    const lowerCaseSearchTerm = searchTerm?.toLowerCase?.();

    return filter(
      ({ id }) => id.toLowerCase().includes(lowerCaseSearchTerm),
      fields
    );
  }, [searchTerm, fields]);

  const onExportToCsv = async () => {
    const telemetriesKeys = filteredFields
      .filter(({ id, isChecked }) => isChecked && id?.trim?.() !== '')
      .map(({ id }) => id);

    try {
      await deviceStateHistoryExportToCsv.mutateAsync({
        telemetriesKeys,
        date: todayDate?.toISOString(),
      });

      closeMe();
    } catch (error) {
      console.error(error);
    }
  };

  const onLabelChange = (label: string, fieldIndex: number) => {
    setFields((fields) => {
      const newFields = [...fields];
      newFields[fieldIndex] = { ...newFields[fieldIndex], id: label };
      return newFields;
    });
  };

  const onSelectAll = (checked: boolean) => {
    setFields((prevState) => {
      const newState = [...prevState];
      newState.forEach((field) => {
        field.isChecked = checked;
      });

      return newState;
    });
  };

  const onCheckboxChange = (checked: boolean, fieldIndex: number) => {
    setFields((prevState) => {
      const newState = [...prevState];
      newState[fieldIndex].isChecked = checked;

      return newState;
    });
  };

  const onAddField = () => {
    setFields((prevState) => [
      ...prevState,
      {
        id: '',
        isChecked: true,
        isCustom: true,
      },
    ]);
  };

  const onRemoveField = (index: number) => {
    setFields((prevState) => {
      const newState = [...prevState];
      newState.splice(index, 1);

      return newState;
    });
  };

  const isAllUnchecked = useMemo(
    () =>
      (fields || []).every(
        ({ isChecked, id }) => !isChecked || id?.trim?.() === ''
      ),
    [fields]
  );

  return (
    <Modal
      opened
      onClose={closeMe}
      title="Export device state history as CSV"
      padding={32}
    >
      <Stack>
        <ChooseDate
          date={todayDate}
          onSetDate={setTodayDate}
          onSetIsOpened={setIsOpened}
          isOpened={isOpened}
        />

        <SelectFields
          onSetSearchTerm={setSearchTerm}
          onAddField={onAddField}
          onCheckboxChange={onCheckboxChange}
          onLabelChange={onLabelChange}
          fields={filteredFields}
          onRemoveField={onRemoveField}
          searchTerm={searchTerm}
          onSelectAll={onSelectAll}
        />

        <ModalFooter position="right">
          <Button variant="default" onClick={closeMe}>
            Cancel
          </Button>

          <Button
            disabled={
              formatDateTime(todayDate) === 'Invalid Date' || isAllUnchecked
            }
            data-testid="export-state-button"
            onClick={onExportToCsv}
          >
            Export State
          </Button>
        </ModalFooter>
      </Stack>
    </Modal>
  );
}
