import { get } from 'lodash/fp';
import React, { FC } from 'react';
import { connect } from 'react-redux';

import { AutoFormik } from '@portals/autoformik';
import { FieldType, FieldTypeEnum, IntegrationType } from '@portals/types';

import ServiceDisabled from './ServiceDisabled';
import { ServiceCardFormProps } from './types';

const FIELDS: Array<FieldType> = [
  {
    name: 'webhook',
    title: 'Webhook',
    required: true,
    type: FieldTypeEnum.Text,
  },
];

const EditCiscoTeams: FC<
  ServiceCardFormProps & { integration: IntegrationType }
> = ({ integration, data, readOnly, onSubmit }) => {
  if (!integration || integration.state !== 'active') {
    return (
      <ServiceDisabled
        name="Cisco Webex Teams"
        url="/settings/integrations/messaging/cisco_teams"
      />
    );
  }

  return (
    <AutoFormik
      fields={FIELDS}
      initialValues={data}
      handleSubmit={onSubmit}
      readOnly={readOnly}
      inProgress={false}
      submitTitle="Update"
    />
  );
};

const mapStateToProps = (state) => ({
  integration: get(['data', 'integrations', 'cisco_teams'], state),
});

export default connect(mapStateToProps)(EditCiscoTeams);
