import {
  createStyles,
  NumberInput,
  NumberInputProps,
  Text,
} from '@mantine/core';
import React, { useMemo } from 'react';

import { CurrencyCode } from '@portals/types';
import { formatPrice, getCurrencySign } from '@portals/utils';

import { useConvertedCurrencyInput } from '../hooks/currency-input';

export interface CurrencyInputProps
  extends Omit<NumberInputProps, 'classNames'> {
  currencyCode: CurrencyCode;
}

export function CurrencyInput({
  currencyCode,
  value,
  onChange,
  readOnly,
  ...numberInputProps
}: CurrencyInputProps) {
  const { classes } = useStyles();

  const [adjustedValue, onChangeHandler] = useConvertedCurrencyInput(
    currencyCode,
    value,
    onChange
  );
  const currencySign = useMemo(
    () => getCurrencySign(currencyCode),
    [currencyCode]
  );

  if (readOnly) {
    const priceValue = formatPrice({
      value: value || 0,
      currencyCode,
      withCurrencySign: false,
    });

    return (
      <Text data-testid="currency-input-read-only-price-value">
        <Text span color="gray.6">
          {currencySign}{' '}
        </Text>
        <Text span>{priceValue}</Text>
      </Text>
    );
  }

  return (
    <NumberInput
      hideControls
      icon={currencySign}
      iconWidth={24}
      precision={2}
      type="number"
      value={adjustedValue}
      onChange={onChangeHandler}
      data-testid="currency-input"
      classNames={{
        root: classes.root,
        input: classes.input,
        icon: classes.icon,
        error: classes.error,
      }}
      {...numberInputProps}
    />
  );
}

const useStyles = createStyles((theme) => ({
  root: {
    position: 'relative',
  },
  input: {
    width: 100,
  },
  icon: {
    color: theme.colors.gray[6],
  },
  error: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    transform: 'translateY(100%)',
  },
}));
