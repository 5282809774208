export const PATH_LENGTH_TO_TRUNCATE = 3;
export const DEFAULT_FONT_SIZE = 20;
export const MIN_FONT_SIZE = 14;
export const ACTION_BUTTONS_WIDTH = 100;

//Will calculate the font size based on the width of the text
//Per available width of the container

export const handleTextResize = ({
  pathWidth,
  containerWidth,
  rightButtonsWidth,
}: {
  pathWidth: number | undefined;
  containerWidth: number | undefined;
  rightButtonsWidth: number;
}) => {
  if (!pathWidth || !containerWidth) {
    return MIN_FONT_SIZE;
  }

  const allElementsWidth = pathWidth + ACTION_BUTTONS_WIDTH + rightButtonsWidth;

  if (allElementsWidth > containerWidth) {
    return Math.max(DEFAULT_FONT_SIZE - 4, MIN_FONT_SIZE);
  } else {
    return Math.min(DEFAULT_FONT_SIZE + 4, DEFAULT_FONT_SIZE);
  }
};

//Will calculate if the text needs to be truncated
//Per available width

export const handleTruncateSize = ({
  pathWidth,
  containerWidth,
  rightButtonsWidth,
}: {
  pathWidth: number | undefined;
  containerWidth: number | undefined;
  rightButtonsWidth: number;
}) => {
  if (!pathWidth || !containerWidth) {
    return true;
  }
  const allElementsWidth = pathWidth + ACTION_BUTTONS_WIDTH + rightButtonsWidth;

  return allElementsWidth > containerWidth;
};

export function replaceInBetweenSpacesWithEllipsis(path: string | undefined) {
  if (!path) {
    return '';
  }

  const parts = path.split('/');

  if (parts.length > 2) {
    return parts[0] + '/.../' + parts[parts.length - 1];
  } else {
    return path;
  }
}

//This will calculate the width of the text per length of the string
//Taking into consideration the fontFamily and the fontSize
//Will also calculate the width of the truncated text
//Solution can be found here https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript

export function getTextWidth({
  text,
  font,
  containerWidth,
  rightButtonsWidth,
}: {
  text: string | undefined;
  font: string;
  containerWidth: number;
  rightButtonsWidth: number;
}) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  if (!context || !text) {
    return 0;
  }

  context.font = font;
  const metrics = context.measureText(text);
  const textWidth = metrics.width;

  const allElementsWidth = textWidth + ACTION_BUTTONS_WIDTH + rightButtonsWidth;

  if (allElementsWidth > containerWidth) {
    const truncatedText = replaceInBetweenSpacesWithEllipsis(text);
    return context.measureText(truncatedText).width;
  }

  return textWidth;
}
