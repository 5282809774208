import {
  Box,
  createStyles,
  Group,
  LoadingOverlay,
  Stack,
  StackProps,
  Switch,
  Text,
} from '@mantine/core';
import { find, noop } from 'lodash/fp';
import React from 'react';

import { OfflineDeviceStateTooltip } from '../../common/OfflineDeviceStateTooltip';
import { WIDGET_ICONS } from '../../widgets.constants';
import { WidgetColorType, WidgetIconNameType } from '../../widgets.types';

export interface ToggleWidgetProps {
  title: string;
  iconName: WidgetIconNameType;
  color: WidgetColorType;

  isChecked: boolean;
  onToggle?: () => void;
  isLoading?: boolean;

  stackProps?: StackProps;

  isDeviceOffline?: boolean;
  lastUpdateTimestamp?: string;
}

export function ToggleWidget({
  title,
  iconName,
  color,

  isChecked,
  isLoading = false,
  onToggle = noop,

  stackProps = {},

  isDeviceOffline,
  lastUpdateTimestamp,
}: ToggleWidgetProps) {
  const { classes, theme } = useStyles();

  const widgetIcon = find({ iconName }, WIDGET_ICONS);

  const Icon = widgetIcon?.Icon || WIDGET_ICONS[0].Icon;
  const themeColor = theme.fn.themeColor(color);

  return (
    <Stack
      className={classes.container}
      p="xl"
      spacing="sm"
      pos="relative"
      h="100%"
      w="100%"
      bg="white"
      justify="center"
      {...stackProps}
    >
      <LoadingOverlay visible={isLoading} />

      <Box
        sx={{
          color: themeColor,
        }}
      >
        <Icon height={26} width={26} />
      </Box>

      <Group noWrap align="center" spacing="sm">
        <Text
          size="md"
          data-testid="dashboard-toggle-widget-name"
          color="gray.5"
          truncate
        >
          {title}
        </Text>

        {isDeviceOffline ? (
          <OfflineDeviceStateTooltip
            lastUpdateTimestamp={lastUpdateTimestamp}
          />
        ) : null}
      </Group>

      <Switch
        size="lg"
        checked={Boolean(isChecked)}
        onChange={onToggle}
        color={color}
        disabled={isDeviceOffline}
      />
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    borderRadius: theme.radius.lg,
  },
}));
