import { createStyles, Text } from '@mantine/core';
import React from 'react';

import { DevicesByModelTable } from './DevicesByModelTable';
import { useOverviewContext } from '../../../overview.context';
import { WidgetCardWrapper } from '../common/WidgetCardWrapper';

export function DevicesByModel() {
  const { classes } = useStyles();
  const overviewContext = useOverviewContext();

  return (
    <WidgetCardWrapper
      paperProps={{
        className: classes.container,
      }}
      header={
        <Text size="md" color="gray.5" data-testid="devices-by-model-title">
          Devices by Model
        </Text>
      }
    >
      <DevicesByModelTable isLocal={overviewContext.isLocalDataLevel} />
    </WidgetCardWrapper>
  );
}

const useStyles = createStyles(() => ({
  container: {
    overflow: 'hidden',

    '.smart-table-container': {
      width: 'calc(100% + 30px)',
      transform: 'translateX(-15px)',

      '.thead': {
        backgroundColor: 'transparent',
      },
    },
  },
}));
