import { Text } from '@mantine/core';
import React, { FC } from 'react';

import { AuditLogType } from '@portals/types';

interface UpdateIntegrationProps {
  auditLog: AuditLogType;
}

export const UpdateIntegration: FC<UpdateIntegrationProps> = ({ auditLog }) => (
  <Text size="sm">
    Updated {auditLog?.params[0]?.model}: {auditLog?.params[0]?.display_name}
  </Text>
);
