import { Box, Button, createStyles, Modal, Stack, Text } from '@mantine/core';
import React from 'react';

import { useConfig } from '@portals/api/organizations';
import { useCurrentUser, useIsWelcomeVideosPlayed } from '@portals/api/ui';
import { ModalProps } from '@portals/framework';
import { useOpenModal } from '@portals/redux';
import { TenantType } from '@portals/types';

export interface WelcomeVideoModalProps extends ModalProps {}

export function WelcomeVideoModal({ closeMe }: WelcomeVideoModalProps) {
  const { classes } = useStyles();
  const config = useConfig();
  const openModal = useOpenModal();
  const currentUser = useCurrentUser();
  const isWelcomeVideosPlayed = useIsWelcomeVideosPlayed();

  const onDismiss = () => {
    closeMe();

    if (!currentUser.data.settings.is_welcome_videos_played) {
      openModal('WelcomeVideoAvailableLaterModal');
      isWelcomeVideosPlayed.mutate({ user: currentUser.data });
    }
  };

  return (
    <Modal
      opened
      onClose={onDismiss}
      classNames={{
        content: classes.modalContent,
        header: classes.modalHeader,
        body: classes.modalBody,
      }}
    >
      <Box className={classes.videoWrapper}>
        <iframe
          title="welcome-video"
          width="100%"
          height="370"
          src="https://www.youtube.com/embed/MbjKsjkUrng?&mute=1&autoplay=1"
          allowFullScreen
        />
      </Box>

      <Stack spacing="xs" align="center" px="xl">
        <Text size="lg">
          Welcome to{' '}
          {config.data?.[TenantType.Organization].partner_display_name}'s device
          cloud
        </Text>
        <Text align="center">
          We’re happy you’re here. Discover the platform's full potential in
          just a few minutes. Watch this short video and unlock a world of
          productivity, collaboration, and success.
        </Text>
      </Stack>

      <Box className={classes.modalFooter} p="xl">
        <Button data-testid="video-cancel-button" onClick={onDismiss}>
          Dismiss
        </Button>
      </Box>
    </Modal>
  );
}

const useStyles = createStyles((theme) => ({
  modalContent: {
    backgroundColor: theme.black,
    borderRadius: theme.radius.lg,
    color: theme.colors.gray[2],
  },

  modalBody: {
    padding: 0,
  },

  modalHeader: {
    backgroundColor: theme.black,
  },

  modalFooter: {
    display: 'flex',
    justifyContent: 'end',
  },

  videoWrapper: {
    iframe: {
      border: 'none',
    },
  },
}));
