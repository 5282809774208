import {
  Button,
  createStyles,
  Divider,
  Flex,
  Modal,
  Stack,
  Text,
  Textarea,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React from 'react';

import { useSendInternalSupportEmail } from '@portals/api/organizations';
import { ModalBody, ModalFooter } from '@portals/core';
import { ModalProps } from '@portals/framework';
import { useOpenModal } from '@portals/redux';

interface SuggestBrandModalProps extends ModalProps {}

export function SuggestBrandModal({ closeMe }: SuggestBrandModalProps) {
  const { classes } = useStyles();

  const openModal = useOpenModal();

  const sendInternalSupportEmail = useSendInternalSupportEmail();

  const form = useForm({
    initialValues: {
      brandName: '',
      deviceModel: '',
      contactName: '',
      contactEmail: '',
      comments: '',
    },
  });

  const onSubmit = (values: typeof form.values) => {
    sendInternalSupportEmail.mutate(
      {
        title: 'Brand suggestion',
        message: `
        Brand name: ${values.brandName}
        Device model: ${values.deviceModel}
        Contact name: ${values.contactName}
        Contact email: ${values.contactEmail}
        Comments: ${values.comments}
        `,
      },
      {
        onSuccess: () => {
          closeMe();
          openModal('ContactSupportSuccessModal');
        },
      }
    );
  };

  return (
    <Modal opened onClose={closeMe} padding="xxl" title="Suggest a brand">
      <form onSubmit={form.onSubmit(onSubmit)}>
        <ModalBody>
          <Stack spacing="xl">
            <Stack className={classes.brandFieldsWrapper}>
              <TextInput
                required
                data-autofocus
                label="Requested brand name"
                placeholder="Brand name"
                {...form.getInputProps('brandName')}
                data-testid="brand-name-input"
              />

              <TextInput
                label="Device model"
                placeholder="Device model"
                data-testid="device-model-input"
                {...form.getInputProps('deviceModel')}
              />
            </Stack>

            <Divider />

            <Stack>
              <Text fw={500} c="gray.7">
                Contact person at the brand
              </Text>

              <Flex direction={{ base: 'column', md: 'row' }} gap="md">
                <TextInput
                  w="100%"
                  label="Contact name"
                  placeholder="Contact name"
                  {...form.getInputProps('contactName')}
                  data-testid="contact-name-input"
                />

                <TextInput
                  w="100%"
                  label="Contact email"
                  placeholder="Contact email"
                  type="email"
                  {...form.getInputProps('contactEmail')}
                  data-testid="contact-email-input"
                />
              </Flex>
            </Stack>

            <Divider />

            <Textarea
              label="Comments"
              placeholder="Add your comments"
              minRows={5}
              {...form.getInputProps('comments')}
              data-testid="comments-input"
            />
          </Stack>
        </ModalBody>

        <ModalFooter position="right">
          <Button
            type="submit"
            loading={sendInternalSupportEmail.isLoading}
            data-testid="suggest-brand-submit-button"
          >
            Submit
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}

const useStyles = createStyles((theme) => ({
  brandFieldsWrapper: {
    width: '50%',
    paddingRight: theme.spacing.xs,

    [theme.fn.smallerThan('md')]: {
      width: '100%',
      paddingRight: 0,
    },
  },
}));
