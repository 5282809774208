import { MediaQuery } from '@mantine/core';
import { groupBy } from 'lodash/fp';
import React from 'react';

import { OrderType } from '@portals/api/organizations';
import { useGenericContext } from '@portals/framework';

import { OrderProductSummaryRow } from './OrderProductSummaryRow';

export function OrderProductsSummary() {
  const order = useGenericContext<OrderType>();

  if (!order) return null;

  const groupedOrderItems = groupBy(
    (orderItem) =>
      `${orderItem.store_listing.id}-${orderItem.payment_interval}`,
    order?.items
  );

  const orderItems = Object.values(groupedOrderItems)
    .map((item) => ({
      ...item[0],
      quantity: item.length,
      total_charged_amount_in_scu: item.reduce(
        (sum, currItem) => (sum += currItem.total_charged_amount_in_scu),
        0
      ),
    }))
    .sort((a, b) => a.store_listing.id.localeCompare(b.store_listing.id));

  const withSku = orderItems.some((orderItem) =>
    Boolean(orderItem.product.sku)
  );

  const withBasePrice = orderItems.some(
    (orderItem) => orderItem.usage_based?.base_price_in_scu
  );

  return (
    <MediaQuery largerThan="md" styles={{ maxHeight: 507, overflow: 'auto' }}>
      <div>
        {orderItems.map((item, index) => (
          <OrderProductSummaryRow
            key={index}
            orderItem={item}
            withSku={withSku}
            withBasePrice={withBasePrice}
          />
        ))}
      </div>
    </MediaQuery>
  );
}
