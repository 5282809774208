import { TenantType } from '@portals/types';

import { API } from '../constants';
import { SYSTEM_ISSUES_SCHEMA } from '../schemas';
import { replaceNormalizedData } from './data';

export const getSystemIssues = (
  tenantType: TenantType.Organization | TenantType.Partner
) => ({
  type: API,
  payload: {
    url: `ui/${tenantType}/system_issues`,
    success: replaceNormalizedData('system_issues'),
    normalize: [SYSTEM_ISSUES_SCHEMA],
    name: 'getSystemIssues',
  },
});
