import { createStyles, keyframes } from '@mantine/core';
import React from 'react';

export const LinearProgressBar = () => {
  const { classes } = useStyles();

  return <progress className={classes.container} />;
};

const progressLinear = keyframes({
  '0%': {
    backgroundSize: '200% 100%',
    backgroundPosition: 'left -31.25% top 0%',
  },
  '50%': {
    backgroundSize: '800% 100%',
    backgroundPosition: 'left -49% top 0%',
  },
  '100%': {
    backgroundSize: '400% 100%',
    backgroundPosition: 'left -102% top 0%',
  },
});

const useStyles = createStyles((theme) => ({
  container: {
    width: '100%',
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    appearance: 'none',
    border: 'none',
    height: '6px',
    color: theme.colors.blue_accent[4],
    backgroundColor: theme.colors.gray[2],
    fontSize: theme.fontSizes.sm,
    borderRadius: theme.radius.xl,

    '&::-webkit-progress-bar': {
      backgroundColor: 'transparent',
    },

    '&::-webkit-progress-value': {
      backgroundColor: 'currentColor',
      transition: 'all 0.2s',
    },

    '&::-moz-progress-bar': {
      backgroundColor: 'currentColor',
      transition: 'all 0.2s',
    },

    '&::-ms-fill': {
      border: 'none',
      backgroundColor: 'currentColor',
      transition: 'all 0.2s',
    },

    '&:indeterminate': {
      backgroundSize: '200% 100%',
      backgroundImage: `linear-gradient(to right, transparent 50%, currentColor 50%, currentColor 60%, transparent 60%, transparent 71.5%, currentColor 71.5%, currentColor 84%, transparent 84%)`,
      animation: `${progressLinear} 2s infinite linear`,

      '&::-moz-progress-bar': {
        backgroundColor: 'transparent',
      },

      '&::-ms-fill': {
        animationName: 'none',
      },
    },
  },
}));
