import * as types from '../constants';

const initialState = {
  isOpen: JSON.parse(localStorage.getItem('sidebar.isOpened')) ?? true,
  isSticky: true,
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.SIDEBAR_VISIBILITY_TOGGLE:
      const newOpenState = !state.isOpen;
      localStorage.setItem('sidebar.isOpened', JSON.stringify(newOpenState));

      return {
        ...state,
        isOpen: newOpenState,
      };

    default:
      return state;
  }
}
