import { Paper, PaperProps, Stack, StackProps } from '@mantine/core';
import React, { ReactNode } from 'react';

interface WidgetCardWrapperProps {
  children: React.ReactNode;
  header: ReactNode;
  paperProps?: PaperProps;
  stackProps?: StackProps;
}

export function WidgetCardWrapper({
  children,
  header,
  paperProps = {},
  stackProps = {},
}: WidgetCardWrapperProps) {
  return (
    <Paper p={32} {...paperProps} radius={20} data-testid="widget-card-wrapper">
      <Stack sx={{ height: '100%' }} spacing="xl" {...stackProps}>
        {header}

        <Stack sx={{ height: '100%' }}>{children}</Stack>
      </Stack>
    </Paper>
  );
}
