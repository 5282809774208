import { QueryOptions } from '@portals/api';
import {
  PortalConfigType,
  useConfig as useOrgConfig,
  usePortalConfig as useOrganizationPortalConfig,
} from '@portals/api/organizations';
import {
  useConfig as usePartnerConfig,
  usePortalConfig as usePartnerPortalConfig,
} from '@portals/api/partners';
import {
  OrganizationConfigurationType,
  PartnerConfigurationType,
  TenantType,
} from '@portals/types';

import { useAppConfig } from '../context';

export function usePortalDomain() {
  const portalConfig = usePartnerPortalConfig();
  const config = usePartnerConfig();

  const partnerName =
    config?.data?.[TenantType.Partner]?.name ||
    config?.data?.[TenantType.Organization]?.partner_name;

  if (partnerName === 'xyte') {
    return 'app.xyte.io';
  }

  const customDomain = portalConfig.data?.domain;

  return customDomain || `${partnerName}.on-xyte.com`;
}

export function usePortalProtocolAndDomain() {
  const domain = usePortalDomain();
  const { isLoading } = usePartnerPortalConfig();

  return {
    isLoading,
    protocolAndDomain: isLoading
      ? null
      : `${window.location.protocol}//${domain}`,
  };
}

export function useCommonConfig() {
  const { tenantType } = useAppConfig();

  const partnerConfig = usePartnerConfig({
    enabled: tenantType === TenantType.Partner,
  });
  const orgConfig = useOrgConfig({
    enabled: tenantType === TenantType.Organization,
  });

  return tenantType === TenantType.Partner ? partnerConfig : orgConfig;
}

export function useCommonOrganizationConfig() {
  const { tenantType } = useAppConfig();
  const config = useCommonConfig();

  if (tenantType === TenantType.Partner || !config.isFetched) return;

  return (config.data as OrganizationConfigurationType)?.[
    TenantType.Organization
  ];
}

export function useCommonPartnerConfig() {
  const { tenantType } = useAppConfig();
  const config = useCommonConfig();

  if (tenantType === TenantType.Organization || !config.isFetched) return;

  return (config.data as PartnerConfigurationType)?.[TenantType.Partner];
}

export function useCommonPortalCapabilities() {
  const config = useCommonConfig();

  return config?.data?.portal_capabilities;
}

export function useCommonFeatureFlags() {
  const config = useCommonConfig();

  return config?.data?.feature_flags;
}

export function useCommonDesign() {
  const { tenantType } = useAppConfig();
  const config = useCommonConfig();

  if (!config.isFetched) return;

  if (tenantType === TenantType.Partner) {
    return {};
  } else {
    return (config.data as OrganizationConfigurationType)[
      TenantType.Organization
    ].design;
  }
}

export function useCommonPortalConfig(
  queryOptions: QueryOptions<PortalConfigType | Record<string, never>> = {}
) {
  const { tenantType } = useAppConfig();

  return useOrganizationPortalConfig({
    enabled: tenantType !== TenantType.Partner,
    ...queryOptions,
  });
}
