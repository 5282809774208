import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Pendo } from '@portals/analytics';
import {
  useFeatureFlags,
  useOrganizationConfig,
} from '@portals/api/organizations';
import { useCurrentUser } from '@portals/api/ui';

// This one is Organizations-specific, should be moved under `apps/organizations` when Org
// portal is decoupled from framework-based routing.
export function useAnalytics() {
  const location = useLocation();
  const currentUser = useCurrentUser();
  const featureFlags = useFeatureFlags();
  const organizationConfig = useOrganizationConfig();

  useEffect(() => {
    if (currentUser.data && organizationConfig) {
      Pendo.initializeAuthenticated({
        visitor: {
          id: currentUser.data?.email,
          firstName: currentUser.data?.name?.split(' ')?.[0],
        },
        account: {
          id: organizationConfig.display_name,
          partner_tenant: organizationConfig?.partner_display_name,
          support_email:
            organizationConfig?.partner_support_email || 'support@xyte.io',
          customer_tenant_type:
            organizationConfig?.lab || organizationConfig?.is_sandbox
              ? 'Test'
              : 'Live',
          created_at: currentUser.data?.created_at,
        },
      });
    }
  }, [currentUser.data, organizationConfig]);

  useEffect(
    function initAnonymousPendo() {
      if (!currentUser.data && !currentUser.isInitialLoading) {
        // Do not initialize pendo on sign-in page
        if (location.pathname.includes('/auth/sign-in')) return;

        Pendo.initializeAnonymous();
      }
    },
    [location.pathname, currentUser.data, currentUser.isInitialLoading]
  );

  useEffect(function trackScreenResolution() {
    Pendo.track(`Screen resolution (v1)`, {
      width: window.screen.availWidth,
      height: window.screen.availHeight,
    });
  }, []);

  useEffect(
    function trackFeatureFlags() {
      if (featureFlags) {
        Pendo.track('featureFlags', featureFlags);
      }
    },
    [featureFlags]
  );
}
