import { omit } from 'lodash/fp';

import { CLOSE_MODAL, OPEN_MODAL, SIGNED_OUT } from '../constants';

const initialState = {};

const reducer = (state = initialState, action: Record<string, any>) => {
  switch (action.type) {
    case OPEN_MODAL:
      return { ...state, [action.payload.name]: action.payload.params ?? {} };

    case CLOSE_MODAL:
      return omit([action.payload], state);

    case SIGNED_OUT:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
