import { useApiQuery } from '../../hooks';
import { useCurrentUser } from '../users';
import { CRM_BASE_URL, crmQueryKeys } from './crm.constants';

export function useHubspotConversationsToken(isEnabled: boolean) {
  const currentUser = useCurrentUser();

  return useApiQuery<{ token: string }>(
    CRM_BASE_URL,
    crmQueryKeys.hubspotConversationsToken,
    { enabled: Boolean(currentUser.data) && isEnabled }
  );
}
