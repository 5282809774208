import {
  ActionIcon,
  ActionIconProps,
  createStyles,
  MantineColor,
  PopoverProps,
  SimpleGrid,
  Text,
  Tooltip,
} from '@mantine/core';
import { keys, map } from 'lodash/fp';
import React, { ReactNode, useRef, useState } from 'react';

import { useOnClickOutside } from '@portals/utils';

interface IconItem<IconName extends string> {
  name: IconName;
  component: ReactNode;
  color: MantineColor;
}

export interface IconPickerProps<IconName extends string> {
  icons: Record<IconName, IconItem<IconName>>;
  onChange: (iconName: IconName) => void;
  iconName: IconName;
  withTooltip?: boolean;
  position?: PopoverProps['position'];
  triggerSize?: ActionIconProps['size'];
  isLoading?: boolean;
}

export function IconPicker<IconName extends string>({
  icons,
  iconName,
  onChange,
  position,
  triggerSize,
  withTooltip = true,
  isLoading,
}: IconPickerProps<IconName>) {
  const { classes } = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const selectedIcon = getIconItemByName(iconName, icons);

  const onIconClicked = (iconItem: IconItem<IconName>) => {
    onChange(iconItem.name);
    setIsOpen(false);
  };

  const tooltipContentRef = useRef(null);
  useOnClickOutside(tooltipContentRef, () => setIsOpen(false), isOpen);

  return (
    <Tooltip
      opened={isOpen}
      withinPortal
      withArrow
      arrowSize={20}
      position={position}
      classNames={{
        tooltip: classes.tooltip,
      }}
      label={
        <SimpleGrid cols={5} spacing="xs" p="md" ref={tooltipContentRef}>
          {map((iconItem) => {
            const isSelected = selectedIcon.name === iconItem.name;

            return (
              <Tooltip
                key={iconItem.name}
                disabled={!withTooltip}
                label={<Text transform="capitalize">{iconItem.name}</Text>}
              >
                <ActionIcon
                  radius="md"
                  size={40}
                  onClick={() => onIconClicked(iconItem)}
                  variant={isSelected ? 'light' : 'subtle'}
                  color={isSelected ? iconItem.color : 'blue_gray'}
                >
                  {iconItem.component}
                </ActionIcon>
              </Tooltip>
            );
          }, icons)}
        </SimpleGrid>
      }
    >
      <ActionIcon
        variant={isOpen ? 'outline' : 'light'}
        color={selectedIcon.color}
        size={triggerSize}
        onClick={() => setIsOpen(true)}
        loading={isLoading}
      >
        {selectedIcon.component}
      </ActionIcon>
    </Tooltip>
  );
}

function getIconItemByName<IconName extends string>(
  selectedIconName: IconPickerProps<IconName>['iconName'],
  icons: IconPickerProps<IconName>['icons']
): IconItem<IconName> {
  // If selectedIconName is provided and the icon exists in the icons map, return the corresponding icon item.
  if (selectedIconName && icons[selectedIconName]) {
    return icons[selectedIconName];
  }

  // Otherwise, log an error message if the selected icon is not found in the icons map.
  if (selectedIconName && !icons[selectedIconName]) {
    console.error(`"${selectedIconName}" was not found in the icons map`);
  }

  // Return the first icon item in the icons map.
  return icons[keys(icons)[0] as IconName];
}

const useStyles = createStyles((theme) => ({
  tooltip: {
    pointerEvents: 'all',
    background: 'white',
    padding: 0,
    filter: 'drop-shadow(-4px 4px 36px rgba(0, 2, 41, 0.1))',
  },
}));
