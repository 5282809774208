import {
  createStyles,
  Flex,
  MantineTheme,
  Menu,
  MenuProps,
  Stack,
  Text,
} from '@mantine/core';
import React, { useRef, useState } from 'react';
import { useKey } from 'react-use';

import { useOrganizationConfig } from '@portals/api/organizations';
import { useOnClickOutside } from '@portals/utils';

import { useShouldDisplayOrganizationTenantMenu } from './hooks';
import { OrganizationMenuContent } from './TenantMenuContent';
import { OrganizationUser } from './TenantUser';

export function TenantMenu() {
  const { classes, theme } = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const configuration = useOrganizationConfig();

  const contentRef = useRef<HTMLDivElement>(null);

  const onEscape = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  useOnClickOutside(contentRef, () => setIsOpen(false), isOpen);
  useKey('Escape', onEscape, { event: 'keydown' }, [isOpen]);

  const shouldDisplayTenantMenu = useShouldDisplayOrganizationTenantMenu();

  if (!shouldDisplayTenantMenu) {
    return (
      <Stack className={classes.menuTargetContainer}>
        <OrganizationUser isActive={false} isDisabled />

        <Flex justify="center">
          <Text truncate="end" fz="10px">
            {configuration?.display_name}
          </Text>
        </Flex>
      </Stack>
    );
  }

  return (
    <Menu
      offset={25}
      withinPortal
      opened={isOpen}
      closeOnItemClick={false}
      position="right"
      width={300}
      styles={menuStyles(theme)}
    >
      <Menu.Target>
        <Stack
          onClick={() => setIsOpen((curr) => !curr)}
          className={classes.menuTargetContainer}
        >
          <OrganizationUser isActive={isOpen} />

          <Flex justify="center">
            <Text truncate fz="10px">
              {configuration?.display_name}
            </Text>
          </Flex>
        </Stack>
      </Menu.Target>

      <Menu.Dropdown>
        <OrganizationMenuContent
          ref={contentRef}
          onClose={() => setIsOpen(false)}
        />
      </Menu.Dropdown>
    </Menu>
  );
}

const menuStyles: (theme: MantineTheme) => MenuProps['styles'] = (theme) => ({
  dropdown: {
    padding: `0 !important`,
    transform: `translateY(${theme.spacing.sm})`,
    boxShadow: '0px 5px 12px 0px rgba(38, 50, 56, 0.30);',
    borderRadius: theme.radius.lg,
    overflow: 'hidden',
  },
});

const useStyles = createStyles((theme) => ({
  menuTargetContainer: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: 0,
    gap: theme.spacing.xs,
  },
}));
