import { Button, ButtonProps } from '@mantine/core';
import React from 'react';

import { WarrantyType } from '@portals/api/organizations';
import { useOpenModal } from '@portals/redux';

import { UploadWarrantyModalData } from '../../../../modals';

interface UploadWarrantyButtonProps
  extends Omit<ButtonProps, 'onClick' | 'children'> {
  deviceId: string;
  warranty?: WarrantyType;
}

export function UploadWarrantyButton({
  deviceId,
  warranty,
  ...buttonProps
}: UploadWarrantyButtonProps) {
  const openModal = useOpenModal();

  return (
    <Button
      onClick={() =>
        openModal<UploadWarrantyModalData>('UploadWarranty', {
          deviceId,
          warrantyToEdit: warranty,
        })
      }
      {...buttonProps}
    >
      Upload Warranty
    </Button>
  );
}
