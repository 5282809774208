import { Box, createStyles, Group, Stack, Text } from '@mantine/core';
import { AnimatePresence, motion } from 'framer-motion';
import { join, keys } from 'lodash/fp';
import React, { useEffect, useRef, useState } from 'react';
import ImageUploading, { ImageType } from 'react-images-uploading';
import { ExportInterface } from 'react-images-uploading/dist/typings';

import { ReactComponent as Upload } from '@portals/icons/linear/upload.svg';

import { CropConfig } from './ImageSelectorField';
import { ReactComponent as DropFile } from '../../../assets/drop-file.svg';

interface ImageInputProps {
  label?: string;
  onChange: (imageList: Array<ImageType>) => void;
  acceptType?: Array<string>;
  className?: string;
  cropConfig: CropConfig;
}

const ERRORS_MAP = ({
  maxNumber,
  acceptType,
}: {
  maxNumber?: number;
  acceptType?: Array<string>;
}) => ({
  maxNumber: `Number of selected images exceed ${maxNumber}`,
  acceptType: `Your selected file type is not allowed (${join(
    ', ',
    acceptType
  )})`,
});

export function ImageInput({
  label = 'Upload image',
  onChange,
  acceptType = ['jpg', 'jpeg', 'gif', 'png', 'svg'],
  cropConfig,
}: ImageInputProps) {
  const { classes, cx } = useStyles();
  const [errors, setErrors] = useState(null);
  const errorTimeout = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    errorTimeout.current = setTimeout(() => {
      setErrors(null);
    }, 10000);

    return () => {
      if (errorTimeout.current) {
        clearTimeout(errorTimeout.current);
      }
    };
  }, []);

  const removeError = () => {
    setErrors(null);

    if (errorTimeout.current) {
      clearTimeout(errorTimeout.current);
    }
  };

  return (
    <Box w="100%" h="100%" pos="relative" sx={{ overflow: 'hidden' }}>
      <AnimatePresence>
        {errors ? (
          <motion.div
            initial={{ opacity: 0, y: '-50%' }}
            animate={{ opacity: 1, y: '0%' }}
            exit={{ opacity: 0, y: '-50%' }}
            style={{
              position: 'absolute',
              top: 0,
              width: '100%',
              zIndex: 2,
            }}
          >
            <Box
              sx={(theme) => ({
                backgroundColor: theme.colors.red_accent[4],
                color: theme.white,
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              })}
              onClick={removeError}
            >
              {keys(errors).map((error) => (
                <Text key={error} size="xs" p="xs">
                  {ERRORS_MAP({ maxNumber: 1, acceptType })[error] ||
                    'Failed to upload image, please try again'}
                </Text>
              ))}
            </Box>
          </motion.div>
        ) : null}
      </AnimatePresence>

      <ImageUploading
        value={[]}
        acceptType={acceptType}
        onChange={onChange}
        maxNumber={1}
        onError={(errors) => setErrors(errors)}
        //@ts-ignore
        inputProps={{ 'data-testid': 'image-uploading-input' }}
      >
        {({ onImageUpload, isDragging, dragProps }: ExportInterface) => (
          <Stack
            {...dragProps}
            className={cx(classes.container, {
              [classes.dragging]: isDragging,
            })}
            h="100%"
            w="100%"
            align="center"
            justify="center"
            onClick={onImageUpload}
          >
            {isDragging ? (
              <Stack align="center" justify="center">
                <DropFile />

                <Text size="xs" color="blue_gray.4">
                  {label}
                </Text>
              </Stack>
            ) : (
              <Stack
                align="center"
                spacing="xs"
                h="100%"
                w="100%"
                justify="center"
                pos="relative"
              >
                <Upload width={31} height={31} />

                <Stack spacing={0} align="center">
                  <Text
                    size="xs"
                    data-testid="upload-image-txt"
                    color="blue_gray.4"
                  >
                    {label}
                  </Text>
                </Stack>

                {cropConfig?.width && cropConfig?.height ? (
                  <Group
                    pos="absolute"
                    sx={{ bottom: 0 }}
                    p="sm"
                    position="center"
                  >
                    <Text size="xs" color="blue_gray.3" align="center">
                      {`Recommended size: ${cropConfig.width}x${cropConfig.height} px`}
                    </Text>
                  </Group>
                ) : null}
              </Stack>
            )}
          </Stack>
        )}
      </ImageUploading>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    borderRadius: theme.radius.sm,
    background: theme.colors.gray[0],
    border: `1px dashed ${theme.colors.gray[2]}`,
    transition: 'all 0.15s ease-in-out',
    color: theme.colors.blue_gray[2],
    cursor: 'pointer',

    '&:hover': {
      borderColor: theme.colors.blue_accent[4],
      background: theme.fn.rgba(theme.colors.blue_accent[4], 0.1),
      color: theme.colors.blue_accent[4],
    },
  },
  dragging: {
    borderColor: theme.colors.blue_accent[4],
    background: theme.fn.rgba(theme.colors.blue_accent[4], 0.1),
    color: theme.colors.blue_accent[4],
  },
}));
