import { Box, createStyles, getStylesRef, Group, Text } from '@mantine/core';
import React, { ReactNode } from 'react';

interface TelemetryItemProps {
  icon: ReactNode;
  label: ReactNode;
  isActive: boolean;
  onClick: () => void;
}

export function TelemetryItem({
  icon,
  label,
  isActive,
  onClick,
}: TelemetryItemProps) {
  const { classes, cx } = useStyles();

  return (
    <div
      className={cx(classes.wrapper, { [classes.active]: isActive })}
      onClick={onClick}
    >
      <Group className={classes.content}>
        <Box className={classes.iconWrapper}>{icon}</Box>
        <Text className={classes.label}>{label}</Text>
      </Group>
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  wrapper: {
    height: 68,
    paddingInline: theme.spacing.md,
    borderRadius: theme.radius.md,
    cursor: 'pointer',
    border: '1px solid transparent',

    [`&:last-child .${getStylesRef('iconWrapper')}:after`]: {
      height: '50%',
    },
    [`&:first-of-type .${getStylesRef('iconWrapper')}:after`]: {
      height: '50%',
      top: 'auto',
      bottom: -2,
    },
  },
  iconWrapper: {
    ref: getStylesRef('iconWrapper'),
    position: 'relative',
    height: '100%',
    display: 'flex',
    alignItems: 'center',

    '&:after': {
      content: '""',
      display: 'block',
      height: 'calc(100% + 2px)',
      width: 1,
      backgroundColor: theme.colors.blue_gray[1],
      position: 'absolute',
      left: '50%',
      top: 0,
      transform: 'translateX(-50%)',
    },
  },
  content: {
    height: '100%',
  },
  active: {
    borderColor: theme.colors.primary[4],
    backgroundColor: theme.colors.primary[0],
  },
  label: {
    fontSize: theme.fontSizes.sm,
    color: theme.colors.blue_gray[6],
  },
}));
