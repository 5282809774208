import { SET_ERROR, CLEAR_ERROR } from '../constants';

export const setError = (name, error) => ({
  type: SET_ERROR,
  payload: {
    name,
    error: error.error ? error.error : error,
  },
});

export const clearError = (name) => ({
  type: CLEAR_ERROR,
  payload: name,
  meta: { quiet: true },
});
