import { InvalidateQueryFilters, useQueryClient } from '@tanstack/react-query';
import { forEach } from 'lodash/fp';
import { useCallback } from 'react';

export const useClearDataCache = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (queriesKeys: Array<Array<string>>, filters?: InvalidateQueryFilters) => {
      forEach(
        (queryKey) => queryClient.invalidateQueries(queryKey, filters),
        queriesKeys
      );
    },
    [queryClient]
  );
};
