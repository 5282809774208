import React, { ComponentProps, useCallback, useMemo } from 'react';

import {
  OrderSummaryType,
  useUpdatePurchaseOrderFile,
} from '@portals/api/organizations';
import { UploadFileProps } from '@portals/framework/modals';
import { ReactComponent as CloseCircle } from '@portals/icons/linear/close-circle.svg';
import { ReactComponent as DocumentUpload } from '@portals/icons/linear/document-upload.svg';
import { ReactComponent as Lifebuoy } from '@portals/icons/linear/lifebuoy.svg';
import { ReactComponent as MenuBoard } from '@portals/icons/linear/menu-board.svg';
import { useOpenModal } from '@portals/redux';
import { RowMenuItems } from '@portals/table';

interface ActiveOrdersRowMenuProps {
  order: OrderSummaryType;
  wrapperProps?: ComponentProps<typeof RowMenuItems>['wrapperProps'];
}

export function ActiveOrdersRowMenu({
  order,
  wrapperProps,
}: ActiveOrdersRowMenuProps) {
  const openModal = useOpenModal();
  const updatePurchaseOrderFile = useUpdatePurchaseOrderFile();

  const hasSupportEmail = order.partner.support_contact_email !== null;
  const isInactiveOrder = ['expired', 'archived', 'failed'].includes(
    order.payment_status
  );
  const isMissingPPP =
    !isInactiveOrder && order.fulfillment_status === 'missing_information';
  const isMissingPO =
    !isInactiveOrder && order.payment_status === 'awaiting_po_upload';

  const supportEmailHref = hasSupportEmail
    ? `mailto:${order.partner.support_contact_email}?subject=Inquiry regarding ${order.short_id}`
    : null;

  const onPOUploadSuccess: UploadFileProps['data']['onSuccess'] = useCallback(
    (params) => {
      const [purchaseOrderFileUrl] = params.map((param) => param.fileUrl);

      updatePurchaseOrderFile.mutate({
        payment_id: order.payment?.id,
        purchase_order_file_url: purchaseOrderFileUrl,
      });
    },
    [order.payment?.id, updatePurchaseOrderFile]
  );

  const items = useMemo(() => {
    const items: ComponentProps<typeof RowMenuItems>['items'] = [];

    if (supportEmailHref) {
      items.push({
        id: 'support',
        label: 'Contact Support',
        Icon: Lifebuoy,
        onClick: () => {
          wrapperProps.menuRef?.onClose?.();

          window.open(supportEmailHref, '_blank');
        },
      });
    }

    if (isMissingPPP) {
      items.push({
        id: 'ppp',
        label: 'Complete Information',
        Icon: MenuBoard,
        onClick: () => {
          wrapperProps.menuRef?.onClose?.();

          openModal('PostPurchaseParametersModal', {
            orderId: order.id,
          });
        },
      });
    }

    if (isMissingPO) {
      items.push({
        id: 'po',
        label: 'Upload Purchase Order',
        Icon: DocumentUpload,
        onClick: () => {
          wrapperProps.menuRef?.onClose?.();

          openModal<UploadFileProps['data']>('UploadFile', {
            title: 'Upload Purchase Order',
            onSuccess: onPOUploadSuccess,
            dropzoneProps: {
              sx: { height: 350 },
              maxSize: 20 * 1024 * 1024,
            },
          });
        },
      });

      items.push({
        id: 'cancel',
        label: 'Cancel Order',
        color: 'red',
        withTopDivider: true,
        Icon: CloseCircle,
        onClick: () => {
          wrapperProps.menuRef?.onClose?.();

          openModal('CancelOrderModal', {
            orderId: order.id,
          });
        },
      });
    }

    return items;
  }, [
    onPOUploadSuccess,
    isMissingPO,
    isMissingPPP,
    openModal,
    order.id,
    supportEmailHref,
    wrapperProps.menuRef,
  ]);

  if (!items.length) return null;

  return <RowMenuItems items={items} wrapperProps={wrapperProps} />;
}
