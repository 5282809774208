import {
  Button,
  createStyles,
  Divider,
  Group,
  Stack,
  Text,
} from '@mantine/core';
import React from 'react';

import { useOrganizationConfig } from '@portals/api/organizations';
import { QuantityInput } from '@portals/core';
import { usePermissionAccess } from '@portals/framework';
import { useAuth, useOpenModal } from '@portals/redux';
import { StoreListingStatus } from '@portals/types';

import { useProductDetails } from './ProductDetailsProvider';

interface ProductActionsProps {
  storeListingStatus: StoreListingStatus;
}

export function ProductActions({ storeListingStatus }: ProductActionsProps) {
  const auth = useAuth();
  const { classes } = useStyles();

  const organizationConfig = useOrganizationConfig();

  const openModal = useOpenModal();

  const { isAdmin } = usePermissionAccess();

  const { onAddToCart, selectedQuantity, setSelectedQuantity } =
    useProductDetails();

  const onAddToCartClick = () => {
    // If user is signed in and is not admin, they can't purchase products
    if (auth && !isAdmin) {
      openModal('PreventProductPurchase');
      return;
    }

    onAddToCart();
  };

  return (
    <Stack spacing="xl">
      <Group className={classes.quantityRow}>
        <Text>Quantity</Text>

        <QuantityInput
          min={1}
          value={selectedQuantity}
          onChange={setSelectedQuantity}
        />
      </Group>

      <Button
        size="md"
        onClick={onAddToCartClick}
        disabled={
          storeListingStatus !== 'published' && !organizationConfig?.lab
        }
        data-testid="product-actions-add-to-cart-button"
      >
        {storeListingStatus === 'draft' && !organizationConfig?.lab
          ? "Can't Add Draft Products to Cart"
          : 'Add to Cart'}
      </Button>

      <Divider />
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  quantityRow: {
    [theme.fn.smallerThan('md')]: {
      justifyContent: 'space-between',
    },
  },
}));
