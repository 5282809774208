import { Box, createStyles, Stack } from '@mantine/core';
import React from 'react';

import { SearchInput } from '@portals/core';

import { IntegrationCategoryType } from './integrations.types';

interface IntegrationsSidebarProps {
  searchTerm: string;
  setSearchTerm: (value: string) => void;
  categories: Array<IntegrationCategoryType>;
  selectedCategory: IntegrationCategoryType;
  setSelectedCategory: (value: IntegrationCategoryType) => void;
}

export function IntegrationsSidebar({
  searchTerm,
  setSearchTerm,
  categories = [],
  selectedCategory,
  setSelectedCategory,
}: IntegrationsSidebarProps) {
  const { classes, cx } = useStyles();

  return (
    <Stack className={classes.container}>
      <SearchInput
        data-autofocus={true}
        onClear={() => setSearchTerm('')}
        value={searchTerm}
        onChange={(event) => setSearchTerm(event.target.value)}
        size="md"
        w="100%"
      />

      <Stack spacing={4}>
        {categories.map((category) => (
          <Box
            key={category.id}
            className={cx(classes.category, {
              selected: category.id === selectedCategory.id,
            })}
            onClick={() => setSelectedCategory(category)}
            p="sm"
          >
            {category.label}
          </Box>
        ))}
      </Stack>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    height: '100%',
    borderRight: `1px solid ${theme.colors.gray[2]}`,
    paddingBlock: theme.spacing.xl,
    paddingInline: theme.spacing.xxl,
    gap: theme.spacing.xl,
  },

  category: {
    borderRadius: theme.radius.xl,
    cursor: 'pointer',

    '&.selected': {
      backgroundColor: theme.colors.gray[1],
    },

    '&:hover': {
      backgroundColor: theme.colors.gray[1],
    },
  },
}));
