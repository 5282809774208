import { get } from 'lodash/fp';
import { createSelector, Selector } from 'reselect';

import { StateType } from '@portals/types';

export const getErrors = (state: StateType): StateType['errors'] =>
  state.errors;

export const getErrorByActionName: (
  actionName: string
) => Selector<StateType, string> = (actionName: string) =>
  createSelector(getErrors, get(actionName));
