import {
  createStyles,
  Group,
  LoadingOverlay,
  Slider,
  Stack,
  StackProps,
  Text,
} from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import React, { useState } from 'react';

import { OfflineDeviceStateTooltip } from '../../common/OfflineDeviceStateTooltip';
import { WidgetColorType } from '../../widgets.types';

type OnChangeCallback = (value: number) => void | Promise<void>;

export interface BarControllerWidgetProps {
  title: string;
  value: number;
  unit?: string | null;
  min: number;
  max: number;
  gradient: { from: WidgetColorType; to: WidgetColorType };
  onChange: OnChangeCallback;
  onClick?: () => void;
  isLoading?: boolean;

  stackProps?: StackProps;

  isDeviceOffline?: boolean;
  lastUpdateTimestamp?: string;
  disabled?: boolean;
}

export function BarControllerWidget({
  title,
  value,
  unit,
  min,
  max,
  gradient,
  onChange,
  onClick,
  isLoading,

  stackProps = {},

  isDeviceOffline,
  lastUpdateTimestamp,
  disabled,
}: BarControllerWidgetProps) {
  const { classes } = useStyles();
  const { ref, width } = useElementSize();

  const [barControllerValue, setBarControllerValue] = useState(value);

  const getValue = () => {
    if (!unit) return isNaN(barControllerValue) ? '--' : barControllerValue;

    return `${isNaN(barControllerValue) ? '--' : barControllerValue} ${unit}`;
  };

  return (
    <Stack
      className={classes.container}
      p="xl"
      h="100%"
      w="100%"
      bg="white"
      justify="center"
      pos="relative"
      spacing="xl"
      {...stackProps}
    >
      {isLoading ? <LoadingOverlay visible={isLoading} /> : null}

      <Group
        w="100%"
        maw={width}
        position="apart"
        align={isDeviceOffline ? 'center' : 'start'}
        noWrap
      >
        <Group
          noWrap
          align="center"
          spacing="sm"
          className={classes.titleWrapper}
        >
          <Text
            size="md"
            data-testid="dashboard-bar-controller-widget-name"
            color="gray.5"
            truncate
          >
            {title}
          </Text>

          {isDeviceOffline ? (
            <OfflineDeviceStateTooltip
              lastUpdateTimestamp={lastUpdateTimestamp}
            />
          ) : null}
        </Group>

        <Text
          color="gray.8"
          data-testid="bar-controller-value-preview"
          fz={28}
          className={classes.value}
        >
          {getValue()}
        </Text>
      </Group>

      <Slider
        value={barControllerValue}
        onChange={setBarControllerValue}
        onChangeEnd={onChange}
        size="xl"
        label={null}
        min={min}
        max={max}
        thumbSize={28}
        ref={ref}
        disabled={disabled}
        onClick={onClick}
        styles={(theme) => ({
          // Had to do some hacking here, since the Slider component doesn't support
          // gradient background
          root: {
            pointerEvents: isDeviceOffline ? 'none' : 'auto',
          },
          bar: {
            height: 14,
            opacity: isDeviceOffline ? 0.5 : 1,

            background: theme.fn.linearGradient(
              90,
              theme.fn.themeColor(gradient.from),
              theme.fn.themeColor(gradient.to)
            ),
          },
          track: {
            background: theme.colors.gray[2],
          },
          thumb: {
            background: isDeviceOffline ? theme.colors.gray[4] : theme.white,
            boxShadow: '0px 1px 11px rgba(10, 8, 106, 0.16)',
            border: 'none',

            '&:active': {
              boxShadow: '0px 1px 11px rgba(10, 8, 106, 0.25)',
            },
          },
        })}
      />
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    borderRadius: theme.radius.lg,
  },
  titleWrapper: {
    overflow: 'hidden',
  },
  value: {
    flexShrink: 0,
  },
}));
