import { SegmentedControl, Stack, useMantineTheme, Text } from '@mantine/core';
import { capitalize } from 'lodash/fp';
import React, { FC, useMemo, useState } from 'react';

import {
  DeviceType,
  IncidentClosedMethodEnum,
  INCIDENTS_API_URL,
  incidentsQueryKeys,
  IncidentType,
  useIncidents,
} from '@portals/api/organizations';
import { IncidentStatusBadge, PriorityIcon } from '@portals/framework';
import { DateCell, PaginatedTable } from '@portals/table';
import {
  PaginatedFilterTypeEnum,
  TableColumn,
  TableFilterTypeEnum,
} from '@portals/types';
import { PRIORITY_OPTIONS } from '@portals/utils';

import { IncidentDetailsPanel } from '../../../../components/Incidents/IncidentDetailsPanel';

interface IncidentsProps {
  device: DeviceType;
}

const FILTER_OPTIONS = {
  active: 'Active Only',
  all: 'All Incidents',
} as const;

type FilterValues = (typeof FILTER_OPTIONS)[keyof typeof FILTER_OPTIONS];

export const getDefaultFilters = (
  deviceId: string,
  selectedTab: FilterValues
): string => {
  switch (selectedTab) {
    case FILTER_OPTIONS.active:
      return `?q[device_id_eq]=${deviceId}&q[status_eq]=active`;

    case FILTER_OPTIONS.all:
    default:
      return `?q[device_id_eq]=${deviceId}`;
  }
};

export const Incidents: FC<IncidentsProps> = ({ device }) => {
  const mantineTheme = useMantineTheme();
  const [filter, setFilter] = useState<FilterValues>(FILTER_OPTIONS.active);

  const columns = useMemo(() => {
    const defaultColumns: Array<TableColumn<IncidentType>> = [
      {
        dataField: 'priority',
        text: '',
        maxWidth: 90,
        minWidth: 90,
        sort: true,
        filter: {
          type: PaginatedFilterTypeEnum.Select,
          options: PRIORITY_OPTIONS,
        },
        formatter: (_, { priority }) => {
          return <PriorityIcon priorityLevel={priority} iconSize={24} />;
        },
      },
      {
        dataField: 'title',
        text: 'Title',
        sort: true,
        filter: {
          type: PaginatedFilterTypeEnum.Text,
        },
      },
      {
        dataField: 'created_at',
        text: 'Created',
        sort: true,
        filter: {
          type: PaginatedFilterTypeEnum.Date,
        },
        formatter: (_, { created_at }) => <DateCell date={created_at} />,
      },
      {
        dataField: 'updated_at',
        text: 'Updated',
        sort: true,
        filter: {
          type: PaginatedFilterTypeEnum.Date,
        },
        formatter: (_, { updated_at }) => <DateCell date={updated_at} />,
      },
      ...(filter === FILTER_OPTIONS.all
        ? [
            {
              dataField: 'closed_at',
              text: 'Closed',
              hidden: true,
              sort: true,
              filter: {
                type: TableFilterTypeEnum.Date,
              },
              formatter: (cell, { closed_at }) => (
                <DateCell date={closed_at} withTimeAgo={false} />
              ),
            } as const,
          ]
        : []),
    ];

    if (filter === FILTER_OPTIONS.all) {
      defaultColumns.push({
        dataField: 'status',
        text: 'Status',
        sort: true,
        minWidth: 200,
        maxWidth: 200,
        filter: {
          type: TableFilterTypeEnum.Select,
          options: {
            active: 'Active',
            closed: 'Closed',
          },
        },
        formatter: (_, { status }) => {
          return <IncidentStatusBadge status={status} />;
        },
      });

      defaultColumns.push({
        dataField: 'closed_method',
        text: 'Closed by',
        sort: true,
        minWidth: 200,
        filter: {
          type: TableFilterTypeEnum.Select,
          options: {
            [IncidentClosedMethodEnum.Manually]: 'Manually',
            [IncidentClosedMethodEnum.Automatically]: 'Automatically',
            [IncidentClosedMethodEnum.Device]: 'Device',
          },
        },
        formatter: (_, { closed_method, closed_by }) => (
          <Text>
            <Text span color="gray.7">
              {capitalize(closed_method)}{' '}
            </Text>
            {closed_by ? (
              <Text span color="gray.5">
                (by {closed_by.name})
              </Text>
            ) : null}
          </Text>
        ),
      });
    }

    return defaultColumns;
  }, [filter]);

  return (
    <Stack
      spacing="xl"
      py="xl"
      sx={{
        height: '100%',
      }}
    >
      <SegmentedControl
        data={[FILTER_OPTIONS.active, FILTER_OPTIONS.all]}
        onChange={(filter: FilterValues) => setFilter(filter)}
        value={filter}
        data-testid="location-tabs"
      />

      <PaginatedTable<IncidentType>
        key={filter}
        keyField="id"
        name={`device-incidents-${filter}`}
        columns={columns}
        dataHookUrl={`${INCIDENTS_API_URL}/${getDefaultFilters(
          device.id,
          filter
        )}`}
        dataHook={useIncidents}
        dataHookQueryKey={[...incidentsQueryKeys.base, 'device-details']}
        noDataIndication={{
          title:
            filter === FILTER_OPTIONS.all
              ? 'No incidents'
              : 'No active incidents',
        }}
        noHeader
        isUrlSyncEnabled={false}
        defaultSortBy={
          filter === FILTER_OPTIONS.all
            ? [{ id: 'created_at', desc: true }]
            : [{ id: 'priority', desc: false }]
        }
        detailsPanel={{
          type: 'inner',
          renderer: ({ row, onClose }) => (
            <IncidentDetailsPanel
              incident={row.original}
              onClosePanel={onClose}
            />
          ),
        }}
        rowStyle={({ status }) => ({
          color: status === 'closed' ? mantineTheme.colors.gray[2] : 'unset',
        })}
      />
    </Stack>
  );
};
