import { get, isNumber } from 'lodash/fp';

import { AccessLevelEnum } from '@portals/types';

import { Entity } from './types';

export const getEntityAccessLevel = (entity: Entity) => {
  const entityAccessLevel = get('access_level', entity);

  if (!isNumber(entityAccessLevel)) return AccessLevelEnum.None;

  return entityAccessLevel;
};
