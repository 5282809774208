import { Box, createStyles } from '@mantine/core';
import React from 'react';

import {
  DeviceType,
  useUpdateDeviceConfiguration,
} from '@portals/api/organizations';
import { ConfigView } from '@portals/framework';

interface DeviceConfigProps {
  device: DeviceType;
}

export const DeviceConfig = ({ device }: DeviceConfigProps) => {
  const updateDeviceConfig = useUpdateDeviceConfiguration();
  const { classes } = useStyles();

  const updateConfig = (device_id, configuration: string) => {
    updateDeviceConfig.mutate({
      device_id,
      configuration,
    });
  };

  return (
    <Box className={classes.wrapper} py="xl">
      <Box className={classes.container}>
        <ConfigView device={device} updateConfig={updateConfig} />
      </Box>
    </Box>
  );
};

const useStyles = createStyles((theme) => ({
  wrapper: {
    height: '100%',
    width: '100%',
  },
  container: {
    width: '100%',
    height: '100%',
    border: `1px solid ${theme.colors.gray[2]}`,
    borderRadius: theme.radius.md,
    overflow: 'hidden',
  },
}));
