import { Anchor } from '@mantine/core';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { SpaceType, useDeleteSpaceFile } from '@portals/api/organizations';
import { ModalButton, useConfirmationModal } from '@portals/framework';
import { EntityAccess } from '@portals/framework/EntityAccess';
import { ReactComponent as DocumentDownload } from '@portals/icons/linear/document-download.svg';
import { ReactComponent as DocumentUpload } from '@portals/icons/linear/document-upload.svg';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
import { RowMenuItems, SmartTable } from '@portals/table';
import {
  AccessLevelEnum,
  OrganizationFileResponseType,
  TableColumn,
  TableFilterTypeEnum,
  TableViewModeType,
} from '@portals/types';

import { SpaceFileCard } from './SpaceFileCard';

interface SpaceFilesTableProps {
  space: SpaceType;
  files: Array<OrganizationFileResponseType>;
  viewType: TableViewModeType;
}

export function SpaceFilesList({
  space,
  files,
  viewType,
}: SpaceFilesTableProps) {
  const deleteSpaceFile = useDeleteSpaceFile();
  const asyncConfirmation = useConfirmationModal();

  const onDeleteFile = async (file: OrganizationFileResponseType) => {
    const isConfirmed = await asyncConfirmation({
      title: 'Delete File?',
      description: `Are you sure you want to delete "${file.name}"`,
    });

    if (isConfirmed) {
      deleteSpaceFile.mutate({ spaceId: space.id, fileId: file.id });
    }
  };

  const columns = useMemo<TableColumn<OrganizationFileResponseType>[]>(
    () => [
      {
        dataField: 'name',
        text: 'Name',
        sort: true,
        formatter: (_, { id, name }) => (
          <Anchor component={Link} to={`/files/${id}`}>
            {name}
          </Anchor>
        ),
        filter: {
          type: TableFilterTypeEnum.Text,
        },
      },
      {
        dataField: 'type',
        text: 'Type',
        sort: true,
        filter: {
          type: TableFilterTypeEnum.Text,
        },
      },
    ],
    []
  );

  return (
    <SmartTable<OrganizationFileResponseType>
      keyField="id"
      name="organizations.files"
      data={files}
      columns={columns}
      exportParams={{ isEnabled: false }}
      noHeader
      isUrlSyncEnabled={false}
      noColumnsSelection
      additionalActions={() => (
        <EntityAccess
          minLevel={AccessLevelEnum.Edit}
          entity={space}
          id="overview-space-files-button"
        >
          <ModalButton
            modalName="SpaceFileUploader"
            label="Upload File"
            data={{ space }}
            leftIcon={<DocumentUpload />}
          />
        </EntityAccess>
      )}
      viewType={viewType}
      gridView={{
        itemRenderer: ({ item }) => <SpaceFileCard file={item.original} />,
      }}
      noDataIndication={{ title: 'No files' }}
      defaultSorted={[{ id: 'name', desc: false }]}
      noFilters
      pageSize={12}
      rowMenu={({ row, wrapperProps }) => (
        <RowMenuItems
          wrapperProps={wrapperProps}
          items={[
            {
              id: 'view',
              onClick: () => {
                wrapperProps.menuRef?.onClose();
                window.open(row.original.url, '_blank', 'noopener noreferrer');
              },
              label: 'Download',
              Icon: DocumentDownload,
            },
            {
              id: 'delete',
              onClick: () => {
                wrapperProps.menuRef?.onClose();
                onDeleteFile(row.original);
              },
              label: 'Delete',
              Icon: Trash,
              color: 'red',
            },
          ]}
        />
      )}
    />
  );
}
