import {
  IncidentPriorityEnum,
  SpaceTreeNodeType,
} from '@portals/api/organizations';

import { DataLevelEnum } from '../../../overview.types';

interface GetIncidentsCountProps {
  spaceTreeChildren: SpaceTreeNodeType[];
  totalDevicesCount: number | undefined;
  dataLevel: DataLevelEnum;
  localDevicesCount: number;
}

export function countSpacesWithDevices({
  spaceTreeChildren,
  dataLevel,
  localDevicesCount,
  totalDevicesCount,
}: GetIncidentsCountProps) {
  if (!totalDevicesCount) {
    return 0;
  }

  if (!spaceTreeChildren.length) {
    return 1;
  }

  if (dataLevel === DataLevelEnum.Local) {
    return 1;
  }

  for (const space of spaceTreeChildren) {
    if (space.local_device_count && space.local_device_count > 0) {
      localDevicesCount++;
    }

    if (space.children && space.children.length > 0 && space.device_count) {
      localDevicesCount = countSpacesWithDevices({
        spaceTreeChildren: space.children,
        totalDevicesCount,
        dataLevel,
        localDevicesCount,
      });
    }
  }

  return localDevicesCount;
}

export function countSpacesWithIncidentsIncludingChildren(
  selectedIncidentPriorities: IncidentPriorityEnum[],
  space: SpaceTreeNodeType
) {
  let incidentsCounter = countLocalIncidentsInSpace(
    selectedIncidentPriorities,
    space
  );

  for (const spaceChild of space.children) {
    incidentsCounter += countSpacesWithIncidentsIncludingChildren(
      selectedIncidentPriorities,
      spaceChild
    );
  }

  return incidentsCounter;
}

export function countLocalIncidentsInSpace(
  selectedIncidentPriorities: IncidentPriorityEnum[],
  space: SpaceTreeNodeType
) {
  let incidentsCounter = 0;

  for (const incidentPriority of selectedIncidentPriorities) {
    const incidentPriorityKey =
      incidentPriority.toLowerCase() as keyof typeof space.local_incidents;

    if (space.local_incidents[incidentPriorityKey] > 0) {
      incidentsCounter += 1;
      break;
    }
  }

  return incidentsCounter;
}
