import React, { createContext, ReactNode, useContext } from 'react';

interface RouteOverlaysContextType {
  onClose: () => void;
}

const RouteOverlaysContext = createContext<RouteOverlaysContextType | null>(
  null
);

interface RouteOverlaysProviderProps {
  onClose: () => void;
  children?: ReactNode;
}

export function RouteOverlaysProvider({
  onClose,
  children,
}: RouteOverlaysProviderProps) {
  return (
    <RouteOverlaysContext.Provider value={{ onClose }}>
      {children}
    </RouteOverlaysContext.Provider>
  );
}

export function useRouteOverlays() {
  const context = useContext(RouteOverlaysContext);

  if (context === null) {
    throw new Error(
      'useRouteOverlays must be used within a RouteOverlaysProvider'
    );
  }

  return context;
}
