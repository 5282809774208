import { Button } from '@mantine/core';
import { getOr } from 'lodash/fp';
import React, { useContext } from 'react';

import { AutoFormik } from '@portals/autoformik';
import { FieldTypeEnum } from '@portals/types';
import { upcaseFirst } from '@portals/utils';

import { IntegrationsContext } from './Integrations';

const FIELDS = [
  { name: 'create', title: 'Create incident URL' },
  { name: 'update', title: 'Update incident URL' },
  { name: 'close', title: 'Close incident URL' },
  {
    name: 'custom_header',
    title: 'Add custom header to requests',
    type: FieldTypeEnum.Checkbox,
    default: false,
  },
  {
    name: 'custom_header_group',
    title: 'Custom Header',
    type: FieldTypeEnum.Group,
    children: [
      {
        name: 'custom_header_name',
        title: 'Field name',
        placeholder: 'e.g. Authentication',
      },
      {
        name: 'custom_header_value',
        title: 'Field value',
        placeholder: 'Sample key',
      },
    ],
    hidden: ({ custom_header }) => !custom_header,
  },
  {
    name: 'custom_param',
    title: 'Add custom parameter to requests',
    type: FieldTypeEnum.Checkbox,
    default: false,
  },
  {
    name: 'custom_param_group',
    title: 'Custom Param',
    type: FieldTypeEnum.Group,
    children: [
      {
        name: 'custom_param_name',
        title: 'Field name',
        placeholder: 'e.g. Client ID',
      },
      {
        name: 'custom_param_value',
        title: 'Field value',
        placeholder: 'Sample ID',
      },
    ],
    hidden: ({ custom_param }) => !custom_param,
  },
];

const ShowError = ({ settings, field }) => {
  const error = settings[field + '_error'];

  return error ? (
    <p className="text-danger">
      Error using {upcaseFirst(field)} URL : {error}
    </p>
  ) : null;
};

const ShowErrors = ({ settings }) => (
  <div className="mt-3">
    {FIELDS.map(({ name }) => (
      <ShowError key={name} settings={settings} field={name} />
    ))}
  </div>
);

const TestConnection = (testConnection, id) => (values) =>
  ['create', 'update', 'close'].map((name) => (
    <Button
      key={name}
      onClick={() => testConnection(id, { method: name, url: values[name] })}
    >
      Test {name}
    </Button>
  ));

const EditWebHook = ({ id = 'web_hook' }) => {
  const { integrations, updateIntegration, testConnection } =
    useContext(IntegrationsContext);
  const settings = getOr({}, [id, 'params'], integrations);

  return (
    <div>
      <AutoFormik
        fields={FIELDS}
        initialValues={settings}
        inProgress={false} // For now we choose not to connect the Component only to get the traffic. 22.9.21 Dor (approved by boris)
        handleSubmit={(values) => updateIntegration(id, values)}
        wrapped={TestConnection(testConnection, id)}
      />

      <ShowErrors settings={settings} />
    </div>
  );
};

export default EditWebHook;
