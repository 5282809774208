import { CustomInput } from 'reactstrap';
import styled from 'styled-components';

import { getStyledThemeColor } from '@portals/utils';

const Switch = styled(CustomInput)`
  .custom-control-label {
    font-weight: 700;
  }

  .custom-control-label:after,
  .custom-control-input:checked ~ .custom-control-label:after {
    background-color: ${getStyledThemeColor('white')};
  }

  .custom-control-label:before {
    border-color: #b8c1c1;
    background-color: #b8c1c1;
  }

  .custom-control-input[disabled] ~ .custom-control-label,
  .custom-control-input:disabled:checked ~ .custom-control-label {
    color: inherit;

    :before {
      border-color: #d3d7d7;
      background-color: #d3d7d7;
    }

    :after {
      background-color: #b8c1c1;
    }
  }
`;

export default Switch;
