import {
  Box,
  Button,
  createStyles,
  Divider,
  Group,
  Modal,
  Stack,
  Text,
  UnstyledButton,
} from '@mantine/core';
import { ModalProps as MantineModalProps } from '@mantine/core/lib/Modal/Modal';
import { useForm } from '@mantine/form';
import { useMediaQuery } from '@mantine/hooks';
import React from 'react';

import {
  useCreditCards,
  useReplaceCreditCard,
} from '@portals/api/organizations';
import { ModalFooter } from '@portals/core';
import { ModalProps } from '@portals/framework';
import { ReactComponent as Add } from '@portals/icons/linear/add.svg';
import { ReactComponent as ArrowDown } from '@portals/icons/linear/arrow-down-1.svg';
import { useOpenModal } from '@portals/redux';
import { CreditCardType } from '@portals/types';

import { PaymentsSuccessfullyTransferredModalProps } from './PaymentsSuccessfullyTansferredModal';
import { CardDetails } from '../pages/settings/credit-cards/CardDetails';
import { CreditCardsSelect } from '../pages/settings/credit-cards/CreditCardsSelect';

export interface TransferPaymentModalProps
  extends ModalProps<{ currentCard: CreditCardType }> {}

export function TransferPaymentModal({
  closeMe,
  data: { currentCard },
}: TransferPaymentModalProps) {
  const { classes, theme } = useStyles();

  const creditCards = useCreditCards();
  const openModal = useOpenModal();
  const replaceCreditCard = useReplaceCreditCard();
  const isSmallerThanMd = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

  const form = useForm({
    initialValues: {
      selectedCardId: null,
    },
  });

  const creditCardsOptions = creditCards.data?.filter(
    (card) => card.id !== currentCard.id && !card.archived_at
  );

  const creditCardsData = creditCardsOptions.map((card) => {
    const label = `${card.brand} •••• ${card.last_4_digits}    ${card.exp_month}/${card.exp_year}`;

    return {
      value: card.id,
      label,
      card,
    };
  });

  const onSubmit = (values: typeof form.values) => {
    replaceCreditCard.mutate(
      {
        oldCreditCardId: currentCard.id,
        newCreditCardId: values.selectedCardId,
      },
      {
        onSuccess: () => {
          closeMe();

          const newCard = creditCardsOptions.find(
            (card) => card.id === values.selectedCardId
          );

          openModal<PaymentsSuccessfullyTransferredModalProps['data']>(
            'PaymentsSuccessfullyTransferredModal',
            {
              oldCard: currentCard,
              newCard: newCard,
            }
          );
        },
      }
    );
  };

  return (
    <Modal
      opened
      onClose={closeMe}
      title="Transfer payments"
      styles={modalStyles}
    >
      <Text weight={600} mb="md">
        Transfer all future payments to other credit card.
      </Text>

      <Stack spacing="xs">
        <Text color="gray.6" weight={600}>
          Current card
        </Text>

        <Box
          className={classes.cardContainer}
          data-testid="transfer-payments-modal-current-card-box"
        >
          <CardDetails card={currentCard} withLogo={false} />
        </Box>

        <Divider
          my={isSmallerThanMd ? 'xs' : 'lg'}
          label={<ArrowDown color={theme.colors.gray[7]} />}
          labelPosition="center"
        />

        <form onSubmit={form.onSubmit(onSubmit)}>
          <CreditCardsSelect
            data={creditCardsData}
            withinPortal
            {...form.getInputProps('selectedCardId')}
          />
          <UnstyledButton
            mt="sm"
            onClick={() =>
              openModal('AddCreditCard', {
                returnUrl: `${window.location.origin}/new-card-confirmation/?current_card_id=${currentCard.id}`,
              })
            }
          >
            <Group spacing={4}>
              <Add width={16} height={16} />
              <Text weight={600} color="gray.9" size="sm">
                Add new card
              </Text>
            </Group>
          </UnstyledButton>

          <ModalFooter position="right" mt="xl">
            <Button variant="default" size="md" onClick={closeMe}>
              Cancel
            </Button>
            <Button
              type="submit"
              size="md"
              disabled={!form.values.selectedCardId}
              data-testid="transfer-payments-modal-transfer-button"
            >
              Transfer
            </Button>
          </ModalFooter>
        </form>
      </Stack>
    </Modal>
  );
}

const modalStyles: MantineModalProps['styles'] = (theme) => ({
  header: {
    padding: `${theme.spacing.xxl} ${theme.spacing.xxl} ${theme.spacing.md}`,
  },
  body: {
    padding: `0 ${theme.spacing.xxl} ${theme.spacing.xl}`,
  },
});

const useStyles = createStyles((theme) => ({
  cardContainer: {
    border: `1px solid ${theme.colors.gray[3]}`,
    padding: theme.spacing.lg,
    borderRadius: theme.radius.md,
  },
}));
