import React from 'react';
import { Spinner, SpinnerProps } from 'reactstrap';
import styled from 'styled-components';

export type LoaderProps = {
  className?: string;
} & SpinnerProps;

export const Loader: React.FC<LoaderProps> = ({ className, ...rest }) => (
  <Container className={className}>
    <Spinner color="primary" {...rest} />
  </Container>
);

const Container = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default Loader;
