import {
  Badge,
  BadgeProps,
  createStyles,
  Group,
  HoverCard,
  Text,
} from '@mantine/core';
import { keyBy } from 'lodash/fp';
import React from 'react';

import { ReactComponent as ArrowRight } from '@portals/icons/linear/arrow-right.svg';
import { ReactComponent as Eye } from '@portals/icons/linear/eye.svg';
import {
  AuditLogParamsType,
  AuditLogType,
  UserPermissionNames,
  UserPermissions,
} from '@portals/types';
import {
  ACCESS_LEVEL_DISPLAY_NAMES,
  PERMISSION_DISPLAY_NAMES,
} from '@portals/utils';

interface UpdateGroupPermissionsProps {
  auditLog: AuditLogType;
}

export function UpdateGroupPermissions({
  auditLog,
}: UpdateGroupPermissionsProps) {
  const { classes, theme } = useStyles();

  const paramsMap = keyBy('name', auditLog.params);

  const groupParam = paramsMap['group'];
  const currentPermissionsParam = paramsMap['current_permissions'];
  const newPermissionsParam = paramsMap['new_permissions'];

  const permissionsDiff = getPermissionsDiff(
    currentPermissionsParam.value,
    newPermissionsParam.value
  );

  return (
    <Group spacing="xs">
      <Text>
        Updated permissions for {groupParam?.model}:{' '}
        <strong>{groupParam?.display_name}</strong>
      </Text>

      {permissionsDiff?.length ? (
        <HoverCard
          withArrow
          arrowSize={15}
          shadow="lg"
          width="fit-content"
          radius="md"
        >
          <HoverCard.Target>
            <Eye width={20} height={20} />
          </HoverCard.Target>

          <HoverCard.Dropdown px="xl" pt="xl" pb="md">
            <div>
              {permissionsDiff.map((item) => (
                <div className={classes.row} key={item.permissionName}>
                  <Text weight={600} color="gray.9">
                    {item.permissionName}
                  </Text>

                  <Badge
                    color="gray.6"
                    radius="md"
                    size="xl"
                    styles={badgeStyles}
                  >
                    {item.oldValue}
                  </Badge>

                  <ArrowRight color={theme.colors.gray[5]} />

                  <Badge
                    color="gray.9"
                    radius="md"
                    size="xl"
                    styles={badgeStyles}
                  >
                    {item.newValue}
                  </Badge>
                </div>
              ))}
            </div>
          </HoverCard.Dropdown>
        </HoverCard>
      ) : null}
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  row: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 24px 1fr',
    gap: theme.spacing.md,
    paddingBlock: theme.spacing.md,
    alignItems: 'center',

    '&:not(:last-of-type)': {
      borderBottom: `1px solid ${theme.colors.gray[2]}`,
    },
  },
}));

const badgeStyles: BadgeProps['styles'] = (theme) => ({
  root: {
    fontSize: theme.fontSizes.sm,
    fontWeight: 600,
    backgroundColor: theme.colors.gray[1],
  },
});

function getPermissionsDiff(
  oldPermissions: AuditLogParamsType['value'],
  newPermissions: AuditLogParamsType['value']
) {
  try {
    if (
      typeof oldPermissions !== 'string' ||
      typeof newPermissions !== 'string'
    ) {
      throw new Error('Invalid permissions type');
    }

    const oldPermissionsAsJson = JSON.parse(oldPermissions) as UserPermissions;
    const newPermissionsAsJson = JSON.parse(newPermissions) as UserPermissions;

    const result: Array<{
      permissionName: string;
      oldValue: string;
      newValue: string;
    }> = [];

    (
      Object.entries(newPermissionsAsJson) as Array<
        [UserPermissionNames, UserPermissions[UserPermissionNames]]
      >
    ).forEach(([permissionName, permissionValue]) => {
      if (oldPermissionsAsJson[permissionName] !== permissionValue) {
        result.push({
          permissionName: PERMISSION_DISPLAY_NAMES[permissionName],
          oldValue:
            ACCESS_LEVEL_DISPLAY_NAMES[oldPermissionsAsJson[permissionName]],
          newValue: ACCESS_LEVEL_DISPLAY_NAMES[permissionValue],
        });
      }
    });

    return result;
  } catch (e) {
    console.error(e);
  }
}
