import { Button, ButtonProps } from '@mantine/core';
import React from 'react';

import {
  useOrganizationConfig,
  useStartFreeTrial,
} from '@portals/api/organizations';
import { useOpenModal } from '@portals/redux';

interface StartFreeTrialButtonProps extends ButtonProps {}

export function StartFreeTrialButton({
  children,
  ...buttonProps
}: StartFreeTrialButtonProps) {
  const openModal = useOpenModal();
  const startFreeTrial = useStartFreeTrial();
  const organizationConfig = useOrganizationConfig();

  if (organizationConfig?.free_trial_status !== 'available') {
    return null;
  }

  const onClick = async () => {
    try {
      await startFreeTrial.mutateAsync();

      openModal('FreeTrialModal');
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Button
      onClick={onClick}
      loading={startFreeTrial.isLoading}
      {...buttonProps}
    >
      {children || 'Start 30-day Free Trial'}
    </Button>
  );
}
