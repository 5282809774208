import { Badge } from '@mantine/core';
import React from 'react';

interface OwnerBadgeProps {
  isPartnerFile: boolean;
}

export function OwnerBadge({ isPartnerFile }: OwnerBadgeProps) {
  return (
    <Badge radius="sm" color={isPartnerFile ? 'primary' : 'purple'}>
      {isPartnerFile ? 'Manufacturer' : 'Organization'}
    </Badge>
  );
}
