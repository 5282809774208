import { Group, Stack, Text } from '@mantine/core';
import React from 'react';

import { prettyTimeMoment, timeAgo } from '@portals/utils';

interface DateCellProps {
  date: string | null;
  format?: string;
  withTimeAgo?: boolean;
}

export const DateCell = ({
  date,
  format,
  withTimeAgo = true,
}: DateCellProps) => {
  if (!date) return null;

  const parsedDate = Date.parse(date);

  return (
    <Group
      noWrap
      sx={(theme) => ({
        color: theme.colors.blue_gray[7],

        svg: {
          flexShrink: 0,
          width: 18,
          height: 18,
        },
      })}
    >
      <Stack spacing={0}>
        <Text size="sm" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {prettyTimeMoment(parsedDate, format)}
        </Text>

        {withTimeAgo ? (
          <Text size="sm" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            ({timeAgo(parsedDate)})
          </Text>
        ) : null}
      </Stack>
    </Group>
  );
};
