import { createStyles, Group, Stack, Text } from '@mantine/core';
import { get, isUndefined, last } from 'lodash/fp';
import React, { FC } from 'react';

import { EmptyState } from '@portals/table';

import { WidgetProps } from './types';
import { ReactComponent as MetricWidgetIcon } from '../../../assets/img/metric-widget.svg';

const MetricWidget: FC<WidgetProps> = ({ widget, deviceStates }) => {
  const { classes, cx } = useStyles();
  const { fields } = widget.config;
  const {
    tracked_measurement,
    tracked_measurement_unit,
    tracked_measurement_hide_icon,
  } = fields;

  const lastDeviceState = last(deviceStates)?.data;

  const value = get([tracked_measurement], lastDeviceState);

  if (isUndefined(value)) return <EmptyState label="No data available" />;

  return (
    <Stack className={classes.container}>
      <Group align="center" position="center">
        {tracked_measurement_hide_icon ? null : <MetricWidgetIcon />}

        <Stack>
          <Text
            color="gray.6"
            align={tracked_measurement_unit ? 'center' : 'left'}
            className={cx('value', {
              'with-unit': !!tracked_measurement_unit,
            })}
          >
            {value}
          </Text>

          {tracked_measurement_unit ? (
            <div className="unit" color="gray.5">
              {tracked_measurement_unit}
            </div>
          ) : null}
        </Stack>
      </Group>
    </Stack>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    justifyContent: 'center',

    '.value': {
      fontSize: 60,
      lineHeight: '60px',

      '&.with-unit': {
        fontSize: 40,
        lineHeight: '40px',
      },
    },

    '.unit': {
      fontSize: 20,
      lineHeight: '20px',
      color: theme.colors.gray[5],
      textAlign: 'center',
    },
  },
}));

export default MetricWidget;
