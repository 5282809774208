import {
  Button,
  Paper,
  Stack,
  Text,
  TextInput,
  LoadingOverlay,
  createStyles,
  ButtonProps,
  Box,
  Indicator,
  Menu,
  IndicatorProps,
} from '@mantine/core';
import {
  Dropzone,
  DropzoneProps,
  FileWithPath,
  IMAGE_MIME_TYPE,
} from '@mantine/dropzone';
import { useForm } from '@mantine/form';
import React, { useRef, useState } from 'react';

import { useUploadFileToS3 } from '@portals/api';
import { useCurrentUser, useUpdateUser } from '@portals/api/ui';
import { NameAbbreviationAvatar } from '@portals/core';
import { ReactComponent as Edit } from '@portals/icons/linear/edit-3.svg';
import { ReactComponent as MoreIcon } from '@portals/icons/linear/more.svg';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
import { useOpenModal } from '@portals/redux';

export function MyAccount() {
  const currentUser = useCurrentUser();

  const { classes } = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const openRef = useRef<() => void>(null);

  const updateUser = useUpdateUser();
  const uploadFileToS3 = useUploadFileToS3();

  const form = useForm({
    initialValues: {
      fullName: currentUser.data?.name ?? '',
      email: currentUser.data?.email ?? '',
      profileImage: currentUser.data?.settings.profile_image ?? '',
    },
  });

  const openModal = useOpenModal();

  const onRemoveAvatar = () => {
    if (!currentUser.data) {
      return;
    }

    updateUser.mutate(
      {
        user: {
          ...currentUser.data,
          settings: {
            ...currentUser.data.settings,
            profile_image: '',
          },
        },
      },
      {
        onSuccess: () => {
          form.setFieldValue('img', '');
        },
      }
    );
  };

  const onEdit = () => {
    openRef.current?.();
  };

  const onDrop = async (files: FileWithPath[]) => {
    if (!currentUser.data) {
      return;
    }

    try {
      setIsLoading(true);

      const fileBlob = await fileToBlob(files[0]);
      const fileUrl = await uploadFileToS3.mutateAsync({
        originalFileName: files[0].name,
        blob: fileBlob,
      });

      updateUser.mutate(
        {
          user: {
            ...currentUser.data,
            settings: {
              ...currentUser.data.settings,
              profile_image: fileUrl,
            },
          },
        },
        {
          onSuccess: () => {
            form.setFieldValue('img', fileUrl);
          },
          onError: () => {
            form.setFieldError('img', 'Failed to upload image');
          },
          onSettled: () => {
            setIsLoading(false);
          },
        }
      );
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const onChangeName = () => {
    if (!currentUser.data) {
      return;
    }

    if (form.values.fullName === currentUser.data.name) {
      return;
    }

    updateUser.mutate({
      user: {
        ...currentUser.data,
        name: form.values.fullName,
      },
    });
  };

  if (!currentUser.data) {
    return null;
  }

  return (
    <>
      <LoadingOverlay visible={isLoading} />

      <Stack spacing="xl">
        <Stack ta="center" w="fit-content" className={classes.container}>
          <Box className={classes.dropzoneContainer}>
            <Dropzone
              onDrop={onDrop}
              accept={IMAGE_MIME_TYPE}
              className={classes.dropzone}
              activateOnClick={false}
              openRef={openRef}
              styles={dropzoneStyles}
              pt="lg"
              p={0}
            >
              <Indicator
                label={
                  currentUser.data.settings.profile_image ? (
                    <IndicatorMenu
                      onRemoveAvatar={onRemoveAvatar}
                      onEdit={onEdit}
                    />
                  ) : (
                    <Edit color="black" onClick={onEdit} />
                  )
                }
                size={40}
                color="white"
                withBorder
                offset={10}
                styles={indicatorStyles}
              >
                <NameAbbreviationAvatar
                  name={currentUser.data.name}
                  radius={98}
                  size={98}
                  withToolTip={false}
                  src={currentUser.data.settings.profile_image}
                />
              </Indicator>
            </Dropzone>
          </Box>

          <Stack spacing="xs">
            <Text c="gray.9" fz="lg" data-testid="current-user-name">
              {currentUser.data.name}
            </Text>

            <Text c="gray.5">{currentUser.data.email}</Text>
          </Stack>
        </Stack>

        <Paper p="xxl" radius="lg" withBorder>
          <form>
            <Stack align="flex-start" spacing="xl">
              <Text fz="md" c="gray.8">
                Profile
              </Text>

              <TextInput
                label="Full name"
                placeholder="Full name"
                {...form.getInputProps('fullName')}
                onBlur={onChangeName}
                data-testid="full-name-text-input"
              />

              <Stack spacing="xxl">
                <Text>Password</Text>

                <Button
                  variant="light"
                  bg="gray.1"
                  onClick={() => openModal('ChangePasswordModal')}
                  styles={buttonStyles}
                  data-testid="change-password-button"
                >
                  Change password
                </Button>
              </Stack>
            </Stack>
          </form>
        </Paper>
      </Stack>
    </>
  );
}

interface IndicatorMenuProps {
  onRemoveAvatar: () => void;
  onEdit: () => void;
}

function IndicatorMenu({ onRemoveAvatar, onEdit }: IndicatorMenuProps) {
  return (
    <Menu position="bottom-start">
      <Menu.Target>
        <MoreIcon color="black" />
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item icon={<Edit />} onClick={onEdit}>
          Edit
        </Menu.Item>
        <Menu.Item icon={<Trash />} onClick={onRemoveAvatar}>
          Delete
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}

async function fileToBlob(file: File) {
  return new Blob([new Uint8Array(await file.arrayBuffer())], {
    type: file.type,
  });
}

const useStyles = createStyles(() => ({
  dropzone: {
    border: 0,
    backgroundColor: 'transparent',
    alignSelf: 'center',
  },

  dropzoneContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
    alignSelf: 'center',
  },

  container: {
    alignSelf: 'center',
  },
}));

const buttonStyles: ButtonProps['styles'] = (theme) => ({
  root: {
    color: theme.colors.gray[9],
  },
});

const indicatorStyles: IndicatorProps['styles'] = (theme) => ({
  common: {
    border: `1px solid ${theme.colors.gray[4]}`,

    '&:hover': {
      border: `1px solid ${theme.colors.gray[7]}`,
    },
  },
});

const dropzoneStyles: DropzoneProps['styles'] = () => ({
  inner: { pointerEvents: 'all' },
});
