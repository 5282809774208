import { combineReducers } from 'redux';

import data from './data';
import errors from './errors';
import layout from './layoutReducer';
import modals from './modals';
import sidebar from './sidebarReducers';
import store from './store';
import ui from './ui';

export default combineReducers({
  store,
  sidebar,
  layout,
  ui,
  data,
  modals,
  errors,
});
