import React, { createContext, ReactNode, useContext } from 'react';

interface TreeContextProviderProps {
  expandedNodes: Array<number>;
  children?: ReactNode;
}

const TreeContext = createContext<TreeContextProviderProps>({
  expandedNodes: [],
});

export function TreeContextProvider({
  children,
  expandedNodes,
}: TreeContextProviderProps) {
  return (
    <TreeContext.Provider value={{ expandedNodes }}>
      {children}
    </TreeContext.Provider>
  );
}

export const useTreeContext = () => useContext(TreeContext);
