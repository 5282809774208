import { Badge, BadgeProps, createStyles, MantineSize } from '@mantine/core';
import React from 'react';

import { ReactComponent as Crown } from '@portals/icons/linear/crown-1.svg';

import {
  UpgradeTierHoverCard,
  UpgradeTierHoverCardProps,
} from './UpgradeTierHoverCard';

export interface UpgradeTierBadgeProps extends UpgradeTierHoverCardProps {
  badgeProps?: Partial<BadgeProps>;
  size?: MantineSize;
  label?: string;
}

export function UpgradeTierBadgeWithText({
  badgeProps = {},
  size = 'md',
  label = 'Upgrade',
  ...upgradeTierHoverCardProps
}: UpgradeTierBadgeProps) {
  const { classes, theme } = useBadgeStyles();

  return (
    <UpgradeTierHoverCard {...upgradeTierHoverCardProps}>
      <Badge
        py="sm"
        size={size}
        leftSection={
          <Crown width={theme.fontSizes[size]} height={theme.fontSizes[size]} />
        }
        classNames={classes}
        {...badgeProps}
      >
        {label}
      </Badge>
    </UpgradeTierHoverCard>
  );
}

export function UpgradeTierBadge({
  badgeProps = {},
  size = 'md',
  ...upgradeTierHoverCardProps
}: UpgradeTierBadgeProps) {
  const { classes, theme } = useBadgeStyles();

  return (
    <UpgradeTierHoverCard {...upgradeTierHoverCardProps}>
      <Badge classNames={classes} size={size} {...badgeProps}>
        <Crown width={theme.fontSizes[size]} height={theme.fontSizes[size]} />
      </Badge>
    </UpgradeTierHoverCard>
  );
}

const useBadgeStyles = createStyles((theme) => ({
  root: {
    cursor: 'pointer',
    background: 'linear-gradient(84.32deg, #64B5F6 -28.06%, #304FFE 105.96%)',
    color: theme.white,
    textTransform: 'unset',
    fontWeight: 'normal',
  },
}));
