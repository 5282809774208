import * as DINERO_CURRENCIES from '@dinero.js/currencies';

import { CurrencyCode } from '@portals/types';

const currencyCodes = Object.keys(DINERO_CURRENCIES) as CurrencyCode[];

const currencyName = new Intl.DisplayNames(['en'], { type: 'currency' });

const CURRENCIES = currencyCodes.reduce((currencies, code) => {
  currencies[code] = currencyName.of(code) ?? 'Currency name not found';
  return currencies;
}, {} as Record<CurrencyCode, string>);

export { CURRENCIES };
