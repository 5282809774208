import { Dispatch, ReactNode, SetStateAction } from 'react';

export enum WizardTransitionType {
  Next = 'next',
  Prev = 'prev',
  Finish = 'finish',
  Skip = 'skip',
}

export type WizardOnTransition<
  ContextData = unknown,
  StepIds extends string = string
> = (params: {
  transitionType: WizardTransitionType;
  from: StepIds;
  to?: StepIds;
  contextData: ContextData;
  setContextData: Dispatch<SetStateAction<ContextData>>;
}) => Promise<boolean>;

type StepCondition<
  ContextData = unknown,
  StepIds extends string = string
> = (params: {
  contextData: ContextData;
  currentStepId: StepIds;
  steps: WizardSteps<ContextData, StepIds>;
}) => boolean;

type StepTransition<ContextData = unknown, StepIds extends string = string> =
  | { id: StepIds }
  | Array<{
      id: StepIds;
      condition: StepCondition<ContextData, StepIds>;
    }>;

interface StepTransitionConfig<
  ContextData = unknown,
  StepIds extends string = string
> {
  transition?: StepTransition<ContextData, StepIds>;
  isDisabled?: StepCondition<ContextData, StepIds>;
}

export interface WizardStep<
  ContextData = unknown,
  StepIds extends string = string
> {
  id: StepIds;
  component: ReactNode;
  onTransition?: WizardOnTransition<ContextData, StepIds>;
  isFinalStep: boolean | ((params: { contextData: ContextData }) => boolean);
  [WizardTransitionType.Next]?: StepTransitionConfig<ContextData, StepIds>;
  [WizardTransitionType.Prev]?: StepTransitionConfig<ContextData, StepIds>;
}

export type WizardSteps<
  ContextData = unknown,
  StepIds extends string = string
> = Record<StepIds, WizardStep<ContextData, StepIds>>;

export type SkipToStepFn<StepIds> = (
  stepId: StepIds,
  transitionOptions?: { runTransitionHandler: boolean; forceSkip: boolean }
) => Promise<void>;

export interface WizardContextType<
  ContextData = unknown,
  StepIds extends string = string
> {
  steps: WizardSteps<ContextData, StepIds>;
  currentStep: WizardStep<ContextData, StepIds>;
  onNext: () => void;
  onPrev: () => void;
  setStep: (step: StepIds) => void;
  isNextDisabled: boolean;
  isPrevDisabled: boolean;
  isFinalStep: boolean;
  isInTransition: boolean;
  contextData: ContextData;
  setContextData: Dispatch<SetStateAction<ContextData>>;
  skipToStep: SkipToStepFn<StepIds>;
}

export interface WizardProviderProps<
  ContextData = unknown,
  StepIds extends string = string
> {
  children: ReactNode;
  steps: WizardSteps<ContextData, StepIds>;
  initialStep: StepIds;
  contextData: ContextData;
  setContextData: Dispatch<SetStateAction<ContextData>>;
  onTransition?: WizardOnTransition<ContextData, StepIds>;
}
