import { Global } from '@mantine/core';
import React, { useState } from 'react';
import Joyride, { CallBackProps, Step } from 'react-joyride';
import { useNavigate } from 'react-router-dom';
import { useMount } from 'react-use';

import {
  GettingStartedToursNamesEnum,
  useCompleteGettingStartedTour,
  useOnboardingTours,
} from '@portals/api/organizations';
import { TOUR_STEPS_IDS } from '@portals/framework';
import { useOpenModal } from '@portals/redux';

import { useMonitoringTourContext } from '../MonitoringTourContextProvider';
import { TourStepTooltip } from '../TourStepTooltip';

export function MonitoringTourPartTwo() {
  const openModal = useOpenModal();
  const { setRunTourPartOne, runTourPartTwo, setRunTourPartTwo } =
    useMonitoringTourContext();
  const completeGettingStartedTour = useCompleteGettingStartedTour();
  const navigate = useNavigate();
  const onBoardingTours = useOnboardingTours();

  const [runTour, setRunTour] = useState(false);

  useMount(() => {
    setRunTour(true);
  });

  const steps: Array<Step> = [
    {
      target: `[data-tour-step-id=${TOUR_STEPS_IDS.deviceActions}]`,
      title: 'Explore the Device Action Bar',
      content:
        'Clicking on Support will allow you to report an incident, open a ticket, or turn off incident tracking. You may also replace or delete a device using the three dots on the right-hand side of the bar.',
      disableBeacon: true,
      floaterProps: {
        disableAnimation: true,
      },
      placement: 'right',
    },
    {
      target: `[data-tour-step-id=${TOUR_STEPS_IDS.deviceDetails}]`,
      title: 'Device Details and Commands',
      content:
        'The device details will show the vendor name, device serial number, most recent connection, firmware version, cloud ID, claim date and a host of other parameters. The optional commands bar enables you to send any customized command if set up by the OEM.',
      disableBeacon: true,
      floaterProps: {
        disableAnimation: true,
      },
      placement: 'right',
      isFixed: true,
    },
    {
      target: `[data-tour-step-id=${TOUR_STEPS_IDS.deviceOverview}]`,
      title: 'Device overview',
      content:
        "The overview screen will display the device's status, incidents, pending commands, open tickets and key telemetries and metrics set up by the OEM.",
      disableBeacon: true,
      floaterProps: {
        disableAnimation: true,
      },
      placement: 'right',
    },
  ];

  const onTransitionCallback = (data: CallBackProps) => {
    const { status, index, action, lifecycle } = data;

    if (index === 0 && action === 'prev' && lifecycle === 'complete') {
      navigate(
        `/overview/?active_tour=${GettingStartedToursNamesEnum.Monitoring}`
      );
      setRunTourPartOne(true);
    }

    if (status === 'finished' && lifecycle === 'complete') {
      if (!onBoardingTours?.getting_started?.monitoring_completed) {
        openModal('OnboardingToursCompletedModal');
      }
      completeGettingStartedTour.mutate('monitoring_completed');

      setRunTourPartOne(false);
      setRunTourPartTwo(false);
      navigate('/', { replace: true });
    }
  };

  const onSkipTour = () => {
    setRunTourPartOne(false);
    setRunTourPartTwo(false);
    navigate('/', { replace: true });
  };

  return (
    <>
      <Joyride
        run={runTourPartTwo && runTour}
        steps={steps}
        continuous
        disableOverlayClose
        callback={onTransitionCallback}
        tooltipComponent={(props) => (
          <TourStepTooltip
            tourName="Monitor and manage devices"
            totalSize={4}
            startIndex={2}
            onSkip={onSkipTour}
            {...props}
          />
        )}
        styles={{
          options: {
            arrowColor: 'black',
            zIndex: 10000,
          },
        }}
      />

      <Global
        styles={() => ({
          'react-joyride__overlay': {
            position: 'fixed',
            bottom: 0,
          },
        })}
      />
    </>
  );
}
