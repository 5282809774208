import { ReactNode } from 'react';

import { OrganizationPortalCapabilities } from '@portals/types';

export enum OverviewSettingsTabEnum {
  Space = 'space',
  IncidentRouting = 'incident-routing',
  Access = 'access',
}

type RouteCheckCallback = (params: {
  portalCapabilities: OrganizationPortalCapabilities;
}) => boolean;

export interface OverviewSettingsTabType {
  sidebarLabel: ReactNode;
  tabLabel: ReactNode;
  id: OverviewSettingsTabEnum;
  component: ReactNode;
  visible?: RouteCheckCallback;
}
