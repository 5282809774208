import { TextInput } from '@mantine/core';
import React, { useCallback, useRef, useState } from 'react';

import { useUpdateSpace } from '@portals/api/organizations';

import { useOverviewContext } from '../overview.context';
import { useCurrentSpace } from '../overview.hooks';

export function SpaceNameInput() {
  const inputRef = useRef<HTMLInputElement>(null);
  const overview = useOverviewContext();
  const space = useCurrentSpace();
  const updateSpace = useUpdateSpace();

  const [nameInput, setNameInput] = useState(space.name || '');
  const isRevert = useRef(false);

  // Select all text for easy replacement upon editing mode toggle
  const onRef = useCallback((node: HTMLInputElement) => {
    if (!node) return;

    inputRef.current = node;
    inputRef.current.select();
  }, []);

  const onSearchInputKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.target.blur();
    } else if (event.key === 'Escape') {
      isRevert.current = true;

      event.target.blur();
    }
  };

  const onBlur = async () => {
    if (!nameInput || nameInput === space.name || isRevert.current) {
      isRevert.current = false;
      setNameInput(space.name);
      overview.setSpaceEditModeId(null);

      return;
    }

    try {
      await updateSpace.mutateAsync({
        spaceId: space?.id,
        updatedSpace: { name: nameInput },
      });

      overview.setSpaceEditModeId(null);
    } catch (error) {
      inputRef.current.focus();

      console.error(error?.error);
    }
  };

  return (
    <TextInput
      size="md"
      labelProps={{
        sx: (theme) => ({
          fontSize: theme.fontSize.xl,
        }),
      }}
      value={nameInput}
      w={250}
      onChange={(event) => setNameInput(event.target.value)}
      onBlur={onBlur}
      onKeyDown={onSearchInputKeyDown}
      placeholder={space.name}
      ref={onRef}
      autoFocus
      data-autofocus
    />
  );
}
