import { find, map } from 'lodash/fp';
import React, { useMemo } from 'react';

import { useActiveDeviceCommands, useDevice } from '@portals/api/organizations';
import {
  StateControllerWidget,
  StateControllerWidgetFormType,
  useDebouncedTelemetryValue,
} from '@portals/device-widgets';
import { useSendCommandWithApproval } from '@portals/framework';
import { PremiumCommandLicenseModalProps } from '@portals/framework/modals';
import { useOpenModal } from '@portals/redux';

import { useDeviceCommand } from '../../../../../../../hooks/commands';
import { WidgetProps } from '../device-widgets.types';

export function StateControllerWidgetWrapper({
  widget,
  deviceState,
  deviceId,
  isDeviceOffline,
  lastUpdateTimestamp,
}: WidgetProps<StateControllerWidgetFormType>) {
  const fields = widget?.config?.fields as StateControllerWidgetFormType;

  const sendCommandWithApproval = useSendCommandWithApproval();
  const activeCommands = useActiveDeviceCommands(deviceId);

  const {
    telemetry,
    display_title,
    display_icons,
    command_name,
    command_param_key,
    color,
    title_telemetry,
  } = fields ?? {};

  const name =
    fields?.telemetry_as_title && deviceState?.[title_telemetry]
      ? deviceState?.[title_telemetry]
      : fields?.name;

  const command = useDeviceCommand(deviceId, command_name);
  const openModal = useOpenModal();
  const device = useDevice(deviceId);

  const hasCommandLicense = useMemo(() => {
    return (device?.data?.supported_commands || []).find(
      (currentCommand) => command?.id === currentCommand.id
    )?.has_license;
  }, [command, device]);

  const onToggle = async (option: string) => {
    if (command?.premium && !hasCommandLicense) {
      openModal<PremiumCommandLicenseModalProps['data']>(
        'PremiumCommandLicenseModal',
        { deviceId, commandId: command?.id, commandName: command?.name }
      );

      return;
    }

    if (command?.custom_fields?.length) {
      openModal('RunCommand', {
        deviceType: device.data?.partner?.type_id,
        command,
        deviceIds: [deviceId],
        prepopulatedParams: {
          [command_param_key]: option,
        },
      });

      return;
    }

    try {
      await sendCommandWithApproval({
        deviceIds: [deviceId],
        command,
        extra_params: {
          [command_param_key]: option,
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  const options = map(
    (field) => ({
      key: field.key,
      label: field.label,
      iconName: field.icon_name,
      value: field.value,
      telemetryValue: field.telemetry_value || field.value,
    }),
    fields.options
  );

  const telemetryValue = deviceState?.[telemetry];
  const activeCommand = find({ name: command_name }, activeCommands.data);
  const isCommandActive = Boolean(activeCommand);

  const sentCommandValue =
    activeCommand && activeCommand.params?.[command_param_key];

  const debouncedTelemetryValue = useDebouncedTelemetryValue(
    telemetryValue,
    sentCommandValue
  );

  return (
    <StateControllerWidget
      color={color}
      title={name}
      options={options}
      onToggle={onToggle}
      isLoading={isCommandActive}
      displayTitle={display_title}
      displayIcons={display_icons}
      value={debouncedTelemetryValue ?? null}
      isDeviceOffline={isDeviceOffline}
      lastUpdateTimestamp={lastUpdateTimestamp}
    />
  );
}
