import { Descope, getSessionToken } from '@descope/react-sdk';
import { Center, Loader } from '@mantine/core';
import { jwtDecode } from 'jwt-decode';
import { noop } from 'lodash/fp';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useMatch, useNavigate } from 'react-router-dom';

import {
  useCreateOrganization,
  usePortalConfig,
} from '@portals/api/organizations';
import {
  captureDescopeError,
  sendDescopeErrorReport,
} from '@portals/framework';
import { signedIn } from '@portals/redux/actions/auth';
import { CustomerFeatureFlagEnum } from '@portals/types';

import { SignUpWizard } from '../Sign-up/SignUpWizard';

interface SignUpJWT {
  name: string;
  org: string;
  email: string;
}

export function Signup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isDescopeFlowLoading, setIsDescopeFlowLoading] = useState(true);

  const portalConfig = usePortalConfig();
  const createOrganization = useCreateOrganization();

  const match = useMatch('/auth/sign-up/:partner_name?');

  useEffect(
    function redirectToSignIn() {
      const isReferral = !!localStorage.getItem('referral');

      if (!isReferral && portalConfig.data?.signup !== true) {
        const path = generatePath('/auth/sign-in/:partner_name?', {
          partner_name: match?.params?.partner_name || null,
        });

        navigate(path, { replace: true });
      }
    },
    [match?.params?.partner_name, navigate, portalConfig.data?.signup]
  );

  // We perform a direct check here as we can't determine if it's a lab account when a user isn't logged in.
  // Therefore, if the `b2c_view` feature flag is set to either `all` or `lab-only`, we consider the account as `b2c`.
  const isB2C =
    portalConfig.data?.b2c_view === CustomerFeatureFlagEnum.All ||
    portalConfig.data?.b2c_view === CustomerFeatureFlagEnum.LabOnly;

  if (!isB2C) {
    return <SignUpWizard />;
  }

  return (
    <>
      {isDescopeFlowLoading && (
        <Center>
          <Loader />
        </Center>
      )}

      <Descope
        flowId="sign-up-b-2-c"
        onError={captureDescopeError}
        onReady={() => setIsDescopeFlowLoading(false)}
        onSuccess={(e) => {
          const sessionToken = getSessionToken();
          const parsedJWT = jwtDecode<SignUpJWT>(e.detail.sessionJwt);

          createOrganization.mutate(
            {
              org: parsedJWT.org,
              name: parsedJWT.name,
              email: parsedJWT.email,
              token: sessionToken,
            },
            {
              onSuccess: (auth) => {
                dispatch(signedIn(auth));
                navigate('/');
              },
            }
          );
        }}
        logger={{
          info: (_title, _description, state) => {
            sendDescopeErrorReport(state, 'Framework > SignUp');
          },
          debug: noop,
          warn: noop,
          error: noop,
        }}
      />
    </>
  );
}
