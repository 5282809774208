import { MantineThemeOther } from '@mantine/core';

export const THEME_OTHER: MantineThemeOther = {
  // @todo: remove `primaryColor` in favor of `theme.fn.primaryColor()`
  primaryColor: '#2979FF',
  navbarHeight: 64,

  sidebarFullWidth: 300,
  sidebarCompactWidth: 250,
  sidebarCollapsedWidth: 76,
};
