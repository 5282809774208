import React, { FC } from 'react';

import { AuthHeader } from './AuthHeader';
import { DashboardHeader } from './DashboardHeader';

type HeaderProps = {
  onOpen: () => void;
  isAuth?: boolean;
};

const Header: FC<HeaderProps> = ({ onOpen, isAuth }) =>
  isAuth ? <AuthHeader /> : <DashboardHeader onOpen={onOpen} />;

export default Header;
