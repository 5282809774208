import { Descope } from '@descope/react-sdk';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useSignIn } from '@portals/api/auth';
import {
  captureDescopeError,
  sendDescopeErrorReport,
} from '@portals/framework';
import { switchTenant } from '@portals/redux/actions/auth';
import { TenantType } from '@portals/types';

export function AutoSignIn() {
  const dispatch = useDispatch();

  const location = useLocation();

  const signIn = useSignIn();

  return (
    <Descope
      flowId="frictionless-access-via-customer-portal"
      onError={captureDescopeError}
      onSuccess={(e) => {
        signIn.mutate(
          {
            token: e.detail.sessionJwt,
            tenant_type: TenantType.Organization,
            is_support_user: true,
          },
          {
            onSuccess: (auth) => {
              const searchParams = new URLSearchParams(location.search);

              const customerId = searchParams.get('customer_id');
              const pathname =
                searchParams.get('afterAuthRedirectPathname') || '/';

              const tenant =
                customerId !== null ? { id: customerId } : auth.tenant;

              dispatch(switchTenant(tenant, TenantType.Organization, pathname));
            },
          }
        );
      }}
      logger={{
        debug: (title: string, description?: string) => {
          return;
        },
        info: (title, description, state) => {
          sendDescopeErrorReport(state, 'Organizations > AutoSignIn');
        },
        warn: () => {
          return;
        },
        error: () => {
          return;
        },
      }}
    />
  );
}
