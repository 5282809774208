import { Alert, Box, createStyles, Stack } from '@mantine/core';
import { StripeError } from '@stripe/stripe-js';
import { noop } from 'lodash/fp';
import React, { useState } from 'react';

import { ModalCenteredMediaLayout } from '@portals/core';
import { ModalProps } from '@portals/framework';
import { ReactComponent as Danger } from '@portals/icons/linear/danger.svg';
import { CardBrand } from '@portals/types';

import { CreditCard } from '../pages/checkout/common/credit-card/CreditCard';
import { CardInfoWrapper } from '../pages/checkout/steps/payment/CardInfoForm';

export interface AddCreditCardProps extends ModalProps<{ returnUrl: string }> {}

export function AddCreditCard({
  closeMe,
  data: { returnUrl },
}: AddCreditCardProps) {
  const { classes } = useStyles();

  const [error, setError] = useState<StripeError | null>(null);

  return (
    <ModalCenteredMediaLayout
      opened
      onClose={closeMe}
      mediaSize="unset"
      media={
        <CreditCard
          brand={CardBrand.unknown}
          lastDigits="****"
          expiration="MM/YY"
          onChange={noop}
          isSelected={false}
          className={classes.card}
          boxProps={{
            mb: 'lg',
            sx: {
              boxShadow: '0px 24.2667px 32.76px rgba(0, 5, 123, 0.12)',
              zIndex: 2,
            },
          }}
        />
      }
    >
      <Stack c="blue_gray.4">
        {error ? (
          <Alert
            icon={<Danger width={16} height={16} />}
            title="Something went wrong"
            data-testid="add-credit-card-error"
            color="red"
            withCloseButton
            onClose={() => setError(null)}
          >
            {error.message}
          </Alert>
        ) : null}

        <Box sx={{ minHeight: 335 }}>
          <CardInfoWrapper
            onError={setError}
            onCancel={closeMe}
            returnUrl={returnUrl}
          />
        </Box>
      </Stack>
    </ModalCenteredMediaLayout>
  );
}

const useStyles = createStyles(() => ({
  card: {
    width: 230,
  },
}));
