import React, { useMemo } from 'react';

import { PostPurchaseParametersType } from '@portals/api/organizations';

import {
  FileField,
  NumberField,
  SelectField,
  TextareaField,
  TextField,
} from './form-fields';
import { getAutoFocusIndex } from './post-purchase-parameters.utils';

interface PostPurchaseParametersFormProps {
  localPostPurchaseParams: PostPurchaseParametersType;
  onChange: (key: string, value: number | string | null) => void;
  withErrors?: boolean;
}

export function PostPurchaseParametersForm({
  localPostPurchaseParams,
  onChange,
  withErrors,
}: PostPurchaseParametersFormProps) {
  const autoFocusIndex = withErrors
    ? getAutoFocusIndex(localPostPurchaseParams)
    : 0;

  const { values, definition } =
    localPostPurchaseParams.post_purchase_parameters;

  const fields = useMemo(
    () =>
      definition.map((definition, definitionIndex) => {
        const value = values?.[definition.label]?.value;
        const inputProps =
          definitionIndex === autoFocusIndex
            ? { autoFocus: true, 'data-autofocus': true }
            : {};

        switch (definition.type) {
          case 'text':
            return (
              <TextField
                key={`${definition.label}-${definition.type}-${definitionIndex}`}
                field={definition}
                value={value}
                onChange={(value) => onChange(definition.label, value)}
                withErrors={withErrors}
                inputProps={inputProps}
              />
            );

          case 'number':
            return (
              <NumberField
                key={`${definition.label}-${definition.type}-${definitionIndex}`}
                field={definition}
                value={value as number}
                onChange={(value) => onChange(definition.label, value)}
                withErrors={withErrors}
                inputProps={inputProps}
              />
            );

          case 'select':
            return (
              <SelectField
                key={`${definition.label}-${definition.type}-${definitionIndex}`}
                field={definition}
                value={value}
                onChange={(value) => onChange(definition.label, value)}
                withErrors={withErrors}
                inputProps={inputProps}
              />
            );

          case 'file':
            return (
              <FileField
                key={`${definition.label}-${definition.type}-${definitionIndex}`}
                field={definition}
                value={value}
                onChange={(value) => onChange(definition.label, value)}
                withErrors={withErrors}
                inputProps={inputProps}
              />
            );

          case 'textarea':
            return (
              <TextareaField
                key={`${definition.label}-${definition.type}-${definitionIndex}`}
                field={definition}
                value={value}
                onChange={(value) => onChange(definition.label, value)}
                withErrors={withErrors}
                inputProps={inputProps}
              />
            );

          default:
            return null;
        }
      }),
    [autoFocusIndex, onChange, definition, values, withErrors]
  );

  return <>{fields}</>;
}
