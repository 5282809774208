import React, { Suspense } from 'react';

import {
  FeatureNotificationEnum,
  useDismissFeatureNotification,
} from '@portals/api/ui';

import { FEATURES_MAP } from './feature-notifications.constants';
import { ModalProps } from '../../components/Modals';

export interface FeaturesNotificationsProps
  extends ModalProps<{ featureNotification: FeatureNotificationEnum }> {}

export function FeaturesNotifications({
  closeMe,
  data,
}: FeaturesNotificationsProps) {
  const FeatureNotificationModal = FEATURES_MAP[data.featureNotification];
  const dismissFeatureNotification = useDismissFeatureNotification();

  if (!FeatureNotificationModal) return null;

  const onClose = () => {
    dismissFeatureNotification.mutate({
      notification_name: data.featureNotification,
    });

    closeMe();
  };

  return (
    <Suspense fallback={null}>
      <FeatureNotificationModal onClose={onClose} />
    </Suspense>
  );
}
