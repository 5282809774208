import { Anchor } from '@mantine/core';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row } from 'reactstrap';

import { IntegrationType } from '@portals/types';

interface ConfigurationMissingMessageProps {
  integration: IntegrationType<any>;
  linkTo: string;
  message: string;
  onLinkClick: () => void;
}

const ConfigurationMissingMessage: FC<ConfigurationMissingMessageProps> = ({
  integration,
  message,
  onLinkClick,
  linkTo,
}) => {
  if (integration && integration.state === 'active') {
    return null;
  }

  let linkMessage = '';

  if (!integration) {
    linkMessage = 'Click here to configure';
  } else if (integration.state !== 'active') {
    linkMessage = 'Click here to activate';
  }

  return (
    <Container>
      <Row>{message}</Row>
      <Row>
        <Anchor component={Link} to={linkTo} onClick={onLinkClick}>
          {linkMessage}
        </Anchor>
      </Row>
    </Container>
  );
};

export default ConfigurationMissingMessage;
