import {
  createStyles,
  Divider,
  LoadingOverlay,
  Stack,
  Text,
} from '@mantine/core';
import React from 'react';

import { useStoreSettings } from '@portals/api/organizations';
import { formatCurrency } from '@portals/utils';

import { useActiveOrderPreview } from '../../../../redux/hooks/store';
import { Price } from '../Price';

export function CompactOrderSummary() {
  const { classes } = useStyles();

  const orderPreview = useActiveOrderPreview();
  const storeSettings = useStoreSettings();

  if (!orderPreview.isLoading && !orderPreview.data) return null;

  const summary = orderPreview.data?.summary;
  const currency = orderPreview.data?.currency;

  if (storeSettings.data?.tax_enabled === false) {
    return null;
  }

  return (
    <Stack className={classes.container} spacing="md">
      <LoadingOverlay
        visible={orderPreview.isFetching || storeSettings.isFetching}
      />

      <Text
        size="sm"
        sx={(theme) => ({
          color: theme.colors.gray[5],
        })}
      >
        Tax will be calculated during checkout
      </Text>

      <Divider sx={(theme) => ({ borderColor: theme.colors.gray[2] })} />

      {summary ? (
        <>
          {summary?.today ? (
            <Price
              label={
                <Text size="sm" className={classes.title}>
                  Total Due Today
                </Text>
              }
              price={
                <Text size="lg">
                  {formatCurrency(summary?.today.estimated_total, currency)}
                </Text>
              }
            />
          ) : null}

          {summary?.next_month ? (
            <>
              <Divider variant="dashed" />

              <Price
                label={
                  <Text size="sm" className={classes.title}>
                    Monthly Recurring
                  </Text>
                }
                price={
                  <Text size="lg">
                    {formatCurrency(
                      summary?.next_month.estimated_total,
                      currency
                    )}
                  </Text>
                }
              />
            </>
          ) : null}

          {summary?.next_year ? (
            <>
              <Divider variant="dashed" />

              <Price
                label={
                  <Text size="sm" className={classes.title}>
                    Yearly Recurring
                  </Text>
                }
                price={
                  <Text size="lg">
                    {formatCurrency(
                      summary?.next_year.estimated_total,
                      currency
                    )}
                  </Text>
                }
              />
            </>
          ) : null}
        </>
      ) : null}
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    position: 'relative',
    minHeight: 75,
  },
  title: {
    color: theme.colors.gray[8],
  },
}));
