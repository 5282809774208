import classnames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import React, { FC } from 'react';
import { Spinner } from 'reactstrap';
import styled from 'styled-components';

type PendingOverlayProps = {
  isVisible: boolean;
  className?: string;
};

export const PendingOverlay: FC<PendingOverlayProps> = ({
  isVisible,
  className = '',
}) => (
  <AnimatePresence>
    {isVisible ? (
      <Container className={classnames('pending-overlay-container', className)}>
        <Spinner color="primary" />
      </Container>
    ) : null}
  </AnimatePresence>
);

const Container = styled(motion.div).attrs(() => ({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.1 },
}))`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

export default PendingOverlay;
