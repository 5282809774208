import { Button, ButtonProps, Group } from '@mantine/core';
import React from 'react';

import { FailureIcon } from '@portals/assets';
import { ModalCenteredMediaLayout } from '@portals/core';

import { ModalProps } from '../../components/Modals';

interface RetryChargeFailureModalProps
  extends ModalProps<{
    failureReason: string;
  }> {}

export function RetryChargeFailureModal({
  closeMe,
  data: { failureReason },
}: RetryChargeFailureModalProps) {
  return (
    <ModalCenteredMediaLayout
      opened
      onClose={closeMe}
      media={<FailureIcon />}
      mediaSize={115}
      title="Payment Attempt Failed"
      description={failureReason ? `Failure reason: ${failureReason}` : null}
      footer={
        <Group grow>
          <Button
            variant="default"
            onClick={closeMe}
            component="a"
            href="mailto:support@xyte.io"
            styles={buttonStyles}
          >
            Contact Support
          </Button>
          <Button onClick={closeMe}>Close</Button>
        </Group>
      }
    />
  );
}

const buttonStyles: ButtonProps['styles'] = (theme) => ({
  root: {
    '&:hover': {
      color: theme.black,
      textDecoration: 'none',
    },
  },
});
