import { createStyles, Stack, Title } from '@mantine/core';
import React from 'react';
import { Link, Navigate } from 'react-router-dom';

import { ProductImage } from '@portals/framework';
import { ReactComponent as ArrowLeftIcon } from '@portals/icons/linear/arrow-left.svg';
import {
  CurrencyCode,
  OrganizationStoreListing,
  ProductTypeEnum,
} from '@portals/types';

import { ProductActions } from '../../../desktop/pages/store/product-details/ProductActions';
import { ProductDescription } from '../../../desktop/pages/store/product-details/ProductDescription';
import { ProductDetailsProvider } from '../../../desktop/pages/store/product-details/ProductDetailsProvider';
import { ProductModelsAndCommands } from '../../../desktop/pages/store/product-details/ProductModelsAndCommands';
import { ProductPolicies } from '../../../desktop/pages/store/product-details/ProductPolicies';
import { ProductPrice } from '../../../desktop/pages/store/product-details/ProductPrice';

interface ProductDetailsProps {
  baseStoreUrl?: string;
  currencyCode: CurrencyCode;
  storeListing: OrganizationStoreListing | undefined;
}

export function ProductDetails({
  storeListing,
  currencyCode,
  baseStoreUrl = '/store',
}: ProductDetailsProps) {
  const { classes } = useStyles();

  if (!storeListing) {
    return <Navigate to={baseStoreUrl} replace />;
  }

  return (
    <ProductDetailsProvider storeListing={storeListing}>
      <div className={classes.container}>
        <div className={classes.imageContainer}>
          <Link to={baseStoreUrl} className={classes.storeLink}>
            <ArrowLeftIcon />
          </Link>
          <ProductImage src={storeListing.product.image_url} />
        </div>

        <Stack spacing="xl" px="lg" pt="xl">
          <div>
            {storeListing.product.device_model_name && (
              <Title truncate order={5} weight={600} color="blue_gray.6">
                {storeListing.product.device_model_name}
              </Title>
            )}

            <Title truncate order={3} weight={400} color="blue_gray.9">
              {storeListing.product.name}
            </Title>
          </div>

          <ProductPrice
            storeListing={storeListing}
            currencyCode={currencyCode}
          />

          <ProductActions storeListingStatus={storeListing.status} />

          <ProductDescription product={storeListing.product} />
        </Stack>

        <Stack spacing="xl" px="lg">
          {storeListing.product.product_type ===
          ProductTypeEnum.PlatformLicense ? (
            <ProductModelsAndCommands
              supportedCommands={storeListing.product.supported_commands}
            />
          ) : null}

          <ProductPolicies />
        </Stack>
      </div>
    </ProductDetailsProvider>
  );
}

const useStyles = createStyles((theme) => ({
  container: {},
  imageContainer: {
    position: 'relative',
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },
  storeLink: {
    position: 'absolute',
    zIndex: 1,
    top: theme.spacing.xl,
    left: theme.spacing.xl,
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: theme.fn.rgba(theme.white, 0.7),
    color: theme.colors.blue_gray[9],
    boxShadow: theme.shadows.sm,
  },
}));
