import { find } from 'lodash/fp';
import React, { useMemo } from 'react';

import { useActiveDeviceCommands, useDevice } from '@portals/api/organizations';
import {
  ToggleWidget,
  ToggleWidgetFormType,
  useDebouncedTelemetryValue,
} from '@portals/device-widgets';
import { useSendCommandWithApproval } from '@portals/framework';
import { PremiumCommandLicenseModalProps } from '@portals/framework/modals';
import { useOpenModal } from '@portals/redux';

import { useDeviceCommand } from '../../../../../../../hooks/commands';
import { WidgetProps } from '../device-widgets.types';

export function ToggleWidgetWrapper({
  widget,
  deviceState,
  deviceId,
  isDeviceOffline,
  lastUpdateTimestamp,
}: WidgetProps<ToggleWidgetFormType>) {
  const fields = widget?.config?.fields;

  const sendCommandWithApproval = useSendCommandWithApproval();
  const activeCommands = useActiveDeviceCommands(deviceId);

  const {
    name,
    telemetry,
    color,
    icon_name,
    command_name,
    command_param_key,
    telemetry_as_title,
    title_telemetry,
  } = fields ?? {};

  const telemetryValue = deviceState?.[telemetry];
  const title = telemetry_as_title
    ? deviceState?.[title_telemetry] || 'N/A'
    : name;

  const command = useDeviceCommand(deviceId, command_name);
  const activeCommand = find({ name: command_name }, activeCommands.data);
  const sentCommandValue =
    activeCommand && activeCommand.params?.[command_param_key];

  const debouncedTelemetryValue = useDebouncedTelemetryValue(
    telemetryValue,
    sentCommandValue
  );

  const openModal = useOpenModal();
  const device = useDevice(deviceId);

  const hasCommandLicense = useMemo(() => {
    return (device?.data?.supported_commands || []).find(
      (currentCommand) => command?.id === currentCommand.id
    )?.has_license;
  }, [command, device]);

  const onToggle = async () => {
    if (command?.premium && !hasCommandLicense) {
      openModal<PremiumCommandLicenseModalProps['data']>(
        'PremiumCommandLicenseModal',
        { deviceId, commandId: command?.id, commandName: command?.name }
      );

      return;
    }

    if (command?.custom_fields?.length) {
      openModal('RunCommand', {
        deviceType: device.data?.partner?.type_id,
        command,
        deviceIds: [deviceId],
        prepopulatedParams: {
          [command_param_key]: !telemetryValue,
        },
      });

      return;
    }

    try {
      await sendCommandWithApproval({
        deviceIds: [deviceId],
        command,
        extra_params: {
          [command_param_key]: !telemetryValue,
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <ToggleWidget
      title={title}
      color={color}
      iconName={icon_name}
      isChecked={debouncedTelemetryValue}
      onToggle={onToggle}
      isLoading={!!activeCommand}
      isDeviceOffline={isDeviceOffline}
      lastUpdateTimestamp={lastUpdateTimestamp}
    />
  );
}
