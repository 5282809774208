import { Box, createStyles, Group } from '@mantine/core';
import { filter, groupBy, includes, keys, map, toLower, xor } from 'lodash/fp';
import React, { useMemo, useState } from 'react';

import { RouteModal } from '@portals/framework/route-modals';

import { CATEGORIES, VENDORS } from './connect.constants';
import {
  VendorCategoryEnum,
  VendorCategoryType,
  VendorsGroupType,
} from './connect.types';
import { ConnectSidebar } from './ConnectSidebar';
import { VendorsList } from './VendorsList';
import { ReactComponent as ConnectIcon } from '../../../../assets/img/icons/connect.svg';

export function LegacyConnectRouteModal() {
  const { classes } = useStyles();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<VendorCategoryType>(
    CATEGORIES[0]
  );
  const [selectedVendors, setSelectedVendors] = useState<Array<string>>([]);

  const vendorsGroups = useMemo<Array<VendorsGroupType>>(() => {
    const lowerCaseSearchTerm = toLower(searchTerm);

    const filteredVendors = filter(({ name, category }) => {
      const isSearchFiltered =
        !lowerCaseSearchTerm || includes(lowerCaseSearchTerm, toLower(name));
      const isCategoryFiltered =
        !selectedCategory || selectedCategory.id === category;

      return isSearchFiltered && isCategoryFiltered;
    }, VENDORS);

    const groupedVendors = groupBy('category', filteredVendors);

    return map(
      (category: VendorCategoryEnum) => ({
        category,
        vendors: groupedVendors[category],
      }),
      keys(groupedVendors)
    );
  }, [searchTerm, selectedCategory]);

  const toggleSelectedVendor = (vendorId: string) =>
    setSelectedVendors((curr) => xor([vendorId], curr));

  return (
    <RouteModal modalId="connect">
      <Box className={classes.container}>
        <Group className={classes.header}>
          <ConnectIcon />

          <span>Connect +</span>
        </Group>

        <Box className={classes.content}>
          <ConnectSidebar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            categories={CATEGORIES}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />

          <VendorsList
            vendorsGroups={vendorsGroups}
            selectedVendors={selectedVendors}
            toggleSelectedVendor={toggleSelectedVendor}
          />
        </Box>
      </Box>
    </RouteModal>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
  },
  header: {
    padding: theme.spacing.xl,
    fontSize: theme.fontSizes.xl,
    alignItems: 'center',
    borderBottom: `1px solid ${theme.colors.gray[2]}`,

    svg: {
      width: 34,
      height: 34,
    },
  },
  content: {
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '237px 1fr',
    overflow: 'hidden',
  },
}));
