import { PRODUCTS_STATS_BASE_URL, statsQueryKeys } from './stats.constants';
import { ProductsStatsType } from './stats.types';
import { useApiQuery } from '../../hooks';

export function useProductsStats() {
  return useApiQuery<ProductsStatsType>(
    PRODUCTS_STATS_BASE_URL,
    statsQueryKeys.products,
    {
      staleTime: 0,
    }
  );
}
