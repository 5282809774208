import { Box, createStyles, Text } from '@mantine/core';
import React from 'react';

interface LiveAccountAlertProps {
  tenantType: string;
}

export function LiveAccountAlert({ tenantType }: LiveAccountAlertProps) {
  const { classes } = useStyles();

  return (
    <Box className={classes.container}>
      <Text color="white">You are viewing a live {tenantType} account</Text>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    zIndex: 999,
    borderBottomLeftRadius: theme.radius.lg,
    borderBottomRightRadius: theme.radius.lg,
    position: 'fixed',
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: theme.colors.red_accent[2],
    padding: `${theme.spacing.sm} ${theme.spacing.xl}`,
  },
}));
