import {
  Badge,
  Box,
  Button,
  createStyles,
  Group,
  LoadingOverlay,
  Stack,
  Text,
} from '@mantine/core';
import React from 'react';

import {
  OrderType,
  useCancelPayment,
  useUpdateOrderShipmentToDelivered,
} from '@portals/api/organizations';
import { useGenericContext } from '@portals/framework';
import {
  OrganizationOrderFulfillmentStatusBadge,
  OrganizationOrderPaymentStatusBadge,
} from '@portals/framework/route-modals';
import { ReactComponent as CloseCircle } from '@portals/icons/linear/close-circle.svg';
import { ReactComponent as DocumentUpload } from '@portals/icons/linear/document-upload.svg';
import { ReactComponent as Export } from '@portals/icons/linear/export-2.svg';
import { ReactComponent as Lifebuoy } from '@portals/icons/linear/lifebuoy.svg';
import { ReactComponent as MenuBoard } from '@portals/icons/linear/menu-board.svg';
import { ReactComponent as TickCircleIcon } from '@portals/icons/linear/tick-circle.svg';
import { useOpenModal } from '@portals/redux';
import { prettyTimeMoment } from '@portals/utils';

import { useUploadPurchaseOrder } from './order.hooks';

export function OrderHeader() {
  const { classes } = useStyles();
  const order = useGenericContext<OrderType>();
  const cancelPayment = useCancelPayment();
  const uploadPurchaseOrder = useUploadPurchaseOrder();
  const openModal = useOpenModal();
  const updateToDelivered = useUpdateOrderShipmentToDelivered();

  const hasSupportEmail = order.partner.support_contact_email !== null;

  const isInactiveOrder = ['expired', 'archived', 'failed'].includes(
    order.payment_status
  );
  const isMissingPPP =
    !isInactiveOrder && order.fulfillment_status === 'missing_information';
  const isMissingPO =
    !isInactiveOrder && order.payment_status === 'awaiting_po_upload';

  const supportEmailHref = hasSupportEmail
    ? `mailto:${order.partner.support_contact_email}?subject=Inquiry regarding ${order.short_id}`
    : null;

  const onCancelPayment = async () => {
    try {
      await cancelPayment.mutateAsync(order.payment.id);
    } catch (err) {
      console.error(err);
    }
  };

  const onUpdateToDelivered = async () => {
    try {
      await updateToDelivered.mutateAsync(order.id);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box className={classes.wrapper}>
      <LoadingOverlay
        visible={cancelPayment.isLoading || updateToDelivered.isLoading}
      />

      <Box className={classes.container}>
        <Group align="center" position="apart">
          <Stack spacing="xs">
            <Group align="center">
              <span data-testid="order-header-short-id">{order.short_id}</span>

              <OrganizationOrderPaymentStatusBadge order={order} />

              {!isInactiveOrder ? (
                <OrganizationOrderFulfillmentStatusBadge order={order} />
              ) : null}

              {order.tracking_number ? (
                <Badge
                  size="md"
                  color="gray"
                  variant="outline"
                  c="gray.9"
                  fw={300}
                  py="sm"
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    window.open(
                      `https://t.17track.net/en#nums=${order.tracking_number}`,
                      '_blank'
                    )
                  }
                >
                  <Group
                    c="gray.7"
                    spacing="xs"
                    data-testid="tracking-number-badge"
                  >
                    Tracking No: {order.tracking_number}
                    <Export width={12} height={12} />
                  </Group>
                </Badge>
              ) : null}
            </Group>

            <Text size="sm" className={classes.date}>
              Placed on {prettyTimeMoment(order.created_at)}
            </Text>
          </Stack>

          <Group align="center">
            {supportEmailHref ? (
              <Button
                variant="default"
                onClick={() => window.open(supportEmailHref, '_blank')}
                color="blue_gray.8"
                leftIcon={<Lifebuoy width={16} height={16} />}
              >
                Contact Support
              </Button>
            ) : null}

            {isMissingPPP ? (
              <Button
                onClick={() =>
                  openModal('PostPurchaseParametersModal', {
                    orderId: order.id,
                  })
                }
                leftIcon={<MenuBoard width={16} height={16} />}
              >
                Complete Information
              </Button>
            ) : null}

            {isMissingPO ? (
              <>
                <Button
                  onClick={onCancelPayment}
                  leftIcon={<CloseCircle width={16} height={16} />}
                  variant="default"
                >
                  Cancel Order
                </Button>

                <Button
                  onClick={uploadPurchaseOrder}
                  leftIcon={<DocumentUpload width={16} height={16} />}
                >
                  Upload Purchase Order
                </Button>
              </>
            ) : null}

            {!isInactiveOrder && order.fulfillment_status === 'shipped' ? (
              <Button
                leftIcon={<TickCircleIcon width={16} height={16} />}
                onClick={onUpdateToDelivered}
              >
                Mark as Delivered
              </Button>
            ) : null}
          </Group>
        </Group>
      </Box>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: 'grid',
    background: theme.white,
    width: '100%',
    gridTemplateColumns: '1fr',
    justifyItems: 'center',
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },

  container: {
    padding: `${theme.spacing.md} 30px`,
    fontSize: '28px',
    color: theme.colors.blue_gray[8],
    fontWeight: 500,
    maxWidth: 1600,
    width: '100%',
  },

  date: { color: theme.colors.gray[5], fontWeight: 300 },

  badge: {
    color: theme.colors.gray[9],
    fontWeight: 400,
  },

  badgeIndicator: {
    width: 8,
    height: 8,
    borderRadius: '50%',
  },
}));
