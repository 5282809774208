import classnames from 'classnames';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { findIndex, isNil } from 'lodash/fp';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import {
  getStyledStringOrNumberValue,
  getStyledThemeColor,
} from '@portals/utils';

export type ButtonGroupValueType = any;

export type ButtonGroupOptionType = {
  label: string;
  value: ButtonGroupValueType;
  isDisabled?: boolean;
};

export type ButtonGroupProps = {
  options: Array<ButtonGroupOptionType>;
  onChange: (value: ButtonGroupValueType) => any;
  value: ButtonGroupValueType;
  width?: string | number;
  height: string | number;
  className?: string;
  color?: string;
};

const PADDING = 4;

export const ButtonGroup = ({
  options,
  onChange,
  value,
  height,
  className,
  color = 'primary',
}: ButtonGroupProps) => {
  const currentValueIndex = useMemo(
    () => (isNil(value) ? 0 : findIndex({ value: value }, options)),
    [value, options]
  );

  const optionsList = useMemo(
    () =>
      options.map(({ label, value, isDisabled }, index) => (
        <Option
          className={classnames([
            'button-group',
            'button-group-option',
            {
              selected: currentValueIndex === index,
              disabled: isDisabled,
            },
          ])}
          key={`${label}-${index}`}
          $isSelected={currentValueIndex === index}
          onClick={() => !isDisabled && onChange(value)}
        >
          {currentValueIndex === index ? (
            <SelectedValueOverlay
              $color={color}
              transition={{ duration: 0.15 }}
              layoutId="selected-button-group-overlay"
              className="button-group button-group-selected-overlay"
            />
          ) : null}

          <div
            className={classNames('label', {
              selected: currentValueIndex === index,
            })}
          >
            {label}
          </div>
        </Option>
      )),
    [color, currentValueIndex, options, onChange]
  );

  return (
    <Container
      height={height}
      className={classnames(['button-group button-group-container', className])}
    >
      {optionsList}
    </Container>
  );
};

const Container = styled.div<{
  height: string | number;
}>`
  height: ${getStyledStringOrNumberValue('height')};
  position: relative;
  display: flex;
  align-items: center;
  background-color: ${getStyledThemeColor('gray200')};
  border-radius: 6px;
  padding: 5px 0;
`;

const Option = styled.div<{ $isSelected: boolean }>`
  position: relative;
  flex-shrink: 0;
  padding: 0 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  z-index: 1;
  cursor: pointer;

  .label {
    z-index: 1;
    transition: color 0.1s ease-in-out;
    color: ${({ $isSelected, theme }) =>
      $isSelected ? theme.color.white : theme.color.gray600};
  }

  &.disabled {
    cursor: default;
    opacity: 0.5;
  }
`;

const SelectedValueOverlay = styled(motion.div)<{ $color: string }>`
  position: absolute;
  height: 100%;
  width: calc(100% - ${PADDING * 2}px);
  background-color: ${({ $color, theme }) => theme.color[$color]};
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(120, 138, 154, 0.3);
  z-index: -1;
  transition: background-color 0.15s ease-in-out;
`;
