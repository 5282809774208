import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import dayjs from 'dayjs';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import { toastrError } from '@portals/redux/actions/toastr';
import { formatDateTime } from '@portals/utils';

import {
  devicesQueryKeys,
  getDeviceStateHistoryApiUrl,
} from './devices.constants';
import {
  DeviceStateHistory,
  DeviceStateType,
  WidgetDataRangeEnum,
} from './devices.types';
import { useApiQuery } from '../../hooks';
import { QueryOptions, ServerError } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils';

interface UseDeviceHistoryParams {
  deviceId: string;
  widgetDataRange: WidgetDataRangeEnum;
  queryOptions?: UseQueryOptions<DeviceStateHistory[]>;
}

function getDateRange(widgetDataRange: WidgetDataRangeEnum) {
  if (widgetDataRange === WidgetDataRangeEnum.Live) {
    return {
      from: '',
      to: '',
    };
  }

  if (widgetDataRange === WidgetDataRangeEnum.Day) {
    return {
      from: moment().subtract(24, 'hours').toDate().toISOString(),
      to: moment().toDate().toISOString(),
    };
  }

  if (widgetDataRange === WidgetDataRangeEnum.MonthToDate) {
    return {
      from: dayjs().startOf('month').toISOString(),
      to: dayjs().endOf('month').toISOString(),
    };
  }

  return {
    from: moment()
      .subtract(1, widgetDataRange)
      .startOf('day')
      .toDate()
      .toISOString(),
    to: moment().endOf('day').toDate().toISOString(),
  };
}

export function useDeviceStateHistory({
  deviceId,
  widgetDataRange,
  queryOptions = {},
}: UseDeviceHistoryParams) {
  const { url, options } = useRequestOptions({
    url: getDeviceStateHistoryApiUrl(deviceId),
  });

  return useQuery<DeviceStateHistory[]>({
    queryKey: [...devicesQueryKeys.stateHistory(deviceId), widgetDataRange],
    queryFn: () => {
      const dateRange = getDateRange(widgetDataRange);

      return fetchApiRequest(
        `${url}?from=${dateRange.from}&to=${dateRange.to}`,
        options
      );
    },
    meta: {
      method: 'GET',
      baseUrl: getDeviceStateHistoryApiUrl(':id'),
    },
    ...queryOptions,
  });
}

export function useDeviceLastKnownState(
  deviceId: string,
  queryOptions: QueryOptions<DeviceStateType> = {}
) {
  return useApiQuery<DeviceStateType>(
    `${getDeviceStateHistoryApiUrl(deviceId)}/last_known_state`,
    devicesQueryKeys.stateHistory(deviceId),
    queryOptions
  );
}

interface UseDeviceStateHistoryExportToCsvParams {
  telemetriesKeys: string[];
  date: string | undefined;
}

export function useDeviceStateHistoryExportToCsv(deviceId: string) {
  const dispatch = useDispatch();

  const { url, options } = useRequestOptions({
    url: getDeviceStateHistoryApiUrl(deviceId),
    method: 'GET',
  });

  return useMutation<Blob, ServerError, UseDeviceStateHistoryExportToCsvParams>(
    {
      mutationFn: async ({ date, telemetriesKeys }) => {
        const response = await fetch(
          `${url}/export_to_csv?from=${date}&${telemetriesKeys
            .map((key) => `keys[]=${key}`)
            .join('&')}`,
          options
        );
        return await response.blob();
      },
      onSuccess: (blob, { date }) => {
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);

        a.download = `device-${deviceId}-state-history-${formatDateTime(
          date,
          'MM-DD-YYYY'
        )}.csv`;

        a.click();
      },
      onError: ({ error }) => {
        dispatch(toastrError(error));
      },
      meta: {
        mutationName: 'useDeviceStateHistoryExportToCsv',
        baseUrl: `${getDeviceStateHistoryApiUrl(':id')}/export_to_csv`,
        method: 'GET',
      },
    }
  );
}
