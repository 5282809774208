import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';
import {
  SubscriptionInvoiceItemType,
  UsePaginatedTableApiQuery,
} from '@portals/types';

import {
  SUBSCRIPTIONS_API_URL,
  subscriptionsQueryKeys,
} from './subscriptions.constants';
import {
  SubscriptionDetailsType,
  SubscriptionSummaryType,
} from './subscriptions.types';
import { useApiQuery } from '../../hooks';
import { QueryOptions, ServerError } from '../../types';
import {
  fetchApiRequest,
  usePaginatedTableApiQuery,
  useRequestOptions,
} from '../../utils';

export function useSubscriptions(
  tableState: UsePaginatedTableApiQuery<SubscriptionSummaryType>['tableState'],
  columns: UsePaginatedTableApiQuery<SubscriptionSummaryType>['columns'],
  baseUrl = SUBSCRIPTIONS_API_URL
) {
  return usePaginatedTableApiQuery<SubscriptionSummaryType>({
    baseUrl,
    queryKey: [...subscriptionsQueryKeys.base, baseUrl, tableState],
    tableState,
    columns,
    queryOptions: { staleTime: 0 },
  });
}

export function useSubscription(subscriptionId: string) {
  return useApiQuery<SubscriptionDetailsType>(
    `${SUBSCRIPTIONS_API_URL}/${subscriptionId}`,
    subscriptionsQueryKeys.detail(subscriptionId),
    {
      staleTime: 0,
    }
  );
}

export function useSubscriptionInvoiceItems(
  subscriptionId: string,
  queryOptions: QueryOptions<SubscriptionInvoiceItemType[]>
) {
  return useApiQuery<SubscriptionInvoiceItemType[]>(
    `${SUBSCRIPTIONS_API_URL}/${subscriptionId}/invoice_items`,
    subscriptionsQueryKeys.invoiceItems(subscriptionId),
    {
      staleTime: 0,
      ...queryOptions,
    }
  );
}

interface UseCancelSubscriptionParams {
  subscriptionId: string;
  reason: string | undefined;
  freeText: string | undefined;
}

export function useCancelSubscription() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: SUBSCRIPTIONS_API_URL,
    method: 'POST',
  });

  return useMutation<void, ServerError, UseCancelSubscriptionParams>({
    mutationFn: ({ subscriptionId, reason, freeText }) => {
      return fetchApiRequest(`${url}/${subscriptionId}/cancel`, {
        ...options,
        body: JSON.stringify({ reason, comment: freeText }),
      });
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Subscription successfully canceled'));

      queryClient.invalidateQueries(subscriptionsQueryKeys.base);
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useCancelSubscription',
      baseUrl: `${SUBSCRIPTIONS_API_URL}/:id/cancel`,
      method: 'POST',
    },
  });
}

interface UseRenewSubscriptionParams {
  subscriptionId: string;
}

export function useRenewSubscription() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: SUBSCRIPTIONS_API_URL,
    method: 'POST',
  });

  return useMutation<void, ServerError, UseRenewSubscriptionParams>({
    mutationFn: ({ subscriptionId }) => {
      return fetchApiRequest(`${url}/${subscriptionId}/renew`, options);
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Subscription successfully renewed'));

      queryClient.invalidateQueries(subscriptionsQueryKeys.base);
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useRenewSubscription',
      baseUrl: `${SUBSCRIPTIONS_API_URL}/:id/renew`,
      method: 'POST',
    },
  });
}
