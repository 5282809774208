import { Group } from '@mantine/core';
import React from 'react';
import { Navigate, useMatch, useNavigate } from 'react-router-dom';
import { Col, ListGroup, ListGroupItem, Row } from 'reactstrap';

import { ComponentRendererType, TabType } from '@portals/types';

interface SideTabsProps {
  tabs: Array<TabType>;
  urlBase: string;
  titleWrap?: ComponentRendererType<any>;
  disabled?: boolean;
}

export function SideTabs({
  tabs,
  urlBase,
  titleWrap,
  disabled,
}: SideTabsProps) {
  const navigate = useNavigate();
  const match = useMatch(`${urlBase}/:subTabId`);

  const selectedSubTab = tabs.find(({ id }) => id === match?.params?.subTabId);

  if (match === null || !selectedSubTab) {
    const defaultId = tabs[0].id;

    return <Navigate to={`${urlBase}/${defaultId}`} />;
  }

  const onSelectSubTab = (subTabId: string) => {
    if (subTabId === selectedSubTab.id) return;

    navigate(`${urlBase}/${subTabId}`);
  };

  return (
    <Row style={disabled ? { pointerEvents: 'none', opacity: 0.5 } : {}}>
      <Col md="3" className="border-right">
        <ListGroup flush>
          {tabs.map((tab) => (
            <ListGroupItem
              tag="a"
              key={tab.id}
              active={selectedSubTab.id === tab.id}
              action
              className="mr-1"
              onClick={() => onSelectSubTab(tab.id)}
            >
              <Group position="apart" align="center">
                {titleWrap ? React.createElement(titleWrap, tab) : tab.title}

                {tab.badge || null}
              </Group>
            </ListGroupItem>
          ))}
        </ListGroup>
      </Col>
      <Col>
        {tabs.map(({ id, Component, props = {} }) =>
          id === selectedSubTab.id ? (
            <Component key={id} id={id} {...props} />
          ) : null
        )}
      </Col>
    </Row>
  );
}
