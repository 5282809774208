import { createStyles, Stack, Text, UnstyledButton } from '@mantine/core';
import React from 'react';

import { OrganizationStoreListing, PaymentIntervalEnum } from '@portals/types';
import { formatPrice } from '@portals/utils';

import { useProductDetails } from './ProductDetailsProvider';
import { useStoreCurrency } from '../../../../redux/hooks/store';

interface ProductUsageBasedPriceProps {
  storeListing: OrganizationStoreListing;
}

export function ProductUsageBasedPrice({
  storeListing,
}: ProductUsageBasedPriceProps) {
  const { classes } = useStyles();

  const currency = useStoreCurrency();

  const { pricingOptions, selectedPricingOptionType } = useProductDetails();

  if (!selectedPricingOptionType || !pricingOptions) {
    return null;
  }

  const usageBasedPrice = pricingOptions.find(
    (option) => option.type === PaymentIntervalEnum.UsageBased
  )?.amount;

  const basePrice = pricingOptions.find(
    (option) => option.type === PaymentIntervalEnum.Monthly
  )?.amount;

  return (
    <div>
      <UnstyledButton className={classes.priceCard}>
        <Stack>
          <Text size="lg" truncate>
            By {storeListing.product.usage_based_display_name}
          </Text>

          <Stack spacing="xs">
            {basePrice ? (
              <>
                <Text size="xl" weight={700} span>
                  {formatPrice({
                    value: basePrice,
                    currencyCode: currency.selected,
                  })}
                </Text>

                <Text size="xl" color="gray.5" weight={700}>
                  +
                </Text>
              </>
            ) : null}

            <div>
              <Text size="xl" weight={700} span>
                {formatPrice({
                  value: usageBasedPrice,
                  currencyCode: currency.selected,
                })}
              </Text>{' '}
              <Text size="sm" color="gray.5" weight={600} span>
                / {storeListing.product.usage_based_unit_name}
              </Text>
            </div>
          </Stack>

          <Text size="sm" color="gray.7" weight={600}>
            Billed monthly
          </Text>
        </Stack>
      </UnstyledButton>
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  priceCard: {
    minWidth: '50%',
    padding: theme.spacing.xl,
    borderWidth: 1,
    borderStyle: 'solid',
    border: `1px solid ${theme.colors.gray[3]}`,
    borderRadius: theme.radius.md,
    borderColor: theme.fn.primaryColor(),
  },
}));
