import { Box, createStyles } from '@mantine/core';
import React from 'react';

import { DeviceType } from '@portals/api/organizations';
import { ModelType } from '@portals/types';

import { OverviewRow } from './OverviewRow';
import { OverviewWidgets } from './OverviewWidgets';

interface DeviceOverviewProps {
  device: DeviceType;
  model: ModelType;
}

export function LegacyOverview({ device, model }: DeviceOverviewProps) {
  const { classes } = useStyles();

  return (
    <Box className={classes.container}>
      <OverviewRow device={device} />

      <OverviewWidgets modelId={model?.id} deviceId={device?.id} />
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'grid',
    height: '100%',
    gridTemplateRows: 'min-content min-content 1fr',
    padding: `${theme.spacing.xl} ${theme.spacing.md}`,
  },
}));
