import { Stack } from '@mantine/core';
import { find, isEmpty } from 'lodash/fp';
import React from 'react';

import { useStoreListingsAndCheckCartItems } from '../../../hooks/store';
import { useActiveOrderPreview } from '../../../redux/hooks/store';
import { OrderRow } from '../../pages/checkout/steps/information/order/OrderRow';

export function OrderItemsPreview() {
  const orderPreview = useActiveOrderPreview();
  const storeListings = useStoreListingsAndCheckCartItems();

  if (
    !orderPreview.isFetched ||
    !storeListings.isFetched ||
    isEmpty(orderPreview.data?.items)
  )
    return null;

  return (
    <Stack spacing={0}>
      {orderPreview.data?.items.map((orderPreviewItem) => {
        const storeListing = find(
          { id: orderPreviewItem.id },
          storeListings.data
        );

        if (!storeListing) return null;

        return (
          <OrderRow
            key={`${orderPreviewItem.id}-${orderPreviewItem.selected_payment_interval}`}
            orderPreview={orderPreviewItem}
            storeListing={storeListing}
            currency={orderPreview.data?.currency}
          />
        );
      })}
    </Stack>
  );
}
