import {
  Avatar,
  Badge,
  Box,
  Button,
  createStyles,
  Group,
  Stack,
  Text,
} from '@mantine/core';
import React from 'react';

import { useAssignLicenseToDevice } from '@portals/api/organizations';
import { ReactComponent as Gallery } from '@portals/icons/bold/gallery.svg';
import { LicenseType } from '@portals/types';

interface LicensesToAssignProps {
  licenses: LicenseType[];
  deviceId: string | undefined;
  closeModal: () => void;
}
export function LicensesToAssign({
  licenses,
  deviceId,
  closeModal,
}: LicensesToAssignProps) {
  const { classes } = useStyles();

  const license = licenses[0];

  const assignLicenseToDevice = useAssignLicenseToDevice();

  const onAssignLicense = (license: LicenseType) => {
    if (!deviceId) return;

    assignLicenseToDevice.mutate(
      { deviceId: deviceId, licenseId: license.id },
      { onSuccess: closeModal }
    );
  };

  return (
    <Group position="apart" className={classes.container} align="start" noWrap>
      <Stack spacing="sm" className={classes.content}>
        <Group spacing="sm">
          <Avatar
            src={license?.product_image}
            radius="md"
            color="blue_gray"
            size={40}
          >
            <Gallery />
          </Avatar>

          <Box>
            <Group spacing="xs">
              <Text color="gray.9" weight={500}>
                {license?.product_name}
              </Text>

              <Badge
                size="lg"
                color="gray.1"
                radius="md"
                variant="filled"
                c="gray.6"
                fw={400}
                fz="xs"
              >
                {licenses.length}x
              </Badge>
            </Group>

            <Text color="gray.5" weight={500} size="xs">
              by {license?.partner_display_name}
            </Text>
          </Box>
        </Group>

        {license?.product_subtitle ? (
          <Text color="gary.7" truncate>
            {license?.product_subtitle}
          </Text>
        ) : null}
      </Stack>

      <Button
        size="xs"
        radius="md"
        onClick={() => onAssignLicense(license)}
        data-testid="assign-button"
        loading={assignLicenseToDevice.isLoading}
      >
        Assign
      </Button>
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    borderRadius: theme.radius.md,
    border: `1px solid ${theme.colors.gray[3]}`,
    padding: theme.spacing.md,
  },

  content: {
    overflow: 'hidden',
  },
}));
