import {
  Box,
  createStyles,
  Group,
  LoadingOverlay,
  Stack,
  Text,
} from '@mantine/core';
import { isEmpty } from 'lodash/fp';
import React from 'react';

import { InsightType } from '@portals/api/organizations';
import { ReactComponent as Danger } from '@portals/icons/linear/danger.svg';
import { ReactComponent as InfoCircle } from '@portals/icons/linear/info-circle.svg';
import { ReactComponent as TickCircle } from '@portals/icons/linear/tick-circle.svg';

import { ReactComponent as EmptyTask } from '../../assets/empty-task.svg';

interface InsightsProps {
  insights: Array<InsightType>;
  isFetched: boolean;
  isError: boolean;
  isLoading: boolean;
}

const INSIGHTS_COLOR = {
  info: 'indigo_accent',
  error: 'red',
  success: 'teal',
};

const INSIGHTS_ICON = {
  info: InfoCircle,
  error: Danger,
  success: TickCircle,
};

export function Insights({
  insights,
  isFetched,
  isError,
  isLoading,
}: InsightsProps) {
  const styles = useInsightsStyles();

  if (isError || (isFetched && isEmpty(insights))) {
    return (
      <Stack
        justify="center"
        align="center"
        p="md"
        sx={{ width: '100%', height: '100%' }}
      >
        {!isFetched || isLoading ? <LoadingOverlay visible /> : null}

        {!isLoading ? <EmptyTask /> : <Box sx={{ height: 149 }} />}

        {!isLoading ? (
          <Text className={styles.classes.insightTitle}>
            No available insights
          </Text>
        ) : null}
      </Stack>
    );
  }

  return (
    <Stack spacing="xl">
      {!isFetched || isLoading ? <LoadingOverlay visible /> : null}

      {(insights || []).map((insight, index) => {
        const Icon = INSIGHTS_ICON[insight.type] || InfoCircle;

        return (
          <Stack
            key={index}
            p="md"
            spacing="lg"
            sx={(theme) => ({
              borderRadius: theme.radius.md,
              background: theme.fn.rgba(
                theme.colors[
                  INSIGHTS_COLOR?.[insight.type] || 'blue_accent'
                ][0],
                0.15
              ),
            })}
          >
            <Group
              align="center"
              spacing="xs"
              className={styles.classes.insightTitle}
            >
              <Icon width={20} height={20} />

              <Text>{insight.title}</Text>
            </Group>

            {(insight.points || []).map((point: string, index: number) => (
              <Stack spacing="sm" key={index}>
                <Box
                  p="md"
                  className={styles.classes.insightContainer}
                  sx={(theme) => ({
                    backgroundColor: theme.fn.rgba(
                      theme.colors[
                        INSIGHTS_COLOR?.[insight.type] || 'blue_accent'
                      ][0],
                      0.35
                    ),
                  })}
                >
                  {point}
                </Box>
              </Stack>
            ))}
          </Stack>
        );
      })}
    </Stack>
  );
}

export const useInsightsStyles = createStyles((theme) => ({
  insightTitle: {
    fontSize: theme.fontSizes.sm,
    color: theme.colors.blue_gray[9],
    fontWeight: 500,
  },
  insightContainer: {
    borderRadius: theme.radius.md,
    fontSize: theme.fontSizes.sm,
    color: theme.colors.blue_gray[8],
    fontWeight: 300,
  },
}));
