import { createStyles, LoadingOverlay, Text } from '@mantine/core';
import { size } from 'lodash/fp';
import React, { useState } from 'react';

import { useSpaceFiles } from '@portals/api/organizations';
import { ModalButton } from '@portals/framework';
import { EntityAccess } from '@portals/framework/EntityAccess';
import { AccessLevelEnum, TableViewModeType } from '@portals/types';

import { SpaceFilesList } from './SpaceFilesList';
import { useCurrentSpace } from '../../../overview.hooks';
import { WidgetCardHeader } from '../common/WidgetCardHeader';
import { WidgetCardWrapper } from '../common/WidgetCardWrapper';

export function FilesWidget() {
  const { classes } = useStyles();
  const space = useCurrentSpace();
  const files = useSpaceFiles(space.id);
  const [viewType, setViewType] = useState<TableViewModeType>('grid');

  return (
    <WidgetCardWrapper
      paperProps={{
        className: classes.container,
        pt: 0,
      }}
      stackProps={{
        spacing: 0,
      }}
      header={
        <WidgetCardHeader
          viewType={viewType}
          setViewType={setViewType}
          actions={
            <EntityAccess
              minLevel={AccessLevelEnum.Edit}
              entity={space}
              id="overview-space-files-btn"
            >
              <ModalButton
                modalName="SpaceFileUploader"
                label="Upload File"
                data={{ space }}
                size="sm"
                data-testid="upload-file-button"
              />
            </EntityAccess>
          }
          title={
            <>
              <Text size="md" color="gray.5">
                {space.name} Files
              </Text>

              <Text size="md" color="gray.5">
                ({size(files.data)})
              </Text>
            </>
          }
        />
      }
    >
      {!files.isFetched ? (
        <LoadingOverlay visible />
      ) : (
        <SpaceFilesList files={files.data} space={space} viewType={viewType} />
      )}
    </WidgetCardWrapper>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    '.table-scroll-container': {
      border: 'none',
      paddingLeft: 0,
      paddingRight: 0,
      width: 'calc(100% + 30px)',
      transform: 'translateX(-15px)',
    },
  },
}));
