import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError } from '@portals/redux/actions/toastr';

import {
  getSpaceApiUrl,
  SPACES_API_URL,
  spacesQueryKeys,
} from './spaces.constants';
import { IncidentRoutingConfigType } from './spaces.types';
import { ServerError } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils';

function getApiUrl(spaceId: number) {
  return `${getSpaceApiUrl(spaceId)}/incident_routing`;
}

interface UseUpdateRoutingConfigParams {
  spaceId: number;
  incidentRoutingConfig: IncidentRoutingConfigType;
}

export function useUpdateRoutingConfig() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { url: baseUrl, options } = useRequestOptions({
    url: '',
    method: 'PUT',
  });

  return useMutation<void, ServerError, UseUpdateRoutingConfigParams>({
    mutationFn: ({ spaceId, incidentRoutingConfig }) =>
      fetchApiRequest(`${baseUrl}/${getApiUrl(spaceId)}`, {
        ...options,
        body: JSON.stringify({
          incident_routing_config: incidentRoutingConfig,
        }),
      }),
    onSuccess: (_, { spaceId }) => {
      queryClient.invalidateQueries(spacesQueryKeys.detail(spaceId));
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useUpdateRoutingConfig',
      baseUrl: `${SPACES_API_URL}/:id/incident_routing`,
      method: 'PUT',
    },
  });
}
