import { useNewDeviceWidgets } from '@portals/api/organizations';

export function useIsLegacyWidgets(modelId: string): {
  isLoading: boolean;
  isLegacy: boolean;
} {
  const deviceWidgets = useNewDeviceWidgets(modelId);

  if (!deviceWidgets.isFetched)
    return {
      isLoading: true,
      isLegacy: false,
    };

  if (deviceWidgets.data === null) {
    return {
      isLoading: false,
      isLegacy: true,
    };
  }

  return {
    isLoading: false,
    isLegacy: false,
  };
}
