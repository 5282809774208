import { CLEAR_ERROR, SET_ERROR } from '../constants';

const initialState = {};

const reducer = (state = initialState, action) => {
  const { name, error } = action.payload || {};

  switch (action.type) {
    case SET_ERROR:
      return { ...state, [name]: error };

    case CLEAR_ERROR:
      const deletedData = Object.assign({}, state);
      delete deletedData[action.payload];
      return deletedData;

    default:
      return state;
  }
};

export default reducer;
