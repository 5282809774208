import { Box, Button, Group, LoadingOverlay, Stack } from '@mantine/core';
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe, StripeError } from '@stripe/stripe-js';
import React, { useCallback, useState } from 'react';
import { useEffectOnce } from 'react-use';

import { useStripeSetupIntent } from '@portals/api/organizations';
import { StripeInfoSetupIntentType } from '@portals/types';

const Stripe = loadStripe(process.env.NX_STRIPE_API_KEY);

interface CardInfoFormProps {
  onError: (error: StripeError) => void;
  onCancel?: () => void;
  returnUrl: string;
}

function CardInfoForm({ onError, onCancel, returnUrl }: CardInfoFormProps) {
  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    onError(null);
    setIsLoading(true);

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: returnUrl,
      },
    });

    setIsLoading(false);

    if (error) {
      onError(error);

      return;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing="lg" sx={{ position: 'relative' }}>
        <LoadingOverlay visible={isLoading} />

        <Box sx={{ minHeight: 285 }}>
          <PaymentElement />
        </Box>

        <Group grow>
          <Button variant="default" onClick={onCancel}>
            Cancel
          </Button>

          <Button
            type="submit"
            disabled={!stripe || !elements}
            data-testid="card-info-form-submit-button"
          >
            Add Card
          </Button>
        </Group>
      </Stack>
    </form>
  );
}

interface CardInfoWrapperProps {
  onError: (error: StripeError) => void;
  onCancel?: () => void;
  returnUrl: string;
}

export function CardInfoWrapper({
  onError,
  onCancel,
  returnUrl,
}: CardInfoWrapperProps) {
  const [setupIntent, setSetupIntent] =
    useState<StripeInfoSetupIntentType | null>(null);
  const stripeSetupIntent = useStripeSetupIntent();

  const fetchStripeSetupIntent = useCallback(async () => {
    if (stripeSetupIntent.isLoading || setupIntent) return;

    const response = await stripeSetupIntent.mutateAsync();

    if (response) {
      setSetupIntent(response);
    }
  }, [setupIntent, stripeSetupIntent]);

  useEffectOnce(() => {
    fetchStripeSetupIntent();
  });

  if (!setupIntent || stripeSetupIntent.isLoading)
    return (
      <Box sx={{ position: 'relative', minHeight: 355, zIndex: 1 }}>
        <LoadingOverlay visible />
      </Box>
    );

  return (
    <Elements
      stripe={Stripe}
      options={{
        clientSecret: setupIntent.client_secret,
      }}
    >
      <CardInfoForm
        onError={onError}
        onCancel={onCancel}
        returnUrl={returnUrl}
      />
    </Elements>
  );
}
