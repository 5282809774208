import { LoadingOverlay } from '@mantine/core';
import { GeoJSON, Point } from 'geojson';
import { capitalize } from 'lodash/fp';
import React, { useMemo } from 'react';

import {
  DeviceType,
  SpaceType,
  useDevicesBySpaceId,
  useSpaces,
} from '@portals/api/organizations';
import {
  DeviceIncidentMarker,
  Map,
  MapListItem,
  ORGANIZATIONS_PRIORITY_COLORS,
} from '@portals/framework';
import {
  RouteModalParams,
  useOpenRouteModal,
} from '@portals/framework/route-modals';
import {
  DeviceStatusType,
  PaginatedFilterTypeEnum,
  PaginatedQueryFilterType,
  TableState,
} from '@portals/types';
import {
  childrenToPoints,
  findSpaceChildren,
  useGetLocation,
} from '@portals/utils';

import { useOverviewContext } from '../../../overview.context';
import { useCurrentSpace } from '../../../overview.hooks';

const DEVICE_STATE_MAPPING = {
  error: 1,
  unavailable: 2,
  offline: 3,
  online: 5,
} as const;

const mapper =
  (
    openRouteModal: ({
      modalId,
      pathParams,
      backgroundPath,
    }: RouteModalParams) => void
  ) =>
  (device: DeviceType) => {
    const severityCategory: DeviceStatusType = device.status || 'offline';
    const priority = DEVICE_STATE_MAPPING[severityCategory];
    const color = ORGANIZATIONS_PRIORITY_COLORS[priority - 1];
    const onClick = () =>
      openRouteModal({
        modalId: 'device',
        pathParams: [device.id],
      });

    return {
      id: device.id,
      priority,
      severityCategory: capitalize(severityCategory),
      renderSinglePoint: () => (
        <DeviceIncidentMarker
          title={device.name}
          description={device.partner.model}
          color={color}
          onClick={onClick}
        />
      ),
      renderClusterItem: () => (
        <MapListItem
          key={device.id}
          title={device.name}
          description={device.partner.model}
          color={color}
          onClick={onClick}
        />
      ),
    };
  };

interface DevicesMapProps {
  filters: TableState<DeviceType>['filters'];
  sortBy: TableState<DeviceType>['sortBy'];
}

const getPaginationRequestParamsFromFilters = (
  filters: DevicesMapProps['filters'] = []
): Array<PaginatedQueryFilterType<DeviceType>> => {
  return filters.map(function (filter) {
    switch (filter.id) {
      case 'status':
        return {
          ...filter,
          type: PaginatedFilterTypeEnum.Select,
        };

      case 'name':
      case 'device_model_name':
      default:
        return {
          ...filter,
          type: PaginatedFilterTypeEnum.Contains,
        };
    }
  }, []);
};

export const getPaginationRequestParamsFromSortBy = (
  sortBy: DevicesMapProps['sortBy']
) => (sortBy[0] ? [{ id: sortBy[0].id, desc: false }] : []);

function DevicesMap({
  filters,
  sortBy,
  space,
}: DevicesMapProps & { space: SpaceType }) {
  const openRouteModal = useOpenRouteModal();
  const overview = useOverviewContext();

  const spaces = useSpaces();
  const devices = useDevicesBySpaceId({
    spaceId: space.id,
    filters: getPaginationRequestParamsFromFilters(filters),
    sorting: getPaginationRequestParamsFromSortBy(sortBy),
    queryOptions: {
      staleTime: 0,
    },
  });

  const baseLocation = useGetLocation(space);

  const points = useMemo(() => {
    if (!spaces.isFetched || !devices.isFetched || !space || !devices.data) {
      return [];
    }

    const includedSpaces = overview.isLocalDataLevel
      ? [space]
      : findSpaceChildren(spaces.data, space);

    return childrenToPoints<DeviceType>(
      devices.data,
      includedSpaces,
      mapper(openRouteModal)
    );
  }, [
    devices.data,
    devices.isFetched,
    overview.isLocalDataLevel,
    space,
    spaces.data,
    spaces.isFetched,
    openRouteModal,
  ]);

  return (
    <>
      <LoadingOverlay
        visible={
          !devices.isFetched ||
          !spaces.isFetched ||
          devices.isLoading ||
          spaces.isLoading
        }
      />

      {devices.isFetched && spaces.isFetched ? (
        <Map
          points={points as Array<GeoJSON.Feature<Point>>}
          base={baseLocation}
          emptyStateText="No devices"
        />
      ) : null}
    </>
  );
}

export function DevicesMapWrapper({ filters, sortBy }: DevicesMapProps) {
  const space = useCurrentSpace();

  if (!space) return null;

  return <DevicesMap filters={filters} sortBy={sortBy} space={space} />;
}
