import { ActionIcon, Box, createStyles, Tooltip } from '@mantine/core';
import { noop } from 'lodash/fp';
import React from 'react';

import { ReactComponent as CloseCircle } from '@portals/icons/bold/close-circle.svg';
import { ReactComponent as Paintbucket } from '@portals/icons/bold/paintbucket.svg';

import { OnAddCustomColorFn, WidgetColorType } from '../../../widgets.types';
import { PopoverColorSelector } from '../../PopoverColorSelector';
import { HANDLE_WIDTH } from '../segmented-range-selector.constants';
import { RangeSegment } from '../segmented-range-selector.types';

interface HandleProps {
  color: RangeSegment['color'];
  isDisabled?: boolean;
  onChangeColor: (color: WidgetColorType) => void;
  onRemove?: () => void;
  position: 'top' | 'bottom';
  isDragging: boolean;
  onAddCustomColor: OnAddCustomColorFn | undefined;
  colors: Array<WidgetColorType> | undefined;
  setIsPerformingAction: (isPerformingAction: boolean) => void;
  isColorPickerOpen: boolean;
  setIsColorPicketOpen: (isOpen: boolean) => void;
}

export function Handle({
  color,
  position,
  isDisabled,
  onRemove,
  onChangeColor,
  isDragging,
  colors,
  onAddCustomColor,
  setIsPerformingAction,
  isColorPickerOpen,
  setIsColorPicketOpen,
}: HandleProps) {
  const { classes, cx, theme } = useHandleStyles();

  const className = cx(classes.container, { active: isColorPickerOpen });

  return (
    <Box pos="relative" className={className}>
      {!isDragging ? (
        <>
          {isDisabled || !onRemove ? null : (
            <Tooltip label="Remove segment">
              <ActionIcon
                pos="absolute"
                top={-10}
                right={-5}
                miw={20}
                mih={20}
                w={20}
                h={20}
                color="blue_gray.8"
                variant="transparent"
                className="handle-action"
                onClick={onRemove}
                onMouseEnter={() => setIsPerformingAction(true)}
                onMouseLeave={() => setIsPerformingAction(false)}
              >
                <CloseCircle width={20} height={20} />
              </ActionIcon>
            </Tooltip>
          )}

          <PopoverColorSelector
            colors={colors}
            onAddCustomColor={onAddCustomColor}
            selectedColor={color}
            onChange={onChangeColor}
            onOpen={() => setIsColorPicketOpen(true)}
            onClose={() => setIsColorPicketOpen(false)}
            tooltipProps={{
              offset: 65,
              withArrow: false,
            }}
          >
            {() => (
              <Tooltip withinPortal label="Change color">
                <ActionIcon
                  pos="absolute"
                  top={-8}
                  left={-5}
                  miw={16}
                  mih={16}
                  w={16}
                  h={16}
                  color="blue_gray.8"
                  variant="filled"
                  className="handle-action"
                  onClick={noop}
                  p={0}
                  sx={{ borderRadius: '50%' }}
                  onMouseEnter={() => setIsPerformingAction(true)}
                  onMouseLeave={() => setIsPerformingAction(false)}
                >
                  <Paintbucket width={10} height={10} />
                </ActionIcon>
              </Tooltip>
            )}
          </PopoverColorSelector>
        </>
      ) : null}

      <svg
        width={HANDLE_WIDTH}
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          pointerEvents: 'none',
          transform: position === 'bottom' ? 'rotate(180deg)' : 'rotate(0deg)',
        }}
      >
        <g filter="url(#filter0_d_202_60696)">
          <mask id="path-1-inside-1_202_60696" fill="white">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19 4C11.268 4 5 10.268 5 18C5 24.2955 9.15534 29.6204 14.8729 31.3818L17.8653 35.5282C18.4241 36.3025 19.5772 36.3025 20.136 35.5282L23.1288 31.3812C28.8455 29.6194 33 24.2949 33 18C33 10.268 26.732 4 19 4Z"
            />
          </mask>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19 4C11.268 4 5 10.268 5 18C5 24.2955 9.15534 29.6204 14.8729 31.3818L17.8653 35.5282C18.4241 36.3025 19.5772 36.3025 20.136 35.5282L23.1288 31.3812C28.8455 29.6194 33 24.2949 33 18C33 10.268 26.732 4 19 4Z"
            fill="white"
          />
          <path
            d="M14.8729 31.3818L15.8189 30.699L15.5901 30.3819L15.2164 30.2668L14.8729 31.3818ZM17.8653 35.5282L18.8113 34.8454V34.8454L17.8653 35.5282ZM20.136 35.5282L19.19 34.8454L20.136 35.5282ZM23.1288 31.3812L22.7852 30.2663L22.4115 30.3815L22.1827 30.6985L23.1288 31.3812ZM6.16667 18C6.16667 10.9123 11.9123 5.16667 19 5.16667V2.83333C10.6237 2.83333 3.83333 9.62368 3.83333 18H6.16667ZM15.2164 30.2668C9.97429 28.652 6.16667 23.7691 6.16667 18H3.83333C3.83333 24.8219 8.33638 30.5889 14.5294 32.4967L15.2164 30.2668ZM18.8113 34.8454L15.8189 30.699L13.9269 32.0645L16.9193 36.2109L18.8113 34.8454ZM19.19 34.8454C19.0968 34.9745 18.9045 34.9745 18.8113 34.8454L16.9193 36.2109C17.9438 37.6305 20.0575 37.6305 21.082 36.2109L19.19 34.8454ZM22.1827 30.6985L19.19 34.8454L21.082 36.2109L24.0748 32.064L22.1827 30.6985ZM31.8333 18C31.8333 23.7685 28.0264 28.651 22.7852 30.2663L23.4724 32.4962C29.6645 30.5878 34.1667 24.8212 34.1667 18H31.8333ZM19 5.16667C26.0877 5.16667 31.8333 10.9123 31.8333 18H34.1667C34.1667 9.62368 27.3763 2.83333 19 2.83333V5.16667Z"
            fill={
              isColorPickerOpen
                ? theme.colors['blue_accent'][3]
                : theme.colors.gray[3]
            }
            mask="url(#path-1-inside-1_202_60696)"
          />
          <rect
            x="9.57227"
            y="8.81055"
            width="18.8564"
            height="18.8564"
            rx="9.42822"
            fill={theme.fn.themeColor(color)}
          />
        </g>
        <defs>
          <filter
            id="filter0_d_202_60696"
            x="0"
            y="0"
            width="40"
            height="40"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="2.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.14902 0 0 0 0 0.196078 0 0 0 0 0.219608 0 0 0 0.13 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_202_60696"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_202_60696"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </Box>
  );
}

const useHandleStyles = createStyles(() => ({
  container: {
    transform: 'translateX(-50%)',

    '&:not(.active)': {
      '.handle-action': {
        opacity: 0,
        transition: 'opacity 150ms ease-in-out',
      },
    },

    '&:hover': {
      '.handle-action': {
        opacity: 1,
      },
    },
  },
}));
