import React, { ReactNode } from 'react';
import { Button } from 'reactstrap';
import styled from 'styled-components';

import errorSrc from './assets/img/icons/404.svg';

type ErrorBoundaryState = {
  error: null | Record<string, any>;
  errorInfo: null | {
    componentStack: string;
  };
};

type ErrorBoundaryProps = {
  children: ReactNode;
};

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
  }

  handleLogout = () => {
    localStorage.setItem('auth', null);
    window.location.assign('/');

    this.setState({ error: null, errorInfo: null });
  };

  render() {
    if (!this.state.errorInfo) {
      return this.props.children;
    }

    return (
      <Container>
        <div>
          <h1 className="mb-0">Oops...</h1>

          <div className="mt-2 mb-5 font-size-lg text-muted">
            We are sorry, but it seems that something went wrong
          </div>

          <Button
            color="dark"
            size="lg"
            className="btn-branding pr-5 pl-5"
            onClick={this.handleLogout}
          >
            Return to main page
          </Button>
        </div>

        <div>
          <img src={errorSrc} alt="404" />
        </div>
      </Container>
    );
  }
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 450px);
  column-gap: 50px;
  justify-content: center;
  padding: 50px;
  background-color: #dee2e6;

  > div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    max-width: 450px;

    h1 {
      font-size: 40px;
    }

    img {
      max-width: 100%;
      max-height: calc(100vh - 300px);
    }

    .btn {
      width: 250px;
    }
  }
`;
