import { Stack, Text, useMantineTheme } from '@mantine/core';
import React from 'react';

import { ReactComponent as Danger } from '@portals/icons/bold/danger.svg';

export function DeviceModelBreakdownEmptyState() {
  const theme = useMantineTheme();

  return (
    <Stack align="center" spacing={4} mt="xl">
      <Danger color={theme.colors.gray[5]} width={32} height={32} />

      <Stack align="center" spacing={0}>
        <Text>No devices</Text>
        <Text size="xs">Claim devices or adjust filters.</Text>
      </Stack>
    </Stack>
  );
}
