import { CLOSE_MODAL, OPEN_MODAL } from '../constants';

export function closeModal(name: string) {
  return {
    type: CLOSE_MODAL,
    payload: name,
  };
}

export function openModal<TModalParams = any>(
  name: string,
  params?: TModalParams
) {
  return {
    type: OPEN_MODAL,
    payload: {
      name,
      params,
    },
  };
}
