import {
  Button,
  CloseButton,
  createStyles,
  Group,
  Stack,
  Text,
  UnstyledButton,
} from '@mantine/core';
import { isNumber } from 'lodash/fp';
import React from 'react';
import { TooltipProps, TooltipRenderProps } from 'react-joyride';

type TourStepTooltipProps = {
  tourName: string;
  totalSize?: number;
  startIndex?: number;
  onSkip?: () => void;
} & Partial<TooltipProps> &
  Partial<TooltipRenderProps>;

export function TourStepTooltip({
  continuous,
  index,
  step,
  backProps,
  primaryProps,
  skipProps,
  size,
  tourName,
  totalSize,
  startIndex,
  onSkip,
}: TourStepTooltipProps) {
  const { classes } = useStyles();

  const showNextButton =
    continuous && isNumber(index) && isNumber(size) && index < size;

  const currentIndex = startIndex ? index + startIndex : index + 1;

  return (
    <Stack className={classes.container}>
      <Group position="apart" className={classes.header}>
        <Text color="gray.3">{tourName}</Text>
        <CloseButton
          onClick={(props) => {
            onSkip?.();
            skipProps?.onClick(props);
          }}
          variant="transparent"
        />
      </Group>

      <Stack spacing="lg" px="xl" pb="xxl">
        <Stack spacing="xs">
          <Text data-testid="tour-step-title" size="md">
            {step?.title}
          </Text>
          <Text color="gray.2">{step?.content}</Text>
        </Stack>

        <Group position="apart">
          <Text color="gray.3" size="xs">
            {currentIndex} of {totalSize ? totalSize : size}
          </Text>

          <Group spacing="xl">
            {currentIndex - 1 > 0 && (
              <UnstyledButton
                onClick={(props) => {
                  backProps?.onClick(props);
                }}
              >
                <Text color="gray.3" data-testid="tour-button-back" size="xs">
                  Back
                </Text>
              </UnstyledButton>
            )}
            {showNextButton && (
              <Button
                data-testid="tour-button-next"
                onClick={(props) => {
                  primaryProps?.onClick(props);
                }}
              >
                Next
              </Button>
            )}
          </Group>
        </Group>
      </Stack>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    backgroundColor: theme.black,
    color: theme.white,
    borderRadius: theme.radius.lg,
    maxWidth: 328,
  },

  header: {
    borderBottom: `1px solid ${theme.colors.gray[8]}`,
    paddingInline: theme.spacing.lg,
    paddingBlock: theme.spacing.md,
  },
}));
