import { Stack } from '@mantine/core';
import { DropzoneProps } from '@mantine/dropzone';
import { filter, forEach, isEmpty, noop } from 'lodash/fp';
import React, { useCallback, useEffect } from 'react';
import { v4 as uuid } from 'uuid';

import { useUploadFileToS3 } from '@portals/api';

import { FileRow } from './FileRow';
import { FileState } from './types';
import { UploadDropzone } from './UploadDropzone';
import { UploadedFileRow } from './UploadedFileRow';

export interface FileUploaderDropzoneProps {
  dropzoneProps: Omit<DropzoneProps, 'children' | 'loading' | 'onDrop'>;
  files: FileState[];
  setFiles: React.Dispatch<React.SetStateAction<FileState[]>>;
  uploadedFileUrls?: string[];
  onDeleteUploadedFile?: (fileToDeleteUrl: string) => void;
  onDeletePendingFile?: (fileToDeleteUrl: string) => void;
}

export function FileUploaderDropzone({
  dropzoneProps,
  files,
  setFiles,
  uploadedFileUrls,
  onDeleteUploadedFile = noop,
  onDeletePendingFile = noop,
}: FileUploaderDropzoneProps) {
  const uploadFileToS3 = useUploadFileToS3();

  const updateFileState = useCallback(
    (fileStateId: string, updatedFileState: Partial<FileState>) =>
      setFiles((curr) =>
        curr.map((fileState) =>
          fileState.id === fileStateId
            ? {
                ...fileState,
                ...updatedFileState,
              }
            : fileState
        )
      ),
    [setFiles]
  );

  const uploadFile = useCallback(
    async (fileStateToUpload: FileState) => {
      updateFileState(fileStateToUpload.id, { state: 'uploading' });

      const { file } = fileStateToUpload;

      try {
        const fileBlob = await fileToBlob(file);
        const fileUrl = await uploadFileToS3.mutateAsync({
          originalFileName: file.name,
          blob: fileBlob,
        });

        updateFileState(fileStateToUpload.id, { state: 'finished', fileUrl });
      } catch (error: any) {
        updateFileState(fileStateToUpload.id, { state: 'error', error });
      }
    },
    [updateFileState, uploadFileToS3]
  );

  useEffect(
    function onFilesUpdated() {
      const filesToUpload = filter({ state: 'pending' }, files);

      forEach(uploadFile, filesToUpload);
    },
    [files, uploadFile]
  );

  const onDropFiles = (files: Array<File> = []) => {
    const filesToUpload: Array<FileState> = files.map((file) => ({
      file,
      id: uuid(),
      state: 'pending',
    }));

    setFiles((curr) => [...curr, ...filesToUpload]);
  };

  /*
   * Used for deleting files which wasn't uploaded yet.
   * For create mode
   */
  const onDeletePendingFileWrapper = (fileToDeleteId: string) => {
    const withoutDeletedFile = files.filter(
      (file) => file.id !== fileToDeleteId
    );

    setFiles(withoutDeletedFile);
    onDeletePendingFile(fileToDeleteId);
  };

  const onTryAgain = (fileId: string) => {
    updateFileState(fileId, { state: 'pending', error: undefined });
  };

  if (!isEmpty(uploadedFileUrls)) {
    return (
      <Stack>
        {uploadedFileUrls?.map((fileUrl) => (
          <UploadedFileRow
            key={fileUrl}
            fileUrl={fileUrl}
            onDeleteFile={onDeleteUploadedFile}
          />
        ))}
      </Stack>
    );
  }

  if (isEmpty(files)) {
    return (
      <UploadDropzone dropzoneProps={dropzoneProps} onDrop={onDropFiles} />
    );
  }

  return (
    <Stack>
      {files.map((fileState) => (
        <FileRow
          key={fileState.id}
          {...fileState}
          onDeleteFile={onDeletePendingFileWrapper}
          onTryAgain={() => onTryAgain(fileState.id)}
        />
      ))}
    </Stack>
  );
}

async function fileToBlob(file: File) {
  return new Blob([new Uint8Array(await file.arrayBuffer())], {
    type: file.type,
  });
}
