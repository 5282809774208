import React, { createContext, ReactNode, useContext } from 'react';

const EntityAccessContext = createContext({ isDisabled: false });

export const useEntityAccessContext = () => {
  const accessContext = useContext(EntityAccessContext);

  return accessContext || { isDisabled: false };
};

interface EntityAccessProviderProps {
  isDisabled: boolean;
  children: ReactNode;
}

export function EntityAccessProvider({
  isDisabled = false,
  children,
}: EntityAccessProviderProps) {
  return (
    <EntityAccessContext.Provider value={{ isDisabled }}>
      {children}
    </EntityAccessContext.Provider>
  );
}
