import { find } from 'lodash/fp';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { RoutePanelParams } from './route-panels.types';
import { useAppConfig } from '../context';
import {
  getBackgroundPath,
  RouteOverlayLocationState,
  useRouteOverlays,
} from '../route-overlays';

export function useRoutePanels() {
  return useRouteOverlays();
}

export function useRoutePanelConfig(routePanelId: string) {
  const { routes } = useAppConfig();

  const routePanelConfig = find({ id: routePanelId }, routes?.routePanels);

  if (!routePanelConfig) {
    throw new Error(`Panel with ID ${routePanelId} not found`);
  }

  return routePanelConfig;
}

export function useOpenRoutePanel() {
  const location = useLocation();
  const navigate = useNavigate();
  const { routes } = useAppConfig();

  return useCallback(
    ({ panelId, pathParams = [], backgroundPath }: RoutePanelParams) => {
      const route = find((route) => route.id === panelId, routes?.routePanels);

      if (!route) {
        console.error(`Panel with ID ${panelId} not found`);

        return;
      }

      const calculatedBackgroundPath =
        backgroundPath || getBackgroundPath(location, route.backgroundPath);

      return navigate(route.generatePath(pathParams), {
        state: {
          routeOverlay: {
            entityId: panelId,
            backgroundPath: calculatedBackgroundPath,
            path: route.path as string,
          },
        } satisfies RouteOverlayLocationState,
      });
    },
    [location, navigate, routes?.routePanels]
  );
}
