import { createStyles, Group, Text } from '@mantine/core';
import React from 'react';

import { ReactComponent as Danger } from '@portals/icons/bold/danger.svg';

interface OrganizationMarkerProps {
  title: string;
  description: string;
  color: string;
  onClick: () => void;
}

export function DeviceIncidentMarker({
  title,
  description,
  color,
  onClick,
}: OrganizationMarkerProps) {
  const { classes, theme } = useStyles();

  return (
    <Group
      className={classes.container}
      noWrap
      px="xl"
      py="sm"
      spacing="xs"
      onClick={onClick}
    >
      <Danger color={theme.colors[color][4]} />

      <div>
        <Text weight={700} size="sm" color="gray.7" className={classes.label}>
          {title}
        </Text>

        <Text size="xs" color="gray.5" className={classes.label}>
          {description}
        </Text>
      </div>
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    cursor: 'pointer',
    height: 56,
    maxHeight: 56,
    boxShadow: '0px 13px 36px rgba(38, 50, 56, 0.2)',
    backgroundColor: theme.white,
    width: 'fit-content',
    borderRadius: 58,
  },
  label: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: 150,
  },
}));
