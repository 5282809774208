import React from 'react';

import {
  useOrganizationConfig,
  usePortalConfig,
} from '@portals/api/organizations';
import { Meta, SystemIssues, useBrandingConfig } from '@portals/framework';
import { useAuth } from '@portals/redux';
import { TenantType } from '@portals/types';
import { portalFavicon, portalWindowTitle } from '@portals/utils';

export function OrganizationsMeta() {
  const isLoggedIn = Boolean(useAuth());
  const organizationConfig = useOrganizationConfig();
  const portalConfig = usePortalConfig({ enabled: !isLoggedIn });

  const adjustedConfig = useBrandingConfig({
    tenantType: TenantType.Organization,
    isLoggedIn,
    portalConfig: {
      ...((isLoggedIn ? organizationConfig : portalConfig.data) || {}),
      name: organizationConfig?.partner_display_name || portalConfig.data?.name,
    },
  });

  if (!isLoggedIn && !portalConfig.isFetched) return null;
  else if (isLoggedIn && !organizationConfig) return null;

  return (
    <>
      <Meta
        title={portalWindowTitle(adjustedConfig)}
        links={[
          {
            href: portalFavicon(adjustedConfig),
            rel: 'icon',
            sizes: '192x192',
          },
        ]}
      />

      {isLoggedIn && <SystemIssues tenantType={TenantType.Organization} />}
    </>
  );
}
