import {
  SegmentedControlProps,
  SegmentedControlStylesNames,
  SegmentedControlStylesParams,
} from '@mantine/core';

import { ThemeComponentOverrides } from '../common-types';

export const SegmentedControl: ThemeComponentOverrides<
  SegmentedControlProps,
  SegmentedControlStylesNames,
  SegmentedControlStylesParams
> = {
  styles: {
    label: {
      marginBottom: 0,
    },
  },
};
