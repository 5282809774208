import { Text } from '@mantine/core';
import React from 'react';

import DevicesBoxes from '../../../../../components/Dashboard/Widgets/ModernWidgets/Devices/DevicesBoxes';
import { useOverviewContext } from '../../../overview.context';
import { WidgetCardWrapper } from '../common/WidgetCardWrapper';

export function LegacyDevicesBoxes() {
  const overviewContext = useOverviewContext();

  return (
    <WidgetCardWrapper
      paperProps={{
        sx: {
          overflow: 'hidden',
        },
      }}
      header={
        <Text size="md" color="gray.5">
          Devices Overview
        </Text>
      }
    >
      <DevicesBoxes isLocal={overviewContext.isLocalDataLevel} />
    </WidgetCardWrapper>
  );
}
