import { globalQueryKeys } from '../global-query-keys';

export const PARTNERS_INVITATIONS_API_URL = 'ui/partner/invitations';

export const partnersInvitationsQueryKeys = {
  global: [...globalQueryKeys.partnersInvitations],
  all: () => [
    ...globalQueryKeys.partnersInvitations,
    PARTNERS_INVITATIONS_API_URL,
  ],
  available: () => [...partnersInvitationsQueryKeys.all(), 'available'],
  incoming: () => [...partnersInvitationsQueryKeys.all(), 'incoming'],
  invited: () => [...partnersInvitationsQueryKeys.all(), 'invited'],
  canceled: () => [...partnersInvitationsQueryKeys.all(), 'canceled'],
};
