import {
  ActionIcon,
  Box,
  Button,
  createStyles,
  Group,
  Input,
  Stack,
  TextInput,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { motion } from 'framer-motion';
import React from 'react';
import { v4 as uuid } from 'uuid';

import { ReactComponent as CloseX } from '@portals/icons/linear/close-x.svg';

import { SplineChartWidgetFormType } from './spline-chart-form.types';
import { PopoverColorSelector } from '../../common/PopoverColorSelector';
import { OnAddCustomColorFn, WidgetColorType } from '../../widgets.types';

interface StaticMeasurementsProps {
  form: UseFormReturnType<SplineChartWidgetFormType>;
  colors: Array<WidgetColorType> | undefined;
  onAddCustomColor: OnAddCustomColorFn | undefined;
}

export function StaticMeasurements({
  form,
  colors,
  onAddCustomColor,
}: StaticMeasurementsProps) {
  const { classes } = useStyles();

  return (
    <>
      <motion.div layout="position">
        <Stack>
          {form.values.measurements.map((measurement, index) => (
            <Group w="100%" key={measurement.key}>
              <Input.Wrapper pt={4}>
                <Input.Label />

                <PopoverColorSelector
                  colors={colors}
                  onAddCustomColor={onAddCustomColor}
                  selectedColor={form.values.measurements[index].color}
                  onChange={(color) =>
                    form.setFieldValue(`measurements.${index}.color`, color)
                  }
                >
                  {({ color }) => (
                    <Box className={classes.indicator} bg={color} />
                  )}
                </PopoverColorSelector>
              </Input.Wrapper>

              <TextInput
                {...form.getInputProps(`measurements.${index}.label`)}
                data-testid="spline-chart-display_name"
                label="Display Name"
                required
                sx={{ flex: 1 }}
              />

              <TextInput
                {...form.getInputProps(`measurements.${index}.telemetry`)}
                data-testid="spline-chart-telemetry"
                label="Telemetry"
                required
                sx={{ flex: 1 }}
              />

              <TextInput
                {...form.getInputProps(`measurements.${index}.unit`)}
                data-testid="spline-chart-unit"
                label="Unit"
                sx={{ flex: 0.5 }}
              />

              {form.values.measurements.length > 1 ? (
                <Input.Wrapper sx={{ flexGrow: 0 }} pt="xs">
                  <Input.Label></Input.Label>

                  <ActionIcon
                    size="xs"
                    color="gray.4"
                    onClick={() => form.removeListItem('measurements', index)}
                  >
                    <CloseX />
                  </ActionIcon>
                </Input.Wrapper>
              ) : null}
            </Group>
          ))}
        </Stack>
      </motion.div>

      <motion.div layout="position">
        <Group grow>
          <Button
            variant="default"
            onClick={() =>
              form.insertListItem('measurements', {
                color: 'teal_accent.4',
                display_name: '',
                value: '',
                key: uuid(),
              })
            }
          >
            Add Measurement
          </Button>
        </Group>
      </motion.div>
    </>
  );
}

const useStyles = createStyles((theme) => ({
  indicator: {
    width: 19,
    height: 19,
    borderRadius: theme.radius.md,
    cursor: 'pointer',
  },
}));
