import {
  DividerProps,
  DividerStylesNames,
  DividerStylesParams,
} from '@mantine/core';

import { ThemeComponentOverrides } from '../common-types';

export const Divider: ThemeComponentOverrides<
  DividerProps,
  DividerStylesNames,
  DividerStylesParams
> = {
  defaultProps: {
    color: 'gray.3',
  },
};
