import { Divider, Stack, Text } from '@mantine/core';
import React from 'react';

import { TextEditor, TextEditorProps } from '@portals/core';
import { OrganizationStoreListing } from '@portals/types';

interface ProductDescriptionProps {
  product: OrganizationStoreListing['product'];
}

export function ProductDescription({ product }: ProductDescriptionProps) {
  return (
    <Stack>
      <Text size="md" color="gray.9">
        Product Description
      </Text>
      <TextEditor
        data-testid="store-product-description"
        styles={rteStyles}
        editorParams={{ content: product?.description, editable: false }}
      />
      <Divider />
    </Stack>
  );
}

const rteStyles: TextEditorProps['styles'] = (theme) => ({
  root: {
    border: 'none',
    paddingRight: theme.spacing.sm,
  },
  content: {
    color: theme.colors.blue_gray[7],

    '.ProseMirror': {
      padding: 0,
    },
  },
});
