import { useMutation } from '@tanstack/react-query';

import { MethodType } from '@portals/types';

import { licensesQueryKeys } from './licenses.constants';
import { useClearDataCache } from '../../hooks/common';
import { OptionsType } from '../../types/common';
import { fetchApiRequest, useRequestOptions } from '../../utils/common';

export const useLicensesDataMutation = (
  method: MethodType,
  getUrl: (licenceId: string, deviceId?: string) => string
) => {
  const clearDataCache = useClearDataCache();
  const { url: baseUrl, options } = useRequestOptions({
    url: '',
    method,
  });

  return useMutation(
    ({
      deviceId,
      licenseId,
      customOptions,
    }: {
      deviceId?: string;
      licenseId?: string;
      customOptions?: Partial<OptionsType>;
    } = {}) =>
      fetchApiRequest(baseUrl + getUrl(deviceId as string, licenseId), {
        ...options,
        ...(customOptions || {}),
      }),
    {
      onSuccess: () => clearDataCache([licensesQueryKeys.base]),
      meta: {
        mutationName: 'useLicensesDataMutation',
        baseUrl: getUrl(':id', ':id'),
        method,
      },
    }
  );
};
