import { Affix, Button, Group, Notification, Stack, Text } from '@mantine/core';
import { NotificationProps } from '@mantine/notifications';
import React, { useMemo } from 'react';

import { useCurrentUser, useUpdateUser } from '@portals/api/ui';
import { ReactComponent as Shield } from '@portals/icons/linear/shield-tick.svg';
import { useOpenModal } from '@portals/redux';
import { TenantType } from '@portals/types';

import { useAppConfig } from '../context';
import { PersonalSettingsModalProps } from '../modals/PersonalSettingsModal/PersonalSettingsModal';

export function Enable2FANotification() {
  const currentUser = useCurrentUser();
  const updateUser = useUpdateUser();
  const { tenantType } = useAppConfig();

  const displayNotificationForNonPartnerUsers = useMemo(() => {
    if (
      !currentUser.data ||
      (currentUser.data?.partner_id && tenantType === TenantType.Organization)
    ) {
      return false;
    }

    return true;
  }, [currentUser.data, tenantType]);

  const openModal = useOpenModal();

  if (
    currentUser.data?.settings.is_mfa_notification_dismissed ||
    !displayNotificationForNonPartnerUsers
  ) {
    return null;
  }

  const onDismiss = () => {
    if (!currentUser.data) {
      return;
    }

    updateUser.mutate({
      user: {
        ...currentUser.data,
        settings: {
          ...currentUser.data.settings,
          is_mfa_notification_dismissed: true,
        },
      },
    });
  };

  return (
    <Affix position={{ top: 70, right: 20 }}>
      <Notification
        icon={<Shield color="blue" />}
        title="Enhance your account security with 2FA"
        styles={notificationStyles}
        withCloseButton={false}
      >
        <Stack>
          <Text c="gray.7">
            Protect your account with an extra layer of security by setting up
            Two-Factor Authentication
          </Text>

          <Group grow>
            <Button
              variant="default"
              onClick={onDismiss}
              data-testid="dismiss-button"
            >
              I'll do it later
            </Button>

            <Button
              color="gray.9"
              onClick={() => {
                openModal<PersonalSettingsModalProps['data']>(
                  'PersonalSettingsModal',
                  {
                    preSelectedCategory: 'security',
                  }
                );

                onDismiss();
              }}
            >
              Set up now
            </Button>
          </Group>
        </Stack>
      </Notification>
    </Affix>
  );
}

const notificationStyles: NotificationProps['styles'] = (theme) => ({
  root: {
    backdropFilter: 'blur(50px)',
    boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.10)',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
  },

  icon: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },

  body: {
    marginRight: 0,
  },
});
