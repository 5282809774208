import React from 'react';

import {
  SectionHeaderWidget,
  SectionHeaderWidgetFormType,
} from '@portals/device-widgets';

import { WidgetProps } from '../device-widgets.types';

export function SectionHeaderWidgetWrapper({
  widget,
}: WidgetProps<SectionHeaderWidgetFormType>) {
  const fields = widget?.config?.fields;

  const { name, icon_name, display_icon, background_color, text_color } =
    fields ?? {};

  return (
    <SectionHeaderWidget
      title={name}
      iconName={icon_name}
      displayIcon={display_icon}
      backgroundColor={background_color}
      textColor={text_color}
    />
  );
}
