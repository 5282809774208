import { Tabs, TabsProps } from '@mantine/core';
import React from 'react';

import { PurchasedProductType } from '@portals/api/organizations';
import { DetailsPanel } from '@portals/core';

import { FeaturesAndModelsTab } from './tabs/FeaturesAndModelsTab';
import { OverviewTab } from './tabs/OverviewTab';

interface PlatformLicenseTabsTabs {
  purchasedProduct: PurchasedProductType;
}

export function PlatformLicenseTabs({
  purchasedProduct,
}: PlatformLicenseTabsTabs) {
  return (
    <Tabs defaultValue="overview" styles={tabsStyles} keepMounted={false}>
      <DetailsPanel.Header px={0}>
        <Tabs.List grow>
          <Tabs.Tab value="overview">Overview</Tabs.Tab>
          <Tabs.Tab value="featuresAndModels">Models & Commands</Tabs.Tab>
        </Tabs.List>
      </DetailsPanel.Header>

      <Tabs.Panel value="overview">
        <DetailsPanel.Body>
          <OverviewTab purchasedProduct={purchasedProduct} />
        </DetailsPanel.Body>
      </Tabs.Panel>

      <Tabs.Panel value="featuresAndModels">
        <DetailsPanel.Body>
          <FeaturesAndModelsTab
            supportedCommands={purchasedProduct.product.supported_commands}
          />
        </DetailsPanel.Body>
      </Tabs.Panel>
    </Tabs>
  );
}

const tabsStyles: TabsProps['styles'] = (theme) => ({
  root: {
    height: '100%',
  },
  tabsList: {
    marginInline: theme.spacing.xxl,
  },
  panel: {
    height: '100%',
  },
});
