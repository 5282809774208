import { useMantineTheme } from '@mantine/core';
import seedrandom from 'seedrandom';

export function useSeedRandomColor(seed: string) {
  const theme = useMantineTheme();

  const allColorNames = Object.keys(theme.colors);

  /** colors with all 10 hex values */
  const usableColors = allColorNames.filter((colorName) =>
    theme.colors[colorName].every((hex) => hex !== '')
  );

  const rng = seedrandom(seed);
  const randomPositiveNumber = Math.abs(rng.int32());

  return usableColors[randomPositiveNumber % usableColors.length];
}
