import { LoadingOverlay } from '@mantine/core';
import React from 'react';
import { useParams } from 'react-router-dom';

import { useShopStoreListing } from '@portals/api/organizations';

import { useShopCurrency } from '../../../redux/hooks/store';
import { ProductDetails } from '../../components/store/ProductDetails';

export function ExternalStoreListingPage() {
  const { storeListingSlug } = useParams<{
    storeListingSlug: string;
  }>();

  const storeListing = useShopStoreListing(storeListingSlug);
  const shopCurrency = useShopCurrency();

  if (storeListing.isLoading) {
    return <LoadingOverlay visible />;
  }

  return (
    <ProductDetails
      currencyCode={shopCurrency.selected}
      storeListing={storeListing.data}
    />
  );
}
