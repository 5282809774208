import { LoadingOverlay } from '@mantine/core';
import { isFunction } from 'lodash/fp';
import React, { ReactNode, Suspense } from 'react';
import { Route } from 'react-router-dom';

import { CommonConfigurationType } from '@portals/types';

import { ChildRoutesParams, GetRouteComponentParams } from './routes.types';

export function getRouteComponent<
  TCommonConfigurationType extends CommonConfigurationType
>({
  route,
  configuration,
  canEdit,
  canView,
  isAdmin,
  hasSupportSeat,
}: GetRouteComponentParams<TCommonConfigurationType>) {
  const { canAccessRoute, path, component: Component } = route;

  if (
    canAccessRoute &&
    !canAccessRoute({
      configuration,
      canView,
      canEdit,
      isAdmin,
      hasSupportSeat,
    })
  ) {
    return;
  }

  const adjustedPath = isFunction(path)
    ? path({
        configuration,
        isAdmin,
      })
    : path;

  if (!Component) return null;

  if (Array.isArray(adjustedPath)) {
    return (
      <>
        {adjustedPath.map((path) => {
          return (
            <Route
              key={path}
              path={path}
              element={
                <Suspense fallback={<LoadingOverlay visible />}>
                  <Component />
                </Suspense>
              }
            />
          );
        })}
      </>
    );
  }

  return (
    <Route
      key={adjustedPath}
      path={adjustedPath}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          <Component />
        </Suspense>
      }
    />
  );
}

export function getChildRoutes<
  TCommonConfigurationType extends CommonConfigurationType
>({
  routes,
  configuration,
  canEdit,
  canView,
  isAdmin,
  hasSupportSeat,
}: ChildRoutesParams<TCommonConfigurationType>) {
  const result: ReactNode[] = [];

  routes.forEach((route) => {
    const { canAccessRoute, children, path } = route;

    if (
      canAccessRoute &&
      !canAccessRoute({
        configuration,
        canView,
        canEdit,
        isAdmin,
        hasSupportSeat,
      })
    ) {
      return;
    }

    // Route item with children
    if (children) {
      children.forEach((childRoute) => {
        result.push(
          getRouteComponent<TCommonConfigurationType>({
            route: childRoute,
            configuration,
            canView,
            canEdit,
            isAdmin,
            hasSupportSeat,
          })
        );
      });
    }

    if (path) {
      result.push(
        getRouteComponent({
          route,
          configuration,
          canView,
          canEdit,
          isAdmin,
          hasSupportSeat,
        })
      );
    }
  });

  return result.filter(Boolean);
}
