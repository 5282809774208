import { isUndefined } from 'lodash/fp';
import { useEffect, useState } from 'react';

export function useDebouncedTelemetryValue(
  currentTelemetryValue: any,
  activeCommandValue: any
) {
  const [localTelemetryValue, setLocalTelemetryValue] = useState(
    currentTelemetryValue
  );

  useEffect(
    function updateWithCommandValue() {
      if (isUndefined(activeCommandValue)) return;

      setLocalTelemetryValue(activeCommandValue);
    },
    [activeCommandValue]
  );

  useEffect(() => {
    setLocalTelemetryValue(currentTelemetryValue);
  }, [currentTelemetryValue]);

  return localTelemetryValue;
}
