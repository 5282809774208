import { compact, find, forEach, keys, map, reduce } from 'lodash/fp';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  useAvailableStores,
  useIsCecEnabled,
  useOrderPreview,
  UseOrderPreviewParams,
  useOrderPriceQuotePreview,
} from '@portals/api/organizations';
import {
  OrganizationAddressType,
  PaymentIntervalEnum,
  PaymentMethodEnum,
  PeriodEnum,
  UseCheckout,
} from '@portals/types';

import {
  useShopAndCheckSelectedCurrency,
  useStoreListingsAndCheckCartItems,
  useStoreSettingsAndCheckSelectedCurrency,
} from '../../hooks/store';
import {
  checkSelectedCurrency,
  setBillingAddressId as setBillingAddressIdAction,
  setCreditCardId as setCreditCardIdAction,
  setIsSameAddress as setIsSameAddressAction,
  setPaymentMethod as setPaymentMethodAction,
  setSellerNotes,
  setShippingAddressId as setShippingAddressIdAction,
  setStoreCurrency,
} from '../actions/store';
import {
  getCheckoutNotes,
  getIsSameAddress,
  getNotesSwitchStatus,
  getPaymentMethod,
  getSelectedBillingAddressId,
  getSelectedCreditCardId,
  getSelectedShippingAddressId,
  getSelectedStoreCurrency,
  getStoreCart,
} from '../selectors/store';

type UseCart = Array<{
  id: string;
  period: PaymentIntervalEnum;
  quantity: number;
  price?: number;
}>;

export const useCartProductsList = () => {
  const cart = useSelector(getStoreCart);

  return reduce(
    (acc: UseCart, id: string) => {
      const productsList = [];

      forEach((period: PeriodEnum) => {
        productsList.push({
          id,
          period,
          quantity: cart?.items?.[id]?.[period]?.quantity,
          price: cart?.items?.[id]?.[period]?.price,
        });
      }, keys(cart?.items?.[id]) as PeriodEnum[]);

      return [...acc, ...productsList];
    },
    [],
    keys(cart.items)
  );
};

export const useStoreCurrency = () => {
  const dispatch = useDispatch();
  const isCecEnabled = useIsCecEnabled();
  const storeSettings = useStoreSettingsAndCheckSelectedCurrency();
  const availableStores = useAvailableStores({ isCecEnabled });

  useEffect(() => {
    if (isCecEnabled && availableStores.data?.length > 0) {
      dispatch(
        checkSelectedCurrency(
          availableStores.data[0].currencies,
          availableStores.data[0].default_currency
        )
      );
    }
  }, [availableStores.data, isCecEnabled, dispatch]);

  const selected = useSelector(getSelectedStoreCurrency);

  const onSelect = useCallback(
    (currency: string) => dispatch(setStoreCurrency(currency)),
    [dispatch]
  );

  return {
    currencies: isCecEnabled
      ? availableStores.data?.[0]?.currencies
      : storeSettings.data?.currencies,
    isLoading: isCecEnabled
      ? availableStores.isLoading
      : storeSettings.isLoading,
    selected,
    onSelect,
  };
};

export const useShopCurrency = () => {
  const dispatch = useDispatch();
  const shop = useShopAndCheckSelectedCurrency();
  const selected = useSelector(getSelectedStoreCurrency);

  const onSelect = useCallback(
    (currency: string) => dispatch(setStoreCurrency(currency)),
    [dispatch]
  );

  return {
    currencies: shop.data?.currencies,
    isLoading: shop.isLoading,
    selected,
    onSelect,
  };
};

export function useOrder(
  addresses?: UseActiveOrderPreviewParams
): UseOrderPreviewParams {
  const cartProductsList = useCartProductsList();
  const storeCurrency = useStoreCurrency();
  const storeListings = useStoreListingsAndCheckCartItems();

  const items = compact(
    map(({ id, quantity, period, price }) => {
      const storeListing = find({ id }, storeListings.data);
      const storeListingId = storeListing?.id;

      if (!storeListingId) return null;

      return {
        quantity,
        price,
        store_listing_id: storeListingId,
        selected_payment_interval: period,
        product_type: storeListing.product.product_type,
      };
    }, cartProductsList)
  );

  return {
    shipping_address_id: addresses?.shippingAddressId,
    billing_address_id: addresses?.billingAddressId,
    currency: storeCurrency.selected,
    items,
  };
}

export const useOrderHasShipping = (order: UseOrderPreviewParams) =>
  !!find({ product_type: 'physical' }, order.items);

interface UseActiveOrderPreviewParams {
  shippingAddressId?: OrganizationAddressType['id'] | null;
  billingAddressId?: OrganizationAddressType['id'] | null;
}

export const useActiveOrderPreview = (
  addresses?: UseActiveOrderPreviewParams
) => {
  const order = useOrder(addresses);

  return useOrderPreview(order);
};

export const useActiveOrderForPriceQuote = () => {
  const billingAddressId = useSelector(getSelectedBillingAddressId);
  const shippingAddressId = useSelector(getSelectedShippingAddressId);

  const order = useOrder({
    shippingAddressId,
    billingAddressId,
  });

  return {
    ...order,
    items: map((item) => {
      if (item.selected_payment_interval === PaymentIntervalEnum.OneTime)
        return item;

      return {
        ...item,
        prepaid_interval_units:
          item.selected_payment_interval === PaymentIntervalEnum.Monthly
            ? 12
            : 1,
      };
    }, order.items),
  };
};

export const useActiveOrderPriceQuotePreview = () => {
  const priceQuoteOrderParams = useActiveOrderForPriceQuote();

  return useOrderPriceQuotePreview(priceQuoteOrderParams);
};

export const useCheckout = (): UseCheckout => {
  const dispatch = useDispatch();

  const shippingAddressId = useSelector(getSelectedShippingAddressId);
  const billingAddressId = useSelector(getSelectedBillingAddressId);
  const creditCardId = useSelector(getSelectedCreditCardId);
  const isSameAddress = useSelector(getIsSameAddress);
  const paymentMethod = useSelector(getPaymentMethod);
  const switchStatus = useSelector(getNotesSwitchStatus);
  const checkoutNotes = useSelector(getCheckoutNotes);
  const notes = switchStatus ? checkoutNotes : null;

  const setBillingAddressId = (id: string | null) =>
    dispatch(setBillingAddressIdAction(id));
  const setShippingAddressId = (id: string | null) =>
    dispatch(setShippingAddressIdAction(id));
  const setCreditCardId = (id: string | null) =>
    dispatch(setCreditCardIdAction(id));
  const setIsSameAddress = (isSameAddress: boolean) =>
    dispatch(setIsSameAddressAction(isSameAddress));
  const setPaymentMethod = (paymentMethod: PaymentMethodEnum) =>
    dispatch(setPaymentMethodAction(paymentMethod));

  return {
    note: {
      selected: notes,
      onChange: (notes: string) => dispatch(setSellerNotes(notes)),
    },
    isSameAddress: {
      checked: isSameAddress,
      onChange: setIsSameAddress,
    },
    address: {
      billing: {
        selected: billingAddressId,
        onChange: setBillingAddressId,
      },
      shipping: {
        selected: shippingAddressId,
        onChange: setShippingAddressId,
      },
    },
    creditCard: {
      selected: creditCardId,
      onChange: setCreditCardId,
    },
    paymentMethod: {
      selected: paymentMethod,
      onChange: setPaymentMethod,
    },
  };
};
