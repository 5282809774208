import { useMantineTheme } from '@mantine/core';
import React from 'react';

import { WidgetColorType } from '../../widgets.types';

interface SvgGradientProps {
  color: WidgetColorType;
  index: number;
  id: string;
}

export function SvgGradient({ color, id, index }: SvgGradientProps) {
  const theme = useMantineTheme();

  return (
    <linearGradient
      id={`${id}-${index}-${color}-widget-line-gradient`}
      x1="0%"
      x2="100%"
      y1="0"
      y2="0"
      gradientUnits="userSpaceOnUse"
    >
      <stop
        offset="0%"
        stopColor={theme.fn.themeColor(color)}
        stopOpacity={0.2}
      />
      <stop
        offset="100%"
        stopColor={theme.fn.themeColor(color)}
        stopOpacity={1}
      />
    </linearGradient>
  );
}
