import { Text } from '@mantine/core';
import React from 'react';

import { AuditLogType } from '@portals/types';

interface AttributeUpdatedProps {
  auditLog: AuditLogType;
}

const ATTRIBUTE_MAP = {
  organization_self_managed_warranties_enabled: (status) =>
    `Customer portal features: Warranty management was ${
      status ? 'enabled' : 'disabled'
    }`,
  device_monitoring_enabled: (status) =>
    `Customer portal features: Device management was ${
      status ? 'enabled' : 'disabled'
    }`,
  admin_contact_name: (name) =>
    `Contact details updated: Admin contact name ${
      name ? `was updated to ${name}` : 'was removed'
    } `,
  admin_contact_email: (email) =>
    `Contact details updated: Admin contact email ${
      email ? `was updated to ${email}` : 'was removed'
    } `,
  finance_contact_name: (name) =>
    `Contact details updated: Finance contact name ${
      name ? `was updated to ${name}` : 'was removed'
    } `,
  finance_contact_email: (email) =>
    `Contact details updated: Finance contact email ${
      email ? `was updated to ${email}` : 'was removed'
    } `,
  support_contact_email: (email) =>
    `Contact details updated: Support contact email ${
      email ? `was updated to ${email}` : 'was removed'
    } `,
  certified: (status) =>
    status
      ? 'Partner was certified as CSP'
      : 'Partner is no longer certified as a CSP',
} as const;

export function AttributeUpdated({ auditLog }: AttributeUpdatedProps) {
  const templateCallback = ATTRIBUTE_MAP[auditLog?.params[1]?.value as string];

  if (!templateCallback) {
    return <Text size="sm"> {auditLog.full_message}</Text>;
  }

  return (
    <Text size="sm">
      {ATTRIBUTE_MAP[auditLog?.params[1]?.value as string](
        auditLog?.params[3]?.value
      )}
    </Text>
  );
}
