import { AuditLogType, TableColumn, TableState } from '@portals/types';

import { usePaginatedTableApiQuery } from '../../utils/paginated-table';

export const usePaginatedAuditLogs = (
  tableState: Pick<TableState<AuditLogType>, 'filters'>,
  columns?: Array<TableColumn<AuditLogType>>
) =>
  usePaginatedTableApiQuery<AuditLogType>({
    baseUrl: `ui/audit_logs`,
    tableState,
    columns,
    queryOptions: { cacheTime: 0 },
  });
