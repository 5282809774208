import { get, toNumber } from 'lodash/fp';
import React, { useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';

import {
  useDevice,
  useDeviceStateHistory,
  WidgetDataRangeEnum,
} from '@portals/api/organizations';
import {
  MeasurementType,
  SplineChartWidget,
  SplineChartWidgetFormType,
} from '@portals/device-widgets';

import { SPLINE_CHART_COLORS } from '../device-widgets.constants';
import { WidgetProps } from '../device-widgets.types';

export function SplineChartWidgetWrapper({
  widget,
  deviceId,

  isDeviceOffline,
  lastUpdateTimestamp,
}: WidgetProps<SplineChartWidgetFormType>) {
  const [range, setRange] = useState<WidgetDataRangeEnum>(
    WidgetDataRangeEnum.Day
  );
  const deviceHistory = useDeviceStateHistory({
    deviceId,
    widgetDataRange: range,
    queryOptions: {
      enabled: !!deviceId,
      staleTime: 0,
    },
  });

  const device = useDevice(deviceId);

  const fields = widget?.config?.fields;

  const {
    name,
    measurements,
    scale_type,
    number_format,
    num_of_decimals,
    measurement_type = 'static',
    path,
  } = fields ?? {};

  const dynamicMeasurements = useMemo<Array<MeasurementType>>(() => {
    if (!path || !device.isFetched) return [];

    const measurements: Array<{
      name: string;
      telemetry: string;
      unit?: string;
    }> = get(path, device.data) || [];

    return measurements?.map((measurement, index) => ({
      label: measurement.name,
      telemetry: measurement.telemetry,
      unit: measurement.unit,
      key: uuid(),
      color: SPLINE_CHART_COLORS[index % SPLINE_CHART_COLORS.length],
    }));
  }, [device.data, device.isFetched, path]);

  const measurementsByType =
    measurement_type === 'static' ? measurements : dynamicMeasurements;

  const data = useMemo(() => {
    if (!deviceHistory.isFetched) return [];

    return deviceHistory.data?.map(([state, timestamp]) => ({
      timestamp,
      ...measurementsByType?.reduce((acc, measurement) => {
        acc[measurement.telemetry] = toNumber(state[measurement.telemetry]);

        return acc;
      }, {}),
    }));
  }, [deviceHistory.data, deviceHistory.isFetched, measurementsByType]);

  return (
    <SplineChartWidget
      title={name}
      measurements={measurementsByType}
      data={data}
      isLoading={deviceHistory.isLoading}
      range={range}
      id={widget.id}
      setRange={setRange}
      scaleType={scale_type}
      numberFormat={number_format}
      numOfDecimals={num_of_decimals}
      isDeviceOffline={isDeviceOffline}
      lastUpdateTimestamp={lastUpdateTimestamp}
    />
  );
}
