import { MenuProps } from '@mantine/core';
import {
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { CSSProperties, MutableRefObject, ReactNode } from 'react';
import {
  Cell,
  DefaultSortTypes,
  Filters,
  HeaderGroup,
  HeaderProps,
  IdType,
  Renderer,
  Row,
  SortByFn,
  SortingRule,
  TableInstance,
  TableOptions as RTTableOptions,
  TableState as RTTableState,
  UseExpandedOptions,
  UseExpandedRowProps,
  UseExpandedState,
  UseFiltersColumnOptions,
  UseFiltersColumnProps,
  UseFiltersInstanceProps,
  UseFiltersOptions,
  UseFiltersState,
  UseGlobalFiltersColumnOptions,
  UseGlobalFiltersInstanceProps,
  UseGlobalFiltersOptions,
  UseGlobalFiltersState,
  UsePaginationInstanceProps,
  UsePaginationOptions,
  UsePaginationState,
  UseResizeColumnsColumnOptions,
  UseResizeColumnsColumnProps,
  UseRowSelectInstanceProps,
  UseRowSelectOptions,
  UseRowSelectRowProps,
  UseRowSelectState,
  UseSortByColumnOptions,
  UseSortByColumnProps,
  UseSortByOptions,
  UseSortByState,
  UseTableColumnOptions,
  UseTableColumnProps,
} from 'react-table';
import { CSSObject } from 'styled-components';

import {
  PaginatedFilterTypeEnum as TableFilterTypeEnum,
  PaginationResponse,
} from './pagination';

export { PaginatedFilterTypeEnum as TableFilterTypeEnum } from './pagination';

export type TableState<TData extends object> = RTTableState<TData> &
  UsePaginationState<TData> &
  UseSortByState<TData> &
  UseExpandedState<TData> &
  UseFiltersState<TData> &
  UseGlobalFiltersState<TData> &
  UseRowSelectState<TData> & {
    expanded: UseExpandedState<TData>['expanded'] | Record<string, never>;
  };

export type TableOptions<TData extends object> = RTTableOptions<TData> &
  UsePaginationOptions<TData> &
  UseGlobalFiltersOptions<TData> &
  UseFiltersOptions<TData> &
  UseExpandedOptions<TData> &
  UseRowSelectOptions<TData> &
  UseSortByOptions<TData>;

export type TableInstanceType<TData extends object> = TableInstance<TData> &
  UsePaginationInstanceProps<TData> &
  UseGlobalFiltersColumnOptions<TData> &
  UseGlobalFiltersOptions<TData> &
  UseRowSelectInstanceProps<TData> &
  UseFiltersInstanceProps<TData> &
  UseGlobalFiltersInstanceProps<TData> & {
    state: Partial<TableState<TData>>;
  };

export type ColumnType<TData extends object> = HeaderGroup<TData> &
  UseTableColumnProps<TData> &
  UseSortByColumnProps<TData> &
  UseFiltersColumnProps<TData> &
  UseResizeColumnsColumnProps<TData> &
  UseTableColumnOptions<TData> &
  UseSortByColumnOptions<TData> &
  UseFiltersColumnOptions<TData> &
  UseResizeColumnsColumnOptions<TData> & {
    filterType?: TableFilterTypeEnum;
  } & { isSticky: boolean };

type TableFilterBaseType<TData = TableFilterTypeEnum> = {
  type: TData;
  placeholder?: string;
  hidden?: boolean;
};

type TableFilterText = TableFilterBaseType<TableFilterTypeEnum.Text>;
type TableFilterNumber = TableFilterBaseType<TableFilterTypeEnum.Number>;
type TableDateFilter = TableFilterBaseType<TableFilterTypeEnum.Date>;
type TableBooleanFilter = TableFilterBaseType<TableFilterTypeEnum.Boolean> & {
  options: {
    true: string;
    false: string;
    all: string;
  };
};
type TableSelectFilter = TableFilterBaseType<TableFilterTypeEnum.Select> & {
  options: Record<string, string>;
};
type TableSingleSelectFilter =
  TableFilterBaseType<TableFilterTypeEnum.SingleSelect> & {
    options: Record<string, string>;
  };

export type TableFilterType =
  | TableFilterText
  | TableFilterNumber
  | TableDateFilter
  | TableSelectFilter
  | TableSingleSelectFilter
  | TableBooleanFilter;

export type TableColumnFormatter<TData extends object = any> = (
  cell: Cell<TData>,
  rowData: TData,
  row: Row<TData> & UseExpandedRowProps<TData>
) => ReactNode;

// @todo: loose this `any` when all usages are strictly typed
export type TableColumn<TData extends object = any> = {
  dataField: IdType<TData>;
  text: string;
  sort?: boolean;
  hidden?: boolean;
  filter?: TableFilterType;
  formatter?: TableColumnFormatter<TData>;
  readOnlyFormatter?: TableColumnFormatter<TData>;
  headerFormatter?: (
    column: TableColumn<TData>
  ) => Renderer<HeaderProps<TData>>;
  rowStyle?: CSSObject;
  isAction?: boolean;
  sortValue?: SortByFn<TData> | DefaultSortTypes | string;
  minWidth?: number;
  maxWidth?: number;
  withTooltip?: boolean;
  withGlobalFilter?: boolean;
  isSticky?: boolean;
  isEditable?: boolean;
};

export enum ComparatorEnum {
  EQ = '=',
  NE = '!=',
  GT = '>',
  GE = '>=',
  LT = '<',
  LE = '<=',
}

export interface TableSortType<TData extends object = any> {
  id: IdType<TData>;
  desc?: boolean;
}

export type TableCell<TData extends object> = Cell<TData> & {
  column: { isSticky?: boolean; isEditable?: boolean };
};

export type RowType<TData extends object> = Row<TData> &
  UseExpandedRowProps<TData> &
  UseRowSelectRowProps<TData>;

export interface ExpandRowType<TData extends object> {
  renderer: (rowData: TData, row: RowType<TData>) => JSX.Element;
  showExpandColumn?: boolean;
  expandHeaderColumnRenderer?: (header: any) => JSX.Element;
  expandColumnRenderer?: (params: { expanded: boolean }) => JSX.Element;
  height?: number | ((row: RowType<TData>) => number);
  isEnabled?: (row: RowType<TData>) => boolean;
}

export type NoDataIndicationType = {
  title: ReactNode;
  subtitle?: ReactNode;
  assetSrc?: string;
  actions?: ReactNode;
};

export type TableViewModeType = 'grid' | 'list' | 'map';

export type GetColumnCountFnProps = { tableWidth: number; itemsCount: number };

export interface GridView<TData extends object> {
  getColumnsCount?: (params: GetColumnCountFnProps) => number;
  itemRenderer: (params: { item: RowType<TData> }) => ReactNode;
}

export interface ExportParams {
  isEnabled: boolean;
  remoteUrl?: string;
  fileName?: string;
}

export type PaginatedTableProps<
  TData extends object,
  TKeyField extends keyof TData
> = {
  name: string;
  exportParams?: ExportParams;
  keyField: TKeyField;
  noHeader?: boolean;
  noFilters?: boolean;
  isCompact?: boolean;
  noColumnsSelection?: boolean;
  columns: UsePaginatedTable<TData>['columns'];
  expandRow?: ExpandRowType<TData>;
  noDataIndication?: NoDataIndicationType;
  additionalActions?: () => ReactNode;
  rowMenu?: RowMenuContentRendererType<TData>;
  selectedItemsActions?: () => ReactNode;
  viewType?: TableViewModeType;
  gridView?: GridView<TData>;
  pageSize?: number;
  detailsPanel?: TableDetailsPanelType<TData>;
  readOnly?: boolean;
  onRowClick?: (row: RowType<TData>) => void;
};

export type RowMenuRefType = {
  onClose: () => void;
  onOpen: () => void;
};

export type RowMenuWrapperType = {
  menuRef: RowMenuRefType | undefined;
  isHovered: boolean;
  isMenuOpen: boolean;
  setIsMenuOpen: (isMenuOpen: boolean) => void;
};

export type RowMenuContentRendererParams<TData extends object> = {
  row: RowType<TData>;
  wrapperProps: RowMenuWrapperType;
  menuProps?: Omit<MenuProps, 'children'>;
};

export type RowMenuContentRendererType<TData extends object> = (
  params: RowMenuContentRendererParams<TData>
) => ReactNode;

export interface TableDetailsPanelRendererParams<TData extends object> {
  row: Row<TData>;
  onClose: () => void;
  tableRef: MutableRefObject<HTMLDivElement | null>;
}

export interface TableDetailsPanelType<TData extends object = any> {
  renderer: (params: TableDetailsPanelRendererParams<TData>) => ReactNode;
  type: 'inner' | 'page';
  className?: string;
  width?: CSSProperties['width'];
  withClickOutside?: boolean;
}

export type SmartTableProps<
  TData extends object,
  TKeyField extends keyof TData
> = {
  name: string;
  keyField: TKeyField;
  data: Array<TData>;
  columns: Array<TableColumn<TData>>;
  onSelected?: (rowIds: Array<string>) => void;
  rowStyle?: (params: TData) => CSSProperties;
  noDataIndication?: NoDataIndicationType;
  expandRow?: ExpandRowType<TData>;
  expanded?: TableState<TData>['expanded'];
  exportParams?: ExportParams;
  noHeader?: boolean;
  noSort?: boolean;
  noColumnsSelection?: boolean;
  noFilters?: boolean;
  defaultSorted?: Array<TableSortType<TData>>;
  defaultFilters?: Filters<TData>;
  additionalActions?: () => ReactNode;
  selectedItemsActions?: () => ReactNode;
  rowMenu?: RowMenuContentRendererType<TData>;
  isUrlSyncEnabled?: boolean;
  withGlobalSearch?: boolean;
  isCompact?: boolean;
  menuProps?: Omit<MenuProps, 'children'>;
  pageSize?: number;
  viewType?: TableViewModeType;
  gridView?: GridView<TData>;
  detailsPanel?: TableDetailsPanelType<TData>;
  readOnly?: boolean;
  onRowClick?: (row: RowType<TData>) => void;
  disabledRowClickId?: string | null;
};

export type PaginatedDataHook<TData extends object> = (
  initialSearchParams: Partial<TableState<TData>>,
  columns: Array<TableColumn>,
  url: string | undefined,
  queryKey: Array<string> | undefined
) => UseQueryResult<PaginationResponse<TData>>;

export type UsePaginatedTable<TData extends object> = {
  defaultSortBy?: Array<SortingRule<TData>>;
  defaultFilters?: Filters<TData>;
  columns: Array<TableColumn<TData>>;
  dataHook: PaginatedDataHook<TData>;
  dataHookUrl?: string;
  dataHookQueryKey?: Array<string>;
  mapDataFn?: (row: TData) => TData & Record<string, any>;
  expandRow?: ExpandRowType<TData>;
  rowStyle?: (params: any) => CSSProperties;
  isUrlSyncEnabled?: boolean;
  name: string;
  pageSize?: number;
  onSelected?: (rowIds: Array<string>) => void;
  readOnly?: boolean;
  exportParams?: ExportParams;
};

export type UseSmartTable<TData extends object> = {
  defaultSortBy?: UseSortByState<TData>['sortBy'];
  expanded?: TableState<TData>['expanded'];
  defaultFilters?: Filters<TData>;
  columns: Array<TableColumn<TData>>;
  data: Array<TData>;
  expandRow?: ExpandRowType<TData>;
  rowStyle?: (params: TData) => CSSProperties;
  onSelected?: (rowIds: Array<string>) => void;
  selected?: Array<string>;
  isUrlSyncEnabled?: boolean;
  noColumnsSelection?: boolean;
  pageSize?: number;
  name: string;
  readOnly: boolean;
};

export type TableColumnSortValue<TData extends object> = (
  rowA: Row<TData>,
  rowB: Row<TData>
) => number;

export interface UsePaginatedTableApiQuery<TData extends object> {
  baseUrl: string;
  csvUrl?: string;
  columns?: Array<TableColumn<TData>>;
  queryKey?: QueryKey;
  queryOptions?: Omit<
    UseQueryOptions<
      (...args: any[]) => Promise<PaginationResponse<TData>>,
      { error: string },
      PaginationResponse<TData>
    >,
    'queryKey' | 'queryFn'
  >;
  tableState: Partial<TableState<TData>>;
}

export const RESULTS_PER_PAGE = 20;
