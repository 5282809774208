export enum EntityEnum {
  Licenses = 'licenses',
  Orders = 'orders',
  Experts = 'experts',
  Subscriptions = 'Subscriptions',
}

export const DATA_BASE_URL = {
  [EntityEnum.Licenses]: 'ui/organization/licenses',

  [EntityEnum.Experts]: 'ui/organization/experts',

  [EntityEnum.Subscriptions]: 'ui/organization/subscriptions',
};
