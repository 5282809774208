import {
  Button,
  createStyles,
  Group,
  LoadingOverlay,
  Stack,
} from '@mantine/core';
import { default as Form, IdSchema } from '@rjsf/core';
import metaSchema04 from 'ajv/lib/refs/json-schema-draft-04.json';
import metaSchema06 from 'ajv/lib/refs/json-schema-draft-06.json';
import React, { useCallback, useEffect, useState } from 'react';

import ArrayFieldTemplate from './theme/ArrayFieldTemplate';
import ObjectFieldTemplate from './theme/ObjectFieldTemplate';
import { CheckboxWidget } from './theme/widgets/CheckboxWidget';
import { MessageTypeEnum } from '../types';

const WIDGETS = {
  CheckboxWidget,
};

const UI_SCHEMA = {
  'ui:ObjectFieldTemplate': ObjectFieldTemplate,
  'ui:ArrayFieldTemplate': ArrayFieldTemplate,
};

interface FormConfig {
  schema: IdSchema;
  formData: string;
  isReadOnly: boolean;
}

export function IframeJsonSchema() {
  const [withError, setWithError] = useState(false);
  const { classes } = useStyles({ withError });
  const [formConfig, setFormConfig] = useState<FormConfig>({
    schema: null,
    formData: null,
    isReadOnly: false,
  });

  const onSubmit = useCallback((formData) => {
    window.parent.postMessage(
      {
        type: MessageTypeEnum.SubmitFormData,
        formData,
      },
      '*'
    );
  }, []);

  const onLoad = useCallback(() => {
    window.parent.postMessage(
      {
        type: MessageTypeEnum.OnLoad,
      },
      '*'
    );
  }, []);

  const onMessage = useCallback((e) => {
    const { type } = e.data;

    switch (type) {
      case MessageTypeEnum.InitFormConfig: {
        const { schema, formData, isReadOnly } = e.data;

        setFormConfig({ schema, formData, isReadOnly });
      }
    }
  }, []);

  useEffect(
    function registerMessagesListener() {
      window.addEventListener('message', onMessage);

      return () => {
        window.removeEventListener('message', onMessage);
      };
    },
    [onMessage]
  );

  return (
    <Stack ref={onLoad} className={classes.container} spacing={0}>
      {!formConfig.schema ? (
        <LoadingOverlay visible />
      ) : (
        <Form
          readonly={formConfig.isReadOnly}
          schema={formConfig.schema}
          formData={formConfig.formData}
          widgets={WIDGETS}
          uiSchema={UI_SCHEMA}
          additionalMetaSchemas={[metaSchema06, metaSchema04]}
          ArrayFieldTemplate={ArrayFieldTemplate}
          onSubmit={({ formData }) => {
            setWithError(false);
            onSubmit(formData);
          }}
          onError={() => setWithError(true)}
        >
          {formConfig.isReadOnly ? (
            <div />
          ) : (
            <Group position="right" className="json-schema-form-footer" py="xl">
              <Button type="submit" size="sm">
                Save Changes
              </Button>
            </Group>
          )}
        </Form>
      )}
    </Stack>
  );
}

const useStyles = createStyles(
  (theme, { withError }: { withError: boolean }) => ({
    container: {
      height: '100%',

      form: {
        height: '100%',
        display: 'grid',
        gridTemplateRows: withError
          ? 'min-content 1fr min-content'
          : '1fr min-content',
        overflow: 'hidden',
        padding: '35px 30px 0',

        '.panel-danger': {
          marginBottom: theme.spacing.md,
          borderBottom: `1px solid ${theme.colors.gray[2]}`,
          padding: `0 0 ${theme.spacing.md}`,

          h3: {
            color: theme.colors.gray[8],
            fontWeight: 500,
            fontSize: theme.fontSizes.md,
          },

          '.list-group': {
            maxHeight: 300,
            overflowY: 'auto',
          },
        },

        '>.form-group': {
          overflowY: 'auto',
          overflowX: 'hidden',
          marginBottom: '0px !important',
        },

        '.field-object': {
          '&:not(:last-of-type)': {
            borderBottom: `1px solid ${theme.colors.gray[2]}`,
          },

          legend: {
            fontSize: theme.fontSizes.sm,
            color: theme.colors.gray[8],
            fontWeight: 500,
            marginBottom: 0,

            '&#root__title': {
              fontSize: theme.fontSizes.md,
            },
          },

          '#root__description': {
            fontSize: theme.fontSizes.xs,
            color: theme.colors.gray[6],
          },

          '.form-group': {
            marginTop: theme.spacing.md,
          },

          '.field': {
            marginBottom: theme.spacing.md,

            '.error-detail': {
              paddingTop: theme.spacing.sm,
              paddingLeft: theme.spacing.md,
              color: theme.colors.red_accent[4],
            },

            '&.field-boolean': {
              '>label': {
                display: 'none',
              },
            },

            '.control-label': {
              fontSize: theme.fontSizes.sm,
              color: theme.colors.gray[6],
              marginBottom: 0,
            },

            '.field-description': {
              fontSize: theme.fontSizes.sm,
              color: theme.colors.gray[5],
              marginBottom: 0,
            },

            '.form-control': {
              marginTop: theme.spacing.sm,
              height: 40,
            },
          },
        },

        '.json-schema-form-footer': {
          borderTop: `1px solid ${theme.colors.gray[2]}`,
        },
      },
    },
  })
);

export default IframeJsonSchema;
