import { Button, Modal } from '@mantine/core';
import { any, map } from 'lodash/fp';
import React, { useMemo, useState } from 'react';

import {
  DeviceDetailsType,
  DeviceType,
  useBulkDeleteDevices,
} from '@portals/api/organizations';
import { ModalBody, ModalFooter } from '@portals/core';
import { ModalProps } from '@portals/framework';

import {
  IndependentDevices,
  MultipleParentDevices,
  ParentAndIndependentDevices,
  SingleParentDevice,
} from './delede-device-modal.renderers';
import {
  DELETE_DEVICE_MODAL_SETTINGS,
  DeleteDeviceModalType,
} from './delete-device-modal.constants';

export interface DeleteDeviceModalProps
  extends ModalProps<{
    devices: Array<DeviceType | DeviceDetailsType>;
    onConfirm?: () => void;
    onCancel?: () => void;
    onFinished?: () => void;
    onError?: () => void;
  }> {}

export function DeleteDeviceModal({ data, closeMe }: DeleteDeviceModalProps) {
  const { devices, onConfirm, onCancel, onFinished, onError } = data;

  const deleteDevices = useBulkDeleteDevices();

  const modalType = useMemo(() => {
    const hasParents = any((device) => device.child_devices_count > 0, devices);
    const hasIndependents = any(
      (device) => device.child_devices_count === 0,
      devices
    );

    if (devices.length === 1) {
      if (hasParents) {
        return DeleteDeviceModalType.SingleParentDevice;
      }

      return DeleteDeviceModalType.SingleIndependentDevice;
    } else {
      if (hasParents && hasIndependents) {
        return DeleteDeviceModalType.ParentAndIndependentDevices;
      }

      if (hasParents) {
        return DeleteDeviceModalType.MultipleParentDevices;
      }

      return DeleteDeviceModalType.MultipleIndependentDevices;
    }
  }, [devices]);

  const [isWithChildren, setIsWithChildren] = useState(
    DELETE_DEVICE_MODAL_SETTINGS[modalType].initialIsWithChildren
  );

  const onDeleteDevice = async () => {
    try {
      onConfirm?.();

      await deleteDevices.mutateAsync({
        device_ids: map('id', devices),
        with_children: isWithChildren,
      });

      onFinished?.();
      closeMe();
    } catch (e) {
      onError?.();
      console.error(e);
    }
  };

  const content = useMemo(() => {
    switch (modalType) {
      case DeleteDeviceModalType.SingleIndependentDevice:
      case DeleteDeviceModalType.MultipleIndependentDevices:
        return <IndependentDevices />;
      case DeleteDeviceModalType.SingleParentDevice:
        return (
          <SingleParentDevice
            device={devices[0]}
            isWithChildren={isWithChildren}
            setIsWithChildren={setIsWithChildren}
          />
        );
      case DeleteDeviceModalType.ParentAndIndependentDevices:
        return (
          <ParentAndIndependentDevices
            devices={devices}
            isWithChildren={isWithChildren}
            setIsWithChildren={setIsWithChildren}
          />
        );
      case DeleteDeviceModalType.MultipleParentDevices:
        return (
          <MultipleParentDevices
            devices={devices}
            isWithChildren={isWithChildren}
            setIsWithChildren={setIsWithChildren}
          />
        );

      default:
        return null;
    }
  }, [modalType, devices, isWithChildren, setIsWithChildren]);

  return (
    <Modal
      opened
      onClose={closeMe}
      padding="xxl"
      title={DELETE_DEVICE_MODAL_SETTINGS[modalType].title(devices)}
      withinPortal
    >
      <ModalBody>{content}</ModalBody>

      <ModalFooter position="right">
        <Button
          variant="default"
          onClick={() => {
            closeMe();
            onCancel?.();
          }}
          disabled={deleteDevices.isLoading}
          data-testid="cancel-delete-device-button"
        >
          Cancel
        </Button>

        <Button
          data-testid="delete-device-button"
          onClick={onDeleteDevice}
          color="red.4"
          loading={deleteDevices.isLoading}
        >
          {DELETE_DEVICE_MODAL_SETTINGS[modalType].submitButtonLabel()}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
