import {
  SwitchProps,
  SwitchStylesNames,
  SwitchStylesParams,
} from '@mantine/core';

import { ThemeComponentOverrides } from '../common-types';

export const Switch: ThemeComponentOverrides<
  SwitchProps,
  SwitchStylesNames,
  SwitchStylesParams
> = {
  styles: (theme) => ({
    label: {
      marginBottom: 0,
      color: theme.colors.gray[7],
      fontWeight: 400,
      fontSize: theme.fontSizes.sm,
    },
    input: {
      backgroundColor: theme.colors.blue_gray[1],

      '&:disabled': {
        backgroundColor: theme.colors.blue_gray[1],

        '&:before': {
          backgroundColor: theme.colors.gray[4],
        },
      },

      '&:not(:checked) + .mantine-Switch-track': {
        backgroundColor: theme.colors.gray[4],
      },

      '&:disabled + .mantine-Switch-track': {
        backgroundColor: theme.colors.gray[3],
        '.mantine-Switch-thumb': {
          backgroundColor: theme.colors.gray[4],
        },
      },
    },
    description: {
      fontSize: theme.fontSizes.sm,
    },
    track: {
      border: 'none',
    },
    thumb: {
      border: 'none',
    },
  }),
  sizes: {
    xs: (theme) => ({
      track: {
        width: 25,
        minWidth: 0,
      },
    }),
    sm: (theme) => ({
      track: {
        width: 32,
        minWidth: 0,
      },
    }),
    md: (theme) => ({
      track: {
        width: 39,
        minWidth: 0,
      },
    }),
  },
};
