import {
  Box,
  createStyles,
  Divider,
  Group,
  List,
  ListProps,
  Paper,
  Stack,
  Text,
  ThemeIcon,
  Title,
} from '@mantine/core';
import React, { useMemo } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelProps,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
  Text as RechartsText,
  ResponsiveContainer,
} from 'recharts';

import { SpaceType, useAllIncidents } from '@portals/api/organizations';
import { ReactComponent as Circle } from '@portals/icons/bold/info-circle.svg';

import {
  filterIncidentsByAge,
  formatMedian,
  shouldDisplayRadius,
} from './active-incidents-age.utils';
import {
  BarType,
  DataLevelEnum,
  PRIORITY_NAMES,
  TierType,
} from '../../../overview.types';

interface ActiveIncidentsAgeWidgetProps {
  tiers: TierType[];
  dataLevel: DataLevelEnum | undefined;
  space: SpaceType;
  displayIncidentSeverity: boolean;
  median: number;
}

export function ActiveIncidentsAgeWidget({
  tiers,
  dataLevel,
  space,
  median,
  displayIncidentSeverity,
}: ActiveIncidentsAgeWidgetProps) {
  const { classes, theme } = useStyles();

  const bars: BarType[] = [
    {
      dataKey: PRIORITY_NAMES.planning,
      fill: theme.colors.indigo_accent[0],
      barSize: 20,
    },
    {
      dataKey: PRIORITY_NAMES.low,
      fill: theme.colors.indigo_accent[1],
      barSize: 20,
    },
    {
      dataKey: PRIORITY_NAMES.moderate,
      fill: theme.colors.indigo_accent[2],
      barSize: 20,
    },
    {
      dataKey: PRIORITY_NAMES.high,
      fill: theme.colors.indigo_accent[4],
      barSize: 20,
    },
    {
      dataKey: PRIORITY_NAMES.critical,
      fill: theme.colors.indigo_accent[8],
      barSize: 20,
    },
  ];

  const incidents = useAllIncidents(
    dataLevel === DataLevelEnum.Local
      ? {
          status: 'active',
          spaceId: space?.id,
        }
      : {
          status: 'active',
          spaceTreePathName: space?.tree_path_name,
        }
  );

  const adjustedTiers: TierType[] = useMemo(
    () =>
      filterIncidentsByAge({
        incidents: incidents.data,
        tiers,
      }),
    [incidents.data, tiers]
  );

  const formattedMedian = useMemo(() => formatMedian(median), [median]);

  return (
    <Box className={classes.responsiveContainer}>
      <Stack spacing={2} mt="xs">
        <Title order={2} datat-testid="median-title">
          {formattedMedian}
        </Title>

        <Text color="gray.6">(median)</Text>
      </Stack>

      <ResponsiveContainer width="99%" height="80%">
        <BarChart
          width={500}
          height={300}
          data={adjustedTiers}
          margin={{
            top: 30,
            right: 10,
            bottom: 50,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />

          <XAxis
            stroke={theme.colors.gray[5]}
            dataKey="displayName"
            interval={0}
            tick={(props: CustomAxisTick) => (
              <CustomizedAxisTick
                {...props}
                maxWidth={8}
                tiersLength={adjustedTiers.length}
              />
            )}
          />

          <YAxis stroke={theme.colors.gray[5]} />

          <Tooltip
            cursor={{ fill: theme.colors.gray[1] }}
            content={(props: TooltipProps<number, string>) => (
              <CustomTooltip {...props} />
            )}
          />

          {displayIncidentSeverity ? (
            bars.map((bar) => (
              <Bar
                key={bar.dataKey}
                dataKey={bar.dataKey}
                stackId="a"
                fill={bar.fill}
                barSize={bar.barSize}
                radius={[10, 10, 0, 0]}
              >
                {adjustedTiers.map((tier, index) =>
                  shouldDisplayRadius(bar.dataKey, tier) ? (
                    <Cell key={index} />
                  ) : (
                    <Cell key={index} radius={0} />
                  )
                )}
              </Bar>
            ))
          ) : (
            <Bar
              dataKey="incidentCount"
              stackId="a"
              fill={theme.colors.indigo_accent[2]}
              barSize={20}
              radius={[10, 10, 0, 0]}
            />
          )}
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
}

interface CustomAxisTick extends LabelProps {
  payload: {
    value: string;
  };
  tiersLength: number;
  maxWidth: number;
}

const MAX_TICKS_TO_SHOW_HORIZONTALLY = 4;

function CustomizedAxisTick({
  x,
  y,
  payload,
  tiersLength,
  maxWidth,
}: CustomAxisTick) {
  return (
    <RechartsText
      x={x}
      y={y}
      dy={16}
      textAnchor="middle"
      verticalAnchor="start"
      transform={
        tiersLength > MAX_TICKS_TO_SHOW_HORIZONTALLY
          ? `rotate(-35, ${x}, ${parseInt(y?.toString() ?? '0') + 20})`
          : undefined
      }
    >
      {payload.value.length < maxWidth
        ? payload.value
        : `${payload.value.substring(0, maxWidth)}...`}
    </RechartsText>
  );
}

function CustomTooltip({
  active,
  payload,
  label,
}: TooltipProps<number, string>) {
  if (active && payload && payload.length) {
    return (
      <Paper p="xl" withBorder radius="lg" shadow="xl">
        <Text truncate maw={100}>
          {label}
        </Text>

        <Divider my="xs" />

        <List spacing="xs" size="sm" center styles={listStyles}>
          {payload.reverse().map((item) => (
            <List.Item
              key={item.dataKey}
              icon={
                <ThemeIcon color={item.color} size={11} radius="xl">
                  <Circle color={item.color} />
                </ThemeIcon>
              }
            >
              <Group align="flex-start">
                <Text>
                  {item.dataKey === 'incidentCount'
                    ? 'Incidents Count'
                    : item.dataKey}
                  :
                </Text>

                <Text fw={700}>{item.value}</Text>
              </Group>
            </List.Item>
          ))}
        </List>
      </Paper>
    );
  }

  return null;
}

const listStyles: ListProps['styles'] = (theme) => ({
  item: {
    textTransform: 'capitalize',
  },

  itemIcon: {
    marginTop: theme.spacing.xs,
  },
});

const useStyles = createStyles(() => ({
  responsiveContainer: {
    height: '100%',
  },
}));
