import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError } from '@portals/redux/actions/toastr';
import {
  OrganizationConfigurationType,
  OrganizationFeatureFlagsType,
} from '@portals/types';

import {
  configurationQueryKeys,
  FEATURE_FLAGS_API_URL,
} from './configuration.constants';
import { ServerError } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils';

export const useUpdateFeatureFlags = () => {
  const queryClient = useQueryClient();

  const dispatch = useDispatch();
  const { url, options } = useRequestOptions({
    url: FEATURE_FLAGS_API_URL,
    method: 'POST',
  });

  return useMutation<
    OrganizationConfigurationType,
    ServerError,
    { feature_flags: OrganizationFeatureFlagsType }
  >({
    mutationFn: (featureFlags) =>
      fetchApiRequest(url, {
        ...options,
        body: JSON.stringify(featureFlags),
      }),
    onSuccess: (config) => {
      queryClient.setQueryData(configurationQueryKeys.config, config);
    },
    onError: ({ error }: any) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useUpdateFeatureFlags',
      baseUrl: FEATURE_FLAGS_API_URL,
      method: 'POST',
    },
  });
};
