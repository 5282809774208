import { AccessLevelEnum } from '@portals/types';

import { Entity } from './types';
import { getEntityAccessLevel } from './utils';

export function useHasEntityAccess(
  entity: Entity,
  minAccessLevel: AccessLevelEnum
) {
  const accessLevel = getEntityAccessLevel(entity);

  return accessLevel >= minAccessLevel;
}
