import {
  Box,
  createStyles,
  NumberInput,
  NumberInputProps,
  Popover,
  SegmentedControl,
  SegmentedControlProps,
} from '@mantine/core';
import React, { useMemo, useRef, useState } from 'react';

import { TierType, TimeUnit } from '../../../overview.types';

interface TimeInputProps {
  timeUnit: TimeUnit;
  tier: TierType;
  timeUnitLabel: keyof Pick<
    TierType,
    'firstValueTimeUnit' | 'lastValueTimeUnit'
  >;
  inputProps?: NumberInputProps;
  disabled?: boolean;
  onTimeUnitChange: (params: {
    field: keyof Pick<TierType, 'firstValueTimeUnit' | 'lastValueTimeUnit'>;
    value: TimeUnit;
    changedTier: TierType;
  }) => void;
}

const MIN_VALUE = 0;
const MAX_VALUE = 999;

export function TimeInput({
  timeUnit,
  tier,
  timeUnitLabel,
  disabled,
  inputProps = {},
  onTimeUnitChange,
}: TimeInputProps) {
  const { classes } = useStyles();

  const ref = useRef<HTMLInputElement>(null);

  const [isTimeUnitsMenuOpened, setIsTimeUnitsMenuOpened] = useState(false);

  const onSetFocus = () => {
    ref.current?.focus();
  };

  const onOpenTimeUnitsMenu = () => {
    onSetFocus();

    setIsTimeUnitsMenuOpened(true);
  };

  const onCloseTimeUnitsMenu = () => {
    ref.current?.blur();

    setIsTimeUnitsMenuOpened(false);
  };

  const adjustedTimeUnitsOptions: SegmentedControlProps['data'] =
    useMemo(() => {
      const secondsTimeUnitOption = {
        value: TimeUnit.Seconds,
        label: 'Seconds',
      };
      const minutesTimeUnitOption = {
        value: TimeUnit.Minutes,
        label: 'Minutes',
      };
      const hoursTimeUnitOption = {
        value: TimeUnit.Hours,
        label: 'Hours',
      };

      if (timeUnitLabel === 'lastValueTimeUnit') {
        switch (tier.firstValueTimeUnit) {
          case TimeUnit.Hours:
            return [hoursTimeUnitOption];
          case TimeUnit.Minutes:
            return [minutesTimeUnitOption, hoursTimeUnitOption];
          default:
            return [
              secondsTimeUnitOption,
              minutesTimeUnitOption,
              hoursTimeUnitOption,
            ];
        }
      }

      return [
        secondsTimeUnitOption,
        minutesTimeUnitOption,
        hoursTimeUnitOption,
      ];
    }, [timeUnitLabel, tier.firstValueTimeUnit]);

  return (
    <Popover
      position="top"
      opened={isTimeUnitsMenuOpened}
      onClose={onCloseTimeUnitsMenu}
      radius="md"
      shadow="md"
    >
      <Popover.Target>
        <NumberInput
          data-testid={`time-input-${tier.displayName}`}
          ref={ref}
          type="number"
          styles={inputStyles}
          disabled={disabled}
          w={75}
          min={MIN_VALUE}
          max={MAX_VALUE}
          rightSectionWidth={35}
          rightSection={
            <Box
              className={classes.labelText}
              onClick={() => {
                if (disabled) {
                  return;
                }

                onOpenTimeUnitsMenu();
              }}
            >
              {timeUnit}
            </Box>
          }
          onFocus={onOpenTimeUnitsMenu}
          {...inputProps}
        />
      </Popover.Target>

      <Popover.Dropdown p={0}>
        <SegmentedControl
          data-testid="time-unit"
          value={timeUnit}
          data={adjustedTimeUnitsOptions}
          radius="md"
          onChange={(value: TimeUnit) => {
            onTimeUnitChange({
              field: timeUnitLabel,
              value,
              changedTier: tier,
            });

            onSetFocus();
          }}
        />
      </Popover.Dropdown>
    </Popover>
  );
}

const inputStyles: NumberInputProps['styles'] = (theme) => ({
  wrapper: {
    width: 80,
  },

  rightSection: {
    display: 'flex !important',
    justifyContent: 'flex-start',
  },
});

const useStyles = createStyles((theme) => ({
  labelText: {
    color: theme.colors.gray[5],
    zIndex: 1,
  },
}));
