import React from 'react';
import { Badge } from 'reactstrap';

import { SmartTable } from '@portals/table';
import {
  OrganizationFileResponseType,
  PartnerFileResponseType,
  TableColumn,
  TableFilterTypeEnum,
} from '@portals/types';

const OWNER_OPTIONS = {
  true: 'Manufacturer',
  false: 'Organization',
};

const COLUMNS: Array<TableColumn> = [
  {
    dataField: 'partner',
    text: 'Owner',
    filter: {
      type: TableFilterTypeEnum.Select,
      options: OWNER_OPTIONS,
    },
    formatter: (cell, { partner }) =>
      partner ? (
        <Badge color="secondary">Manufacturer</Badge>
      ) : (
        <Badge color="primary">Organization</Badge>
      ),
  },
  {
    dataField: 'name',
    text: 'Name',
    filter: {
      type: TableFilterTypeEnum.Text,
    },
  },
  {
    dataField: 'desc',
    text: 'Description',
    filter: {
      type: TableFilterTypeEnum.Text,
    },
  },
  {
    dataField: 'type',
    text: 'Type',
    filter: {
      type: TableFilterTypeEnum.Text,
    },
  },
  {
    dataField: 'version',
    text: 'Version',
    filter: {
      type: TableFilterTypeEnum.Text,
    },
  },
  {
    dataField: 'url',
    text: 'Link',
    formatter: (cell, { url }) => (
      <a href={url} target="_blank" rel="noopener noreferrer">
        Open
      </a>
    ),
  },
];

interface FilesViewProps<
  TFileType extends OrganizationFileResponseType | PartnerFileResponseType
> {
  files: Array<TFileType>;
  tableName: string;
}

export function FilesTable<
  TFileType extends OrganizationFileResponseType | PartnerFileResponseType
>({ files, tableName }: FilesViewProps<TFileType>) {
  return (
    <SmartTable<TFileType>
      keyField="id"
      name={tableName}
      data={files}
      columns={COLUMNS}
      noHeader
      noSort
      noFilters
      noDataIndication={{ title: 'No files' }}
      defaultSorted={[{ id: 'name', desc: false }]}
    />
  );
}
