import {
  Badge,
  Box,
  Container,
  createStyles,
  Group,
  Paper,
  Stack,
  Text,
} from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { get, isObject } from 'lodash/fp';
import React, { useState } from 'react';

import { DeviceType } from '@portals/api/organizations';
import { CopyToClipboard } from '@portals/core';
import { getDeviceConfigStatusText } from '@portals/framework/route-modals';
import { DeviceType as CommonDeviceType } from '@portals/types';
import { prettyTime, timeAgo } from '@portals/utils';

interface DeviceInfoProps<TDevice extends DeviceType | CommonDeviceType> {
  device: TDevice;
}

export function DeviceInfo<TDevice extends DeviceType | CommonDeviceType>({
  device,
}: DeviceInfoProps<TDevice>) {
  const { classes } = useStyles();
  const { width } = useViewportSize();
  const [isVisible, setIsVisible] = useState(false);

  if (!width) return null;

  return (
    <Paper p="xl" radius="xl">
      <Stack pb="md" className={classes.rowsContainer}>
        <Text color="gray.9" size="sm">
          Details
        </Text>

        <Stack spacing={0}>
          <Box className={classes.detailsLabel}>Vendor</Box>
          <Box
            className={classes.detailsValue}
            data-testid="device-details-vendor-value"
          >
            {device.partner.vendor}
          </Box>
        </Stack>

        <Stack spacing={0}>
          <Box className={classes.detailsLabel}>Model</Box>
          <Box
            className={classes.detailsValue}
            data-testid="device-details-model-value"
          >
            {device.partner.model}
          </Box>
        </Stack>

        {device.partner.sub_model ? (
          <Stack spacing={0}>
            <Box className={classes.detailsLabel}>Submodel</Box>
            <Box
              className={classes.detailsValue}
              data-testid="device-details-sub-model-value"
            >
              {device.partner.sub_model}
            </Box>
          </Stack>
        ) : null}

        {device.last_seen ? (
          <Stack spacing={0}>
            <Box className={classes.detailsLabel}>Last seen</Box>
            <Box
              className={classes.detailsValue}
              data-testid="device-details-last-seen-value"
            >
              {timeAgo(Date.parse(device.last_seen))}
            </Box>
          </Stack>
        ) : null}

        <Stack spacing={0}>
          <Box className={classes.detailsLabel}>Firmware version</Box>
          <Box
            className={classes.detailsValue}
            data-testid="device-details-firmware-version-value"
          >
            {/* Partner & org devices have different structure  */}
            {isObject(device.firmware)
              ? get(['firmware', 'version'], device)
              : device.firmware}
          </Box>
        </Stack>

        <Stack spacing={0}>
          <Box className={classes.detailsLabel}>Serial number</Box>
          <Box
            className={classes.detailsValue}
            data-testid="device-details-serial-number-value"
          >
            {device.partner.sn}
          </Box>
        </Stack>

        <Stack spacing={0}>
          <Box className={classes.detailsLabel}>Creation date</Box>
          <Box
            className={classes.detailsValue}
            data-testid="device-details-creation-date-value"
          >
            {prettyTime(device.created_at)}
          </Box>
        </Stack>

        {device.claimed_at ? (
          <Stack spacing={0}>
            <Box className={classes.detailsLabel}>Claim date</Box>
            <Box
              className={classes.detailsValue}
              data-testid="device-details-claim-date-value"
            >
              {prettyTime(device.claimed_at)}
            </Box>
          </Stack>
        ) : null}

        {device.partner?.mac ? (
          <Stack spacing={0}>
            <Box className={classes.detailsLabel}>MAC address</Box>
            <Box
              className={classes.detailsValue}
              data-testid="device-details-mac-value"
            >
              <Group spacing="xs">
                {device.partner.mac}

                <CopyToClipboard value={device.partner.mac} />
              </Group>
            </Box>
          </Stack>
        ) : null}

        {device.partner?.cloud_id ? (
          <Stack spacing={0}>
            <Box className={classes.detailsLabel}>Cloud ID</Box>
            <Box
              className={classes.detailsValue}
              data-testid="device-details-cloud-id-value"
            >
              {device.partner.cloud_id}
            </Box>
          </Stack>
        ) : null}

        <Stack spacing={0}>
          <Box className={classes.detailsLabel}>Config</Box>
          <Box
            className={classes.detailsValue}
            data-testid="device-details-config-value"
          >
            {getDeviceConfigStatusText({
              configVersion: device?.config_version,
              appliedConfigVersion: device?.applied_config_version,
            })}
          </Box>
        </Stack>

        <Stack spacing={0}>
          <Box className={classes.detailsLabel}>UUID</Box>
          <Box
            className={classes.detailsValue}
            data-testid="device-details-uuid-value"
          >
            {device.id}
          </Box>
        </Stack>

        <Stack spacing={0}>
          <Box className={classes.detailsLabel}>Communication protocol</Box>
          <Box
            className={classes.detailsValue}
            data-testid="device-details-communication-protocol"
          >
            {device.communication_protocol}
          </Box>
        </Stack>

        {device?.connection_info?.access_key ? (
          <Stack spacing={0}>
            <Box className={classes.detailsLabel}>Access key</Box>
            <Box
              className={classes.detailsValue}
              data-testid="device-details-access-key-value"
            >
              {isVisible ? (
                <Group position="apart" align="center" spacing="xs">
                  <Text>{device.connection_info.access_key}</Text>

                  <Group spacing="xs" align="center" noWrap>
                    <Text
                      inherit
                      onClick={() => setIsVisible(false)}
                      color="blue_accent"
                      sx={{ cursor: 'pointer' }}
                    >
                      Hide
                    </Text>
                  </Group>
                </Group>
              ) : (
                <Group position="apart" align="center" noWrap>
                  <Text
                    inherit
                    onClick={() => setIsVisible(true)}
                    color="blue_accent"
                    sx={{ cursor: 'pointer' }}
                    data-testid="device-details-access-key-value-show"
                  >
                    Show
                  </Text>
                </Group>
              )}
            </Box>
          </Stack>
        ) : null}

        {device?.connection_info?.hub_url ? (
          <Stack spacing={0}>
            <Box className={classes.detailsLabel}>Assigned server</Box>
            <Box
              className={classes.detailsValue}
              data-testid="device-details-assigned-server-value"
            >
              {device.connection_info.hub_url}
            </Box>
          </Stack>
        ) : null}

        {device?.connection_info?.hub_url_static_cert ? (
          <Stack spacing={0}>
            <Box className={classes.detailsLabel}>
              Assigned static cert server
            </Box>
            <Box
              className={classes.detailsValue}
              data-testid="device-details-assigned-cert-value"
            >
              {device.connection_info.hub_url_static_cert}
            </Box>
          </Stack>
        ) : null}

        {device?.connection_info?.mqtt_hub_url ? (
          <Stack spacing={0}>
            <Box className={classes.detailsLabel}>Assigned MQTT server</Box>
            <Box
              className={classes.detailsValue}
              data-testid="device-details-assigned-mqtt-server-value"
            >
              {device.connection_info.mqtt_hub_url}
            </Box>
          </Stack>
        ) : null}

        {device?.test ? (
          <Container className={classes.container}>
            <Badge>Test Device</Badge>
          </Container>
        ) : null}
      </Stack>
    </Paper>
  );
}

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: theme.fontSizes.md,
    color: theme.colors.blue_gray[7],
    fontWeight: 500,
  },
  rowsContainer: {
    gap: 10,
    overflow: 'hidden',
  },
  detailsLabel: {
    color: theme.colors.blue_gray[5],
    fontWeight: 400,
    fontSize: theme.fontSizes.xs,
  },
  detailsValue: {
    color: theme.colors.blue_gray[9],
    fontWeight: 400,
    fontSize: theme.fontSizes.xs,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  container: {
    marginLeft: 'unset',
    paddingLeft: 'unset',
  },
}));
