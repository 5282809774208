import { Badge, BadgeProps, Box, Group, Stack, Text } from '@mantine/core';
import { entries, groupBy } from 'lodash/fp';
import React from 'react';

import { PurchasedProductType } from '@portals/api/organizations';

interface CommandsAndModelsListProps {
  supportedCommands: PurchasedProductType['product']['supported_commands'];
}

export function CommandsAndModelsList({
  supportedCommands,
}: CommandsAndModelsListProps) {
  const groupedByModelSupportedCommands = entries(
    groupBy('device_model_name', supportedCommands)
  );

  return (
    <Stack>
      {groupedByModelSupportedCommands.map(
        ([deviceModeName, commands], index) => (
          <Box key={deviceModeName}>
            <Text fz="xs" color="gray.9" mb="xs">
              {deviceModeName}
            </Text>

            <Group spacing="xs">
              {commands.map((command) => (
                <Badge
                  py="sm"
                  px="xl"
                  styles={badgeStyles}
                  color="gray"
                  key={command.id}
                >
                  {command.friendly_name}
                </Badge>
              ))}
            </Group>
          </Box>
        )
      )}
    </Stack>
  );
}

const badgeStyles: BadgeProps['styles'] = (theme) => ({
  inner: {
    fontWeight: 500,
    fontSize: theme.fontSizes.xs,
    color: theme.colors.gray[6],
  },
});
