import {
  Button,
  Divider,
  Group,
  Image,
  LoadingOverlay,
  Modal,
  Stack,
  Text,
  Textarea,
  TextInput,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import React from 'react';
import { object, string } from 'yup';

import { useSendEmailToServiceProvider } from '@portals/api/organizations';
import { useCurrentUser } from '@portals/api/ui';
import { ModalBody, ModalFooter } from '@portals/core';
import { ModalProps } from '@portals/framework';

export interface ContactServiceProviderModalProps
  extends ModalProps<{
    logo: string;
    name: string;
    id: string;
  }> {}

interface ContactServiceProviderFormValues {
  title: string;
  description: string;
  contactName: string;
  contactEmail: string;
}

const schema = object({
  title: string().required(),
  description: string().required(),
  contactName: string().required(),
  contactEmail: string().email().required(),
});

export function ContactServiceProviderModal({
  data,
  closeMe,
}: ContactServiceProviderModalProps) {
  const { name, logo } = data;

  const sendEmailToServiceProvider = useSendEmailToServiceProvider();

  const currentUser = useCurrentUser();

  const onSubmit = async (values: ContactServiceProviderFormValues) => {
    if (!currentUser.data) return;

    try {
      await sendEmailToServiceProvider.mutateAsync({
        title: values.title,
        description: values.description,
        name: currentUser.data?.name,
        email: currentUser.data?.email,
        id: data.id,
      });

      closeMe();
    } catch (error) {
      console.error(error);
    }
  };

  const form = useForm<ContactServiceProviderFormValues>({
    initialValues: {
      title: `Service Request: ${currentUser.data?.organization_display_name}`,
      description:
        `Dear ${name},\n` +
        'I am interested in your service and would like to discuss the possibility of \n' +
        'engaging your services for our organization. \n' +
        '\n' +
        'Kindly provide me with more information regarding your availability and \n' +
        'pricing structure. Looking forward to discussing this opportunity with you\n' +
        'in more detail.\n' +
        '\n' +
        'Thank you,',
      contactName: currentUser.data?.name,
      contactEmail: currentUser.data?.email,
    },
    validate: yupResolver(schema),
  });

  if (currentUser.isInitialLoading) {
    return <LoadingOverlay visible />;
  }

  return (
    <Modal
      onClose={closeMe}
      opened
      title="Contact service provider"
      padding="xxl"
    >
      <Divider />

      <Stack align="center">
        <Image src={logo} width={96} height={96} withPlaceholder mt="xl" />

        <Text size="xl">{name}</Text>
      </Stack>

      <form onSubmit={form.onSubmit(onSubmit)}>
        <ModalBody>
          <Stack spacing="md" mb={32}>
            <TextInput label="Title" {...form.getInputProps('title')} />

            <Textarea
              label="Description"
              autosize
              {...form.getInputProps('description')}
            />

            <Group position="center" grow>
              <TextInput
                label="Contact name"
                {...form.getInputProps('contactName')}
              />

              <TextInput
                label="Contact email"
                {...form.getInputProps('contactEmail')}
              />
            </Group>
          </Stack>
        </ModalBody>

        <Divider my="md" />

        <ModalFooter grow>
          <Button variant="default" onClick={closeMe}>
            Cancel
          </Button>

          <Button type="submit">Send Request</Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
