import {
  Button,
  createStyles,
  Group,
  LoadingOverlay,
  Modal,
  ModalProps as MantineModalProps,
  Stack,
  Text,
  UnstyledButton,
} from '@mantine/core';
import { filter, groupBy, isEmpty } from 'lodash/fp';
import React, { useEffect, useMemo, useState } from 'react';

import {
  useAssignLicenseToDevice,
  useDeviceModelUnassignedLicenses,
} from '@portals/api/organizations';
import { EmptyState } from '@portals/core';
import { ModalProps } from '@portals/framework';
import { useOpenModal } from '@portals/redux';
import { LicenseType } from '@portals/types';

import { LicenseCategories } from './LicenseCategories';
import { RedeemLicenseCodeModalProps } from './RedeemLicenseCodeModal';
import { UnassignedLicenseCard } from './UnassignedLicenseCard';

export interface UnassignedLicensesModalData {
  deviceModelId: string;
  deviceId: string;
  hasTokenLicenses: boolean;
}

interface UnassignedLicensesProps
  extends ModalProps<UnassignedLicensesModalData> {}

export function UnassignedLicenses({
  closeMe,
  data: { deviceModelId, deviceId, hasTokenLicenses },
}: UnassignedLicensesProps) {
  const { classes } = useStyles();

  const assignLicenseToDevice = useAssignLicenseToDevice();
  const licenses = useDeviceModelUnassignedLicenses(deviceModelId);
  const openModal = useOpenModal();

  const [selectedLicense, setSelectedLicense] = useState<LicenseType>();
  const [selectedCategory, setSelectedCategory] =
    useState<LicenseType['product_category']>(null);

  const filteredLicenses = useMemo(() => {
    if (!licenses.data) return {};
    let filteredProducts: LicenseType[];

    if (selectedCategory) {
      filteredProducts = filter(
        { product_category: selectedCategory },
        licenses.data
      );
    } else {
      filteredProducts = licenses.data;
    }

    return groupBy('product_id', filteredProducts);
  }, [licenses.data, selectedCategory]);

  useEffect(
    function clearSelectedLicenseIfNotInFilteredLicenses() {
      // Check if the current selected license is NOT in the filtered licenses
      if (selectedLicense && !filteredLicenses[selectedLicense.product_id]) {
        // if it's not, clear the selectedLicense
        setSelectedLicense(undefined);
      }
    },
    [filteredLicenses, selectedLicense]
  );

  const onAssignLicense = async () => {
    if (!selectedLicense) return;

    try {
      await assignLicenseToDevice.mutateAsync({
        deviceId,
        licenseId: selectedLicense.id,
      });

      closeMe();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal
      padding={0}
      title="Assign license"
      opened={true}
      onClose={closeMe}
      size="80%"
      styles={modalStyles}
    >
      <LoadingOverlay visible={licenses.isLoading} />

      <LicenseCategories
        licenses={licenses.data || []}
        selectedCategory={selectedCategory}
        onSelectCategory={setSelectedCategory}
      />

      <Stack h="100%" bg="gray.0">
        {hasTokenLicenses ? (
          <Group w="100%" position="right" pt="xxl" pr="xl">
            <UnstyledButton
              onClick={() =>
                openModal<RedeemLicenseCodeModalProps['data']>(
                  'RedeemLicenseCodeModal',
                  { deviceId, onSuccess: closeMe }
                )
              }
            >
              <Text
                underline
                color="gray.9"
                data-testid="assign-license-with-code"
              >
                Assign license with a code
              </Text>
            </UnstyledButton>
          </Group>
        ) : null}

        {isEmpty(licenses.data) ? (
          <EmptyState messageDescription="There are no licenses to assign to this device" />
        ) : (
          <div className={classes.cardsGrid}>
            {Object.entries(filteredLicenses).map(([productId, licenses]) => {
              return (
                <UnassignedLicenseCard
                  key={productId}
                  license={licenses[0]}
                  amountLeft={licenses.length}
                  isSelected={productId === selectedLicense?.product_id}
                  onSelect={setSelectedLicense}
                />
              );
            })}
          </div>
        )}
      </Stack>

      <Group className={classes.footer} position="right">
        <Button variant="default" onClick={closeMe} data-testid="cancel-button">
          Cancel
        </Button>
        <Button
          disabled={!selectedLicense}
          loading={assignLicenseToDevice.isLoading}
          onClick={onAssignLicense}
          data-testid="unassigned-license-assign-button"
        >
          Assign
        </Button>
      </Group>
    </Modal>
  );
}

const modalStyles: MantineModalProps['styles'] = (theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '90%',
  },
  header: {
    margin: 0,
    padding: theme.spacing.xl,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },
  body: {
    flexGrow: 1,
    display: 'grid',
    gridTemplateColumns: 'minmax(240px, 1fr) 4fr',
    gridTemplateRows: 'auto min-content',
  },
});

const useStyles = createStyles((theme) => ({
  cardsGrid: {
    display: 'grid',
    gap: theme.spacing.xl,
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridAutoRows: 280,
    padding: theme.spacing.xl,
    backgroundColor: theme.colors.gray[0],
    height: '100%',

    [theme.fn.largerThan(1600)]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
  },
  footer: {
    gridColumn: 'span 3',
    padding: theme.spacing.xl,
    borderTop: `1px solid ${theme.colors.gray[2]}`,
  },
}));
