import { Badge, BadgeProps, createStyles } from '@mantine/core';
import React from 'react';

interface ProductCategoryBadgeProps extends BadgeProps {
  category: string;
}

export function ProductCategoryBadge({
  category,
  className,
  ...badgeProps
}: ProductCategoryBadgeProps) {
  const { cx, classes } = useStyles();

  return (
    <Badge size="lg" className={cx(classes.badge, className)} {...badgeProps}>
      {category}
    </Badge>
  );
}

const useStyles = createStyles((theme) => ({
  badge: {
    backgroundColor: theme.colors.blue_gray[9],
    color: theme.white,
    fontSize: theme.fontSizes.xs,
    fontWeight: 500,
    textTransform: 'capitalize',
  },
}));
