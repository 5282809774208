import {
  OrderSummaryType as OrganizationOrderSummaryType,
  OrderType as OrganizationOrderType,
} from '@portals/api/organizations';
import {
  OrderSummaryType as PartnerOrderSummaryType,
  OrderType as PartnerOrderType,
} from '@portals/api/partners';
import { PaymentMethodEnum } from '@portals/types';

import { PAYMENT_METHOD_DISPLAY_NAMES } from './consts';

type CommonOrderType =
  | OrganizationOrderSummaryType
  | OrganizationOrderType
  | PartnerOrderSummaryType
  | PartnerOrderType;

export function getOrderPaymentMethodLabel(
  paymentMethod: CommonOrderType['payment_method']
) {
  switch (paymentMethod) {
    case 'credit_card':
      return 'Credit Card';

    case 'purchase_order':
      return 'Purchase Order';

    case 'offline':
      return 'Offline payment';

    case 'lab':
      return 'Lab';
  }
}

export function getOrganizationOrderFulfillmentStatusColorAndLabel(
  fulfillmentStatus: OrganizationOrderSummaryType['fulfillment_status']
) {
  switch (fulfillmentStatus) {
    case 'missing_information':
    case 'order_placed': {
      return {
        bgColor: 'amber.0',
        indicatorColor: 'amber.6',
        label:
          fulfillmentStatus === 'missing_information'
            ? 'Missing information'
            : 'Order placed',
      };
    }

    case 'processing': {
      return {
        bgColor: 'gray.1',
        indicatorColor: 'gray.4',
        label: 'Processing',
      };
    }

    case 'ready': {
      return {
        bgColor: 'indigo.0',
        indicatorColor: 'indigo.6',
        label: 'Shipment ready',
      };
    }

    case 'shipped': {
      return {
        bgColor: 'blue_accent.0',
        indicatorColor: 'blue.3',
        label: 'Shipped',
      };
    }

    case 'available':
    case 'delivered': {
      return {
        bgColor: 'teal.0',
        indicatorColor: 'teal_accent.4',
        label: fulfillmentStatus === 'available' ? 'Available' : 'Delivered',
      };
    }

    case 'cancelled_during_ready':
    case 'cancelled': {
      return {
        bgColor: 'deep_orange.0',
        indicatorColor: 'deep_orange.6',
        label: 'Cancelled',
      };
    }
  }
}

export function getPartnerOrderFulfillmentStatusColorAndLabel(
  fulfillmentStatus: PartnerOrderSummaryType['fulfillment_status']
) {
  switch (fulfillmentStatus) {
    case 'order_placed':
    case 'missing_information': {
      return {
        bgColor: 'amber.0',
        indicatorColor: 'amber.6',
        label:
          fulfillmentStatus === 'missing_information'
            ? 'Missing buyer input'
            : 'New order placed',
      };
    }

    case 'processing': {
      return {
        bgColor: 'gray.1',
        indicatorColor: 'gray.4',
        label: 'Needs shipment',
      };
    }

    case 'ready': {
      return {
        bgColor: 'indigo.0',
        indicatorColor: 'indigo.6',
        label: 'Shipment ready',
      };
    }

    case 'shipped': {
      return {
        bgColor: 'blue_accent.0',
        indicatorColor: 'blue.3',
        label: 'Shipped',
      };
    }

    case 'available':
    case 'delivered': {
      return {
        bgColor: 'teal.0',
        indicatorColor: 'teal_accent.4',
        label: fulfillmentStatus === 'available' ? 'Available' : 'Delivered',
      };
    }

    case 'cancelled_during_ready':
    case 'cancelled': {
      return {
        bgColor: 'deep_orange.0',
        indicatorColor: 'deep_orange.6',
        label: 'Cancelled',
      };
    }

    default: {
      return {
        bgColor: 'gray.1',
        indicatorColor: 'gray.4',
        label: 'Unknown',
      };
    }
  }
}

export function getOrganizationOrderPaymentStatusColorAndLabel(
  paymentStatus: OrganizationOrderSummaryType['payment_status']
) {
  switch (paymentStatus) {
    case 'pending': {
      return {
        bgColor: 'gray.1',
        indicatorColor: 'gray.4',
        label: 'Payment in process',
      };
    }

    case 'paid': {
      return {
        bgColor: 'teal.0',
        indicatorColor: 'teal_accent.4',
        label: 'Paid',
      };
    }

    case 'archived':
    case 'failed':
    case 'expired': {
      const getLabel = () => {
        if (paymentStatus === 'archived') {
          return 'Archived';
        } else if (paymentStatus === 'failed') {
          return 'Payment failed';
        } else {
          return 'Expired';
        }
      };

      return {
        bgColor: 'red.0',
        indicatorColor: 'red.4',
        label: getLabel(),
      };
    }

    case 'awaiting_po_approval':
    case 'awaiting_po_upload': {
      return {
        bgColor: 'amber.0',
        indicatorColor: 'amber.6',
        label:
          paymentStatus === 'awaiting_po_upload'
            ? 'Requires PO'
            : `Pending seller approval`,
      };
    }

    case 'refunded': {
      return {
        bgColor: 'indigo.0',
        indicatorColor: 'indigo.3',
        label: 'Refunded',
      };
    }

    default: {
      return {
        bgColor: 'gray.1',
        indicatorColor: 'gray.4',
        label: paymentStatus,
      };
    }
  }
}

export function getPartnerOrderPaymentStatusColorAndLabel(
  paymentStatus: OrganizationOrderSummaryType['payment_status']
) {
  switch (paymentStatus) {
    case 'pending': {
      return {
        bgColor: 'gray.1',
        indicatorColor: 'gray.4',
        label: 'Payment in process',
      };
    }

    case 'paid': {
      return {
        bgColor: 'teal.0',
        indicatorColor: 'teal_accent.4',
        label: 'Paid',
      };
    }

    case 'archived':
    case 'failed':
    case 'expired': {
      const getLabel = () => {
        if (paymentStatus === 'archived') {
          return 'Archived';
        } else if (paymentStatus === 'failed') {
          return 'Payment failed';
        } else {
          return 'Expired';
        }
      };

      return {
        bgColor: 'red.0',
        indicatorColor: 'red.4',
        label: getLabel(),
      };
    }

    case 'awaiting_po_approval':
    case 'awaiting_po_upload': {
      return {
        bgColor: 'amber.0',
        indicatorColor: 'amber.6',
        label:
          paymentStatus === 'awaiting_po_upload'
            ? 'Pending PO'
            : `Requires PO Approval`,
      };
    }

    case 'refunded': {
      return {
        bgColor: 'indigo.0',
        indicatorColor: 'indigo.3',
        label: 'Refunded',
      };
    }

    default: {
      return {
        bgColor: 'gray.1',
        indicatorColor: 'gray.4',
        label: paymentStatus,
      };
    }
  }
}

export function getPaymentMethodDisplayName(paymentMethod: PaymentMethodEnum) {
  return PAYMENT_METHOD_DISPLAY_NAMES[paymentMethod];
}
