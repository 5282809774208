import { Loader } from '@mantine/core';
import { Formik } from 'formik';
import { flatten } from 'lodash/fp';
import React, { useCallback, useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { AutoFormikProps, FieldTypeEnum } from '@portals/types';

import {
  buildInitialValues,
  buildSchema,
  formBuilder,
} from './autoformik.utils';

export function AutoFormik(props: AutoFormikProps) {
  const { fields, initialValues, serverError, handleSubmit } = props;

  const dataFields = useMemo(
    () =>
      flatten(
        fields.reduce(
          (list, field) => [
            ...list,
            field.type === FieldTypeEnum.Group ? field.children : field,
          ],
          []
        )
      ),
    [fields]
  );

  const [schema, setSchema] = useState(buildSchema(dataFields));
  const [initial, setInitial] = useState(
    buildInitialValues(dataFields, initialValues)
  );

  useUpdateEffect(
    function setSchemaBasedOnDataFields() {
      setSchema(buildSchema(dataFields));
    },
    [dataFields]
  );

  useUpdateEffect(
    function setInitialValues() {
      setInitial(buildInitialValues(dataFields, initialValues));
    },
    [dataFields, initialValues]
  );

  const onSubmit = useCallback(
    (newValues: Record<string, any>) =>
      handleSubmit({ ...initialValues, ...newValues }),
    [handleSubmit, initialValues]
  );

  return !schema ? (
    <Loader />
  ) : (
    <Formik
      innerRef={props.innerRef}
      enableReinitialize={true}
      initialValues={initial}
      initialErrors={serverError && { server: serverError }}
      onSubmit={onSubmit}
      validationSchema={schema}
      {...(props.formikProps || {})}
    >
      {formBuilder(props)}
    </Formik>
  );
}

export default AutoFormik;
