export const TOUR_STEPS_IDS = {
  device: 'device',
  overviewSpaces: 'overview-spaces',
  claimDeviceButton: 'claim-device-button',
  deviceActions: 'device-actions',
  deviceDetails: 'device-details',
  deviceOverview: 'device-overview',
  space: 'space',
  overview: 'overview',
  overviewHeaderMenu: 'overview-header-menu',
};
