import { LoadingOverlay } from '@mantine/core';
import { find } from 'lodash/fp';
import React, { useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { useClearDataCache } from '@portals/api';
import {
  DeviceDetailsType,
  DeviceModelType,
  devicesQueryKeys,
  DeviceType,
  incidentsQueryKeys,
  licensesQueryKeys,
  useApproveDeviceReplacement,
  useCancelDeviceReplacement,
  useDevice,
  useDeviceModels,
  useReplaceDevice,
  useSnoozeDevice,
  useUnsnoozeDevice,
  useUpdateDevice,
  useWithSupportCenter,
} from '@portals/api/organizations';
import {
  Device,
  DeviceContextProvider,
  DeviceContextType,
} from '@portals/framework/route-modals';
import { useOpenModal } from '@portals/redux';
import {
  OrganizationFeatureFlagsType,
  OrganizationPortalCapabilities,
} from '@portals/types';

import { DeviceStateProvider } from './DeviceStateProvider';
import { useVisibleTabs } from './tabs/device-tabs.hooks';
import { MonitoringTourPartTwo } from '../../components/setup-tours/MonitoringTourPartTwo';
import { DeleteDeviceModalProps } from '../../modals';

interface OrganizationDeviceContextType
  extends DeviceContextType<
    DeviceType,
    DeviceModelType,
    OrganizationFeatureFlagsType,
    OrganizationPortalCapabilities
  > {}

export const DeviceWrapper = () => {
  const params = useParams<{ device_id: string }>();
  const navigate = useNavigate();
  const [isDeletingDevice, setIsDeletingDevice] = useState(false);

  const clearDataCache = useClearDataCache();
  const updateDevice = useUpdateDevice();
  const snoozeDevice = useSnoozeDevice(params.device_id);
  const unsnoozeDevice = useUnsnoozeDevice(params.device_id);
  const cancelReplacement = useCancelDeviceReplacement();
  const approveReplacement = useApproveDeviceReplacement();
  const replaceDevice = useReplaceDevice();
  const withSupportCenter = useWithSupportCenter();
  const openModal = useOpenModal();

  const device = useDevice(params.device_id, { enabled: !isDeletingDevice });
  const models = useDeviceModels();
  const tabs = useVisibleTabs(device?.data);

  const onRefresh = () => {
    clearDataCache([
      incidentsQueryKeys.base,
      licensesQueryKeys.base,
      devicesQueryKeys.commands.all(device.data?.id),
      devicesQueryKeys.details(device.data?.id),
    ]);
  };

  const onUpdateDevice: OrganizationDeviceContextType['onUpdateDevice'] = (
    updatedDevice
  ) => {
    updateDevice.mutate({
      spaceId: device.data?.space_id,
      deviceId: device.data?.id,
      updatedDevice: updatedDevice as DeviceDetailsType,
    });
  };

  const onSnoozeDevice: OrganizationDeviceContextType['onSnoozeDevice'] = (
    minutes
  ) => {
    snoozeDevice.mutate(minutes);
  };

  const onUnsnoozeDevice: OrganizationDeviceContextType['onUnsnoozeDevice'] =
    () => {
      unsnoozeDevice.mutate();
    };

  const onDeleteDevice: OrganizationDeviceContextType['onDeleteDevice'] =
    () => {
      openModal<DeleteDeviceModalProps['data']>('DeleteDeviceModal', {
        devices: [device.data],
        onConfirm: () => {
          setIsDeletingDevice(true);
        },
        onFinished: () => navigate('/devices'),
        onError: () => {
          setIsDeletingDevice(false);
        },
      });
    };

  if (
    (device.isFetched && !device.data) ||
    (models.isFetched && !models.data)
  ) {
    return <Navigate to="/devices" replace />;
  } else if (!device.isFetched || !models.isFetched) {
    return <LoadingOverlay visible />;
  }

  return (
    <DeviceContextProvider<
      DeviceType,
      DeviceModelType,
      OrganizationFeatureFlagsType,
      OrganizationPortalCapabilities
    >
      tabs={tabs}
      onUpdateDevice={onUpdateDevice}
      onSnoozeDevice={onSnoozeDevice}
      onUnsnoozeDevice={onUnsnoozeDevice}
      onRefresh={onRefresh}
      isUpdatingDevice={updateDevice.isLoading}
      onDeleteDevice={onDeleteDevice}
      onCancelReplace={cancelReplacement}
      onApproveReplace={approveReplacement}
      onReplace={replaceDevice}
      withSupportCenter={withSupportCenter}
    >
      <DeviceStateProvider device={device.data}>
        <MonitoringTourPartTwo />

        <Device<DeviceDetailsType, DeviceModelType>
          model={find({ id: device.data.partner?.type_id }, models.data)}
          device={!device.error ? device.data : null}
          isLoading={updateDevice.isLoading || isDeletingDevice}
        />
      </DeviceStateProvider>
    </DeviceContextProvider>
  );
};
