import React from 'react';

import { IconPicker, IconPickerProps } from '@portals/core';

import { GROUP_ICONS, GroupIconNames } from './group-icons';

interface GroupIconPickerProps
  extends Omit<IconPickerProps<GroupIconNames>, 'icons'> {}

export function GroupIconPicker({ ...iconPickerProps }: GroupIconPickerProps) {
  return (
    <IconPicker<GroupIconNames>
      withTooltip={false}
      icons={GROUP_ICONS}
      triggerSize={36}
      position="right"
      {...iconPickerProps}
    />
  );
}
