import PropTypes from 'prop-types';
import React from 'react';

import { InfoTable } from '@portals/table';

const CrestronView = ({ device }) => {
  const manifest_url =
    process.env.NX_SERVER_URL +
    `external/crestron/${device.id}/` +
    `manifest/${device.custom_info_version}` +
    `?access_token=${device.connection_info?.access_key}`;

  const remote_url = device.config?.deviceInfo?.remote_url;

  return (
    <div className="h-100 p-3">
      <InfoTable.Table>
        <InfoTable.Row
          label="Manifest File"
          value={
            <a href={manifest_url} target="_blank" rel="noopener noreferrer">
              {manifest_url}
            </a>
          }
        />

        <InfoTable.Row
          label="Remote URL"
          value={
            remote_url ? (
              <a href={remote_url} target="_blank" rel="noopener noreferrer">
                {remote_url}
              </a>
            ) : (
              <span className="text-muted">No remote url set in config</span>
            )
          }
        />
      </InfoTable.Table>
    </div>
  );
};

CrestronView.propTypes = {
  device: PropTypes.object.isRequired,
};

export default CrestronView;
