import { useMemo } from 'react';

import { useCurrentUser } from '@portals/api/ui';
import { TenantType } from '@portals/types';

import { useAppConfig } from '../context';

export function useCurrentUserAccessibleTenants() {
  const { tenantType } = useAppConfig();
  const currentUser = useCurrentUser();

  return useMemo(() => {
    if (tenantType === TenantType.Organization) {
      return currentUser.data?.accessible_tenants.organizations;
    } else if (tenantType === TenantType.Partner) {
      return currentUser.data?.accessible_tenants.partners;
    }

    return undefined;
  }, [
    currentUser.data?.accessible_tenants.organizations,
    currentUser.data?.accessible_tenants.partners,
    tenantType,
  ]);
}
