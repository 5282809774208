import { createStyles } from '@mantine/core';
import React from 'react';

import { PageBreadcrumbs } from '@portals/core';

const BreadcrumbPageTitle = ({
  backLabel,
  backTo,
  currentLabel,
  title = '',
}) => {
  const { classes } = useStyles();

  return (
    <PageBreadcrumbs
      className={classes.breadcrumbs}
      crumbs={[
        {
          label: backLabel,
          to: backTo,
        },
        { label: currentLabel },
      ]}
    />
  );
};

const useStyles = createStyles((theme) => ({
  breadcrumbs: {
    marginBottom: theme.spacing.md,
  },
}));

export default BreadcrumbPageTitle;
