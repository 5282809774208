import {
  Anchor,
  Button,
  createStyles,
  Group,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  OrganizationInvoiceType,
  useInvoiceRetryCharge,
} from '@portals/api/organizations';
import { useOpenModal } from '@portals/redux';

interface InvoicePaymentFailedProps {
  invoice: OrganizationInvoiceType;
}

export function InvoicePaymentFailed({ invoice }: InvoicePaymentFailedProps) {
  const { classes } = useStyles();

  const invoiceRetryCharge = useInvoiceRetryCharge();
  const openModal = useOpenModal();
  const navigate = useNavigate();

  const onRetryPayment = () => {
    invoiceRetryCharge.mutate(
      { invoiceId: invoice.id },
      {
        onSuccess: () => openModal('RetryChargeSuccessModal'),
        onError: () => {
          openModal('RetryChargeFailureModal', {
            failureReason: invoice.failure_reason,
          });
        },
      }
    );
  };

  return (
    <Group align="center" spacing="xl" className={classes.container}>
      <Text weight={600} color="gray.9" align="center">
        {invoice.failure_reason}. Please take action to make the payment and
        keep your subscription active
      </Text>

      <Stack w="100%" spacing="xs">
        <Button
          onClick={onRetryPayment}
          variant="filled"
          w="100%"
          size="md"
          loading={invoiceRetryCharge.isLoading}
        >
          Retry Payment
        </Button>

        <Tooltip
          label="Once credit card details are updated, click on retry payment."
          position="bottom"
        >
          <Anchor
            onClick={() => navigate('/settings/credit_cards')}
            color="blue_gray.8"
            align="center"
            className={classes.link}
          >
            Edit credit card
          </Anchor>
        </Tooltip>
      </Stack>
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    padding: theme.spacing.xl,
    backgroundColor: theme.colors.red[0],
    borderRadius: theme.radius.md,
  },

  link: {
    textDecoration: 'underline',
  },
}));
