import { useQuery } from '@tanstack/react-query';

import { DEVICE_INSIGHTS_URL, SPACE_INSIGHTS_URL } from './ai.constants';
import { InsightType } from './ai.types';
import { parseInsightsResponse } from './ai.utils';
import { fetchApiRequest, useRequestOptions } from '../../utils/common';

export const useSpaceInsights = (spaceId: number) => {
  const { url, options } = useRequestOptions({ url: SPACE_INSIGHTS_URL });

  return useQuery<Array<InsightType>, Error>(
    [SPACE_INSIGHTS_URL, 'space_insights', spaceId],
    async () => {
      const response = await fetchApiRequest(
        `${url}/${spaceId}/insights`,
        options
      );

      return parseInsightsResponse(response);
    },
    {
      enabled: !!spaceId,
    }
  );
};

export const useDeviceInsights = (deviceId: string) => {
  const { url, options } = useRequestOptions({ url: DEVICE_INSIGHTS_URL });

  return useQuery<Array<InsightType>, Error>(
    [DEVICE_INSIGHTS_URL, 'device_insights', deviceId],
    async () => {
      const response = await fetchApiRequest(
        `${url}/${deviceId}/insights`,
        options
      );

      return parseInsightsResponse(response);
    },
    {
      enabled: !!deviceId,
    }
  );
};

export const useDeviceTelemetriesInsights = (deviceId: string) => {
  const { url, options } = useRequestOptions({ url: DEVICE_INSIGHTS_URL });

  return useQuery<Array<InsightType>, Error>(
    [DEVICE_INSIGHTS_URL, 'telemetries_anomalies', deviceId],
    async () => {
      const response = await fetchApiRequest(
        `${url}/${deviceId}/telemetries/anomalies`,
        options
      );

      return parseInsightsResponse(response);
    },
    {
      enabled: !!deviceId,
    }
  );
};
