import { useMutation } from '@tanstack/react-query';

import { fetchApiRequest, useRequestOptions } from '@portals/api';

export interface ValidateTaxAddressParams {
  address_1: string;
  address_2: string;
  address_3: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  note_to_shipper: string;
}

export function useValidateTaxAddress() {
  const { url, options } = useRequestOptions({
    url: 'ui/validate_tax_address',
    method: 'POST',
  });

  return useMutation({
    mutationFn: (params: ValidateTaxAddressParams) =>
      fetchApiRequest(url, {
        ...options,
        body: JSON.stringify(params),
      }),
  });
}
