import { NumberInput, NumberInputProps } from '@mantine/core';
import React from 'react';

import { CurrencyCode } from '@portals/types';
import { getCurrencySign } from '@portals/utils';

import { useProductDetails } from './ProductDetailsProvider';

export interface ProductPersonalizedPriceProps {
  currencyCode: CurrencyCode | null;
}

export function ProductPersonalizedPrice({
  currencyCode,
}: ProductPersonalizedPriceProps) {
  const currencySymbol = getCurrencySign(currencyCode);

  const { personalizedPriceFromUrl, personalizedPrice, setPersonalizedPrice } =
    useProductDetails();

  return (
    <NumberInput
      autoFocus
      label="Enter price"
      placeholder="Enter price"
      size="xl"
      radius="md"
      hideControls
      precision={2}
      disabled={Boolean(personalizedPriceFromUrl)}
      value={personalizedPrice}
      onChange={setPersonalizedPrice}
      iconWidth={50}
      icon={currencySymbol}
      styles={inputStyles}
    />
  );
}

const inputStyles: NumberInputProps['styles'] = (theme) => ({
  root: {
    alignSelf: 'flex-start',
    width: 250,

    [theme.fn.smallerThan('md')]: {
      width: '100%',
    },
  },
  label: {
    fontSize: theme.fontSizes.lg,
    color: theme.colors.gray[9],
  },
  input: {
    '::placeholder': {
      fontSize: theme.fontSizes.xl,
    },
  },
  icon: {
    fontSize: theme.fontSizes.xl,
    color: theme.colors.gray[4],
  },
});
