import { IntegrationState } from '@portals/types';

export enum IntegrationCategoryEnum {
  Ticketing = 'ticketing',
  Messaging = 'messaging',
  All = 'all',
  Enabled = 'enabled',
}

export interface IntegrationCategoryType {
  id: IntegrationCategoryEnum;
  label: string;
}

export interface IntegrationType {
  id: string;
  name: string;
  logo: string;
  owner: string;
  description: string;
  status: IntegrationState;
  link: string | null;
  isComingSoon: boolean;
}

export interface IntegrationsGroupType {
  integrations: Array<IntegrationType>;
  category: IntegrationCategoryEnum;
}
