import { Anchor, Stack, Text } from '@mantine/core';
import React from 'react';
import { Link } from 'react-router-dom';

interface NoLicenseProps {
  closeMe: () => void;
}

export function NoLicense({ closeMe }: NoLicenseProps) {
  return (
    <Stack align="center" spacing="sm" mb="xl">
      <Text size="xl" weight={500}>
        There are no available device monitoring licenses
      </Text>
      <Text>
        Please subscribe to additional "Device monitoring" licenses via the{' '}
        <Anchor component={Link} to="/store" onClick={closeMe}>
          Store
        </Anchor>
      </Text>
    </Stack>
  );
}
