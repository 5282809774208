import { Button, ButtonProps, CopyButton } from '@mantine/core';
import React from 'react';

import { ReactComponent as TickCircleIcon } from '@portals/icons/bold/tick-circle.svg';
import { ReactComponent as CopyIcon } from '@portals/icons/linear/copy.svg';

interface GroupNameAndIconProps {
  groupId: string;
}

export function GroupId({ groupId }: GroupNameAndIconProps) {
  return (
    <CopyButton value={groupId}>
      {({ copied, copy }) => (
        <Button
          variant="outline"
          onClick={copy}
          styles={buttonStyles}
          color={copied ? 'teal' : 'blue_gray'}
          leftIcon={copied ? <TickCircleIcon /> : <CopyIcon />}
        >
          {copied ? 'Group ID Copied' : 'Copy Group ID'}
        </Button>
      )}
    </CopyButton>
  );
}

const buttonStyles: ButtonProps['styles'] = () => ({
  leftIcon: {
    width: 16,
    height: 16,
  },
});
