import React from 'react';

import {
  PURCHASED_PRODUCTS_API_URL,
  PurchasedProductType,
  usePaginatedPurchasedProducts,
} from '@portals/api/organizations';
import {
  AvatarCell,
  BadgeCell,
  DateCell,
  PaginatedTable,
} from '@portals/table';
import { TableColumn, TableFilterTypeEnum } from '@portals/types';

import { PurchasedProductDetailsPanel } from './PurchasedProductDetailsPanel';

const TABLE_COLUMNS: Array<TableColumn<PurchasedProductType>> = [
  {
    dataField: 'product_name',
    text: 'Name',
    sort: true,
    filter: { type: TableFilterTypeEnum.Text },
    formatter: (_, { product }) => (
      <AvatarCell label={product.name} src={product.image_url} radius="md" />
    ),
  },
  {
    dataField: 'product_category',
    text: 'Category',
    sort: true,
    minWidth: 200,
    maxWidth: 200,
    filter: { type: TableFilterTypeEnum.Text },
    formatter: (_, { product }) =>
      product.category ? (
        <BadgeCell
          label={product.category}
          variant="filled"
          color="dark"
          radius="lg"
          sx={(theme) => ({
            color: theme.white,
            fontWeight: 400,
            fontSize: theme.fontSizes.xs,
            textTransform: 'uppercase',
          })}
        />
      ) : (
        '--'
      ),
  },
  {
    dataField: 'partner_display_name',
    text: 'Seller',
    sort: true,
    filter: { type: TableFilterTypeEnum.Text },
    formatter: (_, { seller }) => (
      <AvatarCell label={seller.display_name} src={seller.logo} radius="sm" />
    ),
  },
  {
    text: 'Purchase Date',
    dataField: 'created_at',
    sort: true,
    minWidth: 220,
    maxWidth: 220,
    filter: { type: TableFilterTypeEnum.Date },
    formatter: (_, { created_at }) => (
      <DateCell date={created_at} withTimeAgo={false} />
    ),
  },
];

interface PurchasedProductsListProps {
  dataHookUrl: string;
  dataHookQueryKey: string[];
}

export function PurchasedProductsList({
  dataHookUrl,
  dataHookQueryKey,
}: PurchasedProductsListProps) {
  return (
    <PaginatedTable<PurchasedProductType>
      noHeader
      key={dataHookUrl}
      columns={TABLE_COLUMNS}
      dataHookQueryKey={dataHookQueryKey}
      dataHook={usePaginatedPurchasedProducts}
      dataHookUrl={`${PURCHASED_PRODUCTS_API_URL}${dataHookUrl}`}
      defaultSortBy={[{ id: 'created_at', desc: true }]}
      noDataIndication={{ title: 'No purchased products' }}
      name="organizations.purchased_products"
      keyField="id"
      detailsPanel={{
        type: 'page',
        renderer: ({ row, onClose }) => (
          <PurchasedProductDetailsPanel
            purchasedProductId={row.original.id}
            onClose={onClose}
          />
        ),
      }}
    />
  );
}
