import { Group, Loader, Text } from '@mantine/core';
import { get, getOr } from 'lodash/fp';
import React, { useMemo } from 'react';

import {
  CommonConfigurationType,
  PartnerConfigurationType,
  StateType,
  TabType,
  TenantType,
} from '@portals/types';
import { ButtonTabs, SideTabs } from '@portals/ui';

import IntegrationErrors from './IntegrationErrors';
import IntegrationState from './IntegrationState';
import { useAppConfig } from '../../context';
import { useCommonPortalCapabilities } from '../../hooks/portal-config';
import { usePermissionAccess } from '../permission-access/use-permission-access';

export const IntegrationsContext = React.createContext(null);

const TitleWithError = ({ title, state }) => (
  <Group align="center">
    <Text size="sm">{title}</Text>

    {state && <IntegrationState state={state} className="float-right" />}
  </Group>
);

function setSubTabs<TCommonConfigurationType extends CommonConfigurationType>(
  tab: TabType<TCommonConfigurationType['feature_flags']>,
  integrations: StateType['data']['integrations']
) {
  tab.tabs?.forEach?.(
    (subTab) => (subTab.state = get([subTab.id, 'state'], integrations))
  );

  return tab.tabs?.filter?.((subTab) => subTab.state === 'error').length;
}

interface IntegrationsProps<
  TCommonConfigurationType extends CommonConfigurationType = CommonConfigurationType
> {
  integrations: any;
  config: TCommonConfigurationType | undefined;
  tabs: TabType<
    TCommonConfigurationType['feature_flags'],
    TCommonConfigurationType['portal_capabilities'],
    { config: TCommonConfigurationType }
  >[];
}

function Integrations<
  TCommonConfigurationType extends CommonConfigurationType = CommonConfigurationType
>(props: IntegrationsProps<TCommonConfigurationType>) {
  const { tenantType } = useAppConfig();
  const portalCapabilities =
    useCommonPortalCapabilities() as TCommonConfigurationType['portal_capabilities'];
  const { integrations, tabs, config } = props;
  const { isAdmin, canView, canEdit } = usePermissionAccess();

  // Features: Only show tabs that should be visible according to feature flags.
  // Errors: For each tab, we check the subTabs if their integration has an error
  //   If so, we set subTab.error = true
  //   Return back for each tab the number of errors of its subTabs
  const visibleTabs = useMemo(() => {
    let result = tabs.filter((tab) =>
      tab.visible
        ? tab.visible({
            config,
            featureFlags: config.feature_flags,
            portalCapabilities,
            isAdmin,
            canView,
            canEdit,
          })
        : true
    );

    result = result.reduce((tabsList, tab) => {
      const subTabs = tab.tabs?.filter?.((subTab) => {
        if (tenantType === TenantType.Organization) return true;

        if (subTab.id === 'ms_teams_c2c') {
          return true;
        }

        const features = (config as PartnerConfigurationType)?.settings
          ?.features;

        return getOr(true, ['integrations', subTab.id], features);
      });

      if (subTabs.length > 0) {
        tabsList.push({ ...tab, tabs: subTabs });
      }

      return tabsList;
    }, []);

    result.forEach(
      (tab) =>
        (tab.errors = setSubTabs<TCommonConfigurationType>(tab, integrations))
    );

    return result;
  }, [
    canView,
    canEdit,
    config,
    tenantType,
    portalCapabilities,
    integrations,
    isAdmin,
    tabs,
  ]);

  return !integrations ? (
    <Loader />
  ) : (
    <IntegrationsContext.Provider value={props}>
      <ButtonTabs
        tabs={visibleTabs}
        urlBase="/settings/integrations"
        titleWrap={IntegrationErrors}
      >
        {(tab, urlBase) => (
          <SideTabs
            tabs={tab.tabs}
            urlBase={urlBase}
            titleWrap={TitleWithError}
            disabled={tab.disabled}
          />
        )}
      </ButtonTabs>
      <div className="text-center mt-5 pt-5">
        <a
          href="mailto:support@xyte.io"
          target="_blank"
          rel="noopener noreferrer"
        >
          Looking for an integration you cannot find? Let us know!
        </a>
      </div>
    </IntegrationsContext.Provider>
  );
}

export default Integrations;
