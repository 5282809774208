/// <reference types="google.maps" />
// do not remove the above line without making sure that Google Maps type support works

import { Point } from 'geojson';
import { Coords } from 'google-map-react';
import { find, getOr } from 'lodash/fp';
import { useState } from 'react';
import { useEffectOnce } from 'react-use';

import {
  DeviceType,
  IncidentType,
  SpaceType,
} from '@portals/api/organizations';
import { MapCoordinatesType } from '@portals/types';
import { DEFAULT_MAP_CENTER } from '@portals/utils';

export function childrenToPoints<TEntity extends DeviceType | IncidentType>(
  entities: TEntity[] | undefined,
  spaces: SpaceType[],
  mapper: (entities: TEntity) => Record<string, any>
) {
  return (entities || [])
    .map((entity: TEntity): GeoJSON.Feature<Point> | null => {
      const space = find({ id: Number(entity.space_id) }, spaces);
      if (!space) {
        return null;
      }

      const location = space.config.location?.location || null;
      if (!location) {
        return null;
      }

      return {
        type: 'Feature',
        properties: { cluster: false, ...mapper(entity) },
        geometry: {
          type: 'Point',
          coordinates: [location.lng, location.lat],
        },
      };
    })
    .filter((item) => item !== null)
    .sort((a, b) =>
      (a?.properties?.priority || 0) > (b?.properties?.priority || 0) ? 1 : -1
    );
}

async function getTimeZoneCoordinates(
  spaceLocation: MapCoordinatesType | null,
  setCoordinates: (coordinates: MapCoordinatesType) => void
) {
  if (spaceLocation === null) {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const city = tz === 'UTC' ? 'Los_Angeles' : tz.split('/')[1];

    try {
      const geocoder = new window.google.maps.Geocoder();
      const { results } = await geocoder.geocode({ address: city });
      const location = results[0].geometry.location;

      setCoordinates({ lat: location.lat(), lng: location.lng() });
    } catch (err) {
      console.error(err);
    }
  }
}

export const useGetLocation = (space: SpaceType | undefined) => {
  const [coordinates, setCoordinates] = useState(DEFAULT_MAP_CENTER);
  const [isMounted, setIsMounted] = useState(true);
  const spaceLocation = space?.config?.location?.location || null;

  useEffectOnce(() => {
    if (isMounted) {
      (async () => {
        await getTimeZoneCoordinates(spaceLocation, setCoordinates);
      })();
    }

    return () => setIsMounted(false);
  });

  const { lat, lng } = getOr(coordinates, 'config.location.location', space);

  return {
    lat: parseFloat(lat),
    lng: parseFloat(lng),
  };
};

export function jitterPosition(
  originalPosition: Coords,
  index: number,
  total: number
): Coords {
  const JITTER_AMOUNT = 0.00005;

  const angle = (index / total) * 2 * Math.PI;

  const latOffset = JITTER_AMOUNT * Math.cos(angle);
  const lngOffset = JITTER_AMOUNT * Math.sin(angle);

  return {
    lat: originalPosition.lat + latOffset,
    lng: originalPosition.lng + lngOffset,
  };
}
