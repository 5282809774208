import { get } from 'lodash/fp';
import React, { FC } from 'react';
import { connect } from 'react-redux';

import { IntegrationType } from '@portals/types';

import ServiceDisabled from './ServiceDisabled';
import { ServiceCardFormProps } from './types';

type FreshServiceEventsTypes = {
  integration: IntegrationType;
} & ServiceCardFormProps;

const EditFreshService: FC<FreshServiceEventsTypes> = ({ integration }) => {
  if (!integration || integration.state !== 'active') {
    return (
      <ServiceDisabled
        name="Freshservice"
        url="/settings/integrations/ticketing/fresh_service"
      />
    );
  }

  return (
    <div className="d-flex flex-column justify-content-center align-items-center w-100">
      <h5>No additional routing supported</h5>
    </div>
  );
};

const mapStateToProps = (state) => ({
  integration: get(['data', 'integrations', 'fresh_service'], state),
});

export default connect(mapStateToProps)(EditFreshService);
