export const SUPPORTED_COMMANDS_API_URL = 'ui/organization/supported_commands';

export const supportedCommandsQueryKeys = {
  all: [SUPPORTED_COMMANDS_API_URL],
  relatedProductsToSupportedByCommandId: (commandId: string) => [
    ...supportedCommandsQueryKeys.all,
    'relatedProductsToSupportedByCommandId',
    commandId,
  ],
};
