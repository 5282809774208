import { Center, createStyles } from '@mantine/core';
import React from 'react';

import { ProductImage } from '@portals/framework';
import { OrganizationStoreListing } from '@portals/types';

interface ProductGalleryProps {
  product: OrganizationStoreListing['product'];
}

export function ProductGallery({ product }: ProductGalleryProps) {
  const { classes } = useStyles();

  return (
    <Center className={classes.gallery}>
      <ProductImage src={product.image_url} className={classes.image} />
    </Center>
  );
}

const useStyles = createStyles((theme) => ({
  gallery: {
    padding: '0 50px',
    backgroundColor: theme.colors.gray[0],
  },
  image: {
    maxWidth: 300,
    border: `1px solid ${theme.colors.blue_gray[0]}`,
    borderRadius: theme.radius.md,
    backgroundColor: theme.colors.blue_gray[0],

    [theme.fn.largerThan('xl')]: {
      maxWidth: 475,
    },
  },
}));
