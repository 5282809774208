import { Text } from '@mantine/core';
import React, { FC } from 'react';

import { AuditLogType } from '@portals/types';

interface CreateGroupProps {
  auditLog: AuditLogType;
}

export const CreateGroup: FC<CreateGroupProps> = ({ auditLog }) => (
  <Text size="sm">
    Created {auditLog?.params[0]?.model}: {auditLog?.params[0]?.display_name}
  </Text>
);
