import { Box, BoxProps, createStyles } from '@mantine/core';
import React from 'react';

// import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
// import { useRemoveCreditCard } from '@portals/api/organizations';
import { CardBrand } from '@portals/types';

import amexSrc from '../../../../../assets/img/cards/amex.png';
import dinersSrc from '../../../../../assets/img/cards/diners.png';
import discoverSrc from '../../../../../assets/img/cards/discover.png';
import jcbSrc from '../../../../../assets/img/cards/jcb.png';
import mastercardSrc from '../../../../../assets/img/cards/mastercard.png';
import unionpaySrc from '../../../../../assets/img/cards/unionpay.png';
import unknownSrc from '../../../../../assets/img/cards/unknown.png';
import visaSrc from '../../../../../assets/img/cards/visa.png';

// import { CreditCardMenu } from './CreditCardMenu';

interface CreditCardProps {
  id?: string;
  brand: CardBrand;
  lastDigits: string;
  expiration: string;
  isSelected: boolean;
  onChange: (selectedLastDigits: string) => void;
  className?: string;
  boxProps?: BoxProps;
}

const CARD_IMAGE = {
  [CardBrand.amex]: amexSrc,
  [CardBrand.diners]: dinersSrc,
  [CardBrand.discover]: discoverSrc,
  [CardBrand.jcb]: jcbSrc,
  [CardBrand.mastercard]: mastercardSrc,
  [CardBrand.unionpay]: unionpaySrc,
  [CardBrand.unknown]: unknownSrc,
  [CardBrand.visa]: visaSrc,
};

export function CreditCard({
  brand,
  lastDigits,
  expiration,
  isSelected,
  onChange,
  id,
  className,
  boxProps = {},
}: CreditCardProps) {
  // const removeCreditCard = useRemoveCreditCard();
  const { cx, classes } = useStyles({
    brandSrc: CARD_IMAGE[brand],
    isSelected,
  });

  // const onRemove = async () => {
  //   await removeCreditCard.mutateAsync(id as string);
  // };

  return (
    <Box className={cx(classes.wrapper, className, { 'with-border': !!id })}>
      <Box
        className={cx(classes.container)}
        onClick={() => onChange(id)}
        {...boxProps}
      >
        {/*<LoadingOverlay visible={removeCreditCard.isLoading} />*/}

        <div
          className={classes.cardNumber}
          data-testid="credit-card-card-number"
        >
          **** **** **** {lastDigits}
        </div>

        <div className={classes.footer}>
          <div>{expiration}</div>
        </div>

        {/*{id ? (*/}
        {/*  <Box className={classes.menu}>*/}
        {/*    <CreditCardMenu*/}
        {/*      items={[*/}
        {/*        {*/}
        {/*          Icon: Trash,*/}
        {/*          title: 'Remove',*/}
        {/*          color: 'red',*/}
        {/*          onClick: onRemove,*/}
        {/*        },*/}
        {/*      ]}*/}
        {/*    />*/}
        {/*  </Box>*/}
        {/*) : null}*/}
      </Box>
    </Box>
  );
}

const useStyles = createStyles(
  (
    theme,
    {
      brandSrc,
      isSelected,
    }: {
      brandSrc: string;
      isSelected: boolean;
      noBorder?: boolean;
    }
  ) => ({
    wrapper: {
      padding: 3,
      aspectRatio: '229 / 150',

      '&.with-border': {
        transition: 'box-shadow 0.15s ease-in-out',
        cursor: isSelected ? 'default' : 'pointer',
        borderRadius: 12,
        boxShadow: `0 0 0 2px ${
          isSelected ? theme.colors.blue_accent[4] : theme.colors.gray[3]
        }`,
      },
    },
    container: {
      width: '100%',
      height: '100%',
      position: 'relative',
      backgroundImage: `url(${brandSrc})`,
      backgroundSize: 'cover',
      fontSize: theme.fontSizes.sm,
      color: theme.white,
      fontFamily: 'Courier',
      borderRadius: 12,
    },
    cardNumber: {
      position: 'absolute',
      zIndex: 2,
      top: '50%',
      transform: 'translateY(-50%)',
      left: theme.spacing.sm,
    },
    menu: {
      position: 'absolute',
      zIndex: 2,
      top: theme.spacing.sm,
      right: theme.spacing.sm,
    },
    footer: {
      position: 'absolute',
      zIndex: 2,
      left: theme.spacing.sm,
      right: theme.spacing.sm,
      bottom: theme.spacing.md,
      display: 'flex',
      flexDirection: 'row-reverse',
    },
  })
);
