import { AnimatePresence, motion } from 'framer-motion';
import React, { FC, ReactNode } from 'react';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components';

import { ReactComponent as TrendDown } from '@portals/icons/linear/trend-down.svg';
import { ReactComponent as TrendUp } from '@portals/icons/linear/trend-up.svg';
import { useIsPending } from '@portals/redux';
import { getStyledThemeColor } from '@portals/utils';

import { FADE_IN_OUT } from '../animations';
import { useTheme } from '../ThemeProvider';

export enum TrendEnum {
  Up = 'up',
  Down = 'down',
}

const TREND_ICON = {
  [TrendEnum.Up]: <TrendUp className="delta-icon trend-up" />,
  [TrendEnum.Down]: <TrendDown className="delta-icon trend-down" />,
};

type FinanceCardType = {
  title: string;
  icon: ReactNode;
  color: string;
  value: string | number;
  inProgress?: Array<string>;
  deltas?: Array<any>;
};

export const MetricCard: FC<FinanceCardType> = ({
  title,
  icon,
  value,
  deltas,
  color,
  inProgress,
}) => {
  const theme = useTheme();
  const isPending = useIsPending(inProgress);

  return (
    <Container color={color} className="metric-card-container">
      <IconWrapper color={color}>{icon}</IconWrapper>

      <Header className="header">
        <AnimatePresence initial={false} mode="wait">
          {isPending ? (
            <Value key="loading-skeleton">
              <ContentLoader
                speed={2}
                width={70}
                height={50}
                viewBox="0 0 70 50"
                backgroundColor={theme.color.gray200}
                foregroundColor={theme.color.gray100}
              >
                <rect x="0" y="0" rx="5" ry="5" width="70" height="40" />
              </ContentLoader>
            </Value>
          ) : (
            <Value key="value">{value}</Value>
          )}
        </AnimatePresence>
      </Header>

      <ContentWrapper>
        <Label className="label">{title}</Label>

        <AnimatePresence initial={false} mode="wait">
          {isPending ? (
            <Deltas key="loading-skeleton">
              <Delta>
                <ContentLoader
                  speed={2}
                  width={200}
                  height={25}
                  viewBox="0 0 200 25"
                  backgroundColor={theme.color.gray200}
                  foregroundColor={theme.color.gray100}
                >
                  <rect x="0" y="5" rx="5" ry="5" width="200" height="15" />
                </ContentLoader>
              </Delta>
            </Deltas>
          ) : (
            <Deltas key="deltas">
              {deltas.map(({ value, trend }, index) => (
                <Delta key={index}>
                  {value}

                  {trend ? TREND_ICON[trend] : null}
                </Delta>
              ))}
            </Deltas>
          )}
        </AnimatePresence>
      </ContentWrapper>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${getStyledThemeColor('white')};
  padding: 15px 30px 15px 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  position: relative;
  flex-shrink: 0;
  width: 285px;
  height: 170px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04), 0 0 2px rgba(0, 0, 0, 0.06),
    0 0 1px rgba(0, 0, 0, 0.04);

  &:not(:last-of-type) {
    margin-right: 20px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const IconWrapper = styled.div`
  color: ${getStyledThemeColor('white')};
  position: absolute;
  top: 15px;
  right: 15px;
  opacity: 0.7;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, color }) => theme.color[color]};
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 12px;
  filter: drop-shadow(0px 2px 2px ${getStyledThemeColor('gray400')});

  svg {
    height: 100%;
    width: 100%;
  }
`;

const Value = styled(motion.div).attrs(() => FADE_IN_OUT)`
  font-size: 25px;
  font-weight: 700;
  height: 50px;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

const Label = styled.div`
  font-weight: 600;
  font-size: 18px;
`;

const Deltas = styled(motion.div).attrs(() => FADE_IN_OUT)``;

const Delta = styled.div`
  height: 25px;
  display: flex;
  font-size: 15px;
  color: ${getStyledThemeColor('gray600')};
  width: 100%;
  flex: 1;
  align-items: flex-end;

  .delta-icon {
    margin-left: 5px;

    &.trend-up {
      color: ${getStyledThemeColor('success')};
    }

    &.trend-down {
      color: ${getStyledThemeColor('danger')};
    }
  }
`;
