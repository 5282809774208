import { Modal } from '@mantine/core';
import React, { FC } from 'react';

import { JsonBlob } from '@portals/ui';

type ShowHashParams = {
  header: string;
  keyMap: Record<string, unknown>;
};

export type ShowHashProps = {
  data: ShowHashParams;
  closeMe: () => void;
};

export const ShowHash: FC<ShowHashProps> = ({ data, closeMe }) => {
  const { header, keyMap } = data;

  return (
    <Modal opened onClose={closeMe} title={header} size="xl">
      <JsonBlob json={keyMap} height="500px" />
    </Modal>
  );
};

export default ShowHash;
