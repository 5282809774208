import {
  Box,
  Center,
  createStyles,
  Group,
  LoadingOverlay,
  Stack,
  Text,
} from '@mantine/core';
import React, { useState } from 'react';

import { useServiceProviders } from '@portals/api/organizations';
import { SearchInput } from '@portals/core';
import { RouteModal } from '@portals/framework/route-modals';
import { NoDataState } from '@portals/table';
import { useDebouncedValue } from '@portals/utils';

import { ServiceProviderCard } from './ServiceProviderCard';

export function ServiceProviders() {
  const { classes } = useStyles();
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebouncedValue(searchTerm, 400);

  const serviceProviders = useServiceProviders(debouncedSearchTerm);

  const onChange = (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  if (serviceProviders?.data?.length === 0) {
    return (
      <RouteModal modalId="certified_service_provider">
        <ServiceProviderModalTitle
          onChange={onChange}
          searchValue={searchTerm}
        />

        <Center>
          <NoDataState title="No Service Providers" />
        </Center>
      </RouteModal>
    );
  }

  return (
    <RouteModal modalId="certified_service_provider">
      <Stack className={classes.container}>
        <ServiceProviderModalTitle
          onChange={onChange}
          searchValue={searchTerm}
        />

        <Box className={classes.content}>
          <LoadingOverlay visible={serviceProviders.isFetching} />

          <div className={classes.grid}>
            {serviceProviders.data?.map((serviceProvider) => (
              <Box key={serviceProvider.id}>
                <ServiceProviderCard
                  logo={serviceProvider.logo_url}
                  name={serviceProvider.display_name}
                  id={serviceProvider.id}
                  description={serviceProvider.description}
                />
              </Box>
            ))}
          </div>
        </Box>
      </Stack>
    </RouteModal>
  );
}

function ServiceProviderModalTitle({
  onChange,
  searchValue,
}: {
  onChange: (searchTerm: string) => void;
  searchValue: string;
}) {
  const { classes } = useStyles();

  return (
    <Group className={classes.header}>
      <Text
        size="xl"
        className={classes.title}
        data-testid="service-providers-title"
      >
        Service Providers
      </Text>

      <SearchInput
        m="auto"
        autoFocus
        value={searchValue}
        onClear={() => onChange('')}
        onChange={(event) => onChange(event.target.value)}
      />
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  header: {
    padding: theme.spacing.xl,
    fontSize: theme.fontSizes.xl,
    alignItems: 'center',
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
    backgroundColor: theme.white,
    position: 'relative',
  },

  title: {
    position: 'absolute',
    transform: 'translateY(-50%)',
    top: '50%',
    left: theme.spacing.xl,
  },

  content: {
    backgroundColor: theme.colors.gray[0],
    padding: theme.spacing.xl,
    position: 'relative',
    flexGrow: 1,
  },

  container: {
    backgroundColor: theme.colors.gray[0],
    height: 'inherit',
  },

  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(380px, 1fr))',
    gap: theme.spacing.xl,
  },
}));
