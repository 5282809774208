import {
  DeviceType,
  IncidentPriorityEnum,
  SpaceType,
} from '@portals/api/organizations';

import { DataLevelEnum } from '../../../overview.types';

export function getDevicesCount(space: SpaceType, dataLevel: DataLevelEnum) {
  if (dataLevel === DataLevelEnum.Local) {
    return space?.state?.local_devices || 0;
  } else {
    return space?.state?.devices || 0;
  }
}

export function countIncidentsOnDeviceInSpace(
  selectedIncidentPriorities: IncidentPriorityEnum[],
  spaceId: number,
  devices: DeviceType[],
  dataLevel: DataLevelEnum
) {
  if (!devices) {
    return 0;
  }

  let incidentsCounter = 0;

  for (const device of devices) {
    if (dataLevel === DataLevelEnum.Local && spaceId !== device.space_id) {
      continue;
    }

    const deviceIncidents = convertIncidentsArrayToObject(device.incidents);

    for (const incidentPriority of selectedIncidentPriorities) {
      const incidentPriorityKey =
        incidentPriority.toLowerCase() as keyof typeof deviceIncidents;

      if (deviceIncidents[incidentPriorityKey] > 0) {
        incidentsCounter += 1;
        break;
      }
    }
  }

  return incidentsCounter;
}

export function convertIncidentsArrayToObject(
  incidentsByPriorities: Array<number>
) {
  const deviceIncidentsByPriorities = {
    [IncidentPriorityEnum.Critical]: 0,
    [IncidentPriorityEnum.High]: 0,
    [IncidentPriorityEnum.Moderate]: 0,
    [IncidentPriorityEnum.Low]: 0,
    [IncidentPriorityEnum.Planning]: 0,
  };

  Object.keys(deviceIncidentsByPriorities).forEach((key, index) => {
    deviceIncidentsByPriorities[key] = incidentsByPriorities[index];
  });

  return deviceIncidentsByPriorities;
}
