import { ActionIcon } from '@mantine/core';
import React, { FC } from 'react';
import { FilterProps, UseFiltersColumnProps } from 'react-table';

import { useOpenModal } from '@portals/redux';
import { suppressPropagation } from '@portals/utils';

import { ReactComponent as ActiveFilterIcon } from '../../assets/active-filter.svg';
import { ReactComponent as FilterIcon } from '../../assets/filter.svg';

type DateFilterProps = {
  column: FilterProps<any>['column'] & UseFiltersColumnProps<any>;
};

const DateFilter: FC<DateFilterProps> = ({ column }) => {
  const { filterValue } = column;

  const openModal = useOpenModal();

  const onToggleFilterModal = () =>
    openModal('DateRange', {
      startDate: column.filterValue?.gte,
      endDate: column.filterValue?.lte,
      onSubmit: (dateRange) =>
        column.setFilter({ gte: dateRange.startDate, lte: dateRange.endDate }),
      onClear: () => column.setFilter(null),
    });

  return (
    <ActionIcon
      onClick={suppressPropagation(onToggleFilterModal)}
      size="xs"
      className="column-filter-toggle"
      data-testid={`table-${column.id}-column-date-filter-button`}
    >
      {filterValue?.gte || filterValue?.lte ? (
        <ActiveFilterIcon />
      ) : (
        <FilterIcon />
      )}
    </ActionIcon>
  );
};

export default DateFilter;
