import { contains, sortBy } from 'lodash/fp';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  useDeviceStateHistory,
  WidgetDataRangeEnum,
} from '@portals/api/organizations';
import { NewWidgetType, WidgetType } from '@portals/device-widgets';

import { WIDGETS_WITH_STATE_HISTORY } from '../desktop/components/DeviceWidgets/constants';
import { DeviceStateType } from '../desktop/route-modals/Device/tabs/device-tabs.types';

export const useDeviceStateLiveOrHistory = (
  widget: WidgetType | NewWidgetType,
  deviceLiveState: Array<DeviceStateType>
) => {
  const { device_id: deviceId } = useParams<{ device_id: string }>();

  const isWithHistory = contains(widget.config.id, WIDGETS_WITH_STATE_HISTORY);
  const [widgetDataRange, setWidgetDataRange] = useState<WidgetDataRangeEnum>(
    isWithHistory ? WidgetDataRangeEnum.Day : WidgetDataRangeEnum.Live
  );

  const { data: deviceStateHistory, isInitialLoading } = useDeviceStateHistory({
    deviceId,
    widgetDataRange,
    queryOptions: {
      enabled: widgetDataRange !== WidgetDataRangeEnum.Live,
      refetchInterval: 1000 * 60 * 5,
      staleTime: 0,
    },
  });

  const adjustedDeviceStates = useMemo(() => {
    if (!deviceStateHistory || widgetDataRange === WidgetDataRangeEnum.Live) {
      return deviceLiveState;
    }

    const adjustedDeviceStates = (deviceStateHistory || []).map(
      ([state, timestamp]) => ({
        data: state,
        timestamp,
      })
    ) as DeviceStateType[];

    return sortBy('timestamp', adjustedDeviceStates);
  }, [widgetDataRange, deviceStateHistory, deviceLiveState]);

  return {
    isInitialLoading,
    deviceStates: adjustedDeviceStates,
    isWithHistory,
    widgetDataRange,
    setWidgetDataRange,
  };
};
