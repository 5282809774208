import { Box, createStyles } from '@mantine/core';
import { AnimatePresence } from 'framer-motion';
import React from 'react';

import { StepAnimationWrapper } from './common';
import { useSignUpWizard } from './wizard/wizard.hooks';

export function SignUpLayout() {
  const { classes } = useStyles();
  const { currentStep } = useSignUpWizard();

  return (
    <Box className={classes.container}>
      <AnimatePresence initial={false}>
        <StepAnimationWrapper stepId={currentStep.id}>
          {currentStep.component}
        </StepAnimationWrapper>
      </AnimatePresence>
    </Box>
  );
}

const useStyles = createStyles({
  container: {
    width: 450,
  },
});
