import {
  NotificationStylesNames,
  NotificationStylesParams,
} from '@mantine/core';
import { NotificationProps } from '@mantine/notifications';

import { ThemeComponentOverrides } from '../common-types';

export const Notification: ThemeComponentOverrides<
  NotificationProps,
  NotificationStylesNames,
  NotificationStylesParams
> = {
  defaultProps: {
    radius: 'lg',
  },
  styles: (theme) => ({
    root: {
      width: 420,
      alignItems: 'flex-start',
      padding: theme.spacing.xl,
      boxShadow: `0 4px 10px ${theme.fn.rgba(theme.black, 0.1)}`,
      backdropFilter: 'blur(100px)',

      '&[data-with-icon]': {
        paddingLeft: theme.spacing.xl,
      },
    },
    title: {
      fontSize: theme.fontSizes.md,
      fontWeight: 700,
      color: theme.colors.blue_gray[8],
      marginBottom: theme.spacing.xs,
    },
    icon: {
      display: 'flex',
      alignSelf: 'self-start',
      backgroundColor: theme.white,
    },
  }),
};
