import { createStyles } from '@mantine/core';
import React from 'react';

import { MapFeature, SuperClusterType } from './device-location-widget.types';

interface ClusterProps {
  item: MapFeature;
  superCluster?: SuperClusterType;
  showPartnerMarker?: boolean;
  onClick: (cluster: MapFeature) => void;
  zoom: number;
  lat: number;
  lng: number;
}

export function DeviceLocationCluster(props: ClusterProps) {
  const { item, onClick } = props;
  const { classes } = useStyles();

  const pointCount = item.properties?.point_count || 0;

  if (!pointCount) return null;

  return (
    <div className={classes.clusterContainer} onClick={() => onClick(item)}>
      {pointCount}
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  clusterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    color: theme.colors.gray[7],
    fontSize: theme.fontSizes.lg,
    borderRadius: '50%',
    border: `1px solid ${theme.white}`,
    padding: theme.spacing.xs,
    minWidth: 40,
    minHeight: 40,
    backgroundColor: theme.white,
    boxShadow: theme.shadows.md,
    position: 'relative',
    top: -10,
    left: -10,
  },
}));
