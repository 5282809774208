import { once } from 'lodash/fp';
import React, { createContext, ReactNode, useContext } from 'react';

const createGenericContext = once(<Value extends object>() =>
  createContext<Value>({} as Value)
);

export const useGenericContext = <Value extends object>(): Value | null => {
  const value = useContext(createGenericContext<Value>());

  if (!value) {
    console.warn('Context is not defined');

    return null;
  }

  return value;
};

interface GenericContextProps<Value extends object> {
  value: Value;
  children: ReactNode;
}

export const GenericContextProvider = <Value extends object>({
  value,
  children,
}: GenericContextProps<Value>) => {
  const Context = createGenericContext<Value>();

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
