import { Alert, Loader, Stack, Text } from '@mantine/core';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React from 'react';

import { CommandType } from '@portals/types';

dayjs.extend(relativeTime);

interface CommandInProgressEventProps {
  command: CommandType;
}
export function CommandInProgressEvent({
  command,
}: CommandInProgressEventProps) {
  const getExecutedAtTimeAgo = () => {
    const parsedExecutedAt = Date.parse(command?.created_at);

    return dayjs().to(dayjs(parsedExecutedAt));
  };

  return (
    <Alert
      title={
        <Text color="black" size="md" weight={600}>
          {command?.friendly_name} is in progress...
        </Text>
      }
      icon={<Loader />}
      color="blue_accent"
      p="xl"
      radius="lg"
    >
      <Stack spacing={4}>
        <Text color="gray.8">Executed by {command?.issuer_name}</Text>
        <Text color="gray.7" size="xs">
          {getExecutedAtTimeAgo()}
        </Text>
      </Stack>
    </Alert>
  );
}
