import {
  Button,
  Center,
  createStyles,
  Divider,
  LoadingOverlay,
  Stack,
  Text,
} from '@mantine/core';
import { isEmpty } from 'lodash/fp';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import emptyCartSrc from '../../../assets/img/empty-cart.svg';
import { useStoreListingsAndCheckCartItems } from '../../../hooks/store';
import { useActiveOrderPreview, useOrder } from '../../../redux/hooks/store';
import { CompactOrderSummary } from '../store/order-summary/CompactOrderSummary';
import { OrderItemsPreview } from '../store/OrderItemsPreview';

interface ShoppingCartProps {
  onClose: () => void;
}

export function ShoppingCart({ onClose }: ShoppingCartProps) {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const orderPreview = useActiveOrderPreview();
  const storeListings = useStoreListingsAndCheckCartItems();

  const order = useOrder();

  if (isEmpty(order.items)) {
    return (
      <Center py="xl">
        <Stack w={210} align="center">
          <img src={emptyCartSrc} height={210} width={210} alt="empty cart" />

          <Text size="md" weight={500} color="gray.8">
            Your Cart is Empty
          </Text>

          <Text align="center" size="sm" color="gray.5" weight={500}>
            Looks like you haven’t added anything to your cart yet
          </Text>

          <Button
            variant="white"
            onClick={() => {
              navigate('/store');
              onClose();
            }}
          >
            Go To Store
          </Button>
        </Stack>
      </Center>
    );
  }

  return (
    <Stack spacing={0} className={classes.container}>
      <LoadingOverlay
        visible={orderPreview.isLoading || storeListings.isLoading}
      />

      <div className={classes.content}>
        <OrderItemsPreview />
      </div>

      <Stack className={classes.footer}>
        <Divider />

        <CompactOrderSummary />

        <Button
          fullWidth
          data-testid="shopping-cart-checkout-button"
          onClick={() => {
            navigate('/checkout');
            onClose();
          }}
        >
          Checkout
        </Button>
      </Stack>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    overflow: 'hidden',
    minHeight: 300,
    maxHeight: '80dvh',
  },
  content: {
    overflow: 'auto',
    paddingInline: theme.spacing.xl,
    paddingBlock: theme.spacing.md,
  },
  footer: {
    position: 'sticky',
    bottom: 0,
    marginTop: 'auto',
    paddingInline: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
  },
}));
