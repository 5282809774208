import { Anchor, Badge, BadgeProps, Text } from '@mantine/core';
import React from 'react';
import { Link } from 'react-router-dom';

import {
  LICENSES_STATUS_COLORS,
  LICENSES_STATUS_NUMERIC_VALUES,
  LICENSES_STATUS_VALUES,
  PURCHASED_PRODUCTS_API_URL,
  PurchasedProductType,
  usePaginatedPurchasedProducts,
} from '@portals/api/organizations';
import {
  AvatarCell,
  BadgeCell,
  DateCell,
  PaginatedTable,
} from '@portals/table';
import { TableColumn, TableFilterTypeEnum } from '@portals/types';

import { PurchasedProductDetailsPanel } from './PurchasedProductDetailsPanel';

const TABLE_COLUMNS: Array<TableColumn<PurchasedProductType>> = [
  {
    dataField: 'product_name',
    text: 'Name',
    sort: true,
    filter: { type: TableFilterTypeEnum.Text },
    formatter: (_, { product }) => (
      <AvatarCell label={product.name} src={product.image_url} radius="md" />
    ),
  },
  {
    dataField: 'license.device_name',
    text: 'Assigned device',
    minWidth: 200,
    formatter: (_, { license }) => {
      const deviceName = license?.device_name;

      if (!deviceName) {
        return <Text c="gray.5">No device</Text>;
      }

      return (
        <Anchor component={Link} to={`/m/device/${license.device_id}`}>
          {deviceName}
        </Anchor>
      );
    },
  },
  {
    dataField: 'license_state',
    text: 'Status',
    sort: true,
    filter: {
      type: TableFilterTypeEnum.Select,
      options: LICENSES_STATUS_NUMERIC_VALUES,
    },
    formatter: (_, { license }) => (
      <Badge
        color={LICENSES_STATUS_COLORS[license.state]}
        styles={badgeStyles}
        px="sm"
        py="xs"
      >
        {LICENSES_STATUS_VALUES[license.state]}
      </Badge>
    ),
  },
  {
    dataField: 'product_category',
    text: 'Category',
    sort: true,
    minWidth: 200,
    maxWidth: 200,
    filter: { type: TableFilterTypeEnum.Text },
    formatter: (_, { product }) =>
      product.category ? (
        <BadgeCell
          label={product.category}
          variant="filled"
          color="dark"
          radius="lg"
          sx={(theme) => ({
            color: theme.white,
            fontWeight: 400,
            fontSize: theme.fontSizes.xs,
            textTransform: 'uppercase',
          })}
        />
      ) : (
        '--'
      ),
  },
  {
    dataField: 'partner_display_name',
    text: 'Seller',
    sort: true,
    filter: { type: TableFilterTypeEnum.Text },
    formatter: (_, { seller }) => (
      <AvatarCell label={seller.display_name} src={seller.logo} radius="sm" />
    ),
  },
  {
    text: 'Purchase Date',
    dataField: 'created_at',
    sort: true,
    minWidth: 220,
    maxWidth: 220,
    filter: { type: TableFilterTypeEnum.Date },
    formatter: (_, { created_at }) => (
      <DateCell date={created_at} withTimeAgo={false} />
    ),
  },
];

interface PurchasedLicenseListProps {
  dataHookUrl: string;
  dataHookQueryKey: string[];
}

export function PurchasedLicenseList({
  dataHookUrl,
  dataHookQueryKey,
}: PurchasedLicenseListProps) {
  return (
    <PaginatedTable<PurchasedProductType>
      noHeader
      key={dataHookUrl}
      columns={TABLE_COLUMNS}
      dataHookQueryKey={dataHookQueryKey}
      dataHook={usePaginatedPurchasedProducts}
      dataHookUrl={`${PURCHASED_PRODUCTS_API_URL}${dataHookUrl}`}
      defaultSortBy={[{ id: 'created_at', desc: true }]}
      noDataIndication={{ title: 'No purchased products' }}
      name="organizations.purchased_products"
      keyField="id"
      detailsPanel={{
        type: 'page',
        renderer: ({ row, onClose }) => (
          <PurchasedProductDetailsPanel
            purchasedProductId={row.original.id}
            onClose={onClose}
          />
        ),
      }}
    />
  );
}

const badgeStyles: BadgeProps['styles'] = (theme) => ({
  inner: {
    fontWeight: 400,
    color: theme.colors.gray[7],
    fontSize: theme.fontSizes.xs,
    textTransform: 'none',
  },
});
