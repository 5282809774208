import { WidgetType } from '@portals/device-widgets';

import { useApiQuery } from '../../hooks';
import {
  deviceModelsQueryKeys,
  getDeviceModelApiUrl,
} from './device-models.constants';

function getApiUrl(modelId: string) {
  return `${getDeviceModelApiUrl(modelId)}/device_widgets`;
}

export function useDeviceWidgets(modelId: string) {
  return useApiQuery<Array<WidgetType>>(
    getApiUrl(modelId),
    deviceModelsQueryKeys.widgets.all(modelId),
    {
      enabled: !!modelId,
    }
  );
}
