import { Badge, Center, createStyles, Paper, Stack, Text } from '@mantine/core';
import React from 'react';

import {
  DeviceModelBreakdownViewEnum,
  SpaceType,
} from '@portals/api/organizations';

import { useChartData } from './device-model-breakdown-widget.hooks';
import { DeviceModelBreakdownChart } from './DeviceModelBreakdownChart';
import { DeviceModelBreakdownEmptyState } from './DeviceModelBreakdownEmptyState';

export interface DeviceModelBreakdownWidgetProps {
  title: string;
  deviceModelBreakdownView: DeviceModelBreakdownViewEnum;
  space: SpaceType;
}

export function DeviceModelBreakdownWidget({
  title,
  deviceModelBreakdownView,
  space,
}: DeviceModelBreakdownWidgetProps) {
  const { classes } = useStyles();

  const { chartDataItems, isChartDataEmpty, totalDevicesCount } = useChartData(
    space,
    deviceModelBreakdownView
  );

  return (
    <Paper radius="lg" w={420} h={330} className={classes.container}>
      <Stack p="xl" spacing="xs">
        <Stack spacing="sm">
          <Text color="gray.6" truncate size="md">
            {title}
          </Text>

          <Badge color="gray" variant="outline" px="md" py="sm" w="fit-content">
            {deviceModelBreakdownView ===
            DeviceModelBreakdownViewEnum.OnlineDevices
              ? 'Online Devices'
              : 'All Devices'}
          </Badge>
        </Stack>

        <Center>
          {isChartDataEmpty ? (
            <DeviceModelBreakdownEmptyState />
          ) : (
            <DeviceModelBreakdownChart
              chartDataItems={chartDataItems}
              totalDevicesCount={totalDevicesCount}
            />
          )}
        </Center>
      </Stack>
    </Paper>
  );
}

const useStyles = createStyles(() => ({
  container: {
    boxShadow: '0px 24px 36px 0px rgba(13, 14, 48, 0.05)',
  },
}));
