import React, { useMemo, useState } from 'react';

import {
  useDeviceEventsByRange,
  WidgetDataRangeEnum,
} from '@portals/api/organizations';
import {
  getScatterChartEventsConfig,
  ScatterChartWidget,
  ScatterChartWidgetFormType,
  ScatterChartWidgetProps,
} from '@portals/device-widgets';

import { WidgetProps } from '../device-widgets.types';

export function ScatterChartWidgetWrapper({
  widget,
  deviceId,

  isDeviceOffline,
  lastUpdateTimestamp,
}: WidgetProps<ScatterChartWidgetFormType>) {
  const [range, setRange] = useState<WidgetDataRangeEnum>(
    WidgetDataRangeEnum.Day
  );

  const eventNames = useMemo(
    () =>
      (widget?.config?.fields?.events || []).map((event) => event.event_name),
    [widget?.config?.fields?.events]
  );

  const deviceEvents = useDeviceEventsByRange({
    deviceId,
    widgetDataRange: range,
    eventNames,
    queryOptions: {
      enabled: !!deviceId,
      staleTime: 0,
    },
  });

  const fields = widget?.config?.fields;

  const { name, scale_type, number_format, num_of_decimals, events } =
    fields ?? {};

  const eventsConfig = useMemo(() => {
    return getScatterChartEventsConfig(events);
  }, [events]);

  const data = useMemo<ScatterChartWidgetProps['data']>(() => {
    if (!deviceEvents.isFetched || !deviceEvents.data) return [];

    // Map `eventsConfig` keyed by `event_name`, they are currently mapped by id
    const eventsConfigByName = Object.values(eventsConfig).reduce<
      typeof eventsConfig
    >((acc, eventConfig) => {
      acc[eventConfig.event_name] = { ...eventConfig };
      return acc;
    }, {});

    return deviceEvents.data.map((deviceEvent) => ({
      timestamp: new Date(deviceEvent.timestamp).getTime(),
      eventId: eventsConfigByName[deviceEvent.name].id,
      value:
        deviceEvent.additional_info[
          eventsConfigByName[deviceEvent.name].numeric_parameter
        ],
    }));
  }, [deviceEvents.data, deviceEvents.isFetched, eventsConfig]);

  return (
    <ScatterChartWidget
      title={name}
      eventsConfig={eventsConfig}
      data={data}
      isLoading={deviceEvents.isLoading}
      range={range}
      setRange={setRange}
      scaleType={scale_type}
      numberFormat={number_format}
      numOfDecimals={num_of_decimals}
      isDeviceOffline={isDeviceOffline}
      lastUpdateTimestamp={lastUpdateTimestamp}
    />
  );
}
