export const TICKETS_API_URL = 'ui/organization/tickets';

export function getTicketApiUrl(ticketId: string) {
  return `${TICKETS_API_URL}/${ticketId}`;
}

export const ticketsQueryKeys = {
  all: [TICKETS_API_URL],
  unresolvedCount: () => [...ticketsQueryKeys.all, 'unresolvedCount'],
  includeResolved: (includeResolved: boolean) => [
    ...ticketsQueryKeys.all,
    'includeResolved',
    includeResolved.toString(),
  ],
  detail: (ticketId: string) => [...ticketsQueryKeys.all, 'detail', ticketId],
};
