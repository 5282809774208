import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

import { usePortalConfig } from '@portals/api/organizations';
import { WizardProvider } from '@portals/framework';

import { SignUpLayout } from './SignUpLayout';
import { STEPS } from './wizard/steps-map';
import { SignupWizardContextDataType, StepIdEnum } from './wizard/wizard.types';

export function SignUpWizard() {
  const navigate = useNavigate();

  const [auth] = useLocalStorage('auth');
  const [isReferral] = useLocalStorage('referral');

  const portalConfig = usePortalConfig();

  const [contextData, setContextData] = useState<SignupWizardContextDataType>({
    email: '',
    password: '',
    name: '',
  });

  const initialStep = getInitialStep(contextData);

  useEffect(
    function redirectToSignin() {
      if (auth) {
        navigate('/auth/sign-in');
      }

      if (!isReferral && portalConfig.data?.signup !== true) {
        navigate('/auth/sign-in');
      }
    },
    [isReferral, navigate, portalConfig.data?.signup, auth]
  );

  return (
    <WizardProvider<SignupWizardContextDataType, StepIdEnum>
      steps={STEPS}
      initialStep={initialStep}
      contextData={contextData}
      setContextData={setContextData}
    >
      <SignUpLayout />
    </WizardProvider>
  );
}

function getInitialStep(contextData: SignupWizardContextDataType) {
  const isUserCreated = contextData.email;
  const isOrganizationCreated = contextData.org;
  const isOrganizationAlreadyExists = contextData.organizationExists;

  if (!isUserCreated) {
    return StepIdEnum.Authentication;
  } else if (isOrganizationAlreadyExists) {
    return StepIdEnum.OrganizationExists;
  } else if (!isOrganizationCreated) {
    return StepIdEnum.OrganizationDoesntExists;
  } else {
    return StepIdEnum.InviteCoWorkers;
  }
}
