import {
  ActionIcon,
  createStyles,
  Indicator,
  Overlay,
  Popover,
  Tooltip,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { map, sum } from 'lodash/fp';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMatch } from 'react-router-dom';
import { useKey, useUpdateEffect } from 'react-use';

import { usePortalCapabilities } from '@portals/api/organizations';
import { usePermissionAccess } from '@portals/framework';
import { ReactComponent as ShoppingCartIcon } from '@portals/icons/linear/shopping-cart.svg';

import { ShoppingCart } from './ShoppingCart';
import { useCartProductsList } from '../../../redux/hooks/store';

export function ShoppingCartToggle() {
  const { classes, theme } = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  const { isAdmin } = usePermissionAccess();

  const isLargerThanMd = useMediaQuery(`(min-width: ${theme.breakpoints.md})`);

  const cartProductsList = useCartProductsList();
  const portalCapabilities = usePortalCapabilities();

  const totalCount = useMemo(
    () => sum(map('quantity', cartProductsList)),
    [cartProductsList]
  );

  const onToggle = useCallback(() => setIsOpen((curr) => !curr), [setIsOpen]);

  // Toggle cart popover on count change, if not on checkout page
  const isCheckout = Boolean(useMatch({ path: '/checkout', end: false }));
  const isCheckoutRef = useRef(isCheckout);

  useEffect(() => {
    isCheckoutRef.current = isCheckout;
  }, [isCheckout]);

  useUpdateEffect(() => {
    if (totalCount > 0 && !isOpen && !isCheckoutRef.current) {
      setIsOpen(true);
    }
  }, [totalCount]);

  useKey(
    'Escape',
    () => {
      if (isOpen) {
        setIsOpen(false);
      }
    },
    {},
    [isOpen]
  );

  if (portalCapabilities?.purchased_products !== true) return null;

  if (!isAdmin) {
    return null;
  }

  return (
    <Popover
      opened={isOpen}
      onClose={() => setIsOpen(false)}
      withArrow={isLargerThanMd}
      arrowSize={20}
      position="bottom"
      width={isLargerThanMd ? 500 : '100%'}
      classNames={{
        dropdown: classes.dropdown,
      }}
    >
      <Popover.Target>
        <Tooltip
          withinPortal
          label="Shopping cart"
          disabled={isOpen || !isLargerThanMd}
        >
          <Indicator
            inline
            size={16}
            label={totalCount}
            disabled={!totalCount}
            color="red_accent.4"
            offset={6}
          >
            <ActionIcon
              onClick={onToggle}
              variant="transparent"
              size="lg"
              sx={(theme) => ({
                color: theme.colors.blue_gray[6],
              })}
            >
              <ShoppingCartIcon />
            </ActionIcon>
          </Indicator>
        </Tooltip>
      </Popover.Target>

      <Popover.Dropdown>
        {isOpen && !isLargerThanMd && (
          <Overlay
            className={classes.overlay}
            onClick={() => setIsOpen(false)}
          />
        )}

        <div className={classes.cartContainer}>
          <ShoppingCart onClose={() => setIsOpen(false)} />
        </div>
      </Popover.Dropdown>
    </Popover>
  );
}

const useStyles = createStyles((theme) => ({
  dropdown: {
    padding: 0,
    backgroundColor: theme.white,
    borderBottomLeftRadius: theme.radius.lg,
    borderBottomRightRadius: theme.radius.lg,

    [theme.fn.largerThan('md')]: {
      borderRadius: theme.radius.md,
      filter: 'drop-shadow(-4px 4px 36px rgba(0, 2, 41, 0.1))',
    },
  },
  cartContainer: {
    position: 'relative',
    zIndex: 201,
    borderBottomLeftRadius: theme.radius.lg,
    borderBottomRightRadius: theme.radius.lg,
    backgroundColor: theme.white,

    [theme.fn.largerThan('md')]: {
      borderRadius: theme.radius.md,
    },
  },
  overlay: {
    width: '100%',
    height: '100dvh',
  },
}));
