import { Loader, useMantineTheme } from '@mantine/core';
import { isEmpty, isUndefined } from 'lodash/fp';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import styled from 'styled-components';

import { ReactComponent as Refresh2 } from '@portals/icons/linear/refresh-2.svg';
import { VerticalScrollBar } from '@portals/scrollbar';
import { EmptyState, InfoTable } from '@portals/table';
import { TelemetryType } from '@portals/types';
import { JsonBlob, NetworkButton } from '@portals/ui';
import { getStyledThemeColor, timeAgo } from '@portals/utils';

type TelemetriesViewProps = {
  telemetries?: Array<TelemetryType>;
  showing: number;
  getTelemetries: () => void;
};

const formatDate = (date: string) =>
  moment(date).format('MM/DD/YYYY HH:mm:ss:SS');

const TelemetriesView: FC<TelemetriesViewProps> = ({
  telemetries,
  showing,
  getTelemetries,
}) => {
  const theme = useMantineTheme();

  const [selected, setSelected] = useState(0);

  // Auto select the first telemetry everything telemetries data changes
  useEffect(() => {
    setSelected(0);
  }, [telemetries]);

  if (isUndefined(telemetries)) {
    return (
      <div className="h-100 d-flex align-items-center justify-content-center">
        <Loader />
      </div>
    );
  }

  if (isEmpty(telemetries)) {
    return (
      <div className="h-100 d-flex align-items-center justify-content-center">
        <EmptyState label="No telemetries" />
      </div>
    );
  }

  return (
    <Container className="telemetries-view-container p-3">
      <Header>
        <div className="title">Latest Telemetries</div>
        <div className="title">Telemetry Details</div>
      </Header>
      <Content>
        <div className="timestamps-container">
          <VerticalScrollBar
            renderView={(props) => (
              <div {...props} className="timestamps-scroll-container" />
            )}
          >
            <ListGroup flush>
              {telemetries.map(({ id, created_at }, index) => (
                <ListGroupItem
                  tag="a"
                  key={index}
                  onClick={() => setSelected(index)}
                  action
                  active={index === selected}
                >
                  {formatDate(created_at)}
                </ListGroupItem>
              ))}
            </ListGroup>
          </VerticalScrollBar>

          <Footer>
            <div className="showing-last">{`Displaying last ${showing}`}</div>

            {getTelemetries ? (
              <NetworkButton
                request="getTelemetries"
                color="primary"
                className="shadow-sm ml-2 d-flex align-items-center justify-content-center"
                onClick={getTelemetries}
              >
                <Refresh2
                  width={17}
                  height={17}
                  style={{ marginRight: theme.spacing.xs }}
                />
                Reload
              </NetworkButton>
            ) : null}
          </Footer>
        </div>

        <div className="telemetries-table-container">
          <InfoTable.Table>
            <InfoTable.Row
              label="Created At"
              value={
                <>
                  {telemetries[selected].timestamp}{' '}
                  <b>
                    {`${formatDate(
                      telemetries[selected].created_at
                    )} (${timeAgo(
                      Date.parse(telemetries[selected].created_at)
                    )})`}
                  </b>
                </>
              }
            />

            <InfoTable.Row
              label="Device Timestamp"
              value={
                <>
                  {telemetries[selected].timestamp}{' '}
                  <b>
                    {`${formatDate(
                      telemetries[selected].device_timestamp
                    )} (${timeAgo(
                      Date.parse(telemetries[selected].device_timestamp)
                    )})`}
                  </b>
                </>
              }
            />

            <InfoTable.Row
              className="json-preview-row"
              label="Telemetries"
              value={<JsonBlob json={telemetries[selected].common} />}
            />
          </InfoTable.Table>
        </div>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-rows: max-content 1fr;
  height: 100%;
  min-height: 500px;
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: minmax(250px, 20%) 1fr;
  column-gap: 20px;

  .timestamps-container {
    display: grid;
    grid-template-rows: 1fr max-content;
    grid-template-columns: 1fr;
    background-color: ${getStyledThemeColor('gray100')};

    .timestamps-scroll-container {
      border: 1px solid ${getStyledThemeColor('gray300')};

      .list-group-flush {
        height: 100%;
      }
    }
  }

  .telemetries-table-container {
    display: grid;

    .info-table {
      height: 100%;
      grid-template-rows: max-content max-content 1fr;

      .info-table-row {
        grid-template-columns: 20% 1fr;

        .info-table-row-label {
          padding: 15px 20px;
        }

        &.json-preview-row {
          height: 100%;

          .info-table-row-label,
          .info-table-row-value {
            align-items: flex-start;
          }

          .info-table-row-value {
            padding: 0;
          }
        }
      }
    }
  }
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: minmax(250px, 20%) 1fr;
  column-gap: 20px;
  padding: 0 0 10px 0;
  font-size: 18px;
  color: ${getStyledThemeColor('dark')};

  > div {
    padding-left: 5px;
  }
`;

const Footer = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  background-color: ${getStyledThemeColor('gray150')};
  align-items: center;
  border: 1px solid ${getStyledThemeColor('gray300')};

  padding: 15px;
`;

export default TelemetriesView;
