import {
  ActionIcon,
  createStyles,
  Divider,
  Group,
  Tooltip,
} from '@mantine/core';
import React, { Dispatch, ReactNode, SetStateAction } from 'react';

import { ReactComponent as Element3 } from '@portals/icons/linear/element-3.svg';
import { ReactComponent as List } from '@portals/icons/linear/list.svg';
import { ReactComponent as Map } from '@portals/icons/linear/map-1.svg';
import { TableViewModeType } from '@portals/types';

interface WidgetCardHeaderProps {
  viewType: TableViewModeType;
  setViewType: Dispatch<SetStateAction<TableViewModeType>>;
  title: ReactNode;
  actions?: ReactNode;
  filter?: ReactNode;
  withMapViewType?: boolean;
}

export function WidgetCardHeader({
  viewType,
  setViewType,
  title,
  actions,
  filter,
  withMapViewType,
}: WidgetCardHeaderProps) {
  const styles = useStyles();

  return (
    <Group
      align="center"
      position="apart"
      spacing={4}
      h={90}
      className={styles.classes.container}
    >
      <Group align="center" spacing={4}>
        {title}
      </Group>

      <Group align="center" spacing="sm">
        {filter ? (
          <>
            {filter}

            <Divider orientation="vertical" sx={{ height: 32 }} />
          </>
        ) : null}

        {withMapViewType ? (
          <Tooltip label="Map view">
            <ActionIcon
              size="md"
              variant={viewType === 'map' ? 'light' : 'transparent'}
              color="blue_gray"
              p={4}
              onClick={() => setViewType('map')}
            >
              <Map />
            </ActionIcon>
          </Tooltip>
        ) : null}

        <Tooltip label="List view">
          <ActionIcon
            size="md"
            data-testid="list-view-toggle-button"
            variant={viewType === 'list' ? 'light' : 'transparent'}
            color="blue_gray"
            p={4}
            onClick={() => setViewType('list')}
          >
            <List />
          </ActionIcon>
        </Tooltip>

        <Tooltip label="Grid view">
          <ActionIcon
            size="md"
            variant={viewType === 'grid' ? 'light' : 'transparent'}
            color="blue_gray"
            p={4}
            onClick={() => setViewType('grid')}
          >
            <Element3 />
          </ActionIcon>
        </Tooltip>

        {actions ? (
          <>
            <Divider orientation="vertical" sx={{ height: 32 }} />

            {actions}
          </>
        ) : null}
      </Group>
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    height: 90,
    width: '100%',
  },
}));
