import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';

import { getDeviceApiUrl } from './devices.constants';
import { ServerError } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils';
import { TicketDetailsType, ticketsQueryKeys } from '../tickets';

function getDeviceTicketsApiUrl(deviceId: string) {
  return `${getDeviceApiUrl(deviceId)}/tickets`;
}

interface CreateTicketParams {
  deviceId: string;
  title: string;
  description: string;
}

export function useCreateTicket() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { url, options } = useRequestOptions({
    url: '',
    method: 'POST',
  });

  return useMutation<TicketDetailsType, ServerError, CreateTicketParams>({
    mutationFn: async ({ deviceId, title, description }) =>
      fetchApiRequest(`${url}/${getDeviceTicketsApiUrl(deviceId)}`, {
        ...options,
        body: JSON.stringify({
          title,
          description,
        }),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(ticketsQueryKeys.all);

      dispatch(toastrSuccess('Ticket created successfully'));
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useCreateTicket',
      baseUrl: getDeviceTicketsApiUrl(':id'),
      method: 'POST',
    },
  });
}
