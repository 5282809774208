import { UseQueryResult } from '@tanstack/react-query';
import React, { PropsWithChildren } from 'react';

import { PaginatedTableProps, UsePaginatedTable } from '@portals/types';

import { usePaginatedTable } from './paginated-table.hooks';
import { PaginatedTable } from './PaginatedTable';
import { PaginatedQueryProvider, TableInstanceProvider } from '../context';

export function PaginatedTableProvider<
  TData extends object,
  TKeyField extends keyof TData = keyof TData
>(
  props: PropsWithChildren<
    UsePaginatedTable<TData> & PaginatedTableProps<TData, TKeyField>
  >
) {
  const {
    additionalActions,
    noDataIndication,
    isCompact = false,
    exportParams = { isEnabled: false },
    noHeader = false,
    noFilters = false,
    readOnly = false,
    rowMenu,
    selectedItemsActions,
    expandRow,
    viewType,
    noColumnsSelection,
    gridView,
    detailsPanel,
    pageSize,
    onRowClick,
    keyField,
  } = props;

  const { query, tableInstance, csvUrl } = usePaginatedTable<TData>(props);

  return (
    <PaginatedQueryProvider<TData> query={query as UseQueryResult<TData>}>
      <TableInstanceProvider<TData> instance={tableInstance}>
        <PaginatedTable<TData, TKeyField>
          additionalActions={additionalActions}
          name={props.name}
          keyField={keyField}
          columns={props.columns}
          isCompact={isCompact}
          noHeader={noHeader}
          noFilters={noFilters}
          noDataIndication={noDataIndication}
          exportParams={{ ...exportParams, remoteUrl: csvUrl }}
          rowMenu={rowMenu}
          selectedItemsActions={selectedItemsActions}
          expandRow={expandRow}
          viewType={viewType}
          noColumnsSelection={noColumnsSelection}
          gridView={gridView}
          detailsPanel={detailsPanel}
          pageSize={pageSize}
          readOnly={readOnly}
          onRowClick={onRowClick}
        />
      </TableInstanceProvider>
    </PaginatedQueryProvider>
  );
}
