import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';
import { TableColumn, TableState } from '@portals/types';

import {
  getTicketApiUrl,
  TICKETS_API_URL,
  ticketsQueryKeys,
} from './tickets.constants';
import { TicketDetailsType, TicketSummaryType } from './tickets.types';
import { useApiQuery } from '../../hooks';
import { ServerError } from '../../types';
import {
  fetchApiRequest,
  usePaginatedTableApiQuery,
  useRequestOptions,
} from '../../utils';

export const useTickets = (
  tableState: Pick<TableState<TicketSummaryType>, 'sortBy'>,
  columns: Array<TableColumn>,
  baseUrl = TICKETS_API_URL,
  queryKey: Array<string>
) =>
  usePaginatedTableApiQuery<TicketSummaryType>({
    baseUrl,
    queryKey: queryKey
      ? [...queryKey, baseUrl, tableState]
      : [...ticketsQueryKeys.all, baseUrl, tableState],
    tableState,
    columns,
    queryOptions: { staleTime: 0 },
  });

export function useTicket(ticketId = '') {
  return useApiQuery<TicketDetailsType>(
    getTicketApiUrl(ticketId),
    ticketsQueryKeys.detail(ticketId),
    {
      enabled: !!ticketId,
      staleTime: 0,
    }
  );
}

export function useUnresolvedTicketsCount() {
  return useApiQuery<number>(
    `${TICKETS_API_URL}/unresolved_count`,
    ticketsQueryKeys.unresolvedCount()
  );
}

export function useSendMessage(ticketId: string) {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { url, options } = useRequestOptions({
    url: `${getTicketApiUrl(ticketId)}/chat`,
    method: 'POST',
  });

  return useMutation<TicketDetailsType, ServerError, string>({
    mutationFn: (message) => {
      return fetchApiRequest(url, {
        ...options,
        body: JSON.stringify({ message }),
      });
    },
    onSuccess: (updatedTicket) => {
      queryClient.setQueryData(
        ticketsQueryKeys.detail(ticketId),
        updatedTicket
      );
    },
    onError: () => {
      dispatch(toastrError('Failed to send message'));
    },
    meta: {
      mutationName: 'useSendMessage',
      baseUrl: `${TICKETS_API_URL}/:id/chat`,
      method: 'POST',
    },
  });
}

export function useUpdateSeen(ticketId: string) {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { url, options } = useRequestOptions({
    url: getTicketApiUrl(ticketId),
    method: 'PUT',
  });

  return useMutation<TicketDetailsType, ServerError, boolean>({
    mutationFn: (seen) => {
      return fetchApiRequest(`${url}/${seen ? 'seen' : 'unseen'}`, {
        ...options,
      });
    },
    onSuccess: async (updatedTicket) => {
      queryClient.setQueryData(
        ticketsQueryKeys.detail(ticketId),
        updatedTicket
      );

      await queryClient.invalidateQueries(ticketsQueryKeys.unresolvedCount());

      dispatch(toastrSuccess('Update ticket successfully'));
    },
    onError: () => {
      dispatch(toastrError('Update ticket failed'));
    },
    meta: {
      mutationName: 'useUpdateSeen',
      baseUrl: `${TICKETS_API_URL}/:id/:seen`,
      method: 'PUT',
    },
  });
}

export function useUpdateTicket(ticketId: string) {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { url, options } = useRequestOptions({
    url: getTicketApiUrl(ticketId),
    method: 'PUT',
  });

  return useMutation<
    TicketDetailsType,
    ServerError,
    Partial<TicketDetailsType>
  >({
    mutationFn: (ticket) => {
      return fetchApiRequest(url, {
        ...options,
        body: JSON.stringify(ticket),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(ticketsQueryKeys.all);

      dispatch(toastrSuccess('Update ticket successfully'));
    },
    onError: () => {
      dispatch(toastrError('Update ticket failed'));
    },
    meta: {
      mutationName: 'useUpdateTicket',
      baseUrl: `${TICKETS_API_URL}/:id`,
      method: 'PUT',
    },
  });
}
