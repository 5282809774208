import { filter, includes, orderBy, toLower } from 'lodash/fp';
import { useMemo } from 'react';

import {
  useOrganizationConfig,
  usePortalConfig,
} from '@portals/api/organizations';
import { useAuth } from '@portals/redux';
import { DesignType, OrganizationStoreListing } from '@portals/types';

import { SortByEnum } from './SortBy';

export const useFilteredAndSortedStoreListings = ({
  storeListings,
  searchValue,
  category,
  sortBy,
}: {
  storeListings: Array<OrganizationStoreListing> | undefined;
  searchValue: string;
  category: string | null;
  sortBy: SortByEnum;
}) => {
  const storeListingsFilteredBySearchTerm = useMemo(() => {
    if (!storeListings) return [];
    else if (!searchValue) return storeListings;

    const lowerCaseSearchValue = toLower(searchValue);

    return filter(({ product }) => {
      const lowerCaseName = toLower(product.name);

      return includes(lowerCaseSearchValue, lowerCaseName);
    }, storeListings);
  }, [searchValue, storeListings]);

  const storeListingsFilteredByCategories = useMemo(() => {
    let filteredList;

    if (!category) {
      filteredList = storeListingsFilteredBySearchTerm;
    } else {
      filteredList = filter(
        ({ product }) => product.category === category,
        storeListingsFilteredBySearchTerm
      );
    }

    return filteredList;
  }, [storeListingsFilteredBySearchTerm, category]);

  const sortedStoreListings = useMemo(() => {
    if (!sortBy) return storeListingsFilteredByCategories;

    switch (sortBy) {
      case SortByEnum.Alphabetical:
        return orderBy(
          ({ product }) => product.name,
          'asc',
          storeListingsFilteredByCategories
        );

      case SortByEnum.HighestPrice:
        return orderBy(
          ({ prices }) => prices[0]?.pricing_options?.[0]?.amount,
          'desc',
          storeListingsFilteredByCategories
        );

      case SortByEnum.LowestPrice:
        return orderBy(
          ({ prices }) => prices[0]?.pricing_options?.[0]?.amount,
          'asc',
          storeListingsFilteredByCategories
        );

      case SortByEnum.MostRecent:
        return orderBy(
          ({ product, updated_at }) => new Date(updated_at).getTime(),
          'asc',
          storeListingsFilteredByCategories
        );

      default:
        return storeListingsFilteredByCategories;
    }
  }, [storeListingsFilteredByCategories, sortBy]);

  return {
    sortedStoreListings,
    storeListingsFilteredBySearchTerm,
  };
};

export function useStoreDesign(): DesignType['store'] {
  const auth = useAuth();
  const orgConfig = useOrganizationConfig();
  const portalConfig = usePortalConfig({ enabled: Boolean(auth) });

  return !auth ? portalConfig?.data?.design?.store : orgConfig?.design?.store;
}
