import { createStyles, Modal } from '@mantine/core';
import React, { FC } from 'react';

import { OrderType } from '@portals/api/organizations';
import { ModalProps } from '@portals/framework';

import { PDFPreview } from './PDFPreview';

interface PriceQuoteProps {
  order: OrderType;
}

export const PriceQuotePreview: FC<ModalProps<PriceQuoteProps>> = ({
  closeMe,
  data,
}) => {
  const { order } = data;
  const { classes } = useStyles();

  return (
    <Modal
      opened
      size="sm"
      closeOnEscape={true}
      onClose={closeMe}
      padding={0}
      classNames={{
        root: classes.root,
        content: classes.content,
        header: classes.header,
      }}
    >
      <PDFPreview url={order.price_quote_file_url} onSubmit={closeMe} />
    </Modal>
  );
};

const useStyles = createStyles(() => ({
  root: {
    overflow: 'hidden',
  },
  content: {
    minWidth: 900,
    minHeight: 330,
    overflow: 'hidden',
  },
  header: {
    display: 'none',
  },
}));
