import {
  ActionIcon,
  Box,
  createStyles,
  Group,
  Stack,
  Text,
} from '@mantine/core';
import { motion } from 'framer-motion';
import React from 'react';

import { useConfig } from '@portals/api/organizations';
import { PartnerAvatar } from '@portals/framework';
import { ReactComponent as Back } from '@portals/icons/linear/arrow-left.svg';
import { ReactComponent as Export } from '@portals/icons/linear/import-3.svg';
import { useDownloadFile } from '@portals/utils';

interface SuccessCenterPartnerManualsProps {
  onClose: VoidFunction;
}

export function SuccessCenterPartnerManuals({
  onClose,
}: SuccessCenterPartnerManualsProps) {
  const { classes } = useStyles();

  const config = useConfig();

  const downloadFile = useDownloadFile();

  return (
    <Stack>
      <Box className={classes.header}>
        <ActionIcon onClick={onClose}>
          <Back />
        </ActionIcon>

        <Text color="gray.8" weight={600} ta="center">
          Manufacturer manuals
        </Text>
      </Box>

      <motion.div
        layout
        initial={{ x: -300, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 300, opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <Stack spacing="xs" className={classes.manufacturerManualsContainer}>
          {config.data?.organization.partner_manuals.map((partnerManual) => (
            <Group
              key={partnerManual.id}
              noWrap
              className={classes.manufacturerManual}
              onClick={() =>
                downloadFile.mutate({
                  fileUrl: partnerManual.manual_url,
                  fileName: partnerManual.file_name,
                })
              }
            >
              <PartnerAvatar
                partnerName={partnerManual.partner_display_name}
                src={partnerManual.partner_logo_url}
                radius="xl"
                className={classes.partnerLogo}
              />

              <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                <Text fz="xs" fw={500} truncate>
                  {partnerManual.partner_display_name}
                </Text>

                <Text c="gray.7" fz="xs" truncate data-testid="file-name">
                  {partnerManual.file_name}
                </Text>
              </Box>

              <span>
                <Export width={18} height={18} />
              </span>
            </Group>
          ))}
        </Stack>
      </motion.div>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  header: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    paddingTop: theme.spacing.sm,
    paddingBottom: theme.spacing.xs,
  },

  manufacturerManual: {
    cursor: 'pointer',
    border: `1px solid ${theme.colors.gray[3]}`,
    borderRadius: theme.radius.md,
    padding: theme.spacing.md,
    alignItems: 'center',
  },

  partnerLogo: {
    border: `1px solid ${theme.colors.gray[2]}`,
  },

  manufacturerManualsContainer: {
    overflow: 'auto',
    maxHeight: 300,
  },
}));
