import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError } from '@portals/redux/actions/toastr';

import { SUPPORT_API_URL } from './support.constants';
import { ServerError } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils';

export interface UseSendSupportEmailParams {
  title: string;
  message: string;
}

export function useSendInternalSupportEmail() {
  const dispatch = useDispatch();

  const endpointUrl = `${SUPPORT_API_URL}/internal`;

  const { url, options } = useRequestOptions({
    url: endpointUrl,
    method: 'POST',
  });

  return useMutation<void, ServerError, UseSendSupportEmailParams>({
    mutationFn: ({ title, message }) => {
      return fetchApiRequest(url, {
        ...options,
        body: JSON.stringify({ title, message }),
      });
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
  });
}

export function useSendPartnerSupportEmail() {
  const dispatch = useDispatch();

  const endpointUrl = `${SUPPORT_API_URL}/contact`;

  const { url, options } = useRequestOptions({
    url: endpointUrl,
    method: 'POST',
  });

  return useMutation<void, ServerError, UseSendSupportEmailParams>({
    mutationFn: ({ title, message }) => {
      return fetchApiRequest(url, {
        ...options,
        body: JSON.stringify({ title, message }),
      });
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
  });
}
