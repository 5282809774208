import { Box, Group, Image, Stack, Text } from '@mantine/core';
import React, { FC } from 'react';

import noSearchResultsSrc from '../../../assets/img/no-search-results.svg';

interface EmptyStateProps {
  searchValue: string;
}

export const EmptySearchState: FC<EmptyStateProps> = ({ searchValue }) => (
  <Box
    sx={{
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Stack sx={{ width: 400, height: 500 }}>
      <Image src={noSearchResultsSrc} mb={55} />

      <Text
        size="sm"
        weight={600}
        align="center"
        data-testid="no-results-found-text"
      >
        No results found
      </Text>

      <Stack spacing={0}>
        <Group spacing={5} position="center">
          <Text size="xs" color="dimmed" align="center">
            We didn't find any matches for
          </Text>
          <Text
            weight={600}
            size="xs"
            color="dark"
            align="center"
            sx={{
              wordBreak: 'break-word',
            }}
          >
            {searchValue === ''
              ? 'what you were looking for'
              : `"${searchValue}"`}
          </Text>
        </Group>
        <Text size="xs" color="dimmed" align="center">
          Try using different keywords
        </Text>
      </Stack>
    </Stack>
  </Box>
);

export const EmptyState = () => (
  <Box
    sx={{
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Stack sx={{ width: 400, height: 500 }}>
      <Image src={noSearchResultsSrc} mb={55} />

      <Text size="sm" weight={600} align="center">
        No product available
      </Text>
    </Stack>
  </Box>
);
