import { createStyles, CSSObject, px } from '@mantine/core';
import { MotionStyle } from 'framer-motion';
import React, { useEffect, useState } from 'react';

import { TicketSummaryType } from '@portals/api/organizations';
import {
  TableDetailsPanelRendererParams,
  TableDetailsPanelType,
} from '@portals/types';

import { TicketDetailsPanel } from './TicketDetailsPanel';

interface TicketsTableDetailsPanelProps {
  ticketId: string;
  showOpenDeviceButton?: boolean;
  detailsPanelType: TableDetailsPanelType['type'];
  onClose: TableDetailsPanelRendererParams<TicketSummaryType>['onClose'];
  tableRef: TableDetailsPanelRendererParams<TicketSummaryType>['tableRef'];
}

export function TicketsTableDetailsPanel({
  ticketId,
  showOpenDeviceButton = true,
  detailsPanelType,
  onClose,
  tableRef,
}: TicketsTableDetailsPanelProps) {
  const { classes, theme } = useStyles(detailsPanelType);

  const [chatWrapperStyles, setChatWrapperStyles] = useState<MotionStyle>();

  useEffect(
    function calcChatDimensions() {
      if (detailsPanelType === 'page') return;

      function resizeListener() {
        const tableRect = tableRef.current?.getBoundingClientRect?.();
        const detailsPanelWidth = tableRef.current?.getElementsByClassName?.(
          'table-details-panel-container'
        )?.[0]?.clientWidth;

        if (!tableRect || !detailsPanelWidth) return;

        setChatWrapperStyles({
          top: tableRect.y - px(theme.spacing.xl),
          left: tableRect.left - px(theme.spacing.xl),
          height: tableRect.height,
          // -1px for the details panel left border
          width: tableRect.width - detailsPanelWidth - 1,
        });
      }

      // Initial calculation
      resizeListener();

      window.addEventListener('resize', resizeListener);

      return () => {
        window.removeEventListener('resize', resizeListener);
      };
    },
    [detailsPanelType, tableRef, theme.spacing.xl]
  );

  return (
    <TicketDetailsPanel
      ticketId={ticketId}
      onClose={onClose}
      showOpenDeviceButton={showOpenDeviceButton}
      chatWrapperProps={{
        className: classes.chatWrapper,
        style: chatWrapperStyles,
      }}
    />
  );
}

const useStyles = createStyles(
  (
    theme,
    detailsPanelType: TicketsTableDetailsPanelProps['detailsPanelType']
  ) => {
    let commonChatWrapperCss: CSSObject = {
      position: 'fixed',
      backgroundColor: theme.white,
    };

    if (detailsPanelType === 'page') {
      commonChatWrapperCss = {
        ...commonChatWrapperCss,

        top: theme.other.navbarHeight,
        right: 400,
        width: 700,
        height: `calc(100vh - ${theme.other.navbarHeight}px)`,
        boxShadow: '6px 0px 41px 0px rgba(0, 0, 0, 0.13)',
        clipPath: 'inset(0 0 0 -41px)',
      };
    } else if (detailsPanelType === 'inner') {
      commonChatWrapperCss = {
        ...commonChatWrapperCss,

        border: `1px solid ${theme.colors.gray[3]}`,
      };
    }

    return {
      chatWrapper: commonChatWrapperCss,
    };
  }
);
