import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';
import { OrganizationReportSettingsType } from '@portals/types';

import {
  CONFIGURATION_API_URL,
  configurationQueryKeys,
} from './configuration.constants';
import { ServerError } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils';

interface UpdateReportsSettingsParams {
  report_settings: OrganizationReportSettingsType;
}

export function useUpdateReportsSettings() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: `${CONFIGURATION_API_URL}/reports`,
    method: 'POST',
  });

  return useMutation<void, ServerError, UpdateReportsSettingsParams>({
    mutationFn: (params) => {
      return fetchApiRequest(url, {
        ...options,
        body: JSON.stringify(params),
      });
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Reports settings updated successfully'));

      queryClient.invalidateQueries(configurationQueryKeys.config);
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useUpdateReportsSettings',
      baseUrl: `${CONFIGURATION_API_URL}/reports`,
      method: 'POST',
    },
  });
}
