import { Paper, Stack } from '@mantine/core';
import { noop } from 'lodash/fp';
import React, { useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { PaginatedFilterTypeEnum } from '@portals/types';

import { ListWithActions } from './ListWithActions';
import { useOverviewContext } from '../../../overview.context';
import { useCurrentSpace } from '../../../overview.hooks';

// Copy-pasted from Partner's "Support Overview" dashboard.
// In the future, should be moved to a common component under @portals/framework, and used both
// here & in Partner's dashboard.
export function IncidentsWidget({ closeModal = noop }) {
  const space = useCurrentSpace();
  const overviewContext = useOverviewContext();

  const [incidentsParams, setIncidentsParams] = useState({
    sorting: [{ id: 'created_at', desc: false }],
    pagination: {
      page: 0,
      pageSize: 10,
    },
    filters: [
      {
        id: 'status',
        value: 'active',
        type: PaginatedFilterTypeEnum.Eq,
      },
      {
        id: 'space_tree_path_name',
        value: space.tree_path_name,
        type: PaginatedFilterTypeEnum.Contains,
      },
      ...(overviewContext.isLocalDataLevel
        ? [
            {
              id: 'device_space_id',
              value: space.id,
              type: PaginatedFilterTypeEnum.Eq,
            },
          ]
        : []),
    ],
  });
  const [incidentsSortBy, setIncidentsSortBy] = useState('created_at');

  const onSortByChanged = (sortBy: string) => {
    setIncidentsSortBy(sortBy);

    setIncidentsParams({
      ...incidentsParams,
      sorting: [{ id: sortBy, desc: sortBy === 'created_at' }],
    });
  };

  useUpdateEffect(
    function updateOnSpaceChange() {
      if (space.tree_path_name || space.id) {
        setIncidentsParams({
          ...incidentsParams,
          filters: [
            {
              id: 'status',
              value: 'active',
              type: PaginatedFilterTypeEnum.Eq,
            },
            {
              id: 'space_tree_path_name',
              value: space.tree_path_name,
              type: PaginatedFilterTypeEnum.Contains,
            },
            ...(overviewContext.isLocalDataLevel
              ? [
                  {
                    id: 'device_space_id',
                    value: space.id,
                    type: PaginatedFilterTypeEnum.Eq,
                  },
                ]
              : []),
          ],
        });
      }
    },
    [space.tree_path_name, space.id, overviewContext.isLocalDataLevel]
  );

  const numOfIncidents =
    (overviewContext.isLocalDataLevel
      ? space?.state?.local_incidents?.total
      : space?.state?.incidents?.total) || 0;

  return (
    <Paper p={32} radius={20}>
      <Stack
        sx={{
          height: '100%',
          pointerEvents: overviewContext.isDragging ? 'none' : 'auto',
        }}
      >
        <ListWithActions
          numOfIncidents={numOfIncidents}
          showSortBySeverity
          viewAllLink={`/incidents?q[space_tree_path_name_i_cont]=${encodeURIComponent(
            space.tree_path_name
          )}&q[status_eq]=active`}
          onSortByChanged={onSortByChanged}
          selectedItem={incidentsSortBy}
          itemsParams={incidentsParams}
          itemsKey="incidents"
          endpointUrl="ui/organization/incidents"
          emptyStateLabel="No active incidents"
          closeModal={closeModal}
        />
      </Stack>
    </Paper>
  );
}
