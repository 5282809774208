import { UserPermissionNames } from './common';

export enum AccessLevelEnum {
  None,
  View,
  Edit,
  Admin,
  Disabled, // Can be used to disable features for everyone
}

export type UserPermissionsCallback = (
  permissionKeys: UserPermissionNames | Array<UserPermissionNames>
) => boolean;
