import { isNil } from 'lodash';
import { any, filter, findIndex, isNumber, map } from 'lodash/fp';

import { PostPurchaseParametersType } from '@portals/api/organizations';

export function getIsMissingRequiredFields(
  postPurchaseParams: PostPurchaseParametersType
) {
  const hasRequiredFields = filter(
    { required: true },
    postPurchaseParams.post_purchase_parameters.definition
  );

  if (!hasRequiredFields.length) return null;

  const requiredFieldsKeys = map('label', hasRequiredFields);

  return any((key) => {
    const value =
      postPurchaseParams.post_purchase_parameters.values?.[key]?.value;

    return !value && !isNumber(value);
  }, requiredFieldsKeys);
}

export function getProductPPPStatus(
  postPurchaseParams: PostPurchaseParametersType
) {
  const isMissingRequiredFields =
    getIsMissingRequiredFields(postPurchaseParams);

  if (isMissingRequiredFields) {
    return {
      label: 'Missing required information',
      indicator: 'red_accent.4',
    };
  }

  return {
    label: 'All set',
    indicator: 'teal_accent.4',
  };
}

export function getWasEdited(postPurchaseParams: PostPurchaseParametersType) {
  return postPurchaseParams.post_purchase_parameters.values !== null;
}

export function getAutoFocusIndex(
  postPurchaseParams: PostPurchaseParametersType
) {
  const isMissingRequiredFields =
    getIsMissingRequiredFields(postPurchaseParams);

  if (!isMissingRequiredFields) return 0;

  const firstMissingFieldIndex = findIndex((field) => {
    if (!field.required) return false;

    const value =
      postPurchaseParams.post_purchase_parameters.values?.[field.label]?.value;

    return !value && !isNumber(value);
  }, postPurchaseParams.post_purchase_parameters.definition);

  return isNil(firstMissingFieldIndex) ? 0 : firstMissingFieldIndex;
}
