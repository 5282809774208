import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import dayjs from 'dayjs';
import moment from 'moment';

import { PaginationResponse, UsePaginatedTableApiQuery } from '@portals/types';

import {
  DEVICES_API_URL,
  devicesQueryKeys,
  getDeviceEventsApiUrl,
} from './devices.constants';
import { DeviceEventType, WidgetDataRangeEnum } from './devices.types';
import { ServerError } from '../../types';
import {
  fetchApiRequest,
  usePaginatedTableApiQuery,
  useRequestOptions,
} from '../../utils';
import { globalQueryKeys } from '../global-query-keys';

interface UseDeviceEventsParams {
  deviceId: string;
  widgetDataRange: WidgetDataRangeEnum;
  eventNames: string[];
  queryOptions?: UseQueryOptions<
    PaginationResponse<DeviceEventType>,
    ServerError,
    Array<DeviceEventType>
  >;
}

function getDateRange(widgetDataRange: WidgetDataRangeEnum) {
  if (widgetDataRange === WidgetDataRangeEnum.Live) {
    return {
      from: '',
      to: '',
    };
  }

  if (widgetDataRange === WidgetDataRangeEnum.Day) {
    return {
      from: moment().subtract(24, 'hours').toDate().toISOString(),
      to: moment().toDate().toISOString(),
    };
  }

  if (widgetDataRange === WidgetDataRangeEnum.MonthToDate) {
    return {
      from: dayjs().startOf('month').toISOString(),
      to: dayjs().endOf('month').toISOString(),
    };
  }

  return {
    from: moment()
      .subtract(1, widgetDataRange)
      .startOf('day')
      .toDate()
      .toISOString(),
    to: moment().endOf('day').toDate().toISOString(),
  };
}

export function useDeviceEventsByRange({
  deviceId,
  widgetDataRange,
  eventNames,
  queryOptions = {},
}: UseDeviceEventsParams) {
  const { url, options } = useRequestOptions({
    url: getDeviceEventsApiUrl(deviceId),
  });

  return useQuery<
    PaginationResponse<DeviceEventType>,
    ServerError,
    Array<DeviceEventType>
  >({
    queryKey: [
      ...devicesQueryKeys.events(deviceId),
      widgetDataRange,
      'eventNames',
      ...eventNames,
    ],
    queryFn: () => {
      const dateRange = getDateRange(widgetDataRange);
      const finalUrl = new URL(url);

      finalUrl.searchParams.set('from', dateRange.from);
      finalUrl.searchParams.set('to', dateRange.to);
      finalUrl.searchParams.set('populate_missing_data_points', 'true');

      eventNames.forEach((eventName) => {
        finalUrl.searchParams.append('q[name_in][]', eventName);
      });

      return fetchApiRequest(finalUrl.toString(), options);
    },
    select: (response) => response.data,
    meta: {
      method: 'GET',
      baseUrl: getDeviceEventsApiUrl(':id'),
    },
    ...queryOptions,
  });
}

export function useDeviceEvents(
  tableState: UsePaginatedTableApiQuery<DeviceEventType>['tableState'],
  columns: UsePaginatedTableApiQuery<DeviceEventType>['columns'],
  baseUrl: string = DEVICES_API_URL,
  queryKey: Array<string>
) {
  return usePaginatedTableApiQuery<DeviceEventType>({
    baseUrl,
    queryKey: queryKey
      ? [...queryKey, baseUrl, tableState]
      : [...globalQueryKeys.incidents, baseUrl, tableState],
    tableState,
    columns,
    queryOptions: {
      cacheTime: 0,
    },
  });
}
