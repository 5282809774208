import { isObject } from 'lodash/fp';
import React from 'react';

import { DeviceType } from '@portals/api/organizations';
import { DeviceType as CommonDeviceType } from '@portals/types';

import JsonSchemaForm from './index';

interface WithSchemaProps {
  device: DeviceType | CommonDeviceType;
  updateConfig: any;
}

export function WithSchema({ device, updateConfig }: WithSchemaProps) {
  try {
    return (
      <JsonSchemaForm
        schema={
          isObject(device.config_schema)
            ? device.config_schema
            : JSON.parse(device.config_schema)
        }
        isReadOnly={device.is_config_schema_readonly}
        data={device.config || {}}
        onSubmit={(data) => updateConfig(device.id, JSON.stringify(data))}
      />
    );
  } catch (error) {
    return (
      <span className="pl-2 font-size-lg text-danger">
        Error: Invalid schema
      </span>
    );
  }
}
