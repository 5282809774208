import { Box, createStyles, Group, Text } from '@mantine/core';
import React, { forwardRef } from 'react';

import { PartnerAvatar } from '@portals/framework';

interface BrandSearchResultProps {
  name: string;
  logoUrl?: string | null;
  isHovered: boolean;
  handleSelected: () => void;
}

export const BrandSearchResult = forwardRef<
  HTMLDivElement,
  BrandSearchResultProps
>(({ name, logoUrl, isHovered, handleSelected }, ref) => {
  const { classes, cx } = useStyles();

  return (
    <Box
      ref={ref}
      className={cx({
        [classes.hoveredItem]: isHovered,
      })}
      onClick={handleSelected}
    >
      <Group p="xs" className={classes.container}>
        <PartnerAvatar src={logoUrl} partnerName={name} size={46} />

        <Text weight={500}>{name}</Text>
      </Group>
    </Box>
  );
});
const useStyles = createStyles((theme) => ({
  container: {
    borderRadius: theme.radius.md,

    '&:hover': {
      backgroundColor: theme.colors.gray[1],
    },
  },
  hoveredItem: {
    borderColor: theme.colors.gray[5],
    backgroundColor: theme.colors.gray[2],
  },
}));
