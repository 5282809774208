import {
  ActionIcon,
  Box,
  createStyles,
  Flex,
  Group,
  Input,
  Select,
  Stack,
  TextInput,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { Identifier } from 'dnd-core';
import { find, map, sortBy } from 'lodash/fp';
import React, { MutableRefObject, useMemo } from 'react';
import { useUpdateEffect } from 'react-use';

import { ReactComponent as CloseX } from '@portals/icons/linear/close-x.svg';
import { ReactComponent as Drag } from '@portals/icons/linear/drag.svg';
import { ReactComponent as InfoCircle } from '@portals/icons/linear/info-circle.svg';
import { ReactComponent as QuestionCircle } from '@portals/icons/linear/question-circle.svg';
import { FieldTypeEnum, SupportedCommandType } from '@portals/types';

import {
  InputLabelWithTooltip,
  SelectItemComponent,
} from '../../../common/input-helpers';
import { PopoverIconSelector } from '../../../common/PopoverIconSelector';
import {
  GroupedSwitchCommandType,
  GroupedSwitchesWidgetFormType,
} from '../grouped-switches.types';

export interface GroupedSwitchFormProps {
  commands: SupportedCommandType[] | undefined;
  commandField: GroupedSwitchCommandType;
  index: number;
  form: UseFormReturnType<GroupedSwitchesWidgetFormType>;
  handlerId: Identifier | null;
  innerRef: MutableRefObject<HTMLDivElement>;
}

export function GroupedSwitchForm({
  commands,
  commandField,
  index,
  form,
  handlerId,
  innerRef,
}: GroupedSwitchFormProps) {
  const numOfRows = form.values.commands.length;
  const { classes } = useStyles({
    numOfRows: numOfRows || 0,
  });

  const commandsOptions = useMemo(() => {
    const options = map((command) => {
      const hasBooleanFields = Boolean(
        find({ type: FieldTypeEnum.Checkbox }, command.custom_fields)
      );

      return {
        value: command.name,
        label: command.friendly_name,
        disabled: !hasBooleanFields,
      };
    }, commands);

    return sortBy('disabled', options);
  }, [commands]);

  const paramsOptions = useMemo(() => {
    if (!commandField.command_name) {
      return [];
    }

    const command = commands?.find(
      (command) => command.name === commandField.command_name
    );

    if (!command || !command.custom_fields) return [];

    const options = map((param) => {
      return {
        value: param.name,
        label: param.name,
        disabled: param.type !== FieldTypeEnum.Checkbox,
      };
    }, command?.custom_fields);

    return sortBy('disabled', options);
  }, [commandField, commands]);

  useUpdateEffect(() => {
    form.setFieldValue(`commands.${index}.command_param_key`, null);
  }, [commandField.command_name]);

  return (
    <Box
      className={classes.commandWrapper}
      w="100%"
      data-handler-id={handlerId}
      ref={innerRef}
      p={form.values.commands.length > 1 ? 'sm' : 0}
    >
      {form.values.commands.length > 1 ? (
        <Flex h="100%" align="center" justify="center">
          <Box className={classes.dragWrapper}>
            <Drag />
          </Box>
        </Flex>
      ) : null}
      <Stack w="100%">
        <Group noWrap align="center">
          <Input.Wrapper label="Icon" required sx={{ flex: 0 }}>
            <PopoverIconSelector
              selectedIconName={commandField.icon_name}
              onChange={(iconName) =>
                form.setFieldValue(`commands.${index}.icon_name`, iconName)
              }
              color={form.values.color}
            />
          </Input.Wrapper>

          <TextInput
            w="100%"
            {...form.getInputProps(`commands.${index}.label`)}
            data-testid="command-title-input"
            label={form.values.display_title ? 'Title' : 'Title (optional)'}
            required={form.values.display_title}
          />
        </Group>

        <TextInput
          w="100%"
          {...form.getInputProps(`commands.${index}.telemetry`)}
          withAsterisk={false}
          data-testid="group-switches-boolean-telemetry-input"
          label={
            <InputLabelWithTooltip
              data-testid="boolean-telemetry-key-input"
              label="Boolean Telemetry Key"
              Icon={QuestionCircle}
              tooltipLabel="The key to obtain boolean telemetry data used to determine the toggle's state"
            />
          }
          required
        />

        <Group grow>
          <Select
            data={commandsOptions}
            searchable
            clearable
            required
            withinPortal
            labelProps={{
              w: '100%',
            }}
            withAsterisk={false}
            data-testid="toggle-command-selection"
            label={
              <InputLabelWithTooltip
                label="Toggle Command"
                Icon={QuestionCircle}
                tooltipLabel="The command sent to the device when the widget is toggled"
              />
            }
            {...form.getInputProps(`commands.${index}.command_name`)}
            itemComponent={(props) => (
              <SelectItemComponent
                {...props}
                Icon={InfoCircle}
                disabledLabel="Command must have a boolean field"
              />
            )}
          />

          <Select
            data={paramsOptions}
            searchable
            clearable
            required
            withinPortal
            disabled={!commandField.command_name}
            placeholder={
              !commandField.command_name
                ? 'Select command'
                : 'Select command param'
            }
            withAsterisk={false}
            {...form.getInputProps(`commands.${index}.command_param_key`)}
            data-testid="boolean-parameter-selection"
            label={
              <InputLabelWithTooltip
                label="Boolean Parameter"
                Icon={QuestionCircle}
                tooltipLabel="The key of the boolean parameter sent with the toggle command"
              />
            }
            itemComponent={(props) => (
              <SelectItemComponent
                {...props}
                Icon={InfoCircle}
                disabledLabel="Only boolean paramaters are supported"
              />
            )}
          />
        </Group>
      </Stack>
      {form.values.commands.length > 1 ? (
        <Flex h="100%" align="center" justify="center">
          <ActionIcon
            size="xs"
            color="gray.4"
            onClick={() => form.removeListItem('commands', index)}
          >
            <CloseX />
          </ActionIcon>
        </Flex>
      ) : null}
    </Box>
  );
}

const useStyles = createStyles(
  (theme, { numOfRows }: { numOfRows: number }) => ({
    commandWrapper: {
      display: 'grid',
      gridTemplateColumns:
        numOfRows > 1 ? 'min-content 1fr min-content' : '1fr',
      radius: theme.radius.md,
      gap: theme.spacing.md,
      border: numOfRows > 1 ? `1px solid ${theme.colors.gray[2]}` : 'none',
    },
    dragWrapper: {
      transform: 'rotate(90deg)',
      color: theme.colors.gray[4],
      cursor: 'grab',

      '&:active': {
        cursor: 'grabbing',
      },
    },
  })
);
