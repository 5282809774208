import {
  Box,
  createStyles,
  Group,
  Stack,
  StackProps,
  Text,
} from '@mantine/core';
import React from 'react';

import { OfflineDeviceStateTooltip } from '../../common/OfflineDeviceStateTooltip';

export interface StatusWidgetProps {
  title: string;
  color: string;
  value: string;

  stackProps?: StackProps;

  isDeviceOffline?: boolean;
  lastUpdateTimestamp?: string;
}

export function StatusWidget({
  title,
  color,
  value,

  stackProps = {},

  isDeviceOffline,
  lastUpdateTimestamp,
}: StatusWidgetProps) {
  const { classes } = useStyles();

  return (
    <Stack
      className={classes.container}
      p="xl"
      h="100%"
      w="100%"
      bg="white"
      spacing={4}
      justify="center"
      {...stackProps}
    >
      <Group align="center" spacing="sm" noWrap>
        <Text
          size="md"
          data-testid="dashboard-status-widget-name"
          color="gray.5"
          truncate
        >
          {title}
        </Text>

        {isDeviceOffline ? (
          <OfflineDeviceStateTooltip
            lastUpdateTimestamp={lastUpdateTimestamp}
          />
        ) : null}
      </Group>

      <Group align="center" spacing="md" noWrap>
        <Box>
          <Box w={12} h={12} bg={color} sx={{ borderRadius: '50%' }} />
        </Box>

        <Text
          size={28}
          data-testid="preview-status-widget-name"
          color="gray.8"
          truncate
        >
          {value}
        </Text>
      </Group>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    borderRadius: theme.radius.lg,
  },
}));
