import { Box, createStyles, Stack } from '@mantine/core';
import { isEmpty } from 'lodash/fp';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useShop } from '@portals/api/organizations';
import { SearchInput } from '@portals/core';
import { ProductCard } from '@portals/framework';

import { useShopCurrency } from '../../../redux/hooks/store';
import { EmptySearchState, EmptyState } from '../store/EmptyState';
import { useFilteredAndSortedStoreListings } from '../store/hooks';
import { PricePreview } from '../store/price-preview/PricePreview';
import { ProductCategories } from '../store/ProductCategories';
import { SortByEnum } from '../store/SortBy';

interface ShopProductsListProps {
  sortBy: SortByEnum;
}

export function ExternalStoreProductsList({ sortBy }: ShopProductsListProps) {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const shopCurrency = useShopCurrency();
  const [searchValue, setSearchValue] = useState('');

  const [selectedCategory, setSelectedCategory] = useState('');
  const shop = useShop({ sortByPosition: true });

  const filteredStoreListings = useMemo(
    () =>
      shop.data?.products.filter(
        (storeListing) => storeListing.product.pricing_model !== 'personalized'
      ),
    [shop.data?.products]
  );

  const { sortedStoreListings, storeListingsFilteredBySearchTerm } =
    useFilteredAndSortedStoreListings({
      storeListings: filteredStoreListings,
      searchValue,
      sortBy,
      category: selectedCategory,
    });

  const content = useMemo(() => {
    if (!shop.isFetched) return null;

    if (isEmpty(shop.data?.products)) {
      return <EmptyState />;
    } else if (isEmpty(sortedStoreListings)) {
      return <EmptySearchState searchValue={searchValue} />;
    }

    return (
      <Box className={classes.scrollWrapper}>
        {sortedStoreListings.map((storeListing) => (
          <ProductCard
            key={storeListing.id}
            className={classes.productCard}
            onClick={() => navigate(`/store/${storeListing.slug}`)}
          >
            <ProductCard.Image
              imageUrl={storeListing.product.image_url}
              category={storeListing.product.category}
            />

            <ProductCard.Details
              name={storeListing.product.name}
              subtitle={storeListing.product.subtitle}
            >
              <PricePreview
                storeListing={storeListing}
                currencyCode={shopCurrency.selected}
              />
            </ProductCard.Details>
          </ProductCard>
        ))}
      </Box>
    );
  }, [
    classes.scrollWrapper,
    classes.productCard,
    shop.data?.products,
    shop.isFetched,
    shopCurrency.selected,
    navigate,
    searchValue,
    sortedStoreListings,
  ]);

  return (
    <Box className={classes.content}>
      <Stack className={classes.sidebar}>
        <SearchInput
          onClear={() => setSearchValue('')}
          onChange={(event) => setSearchValue(event.target.value)}
          value={searchValue}
          placeholder="Search products..."
          size="md"
          w="100%"
        />

        <ProductCategories
          storeListings={storeListingsFilteredBySearchTerm}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
      </Stack>

      <div className={classes.productCardsWrapper}>{content}</div>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  content: {
    flexGrow: 1,
    display: 'grid',
    gridTemplateColumns: '330px 1fr',
  },
  sidebar: {
    borderRight: `1px solid ${theme.colors.blue_gray[0]}`,
    padding: 30,
    background: theme.white,
  },
  productCardsWrapper: {
    overflowX: 'hidden',
  },
  scrollWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    gap: theme.spacing.xl,
    maxWidth: 1200,
    margin: '0 auto',
    padding: theme.spacing.xl,
  },
  productCard: {
    cursor: 'pointer',
    transition: 'all 0.15s ease-in-out',

    '&:hover': {
      boxShadow: theme.shadows.xl,
      transform: 'scale(1.005)',
    },
  },
}));
