import { useMemo } from 'react';

import { C2C_INTEGRATIONS, C2CIntegrationItem } from '@portals/framework';
import { useIntegrations } from '@portals/redux';

type Integrations = ReturnType<typeof useIntegrations>;
type IntegrationsWithIds = ReturnType<typeof getActiveIntegrationsWithIds>;

export interface OrgC2CIntegrationItem extends C2CIntegrationItem {
  href?: string;
  isComingSoon?: boolean;
}

function getActiveIntegrationsWithIds(integrations: Integrations) {
  const integrationsWithIds = Object.entries(integrations).map(
    ([id, integration]) => ({
      id,
      ...integration,
    })
  );

  return integrationsWithIds.filter(
    (integration) => integration.state === 'active'
  );
}

function filterActiveIntegrationsList(
  integrationsList: OrgC2CIntegrationItem[],
  activeIntegrations: IntegrationsWithIds
) {
  return integrationsList.filter((integration) =>
    activeIntegrations.some(
      (activeIntegration) => activeIntegration.id === integration.id
    )
  );
}

export function useIntegrationsList(): Array<OrgC2CIntegrationItem> {
  return useMemo(
    () => [
      {
        ...C2C_INTEGRATIONS.mtr,
        href: '/settings/integrations/uc/mtr',
      },
      {
        ...C2C_INTEGRATIONS.zoom,
        href: '/settings/integrations/uc/zoom',
      },
      {
        ...C2C_INTEGRATIONS.xio,
        href: '/settings/integrations/monitoring/xio',
      },
      {
        ...C2C_INTEGRATIONS.q_sys,
        href: '/settings/integrations/monitoring/q_sys',
      },
      {
        ...C2C_INTEGRATIONS.bright_sign,
        href: '/settings/integrations/signage/bright_sign',
      },
      {
        ...C2C_INTEGRATIONS.webex,
        isComingSoon: true,
      },
      {
        ...C2C_INTEGRATIONS.poly,
        isComingSoon: true,
      },
      {
        ...C2C_INTEGRATIONS.neat,
        isComingSoon: true,
      },
      {
        ...C2C_INTEGRATIONS.novastar,
        isComingSoon: true,
      },
      {
        ...C2C_INTEGRATIONS.yale,
        isComingSoon: true,
      },
      {
        ...C2C_INTEGRATIONS['2n'],
        isComingSoon: true,
      },
      {
        ...C2C_INTEGRATIONS.honeywell,
        isComingSoon: true,
      },
      {
        ...C2C_INTEGRATIONS.lenel,
        isComingSoon: true,
      },
      {
        ...C2C_INTEGRATIONS.assa,
        isComingSoon: true,
      },
      {
        ...C2C_INTEGRATIONS.visionline,
        isComingSoon: true,
      },
      {
        ...C2C_INTEGRATIONS.avigilon,
        isComingSoon: true,
      },
      {
        ...C2C_INTEGRATIONS.brivo,
        isComingSoon: true,
      },
      {
        ...C2C_INTEGRATIONS.controlbyweb,
        isComingSoon: true,
      },
      {
        ...C2C_INTEGRATIONS['4suites'],
        isComingSoon: true,
      },
      {
        ...C2C_INTEGRATIONS.pti,
        isComingSoon: true,
      },
      {
        ...C2C_INTEGRATIONS.salto,
        isComingSoon: true,
      },
      {
        ...C2C_INTEGRATIONS.domotz,
        isComingSoon: true,
      },
      {
        ...C2C_INTEGRATIONS.biamp,
        isComingSoon: true,
      },
      {
        ...C2C_INTEGRATIONS.sonos,
        isComingSoon: true,
      },
      {
        ...C2C_INTEGRATIONS.viewsonic,
        isComingSoon: true,
      },
      {
        ...C2C_INTEGRATIONS.yaelink,
        isComingSoon: true,
      },
    ],
    []
  );
}

export function useActiveCloudIntegrations() {
  const integrations = useIntegrations();
  const integrationsList = useIntegrationsList();

  const activeIntegrations = useMemo(() => {
    return getActiveIntegrationsWithIds(integrations);
  }, [integrations]);

  return useMemo(
    () => filterActiveIntegrationsList(integrationsList, activeIntegrations),
    [integrationsList, activeIntegrations]
  );
}
