import { getStylesRef, Select, SelectItem, SelectProps } from '@mantine/core';
import React from 'react';

import { ReactComponent as SearchNormal } from '@portals/icons/linear/search-normal 2.svg';

import { SettingPage } from './settings-page.types';

interface SettingsPageSelectFieldProps
  extends Pick<SelectProps, 'onChange' | 'onSearchChange'> {
  pages: SettingPage[];
}

export function SettingsPageSelectField({
  pages,
  onChange,
  onSearchChange,
}: SettingsPageSelectFieldProps) {
  return (
    <Select
      maw={300}
      size="md"
      searchable
      clearable
      hoverOnSearchChange
      onChange={onChange}
      placeholder="Search settings..."
      rightSection={<SearchNormal />}
      styles={selectStyles}
      onSearchChange={onSearchChange}
      data={composeSelectOptions(pages)}
      filter={(value, item: SelectItem) => {
        const lowerValue = value.toLowerCase();

        return (
          item.label?.toLowerCase().includes(lowerValue) ||
          item.description.toLowerCase().includes(lowerValue)
        );
      }}
    />
  );
}

const selectStyles: SelectProps['styles'] = (theme) => ({
  rightSection: {
    ref: getStylesRef('rightSection'),
    color: theme.colors.gray[6],

    svg: {
      width: 20,
      height: 20,
    },
  },
  input: {
    [`:focus + .${getStylesRef('rightSection')}`]: {
      color: theme.fn.primaryColor(),
    },
  },
});

function composeSelectOptions(pages: SettingPage[]) {
  return pages
    .map((page) => ({
      value: page.path,
      label: page.title,
      description: page.description,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
}
