import { Group, Text, useMantineTheme, Tooltip } from '@mantine/core';
import React from 'react';
import {
  Cell,
  Label,
  Legend,
  Pie,
  PieChart,
  Tooltip as RechartsTooltip,
} from 'recharts';

import { ChartTooltip } from './ChartTooltip';

export interface ChartItemType {
  name: string;
  value: number;
  percentage: number;
}

interface DeviceModelBreakdownChartProps {
  chartDataItems: ChartItemType[];
  totalDevicesCount: number;
}

export function DeviceModelBreakdownChart({
  chartDataItems,
  totalDevicesCount,
}: DeviceModelBreakdownChartProps) {
  const theme = useMantineTheme();

  const COLORS = [
    theme.colors.teal_accent[4],
    theme.colors.pink_accent[2],
    theme.colors.primary[4],
    theme.colors.orange[4],
    theme.colors.amber[4],
    theme.colors.purple_accent[2],
    theme.colors.gray[4],
  ];

  const legendItemFormatter = (value: string, entry: any) => {
    const { payload } = entry;

    return (
      <Group
        spacing={2}
        display="inline-flex"
        ml="xs"
        noWrap
        maw="80%"
        data-testid="legend-item-group"
      >
        <Tooltip label={value}>
          <Text
            size="xs"
            color="blue_gray.9"
            data-testid="legend-item-name"
            truncate
          >
            {value}
          </Text>
        </Tooltip>
        <Text size="xs" color="gray.5" data-testid="legend-item-value">
          ({payload.percentage}%)
        </Text>
      </Group>
    );
  };

  return (
    <PieChart width={400} height={200}>
      <Pie
        data={chartDataItems}
        innerRadius={50}
        outerRadius={90}
        paddingAngle={1}
        dataKey="value"
      >
        <Label
          position="center"
          value={totalDevicesCount}
          fontSize={24}
          color={theme.colors.gray[6]}
        />

        {chartDataItems.map((_, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>

      <RechartsTooltip
        content={<ChartTooltip totalDevicesCount={totalDevicesCount} />}
      />

      <Legend
        layout="vertical"
        align="right"
        verticalAlign="middle"
        iconType="circle"
        iconSize={10}
        wrapperStyle={{ right: 10 }}
        width={150}
        formatter={legendItemFormatter}
      />
    </PieChart>
  );
}
