import { createStyles } from '@mantine/core';
import { Identifier } from 'dnd-core';
import { motion } from 'framer-motion';
import React, { PropsWithChildren, useEffect, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

interface SortableDndListProps {
  dndTypeIdentifier: Identifier;
  itemIndex: number;
  itemUniqueId: string | number;
  setCursorToGrabWhenHover?: boolean;
  onMove: (dragIndex: number, hoverIndex: number) => void;
  handlerClassName?: string;
  isPreview?: boolean;
}
export function SortableDndItem({
  dndTypeIdentifier,
  itemIndex,
  itemUniqueId,
  setCursorToGrabWhenHover = true,
  onMove,
  children,
  handlerClassName,
  isPreview,
}: PropsWithChildren<SortableDndListProps>) {
  const ref = useRef<HTMLDivElement>(null);

  const [{ isDragging }, drag, preview] = useDrag({
    type: dndTypeIdentifier,
    item: () => ({
      index: itemIndex,
      id: itemUniqueId,
      getSize: () => ({
        width: ref.current?.getBoundingClientRect().width ?? 0,
        height: ref.current?.getBoundingClientRect().height ?? 0,
      }),
      count: () => ref.current?.parentElement?.children?.length ?? 0,
    }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging() && monitor.getItem().id === itemUniqueId,
    }),
  });

  const { classes, cx } = useStyles({
    isDragging,
  });

  const [{ handlerId }, drop] = useDrop<
    { index: number; id: string },
    void,
    { handlerId: Identifier | null }
  >({
    accept: dndTypeIdentifier,
    collect: (monitor) => {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },

    hover: (item, monitor) => {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = itemIndex;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const dragElement = document.getElementById(monitor.getItem().id);
      const dragBoundingRect = dragElement?.getBoundingClientRect();

      // Get vertical middle
      // Get vertical middle
      // get hovered image vertical middle position
      // only perform the move when the mouse has crossed half of the items height/width
      // are we dragging right or left?
      const dragRight = dragIndex === hoverIndex - 1;
      const dragLeft = dragIndex === hoverIndex + 1;

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) * 0.25;

      // get hovered image horizontal middle position
      const hoverMiddleX = dragRight
        ? (hoverBoundingRect.right - hoverBoundingRect.left) * 0.1
        : (hoverBoundingRect.right - hoverBoundingRect.left) * 0.9;

      // determine mouse position
      const clientOffset = monitor.getClientOffset();

      const clientOffsetY = clientOffset?.y ?? 0;
      const clientOffsetX = clientOffset?.x ?? 0;
      // get pixels to the top
      const hoverClientY = clientOffsetY - hoverBoundingRect.top;

      // get pixels to the left
      const hoverClientX = clientOffsetX - hoverBoundingRect.left;

      // are we dragging up or down?
      const dragBoundRectTop = dragBoundingRect?.top ?? 0;
      const dragBoundRectBottom = dragBoundingRect?.bottom ?? 0;

      const dragUp = dragBoundRectTop > hoverBoundingRect.top;
      const dragDown = dragBoundRectBottom < hoverBoundingRect.bottom;

      if (dragRight && dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return;
      }

      if (dragLeft && dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return;
      }

      if (dragUp && dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      if (dragDown && dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      onMove(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  drag(drop(ref));

  useEffect(() => {
    if (isPreview) {
      preview(getEmptyImage(), { captureDraggingState: true });
    }
  }, [preview, isPreview]);

  return (
    <motion.div
      transition={{ duration: 0.01 }}
      className={cx({ [classes.cursorGrab]: setCursorToGrabWhenHover })}
    >
      <div ref={ref} data-handler-id={handlerId} className={handlerClassName}>
        {children}
      </div>
    </motion.div>
  );
}

const useStyles = createStyles((theme, params: { isDragging: boolean }) => ({
  cursorGrab: {
    cursor: params.isDragging ? 'grabbing' : 'grab',
    backgroundColor: params.isDragging ? theme.colors.gray[2] : 'none',
  },
}));
