import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';

import {
  AUTHORIZED_ACCESS_API_URL,
  authorizedAccessQueryKeys,
} from './authorized-access.constants';
import { AuthorizedAccessResponseType } from './authorized-access.types';
import { useApiQuery } from '../../hooks';
import { ServerError } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils';

export function useAuthorizedAccess() {
  return useApiQuery<AuthorizedAccessResponseType>(
    AUTHORIZED_ACCESS_API_URL,
    authorizedAccessQueryKeys.all,
    { staleTime: 0 }
  );
}

interface UseUpdateAuthorizedAccessParams {
  /** userId / groupId */
  id: string;
  notes: string;
}

export function useUpdateAuthorizedAccessNotes() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: AUTHORIZED_ACCESS_API_URL,
    method: 'PUT',
  });

  return useMutation<void, ServerError, UseUpdateAuthorizedAccessParams>({
    mutationFn: ({ id, notes }) => {
      return fetchApiRequest(`${url}/${id}`, {
        ...options,
        body: JSON.stringify({ notes }),
      });
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Notes updated successfully'));

      queryClient.invalidateQueries(authorizedAccessQueryKeys.all);
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useUpdateAuthorizedAccessNotes',
      baseUrl: `${AUTHORIZED_ACCESS_API_URL}/:id`,
      method: 'PUT',
    },
  });
}

export function useRevokeAuthorizedAccess() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: AUTHORIZED_ACCESS_API_URL,
    method: 'DELETE',
  });

  return useMutation<void, ServerError, { id: string }>({
    mutationFn: ({ id }) => {
      return fetchApiRequest(`${url}/${id}`, options);
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Authorized access revoked successfully'));

      queryClient.invalidateQueries(authorizedAccessQueryKeys.all);
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useRevokeAuthorizedAccess',
      baseUrl: `${AUTHORIZED_ACCESS_API_URL}/:id`,
      method: 'DELETE',
    },
  });
}
