import { createStyles, ScrollArea, Space, Text } from '@mantine/core';
import React from 'react';

import { OrderType } from '@portals/api/organizations';
import { useGenericContext } from '@portals/framework';

import { OrderProductBreakdownRow } from './OrderProductBreakdownRow';

interface OrderProductsBreakdownProps {
  onSelectPurchasedProductId: (purchasedProductId: string) => void;
  selectedPurchasedProductId: string | null;
}

export function OrderProductsBreakdown({
  onSelectPurchasedProductId,
  selectedPurchasedProductId,
}: OrderProductsBreakdownProps) {
  const order = useGenericContext<OrderType>();

  const withSerialNumber = order?.purchased_products?.some?.(
    (purchasedProduct) => Boolean(purchasedProduct.sn)
  );

  const withSku = order?.items?.some?.(
    (orderItem) => orderItem.product.sku !== null
  );

  const withBasePrice = order?.items?.some?.(
    (orderItem) => orderItem.usage_based?.base_price_in_scu !== undefined
  );

  const numOfGridMiddleColumns =
    [withSku, withSerialNumber, withBasePrice].filter(Boolean).length + 2;
  const { classes } = useStyles(numOfGridMiddleColumns);

  return (
    <div data-testid="order-products-breakdown-stack">
      <div className={classes.tableHeadContainer}>
        <Text>Name</Text>
        <Text>Model</Text>
        {withSerialNumber ? <Text>Serial Number</Text> : null}
        {withSku ? <Text>SKU</Text> : null}
        {withBasePrice ? <Text>Base fee</Text> : null}
        <Text>Price</Text>

        <div />
      </div>

      <ScrollArea.Autosize mah={430}>
        {(order?.purchased_products || []).map(({ id, order_item_id, sn }) => (
          <OrderProductBreakdownRow
            key={id}
            orderItemId={order_item_id}
            onSelect={() => onSelectPurchasedProductId(id)}
            isSelected={selectedPurchasedProductId === id}
            withSerialNumber={withSerialNumber ?? false}
            withSku={withSku ?? false}
            withBasePrice={withBasePrice ?? false}
            sn={sn}
            numOfGridMiddleColumns={numOfGridMiddleColumns}
          />
        ))}

        <Space h="xs" />
      </ScrollArea.Autosize>
    </div>
  );
}

const useStyles = createStyles((theme, numOfGridMiddleColumns: number) => ({
  tableHeadContainer: {
    display: 'grid',
    gridTemplateColumns: `2fr repeat(${numOfGridMiddleColumns}, 1.5fr) 100px`,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
    gap: theme.spacing.sm,
    paddingInline: theme.spacing.xs,
    paddingBottom: theme.spacing.xs,
  },
}));
