import { Loader } from '@mantine/core';
import { noop } from 'lodash/fp';
import React, { useMemo, useRef } from 'react';
import { useLocalStorage } from 'react-use';

import { SpaceType } from '@portals/api/organizations';
import { buildTreeFromNodes, expandNodesByDepth } from '@portals/utils';

import { Tree } from './components/Tree';

interface SpaceTreeProps {
  spaces: SpaceType[];
  handleSelected: (nodeId: number) => void;
  selected: SpaceType;
  readonly?: boolean;
  draggable?: boolean;
  openModal: (type: string, params?: any) => void;
}

export function SpaceTree(props: SpaceTreeProps) {
  const { spaces, handleSelected, selected, readonly } = props;

  const treeData = useMemo(() => buildTreeFromNodes(spaces), [spaces]);

  // Load expanded nodes from local storage if exists
  const [storedExpandedNodeIds] = useLocalStorage(
    'spacesTree.expandedNodes',
    null
  );
  // Set expanded nodes from local storage if exists, or use default 3 level expansion
  const initialExpandedNodeIds = useRef(
    storedExpandedNodeIds || expandNodesByDepth(treeData, 3)
  );

  if (!treeData) {
    return <Loader />;
  }

  return treeData ? (
    <Tree
      {...props}
      spaces={spaces}
      treeData={treeData}
      handleSelected={handleSelected}
      handleMoveSpace={noop}
      readonly={readonly}
      initialExpandedNodeIds={initialExpandedNodeIds.current}
      selected={selected ? selected.id : null}
    />
  ) : null;
}
