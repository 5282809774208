import { TextInput, TextInputProps } from '@mantine/core';
import React, { FC } from 'react';

import { ReactComponent as CloseX } from '@portals/icons/linear/close-x.svg';
import { ReactComponent as SearchNormal2 } from '@portals/icons/linear/search-normal 2.svg';

import { useStyles } from './styles';

interface SearchInputProps extends TextInputProps {
  onClear: () => void;
}

const SearchInput: FC<SearchInputProps> = ({
  onClear,
  className,
  ...textInputProps
}) => {
  const { classes, cx } = useStyles();

  return (
    <TextInput
      rightSection={
        textInputProps.value ? (
          <CloseX
            onClick={onClear}
            className="search-input-icon"
            style={{ cursor: 'pointer' }}
          />
        ) : (
          <SearchNormal2 className="search-input-icon" />
        )
      }
      placeholder="Search..."
      data-testid="search-input"
      size="xs"
      className={cx(classes.root, className)}
      {...textInputProps}
    />
  );
};

export default SearchInput;
