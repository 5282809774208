import { sumBy } from 'lodash/fp';
import { useMemo } from 'react';

import {
  DeviceModelBreakdownViewEnum,
  SpaceType,
} from '@portals/api/organizations';

import { ChartItemType } from './DeviceModelBreakdownChart';

const MAX_ITEMS_IN_CHART = 7;

export function useChartData(
  space: SpaceType | undefined,
  deviceModelBreakdownView: DeviceModelBreakdownViewEnum | undefined
) {
  // Sort devices_status_by_device_model by the size of claimed_devices/online_devices
  const sortedModels = Object.entries(
    space?.state?.devices_status_by_device_model || {}
  ).sort((a, b) => {
    if (
      deviceModelBreakdownView === DeviceModelBreakdownViewEnum.OnlineDevices
    ) {
      return b[1].online_devices - a[1].online_devices;
    } else {
      return b[1].claimed_devices - a[1].claimed_devices;
    }
  });

  const { chartDataItems, otherModelsValue } = useMemo(() => {
    let otherModelsValue = 0;

    const chartDataItems = sortedModels.reduce(
      (acc: { name: string; value: number }[], model, index) => {
        if (index >= MAX_ITEMS_IN_CHART - 1) {
          if (
            deviceModelBreakdownView ===
            DeviceModelBreakdownViewEnum.OnlineDevices
          ) {
            otherModelsValue += model[1].online_devices;
          } else {
            otherModelsValue += model[1].claimed_devices;
          }

          return acc;
        }

        acc.push({
          name: model[0],
          value:
            deviceModelBreakdownView ===
            DeviceModelBreakdownViewEnum.OnlineDevices
              ? model[1].online_devices
              : model[1].claimed_devices,
        });

        return acc;
      },
      []
    );
    return { chartDataItems, otherModelsValue };
  }, [sortedModels, deviceModelBreakdownView]);

  if (otherModelsValue > 0) {
    chartDataItems.push({
      name: 'Other',
      value: otherModelsValue,
    });
  }

  const totalDevicesCount = sumBy('value', chartDataItems);

  const chartDataWithPercentage: ChartItemType[] = chartDataItems.map(
    (item) => ({
      ...item,
      percentage: Math.round((item.value / totalDevicesCount) * 100),
    })
  );

  const isChartDataEmpty = chartDataItems.every((item) => item.value === 0);

  return {
    chartDataItems: chartDataWithPercentage,
    isChartDataEmpty,
    totalDevicesCount,
  };
}
