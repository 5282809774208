import { createStyles, Paper, Stack, Text } from '@mantine/core';
import React from 'react';

import { OrderType } from '@portals/api/organizations';
import { useGenericContext } from '@portals/framework';
import { prettyTimeMoment } from '@portals/utils';

interface PaymentMethodDetailsProps {
  order: OrderType;
}

function CreditCard({ order }: PaymentMethodDetailsProps) {
  const { classes, cx } = useStyles();

  return (
    <>
      <Text className={cx(classes.title, classes.ellipsis)} mb="xs">
        Credit Card
      </Text>

      <Text className={cx(classes.details, classes.ellipsis)}>
        **** {order.credit_card?.last_4_digits}
      </Text>

      <Text className={cx(classes.details, classes.ellipsis)}>
        {order.credit_card?.brand}
      </Text>
    </>
  );
}

function PurchaseOrder({ order }: PaymentMethodDetailsProps) {
  const { classes, cx } = useStyles();

  return (
    <>
      <Text className={cx(classes.title, classes.ellipsis)} mb="xs">
        Purchase Order
      </Text>

      <Text className={cx(classes.details, classes.ellipsis)}>
        Pricing quote: {prettyTimeMoment(order.purchase_order?.created_at)}
      </Text>

      {order.purchase_order?.purchase_order_file_uploaded_at ? (
        <Text className={cx(classes.details, classes.ellipsis)}>
          Purchase order:{' '}
          {prettyTimeMoment(
            order.purchase_order?.purchase_order_file_uploaded_at
          )}
        </Text>
      ) : null}

      {order.purchase_order?.approved_at ? (
        <Text className={cx(classes.details, classes.ellipsis)}>
          Approved: {prettyTimeMoment(order.purchase_order?.approved_at)} by{' '}
          {order.approved_by?.name} (
          <a href={`mailto:${order.approved_by?.email}`}>
            {order.approved_by?.email}
          </a>
          )
        </Text>
      ) : null}
    </>
  );
}

export function OrderPaymentMethodCard() {
  const order = useGenericContext<OrderType>();
  const { classes, cx } = useStyles();

  let details;

  switch (order.payment_method) {
    case 'credit_card':
      details = <CreditCard order={order} />;
      break;

    case 'purchase_order':
      details = <PurchaseOrder order={order} />;
      break;

    default:
      details = (
        <Text className={cx(classes.title, classes.ellipsis)} mb="xs">
          Lab
        </Text>
      );
  }

  return (
    <Paper withBorder p="xl" radius="md">
      <Stack>
        <Text size="md" color="gray.8">
          Payment
        </Text>

        <Stack spacing="xs">{details}</Stack>
      </Stack>
    </Paper>
  );
}

const useStyles = createStyles((theme) => ({
  ellipsis: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  title: {
    fontSize: theme.fontSizes.sm,
    color: theme.colors.blue_gray[9],
    fontWeight: 600,
  },
  details: {
    fontSize: theme.fontSizes.sm,
    color: theme.colors.blue_gray[4],
  },
}));
