import { Box, Button, Select } from '@mantine/core';
import React, { FC } from 'react';

import { OrganizationFileResponseType } from '@portals/types';

type FilesListProps = {
  files: Array<OrganizationFileResponseType>;
  selected?: string;
  handleSelected?: (selected: string) => void;
  filesType: any;
  openUploadFile: () => void;
};

const FilesList: FC<FilesListProps> = ({
  files,
  selected,
  handleSelected,
  filesType,
  openUploadFile,
}) => (
  <Box
    sx={(theme) => ({
      display: 'grid',
      gridTemplateColumns: '1fr min-content',
      gap: theme.spacing.md,
    })}
  >
    <Select
      withinPortal={false}
      id="file"
      name="file"
      value={selected}
      onChange={handleSelected}
      placeholder="-- select a file --"
      data={files.map(({ id, name, desc, version }) => ({
        value: id,
        label: `${name} ${desc ? `(${desc})` : ''} ${version || ''}`,
      }))}
    />

    <div>
      {filesType === 'custom' ? (
        <Button color="primary" onClick={openUploadFile}>
          Upload file
        </Button>
      ) : null}
    </div>
  </Box>
);

export default FilesList;
