import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';
import { CecPartnerType } from '@portals/types';

import {
  CEC_PARTNERS_API_URL,
  cecPartnersQueryKeys,
} from './cec-partners.constants';
import { useApiQuery } from '../../hooks';
import { ServerError } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils';
import { useIsCecEnabled } from '../portal-config';

export function useCecPartners() {
  const isCecEnabled = useIsCecEnabled();

  return useApiQuery<Array<CecPartnerType>>(
    CEC_PARTNERS_API_URL,
    cecPartnersQueryKeys.base,
    { staleTime: 0, enabled: !!isCecEnabled }
  );
}

export function useConnectCecPartner() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: CEC_PARTNERS_API_URL,
    method: 'POST',
  });

  return useMutation<void, ServerError, { partnerId: string }>({
    mutationFn: ({ partnerId }) => {
      return fetchApiRequest(url, {
        ...options,
        body: JSON.stringify({ partner_id: partnerId }),
      });
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Brand connected successfully'));

      return queryClient.invalidateQueries(cecPartnersQueryKeys.base);
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
  });
}
