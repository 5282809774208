import { useDescope } from '@descope/react-sdk';
import { Button, LoadingOverlay, Stack, TextInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { captureException } from '@sentry/react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { object, string } from 'yup';

import { useCreateOrganization } from '@portals/api/organizations';
import { signedIn } from '@portals/redux/actions/auth';

import { StepTitle } from '../common';
import { useSignUpWizard, useTrackPageView } from '../wizard/wizard.hooks';

const schema = object({
  org: string().required('Organization name is required'),
});

export function OrganizationDoesntExists() {
  const navigate = useNavigate();

  const { onNext, setContextData, contextData } = useSignUpWizard();
  const dispatch = useDispatch();

  const params = useParams<{ partner_name?: string }>();

  const form = useForm({
    initialValues: {
      org: contextData.org ?? '',
    },
    validate: yupResolver(schema),
  });

  const createOrganization = useCreateOrganization();

  const descopeSdk = useDescope();

  const onSubmit = async (values: typeof form.values) => {
    const referral = localStorage.getItem('referral');

    let token;

    try {
      if (contextData.sessionToken) {
        token = contextData.sessionToken;
      } else {
        const descopeSdkResponse = await descopeSdk.password.signUp(
          contextData.email,
          contextData.password,
          {
            name: contextData.name,
            email: contextData.email,
          }
        );

        if (descopeSdkResponse.error || !descopeSdkResponse.data) {
          form.setFieldError('org', descopeSdkResponse.error?.errorDescription);

          captureException(
            `descope error - ${descopeSdkResponse.error?.errorDescription}`
          );

          throw new Error(
            `Failed to create user ${descopeSdkResponse.error?.errorDescription}`
          );
        }

        token = descopeSdkResponse.data.sessionJwt;
      }

      const auth = await createOrganization.mutateAsync({
        org: values.org,
        name: contextData.name,
        email: contextData.email,
        partner_name: params.partner_name,
        afterAuthPath: '/',
        token,
        referral,
      });

      setContextData((curr) => ({
        ...curr,
        org: values.org,
      }));

      dispatch(signedIn(auth));

      onNext();
    } catch (error) {
      console.error(error);
    }
  };

  useTrackPageView('Name your org screen');

  if (createOrganization.isLoading) {
    return <LoadingOverlay visible />;
  }

  return (
    <Stack spacing="lg">
      <StepTitle>Name your organization</StepTitle>

      <form onSubmit={form.onSubmit(onSubmit)}>
        <Stack spacing="xxl">
          <TextInput
            required
            data-testid="onboarding-organization-name-input"
            label="Organization name"
            placeholder="e.g. Xyte"
            {...form.getInputProps('org')}
          />

          <Stack spacing="sm">
            <Button
              data-testid="create-org-button"
              fullWidth
              type="submit"
              data-analyticsid="create-org-button"
            >
              Continue
            </Button>

            {form.errors.org === 'User already exists' && (
              <Button
                data-testid="go-to-sign-in"
                data-analyticsid="go-to-sign-in-after-user-already-exists"
                variant="default"
                fullWidth
                onClick={() => navigate('/auth/sign-in')}
              >
                Go to sign in
              </Button>
            )}
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
}
