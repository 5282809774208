import { SelectProps, SelectStylesNames } from '@mantine/core';

import { ThemeComponentOverrides } from '../common-types';

export const Select: ThemeComponentOverrides<
  SelectProps,
  SelectStylesNames,
  any
> = {
  styles: (theme) => ({
    root: {
      width: '100%',
    },
    label: {
      marginBottom: theme.spacing.xs,
      color: theme.colors.gray[7],
      fontWeight: 400,
    },
    input: {
      padding: `0 ${theme.spacing.md}`,
      borderColor: theme.fn.rgba(theme.colors.gray[4], 0.75),

      '&::placeholder': {
        color: theme.colors.gray[4],
        fontWeight: 400,
        fontSize: theme.fontSizes.sm,
      },
    },
    dropdown: {
      padding: 4,
      transform: 'translateY(5px)',
      borderRadius: theme.radius.sm,
    },
    item: {
      width: '100%',
      minHeight: 33,
      display: 'flex',
      alignItems: 'center',
      padding: `0 ${theme.spacing.md}`,
      fontWeight: 400,
      fontSize: theme.fontSizes.sm,

      '&[date-hovered]': {
        backgroundColor: theme.colors.gray[1],
      },

      '&[data-selected]': {
        color: theme.other.primaryColor,
        background: 'none',

        '&:hover': {
          backgroundColor: theme.colors.gray[1],
        },
      },
    },
  }),
};
