import { Button, Group, ButtonProps } from '@mantine/core';
import React from 'react';

import { ModalCenteredMediaLayout } from '@portals/core';
import { ModalProps } from '@portals/framework';

import failedCreditCardSrc from '../../assets/img/failed-credit-card.svg';

export function FailedCreditCard({ closeMe }: ModalProps) {
  return (
    <ModalCenteredMediaLayout
      opened
      title="Failed to Add Card"
      description="Please try again or contact support"
      onClose={closeMe}
      media={<img src={failedCreditCardSrc} />}
      footer={
        <Group grow mt="md">
          <Button
            variant="default"
            onClick={closeMe}
            component="a"
            href="mailto:support@xyte.io"
            styles={buttonStyles}
          >
            Contact Support
          </Button>
          <Button onClick={closeMe}>Close</Button>
        </Group>
      }
    />
  );
}

const buttonStyles: ButtonProps['styles'] = (theme) => ({
  root: {
    '&:hover': {
      color: theme.black,
      textDecoration: 'none',
    },
  },
});
