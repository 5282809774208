import { Middleware } from 'redux';

import { StateType, TenantType } from '@portals/types';

import { API } from '../constants';

// This middleware is to block any requests to APIs
// which requires admin access, for non admin users.
export const adminAccessMiddleware: Middleware<any, StateType> =
  ({ getState }) =>
  (next) =>
  (action) => {
    if (action.type !== API) return next(action);
    if (!action.meta?.requiresAdminAccess) return next(action);

    const state = getState();
    const isGlobalAdmin = state.ui.auth?.global_admin;

    if (isGlobalAdmin) return next(action);

    let isLocalAdmin = false;
    const tenantType = state.ui.auth?.tenant.type;

    if (tenantType === TenantType.Organization) {
      isLocalAdmin = state.ui.auth?.org_admin === true;
    } else if (tenantType === TenantType.Partner) {
      isLocalAdmin = state.ui.auth?.partner_admin === true;
    }

    if (isLocalAdmin) {
      return next(action);
    } else {
      const { url } = action.payload;

      return next({
        type: 'NOT_AUTHORIZED',
        payload: `User is not an admin and cannot access the "${url}" API`,
      });
    }
  };
