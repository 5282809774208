import classNames from 'classnames';
import React, { useState } from 'react';

import { InfoTable } from '@portals/table';

import { HDBaseTPartners } from './HDBaseT_partners';
import MSE from './MSE';

const calcQuality = (state) => {
  const keys = [
    'local_hdbt_mse_ch_a_db',
    'local_hdbt_mse_ch_b_db',
    'local_hdbt_mse_ch_c_db',
    'local_hdbt_mse_ch_d_db',
  ];

  if (keys.some((key) => state[key] >= -11.5)) {
    return 'Low';
  }

  if (keys.some((key) => state[key] >= -12.5)) {
    return 'Marginal';
  }

  return 'High';
};

const clockRate = (state) =>
  state.local_gender === 'TX'
    ? state.local_hdbt_hdmi_in_pixel_clock_rate
    : state.remote_hdbt_hdmi_in_pixel_clock_rate;

const resolution = (state) => {
  const prefix = state.local_gender === 'TX' ? 'local' : 'remote';
  const horizontal = state[prefix + '_hdbt_hdmi_in_horizontal_active_pixels'];
  const vertical = state[prefix + '_hdbt_hdmi_in_vertical_active_pixels'];
  const refresh = state[prefix + '_hdbt_hdmi_in_refresh_rate'];
  const format = state[prefix + '_hdbt_hdmi_in_video_format'];

  return `${horizontal}x${vertical} @ ${refresh}Hz (${format})`;
};

const VideoLinkDetails = ({ state }) => (
  <>
    <InfoTable.Row
      label="Retransmit rate"
      value={
        state.local_hdbt_retransrate
          ? `1:${state.local_hdbt_retransrate}`
          : 'Unknown'
      }
    />

    <InfoTable.Row label="Resolution" value={resolution(state)} />

    <InfoTable.Row label="HDMI Pixel Clock" value={`${clockRate(state)} Mhz`} />
  </>
);

const HDBaseT = ({ device }) => {
  const [showDetails, setShowDetails] = useState(false);

  const { state } = device;
  const linkQuality = calcQuality(state);
  const linkQualityText = classNames({
    'text-warning': linkQuality === 'Marginal',
    'text-danger': linkQuality === 'Low',
    'text-primary': linkQuality === 'High',
  });

  return (
    <>
      <InfoTable.Row isHeader label={<b>HDBaseT</b>} value={null} />

      <InfoTable.Row
        label="Vendor"
        value={
          HDBaseTPartners[parseInt(state.local_hdbt_device_vendor_id || '0')] ||
          'Valens'
        }
      />

      <InfoTable.Row
        label="Chip Type"
        value={state.remote_hdbt_product_part_number || 'Unknown'}
      />

      <InfoTable.Row
        label="Chip Gender"
        value={state.local_gender || 'Unknown'}
      />

      <InfoTable.Row
        label="Cable Length"
        value={
          state.port_hdbt_cablelength
            ? `${
                parseInt(state.port_hdbt_cablelength) > 30000
                  ? '>100'
                  : state.port_hdbt_cablelength
              } meters`
            : 'Unknown'
        }
      />

      {!clockRate(state) ? (
        <InfoTable.Row label="Video signal" value="None" />
      ) : (
        <VideoLinkDetails state={state} />
      )}

      <InfoTable.Row
        label="Link quality"
        value={
          <>
            <span className={linkQualityText}>{linkQuality} </span>
            <div
              className="float-right text-primary btn-link"
              onClick={() => setShowDetails(!showDetails)}
            >
              {`(${showDetails ? 'hide' : 'show'})`}
            </div>
          </>
        }
      />

      {showDetails && (
        <>
          <MSE mse={state.local_hdbt_mse_ch_a_db} channel="A" />
          <MSE mse={state.local_hdbt_mse_ch_b_db} channel="B" />
          <MSE mse={state.local_hdbt_mse_ch_c_db} channel="C" />
          <MSE mse={state.local_hdbt_mse_ch_d_db} channel="D" />
        </>
      )}
    </>
  );
};

export default HDBaseT;
