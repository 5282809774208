import { Button, Group, Stack } from '@mantine/core';
import { isEmpty } from 'lodash/fp';
import React, { FC } from 'react';

import { useDeviceInsights } from '@portals/api/organizations';
import { ReactComponent as Refresh } from '@portals/icons/linear/refresh.svg';
import { VerticalScrollBar } from '@portals/scrollbar';

import { WidgetProps } from './types';
import { Insights } from '../../pages/overview/overview-dashboard/widgets/ai/Insights';

const InsightsWidgets: FC<WidgetProps> = ({ deviceId }) => {
  const insights = useDeviceInsights(deviceId);

  return (
    <VerticalScrollBar>
      <Stack sx={{ height: '100%', position: 'relative' }} spacing="xs" p="md">
        {insights.isFetched && isEmpty(insights.data) ? (
          <Button
            variant="default"
            onClick={() => insights.refetch()}
            size="xs"
            sx={{
              position: 'absolute',
              top: 20,
              right: 20,
            }}
          >
            <Group align="center" spacing="xs">
              <Refresh width={15} height={15} />
              Regenerate
            </Group>
          </Button>
        ) : null}

        <Insights
          insights={insights.data}
          isError={insights.isError}
          isFetched={insights.isFetched}
          isLoading={insights.isInitialLoading}
        />
      </Stack>
    </VerticalScrollBar>
  );
};

export default InsightsWidgets;
