import { Button, Stack, Tabs, TabsProps, Text } from '@mantine/core';
import React from 'react';

import { PurchasedProductType } from '@portals/api/organizations';
import { DetailsPanel } from '@portals/core';
import { PostPurchaseParamsDetailsList } from '@portals/framework';
import { useOpenModal } from '@portals/redux';

import { OverviewTab } from './tabs/OverviewTab';
import { PostPurchaseParametersModalProps } from '../../../../modals';

interface PostPurchaseParamsTabs {
  purchasedProduct: PurchasedProductType;
}

export function PostPurchaseParamsTabs({
  purchasedProduct,
}: PostPurchaseParamsTabs) {
  const openModal = useOpenModal();

  return (
    <Tabs defaultValue="overview" styles={tabsStyles} keepMounted={false}>
      <Tabs.List grow>
        <Tabs.Tab value="overview">Overview</Tabs.Tab>
        <Tabs.Tab value="moreDetails">More details</Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="overview">
        <DetailsPanel.Body>
          <OverviewTab purchasedProduct={purchasedProduct} />
        </DetailsPanel.Body>
      </Tabs.Panel>

      <Tabs.Panel value="moreDetails">
        <DetailsPanel.Body>
          {purchasedProduct.post_purchase_parameters ? (
            <PostPurchaseParamsDetailsList
              pppFields={purchasedProduct.post_purchase_parameters}
            />
          ) : (
            <Stack align="flex-start">
              <Text>
                This product requires additional purchase information. Please
                provide the missing information.
              </Text>
              <Button
                variant="light"
                onClick={() =>
                  openModal<PostPurchaseParametersModalProps['data']>(
                    'PostPurchaseParametersModal',
                    {
                      orderId: purchasedProduct.order_id,
                    }
                  )
                }
              >
                Complete Purchase Information
              </Button>
            </Stack>
          )}
        </DetailsPanel.Body>
      </Tabs.Panel>
    </Tabs>
  );
}

const tabsStyles: TabsProps['styles'] = (theme) => ({
  root: {
    height: '100%',
  },
  tabsList: {
    marginInline: theme.spacing.xxl,
  },
  panel: {
    height: '100%',
  },
});
