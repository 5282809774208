import { Button, Checkbox, Group, Modal, Text } from '@mantine/core';
import React, { useState } from 'react';

import {
  SpaceType,
  useUpdateSpaceMaintenance,
} from '@portals/api/organizations';
import { ModalBody, ModalFooter } from '@portals/core';
import { ModalProps } from '@portals/framework';
import { ReactComponent as MaintenanceIcon } from '@portals/icons/linear/maintenance.svg';

export interface SpaceMaintenanceActivationModalProps
  extends ModalProps<{ spaceId: SpaceType['id'] }> {}

export function SpaceMaintenanceActivationModal({
  closeMe,
  data: { spaceId },
}: SpaceMaintenanceActivationModalProps) {
  const [closeAllIncidents, setCloseAllIncidents] = useState(false);

  const updateSpaceMaintenance = useUpdateSpaceMaintenance();

  return (
    <Modal
      opened
      onClose={closeMe}
      padding="xxl"
      title={
        <Group>
          <MaintenanceIcon />

          <Text>Maintenance Mode Activation</Text>
        </Group>
      }
    >
      <ModalBody>
        <Text>
          Maintenance mode will turn off all incident notifications.
          <br />
          This will allow you to perform maintenance and updates without
          disrupting your work. Do you want to continue?
        </Text>

        <Checkbox
          mt="md"
          label="Close all active incidents associated with this space"
          checked={closeAllIncidents}
          onChange={(e) => setCloseAllIncidents(e.currentTarget.checked)}
        />
      </ModalBody>

      <ModalFooter position="right">
        <Button variant="default" onClick={closeMe}>
          Cancel
        </Button>

        <Button
          loading={updateSpaceMaintenance.isLoading}
          onClick={() =>
            updateSpaceMaintenance.mutate(
              { spaceId, enabled: true, closeAllIncidents },
              { onSuccess: closeMe }
            )
          }
        >
          Turn on
        </Button>
      </ModalFooter>
    </Modal>
  );
}
