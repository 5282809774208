import { Accordion, AccordionProps } from '@mantine/core';
import React, { useState } from 'react';

import { ReactComponent as Add } from '@portals/icons/linear/add.svg';
import { ReactComponent as MinusIcon } from '@portals/icons/linear/minus.svg';
import { OrganizationStoreListing } from '@portals/types';

import { CommandsAndModelsList } from '../../../components/CommandAndModelsList';

interface ProductModelsAndCommandsProps {
  supportedCommands: OrganizationStoreListing['product']['supported_commands'];
}

export function ProductModelsAndCommands({
  supportedCommands,
}: ProductModelsAndCommandsProps) {
  const [accordionControlValue, setAccordionControlValue] = useState<
    string | null
  >(null);

  return (
    <Accordion
      value={accordionControlValue}
      onChange={setAccordionControlValue}
      styles={accordionStyles}
    >
      <Accordion.Item value="modelsAndCommands">
        <Accordion.Control
          chevron={accordionControlValue ? <MinusIcon /> : <Add />}
          py="xl"
          px={0}
        >
          Models & Commands
        </Accordion.Control>

        <Accordion.Panel>
          <CommandsAndModelsList supportedCommands={supportedCommands} />
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
}

const accordionStyles: AccordionProps['styles'] = (theme) => ({
  label: {
    padding: 0,
  },

  content: {
    padding: `0 0 ${theme.spacing.xl}`,
  },
});
