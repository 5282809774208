import { Anchor, Box, createStyles, Title } from '@mantine/core';
import React from 'react';
import { Link } from 'react-router-dom';

import { DeviceType, useDevices } from '@portals/api/organizations';
import { PaginatedTable } from '@portals/table';
import { TableColumn, TableFilterTypeEnum } from '@portals/types';

import { PageContainer } from '../../../components/layout/Page';

const COLUMNS: Array<TableColumn<DeviceType>> = [
  {
    dataField: 'name',
    text: 'Name',
    sort: true,
    minWidth: 200,
    filter: {
      type: TableFilterTypeEnum.Text,
    },
    formatter: (cell, { name, id }) => (
      <Anchor component={Link} to={`/devices/${id}`}>
        {name || 'Unknown'}
      </Anchor>
    ),
  },
  {
    dataField: 'space_tree_path_name',
    text: 'Space',
    sort: true,
    minWidth: 200,
    filter: {
      type: TableFilterTypeEnum.Text,
    },
    formatter: (_, { space_id, space_tree_path_name }) => (
      <Anchor component={Link} to={`/overview/${space_id}`}>
        {space_tree_path_name}
      </Anchor>
    ),
  },
];

export function Devices() {
  const { classes } = useStyles();

  return (
    <PageContainer>
      <Box className={classes.content} py="lg" px="sm">
        <Title order={4}>Devices</Title>

        <Box className={classes.table}>
          <PaginatedTable<DeviceType>
            noHeader
            keyField="id"
            name="mobile-organizations.devices"
            dataHook={useDevices}
            columns={COLUMNS}
            noDataIndication={{ title: 'No devices' }}
          />
        </Box>
      </Box>
    </PageContainer>
  );
}

const useStyles = createStyles((theme) => ({
  content: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
    gap: theme.spacing.md,
  },
  table: {
    border: `1px solid ${theme.colors.gray[3]}`,

    '.table-scroll-container': {
      padding: 0,
      border: 'none',
    },
  },
}));
