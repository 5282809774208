import { Badge } from '@mantine/core';
import React from 'react';

import { upcaseFirst } from '@portals/utils';

const STATE_COLORS = {
  error: 'red_accent',
  unknown: 'dark',
  active: 'blue_accent',
  disabled: 'gray',
};

const IntegrationState = (props) => {
  const { state = 'unknown' } = props;

  return (
    <Badge {...props} color={STATE_COLORS[state]} variant="filled">
      {upcaseFirst(state)}
    </Badge>
  );
};

export default IntegrationState;
