import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError } from '@portals/redux/actions/toastr';
import { CreditCardType, StripeInfoSetupIntentType } from '@portals/types';

import {
  CREDIT_CARDS_API_URL,
  creditCardsQueryKeys,
} from './credit-cards.constants';
import { useApiQuery } from '../../hooks/query';
import { QueryOptions, ServerError } from '../../types/common';
import { fetchApiRequest, useRequestOptions } from '../../utils/common';

export function useCreditCards(queryOptions?: QueryOptions<CreditCardType[]>) {
  return useApiQuery<CreditCardType[]>(
    CREDIT_CARDS_API_URL,
    creditCardsQueryKeys.all,
    queryOptions
  );
}

export function useFetchCreditCards() {
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: CREDIT_CARDS_API_URL,
    method: 'GET',
  });

  return useMutation({
    mutationFn: (): Promise<Array<CreditCardType>> =>
      fetchApiRequest(url, options),
    onSuccess: (data) => {
      queryClient.setQueryData(creditCardsQueryKeys.all, data);
    },
    meta: {
      mutationName: 'useFetchCreditCards',
      baseUrl: CREDIT_CARDS_API_URL,
      method: 'GET',
    },
  });
}

export function useCreateCreditCard() {
  const { url, options } = useRequestOptions({
    url: CREDIT_CARDS_API_URL,
    method: 'POST',
  });

  return useMutation({
    mutationFn: (): Promise<CreditCardType> => fetchApiRequest(url, options),
    meta: {
      mutationName: 'useCreateCreditCard',
      baseUrl: CREDIT_CARDS_API_URL,
      method: 'POST',
    },
  });
}

export function useRemoveCreditCard() {
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: CREDIT_CARDS_API_URL,
    method: 'DELETE',
  });

  return useMutation({
    mutationFn: (creditCardId: string): Promise<CreditCardType> =>
      fetchApiRequest(`${url}/${creditCardId}`, {
        ...options,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(creditCardsQueryKeys.all);
    },
    meta: {
      mutationName: 'useRemoveCreditCard',
      baseUrl: `${CREDIT_CARDS_API_URL}/:id`,
      method: 'DELETE',
    },
  });
}

export function useStripeSetupIntent() {
  const { url, options } = useRequestOptions({
    url: `${CREDIT_CARDS_API_URL}/new`,
    method: 'GET',
  });

  return useMutation({
    mutationFn: (): Promise<StripeInfoSetupIntentType> =>
      fetchApiRequest(url, {
        ...options,
      }),
    meta: {
      mutationName: 'useStripeSetupIntent',
      baseUrl: `${CREDIT_CARDS_API_URL}/new`,
      method: 'GET',
    },
  });
}

interface UseReplaceCreditCardParams {
  oldCreditCardId: string;
  newCreditCardId: string;
}

export function useReplaceCreditCard() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { url, options } = useRequestOptions({
    url: CREDIT_CARDS_API_URL,
    method: 'POST',
  });

  return useMutation<void, ServerError, UseReplaceCreditCardParams>({
    mutationFn: ({ oldCreditCardId, newCreditCardId }) =>
      fetchApiRequest(`${url}/${oldCreditCardId}/replace`, {
        ...options,
        body: JSON.stringify({ replacement_card_id: newCreditCardId }),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(creditCardsQueryKeys.all);
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useReplaceCreditCard',
      baseUrl: `${CREDIT_CARDS_API_URL}/:id/replace`,
      method: 'POST',
    },
  });
}

interface UseArchiveCreditCardParams {
  creditCardId: string;
}

export function useArchiveCreditCard() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { url, options } = useRequestOptions({
    url: CREDIT_CARDS_API_URL,
    method: 'POST',
  });

  return useMutation<void, ServerError, UseArchiveCreditCardParams>({
    mutationFn: ({ creditCardId }) =>
      fetchApiRequest(`${url}/${creditCardId}/archive`, {
        ...options,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(creditCardsQueryKeys.all);
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useArchiveCreditCard',
      baseUrl: `${CREDIT_CARDS_API_URL}/:id/archive`,
      method: 'POST',
    },
  });
}
