import {
  createStyles,
  Group,
  LoadingOverlay,
  SegmentedControl,
  Stack,
  StackProps,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { isEmpty, keys, reduce, without } from 'lodash/fp';
import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import {
  CartesianGrid,
  Dot,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';

import { LegendLabel } from './LegendLabel';
import { SvgGradient } from './SvgGradient';
import { TooltipContent } from './TooltipContent';
import { XAxisLabel, XAxisLabelProps } from './XAxisLabel';
import { FORMATTERS } from '../../../utils/formatters';
import { OfflineDeviceStateTooltip } from '../../common/OfflineDeviceStateTooltip';
import { NumberFormatType, ScaleType } from '../../widgets.types';
import { MeasurementType } from '../form';

enum WidgetDataRangeEnum {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Live = 'live',
  MonthToDate = 'monthToDate',
}

export interface SplineChartWidgetProps {
  title: string;
  measurements: Array<MeasurementType>;
  data: Array<{ timestamp: string } & Record<string, any>>;
  stackProps?: StackProps;
  range?: WidgetDataRangeEnum;
  setRange?: Dispatch<SetStateAction<WidgetDataRangeEnum>>;
  isLoading?: boolean;
  scaleType: ScaleType;
  numberFormat: NumberFormatType;
  numOfDecimals: number;
  id: string;

  isDeviceOffline?: boolean;
  lastUpdateTimestamp?: string;
}

export function SplineChartWidget({
  title,
  measurements,
  data,
  range,
  setRange,
  id,
  isLoading = false,
  stackProps = {},
  scaleType = 'linear',
  numberFormat = 'none',
  numOfDecimals = 0,

  isDeviceOffline,
  lastUpdateTimestamp,
}: SplineChartWidgetProps) {
  const theme = useMantineTheme();
  const { classes } = useStyles();

  const formatter = useCallback(
    (value: number | undefined) =>
      FORMATTERS[numberFormat](value, numOfDecimals),
    [numberFormat, numOfDecimals]
  );

  const adjustedData = useMemo(() => {
    if (scaleType === 'log') {
      // Log scale doesn't support negative values
      return data.map((dataPoint) => {
        const dataKeys = keys(dataPoint);
        const dataKeysWithoutTimestamp = without(['timestamp'], dataKeys);

        return reduce(
          (acc, key) => {
            const currentValue = dataPoint[key];

            if (currentValue < 0) {
              return {
                ...acc,
                [key]: null,
              };
            }

            return acc;
          },
          dataPoint,
          dataKeysWithoutTimestamp
        );
      });
    }

    return data;
  }, [data, scaleType]);

  return (
    <Stack
      className={classes.container}
      p="xl"
      h="100%"
      w="100%"
      bg="white"
      justify="center"
      spacing="xl"
      pos="relative"
      {...stackProps}
    >
      <LoadingOverlay visible={isLoading} />

      <Group align="center" position="apart">
        <Group noWrap align="center" spacing="sm">
          <Text
            size="md"
            data-testid="dashboard-spline-chart-widget-name"
            color="gray.7"
            truncate
          >
            {title}
          </Text>

          {isDeviceOffline ? (
            <OfflineDeviceStateTooltip
              lastUpdateTimestamp={lastUpdateTimestamp}
            />
          ) : null}
        </Group>

        {range ? (
          <SegmentedControl
            size="xs"
            data={[
              { value: WidgetDataRangeEnum.Month, label: 'Last 30 Days' },
              { value: WidgetDataRangeEnum.Week, label: 'Last 7 Days' },
              { value: WidgetDataRangeEnum.Day, label: 'Last 24hr' },
            ]}
            value={range}
            onChange={(range: WidgetDataRangeEnum) => setRange?.(range)}
          />
        ) : null}
      </Group>

      {isEmpty(data) ? (
        <Stack align="center" justify="center" w="100%" h="100%">
          <Text size="sm" color="gray.5">
            No data
          </Text>
        </Stack>
      ) : (
        <ResponsiveContainer width="100%" minHeight={100}>
          <LineChart
            data={adjustedData}
            margin={{ top: 5, left: 0, right: 0, bottom: 10 }}
          >
            <defs>
              {measurements.map((measurement, index) => (
                <SvgGradient
                  id={id}
                  key={`${measurement.key}-${measurement.color}`}
                  color={measurement.color}
                  index={index}
                />
              ))}
            </defs>

            <CartesianGrid
              stroke={theme.colors.gray[2]}
              strokeDasharray="3 3"
            />

            <XAxis
              dataKey="timestamp"
              tickMargin={5}
              tickSize={5}
              tickLine={{
                strokeWidth: 1,
                stroke: theme.colors.gray[2],
              }}
              tick={(props: XAxisLabelProps) => <XAxisLabel {...props} />}
              axisLine={{
                strokeWidth: 1,
                stroke: theme.colors.gray[2],
              }}
            />

            <YAxis
              width={80}
              interval="preserveStartEnd"
              tickFormatter={(value) => formatter(value) || 'N/A'}
              type="number"
              scale={scaleType}
              allowDataOverflow
              domain={['auto', 'auto']}
              tickLine={{
                strokeWidth: 1,
                stroke: theme.colors.gray[2],
              }}
              tick={{
                fill: theme.colors.gray[4],
                fontSize: theme.fontSizes.xs,
              }}
              axisLine={{
                strokeWidth: 1,
                stroke: theme.colors.gray[2],
              }}
            />

            <Tooltip<number, string>
              wrapperStyle={{
                zIndex: 999,
              }}
              isAnimationActive={false}
              content={(props: TooltipProps<number, string>) => (
                <TooltipContent
                  {...props}
                  measurements={measurements}
                  customFormatter={formatter}
                />
              )}
            />

            <Legend
              iconType="square"
              iconSize={0}
              layout="horizontal"
              align="left"
              wrapperStyle={{
                bottom: 0,
              }}
              content={(props) => {
                const { payload } = props;

                return (
                  <Group spacing="sm" align="end">
                    {(payload || []).map((entry, index) => (
                      <LegendLabel
                        key={measurements[index].key}
                        index={index}
                        measurements={measurements}
                      />
                    ))}
                  </Group>
                );
              }}
            />

            {measurements.map((measurement, index) => (
              <Line
                key={`${measurement.key}-${measurement.color}-${measurement.telemetry}-${id}-${index}`}
                isAnimationActive={false}
                dataKey={measurement.telemetry}
                strokeWidth={2}
                stroke={`url(#${id}-${index}-${measurement.color}-widget-line-gradient)`}
                dot={false}
                activeDot={(props) => (
                  <Dot
                    {...props}
                    fill={theme.fn.themeColor(measurement.color)}
                    r={6}
                    stroke={theme.fn.themeColor(theme.white)}
                    strokeWidth={0}
                  />
                )}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      )}
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    borderRadius: theme.radius.lg,

    '.recharts-default-legend': {
      display: 'flex',

      '.recharts-legend-item': {
        display: 'flex !important',
        alignItems: 'center',
      },
    },
  },
  indicator: {
    width: 14,
    height: 14,
    borderRadius: theme.radius.sm,
  },
}));
