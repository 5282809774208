import React from 'react';
import Joyride, { CallBackProps, Step } from 'react-joyride';
import { useLocation, useNavigate } from 'react-router-dom';

import { TOUR_STEPS_IDS } from '@portals/framework';

import { TourStepTooltip } from '../TourStepTooltip';

export function ClaimDeviceTour() {
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);

  const steps: Array<Step> = [
    {
      target: `[data-tour-step-id=${TOUR_STEPS_IDS.overviewSpaces}]`,
      title: 'Choose a space',
      content:
        'Before claiming your first device, choose the space you would like to claim the device to.',
      disableBeacon: true,
      floaterProps: {
        disableAnimation: true,
      },
      placement: 'right',
    },
    {
      target: `[data-tour-step-id=${TOUR_STEPS_IDS.claimDeviceButton}]`,
      title: 'Click on “Claim Device” ',
      content:
        "Follow the instructions in the claiming process. You can claim a device at any time from the space actions bar, or through the devices widget on the space's home page.",
      disableBeacon: true,
      floaterProps: {
        disableAnimation: true,
      },
    },
  ];

  const onTransitionCallback = (data: CallBackProps) => {
    const { status, lifecycle } = data;

    if (status === 'finished' && lifecycle === 'complete') {
      searchParams.delete('active_tour');
      navigate({ search: searchParams.toString() }, { replace: true });
    }
  };

  const onSkipTour = () => {
    searchParams.delete('active_tour');
    navigate({ search: searchParams.toString() }, { replace: true });
  };

  return (
    <Joyride
      steps={steps}
      continuous
      disableOverlayClose
      callback={onTransitionCallback}
      tooltipComponent={(props) => (
        <TourStepTooltip
          tourName="Claim your first device"
          onSkip={onSkipTour}
          {...props}
        />
      )}
      styles={{
        options: {
          arrowColor: 'black',
        },
      }}
    />
  );
}
