import { Box, createStyles, Text } from '@mantine/core';
import React from 'react';

import { useSendCommand } from '@portals/api/organizations';

import { useConfirmationModal } from './modals';

export function useSendCommandWithApproval() {
  const { classes } = useStyles();
  const asyncConfirmationCheck = useConfirmationModal();
  const sendCommand = useSendCommand();

  return async (commandParams: any) => {
    const commandToSend = commandParams.command;

    if (commandToSend.confirmation_message) {
      const shouldSendCommand = await asyncConfirmationCheck({
        title: (
          <Text data-testid="command-approval-modal-title">
            Confirm {commandToSend.friendly_name} command
          </Text>
        ),
        description: (
          <Box
            data-testid="command-description-field"
            className={classes.commandDescription}
          >
            {commandToSend.confirmation_message}
          </Box>
        ),
        confirmationLabel: 'Proceed with Command',
        confirmButtonProps: {
          color: 'primary',
          'data-testid': 'command-approval-modal-confirm-button',
        },
        cancelButtonProps: {
          'data-testid': 'command-approval-modal-cancel-button',
        },
      });

      if (!shouldSendCommand) {
        throw new Error('Command execution cancelled');
      }
    }

    return await sendCommand.mutateAsync(commandParams);
  };
}

const useStyles = createStyles((theme) => ({
  commandDescription: {
    whiteSpace: 'break-spaces',
  },
}));
