import { Button, Modal, Text } from '@mantine/core';
import { ModalProps as MantineModalProps } from '@mantine/core/lib/Modal/Modal';
import React from 'react';

import { useMarkAsUncollectible } from '@portals/api/partners';
import { ModalFooter } from '@portals/core';

import { ModalProps } from '../../components/Modals';

export interface MarkAsUncollectibleModalProps
  extends ModalProps<{ invoiceId: string }> {}

export function MarkAsUncollectibleModal({
  closeMe,
  data: { invoiceId },
}: MarkAsUncollectibleModalProps) {
  const markAsUncollectible = useMarkAsUncollectible();

  const onMarkAsUncollectible = () => {
    markAsUncollectible.mutate(
      { invoiceId },
      {
        onSuccess: () => {
          closeMe();
        },
      }
    );
  };

  return (
    <Modal
      opened
      onClose={closeMe}
      title="Confirm Uncollectible Invoice Status"
      styles={modalStyles}
    >
      <Text weight={500}>
        Marking an invoice as uncollectible means that the invoice is not not
        expected to be paid and should be written off. Are you sure you want to
        mark this invoice as uncollectible?
      </Text>

      <ModalFooter position="right" mt="xxl">
        <Button variant="default" size="md" onClick={closeMe}>
          Cancel
        </Button>
        <Button size="md" color="red" onClick={onMarkAsUncollectible}>
          Mark as Uncollectible
        </Button>
      </ModalFooter>
    </Modal>
  );
}

const modalStyles: MantineModalProps['styles'] = (theme) => ({
  header: {
    padding: `${theme.spacing.xxl} ${theme.spacing.xxl} ${theme.spacing.md}`,
  },

  body: {
    padding: `${theme.spacing.xxl} ${theme.spacing.xxl} ${theme.spacing.xl}`,
  },
});
