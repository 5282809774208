import { createStyles, Paper, Stack, Text } from '@mantine/core';
import React, { ReactNode } from 'react';

interface SpaceFormCardProps {
  title: string;
  children: ReactNode;
}

export function SpaceFormCard({ title, children }: SpaceFormCardProps) {
  const styles = useStyles();

  return (
    <Paper className={styles.classes.formCard}>
      <Text className={styles.classes.formCardTitle} mb="md">
        {title}
      </Text>

      <Stack spacing="xl">{children}</Stack>
    </Paper>
  );
}

const useStyles = createStyles((theme) => ({
  formCard: {
    borderRadius: theme.radius.md,
    background: theme.white,
    padding: theme.spacing.xl,
    border: `1px solid ${theme.colors.gray[2]}`,

    input: {
      ':not([type=checkbox])': {
        height: 40,
      },
    },
  },
  formCardTitle: {
    fontSize: theme.fontSizes.md,
    color: theme.colors.gray[8],
  },
}));
