import React, { FC } from 'react';
import styled from 'styled-components';

import { getStyledThemeColor } from '@portals/utils';

export const EmptyState = ({ label = 'No data' }: { label?: string }) => (
  <EmptyStateWrapper>
    <img src="empty-state.svg" alt="" className="mb-4" />

    <div className="empty-state-title">{label}</div>
  </EmptyStateWrapper>
);

export const CustomTooltip: FC<{
  label: string;
  items: Array<{ label: string; value: any; color?: string }>;
}> = ({ label, items }) => (
  <div className="custom-tooltip">
    <div className="custom-tooltip-label">{label}</div>

    <div className="custom-tooltip-content">
      {items.map(({ value, label, color }, index) => (
        <div className="custom-tooltip-item" key={index} style={{ color }}>
          <div className="custom-tooltip-item-label">{label}:</div>

          <div className="custom-tooltip-item-value">{value}</div>
        </div>
      ))}
    </div>
  </div>
);

const EmptyStateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  flex-direction: column;
  height: 100%;

  img {
    height: 150px;
  }

  .empty-state-title {
    font-size: 15px;
    color: ${getStyledThemeColor('gray500')};
  }
`;
