import { Box, Group, Stack, Text } from '@mantine/core';
import React, { ReactNode } from 'react';

import { ForbiddenErrorSVG } from '@portals/assets';

interface Page403Props {
  title?: ReactNode;
  description?: ReactNode;
  action?: ReactNode;
}

export function Page403({
  title = 'Access Restricted',
  description = `You currently do not have sufficient permissions to access any space. Please contact your administrator for further assistance and to request additional permissions.`,
  action,
}: Page403Props) {
  return (
    <Stack
      align="center"
      justify="center"
      h="100%"
      w="50%"
      m="auto"
      spacing="xl"
    >
      <ForbiddenErrorSVG />

      <Box>
        <Text size="md" align="center" c="gray.8" weight={600} mb="xs">
          {title}
        </Text>

        <Text size="sm" align="center" c="gray.5" weight={600} mb="md">
          {description}
        </Text>

        <Group position="center" w="100%">
          {action}
        </Group>
      </Box>
    </Stack>
  );
}
