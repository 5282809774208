import { Button, createStyles, Group, Stack, Text } from '@mantine/core';
import React from 'react';

import emptyStateSrc from '../../../../assets/img/dashboard-empty-state.png';
import { useOverviewContext } from '../overview.context';

export function DashboardEmptyState() {
  const { classes } = useStyles();
  const overview = useOverviewContext();

  return (
    <Stack
      className={classes.container}
      align="center"
      justify="center"
      spacing="xl"
    >
      <img src={emptyStateSrc} alt="Empty state" />

      <Text size="sm" color="gray.8" align="center">
        Customize Your Dashboard with Widgets
      </Text>

      <Text size="xs" color="gray.5" align="center">
        Make the Most of Your Dashboard by Adding Relevant Widgets and Creating
        a Customized Experience
      </Text>

      <Group position="center">
        <Button onClick={overview.onOpenWidgetsPanel} w="fit-content">
          Add Widgets
        </Button>
      </Group>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    position: 'absolute',
    width: '100%',
    top: -300,
    bottom: 0,

    img: {
      height: 270,
    },

    '>*': {
      width: 350,
    },
  },
}));
