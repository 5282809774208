import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';

interface MonitoringTourContextType {
  runTourPartOne: boolean;
  setRunTourPartOne: Dispatch<SetStateAction<boolean>>;
  runTourPartTwo: boolean;
  setRunTourPartTwo: Dispatch<SetStateAction<boolean>>;
}

const MonitoringTourContext = createContext<MonitoringTourContextType | null>(
  null
);

export function MonitoringTourContextProvide({
  children,
}: {
  children: ReactNode;
}) {
  const [runTourPartOne, setRunTourPartOne] = useState(false);
  const [runTourPartTwo, setRunTourPartTwo] = useState(false);

  return (
    <MonitoringTourContext.Provider
      value={{
        runTourPartOne,
        setRunTourPartOne,
        runTourPartTwo,
        setRunTourPartTwo,
      }}
    >
      {children}
    </MonitoringTourContext.Provider>
  );
}

export function useMonitoringTourContext() {
  const context = useContext(MonitoringTourContext);

  if (!context) {
    throw new Error(
      'useMonitoringTourContext must be used within a MonitoringTourContextProvide'
    );
  }

  return context;
}
