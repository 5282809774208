import { createGlobalStyle, css } from 'styled-components';

import { getStyledThemeColor } from '@portals/utils';

const mainWrappersAdjustments = css`
  html,
  body {
    overflow-x: hidden;
    height: 100dvh;
    width: 100%;
    max-width: 100dvw;
  }

  body {
    transition: background-color 0.15s ease-in-out;
    background-color: ${getStyledThemeColor('white')};
  }
`;

const hiddenWebElements = css`
  .sidebar {
    display: none;
  }

  .footer {
    display: none;
  }

  .sidebar-toggle {
    display: none !important;
  }

  .locale-selection-container {
    display: none;
  }

  .env-info {
    display: none;
  }

  .main {
    margin: 0 !important;
  }
`;

const tableHeaders = css`
  .category-header-container {
    padding: 15px 20px;
    background-color: ${getStyledThemeColor('gray150')};
    color: ${getStyledThemeColor('white')} !important;

    .header-title {
      margin: 0;
      padding: 0;

      :first-of-type {
        margin-right: 10px;
      }

      &:not(:last-of-type) {
        margin-right: 5px;
      }

      &:not(:first-of-type) {
        text-align: center;
      }
    }
  }
`;

const modals = css`
  .modal {
    .modal-dialog {
      .modal-content {
        .modal-header {
          height: auto;
          padding: 20px 20px !important;

          .modal-title {
            width: 100%;
            font-size: 20px;
            text-align: center;
          }
        }

        .modal-body {
          padding: 15px 20px;
        }

        .modal-footer {
          padding: 15px 20px;

          > div {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
          }

          .btn {
            min-width: unset;
            padding-left: 20px;
            padding-right: 20px;
          }
        }
      }

      &.add-billing-info-modal,
      &.update-billing-info-modal {
        margin: 0;

        .modal-header {
          position: sticky;
          top: 0;
          z-index: 1;
        }

        .modal-header,
        form,
        .modal-body,
        .modal-content,
        .modal-footer {
          border-radius: 0 0 0 0 !important;
        }

        form {
          input,
          select {
            max-width: 100%;
          }

          .form-group {
            margin-bottom: 0;

            > div {
              &:first-of-type {
                flex-wrap: nowrap;
                max-width: 46%;
              }

              > div {
                max-width: 100%;
                margin: 0 4%;
              }
            }
          }
        }
      }
    }
  }
`;

export default createGlobalStyle`
  ${mainWrappersAdjustments};

  ${hiddenWebElements};

  ${modals};

  ${tableHeaders};
`;
