import React from 'react';
import {
  Mic,
  Camera,
  Cast,
  Bell,
  BarChart,
  Disc,
  Codepen,
  Cpu,
  Globe,
  Box,
  Briefcase,
  Trash2,
  Database,
  Key,
  Monitor,
  Rss,
  Users,
  Phone,
  Package,
  Settings,
  Pocket,
  Video,
  Volume,
  Sliders,
  Power,
  Inbox,
  Speaker,
  Watch,
  Voicemail,
  Terminal,
  Lock,
  Grid,
  Tablet,
  Folder,
  CreditCard,
  Calendar,
  Archive,
  Aperture,
  Coffee,
  Eye,
  HardDrive,
  Layers,
  Server,
  Battery,
  Target,
  Zap,
  LifeBuoy,
  Printer,
} from 'react-feather';

import { IconPickerProps } from '@portals/core';
import { DeviceModelIconName } from '@portals/types';

export const DEVICE_MODEL_ICONS: IconPickerProps<DeviceModelIconName>['icons'] =
  {
    Mic: {
      name: 'Mic',
      component: <Mic />,
      color: 'primary',
    },
    Camera: {
      name: 'Camera',
      component: <Camera />,
      color: 'primary',
    },
    Cast: {
      name: 'Cast',
      component: <Cast />,
      color: 'primary',
    },
    Bell: {
      name: 'Bell',
      component: <Bell />,
      color: 'primary',
    },
    BarChart: {
      name: 'BarChart',
      component: <BarChart />,
      color: 'primary',
    },
    Disc: {
      name: 'Disc',
      component: <Disc />,
      color: 'primary',
    },
    Codepen: {
      name: 'Codepen',
      component: <Codepen />,
      color: 'primary',
    },
    Cpu: {
      name: 'Cpu',
      component: <Cpu />,
      color: 'primary',
    },
    Globe: {
      name: 'Globe',
      component: <Globe />,
      color: 'primary',
    },
    Box: {
      name: 'Box',
      component: <Box />,
      color: 'primary',
    },
    Briefcase: {
      name: 'Briefcase',
      component: <Briefcase />,
      color: 'primary',
    },
    Trash2: {
      name: 'Trash2',
      component: <Trash2 />,
      color: 'primary',
    },
    Database: {
      name: 'Database',
      component: <Database />,
      color: 'primary',
    },
    Key: {
      name: 'Key',
      component: <Key />,
      color: 'primary',
    },
    Monitor: {
      name: 'Monitor',
      component: <Monitor />,
      color: 'primary',
    },
    Rss: {
      name: 'Rss',
      component: <Rss />,
      color: 'primary',
    },
    Users: {
      name: 'Users',
      component: <Users />,
      color: 'primary',
    },
    Phone: {
      name: 'Phone',
      component: <Phone />,
      color: 'primary',
    },
    Package: {
      name: 'Package',
      component: <Package />,
      color: 'primary',
    },
    Settings: {
      name: 'Settings',
      component: <Settings />,
      color: 'primary',
    },
    Pocket: {
      name: 'Pocket',
      component: <Pocket />,
      color: 'primary',
    },
    Video: {
      name: 'Video',
      component: <Video />,
      color: 'primary',
    },
    Volume: {
      name: 'Volume',
      component: <Volume />,
      color: 'primary',
    },
    Sliders: {
      name: 'Sliders',
      component: <Sliders />,
      color: 'primary',
    },
    Power: {
      name: 'Power',
      component: <Power />,
      color: 'primary',
    },
    Inbox: {
      name: 'Inbox',
      component: <Inbox />,
      color: 'primary',
    },
    Speaker: {
      name: 'Speaker',
      component: <Speaker />,
      color: 'primary',
    },
    Watch: {
      name: 'Watch',
      component: <Watch />,
      color: 'primary',
    },
    Voicemail: {
      name: 'Voicemail',
      component: <Voicemail />,
      color: 'primary',
    },
    Terminal: {
      name: 'Terminal',
      component: <Terminal />,
      color: 'primary',
    },
    Lock: {
      name: 'Lock',
      component: <Lock />,
      color: 'primary',
    },
    Grid: {
      name: 'Grid',
      component: <Grid />,
      color: 'primary',
    },
    Tablet: {
      name: 'Tablet',
      component: <Tablet />,
      color: 'primary',
    },
    Folder: {
      name: 'Folder',
      component: <Folder />,
      color: 'primary',
    },
    CreditCard: {
      name: 'CreditCard',
      component: <CreditCard />,
      color: 'primary',
    },
    Calendar: {
      name: 'Calendar',
      component: <Calendar />,
      color: 'primary',
    },
    Archive: {
      name: 'Archive',
      component: <Archive />,
      color: 'primary',
    },
    Aperture: {
      name: 'Aperture',
      component: <Aperture />,
      color: 'primary',
    },
    Coffee: {
      name: 'Coffee',
      component: <Coffee />,
      color: 'primary',
    },
    Eye: {
      name: 'Eye',
      component: <Eye />,
      color: 'primary',
    },
    HardDrive: {
      name: 'HardDrive',
      component: <HardDrive />,
      color: 'primary',
    },
    Layers: {
      name: 'Layers',
      component: <Layers />,
      color: 'primary',
    },
    Server: {
      name: 'Server',
      component: <Server />,
      color: 'primary',
    },
    Battery: {
      name: 'Battery',
      component: <Battery />,
      color: 'primary',
    },
    Target: {
      name: 'Target',
      component: <Target />,
      color: 'primary',
    },
    Zap: {
      name: 'Zap',
      component: <Zap />,
      color: 'primary',
    },
    LifeBuoy: {
      name: 'LifeBuoy',
      component: <LifeBuoy />,
      color: 'primary',
    },
    Printer: {
      name: 'Printer',
      component: <Printer />,
      color: 'primary',
    },
  };
