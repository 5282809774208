import { Button, Modal } from '@mantine/core';
import React, { ReactNode } from 'react';

import { ModalBody, ModalFooter } from '@portals/core';

import { ModalProps } from '../components/Modals';

export interface AlertModalProps
  extends ModalProps<{
    title: ReactNode;
    description: ReactNode;
    buttonLabel?: ReactNode;
  }> {}

export function AlertModal({
  closeMe,
  data: { title, description, buttonLabel = 'OK' },
}: AlertModalProps) {
  return (
    <Modal opened onClose={closeMe} title={title}>
      <ModalBody>{description}</ModalBody>
      <ModalFooter position="right">
        <Button onClick={closeMe}>{buttonLabel}</Button>
      </ModalFooter>
    </Modal>
  );
}
