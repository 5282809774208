import { UseMutationResult } from '@tanstack/react-query';
import React, { createContext, ReactNode, useContext } from 'react';

import {
  DeviceModelType as OrgDeviceModelType,
  DeviceType as OrgDeviceType,
} from '@portals/api/organizations';
import { DeviceModelSummaryType as PartnerDeviceModelType } from '@portals/api/partners';
import {
  CommonFeatureFlagsType,
  CommonPortalCapabilities,
  DeviceType as PartnerDeviceType,
  TabType,
} from '@portals/types';

type CommonDeviceType = OrgDeviceType | PartnerDeviceType;
type CommonDeviceModelType = OrgDeviceModelType | PartnerDeviceModelType;

export interface DeviceContextType<
  DeviceType extends OrgDeviceType | PartnerDeviceType = CommonDeviceType,
  DeviceModelType extends
    | OrgDeviceModelType
    | PartnerDeviceModelType = CommonDeviceModelType,
  FeatureFlagsType extends CommonFeatureFlagsType = CommonFeatureFlagsType,
  PortalCapabilities extends CommonPortalCapabilities = CommonPortalCapabilities
> {
  onUpdateDevice: (device: Partial<DeviceType>) => void;
  onSnoozeDevice: (minutes: number) => void;
  onUnsnoozeDevice: () => void;
  onRefresh: () => void;
  tabs: Array<
    TabType<
      FeatureFlagsType,
      PortalCapabilities,
      {
        device: DeviceType;
        model: DeviceModelType;
      },
      any,
      DeviceType
    >
  >;
  isUpdatingDevice: boolean;
  withSupportCenter: boolean;
  onDeleteDevice: () => void;
  onCancelReplace: UseMutationResult<any, any, { deviceId: string }>;
  onApproveReplace: UseMutationResult<any, any, { deviceId: string }>;
  onReplace: UseMutationResult<
    any,
    any,
    {
      deviceId: string;
      sn: string;
      notes?: string;
    }
  >;
}

const DeviceContext = createContext<DeviceContextType>(undefined);

export function DeviceContextProvider<
  DeviceType extends CommonDeviceType,
  DeviceModelType extends CommonDeviceModelType,
  FeatureFlagsType extends CommonFeatureFlagsType,
  PortalCapabilities extends CommonPortalCapabilities
>({
  children,
  onUpdateDevice,
  onSnoozeDevice,
  onUnsnoozeDevice,
  onRefresh,
  tabs,
  isUpdatingDevice,
  onDeleteDevice,
  onCancelReplace,
  onApproveReplace,
  onReplace,
  withSupportCenter,
}: DeviceContextType<
  DeviceType,
  DeviceModelType,
  FeatureFlagsType,
  PortalCapabilities
> & {
  children: ReactNode;
}) {
  return (
    <DeviceContext.Provider
      value={{
        onUpdateDevice,
        onSnoozeDevice,
        onUnsnoozeDevice,
        onRefresh,
        tabs,
        isUpdatingDevice,
        onDeleteDevice,
        onCancelReplace,
        onApproveReplace,
        onReplace,
        withSupportCenter,
      }}
    >
      {children}
    </DeviceContext.Provider>
  );
}

export function useDeviceContext<
  DeviceType extends CommonDeviceType,
  DeviceModelType extends CommonDeviceModelType,
  FeatureFlagsType extends CommonFeatureFlagsType,
  PortalCapabilities extends CommonPortalCapabilities
>() {
  return useContext(DeviceContext) as DeviceContextType<
    DeviceType,
    DeviceModelType,
    FeatureFlagsType,
    PortalCapabilities
  >;
}
