import {
  Box,
  createStyles,
  Group,
  Spoiler,
  Stack,
  Text,
  Timeline,
  TimelineProps,
} from '@mantine/core';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React from 'react';

import {
  InvoiceTimelineEventType,
  InvoiceTimelineEventTypeType,
} from '@portals/types';

import { ReactComponent as FailureIcon } from '../../assets/img/icons/failure.svg';
import { ReactComponent as SuccessIcon } from '../../assets/img/icons/success.svg';

dayjs.extend(relativeTime);

interface InvoiceTimelineProps {
  timelineEvents: InvoiceTimelineEventType[];
}

export function InvoiceTimeline({ timelineEvents }: InvoiceTimelineProps) {
  const { classes } = useStyles();

  const getTimelineItemContent = (
    createdAt: string | null,
    description: string
  ) => {
    const time = dayjs(createdAt).format('MMM D, YYYY h:mm');

    return (
      <Stack spacing={2}>
        <Text size="xs" color="gray.5" truncate>
          {description}
        </Text>
        <Text size="xs" color="gray.5">
          {time}
        </Text>
      </Stack>
    );
  };

  const getBullet = (evenType: InvoiceTimelineEventTypeType) => {
    if (
      evenType === 'credit_card_payment_received' ||
      evenType === 'purchase_order_approved'
    ) {
      return <SuccessIcon />;
    } else if (evenType === 'credit_card_payment_failed') {
      return <FailureIcon />;
    }

    return null;
  };

  return (
    <Stack className={classes.container} spacing="xl">
      <Text size="md" weight={600}>
        Timeline
      </Text>

      <Spoiler
        maxHeight={200}
        transitionDuration={500}
        hideLabel={
          <Text color="gray.8" className={classes.spoilerHideLabel}>
            Hide older
          </Text>
        }
        showLabel={
          <Group w="100%" position="center" pt="sm" pos="relative">
            <Text color="gray.8" className={classes.spoilerShowLabel}>
              Show more
            </Text>

            <Box
              sx={{
                zIndex: 1,
                position: 'absolute',
                top: -24,
                left: 0,
                right: -20,
                height: 48,
                width: '100%',
                transform: 'translateY(-50%)',
                background:
                  'linear-gradient(180deg, rgba(249, 250, 251, 0.00) 0%, #F9FAFB 100%)',
              }}
            />
          </Group>
        }
        styles={() => ({
          root: { display: 'flex', flexDirection: 'column' },
          button: { width: '100%', userSelect: 'none' },
        })}
      >
        <Timeline color="gray" lineWidth={2} styles={timelineStyles}>
          {timelineEvents?.map((event) => (
            <Timeline.Item
              key={event.id}
              title={event.title}
              bulletSize={16}
              bullet={getBullet(event.type)}
              pl="md"
            >
              {getTimelineItemContent(event.created_at, event.description)}
            </Timeline.Item>
          ))}
        </Timeline>
      </Spoiler>
    </Stack>
  );
}

const timelineStyles: TimelineProps['styles'] = (theme) => ({
  item: {
    '&:not(:first-child)': {
      marginTop: `${theme.spacing.lg} !important`,
    },
  },

  itemTitle: {
    fontWeight: 400,
    color: theme.colors.gray[8],
    marginBottom: 2,
  },
});

const useStyles = createStyles((theme) => ({
  container: {
    backgroundColor: theme.fn.rgba(theme.colors.blue_gray[0], 0.3),
    borderRadius: theme.radius.lg,
    padding: theme.spacing.xxl,
  },

  spoilerShowLabel: {
    textDecoration: 'underline',
  },

  spoilerHideLabel: {
    textDecoration: 'underline',
    paddingTop: theme.spacing.sm,
  },
}));
