export const PURCHASED_PRODUCTS_API_URL = 'ui/organization/purchased_products';

export const purchasedProductsQueryKeys = {
  base: [PURCHASED_PRODUCTS_API_URL],
  list: () => [...purchasedProductsQueryKeys.base, 'list'],
  detail: (productId: string) => [
    ...purchasedProductsQueryKeys.base,
    productId,
  ],
  physical: () => [...purchasedProductsQueryKeys.list(), 'physical'],
  license: () => [...purchasedProductsQueryKeys.list(), 'license'],
};
