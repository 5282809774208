import { Point } from 'geojson';
import { Coords } from 'google-map-react';
import {
  capitalize,
  find,
  findIndex,
  getOr,
  groupBy,
  isEmpty,
  isNumber,
  keys,
  reduce,
} from 'lodash/fp';
import React, { useMemo } from 'react';

import {
  DeviceDetailsType,
  SpaceType,
  useDevice,
  useDeviceLastKnownState,
  useSpace,
  useSpaces,
} from '@portals/api/organizations';
import {
  DeviceLocationMarker,
  DeviceLocationType,
  DeviceLocationWidget,
  DeviceLocationWidgetFormType,
  DeviceMapConfig,
} from '@portals/device-widgets';
import { useOpenRouteModal } from '@portals/framework/route-modals';
import {
  DEFAULT_MAP_CENTER,
  jitterPosition,
  useGetLocation,
} from '@portals/utils';

import { ChildDeviceLocationMarker } from './ChildDeviceLocationMapMarker';
import { WidgetProps } from '../../device-widgets.types';

export function getDeviceCoordinates(
  deviceState: DeviceDetailsType['state'] | undefined,
  space: SpaceType
): Coords {
  if (isNumber(deviceState?.lat) && isNumber(deviceState?.lng)) {
    return {
      lat: deviceState.lat,
      lng: deviceState.lng,
    };
  }

  if (!isEmpty(space?.config?.location)) {
    const { lat, lng } = getOr(
      DEFAULT_MAP_CENTER,
      'config.location.location',
      space
    );

    return {
      lat: parseFloat(lat),
      lng: parseFloat(lng),
    };
  }

  return DEFAULT_MAP_CENTER;
}

export function childrenToPoints(devicesMapConfig: DeviceMapConfig[]) {
  return devicesMapConfig
    .map((deviceMapConfig): GeoJSON.Feature<Point> | null => {
      return {
        type: 'Feature',
        id: deviceMapConfig.id,
        properties: { cluster: false, ...deviceMapConfig },
        geometry: {
          type: 'Point',
          coordinates: [
            deviceMapConfig.coordinates.lng,
            deviceMapConfig.coordinates.lat,
          ],
        },
      };
    })
    .filter((item) => item !== null);
}

function getCoordinatesKey(coordinates: Coords) {
  return `${coordinates.lat}-${coordinates.lng}`;
}

export function DeviceLocationWidgetWrapper({
  widget,
  deviceId,

  isDeviceOffline,
  lastUpdateTimestamp,
  deviceState,
}: WidgetProps<DeviceLocationWidgetFormType>) {
  const deviceLastKnownState = useDeviceLastKnownState(deviceId);

  const fields = widget?.config?.fields;
  const openRouteModal = useOpenRouteModal();

  const { color, show_location_name } = fields ?? {};

  const device = useDevice(deviceId);
  const space = useSpace({ spaceId: device.data?.space_id });
  const spaces = useSpaces();

  const baseLocation = useGetLocation(space);

  const devicesMapConfig = useMemo<DeviceMapConfig[]>(() => {
    if (!device.data || !deviceLastKnownState.data?.state) return [];

    const mainDeviceCoordinates = getDeviceCoordinates(deviceState, space);

    const currentDevice = {
      type: DeviceLocationType.Main,
      id: device.data?.id,
      coordinates: mainDeviceCoordinates,
      name: device.data?.name,
      status: device.data?.state?.status,
      renderSinglePoint: () => (
        <DeviceLocationMarker
          key={device.data.id}
          title={capitalize(device.data.name)}
          showLocationName={show_location_name}
          color={color}
          isDeviceOffline={isDeviceOffline}
          lastUpdateTimestamp={lastUpdateTimestamp}
          status={device.data.state?.status}
          lat={mainDeviceCoordinates.lat}
          lng={mainDeviceCoordinates.lng}
        />
      ),
    };

    // Device has no children, render only the main device marker on map
    if (!device.data.child_devices_count) {
      return [currentDevice];
    }

    const allDevicesCoordinates = [
      // Main device
      {
        id: device.data.id,
        coordinates: mainDeviceCoordinates,
      },

      // Child devices
      ...device.data.child_devices.map((childDevice) => {
        const childSpaceData = find(
          { id: childDevice.space_id },
          spaces.data
        ) as SpaceType;

        return {
          id: childDevice.id,
          coordinates: getDeviceCoordinates(childDevice.state, childSpaceData),
        };
      }),
    ];

    // When number of devices are at exact same coordinates, we need to jitter them
    // The jittering logic depends on number of devices on given coordinate
    const groupedByCoordinates = groupBy(
      ({ coordinates }) => getCoordinatesKey(coordinates),
      allDevicesCoordinates
    );

    // Here we calculate how many devices are at each coordinate:
    // Input: { [coordA]: [device a, device b], [coordB]: [device c, device d] }
    // Output: { [coordA]: 2, [coordB]: 2 }
    const numOfDevicesPerCoord = reduce(
      (acc, coordinate) => ({
        ...acc,
        [coordinate]: groupedByCoordinates[coordinate].length,
      }),
      {},
      keys(groupedByCoordinates)
    );

    const childDevices: DeviceMapConfig[] = device.data?.child_devices.map(
      (childDevice, index) => {
        const childSpaceData = find(
          { id: childDevice.space_id },
          spaces.data
        ) as SpaceType;

        let coordinates = getDeviceCoordinates(
          childDevice?.state,
          childSpaceData
        );

        // If there are multiple devices at the same coordinates, we need to jitter them
        if (numOfDevicesPerCoord[getCoordinatesKey(coordinates)] > 1) {
          const deviceIndexInCoordGroup = findIndex(
            { id: childDevice.id },
            groupedByCoordinates[getCoordinatesKey(coordinates)]
          );

          coordinates = jitterPosition(
            coordinates,
            deviceIndexInCoordGroup,
            numOfDevicesPerCoord[getCoordinatesKey(coordinates)]
          );
        }

        return {
          type: DeviceLocationType.Child,
          id: childDevice.id,
          coordinates,
          name: childDevice?.name,
          status: childDevice?.state?.status,
          onClick: () =>
            openRouteModal({ modalId: 'device', pathParams: [childDevice.id] }),
          renderSinglePoint: () => (
            <ChildDeviceLocationMarker
              key={childDevice.id}
              title={childDevice.name}
              device={childDevice}
              status={childDevice?.state?.status}
              lat={coordinates.lat}
              lng={coordinates.lng}
            />
          ),
        };
      }
    );

    return [currentDevice, ...childDevices];
  }, [
    color,
    device.data,
    deviceLastKnownState.data?.state,
    deviceState,
    isDeviceOffline,
    lastUpdateTimestamp,
    openRouteModal,
    show_location_name,
    space,
    spaces.data,
  ]);

  const points = useMemo(
    () => childrenToPoints(devicesMapConfig),
    [devicesMapConfig]
  );

  return (
    <DeviceLocationWidget
      title={space.tree_path_name}
      color={color}
      showLocationName={show_location_name}
      isDeviceOffline={isDeviceOffline}
      lastUpdateTimestamp={lastUpdateTimestamp}
      points={points}
      base={baseLocation}
    />
  );
}
