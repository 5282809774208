import { Box, createStyles, Group, Text } from '@mantine/core';
import { isEmpty, join, split } from 'lodash/fp';
import React, { FC } from 'react';

import { IncidentType } from '@portals/api/organizations';
import { RouteModalLink } from '@portals/framework/route-modals';
import { suppressPropagation } from '@portals/utils';

interface SpacePathProps {
  incident: IncidentType;
}

export const SpacePath: FC<SpacePathProps> = ({ incident }) => {
  const { classes } = useStyles();

  const spaceNames = split('/', incident.space_tree_path_name).slice(1);
  const firstSpaceName = spaceNames[0];
  const otherSpaceNames = spaceNames.slice(1);
  const spacePath = join(' / ', otherSpaceNames);
  const deviceName = incident.device_name;

  return (
    <Group noWrap sx={{ overflow: 'hidden' }} spacing={3}>
      <Text size="xs">{firstSpaceName} /</Text>
      <Text size="xs" className={classes.longText}>
        {spacePath}
      </Text>

      <Text size="xs">
        <Group align="center" spacing={4} noWrap>
          {isEmpty(spacePath) ? null : (
            <Text inline inherit>
              /
            </Text>
          )}

          <Box onClick={suppressPropagation()}>
            <RouteModalLink
              modalId="device"
              pathParams={[incident.device_id, 'incidents']}
            >
              {deviceName}
            </RouteModalLink>
          </Box>
        </Group>
      </Text>
    </Group>
  );
};

const useStyles = createStyles((theme) => ({
  longText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));
