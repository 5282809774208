import { createStyles, Group, Stack, Text } from '@mantine/core';
import React from 'react';

import { ReactComponent as NoSearchResultsIcon } from './no-search-results.svg';

interface NoSearchResultsProps {
  searchValue: string;
}

export function EmptySearchResults({ searchValue }: NoSearchResultsProps) {
  const { classes } = useStyles();

  return (
    <Stack justify="center" align="center" className={classes.container}>
      <NoSearchResultsIcon />

      <Text size="sm" weight={600} align="center" data-testid="no-results-text">
        No results found
      </Text>

      <Stack spacing={0}>
        <Group spacing={5} position="center">
          <Text size="xs" color="dimmed" align="center">
            We didn't find any matches for
          </Text>
          <Text
            weight={600}
            size="xs"
            color="dark"
            align="center"
            sx={{ wordBreak: 'break-word' }}
          >
            "{searchValue}"
          </Text>
        </Group>
        <Text size="xs" color="dimmed" align="center">
          Try using different keywords
        </Text>
      </Stack>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
  },
}));
