import { keys, map } from 'lodash/fp';
import React, { FC, useCallback, useMemo, useState } from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import styled from 'styled-components';

import { Locale, LOCALE_FLAG, LOCALE_LABEL } from '../constants';
import { useLocalization } from '../hooks';

const LocaleSelection: FC<Record<string, never>> = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { locale, setLocale, locales } = useLocalization();

  const toggle = useCallback(() => setIsOpen((curr) => !curr), []);

  const localesList = useMemo(
    () =>
      map(
        (currLocale: Locale) => (
          <DropdownItem
            key={currLocale}
            onClick={() => setLocale(currLocale)}
            disabled={currLocale !== Locale.en}
            aria-disabled={currLocale !== Locale.en}
          >
            <img
              src={LOCALE_FLAG[currLocale]}
              alt={LOCALE_LABEL[currLocale]}
              width={20}
              className="align-middle mr-1"
            />
            <span className="align-middle">{LOCALE_LABEL[currLocale]}</span>
          </DropdownItem>
        ),
        keys(locales)
      ),
    [setLocale, locales]
  );

  return (
    <Container className="locale-selection-container">
      <Dropdown isOpen={isOpen} toggle={toggle} tag="div">
        <DropdownToggle
          nav
          tag="li"
          data-toggle="dropdown"
          aria-expanded={isOpen}
          className="nav-flag"
        >
          <img src={LOCALE_FLAG[locale]} alt={LOCALE_LABEL[locale]} />
        </DropdownToggle>

        <DropdownMenu>{localesList}</DropdownMenu>
      </Dropdown>
    </Container>
  );
};

const Container = styled.div`
  margin-right: 10px;

  .nav-flag {
    cursor: pointer;
  }

  .dropdown-menu {
    top: 40px !important;
    left: -70px !important;

    .disabled {
      opacity: 0.5;
    }
  }
`;

export default LocaleSelection;
