import { useMemo } from 'react';

import {
  bmcSrc,
  connectWiseSrc,
  emailSrc,
  freshServiceSrc,
  gchatSrc,
  jiraSrc,
  msTeamsLogo,
  salesforceSrc,
  servicenowSrc,
  slackSrc,
  solarWindSrc,
  sysaidSrc,
  webexSrc,
  webhooksSrc,
  zendeskSrc,
  zohoDeskSrc,
} from '@portals/assets';
import { useIntegrations } from '@portals/redux';

import {
  IntegrationCategoryEnum,
  IntegrationsGroupType,
} from './integrations.types';

export function useIncidentIntegrations() {
  const integrations = useIntegrations();

  const incidentIntegrations: Array<IntegrationsGroupType> = useMemo(
    () => [
      {
        category: IntegrationCategoryEnum.Ticketing,
        integrations: [
          {
            id: 'service_now',
            name: 'ServiceNow',
            logo: servicenowSrc,
            owner: 'XYTE',
            description:
              'The ServiceNow integration allows organizations to connect their ServiceNow platform with other systems and applications to automate and streamline their IT service management processes.',
            isComingSoon: false,
            status: integrations['service_now']?.state,
            link: '/settings/integrations/ticketing/service_now',
          },
          {
            id: 'salesforce',
            name: 'Salesforce',
            logo: salesforceSrc,
            owner: 'XYTE',
            description:
              'The Salesforce integration allows businesses to connect their customer relationship management system with Xyte platform to improve their sales, customer service, and marketing operations.',
            isComingSoon: false,
            status: integrations['salesforce']?.state,
            link: '/settings/integrations/ticketing/salesforce',
          },
          {
            id: 'jira',
            name: 'Jira',
            logo: jiraSrc,
            owner: 'XYTE',
            description:
              'The Jira integration allows teams to connect their project management and issue tracking platform with Xyte platform to streamline their workflow and improve collaboration.',
            isComingSoon: false,
            status: integrations['jira']?.state,
            link: '/settings/integrations/ticketing/jira',
          },
          {
            id: 'zoho',
            name: 'Zoho desk',
            logo: zohoDeskSrc,
            owner: 'XYTE',
            description:
              'The Zoho Desk integration allows businesses to connect their customer support system with the Xyte platform to improve ticket management, customer service, and operational efficiency.',
            isComingSoon: true,
            status: integrations['zoho']?.state,
            link: null,
          },
          {
            id: 'fresh_service',
            name: 'FreshService',
            logo: freshServiceSrc,
            owner: 'XYTE',
            description:
              'The Freshservice integration allows organizations to connect their IT service management platform with Xyte to automate and streamline their IT support processes.',
            isComingSoon: false,
            status: integrations['fresh_service']?.state,
            link: '/settings/integrations/ticketing/fresh_service',
          },
          {
            id: 'connect_wise',
            name: 'ConnectWise',
            logo: connectWiseSrc,
            owner: 'XYTE',
            description:
              'The ConnectWise integration allows businesses to connect their services automation software with Xyte platform to improve their service delivery and customer management processes.',
            isComingSoon: false,
            status: integrations['connect_wise']?.state,
            link: '/settings/integrations/ticketing/connect_wise',
          },
          {
            id: 'zenDesk',
            name: 'ZenDesk',
            logo: zendeskSrc,
            owner: 'XYTE',
            description:
              'The Zendesk integration allows businesses to connect their customer support platform with Xyte platform to enhance ticket management, customer service, and operational workflows.',
            isComingSoon: true,
            status: integrations['zenDesk']?.state,
            link: null,
          },
          {
            id: 'bmcHelix',
            name: 'BMC Helix',
            logo: bmcSrc,
            owner: 'XYTE',
            description:
              'The BMC Helix integration enables businesses to connect their IT service management system with Xyte platform to improve incident response, service delivery, and workflow automation.',
            isComingSoon: true,
            status: integrations['bmcHelix']?.state,
            link: null,
          },
          {
            id: 'solarWinds',
            name: 'Solar Winds',
            logo: solarWindSrc,
            owner: 'XYTE',
            description:
              'The SolarWinds integration allows businesses to connect their IT monitoring tools with the Xyte platform to improve infrastructure management, system monitoring, and operational efficiency.',
            isComingSoon: true,
            status: integrations['solarWinds']?.state,
            link: null,
          },
          {
            id: 'sysAid',
            name: 'SysAid',
            logo: sysaidSrc,
            owner: 'XYTE',
            description:
              'The SysAid integration enables businesses to connect their IT service management platform with the Xyte platform to enhance service desk operations, ticket management, and workflow automation.',
            isComingSoon: true,
            status: integrations['sysAid']?.state,
            link: null,
          },
        ],
      },
      {
        category: IntegrationCategoryEnum.Messaging,
        integrations: [
          {
            id: 'email',
            name: 'Email',
            logo: emailSrc,
            owner: 'XYTE',
            description:
              'The Email integration allows businesses to connect their email system with the Xyte platform to streamline their incidents communication and support.',
            isComingSoon: false,
            status: integrations['email']?.state,
            link: '/settings/integrations/email/email',
          },
          {
            id: 'gchat',
            name: 'Google Chat',
            logo: gchatSrc,
            owner: 'XYTE',
            description:
              'The Google Chat integration allows businesses to connect their messaging platform with the Xyte platform to improve team collaboration, communication, and productivity.',
            isComingSoon: false,
            status: integrations['gchat']?.state,
            link: '/settings/integrations/messaging/gchat',
          },
          {
            id: 'msteams',
            name: 'Microsoft Teams',
            logo: msTeamsLogo,
            owner: 'XYTE',
            description:
              'The Microsoft Teams integration allows businesses to connect their messaging platform with the Xyte platform to improve collaboration, communication, and workflow management.',
            isComingSoon: false,
            status: integrations['msteams']?.state,
            link: '/settings/integrations/messaging/msteams',
          },
          {
            id: 'slack',
            name: 'Slack',
            logo: slackSrc,
            owner: 'XYTE',
            description:
              'The Slack integration allows businesses to connect their messaging platform with the Xyte platform to improve collaboration, communication, and workflow management.',
            isComingSoon: false,
            status: integrations['slack']?.state,
            link: '/settings/integrations/messaging/slack',
          },
          {
            id: 'cisco_teams',
            name: 'WebEx Teams',
            logo: webexSrc,
            owner: 'XYTE',
            description:
              'The WebEx Teams integration enables businesses to connect their collaboration tools with the Xyte platform to optimize communication, meetings, and project coordination.',
            isComingSoon: false,
            status: integrations['cisco_teams']?.state,
            link: '/settings/integrations/messaging/cisco_teams',
          },
          {
            id: 'web_hook',
            name: 'Webhooks',
            logo: webhooksSrc,
            owner: 'XYTE',
            description:
              'The Webhooks integration enables businesses to connect their event-driven systems with the Xyte platform to streamline automation, notifications, and system workflows.',
            isComingSoon: false,
            status: integrations['web_hook']?.state,
            link: '/settings/integrations/other/web_hook',
          },
        ],
      },
    ],
    [integrations]
  );

  return incidentIntegrations;
}
