import { Box, createStyles } from '@mantine/core';
import React from 'react';
import Geosuggest, { GeosuggestProps } from 'react-geosuggest';

export function GeosuggestField({
  placeholder,
  autoActivateFirstSuggest = true,
  initialValue,
  disabled,
  readOnly,
  onSuggestSelect,
}: GeosuggestProps) {
  const styles = useStyles();

  return (
    <Box className={styles.classes.container}>
      <Geosuggest
        placeholder={placeholder}
        inputClassName="form-control"
        autoActivateFirstSuggest={autoActivateFirstSuggest}
        initialValue={initialValue}
        disabled={disabled}
        readOnly={readOnly}
        onSuggestSelect={onSuggestSelect}
      />
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    zIndex: 1,

    '.geosuggest__input': {
      height: 40,

      '&:focus': {
        boxShadow: 'none',
        borderColor: theme.other.primaryColor,
      },
    },

    '.geosuggest__suggests': {
      width: '100%',
      position: 'absolute',
      background: 'white',
      zIndex: 1,
      boxShadow: `0px 11px 18px rgba(38, 50, 56, 0.1)`,
    },

    '.geosuggest__item': {
      padding: 10,
      display: 'flex',
      alignItems: 'center',
      fontSize: theme.fontSizes.sm,
      fontWeight: 300,
      color: theme.colors.gray[8],
      borderRadius: theme.radius.sm,

      '&:not(:last-child)': {
        marginBottom: theme.spacing.xs,
      },

      '&:hover': {
        background: theme.colors.gray[0],
      },

      '&.geosuggest__item--active': {
        background: theme.other.primaryColor,
        color: theme.white,
      },
    },
  },
}));
