import { Avatar, AvatarProps, Group, Stack, Text } from '@mantine/core';
import React, { ReactNode } from 'react';

import { NameAbbreviationAvatar } from '@portals/core';

interface AvatarCellProps extends AvatarProps {
  label: string;
  description?: ReactNode;
  withAbbreviation?: boolean;
  labelColor?: string;
}

export function AvatarCell({
  label,
  description,
  withAbbreviation = true,
  labelColor = 'gray.7',
  ...avatarProps
}: AvatarCellProps) {
  return (
    <Group noWrap sx={{ overflow: 'hidden' }}>
      {withAbbreviation ? (
        <NameAbbreviationAvatar name={label} radius="xl" {...avatarProps} />
      ) : (
        <Avatar radius="xl" color="blue" {...avatarProps} />
      )}

      <Stack spacing={2} sx={{ overflow: 'hidden' }}>
        <Text size="sm" color={labelColor} title={label} truncate>
          {label}
        </Text>

        {description && (
          <Text size="xs" color="gray.5" truncate>
            {description}
          </Text>
        )}
      </Stack>
    </Group>
  );
}
