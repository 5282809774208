import { TableColumn, TableState } from '@portals/types';

import {
  PURCHASED_PRODUCTS_API_URL,
  purchasedProductsQueryKeys,
} from './purchased-products.constants';
import { PurchasedProductType } from './purchased-products.types';
import { useApiQuery } from '../../hooks';
import { usePaginatedTableApiQuery } from '../../utils';

export const usePaginatedPurchasedProducts = (
  tableState: Pick<TableState<PurchasedProductType>, 'sortBy' | 'filters'>,
  columns: Array<TableColumn>,
  baseUrl = PURCHASED_PRODUCTS_API_URL
) =>
  usePaginatedTableApiQuery<PurchasedProductType>({
    baseUrl,
    queryKey: [...purchasedProductsQueryKeys.list(), baseUrl, tableState],
    tableState,
    columns,
  });

export function usePurchasedProduct(productId: string) {
  return useApiQuery<PurchasedProductType>(
    `${PURCHASED_PRODUCTS_API_URL}/${productId}`,
    purchasedProductsQueryKeys.detail(productId),
    {
      staleTime: 0,
    }
  );
}
