import { Box, Button, createStyles, Group, Stack, Text } from '@mantine/core';
import { ModalProps as MantineModalProps } from '@mantine/core/lib/Modal/Modal';
import { useMediaQuery } from '@mantine/hooks';
import { size } from 'lodash/fp';
import React from 'react';

import { useNonePaginatedInvoices } from '@portals/api/organizations';
import { ModalCenteredMediaLayout, ModalFooter } from '@portals/core';
import { ModalProps } from '@portals/framework';
import { ReactComponent as ArrowDown } from '@portals/icons/linear/arrow-down-1.svg';
import { ReactComponent as ArrowRight1 } from '@portals/icons/linear/arrow-right.svg';
import { VerticalScrollBar } from '@portals/scrollbar';
import { CreditCardType, PaginatedFilterTypeEnum } from '@portals/types';

import { ReactComponent as Order } from '../../assets/img/tick-credit-card.svg';
import { CardDetails } from '../pages/settings/credit-cards/CardDetails';
import { InvoicePayment } from '../pages/settings/credit-cards/InvoicePayment';

export interface PaymentsSuccessfullyTransferredModalProps
  extends ModalProps<{ oldCard: CreditCardType; newCard: CreditCardType }> {}

export function PaymentsSuccessfullyTransferredModal({
  closeMe,
  data: { oldCard, newCard },
}: PaymentsSuccessfullyTransferredModalProps) {
  const { classes, theme } = useStyles();
  const isSmallerThanMd = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

  const invoices = useNonePaginatedInvoices({
    filters: [
      { id: 'status', value: 'failed', type: PaginatedFilterTypeEnum.Eq },
    ],
  });

  return (
    <ModalCenteredMediaLayout
      opened
      onClose={closeMe}
      title="Payments successfully transferred"
      media={<Order />}
      styles={modalStyles}
    >
      <Stack spacing="xl">
        <Group
          position="center"
          spacing={isSmallerThanMd ? 'md' : 'xxl'}
          mb="xl"
          className={classes.cards}
        >
          <Stack align="center" spacing="xs">
            <Text color="blue_gray.7">From</Text>
            <CardDetails card={oldCard} withBrand={false} />
          </Stack>

          {isSmallerThanMd ? (
            <ArrowDown width={24} height={24} />
          ) : (
            <ArrowRight1 width={24} height={24} />
          )}

          <Stack align="center" spacing="xs">
            <Text color="blue_gray.7">To</Text>
            <CardDetails card={newCard} withBrand={false} />
          </Stack>
        </Group>

        {size(invoices.data) > 0 ? (
          <>
            <Stack spacing={0} align="center">
              <Text>
                Please note that you have {size(invoices.data)} failed payments
                from the previous card.
              </Text>
              <Text>Pay it now to keep your subscription active.</Text>
            </Stack>

            <Box h="100%">
              <VerticalScrollBar autoHeight>
                <Stack spacing="xl">
                  {invoices.data.map((invoice) => {
                    return (
                      <InvoicePayment key={invoice.id} invoice={invoice} />
                    );
                  })}
                </Stack>
              </VerticalScrollBar>
            </Box>
          </>
        ) : null}
      </Stack>

      <ModalFooter position="right" mt="xl">
        <Button
          variant="default"
          size="md"
          onClick={closeMe}
          data-testid="payment-successfully-transferred-done-button"
        >
          {size(invoices.data) ? 'I’ll do it later' : 'Done'}
        </Button>
      </ModalFooter>
    </ModalCenteredMediaLayout>
  );
}

const modalStyles: MantineModalProps['styles'] = (theme) => ({
  body: {
    padding: theme.spacing.xxl,
  },
});

const useStyles = createStyles((theme) => ({
  cards: {
    [theme.fn.smallerThan('md')]: {
      flexDirection: 'column',
    },
  },
}));
