import { CommandStatusType } from '@portals/types';

const COMMAND_STATUS_INFO = {
  [CommandStatusType.InProgress]: {
    color: 'blue',
    label: 'In progress',
  },
  [CommandStatusType.Done]: {
    color: 'green',
    label: 'Done',
  },
  [CommandStatusType.Aborted]: {
    color: 'orange',
    label: 'Aborted',
  },
  [CommandStatusType.Pending]: {
    color: 'indigo',
    label: 'Pending',
  },
  [CommandStatusType.Failed]: {
    color: 'red',
    label: 'Failed',
  },
};

export function getCommandStatusInfo(commandStatus: CommandStatusType) {
  return COMMAND_STATUS_INFO[commandStatus];
}
