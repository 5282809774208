import { createStyles, Stack, Text } from '@mantine/core';
import React, { ReactNode } from 'react';

interface ProductCardDetailsProps {
  name: string;
  subtitle?: string | null;
  children?: ReactNode;
}

export function ProductCardDetails({
  name,
  subtitle,
  children,
}: ProductCardDetailsProps) {
  const { classes } = useStyles();

  return (
    <Stack
      spacing="xs"
      p="xl"
      justify="space-between"
      className={classes.container}
    >
      <Stack spacing={4}>
        <Text
          truncate
          title={name}
          className={classes.title}
          data-testid="product-card-name"
        >
          {name}
        </Text>

        {subtitle ? (
          <Text
            truncate
            title={subtitle}
            className={classes.subtitle}
            data-testid="product-card-subtitle"
          >
            {subtitle}
          </Text>
        ) : null}
      </Stack>

      {children}
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    flexGrow: 1,
    borderTop: `1px solid ${theme.colors.gray[2]}`,
  },
  title: {
    fontSize: theme.fontSizes.xl,

    [theme.fn.smallerThan('xl')]: {
      fontSize: theme.fontSizes.md,
    },
  },
  subtitle: {
    fontWeight: 500,
    color: theme.colors.gray[5],
  },
}));
