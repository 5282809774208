import React from 'react';
import { Helmet, HelmetProps } from 'react-helmet-async';

import { useCSP } from '../hooks/csp';

interface MetaProps {
  title: HelmetProps['title'];
  links?: HelmetProps['link'];
  meta?: HelmetProps['meta'];
}

export function Meta({ title, links, meta = [] }: MetaProps) {
  const cspContent = useCSP();

  return (
    <Helmet defer={false} title={title}>
      <meta
        key="CSP"
        httpEquiv="Content-Security-Policy"
        content={cspContent}
      />

      {/* Launchnotes-embed */}
      <script
        src="https://embed.launchnotes.io/1.0.3/dist/esm/launchnotes-embed.js"
        async={false}
        type="module"
      />
      {/* Launchnotes-embed */}

      {meta.map((props, index) => (
        <meta {...props} key={index} />
      ))}

      {links ? (
        links.map((props, index) => <link key={index} {...props} />)
      ) : (
        <link href="favicon.ico" rel="icon" sizes="192x192" />
      )}
    </Helmet>
  );
}
