import { map } from 'lodash/fp';

import {
  IntegrationCategoryEnum,
  IntegrationsGroupType,
} from './integrations.types';

export function setIntegrationsWithEnabledCategory(
  integrations: Array<IntegrationsGroupType>
) {
  const enabledCategory: IntegrationsGroupType = {
    category: IntegrationCategoryEnum.Enabled,
    integrations: integrations.flatMap(({ integrations }) =>
      integrations.filter((integration) => integration.status === 'active')
    ),
  };

  return [
    enabledCategory,
    ...integrations.filter(
      ({ category }) => category !== IntegrationCategoryEnum.Enabled
    ),
  ];
}

function filterEmptyIntegrations(integrations: Array<IntegrationsGroupType>) {
  return integrations.filter(({ integrations }) => integrations.length > 0);
}

export function filterIntegrationsBySearchTerm({
  integrations,
  searchTerm,
}: {
  integrations: IntegrationsGroupType[];
  searchTerm: string;
}) {
  const filteredIntegrations = map(
    ({ integrations, category }) => ({
      category: category,
      integrations: integrations.filter((integration) =>
        integration.name.toLowerCase().includes(searchTerm)
      ),
    }),
    integrations
  );

  return filterEmptyIntegrations(filteredIntegrations);
}

export function filterIntegrationsByCategory({
  integrations,
  selectedCategory,
}: {
  integrations: Array<IntegrationsGroupType>;
  selectedCategory: IntegrationCategoryEnum;
}) {
  if (selectedCategory === IntegrationCategoryEnum.All) {
    return integrations;
  }

  return integrations.filter(({ category }) => category === selectedCategory);
}
