import { Button, Modal, Stack, Text } from '@mantine/core';
import { ModalProps as MantineModalProps } from '@mantine/core/lib/Modal/Modal';
import React from 'react';

import { useArchiveCreditCard } from '@portals/api/organizations';
import { ModalFooter } from '@portals/core';
import { ModalProps } from '@portals/framework';
import { CreditCardType } from '@portals/types';

import { useCheckout } from '../../redux/hooks/store';
import { CardDetails } from '../pages/settings/credit-cards/CardDetails';

export interface ArchiveCardModalProps
  extends ModalProps<{ card: CreditCardType }> {}

export function ArchiveCardModal({
  closeMe,
  data: { card },
}: ArchiveCardModalProps) {
  const archiveCreditCard = useArchiveCreditCard();

  const checkout = useCheckout();

  const onArchive = () => {
    archiveCreditCard.mutate(
      { creditCardId: card.id },
      {
        onSuccess: () => {
          if (checkout.creditCard.selected === card.id) {
            checkout.creditCard.onChange(null);
          }

          closeMe();
        },
      }
    );
  };

  return (
    <Modal
      opened
      onClose={closeMe}
      title="Archive card"
      data-testid="archive-card-modal"
      styles={modalStyles}
    >
      <Stack spacing="xs">
        <Text weight={500}>
          This card will no longer be available for payment:{' '}
        </Text>
        <CardDetails card={card} />
      </Stack>

      <ModalFooter position="right" mt="xxl">
        <Button variant="default" size="md" onClick={closeMe}>
          Cancel
        </Button>
        <Button
          size="md"
          color="red"
          onClick={onArchive}
          data-testid="archive-card-modal-archive-button"
        >
          Archive
        </Button>
      </ModalFooter>
    </Modal>
  );
}

const modalStyles: MantineModalProps['styles'] = (theme) => ({
  header: {
    padding: `${theme.spacing.xxl} ${theme.spacing.xxl} ${theme.spacing.md}`,
  },

  body: {
    padding: `${theme.spacing.xxl} ${theme.spacing.xxl} ${theme.spacing.xl}`,
  },
});
