import { Group, Badge, BadgeProps } from '@mantine/core';
import React, { FC, ReactNode } from 'react';

interface BadgeCellProps extends BadgeProps {
  label: ReactNode;
}

export const BadgeCell: FC<BadgeCellProps> = ({ label, ...badgeProps }) => (
  <Group>
    <Badge radius="sm" size="lg" {...badgeProps}>
      {label}
    </Badge>
  </Group>
);
