import {
  Button,
  Divider,
  Grid,
  Input,
  Paper,
  Popover,
  Stack,
  Text,
} from '@mantine/core';
import { DateInput, DatePicker, DatePickerProps } from '@mantine/dates';
import React from 'react';

import { ReactComponent as Calendar } from '@portals/icons/linear/calendar.svg';
import { formatDateTime } from '@portals/utils';

interface ChooseDateProps {
  isOpened: boolean;
  onSetIsOpened: (isOpened: boolean) => void;
  onSetDate: (date: Date) => void;
  date: Date | null;
}

export function ChooseDate({
  isOpened,
  date,
  onSetDate,
  onSetIsOpened,
}: ChooseDateProps) {
  return (
    <Paper withBorder radius="lg" p="xl">
      <Stack>
        <Text color="blue_gray.9" size={18}>
          Choose Date
        </Text>

        <Text color="gray.6">
          Choose the desired date using the date picker below to export your
          device's state history as a CSV file.
        </Text>

        <Popover
          opened={isOpened}
          position="bottom"
          closeOnClickOutside
          closeOnEscape
          onClose={() => onSetIsOpened(false)}
        >
          <Popover.Target>
            <Input
              component="button"
              data-testid="choose-date-input"
              icon={<Calendar />}
              error={formatDateTime(date) === 'Invalid Date'}
              onClick={() => onSetIsOpened(!isOpened)}
            >
              {formatDateTime(date, 'MMMM DD, YYYY')}
            </Input>
          </Popover.Target>

          <Popover.Dropdown py="lg" px="xl">
            <DatePicker
              maxDate={new Date(Date.now())}
              value={date}
              onChange={onSetDate}
              styles={datePickerStyles}
              weekendDays={[]}
            />

            <Divider my="lg" />

            <Grid>
              <Grid.Col span={4}>
                <DateInput
                  popoverProps={{
                    disabled: true,
                  }}
                  data-testid="date-data-input"
                  value={date}
                  onChange={(event) => onSetDate(new Date(event))}
                  valueFormat="MM/DD/YYYY"
                  maxDate={new Date(Date.now())}
                  minDate={new Date(2019, 1, 1)}
                />
              </Grid.Col>

              <Grid.Col span="auto" offset={2}>
                <Button
                  data-testid="cancel-export-button"
                  variant="default"
                  onClick={() => {
                    onSetIsOpened(false);
                    onSetDate(new Date(Date.now()));
                  }}
                >
                  Cancel
                </Button>
              </Grid.Col>

              <Grid.Col span="auto">
                <Button
                  data-testid="apply-date-button"
                  disabled={formatDateTime(date) === 'Invalid Date'}
                  onClick={() => onSetIsOpened(false)}
                >
                  Apply
                </Button>
              </Grid.Col>
            </Grid>
          </Popover.Dropdown>
        </Popover>
      </Stack>
    </Paper>
  );
}

const datePickerStyles: DatePickerProps['styles'] = {
  decadeLevelGroup: {
    justifyContent: 'center',
  },

  yearLevelGroup: {
    justifyContent: 'center',
  },

  monthLevelGroup: {
    justifyContent: 'center',
  },

  weekday: {
    paddingLeft: 10,
    paddingRight: 25,
  },

  calendarHeader: {
    maxWidth: 'unset',
  },

  monthCell: {
    '[data-selected]': {
      borderRadius: 8,
    },
  },
};
