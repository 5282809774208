import React from 'react';

import {
  CurrencyCode,
  OrganizationStoreListing,
  ProductPricingModel,
} from '@portals/types';

import { ProductPersonalizedPrice } from './ProductPersonalizedPrice';
import { ProductStandardPrice } from './ProductStandardPrice';
import { ProductUsageBasedPrice } from './ProductUsageBasedPrice';

interface ProductPriceProps {
  storeListing: OrganizationStoreListing;
  currencyCode: CurrencyCode | null;
}

export function ProductPrice({
  storeListing,
  currencyCode,
}: ProductPriceProps) {
  const { product } = storeListing;

  if (product.pricing_model === ProductPricingModel.Personalized) {
    return <ProductPersonalizedPrice currencyCode={currencyCode} />;
  }

  if (product.pricing_model === ProductPricingModel.Standard) {
    return <ProductStandardPrice />;
  }

  if (product.pricing_model === ProductPricingModel.UsageBased) {
    return <ProductUsageBasedPrice storeListing={storeListing} />;
  }

  return null;
}
