import { filter, includes } from 'lodash/fp';

import { SpaceType } from '@portals/api/organizations';

export function findSpaceChildren(
  spaces: SpaceType[] | undefined,
  selected: SpaceType
): SpaceType[] {
  if (!selected.parent_id) {
    return spaces || [];
  }

  return filter((space) => includes(selected.id, space.path), spaces);
}
