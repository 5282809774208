export function initGoogleMap() {
  if (process.env.NX_GOOGLE_KEY && !window.initMap) {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.NX_GOOGLE_KEY}&libraries=places&callback=initMap`;
    script.defer = true;

    // Attach your callback function to the `window` object
    window.initMap = () => {
      // TODO: Enable map api usage
    };

    // Append the 'script' element to 'head'
    document.head.appendChild(script);
  }
}

declare global {
  interface Window {
    initMap: () => void;
  }
}
