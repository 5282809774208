import {
  Button,
  createStyles,
  Group,
  Modal,
  Stack,
  Text,
  Textarea,
  TextInput,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { MIME_TYPES } from '@mantine/dropzone';
import { useForm } from '@mantine/form';
import React, { useState } from 'react';

import {
  useCreateDeviceWarranty,
  useUpdateDeviceWarranty,
  WarrantyType,
} from '@portals/api/organizations';
import {
  FileUploaderDropzone,
  ModalFooter,
  useFileUploaderDropzone,
} from '@portals/core';
import { ModalProps } from '@portals/framework';
import { ReactComponent as ArrowRight } from '@portals/icons/linear/arrow-right.svg';
import { ReactComponent as Calendar } from '@portals/icons/linear/calendar.svg';

export interface UploadWarrantyModalData {
  deviceId: string;
  warrantyToEdit?: WarrantyType;
}

interface AddWarrantyProps extends ModalProps<UploadWarrantyModalData> {}

interface FormValues {
  name: string;
  description: string;
  startDate?: Date;
  endDate?: Date;
}

export function UploadWarranty({
  closeMe,
  data: { deviceId, warrantyToEdit },
}: AddWarrantyProps) {
  const { classes, cx } = useStyles();
  const createDeviceWarranty = useCreateDeviceWarranty(deviceId);
  const updateDeviceWarranty = useUpdateDeviceWarranty(deviceId);

  const isEditMode = warrantyToEdit !== undefined;

  const [isSubmitted, setIsSubmitted] = useState(false);

  const { values, getInputProps, onSubmit } = useForm<FormValues>({
    initialValues: {
      name: isEditMode ? warrantyToEdit.name : '',
      description: warrantyToEdit?.description || '',
      startDate: isEditMode ? new Date(warrantyToEdit.start_date) : undefined,
      endDate: isEditMode ? new Date(warrantyToEdit.end_date) : undefined,
    },
    validate: {
      name: (value) => (!value ? 'Required' : null),
      startDate: (value: FormValues['startDate']) =>
        !value ? 'Required' : null,
      endDate: (value: FormValues['endDate']) => (!value ? 'Required' : null),
    },
  });

  const onDeleteUploadedFile = (fileToDeleteUrl: string) => {
    const withoutFileToDelete = uploadedFileUrls?.filter(
      (fileUrl) => fileUrl !== fileToDeleteUrl
    );

    setUploadedFileUrls(withoutFileToDelete);
  };

  const {
    files,
    setFiles,
    isFilesEmpty,
    isLoading,
    uploadedFileUrls,
    setUploadedFileUrls,
  } = useFileUploaderDropzone({
    onDeleteUploadedFile,
    initialFileUrls: isEditMode
      ? [warrantyToEdit.warranty_document_url]
      : undefined,
  });

  const submitHandler = async ({
    name,
    startDate,
    endDate,
    description,
  }: FormValues) => {
    if (isEditMode) {
      if (!name || !startDate || !endDate) return;

      const updatedWarranty: WarrantyType = {
        ...warrantyToEdit,
        name,
        description,
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),

        warranty_document_url:
          files[0]?.fileUrl || warrantyToEdit?.warranty_document_url,
      };

      try {
        await updateDeviceWarranty.mutateAsync(updatedWarranty);

        closeMe();
      } catch (e) {
        console.error(e);
      }
    } else {
      if (!files[0]?.fileUrl || !name || !startDate || !endDate) return;

      try {
        await createDeviceWarranty.mutateAsync({
          name,
          start_date: startDate.toISOString(),
          end_date: endDate.toISOString(),
          description,
          warranty_document_url: files[0].fileUrl,
        });

        closeMe();
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <Modal
      opened
      size={680}
      padding={32}
      onClose={closeMe}
      title={isEditMode ? 'Update Warranty' : 'Upload Warranty'}
      styles={(theme) => ({ header: { marginBottom: theme.spacing.xl } })}
    >
      <form noValidate onSubmit={onSubmit(submitHandler)}>
        <Stack spacing={32}>
          <div>
            <FileUploaderDropzone
              files={files}
              setFiles={setFiles}
              uploadedFileUrls={uploadedFileUrls}
              onDeleteUploadedFile={onDeleteUploadedFile}
              dropzoneProps={{
                accept: [MIME_TYPES.pdf, MIME_TYPES.jpeg, MIME_TYPES.png],
                className: cx(classes.dropzone, {
                  [classes.dropzoneError]: isSubmitted && isFilesEmpty,
                }),
              }}
            />
            {isSubmitted && isFilesEmpty && (
              <Text color="red" size="sm" mt={4}>
                Required
              </Text>
            )}
          </div>

          <TextInput
            required
            label="Name"
            placeholder="Warranty name"
            {...getInputProps('name')}
          />

          <Group align="flex-start">
            <DatePickerInput
              required
              label="Start date"
              placeholder="Start date"
              maxDate={values.endDate}
              icon={<Calendar width={18} height={18} />}
              sx={{ flexGrow: 1 }}
              popoverProps={{ withinPortal: true }}
              {...getInputProps('startDate')}
            />

            <ArrowRight
              className={classes.arrowContainer}
              width={16}
              height={16}
            />

            <DatePickerInput
              required
              label="End date"
              placeholder="End date"
              minDate={values.startDate}
              icon={<Calendar width={18} height={18} />}
              sx={{ flexGrow: 1 }}
              popoverProps={{ withinPortal: true }}
              {...getInputProps('endDate')}
            />
          </Group>

          <Textarea
            maxRows={3}
            minRows={3}
            label="Short description (optional)"
            placeholder="Short description (optional)"
            {...getInputProps('description')}
          />

          <ModalFooter position="right">
            <Button variant="default" onClick={closeMe}>
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={() => setIsSubmitted(true)}
              disabled={
                isEditMode && !uploadedFileUrls?.length && !files[0]?.fileUrl
              }
              loading={
                isLoading ||
                createDeviceWarranty.isLoading ||
                updateDeviceWarranty.isLoading
              }
            >
              {isEditMode ? 'Update Warranty' : 'Add Warranty'}
            </Button>
          </ModalFooter>
        </Stack>
      </form>
    </Modal>
  );
}

const useStyles = createStyles((theme) => ({
  dropzone: {
    height: 260,
  },
  dropzoneError: {
    borderColor: theme.colors.red[7],
  },
  arrowContainer: {
    marginTop: '6.2%', // using percentage in order handle errors space
  },
}));
