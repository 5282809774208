import { DatePicker, DatePickerProps } from '@mantine/dates';
import React from 'react';

import { DateRangeType } from './types';

interface RangeCalendarProps {
  value: DateRangeType;
  onChange: (dateRange: DateRangeType) => void;
}

export function RangeCalendar({ value, onChange }: RangeCalendarProps) {
  return (
    <DatePicker
      type="range"
      numberOfColumns={2}
      size="sm"
      styles={datePickerStyles}
      value={[value.startDate, value.endDate]}
      onChange={(rangeDate) =>
        onChange({ startDate: rangeDate[0], endDate: rangeDate[1] })
      }
    />
  );
}

const datePickerStyles: DatePickerProps['styles'] = (theme) => ({
  monthLevelGroup: {
    width: '100%',
    height: '100%',
  },
  calendarHeader: {
    maxWidth: 'none',
    marginBottom: theme.spacing.md,
  },
  month: {
    width: '100%',
  },
  monthLevel: {
    flexGrow: 1,
    paddingInline: theme.spacing.xl,
    paddingBlock: theme.spacing.lg,

    '&[data-month-level]:not(:last-of-type)': {
      margin: '0',
      borderRight: `1px solid ${theme.colors.gray[3]}`,
    },
  },
});
