import { Badge, Group } from '@mantine/core';
import React, { Dispatch, SetStateAction, useMemo } from 'react';

import {
  DeviceDetailsType,
  useNewDeviceWidgets,
} from '@portals/api/organizations';

import { DeviceTab } from '../device.types';

interface DeviceTabsProps {
  device: DeviceDetailsType;
  tab: DeviceTab;
  setTab: Dispatch<SetStateAction<DeviceTab>>;
}

const TABS: Array<{ id: DeviceTab; label: string }> = [
  {
    id: 'general',
    label: 'General',
  },
  {
    id: 'overview',
    label: 'Map',
  },
  {
    id: 'commands',
    label: 'Commands',
  },
  {
    id: 'state',
    label: 'State',
  },
];

export function DeviceTabs({ device, tab, setTab }: DeviceTabsProps) {
  const deviceWidgets = useNewDeviceWidgets(device.partner.type_id);

  const tabs = useMemo(() => {
    const hasDeviceLocationWidget = deviceWidgets?.data.find(
      ({ config }) => config.id === 'device_location'
    );

    return TABS.map((currentTab) => {
      const isActive = currentTab.id === tab;

      if (!hasDeviceLocationWidget && currentTab.id === 'overview') return null;

      return (
        <Badge
          key={currentTab.id}
          variant={isActive ? 'filled' : 'default'}
          size="lg"
          fw="400"
          color={isActive ? 'gray.9' : 'gray.6'}
          onClick={() => setTab(currentTab.id)}
          data-testid={`mobile-tab-${currentTab.id}`}
        >
          {currentTab.label}
        </Badge>
      );
    });
  }, [deviceWidgets?.data, setTab, tab]);

  return (
    <Group
      w="100%"
      position="center"
      align="space-between"
      py={0}
      mt="xs"
      noWrap
      spacing="xs"
    >
      {tabs}
    </Group>
  );
}
