import { useMemo } from 'react';

import { PortalConfigType as OrganizationPortalConfig } from '@portals/api/organizations';
import { PortalConfigType as PartnerPortalConfig } from '@portals/api/partners';
import { TenantType } from '@portals/types';

interface UseBrandingConfigParams {
  tenantType: TenantType;
  isLoggedIn: boolean;
  portalConfig: Partial<OrganizationPortalConfig | PartnerPortalConfig>;
}

export const useBrandingConfig = ({
  tenantType,
  isLoggedIn,
  portalConfig,
}: UseBrandingConfigParams): Partial<OrganizationPortalConfig> =>
  useMemo(() => {
    if (!isLoggedIn) return portalConfig as OrganizationPortalConfig;

    let adjustedPortalConfig;

    if (tenantType === TenantType.Organization) {
      adjustedPortalConfig = portalConfig as OrganizationPortalConfig;
    } else {
      adjustedPortalConfig = portalConfig as PartnerPortalConfig;
    }

    return {
      tagline: adjustedPortalConfig?.tagline,
      tagline_color: adjustedPortalConfig?.tagline_color,
      logo: adjustedPortalConfig.logo,
      hero: adjustedPortalConfig.hero,
      hero_gradient_primary: adjustedPortalConfig?.hero_gradient_primary,
      hero_gradient_secondary: adjustedPortalConfig?.hero_gradient_secondary,
      window_title: adjustedPortalConfig?.window_title,
      name: adjustedPortalConfig?.name,
      favicon: adjustedPortalConfig.favicon,
      design: adjustedPortalConfig.design,
    };
  }, [portalConfig, isLoggedIn, tenantType]);
