import { LoadingOverlay } from '@mantine/core';
import React, { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { useEffectOnce } from 'react-use';

import { useConfig, useSpaces } from '@portals/api/organizations';
import { useCurrentUser } from '@portals/api/ui';

import { useAddressesAndCheckSelectedAddress } from '../../../hooks/addresses';
import { useCreditCardsAndCheckSelectedCreditCard } from '../../../hooks/credit-cards';
import {
  useCheckPaymentMethod,
  useStoreListingsAndCheckCartItems,
  useStoreSettingsAndCheckSelectedCurrency,
} from '../../../hooks/store';
import { fetchIntegrations } from '../../../redux/actions/integrations';

interface Props {
  children: ReactNode;
}

export const OrganizationDataLayout = ({ children }: Props) => {
  const dispatch = useDispatch();

  useEffectOnce(function prefetchIntegrations() {
    dispatch(fetchIntegrations());
  });

  // Checkout-related, used for removing from cart selected items that don't exist anymore and
  // de-selecting addresses/cards that were removed
  useStoreListingsAndCheckCartItems();
  useAddressesAndCheckSelectedAddress();
  useCreditCardsAndCheckSelectedCreditCard();
  useCheckPaymentMethod();

  const storeSettings = useStoreSettingsAndCheckSelectedCurrency();
  const currentUser = useCurrentUser();
  const spaces = useSpaces();
  const config = useConfig();

  const isFetched =
    storeSettings.isFetched &&
    currentUser.isFetched &&
    spaces.isFetched &&
    config.isFetched;

  if (!isFetched) {
    return <LoadingOverlay visible />;
  }

  return <>{children}</>;
};
