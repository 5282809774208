import { Tooltip } from '@mantine/core';
import React, { FC } from 'react';
import styled from 'styled-components';

import { SearchInput } from '@portals/core';
import { ReactComponent as Collapse } from '@portals/icons/linear/collapse.svg';
import { ReactComponent as Expand } from '@portals/icons/linear/expand.svg';
import { ReactComponent as Maximize1 } from '@portals/icons/linear/maximize-1.svg';
import { getStyledThemeColor } from '@portals/utils';

interface TreeHeaderProps {
  handleSearchOnChange: (value: string) => void;
  searchString: string;
  onCollapseAll: () => void;
  onExpandAll: () => void;
  onJumpToSelected: () => void;
}

const TreeHeader: FC<TreeHeaderProps> = ({
  handleSearchOnChange,
  searchString,
  onCollapseAll,
  onExpandAll,
  onJumpToSelected,
}) => {
  return (
    <Container className="tree-header">
      <SearchInput
        value={searchString}
        placeholder="Find space / area..."
        onClear={() => handleSearchOnChange('')}
        onChange={(event) => handleSearchOnChange(event.target.value)}
        sx={{
          width: 'auto',
        }}
      />

      <Actions className="tree-actions">
        <Tooltip label="Jump to selected" withinPortal>
          <div
            className="action-wrapper mr-2"
            id="tree-action-selected"
            onClick={onJumpToSelected}
          >
            <Maximize1 />
          </div>
        </Tooltip>

        <Tooltip label="Expand all" withinPortal>
          <div
            className="action-wrapper mr-2"
            id="tree-action-maximize"
            onClick={onExpandAll}
          >
            <Expand />
          </div>
        </Tooltip>

        <Tooltip label="Collapse all" withinPortal>
          <div
            className="action-wrapper"
            id="tree-action-minimize"
            onClick={onCollapseAll}
          >
            <Collapse />
          </div>
        </Tooltip>
      </Actions>
    </Container>
  );
};

const Container = styled.div`
  padding: 5px 15px;
  width: 100%;
  background-color: ${getStyledThemeColor('gray150')};
  border-bottom: 1px solid ${getStyledThemeColor('gray300')};
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-column-gap: 10px;
  align-items: center;

  .search-input-container {
    height: 30px;
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;

  .action-wrapper {
    font-size: 14px;
    border: 1px solid ${getStyledThemeColor('gray500')};
    color: ${getStyledThemeColor('gray600')};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 2px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: ${getStyledThemeColor('primary')};
      color: ${getStyledThemeColor('white')};
      border-color: ${getStyledThemeColor('primary')};
    }

    svg {
      width: 15px;
      height: 15px;
    }
  }
`;

export default TreeHeader;
