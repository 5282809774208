import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError } from '@portals/redux/actions/toastr';
import {
  PaginatedQueryFilterType,
  PaginatedQuerySortType,
  PaginationResponse,
} from '@portals/types';

import {
  getSpaceApiUrl,
  SPACES_API_URL,
  spacesQueryKeys,
} from './spaces.constants';
import { QueryOptions, ServerError } from '../../types';
import {
  buildUrlFromFilters,
  fetchApiRequest,
  useRequestOptions,
} from '../../utils';
import { DeviceDetailsType, DeviceType } from '../devices';
import { globalQueryKeys } from '../global-query-keys';
import { incidentsQueryKeys } from '../incidents';

function getApiUrl(spaceId: number) {
  return `${getSpaceApiUrl(spaceId)}/devices`;
}

function getDeviceApiUrl(spaceId: number, deviceId: string) {
  return `${getApiUrl(spaceId)}/${deviceId}`;
}

export function useDevicesBySpaceId({
  spaceId,
  queryOptions,
  filters,
  sorting,
  pageSize = 500,
}: {
  spaceId: number;
  queryOptions?: Pick<QueryOptions<DeviceType[]>, 'enabled' | 'staleTime'>;
  filters?: Array<PaginatedQueryFilterType<DeviceType>>;
  sorting?: Array<PaginatedQuerySortType<DeviceType>>;
  pageSize?: number;
}) {
  const { url, options } = useRequestOptions({ url: getApiUrl(spaceId) });

  const requestUrl = buildUrlFromFilters<DeviceType>({
    filters,
    sorting,
    url,
    pagination: {
      page: 0,
      pageSize,
    },
  });

  return useQuery<PaginationResponse<DeviceType>, any, DeviceType[]>({
    queryKey: [...spacesQueryKeys.devices.all(spaceId), requestUrl],
    queryFn: () => fetchApiRequest(requestUrl, options),
    select: (response) => response.data,
    meta: {
      baseUrl: url,
      method: 'GET',
    },
    ...(queryOptions || {}),
  });
}

interface UseUpdateDeicePayload {
  spaceId: number;
  deviceId: string;
  updatedDevice: Partial<DeviceDetailsType>;
}

export function useUpdateDevice() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { url: baseUrl, options } = useRequestOptions({
    url: '',
    method: 'PUT',
  });

  return useMutation<DeviceType, ServerError, UseUpdateDeicePayload>({
    mutationFn: ({ spaceId, deviceId, updatedDevice }) => {
      const finalUrl = `${baseUrl}/${getDeviceApiUrl(spaceId, deviceId)}`;

      return fetchApiRequest(finalUrl, {
        ...options,
        body: JSON.stringify(updatedDevice),
      });
    },
    onSuccess: (_, { spaceId }) => {
      queryClient.invalidateQueries(incidentsQueryKeys.base);
      queryClient.invalidateQueries(spacesQueryKeys.detail(spaceId));
      queryClient.invalidateQueries(globalQueryKeys.devices);

      setTimeout(() => {
        queryClient.invalidateQueries(spacesQueryKeys.base);
      }, 2500);
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useUpdateDevice',
      baseUrl: `${SPACES_API_URL}/:id/devices/:id`,
      method: 'PUT',
    },
  });
}
