import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  useOrganizationConfig,
  usePortalConfig,
  useShop,
  useStoreListings,
  useStoreSettings,
} from '@portals/api/organizations';
import { PaymentMethodEnum } from '@portals/types';

import { checkCartItems, checkSelectedCurrency } from '../redux/actions/store';
import { useCheckout } from '../redux/hooks/store';

export function useStoreListingsAndCheckCartItems({
  sortByPosition,
}: { sortByPosition?: boolean } = {}) {
  const dispatch = useDispatch();

  return useStoreListings({
    sortByPosition,
    queryOptions: {
      onSuccess: (data) => {
        dispatch(checkCartItems(data));
      },
    },
  });
}

export function useStoreSettingsAndCheckSelectedCurrency() {
  const dispatch = useDispatch();

  const portalConfig = usePortalConfig();

  const cecEnabled = portalConfig.data?.cec_enabled;

  return useStoreSettings({
    onSuccess: (data) => {
      if (!cecEnabled && portalConfig.isFetched) {
        dispatch(checkSelectedCurrency(data.currencies, data.default_currency));
      }
    },
  });
}

export function useShopAndCheckSelectedCurrency({
  sortByPosition,
}: { sortByPosition?: boolean } = {}) {
  const dispatch = useDispatch();

  return useShop({
    sortByPosition,
    queryOptions: {
      onSuccess: (data) => {
        dispatch(checkSelectedCurrency(data.currencies, data.default_currency));
      },
    },
  });
}

export const useCheckPaymentMethod = () => {
  const { paymentMethod } = useCheckout();
  const organization = useOrganizationConfig();
  const storeSettings = useStoreSettings();

  useEffect(() => {
    if (!organization || !storeSettings.data) return;

    if (!organization.lab && paymentMethod.selected === PaymentMethodEnum.Lab) {
      paymentMethod.onChange(PaymentMethodEnum.CreditCard);
    } else if (
      paymentMethod.selected === PaymentMethodEnum.PurchaseOrder &&
      !storeSettings.data.purchase_order_payment_method_enabled
    ) {
      paymentMethod.onChange(PaymentMethodEnum.CreditCard);
    }
  }, [storeSettings.data, organization, paymentMethod]);
};
