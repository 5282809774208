import {
  Badge,
  Button,
  Stack,
  Text,
  ModalProps as MantineModalProps,
} from '@mantine/core';
import React from 'react';

import { ModalCenteredMediaLayout, ModalFooter } from '@portals/core';

import { ModalProps } from '../components/Modals';

export interface ComingSoonIntegrationModalProps
  extends ModalProps<{
    integrationName: string;
    logo: string;
  }> {}

export function ComingSoonIntegrationModal({
  data: { integrationName, logo },
  closeMe,
}: ComingSoonIntegrationModalProps) {
  return (
    <ModalCenteredMediaLayout
      opened
      onClose={closeMe}
      title={
        <Stack align="center">
          <Badge p="xs" fz="xs" fw={400} bg="gray.2" h="xxl">
            Coming soon
          </Badge>
          <Text fz={24}>{integrationName} integration is coming soon</Text>{' '}
        </Stack>
      }
      media={<img src={logo} />}
      styles={modalStyles}
    >
      <Stack>
        <Text ta="center">
          Thanks for joining {integrationName} wait list. Our Customer Support
          team will inform you once this integration is available. Stay tuned!
        </Text>

        <ModalFooter position="center">
          <Button onClick={closeMe}>Got it</Button>
        </ModalFooter>
      </Stack>
    </ModalCenteredMediaLayout>
  );
}

const modalStyles: MantineModalProps['styles'] = (theme) => ({
  header: {
    paddingBottom: theme.spacing.md,
    paddingRight: theme.spacing.xxl,
  },
});
