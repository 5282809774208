import {
  createStyles,
  Group,
  Paper,
  SegmentedControl,
  Text,
} from '@mantine/core';
import { compact, values } from 'lodash/fp';
import React, { FC, useMemo } from 'react';

import { WidgetDataRangeEnum } from '@portals/api/organizations';
import { WidgetType } from '@portals/device-widgets';
import { EmptyState } from '@portals/table';

import WIDGETS_MAP from './constants';
import { WidgetProps } from './types';
import { useDeviceStateLiveOrHistory } from '../../../hooks/device-state';
import { DeviceStateType } from '../../route-modals/Device/tabs/device-tabs.types';

interface WidgetWrapperProps {
  widget: WidgetType;
  deviceStates: Array<DeviceStateType>;
  deviceId: string;
}

export function WidgetWrapper({
  widget,
  deviceStates,
  deviceId,
}: WidgetWrapperProps) {
  const { config } = widget;
  const Widget: FC<WidgetProps> = WIDGETS_MAP[config.id];

  const dataFields = useMemo(() => {
    const { name, ...fields } = config.fields;

    return compact(values(fields));
  }, [config.fields]);

  const { classes } = useStyles({
    hideTitle: config.fields.status_measurement_hide_title,
  });

  const {
    isInitialLoading,
    isWithHistory,
    setWidgetDataRange,
    widgetDataRange,
    deviceStates: adjustedDeviceStates,
  } = useDeviceStateLiveOrHistory(widget, deviceStates);

  return (
    <Paper withBorder radius="md" className={classes.container}>
      {!config.fields.status_measurement_hide_title ? (
        <Group position="apart" className={classes.header}>
          <Text size="md" weight={500} data-testid="widget-name" color="gray.8">
            {widget.name}
          </Text>

          {isWithHistory ? (
            <SegmentedControl
              data={[
                { value: WidgetDataRangeEnum.Month, label: 'Last 30 Days' },
                { value: WidgetDataRangeEnum.Week, label: 'Last 7 Days' },
                { value: WidgetDataRangeEnum.Day, label: 'Last 24hr' },
              ]}
              value={widgetDataRange}
              onChange={(range: WidgetDataRangeEnum) =>
                setWidgetDataRange(range)
              }
            />
          ) : null}
        </Group>
      ) : null}

      {Widget ? (
        <Widget
          isLoading={isInitialLoading}
          widget={widget}
          deviceStates={adjustedDeviceStates}
          dataFields={dataFields}
          deviceId={deviceId}
        />
      ) : (
        <EmptyState label="Widget not found" src="empty-state-widgets" />
      )}
    </Paper>
  );
}

const useStyles = createStyles(
  (theme, { hideTitle }: { hideTitle: boolean }) => ({
    container: {
      height: '100%',
      overflow: 'hidden',
      display: 'grid',
      gridTemplateRows: hideTitle ? '1fr' : 'max-content 1fr',
    },
    header: {
      padding: `${theme.spacing.sm} ${theme.spacing.md}`,
      borderBottom: `1px solid ${theme.colors.gray[2]}`,
    },
  })
);
