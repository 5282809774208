import { ScaleType } from '../../widgets.types';
import { MeasurementType } from '../form';

const PRE_GENERATED_LINEAR_MOCK_DATA = Array.from(new Array(20), (_) => [
  ...Array.from(new Array(20), () => Math.floor(Math.random() * 100) + 1),
]);

const PRE_GENERATED_LOG_MOCK_DATA = Array.from(new Array(20), (_) => [
  ...Array.from(new Array(20), () => Math.floor(Math.random() * 10000) + 1),
]);

export function getSplineChartMockData(
  measurements: Array<MeasurementType>,
  scaleType: ScaleType
) {
  return Array.from(new Array(20), (_, index) => ({
    timestamp: new Date(Date.now() - 1000 * 60 * 60 * 24 * index).toISOString(),
    ...Object.fromEntries(
      measurements.map((measurement, measurementIndex) => [
        measurement.telemetry,
        (scaleType === 'log'
          ? PRE_GENERATED_LOG_MOCK_DATA
          : PRE_GENERATED_LINEAR_MOCK_DATA)[index][measurementIndex],
      ])
    ),
  }));
}
