import { useMantineTheme } from '@mantine/core';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { abbreviateNumber, formatDateTime, formatNumber } from '@portals/utils';

export interface UsageBasedLineChartProps {
  chartData: Array<{ timestamp: string; value: number | null }>;
  usageBasedUnitName: string | null;
  usageBasedDisplayName: string | null;
}

export function UsageBasedLineChart({
  chartData,
  usageBasedUnitName,
  usageBasedDisplayName,
}: UsageBasedLineChartProps) {
  const theme = useMantineTheme();

  return (
    <ResponsiveContainer width="100%" height={200}>
      <LineChart data={chartData}>
        <RechartsTooltip<number, string>
          labelFormatter={(label) => formatDateTime(label)}
          formatter={(value) => [
            `${formatNumber(value)}${usageBasedUnitName}`,
            usageBasedDisplayName,
          ]}
        />

        <CartesianGrid vertical={false} stroke={theme.colors.gray[2]} />

        <XAxis
          interval="preserveStartEnd"
          dataKey="timestamp"
          tickFormatter={(value) => dayjs(value).format('MMM DD')}
          color={theme.colors.gray[5]}
          tick={{ fill: theme.colors.gray[5] }}
          axisLine={{ stroke: theme.colors.gray[2] }}
        />

        <YAxis
          width={40}
          tickFormatter={(value) => abbreviateNumber(value)}
          tick={{ fill: theme.colors.gray[5] }}
          axisLine={{ stroke: theme.colors.gray[2] }}
        />

        <Line type="monotone" dataKey="value" stroke="#8884d8" dot={false} />
      </LineChart>
    </ResponsiveContainer>
  );
}

export function useUsageBasedLineChart({
  stateHistories,
  telemetryKey,
  lastMeterEnd,
  currentMeter,
}: {
  stateHistories: Array<[Record<string, unknown>, string]> | undefined;
  telemetryKey: string | undefined;
  lastMeterEnd: number | undefined;
  currentMeter: number | undefined;
}) {
  const chartData = useMemo(() => {
    if (!stateHistories || !telemetryKey) {
      return [];
    }

    const lastMeterEndAsNumber = lastMeterEnd ?? 0;

    let result: UsageBasedLineChartProps['chartData'] = stateHistories.map(
      ([stateData, timestamp]) => {
        const value = Number(stateData[telemetryKey]);
        const delta = value - lastMeterEndAsNumber;

        return {
          timestamp,
          value: delta,
        };
      }
    );

    // Add null value for the first day of the month if it's not present
    const startOfMonth = dayjs().startOf('month');
    if (!startOfMonth.isSame(result[0]?.timestamp, 'day')) {
      result = [
        { timestamp: startOfMonth.toISOString(), value: null },
        ...result,
      ];
    }

    return result;
  }, [lastMeterEnd, stateHistories, telemetryKey]);

  const currentMeterDelta = useMemo(() => {
    if (typeof lastMeterEnd !== 'number') {
      return currentMeter;
    }

    if (!currentMeter) return 0;

    return currentMeter - lastMeterEnd;
  }, [currentMeter, lastMeterEnd]);

  return { chartData, currentMeterDelta };
}
