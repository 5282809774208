import { ActionIcon, createStyles, Grid, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { parseInt } from 'lodash';
import React from 'react';

import { ReactComponent as CloseX } from '@portals/icons/linear/close-x.svg';

import { convertTimeUnits } from './active-incidents-age.utils';
import { TimeInput } from './TimeInput';
import {
  ActiveIncidentsAgeWidgetFormType,
  TierType,
  TimeUnit,
} from '../../../overview.types';

interface TierProps {
  displayNameInputProps: ReturnType<
    UseFormReturnType<ActiveIncidentsAgeWidgetFormType>['getInputProps']
  >;
  tier: TierType;
  onTimeValueChange: (params: {
    field: keyof Pick<TierType, 'firstValue' | 'lastValue'>;
    value: number;
    changedTier: TierType;
  }) => void;
  onTimeUnitChange: (params: {
    field: keyof Pick<TierType, 'firstValueTimeUnit' | 'lastValueTimeUnit'>;
    value: TimeUnit;
    changedTier: TierType;
  }) => void;
  onRemoveTier: (tierToRemove: TierType) => void;
  displayRemoveButton: boolean;
  disableFirstValue: boolean;
}

export function Tier({
  displayNameInputProps,
  tier,
  onTimeValueChange,
  onTimeUnitChange,
  onRemoveTier,
  displayRemoveButton,
  disableFirstValue,
}: TierProps) {
  const { classes } = useStyles();

  const { lastValue, firstValue, firstValueTimeUnit, lastValueTimeUnit } = tier;

  const onLastValueBlur = (value: string) => {
    const parsedValue = parseInt(value);
    if (lastValue === parsedValue) {
      return;
    }

    if (value === '') {
      return;
    }

    const valueInSec = convertTimeUnits({
      value: parsedValue,
      from: lastValueTimeUnit,
      to: TimeUnit.Seconds,
    });

    onTimeValueChange({
      field: 'lastValue',
      value: valueInSec,
      changedTier: tier,
    });
  };

  const onFirstValueBlur = (value: string) => {
    const parsedValue = parseInt(value);

    if (firstValue === parsedValue) {
      return;
    }

    const valueInSec = convertTimeUnits({
      value: parsedValue,
      from: firstValueTimeUnit,
      to: TimeUnit.Seconds,
    });

    onTimeValueChange({
      field: 'firstValue',
      value: valueInSec,
      changedTier: tier,
    });
  };

  return (
    <Grid align="flex-start">
      <Grid.Col span="auto">
        <TextInput
          data-testid="tier-name"
          label="Display name"
          placeholder="Enter display name..."
          {...displayNameInputProps}
        />
      </Grid.Col>

      <Grid.Col span={2}>
        <TimeInput
          timeUnit={firstValueTimeUnit}
          onTimeUnitChange={onTimeUnitChange}
          tier={tier}
          disabled={disableFirstValue}
          timeUnitLabel="firstValueTimeUnit"
          inputProps={{
            label: 'First value',
            placeholder: '0',
            value: convertTimeUnits({
              value: firstValue,
              from: TimeUnit.Seconds,
              to: firstValueTimeUnit,
            }),
            onBlur: (event) => onFirstValueBlur(event.target.value),
          }}
        />
      </Grid.Col>

      <Grid.Col span={2}>
        <TimeInput
          timeUnit={lastValueTimeUnit}
          onTimeUnitChange={onTimeUnitChange}
          tier={tier}
          timeUnitLabel="lastValueTimeUnit"
          inputProps={{
            label: 'Last value',
            placeholder: '∞',
            value: convertTimeUnits({
              value: lastValue,
              from: TimeUnit.Seconds,
              to: lastValueTimeUnit,
            }),
            formatter: (value) => {
              if (value !== 'Infinity') {
                return value;
              }

              return '';
            },
            onBlur: (event) => onLastValueBlur(event.target.value),
          }}
        />
      </Grid.Col>

      <Grid.Col span={1} className={classes.removeTierContainer}>
        {displayRemoveButton && (
          <ActionIcon
            onClick={() => onRemoveTier(tier)}
            className={classes.removeTier}
            data-testid="remove-tier"
          >
            <CloseX />
          </ActionIcon>
        )}
      </Grid.Col>
    </Grid>
  );
}

const useStyles = createStyles((theme) => ({
  removeTierContainer: {
    position: 'relative',
  },

  removeTier: {
    position: 'absolute',
    top: 38,
  },

  labelText: {
    color: theme.colors.gray[5],
  },
}));
