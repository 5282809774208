import { Box, Stack, Text } from '@mantine/core';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { isEmpty } from 'lodash/fp';
import React, { useState } from 'react';

import {
  OrganizationInvoiceType,
  useInvoiceTimeline,
} from '@portals/api/organizations';
import { DetailsPanel } from '@portals/core';
import {
  InvoiceAddressDetails,
  InvoiceItemsPanel,
  InvoicePaymentDetails,
  OrganizationInvoicePaymentStatusBadge,
  InvoiceTimeline,
  PurchaseOrderPanel,
  ReceiptPanel,
  usePermissionAccess,
} from '@portals/framework';
import { PaymentMethodEnum } from '@portals/types';
import { formatCurrency, formatDateTime } from '@portals/utils';

import { InvoicePaymentFailed } from './InvoicePaymentFailed';
import { PartnerDetails } from './PartnerDetails';

dayjs.extend(relativeTime);

interface InvoiceDetailsPanelProps {
  invoice: OrganizationInvoiceType;
  onClose: () => void;
}

export function InvoiceDetailsPanel({
  invoice,
  onClose,
}: InvoiceDetailsPanelProps) {
  const [isItemsPanelOpen, setIsItemsPanelOpen] = useState(false);
  const [isReceiptPanelOpen, setIsReceiptPanelOpen] = useState(false);
  const [isPurchaseOrderPanelOpen, setIsPurchaseOrderPanelOpen] =
    useState(false);

  const { canEdit } = usePermissionAccess();
  const { isAdmin } = usePermissionAccess();

  const timelineEvents = useInvoiceTimeline(invoice.id);

  const createdAtDate = formatDateTime(
    invoice?.created_at,
    'MMMM D, YYYY h:mm A'
  );

  const showPaymentFailed =
    invoice.payment_method === PaymentMethodEnum.CreditCard &&
    invoice.status === 'failed' &&
    canEdit(['finance']) &&
    isAdmin;

  const getPrimaryButton = () => {
    if (invoice?.status === 'failed') {
      return (
        <DetailsPanel.ActionButton
          onClick={() => window.open('mailto:support@xyte.io', '_blank')}
        >
          Contact support
        </DetailsPanel.ActionButton>
      );
    }

    if (invoice?.invoice_file_url) {
      return (
        <DetailsPanel.ActionButton
          onClick={() => setIsReceiptPanelOpen(!isReceiptPanelOpen)}
        >
          {isReceiptPanelOpen ? 'Hide Receipt' : 'Show Receipt'}
        </DetailsPanel.ActionButton>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <ReceiptPanel
        isOpen={isReceiptPanelOpen}
        receiptId={invoice.id}
        fileUrl={invoice.invoice_file_url}
        onClose={() => setIsReceiptPanelOpen(false)}
      />

      <PurchaseOrderPanel
        isOpen={isPurchaseOrderPanelOpen}
        fileUrl={invoice.purchase_order?.file_url}
        orderId={invoice?.order_id}
        onClose={() => setIsPurchaseOrderPanelOpen(false)}
      />

      <InvoiceItemsPanel
        isOpen={isItemsPanelOpen}
        invoiceItems={invoice.items}
        currency={invoice.currency}
        itemsCount={invoice.items_count}
        onClose={() => setIsItemsPanelOpen(false)}
        paymentMethod={invoice.payment_method}
        orderId={invoice.order_id}
      />

      <DetailsPanel pos="relative" bg="white">
        <DetailsPanel.Header onClose={onClose} spacing="xxl">
          <Stack align="center">
            <Box>
              <DetailsPanel.Title align="center">
                <Text size="md">{invoice?.order_short_id}</Text>
              </DetailsPanel.Title>

              <Text color="gray.5">{createdAtDate}</Text>
            </Box>

            <Text size={28}>
              {formatCurrency(invoice.amount_in_scu, invoice.currency)}
            </Text>
            <OrganizationInvoicePaymentStatusBadge status={invoice?.status} />

            {showPaymentFailed ? (
              <InvoicePaymentFailed invoice={invoice} />
            ) : null}
          </Stack>

          <DetailsPanel.Actions>
            {getPrimaryButton()}
            <DetailsPanel.ActionButton
              onClick={() => setIsItemsPanelOpen(!isItemsPanelOpen)}
            >
              {isItemsPanelOpen ? 'Hide Items' : 'Show Items'}
            </DetailsPanel.ActionButton>
          </DetailsPanel.Actions>
        </DetailsPanel.Header>

        <DetailsPanel.Body>
          <PartnerDetails invoicePartner={invoice?.partner} />

          <InvoicePaymentDetails
            payment_method={invoice?.payment_method}
            currency={invoice?.currency}
            tax={invoice?.tax_in_scu}
            amount={invoice?.amount_in_scu}
            credit_card={invoice?.credit_card}
            onOpenPurchaseOrderPanel={() => setIsPurchaseOrderPanelOpen(true)}
            invoiceFileUploadedAt={invoice?.invoice_file_uploaded_at}
          />

          {!isEmpty(timelineEvents.data) ? (
            <InvoiceTimeline timelineEvents={timelineEvents.data} />
          ) : null}

          <InvoiceAddressDetails
            country={invoice?.billing_address?.country}
            city={invoice?.billing_address?.city}
            address={invoice?.billing_address?.line_1}
            state={invoice?.billing_address?.state}
            zip={invoice?.billing_address?.zip}
            title="Billing address"
          />

          <InvoiceAddressDetails
            country={invoice?.shipping_address?.country}
            city={invoice?.shipping_address?.city}
            address={invoice?.shipping_address?.line_1}
            state={invoice?.shipping_address?.state}
            zip={invoice?.shipping_address?.zip}
            title="Shipping address"
          />
        </DetailsPanel.Body>
      </DetailsPanel>
    </>
  );
}
