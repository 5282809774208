import React from 'react';

import { useTableInstance } from '../../context';
import SearchInput from '../SearchInput';

export function GlobalFilter<TData extends object>() {
  const instance = useTableInstance<TData>();

  const { setGlobalFilter, state } = instance;

  const onChange = (event) => {
    if (instance.toggleAllRowsSelected) {
      instance.toggleAllRowsSelected(false);
    }

    setGlobalFilter(event.target.value);
  };

  const onClear = () => {
    if (instance.toggleAllRowsSelected) {
      instance.toggleAllRowsSelected(false);
    }

    setGlobalFilter('');
  };

  return (
    <SearchInput
      onClear={onClear}
      value={state.globalFilter || ''}
      onChange={onChange}
    />
  );
}
