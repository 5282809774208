import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  InvoiceTimelineEventType,
  PaginatedQueryFilterType,
  PaginationResponse,
  UsePaginatedTableApiQuery,
} from '@portals/types';

import { INVOICES_API_URL, invoicesQueryKeys } from './invoices.constants';
import { OrganizationInvoiceType } from './invoices.types';
import { ServerError } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils/common';
import { buildUrlFromFilters } from '../../utils/paginated-query';
import { usePaginatedTableApiQuery } from '../../utils/paginated-table';

export const useOrganizationInvoicesTableData = (
  tableState: UsePaginatedTableApiQuery<OrganizationInvoiceType>['tableState'],
  columns: UsePaginatedTableApiQuery<OrganizationInvoiceType>['columns'],
  baseUrl = INVOICES_API_URL
) => {
  return usePaginatedTableApiQuery<OrganizationInvoiceType>({
    baseUrl,
    tableState,
    columns,
    queryKey: [...invoicesQueryKeys.list(), baseUrl, tableState],
  });
};

/*
 * Mutations
 * */

export function useUpdateInvoiceOrganizationNote() {
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: INVOICES_API_URL,
    method: 'PATCH',
  });

  return useMutation({
    mutationFn: ({ note, invoiceId }: { invoiceId: string; note: string }) =>
      fetchApiRequest(`${url}/${invoiceId}`, {
        ...options,
        body: JSON.stringify({ organization_note: note }),
      }),
    onSuccess: async () => {
      await queryClient.refetchQueries(invoicesQueryKeys.all);
    },
    meta: {
      mutationName: 'useUpdateInvoiceOrganizationNote',
      baseUrl: `${INVOICES_API_URL}/:id`,
      method: 'PATCH',
    },
  });
}

interface UseInvoiceRetryChargeParams {
  invoiceId: string;
}

export function useInvoiceRetryCharge() {
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: INVOICES_API_URL,
    method: 'PATCH',
  });

  return useMutation<void, ServerError, UseInvoiceRetryChargeParams>({
    mutationFn: ({ invoiceId }) =>
      fetchApiRequest(`${url}/${invoiceId}/retry_charge`, options),
    onSuccess: async () => {
      queryClient.invalidateQueries(invoicesQueryKeys.all);
    },
    meta: {
      mutationName: 'useInvoiceRetryCharge',
      baseUrl: `${INVOICES_API_URL}/:id/retry_charge`,
      method: 'PATCH',
    },
  });
}

export function useNonePaginatedInvoices(params?: {
  filters: Array<PaginatedQueryFilterType<OrganizationInvoiceType>>;
}) {
  const { url, options } = useRequestOptions({ url: INVOICES_API_URL });

  const requestUrl = buildUrlFromFilters({
    url,
    filters: params?.filters,
    pagination: {
      page: 0,
      pageSize: 1000,
    },
  });

  return useQuery<
    PaginationResponse<OrganizationInvoiceType>,
    ServerError,
    OrganizationInvoiceType[]
  >({
    queryKey: [...invoicesQueryKeys.all, JSON.stringify(params?.filters)],
    queryFn: () => fetchApiRequest(requestUrl, options),
    select: (response) => response.data,
    meta: {
      method: 'GET',
      baseUrl: INVOICES_API_URL,
    },
  });
}

export function useInvoiceTimeline(invoiceId: string) {
  const { url, options } = useRequestOptions({
    url: `${INVOICES_API_URL}/${invoiceId}/timeline`,
    method: 'GET',
  });

  return useQuery<InvoiceTimelineEventType[], ServerError>({
    queryKey: invoicesQueryKeys.all,
    queryFn: () => fetchApiRequest(url, options),
    staleTime: 0,
    meta: {
      baseUrl: `${INVOICES_API_URL}/:id/timeline`,
      method: 'GET',
    },
  });
}
