import { Alert, AlertProps, Anchor, Group, Text } from '@mantine/core';
import React from 'react';

import { SpaceType } from '@portals/api/organizations';
import { useOpenModal } from '@portals/redux';

import { type ChangeSpaceModalProps } from '../../../ChangeSpaceModal';

export interface ChangeSpaceFieldProps {
  space: SpaceType | undefined;
  onSetSpaceId: (spaceId: number) => void;
}

export function ChangeSpaceField({
  space,
  onSetSpaceId,
}: ChangeSpaceFieldProps) {
  const openModal = useOpenModal();

  return (
    <Alert
      title="Add device to"
      color="gray.0"
      styles={alertStyles}
      radius="lg"
      p="xl"
    >
      <Group position="apart">
        <Text color="gray.9">{space?.name}</Text>

        <Anchor
          onClick={() =>
            openModal<ChangeSpaceModalProps['data']>('ChangeSpaceModal', {
              onSetSpaceId,
              space,
            })
          }
        >
          Change space
        </Anchor>
      </Group>
    </Alert>
  );
}

const alertStyles: AlertProps['styles'] = (theme) => ({
  title: {
    color: theme.colors.gray[6],
  },
});
