import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';

import { useLicensesDataMutation } from '@portals/api/organizations';
import { AutoFormik } from '@portals/autoformik';

import { DATA_BASE_URL, EntityEnum } from '../../constants/data';

const FIELDS = [{ name: 'id', title: 'License id', required: true }];

const AddLicense = ({ closeMe }) => {
  const { mutate, isLoading } = useLicensesDataMutation(
    'POST',
    () => DATA_BASE_URL[EntityEnum.Licenses]
  );

  const handleSubmit = async ({ id }) =>
    mutate({ deviceId: id, customOptions: { body: id } });

  return (
    <Modal isOpen={true} toggle={closeMe} centered>
      <ModalHeader toggle={closeMe}>Add License</ModalHeader>

      <AutoFormik
        fields={FIELDS}
        inProgress={isLoading}
        handleSubmit={handleSubmit}
        modal={closeMe}
        submitTitle="Add"
      />
    </Modal>
  );
};

export default AddLicense;
