import {
  Alert,
  createStyles,
  Loader,
  Text,
  UnstyledButton,
} from '@mantine/core';
import { size } from 'lodash/fp';
import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { CommandType } from '@portals/types';

import { useRouteModalConfig } from '../../../../route-modals.hooks';

interface ActiveCommandsEventProps {
  deviceId: string;
  commands: Array<CommandType>;
}

export function ActiveCommandsEvent({
  commands,
  deviceId,
}: ActiveCommandsEventProps) {
  const { classes } = useStyles();

  const navigate = useNavigate();
  const routeModalConfig = useRouteModalConfig('device');
  const location = useLocation();

  const navigateToCommands = () => {
    navigate(routeModalConfig.generatePath([deviceId, 'commands']), {
      replace: true,
      state: location.state,
    });
  };

  const commandInProgress = useMemo(() => {
    const command = commands?.find(
      (command) => command.status === 'in_progress'
    );
    return command ? command.name : '';
  }, [commands]);

  return (
    <Alert
      title={
        <Text color="black" size="md" weight={600}>
          {size(commands)} commands are queued...
        </Text>
      }
      icon={<Loader />}
      color="blue_accent"
      p="xl"
      radius="lg"
    >
      <Text color="gray.8">
        Now in progress: <b>{commandInProgress}</b>
      </Text>

      <UnstyledButton
        fz="xs"
        onClick={navigateToCommands}
        className={classes.showCommandsButton}
      >
        Show all commands
      </UnstyledButton>
    </Alert>
  );
}

const useStyles = createStyles((theme) => ({
  showCommandsButton: {
    display: 'flex',
    justifyContent: 'end',
    width: '100%',
    fontWeight: 500,
  },
}));
