import { useQuery } from '@tanstack/react-query';

import { LicenseType, PaginationResponse } from '@portals/types';

import {
  DEVICE_MODELS_API_URL,
  deviceModelsQueryKeys,
  getDeviceModelApiUrl,
} from './device-models.constants';
import { fetchApiRequest, useRequestOptions } from '../../utils/common';
import { buildUrlFromFilters } from '../../utils/paginated-query';

function getApiUrl(deviceModelId: string) {
  return `${getDeviceModelApiUrl(deviceModelId)}/unassigned_licenses`;
}

export function useDeviceModelUnassignedLicenses(deviceModelId: string) {
  const { url, options } = useRequestOptions({ url: getApiUrl(deviceModelId) });

  const requestUrl = buildUrlFromFilters({
    url,
    pagination: {
      page: 0,
      pageSize: 500,
    },
  });

  return useQuery<
    PaginationResponse<LicenseType>,
    { error: string },
    LicenseType[]
  >({
    queryKey: deviceModelsQueryKeys.licenses.unassigned(deviceModelId),
    queryFn: () => fetchApiRequest(requestUrl, options),
    select: (response) => response.data,
    staleTime: 0,
    enabled: !!deviceModelId,
    meta: {
      baseUrl: `${DEVICE_MODELS_API_URL}/:id/unassigned_licenses`,
      method: options?.method || 'GET',
    },
  });
}
