import { Box, createStyles, SimpleGrid } from '@mantine/core';
import { map } from 'lodash/fp';
import React, { useMemo } from 'react';
import { Row, UseExpandedRowProps, UseRowSelectRowProps } from 'react-table';
import { AutoSizer } from 'react-virtualized';

import { RowType, SmartTableProps } from '@portals/types';

import { useTableInstance } from '../context';

type TGridProps<TData extends object, TKeyField extends keyof TData> = Pick<
  SmartTableProps<TData, TKeyField>,
  'gridView'
> & {
  data: Array<Row<TData>>;
  onRowClick?: (row: RowType<TData>) => void;
};

export function TGrid<TData extends object, TKeyField extends keyof TData>({
  data,
  gridView,
  onRowClick,
}: TGridProps<TData, TKeyField>) {
  const { cx, classes } = useStyles();
  const instance = useTableInstance<TData>();
  const { prepareRow, getTableBodyProps } = instance;

  const items = useMemo(
    () =>
      map(
        (
          row: Row<TData> &
            UseExpandedRowProps<TData> &
            UseRowSelectRowProps<TData>
        ) => {
          if (!row) return null;

          prepareRow(row);

          return (
            <div
              key={row.id}
              className={cx(classes.gridItemWrapper, 'grid-item-wrapper')}
              onClick={() => onRowClick?.(row)}
            >
              {gridView.itemRenderer({ item: row })}
            </div>
          );
        },
        data
      ),
    [data, gridView, onRowClick, prepareRow, cx, classes.gridItemWrapper]
  );

  return (
    <Box {...getTableBodyProps()} className="tbody" h="100%">
      <AutoSizer disableHeight>
        {({ width }) => (
          <SimpleGrid
            cols={
              gridView.getColumnsCount?.({
                tableWidth: width,
                itemsCount: items.length,
              }) || 3
            }
            p="md"
            spacing="md"
            w={width}
          >
            {items}
          </SimpleGrid>
        )}
      </AutoSizer>
    </Box>
  );
}

const useStyles = createStyles(() => ({
  gridItemWrapper: {
    '> *': {
      height: '100%',
    },
  },
}));
