import { Card, Stack, Text, Title } from '@mantine/core';
import React, { ReactNode } from 'react';

interface FreeTrialModalCardProps {
  imageSrc: string;
  title: ReactNode;
  description: ReactNode;
}

export function FreeTrialModalCard({
  imageSrc,
  title,
  description,
}: FreeTrialModalCardProps) {
  return (
    <Card>
      <Card.Section>
        <img src={imageSrc} width="100%" height="auto" />
      </Card.Section>

      <Stack spacing="xl" ta="center" c="gray.9" py="xl">
        <Title order={4} weight={400}>
          {title}
        </Title>
        <Text size="md">{description}</Text>
      </Stack>
    </Card>
  );
}
