import { Input } from '@mantine/core';
import React from 'react';
import { FC } from 'react';

import { FieldRendererProps } from '@portals/types';

const CustomField: FC<FieldRendererProps> = (props) => {
  const { field, error, required } = props;
  const Component = field.component;

  return (
    <Input.Wrapper label={props.title} error={error} required={required}>
      <Component {...props} />
    </Input.Wrapper>
  );
};

export default CustomField;
