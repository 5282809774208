import { Badge, Box, createStyles } from '@mantine/core';
import React from 'react';

import {
  OrderSummaryType as OrganizationSummaryType,
  OrderType as OrganizationOrderType,
} from '@portals/api/organizations';
import {
  OrderShipmentType,
  OrderSummaryType as PartnerSummaryType,
  OrderType as PartnerOrderType,
} from '@portals/api/partners';
import {
  getOrganizationOrderFulfillmentStatusColorAndLabel,
  getOrganizationOrderPaymentStatusColorAndLabel,
  getPartnerOrderFulfillmentStatusColorAndLabel,
  getPartnerOrderPaymentStatusColorAndLabel,
} from '@portals/utils';

export interface OrderBadgeProps<TOrder> {
  order: TOrder;
}

export function OrganizationOrderFulfillmentStatusBadge({
  order,
}: OrderBadgeProps<OrganizationSummaryType | OrganizationOrderType>) {
  const { classes } = useStyles();

  const { bgColor, indicatorColor, label } =
    getOrganizationOrderFulfillmentStatusColorAndLabel(
      order.fulfillment_status
    );

  return (
    <Badge
      size="md"
      bg={bgColor}
      c="gray.9"
      fw={300}
      fz="xs"
      py="sm"
      leftSection={<Box className={classes.indicator} bg={indicatorColor} />}
      classNames={{
        leftSection: classes.leftSection,
      }}
      data-testid={`order-badge-${label}`}
    >
      {label}
    </Badge>
  );
}

export function OrganizationOrderPaymentStatusBadge({
  order,
}: OrderBadgeProps<OrganizationSummaryType | OrganizationOrderType>) {
  const { classes } = useStyles();

  const { bgColor, indicatorColor, label } =
    getOrganizationOrderPaymentStatusColorAndLabel(order.payment_status);

  return (
    <Badge
      size="md"
      bg={bgColor}
      c="gray.9"
      fw={300}
      fz="sm"
      py="sm"
      leftSection={<Box className={classes.indicator} bg={indicatorColor} />}
      classNames={{
        leftSection: classes.leftSection,
      }}
      data-testid={`order-badge-${label}`}
    >
      {label}
    </Badge>
  );
}

export function PartnerOrderFulfillmentStatusBadge({
  order,
}: OrderBadgeProps<PartnerSummaryType | PartnerOrderType | OrderShipmentType>) {
  const { classes } = useStyles();

  const { bgColor, indicatorColor, label } =
    getPartnerOrderFulfillmentStatusColorAndLabel(order.fulfillment_status);

  return (
    <Badge
      size="md"
      bg={bgColor}
      c="gray.9"
      fw={300}
      py="sm"
      fz="xs"
      leftSection={<Box className={classes.indicator} bg={indicatorColor} />}
      classNames={{
        leftSection: classes.leftSection,
      }}
      data-testid={`order-badge-${label}`}
    >
      {label}
    </Badge>
  );
}

export function PartnerOrderPaymentStatusBadge({
  order,
}: OrderBadgeProps<PartnerSummaryType | PartnerOrderType>) {
  const { classes } = useStyles();

  const { bgColor, indicatorColor, label } =
    getPartnerOrderPaymentStatusColorAndLabel(order.payment_status);

  return (
    <Badge
      size="md"
      bg={bgColor}
      c="gray.9"
      fw={300}
      fz="xs"
      py="sm"
      leftSection={<Box className={classes.indicator} bg={indicatorColor} />}
      classNames={{
        leftSection: classes.leftSection,
      }}
      data-testid={`order-badge-${label}`}
    >
      {label}
    </Badge>
  );
}

const useStyles = createStyles((theme) => ({
  indicator: {
    width: 8,
    height: 8,
    borderRadius: '50%',
  },
  leftSection: {
    marginRight: theme.spacing.xs,
  },
}));
