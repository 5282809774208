import React from 'react';

import { CardBrand } from '@portals/types';

import { ReactComponent as AmexSrc } from '../assets/img/credit-cards-logos/amex.svg';
import { ReactComponent as DinersSrc } from '../assets/img/credit-cards-logos/diners.svg';
import { ReactComponent as DiscoverSrc } from '../assets/img/credit-cards-logos/discover.svg';
import { ReactComponent as JcbSrc } from '../assets/img/credit-cards-logos/jcb.svg';
import { ReactComponent as MastercardSrc } from '../assets/img/credit-cards-logos/mastercard.svg';
import { ReactComponent as UnionpaySrc } from '../assets/img/credit-cards-logos/unionpay.svg';
import { ReactComponent as UnknownSrc } from '../assets/img/credit-cards-logos/unknown.svg';
import { ReactComponent as VisaSrc } from '../assets/img/credit-cards-logos/visa.svg';

const CARD_IMAGE = {
  [CardBrand.amex]: <AmexSrc data-testid="amex-logo" />,
  [CardBrand.diners]: <DinersSrc data-testid="diners-logo" />,
  [CardBrand.discover]: <DiscoverSrc data-testid="discover-logo" />,
  [CardBrand.jcb]: <JcbSrc data-testid="jcb-logo" />,
  [CardBrand.mastercard]: <MastercardSrc data-testid="mastercard-logo" />,
  [CardBrand.unionpay]: <UnionpaySrc data-testid="unionpay-logo" />,
  [CardBrand.unknown]: <UnknownSrc data-testid="unknown-logo" />,
  [CardBrand.visa]: <VisaSrc data-testid="visa-logo" />,
};

interface CreditCardLogoProps {
  brand: CardBrand;
}
export function CreditCardLogo({ brand }: CreditCardLogoProps) {
  return CARD_IMAGE[brand];
}
