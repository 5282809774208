import { Group } from '@mantine/core';
import { DateInput, DateInputProps } from '@mantine/dates';
import React, { Dispatch, SetStateAction } from 'react';

import { ReactComponent as ArrowRight } from '@portals/icons/linear/arrow-right.svg';

import { DateRangeType } from './types';

interface DateInputGroupProps {
  dateRange: DateRangeType;
  setDateRange: Dispatch<SetStateAction<DateRangeType>>;
}

export function DateInputGroup({
  dateRange,
  setDateRange,
}: DateInputGroupProps) {
  return (
    <Group align="center" noWrap>
      <DateInput
        size="md"
        styles={dateInputStyles}
        popoverProps={{ disabled: true }}
        placeholder="MM/DD/YYYY"
        valueFormat="MM/DD/YYYY"
        value={dateRange.startDate}
        onChange={(value) =>
          setDateRange((prevState) => ({ ...prevState, startDate: value }))
        }
      />

      <ArrowRight width={15} height={15} />

      <DateInput
        size="md"
        styles={dateInputStyles}
        popoverProps={{ disabled: true }}
        placeholder="MM/DD/YYYY"
        valueFormat="MM/DD/YYYY"
        value={dateRange.endDate}
        onChange={(value) =>
          setDateRange((prevState) => ({ ...prevState, endDate: value }))
        }
      />
    </Group>
  );
}

const dateInputStyles: DateInputProps['styles'] = (theme) => ({
  wrapper: {
    width: 120,
  },
  input: {
    fontSize: theme.fontSizes.sm,
  },
});
