import { Button, createStyles, Group, Stack, Text } from '@mantine/core';
import React from 'react';

import { ModalCenteredMediaLayout } from '@portals/core';
import { ModalProps } from '@portals/framework';

import watchOverviewVideoSrc from '../../assets/img/watch-overview-video.svg';

export interface WelcomeVideoAvailableLaterModalProps extends ModalProps {}

export function WelcomeVideoAvailableLaterModal({
  closeMe,
}: WelcomeVideoAvailableLaterModalProps) {
  const { classes } = useStyles();

  return (
    <ModalCenteredMediaLayout
      opened
      withCloseButton={false}
      onClose={closeMe}
      media={<img src={watchOverviewVideoSrc} />}
      mediaSize="100%"
      classNames={{
        header: classes.modalHeader,
      }}
      title="Video Available to View Later"
      description={
        <Stack spacing={0} className={classes.description}>
          <Text>
            You can access the overview video anytime though the user menu at
            the top right corner of your screen. Simply navigate to the menu and
            click on
          </Text>
          <Text>'Watch overview video'</Text>
        </Stack>
      }
      footer={
        <Group grow>
          <Button
            variant="default"
            data-testid="close-button"
            onClick={closeMe}
            size="md"
          >
            Close
          </Button>
        </Group>
      }
    />
  );
}

const useStyles = createStyles((theme) => ({
  modalHeader: {
    padding: 0,
  },

  description: { paddingInline: theme.spacing.xxl },
}));
