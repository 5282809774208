import { Text, TextProps, Title, TitleProps } from '@mantine/core';
import { motion } from 'framer-motion';
import React, { ReactNode } from 'react';

interface StepTitleProps extends TitleProps {
  children: ReactNode;
  align?: TitleProps['align'];
}

export function StepTitle({
  children,
  align = 'center',
  ...titleProps
}: StepTitleProps) {
  return (
    <Title
      order={2}
      color="blue_gray.9"
      weight={700}
      align={align}
      {...titleProps}
    >
      {children}
    </Title>
  );
}

interface StepSubtitleProps extends TextProps {
  children: ReactNode;
}

export function StepSubtitle({ children, ...textProps }: StepSubtitleProps) {
  return (
    <Text size="sm" color="blue_gray.5" align="center" {...textProps}>
      {children}
    </Text>
  );
}

export function StepAnimationWrapper({
  stepId,
  children,
}: {
  stepId: string;
  children: ReactNode;
}) {
  return (
    <motion.div
      key={stepId}
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
      transition={{
        duration: 0.15,
      }}
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      exit={{
        opacity: 0,
      }}
    >
      {children}
    </motion.div>
  );
}
