import { Box, createStyles, LoadingOverlay } from '@mantine/core';
import React, { useMemo } from 'react';
import { Navigate } from 'react-router-dom';

import { DeviceDetailsType, DeviceModelType } from '@portals/api/organizations';
import { DeviceType as CommonDeviceType } from '@portals/types';

import { useDeviceContext } from './context';
import { DeviceInfo } from './DeviceInfo';
import { DeviceTabs } from './DeviceTabs';
import { useRouteModalConfig } from '../../route-modals.hooks';
import { RouteModal } from '../../RouteModal';

export interface DeviceProps<
  TDevice extends DeviceDetailsType | CommonDeviceType,
  TDeviceModel extends DeviceModelType
> {
  model: TDeviceModel;
  device: TDevice | null;
  isLoading?: boolean;
}

export function Device<
  TDevice extends DeviceDetailsType | CommonDeviceType,
  TDeviceModel extends DeviceModelType
>({ device, isLoading = false, model }: DeviceProps<TDevice, TDeviceModel>) {
  const { classes } = useStyles();
  const defaultBackgroundPath = useRouteModalConfig('device').backgroundPath;
  const { isUpdatingDevice } = useDeviceContext();

  const content = useMemo(() => {
    if (isLoading) {
      return <LoadingOverlay visible />;
    } else if (!device || !model) {
      return <Navigate to={defaultBackgroundPath} replace />;
    }

    return (
      <>
        <LoadingOverlay visible={isUpdatingDevice} />

        <DeviceInfo device={device} model={model} />

        <DeviceTabs device={device} model={model} />
      </>
    );
  }, [isLoading, device, model, isUpdatingDevice, defaultBackgroundPath]);

  return (
    <RouteModal
      styles={{ content: { overflow: 'hidden !important' } }}
      modalId="device"
      opened={!!device}
    >
      <Box className={classes.container}>{content}</Box>
    </RouteModal>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '385px 1fr',

    [theme.fn.largerThan(1600)]: {
      gridTemplateColumns: '538px 1fr',
    },
  },
}));
