import {
  DevicesPerSpaceViewEnum,
  SpaceTreeNodeType,
} from '@portals/api/organizations';

import { DataLevelEnum } from '../../../overview.types';

const getDevicesCount = (
  space: SpaceTreeNodeType,
  devicesPerSpaceView: DevicesPerSpaceViewEnum
) => {
  if (devicesPerSpaceView === DevicesPerSpaceViewEnum.AllDevices) {
    return space.local_device_count ?? 0;
  } else {
    return space.local_online_devices ?? 0;
  }
};

export function getChartData(
  space: SpaceTreeNodeType,
  devicesPerSpaceView: DevicesPerSpaceViewEnum,
  dataLevel: DataLevelEnum
) {
  if (space.children && space.children.length > 0) {
    const children = space.children.map((child) =>
      getChartData(child, devicesPerSpaceView, dataLevel)
    );

    children.unshift({
      name: space.name,
      size: getDevicesCount(space, devicesPerSpaceView),
    });

    return {
      name: space.name,
      children: children,
    };
  } else {
    return {
      name: space.name,
      size: getDevicesCount(space, devicesPerSpaceView),
    };
  }
}
