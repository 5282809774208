import {
  Button,
  Checkbox,
  Group,
  Modal,
  Stack,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { AnimatePresence, motion } from 'framer-motion';
import { filter, includes, keys, map, size, toLower, xor } from 'lodash/fp';
import React, { PropsWithChildren, useState } from 'react';
import { FilterProps, UseFiltersColumnProps } from 'react-table';

import { ReactComponent as SearchNormal2 } from '@portals/icons/linear/search-normal 2.svg';

interface SelectFilterProps<TData extends object> {
  closeMe: () => void;
  data: {
    column: FilterProps<TData>['column'] & UseFiltersColumnProps<TData>;
    options: Record<string, string>;
    placeholder?: string;
  };
}

function SelectFilter<TData extends object>({
  closeMe,
  data,
}: PropsWithChildren<SelectFilterProps<TData>>) {
  const theme = useMantineTheme();
  const { column, options, placeholder } = data;

  const [searchTerm, setSearchTerm] = useState('');
  const [localFilterState, setLocalFilterState] = useState(
    column.filterValue || keys(options)
  );

  const noOptionsSelected = size(localFilterState) === 0;
  const allOptionsSelected = size(localFilterState) === size(options);

  const onClear = () => {
    column.setFilter(null);
    closeMe();
  };

  const onSubmit = () => {
    column.setFilter(allOptionsSelected ? null : localFilterState);
    closeMe();
  };

  const lowerCaseSearchTerm = toLower(searchTerm);
  const filteredOptions = !lowerCaseSearchTerm
    ? keys(options)
    : filter((key) => {
        const lowerCaseOptionLabel = toLower(options[key]);

        return includes(lowerCaseSearchTerm, lowerCaseOptionLabel);
      }, keys(options));

  return (
    <Modal
      opened={true}
      onClose={closeMe}
      size="lg"
      padding={32}
      title={`Filter by '${data.column.Header}'`}
    >
      <Stack>
        <TextInput
          data-testid="select-filter-search-input"
          rightSection={<SearchNormal2 color={theme.colors.blue_gray[6]} />}
          placeholder={placeholder || 'Search...'}
          mb="md"
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.target.value)}
          sx={{
            svg: {
              width: 14,
              height: 14,
            },
          }}
        />

        <AnimatePresence initial={false}>
          {map(
            (key) => (
              <motion.div
                key={key}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <Checkbox
                  data-testid={`select-filter-${key}`}
                  label={options[key]}
                  checked={includes(key, localFilterState)}
                  onChange={() =>
                    setLocalFilterState((curr) => xor([key], curr || []))
                  }
                />
              </motion.div>
            ),
            filteredOptions
          )}
        </AnimatePresence>

        <Group position="right" mt="md">
          <Button
            variant="subtle"
            color="blue_gray"
            size="sm"
            onClick={closeMe}
            data-testid="select-filter-cancel-button"
          >
            Cancel
          </Button>

          <Button
            variant="outline"
            color="blue_gray"
            size="sm"
            onClick={onClear}
            data-testid="select-filter-clear-button"
          >
            Clear Filters
          </Button>

          <Button
            size="sm"
            disabled={noOptionsSelected}
            onClick={onSubmit}
            data-testid="select-filter-apply-button"
          >
            Apply Filters
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
}

export default SelectFilter;
