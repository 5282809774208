import { useMutation, useQueryClient } from '@tanstack/react-query';
import { find, findIndex, forEach, set, without } from 'lodash/fp';
import { useDispatch } from 'react-redux';

import {
  NewWidgetConfigType,
  WidgetConfigType,
  WidgetType,
} from '@portals/device-widgets';
import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';

import {
  DEVICE_MODELS_API_URL,
  deviceModelsQueryKeys,
  getDeviceModelApiUrl,
} from './device-models.constants';
import { useApiQuery } from '../../hooks';
import { ServerError, ServerSuccess } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils';

function getApiUrl(modelId: string) {
  return `${getDeviceModelApiUrl(modelId)}/device_widgets`;
}

export const useDeviceModelWidgetsData = (modelId: string) =>
  useApiQuery<Array<WidgetType>>(
    getApiUrl(modelId),
    deviceModelsQueryKeys.widgets.all(modelId)
  );

interface CreateDeviceModelWidgetParams {
  name: string;
  config: WidgetConfigType;
}

export const useCreateDeviceModelWidget = (modelId: string) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: getApiUrl(modelId),
    method: 'POST',
  });

  return useMutation<WidgetType, ServerError, CreateDeviceModelWidgetParams>({
    mutationFn: ({ name, config }) =>
      fetchApiRequest(url, {
        ...options,
        body: JSON.stringify({ name, config }),
      }),
    onSuccess: (data) => {
      dispatch(toastrSuccess(`Widget ${data.name} created`));

      const currentWidgets = queryClient.getQueryData<Array<WidgetType>>(
        deviceModelsQueryKeys.widgets.all(modelId)
      );

      const updatedWidgets = [...(currentWidgets || []), data];

      queryClient.setQueryData(
        deviceModelsQueryKeys.widgets.all(modelId),
        updatedWidgets
      );
    },
    onError: ({ error }) => dispatch(toastrError(error)),
    meta: {
      mutationName: 'useCreateDeviceModelWidget',
      baseUrl: `${DEVICE_MODELS_API_URL}/:id/device_widgets`,
      method: 'POST',
    },
  });
};

export const useUpdateDeviceModelWidget = (modelId: string) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: getApiUrl(modelId),
    method: 'PUT',
  });

  return useMutation({
    mutationFn: ({
      widget,
      widgetId,
    }: {
      widget: WidgetType;
      widgetId: string;
      withNotification?: boolean;
    }) =>
      fetchApiRequest(`${url}/${widgetId}`, {
        ...options,
        body: JSON.stringify(widget),
      }),
    onSuccess: (data: WidgetType, { withNotification }) => {
      queryClient.invalidateQueries(deviceModelsQueryKeys.widgets.all(modelId));

      if (withNotification) {
        dispatch(toastrSuccess(`Widget ${data.name} updated`));
      }

      const currentWidgets =
        queryClient.getQueryData<Array<WidgetType>>(
          deviceModelsQueryKeys.widgets.all(modelId)
        ) || [];
      const updatedWidgetIndex = findIndex({ id: data?.id }, currentWidgets);
      const updatedWidgets = set(updatedWidgetIndex, data, currentWidgets);

      queryClient.setQueryData(
        deviceModelsQueryKeys.widgets.all(modelId),
        updatedWidgets
      );
    },
    onError: ({ error }) => dispatch(toastrError(error)),
    meta: {
      mutationName: 'useUpdateDeviceModelWidget',
      baseUrl: `${DEVICE_MODELS_API_URL}/:id/device_widgets/:id`,
      method: 'PUT',
    },
  });
};

export const useUpdateDeviceModelWidgets = (modelId: string) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: `${getApiUrl(modelId)}/bulk_update`,
    method: 'POST',
  });

  return useMutation({
    mutationFn: (widgetsToUpdate: Array<WidgetType>) =>
      fetchApiRequest(url, {
        ...options,
        body: JSON.stringify(widgetsToUpdate),
      }),
    onSuccess: (data: Array<WidgetType>, variables) => {
      forEach((widget) => {
        queryClient.invalidateQueries(
          deviceModelsQueryKeys.widgets.all(modelId)
        );

        if (!widget) return;

        const currentWidgets =
          queryClient.getQueryData<Array<WidgetType>>(
            deviceModelsQueryKeys.widgets.all(modelId)
          ) || [];
        const updatedWidgetIndex = findIndex(
          { id: widget?.id },
          currentWidgets
        );
        const updatedWidgets = set(updatedWidgetIndex, widget, currentWidgets);

        queryClient.setQueryData(
          deviceModelsQueryKeys.widgets.all(modelId),
          updatedWidgets
        );
      }, variables);
    },
    onError: ({ error }) => dispatch(toastrError(error)),
    meta: {
      mutationName: 'useUpdateDeviceModelWidgets',
      baseUrl: `${DEVICE_MODELS_API_URL}/:id/device_widgets/bulk_update`,
      method: 'POST',
    },
  });
};

export const useRemoveDeviceModelWidget = (modelId: string) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: getApiUrl(modelId),
    method: 'DELETE',
  });

  return useMutation({
    mutationFn: (widgetId: string) =>
      fetchApiRequest(`${url}/${widgetId}`, options),
    onSuccess: (data, widgetId) => {
      dispatch(toastrSuccess(`Widget removed`));

      const currentWidgets =
        queryClient.getQueryData<Array<WidgetType>>(
          deviceModelsQueryKeys.widgets.all(modelId)
        ) || [];
      const removedWidget = find({ id: widgetId }, currentWidgets);
      const updatedWidgets = without([removedWidget], currentWidgets);

      queryClient.setQueryData(
        deviceModelsQueryKeys.widgets.all(modelId),
        updatedWidgets
      );
    },
    onError: ({ error }) => dispatch(toastrError(error)),
    meta: {
      mutationName: 'useRemoveDeviceModelWidget',
      baseUrl: `${DEVICE_MODELS_API_URL}/:id/device_widgets/:id`,
      method: 'DELETE',
    },
  });
};

interface MigrateLegacyWidgetsParams {
  widgets: Array<{
    name: string;
    config: NewWidgetConfigType;
  }>;
}

export function useMigrateLegacyWidgets(modelId: string) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: getApiUrl(modelId),
    method: 'POST',
  });

  return useMutation<ServerSuccess, ServerError, MigrateLegacyWidgetsParams>({
    mutationFn: ({ widgets }) =>
      fetchApiRequest(`${url}/migrate`, {
        ...options,
        body: JSON.stringify({ widgets }),
      }),
    onSuccess: (data) => {
      if (data.success) {
        queryClient.invalidateQueries(
          deviceModelsQueryKeys.widgets.new(modelId)
        );
      }
    },
    onError: (error) => {
      dispatch(toastrError(error?.error));
    },
    meta: {
      mutationName: 'useMigrateLegacyWidgets',
      baseUrl: `${DEVICE_MODELS_API_URL}/:id/device_widgets/migrate`,
      method: 'POST',
    },
  });
}
