import { forEach, isEmpty, isUndefined, omit, size } from 'lodash/fp';
import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Column,
  Row,
  TableRowProps,
  useExpanded,
  useFilters,
  UseFiltersState,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  UseSortByState,
  useTable,
  UseTableHooks,
} from 'react-table';

import {
  ExpandRowType,
  RESULTS_PER_PAGE,
  RowType,
  TableColumn,
  TableDetailsPanelType,
  TableInstanceType,
  TableOptions,
  TableState,
} from '@portals/types';
import {
  getSearchParamsFromTableState,
  getTableStateFromSearchParams,
} from '@portals/utils';

import { ColumnCheckbox } from './components/Filters';
import { ACTION_COLUMN, CHECKBOX_CELL_ID } from './table.constants';
import { getFilter, getFilterRenderer, getSortType } from './table.utils';

interface UseTableInstanceType<TData extends object> {
  expandRow?: ExpandRowType<TData>;
  onSelected?: (selectedRows: Array<string>) => void;
  selected?: Array<string>;
  rowStyle?: (row: TData) => CSSProperties;
  searchParams: Partial<TableState<TData>>;
  isServerFiltering?: boolean;
  totalCount?: number;
  readOnly: boolean;
}

export const useCreateTableInstance = <TData extends object>(
  data: Array<TData>,
  columns: Array<TableColumn<TData>>,
  {
    expandRow,
    onSelected,
    rowStyle,
    searchParams,
    isServerFiltering = false,
    totalCount,
    readOnly,
  }: UseTableInstanceType<TData>
) => {
  const adjustedColumns: Array<Column<TData>> = useColumnsList(
    columns,
    readOnly
  );
  const tableHooks = useTableHooks(expandRow, onSelected, rowStyle, readOnly);

  const tableOptions: TableOptions<TData> = useMemo(
    () => ({
      columns: adjustedColumns,
      data,
      manualFilters: isServerFiltering,
      manualSortBy: isServerFiltering,
      manualPagination: isServerFiltering,
      pageCount: totalCount || size(data),
      disableMultiSort: true,
      autoResetSortBy: true,
      autoResetFilters: false,
      initialState: searchParams,
    }),
    [adjustedColumns, data, isServerFiltering, searchParams, totalCount]
  );

  // Returns an instance of the `react-table` object, with all its state values, filters,
  // callbacks & API methods
  return useTable<TData>(
    tableOptions,
    ...tableHooks
  ) as TableInstanceType<TData>;
};

// Go over the config of the columns, and adjust their structure to react-table's API
export function useColumnsList<TData extends object>(
  columns: Array<TableColumn<TData>>,
  isReadOnly: boolean
): Array<Column<TData>> {
  return useMemo(
    () =>
      columns.map((column) => {
        const {
          sort,
          dataField,
          isAction,
          text,
          filter,
          formatter,
          readOnlyFormatter,
          headerFormatter,
          sortValue,
          minWidth,
          maxWidth,
          isSticky,
          isEditable,
          withTooltip,
          withGlobalFilter,
        } = column;

        return {
          Header: headerFormatter ? headerFormatter(column) : text,
          accessor: dataField as keyof TData, // which field in row holds the data for each cell
          canFilter: !!filter,
          disableSortBy: isAction || !sort,
          Filter: getFilterRenderer(filter),
          filter: getFilter(dataField, filter),
          filterType: filter?.type,
          sortType: sortValue || getSortType(filter),
          minWidth: minWidth || (filter || isAction ? 250 : 150),
          maxWidth: maxWidth || 500,
          isSticky,
          isEditable,
          disableGlobalFilter: withGlobalFilter,
          withTooltip,
          Cell: ({ row, cell }) => {
            let cellValue;

            if (isReadOnly && readOnlyFormatter) {
              // read-only cell renderer
              cellValue = readOnlyFormatter(cell, row?.original || {}, row);
            } else if (formatter) {
              // cell renderer
              cellValue = formatter(cell, row?.original || {}, row);
            } else {
              cellValue = cell.value;
            }

            // simply render the value, if no renderer passed
            return isUndefined(cellValue) ? null : cellValue;
          },
        };
      }),
    [columns, isReadOnly]
  );
}

// react-table is modular, doesn't come with much functionality in its core.
// we can pass various hooks/plugins to the table, to enable specific features
export const useTableHooks = <TData extends object>(
  expandRow: ExpandRowType<TData>,
  onSelected: (selectedRows: Array<string>) => void,
  rowStyle: (row: TData) => CSSProperties,
  readOnly: boolean
) => {
  return useMemo(() => {
    // Default list of plugins
    const activeHooks: Array<any> = [
      useFilters,
      useGlobalFilter,
      useSortBy,
      useFlexLayout,
    ];

    // Make rows toggleable
    if (expandRow) {
      activeHooks.push(useExpanded);

      // Add a column for row toggle
      if (expandRow.showExpandColumn) {
        activeHooks.push((hooks: UseTableHooks<TData>) => {
          // Renders additional column with checkboxes, if `expandRow` prop is passed to SmartTable
          hooks.visibleColumns.push((columns) => [
            {
              ...ACTION_COLUMN,
              id: 'row-expand-column',
              Header: expandRow.expandHeaderColumnRenderer,
              Cell: ({ row }) =>
                expandRow.expandColumnRenderer({
                  expanded: row.isExpanded,
                }),
            },
            ...columns,
          ]);
        });
      }
    }

    activeHooks.push(usePagination);

    // Add a hook for row-specific styling
    if (rowStyle) {
      activeHooks.push((hooks) => {
        hooks.getRowProps.push(
          (
            props: TableRowProps,
            { row }: { instance: TableInstanceType<TData>; row: Row<TData> }
          ) => ({
            ...props,
            style: {
              ...(props?.style || {}),
              ...(rowStyle(row.original) || {}),
            },
          })
        );
      });
    }

    if (onSelected) {
      activeHooks.push(useRowSelect);
      activeHooks.push((hooks) => {
        // Renders additional column with checkboxes, if `onSelected` prop is passed to SmartTable
        hooks.visibleColumns.push((columns) => [
          {
            id: 'row-selection-column',
            // `getToggleAllRowsSelectedProps` is a prop coming from react-table's rendering,
            // available when used together with `useRowSelect` we pushed above
            Header: ({ getToggleAllPageRowsSelectedProps }) => (
              <ColumnCheckbox
                {...getToggleAllPageRowsSelectedProps()}
                id="column-checkbox-header"
                disabled={readOnly}
              />
            ),
            Cell: ({ row }) => (
              <ColumnCheckbox
                {...row.getToggleRowSelectedProps()}
                data-testid="check-box-button"
                id={`${CHECKBOX_CELL_ID}-${row.id}`}
                disabled={readOnly}
              />
            ),
            ...ACTION_COLUMN,
          },
          ...columns,
        ]);
      });
    }

    return activeHooks;
  }, [expandRow, onSelected, readOnly, rowStyle]);
};

// Wrapper for regular `useState` hook, which allows setting initial state based on provided
// default filters & URL search params
export function useTableStateWithUrlSync<TData extends object>({
  sortBy,
  filters,
  isUrlSyncEnabled = true,
  columns,
  hiddenColumns,
  expanded,
  pageSize,
}: {
  sortBy: UseSortByState<TData>['sortBy'];
  filters: UseFiltersState<TData>['filters'];
  hiddenColumns: TableState<TData>['hiddenColumns'];
  expanded?: TableState<TData>['expanded'];
  isUrlSyncEnabled?: boolean;
  columns: Array<TableColumn>;
  pageSize?: number;
}) {
  const { search } = useLocation();
  const initialTableState = useRef(
    getTableStateFromSearchParams({ urlSearch: search, columns, pageSize })
  );

  const tableState = useState<Partial<TableState<TData>>>({
    sortBy,
    filters,
    hiddenColumns,
    ...(expanded ? { expanded } : {}),
    ...(isUrlSyncEnabled
      ? initialTableState.current
      : {
          pageIndex: 0,
          pageSize: pageSize || RESULTS_PER_PAGE,
        }),
  });

  const stateWithoutHiddenColumns = useMemo(
    () => omit(['hiddenColumns', 'expanded'], tableState[0]),
    [tableState]
  );

  useSyncUrlWithTableState({
    isUrlSyncEnabled,
    queryParams: stateWithoutHiddenColumns,
    columns,
  });

  return tableState;
}

// Hook for syncing URL with table state
export const useSyncUrlWithTableState = <TData extends object>({
  queryParams,
  isUrlSyncEnabled,
  columns,
}: {
  queryParams: Partial<TableState<TData>>;
  columns: Array<TableColumn>;
  isUrlSyncEnabled: boolean;
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { pathname, search } = location;

  // Update current URL to match tables filters state
  useEffect(
    function updateUrlSearchParams() {
      if (!isUrlSyncEnabled) return;

      // Get list of current filters based on queryParams
      const tableSearchParams = getSearchParamsFromTableState(
        queryParams,
        columns
      );

      // If all filters been removed - remove search params from URL
      if (isEmpty(tableSearchParams) && !!search) {
        navigate({ pathname, search: '' }, { replace: true });

        return;
      }

      const urlSearchParams = new URLSearchParams();

      // Assign current filters to URL search params
      forEach(({ value, key }) => {
        if (key === 'per_page') return;

        urlSearchParams.append(key, value);
      }, tableSearchParams);

      const urlSearchParamsString = urlSearchParams.toString();
      const hasActiveFilters = !!urlSearchParamsString;

      if (!hasActiveFilters && search !== '') {
        navigate({ pathname, search: '' }, { replace: true });

        return;
      }

      if (hasActiveFilters && search !== `?${urlSearchParamsString}`) {
        navigate(
          { pathname, search: urlSearchParamsString },
          { replace: true }
        );
      }
    },
    [pathname, search, queryParams, isUrlSyncEnabled, columns, navigate]
  );
};

export function useOnRowClick<
  TData extends object,
  TKeyField extends keyof TData
>({
  onRowClick,
  detailsPanel,
  keyField,
  rows,
  disabledRowClickId,
}: {
  detailsPanel?: TableDetailsPanelType<TData>;
  onRowClick?: (row: RowType<TData>) => void;
  keyField: TKeyField;
  disabledRowClickId?: string;
  rows: Row<TData>[];
}) {
  const [clickedRowOriginalId, setClickedRowOriginalId] =
    useState<TData[TKeyField]>();

  const onRowClickHandler = useCallback(
    (row: RowType<TData>) => {
      if (detailsPanel || onRowClick) {
        onRowClick?.(row);
        setClickedRowOriginalId(row.original[keyField]);
      }
    },
    [detailsPanel, keyField, onRowClick]
  );

  const onCloseDetailsPanel = useCallback(() => {
    setClickedRowOriginalId(undefined);
  }, []);

  const clickedRow = useMemo(() => {
    if (!clickedRowOriginalId) return null;
    if (disabledRowClickId) {
      return rows.find((row) => row.original[keyField] === disabledRowClickId);
    }

    return rows.find((row) => row.original[keyField] === clickedRowOriginalId);
  }, [clickedRowOriginalId, keyField, disabledRowClickId, rows]);

  return {
    clickedRowOriginalId,
    onRowClickHandler:
      detailsPanel || onRowClick ? onRowClickHandler : undefined,
    onCloseDetailsPanel,
    clickedRow,
  };
}
