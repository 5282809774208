import { Box, Tooltip } from '@mantine/core';
import React, { ReactNode } from 'react';

import { DeviceType, SpaceType } from '@portals/api/organizations';
import {
  getIncidentsByDeviceIdTooltipContent,
  getIncidentsBySpaceIdTooltipContent,
} from '@portals/utils';

export interface IncidentsBySpaceIdTooltipProps {
  isLocal?: boolean;
  space?: SpaceType;
  children: ReactNode;
}

export function IncidentsBySpaceIdTooltip({
  space,
  isLocal,
  children,
}: IncidentsBySpaceIdTooltipProps) {
  if (!space) return <>{children}</>;

  const incidentsState = isLocal
    ? space?.state?.local_incidents
    : space?.state?.incidents;

  return (
    <Tooltip
      label={getIncidentsBySpaceIdTooltipContent(incidentsState)}
      withinPortal
      disabled={!incidentsState}
    >
      {children}
    </Tooltip>
  );
}

interface IncidentsByDeviceIdTooltipProps {
  device: DeviceType;
  children: ReactNode;
}

export function IncidentsByDeviceTooltip({
  device,
  children,
}: IncidentsByDeviceIdTooltipProps) {
  return (
    <Tooltip
      withinPortal
      label={getIncidentsByDeviceIdTooltipContent(device.incidents)}
      disabled={!device?.incidents || device.incidents.length === 0}
    >
      <Box>{children}</Box>
    </Tooltip>
  );
}
