import { globalQueryKeys } from '../global-query-keys';

export const DEVICES_API_URL = 'ui/organization/devices';

export function getDeviceApiUrl(deviceId: string) {
  return `${DEVICES_API_URL}/${deviceId}`;
}

export function getDeviceStateHistoryApiUrl(deviceId: string) {
  return `${getDeviceApiUrl(deviceId)}/device_state_histories`;
}

export function getDeviceEventsApiUrl(deviceId: string) {
  return `${getDeviceApiUrl(deviceId)}/device_events`;
}

export const devicesQueryKeys = {
  global: [...globalQueryKeys.devices],
  all: () => [...devicesQueryKeys.global, DEVICES_API_URL],
  details: (deviceId: string) => [
    ...devicesQueryKeys.all(),
    'details',
    deviceId,
  ],
  stateHistory: (deviceId: string) => [
    ...devicesQueryKeys.details(deviceId),
    'stateHistory',
  ],
  events: (deviceId: string) => [
    ...devicesQueryKeys.details(deviceId),
    'events',
  ],
  commands: {
    base: ['commands'],
    all: (deviceId: string) => [
      ...devicesQueryKeys.details(deviceId),
      ...devicesQueryKeys.commands.base,
    ],
    active: (deviceId: string) => [
      ...devicesQueryKeys.commands.all(deviceId),
      'active',
    ],
    failed: (deviceId: string) => [
      ...devicesQueryKeys.commands.all(deviceId),
      'failed',
    ],
  },
  stateDumps: {
    base: ['stateDumps'],
    all: (deviceId: string) => [
      ...devicesQueryKeys.details(deviceId),
      ...devicesQueryKeys.stateDumps.base,
    ],
  },
  telemetries: {
    all: (deviceId: string) => [
      ...devicesQueryKeys.details(deviceId),
      'telemetries',
    ],
  },
  licenses: {
    global: [...globalQueryKeys.licenses],
    all: (deviceId: string) => [
      ...devicesQueryKeys.licenses.global,
      ...devicesQueryKeys.details(deviceId),
    ],
    list: (deviceId: string) => [
      ...devicesQueryKeys.licenses.all(deviceId),
      'list',
    ],
  },
  warranties: {
    global: [...globalQueryKeys.warranties],
    all: (deviceId: string) => [
      ...devicesQueryKeys.warranties.global,
      ...devicesQueryKeys.details(deviceId),
    ],
    list: (deviceId: string) => [...devicesQueryKeys.warranties.all(deviceId)],
  },
};
