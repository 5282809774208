import { useDispatch } from 'react-redux';

import { updateData } from '@portals/redux/actions/data';

import { devicesQueryKeys, getDeviceApiUrl } from './devices.constants';
import {
  AwsDeviceTelemetryType,
  DeviceTelemetriesResponseType,
} from './devices.types';
import { useApiQuery } from '../../hooks/query';

function getApiUrl(deviceId: string) {
  return `${getDeviceApiUrl(deviceId)}/telemetries`;
}

export function useDeviceTelemetries(deviceId: string) {
  const dispatch = useDispatch();

  return useApiQuery<
    DeviceTelemetriesResponseType | Array<AwsDeviceTelemetryType>
  >(getApiUrl(deviceId), devicesQueryKeys.telemetries.all(deviceId), {
    onSuccess: (response) => {
      dispatch(updateData('telemetries')(response));
    },
    staleTime: 0,
  });
}
