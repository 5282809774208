import { Group, SegmentedControl, Stack, Text } from '@mantine/core';
import React, { ReactNode } from 'react';
import { Navigate, useMatch, useNavigate } from 'react-router-dom';

import { ComponentRendererType, TabType } from '@portals/types';

interface ButtonTabsProps {
  tabs: Array<TabType>;
  children: (selectedTab: TabType, currUrl: string) => ReactNode;
  urlBase: string;
  titleWrap: ComponentRendererType<any>;
}

export function ButtonTabs({
  tabs,
  children,
  urlBase,
  titleWrap,
}: ButtonTabsProps) {
  const navigate = useNavigate();
  const match = useMatch({ path: `${urlBase}/:tabId`, end: false });

  const selectedTab = tabs.find(({ id }) => id === match?.params?.tabId);

  if (match === null || !selectedTab) {
    const defaultTabId = tabs[0].id;

    return <Navigate to={`${urlBase}/${defaultTabId}`} />;
  }

  const currUrl = `${urlBase}/${selectedTab.id}`;

  const labelRenderer = (tab: TabType) => {
    const title = titleWrap ? React.createElement(titleWrap, tab) : tab.title;

    return (
      <Group align="center" position="center" spacing="sm" noWrap px="lg">
        <Text inherit>{title}</Text>

        {tab.badge || null}
      </Group>
    );
  };

  return (
    <Stack>
      <SegmentedControl
        styles={{ root: { width: 'fit-content' } }}
        data={tabs.map((tab) => ({
          value: tab.id,
          label: labelRenderer(tab),
        }))}
        value={match?.params?.tabId}
        onChange={(value) => navigate(`${urlBase}/${value}`)}
      />

      {children(selectedTab, currUrl)}
    </Stack>
  );
}
