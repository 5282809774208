import { StackProps, StackStylesParams } from '@mantine/core';

import { ThemeComponentOverrides } from '../common-types';

export const Stack: ThemeComponentOverrides<
  StackProps,
  never,
  StackStylesParams
> = {
  defaultProps: {
    align: 'stretch',
  },
};
