import { Stack } from '@mantine/core';
import React from 'react';

import { DeviceDetailsType } from '@portals/api/organizations';

import { DeviceChildren } from './DeviceChildren';
import { DeviceInfo } from './DeviceInfo';
import { DeviceParent } from './DeviceParent';

export interface DeviceDetailsProps {
  device: DeviceDetailsType;
}

export function DeviceDetails({ device }: DeviceDetailsProps) {
  return (
    <Stack bg="gray.0" p="md" h="100%" spacing="md">
      <DeviceChildren device={device} />

      <DeviceParent device={device} />

      <DeviceInfo device={device} />
    </Stack>
  );
}
