import { Badge, Box, createStyles } from '@mantine/core';
import React from 'react';

import {
  useDesign,
  useOrganizationConfig,
  usePortalCapabilities,
} from '@portals/api/organizations';
import {
  isCustomerFeatureAll,
  isCustomerFeatureLabOnly,
} from '@portals/framework';

import { UserAvatar } from './common';

interface TenantUserProps {
  isActive: boolean;
  isDisabled?: boolean;
  logoSrc?: string;
  label: string;
  isLab?: boolean;
  partner?: string;
}

function TenantUser({
  isActive,
  logoSrc,
  isLab = false,
  isDisabled,
}: TenantUserProps) {
  const { classes, cx } = useStyles();

  return (
    <Box
      className={cx(classes.container, 'tenant-user-container', {
        hoverable: !isDisabled,
      })}
      data-testid="tenant-user-content"
    >
      {isLab ? (
        <Badge
          color="gray.9"
          size="xs"
          variant="filled"
          sx={{
            position: 'absolute',
            top: 0,
            transform: 'translateY(-50%)',
            zIndex: 99,
          }}
        >
          LAB
        </Badge>
      ) : null}

      <UserAvatar logoSrc={logoSrc} isActive={isActive} />
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing.md,
    height: '100%',
    position: 'relative',

    '&.hoverable': {
      cursor: 'pointer',

      '&:hover': {
        '.tenant-menu-avatar': {
          borderColor: theme.colors.blue_accent[4],
        },

        '.tenant-user-menu': {
          color: theme.colors.blue_accent[4],
        },

        '.tenant-menu-toggle': {
          backgroundColor: theme.colors.gray[1],
        },
      },
    },
  },
  content: {
    height: '100%',
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing.md,
    overflow: 'hidden',
  },
  toggle: {
    transition: 'all 0.1s ease-in-out',
    color: theme.colors.blue_gray[2],
    transform: 'rotate(0deg)',

    svg: {
      width: 18,
      height: 18,
    },

    '&.active': {
      color: theme.colors.blue_accent[4],
    },
  },
}));

export function OrganizationUser({
  isActive,
  isDisabled,
}: Pick<TenantUserProps, 'isActive' | 'isDisabled'>) {
  const organization = useOrganizationConfig();
  const design = useDesign();
  const portalCapabilities = usePortalCapabilities();

  if (!organization) return null;

  // If Org is B2C, use partner_display_name as label
  const label =
    isCustomerFeatureAll(portalCapabilities?.b2c_view) ||
    isCustomerFeatureLabOnly(portalCapabilities?.b2c_view, organization?.lab)
      ? organization?.partner_display_name
      : organization?.name;

  const partner =
    !isCustomerFeatureAll(portalCapabilities?.b2c_view) &&
    !isCustomerFeatureLabOnly(portalCapabilities?.b2c_view, organization?.lab)
      ? organization?.partner_display_name
      : '';

  return (
    <TenantUser
      isDisabled={isDisabled}
      isActive={isActive}
      logoSrc={design?.navigation_menu?.logo ?? undefined}
      isLab={organization?.lab}
      label={label}
      partner={partner}
    />
  );
}
