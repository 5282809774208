import { Stack, Text, Button, ButtonProps } from '@mantine/core';
import React from 'react';
import { Link } from 'react-router-dom';

import { error404 } from '@portals/assets';

interface Page404Props {
  title?: string | JSX.Element | Array<string | JSX.Element>;
  description?: string | JSX.Element;
  actionLabel?: string | JSX.Element;
  hideActionButton?: boolean;
  hideImage?: boolean;
}

const DEFAULT_TITLE = ['Oops...', 'The page not found'];
const DEFAULT_DESC =
  "The page your are looking for doesn't exist or has been moved";
const DEFAULT_ACTION_LABEL = 'Return to main page';

export function Page404({
  title = DEFAULT_TITLE,
  description = DEFAULT_DESC,
  actionLabel = DEFAULT_ACTION_LABEL,
  hideActionButton = false,
  hideImage = false,
}: Page404Props) {
  return (
    <Stack h="100%" align="center" justify="center" ta="center">
      {!hideImage && <img src={error404} alt="404" />}

      <Text fw={500} data-testid="404-page-title" fz={28}>
        {title}
      </Text>

      <Text>{description}</Text>

      {!hideActionButton && (
        <Button variant="default" component={Link} to="/" styles={buttonStyles}>
          {actionLabel}
        </Button>
      )}
    </Stack>
  );
}

const buttonStyles: ButtonProps['styles'] = (theme) => ({
  root: {
    ':hover': {
      color: theme.colors.gray[9],
      textDecoration: 'none',
    },
  },
});
