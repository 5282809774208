import {
  CheckboxProps,
  CheckboxStylesNames,
  CheckboxStylesParams,
} from '@mantine/core';

import { ThemeComponentOverrides } from '../common-types';

export const Checkbox: ThemeComponentOverrides<
  CheckboxProps,
  CheckboxStylesNames,
  CheckboxStylesParams
> = {
  styles: (theme) => ({
    label: {
      marginBottom: 0,
      color: theme.colors.gray[7],
      fontWeight: 400,
    },
  }),
};
