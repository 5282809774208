import {
  Button,
  createStyles,
  Group,
  LoadingOverlay,
  SegmentedControl,
  Stack,
  StackProps,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { isEmpty, isNil } from 'lodash/fp';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useUpdateEffect } from 'react-use';
import {
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';

import { CustomScatterDot } from './CustomScatterDot';
import { LegendLabel } from './LegendLabel';
import {
  ScatterChartWidgetEventsConfig,
  WidgetDataRangeEnum,
} from './scatter-chart.types';
import { TooltipContent } from './TooltipContent';
import { XAxisLabel, XAxisLabelProps } from './XAxisLabel';
import { ZoomableScatterChart } from './ZoomableScatterChart';
import { FORMATTERS } from '../../../utils/formatters';
import { OfflineDeviceStateTooltip } from '../../common/OfflineDeviceStateTooltip';
import { NumberFormatType, ScaleType } from '../../widgets.types';

export interface ScatterChartWidgetProps {
  id?: string;
  title: string;
  eventsConfig: ScatterChartWidgetEventsConfig;
  data: Array<{
    eventId: string;
    timestamp: number;
    value: number | null;
  }>;
  stackProps?: StackProps;
  range?: WidgetDataRangeEnum;
  setRange?: Dispatch<SetStateAction<WidgetDataRangeEnum>>;
  isLoading?: boolean;
  scaleType: ScaleType;
  numberFormat: NumberFormatType;
  numOfDecimals: number;
  isDeviceOffline?: boolean;
  lastUpdateTimestamp?: string;
  withZoom?: boolean;
}

export function ScatterChartWidget({
  id,
  title,
  eventsConfig,
  data,
  stackProps,
  range,
  setRange,
  isLoading = false,
  scaleType,
  numberFormat,
  numOfDecimals,
  isDeviceOffline,
  lastUpdateTimestamp,
  withZoom = true,
}: ScatterChartWidgetProps) {
  const theme = useMantineTheme();
  const { classes } = useStyles();

  const [disabledEventIds, setDisabledEventIds] = useState<string[]>([]);
  const [hoveredEventId, setHoveredEventId] = useState<string | null>(null);

  const [zoomDomain, setZoomDomain] = useState<{
    x: [number, number];
    y: [number, number];
  } | null>(null);

  const isZoomed = zoomDomain !== null;
  const formatter = useCallback(
    (value: number | undefined) =>
      FORMATTERS[numberFormat](value, numOfDecimals),
    [numberFormat, numOfDecimals]
  );

  const nullifyNegativeValues = useCallback((rawData: typeof data) => {
    return rawData.map((dataItem) => {
      if (dataItem.value !== null && dataItem.value < 0) {
        return { ...dataItem, value: null };
      }

      return dataItem;
    });
  }, []);

  useUpdateEffect(
    function resetZoomOnRangeUpdate() {
      setZoomDomain(null);
    },
    [range]
  );

  const chartData = useMemo(() => {
    let allDataPoints;

    if (scaleType === 'log') {
      allDataPoints = nullifyNegativeValues(data);
    } else {
      allDataPoints = data;
    }

    const scatterDataMap = Object.keys(eventsConfig).reduce((acc, eventId) => {
      acc[eventId] = [];
      return acc;
    }, {} as Record<string, typeof data>);

    allDataPoints.forEach((dataPoint) => {
      scatterDataMap[dataPoint.eventId].push(dataPoint);
    });

    return scatterDataMap;
  }, [data, eventsConfig, nullifyNegativeValues, scaleType]);

  const enrichedData = useMemo(() => {
    // Flatten all data points from different events into a single array
    const allPoints = Object.values(chartData).flat();
    // Create a Map to store information about overlapping points
    const pointMap = new Map();

    // First pass: Count overlapping points and store their indices
    allPoints.forEach((point, index) => {
      // Skip points with undefined or null values
      if (point.value === undefined || point.value === null) return;

      // Create a key by rounding timestamp and value
      // This groups points that are very close to each other
      const key = `${Math.round(point.timestamp)},${
        Math.round(point.value * 100) / 100
      }`;

      if (!pointMap.has(key)) {
        // If it's a new key, initialize count to 1 and store the index
        pointMap.set(key, { count: 1, indices: [index] });
      } else {
        // If the key exists, increment count and add the index
        const existing = pointMap.get(key);
        existing.count++;
        existing.indices.push(index);
      }
    });

    // Second pass: Enrich each point with overlap information
    return allPoints.map((point, index) => {
      // Skip points with undefined or null values
      if (point.value === undefined || point.value === null) return null;

      // Recreate the key to fetch overlap information
      const key = `${Math.round(point.timestamp)},${
        Math.round(point.value * 100) / 100
      }`;
      const { count, indices } = pointMap.get(key);

      // Return the original point with additional jitter information
      return {
        ...point,
        totalOverlapping: count, // Total number of points at this position
        jitterIndex: indices.indexOf(index), // Index of this point within the overlapping group
      };
    });
  }, [chartData]);

  const onLegendItemClick = useCallback((eventIdToToggle: string) => {
    setDisabledEventIds((prev) => {
      if (prev.includes(eventIdToToggle)) {
        return prev.filter((eventId) => eventId !== eventIdToToggle);
      } else {
        return prev.concat(eventIdToToggle);
      }
    });
  }, []);

  const resetZoom = () => setZoomDomain(null);

  const filteredChartDataByZoomDomain = useMemo(() => {
    if (!zoomDomain || !withZoom) return chartData;

    const [xMin, xMax] = zoomDomain.x;
    const [yMin, yMax] = zoomDomain.y;

    return Object.entries(chartData).reduce((acc, [eventId, eventData]) => {
      acc[eventId] = eventData.filter(
        (point) =>
          point.timestamp >= xMin &&
          point.timestamp <= xMax &&
          (point?.value ?? 0) >= yMin &&
          (point?.value ?? 0) <= yMax
      );
      return acc;
    }, {} as typeof chartData);
  }, [chartData, withZoom, zoomDomain]);

  return (
    <Stack
      className={classes.container}
      p="xl"
      h="100%"
      w="100%"
      bg="white"
      justify="center"
      spacing="xl"
      pos="relative"
      {...stackProps}
    >
      <LoadingOverlay visible={isLoading} />

      <Group align="center" position="apart">
        <Group noWrap align="center" spacing="sm">
          <Text
            size="md"
            data-testid="dashboard-scatter-chart-widget-name"
            color="gray.7"
            truncate
          >
            {title}
          </Text>

          {isDeviceOffline ? (
            <OfflineDeviceStateTooltip
              lastUpdateTimestamp={lastUpdateTimestamp}
            />
          ) : null}
        </Group>

        <Group>
          {isZoomed && (
            <Button onClick={resetZoom} variant="light" size="xs">
              Reset Zoom
            </Button>
          )}

          {range && id !== 'preview' ? (
            <SegmentedControl
              size="xs"
              data={[
                { value: WidgetDataRangeEnum.Month, label: 'Last 30 Days' },
                { value: WidgetDataRangeEnum.Week, label: 'Last 7 Days' },
                { value: WidgetDataRangeEnum.Day, label: 'Last 24hr' },
              ]}
              value={range}
              onChange={(range: WidgetDataRangeEnum) => setRange?.(range)}
            />
          ) : null}
        </Group>
      </Group>

      {isEmpty(data) ? (
        <Stack align="center" justify="center" w="100%" h="100%">
          <Text size="sm" color="gray.5">
            No data
          </Text>
        </Stack>
      ) : (
        <ResponsiveContainer
          width="100%"
          minHeight={100}
          className={classes.chartWrapper}
        >
          <ZoomableScatterChart
            onZoomChange={setZoomDomain}
            withZoom={withZoom}
          >
            <CartesianGrid
              stroke={theme.colors.gray[2]}
              strokeDasharray="3 3"
            />

            <YAxis
              dataKey="value"
              width={60}
              tickFormatter={(value) => formatter(value) || 'N/A'}
              type="number"
              scale={scaleType}
              style={{
                userSelect: 'none',
              }}
              domain={isZoomed ? zoomDomain.y : ['auto', 'auto']}
              tickLine={{
                strokeWidth: 1,
                stroke: theme.colors.gray[2],
              }}
              tick={{
                fill: theme.colors.gray[4],
                fontSize: theme.fontSizes.xs,
              }}
              axisLine={{
                strokeWidth: 1,
                stroke: theme.colors.gray[2],
              }}
            />

            <XAxis
              dataKey="timestamp"
              tickMargin={5}
              tickSize={5}
              domain={isZoomed ? zoomDomain.x : ['auto', 'auto']}
              type="number"
              scale="time"
              interval="preserveStartEnd"
              tickLine={{
                strokeWidth: 1,
                stroke: theme.colors.gray[2],
              }}
              tick={(props: XAxisLabelProps) => (
                <XAxisLabel {...props} range={range} />
              )}
              axisLine={{
                strokeWidth: 1,
                stroke: theme.colors.gray[2],
              }}
            />

            <Tooltip<number, string>
              isAnimationActive={false}
              content={(props: TooltipProps<number, string>) => (
                <TooltipContent
                  {...props}
                  eventsConfig={eventsConfig}
                  customFormatter={formatter}
                />
              )}
            />

            {Object.values(eventsConfig).map((eventConfig) => {
              if (disabledEventIds.includes(eventConfig.id)) return null;

              const eventData = enrichedData.filter(
                (point) => point?.eventId === eventConfig.id
              );

              return (
                <Scatter
                  isAnimationActive={false}
                  key={eventConfig.id}
                  name={eventConfig.event_display_name}
                  data={filteredChartDataByZoomDomain[eventConfig.id]}
                  fill={theme.fn.themeColor(eventConfig.color)}
                  shape={(props) => {
                    const dataPoint = eventData.find(
                      (point) =>
                        point?.timestamp === props?.payload?.timestamp &&
                        point?.value === props?.payload?.value
                    );

                    if (isNil(dataPoint?.value)) return <g />;

                    return (
                      <CustomScatterDot
                        {...props}
                        dataPoint={dataPoint}
                        eventId={eventConfig.id}
                        hoveredEventId={hoveredEventId}
                        onHover={setHoveredEventId}
                      />
                    );
                  }}
                />
              );
            })}

            <Legend
              align="left"
              wrapperStyle={{ bottom: 0 }}
              content={() => (
                <Group>
                  {Object.values(eventsConfig).map((eventConfig) => (
                    <LegendLabel
                      key={eventConfig.id}
                      label={eventConfig.event_display_name}
                      color={eventConfig.color}
                      disabled={disabledEventIds.includes(eventConfig.id)}
                      onClick={() => onLegendItemClick(eventConfig.id)}
                    />
                  ))}
                </Group>
              )}
            />
          </ZoomableScatterChart>
        </ResponsiveContainer>
      )}
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    borderRadius: theme.radius.lg,
  },
  indicator: {
    width: 14,
    height: 14,
    borderRadius: theme.radius.sm,
  },
  chartWrapper: {
    svg: {
      overflow: 'visible',
    },
  },
}));
