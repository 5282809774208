import classNames from 'classnames';
import React, { useState } from 'react';
import { Button, ButtonGroup } from 'reactstrap';

const Wizard = ({ steps, data }) => {
  const [step, setStep] = useState(0);
  const canStepBack = step !== 0;
  const canStepForward =
    step < steps.length - 1 && steps[step + 1].enabled(data);

  return (
    <div>
      <div className="wizard">
        <ul className="step-anchor">
          {steps.map(({ name, enabled }, index) => (
            <li key={name}>
              <button
                className={classNames('step', {
                  enabled: enabled && enabled(data),
                  active: index === step,
                })}
                onClick={() => enabled && enabled(data) && setStep(index)}
              >
                {name}
              </button>
            </li>
          ))}
        </ul>

        {React.createElement(steps[step].component, {
          name: steps[step].name,
          id: steps[step].id,
          ...steps[step].props,
        })}

        <ButtonGroup>
          <Button
            color="primary"
            onClick={() => setStep(step - 1)}
            disabled={!canStepBack}
          >
            Prev
          </Button>
          <Button
            color="primary"
            onClick={() => setStep(step + 1)}
            disabled={!canStepForward}
          >
            Next
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

export default Wizard;
