import { Button, Image, Stack, Text } from '@mantine/core';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import emptyCartSrc from '../../../assets/img/empty-cart.svg';

interface EmptyCart {
  onAction?: () => void;
}

export const EmptyCart: FC<EmptyCart> = ({ onAction }) => {
  const navigate = useNavigate();

  return (
    <Stack
      py="xl"
      sx={{
        height: '100%',
      }}
      align="center"
      justify="center"
    >
      <Stack sx={{ width: 210 }} align="center">
        <Image src={emptyCartSrc} sx={{ height: 210, width: 210 }} />

        <Text
          size="md"
          sx={(theme) => ({ color: theme.colors.gray[8], fontWeight: 500 })}
        >
          Your Cart is Empty
        </Text>

        <Text
          align="center"
          size="sm"
          sx={(theme) => ({ color: theme.colors.gray[5], fontWeight: 500 })}
        >
          Looks like you haven’t added anything to your cart yet
        </Text>

        <Button
          variant="white"
          onClick={() => {
            navigate('/store');

            if (onAction) {
              onAction();
            }
          }}
        >
          Go To Store
        </Button>
      </Stack>
    </Stack>
  );
};
