import { Loader } from '@mantine/core';
import { get } from 'lodash/fp';
import React, { FC, useContext, useEffect, useMemo } from 'react';
import { Button } from 'reactstrap';

import { AutoFormik } from '@portals/autoformik';
import { ReactComponent as Refresh2 } from '@portals/icons/linear/refresh-2.svg';

import { IntegrationsContext } from './Integrations';

const Refresh = (refresh) => () =>
  refresh && (
    <Button className="mr-4 ml-0" onClick={refresh}>
      <Refresh2 width={15} height={15} />
    </Button>
  );

type EditSectionProps = {
  id: string;
  sections: Record<string, any>;
  name: string;
};

const EditSection: FC<EditSectionProps> = ({ id, sections, name }) => {
  const { integrations, updateIntegration, getIntegrationParam } =
    useContext(IntegrationsContext);
  const schema = sections.find((section) => section.name === name);
  const integrationData = useMemo(
    () => integrations[id] || {},
    [id, integrations]
  );
  const optionsPath = schema.optionsPath && schema.optionsPath(integrationData);
  const options = get(optionsPath, integrationData);
  const FIELDS = schema.fields(options);
  const [key, subKey] = schema.loadParams
    ? schema.loadParams(integrationData)
    : [];
  const refresh =
    optionsPath && Refresh(() => getIntegrationParam(id, key, subKey, false));

  useEffect(() => {
    if (optionsPath && !get(optionsPath, integrationData)) {
      getIntegrationParam(id, key, subKey);
    }
  }, [id, getIntegrationParam, integrationData, key, subKey, optionsPath]);

  if (optionsPath && !options) {
    return <Loader />;
  }

  return (
    <AutoFormik
      fields={FIELDS}
      initialValues={integrationData.params}
      inProgress={false} // For now we choose not to connect the Component only to get the traffic. 22.9.21 Dor (approved by boris)
      handleSubmit={(values) => updateIntegration(id, values)}
      requireChange={false}
      wrapped={refresh}
    />
  );
};

export default (id, sections) => (props) =>
  <EditSection id={id} sections={sections} {...props} />;
