import { Badge, createStyles, Group, Image, Stack, Text } from '@mantine/core';
import React, { FC } from 'react';

import { CATEGORY, CATEGORY_ICON } from './connect.constants';
import { VendorType } from './connect.types';

interface VendorCardProps extends VendorType {
  onClick: () => void;
  isSelected: boolean;
}

export const VendorCard: FC<VendorCardProps> = ({
  name,
  logo,
  category,
  onClick,
  isSelected,
}) => {
  const { classes, cx } = useStyles();

  const Icon = CATEGORY_ICON[category];

  return (
    <Stack
      className={cx(classes.container, { selected: isSelected })}
      spacing="md"
      align="flex-start"
      onClick={onClick}
    >
      <Group sx={{ height: 76 }}>
        <Image height="100%" src={logo} />
      </Group>

      <Text className={classes.title}>{name}</Text>

      <Badge
        color="amber"
        radius="sm"
        className={classes.category}
        px={10}
        py={7}
      >
        <Group align="center" spacing={0}>
          <Icon />
          {CATEGORY[category]}
        </Group>
      </Badge>
    </Stack>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    padding: 32,
    borderRadius: theme.radius.sm,
    boxShadow: `0 0 0 1px ${theme.fn.rgba(theme.colors.gray[2], 1)}`,
    background: theme.white,
    cursor: 'pointer',
    transition: 'box-shadow 0.15s ease-in-out',

    '&.selected': {
      boxShadow: `0 0 0 2px ${theme.fn.rgba(theme.colors.blue_accent[4], 1)}`,
    },
  },
  title: {
    fontSize: theme.fontSizes.lg,
    color: theme.colors.gray[9],
    fontWeight: 600,
  },
  location: {
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[5],
    fontWeight: 500,
  },
  category: {
    fontSize: `${theme.fontSizes.xs} !important`,
    color: theme.colors.gray[7],
    textTransform: 'none',
    fontWeight: 300,
    alignItems: 'center',

    svg: {
      width: 15,
      height: 15,
      marginRight: theme.spacing.xs,
    },
  },
}));
