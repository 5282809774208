import { Stack } from '@mantine/core';
import React from 'react';

import {
  CurrencyCode,
  OrganizationStoreListing,
  PaymentIntervalEnum,
  ProductPricingModel,
} from '@portals/types';

import { ExtraPricesBadge } from './ExtraPricesBadge';
import { StandardPrice } from './StandardPrice';
import { UsageBasedPrice } from './UsageBasedPrice';

interface PricePreviewProps {
  storeListing: OrganizationStoreListing;
  currencyCode: CurrencyCode;
}

export function PricePreview({
  storeListing,
  currencyCode,
}: PricePreviewProps) {
  const currencyPrices = storeListing.prices.find(
    (price) => price.currency === currencyCode
  );

  if (!currencyPrices || !currencyPrices.pricing_options?.length) {
    return null;
  }

  const [firstPrice, ...restPrices] = currencyPrices.pricing_options;

  if (storeListing.product.pricing_model === ProductPricingModel.UsageBased) {
    const usageBasedPrice = currencyPrices.pricing_options.find(
      (option) => option.type === PaymentIntervalEnum.UsageBased
    )?.amount;
    const basedPrice = currencyPrices.pricing_options.find(
      (option) => option.type === PaymentIntervalEnum.Monthly
    )?.amount;

    return (
      <UsageBasedPrice
        usageBasedPrice={usageBasedPrice}
        basePrice={basedPrice}
        currencyCode={currencyCode}
        unitName={storeListing.product.usage_based_unit_name as string}
      />
    );
  }

  return (
    <Stack spacing="xs">
      <StandardPrice
        price={firstPrice.amount}
        period={firstPrice.type}
        currencyCode={currencyCode}
      />

      <ExtraPricesBadge pricingOptions={restPrices} />
    </Stack>
  );
}
