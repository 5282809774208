import React from 'react';

import {
  useOrganizationConfig,
  usePortalConfig,
} from '@portals/api/organizations';
import { Meta, useBrandingConfig } from '@portals/framework';
import { useAuth } from '@portals/redux';
import { TenantType } from '@portals/types';
import { portalFavicon, portalWindowTitle } from '@portals/utils';

export function MobileOrganizationMeta() {
  const isLoggedIn = Boolean(useAuth());
  const organizationConfig = useOrganizationConfig();
  const portalConfig = usePortalConfig({ enabled: !isLoggedIn });

  const adjustedPortalConfig = useBrandingConfig({
    tenantType: TenantType.Organization,
    isLoggedIn,
    portalConfig: {
      ...((isLoggedIn ? organizationConfig : portalConfig.data) || {}),
      name: organizationConfig?.partner_display_name || portalConfig.data?.name,
    },
  });

  if (!isLoggedIn && !portalConfig.isFetched) return null;
  else if (isLoggedIn && !organizationConfig) return null;

  return (
    <Meta
      title={portalWindowTitle(adjustedPortalConfig)}
      meta={[
        {
          name: 'viewport',
          content:
            'width=device-width, height=device-height, maximum-scale=1, initial-scale=1, viewport-fit=cover',
        },
      ]}
      links={[
        {
          href: portalFavicon(adjustedPortalConfig),
          rel: 'icon',
          sizes: '192x192',
        },
      ]}
    />
  );
}
