import { TextInput } from '@mantine/core';
import { isString } from 'lodash/fp';
import React, { FC } from 'react';

import { FieldRendererProps } from '@portals/types';

const TextField: FC<FieldRendererProps> = ({
  error,
  field,
  value,
  handleBlur,
  setFieldValue,
  readOnly,
  disabled,
  required,
}) => {
  const { name, title, placeholder, inputProps } = field;
  const { startAdornment, endAdornment, ...restInputProps } = inputProps || {};

  return (
    <TextInput
      error={error}
      data-testid={'text-input-' + name}
      icon={startAdornment}
      rightSection={endAdornment}
      label={title}
      name={name}
      required={required}
      placeholder={placeholder || (isString(title) ? title : '')}
      value={value}
      disabled={disabled || readOnly}
      onChange={(event) => setFieldValue(field.name, event.target.value)}
      onBlur={handleBlur}
      {...restInputProps}
    />
  );
};

export default TextField;
