import { AnchorProps, AnchorStylesParams } from '@mantine/core';

import { ThemeComponentOverrides } from '../common-types';

export const Anchor: ThemeComponentOverrides<
  AnchorProps,
  never,
  AnchorStylesParams
> = {
  styles: (theme) => ({
    root: {
      color: theme.fn.primaryColor(),
      textDecoration: 'underline',
      fontWeight: 500,

      '&:hover': {
        color: theme.colors.gray[8],
      },
    },
  }),
};
