import { AvatarProps } from '@mantine/core';
import React from 'react';

import {
  NameAbbreviationAvatar,
  NameAbbreviationAvatarProps,
} from '@portals/core';

interface PartnerAvatarProps extends AvatarProps {
  partnerName: string | undefined;
}

export function PartnerAvatar({
  className,
  partnerName,
  ...avatarProps
}: PartnerAvatarProps) {
  return (
    <NameAbbreviationAvatar
      withToolTip={false}
      radius="50%"
      styles={nameAbbreviationAvatarStyles}
      name={partnerName || ''}
      {...avatarProps}
    />
  );
}

const nameAbbreviationAvatarStyles: NameAbbreviationAvatarProps['styles'] = (
  theme
) => ({
  placeholder: {
    fontSize: theme.fontSizes.xl,
  },

  image: {
    objectFit: 'contain',
  },
});
