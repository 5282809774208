import React from 'react';

import { TicketDetailsType } from '@portals/api/organizations';
import { DetailsList, DetailsListProps } from '@portals/framework';
import { prettyTime } from '@portals/utils';

interface TicketDeviceDetailsListProps {
  ticket: TicketDetailsType | undefined;
}

export function TicketDeviceDetailsList({
  ticket,
}: TicketDeviceDetailsListProps) {
  const detailsList: DetailsListProps['items'] = [
    {
      label: 'Device',
      value: ticket?.device?.name,
    },
    {
      label: 'Vendor',
      value: ticket?.partner_display_name,
    },
    {
      label: 'Model',
      value: ticket?.device?.model_name,
    },
    {
      label: 'Last seen',
      value: prettyTime(ticket?.device?.last_seen),
    },
    {
      label: 'Firmware version',
      value: ticket?.device?.firmware_version,
    },
  ];

  return <DetailsList title="Device details" items={detailsList} />;
}
