import { LoadingOverlay } from '@mantine/core';
import React from 'react';

import { ProductPricingModel } from '@portals/types';

import { useShopAndCheckSelectedCurrency } from '../../../hooks/store';
import { useShopCurrency } from '../../../redux/hooks/store';
import { StoreProductsList } from '../../components/store/StoreProductsList';

export function ExternalStorePage() {
  const shopCurrency = useShopCurrency();
  const shop = useShopAndCheckSelectedCurrency();

  if (!shop.isFetched) {
    return <LoadingOverlay visible />;
  }

  const filteredStoreListings = (shop.data?.products || []).filter(
    (storeListing) =>
      storeListing.product.pricing_model !== ProductPricingModel.Personalized
  );

  return (
    <StoreProductsList
      storeListings={filteredStoreListings}
      currency={shopCurrency}
    />
  );
}
