import { createStyles, Text } from '@mantine/core';
import React, { Dispatch, SetStateAction } from 'react';

import { RANGE_PRESETS } from './constants';
import { DateRangeType } from './types';

interface DateRangePresetsProps {
  onChange: Dispatch<SetStateAction<DateRangeType>>;
}

export function DateRangePresets({ onChange }: DateRangePresetsProps) {
  const { classes } = useStyles();
  return (
    <div className={classes.container}>
      {RANGE_PRESETS.map((preset) => (
        <div
          key={preset.id}
          className={classes.presetItem}
          onClick={() => onChange(preset.generateRange())}
          data-testid={`date-range-preset-${preset.id}`}
        >
          <Text size="sm">{preset.label}</Text>
        </div>
      ))}
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    height: '100%',
    paddingInline: theme.spacing.xs,
    paddingBlock: theme.spacing.xl,
    borderRight: `1px solid ${theme.colors.gray[3]}`,
  },
  presetItem: {
    paddingBlock: theme.spacing.xs,
    paddingInline: theme.spacing.xl,
    cursor: 'pointer',

    '&:hover': {
      color: theme.fn.primaryColor(),
      backgroundColor: theme.fn.rgba(theme.colors.blue[0], 0.3),
    },
  },
}));
