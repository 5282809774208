import { createStyles, Menu, Text } from '@mantine/core';
import React, { FC } from 'react';

import { CustomerWithControlledType } from '@portals/api/partners';
import { ReactComponent as Export2 } from '@portals/icons/linear/export-2.svg';
import { ReactComponent as More } from '@portals/icons/linear/more.svg';
import { ReactComponent as UserSquare } from '@portals/icons/linear/user-square.svg';

import { usePortalProtocolAndDomain } from '../hooks/portal-config';
import { useOpenRouteModal } from '../RouteModals';

interface CustomerMenuProps {
  customer: CustomerWithControlledType;
}

export const CustomerMenu: FC<CustomerMenuProps> = ({ customer }) => {
  const { classes } = useStyles();
  const { protocolAndDomain } = usePortalProtocolAndDomain();
  const openRouteModal = useOpenRouteModal();

  return (
    <Menu trigger="click" classNames={classes}>
      <Menu.Target>
        <More className={classes.menuIcon} />
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item icon={<UserSquare width={16} height={16} />}>
          <Text
            onClick={() =>
              openRouteModal({ modalId: 'customer', pathParams: [customer.id] })
            }
            size="sm"
            className={classes.viewLink}
          >
            View Customer
          </Text>
        </Menu.Item>

        {customer.controlled && (
          <Menu.Item icon={<Export2 width={16} height={16} />}>
            <Text<'a'>
              component="a"
              href={`${protocolAndDomain}?org=${customer.id}`}
              target="_blank"
              size="sm"
              className={classes.viewLink}
            >
              Visit Portal
            </Text>
          </Menu.Item>
        )}
      </Menu.Dropdown>
    </Menu>
  );
};

const useStyles = createStyles((theme) => ({
  item: {
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
  },
  itemBody: {
    padding: 0,
  },
  menuIcon: {
    color: theme.colors.gray[4],
    fill: theme.colors.gray[4],

    '&:hover': {
      fill: theme.black,
      color: theme.black,
    },
  },
  viewLink: {
    '&:hover': {
      textDecoration: 'none',
      color: 'inherit',
    },
  },
}));
