import { useMutation, useQueryClient } from '@tanstack/react-query';

import { OrganizationAddressType } from '@portals/types';

import { ADDRESSES_API_URL, addressesQueryKeys } from './addresses.constants';
import { useApiQuery } from '../../hooks/query';
import { QueryOptions } from '../../types/common';
import { fetchApiRequest, useRequestOptions } from '../../utils/common';
import { ordersQueryKeys } from '../orders/orders.constants';

export function useAddresses(
  queryOptions?: QueryOptions<OrganizationAddressType[]>
) {
  return useApiQuery<OrganizationAddressType[]>(
    ADDRESSES_API_URL,
    addressesQueryKeys.all,
    queryOptions
  );
}

export function useCreateAddress() {
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: ADDRESSES_API_URL,
    method: 'POST',
  });

  return useMutation({
    mutationFn: (
      address: OrganizationAddressType
    ): Promise<OrganizationAddressType> =>
      fetchApiRequest(url, {
        ...options,
        body: JSON.stringify(address),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(addressesQueryKeys.all);
    },
    meta: {
      mutationName: 'useCreateAddress',
      baseUrl: ADDRESSES_API_URL,
      method: 'POST',
    },
  });
}

export function useUpdateAddress() {
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: ADDRESSES_API_URL,
    method: 'PUT',
  });

  return useMutation({
    mutationFn: (
      address: OrganizationAddressType
    ): Promise<OrganizationAddressType> =>
      fetchApiRequest(`${url}/${address.id}`, {
        ...options,
        body: JSON.stringify(address),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(addressesQueryKeys.all);
      queryClient.invalidateQueries(ordersQueryKeys.previews());
    },
    meta: {
      mutationName: 'useUpdateAddress',
      baseUrl: `${ADDRESSES_API_URL}/:id`,
      method: 'PUT',
    },
  });
}

export function useRemoveAddress() {
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: ADDRESSES_API_URL,
    method: 'DELETE',
  });

  return useMutation({
    mutationFn: (addressId: string): Promise<OrganizationAddressType> =>
      fetchApiRequest(`${url}/${addressId}`, {
        ...options,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(addressesQueryKeys.all);
    },
    meta: {
      mutationName: 'useRemoveAddress',
      baseUrl: `${ADDRESSES_API_URL}/:id`,
      method: 'DELETE',
    },
  });
}
