import React from 'react';
import { useMatch } from 'react-router-dom';

import { OverviewConfigPanel } from './OverviewConfigPanel';
import { OverviewHeaderPanel } from './OverviewHeaderPanel';

export function PanelWrapper() {
  const isConfig = useMatch('/overview/:spaceId/config');

  return isConfig ? <OverviewConfigPanel /> : <OverviewHeaderPanel />;
}
