import { ActionIcon } from '@mantine/core';
import classnames from 'classnames';
import { motion } from 'framer-motion';
import React, { FC, useRef, useState, FocusEvent } from 'react';
import styled from 'styled-components';

import { ReactComponent as CloseX } from '@portals/icons/linear/close-x.svg';
import { ReactComponent as SearchNormal2 } from '@portals/icons/linear/search-normal 2.svg';

import { useTheme } from './ThemeProvider';

export interface SearchInputProps {
  className?: string;
  value: string;
  placeholder?: string;
  onChange: (value: string) => void;
  withRemove?: boolean;
  onInputFocusChanged?: (value: boolean) => void;
  autoFocus?: boolean;
}

export const SearchInput: FC<SearchInputProps> = ({
  className = '',
  value,
  onChange,
  placeholder = 'Search...',
  withRemove = true,
  onInputFocusChanged,
  autoFocus = false,
  ...rest
}) => {
  const { color } = useTheme();
  const [isFocused, setIsFocused] = useState(false);
  const inputElement = useRef(null);

  const onFocusChanged = (event: FocusEvent<HTMLInputElement>) => {
    if (onInputFocusChanged) {
      onInputFocusChanged(isFocused);
    }

    if (event.type === 'blur') {
      setIsFocused(false);
    } else {
      setIsFocused(true);
    }
  };

  return (
    <Container className={classnames('search-input-container', className)}>
      <IconWrapper
        transition={{
          opacity: { duration: 0.1, delay: isFocused ? 0 : 0.1 },
          right: { duration: 0.15 },
        }}
        initial={{
          right: !value ? 10 : -10,
          opacity: !value ? 1 : 0,
        }}
        animate={{
          right: !!value || isFocused ? -10 : 10,
          opacity: !!value || isFocused ? 0 : 1,
        }}
      >
        <SearchNormal2
          id="search-input-icon"
          color={color.gray700}
          width={15}
          height={15}
          onClick={() => inputElement.current.focus()}
          className="search-icon"
        />
      </IconWrapper>

      {withRemove ? (
        <IconWrapper
          transition={{
            opacity: { duration: 0.1 },
            right: { duration: 0.15 },
          }}
          initial={{
            right: value ? 10 : -10,
            opacity: value ? 1 : 0,
          }}
          animate={{
            right: value ? 10 : -10,
            opacity: value ? 1 : 0,
          }}
        >
          <ActionIcon
            size={15}
            color="gray"
            variant="transparent"
            onClick={() => onChange('')}
            id="search-remove-icon"
          >
            <CloseX />
          </ActionIcon>
        </IconWrapper>
      ) : null}

      <Input
        {...rest}
        placeholder={placeholder}
        onChange={(event) => onChange(event?.target?.value)}
        value={value}
        onFocus={onFocusChanged}
        onBlur={onFocusChanged}
        initial={{
          borderColor: color.gray400,
          paddingRight: !value ? 35 : 10,
        }}
        animate={{
          paddingRight: !value && !isFocused ? 35 : 10,
          transition: { delay: !value && !isFocused ? 0 : 0.15 },
        }}
        whileFocus={{
          borderColor: color.cyan,
        }}
        $isFocused={isFocused}
        ref={inputElement}
        data-autofocus={autoFocus}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 35px;
  position: relative;
  align-items: center;
  flex-shrink: 0;
`;

const IconWrapper = styled(motion.div)`
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;

  .search-icon {
    cursor: text;
  }
`;

const Input = styled(motion.input)<{ $isFocused: boolean }>`
  width: 100%;
  height: 100%;
  appearance: none;
  outline: none;
  border: none;
  border-radius: 4px;
  padding-left: 10px;
  font-size: 16px;

  &::placeholder {
    color: ${({ $isFocused }) => ($isFocused ? '#b0bec5' : '#263238')};
    font-weight: ${({ $isFocused }) => ($isFocused ? '500' : '600')};
  }
`;

export default SearchInput;
