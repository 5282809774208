import { Group, MultiSelect, MultiSelectProps, Text } from '@mantine/core';
import React, { forwardRef } from 'react';

import { NameAbbreviationAvatar } from '@portals/core';

interface UsersMultiSelectProps
  extends Omit<MultiSelectProps, 'styles' | 'itemComponent' | 'data'> {
  data: Array<{ label: string; value: string; email: string }>;
}

export function UsersMultiSelect({
  data,
  ...multiSelectProps
}: UsersMultiSelectProps) {
  return (
    <MultiSelect
      searchable
      withinPortal
      label="Select users"
      placeholder="Select users"
      data-testid="users-multi-select"
      data={data}
      itemComponent={SelectItem}
      styles={multiSelectStyles}
      {...multiSelectProps}
    />
  );
}

const multiSelectStyles: MultiSelectProps['styles'] = {
  item: {
    paddingBlock: 10,
  },
};

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string;
  value: string;
  email: string;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, email, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap spacing="xs">
        <NameAbbreviationAvatar name={label} radius="xl" size="md" />

        <div>
          <Text color="blue_gray.9">{label}</Text>
          <Text size="xs" color="blue_gray.5">
            {email}
          </Text>
        </div>
      </Group>
    </div>
  )
);
