import {
  createStyles,
  Group,
  Modal,
  ModalProps,
  Stack,
  Text,
} from '@mantine/core';
import React, { FC } from 'react';

import { ModalFooter } from './ModalFooter';

interface ModalSideMediaLayoutProps extends ModalProps {
  media: React.ReactNode;
  contentText: React.ReactNode;
  actions: React.ReactNode;
}

const useStyles = createStyles(() => ({
  group: {
    gap: 28,
    flexWrap: 'nowrap',
  },
  media: {
    flex: '1 0 96px',

    '> *': {
      width: '100%',
      height: '100%',
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 400,
  },
  actions: {
    justifyContent: 'flex-end',
  },
}));

export const ModalSideMediaLayout: FC<ModalSideMediaLayoutProps> = ({
  children,
  title,
  media,
  contentText,
  actions,
  ...modalProps
}) => {
  const { classes } = useStyles();
  return (
    <Modal {...modalProps}>
      <Stack spacing="md">
        <Group className={classes.group}>
          <div className={classes.media}>{media}</div>
          <div>
            <Text className={classes.title}>{title}</Text>
            {contentText}
          </div>
        </Group>
        <ModalFooter position="right">{actions}</ModalFooter>
      </Stack>
    </Modal>
  );
};
