import { Box, Button, createStyles, Group, Stack, Text } from '@mantine/core';
import { isEmpty } from 'lodash/fp';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { usePortalConfig } from '@portals/api/organizations';

import { OrderItemsPreview } from './OrderItemsPreview';
import { useCartProductsList } from '../../../../redux/hooks/store';
import { EmptyCart } from '../../../components/store/EmptyCart';

interface ExternalStoreShoppingCartProps {
  onClose: () => void;
}

export function ExternalStoreShoppingCart({
  onClose,
}: ExternalStoreShoppingCartProps) {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const portalConfig = usePortalConfig();

  const cartProductsList = useCartProductsList();

  if (isEmpty(cartProductsList)) {
    return <EmptyCart onAction={onClose} />;
  }

  const onCheckout = () => {
    localStorage.setItem('afterAuthPath', '/checkout');

    navigate('/auth/sign-up');
    onClose();
  };

  return (
    <Stack className={classes.container}>
      <Box className={classes.content} px="xl">
        <OrderItemsPreview />
      </Box>

      <Stack className={classes.footer}>
        {portalConfig.data?.tax_enabled && (
          <Group py="lg" className={classes.taxes}>
            <Text size="sm" color="gray.5">
              Tax will be calculated during checkout
            </Text>
          </Group>
        )}

        <Button
          fullWidth
          onClick={onCheckout}
          data-testid="shopping-cart-checkout-button"
        >
          Checkout
        </Button>
      </Stack>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    overflow: 'hidden',
    minHeight: 300,
    maxHeight: '80dvh',
  },
  content: {
    overflow: 'auto',
  },
  taxes: {
    borderTop: `1px solid ${theme.colors.gray[2]}`,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },
  footer: {
    position: 'sticky',
    bottom: 0,
    marginTop: 'auto',
    paddingInline: theme.spacing.xl,
    paddingBlock: theme.spacing.md,
    backgroundColor: theme.white,
  },
}));
