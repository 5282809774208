import {
  RadioGroupProps,
  RadioGroupStylesNames,
  RadioProps,
  RadioStylesNames,
  RadioStylesParams,
} from '@mantine/core';

import { ThemeComponentOverrides } from '../common-types';

export const RadioGroup: ThemeComponentOverrides<
  RadioGroupProps,
  RadioGroupStylesNames,
  any
> = {
  styles: (theme) => ({
    label: {
      marginBottom: theme.spacing.xs,
      color: theme.colors.gray[7],
      fontWeight: 400,
      fontSize: theme.fontSizes.sm,
    },
  }),
};

export const Radio: ThemeComponentOverrides<
  RadioProps,
  RadioStylesNames,
  RadioStylesParams
> = {
  styles: (theme) => ({
    label: {
      marginBottom: theme.spacing.xs,
      color: theme.colors.gray[7],
      fontWeight: 400,
      fontSize: theme.fontSizes.sm,
    },
  }),
};
