import { createStyles, Group, Stack, Text } from '@mantine/core';
import { groupBy, keys, pick } from 'lodash/fp';
import React, { useState } from 'react';

import { SearchInput } from '@portals/core';
import { LicenseType } from '@portals/types';

interface LicenseCategoriesProps {
  licenses: LicenseType[];
  selectedCategory: LicenseType['product_category'];
  onSelectCategory: (categoryName: LicenseType['product_category']) => void;
}

export function LicenseCategories({
  licenses,
  onSelectCategory,
  selectedCategory,
}: LicenseCategoriesProps) {
  const { classes, cx } = useStyles();
  const [searchTerm, setSearchTerm] = useState('');

  const filteredCategories = getFilteredCategoriesBySearchTerm(
    licenses,
    searchTerm
  );

  return (
    <Stack spacing="xl" className={classes.container}>
      <SearchInput
        size="md"
        className={classes.searchInput}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onClear={() => setSearchTerm('')}
      />

      <Stack spacing={4}>
        <Group
          position="apart"
          onClick={() => onSelectCategory(null)}
          className={cx(classes.categoryItem, {
            [classes.activeCategoryItem]: selectedCategory === null,
          })}
        >
          <Text inherit transform="uppercase">
            All Licenses
          </Text>
          <Text inherit>{licenses.length}</Text>
        </Group>

        {keys(filteredCategories).map((categoryName) => {
          if (!categoryName) return null;

          return (
            <Group
              key={categoryName}
              position="apart"
              onClick={() => onSelectCategory(categoryName)}
              className={cx(classes.categoryItem, {
                [classes.activeCategoryItem]: selectedCategory === categoryName,
              })}
            >
              <Text inherit transform="uppercase">
                {categoryName}
              </Text>
              <Text inherit>{filteredCategories[categoryName].length}</Text>
            </Group>
          );
        })}
      </Stack>
    </Stack>
  );
}

function getFilteredCategoriesBySearchTerm(
  licenses: LicenseType[],
  searchTerm: string
) {
  const byCategoryName = groupBy((license) => {
    return license.product_category || '';
  }, licenses);

  if (searchTerm) {
    const relevantCategoryNames = keys(byCategoryName).filter((categoryName) =>
      categoryName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return pick(relevantCategoryNames, byCategoryName);
  } else {
    return byCategoryName;
  }
}

const useStyles = createStyles((theme) => ({
  container: {
    paddingInline: theme.spacing.md,
    paddingBlock: theme.spacing.xl,
    borderRight: `1px solid ${theme.colors.gray[2]}`,
    userSelect: 'none',
  },
  searchInput: {
    width: 'auto',
  },
  categoryItem: {
    paddingBlock: theme.spacing.sm,
    paddingInline: theme.spacing.md,
    borderRadius: theme.radius.xl,
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[7],
    cursor: 'pointer',
    textTransform: 'capitalize',
  },
  activeCategoryItem: {
    color: theme.fn.primaryColor(),
    backgroundColor: theme.colors.primary[1],
  },
}));
