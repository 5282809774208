import { Box, createStyles, Drawer, Group, Stack, Title } from '@mantine/core';
import { map } from 'lodash/fp';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { usePortalCapabilities } from '@portals/api/organizations';

import { OVERVIEW_SETTINGS_TABS } from './settings-drawer.constants';
import { useCurrentSettingsTab } from './settings-drawer.hooks';

export function OverviewSettingsDrawer() {
  const styles = useStyles();
  const params = useParams<{ spaceId: string; settingsTab: string }>();
  const navigate = useNavigate();
  const portalCapabilities = usePortalCapabilities();

  const currentTab = useCurrentSettingsTab();

  const onClose = () =>
    navigate(`/overview/${params.spaceId}`, { replace: true });

  const filteredTabs = map((tab) => {
    if (tab.visible && !tab.visible({ portalCapabilities })) return null;

    return (
      <Group
        className={styles.cx(styles.classes.tabItem, {
          active: params.settingsTab === tab.id,
        })}
        key={tab.id}
        onClick={() =>
          navigate(`/overview/${params.spaceId}/settings/${tab.id}`, {
            replace: true,
          })
        }
      >
        {tab.sidebarLabel}
      </Group>
    );
  }, OVERVIEW_SETTINGS_TABS);

  return (
    <Drawer
      position="right"
      opened={!!currentTab}
      withCloseButton={false}
      onClose={onClose}
      size={1154}
      padding={0}
      classNames={{
        body: styles.classes.drawerBody,
      }}
    >
      {!currentTab ? null : (
        <Box
          className={styles.cx(
            styles.classes.container,
            'settings-drawer-container'
          )}
        >
          <Stack
            className={styles.cx(
              styles.classes.sidebar,
              'settings-drawer-sidebar'
            )}
            spacing={0}
          >
            <Title order={3} sx={{ fontWeight: 500 }} mb="xl">
              Settings
            </Title>

            {filteredTabs}
          </Stack>

          <Stack
            className={styles.cx(
              styles.classes.content,
              'settings-drawer-content'
            )}
          >
            {currentTab.component}
          </Stack>
        </Box>
      )}
    </Drawer>
  );
}

const useStyles = createStyles((theme) => ({
  drawerBody: {
    height: '100%',
  },
  container: {
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '265px 1fr',
    gap: 0,
  },
  sidebar: {
    height: '100%',
    width: '100%',
    background: theme.white,
    padding: 30,
    borderRight: `1px solid ${theme.colors.gray[2]}`,
  },
  content: {
    height: '100%',
    width: '100%',
    background: theme.colors.gray[0],
    overflow: 'hidden',
  },
  tabItem: {
    height: 41,
    borderRadius: theme.radius.lg,
    padding: `0 ${theme.spacing.md}`,
    marginBottom: theme.spacing.xs,
    cursor: 'pointer',
    color: theme.colors.blue_gray[7],
    fontWeight: 300,

    '&:hover': {
      background: theme.colors.gray[0],
    },

    '&.active': {
      background: theme.colors.blue_accent[0],
      color: theme.other.primaryColor,
    },
  },
}));
