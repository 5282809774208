import { useDescope } from '@descope/react-sdk';
import {
  Alert,
  Anchor,
  Box,
  Button,
  Center,
  Divider,
  Input,
  LoadingOverlay,
  PasswordInput,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { captureException } from '@sentry/react';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { object, string } from 'yup';

import { useSignIn } from '@portals/api/auth';
import { GoogleIcon } from '@portals/assets';
import { TenantType } from '@portals/types';

import { Page404 } from './Page404';
import {
  PasswordInputWithRequirements,
  yupPasswordConfirmValidator,
  yupPasswordValidator,
} from './PasswordInputWithRequirements';
import { TermsOfUse } from '../../components/TermsOfUse';
import { useAppConfig } from '../../context';

const schema = object({
  password: yupPasswordValidator,
  confirmPassword: yupPasswordConfirmValidator('password'),
  name: string().required('Name is required'),
});

export function Welcome() {
  const displayName = new URLSearchParams(window.location.search).get('name');
  const token = new URLSearchParams(window.location.search).get('t');

  const [isLoading, setLoading] = useState(false);
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [googleError, setGoogleError] = useState<string | null>(null);

  const signIn = useSignIn();
  const { tenantType } = useAppConfig();
  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      name: '',
      password: '',
      confirmPassword: '',
    },

    validate: yupResolver(schema),
  });

  const descopeSdk = useDescope();

  const onSubmit = async (values: typeof form.values) => {
    setLoading(true);

    if (!token) {
      setLoading(false);
      return;
    }

    try {
      const verifiedToken = await descopeSdk.magicLink.verify(token);

      if (!verifiedToken.ok || !verifiedToken.data?.user?.email) {
        if (verifiedToken.error?.errorDescription === 'Token expired') {
          setIsTokenExpired(true);
        }

        setLoading(false);

        form.setFieldError('name', verifiedToken.error?.errorDescription);
        throw new Error(verifiedToken.error?.errorDescription);
      }

      const updatePassword = await descopeSdk.password.update(
        verifiedToken.data?.user?.email,
        values.password,
        verifiedToken.data?.refreshJwt
      );

      if (!updatePassword.ok) {
        form.setFieldError('name', updatePassword.error?.errorDescription);

        setLoading(false);

        throw new Error(updatePassword.error?.errorDescription);
      }

      const descopeSignIn = await descopeSdk.password.signIn(
        verifiedToken.data.user.email,
        values.password
      );

      if (!descopeSignIn.ok || !descopeSignIn.data?.sessionJwt) {
        form.setFieldError('name', descopeSignIn.error?.errorDescription);

        setLoading(false);

        throw new Error(descopeSignIn.error?.errorDescription);
      }

      await signIn.mutateAsync({
        token: descopeSignIn.data.sessionJwt,
        tenant_type: tenantType as TenantType,
      });

      navigate('/');
    } catch (e) {
      setLoading(false);

      captureException(`descope error - ${e}`);

      console.error(e);
    }
  };

  const onSignUpWithSocialProvider = async (
    socialProvider: keyof Awaited<
      ReturnType<typeof useDescope>['oauth']['start']
    >
  ) => {
    try {
      const descopeSdkResponse = await descopeSdk.oauth.start[socialProvider](
        `${window.location.href}`
      );

      if (!descopeSdkResponse.data || descopeSdkResponse.error) {
        captureException(
          `descope error - ${descopeSdkResponse.error?.errorDescription}`
        );

        setGoogleError(
          descopeSdkResponse.error?.errorDescription ??
            'Failed to start OAuth flow'
        );

        throw new Error('Failed to start OAuth flow');
      }

      window.location.replace(descopeSdkResponse.data.url);
    } catch (error) {
      console.error(error);
    }
  };

  useEffectOnce(() => {
    const finishSocialAuth = async () => {
      const searchParams = new URLSearchParams(window.location.search);
      const code = searchParams.get('code');

      if (!code || !token) {
        return;
      }

      try {
        const descopeSdkResponse = await descopeSdk.oauth.exchange(code);

        if (
          descopeSdkResponse.error ||
          !descopeSdkResponse.data ||
          !descopeSdkResponse.data.user ||
          !descopeSdkResponse.data.user.name ||
          !descopeSdkResponse.data.user.email
        ) {
          captureException(
            `descope error - ${descopeSdkResponse.error?.errorDescription}`
          );

          setGoogleError(
            descopeSdkResponse.error?.errorDescription ??
              'Failed to exchange OAuth code'
          );

          throw new Error('Failed to exchange OAuth code');
        }

        const verifiedToken = await descopeSdk.magicLink.verify(token);

        if (!verifiedToken.ok || !verifiedToken.data?.user?.email) {
          if (verifiedToken.error?.errorDescription === 'Token expired') {
            setIsTokenExpired(true);
          }

          setLoading(false);

          setGoogleError(
            verifiedToken.error?.errorDescription ?? 'Failed to verify token'
          );

          throw new Error(verifiedToken.error?.errorDescription);
        }

        if (
          verifiedToken.data.user.email !== descopeSdkResponse.data.user.email
        ) {
          setGoogleError('Emails do not match');

          throw new Error('Emails do not match');
        }

        await signIn.mutateAsync({
          token: descopeSdkResponse.data.sessionJwt,
          tenant_type: tenantType as TenantType,
        });

        navigate('/');
      } catch (error) {
        captureException(`descope error - ${error}`);

        console.error(error);
      }
    };

    finishSocialAuth();
  });

  if (displayName === 'undefined') {
    return <Page404 />;
  }

  return (
    <Stack maw={450}>
      <LoadingOverlay visible={isLoading} />

      <Center>
        <Title order={1}>Welcome to {displayName}</Title>
      </Center>

      <form onSubmit={form.onSubmit(onSubmit)}>
        <Stack spacing="xl">
          <Stack spacing="lg">
            <TextInput
              label="Name"
              data-testid="full-name-input"
              placeholder="Full name"
              required
              {...form.getInputProps('name')}
            />

            <PasswordInputWithRequirements
              label="New password"
              placeholder="Password"
              data-testid="new-password-input"
              {...form.getInputProps('password')}
            />

            <PasswordInput
              label="Confirm password"
              data-testid="confirm-password-input"
              placeholder="Confirm password"
              required
              {...form.getInputProps('confirmPassword')}
            />

            <Box>
              <Button
                fullWidth
                type="submit"
                data-testid="join-with-email-button"
              >
                Join
              </Button>

              <Divider label="OR" labelPosition="center" my="xs" />

              <Button
                data-testid="join-with-google"
                leftIcon={<GoogleIcon />}
                variant="default"
                fullWidth
                h={40}
                onClick={() => onSignUpWithSocialProvider('google')}
              >
                Continue with Google
              </Button>

              {googleError && <Input.Error mt="xs">{googleError}</Input.Error>}
            </Box>

            <TermsOfUse />
          </Stack>

          {isTokenExpired && (
            <Alert color="red.0" radius="lg">
              <Stack>
                <Box>
                  <Text align="center" fw={700}>
                    This invitation code has already been used
                  </Text>

                  <Text align="center">
                    You might have already signed up using this invitation.
                  </Text>
                </Box>

                <Anchor
                  c="gray.9"
                  align="center"
                  component={Link}
                  to="/auth/sign-in"
                >
                  Sign in
                </Anchor>
              </Stack>
            </Alert>
          )}
        </Stack>
      </form>
    </Stack>
  );
}
