import { Select } from '@mantine/core';
import { size, uniqBy } from 'lodash/fp';
import React, { useEffect, useMemo, useState } from 'react';

import { useClaimableDevicesModels } from '../../add-device-modal.hooks';

interface VendorFieldProps {
  vendor: string;
  setVendor: React.Dispatch<string>;
}

export function VendorField({ vendor, setVendor }: VendorFieldProps) {
  const claimableDevices = useClaimableDevicesModels();
  const [showVendorField, setShowVendorField] = useState(false);

  const options = useMemo(
    () => composePartnerOptions(claimableDevices),
    [claimableDevices]
  );

  useEffect(() => {
    setShowVendorField(size(options) > 1);
  }, [claimableDevices, options]);

  if (!showVendorField) {
    return null;
  }

  return (
    <Select
      required
      searchable
      withinPortal
      data={options}
      label="Vendor"
      data-testid="select-vendor-dropdown"
      placeholder="Select vendor..."
      name="partner"
      value={vendor}
      onChange={setVendor}
      withAsterisk={false}
    />
  );
}

function composePartnerOptions(
  deviceModels: ReturnType<typeof useClaimableDevicesModels>
) {
  const uniqueByPartnerId = uniqBy('partner.id', deviceModels);

  const sortedByPartnerName = uniqueByPartnerId.sort((a, b) =>
    a?.partner?.display_name?.toLowerCase() >
    b?.partner?.display_name?.toLowerCase()
      ? 1
      : -1
  );

  return sortedByPartnerName.map((deviceModel) => ({
    value: deviceModel.partner.id,
    label: deviceModel.partner.display_name,
  }));
}
