import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { FC, ReactNode, useMemo } from 'react';
import { Spinner } from 'reactstrap';
import styled from 'styled-components';

import { getStyledThemeColor } from '@portals/utils';

import { FADE_IN_OUT } from '../animations';
import { EmptyState } from './common';

type FinanceChartCardProps = {
  children: ReactNode | Array<ReactNode>;
  title: ReactNode;
  emptyTitle: string;
  isEmpty?: boolean;
  isPending?: boolean;
  className?: string;
};

export const ChartCard: FC<FinanceChartCardProps> = ({
  children,
  title,
  emptyTitle,
  isEmpty,
  isPending,
  className = '',
}) => {
  const content = useMemo(() => {
    if (isPending)
      return (
        <LoadingStateWrapper>
          <Spinner color="primary" />
        </LoadingStateWrapper>
      );

    if (isEmpty) return <EmptyState label={emptyTitle} />;

    return children;
  }, [isPending, isEmpty, children, emptyTitle]);

  return (
    <Container className={classNames(className, 'chart-card-container')}>
      <Label>{title}</Label>

      <ChartWrapper className="chart-wrapper">{content}</ChartWrapper>
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 400px;
  max-width: 100%;
  background-color: ${getStyledThemeColor('white')};
  padding: 15px 30px 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04), 0 0 2px rgba(0, 0, 0, 0.06),
    0 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
`;

const LoadingStateWrapper = styled(motion.div).attrs(() => FADE_IN_OUT)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Label = styled.div`
  color: ${getStyledThemeColor('gray600')};
  margin-bottom: 25px;
  font-size: 18px;
  font-weight: bold;
`;

const ChartWrapper = styled.div`
  background-color: ${getStyledThemeColor('white')};
  border-radius: 8px;
  flex: 1;
  position: relative;

  g.recharts-line,
  g.recharts-pie-sector {
    path {
      filter: drop-shadow(0px 2px 2px ${getStyledThemeColor('gray400')});
    }
  }

  g.recharts-line-dots {
    circle {
      filter: drop-shadow(0px 2px 2px ${getStyledThemeColor('gray400')});
    }
  }

  .custom-tooltip {
    padding: 15px;
    background-color: ${getStyledThemeColor('gray100')};
    border-radius: 8px;
    box-shadow: 1px 2px 10px 3px rgb(0 0 0 / 10%);
    width: fit-content;

    .custom-tooltip-label {
      font-size: 17px;
      font-weight: bold;
      color: ${getStyledThemeColor('gray700')};
      margin-bottom: 5px;
    }

    .custom-tooltip-content {
      display: flex;
      flex-direction: column;

      .custom-tooltip-item {
        display: flex;
        align-items: center;
        font-size: 15px;
        margin-bottom: 3px;

        .custom-tooltip-item-label {
          text-transform: capitalize;
          margin-right: 5px;
          font-weight: bold;
        }
      }
    }
  }
`;
