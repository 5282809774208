import { useMantineTheme } from '@mantine/core';
import classNames from 'classnames';
import { toString } from 'lodash/fp';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Button, TooltipProps, UncontrolledTooltip } from 'reactstrap';
import styled from 'styled-components';

import { ReactComponent as ArrowLeft } from '@portals/icons/linear/arrow-left.svg';
import { EmptyState } from '@portals/table';
import { AccessLevelEnum } from '@portals/types';
import { suppressPropagation } from '@portals/utils';

import { EntityAccessProvider } from './context';
import { useHasEntityAccess } from './hooks';
import { Entity } from './types';

export interface EntityAccessProps {
  id: string | number;
  minLevel: AccessLevelEnum;
  entity: Entity;
  children: ReactNode;
  reasonLabel?: string;
  className?: string;
  shouldRenderDisabled?: boolean;
  tooltipProps?: Partial<TooltipProps>;
}

export function EntityAccess({
  id,
  entity,
  children,
  minLevel = AccessLevelEnum.View,
  shouldRenderDisabled = true,
  reasonLabel = 'Access rights not sufficient',
  tooltipProps,
  className = '',
}: EntityAccessProps) {
  const hasAccess = useHasEntityAccess(entity, minLevel);

  if (hasAccess) return <>{children}</>;
  else if (!shouldRenderDisabled) return null;

  const entityId = toString(entity?.id);

  return (
    <EntityAccessProvider isDisabled={!hasAccess}>
      <Wrapper
        id={`access-wrapper-${id}-${entityId}`}
        className={classNames('access-denied-wrapper', className)}
      >
        <Container onClick={suppressPropagation()}>{children}</Container>

        <UncontrolledTooltip
          placement="top"
          className="access-denied-tooltip"
          target={`access-wrapper-${id}-${entityId}`}
          {...(tooltipProps || {})}
        >
          {reasonLabel}
        </UncontrolledTooltip>
      </Wrapper>
    </EntityAccessProvider>
  );
}

interface EntityAccessRouteProps {
  minLevel: AccessLevelEnum;
  entity: Entity;
  children: ReactNode;
}

export function EntityAccessRoute({
  entity,
  minLevel,
  children,
}: EntityAccessRouteProps) {
  const theme = useMantineTheme();

  const hasAccess = useHasEntityAccess(entity, minLevel);

  if (hasAccess) return <>{children}</>;

  return (
    <div className="h-100 w-100 d-flex align-items-center justify-content-center flex-column">
      <EmptyState
        label="Access level is not sufficient"
        src="empty-state-access"
      >
        <Link to="/" className="mt-5">
          <Button
            color="dark"
            size="lg"
            className="btn-branding px-4 d-flex align-items-center"
          >
            <ArrowLeft style={{ marginRight: theme.spacing.xs }} />
            Return to main page
          </Button>
        </Link>
      </EmptyState>
    </div>
  );
}

const Wrapper = styled.div`
  opacity: 0.5;
  cursor: not-allowed;
  height: inherit;
`;

const Container = styled.div`
  pointer-events: none;
  height: inherit;
`;
