import { Button, Group, Modal, Radio, Stack } from '@mantine/core';
import { keys, map, sortBy } from 'lodash/fp';
import React, { PropsWithChildren, useState } from 'react';
import { FilterProps, UseFiltersColumnProps } from 'react-table';

interface SingleSelectFilterProps<TData extends object> {
  closeMe: () => void;
  data: {
    column: FilterProps<TData>['column'] & UseFiltersColumnProps<TData>;
    options: Record<string, string> & {
      all: string;
    };
    placeholder?: string;
  };
}

function SingleSelectFilter<TData extends object>({
  closeMe,
  data,
}: PropsWithChildren<SingleSelectFilterProps<TData>>) {
  const { column, options } = data;

  const [localFilterState, setLocalFilterState] = useState(
    column.filterValue || 'all'
  );

  const onClear = () => {
    column.setFilter(null);
    closeMe();
  };

  const onSubmit = () => {
    let value = localFilterState;

    if (localFilterState === 'all') value = null;

    column.setFilter(value);
    closeMe();
  };

  const optionsKeys = sortBy((key) => key !== 'all', keys(options));

  return (
    <Modal
      opened={true}
      onClose={closeMe}
      size="lg"
      padding={32}
      title={`Filter by '${data.column.Header}'`}
    >
      <Stack>
        <Radio.Group
          value={localFilterState}
          onChange={setLocalFilterState}
          data-testid={`boolean-filter-${column.id}`}
        >
          <Stack>
            {map(
              (optionKey) => (
                <Radio value={optionKey} label={options[optionKey]} />
              ),
              optionsKeys
            )}
          </Stack>
        </Radio.Group>

        <Group position="right" mt="md">
          <Button
            variant="subtle"
            color="blue_gray"
            size="sm"
            onClick={closeMe}
          >
            Cancel
          </Button>

          <Button
            variant="outline"
            color="blue_gray"
            size="sm"
            onClick={onClear}
          >
            Clear Filters
          </Button>

          <Button size="sm" onClick={onSubmit}>
            Apply Filters
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
}

export default SingleSelectFilter;
