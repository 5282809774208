import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { RequireExactlyOne } from 'type-fest';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';

import { spacesQueryKeys } from './spaces.constants';
import { ServerError } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils';

type AccessMutationParams = {
  spaceId: number;
  level: string;
} & RequireExactlyOne<{
  email: string;
  user_id: string;
  group_id: string;
}>;

export function useAddSpaceAccess() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { url: baseUrl, options } = useRequestOptions({
    url: 'ui/organization/spaces',
    method: 'PUT',
  });

  return useMutation<void, ServerError, AccessMutationParams>({
    mutationFn: ({ spaceId, email, user_id, group_id, level }) => {
      return fetchApiRequest(`${baseUrl}/${spaceId}/update_access`, {
        ...options,
        body: JSON.stringify({ email, user_id, group_id, level }),
      });
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Access added successfully'));

      queryClient.invalidateQueries(spacesQueryKeys.base);
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useAddSpaceAccess',
      baseUrl: `ui/organization/spaces/:id/update_access`,
      method: 'PUT',
    },
  });
}

export function useRemoveSpaceAccess() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { url: baseUrl, options } = useRequestOptions({
    url: 'ui/organization/spaces',
    method: 'DELETE',
  });

  return useMutation<void, ServerError, Omit<AccessMutationParams, 'level'>>({
    mutationFn: ({ spaceId, user_id, group_id }) => {
      return fetchApiRequest(`${baseUrl}/${spaceId}/remove_access`, {
        ...options,
        body: JSON.stringify({ user_id, group_id }),
      });
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Access removed successfully'));

      queryClient.invalidateQueries(spacesQueryKeys.base);
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useRemoveSpaceAccess',
      baseUrl: `ui/organization/spaces/:id/remove_access`,
      method: 'DELETE',
    },
  });
}
