import { Carousel, CarouselProps, Embla } from '@mantine/carousel';
import {
  Box,
  createStyles,
  Group,
  LoadingOverlay,
  Stack,
  Text,
} from '@mantine/core';
import React, { useState } from 'react';

import { DeviceType, useDeviceModelFiles } from '@portals/api/organizations';
import { FileTypeIcon } from '@portals/core';
import { NoDataState, TableDetailsPanel } from '@portals/table';
import { OrganizationFileResponseType } from '@portals/types';
import { formatDateTime } from '@portals/utils';

import { FileDetailsPanel } from '../../../../components/Files/FileDetailsPanel';

interface ManufacturerFileInfosProps {
  device: DeviceType;
  maxWidth: number | undefined;
}

export function ManufacturerFileInfos({
  device,
  maxWidth,
}: ManufacturerFileInfosProps) {
  const { classes, cx } = useStyles();

  const modelId = device.partner.type_id;
  const files = useDeviceModelFiles(modelId);

  const [embla, setEmbla] = useState<Embla | null>(null);

  const [fileDetailsPanel, setFileDetailsPanel] =
    useState<OrganizationFileResponseType | null>(null);

  if (files.isLoading) {
    return <LoadingOverlay visible />;
  }

  if (!files.data?.length) {
    return (
      <Box h={300}>
        <NoDataState title="No files" />
      </Box>
    );
  }

  const scrollWidth = maxWidth ? Math.floor((175 / maxWidth) * 100) : 1;
  const numberOfSlides = maxWidth ? Math.round(maxWidth / 175) : 1;

  const onClickOnBlockingElement = (index: number) => {
    if (embla && index === numberOfSlides) {
      embla.scrollTo(index);
    }
  };

  return (
    <Stack spacing="xxl">
      {fileDetailsPanel && (
        <TableDetailsPanel
          onClose={() => setFileDetailsPanel(null)}
          type="page"
        >
          <FileDetailsPanel
            file={fileDetailsPanel}
            onClose={() => setFileDetailsPanel(null)}
            device={device}
          />
        </TableDetailsPanel>
      )}

      <Group spacing={4}>
        <Text fz="md" color="gray.9">
          User & Manufacturer Files
        </Text>

        <Text fz="lg" color="gray.5">
          ({files.data.length})
        </Text>
      </Group>

      <Carousel
        withIndicators
        height={200}
        slideSize={scrollWidth}
        slideGap={0}
        align="start"
        slidesToScroll={numberOfSlides - 1}
        controlSize={32}
        draggable={false}
        styles={carouselStyles}
        getEmblaApi={setEmbla}
        withControls={files.data.length > numberOfSlides}
      >
        {files.data.map((file, index) => (
          <Carousel.Slide key={file.id}>
            <Stack
              align="center"
              w={150}
              onClick={() => {
                onClickOnBlockingElement(index);
                setFileDetailsPanel(file);
              }}
              className={cx(classes.fileDetails, {
                selected: fileDetailsPanel?.id === file.id,
              })}
              spacing="xs"
            >
              <FileTypeIcon fileUrl={file.url} width={70} />

              <Text color="gray.7" truncate maw={100}>
                {file.name}
              </Text>

              <Text fz="xs" color="gray.5">
                {formatDateTime(file.created_at, 'MMM DD, YYYY')}
              </Text>
            </Stack>
          </Carousel.Slide>
        ))}
      </Carousel>

      {files.data.length > numberOfSlides && <Box className={classes.fade} />}
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  fade: {
    width: '6%',
    height: '80%',
    top: 0,
    position: 'absolute',
    right: 20,
    background:
      'linear-gradient(270deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%)',
    zIndex: 1,
    pointerEvents: 'none',
  },

  fileDetails: {
    cursor: 'pointer',
    zIndex: 3,
    padding: theme.spacing.sm,

    '&.selected': {
      borderRadius: theme.radius.md,
      border: `1px solid ${theme.colors.primary[4]}`,
      background: theme.colors.primary[0],
    },
  },
}));

const carouselStyles: CarouselProps['styles'] = (theme) => ({
  controls: {
    top: -55,
    left: 'unset',
    zIndex: 2,
  },

  slide: {
    zIndex: 2,
  },

  control: {
    marginRight: 5,
    backgroundColor: theme.colors.gray[1],

    '&[data-inactive]': {
      opacity: 0,
      cursor: 'default',
    },
  },
});
