import { useMantineTheme } from '@mantine/core';
import moment from 'moment';
import React from 'react';
import { LabelProps } from 'recharts';

export interface XAxisLabelProps extends LabelProps {
  index: number;
  visibleTicksCount: number;
  payload: {
    value: string;
  };
}

export function XAxisLabel({
  x,
  y,
  payload,
  textAnchor,
  className,
}: XAxisLabelProps) {
  const theme = useMantineTheme();

  return (
    <g>
      <text
        className={className}
        x={x}
        y={y}
        dy={10}
        fontWeight={400}
        textAnchor={textAnchor}
        fontSize={theme.fontSizes.xs}
        fill={theme.colors.gray[4]}
      >
        {moment(payload.value).format('MM/DD/YY')}
      </text>

      <text
        className={className}
        x={x}
        y={y}
        dy={25}
        fontWeight={400}
        textAnchor={textAnchor}
        fontSize={theme.fontSizes.xs}
        fill={theme.colors.gray[4]}
      >
        {moment(payload.value).format('HH:mm:ss')}
      </text>
    </g>
  );
}
