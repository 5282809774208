import { createStyles, Tooltip } from '@mantine/core';
import React from 'react';

import { useOrganizationConfig } from '@portals/api/organizations';
import { ReactComponent as Lifebuoy } from '@portals/icons/linear/lifebuoy.svg';

import { ContactPartnerSupportButton } from './ContactPartnerSupportButton';

export function NavbarContactSupport() {
  const organizationConfig = useOrganizationConfig();

  const { classes } = useStyles();

  if (!organizationConfig?.partner_support_email) {
    return null;
  }

  return (
    <Tooltip label="Contact support">
      <ContactPartnerSupportButton
        supportModalTitle="Contact support"
        className={classes.actionIcon}
      >
        <Lifebuoy />
      </ContactPartnerSupportButton>
    </Tooltip>
  );
}

const useStyles = createStyles((theme) => ({
  actionIcon: {
    color: theme.colors.blue_gray[6],
    paddingInline: 0,
    '&:hover': {
      color: theme.colors.blue_gray[6],
      backgroundColor: 'transparent',
    },
  },
}));
