import { FormikProps } from 'formik';
import React, { FC, ReactElement, ReactNode } from 'react';
import { ModalBody, ModalFooter } from 'reactstrap';
import * as Yup from 'yup';

import { AutoFormikProps, FieldType, FieldTypeEnum } from '@portals/types';

import Actions from './Actions';
import Body from './Body';
import { FormikOnChange } from './FormikOnChange';

type WrapProps = {
  children: ReactNode;
  Component?:
    | (({ children }: { children: ReactNode }) => ReactNode)
    | typeof ModalBody;
};

const Wrap: FC<WrapProps> | ((props: WrapProps) => ReactElement) = ({
  Component,
  children,
}) => (Component ? <Component>{children}</Component> : children);

export const formBuilder =
  (builderProps: AutoFormikProps) => (formikProps: FormikProps<any>) => {
    return (
      <form onSubmit={formikProps.handleSubmit} autoComplete="off">
        <FormikOnChange onChange={builderProps.onChange} />

        <Wrap Component={builderProps.bodyWrap || ModalBody}>
          <Body {...builderProps} {...formikProps} />
        </Wrap>

        <Wrap Component={builderProps.actionWrap || ModalFooter}>
          {!builderProps.readOnly ? (
            <Actions {...builderProps} {...formikProps} />
          ) : null}
        </Wrap>
      </form>
    );
  };

export const buildSchema = (fields: Array<FieldType>) =>
  Yup.object().shape(
    fields.reduce((obj, field) => {
      let type;

      if (field.validations) {
        type = field.validations;
      } else {
        switch (field.type) {
          // TODO: Support more types
          case FieldTypeEnum.Number:
            type = Yup.number();
            break;

          case FieldTypeEnum.Checkbox:
            type = Yup.boolean();
            break;

          case FieldTypeEnum.Email:
            type = Yup.string().email('email address is not valid');
            break;

          default:
            type = Yup.mixed();
        }
      }

      if (field.required) {
        type = type.required('Required');
      }

      return { ...obj, [field.name]: type };
    }, {})
  );

const firstNonNull = (array: Array<any>) =>
  array.find((i) => i !== null && typeof i !== 'undefined');

export const buildInitialValues = (
  fields: Array<FieldType>,
  initialValues: Record<string, any> = {}
) =>
  fields.reduce(
    (obj, field) => ({
      ...obj,
      [field.name]: firstNonNull([
        initialValues[field.name],
        field.default,
        field.type === FieldTypeEnum.Checkbox ? false : '',
      ]),
    }),
    {}
  );
