import { Stack } from '@mantine/core';
import React, { FC, useMemo } from 'react';

import {
  DeviceDetailsType,
  DeviceEventType,
  DEVICES_API_URL,
  useDeviceEvents,
} from '@portals/api/organizations';
import { DateCell, PaginatedTable } from '@portals/table';
import { PaginatedFilterTypeEnum, TableColumn } from '@portals/types';

interface EventsProps {
  device: DeviceDetailsType;
}

export const Events: FC<EventsProps> = ({ device }) => {
  const columns = useMemo<TableColumn<DeviceEventType>[]>(
    () => [
      {
        dataField: 'name',
        text: 'Name',
        isSticky: true,
        sort: true,
        maxWidth: 200,
        minWidth: 200,
        filter: {
          type: PaginatedFilterTypeEnum.Text,
        },
      },
      {
        dataField: 'category',
        text: 'Category',
        sort: true,
        filter: {
          type: PaginatedFilterTypeEnum.Text,
        },
      },
      {
        dataField: 'int_key_1',
        text: 'INT Key 1',
        maxWidth: 200,
        minWidth: 200,
        sort: true,
        formatter: (_, { additional_info }) => additional_info.int_key_1 || '',
        filter: {
          type: PaginatedFilterTypeEnum.Number,
        },
      },
      {
        dataField: 'int_key_2',
        text: 'INT Key 2',
        maxWidth: 200,
        minWidth: 200,
        sort: true,
        formatter: (_, { additional_info }) => additional_info.int_key_2 || '',
        filter: {
          type: PaginatedFilterTypeEnum.Number,
        },
      },
      {
        dataField: 'string_key_1',
        text: 'STRING Key 1',
        maxWidth: 220,
        minWidth: 220,
        sort: true,
        formatter: (_, { additional_info }) =>
          additional_info.string_key_1 || '',
        filter: {
          type: PaginatedFilterTypeEnum.Text,
        },
      },
      {
        dataField: 'string_key_2',
        text: 'STRING Key 2',
        maxWidth: 220,
        minWidth: 220,
        sort: true,
        formatter: (_, { additional_info }) =>
          additional_info.string_key_2 || '',
        filter: {
          type: PaginatedFilterTypeEnum.Text,
        },
      },
      {
        dataField: 'firmware_version',
        text: 'Firmware Version',
        sort: true,
        filter: {
          type: PaginatedFilterTypeEnum.Text,
        },
      },
      {
        dataField: 'timestamp',
        text: 'Date Created',
        sort: true,
        filter: {
          type: PaginatedFilterTypeEnum.Date,
        },
        formatter: (_, { timestamp }) => <DateCell date={timestamp} />,
      },
    ],
    []
  );

  return (
    <Stack
      spacing="xl"
      py="xl"
      sx={{
        height: '100%',
      }}
    >
      <PaginatedTable<DeviceEventType>
        keyField="id"
        name="device-events"
        columns={columns}
        dataHookUrl={`${DEVICES_API_URL}/${device.id}/device_events`}
        dataHook={useDeviceEvents}
        noDataIndication={{
          title: 'No events',
        }}
        isUrlSyncEnabled={false}
        defaultSortBy={[{ id: 'timestamp', desc: true }]}
      />
    </Stack>
  );
};
