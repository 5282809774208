import React from 'react';

import { Welcome } from '@portals/framework';

import { AuthWrapper } from './common';

export function WelcomeWrapper() {
  return (
    <AuthWrapper>
      <Welcome />
    </AuthWrapper>
  );
}
