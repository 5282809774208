import { createStyles, LoadingOverlay, SimpleGrid, Stack } from '@mantine/core';
import { isEmpty } from 'lodash/fp';
import React from 'react';

import { DeviceType, useDeviceWarranties } from '@portals/api/organizations';

import { EmptyWarrantiesCard } from './EmptyWarrantiesCard';
import { UploadWarrantyButton } from './UploadWarrantyButton';
import { WarrantyCard } from './WarrantyCard';

interface DeviceWarrantiesProps {
  device: DeviceType;
}

export function DeviceWarranties({ device }: DeviceWarrantiesProps) {
  const { classes } = useStyles();
  const warranties = useDeviceWarranties(device.id);

  return (
    <Stack spacing="xl" className={classes.container}>
      <LoadingOverlay visible={warranties.isFetching} />

      {!isEmpty(warranties.data) && (
        <header className={classes.header}>
          <UploadWarrantyButton deviceId={device.id} />
        </header>
      )}

      <SimpleGrid cols={3} spacing="xl" className={classes.grid}>
        {isEmpty(warranties.data) && !warranties.isFetching ? (
          <EmptyWarrantiesCard deviceId={device.id} />
        ) : (
          <>
            {warranties.data?.map((warranty) => (
              <WarrantyCard key={warranty.id} warranty={warranty} />
            ))}
          </>
        )}
      </SimpleGrid>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    height: '100%',
    paddingBlock: theme.spacing.xl,
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  grid: {
    gridAutoRows: 280,
  },
}));
