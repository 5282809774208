import { OrganizationConfigurationType } from '@portals/types';

export interface ContactType {
  finance_contact_name: string;
  finance_contact_email: string;
  admin_contact_name: string;
  admin_contact_email: string;
}

export const GETTING_STARTED_TOURS: Array<
  keyof OrganizationConfigurationType['onboarding_tours']['getting_started']
> = [
  'claim_device_completed',
  'monitoring_completed',
  'setup_spaces_completed',
];

export enum GettingStartedToursNamesEnum {
  SetupSpaces = 'setup_spaces',
  ClaimDevice = 'claim_device',
  Monitoring = 'monitoring',
}
