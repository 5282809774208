import {
  DELETE_ALL_DATA,
  DELETE_DATA,
  DELETE_DATA_BY_ACCESSOR,
  REPLACE_DATA,
  SET_VALUE,
  UPDATE_AND_MERGE_DATA,
  UPDATE_DATA,
  UPDATE_NESTED,
} from '../constants';

export const updateData = (type) => (data) => ({
  type: UPDATE_DATA,
  payload: {
    data,
    type,
  },
});

// Replaces all data of "type" with normalized response
export const replaceNormalizedData = (type) => (data) => ({
  type: REPLACE_DATA,
  payload: {
    data: data.entities[type] || {},
    type,
  },
});

// Updates existing data
export const updateNormalizedData = (type) => (data) => ({
  type: UPDATE_DATA,
  payload: {
    data: data.entities[type],
    type,
  },
});

// Updates existing data & merge existing values
export const updateAndMergeNormalizedData = (type) => (data) => ({
  type: UPDATE_AND_MERGE_DATA,
  payload: {
    data: data.entities[type],
    type,
  },
});

// Removes data of "type" under "id"
export const deleteData = (type, id) => ({
  type: DELETE_DATA,
  payload: {
    type,
    data: id,
  },
});

// Removes data of "type" under "id" by accessor function
export const deleteDataByAccessor = (type, accessor) => ({
  type: DELETE_DATA_BY_ACCESSOR,
  payload: {
    type,
    data: { accessor },
  },
});

// Removes all data of "type" (all ids)
export const deleteAllData = (type) => ({
  type: DELETE_ALL_DATA,
  payload: type,
});

export const setValue = (type, id) => (value) => ({
  type: SET_VALUE,
  payload: { type, id, value },
});

export const setNestedValue = (keys, value) => ({
  type: UPDATE_NESTED,
  payload: {
    keys,
    value,
  },
});

export const setNestedValueWrapped = (keys) => (value) => ({
  type: UPDATE_NESTED,
  payload: {
    keys,
    value,
  },
});
