import { getOr, isUndefined } from 'lodash/fp';

import { PortalConfigType } from '@portals/api/organizations';

export const portalName = (config: Partial<PortalConfigType>) =>
  isUndefined(process.env.NX_BRAND_NAME)
    ? getOr('', 'name', config)
    : process.env.NX_BRAND_NAME;

export const portalWindowTitle = (config: Partial<PortalConfigType>) =>
  config?.window_title || `${portalName(config)} - Simply Connected`;

export const portalFavicon = (
  config: Partial<PortalConfigType>,
  defaultPath: string = 'favicon.ico'
) => config?.favicon || defaultPath;
