import { Button, Group, Stack, Text } from '@mantine/core';
import { ModalProps as MantineModalProps } from '@mantine/core/lib/Modal/Modal';
import React from 'react';

import { ModalCenteredMediaLayout } from '@portals/core';
import { ModalProps } from '@portals/framework';
import { suppressPropagation } from '@portals/utils';

import crownSrc from '../../assets/img/crown-shadow.svg';

export interface PremiumCommandOnMobileModalProps extends ModalProps {}

export function PremiumCommandOnMobile({
  closeMe,
}: PremiumCommandOnMobileModalProps) {
  return (
    <ModalCenteredMediaLayout
      opened
      onClose={closeMe}
      media={<img src={crownSrc} />}
      radius="lg"
      styles={modalStyles}
      withCloseButton={false}
    >
      <Stack>
        <Text ta="center" fz="sm">
          Licenses not available on mobile
        </Text>

        <Group position="center" spacing={4}>
          <Text ta="center" color="gray.9" fz="xs">
            Buying and assigning licenses are only available on the desktop
            version. Please access your account on a desktop to manage your
            licenses.
          </Text>
        </Group>

        <Button
          mt="md"
          data-testid="got-it-button"
          onClick={suppressPropagation(closeMe)}
        >
          Got it
        </Button>
      </Stack>
    </ModalCenteredMediaLayout>
  );
}

const modalStyles: MantineModalProps['styles'] = (theme) => ({
  header: {
    padding: theme.spacing.xxl,
  },
});
