import {
  Anchor,
  Box,
  createStyles,
  Group,
  Paper,
  Stack,
  Text,
} from '@mantine/core';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import {
  IncidentClosedMethodEnum,
  IncidentType,
  useWithSupportCenter,
} from '@portals/api/organizations';
import { DetailsPanel } from '@portals/core';
import {
  DetailsList,
  DetailsListProps,
  IncidentStatusBadge,
  ModalButton,
  PriorityIcon,
} from '@portals/framework';
import { useHasEntityAccess } from '@portals/framework/EntityAccess';
import { RouteModalLink } from '@portals/framework/route-modals';
import { ReactComponent as ArrowUp } from '@portals/icons/linear/arrow-up.svg';
import { ReactComponent as CloseSquare } from '@portals/icons/linear/close-square.svg';
import { ReactComponent as Edit3 } from '@portals/icons/linear/edit-3.svg';
import { AccessLevelEnum } from '@portals/types';
import { formatDateTime, timeAgo } from '@portals/utils';

import { IncidentEditDetailsPanel } from './IncidentEditDetailsPanel';
import { CreateTicketModalProps } from '../../modals';

interface IncidentDetailsPanelProps {
  incident: IncidentType;
  onClosePanel: () => void;
}

export function IncidentDetailsPanel({
  incident,
  onClosePanel,
}: IncidentDetailsPanelProps) {
  const { classes } = useStyles();
  const hasEditAccess = useHasEntityAccess(incident, AccessLevelEnum.Edit);
  const [isEditMode, setIsEditMode] = useState(false);

  const withSupportCenter = useWithSupportCenter();

  const getClosedMethod = () => {
    if (incident.closed_method === IncidentClosedMethodEnum.Manually) {
      return 'manually';
    } else if (
      incident.closed_method === IncidentClosedMethodEnum.Automatically
    ) {
      return 'automatically';
    } else {
      return 'by device';
    }
  };

  const detailsList: DetailsListProps['items'] = [
    {
      label: 'Space',
      value: (
        <Anchor component={Link} to={`/overview/${incident.space_id}`}>
          {incident.space_name}
        </Anchor>
      ),
    },
    {
      label: 'Device',
      value: (
        <RouteModalLink modalId="device" pathParams={[incident.device_id]}>
          {incident.device_name}
        </RouteModalLink>
      ),
    },
    {
      label: 'Issue',
      value: incident.issue,
    },
    { label: 'Created', value: timeAgo(Date.parse(incident.created_at)) },
    {
      label: 'Last Updated',
      value: timeAgo(Date.parse(incident.updated_at)),
    },
    {
      label: 'ID',
      value: incident.id,
    },
  ];

  if (isEditMode) {
    return (
      <IncidentEditDetailsPanel
        incident={incident}
        discardEdit={() => setIsEditMode(false)}
        closeDetailsPanel={onClosePanel}
      />
    );
  }

  return (
    <DetailsPanel>
      <DetailsPanel.Header
        onClose={onClosePanel}
        title={
          <Stack spacing="sm">
            <Box>
              <IncidentStatusBadge status={incident.status} />
            </Box>

            <Group spacing="xs">
              <PriorityIcon priorityLevel={incident.priority} iconSize={18} />
              <DetailsPanel.Title>{incident.title}</DetailsPanel.Title>
            </Group>
          </Stack>
        }
      >
        {incident.status !== 'closed' && hasEditAccess ? (
          <DetailsPanel.Actions>
            <DetailsPanel.ActionButton
              data-testid="sidebar-edit-button"
              leftIcon={<Edit3 width={16} height={16} />}
              onClick={() => setIsEditMode(true)}
            >
              Edit
            </DetailsPanel.ActionButton>

            {withSupportCenter ? (
              <ModalButton
                variant="default"
                size="xs"
                leftIcon={<ArrowUp width={16} height={16} />}
                data-testid="sidebar-escalate-button"
                label="Escalate"
                modalName="CreateTicketModal"
                data={
                  {
                    deviceId: incident.device_id,
                    description: incident.description,
                  } satisfies CreateTicketModalProps['data']
                }
              />
            ) : null}

            <ModalButton
              variant="default"
              size="xs"
              data-testid="sidebar-close-button"
              label="Close"
              leftIcon={<CloseSquare width={16} height={16} />}
              modalName="CloseIncident"
              data={{
                incident,
                onSuccess: () => {
                  onClosePanel();
                },
              }}
            />
          </DetailsPanel.Actions>
        ) : null}
      </DetailsPanel.Header>

      <DetailsPanel.Body>
        <DetailsPanel.Section
          title="Description"
          content={incident.description || '--'}
        />

        {incident.status === 'closed' && (
          <DetailsPanel.Section>
            <Paper className={classes.closeDetails}>
              <Stack spacing="sm">
                <Stack spacing={2}>
                  <Text>
                    <Text span>Closed {getClosedMethod()}</Text>
                    {incident.closed_by ? (
                      <Text span color="gray.7">
                        {' '}
                        (by {incident.closed_by.name})
                      </Text>
                    ) : null}
                  </Text>

                  <Text color="gray.5" size="xs">
                    {formatDateTime(incident.closed_at)}
                  </Text>
                </Stack>

                {incident.closed_method ===
                IncidentClosedMethodEnum.Manually ? (
                  <Text>{incident.closed_reason}</Text>
                ) : null}
              </Stack>
            </Paper>
          </DetailsPanel.Section>
        )}

        <DetailsList title="Information" items={detailsList} />
      </DetailsPanel.Body>
    </DetailsPanel>
  );
}

const useStyles = createStyles((theme) => ({
  closeDetails: {
    backgroundColor: theme.colors.gray[1],
    borderRadius: theme.radius.lg,
    padding: theme.spacing.lg,
  },
}));
