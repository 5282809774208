import {
  Box,
  Button,
  createStyles,
  Group,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import { useHover } from '@mantine/hooks';
import React, { ReactNode } from 'react';

import { ReactComponent as TickCircle } from '@portals/icons/bold/tick-circle.svg';
import { ReactComponent as Replay } from '@portals/icons/linear/replay.svg';

interface TourItemProps {
  icon: ReactNode;
  title: string;
  description: string;
  isCompleted: boolean;
  buttonTitle: string;
  disabled: boolean;
  onClick: () => void;
  replay: () => void;
}
export function TourItem({
  icon,
  title,
  description,
  isCompleted,
  buttonTitle,
  disabled,
  onClick,
  replay,
}: TourItemProps) {
  const { classes, theme } = useStyles();
  const { hovered, ref } = useHover();

  return (
    <Group noWrap position="apart" py="xxl" spacing="xl" ref={ref}>
      <Group noWrap spacing="xl" opacity={isCompleted ? 0.5 : 1} maw="80%">
        <Box className={classes.itemIconContainer}>{icon}</Box>
        <Stack spacing={6}>
          <Text color="gray.9" size="md">
            {title}
          </Text>
          <Text color="gray.7">{description}</Text>
        </Stack>
      </Group>

      {isCompleted ? (
        <Group spacing="xl" noWrap>
          {hovered && (
            <Tooltip label="replay">
              <Box
                onClick={replay}
                data-testid="replay-button"
                className={classes.replayIconContainer}
              >
                <Replay width={32} height={32} />
              </Box>
            </Tooltip>
          )}
          <Box>
            <TickCircle
              width={32}
              height={32}
              color={theme.colors.teal_accent[4]}
            />
          </Box>
        </Group>
      ) : (
        <Button
          onClick={onClick}
          disabled={disabled}
          radius="md"
          data-testid={`getting-started-button-${buttonTitle}`}
        >
          {buttonTitle}
        </Button>
      )}
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  itemIconContainer: {
    borderRadius: theme.radius.md,
    padding: theme.spacing.xs,
    backgroundColor: theme.fn.rgba(theme.colors.indigo_accent[1], 0.3),

    svg: {
      width: 24,
      height: 24,
      color: theme.colors.indigo_accent[2],
    },
  },

  replayIconContainer: {
    cursor: 'pointer',
  },
}));
