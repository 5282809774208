import {
  createStyles,
  Group,
  Paper,
  Stack,
  Switch,
  SwitchProps,
  Text,
  TextInput,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import React from 'react';

import {
  DeviceIncidentRateFormType,
  DeviceIncidentViewEnum,
  IncidentPriorityEnum,
} from '@portals/api/organizations';

interface SpaceIncidentRateFormProps {
  form: UseFormReturnType<DeviceIncidentRateFormType>;
}

export function DeviceIncidentRateForm({ form }: SpaceIncidentRateFormProps) {
  const { classes, cx } = useStyles();

  return (
    <Stack spacing="xl" pr="xxl">
      <Text size="sm" color="gray.9">
        General
      </Text>

      <TextInput {...form.getInputProps('title')} label="Title" />

      <Text size="sm" color="gray.9">
        View
      </Text>

      <Group noWrap grow align="stretch">
        <Paper
          radius="md"
          p="md"
          withBorder
          className={cx(classes.view, {
            [classes.selected]:
              form.values.deviceIncidentView ===
              DeviceIncidentViewEnum.DevicesWithIncidents,
          })}
          onClick={() => {
            form.setFieldValue(
              'deviceIncidentView',
              DeviceIncidentViewEnum.DevicesWithIncidents
            );
          }}
        >
          <Stack spacing={4}>
            <Text size="sm" align="center">
              Devices with incidents
            </Text>

            <Text size="xs" color="gray.9" align="center">
              Devices experiencing incidents, providing an overview of incident
              rates.
            </Text>
          </Stack>
        </Paper>

        <Paper
          withBorder
          radius="md"
          p="md"
          w={250}
          className={cx(classes.view, {
            [classes.selected]:
              form.values.deviceIncidentView ===
              DeviceIncidentViewEnum.DevicesWithoutIncidents,
          })}
          onClick={() => {
            form.setFieldValue(
              'deviceIncidentView',
              DeviceIncidentViewEnum.DevicesWithoutIncidents
            );
          }}
        >
          <Stack spacing={4}>
            <Text size="sm" align="center">
              Incident-free devices
            </Text>

            <Text size="xs" align="center">
              Devices with no incidents, providing an overview of incident
              rates.
            </Text>
          </Stack>
        </Paper>
      </Group>

      <Stack spacing={4}>
        <Text size="sm" color="gray.9">
          Incident priority
        </Text>

        {form.values.incidentPriorities.length === 0 ? (
          <Text color="red_accent.4" size="xs">
            Please select at least one priority
          </Text>
        ) : null}
      </Stack>

      <Switch.Group
        value={form.values.incidentPriorities}
        onChange={(values: IncidentPriorityEnum[]) =>
          form.setFieldValue('incidentPriorities', values)
        }
      >
        <Stack>
          <Switch
            value={IncidentPriorityEnum.Critical}
            label="Critical"
            labelPosition="left"
            styles={switchStyles}
          />

          <Switch
            value={IncidentPriorityEnum.High}
            label="High"
            labelPosition="left"
            styles={switchStyles}
          />

          <Switch
            value={IncidentPriorityEnum.Moderate}
            label="Moderate"
            labelPosition="left"
            styles={switchStyles}
          />

          <Switch
            value={IncidentPriorityEnum.Low}
            label="Low"
            labelPosition="left"
            styles={switchStyles}
          />

          <Switch
            value={IncidentPriorityEnum.Planning}
            label="Planning"
            labelPosition="left"
            styles={switchStyles}
          />
        </Stack>
      </Switch.Group>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  view: {
    cursor: 'pointer',
  },

  selected: {
    color: theme.fn.primaryColor(),
    border: `1px solid ${theme.fn.primaryColor()} !important`,
  },
}));

const switchStyles: SwitchProps['styles'] = (theme) => ({
  body: {
    justifyContent: 'space-between',
  },
});
