import { ModalProps, ModalStylesNames, rem } from '@mantine/core';

import { ThemeComponentOverrides } from '../common-types';

export const Modal: ThemeComponentOverrides<ModalProps, ModalStylesNames, any> =
  {
    defaultProps: {
      centered: true,
      size: 'lg',
      radius: 'lg',
    },
    styles: (theme) => ({
      content: {
        color: theme.colors.blue_gray[9],
      },
      title: {
        fontSize: rem(24),
      },
      body: {
        fontSize: theme.fontSizes.sm,
      },
      close: {
        svg: {
          width: 18,
          height: 18,
        },
      },
    }),
    sizes: {
      lg: () => ({
        content: {
          width: 565,
          flex: 'unset',
        },
      }),
    },
  };
