import {
  Button,
  createStyles,
  Group,
  LoadingOverlay,
  Modal,
} from '@mantine/core';
import { ModalProps as MantineModalProps } from '@mantine/core/lib/Modal/Modal';
import React, { useEffect } from 'react';

import {
  useGroup,
  useRemoveGroupUsers,
  useUpdateGroup,
} from '@portals/api/organizations';
import {
  GroupIconNames,
  GroupId,
  GroupMembersList,
  GroupNameAndIcon,
  ModalProps,
  useGroupMembersList,
  useGroupNameAndIcon,
} from '@portals/framework';
import { useOpenModal } from '@portals/redux';

import { AddExternalUserModalProps } from './AddExternalUserModal';
import { AddInternalUsersToExistingGroupModalProps } from './AddInternalUsersToExistingGroupModal';
import {
  AddUserTypeModalProps,
  InternalOrExternal,
} from '../../AddUserTypeModal';

export interface EditGroupModalProps extends ModalProps<{ groupId: string }> {}

export function EditGroupModal({
  closeMe,
  data: { groupId },
}: EditGroupModalProps) {
  const { classes } = useStyles();
  const openModal = useOpenModal();

  const group = useGroup(groupId);
  const updateGroup = useUpdateGroup();
  const removeGroupUsers = useRemoveGroupUsers();

  const { groupName, setGroupName, iconName, setIconName, isGroupNameError } =
    useGroupNameAndIcon();
  const {
    usersList,
    setUsersList,
    onChangeCheckAllUsers,
    onChangeCheckedUser,
  } = useGroupMembersList();

  const onChangeIconName = (newIconName: GroupIconNames) => {
    setIconName(newIconName);

    if (newIconName !== group.data?.icon_name) {
      updateGroup.mutate({
        groupId,
        name: groupName,
        iconName: newIconName,
      });
    }
  };

  const onBlurGroupName = () => {
    if (groupName.length > 0 && group.data?.name !== groupName) {
      updateGroup.mutate({ groupId, name: groupName, iconName });
    }
  };

  useEffect(
    function initializeDataAfterFetch() {
      if (group.isLoading) return;

      if (group.data) {
        setGroupName(group.data.name);
        setIconName(group.data.icon_name as GroupIconNames);
        setUsersList(
          group.data.users.map((user) => ({ ...user, isChecked: false }))
        );
      }
    },
    [group.data, group.isLoading, setGroupName, setIconName, setUsersList]
  );

  const onUserTypeSubmit = (selectedUserType: InternalOrExternal) => {
    if (selectedUserType === 'internal') {
      openModal<AddInternalUsersToExistingGroupModalProps['data']>(
        'AddInternalUsersToExistingGroupModal',
        { groupId }
      );
    } else if (selectedUserType === 'external') {
      openModal<AddExternalUserModalProps['data']>('AddExternalUserModal', {
        groupId,
      });
    }
  };

  return (
    <Modal
      opened
      title="Manage Group"
      onClose={closeMe}
      padding={0}
      size="clamp(800px, 55%, 1030px)"
      styles={modalStyles}
    >
      <div className={classes.body}>
        <LoadingOverlay visible={group.isLoading} />

        <Group px={30} mb={32} spacing="xl" position="apart" align="flex-end">
          <GroupNameAndIcon
            groupName={groupName}
            onChangeGroupName={setGroupName}
            iconName={iconName}
            onChangeIconName={onChangeIconName}
            error={isGroupNameError}
            disabled={group.data?.is_name_editable !== true}
            isLoading={updateGroup.isLoading}
            onBlur={onBlurGroupName}
          />

          <GroupId groupId={groupId} />
        </Group>

        <GroupMembersList
          usersList={usersList}
          onChangeCheckedUser={onChangeCheckedUser}
          onChangeCheckAllUsers={onChangeCheckAllUsers}
          confirmBeforeRemovingUsers
          onRemoveUsers={(userIdsToRemove) =>
            removeGroupUsers.mutate({ groupId, userIds: userIdsToRemove })
          }
          onAddUsersActionClick={() =>
            openModal<AddUserTypeModalProps['data']>('AddUserTypeModal', {
              onSubmit: onUserTypeSubmit,
            })
          }
        />

        <Group className={classes.footer} position="right">
          <Button onClick={closeMe}>Close</Button>
        </Group>
      </div>
    </Modal>
  );
}

const modalStyles: MantineModalProps['styles'] = () => ({
  content: {
    display: 'grid',
    gridTemplateRows: 'max-content 1fr',
    minHeight: '85%',
    maxHeight: '85%',
  },
  header: {
    padding: 30,
    marginBottom: 0,
  },
});

const useStyles = createStyles((theme) => ({
  body: {
    position: 'relative',
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'max-content 1fr max-content',
  },
  footer: {
    padding: theme.spacing.xl,
    borderTop: `1px solid ${theme.colors.gray[2]}`,
  },
}));
