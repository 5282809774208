import React from 'react';

import { RouteOverlayLink, RouteOverlayLinkProps } from '../route-overlays';

interface RouteModalLinkProps
  extends Omit<RouteOverlayLinkProps, 'overlayType' | 'entityId'> {
  modalId: RouteOverlayLinkProps['entityId'];
}

export function RouteModalLink({ modalId, ...rest }: RouteModalLinkProps) {
  return <RouteOverlayLink overlayType="modal" entityId={modalId} {...rest} />;
}
