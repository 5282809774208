import { Box, createStyles } from '@mantine/core';
import React from 'react';

import { usePortalConfig } from '@portals/api/organizations';

import Logo from './Logo';

export const AuthHeader = () => {
  const { classes } = useStyles();
  const portalConfig = usePortalConfig();

  const design = portalConfig.data?.design;

  return (
    <Box
      className={classes.container}
      bg={design?.sign_in_page?.background_color || 'white'}
    >
      <Logo />
    </Box>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '40px',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `0 ${theme.spacing.md}`,
    height: 60,
    zIndex: 6,
    boxShadow: `0 16px 24px rgba(0, 0, 0, 0.025), 0 2px 6px rgba(0, 0, 0, 0.025), 0 0 1px rgba(0, 0, 0, 0.025)`,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },
}));
