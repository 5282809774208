import { Group, Menu, Text } from '@mantine/core';
import classnames from 'classnames';
import React, { Dispatch, FC, SetStateAction } from 'react';

export enum SortByEnum {
  Featured = 1,
  Alphabetical,
  MostRecent,
  LowestPrice,
  HighestPrice,
}

const SORT_BY_LABEL = {
  [SortByEnum.Featured]: 'Featured',
  [SortByEnum.Alphabetical]: 'A-Z',
  [SortByEnum.MostRecent]: 'Most Recent',
  [SortByEnum.LowestPrice]: 'Lowest Price',
  [SortByEnum.HighestPrice]: 'Highest Price',
};

const SORT_BY_OPTIONS = [
  SortByEnum.Featured,
  SortByEnum.Alphabetical,
  SortByEnum.MostRecent,
  SortByEnum.LowestPrice,
  SortByEnum.HighestPrice,
];

interface SortByProps {
  sortBy: SortByEnum;
  setSortBy: Dispatch<SetStateAction<SortByEnum>>;
}

export const SortBy: FC<SortByProps> = ({ sortBy, setSortBy }) => (
  <Menu
    withinPortal
    styles={(theme) => ({
      item: {
        '&.selected': {
          background: theme.colors.blue_accent[4],
          color: theme.white,
        },
      },
    })}
  >
    <Menu.Target>
      <Group spacing="xs" sx={{ cursor: 'pointer' }}>
        <Text size="sm" sx={(theme) => ({ color: theme.colors.blue_gray[3] })}>
          Sort by:
        </Text>

        <Text size="sm" sx={(theme) => ({ color: theme.colors.blue_gray[6] })}>
          {SORT_BY_LABEL[sortBy]}
        </Text>
      </Group>
    </Menu.Target>

    <Menu.Dropdown>
      {SORT_BY_OPTIONS.map((option) => (
        <Menu.Item
          key={option}
          onClick={() => setSortBy(option)}
          className={classnames({ selected: option === sortBy })}
        >
          {SORT_BY_LABEL[option]}
        </Menu.Item>
      ))}
    </Menu.Dropdown>
  </Menu>
);
