import { toNumber } from 'lodash/fp';
import React from 'react';

import {
  BarIndicatorWidget,
  BarIndicatorWidgetFormType,
} from '@portals/device-widgets';

import { WidgetProps } from '../device-widgets.types';

export function BarIndicatorWidgetWrapper({
  widget,
  deviceState,

  isDeviceOffline,
  lastUpdateTimestamp,
}: WidgetProps<BarIndicatorWidgetFormType>) {
  const fields = widget?.config?.fields;

  const {
    name,
    telemetry,
    segments,
    max,
    min,
    unit,
    scale_type,
    num_of_decimals,
    number_format,
    telemetry_as_title,
    title_telemetry,
  } = fields ?? {};

  const telemetryValue = toNumber(deviceState?.[telemetry]);
  const title = telemetry_as_title
    ? deviceState?.[title_telemetry] || 'N/A'
    : name;

  return (
    <BarIndicatorWidget
      title={title}
      value={telemetryValue}
      segments={segments}
      max={max}
      min={min}
      unit={unit}
      scaleType={scale_type}
      numOfDecimals={num_of_decimals}
      numberFormat={number_format}
      isDeviceOffline={isDeviceOffline}
      lastUpdateTimestamp={lastUpdateTimestamp}
    />
  );
}
