import React, { createContext, ReactNode, useContext } from 'react';

import { ActiveCommandType } from '../../widgets.types';

interface GroupedSwitchesWidgetContextProviderProps {
  children: ReactNode;
  activeCommands: Array<ActiveCommandType>;
  deviceState: Record<string, any>;
  onToggle: (
    commandName: string,
    commandParamKey: string,
    isChecked: boolean
  ) => void;
}

interface GroupedSwitchesWidgetContextType {
  onToggle: GroupedSwitchesWidgetContextProviderProps['onToggle'];
  deviceState: GroupedSwitchesWidgetContextProviderProps['deviceState'];
  activeCommands: GroupedSwitchesWidgetContextProviderProps['activeCommands'];
}

const GroupedSwitchesWidgetContext = createContext<
  GroupedSwitchesWidgetContextType | undefined
>(undefined);

export function GroupedSwitchesWidgetContextProvider({
  children,
  onToggle,
  activeCommands,
  deviceState,
}: GroupedSwitchesWidgetContextProviderProps) {
  return (
    <GroupedSwitchesWidgetContext.Provider
      value={{ onToggle, activeCommands, deviceState }}
    >
      {children}
    </GroupedSwitchesWidgetContext.Provider>
  );
}

export function useGroupedSwitchesWidgetContext() {
  const context = useContext(GroupedSwitchesWidgetContext);

  if (context === undefined) {
    throw new Error(
      'useGroupedSwitchesWidgetContext must be used within a GroupedSwitchesWidgetContextProvider'
    );
  }

  return context;
}
