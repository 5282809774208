import { find } from 'lodash/fp';
import React, { useMemo } from 'react';

import { useActiveDeviceCommands, useDevice } from '@portals/api/organizations';
import { ButtonWidget, ButtonWidgetFormType } from '@portals/device-widgets';
import { useSendCommandWithApproval } from '@portals/framework';
import {
  PremiumCommandLicenseModalProps,
  SSHTunnelLoadingModalProps,
} from '@portals/framework/modals';
import { useOpenModal } from '@portals/redux';

import { useDeviceCommand } from '../../../../../../../hooks/commands';
import { WidgetProps } from '../device-widgets.types';

export function ButtonWidgetWrapper({
  widget,
  deviceId,

  isDeviceOffline,
  lastUpdateTimestamp,
}: WidgetProps<ButtonWidgetFormType>) {
  const fields = widget?.config?.fields;

  const sendCommandWithApproval = useSendCommandWithApproval();
  const activeCommands = useActiveDeviceCommands(deviceId);

  const { name, color, icon_name, show_icon, command_name } = fields ?? {};
  const command = useDeviceCommand(deviceId, command_name);

  const device = useDevice(deviceId);

  const openModal = useOpenModal();

  const hasCommandLicense = useMemo(() => {
    return (device?.data?.supported_commands || []).find(
      (currentCommand) => command?.id === currentCommand.id
    )?.has_license;
  }, [command, device]);

  const onClick = async () => {
    if (command?.premium && !hasCommandLicense) {
      openModal<PremiumCommandLicenseModalProps['data']>(
        'PremiumCommandLicenseModal',
        { deviceId, commandId: command?.id, commandName: command?.name }
      );

      return;
    }

    if (command?.custom_fields?.length) {
      openModal('RunCommand', {
        deviceType: device.data?.partner?.type_id,
        command,
        deviceIds: [device.data?.id],
      });

      return;
    }

    try {
      const commandResponse = await sendCommandWithApproval({
        deviceIds: [deviceId],
        command,
        extra_params: {},
      });

      if (commandResponse[0]?.tunnel_authenticate_url) {
        if (!device.data) {
          return;
        }

        openModal<SSHTunnelLoadingModalProps['data']>('SSHTunnelLoadingModal', {
          command: commandResponse[0],
          deviceId: device.data?.id,
          onSendCommand: onClick,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const isActive = !!find({ name: command_name }, activeCommands.data);

  return (
    <ButtonWidget
      title={name}
      color={color}
      iconName={icon_name}
      showIcon={show_icon}
      onClick={onClick}
      isLoading={isActive}
      isDeviceOffline={isDeviceOffline}
      lastUpdateTimestamp={lastUpdateTimestamp}
    />
  );
}
