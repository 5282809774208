import { Anchor } from '@mantine/core';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as ArrowRight2 } from '@portals/icons/linear/arrow-right-2.svg';
import { ComponentRendererType } from '@portals/types';
import { getStyledThemeColor } from '@portals/utils';

type StatisticsBoxProps = {
  color: string;
  value: string;
  desc: string;
  link?: string;
  Icon: ComponentRendererType;
  isGroup?: boolean;
};

const StatisticsBox: FC<StatisticsBoxProps> = ({
  color = 'primary',
  value,
  desc,
  link,
  Icon,
  isGroup = false,
}) => (
  <Wrapper $isGroup={isGroup} $color={color}>
    <Container className="statics-box-container">
      {Icon ? (
        <IconWrapper className="icon-wrapper">
          <Icon />
        </IconWrapper>
      ) : null}

      <Content className="content-wrapper">
        <StatsWrapper className="stats-wrapper">
          <Value className="value-wrapper">{value}</Value>
          <Description className="description-wrapper">{desc}</Description>
        </StatsWrapper>

        {link ? (
          <LinkWrapper className="link-wrapper">
            <Anchor
              component={Link}
              to={link}
              className={`close text-${color}`}
              style={{ fontSize: '.7rem' }}
            >
              More info <ArrowRight2 width={15} height={15} />
            </Anchor>
          </LinkWrapper>
        ) : null}
      </Content>
    </Container>
  </Wrapper>
);

const Wrapper = styled.div<{ $isGroup: boolean; $color: string }>`
  min-width: 245px;
  width: fit-content;

  .statics-box-container {
    background-color: ${getStyledThemeColor('gray100')};

    .icon-wrapper,
    .value-wrapper {
      color: ${({ $color }) => getStyledThemeColor($color) || $color};
    }
  }

  ${({ $isGroup }) =>
    $isGroup &&
    `
    .statistics-box-container {
      .stats-wrapper {
        flex-direction: row;
        align-items: center;

        .value-wrapper {
          margin-right: 5px;
        }

        .description-wrapper {
          font-size: 20px;
        }

        .link-wrapper {
          flex-direction: row-reverse;
        }
      }
    }
  `}
`;

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 8px;
  padding: 10px 25px;
  flex: 1;
  border: 1px solid ${getStyledThemeColor('gray200')};
`;

const IconWrapper = styled.div`
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  background-color: ${getStyledThemeColor('white')};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 25px;
  border-radius: 50%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StatsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Value = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

const Description = styled.div`
  font-size: 14px;
  color: ${getStyledThemeColor('gray600')};
`;

const LinkWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;

  a {
    display: flex;
    align-items: center;
  }

  svg {
    margin-left: 5px;
    transition: transform 0.15s ease-in-out;
  }

  &:hover {
    svg {
      transform: translateX(5px);
    }
  }
`;

export default StatisticsBox;
