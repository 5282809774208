import {
  Box,
  Button,
  Center,
  createStyles,
  Group,
  Stack,
  Text,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React from 'react';

import {
  DeviceIncidentRateFormType,
  DeviceIncidentViewEnum,
  IncidentPriorityEnum,
  useConfig,
} from '@portals/api/organizations';
import { useConfirmationModal } from '@portals/framework';

import { DeviceIncidentRateForm } from './DeviceIncidentRateForm';
import { DeviceIncidentRateWidget } from './DeviceIncidentRateWidget';
import { WidgetPreviewComponentProps } from '../../../../../modals/WidgetPreview/widget-preview.types';
import { DataLevelEnum } from '../../../overview.types';

export function DeviceIncidentRateWidgetWrapper({
  onClose,
  space,
  onSave,
  type,
  dataLevel,
  widgetId,
}: WidgetPreviewComponentProps & { onClose: () => void }) {
  const { classes } = useStyles();

  const config = useConfig();

  const deviceIncidentRateWidgetExtraParams =
    config.data?.space_dashboard?.list.find(
      (item) => item.id === 'device_incidents_rate' && item.i === widgetId
    )?.extraParams as DeviceIncidentRateFormType;

  const getInitialValues = () => {
    const initialValues: DeviceIncidentRateFormType = {
      title: 'Device incident rate',
      deviceIncidentView: DeviceIncidentViewEnum.DevicesWithIncidents,
      incidentPriorities: [
        IncidentPriorityEnum.Critical,
        IncidentPriorityEnum.High,
        IncidentPriorityEnum.Moderate,
        IncidentPriorityEnum.Low,
        IncidentPriorityEnum.Planning,
      ],
    };

    if (deviceIncidentRateWidgetExtraParams?.title) {
      initialValues.title = deviceIncidentRateWidgetExtraParams.title;
    }

    if (deviceIncidentRateWidgetExtraParams?.deviceIncidentView) {
      initialValues.deviceIncidentView =
        deviceIncidentRateWidgetExtraParams.deviceIncidentView;
    }

    if (deviceIncidentRateWidgetExtraParams?.incidentPriorities) {
      initialValues.incidentPriorities =
        deviceIncidentRateWidgetExtraParams.incidentPriorities;
    }

    return initialValues;
  };

  const form = useForm<DeviceIncidentRateFormType>({
    initialValues: getInitialValues(),
  });

  const asyncConfirmationCheck = useConfirmationModal();

  const onCloseWrapper = async () => {
    if (!form.isDirty()) {
      onClose();
      return;
    }

    const isConfirmed = await asyncConfirmationCheck({
      title: 'Are you sure?',
      description: 'All your changes will be lost',
      confirmationLabel: 'Confirm',
      cancelLabel: 'Cancel',
    });

    if (isConfirmed) {
      onClose();
    }
  };

  const onSubmit = ({
    incidentPriorities,
    deviceIncidentView,
    title,
  }: typeof form.values) => {
    onSave?.({
      incidentPriorities,
      deviceIncidentView,
      title,
    });

    onClose();
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Box className={classes.container}>
        <Stack className={classes.preview} p="xxl" pb={0} spacing="xxl">
          <Text size={24} color="blue_gray.9">
            Device incident rate
          </Text>

          <DeviceIncidentRateForm form={form} />
        </Stack>

        <Center className={classes.config}>
          <DeviceIncidentRateWidget
            title={
              form.values.title ? form.values.title : 'Spaces with incidents'
            }
            incidentsPriorities={form.values.incidentPriorities}
            spaceId={space?.id}
            deviceIncidentView={form.values.deviceIncidentView}
            dataLevel={dataLevel ?? DataLevelEnum.All}
          />
        </Center>

        <Group className={classes.footer} p="xl" position="right">
          <Button variant="default" onClick={onCloseWrapper}>
            Close
          </Button>

          <Button
            type="submit"
            data-testid="modal-add-widget-button"
            disabled={form.values.incidentPriorities.length === 0}
          >
            {type === 'edit' ? 'Save' : 'Add'}
          </Button>
        </Group>
      </Box>
    </form>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr min-content',
    gridTemplateAreas: `
      "preview config"
      "footer footer"
    `,
    gap: 0,
    height: 790,
  },

  preview: {
    gridArea: 'preview',
  },

  config: {
    gridArea: 'config',
    backgroundColor: theme.colors.gray[0],
  },

  footer: {
    gridArea: 'footer',
    borderTop: `1px solid ${theme.colors.gray[2]}`,
  },
}));
