import { CloseButton, createStyles, Modal } from '@mantine/core';
import React from 'react';

import { ModalProps } from '@portals/framework';

import { WIDGET_PREVIEW_COMPONENTS_MAP } from './widget-preview.constants';
import { WidgetPreviewComponentProps } from './widget-preview.types';

export interface WidgetPreviewProps
  extends ModalProps<WidgetPreviewComponentProps> {}

export function WidgetPreview({ closeMe, data }: WidgetPreviewProps) {
  const { classes } = useStyles();
  const WidgetComponent = WIDGET_PREVIEW_COMPONENTS_MAP[data.widget.id];

  if (!WidgetComponent) {
    closeMe();

    return null;
  }

  return (
    <Modal
      opened
      onClose={closeMe}
      withCloseButton={false}
      size="auto"
      padding={0}
      classNames={{
        content: classes.content,
      }}
    >
      <CloseButton
        onClick={closeMe}
        className={classes.closeButton}
        size="lg"
      />

      <WidgetComponent
        onClose={closeMe}
        widget={data.widget}
        space={data.space}
        onSave={data.onSave}
        type={data.type}
        dataLevel={data.dataLevel}
        widgetId={data.widgetId}
        localDashboard={data.localDashboard}
      />
    </Modal>
  );
}

const useStyles = createStyles((theme) => ({
  content: {
    maxWidth: 1240,
    width: '100%',
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing.xl,
    right: theme.spacing.xl,
  },
}));
