import { Button, Group, Modal, Textarea } from '@mantine/core';
import React, { useState } from 'react';

import { IncidentType, useCloseIncident } from '@portals/api/organizations';
import { ModalBody, ModalFooter } from '@portals/core';
import { ModalProps } from '@portals/framework';
import { ReactComponent as CloseSquare } from '@portals/icons/linear/close-square.svg';

interface CloseIncidentProps
  extends ModalProps<{
    incident: IncidentType;
    onSuccess: () => void;
  }> {}

export function CloseIncident({ data, closeMe }: CloseIncidentProps) {
  const { incident, onSuccess } = data;
  const closeIncident = useCloseIncident();

  const [reason, setReason] = useState('');

  const handleSubmit = () => {
    closeIncident.mutate(
      {
        incident: incident,
        closed_reason: reason,
      },
      {
        onSuccess: () => {
          if (onSuccess) {
            onSuccess();
          }

          closeMe();
        },
      }
    );
  };

  return (
    <Modal
      opened
      onClose={closeMe}
      padding="xxl"
      title={
        <Group>
          <CloseSquare />
          Close incident?
        </Group>
      }
    >
      <ModalBody>
        <Textarea
          label="Closing reason"
          placeholder="Add reason..."
          data-testid="close-incident-textarea"
          maxRows={7}
          minRows={7}
          error={closeIncident.isError}
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
      </ModalBody>

      <ModalFooter grow>
        <Button variant="default" onClick={closeMe}>
          Cancel
        </Button>
        <Button
          loading={closeIncident.isLoading}
          data-testid="close-incident-modal-close-button"
          onClick={handleSubmit}
        >
          Close Incident
        </Button>
      </ModalFooter>
    </Modal>
  );
}
