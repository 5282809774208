import { Text } from '@mantine/core';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';

import { useOrganizationConfig } from '@portals/api/organizations';
import { useConfirmationModal } from '@portals/framework';
import { AlertModalProps } from '@portals/framework/modals';
import { useOpenModal } from '@portals/redux';
import {
  OrganizationStoreListing,
  PaymentIntervalEnum,
  ProductPricingModel,
} from '@portals/types';

import { addProductToCart } from '../../../../redux/actions/store';
import {
  useCartProductsList,
  useStoreCurrency,
} from '../../../../redux/hooks/store';

interface ProductDetailsContextType {
  selectedPricingOptionType: PaymentIntervalEnum | undefined;
  setSelectedPricingOptionType: (value: PaymentIntervalEnum) => void;

  selectedQuantity: number;
  setSelectedQuantity: (value: number) => void;

  onAddToCart: () => void;

  personalizedPriceFromUrl: number | undefined;
  personalizedPrice: number | undefined;
  setPersonalizedPrice: (value: number) => void;

  pricingOptions:
    | OrganizationStoreListing['prices'][number]['pricing_options']
    | undefined;
}

const ProductDetailsContext = createContext<ProductDetailsContextType | null>(
  null
);

const PRICE_QUERY_PARAM = 'price';

interface ProductDetailsProviderProps {
  children: ReactNode;
  storeListing: OrganizationStoreListing | undefined;
}

export function ProductDetailsProvider({
  children,
  storeListing,
}: ProductDetailsProviderProps) {
  const openModal = useOpenModal();
  const dispatch = useDispatch();

  const asyncConfirmationCheck = useConfirmationModal();

  const currency = useStoreCurrency();
  const cartProductsList = useCartProductsList();

  const organizationConfig = useOrganizationConfig();

  const [selectedPricingOptionType, setSelectedPricingOptionType] =
    useState<PaymentIntervalEnum>();

  const [selectedQuantity, setSelectedQuantity] = useState(1);

  const personalizedPriceFromUrl = useMemo(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (!queryParams.has(PRICE_QUERY_PARAM)) return;

    const priceAsNumber = Number(queryParams.get(PRICE_QUERY_PARAM));

    if (priceAsNumber <= 0 || Number.isNaN(priceAsNumber)) return;

    return priceAsNumber;
  }, []);

  const [personalizedPrice, setPersonalizedPrice] = useState(
    personalizedPriceFromUrl
  );

  const pricingOptions = useMemo(() => {
    const prices = storeListing?.prices.find(
      (price) => price.currency === currency.selected
    );

    return prices?.pricing_options;
  }, [currency.selected, storeListing?.prices]);

  useEffect(
    function setInitialSelectedPricingOptionType() {
      // No need to set initial selected pricing option type if it's already set
      if (selectedPricingOptionType) return;

      if (
        storeListing?.product.pricing_model === ProductPricingModel.Personalized
      ) {
        // Personalized priced product is always one_time
        setSelectedPricingOptionType(PaymentIntervalEnum.OneTime);
        return;
      }

      if (!pricingOptions) return;

      if (!selectedPricingOptionType) {
        setSelectedPricingOptionType(pricingOptions[0].type);
      }
    },
    [
      pricingOptions,
      selectedPricingOptionType,
      storeListing?.product.pricing_model,
    ]
  );

  const onAddToCart = useCallback(async () => {
    if (!storeListing || !selectedPricingOptionType) {
      if (organizationConfig?.lab && storeListing) {
        const confirmSetPriceModal = await asyncConfirmationCheck({
          title: 'Missing price',
          description:
            "Please set a price for this product listing in order to buy it. You can still save it as a draft once you've done so.",
          confirmationLabel: 'Set price',
          confirmButtonProps: {
            color: 'primary',
          },
          cancelLabel: "I'll do it later",
        });

        if (confirmSetPriceModal) {
          window.open(
            `${process.env.NX_PARTNERS_PORTAL_URL}products/all?q[id_i_cont]=${storeListing.id}&page=1`,
            '_blank'
          );
        }
      }

      return;
    }

    if (
      storeListing.product.pricing_model === ProductPricingModel.Personalized &&
      cartProductsList.find((p) => p.id === storeListing.id)
    ) {
      openModal<AlertModalProps['data']>('AlertModal', {
        title: 'Personalized Priced Product Already in Cart',
        description: (
          <Text>
            The product you're trying to add is a personalized priced product,
            and it's already in your cart.
            <br />
            If you wish to update the quantity of this product, you can do so
            directly from your cart by using the plus (+) and minus (-) buttons.
          </Text>
        ),
      });
      return;
    }

    if (
      storeListing.product.pricing_model === ProductPricingModel.Personalized &&
      !personalizedPrice
    ) {
      return;
    }

    dispatch(
      addProductToCart({
        id: storeListing.id,
        quantity: selectedQuantity,
        period: selectedPricingOptionType,
        price: personalizedPrice || undefined,
      })
    );

    setSelectedQuantity(1);
  }, [
    asyncConfirmationCheck,
    cartProductsList,
    dispatch,
    openModal,
    organizationConfig?.lab,
    personalizedPrice,
    selectedPricingOptionType,
    selectedQuantity,
    storeListing,
  ]);

  return (
    <ProductDetailsContext.Provider
      value={{
        selectedPricingOptionType,
        setSelectedPricingOptionType,

        selectedQuantity,
        setSelectedQuantity,

        onAddToCart,

        personalizedPriceFromUrl,
        personalizedPrice,
        setPersonalizedPrice,

        pricingOptions,
      }}
    >
      {children}
    </ProductDetailsContext.Provider>
  );
}

export function useProductDetails() {
  const context = useContext(ProductDetailsContext);

  if (context === null) {
    throw new Error(
      'useProductDetails must be used within a ProductDetailsProvider'
    );
  }

  return context;
}
