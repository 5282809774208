import { useMutation, useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';
import { PaginationResponse } from '@portals/types';

import {
  SERVICE_PROVIDERS_API_URL,
  serviceProvidersQueryKeys,
} from './service-providers.constants';
import { ServiceProvider } from './types';
import { ServerError } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils';

export function useServiceProviders(searchTerm: string) {
  const { url, options } = useRequestOptions({
    url: SERVICE_PROVIDERS_API_URL,
  });

  return useQuery<
    PaginationResponse<ServiceProvider>,
    ServerError,
    ServiceProvider[]
  >({
    queryKey: [...serviceProvidersQueryKeys.all, searchTerm],
    queryFn: () =>
      fetchApiRequest(`${url}?q[display_name_i_cont]=${searchTerm}`, options),
    select: (response) => response.data,
    staleTime: 0,
    meta: {
      baseUrl: SERVICE_PROVIDERS_API_URL,
      method: 'GET',
    },
  });
}

interface UseSendEmailToServiceProviderParams {
  title: string;
  description: string;
  email: string;
  name: string;
  id: string;
}

export function useSendEmailToServiceProvider() {
  const dispatch = useDispatch();
  const { url, options } = useRequestOptions({
    url: SERVICE_PROVIDERS_API_URL,
    method: 'POST',
  });

  return useMutation<void, ServerError, UseSendEmailToServiceProviderParams>({
    mutationFn: (params) => {
      return fetchApiRequest(`${url}/${params.id}/contact`, {
        ...options,
        body: JSON.stringify(params),
      });
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Email sent to service provider successfully'));
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useSendEmailToServiceProvider',
      baseUrl: `${SERVICE_PROVIDERS_API_URL}/:id/contact`,
      method: 'POST',
    },
  });
}
