import classnames from 'classnames';
import React, { Dispatch, FC, SetStateAction, useMemo } from 'react';
import ReactSelect, { Props as ReactSelectProps } from 'react-select';
import styled from 'styled-components';

import { useGetSelectTheme, useSelectHeight } from '../hooks/select';

type Option = { label: string; value: string };

type SelectProps = {
  selectedItem: Option;
  setSelectedItem: Dispatch<SetStateAction<Option>>;
  className?: any;
  items: Array<Option>;
  isPending?: boolean;
  defaultOptionLabel?: string;
  height?: number;
};

const Select: FC<SelectProps & Partial<ReactSelectProps>> = ({
  items,
  isPending,
  selectedItem,
  setSelectedItem,
  className,
  defaultOptionLabel = 'All',
  height = 30,
  ...props
}) => {
  const itemsList = useMemo(
    () => [{ value: null, label: defaultOptionLabel }, ...items],
    [defaultOptionLabel, items]
  );

  const selectTheme = useGetSelectTheme();
  const selectStyle = useSelectHeight(height);

  return (
    <Container className={classnames(className)}>
      {/*@ts-ignore*/}
      <ReactSelect
        isSearchable
        isClearable={selectedItem?.value !== null}
        isLoading={isPending}
        value={selectedItem}
        onChange={(value) =>
          setSelectedItem(value || { value: null, label: defaultOptionLabel })
        }
        options={itemsList}
        styles={selectStyle}
        theme={selectTheme}
        {...props}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 275px;
  z-index: 2;
  margin-bottom: 35px;

  > div {
    z-index: 2;
  }
`;

export default Select;
