import { Alert, Button, createStyles, Group, Text } from '@mantine/core';
import React from 'react';

import { useHubspotConversations } from '@portals/framework';
import { ReactComponent as InfoCircle } from '@portals/icons/bold/info-circle.svg';
import { ReactComponent as Export } from '@portals/icons/linear/export.svg';
import { ReactComponent as MessageQuestion } from '@portals/icons/linear/message-question.svg';
import { ReactComponent as Note } from '@portals/icons/linear/note-2.svg';
import { useOpenModal } from '@portals/redux';

import { useUploadPurchaseOrder } from './order.hooks';

export const PurchaseOrderAlert = () => {
  const { classes, theme } = useStyles();
  const uploadPurchaseOrder = useUploadPurchaseOrder();

  return (
    <Alert
      color="amber"
      radius="md"
      px="xl"
      py="lg"
      icon={
        <InfoCircle width={24} height={24} color={theme.colors.orange[6]} />
      }
      classNames={{
        root: classes.root,
        wrapper: classes.wrapper,
      }}
    >
      <Group position="apart" align="center">
        <Text color="gray.9" size="md">
          A purchase order must be uploaded and approved by the vendor before
          the order can be sent
        </Text>

        <Button onClick={uploadPurchaseOrder} variant="light" color="amber">
          <Group align="center" color="gray.9" spacing="xs">
            <Export width={18} height={19} color={theme.colors.gray[9]} />
            <Text size="md" color="gray.9">
              Upload Purchase Order
            </Text>
          </Group>
        </Button>
      </Group>
    </Alert>
  );
};

interface PostPurchaseParametersAlertProps {
  orderId: string;
}

export const PostPurchaseParametersAlert = ({
  orderId,
}: PostPurchaseParametersAlertProps) => {
  const { classes, theme } = useStyles();
  const openModal = useOpenModal();

  const onOpenPPPModal = () =>
    openModal('PostPurchaseParametersModal', {
      orderId,
    });

  return (
    <Alert
      color="amber"
      radius="md"
      px="xl"
      py="lg"
      icon={
        <InfoCircle width={24} height={24} color={theme.colors.orange[6]} />
      }
      classNames={{
        root: classes.root,
        wrapper: classes.wrapper,
      }}
    >
      <Group position="apart">
        <Text color="gray.9" size="md">
          Your order is missing crucial details. Please complete the required
          information to proceed with your purchase.
        </Text>

        <Button onClick={onOpenPPPModal} color="amber" variant="light">
          <Group align="center" color="gray.9" spacing="xs">
            <Note width={18} height={19} color={theme.colors.gray[9]} />

            <Text size="md" color="gray.9">
              Complete Information
            </Text>
          </Group>
        </Button>
      </Group>
    </Alert>
  );
};

export const PriceQuoteAlert = () => {
  const { classes, theme } = useStyles();
  const { toggleWidget } = useHubspotConversations();

  return (
    <Alert
      color="red"
      radius="md"
      px="xl"
      py="lg"
      icon={<InfoCircle width={24} height={24} color={theme.colors.red[6]} />}
      classNames={{
        root: classes.root,
        wrapper: classes.wrapper,
      }}
    >
      <Group position="apart">
        <Text color="gray.9" size="md">
          Couldn't generate price quote. Our support team has been notified and
          will reach out to you soon
        </Text>

        <Button
          color="red"
          onClick={toggleWidget}
          classNames={{
            root: classes.button,
            leftIcon: classes.buttonIcon,
          }}
        >
          <Group align="center" c="gray.9" spacing="xs">
            <MessageQuestion
              width={18}
              height={19}
              color={theme.colors.gray[9]}
            />

            <Text color="gray.9" size="md">
              Contact Support
            </Text>
          </Group>
        </Button>
      </Group>
    </Alert>
  );
};

const useStyles = createStyles((theme) => ({
  root: {
    border: `1px solid ${theme.colors.gray[2]}`,
    flexShrink: 0,
  },
  wrapper: {
    alignItems: 'center',
  },
  button: {
    backgroundColor: 'transparent',
  },
  buttonIcon: {
    marginRight: 5,

    svg: {
      width: 17,
      height: 17,
    },
  },
}));
