import React from 'react';

import { IconPickerProps } from '@portals/core';
import { ReactComponent as Box } from '@portals/icons/linear/box.svg';
import { ReactComponent as Brush } from '@portals/icons/linear/brush.svg';
import { ReactComponent as Car } from '@portals/icons/linear/car.svg';
import { ReactComponent as Coffee } from '@portals/icons/linear/coffee.svg';
import { ReactComponent as DollarCircle } from '@portals/icons/linear/dollar-circle.svg';
import { ReactComponent as Electricity } from '@portals/icons/linear/electricity.svg';
import { ReactComponent as Judge } from '@portals/icons/linear/judge.svg';
import { ReactComponent as Lifebuoy } from '@portals/icons/linear/lifebuoy.svg';
import { ReactComponent as Magicpen } from '@portals/icons/linear/magicpen.svg';
import { ReactComponent as MessageQuestion } from '@portals/icons/linear/message-question.svg';
import { ReactComponent as Microphone2 } from '@portals/icons/linear/microphone-2.svg';
import { ReactComponent as Setting2 } from '@portals/icons/linear/setting-2.svg';
import { ReactComponent as Star1 } from '@portals/icons/linear/star-1.svg';
import { ReactComponent as UserOctagon } from '@portals/icons/linear/user-octagon.svg';
import { ReactComponent as Wifi } from '@portals/icons/linear/wifi.svg';

export type GroupIconNames =
  | 'userOctagon'
  | 'box'
  | 'brush'
  | 'car'
  | 'coffee'
  | 'dollarCircle'
  | 'electricity'
  | 'judge'
  | 'lifebuoy'
  | 'magicpen'
  | 'messageQuestion'
  | 'microphone2'
  | 'setting2'
  | 'star'
  | 'wifi';

export const GROUP_ICONS: IconPickerProps<GroupIconNames>['icons'] = {
  userOctagon: {
    name: 'userOctagon',
    color: 'teal',
    component: <UserOctagon />,
  },
  box: {
    name: 'box',
    color: 'light_blue',
    component: <Box />,
  },
  brush: {
    name: 'brush',
    color: 'deep_orange',
    component: <Brush />,
  },
  car: {
    name: 'car',
    color: 'amber',
    component: <Car />,
  },
  coffee: {
    name: 'coffee',
    color: 'brown',
    component: <Coffee />,
  },
  dollarCircle: {
    name: 'dollarCircle',
    color: 'indigo',
    component: <DollarCircle />,
  },
  electricity: {
    name: 'electricity',
    color: 'red',
    component: <Electricity />,
  },
  judge: {
    name: 'judge',
    color: 'green',
    component: <Judge />,
  },
  lifebuoy: {
    name: 'lifebuoy',
    color: 'deep_purple',
    component: <Lifebuoy />,
  },
  magicpen: {
    name: 'magicpen',
    color: 'pink',
    component: <Magicpen />,
  },
  messageQuestion: {
    name: 'messageQuestion',
    color: 'lime',
    component: <MessageQuestion />,
  },
  microphone2: {
    name: 'microphone2',
    color: 'yellow',
    component: <Microphone2 />,
  },
  setting2: {
    name: 'setting2',
    color: 'blue',
    component: <Setting2 />,
  },
  star: {
    name: 'star',
    color: 'purple',
    component: <Star1 />,
  },
  wifi: {
    name: 'wifi',
    color: 'cyan',
    component: <Wifi />,
  },
};
