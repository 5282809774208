export enum VendorCategoryEnum {
  Manufacturer = 'manufacturer',
  CloudService = 'cloud_service',
}

export interface VendorCategoryType {
  id: VendorCategoryEnum;
  label: string;
}

export interface VendorType {
  id: string;
  name: string;
  logo: string;
  category: VendorCategoryEnum;
}

export interface VendorsGroupType {
  category: VendorCategoryEnum;
  vendors: Array<VendorType>;
}
