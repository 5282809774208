import { Group, Text } from '@mantine/core';
import React, { FC } from 'react';

import { ReactComponent as CloseSquare } from '@portals/icons/linear/close-square.svg';
import { ReactComponent as TickSquare } from '@portals/icons/linear/tick-square.svg';

interface BooleanCellProps {
  isActive: boolean;
  withLabel?: boolean;
  label?: string;
}

export const BooleanCell: FC<BooleanCellProps> = ({
  isActive,
  label,
  withLabel = true,
}) => (
  <Group
    spacing="md"
    data-testid={`boolean-cell-${isActive ? 'active' : 'inactive'}`}
    sx={{
      color: isActive ? 'green' : 'red',

      svg: {
        width: 16,
        height: 16,
      },
    }}
  >
    {isActive ? <TickSquare /> : <CloseSquare />}

    {withLabel ? (
      <Text size="sm" color="gray.7">
        {label || (isActive ? 'Yes' : 'No')}
      </Text>
    ) : null}
  </Group>
);
