import {
  Box,
  createStyles,
  Group,
  Stack,
  Text,
  Tooltip,
  TooltipProps,
} from '@mantine/core';
import * as React from 'react';
import { ReactNode, useRef, useState } from 'react';

import { useOnClickOutside } from '@portals/utils';

import { ColorSelector } from './ColorSelector';
import { OnAddCustomColorFn, WidgetColorType } from '../widgets.types';

export interface PopoverColorSelectorProps {
  selectedColor: WidgetColorType;
  onChange: (color: WidgetColorType) => void;
  children?: (params: { color: WidgetColorType; isOpen: boolean }) => ReactNode;
  onOpen?: () => void;
  onClose?: () => void;
  tooltipProps?: Partial<TooltipProps>;
  colors: Array<WidgetColorType> | undefined;
  onAddCustomColor: OnAddCustomColorFn | undefined;
  indicatorClassName?: string;
}

export function PopoverColorSelector({
  onChange,
  selectedColor,
  children,
  onOpen,
  onClose,
  tooltipProps = {},
  colors,
  onAddCustomColor,
  indicatorClassName,
}: PopoverColorSelectorProps) {
  const { classes, cx } = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const setIsOpenWrapper = (isOpen: boolean) => {
    setIsOpen(isOpen);

    if (isOpen) {
      onOpen?.();
    } else {
      onClose?.();
    }
  };

  const onChangeWrapper = (color: WidgetColorType) => {
    onChange(color);

    setIsOpenWrapper(false);
  };

  const tooltipContentRef = useRef(null);
  useOnClickOutside(
    tooltipContentRef,
    () => setIsOpenWrapper(false),
    isOpen && !isHovered
  );

  return (
    <Tooltip
      opened={isOpen}
      withinPortal
      withArrow
      arrowSize={10}
      position="bottom"
      classNames={{
        tooltip: classes.tooltip,
      }}
      label={
        <Stack
          p="xl"
          ref={tooltipContentRef}
          onMouseLeave={(e) => setIsHovered(false)}
          onMouseEnter={(e) => setIsHovered(true)}
        >
          <Group position="center">
            <Text size="lg" color="gray.8" weight="500">
              Select color
            </Text>
          </Group>

          <ColorSelector
            selectedColor={selectedColor}
            onPresetColorSelect={onChangeWrapper}
            colors={colors}
            onAddCustomColor={onAddCustomColor}
          />
        </Stack>
      }
      {...tooltipProps}
    >
      <Group
        key={isOpen ? 'open' : 'closed'}
        align="center"
        position="center"
        onClick={() => setIsOpenWrapper(!isOpen)}
        data-testid="widget-color-selector-toggle"
      >
        {children ? (
          children({ color: selectedColor, isOpen })
        ) : (
          <Box
            bg={selectedColor}
            className={cx(classes.indicator, indicatorClassName)}
          />
        )}
      </Group>
    </Tooltip>
  );
}

const useStyles = createStyles((theme) => ({
  tooltip: {
    pointerEvents: 'all',
    background: 'white',
    padding: 0,
    filter: 'drop-shadow(-4px 4px 36px rgba(0, 2, 41, 0.1))',
  },
  indicator: {
    width: 20,
    height: 20,
    borderRadius: theme.radius.sm,
    cursor: 'pointer',
  },
}));
