import { Box, Button, Group, Stack, Text } from '@mantine/core';
import lottie, { AnimationItem } from 'lottie-web';
import React, { FC, useRef } from 'react';

import { useHubspotConversations } from '@portals/framework';

import * as animation from './assets/animation.json';
import { ReactComponent as ErrorIcon } from './assets/error.svg';

export const LoadingState = () => {
  const animationRef = useRef<AnimationItem>();

  const onRef = (node: HTMLDivElement) => {
    if (!node || !!animationRef.current) return;

    animationRef.current = lottie.loadAnimation({
      container: node,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animation,
    });
  };

  return (
    <Stack align="center" justify="center" sx={{ height: 330 }}>
      <Box
        ref={onRef}
        sx={{
          height: 183,
          width: 183,

          svg: {
            width: '100%',
          },
        }}
      />

      <Text color="blue_gray" size="lg">
        Generating Price Quote....
      </Text>
    </Stack>
  );
};

interface ErrorStateProps {
  onClose: () => void;
}

export const ErrorState: FC<ErrorStateProps> = ({ onClose }) => {
  const hubspotConversations = useHubspotConversations();

  return (
    <Stack align="center" justify="center" sx={{ minHeight: 330 }} spacing="xl">
      <ErrorIcon />

      <Stack align="center" spacing="sm">
        <Text color="blue_gray" size="xl">
          Couldn't Generate Price Quote
        </Text>

        <Text color="blue_gray" size="sm">
          Our support team has been notified and will reach out to you soon
        </Text>
      </Stack>

      <Group grow position="apart" sx={{ width: '100%' }} px={32}>
        <Button
          variant="default"
          onClick={() => {
            hubspotConversations.toggleWidget();
            onClose();
          }}
        >
          Contact Support
        </Button>

        <Button onClick={onClose}>Close</Button>
      </Group>
    </Stack>
  );
};
