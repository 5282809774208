import { Button, ButtonProps } from '@mantine/core';
import React, { useCallback } from 'react';

import {
  SpaceType,
  useFeatureFlags,
  useIsCecEnabled,
} from '@portals/api/organizations';
import { useOpenModal } from '@portals/redux';

import {
  AddDeviceModalPropsV1,
  SelectModelFromPartnerModalProps,
  SelectModelModalProps,
  ClaimDeviceModalProps,
} from '../modals';

interface ClaimDeviceButtonProps extends ButtonProps {
  spaceId?: SpaceType['id'];
  partnerId?: string;
}

export function ClaimDeviceButton({
  spaceId,
  partnerId,
  children,
  ...buttonProps
}: ClaimDeviceButtonProps) {
  const openClaimDeviceModal = useOpenClaimDeviceModal();

  return (
    <Button
      onClick={() => openClaimDeviceModal({ spaceId, partnerId })}
      {...buttonProps}
    >
      {children}
    </Button>
  );
}

export function useOpenClaimDeviceModal() {
  const isCecEnabled = useIsCecEnabled();
  const openModal = useOpenModal();
  const featureFlags = useFeatureFlags();

  return useCallback(
    (params: { spaceId?: SpaceType['id']; partnerId?: string }) => {
      const { spaceId, partnerId } = params;

      if (isCecEnabled) {
        if (partnerId) {
          openModal<SelectModelFromPartnerModalProps['data']>(
            'SelectModelFromPartnerModal',
            { spaceId, partnerId }
          );
        } else {
          openModal<ClaimDeviceModalProps['data']>('ClaimDeviceModal', {
            spaceId,
          });
        }
      } else {
        if (featureFlags?.new_claiming_device_flow) {
          openModal<SelectModelModalProps['data']>('SelectModelModal', {
            spaceId,
          });
        } else {
          openModal<AddDeviceModalPropsV1['data']>('AddDeviceModalV1', {
            spaceId,
          });
        }
      }
    },
    [featureFlags?.new_claiming_device_flow, isCecEnabled, openModal]
  );
}
