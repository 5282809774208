import {
  capitalize,
  compact,
  filter,
  get,
  groupBy,
  includes,
  isEmpty,
  isUndefined,
  keys,
  map,
  size,
  sortBy,
  sum,
  toLower,
  values,
} from 'lodash/fp';

import { DeviceType } from '@portals/api/organizations';

import {
  GroupTypeEnum,
  IncidentsFilterEnum,
  SortTypeEnum,
  StatusesFilterEnum,
} from './devices-list.types';

export const filterByIncidents =
  (incidentsFilter: Array<IncidentsFilterEnum>) =>
  (devices: Array<DeviceType>) => {
    if (
      size(incidentsFilter) === 1 &&
      incidentsFilter[0] === IncidentsFilterEnum.All
    )
      return devices;

    return filter((device) => {
      const { incidents } = device;

      if (isEmpty(incidents)) return false;

      let isActive = false;

      [
        IncidentsFilterEnum.Critical,
        IncidentsFilterEnum.High,
        IncidentsFilterEnum.Moderate,
        IncidentsFilterEnum.Low,
      ].forEach((currentFilter, index) => {
        if (isActive || !includes(currentFilter, incidentsFilter)) return;

        isActive = incidents[index] > 0;
      });

      return isActive;
    }, devices);
  };

export const filterByStatus =
  (statusesFilter: Array<StatusesFilterEnum>) =>
  (devices: Array<DeviceType>) => {
    if (
      size(statusesFilter) === 1 &&
      statusesFilter[0] === StatusesFilterEnum.All
    )
      return devices;

    return filter((device) => {
      const status = capitalize(device.status);

      return includes(status, statusesFilter);
    }, devices);
  };

export const sortDevices =
  (sortByTerm: SortTypeEnum) => (devices: Array<DeviceType>) => {
    switch (sortByTerm) {
      case SortTypeEnum.Incidents:
        return sortBy((device) => -sum(values(device.incidents)), devices);

      case SortTypeEnum.Status:
        return sortBy('status', devices);

      case SortTypeEnum.Name:
      default:
        return sortBy('name', devices);
    }
  };

export const filterBySearchTerm =
  (searchTerm?: string) => (devices: Array<DeviceType>) =>
    filter((device) => {
      if (searchTerm) {
        const lowerCaseName = toLower(device.name);

        return includes(searchTerm, lowerCaseName);
      }

      return true;
    }, devices);

export const getDevicesGroupsList = (
  devices: Array<DeviceType>,
  groupByTerm: GroupTypeEnum,
  collapsedGroups: Record<string, boolean>
) => {
  let groupedDevices;

  switch (groupByTerm) {
    case GroupTypeEnum.Model:
      groupedDevices = groupBy(({ partner }) => partner.model, devices);
      break;

    case GroupTypeEnum.Status:
    default:
      groupedDevices = groupBy('status', devices);
  }

  return compact(
    map((groupName) => {
      const groupDevices = groupedDevices[groupName];

      if (!size(groupDevices)) return null;

      const isOpen =
        isUndefined(get([groupName], collapsedGroups)) ||
        get([groupName], collapsedGroups);

      return {
        devices: groupDevices,
        groupType: groupByTerm,
        groupName,
        isOpen,
      };
    }, keys(groupedDevices))
  );
};
