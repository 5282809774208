import React, { createContext, FC, ReactNode, useContext } from 'react';

import { DeviceType } from '@portals/api/organizations';

import { useDeviceSubscriptions } from './tabs/device-tabs.hooks';
import { DeviceStateType } from './tabs/device-tabs.types';

const DeviceStateContext = createContext<Array<DeviceStateType>>([]);

interface DeviceStateProviderProps {
  children: ReactNode;
  device: DeviceType;
}

export const DeviceStateProvider: FC<DeviceStateProviderProps> = ({
  children,
  device,
}) => {
  const deviceStates = useDeviceSubscriptions(device);

  return (
    <DeviceStateContext.Provider value={deviceStates}>
      {children}
    </DeviceStateContext.Provider>
  );
};

export const useDeviceStateContext = () => useContext(DeviceStateContext);
