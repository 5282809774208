import { Options } from 'intl-messageformat';
import { get } from 'lodash/fp';
import { useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';

import { Locale } from '../constants';
import { LocalizationContext } from '../context';

import type { Config } from '../types';

export const useLocalization = () => useContext(LocalizationContext);

export const useTranslation = () => {
  const { locales } = useLocalization();
  const intl = useIntl();

  return useCallback(
    (id: string, config?: Config, options?: Options) =>
      intl.formatMessage(
        { id, defaultMessage: get(id, locales[Locale.en]) },
        config,
        options
      ),
    [intl, locales]
  );
};
