import { Box, createStyles } from '@mantine/core';
import { find } from 'lodash/fp';
import React, { useMemo } from 'react';

import { TableCell } from '@portals/types';

interface TCellProps<TData extends object> {
  cell: TableCell<TData>;
}

function TCell<TData extends object>({ cell }: TCellProps<TData>) {
  const { cx, classes } = useStyles();
  const { isEditable } = cell.column;
  const cellProps = cell.getCellProps();

  return (
    <Box
      id={`cell-${cellProps.key}`}
      data-testid={`cell-${cell.column.id}`}
      className={cx('tcell', classes.container, {
        editable: isEditable,
      })}
      {...cellProps}
    >
      <Box className={cx('tcell-wrapper', classes.wrapper)}>
        {cell.render('Cell')}
      </Box>
    </Box>
  );
}

function StickyTCell<TData extends object>({ cell }: TCellProps<TData>) {
  const { isEditable } = cell.column;
  const cellProps = cell.getCellProps();

  const withOffset = useMemo(() => {
    if (cell.column.id === 'row-selection-column') return false;

    return !!find(
      ({ column }) => column?.id === 'row-selection-column',
      cell.row.cells
    );
  }, [cell.column.id, cell.row.cells]);

  const { cx, classes } = useStyles();

  return (
    <Box
      id={`cell-${cellProps.key}`}
      data-testid={`cell-${cell.column.id}`}
      className={cx('tcell sticky', classes.container, {
        editable: isEditable,
        'with-offset': withOffset,
      })}
      {...cellProps}
    >
      <Box className={cx('tcell-wrapper', classes.wrapper)}>
        {cell.render('Cell')}
      </Box>
    </Box>
  );
}

function TCellWrapper<TData extends object>({
  cell,
  ...rest
}: TCellProps<TData>) {
  if (cell.column.isSticky) {
    return <StickyTCell<TData> cell={cell} />;
  }

  if (cell.column.id === 'row-selection-column') {
    // @ts-ignore
    if (find(({ column }) => column?.isSticky, cell.row.cells)) {
      return <StickyTCell<TData> cell={cell} />;
    }
  }

  return <TCell<TData> cell={cell} />;
}

const useStyles = createStyles((theme) => ({
  container: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
    display: 'grid',
    color: theme.colors.gray[7],
    fontWeight: 400,
    padding: `0 ${theme.spacing.md}`,

    '&.with-shadow': {
      boxShadow: `8px 0px 9px ${theme.fn.rgba(theme.black, 0.07)}`,
    },

    '&.sticky': {
      height: '100%',
      position: 'sticky',
      left: 0,
      zIndex: 2,
      backgroundColor: theme.white,
      clipPath: 'inset(0px -15px 0px 0px)',

      [theme.fn.smallerThan('md')]: {
        position: 'static',
      },

      '&.with-offset': {
        left: 60,
      },
    },

    '&.editable': {
      padding: 0,

      '.tcell-wrapper': {
        height: '100%',
      },
    },
  },
  wrapper: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

export default TCellWrapper;
