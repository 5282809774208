import {
  ActionIcon,
  Badge,
  createStyles,
  Divider,
  Group,
  Text,
} from '@mantine/core';
import { isEmpty, size } from 'lodash/fp';
import React from 'react';

import { ReactComponent as CloseX } from '@portals/icons/linear/close-x.svg';
import { SmartTableProps } from '@portals/types';

import { Export } from './Export';
import { GlobalFilter } from './GlobalFilter';
import { useTableInstance } from '../../context';
import { ColumnsSettings } from '../ColumnsSettings';

type HeaderProps<TData extends object, TKeyField extends keyof TData> = Pick<
  SmartTableProps<TData, TKeyField>,
  | 'name'
  | 'exportParams'
  | 'noColumnsSelection'
  | 'additionalActions'
  | 'selectedItemsActions'
  | 'withGlobalSearch'
  | 'isCompact'
>;

export function Header<TData extends object, TKeyField extends keyof TData>({
  additionalActions,
  selectedItemsActions,
  name,
  exportParams,
  noColumnsSelection = false,
  withGlobalSearch = false,
  isCompact = false,
}: HeaderProps<TData, TKeyField>) {
  const instance = useTableInstance<TData>();
  const { allColumns, toggleHideColumn } = instance;
  const { classes, theme } = useStyles();

  if (isCompact && (additionalActions || selectedItemsActions))
    return (
      <Group position="apart" sx={{ height: 35 }}>
        {selectedItemsActions ? (
          <Group>
            {isEmpty(instance.state.selectedRowIds) ? null : (
              <>
                <Badge
                  size="lg"
                  rightSection={
                    <ActionIcon
                      data-testid="table-clear-selection-button"
                      color="blue_accent"
                      sx={{ minWidth: 'unset', width: 'auto' }}
                      onClick={() => instance.toggleAllRowsSelected(false)}
                    >
                      <CloseX
                        color={theme.colors.gray[9]}
                        width={14}
                        height={14}
                      />
                    </ActionIcon>
                  }
                  className={classes.headerBadge}
                >
                  <Text size="xs" weight={400}>
                    {size(instance.state.selectedRowIds)} Selected
                  </Text>
                </Badge>

                <Group>
                  <Divider sx={{ height: '21px' }} orientation="vertical" />
                </Group>

                {selectedItemsActions ? (
                  <Group spacing="xs">{selectedItemsActions?.()}</Group>
                ) : null}
              </>
            )}
          </Group>
        ) : null}

        {additionalActions ? (
          <Group position="right" align="center">
            {additionalActions?.()}
          </Group>
        ) : null}
      </Group>
    );

  return (
    <Group position="apart" align="center" sx={{ height: 35 }}>
      <Group>
        {withGlobalSearch ? <GlobalFilter /> : null}

        {isEmpty(instance.state.selectedRowIds) ? null : (
          <>
            <Badge
              size="lg"
              rightSection={
                <ActionIcon
                  color="blue_accent"
                  sx={{ minWidth: 'unset', width: 'auto' }}
                  onClick={() => instance.toggleAllRowsSelected(false)}
                >
                  <CloseX color={theme.colors.gray[9]} width={14} height={14} />
                </ActionIcon>
              }
              className={classes.headerBadge}
            >
              <Text size="xs" weight={400}>
                {size(instance.state.selectedRowIds)} Selected
              </Text>
            </Badge>

            <Group>
              <Divider sx={{ height: '21px' }} orientation="vertical" />
            </Group>

            <Group spacing="xs">{selectedItemsActions?.()}</Group>
          </>
        )}
      </Group>

      <Group spacing="md" align="center">
        {additionalActions ? additionalActions?.() : null}

        {!exportParams.isEnabled && noColumnsSelection ? null : (
          <Group>
            <Divider sx={{ height: '21px' }} orientation="vertical" />
          </Group>
        )}

        {exportParams.isEnabled ? (
          <Export
            remoteUrl={exportParams.remoteUrl}
            fileName={exportParams.fileName}
            instance={instance}
            name={name}
          />
        ) : null}

        {noColumnsSelection ? null : (
          <ColumnsSettings
            columns={allColumns}
            onColumnToggle={toggleHideColumn}
          />
        )}
      </Group>
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  headerBadge: {
    textTransform: 'none',
    lineHeight: '14px',
    background: theme.colors.gray[1],
  },
}));
