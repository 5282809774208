import {
  CurrencyCode,
  PaymentIntervalEnum,
  ProductPricingModel,
  ProductTypeEnum,
} from '@portals/types';

import { formatPrice } from './numbers';

export function getPricingModelDisplayName(pricingModel: ProductPricingModel) {
  switch (pricingModel) {
    case ProductPricingModel.Standard:
      return 'Standard';
    case ProductPricingModel.UsageBased:
      return 'Usage based';
    case ProductPricingModel.Personalized:
      return 'Personalized';
  }
}

export function getProductPriceDisplayText(params: {
  pricingModel: ProductPricingModel;
  usageBasedPriceInScu: number | null | undefined;
  usageBasedUnitName: string | null;
  amountInScu: number;
  paymentInterval: PaymentIntervalEnum;
  currency: CurrencyCode;
}) {
  if (params.pricingModel === ProductPricingModel.UsageBased) {
    return `${formatPrice({
      value: params.usageBasedPriceInScu,
      currencyCode: params.currency,
    })} / ${params.usageBasedUnitName}`;
  }

  let paymentIntervalText = '';

  if (params.paymentInterval === PaymentIntervalEnum.Monthly) {
    paymentIntervalText = ' / month';
  } else if (params.paymentInterval === PaymentIntervalEnum.Yearly) {
    paymentIntervalText = ' / year';
  }

  const priceText = formatPrice({
    value: params.amountInScu,
    currencyCode: params.currency,
  });

  return `${priceText}${paymentIntervalText}`;
}

export function getProductTypeDisplayName(productType: ProductTypeEnum) {
  switch (productType) {
    case ProductTypeEnum.Physical:
      return 'Physical';
    case ProductTypeEnum.DeviceLicense:
      return 'Device license';
    case ProductTypeEnum.PlatformLicense:
      return 'Platform License';
    default:
      return '';
  }
}
