import React, { useCallback, useContext } from 'react';
import { useUpdateEffect } from 'react-use';
import { Button } from 'reactstrap';
import styled from 'styled-components';

import { IntegrationState } from '@portals/types';

import { IntegrationsContext } from './Integrations';

const EditC2CIntegration = ({ id }) => {
  const { integrations, addIntegration } = useContext(IntegrationsContext);
  const { params = {}, state } = integrations[id] || {};
  const { remote_url } = params;

  const navigateToIntegrationDashboard = useCallback(() => {
    window.open(remote_url, '_blank', 'noreferrer');
  }, [remote_url]);

  useUpdateEffect(
    function connectIntegration() {
      if (remote_url) {
        navigateToIntegrationDashboard();
      }
    },
    [navigateToIntegrationDashboard]
  );

  return (
    <Container>
      {remote_url ? (
        <>
          <h2>Integration actions</h2>
          <Actions>
            <Button onClick={navigateToIntegrationDashboard}>
              {state === IntegrationState.Unauthenticated ? 'Connect' : 'Edit'}
            </Button>
          </Actions>
        </>
      ) : (
        <div>
          <p>Add a new MS Teams integration</p>
          <Button color="primary" onClick={() => addIntegration(id)}>
            Add
          </Button>
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: 1rem;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 150px;
  margin: 20px 0 0 0;
`;

export default EditC2CIntegration;
