import { createStyles, Group, Stack, StackProps, Text } from '@mantine/core';
import { isUndefined } from 'lodash/fp';
import React, { useCallback } from 'react';

import { FORMATTERS } from '../../../utils/formatters';
import { OfflineDeviceStateTooltip } from '../../common/OfflineDeviceStateTooltip';
import { NumberFormatType } from '../../widgets.types';

export interface NumericMetricWidgetProps {
  title: string;
  value: number;
  unit?: string | null;
  numberFormat?: NumberFormatType;
  numberOfDecimals?: number;

  stackProps?: StackProps;

  isDeviceOffline?: boolean;
  lastUpdateTimestamp?: string;
}

export function NumericMetricWidget({
  title,
  value,
  unit,
  numberFormat = 'none',
  numberOfDecimals = 0,

  stackProps = {},

  isDeviceOffline,
  lastUpdateTimestamp,
}: NumericMetricWidgetProps) {
  const { classes } = useStyles();

  const formatter = useCallback(
    (value: number | undefined) => {
      if (isUndefined(value)) return 'N/A';

      const adjustedValue = FORMATTERS[numberFormat](value, numberOfDecimals);

      if (isUndefined(adjustedValue)) return 'N/A';

      if (unit) {
        return `${adjustedValue} ${unit}`;
      }

      return adjustedValue;
    },
    [numberFormat, numberOfDecimals, unit]
  );

  return (
    <Stack
      className={classes.container}
      p="xl"
      h="100%"
      w="100%"
      bg="white"
      justify="center"
      spacing={4}
      {...stackProps}
    >
      <Group noWrap align="center" spacing="sm">
        <Text
          size="md"
          data-testid="dashboard-numeric-widget-name"
          color="gray.5"
          truncate
        >
          {title}
        </Text>

        {isDeviceOffline ? (
          <OfflineDeviceStateTooltip
            lastUpdateTimestamp={lastUpdateTimestamp}
          />
        ) : null}
      </Group>

      <Text
        size={28}
        data-testid="track-measurement-unit-preview"
        color="gray.8"
        truncate
      >
        {formatter(value)}
      </Text>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    borderRadius: theme.radius.lg,
  },
}));
