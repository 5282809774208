import {
  BackgroundImage,
  Center,
  createStyles,
  LoadingOverlay,
  Tooltip,
} from '@mantine/core';
import React, { useCallback } from 'react';

import {
  useOrganizationConfig,
  useUpdateConfiguration,
} from '@portals/api/organizations';
import { usePermissionAccess } from '@portals/framework';
import { UploadImageModalProps } from '@portals/framework/modals';
import { ReactComponent as Edit3 } from '@portals/icons/linear/edit-3.svg';
import { useOpenModal } from '@portals/redux';

export function OrganizationAvatar() {
  const { isAdmin } = usePermissionAccess();
  const { classes } = useStyles();
  const organizationConfig = useOrganizationConfig();
  const openModal = useOpenModal();
  const updateLogo = useUpdateConfiguration();

  const onUpdate = useCallback(
    (img: string) => {
      updateLogo.mutate({
        updatedConfig: { logo_url: img },
        withSuccessNotification: true,
      });
    },
    [updateLogo]
  );

  const onUpload = useCallback(() => {
    if (!isAdmin) return;

    openModal<UploadImageModalProps['data']>('UploadImageModal', {
      onUpload: onUpdate,
      cropConfig: {
        aspectRatio: 1,
        height: 100,
        width: 100,
      },
    });
  }, [isAdmin, onUpdate, openModal]);

  return (
    <>
      <LoadingOverlay visible={updateLogo.isLoading} overlayBlur={2} />

      <Tooltip
        label="Update logo"
        position="bottom"
        withArrow
        events={{ hover: isAdmin, focus: false, touch: false }}
      >
        <Center
          w="100%"
          h="100%"
          className={classes.container}
          onClick={onUpload}
        >
          <BackgroundImage
            w="100%"
            h="100%"
            src={
              organizationConfig?.logo_url ||
              organizationConfig?.design?.navigation_menu?.logo ||
              'logo_full_dark.svg'
            }
            className="logo"
          >
            {isAdmin ? (
              <Center w="100%" h="100%" className="is-editable">
                <Edit3 />
              </Center>
            ) : null}
          </BackgroundImage>
        </Center>
      </Tooltip>
    </>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    '.logo': {
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      border: `1px solid ${theme.colors.gray[1]}`,
      borderRadius: theme.radius.lg,
    },

    '.is-editable': {
      height: '100%',
      width: '100%',
      opacity: 0,
      color: theme.white,
      borderRadius: theme.radius.lg,

      '&:hover': {
        cursor: 'pointer',
        opacity: 1,
        backgroundColor: theme.fn.rgba(theme.colors.gray[9], 0.5),
      },
    },
  },
}));
