import {
  Button,
  createStyles,
  Group,
  Overlay,
  Stack,
  Text,
} from '@mantine/core';
import React, { ReactNode } from 'react';
import { Spinner } from 'reactstrap';

import { NoDataIndicationType } from '@portals/types';

import noDataSrc from '../assets/no-data.svg';
import noFiltersSrc from '../assets/no-filters-results.svg';
import noSearchResultsSrc from '../assets/no-search-results.svg';
import { useTableInstance } from '../context';

function DataStateContainer({ children }: { children: ReactNode }) {
  const { classes, cx } = useDataStateContainerStyles();
  return (
    <Stack className={cx('data-state-container', classes.container)}>
      {children}
    </Stack>
  );
}

const useDataStateContainerStyles = createStyles((theme) => ({
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export function NoDataState({
  assetSrc = noDataSrc,
  title = 'No data',
  subtitle,
  actions = null,
}: NoDataIndicationType) {
  return (
    <DataStateContainer>
      <img src={assetSrc} />

      <Text size="sm" weight={600} align="center" data-testid="no-data-title">
        {title}
      </Text>

      {subtitle ? (
        <Text size="xs" align="center" color="gray.5">
          {subtitle}
        </Text>
      ) : null}

      {actions}
    </DataStateContainer>
  );
}

export function NoSearchResultsState() {
  const { state } = useTableInstance();

  return (
    <DataStateContainer>
      <img src={noSearchResultsSrc} />

      <Text
        size="sm"
        weight={600}
        align="center"
        data-testid="no-results-found-text"
      >
        No results found
      </Text>

      <Stack spacing={0}>
        <Group spacing={5} position="center">
          <Text size="xs" color="dimmed" align="center">
            We didn't find any matches for
          </Text>
          <Text
            weight={600}
            size="xs"
            color="dark"
            align="center"
            sx={{
              wordBreak: 'break-word',
            }}
          >
            "{state.globalFilter}"
          </Text>
        </Group>
        <Text size="xs" color="dimmed" align="center">
          Try using different keywords
        </Text>
      </Stack>
    </DataStateContainer>
  );
}

export function LoadingState() {
  return (
    <DataStateContainer>
      <Overlay>
        <Spinner color="primary" />
      </Overlay>
    </DataStateContainer>
  );
}

export function NoFiltersResultsState() {
  const instance = useTableInstance();

  return (
    <DataStateContainer>
      <img src={noFiltersSrc} />

      <Text
        size="sm"
        data-testid="empty-filter-results"
        weight={600}
        align="center"
      >
        Your filters produced no results
      </Text>

      <Text size="xs" color="dimmed" align="center">
        Try adjusting or clearing your filters to display better results
      </Text>

      <Button
        variant="subtle"
        size="xs"
        onClick={() => instance.setAllFilters([])}
      >
        Clear filters
      </Button>
    </DataStateContainer>
  );
}
