import {
  Box,
  Button,
  ButtonProps,
  createStyles,
  HoverCard,
  HoverCardProps,
  Paper,
  Stack,
  Text,
} from '@mantine/core';
import React from 'react';

import { RelatedDevice } from '@portals/api/organizations';
import { DeviceAvatarWithIndicator } from '@portals/framework';
import { useOpenRouteModal } from '@portals/framework/route-modals';
import { DeviceStatusType } from '@portals/types';

function getChildDeviceMarkerColor(status: DeviceStatusType | undefined) {
  switch (status) {
    case 'offline':
    case 'unavailable':
      return 'gray.5';
    case 'error':
      return 'red.4';
    case 'online':
    default:
      return 'blue_accent.4';
  }
}

export interface ChildDeviceLocationMarkerProps {
  title: string;
  status: DeviceStatusType | undefined;

  lat?: number;
  lng?: number;

  device: RelatedDevice;
}

export function ChildDeviceLocationMarker({
  title,
  status,
  device,
}: ChildDeviceLocationMarkerProps) {
  const { classes } = useStyles();
  const openRouteModal = useOpenRouteModal();

  return (
    <HoverCard
      styles={hoverCardStyles}
      withArrow
      withinPortal
      arrowSize={20}
      arrowPosition="center"
      position="top"
    >
      <HoverCard.Target>
        <Box className={classes.childWrapper}>
          <Box
            className={classes.child}
            bg={getChildDeviceMarkerColor(status)}
          />
        </Box>
      </HoverCard.Target>

      <HoverCard.Dropdown>
        <Paper>
          <Stack
            mih={130}
            miw={130}
            align="center"
            justify="center"
            p="xl"
            spacing="xl"
          >
            <DeviceAvatarWithIndicator
              deviceName={title}
              deviceStatus={status}
              size="lg"
              icon={device.model_settings.icon}
              src={device.image_url}
            />

            <Stack align="center">
              <Text size="xs" color="blue_gray.9" weight="bold">
                {device.name}
              </Text>

              <Text size="xs" color="gray.5">
                {device.device_model_name}
              </Text>
            </Stack>

            <Button
              size="sm"
              fullWidth
              variant="default"
              styles={buttonStyles}
              onClick={() =>
                openRouteModal({
                  modalId: 'device',
                  pathParams: [device.id],
                })
              }
            >
              Show device
            </Button>
          </Stack>
        </Paper>
      </HoverCard.Dropdown>
    </HoverCard>
  );
}

const useStyles = createStyles((theme) => ({
  childWrapper: {
    cursor: 'default',
    width: 18,
    height: 18,
    backgroundColor: 'white',
    borderRadius: '50%',
    boxShadow: theme.shadows.md,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.colors.blue_gray[1],
    position: 'relative',
    zIndex: 999,
  },
  child: {
    width: 12,
    height: 12,
    borderRadius: '50%',
  },
}));

const hoverCardStyles: HoverCardProps['styles'] = (theme) => ({
  dropdown: {
    backgroundColor: theme.white,
    borderRadius: theme.radius.lg,
    boxShadow: '0px 11px 23.9px 0px rgba(0, 0, 0, 0.19)',
  },
});

const buttonStyles: ButtonProps['styles'] = (theme) => ({
  root: {
    '&:hover': {
      color: theme.black,
      textDecoration: 'none',
    },
  },
});
