import { Box, createStyles, Stack, Text } from '@mantine/core';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { QuantityInput } from '@portals/core';
import {
  OrganizationStoreListing,
  PaymentIntervalEnum,
  ProductPricingModel,
} from '@portals/types';
import {
  formatCurrency,
  formatPrice,
  getProductPriceDisplayText,
} from '@portals/utils';

import { setProductQuantity } from '../../../../redux/actions/store';
import { useShopCurrency } from '../../../../redux/hooks/store';
import placeholderSrc from '../../store/placeholder.svg';

interface OrderRowProps {
  storeListing: OrganizationStoreListing;
  quantity: number;
  period: PaymentIntervalEnum;
}

export function OrderRow({ storeListing, quantity, period }: OrderRowProps) {
  const { product, id } = storeListing;
  const { classes } = useStyles();

  const dispatch = useDispatch();
  const currency = useShopCurrency();

  const onQuantityChange = (quantity: number) => {
    dispatch(
      setProductQuantity({
        quantity,
        id,
        period,
      })
    );
  };

  const price = useMemo(() => {
    const currentCurrencyPrices = storeListing.prices.find(
      (price) => price.currency === currency.selected
    );

    if (!currentCurrencyPrices) return null;

    if (storeListing.product.pricing_model === ProductPricingModel.UsageBased) {
      const usageBasedPriceOption = currentCurrencyPrices.pricing_options?.find(
        (option) => option.type === PaymentIntervalEnum.UsageBased
      );
      const basePriceOption = currentCurrencyPrices.pricing_options?.find(
        (option) => option.type === PaymentIntervalEnum.Monthly
      );

      if (!usageBasedPriceOption) return null;

      const usageBasedPriceText = getProductPriceDisplayText({
        currency: currentCurrencyPrices.currency,
        usageBasedPriceInScu: usageBasedPriceOption.amount,
        pricingModel: storeListing.product.pricing_model,
        paymentInterval: PaymentIntervalEnum.UsageBased,
        amountInScu: usageBasedPriceOption.amount,
        usageBasedUnitName: storeListing.product.usage_based_unit_name,
      });

      const basedPriceText = basePriceOption
        ? formatPrice({
            value: basePriceOption.amount * quantity,
            currencyCode: currentCurrencyPrices.currency,
          })
        : undefined;

      return (
        <Stack spacing="xs" ta="end">
          <Text className={classes.price} mr="xs">
            {usageBasedPriceText}
          </Text>
          {basedPriceText && (
            <Text className={classes.price} mr="xs">
              {basedPriceText} / month
            </Text>
          )}
        </Stack>
      );
    }

    const currentPriceOption = currentCurrencyPrices?.pricing_options?.find?.(
      (option) => option.type === period
    );

    if (!currentPriceOption?.amount) return null;

    const adjustedAmount = currentPriceOption?.amount * quantity;

    if (period === PaymentIntervalEnum.OneTime) {
      return (
        <Text className={classes.price} mr="xs">
          {formatCurrency(adjustedAmount, currency.selected)}
        </Text>
      );
    } else if (period === PaymentIntervalEnum.Monthly) {
      return (
        <Text className={classes.price}>
          {`${formatCurrency(adjustedAmount, currency.selected)} / month`}
        </Text>
      );
    } else if (period === PaymentIntervalEnum.Yearly) {
      return (
        <Text className={classes.price}>
          {`${formatCurrency(adjustedAmount, currency.selected)} / year`}
        </Text>
      );
    } else {
      return null;
    }
  }, [
    classes.price,
    currency.selected,
    period,
    quantity,
    storeListing.prices,
    storeListing.product.pricing_model,
    storeListing.product.usage_based_unit_name,
  ]);

  return (
    <Box className={classes.container}>
      <Box className={classes.imageWrapper}>
        <img src={product.image_url || placeholderSrc} />
      </Box>

      <Stack spacing={5} justify="center">
        <Text sx={{ wordBreak: 'break-word' }}>{product.name}</Text>
        <QuantityInput min={0} value={quantity} onChange={onQuantityChange} />
      </Stack>

      <Stack align="end" spacing="xs" justify="center">
        {price}
      </Stack>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'max-content 1fr max-content max-content',
    columnGap: theme.spacing.md,
    padding: `${theme.spacing.md} 0`,

    '&:not(:last-of-type)': {
      borderBottom: `1px solid ${theme.colors.gray[2]}`,
    },
  },
  productName: {
    fontSize: theme.fontSizes.sm,
    color: theme.colors.blue_gray[9],
    fontWeight: 500,
  },
  imageWrapper: {
    borderRadius: theme.radius.md,
    overflow: 'hidden',
    border: `1px solid ${theme.colors.gray[1]}`,
    width: 64,
    height: 64,
    alignSelf: 'start',

    img: {
      width: '100%',
      height: '100%',
    },
  },
  price: {
    color: theme.colors.blue_gray[9],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,
  },
}));
