import { Descope } from '@descope/react-sdk';
import { captureMessage, withScope } from '@sentry/react';
import React from 'react';

const ERROR_TYPES = [
  'PasswordSignInFailed',
  'LoggedInFailed',
  'OAuthExchangeCodeFailed',
];

export const descopeErrorTransformer: React.ComponentProps<
  typeof Descope
>['errorTransformer'] = (error) => {
  if (ERROR_TYPES.includes(error.type)) {
    return `${error.text}. If you signed up previously and have trouble signing in, please contact support@xyte.io`;
  } else {
    return error.text;
  }
};

export function sendDescopeErrorReport(state?: any, componentName?: string) {
  if (!state || !state?.error) return;

  const error = state.error;

  withScope((scope) => {
    scope.setContext('authentication', {
      email: state?.screen?.state?.form?.email || 'NO_EMAIL',
      errorText: state?.screen?.state?.errorText || 'NO_ERROR_TEXT',
      errorType: state?.screen?.state?.errorType || 'NO_ERROR_TYPE',
      ...(componentName ? { componentName } : {}),
    });

    const errorCode = error.code;
    const errorMessage = error.message;
    const errorDescription = error.description;

    scope.setFingerprint([
      'descope_authentication',
      errorCode || 'NO_CODE',
      errorMessage || 'NO_MESSAGE',
    ]);

    captureMessage(
      `Descope error | Message: ${errorMessage} | Description: ${errorDescription} | Code: ${errorCode}`
    );
  });
}

export const captureDescopeError: React.ComponentProps<
  typeof Descope
>['onError'] = (error) => {
  captureMessage(
    `Descope error | Message: ${error?.detail?.errorMessage} | Description: ${error?.detail?.errorDescription} | Code: ${error?.detail?.errorCode}`
  );
};
