import { Drawer, DrawerProps } from '@mantine/core';
import React, { ReactNode, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useRoutePanelConfig } from './route-panels.hooks';
import { getBackgroundPath, RouteOverlaysProvider } from '../route-overlays';

interface RoutePanelProps
  extends Omit<DrawerProps, 'opened' | 'onClose' | 'children'> {
  panelId: string;
  children:
    | (({ onClose }: { onClose: () => void }) => React.ReactNode)
    | ReactNode;
}

export function RoutePanel({
  children,
  panelId,
  ...drawerProps
}: RoutePanelProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const defaultBackgroundPath = useRoutePanelConfig(panelId).backgroundPath;

  const onClose = useCallback(() => {
    const backgroundPath = getBackgroundPath(location, defaultBackgroundPath);

    navigate(backgroundPath);
  }, [defaultBackgroundPath, location, navigate]);

  return (
    <RouteOverlaysProvider onClose={onClose}>
      <Drawer
        opened
        styles={drawerStyles}
        onClose={onClose}
        position="right"
        padding={0}
        withCloseButton={false}
        {...drawerProps}
      >
        {typeof children === 'function' ? children({ onClose }) : children}
      </Drawer>
    </RouteOverlaysProvider>
  );
}

const drawerStyles: DrawerProps['styles'] = () => ({
  body: {
    height: '100%',
  },
  content: {
    overflow: 'unset !important',
  },
});
