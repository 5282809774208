import {
  Box,
  Button,
  createStyles,
  Group,
  LoadingOverlay,
  Modal,
  Space,
  Stack,
} from '@mantine/core';
import { useListState } from '@mantine/hooks';
import {
  any,
  findIndex,
  isEmpty,
  isEqual,
  isNumber,
  map,
  reduce,
} from 'lodash/fp';
import React, { useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useUpdateEffect } from 'react-use';

import {
  PostPurchaseParametersType,
  useLockPostPurchaseParameters,
  usePostPurchasedParameters,
  useUpdatePostPurchaseParameters,
} from '@portals/api/organizations';
import { ModalProps, useConfirmationModal } from '@portals/framework';
import { VerticalScrollBar } from '@portals/scrollbar';

import {
  getIsMissingRequiredFields,
  getWasEdited,
} from './post-purchase-parameters.utils';
import { PostPurchaseParametersForm } from './PostPurchaseParametersForm';
import { PostPurchaseParametersProductsList } from './PostPurchaseParametersProductsList';

export interface PostPurchaseParametersModalProps
  extends ModalProps<{
    orderId: string;
  }> {}

export function PostPurchaseParametersModal({
  closeMe,
  data,
}: PostPurchaseParametersModalProps) {
  const { orderId } = data;
  const { classes } = useStyles();

  const [selectedProductIndex, setSelectedProductIndex] = useState(0);
  const [withErrors, setWithErrors] = useState(false);

  const asyncConfirmationCheck = useConfirmationModal();
  const updatePostPurchaseParams = useUpdatePostPurchaseParameters();
  const lockPostPurchaseParams = useLockPostPurchaseParameters();

  const postPurchaseParams = usePostPurchasedParameters({
    orderId,
    queryOptions: {
      staleTime: 0,
      onSuccess: (response) => {
        localPPPHandler.setState(response);

        if (response?.length) {
          const wasEdited = any(Boolean, map(getWasEdited, response));

          if (!wasEdited) {
            setSelectedProductIndex(0);
          } else {
            const productWithMissingParamsIndex = findIndex(
              (postPurchaseParams) =>
                getIsMissingRequiredFields(postPurchaseParams),
              response
            );

            if (productWithMissingParamsIndex !== -1) {
              setSelectedProductIndex(productWithMissingParamsIndex);
            }
          }
        }
      },
    },
  });

  const [localPPP, localPPPHandler] =
    useListState<PostPurchaseParametersType>(null);

  const scrollRef = useRef<Scrollbars>(null);
  useUpdateEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollToTop();
    }
  }, [selectedProductIndex]);

  const onChange = (
    index: number,
    fieldKey: string,
    value: string | number
  ) => {
    const currentPPP = localPPP?.[index]?.post_purchase_parameters;
    const currentValues = currentPPP?.values || {};
    const currentFieldDefinition = currentPPP.definition.find(
      ({ label }) => fieldKey === label
    );

    let updatedValues = { ...currentValues };

    if (!isNumber(value) && !value && currentValues[fieldKey]) {
      delete updatedValues[fieldKey];
    } else if (!isNumber(value) && !value) {
      return;
    } else {
      updatedValues = {
        ...currentValues,
        [fieldKey]: {
          ...(currentValues[fieldKey] || {}),
          value,
          type: currentFieldDefinition.type,
        },
      };
    }

    localPPPHandler.setItemProp(index, 'post_purchase_parameters', {
      ...currentPPP,
      values: updatedValues,
    });
  };

  const onClose = async () => {
    if (isEqual(localPPP, postPurchaseParams.data)) return closeMe();

    const isConfirmed = await asyncConfirmationCheck({
      description: 'All unsaved changes will be lost',
      title: 'Are you sure?',
    });

    if (!isConfirmed) return;

    closeMe();
  };

  const onSave = async () => {
    const adjustedPPP = reduce(
      (acc, curr) => {
        const { values } = curr.post_purchase_parameters || {};

        if (values) {
          acc[curr.id] = values;
        }

        return acc;
      },
      {},
      localPPP
    );

    return updatePostPurchaseParams.mutateAsync({
      orderId: data.orderId,
      postPurchaseParameters: adjustedPPP,
    });
  };

  const onSaveForLater = async () => {
    try {
      await onSave();

      closeMe();
    } catch (error) {
      console.error(error);
    }
  };

  const onLock = async () => {
    const productWithMissingParamsIndex = findIndex(
      (postPurchaseParams) => getIsMissingRequiredFields(postPurchaseParams),
      localPPP
    );

    if (productWithMissingParamsIndex !== -1) {
      setSelectedProductIndex(productWithMissingParamsIndex);

      setWithErrors(true);
    } else {
      try {
        await onSave();

        await lockPostPurchaseParams.mutateAsync({
          orderId: data.orderId,
        });

        closeMe();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const onSelectedProductIndexChange = (selectedIndex: number) => {
    setSelectedProductIndex(selectedIndex);
    setWithErrors(false);
  };

  return (
    <Modal
      opened
      size="sm"
      title="Complete Your Purchase: Provide Additional Information"
      closeOnEscape={false}
      onClose={onClose}
      padding={0}
      classNames={{
        content: classes.modalContent,
        header: classes.header,
        body: classes.body,
      }}
    >
      <LoadingOverlay
        visible={
          postPurchaseParams.isLoading || updatePostPurchaseParams.isLoading
        }
      />

      {postPurchaseParams.isFetched ? (
        <Box className={classes.wrapper}>
          <Box className={classes.content}>
            <Stack className={classes.productsList} spacing="xs">
              <Space h={2} />

              <PostPurchaseParametersProductsList
                selectedProductIndex={selectedProductIndex}
                setSelectedProductIndex={onSelectedProductIndexChange}
                postPurchaseParams={localPPP}
              />

              <Space h={2} />
            </Stack>

            <VerticalScrollBar
              innerRef={scrollRef}
              renderView={(props) => (
                <Stack
                  {...props}
                  className={classes.formScrollContainer}
                  sx={{ overflowX: 'hidden !important' }}
                />
              )}
            >
              {!isEmpty(localPPP) ? (
                <PostPurchaseParametersForm
                  key={selectedProductIndex}
                  localPostPurchaseParams={localPPP?.[selectedProductIndex]}
                  onChange={(key: string, value: string | number | null) =>
                    onChange(selectedProductIndex, key, value)
                  }
                  withErrors={withErrors}
                />
              ) : (
                <LoadingOverlay visible />
              )}
            </VerticalScrollBar>
          </Box>

          <Group p="xl" position="right" className={classes.footer}>
            <Button variant="default" onClick={onSaveForLater}>
              Save For Later
            </Button>

            <Button onClick={onLock}>Complete Purchase</Button>
          </Group>
        </Box>
      ) : null}
    </Modal>
  );
}

const useStyles = createStyles((theme) => ({
  modalContent: {
    minWidth: 946,
    height: 701,
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
  },
  header: {
    padding: theme.spacing.md,
  },
  body: {
    height: '100%',
    borderTop: `1px solid ${theme.colors.gray[2]}`,
    overflow: 'hidden',
  },
  wrapper: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: '1fr 88px',
  },
  content: {
    display: 'grid',
    height: '100%',
    gridTemplateColumns: '381px 1fr',
  },
  footer: {
    borderTop: `1px solid ${theme.colors.gray[2]}`,
  },
  productsList: {
    height: '100%',
    borderRight: `1px solid ${theme.colors.gray[2]}`,
    paddingInline: theme.spacing.md,
  },
  formScrollContainer: {
    marginBottom: '0 !important',
    padding: theme.spacing.xl,
  },
}));
