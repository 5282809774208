import {
  Badge,
  Box,
  Group,
  Indicator,
  Text,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import React from 'react';

import {
  IncidentsCountType,
  useIncidentsCount,
} from '@portals/api/organizations';
import { INCIDENT_PRIORITY_LEVEL } from '@portals/utils';

function getColor(
  highestPriority: IncidentsCountType['highest_priority'] | undefined
) {
  return highestPriority
    ? INCIDENT_PRIORITY_LEVEL[highestPriority].color
    : 'gray';
}

export function IncidentsCounter() {
  const theme = useMantineTheme();
  const incidentsCount = useIncidentsCount();

  return incidentsCount.isFetched && incidentsCount.data?.total_count ? (
    <Tooltip
      label={incidentsCount.data?.tooltip_messages?.join(', ')}
      withinPortal
    >
      <Group sx={{ height: '100%' }} align="center">
        <Badge
          color={theme.fn.rgba(
            `${getColor(incidentsCount.data?.highest_priority)}.1`,
            0.8
          )}
          radius="xl"
          size="sm"
          px="xs"
          variant="filled"
        >
          {incidentsCount.data?.total_count}
        </Badge>
      </Group>
    </Tooltip>
  ) : null;
}

export function IncidentsIndicator({
  children,
}: {
  children?: React.ReactNode;
}) {
  const incidentsCount = useIncidentsCount();

  return (
    <Indicator
      disabled={!incidentsCount.isFetched || !incidentsCount.data?.total_count}
      label={
        <Box>
          <Text size="10px" color="gray.8">
            {incidentsCount.data?.total_count}
          </Text>
        </Box>
      }
      color={`${getColor(incidentsCount.data?.highest_priority)}.1`}
      inline
      size={24}
      styles={{
        indicator: {
          right: '-13px !important',
        },
      }}
    >
      {children}
    </Indicator>
  );
}
