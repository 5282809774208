import { Group, Text } from '@mantine/core';
import { takeRight, toString } from 'lodash/fp';
import React from 'react';

import { CreditCardLogo } from '@portals/framework';
import { CreditCardType } from '@portals/types';

const getExpirationText = (expMonth: number, expYear: number) => {
  const month = expMonth > 9 ? expMonth : `0${expMonth}`;
  const year = takeRight(2, toString(expYear)).join('');

  return `${month}/${year}`;
};

interface CardDetailsProps {
  card: CreditCardType;
  withLogo?: boolean;
  withBrand?: boolean;
}

export function CardDetails({
  card,
  withLogo = true,
  withBrand = true,
}: CardDetailsProps) {
  return (
    <Group>
      <Group spacing="xs">
        {withLogo ? <CreditCardLogo brand={card.brand} /> : null}
        <Text data-testid="card-brand-and-last-4-digits">
          {withBrand && card.brand} •••• {card.last_4_digits}
        </Text>
      </Group>

      <Text>{getExpirationText(card.exp_month, card.exp_year)}</Text>
    </Group>
  );
}
