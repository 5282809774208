import { createStyles, Paper, PaperProps, Stack, Text } from '@mantine/core';
import React, { ReactNode } from 'react';

export interface KpiWidgetType {
  title: string;
  label: ReactNode;
}

interface KPIWidgetProps {
  kpiWidget: KpiWidgetType;
  paperProps?: Partial<PaperProps>;
}

export function KpiWidget({ kpiWidget, paperProps = {} }: KPIWidgetProps) {
  const { classes } = useStyles();
  return (
    <Paper p={32} radius={20} {...paperProps}>
      <Stack spacing="xs">
        <Text
          className={classes.widgetTitle}
          data-testid={`kpi-widget-${kpiWidget.title}-title`}
        >
          {kpiWidget.title}
        </Text>
        <Text
          className={classes.widgetLabel}
          data-testid={`kpi-widget-${kpiWidget.title}-label`}
        >
          {kpiWidget.label}
        </Text>
      </Stack>
    </Paper>
  );
}

const useStyles = createStyles((theme) => ({
  widgetTitle: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.md,
    fontWeight: 300,
  },
  widgetLabel: {
    color: theme.colors.gray[8],
    fontWeight: 600,
    fontSize: 28,
  },
}));
