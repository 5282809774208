import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';
import {
  CommandType,
  PaginatedFilterTypeEnum,
  PaginatedQueryParamsType,
  PaginationResponse,
  UsePaginatedTableApiQuery,
} from '@portals/types';

import {
  DEVICES_API_URL,
  devicesQueryKeys,
  getDeviceApiUrl,
} from './devices.constants';
import { ActiveCommandType } from './devices.types';
import { useApiQuery } from '../../hooks';
import { MutationOptions, QueryOptions, ServerError } from '../../types';
import {
  buildUrlFromFilters,
  fetchApiRequest,
  useRequestOptions,
} from '../../utils';
import { usePaginatedTableApiQuery } from '../../utils/paginated-table';

export function getDeviceCommandApiUrl(deviceId: string, commandId: string) {
  return `${getDeviceApiUrl(deviceId)}/commands/${commandId}`;
}

export const useDeviceCommands = (
  deviceId: string,
  params: PaginatedQueryParamsType<CommandType>
) => {
  const { url, options } = useRequestOptions({
    url: `${getDeviceApiUrl(deviceId)}/commands`,
  });

  const requestUrl = buildUrlFromFilters<CommandType>({
    url,
    ...params,
  });

  return useQuery<PaginationResponse<CommandType>>({
    queryKey: [...devicesQueryKeys.commands.all(deviceId), params],
    queryFn: () => fetchApiRequest(requestUrl, options),
    staleTime: 0,
    meta: {
      baseUrl: `${DEVICES_API_URL}/:id/commands`,
      method: 'GET',
    },
  });
};

export const usePaginatedDeviceCommands = (
  tableState: UsePaginatedTableApiQuery<CommandType>['tableState'],
  columns: UsePaginatedTableApiQuery<CommandType>['columns'],
  baseUrl: string,
  queryKey: string[] = devicesQueryKeys.commands.base
) =>
  usePaginatedTableApiQuery<CommandType>({
    queryKey: [...queryKey, tableState],
    baseUrl,
    columns,
    tableState,
    queryOptions: {
      staleTime: 0,
    },
  });

export const useActiveDeviceCommands = (
  deviceId: string,
  queryOptions?: QueryOptions<ActiveCommandType[]>
) => {
  return useApiQuery<ActiveCommandType[]>(
    `${getDeviceApiUrl(deviceId)}/commands/active`,
    devicesQueryKeys.commands.active(deviceId),
    {
      ...queryOptions,
      enabled: Boolean(deviceId),
      staleTime: 0,
    }
  );
};

export function useFailedDeviceCommands(deviceId: string) {
  const { url, options } = useRequestOptions({
    url: `${getDeviceApiUrl(deviceId)}/commands/`,
    method: 'GET',
  });

  const requestUrl = buildUrlFromFilters({
    url,
    pagination: {
      page: 0,
      pageSize: 1000,
    },
    filters: [
      {
        id: 'status',
        value: ['failed', 'aborted', 'done'],
        type: PaginatedFilterTypeEnum.Select,
      },
    ],
  });

  return useQuery<PaginationResponse<CommandType>, ServerError, CommandType[]>({
    queryKey: devicesQueryKeys.commands.failed(deviceId),
    queryFn: () => fetchApiRequest(requestUrl, options),
    select: (response) => response.data,
  });
}

export function useCancelDeviceCommand(
  mutationOptions: MutationOptions<
    CommandType,
    { error: string },
    { deviceId: string; commandId: string }
  > = {}
) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { url: baseUrl, options } = useRequestOptions({
    url: '',
    method: 'DELETE',
  });

  return useMutation({
    mutationFn: ({ deviceId, commandId }) =>
      fetchApiRequest(
        `${baseUrl}/${getDeviceCommandApiUrl(deviceId, commandId)}`,
        {
          ...options,
        }
      ),
    onSuccess: (command) => {
      queryClient.invalidateQueries(
        devicesQueryKeys.commands.all(command.device_id)
      );

      dispatch(toastrSuccess('Command successfully cancelled'));
    },
    onError: ({ error }: any) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useCancelDeviceCommand',
      baseUrl: `${DEVICES_API_URL}/:id/commands/:commandId`,
      method: 'DELETE',
    },
    ...mutationOptions,
  });
}
