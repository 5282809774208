import { createStyles, LoadingOverlay } from '@mantine/core';
import React, { ReactNode, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { AfterAuthRedirectManager } from '@lib/after-auth-redirect-manager';
import { usePortalConfig } from '@portals/api/organizations';
import { useAppConfig } from '@portals/framework/context';
import { useAuth } from '@portals/redux';
import { OrganizationConfigurationType } from '@portals/types';

import { OrganizationAuthenticationGuard } from '../../desktop/components/layouts/OrganizationAuthenticationGuard';
import Header from '../components/layout/header';
import Sidebar from '../components/layout/sidebar';

interface MobileLayoutProps {
  children: ReactNode;
  isAuth?: boolean;
}

function MobileLayout({ children, isAuth = false }: MobileLayoutProps) {
  const { classes } = useStyles();

  const { extraLayout } = useAppConfig<OrganizationConfigurationType>();
  const [isOpen, setIsOpen] = useState(false);
  const portalConfig = usePortalConfig({ enabled: isAuth });

  if (isAuth && !portalConfig.isFetched) return <LoadingOverlay visible />;

  return (
    <div className={classes.container}>
      {extraLayout?.globalLayout ? extraLayout.globalLayout() : null}

      <Header onOpen={() => setIsOpen(true)} isAuth={isAuth} />

      {isAuth ? null : (
        <Sidebar isOpen={isOpen} onClose={() => setIsOpen(false)} />
      )}

      <div className={classes.content}>{children}</div>
    </div>
  );
}

export function AuthMobileLayout({ children }: MobileLayoutProps) {
  const authentication = useAuth();

  if (authentication) return <Navigate to="/" replace />;

  return <MobileLayout isAuth>{children}</MobileLayout>;
}

export function DashboardMobileLayout({ children }: MobileLayoutProps) {
  const auth = useAuth();
  const navigate = useNavigate();
  const { extraLayout } = useAppConfig();
  const DataLayout = extraLayout?.dashboardDataLayout;

  useEffect(
    function redirectedAuthenticatedUser() {
      if (!auth?.token) return;

      const afterAuth = AfterAuthRedirectManager.getPathname();

      if (!afterAuth || afterAuth === '/') return;

      AfterAuthRedirectManager.removePathname();

      navigate(afterAuth, { replace: true });
    },
    [auth?.token, navigate]
  );

  if (DataLayout) {
    return (
      <OrganizationAuthenticationGuard>
        <DataLayout>
          <MobileLayout>{children}</MobileLayout>
        </DataLayout>
      </OrganizationAuthenticationGuard>
    );
  }

  return (
    <OrganizationAuthenticationGuard>
      <MobileLayout>{children}</MobileLayout>
    </OrganizationAuthenticationGuard>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    position: 'fixed',
    height: '100dvh',
    width: '100%',
    paddingBottom: 'env(safe-area-inset-bottom)',
    paddingTop: 'env(safe-area-inset-top)',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: 1,
    height: '100dvh',
    backgroundColor: theme.white,
    overflow: 'auto',
  },
}));
