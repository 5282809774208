import React from 'react';

import { useFeatureFlags, useIsCecEnabled } from '@portals/api/organizations';
import { Page404 } from '@portals/framework';

import { ConnectRouteModal } from './ConnectRouteModal';
import { useCanAccessConnectRouteModal } from './ConnectToggle';
import { LegacyConnectRouteModal } from './legacy/LegacyConnectRouteModal';

// Once the legacy connect route modal is no longer relevant,
// this file can be deleted and the new ConnectRouteModal should be returned
export function ConnectRouteModalSelector() {
  const isCecEnabled = useIsCecEnabled();
  const featureFlags = useFeatureFlags();

  const canAccessConnectRouteModal = useCanAccessConnectRouteModal();

  if (!canAccessConnectRouteModal) {
    return <Page404 />;
  }

  if (isCecEnabled) {
    return <ConnectRouteModal />;
  } else if (featureFlags?.connect_plus) {
    return <LegacyConnectRouteModal />;
  }
}
