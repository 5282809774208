import {
  Badge,
  createStyles,
  Group,
  HoverCard,
  Stack,
  Text,
} from '@mantine/core';
import React, { ReactNode } from 'react';

import { ProductImage } from '../../ProductImage';

export interface OrderItemsPreviewTooltipProps {
  items: Array<{
    name: string;
    quantity: number;
    imageUrl: string | null;
  }>;
  children: ReactNode;
}

export function OrderItemsPreviewTooltip({
  items,
  children,
}: OrderItemsPreviewTooltipProps) {
  const { classes, theme } = useStyles();

  return (
    <HoverCard withinPortal position="right" openDelay={250}>
      <HoverCard.Target>{children}</HoverCard.Target>

      <HoverCard.Dropdown
        mah={320}
        maw={260}
        sx={{
          overflowY: 'auto',
          borderRadius: theme.radius.md,
          padding: theme.spacing.xl,
          boxShadow: theme.shadows.lg,
        }}
      >
        <Stack spacing="md">
          {items.map((orderItem, index) => (
            <Group noWrap spacing="md" p={0} key={index}>
              <ProductImage
                src={orderItem.imageUrl}
                width={52}
                className={classes.imageWrapper}
              />

              <Stack py="xs" h="100%" spacing="xs" justify="space-between">
                <Text size="sm" weight={500} color="blue_gray.9">
                  {orderItem.name}
                </Text>

                <Group>
                  <Badge size="md" color="gray" radius="lg" variant="light">
                    {orderItem.quantity}x
                  </Badge>
                </Group>
              </Stack>
            </Group>
          ))}
        </Stack>
      </HoverCard.Dropdown>
    </HoverCard>
  );
}

const useStyles = createStyles((theme) => ({
  imageWrapper: {
    overflow: 'hidden',
    borderRadius: theme.radius.md,
  },
}));
