import { useCurrentUser } from '@portals/api/ui';
import { TenantType } from '@portals/types';

import { useAppConfig } from '../context';

export function useCanAccessPersonalSettings() {
  const { tenantType } = useAppConfig();
  const currentUser = useCurrentUser();

  const isOrganization = tenantType === TenantType.Organization;

  return isOrganization && !currentUser.data?.partner_id;
}
