import {
  Button,
  Modal,
  Textarea,
  ModalProps as MantineModalProps,
} from '@mantine/core';
import React, { useState } from 'react';

import { ModalBody, ModalFooter } from '@portals/core';

import { ModalProps } from '../components/Modals';

export interface EditNoteModalProps
  extends ModalProps<
    | {
        note: string | null;
        onSubmitSync: (newNotes: string) => void;
        onSubmitAsync?: never;
      }
    | {
        note: string | null;
        onSubmitSync?: never;
        onSubmitAsync: (newNotes: string) => Promise<unknown>;
      }
  > {}

export function EditNoteModal({
  closeMe,
  data: { note, onSubmitSync, onSubmitAsync },
}: EditNoteModalProps) {
  const [value, setValue] = useState(note || '');
  const [isLoading, setIsLoading] = useState(false);

  const onSubmitAsyncHandler = async () => {
    setIsLoading(true);

    try {
      await onSubmitAsync?.(value);
      closeMe();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmitClick = () => {
    if (onSubmitSync) {
      onSubmitSync(value);
      closeMe();
      return;
    } else {
      return onSubmitAsyncHandler();
    }
  };

  return (
    <Modal
      opened
      onClose={closeMe}
      title="Edit note"
      padding="xxl"
      styles={modalStyles}
    >
      <ModalBody>
        <Textarea
          data-autofocus
          disabled={isLoading}
          minRows={10}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          data-testid="note-text-area"
          maxLength={1000}
        />
      </ModalBody>

      <ModalFooter position="right">
        <Button variant="default" onClick={closeMe}>
          Cancel
        </Button>
        <Button
          loading={isLoading}
          onClick={onSubmitClick}
          data-testid="note-save-button"
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
}

const modalStyles: MantineModalProps['styles'] = (theme) => ({
  header: {
    paddingBottom: theme.spacing.md,
  },
});
