import {
  createStyles,
  Group,
  Paper,
  Stack,
  Switch,
  SwitchProps,
  Text,
  TextInput,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import React from 'react';

import {
  IncidentPriorityEnum,
  SpaceIncidentRateFormType,
  SpaceIncidentViewEnum,
} from '@portals/api/organizations';

interface SpaceIncidentRateFormProps {
  form: UseFormReturnType<SpaceIncidentRateFormType>;
}

export function SpaceIncidentRateForm({ form }: SpaceIncidentRateFormProps) {
  const { classes, cx } = useStyles();

  return (
    <Stack spacing="xl" pr={30}>
      <Text size="sm" color="gray.9">
        General
      </Text>

      <TextInput {...form.getInputProps('title')} label="Title" />

      <Text size="sm" color="gray.9">
        View
      </Text>

      <Group noWrap grow align="stretch">
        <Paper
          radius="md"
          p="md"
          withBorder
          className={cx(classes.view, {
            [classes.selected]:
              form.values.spaceIncidentView ===
              SpaceIncidentViewEnum.SpacesWithIncidents,
          })}
          onClick={() => {
            form.setFieldValue(
              'spaceIncidentView',
              SpaceIncidentViewEnum.SpacesWithIncidents
            );
          }}
        >
          <Stack spacing={4}>
            <Text size="sm" align="center">
              Spaces with incidents
            </Text>

            <Text size="xs" align="center">
              Percentage of spaces experiencing incidents, providing an overview
              of incident rates.
            </Text>
          </Stack>
        </Paper>

        <Paper
          withBorder
          radius="md"
          p="md"
          w={250}
          className={cx(classes.view, {
            [classes.selected]:
              form.values.spaceIncidentView ===
              SpaceIncidentViewEnum.SpacesWithoutIncidents,
          })}
          onClick={() => {
            form.setFieldValue(
              'spaceIncidentView',
              SpaceIncidentViewEnum.SpacesWithoutIncidents
            );
          }}
        >
          <Stack spacing={4}>
            <Text
              size="sm"
              align="center"
              data-testid="incident-free-spaces-text"
            >
              Incident-free spaces
            </Text>

            <Text size="xs" align="center">
              Percentage of spaces with no incidents, providing an overview of
              incident rates.
            </Text>
          </Stack>
        </Paper>
      </Group>

      <Stack spacing={4}>
        <Text size="sm" color="gray.9">
          Incident priority
        </Text>

        {form.values.incidentPriority.length === 0 ? (
          <Text color="red_accent.4" size="xs">
            Please select at least one priority
          </Text>
        ) : null}
      </Stack>

      <Switch.Group
        value={form.values.incidentPriority}
        onChange={(values: IncidentPriorityEnum[]) =>
          form.setFieldValue('incidentPriority', values)
        }
      >
        <Stack>
          <Switch
            value={IncidentPriorityEnum.Critical}
            label="Critical"
            labelPosition="left"
            styles={switchStyles}
            data-testid="incident-priority-critical-switch"
          />

          <Switch
            value={IncidentPriorityEnum.High}
            label="High"
            labelPosition="left"
            styles={switchStyles}
            data-testid="incident-priority-high-switch"
          />

          <Switch
            value={IncidentPriorityEnum.Moderate}
            label="Moderate"
            labelPosition="left"
            styles={switchStyles}
            data-testid="incident-priority-moderate-switch"
          />

          <Switch
            value={IncidentPriorityEnum.Low}
            label="Low"
            labelPosition="left"
            styles={switchStyles}
            data-testid="incident-priority-low-switch"
          />

          <Switch
            value={IncidentPriorityEnum.Planning}
            label="Planning"
            labelPosition="left"
            styles={switchStyles}
            data-testid="incident-priority-planning-switch"
          />
        </Stack>
      </Switch.Group>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  view: {
    cursor: 'pointer',
  },

  selected: {
    color: theme.fn.primaryColor(),
    border: `1px solid ${theme.fn.primaryColor()} !important`,
  },
}));

const switchStyles: SwitchProps['styles'] = (theme) => ({
  body: {
    justifyContent: 'space-between',
  },
});
