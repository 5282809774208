import { OrganizationStoreListing } from '@portals/types';

import { SHOP_API_URL, shopQueryKeys } from './shop.constants';
import { OrganizationShopType } from './shop.types';
import { useApiQuery } from '../../hooks';
import { QueryOptions } from '../../types';

interface UseShopParams {
  sortByPosition?: boolean;
  queryOptions?: QueryOptions<OrganizationShopType>;
}

export function useShop({
  sortByPosition = true,
  queryOptions,
}: UseShopParams = {}) {
  const url = sortByPosition
    ? `${SHOP_API_URL}?q[s]=position+asc`
    : SHOP_API_URL;

  return useApiQuery<OrganizationShopType>(
    url,
    shopQueryKeys.all,
    queryOptions
  );
}

export function useShopStoreListing(storeListingId: string) {
  return useApiQuery<OrganizationStoreListing>(
    `${SHOP_API_URL}/${storeListingId}`,
    shopQueryKeys.storeListing(storeListingId),
    {
      staleTime: 0,
    }
  );
}
