import { captureException, withScope } from '@sentry/react';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { toString } from 'lodash/fp';
import React, { ReactNode } from 'react';

function formatErrorForSentry(error: any) {
  if (error instanceof Error) {
    return error;
  } else if (typeof error === 'string') {
    return new Error(error);
  } else if (typeof error?.error === 'string') {
    return new Error(error.error);
  } else if (typeof error?.message === 'string') {
    return new Error(error.message);
  } else {
    // If the error is an object, stringify it to get more information
    try {
      return new Error(JSON.stringify(error));
    } catch (e) {
      // If stringifying fails, return a generic error
      return new Error('Unknown error');
    }
  }
}

export const queryClient = new QueryClient({
  /*
   * We capture exceptions for every query and mutation and use
   * scope.setFingerprint to define, what fingerprint the scope has,
   * that is what the exception will be grouped by. For now,
   * we use the mutationKey or the queryHash for this. Before using the
   * queryHash we strip away all numbers, so that we don’t get one issue per
   * query parameter combination.
   */
  mutationCache: new MutationCache({
    onError: (error: any, _variables, _context, mutation) => {
      withScope((scope) => {
        scope.setContext('mutation', {
          mutationId: mutation.mutationId,
          variables: mutation.state.variables,
          ...(mutation.meta || {}),
        });

        // Set fingerprint & tags
        const fingerprint = ['app_mutation_error'];

        scope.setTag('errorType', 'app_mutation_error');

        if (mutation.meta?.baseUrl) {
          fingerprint.push(mutation.meta.baseUrl as string);

          scope.setTag('url', mutation.meta.baseUrl as string);
        }

        if (mutation.meta?.method) {
          scope.setTag('method', mutation.meta.method as string);
        }

        if (mutation.meta?.mutationName) {
          fingerprint.push(mutation.meta?.mutationName as string);

          scope.setTag('mutationName', mutation.meta?.mutationName as string);
        }

        if (error?.status_code) {
          scope.setTag('status_code', toString(error.status_code));
          fingerprint.push(toString(error.status_code));
        }

        scope.setFingerprint(fingerprint);

        captureException(formatErrorForSentry(error));
      });
    },
  }),
  queryCache: new QueryCache({
    onError: (error: any, query) => {
      withScope((scope) => {
        scope.setContext('query', {
          queryHash: query.queryHash,
          ...(query.meta || {}),
        });

        scope.setTags({
          errorType: 'app_query_error',
          url: query.meta?.baseUrl as string,
          method: query.meta?.method as string,
        });

        const fingerprint = ['app_query_error'];

        if (query.meta?.baseUrl) {
          fingerprint.push(query.meta?.baseUrl as string);
        }

        if (error?.status_code) {
          scope.setTag('status_code', toString(error.status_code));
          fingerprint.push(toString(error.status_code));
        }

        scope.setFingerprint(fingerprint);

        captureException(formatErrorForSentry(error));
      });
    },
  }),

  defaultOptions: {
    queries: {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

interface QueryClientWrapperProps {
  children: ReactNode;
}

export function QueryClientWrapper({ children }: QueryClientWrapperProps) {
  return (
    <QueryClientProvider client={queryClient}>
      {children}

      {process.env.NODE_ENV === 'development' ? (
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      ) : null}
    </QueryClientProvider>
  );
}
