import { get } from 'lodash/fp';
import { createSelector, Selector } from 'reselect';

import { StateType } from '@portals/types';

export const getSidebar = (state: StateType): StateType['sidebar'] =>
  state.sidebar;

export const getIsSidebarOpen: Selector<
  StateType,
  StateType['sidebar']['isOpen']
> = createSelector(getSidebar, get('isOpen'));

export const getIsSidebarSticky: Selector<
  StateType,
  StateType['sidebar']['isSticky']
> = createSelector(getSidebar, get('isSticky'));
