import { Button, Group, useMantineTheme } from '@mantine/core';
import React, { useState } from 'react';

import { useResendCecMigrationEmail } from '@portals/api/auth';
import { connectInfinitySrc } from '@portals/assets';
import { ModalCenteredMediaLayout } from '@portals/core';
import { ModalProps } from '@portals/framework';
import { ReactComponent as TickCircleIcon } from '@portals/icons/bulk/tick-circle.svg';

export interface CecMigrationWelcomeModalProps
  extends ModalProps<{ userName: string; userEmail: string }> {}

export function CecMigrationWelcomeModal({
  closeMe,
  data: { userName, userEmail },
}: CecMigrationWelcomeModalProps) {
  const theme = useMantineTheme();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const resendCecMigrationEmail = useResendCecMigrationEmail();

  function onResendEmailClick() {
    resendCecMigrationEmail.mutate(
      { email: userEmail },
      {
        onSuccess: () => {
          if (!isSuccessModalOpen) {
            setIsSuccessModalOpen(true);
          }
        },
      }
    );
  }

  return (
    <>
      <ModalCenteredMediaLayout
        opened={!isSuccessModalOpen}
        onClose={closeMe}
        media={<img src={connectInfinitySrc} alt="connect icon" />}
        title="Welcome to your new experience!"
        description={
          <>
            Welcome back <b>{userName}!</b> To ensure your account remains
            secure, we require all users to reset their password before
            continuing. We’ve sent instructions to your email. Please follow the
            steps to update your password and regain access to your account.
          </>
        }
      >
        <Group grow>
          <Button size="md" variant="default" onClick={closeMe}>
            Cancel
          </Button>
          <Button
            size="md"
            onClick={onResendEmailClick}
            loading={resendCecMigrationEmail.isLoading}
          >
            Send reset instructions
          </Button>
        </Group>
      </ModalCenteredMediaLayout>

      <ModalCenteredMediaLayout
        media={<TickCircleIcon color={theme.colors.indigo_accent[4]} />}
        opened={isSuccessModalOpen}
        onClose={closeMe}
        title="Email sent!"
        description={
          <>
            We've sent the password reset instructions to <b>{userEmail}</b>.
            <br />
            Please check your inbox and follow the steps to reset your password.
            If you don’t see the email, check your spam folder or request a new
            one.
          </>
        }
      >
        <Group grow>
          <Button
            size="md"
            variant="default"
            onClick={onResendEmailClick}
            loading={resendCecMigrationEmail.isLoading}
          >
            Resend email
          </Button>
          <Button size="md" onClick={closeMe}>
            Close
          </Button>
        </Group>
      </ModalCenteredMediaLayout>
    </>
  );
}
