import { Descope } from '@descope/react-sdk';
import {
  LoadingOverlay,
  Modal,
  ModalProps as MantineModalProps,
} from '@mantine/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';

import { ModalProps } from '../../components/Modals';
import { captureDescopeError } from '../../utils/descope.utils';

export interface EmailMFAModalProps
  extends ModalProps<{
    isOtpEnabled: boolean;
  }> {}

export function EmailMFAModal({ data, closeMe }: EmailMFAModalProps) {
  const { isOtpEnabled } = data;
  const dispatch = useDispatch();

  const [isDescopeFlowLoading, setIsDescopeFlowLoading] = useState(true);

  const onSuccess: React.ComponentProps<typeof Descope>['onSuccess'] = (e) => {
    if (!e.detail.user?.customAttributes?.isOtpEnabled && isOtpEnabled) {
      dispatch(
        toastrSuccess(
          'Two-Factor Authentication via email has been successfully disabled'
        )
      );

      closeMe();
      return;
    }

    if (e.detail.user?.customAttributes?.isOtpEnabled && !isOtpEnabled) {
      dispatch(
        toastrSuccess(
          'Two-Factor Authentication via email has been successfully set up'
        )
      );

      closeMe();
      return;
    }

    dispatch(toastrError('Something went wrong'));

    closeMe();
  };

  return (
    <Modal
      opened
      onClose={closeMe}
      radius="lg"
      padding="xxl"
      styles={modalStyles}
    >
      <LoadingOverlay visible={isDescopeFlowLoading} />

      <Descope
        flowId={isOtpEnabled ? 'cancel-mfa-email' : 'mfa-email'}
        onSuccess={onSuccess}
        onReady={() => setIsDescopeFlowLoading(false)}
        onError={captureDescopeError}
      />
    </Modal>
  );
}

const modalStyles: MantineModalProps['styles'] = (theme) => ({
  body: {
    minHeight: 480,
  },
});
