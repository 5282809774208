import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function Referral() {
  const location = useLocation();

  useEffect(() => {
    const useQuery = new URLSearchParams(location.search);
    const referral = useQuery.get('referral');

    if (referral) {
      localStorage.setItem('referral', referral);
    }
  }, [location.search]);

  return null;
}
