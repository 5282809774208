import { createStyles } from '@mantine/core';
import React, { ReactNode, useState } from 'react';

import { useAppConfig } from '@portals/framework/context';

import { ExternalStoreNavbar } from '../pages/external-store/ExternalStoreNavbar';

interface MobileExternalStoreLayoutProps {
  children: ReactNode;
}

export function MobileExternalStoreLayout({
  children,
}: MobileExternalStoreLayoutProps) {
  const { classes } = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const { extraLayout } = useAppConfig();

  return (
    <div className={classes.container}>
      <ExternalStoreNavbar isOpen={isOpen} setIsOpen={setIsOpen} />

      <div className={classes.mainContent}>{children}</div>

      {extraLayout?.globalLayout ? extraLayout.globalLayout() : null}
    </div>
  );
}

const useStyles = createStyles(() => ({
  container: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  mainContent: {
    overflow: 'auto',
  },
}));
