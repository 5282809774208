import React from 'react';

import { DetailsList, DetailsListProps } from '../details-list/DetailsList';

interface InvoiceAddressDetailsProps {
  country: string;
  state: string | null;
  city: string;
  address: string;
  zip: string | null;
  title: string;
}
export function InvoiceAddressDetails({
  country,
  state,
  address,
  city,
  zip,
  title,
}: InvoiceAddressDetailsProps) {
  const detailsList: DetailsListProps['items'] = [
    { label: 'Country', value: country },
    { label: 'State', value: state },
    { label: 'Address', value: address },
    { label: 'City', value: city },
    { label: 'ZIP', value: zip },
  ];

  return <DetailsList title={title} items={detailsList} />;
}
