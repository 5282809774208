import {
  Button,
  ButtonProps,
  ScrollArea,
  Stack,
  Switch,
  SwitchProps,
  Text,
  TextInput,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import React from 'react';
import { v4 as uuid } from 'uuid';

import { ReactComponent as Add } from '@portals/icons/linear/add.svg';

import {
  scaleTimeUnitValue,
  convertTimeUnits,
} from './active-incidents-age.utils';
import { Tier } from './Tier';
import {
  ActiveIncidentsAgeWidgetFormType,
  TierType,
  TimeUnit,
} from '../../../overview.types';

interface ActiveIncidentsAgeWidgetFormProps {
  form: UseFormReturnType<ActiveIncidentsAgeWidgetFormType>;
}

const MAX_TIERS = 7;

export function ActiveIncidentsAgeWidgetForm({
  form,
}: ActiveIncidentsAgeWidgetFormProps) {
  const onAddNewTier = () => {
    const tiers = form.values.tiers;
    const lastTier = tiers[tiers.length - 1];

    if (lastTier.lastValue !== Infinity) {
      const newTier = {
        displayName: '',
        firstValue: lastTier.lastValue,
        firstValueTimeUnit: lastTier.lastValueTimeUnit,
        lastValueTimeUnit: lastTier.lastValueTimeUnit,
        lastValue: Infinity,
        id: uuid(),
      };

      form.setFieldValue('tiers', [...tiers, newTier]);
      return;
    }

    const minLastValue =
      lastTier.firstValue +
      convertTimeUnits({
        value: 1,
        from: lastTier.lastValueTimeUnit,
        to: TimeUnit.Seconds,
      });

    const updatedLastTier = {
      ...lastTier,
      lastValue: minLastValue,
    };

    const newTier = {
      displayName: '',
      firstValue: updatedLastTier.lastValue,
      firstValueTimeUnit: updatedLastTier.lastValueTimeUnit,
      lastValueTimeUnit: updatedLastTier.lastValueTimeUnit,
      lastValue: Infinity,
      id: uuid(),
    };

    form.setFieldValue('tiers', [
      ...tiers.slice(0, -1),
      updatedLastTier,
      newTier,
    ]);
  };

  const onTimeValueChange = ({
    field,
    value,
    changedTier,
  }: {
    field: keyof Pick<TierType, 'firstValue' | 'lastValue'>;
    value: number;
    changedTier: TierType;
  }) => {
    const updatedTiers = form.values.tiers.map((tier) =>
      tier.id === changedTier.id ? { ...tier, [field]: value } : tier
    );

    const newTiers: TierType[] = [];
    let lastValue = 0;

    for (let i = 0; i < updatedTiers.length; i++) {
      const tier = updatedTiers[i];

      if (i > 0) {
        tier.firstValue = lastValue;
      }

      if (tier.firstValue >= tier.lastValue) {
        tier.lastValue =
          tier.firstValue +
          convertTimeUnits({
            value: 1,
            from: tier.lastValueTimeUnit,
            to: TimeUnit.Seconds,
          });
      }

      lastValue = tier.lastValue;
      newTiers.push(tier);
    }

    form.setFieldValue('tiers', newTiers);
  };

  const onTimeUnitChange = ({
    field,
    value,
    changedTier,
  }: {
    field: keyof Pick<TierType, 'firstValueTimeUnit' | 'lastValueTimeUnit'>;
    value: TimeUnit;
    changedTier: TierType;
  }) => {
    const updatedTiersWithNewValues = form.values.tiers.map((tier) => {
      if (tier.id !== changedTier.id) {
        return tier;
      }

      // Determine which value to update based on the field
      const valueFieldToUpdate =
        field === 'lastValueTimeUnit' ? 'lastValue' : 'firstValue';
      const valueToConvert =
        field === 'lastValueTimeUnit' ? tier.lastValue : tier.firstValue;
      const originalTimeUnit =
        field === 'lastValueTimeUnit'
          ? tier.lastValueTimeUnit
          : tier.firstValueTimeUnit;

      // Convert the value to the new time unit
      const convertedValue = scaleTimeUnitValue({
        value: valueToConvert,
        from: originalTimeUnit,
        to: value,
      });

      return {
        ...tier,
        [valueFieldToUpdate]: convertedValue,
        [field]: value,
      };
    });

    const newTiers: TierType[] = [];
    let lastValue = 0;

    for (let i = 0; i < updatedTiersWithNewValues.length; i++) {
      const tier = updatedTiersWithNewValues[i];
      const prevLastValueTimeUnit =
        updatedTiersWithNewValues[i - 1]?.lastValueTimeUnit ??
        tier.lastValueTimeUnit;

      if (i > 0) {
        tier.firstValue = lastValue;
        tier.firstValueTimeUnit = prevLastValueTimeUnit;
      }

      if (tier.firstValue >= tier.lastValue) {
        tier.lastValue =
          tier.firstValue +
          convertTimeUnits({
            value: 1,
            from: prevLastValueTimeUnit,
            to: TimeUnit.Seconds,
          });
        tier.lastValueTimeUnit = tier.firstValueTimeUnit;
      }

      lastValue = tier.lastValue;
      newTiers.push(tier);
    }

    form.setFieldValue('tiers', newTiers);
  };

  const onRemoveTier = (tierToRemove: TierType) => {
    const tierToRemoveIndex = form.values.tiers.findIndex(
      (tier) => tier.id === tierToRemove.id
    );

    const newTiers = form.values.tiers
      .map((tier, i) => {
        //we only need to modify the next tier
        //with the first value of the previous tier
        if (i !== tierToRemoveIndex + 1) {
          return tier;
        }

        return {
          ...tier,
          firstValue:
            form.values.tiers[tierToRemoveIndex - 1]?.lastValue ??
            tier.firstValue,
        };
      })
      .filter((tier) => tier.id !== tierToRemove.id);

    form.setFieldValue('tiers', newTiers);
  };

  return (
    <ScrollArea.Autosize mah="50vh" type="auto">
      <Stack spacing="xl" pr="xxl">
        <Text size="sm" color="gray.9">
          General
        </Text>

        <TextInput {...form.getInputProps('title')} label="Title" />

        <Switch
          label="Show incidents severity"
          labelPosition="left"
          styles={switchStyles}
          data-testid="incident-priority-critical-switch"
          {...form.getInputProps('incidents_severity', { type: 'checkbox' })}
        />

        <Text size="sm" color="gray.9">
          Tiers
        </Text>

        <Stack>
          {form.values.tiers.map((tier, index) => (
            <Tier
              key={tier.id}
              tier={tier}
              onTimeValueChange={onTimeValueChange}
              onTimeUnitChange={onTimeUnitChange}
              displayNameInputProps={form.getInputProps(
                `tiers.${index}.displayName`
              )}
              onRemoveTier={onRemoveTier}
              displayRemoveButton={form.values.tiers.length > 1}
              disableFirstValue={index > 0}
            />
          ))}

          <Button
            data-testid="add-new-tier-button"
            onClick={onAddNewTier}
            leftIcon={<Add />}
            variant="subtle"
            styles={buttonStyles}
            disabled={form.values.tiers.length === MAX_TIERS}
            px={0}
            mt="xs"
          >
            Add another tier
          </Button>
        </Stack>

        {form.values.tiers.length === MAX_TIERS && (
          <Text
            color="gray.5"
            size="sm"
            data-testid="limited-tier-addition-title"
          >
            You can add up to {MAX_TIERS} tiers
          </Text>
        )}
      </Stack>
    </ScrollArea.Autosize>
  );
}

const switchStyles: SwitchProps['styles'] = (theme) => ({
  body: {
    justifyContent: 'space-between',
  },
});

const buttonStyles: ButtonProps['styles'] = (theme) => ({
  root: {
    alignSelf: 'flex-start',
    textDecoration: 'underline',

    '&[data-disabled]': {
      backgroundColor: 'transparent',
    },

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});
