import { Checkbox } from '@mantine/core';
import React, { FC, MutableRefObject } from 'react';
import { TableToggleCommonProps } from 'react-table';

const ColumnCheckbox: FC<
  TableToggleCommonProps & { id: string; disabled: boolean }
> = React.forwardRef((props, ref: MutableRefObject<TableToggleCommonProps>) => {
  const { indeterminate, id, disabled, ...rest } = props;

  const defaultRef = React.useRef(null);
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    if (!resolvedRef.current) resolvedRef.current = {};

    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <Checkbox
      id={id}
      ref={resolvedRef}
      indeterminate={indeterminate}
      disabled={disabled}
      data-testid={id}
      {...rest}
    />
  );
});

export default ColumnCheckbox;
