import {
  CloseButton,
  Drawer,
  DrawerProps,
  Group,
  SimpleGrid,
  Stack,
  Text,
} from '@mantine/core';
import { AnimatePresence, motion } from 'framer-motion';
import { filter, map } from 'lodash/fp';
import React, { useState } from 'react';

import { SearchInput } from '@portals/core';
import { ReactComponent as ElementPlus } from '@portals/icons/linear/element-plus.svg';

import { WidgetPreviewCard } from './WidgetPreviewCard';
import noSearchResultsSrc from '../../../../../assets/img/no-search-results.svg';
import { WIDGETS_MAP } from '../../overview.constants';
import { useOverviewContext } from '../../overview.context';

export function WidgetsPanel() {
  const overview = useOverviewContext();
  const [searchTerm, setSearchTerm] = useState('');

  const filteredWidgets = filter(
    (widget) => widget.name.toLowerCase().includes(searchTerm.toLowerCase()),
    WIDGETS_MAP
  );

  return (
    <Drawer
      size={524}
      padding={32}
      position="right"
      opened={overview.isWidgetsConfigMode}
      onClose={overview.onCloseWidgetsPanel}
      withCloseButton={false}
      styles={drawerStyles}
      title={
        <Stack spacing="xl">
          <Group position="apart">
            <Group align="center" spacing={4}>
              <ElementPlus fill="black" />

              <Text size="xl">Add Widgets</Text>
            </Group>

            <CloseButton
              data-testid="add-widgets-close-button"
              size="md"
              onClick={overview.onCloseWidgetsPanel}
            />
          </Group>

          <SearchInput
            onClear={() => setSearchTerm('')}
            value={searchTerm}
            onChange={(event) => setSearchTerm(event.target.value)}
            placeholder="Search widget..."
            size="md"
            w="100%"
          />
        </Stack>
      }
    >
      <Stack spacing="xl" h="100%">
        {!filteredWidgets.length ? (
          <Stack h="100%" justify="center">
            <img src={noSearchResultsSrc} height={150} />
          </Stack>
        ) : (
          <SimpleGrid cols={2}>
            <AnimatePresence initial={false}>
              {map(
                (widget) =>
                  widget.isLegacy ? null : (
                    <motion.div
                      layout
                      key={widget.id}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      <WidgetPreviewCard
                        widget={widget}
                        handleClick={() =>
                          overview.onAddWidget({ id: widget.id })
                        }
                      />
                    </motion.div>
                  ),
                filteredWidgets
              )}
            </AnimatePresence>
          </SimpleGrid>
        )}
      </Stack>
    </Drawer>
  );
}

const drawerStyles: DrawerProps['styles'] = () => ({
  content: {
    display: 'grid',
    gridTemplateRows: 'max-content 1fr',
  },
  header: {
    display: 'block',
  },
});
