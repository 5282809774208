import {
  adaptEnergyLogo,
  avocorLogo,
  avproEdgeLogo,
  dellLogo,
  hpLogo,
  intelLogo,
  legrandLogo,
  msolutionsLogo,
  msTeamsLogo,
  qscReflectLogo,
  tekvoxLogo,
  volvoLogo,
  xioCloudLogo,
  zoomLogo,
} from '@portals/assets';
import { ReactComponent as Box2 } from '@portals/icons/linear/box-2.svg';
import { ReactComponent as Setting2 } from '@portals/icons/linear/setting-2.svg';

import {
  VendorCategoryEnum,
  VendorCategoryType,
  VendorType,
} from './connect.types';

export const CATEGORIES: Array<VendorCategoryType> = [
  {
    id: VendorCategoryEnum.CloudService,
    label: 'Cloud Services',
  },
  {
    id: VendorCategoryEnum.Manufacturer,
    label: 'Manufacturers',
  },
];

export const CATEGORY = {
  [VendorCategoryEnum.Manufacturer]: 'Manufacturer',
  [VendorCategoryEnum.CloudService]: 'Cloud Service',
};

export const CATEGORY_ICON = {
  [VendorCategoryEnum.Manufacturer]: Box2,
  [VendorCategoryEnum.CloudService]: Setting2,
};

export const VENDORS: Array<VendorType> = [
  {
    id: 'avocor',
    name: 'Avocor',
    logo: avocorLogo,
    category: VendorCategoryEnum.Manufacturer,
  },
  {
    id: 'avpro_edge',
    name: 'AVPro Edge',
    logo: avproEdgeLogo,
    category: VendorCategoryEnum.Manufacturer,
  },
  {
    id: 'adapt_energy',
    name: 'Adapt Energy',
    logo: adaptEnergyLogo,
    category: VendorCategoryEnum.Manufacturer,
  },
  {
    id: 'dell',
    name: 'Dell',
    logo: dellLogo,
    category: VendorCategoryEnum.Manufacturer,
  },
  {
    id: 'hp_technologies',
    name: 'HP Technologies',
    logo: hpLogo,
    category: VendorCategoryEnum.Manufacturer,
  },
  {
    id: 'intel',
    name: 'Intel',
    logo: intelLogo,
    category: VendorCategoryEnum.Manufacturer,
  },
  {
    id: 'legrand',
    name: 'Legrand',
    logo: legrandLogo,
    category: VendorCategoryEnum.Manufacturer,
  },
  {
    id: 'msolutions',
    name: 'MSolutions',
    logo: msolutionsLogo,
    category: VendorCategoryEnum.Manufacturer,
  },
  {
    id: 'tekvox',
    name: 'Tekvox',
    logo: tekvoxLogo,
    category: VendorCategoryEnum.Manufacturer,
  },
  {
    id: 'volvo_ce',
    name: 'Volvo CE',
    logo: volvoLogo,
    category: VendorCategoryEnum.Manufacturer,
  },
  {
    id: 'zoom',
    name: 'Zoom Rooms',
    logo: zoomLogo,
    category: VendorCategoryEnum.CloudService,
  },
  {
    id: 'microsoft_teams',
    name: 'Microsoft Teams Rooms',
    logo: msTeamsLogo,
    category: VendorCategoryEnum.CloudService,
  },
  {
    id: 'xio_cloud',
    name: 'Crestron XiO Cloud',
    logo: xioCloudLogo,
    category: VendorCategoryEnum.CloudService,
  },

  {
    id: 'qsc_reflect',
    name: 'QSC Reflect',
    logo: qscReflectLogo,
    category: VendorCategoryEnum.CloudService,
  },
];
