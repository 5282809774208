import { Button, ButtonProps } from '@mantine/core';
import React, { forwardRef, PropsWithChildren } from 'react';

import {
  useSendPartnerSupportEmail,
  UseSendSupportEmailParams,
} from '@portals/api/organizations';
import { ContactSupportModalProps } from '@portals/framework/modals';
import { useOpenModal } from '@portals/redux';

interface ContactPartnerSupportButtonProps extends ButtonProps {
  supportModalTitle: string;
}

export const ContactPartnerSupportButton = forwardRef<
  HTMLButtonElement,
  PropsWithChildren<ContactPartnerSupportButtonProps>
>(({ supportModalTitle, children, ...buttonProps }, ref) => {
  const openModal = useOpenModal();
  const sendPartnerSupportEmail = useSendPartnerSupportEmail();

  function onSendSupportEmail(values: UseSendSupportEmailParams) {
    sendPartnerSupportEmail.mutate(
      {
        title: values.title,
        message: values.message,
      },
      {
        onSuccess: () => {
          openModal('ContactSupportSuccessModal');
        },
      }
    );
  }

  return (
    <Button
      {...buttonProps}
      ref={ref}
      color="blue_gray.8"
      variant="subtle"
      onClick={() =>
        openModal<ContactSupportModalProps['data']>('ContactSupportModal', {
          onSubmit: onSendSupportEmail,
          title: supportModalTitle,
        })
      }
      data-testid="contact-support-button"
    >
      {children || 'Contact support'}
    </Button>
  );
});
