import { find } from 'lodash/fp';
import { Layout } from 'react-grid-layout';

import { DashboardLayoutListItem } from '@portals/types';

import { WidgetType } from '../overview.types';

export function calculateNewWidgetPosition(
  widgets: Array<DashboardLayoutListItem<WidgetType>>
): { x: number; y: number } {
  let maxY = 0;

  widgets.forEach((widget) => {
    const widgetBottomY = widget.y + widget.h;
    if (widgetBottomY > maxY) {
      maxY = widgetBottomY;
    }
  });

  return {
    x: 0, // always start on a new row from the leftmost position
    y: maxY, // place the new widget at the bottom
  };
}

export function assignWidgetIdAndName(
  updatedWidgetsLayout: Array<Layout>,
  assignedWidgets: Array<DashboardLayoutListItem<WidgetType>>
) {
  return updatedWidgetsLayout.map((updatedWidgetLayout) => {
    const assignedWidget = find({ i: updatedWidgetLayout.i }, assignedWidgets);

    return {
      ...updatedWidgetLayout,
      id: assignedWidget?.id,
      name: assignedWidget?.name,
      extraParams: assignedWidget?.extraParams,
    };
  });
}
