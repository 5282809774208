import { BreadcrumbsProps, BreadcrumbsStylesNames } from '@mantine/core';

import { ThemeComponentOverrides } from '../common-types';

export const Breadcrumbs: ThemeComponentOverrides<
  BreadcrumbsProps,
  BreadcrumbsStylesNames,
  any
> = {
  styles: (theme) => {
    return {
      root: {
        alignItems: 'center',

        a: {
          color: theme.colors.blue_gray[5],
        },
      },
      breadcrumb: {
        fontSize: theme.fontSizes.sm,
        fontWeight: 500,
      },
    };
  },
};
