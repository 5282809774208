import { Divider, Input, Stack } from '@mantine/core';
import React, { FC, useEffect, useRef } from 'react';

import { FileUploaderDropzone, useFileUploaderDropzone } from '@portals/core';
import { AutoFormikProps, FieldType } from '@portals/types';
import { scrubFileName } from '@portals/utils';

type FileFieldProps = {
  value: string;
  error?: string;
  field: FieldType;
  setFieldValue: (fieldName: string, path: string) => void;
  horizontal?: AutoFormikProps['inputProps']['horizontal'];
  required: boolean;
};

const FileField: FC<FileFieldProps> = ({
  value,
  field,
  setFieldValue,
  required,
}) => {
  const openRef = useRef(null);
  const { files, setFiles } = useFileUploaderDropzone();

  const { title } = field;

  const { inputProps = {} } = field;

  useEffect(() => {
    if (files?.length) {
      const fileState = files[0];

      if (fileState?.file?.name && field.options?.fileNameField) {
        setFieldValue(
          field.options?.fileNameField,
          scrubFileName(fileState.file.name)
        );
      }

      setFieldValue(field.name, fileState.fileUrl);
    }
  }, [field.name, field.options?.fileNameField, files, setFieldValue]);

  return (
    <Input.Wrapper label={title} required={required} {...inputProps}>
      <Stack>
        <FileUploaderDropzone
          files={files}
          setFiles={setFiles}
          uploadedFileUrls={value ? [value] : []}
          onDeleteUploadedFile={() => {
            setFieldValue(field.name, '');
            setFiles([]);
          }}
          dropzoneProps={{
            openRef: openRef,
            accept: ['*'],
            maxFiles: 1,
          }}
        />

        <Divider />
      </Stack>
    </Input.Wrapper>
  );
};

export default FileField;
