import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  GettingStartedToursNamesEnum,
  useCompleteGettingStartedTour,
  useIsFirstClaimedDevice,
  useOrganizationConfig,
  usePortalCapabilities,
} from '@portals/api/organizations';
import {
  isCustomerFeatureAll,
  isCustomerFeatureLabOnly,
} from '@portals/framework';
import { ReactComponent as TrendUp } from '@portals/icons/bulk/trend-up.svg';
import { useOpenModal } from '@portals/redux';

import { TourCompletedModalProps } from '../desktop/modals';

export function useClaimFirstDeviceTourCompleted(
  onTourCompletedModalOpen?: VoidFunction
) {
  const isFirstClaimedDevice = useIsFirstClaimedDevice();
  const completeGettingStartedTour = useCompleteGettingStartedTour();
  const portalCapabilities = usePortalCapabilities();
  const organizationConfig = useOrganizationConfig();
  const openModal = useOpenModal();
  const navigate = useNavigate();

  const showClaimDeviceTourCompletedModal = useCallback(() => {
    if (!portalCapabilities || !organizationConfig) {
      return false;
    }

    return (
      isCustomerFeatureAll(portalCapabilities.onboarding_guides) ||
      isCustomerFeatureLabOnly(
        portalCapabilities.onboarding_guides,
        organizationConfig.lab
      )
    );
  }, [portalCapabilities, organizationConfig]);

  const handleClaimFirstDevice = useCallback(async () => {
    const isFirstClaimed = await isFirstClaimedDevice.refetch();

    if (isFirstClaimed.data) {
      completeGettingStartedTour.mutate('claim_device_completed');

      if (showClaimDeviceTourCompletedModal()) {
        openModal<TourCompletedModalProps['data']>('TourCompletedModal', {
          title: 'You’ve just claimed your first device',
          nextTourTitle: 'Monitor and manage devices',
          nextTourDescription:
            'Learn how to effectively monitor, manage, and configure your devices, empowering you to keep track of their performance and make necessary adjustments as needed.',
          nextTourIcon: <TrendUp />,
          onNextTourClick: () => {
            navigate(
              `/overview/?active_tour=${GettingStartedToursNamesEnum.Monitoring}`
            );
          },
        });

        onTourCompletedModalOpen?.();
      }
    }
  }, [
    isFirstClaimedDevice,
    completeGettingStartedTour,
    showClaimDeviceTourCompletedModal,
    openModal,
    onTourCompletedModalOpen,
    navigate,
  ]);

  return handleClaimFirstDevice;
}
