import {
  Badge,
  Box,
  createStyles,
  Group,
  Paper,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import React, { useMemo } from 'react';

import {
  IncidentPriorityEnum,
  SpaceIncidentRateFormType,
  SpaceIncidentViewEnum,
} from '@portals/api/organizations';
import { ReactComponent as InfoCircle } from '@portals/icons/linear/info-circle.svg';

import { useSpaceIncidentRateWidgetCountAndPercentage } from './space-incident-rate-widget.hooks';
import {
  countLocalIncidentsInSpace,
  countSpacesWithDevices,
  countSpacesWithIncidentsIncludingChildren,
} from './utils';
import { useSpaceTree } from '../../../../../../lib/spaces';
import { DataLevelEnum } from '../../../overview.types';

export interface SpaceIncidentRateWidgetProps {
  title: string;
  incidentsPriorities: SpaceIncidentRateFormType['incidentPriority'];
  spaceId: number | null;
  parentSpaceId: number | null;
  spaceIncidentView: SpaceIncidentViewEnum;
  dataLevel: DataLevelEnum;
}

export function SpaceIncidentRateWidget({
  title,
  incidentsPriorities,
  spaceId,
  parentSpaceId,
  spaceIncidentView,
  dataLevel,
}: SpaceIncidentRateWidgetProps) {
  const { classes } = useStyles();

  const spaceTree = useSpaceTree({
    spaceId,
    parentSpaceId,
  });

  const spacesCount = countSpacesWithDevices({
    spaceTreeChildren: spaceTree.children,
    dataLevel,
    totalDevicesCount: spaceTree.device_count,
    localDevicesCount:
      spaceTree.local_device_count && spaceTree.local_device_count > 0 ? 1 : 0,
  });

  const spacesWithIncidentsCount = useMemo(() => {
    if (dataLevel === DataLevelEnum.Local) {
      return countLocalIncidentsInSpace(incidentsPriorities, spaceTree);
    }

    return countSpacesWithIncidentsIncludingChildren(
      incidentsPriorities,
      spaceTree
    );
  }, [incidentsPriorities, spaceTree, dataLevel]);

  const getPrioritiesLabel = () => {
    if (
      incidentsPriorities.length === Object.keys(IncidentPriorityEnum).length
    ) {
      return 'All Priorities';
    } else if (incidentsPriorities.length === 1) {
      return incidentsPriorities[0];
    }

    return `${incidentsPriorities.length} priorities`;
  };

  const {
    spacesWithoutIncidentsCount,
    percentageOfSpacesWithoutIncidents,
    percentageOfSpacesWithIncidents,
  } = useSpaceIncidentRateWidgetCountAndPercentage({
    spacesWithIncidentsCount,
    spacesCount,
  });

  return (
    <Paper radius="lg" w={300}>
      <Stack p="xl" spacing="xs" justify="center" pos="relative">
        <Group spacing="xs" position="apart" noWrap>
          <Text color="gray.5" truncate size="md">
            {title}
          </Text>

          <Box>
            <Tooltip label="Showing only spaces with devices. Spaces without devices are ignored.">
              <InfoCircle className={classes.icon} width={18} height={18} />
            </Tooltip>
          </Box>
        </Group>

        <Tooltip
          label={
            <Group spacing={2}>
              <Text>Incident priorities:</Text>

              <Text transform="capitalize">
                {incidentsPriorities.join(', ')}
              </Text>
            </Group>
          }
        >
          <Badge color="gray" variant="outline" px="md" py="sm" w="fit-content">
            {getPrioritiesLabel()}
          </Badge>
        </Tooltip>

        <Box>
          <Group spacing="xs" align="baseline">
            <Text weight={700} size={28} color="blue_gray.9">
              {spaceIncidentView === SpaceIncidentViewEnum.SpacesWithIncidents
                ? percentageOfSpacesWithIncidents
                : percentageOfSpacesWithoutIncidents}
              %
            </Text>

            <Text
              size="sm"
              color="blue_gray.9"
              data-testid="space-incident-rate-widget-incident-count"
            >
              (
              {`${
                spaceIncidentView === SpaceIncidentViewEnum.SpacesWithIncidents
                  ? spacesWithIncidentsCount
                  : spacesWithoutIncidentsCount
              } of ${spacesCount}`}
              )
            </Text>
          </Group>

          <Text size="sm" color="blue_gray.9">
            {spaceIncidentView === SpaceIncidentViewEnum.SpacesWithIncidents
              ? 'spaces with incidents'
              : 'incident-free spaces'}
          </Text>
        </Box>
      </Stack>
    </Paper>
  );
}

const useStyles = createStyles((theme) => ({
  icon: {
    color: theme.colors.gray[5],
  },
}));
