import { isUndefined } from 'lodash/fp';
import React, { useContext } from 'react';

import Wizard from '@portals/ui/Wizard';

import EditSection from './EditSection';
import IntegrationError from './IntegrationError';
import { IntegrationsContext } from './Integrations';

const EditWithWizard = ({ sections, id }) => {
  const { integrations } = useContext(IntegrationsContext);
  const integrationData = integrations[id] || {};

  const STEPS = sections
    .map(({ name, component, enabled, visible, props }) => ({
      id,
      name,
      props,
      enabled,
      visible,
      component: component || EditSection(id, sections),
    }))
    .filter(({ visible }) => isUndefined(visible) || visible(integrationData));

  return (
    <>
      <Wizard steps={STEPS} data={integrationData} />

      <div className="mt-5">
        <IntegrationError
          state={integrationData.state}
          error={integrationData.error}
        />
      </div>
    </>
  );
};

export default EditWithWizard;
