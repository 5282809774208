import { Grid, TextInput, Button, Group } from '@mantine/core';
import React, { MouseEventHandler, useState } from 'react';

import { DeviceType } from '@portals/api/organizations';
import { ReactComponent as CloseCircle } from '@portals/icons/linear/close-circle.svg';
import { ReactComponent as Edit } from '@portals/icons/linear/edit.svg';
import { ReactComponent as Save2 } from '@portals/icons/linear/save-2.svg';
import { DeviceType as CommonDeviceType } from '@portals/types';

type DeviceNameProps = {
  device: DeviceType | CommonDeviceType;
  setDeviceDetails: (spaceId: number, deviceId: string, any) => void;
};

type WithCancelProps = {
  handleCancel: () => void;
};

function EditValue({
  device,
  setDeviceDetails,
  handleCancel,
}: WithCancelProps & DeviceNameProps) {
  const [name, setName] = useState(device.name || '');

  const handleSave = () => {
    setDeviceDetails(device.space_id, device.id, { name });
    handleCancel();
  };

  return (
    <Grid grow align="center" className="edit-device-name-container">
      <Grid.Col span={8}>
        <TextInput
          size="xs"
          autoFocus
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Grid.Col>

      <Grid.Col span={4}>
        <Group grow spacing="xs">
          <Button
            color="orange"
            size="xs"
            onClick={handleCancel}
            leftIcon={<CloseCircle width={15} height={15} />}
          >
            Cancel
          </Button>

          <Button
            color="primary"
            size="xs"
            onClick={handleSave}
            leftIcon={<Save2 width={15} height={15} />}
          >
            Save
          </Button>
        </Group>
      </Grid.Col>
    </Grid>
  );
}

type ShowValueProps = {
  name: string;
  handleEdit: MouseEventHandler;
  withEdit: boolean;
};

function ShowValue({ name, handleEdit, withEdit }: ShowValueProps) {
  return (
    <div className="justify-content-between d-flex align-items-center">
      <span>{name}</span>

      {withEdit && (
        <Button
          onClick={handleEdit}
          size="xs"
          leftIcon={<Edit width={15} height={15} />}
        >
          Edit
        </Button>
      )}
    </div>
  );
}

export function DeviceName({ device, setDeviceDetails }: DeviceNameProps) {
  const [edit, setEdit] = useState(false);

  return edit ? (
    <EditValue
      device={device}
      setDeviceDetails={setDeviceDetails}
      handleCancel={() => setEdit(false)}
    />
  ) : (
    <ShowValue
      name={device.name}
      handleEdit={() => setEdit(true)}
      withEdit={!!setDeviceDetails}
    />
  );
}
