import { BadgeProps } from '@mantine/core';
import React from 'react';

import { BadgeWithDot } from '@portals/core';
import { StoreListingStatus } from '@portals/types';

interface StoreListingStatusBadgeProps extends BadgeProps {
  status: StoreListingStatus;
}

export function StoreListingStatusBadge({
  status,
  ...badgeProps
}: StoreListingStatusBadgeProps) {
  const { color, label } = getColorAndLabel(status);

  return (
    <BadgeWithDot color={color} size="lg" radius="xl" {...badgeProps}>
      {label}
    </BadgeWithDot>
  );
}

function getColorAndLabel(status: StoreListingStatus) {
  switch (status) {
    case 'draft': {
      return {
        label: 'Draft',
        color: 'blue_gray',
      };
    }
    case 'published': {
      return {
        label: 'Published',
        color: 'teal',
      };
    }
    case 'archived': {
      return {
        label: 'Archived',
        color: 'red',
      };
    }
  }
}
