import { createStyles, Menu } from '@mantine/core';
import { groupBy } from 'lodash/fp';
import React from 'react';

import { MapFeature, SuperClusterType } from '../../types/maps';
import { MAX_ZOOM } from './map.constants';
import { PointsAccordion } from './PointsAccordion';

interface ClusterProps {
  item: MapFeature;
  superCluster?: SuperClusterType;
  showPartnerMarker?: boolean;
  zoomToCluster: (cluster: MapFeature) => void;
  zoom: number;
  lat: number;
  lng: number;
  onListScroll: (mapGestureHandling: 'none' | 'greedy') => void;
}

export function Cluster(props: ClusterProps) {
  const {
    item,
    superCluster,
    showPartnerMarker,
    zoomToCluster,
    zoom,
    onListScroll,
    ...coordinates
  } = props;
  const { classes } = useStyles();

  const pointCount = item.properties?.point_count || 0;

  if (!pointCount) return null;

  const clusterPoints =
    superCluster && pointCount > 0
      ? superCluster?.getLeaves(item.properties.cluster_id, Infinity)
      : [];
  const categorizedPoints = groupBy(({ properties }) => {
    return properties?.severityCategory || 'Other';
  }, clusterPoints);

  return (
    <Menu
      withinPortal
      shadow="md"
      width={200}
      trigger="hover"
      openDelay={100}
      closeDelay={100}
      onOpen={() => {
        onListScroll('none');
      }}
      onClose={() => {
        onListScroll('greedy');
      }}
    >
      <Menu.Target>
        <div
          className={classes.clusterContainer}
          onClick={() => zoom !== MAX_ZOOM && zoomToCluster(item)}
          {...coordinates}
        >
          {pointCount}
        </div>
      </Menu.Target>

      <Menu.Dropdown className={classes.dropdown}>
        <PointsAccordion categorizedPoints={categorizedPoints} />
      </Menu.Dropdown>
    </Menu>
  );
}

const useStyles = createStyles((theme) => ({
  clusterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    color: theme.colors.gray[7],
    fontSize: theme.fontSizes.lg,
    borderRadius: '50%',
    border: `1px solid ${theme.white}`,
    padding: theme.spacing.xs,
    minWidth: 55,
    minHeight: 55,
    backgroundColor: theme.white,
    boxShadow: theme.shadows.md,
  },
  dropdown: {
    borderRadius: theme.radius.lg,
    height: 'fit-content',
    boxShadow: '0px 13px 36px rgba(38, 50, 56, 0.2)',
    padding: theme.spacing.md,
    maxHeight: 250,
    overflowX: 'hidden',
    overflowY: 'auto',
  },
}));
