import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const PurchaseShippingSuccess = ({ closeMe }) => (
  <Modal isOpen={true} toggle={closeMe} centered>
    <ModalHeader toggle={closeMe}>Thank you for your purchase</ModalHeader>

    <ModalBody className="text-center m-3">
      <p className="mb-0 font-size-lg">
        We have received your order and will update you as soon as your items
        are shipped.
      </p>
    </ModalBody>

    <ModalFooter>
      <Button color="light" onClick={closeMe}>
        Close
      </Button>
    </ModalFooter>
  </Modal>
);

export default PurchaseShippingSuccess;
