import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError } from '@portals/redux/actions/toastr';

import { usersQueryKeys } from './users.constants';
import { fetchApiRequest, useRequestOptions } from '../../utils';

const API_URL = 'ui/users/self/dismiss_feature_notification';

export interface UseDismissFeatureNotificationParams {
  notification_name: string;
}

export function useDismissFeatureNotification() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: API_URL,
    method: 'POST',
  });

  return useMutation<
    void,
    { error: string },
    UseDismissFeatureNotificationParams
  >({
    mutationFn: (params: UseDismissFeatureNotificationParams) => {
      return fetchApiRequest(url, {
        ...options,
        body: JSON.stringify(params),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(usersQueryKeys.self());
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useDismissFeatureNotification',
      baseUrl: API_URL,
    },
  });
}
