import { Stack, StackProps, Text } from '@mantine/core';
import React, { ReactNode } from 'react';

import emptyStateBox from './empty-state-box.svg';

interface EmptyStateProps extends StackProps {
  messageTitle?: ReactNode;
  messageDescription?: ReactNode;
  media?: ReactNode;
  imageSize?: number;
}

export function EmptyState({
  messageTitle,
  messageDescription,
  media,
  imageSize = 150,
  className,
  ...stackProps
}: EmptyStateProps) {
  return (
    <Stack align="center" justify="center" w="100%" h="100%" {...stackProps}>
      {media ?? (
        <img
          width={imageSize}
          height={imageSize}
          src={emptyStateBox}
          alt="empty box illustration"
        />
      )}

      {messageTitle || messageDescription ? (
        <Stack ta="center" spacing={4}>
          {messageTitle ? (
            <Text data-testid="empty-page-title" color="gray.8" weight={600}>
              {messageTitle}
            </Text>
          ) : null}

          {messageDescription ? (
            <Text color="gray.5">{messageDescription}</Text>
          ) : null}
        </Stack>
      ) : null}
    </Stack>
  );
}
