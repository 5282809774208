import { Text } from '@mantine/core';
import React, { FC } from 'react';

import { AuditLogType } from '@portals/types';

interface RemoveAccessProps {
  auditLog: AuditLogType;
}

export const RemoveAccess: FC<RemoveAccessProps> = ({ auditLog }) => (
  <Text size="sm">
    Access revoked for {auditLog?.params[0]?.display_name} on{' '}
    {auditLog?.params[1]?.display_name}
  </Text>
);
