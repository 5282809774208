import { Box, createStyles } from '@mantine/core';
import React from 'react';

import { DeviceDetailsType } from '@portals/api/organizations';
import { ModelType } from '@portals/types';

import { OverviewRow } from './OverviewRow';
import { OverviewWidgets } from './OverviewWidgets';

interface DeviceOverviewProps {
  device: DeviceDetailsType;
  model: ModelType;
}

export function Overview({ device, model }: DeviceOverviewProps) {
  const { classes } = useStyles();

  return (
    <Box className={classes.wrapper} pt="md">
      <Box className={classes.container}>
        <OverviewRow device={device} />

        <OverviewWidgets modelId={model?.id} deviceId={device.id} />
      </Box>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  wrapper: {
    maxWidth: 1260,
    margin: 'auto',
  },
  container: {
    display: 'grid',
    height: '100%',
    gridTemplateRows: 'min-content 1fr',
    padding: `${theme.spacing.xl} ${theme.spacing.md}`,
    gap: theme.spacing.xl,
    position: 'relative',
  },
  alert: {
    position: 'sticky',
    zIndex: 1,
    top: theme.spacing.xs,
    boxShadow: `0 0.07rem 0.2rem rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1) 0 2.25rem 1.75rem -0.5rem,
    rgba(0, 0, 0, 0.1) 0 1rem 1rem -0.1rem`,
  },
  infoIcon: {
    width: 20,
    height: 20,
    color: theme.colors.amber[7],
  },
}));
