import { useApiQuery } from '@portals/api';
import { OrganizationStoreListing } from '@portals/types';

import {
  STORE_LISTINGS_API_URL,
  storeListingsQueryKeys,
} from './store-listings.constants';

export function useLicenseStoreListingsForSupportedCommand(
  supportedCommandId: string
) {
  return useApiQuery<OrganizationStoreListing[]>(
    `${STORE_LISTINGS_API_URL}?q[product_supported_commands_id_in]=${supportedCommandId}`,
    storeListingsQueryKeys.detail(supportedCommandId),
    {
      staleTime: 0,
      enabled: !!supportedCommandId,
      meta: {
        mutationName: 'useLicenseStoreListingsForSupportedCommand',
        baseUrl: `${STORE_LISTINGS_API_URL}?q[product_supported_commands_id_in]=:id`,
        method: 'GET',
      },
    }
  );
}
