import { createStyles, Group, Text } from '@mantine/core';
import React from 'react';

import { ChatMessageType } from '@portals/types';
import { prettyTimeMoment } from '@portals/utils';

interface SystemChatMessageProps {
  messageText: string;
  createdAt: string;
  user: ChatMessageType['user'];
}
export function SystemChatMessage({
  messageText,
  createdAt,
  user,
}: SystemChatMessageProps) {
  const { classes } = useStyles();
  return (
    <Group spacing="xs" className={classes.systemMessage}>
      <Text color="gray.8" transform="uppercase">
        {messageText}
      </Text>

      {user && <Text color="gray.6">by {user}</Text>}

      <Text color="gray.5">{prettyTimeMoment(createdAt)}</Text>
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  systemMessage: {
    alignSelf: 'center',
    paddingBlock: theme.spacing.xs,
    paddingInline: theme.spacing.md,
    borderRadius: theme.radius.xl,
    backgroundColor: theme.colors.gray[1],
    fontSize: theme.fontSizes.xs,
  },
}));
