import { MenuProps, MenuStylesNames, PopoverStylesParams } from '@mantine/core';

import { ThemeComponentOverrides } from '../common-types';

export const Menu: ThemeComponentOverrides<
  MenuProps,
  MenuStylesNames,
  PopoverStylesParams
> = {
  defaultProps: {
    shadow: 'md',
  },
  styles: (theme) => ({
    itemIcon: {
      width: 16,
      height: 16,
      marginRight: theme.spacing.sm,
    },
  }),
};
