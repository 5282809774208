import { createStyles, Stack, Text, Title } from '@mantine/core';
import React, { ReactNode } from 'react';

import { usePortalConfig } from '@portals/api/organizations';

import { PageContainer } from '../../components/layout/Page';

export function AuthWrapper({ children }: { children: ReactNode }) {
  const { classes } = useStyles();
  const portalConfig = usePortalConfig();

  const label =
    portalConfig?.data?.design?.sign_in_page?.tagline ||
    portalConfig?.data?.name;

  return (
    <PageContainer className="sign-in-page-container">
      <Stack className={classes.container} spacing="sm">
        <Title order={3}>{label}</Title>

        <Text size="sm" color="blue_gray">
          Use your desktop for the full experience
        </Text>

        {children}
      </Stack>
    </PageContainer>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    padding: `50px ${theme.spacing.xl}`,

    '.auth-page-error, .auth-page-title': {
      display: 'none',
    },
  },
}));
