import React from 'react';

import { ByModelForm } from './forms/ByModelForm';
import { VendorForm } from './forms/VendorForm';
import { ZoomForm } from './forms/ZoomForm';
import { FormsMap } from '../add-device-modal.types';

export const FORMS_MAP: FormsMap = {
  vendor: (props) => <VendorForm {...props} />,
  byModel: (props) => <ByModelForm {...props} />,
  zoom: (props) => <ZoomForm {...props} />,
};
