import { every } from 'lodash/fp';

import { GETTING_STARTED_TOURS } from '@portals/api/organizations';
import { OrganizationConfigurationType } from '@portals/types';

export function isGettingStartedToursDone(
  configuration: OrganizationConfigurationType | undefined
) {
  if (!configuration?.onboarding_tours?.getting_started) return false;

  return every(
    (isTourCompleted) =>
      configuration?.onboarding_tours?.getting_started[isTourCompleted],
    GETTING_STARTED_TOURS
  );
}
