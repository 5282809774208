import { flow, map, size, sortBy, toLower } from 'lodash/fp';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { DeviceType } from '@portals/api/organizations';

import DeviceCard from './DeviceCard';
import { useDevicesFiltersContext } from './devices-list.context';
import { GroupTypeEnum } from './devices-list.types';
import {
  filterByIncidents,
  filterBySearchTerm,
  filterByStatus,
  getDevicesGroupsList,
  sortDevices,
} from './devices-list.utils';
import DevicesGroup from './DevicesGroup';

export const useFilteredDevicesList = (
  devices: Array<DeviceType>,
  onSelect: (device: DeviceType) => void
) => {
  const {
    searchTerm,
    incidentsFilter,
    statusesFilter,
    sortTerm,
    collapsedGroups,
    groupTerm,
  } = useDevicesFiltersContext();
  const filteredDevices = useMemo<Array<DeviceType>>(
    () =>
      flow([
        filterBySearchTerm(toLower(searchTerm.value)),
        filterByIncidents(incidentsFilter.value),
        filterByStatus(statusesFilter.value),
        sortDevices(sortTerm.value),
      ])(devices),
    [devices, incidentsFilter, searchTerm, sortTerm, statusesFilter]
  );
  const numOfDevices = size(filteredDevices);

  const devicesList = useMemo(() => {
    if (groupTerm.value !== GroupTypeEnum.None) {
      const groupsList = getDevicesGroupsList(
        filteredDevices,
        groupTerm.value,
        collapsedGroups.value
      );

      return sortBy('groupName', groupsList).map(
        ({ devices, groupType, groupName, isOpen }) => (
          <DevicesGroup
            key={groupName}
            devices={devices}
            groupType={groupType}
            groupName={groupName}
            isOpen={isOpen}
            onDeviceSelect={onSelect}
          />
        )
      );
    } else {
      return (
        <DevicesWrapper>
          {map(
            (device) => (
              <DeviceCard
                key={device.id}
                device={device}
                handleSelected={() => onSelect(device)}
              />
            ),
            filteredDevices
          )}
        </DevicesWrapper>
      );
    }
  }, [collapsedGroups.value, filteredDevices, groupTerm.value, onSelect]);

  return {
    devicesList,
    numOfDevices,
    filteredDevices,
  };
};

export const DevicesWrapper = styled.div.attrs(() => ({
  className: 'devices-wrapper',
}))`
  min-height: 100px;
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fill, 275px);
  grid-gap: 15px;
  margin-bottom: 25px;
  justify-content: space-around;
`;
