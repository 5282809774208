import { Avatar, createStyles, Group, Paper, Stack, Text } from '@mantine/core';
import React, { ComponentType, FC } from 'react';

interface DevicesBoxProps {
  name: string;
  counter: number;
  Icon: ComponentType;
}

export const DevicesBox: FC<DevicesBoxProps> = ({ name, counter, Icon }) => {
  const { classes, theme } = useStyles();

  return (
    <Paper radius="md" className={classes.container}>
      <Stack>
        <Text>{name}</Text>

        <Group spacing="md" noWrap>
          <Avatar
            radius="xl"
            className={classes.avatar}
            styles={{
              placeholder: {
                backgroundColor: theme.colors.green[0],
              },
            }}
          >
            <Icon />
          </Avatar>

          <Text className={classes.counter}>{counter}</Text>
        </Group>
      </Stack>
    </Paper>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    padding: theme.spacing.xl,
    border: `1px solid ${theme.colors.gray[2]}`,
    backgroundColor: theme.colors.gray[0],
    minWidth: 250,
  },
  counter: {
    fontSize: 32,
    fontWeight: 600,
    color: theme.colors.blue_gray[9],
  },
  avatar: {
    width: 59,
    height: 59,

    svg: {
      color: theme.colors.green[4],
    },
  },
  title: {
    color: theme.colors.blue_gray[4],
    fontSize: theme.fontSizes.md,
  },
}));
