import { Box, createStyles, Text } from '@mantine/core';
import React from 'react';

import { EmptyState } from '@portals/table';

import { IntegrationCard } from './IntegrationCard';
import {
  IntegrationCategoryEnum,
  IntegrationsGroupType,
  IntegrationType,
} from './integrations.types';

interface IntegrationsListWrapperProps {
  integrations: IntegrationsGroupType[];
}

export function IntegrationsListWrapper({
  integrations,
}: IntegrationsListWrapperProps) {
  if (integrations.length === 0) {
    return <EmptyState label="No integrations" />;
  }

  return integrations.map(({ integrations, category }) => (
    <IntegrationsList
      key={category}
      integrations={integrations}
      category={category}
    />
  ));
}

interface IntegrationsListProps {
  integrations: IntegrationType[];
  category: IntegrationCategoryEnum;
}

function IntegrationsList({ integrations, category }: IntegrationsListProps) {
  const { classes } = useStyles();

  return (
    <Box className={classes.wrapper}>
      <Text fz="xl" mb="md" transform="capitalize">
        {category}
      </Text>

      <Box className={classes.integrationsGrid}>
        {integrations.map((integration) => (
          <IntegrationCard key={integration.id} integration={integration} />
        ))}
      </Box>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    height: '100%',
    overflow: 'auto',
    paddingBlock: theme.spacing.xl,
    paddingInline: theme.spacing.xxl,
    backgroundColor: theme.colors.gray[0],
  },

  wrapper: {
    maxWidth: 1600,
    margin: '0 auto',
  },

  integrationsGrid: {
    display: 'grid',
    gap: theme.spacing.xl,
    gridTemplateColumns: 'repeat(auto-fill, minmax(370px, 1fr))',
    marginBottom: theme.spacing.md,
  },
}));
