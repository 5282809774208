import {
  Alert,
  AlertProps,
  Box,
  Button,
  createStyles,
  Divider,
  Modal,
  Stack,
  Text,
} from '@mantine/core';
import React from 'react';

import { useDeleteUser, UserResponseType } from '@portals/api/ui';
import { ModalFooter, NameAbbreviationAvatar } from '@portals/core';
import { ModalProps } from '@portals/framework';

export interface DeleteUserModalProps
  extends ModalProps<{
    user: UserResponseType | undefined;
  }> {}

export function DeleteUserModal({ closeMe, data }: DeleteUserModalProps) {
  const { user } = data;
  const { classes } = useStyles();

  const deleteUser = useDeleteUser();

  const onDeleteUser = async () => {
    try {
      await deleteUser.mutateAsync({ userId: user.id });
      closeMe();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal
      opened
      onClose={closeMe}
      title="Delete user"
      classNames={{
        content: classes.modalContent,
        body: classes.modalBody,
        header: classes.modalHeader,
      }}
    >
      <Stack spacing="xxl" data-testid="delete-user-modal">
        <Stack align="center">
          <NameAbbreviationAvatar
            name={user?.name}
            radius={98}
            size={98}
            withToolTip={false}
          />

          <Box>
            <Text size="xl" align="center">
              {user?.name}
            </Text>
            <Text color="gray.5" align="center">
              {user?.email}
            </Text>
          </Box>
        </Stack>

        <Alert color="red" radius="md" styles={alertStyles} mb="xl">
          Please note, initiating deletion for this user is a permanent,
          irreversible action. This will revoke all their access and
          disassociate them from any linked items. However, the user's
          identification will still be recorded in our logs. Proceed with
          caution
        </Alert>
      </Stack>

      <Divider my="xl" />

      <ModalFooter position="right" p={0}>
        <Button variant="default" onClick={closeMe}>
          Cancel
        </Button>

        <Button
          color="red"
          onClick={onDeleteUser}
          loading={deleteUser.isLoading}
          data-testid="delete-user-modal-delete-button"
        >
          Delete User
        </Button>
      </ModalFooter>
    </Modal>
  );
}

const alertStyles: AlertProps['styles'] = (theme) => ({
  message: {
    textAlign: 'center',
    color: theme.colors.gray[8],
  },
});

const useStyles = createStyles((theme) => ({
  modalContent: {
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
  },

  modalBody: {
    display: 'grid',
    gridTemplateRows: '1fr min-content min-content',
    padding: theme.spacing.xxl,
  },

  modalHeader: {
    paddingBlock: theme.spacing.xl,
    paddingInline: theme.spacing.xxl,
  },
}));
