import { Anchor, AnchorProps } from '@mantine/core';
import { find } from 'lodash/fp';
import React, { ReactNode, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import {
  RouteOverlayLocationState,
  RouteOverlayParams,
  RouteOverlayType,
} from './route-overlays.types';
import { getBackgroundPath } from './route-overlays.utils';
import { useAppConfig } from '../context';

export interface RouteOverlayLinkProps
  extends AnchorProps,
    RouteOverlayParams,
    Omit<React.ComponentProps<typeof Link>, 'to' | 'color'> {
  overlayType: RouteOverlayType;
  children?: ReactNode;
}

export function RouteOverlayLink({
  children,
  entityId,
  overlayType,
  pathParams = [],
  backgroundPath,
  ...linkProps
}: RouteOverlayLinkProps) {
  const { routes } = useAppConfig();
  const location = useLocation();

  const routesCollection =
    overlayType === 'modal' ? routes?.routeModals : routes?.routePanels;

  const route = find((route) => route.id === entityId, routesCollection);

  const calculateBackgroundPath = useMemo(
    () => backgroundPath || getBackgroundPath(location, route?.backgroundPath),
    [backgroundPath, location, route?.backgroundPath]
  );

  if (!route) return null;

  return (
    <Anchor
      to={route.generatePath(pathParams)}
      component={Link}
      state={
        {
          routeOverlay: {
            entityId,
            path: route.path as string,
            backgroundPath: calculateBackgroundPath,
          },
        } satisfies RouteOverlayLocationState
      }
      {...linkProps}
    >
      {children}
    </Anchor>
  );
}
