import { Box, Button, createStyles, Group, Text } from '@mantine/core';
import { AnimatePresence, motion } from 'framer-motion';
import { PDFDocumentProxy } from 'pdfjs-dist';
import React, { useState } from 'react';

import { ReactComponent as CollapseRight } from '@portals/icons/linear/collapse-right.svg';
import { ReactComponent as DocumentDownload } from '@portals/icons/linear/document-download.svg';
import { VerticalScrollBar } from '@portals/scrollbar';
import { downloadFile } from '@portals/utils';

import { FilePreview } from '../FilePreview';

interface PurchaseOrderPanelProps {
  isOpen: boolean;
  fileUrl: string;
  orderId: string;
  onClose: () => void;
}

export function PurchaseOrderPanel({
  isOpen,
  fileUrl,
  onClose,
  orderId,
}: PurchaseOrderPanelProps) {
  const { classes } = useStyles();

  const [PDFDocumentProxy, setPDFDocumentProxy] = useState<PDFDocumentProxy>();
  const [isFailedToLoad, setIsFailedToLoad] = useState(false);
  const [isDownloaded, setIsDownloaded] = useState(false);

  return (
    <AnimatePresence initial={false}>
      {isOpen && (
        <motion.div
          initial={{ x: '100%' }}
          animate={{ x: 0 }}
          exit={{ x: '100%' }}
          transition={{ type: 'linear' }}
          className={classes.wrapper}
        >
          <div className={classes.content}>
            <Group position="apart">
              <Text size="md">Purchase order</Text>
              <Group spacing="xl">
                <Button
                  variant="default"
                  leftIcon={<DocumentDownload width={14} height={14} />}
                  onClick={() =>
                    downloadFile(fileUrl, `purchase_order_${orderId}`)
                  }
                  size="xs"
                >
                  Download
                </Button>

                <Button
                  variant="default"
                  leftIcon={<CollapseRight width={14} height={14} />}
                  onClick={onClose}
                  size="xs"
                >
                  Hide
                </Button>
              </Group>
            </Group>

            <Box className={classes.fileWrapper}>
              <VerticalScrollBar>
                <FilePreview
                  fileUrl={fileUrl}
                  fileName="purchase order"
                  withAutomaticDownload={false}
                  PDFDocumentProxy={PDFDocumentProxy}
                  setPDFDocumentProxy={setPDFDocumentProxy}
                  isFailedToLoad={isFailedToLoad}
                  setIsFailedToLoad={setIsFailedToLoad}
                  isDownloaded={isDownloaded}
                  setIsDownloaded={setIsDownloaded}
                />
              </VerticalScrollBar>
            </Box>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'fixed',
    backgroundColor: theme.white,
    top: theme.other.navbarHeight,
    right: 400,
    width: 700,
    height: `calc(100vh - ${theme.other.navbarHeight}px)`,
    boxShadow: '6px 0px 41px 0px rgba(0, 0, 0, 0.13)',
    clipPath: 'inset(0 0 0 -41px)',
    padding: theme.spacing.xxl,
  },

  content: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
    gap: theme.spacing.xxl,
  },

  fileWrapper: {
    borderRadius: theme.radius.md,
    border: `1px solid ${theme.colors.gray[2]}`,
  },
}));
