import {
  createStyles,
  Group,
  Paper,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import React from 'react';

import {
  DevicesPerSpaceFormType,
  DevicesPerSpaceViewEnum,
} from '@portals/api/organizations';
import { ReactComponent as Barcode } from '@portals/icons/linear/barcode.svg';
import { ReactComponent as Flash } from '@portals/icons/linear/flash.svg';

interface DevicesPerSpaceFormProps {
  form: UseFormReturnType<DevicesPerSpaceFormType>;
}

export function DevicesPerSpaceForm({ form }: DevicesPerSpaceFormProps) {
  const { classes, cx } = useStyles();

  return (
    <Stack spacing="xl" pr="xxl">
      <Text size="sm" color="gray.9">
        General
      </Text>

      <TextInput
        {...form.getInputProps('title')}
        label="Title"
        data-testid="title-input"
      />

      <Text size="sm" color="gray.9">
        Data
      </Text>

      <Group noWrap grow align="stretch">
        <Paper
          radius="md"
          p="md"
          className={cx(classes.view, {
            [classes.selected]:
              form.values.devicesPerSpaceView ===
              DevicesPerSpaceViewEnum.OnlineDevices,
          })}
          onClick={() => {
            form.setFieldValue(
              'devicesPerSpaceView',
              DevicesPerSpaceViewEnum.OnlineDevices
            );
          }}
        >
          <Stack
            data-testid="show-online-devices-button"
            spacing={4}
            align="center"
          >
            <Flash />

            <Text size="sm" align="center">
              Show online devices
            </Text>

            <Text size="xs" align="center">
              Provides a breakdown of currently active online devices by models
            </Text>
          </Stack>
        </Paper>

        <Paper
          radius="md"
          p="md"
          w={250}
          className={cx(classes.view, {
            [classes.selected]:
              form.values.devicesPerSpaceView ===
              DevicesPerSpaceViewEnum.AllDevices,
          })}
          onClick={() => {
            form.setFieldValue(
              'devicesPerSpaceView',
              DevicesPerSpaceViewEnum.AllDevices
            );
          }}
        >
          <Stack
            data-testid="show-all-devices-button"
            spacing={4}
            align="center"
          >
            <Barcode />
            <Text size="sm" align="center">
              Show all devices
            </Text>

            <Text size="xs" align="center">
              Provides a breakdown of all the devices that have been claimed
            </Text>
          </Stack>
        </Paper>
      </Group>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  view: {
    cursor: 'pointer',
    border: `1px solid ${theme.colors.gray[3]}`,
  },

  selected: {
    color: theme.fn.primaryColor(),
    borderColor: theme.fn.primaryColor(),
  },
}));
