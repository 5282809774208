import { AvailableStoreType, OrganizationStoreListing } from '@portals/types';

import {
  STORE_LISTINGS_API_URL,
  storeListingsQueryKeys,
} from './store-listings.constants';
import { useApiQuery } from '../../hooks';
import { QueryOptions } from '../../types';

interface UseStoreListingsParams {
  sortByPosition?: boolean;
  queryOptions?: QueryOptions<OrganizationStoreListing[]>;
}

export function useStoreListings({
  sortByPosition = true,
  queryOptions,
}: UseStoreListingsParams) {
  const url = sortByPosition
    ? `${STORE_LISTINGS_API_URL}?q[s]=position+asc&per_page=1000`
    : `${STORE_LISTINGS_API_URL}?per_page=1000`;

  return useApiQuery<OrganizationStoreListing[]>(
    url,
    storeListingsQueryKeys.all,
    queryOptions
  );
}

export function useStoreListing(storeListingId: string) {
  return useApiQuery<OrganizationStoreListing>(
    `${STORE_LISTINGS_API_URL}/${storeListingId}`,
    storeListingsQueryKeys.detail(storeListingId),
    {
      staleTime: 0,
    }
  );
}

interface UseAvailableStoresParams {
  isCecEnabled: boolean;
}

export function useAvailableStores({ isCecEnabled }: UseAvailableStoresParams) {
  return useApiQuery<Array<AvailableStoreType>>(
    `${STORE_LISTINGS_API_URL}/available_stores`,
    storeListingsQueryKeys.availableStores(),
    {
      enabled: isCecEnabled,
      staleTime: 0,
    }
  );
}
