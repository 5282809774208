import {
  Alert,
  Box,
  Button,
  createStyles,
  Group,
  Input,
  Select,
  SimpleGrid,
  Stack,
  Switch,
  Text,
  TextInput,
} from '@mantine/core';
import { Formik, FormikProps } from 'formik';
import { get, getOr } from 'lodash/fp';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';

import {
  LocationType,
  SpaceType,
  useGoogleTimeZone,
  useSpace,
} from '@portals/api/organizations';
import { EntityAccess } from '@portals/framework/EntityAccess';
import { ReactComponent as InfoCircle } from '@portals/icons/linear/info-circle.svg';
import { VerticalScrollBar } from '@portals/scrollbar';
import { AccessLevelEnum } from '@portals/types';

import { GeosuggestField } from './GeosuggestField';
import { SpaceFormCard } from './space-form.common';
import { canAdmin } from '../../../../../../lib/access';
import { OrganizationAvatar } from '../../../overview-sidebar/OrganizationAvatar';

const SCHEMA = Yup.object().shape({
  name: Yup.string().required('Required'),
});

interface SpaceFormProps {
  handleSubmit: (values: Record<string, any>) => void;
  space: SpaceType;
  initialValues: Record<string, any>;
  isLoading: boolean;
}

export function SpaceForm({
  handleSubmit,
  space,
  initialValues,
  isLoading,
}: SpaceFormProps) {
  const styles = useStyles();
  const [location, setLocation] = useState<LocationType | null>(null);
  const formRef = useRef<FormikProps<typeof initialValues>>();

  const isRoot = space.parent_id === null;
  const parent = useSpace({ spaceId: space?.parent_id });
  const { data: timeZone } = useGoogleTimeZone(location);

  // If we are root or inheritance is false, take value otherwise use parent value
  const resolveInherited = (key, values) =>
    (!values[`inherit_${key}`] || isRoot
      ? values[key]
      : get(['config', key], parent)) || '';

  const onLocationChanged = (location, description, setFieldValue) => {
    setLocation(location);
    setFieldValue('location', {
      description,
      location,
      // @ts-ignore - lib's types missing properties
      region_name: timeZone?.timeZoneId,
    });
  };

  const onSubmit = (event, values) => {
    event.preventDefault();

    if (timeZone) {
      const locationWithTimeZone = {
        ...values.location,
        region_name: timeZone.timeZoneId,
        utc_offset: timeZone.rawOffset / 60,
      };
      const valuesWithTimeZone = { ...values, location: locationWithTimeZone };

      handleSubmit(valuesWithTimeZone);
    } else {
      handleSubmit(values);
    }
  };

  useEffect(() => {
    if (!formRef.current) return;

    if (formRef.current?.values?.name !== initialValues.name) {
      formRef.current.setValues({
        ...formRef.current?.values,
        name: initialValues.name,
      });
    }
  }, [initialValues.name, space?.id]);

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={SCHEMA}
    >
      {({
        values,
        touched,
        errors,
        dirty,
        handleChange,
        handleBlur,
        handleReset,
        setFieldValue,
      }) => (
        <form
          onSubmit={(e) => onSubmit(e, values)}
          className={styles.classes.formContainer}
        >
          <VerticalScrollBar>
            <Stack spacing="xl" className={styles.classes.formContent}>
              <SpaceFormCard title="Information">
                <Group
                  align="start"
                  position="apart"
                  spacing="xl"
                  noWrap
                  h={150}
                >
                  {isRoot ? (
                    <Box
                      sx={{
                        height: '100%',
                        width: 150,
                      }}
                    >
                      <OrganizationAvatar />
                    </Box>
                  ) : null}

                  <Stack sx={{ flexGrow: 1 }}>
                    <SimpleGrid cols={2}>
                      <TextInput
                        label="Name"
                        name="name"
                        placeholder="Name"
                        value={values.name}
                        onChange={handleChange}
                        readOnly={!canAdmin(space)}
                        onBlur={handleBlur}
                        error={(touched.name && errors.name) as string}
                      />
                    </SimpleGrid>

                    <SimpleGrid cols={2}>
                      <Input.Wrapper
                        classNames={{
                          root: styles.classes.locationInputWrapperRoot,
                          label: styles.classes.locationInputWrapperLabel,
                        }}
                        label={
                          <Group
                            align="center"
                            position="apart"
                            sx={{ width: '100%' }}
                          >
                            <Text inherit>Location</Text>

                            {canAdmin(space) && !isRoot ? (
                              <Switch
                                id="inherit_location"
                                name="inherit_location"
                                label="Inherit"
                                size="xs"
                                checked={values.inherit_location}
                                disabled={isRoot}
                                readOnly={!canAdmin(space)}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            ) : null}
                          </Group>
                        }
                        error={(touched.location && errors.location) as string}
                      >
                        <GeosuggestField
                          placeholder="Location"
                          autoActivateFirstSuggest={true}
                          initialValue={getOr(
                            '',
                            'description',
                            resolveInherited('location', values)
                          )}
                          disabled={values.inherit_location}
                          readOnly={!canAdmin(space)}
                          onSuggestSelect={
                            // @ts-ignore - lib's types missing properties
                            ({ description, location }: Suggest = {}) =>
                              onLocationChanged(
                                location,
                                description,
                                setFieldValue
                              )
                          }
                        />
                      </Input.Wrapper>

                      <TextInput
                        label="Timezone"
                        disabled={true}
                        placeholder={get('location.region_name', values)}
                        value={timeZone?.timeZoneId}
                      />
                    </SimpleGrid>
                  </Stack>
                </Group>
              </SpaceFormCard>

              <SpaceFormCard title="Units">
                <SimpleGrid cols={2}>
                  <Input.Wrapper
                    classNames={{
                      root: styles.classes.locationInputWrapperRoot,
                      label: styles.classes.locationInputWrapperLabel,
                    }}
                    label={
                      <Group
                        align="center"
                        position="apart"
                        sx={{ width: '100%' }}
                      >
                        <Text inherit>Temperature</Text>

                        {canAdmin(space) && !isRoot ? (
                          <Switch
                            id="inherit_temperature_units"
                            name="inherit_temperature_units"
                            label="Inherit"
                            size="xs"
                            checked={values.inherit_temperature_units}
                            disabled={isRoot}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        ) : null}
                      </Group>
                    }
                  >
                    <Select
                      name="temperature_units"
                      id="temperature_units"
                      data={[
                        { value: 'celsius', label: 'Celsius' },
                        { value: 'fahrenheit', label: 'Fahrenheit' },
                      ]}
                      value={values.temperature_units}
                      onChange={(value) =>
                        setFieldValue('temperature_units', value)
                      }
                      disabled={
                        !canAdmin(space) || values.inherit_temperature_units
                      }
                      onBlur={handleBlur}
                    />
                  </Input.Wrapper>
                </SimpleGrid>
              </SpaceFormCard>

              <SpaceFormCard title="Configuration">
                <SimpleGrid cols={2}>
                  <Input.Wrapper
                    classNames={{
                      root: styles.classes.locationInputWrapperRoot,
                      label: styles.classes.locationInputWrapperLabel,
                    }}
                    label={
                      <Group
                        align="center"
                        position="apart"
                        sx={{ width: '100%' }}
                      >
                        <Text inherit>Priority</Text>

                        {canAdmin(space) && !isRoot ? (
                          <Switch
                            id="inherit_priority_factor"
                            name="inherit_priority_factor"
                            label="Inherit"
                            size="xs"
                            checked={values.inherit_priority_factor}
                            disabled={isRoot}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        ) : null}
                      </Group>
                    }
                  >
                    <Select
                      id="priority_factor"
                      name="priority_factor"
                      value={values.priority_factor || '0'}
                      onChange={(value) =>
                        setFieldValue('priority_factor', value)
                      }
                      data={[
                        { value: '-1', label: 'High priority' },
                        { value: '0', label: 'No Change' },
                        { value: '1', label: 'Low priority' },
                      ]}
                      disabled={
                        !canAdmin(space) || values.inherit_priority_factor
                      }
                      onBlur={handleBlur}
                    />
                  </Input.Wrapper>
                </SimpleGrid>

                <Stack spacing="md">
                  <SimpleGrid cols={2}>
                    <TextInput
                      label="Custom ID"
                      name="custom_id"
                      disabled={!canAdmin(space)}
                      placeholder="Custom Space ID passed to integrations"
                      value={values.custom_id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </SimpleGrid>

                  <Alert>
                    <Group>
                      <Box
                        sx={(theme) => ({
                          height: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          color: theme.colors.blue_gray[7],

                          svg: {
                            width: 15,
                            height: 15,
                          },
                        })}
                      >
                        <InfoCircle />
                      </Box>

                      <Stack spacing={0}>
                        <Text size="xs" color="blue_gray">
                          Set a custom identifier for this space.
                        </Text>
                        <Text size="xs" color="blue_gray">
                          When an incident is reported, this identifier will be
                          added to the data sent to various integration
                          providers.
                        </Text>
                      </Stack>
                    </Group>
                  </Alert>
                </Stack>
              </SpaceFormCard>

              <Box sx={{ height: 42, flexShrink: 0 }} />
            </Stack>
          </VerticalScrollBar>

          <Group
            position="right"
            align="center"
            className={styles.classes.formFooter}
          >
            <EntityAccess
              id="spaceActionsReset"
              minLevel={AccessLevelEnum.Admin}
              entity={space}
            >
              <Button
                variant="default"
                onClick={handleReset}
                disabled={!dirty || isLoading}
              >
                Reset
              </Button>
            </EntityAccess>

            <EntityAccess
              id="spaceActionsUpdate"
              minLevel={AccessLevelEnum.Admin}
              entity={space}
            >
              <Button type="submit" disabled={!dirty || isLoading}>
                Save Changes
              </Button>
            </EntityAccess>
          </Group>
        </form>
      )}
    </Formik>
  );
}

const useStyles = createStyles((theme) => ({
  formContainer: {
    display: 'grid',
    gridTemplateRows: '1fr 82px',
    height: '100%',
  },
  formContent: {
    height: '100%',
    padding: `${theme.spacing.xl} 42px 0`,
  },
  formFooter: {
    height: '100%',
    background: theme.white,
    borderTop: `1px solid ${theme.colors.gray[2]}`,
    padding: '0 42px',
  },
  locationInputWrapperRoot: {
    position: 'relative',
  },
  locationInputWrapperLabel: {
    width: '100%',
  },
}));
