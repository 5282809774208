import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError } from '@portals/redux/actions/toastr';

import { PAYMENTS_API_URL, paymentsQueryKeys } from './payments.constants';
import { fetchApiRequest, useRequestOptions } from '../../utils';
import { ordersQueryKeys } from '../orders';

export function useCancelPayment() {
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: PAYMENTS_API_URL,
    method: 'POST',
  });

  return useMutation({
    mutationFn: (paymentId: string) =>
      fetchApiRequest(`${url}/${paymentId}/archive`, options),
    onSuccess: () => {
      queryClient.invalidateQueries(ordersQueryKeys.all);
      queryClient.invalidateQueries(paymentsQueryKeys.all);
    },
    meta: {
      mutationName: 'useCancelPayment',
      baseUrl: `${PAYMENTS_API_URL}/:id/archive`,
      method: 'POST',
    },
  });
}

export function useUpdatePurchaseOrderFile() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: PAYMENTS_API_URL,
    method: 'PUT',
  });

  return useMutation({
    mutationFn: ({
      payment_id,
      purchase_order_file_url,
    }: {
      payment_id: string;
      purchase_order_file_url: string;
    }) =>
      fetchApiRequest(`${url}/${payment_id}`, {
        ...options,
        body: JSON.stringify({
          purchase_order_file_url,
        }),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(ordersQueryKeys.all);
      queryClient.invalidateQueries(paymentsQueryKeys.all);
    },
    onError: () =>
      dispatch(
        toastrError('Failed to upload Purchase Order file. Please try again')
      ),
    meta: {
      mutationName: 'useUpdatePurchaseOrderFile',
      baseUrl: `${PAYMENTS_API_URL}/:id`,
      method: 'PUT',
    },
  });
}
