import { Group, Text } from '@mantine/core';
import React from 'react';

import {
  SUBSCRIPTIONS_API_URL,
  SubscriptionSummaryType,
  useSubscriptions,
} from '@portals/api/organizations';
import {
  OrganizationInvoicePaymentStatusBadge,
  SubscriptionStatusBadge,
} from '@portals/framework';
import { ReactComponent as Gallery } from '@portals/icons/bold/gallery.svg';
import { AvatarCell, DateCell, PaginatedTable } from '@portals/table';
import {
  PaymentMethodEnum,
  ProductPricingModel,
  SubscriptionStatus,
  TableColumn,
  TableFilterTypeEnum,
} from '@portals/types';
import {
  formatPrice,
  getPaymentMethodDisplayName,
  getPricingModelDisplayName,
  getProductPriceDisplayText,
  getProductTypeDisplayName,
  mediumDateFormatter,
} from '@portals/utils';

import { SubscriptionDetailsPanel } from './subscription-details-panel/SubscriptionDetailsPanel';

const PRODUCT_NAME_COLUMN: TableColumn<SubscriptionSummaryType> = {
  dataField: 'product_name',
  text: 'Product name',
  sort: true,
  isSticky: true,
  minWidth: 350,
  maxWidth: 350,
  filter: { type: TableFilterTypeEnum.Text },
  formatter: (_, { product }) => (
    <AvatarCell
      label={product.name}
      src={product.image_url}
      radius="md"
      withAbbreviation={false}
      color="blue_gray"
    >
      <Gallery />
    </AvatarCell>
  ),
};

const STATUS_COLUMN: TableColumn<SubscriptionSummaryType> = {
  dataField: 'status',
  text: 'Status',
  sort: true,
  minWidth: 200,
  maxWidth: 200,
  formatter: (_, { status }) => <SubscriptionStatusBadge status={status} />,
  filter: {
    type: TableFilterTypeEnum.Select,
    options: {
      active: 'Active',
      pending_cancellation: 'Pending Cancellation',
    },
  },
};

const CHARGE_COLUMN: TableColumn<SubscriptionSummaryType> = {
  dataField: 'amount_in_scu',
  text: 'Price',
  sort: true,
  minWidth: 200,
  maxWidth: 200,
  formatter: (_, subscription) => {
    let basePrice;

    const price = getProductPriceDisplayText({
      pricingModel: subscription.product.pricing_model,
      currency: subscription.currency,
      usageBasedPriceInScu: subscription.usage_based_price_in_scu,
      usageBasedUnitName: subscription.product.usage_based_unit_name,
      amountInScu: subscription.amount_in_scu,
      paymentInterval: subscription.payment_interval,
    });

    // If the subscription is usage-based, we need to display the base price,
    // and the base price is the `amount_in_scu`
    if (
      subscription.product.pricing_model === ProductPricingModel.UsageBased &&
      subscription.amount_in_scu
    ) {
      basePrice = formatPrice({
        value: subscription.amount_in_scu,
        currencyCode: subscription.currency,
      });
    }

    return (
      <Group spacing="xs">
        {basePrice && <Text>{basePrice} / month +</Text>}
        <Text>{price}</Text>
      </Group>
    );
  },
};

const NEXT_BILLING_DATE_COLUMN: TableColumn<SubscriptionSummaryType> = {
  dataField: 'next_charge_at',
  text: 'Next billing date',
  minWidth: 250,
  sort: true,
  filter: { type: TableFilterTypeEnum.Date },
  formatter: (_, { next_charge_at }) =>
    mediumDateFormatter(next_charge_at, 'N/A'),
};

const PAYMENT_METHOD_COLUMN: TableColumn<SubscriptionSummaryType> = {
  dataField: 'payment_method',
  text: 'Payment method',
  sort: true,
  maxWidth: 250,
  minWidth: 250,
  formatter: (_, { payment_method }) =>
    getPaymentMethodDisplayName(payment_method),
  filter: {
    type: TableFilterTypeEnum.Select,
    options: {
      [PaymentMethodEnum.CreditCard]: getPaymentMethodDisplayName(
        PaymentMethodEnum.CreditCard
      ),
      [PaymentMethodEnum.ACHTransfer]: getPaymentMethodDisplayName(
        PaymentMethodEnum.ACHTransfer
      ),
      [PaymentMethodEnum.PurchaseOrder]: getPaymentMethodDisplayName(
        PaymentMethodEnum.PurchaseOrder
      ),
      [PaymentMethodEnum.Lab]: getPaymentMethodDisplayName(
        PaymentMethodEnum.Lab
      ),
    },
  },
};

const DATE_PLACED_COLUMN: TableColumn<SubscriptionSummaryType> = {
  dataField: 'created_at',
  text: 'Date placed',
  minWidth: 250,
  sort: true,
  filter: { type: TableFilterTypeEnum.Date },
  formatter: (_, { created_at }) => <DateCell date={created_at} />,
};

const PRODUCT_TYPE_COLUMN: TableColumn<SubscriptionSummaryType> = {
  dataField: 'product_type',
  text: 'Product type',
  sort: true,
  filter: {
    type: TableFilterTypeEnum.Select,
    options: {
      physical: 'Physical',
      device_license: 'Device license',
      platform_license: 'Platform license',
    },
  },
  formatter: (_, { product }) =>
    getProductTypeDisplayName(product.product_type),
};

const PRODUCT_CATEGORY_COLUMN: TableColumn<SubscriptionSummaryType> = {
  dataField: 'product_category',
  text: 'Product category',
  sort: true,
  filter: { type: TableFilterTypeEnum.Text },
  formatter: (_, { product }) => product.category,
};

const CANCELED_AT_COLUMN: TableColumn<SubscriptionSummaryType> = {
  dataField: 'canceled_at',
  text: 'Date canceled',
  minWidth: 250,
  formatter: (_, { canceled_at }) => <DateCell date={canceled_at} />,
};

const PRICING_MODEL_COLUMN: TableColumn<SubscriptionSummaryType> = {
  dataField: 'pricing_model',
  text: 'Pricing model',
  minWidth: 200,
  formatter: (_, { product }) =>
    getPricingModelDisplayName(product.pricing_model),
};

const LAST_INVOICE_STATUS_COLUMN: TableColumn<SubscriptionSummaryType> = {
  dataField: 'latest_invoice.status',
  text: 'Last invoice status',
  sort: true,
  filter: {
    type: TableFilterTypeEnum.Select,
    options: {
      pending: 'Pending',
      paid: 'Paid',
      failed: 'Payment failed',
      void: 'Void',
      refunded: 'Payment Refunded',
    },
  },
  formatter: (_, { latest_invoice }) => (
    <OrganizationInvoicePaymentStatusBadge status={latest_invoice?.status} />
  ),
};

export const ACTIVE_SUBSCRIPTIONS_COLUMNS: Array<
  TableColumn<SubscriptionSummaryType>
> = [
  PRODUCT_NAME_COLUMN,
  STATUS_COLUMN,
  PRICING_MODEL_COLUMN,
  CHARGE_COLUMN,
  LAST_INVOICE_STATUS_COLUMN,
  NEXT_BILLING_DATE_COLUMN,
  PAYMENT_METHOD_COLUMN,
  DATE_PLACED_COLUMN,
  PRODUCT_TYPE_COLUMN,
  PRODUCT_CATEGORY_COLUMN,
];

export const SUSPENDED_SUBSCRIPTIONS_COLUMNS: Array<
  TableColumn<SubscriptionSummaryType>
> = [
  PRODUCT_NAME_COLUMN,
  PAYMENT_METHOD_COLUMN,
  LAST_INVOICE_STATUS_COLUMN,
  DATE_PLACED_COLUMN,
  PRODUCT_TYPE_COLUMN,
  PRODUCT_CATEGORY_COLUMN,
];

export const CANCELED_SUBSCRIPTIONS_COLUMNS: Array<
  TableColumn<SubscriptionSummaryType>
> = [
  PRODUCT_NAME_COLUMN,
  CANCELED_AT_COLUMN,
  PAYMENT_METHOD_COLUMN,
  LAST_INVOICE_STATUS_COLUMN,
  DATE_PLACED_COLUMN,
  PRODUCT_TYPE_COLUMN,
  PRODUCT_CATEGORY_COLUMN,
];

interface SubscriptionsTableProps {
  statuses: SubscriptionStatus[];
  columns: Array<TableColumn<SubscriptionSummaryType>>;
}

export function SubscriptionsTable({
  statuses,
  columns,
}: SubscriptionsTableProps) {
  return (
    <PaginatedTable<SubscriptionSummaryType>
      noHeader
      keyField="id"
      name="organizations.subscriptions"
      columns={columns}
      dataHook={useSubscriptions}
      dataHookUrl={buildDataHookUrl(statuses)}
      noDataIndication={{ title: 'No subscriptions' }}
      defaultSortBy={[{ id: 'created_at', desc: true }]}
      detailsPanel={{
        type: 'page',
        renderer: ({ row, onClose }) => (
          <SubscriptionDetailsPanel
            subscriptionId={row.original.id}
            onClose={onClose}
          />
        ),
      }}
    />
  );
}

function buildDataHookUrl(statuses: SubscriptionStatus[]) {
  const queryString = statuses
    .map((status) => `q[status_in][]=${status}`)
    .join('&');

  return `${SUBSCRIPTIONS_API_URL}?${queryString}`;
}
