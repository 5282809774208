import { Paper, Stack, Text } from '@mantine/core';
import React from 'react';

import { OrderType } from './order.types';
import { useGenericContext } from '../../../components/GenericContext';

export function OrderNotesToSellerCard<TOrder extends OrderType>() {
  const order = useGenericContext<TOrder>();

  if (!order?.note) return null;

  return (
    <Paper withBorder p="xl" radius="lg">
      <Stack spacing="md">
        <Text size="md" color="gray.8">
          Notes
        </Text>

        <Text size="sm" color="gray.8" data-testid="notes-to-seller-text">
          {order?.note}
        </Text>
      </Stack>
    </Paper>
  );
}
