import { createStyles, Stack, StackProps } from '@mantine/core';
import React from 'react';

import { ProductCardDetails } from './ProductCardDetails';
import { ProductCardImage } from './ProductCardImage';

interface ProductCardProps extends StackProps {}

export function ProductCard({
  children,
  className,
  ...stackProps
}: ProductCardProps) {
  const { classes, cx } = useStyles();

  return (
    <Stack
      spacing={0}
      className={cx(classes.container, className)}
      {...stackProps}
    >
      {children}
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    overflow: 'hidden',
    border: `1px solid ${theme.colors.gray[3]}`,
    borderRadius: theme.radius.md,
    backgroundColor: theme.white,
  },
}));

ProductCard.Image = ProductCardImage;
ProductCard.Details = ProductCardDetails;
