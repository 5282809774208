import { createStyles } from '@mantine/core';
import { get, isUndefined, last } from 'lodash/fp';
import React, { FC } from 'react';

import { EmptyState } from '@portals/table';

import { WidgetProps } from './types';

function createFieldsMap(
  rest: Record<string, string>,
  numOfFields = 8
): { [key: string]: any } {
  const fieldsMap: { [key: string]: any } = {};

  for (let i = 1; i <= numOfFields; i++) {
    const trackedValue = rest[`tracked_measurement_value_${i}`];
    const trackedDisplayName = rest[`tracked_measurement_display_name_${i}`];
    const trackedColor = rest[`tracked_measurement_color_${i}`];
    const trackedTextColor = rest[`tracked_measurement_text_color_${i}`];

    fieldsMap[trackedValue] = {
      displayName: trackedDisplayName,
      color: trackedColor,
      textColor: trackedTextColor,
    };
  }
  return fieldsMap;
}

const StatusWidget: FC<WidgetProps> = ({ widget, deviceStates }) => {
  const { classes, theme } = useStyles();
  const { fields } = widget.config;
  const { name, status_measurement, ...rest } = fields;

  const fieldsMap = createFieldsMap(rest, 8);

  const value = get(['data', status_measurement], last(deviceStates));
  const currentValueConfig = get(value, fieldsMap) || {
    displayName: value,
    color: theme.colors.gray[4],
    textColor: 'white',
  };

  if (isUndefined(value)) return <EmptyState label="No data available" />;

  return (
    <div
      className={classes.container}
      style={{
        background: currentValueConfig.color,
        color: currentValueConfig.textColor || 'white',
      }}
    >
      {currentValueConfig.displayName}
    </div>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 10px',
    fontSize: 40,
    textTransform: 'uppercase',
    fontWeight: 700,
    wordBreak: 'break-word',
    textAlign: 'center',
    color: theme.white,
    transition: 'background-color 0.15s ease-in-out',
  },
}));

export default StatusWidget;
