import { Avatar, AvatarProps } from '@mantine/core';
import React from 'react';

import { DeviceModelIcon, DeviceModelIconProps } from './device-model-icon';

interface DeviceAvatarProps extends AvatarProps {
  icon?: DeviceModelIconProps['iconName'];
}

export function DeviceAvatar({ icon, ...avatarProps }: DeviceAvatarProps) {
  return (
    <Avatar radius="sm" styles={avatarStyles} {...avatarProps}>
      <DeviceModelIcon iconName={icon} />
    </Avatar>
  );
}

const avatarStyles: AvatarProps['styles'] = (theme) => ({
  placeholder: {
    backgroundColor: theme.colors.gray[1],
  },
});
