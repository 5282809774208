import { Stack } from '@mantine/core';
import React, { FC } from 'react';

import { IncidentType } from '@portals/api/organizations';
import { ReactComponent as AZ } from '@portals/icons/linear/a-z.svg';
import { ReactComponent as Calendar } from '@portals/icons/linear/calendar.svg';
import { ReactComponent as Danger } from '@portals/icons/linear/danger.svg';
import { PaginatedQueryParamsType } from '@portals/types';

import { MenuItemType } from './incidents-widget.types';
import { List } from './List';
import { ListActions } from './ListActions';

const MENU_ITEMS: Array<MenuItemType> = [
  { name: 'title', label: 'Alphabetical', icon: AZ },
  { name: 'created_at', label: 'Date', icon: Calendar },
  { name: 'priority', label: 'Severity', icon: Danger },
];

interface ListWithActionsProps {
  showSortBySeverity: boolean;
  viewAllLink: string;
  onSortByChanged: (sortBy: string) => void;
  selectedItem: string;
  itemsParams: PaginatedQueryParamsType<IncidentType>;
  endpointUrl: string;
  itemsKey: string;
  emptyStateLabel: string;
  closeModal?: () => void;
  numOfIncidents: number;
}

export const ListWithActions: FC<ListWithActionsProps> = ({
  showSortBySeverity,
  viewAllLink,
  onSortByChanged,
  selectedItem,
  itemsParams,
  endpointUrl,
  itemsKey,
  emptyStateLabel,
  closeModal,
  numOfIncidents,
}) => (
  <Stack sx={{ height: '100%' }}>
    <ListActions
      showSortBySeverity={showSortBySeverity}
      viewAllLink={viewAllLink}
      onSortByChanged={onSortByChanged}
      items={MENU_ITEMS}
      selectedItem={selectedItem}
      closeModal={closeModal}
      numOfIncidents={numOfIncidents}
    />

    <List
      itemsParams={itemsParams}
      itemsKey={itemsKey}
      endpointUrl={endpointUrl}
      emptyStateLabel={emptyStateLabel}
    />
  </Stack>
);
