import {
  Anchor,
  Avatar,
  Button,
  createStyles,
  Modal,
  ModalProps as MantineModalProps,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import React from 'react';

import {
  useConnectCecPartner,
  useOrganizationConfig,
} from '@portals/api/organizations';
import { ModalBody, ModalFooter } from '@portals/core';
import { ModalProps, PartnerAvatar } from '@portals/framework';
import { CecPartnerType } from '@portals/types';

export interface ConfirmCecConnectionModalProps
  extends ModalProps<{
    cecPartner: CecPartnerType;
    onConnectionSuccess: () => void;
  }> {}

export function ConfirmCecConnectionModal({
  closeMe,
  data: { cecPartner, onConnectionSuccess },
}: ConfirmCecConnectionModalProps) {
  const { classes } = useStyles();

  const organizationConfig = useOrganizationConfig();
  const connectCecPartner = useConnectCecPartner();

  const onConnectionClick = () => {
    connectCecPartner.mutate(
      { partnerId: cecPartner.id },
      {
        onSuccess: () => {
          closeMe();
          onConnectionSuccess();
        },
      }
    );
  };

  return (
    <Modal
      opened
      onClose={closeMe}
      styles={modalStyles}
      padding="xxl"
      title={
        <Stack align="center">
          <Avatar.Group>
            <PartnerAvatar
              size={82}
              partnerName={organizationConfig?.display_name || ''}
              src={organizationConfig?.logo_url}
              classNames={{ root: classes.avatarRoot }}
            />

            <PartnerAvatar
              size={82}
              partnerName={cecPartner.display_name}
              src={cecPartner.logo}
              classNames={{ root: classes.avatarRoot }}
            />
          </Avatar.Group>

          <Title
            weight={400}
            order={4}
            ta="center"
            data-testid="connect-with-brand-title"
          >
            Connect with {cecPartner.display_name}
          </Title>
        </Stack>
      }
    >
      <ModalBody ta="center" className={classes.modalBody}>
        <Text mb="md">
          By connecting with <b>{cecPartner.display_name}</b>, you gain access
          to their cloud services. These services may include claiming and
          managing <b>{cecPartner.display_name}</b> devices, accessing dedicated
          support, and directly purchasing their products and services. Please
          note that if you have external admin users, they will be able to see
          and manage all devices.
        </Text>

        <Text size="xs">
          Please take a moment to review the{' '}
          <Anchor
            weight={600}
            href="https://xyte.io/terms-of-service"
            target="_blank"
            rel="noopener noreferrer"
          >
            terms and conditions
          </Anchor>{' '}
          and{' '}
          <Anchor
            weight={600}
            href="https://xyte.io/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            privacy policy
          </Anchor>{' '}
          related to this connection. By connecting with{' '}
          <b>{cecPartner.display_name}</b>, you agree to abide by these terms.
        </Text>
      </ModalBody>

      <ModalFooter grow>
        <Button
          variant="default"
          onClick={closeMe}
          data-testid="modal-close-button"
        >
          Cancel
        </Button>
        <Button
          loading={connectCecPartner.isLoading}
          onClick={onConnectionClick}
          data-testid="modal-connect-button"
        >
          Connect
        </Button>
      </ModalFooter>
    </Modal>
  );
}

const useStyles = createStyles((theme) => ({
  avatarRoot: {
    border: `1px solid ${theme.colors.gray[3]}`,
  },
  modalBody: {
    b: {
      fontWeight: 700,
    },
  },
}));

const modalStyles: MantineModalProps['styles'] = (theme) => ({
  close: { alignSelf: 'flex-start' },
  title: { flexGrow: 1 },
  header: { paddingBottom: theme.spacing.md },
});
