import { Tooltip } from '@mantine/core';
import { AnimatePresence, motion } from 'framer-motion';
import { find, flow, get, set, unset } from 'lodash/fp';
import React, { useCallback, useRef, useState } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import styled from 'styled-components';

import {
  SpaceType,
  useSpaces,
  useUpdateSpace,
} from '@portals/api/organizations';
import { UploadImageModalProps } from '@portals/framework/modals';
import { ReactComponent as CloseCircle } from '@portals/icons/linear/close-circle.svg';
import { ReactComponent as CloseX } from '@portals/icons/linear/close-x.svg';
import { ReactComponent as Export4 } from '@portals/icons/linear/export-4.svg';
import { ReactComponent as Gallery } from '@portals/icons/linear/gallery.svg';
import { ReactComponent as Import3 } from '@portals/icons/linear/import-3.svg';
import { useOpenModal } from '@portals/redux';
import { getStyledThemeColor, useOnClickOutside } from '@portals/utils';

import { canEdit } from '../../../../lib/access';

type SpaceImageMenuProps = {
  space: SpaceType;
};

export function UpdateCoverPhotoToggle({ space }: SpaceImageMenuProps) {
  const toggleRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const openModal = useOpenModal();

  const spaces = useSpaces();
  const updateSpace = useUpdateSpace();

  const isRoot = space.parent_id === null;
  const isImageInherited = get(['config', 'inherit_img'], space);
  const canUpload = canEdit(space);

  const onUpdate = useCallback(
    (img: string) => {
      const updatedSpace = flow([
        set(['config', 'img'], img),
        set(['config', 'inherit_img'], false),
      ])(space);

      updateSpace.mutate({ spaceId: space.id, updatedSpace });
    },
    [space, updateSpace]
  );

  const onUpload = useCallback(() => {
    openModal<UploadImageModalProps['data']>('UploadImageModal', {
      cropConfig: {
        aspectRatio: 10,
        height: 200,
        width: 2000,
      },
      onUpload: onUpdate,
    });
  }, [onUpdate, openModal]);

  const onRemove = useCallback(() => {
    let updatedSpace;

    if (isImageInherited) {
      updatedSpace = set(['config', 'inherit_img'], false, space);
    } else {
      updatedSpace = unset(['config', 'img'], space);
    }

    updateSpace.mutate({ spaceId: space.id, updatedSpace });
  }, [isImageInherited, space, updateSpace]);

  const onInherit = useCallback(() => {
    const parent =
      space.parent_id !== null && find({ id: space.parent_id }, spaces.data);

    if (!parent) return;

    const parentImage = get(['config', 'img'], parent);

    const updatedSpace = flow([
      set(['config', 'img'], parentImage),
      set(['config', 'inherit_img'], true),
    ])(space);

    updateSpace.mutate({ spaceId: space.id, updatedSpace });
  }, [space, spaces, updateSpace]);

  useOnClickOutside(toggleRef, () => setIsOpen(false), canUpload);

  return canUpload ? (
    <ToggleUpload
      ref={toggleRef}
      $isOpen={isOpen}
      onClick={() => setIsOpen((curr) => !curr)}
      id="upload-space-image"
      title="Upload background image"
    >
      <div className="position-relative">
        {isOpen ? <CloseX /> : <Gallery />}

        <AnimatePresence>
          {isOpen ? (
            <>
              <SubToggle
                id="space-image-upload"
                onClick={onUpload}
                initial={{ opacity: 0, top: 0, left: 0 }}
                exit={{ opacity: 0, top: 0, left: 0 }}
                animate={{ opacity: 1, top: 0, left: -45 }}
              >
                <UncontrolledTooltip
                  placement="left"
                  target="space-image-upload"
                >
                  Upload
                </UncontrolledTooltip>
                <Export4 width={18} height={18} />
              </SubToggle>

              {get(['config', 'img'], space) ? (
                <SubToggle
                  onClick={onRemove}
                  id="space-image-remove"
                  initial={{ opacity: 0, top: 0, left: 0 }}
                  exit={{ opacity: 0, top: 0, left: 0 }}
                  animate={{ opacity: 1, top: 35, left: -25 }}
                >
                  <UncontrolledTooltip
                    placement="bottom"
                    target="space-image-remove"
                  >
                    {isImageInherited ? 'Remove inheritance' : 'Remove image'}
                  </UncontrolledTooltip>
                  <CloseCircle width={18} height={18} />
                </SubToggle>
              ) : null}

              {!isRoot && !get(['config', 'inherit_img'], space) ? (
                <Tooltip label="Inherit from parent">
                  <SubToggle
                    onClick={onInherit}
                    id="space-image-inherit"
                    initial={{ opacity: 0, top: 0, left: 0 }}
                    exit={{ opacity: 0, top: 0, left: 0 }}
                    animate={{ opacity: 1, top: 35, left: 15 }}
                  >
                    <Import3 width={18} height={18} className="upload-icon" />
                  </SubToggle>
                </Tooltip>
              ) : null}
            </>
          ) : null}
        </AnimatePresence>
      </div>
    </ToggleUpload>
  ) : null;
}

const Toggle = styled.div`
  position: absolute;
  color: ${getStyledThemeColor('dark')};
  padding: 7px;
  background-color: ${getStyledThemeColor('gray150')};
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  opacity: 1;
  top: 18px;
`;

const ToggleUpload = styled(Toggle)<{ $isOpen: boolean }>`
  right: 18px;
  transition: opacity 0.15s ease-in-out;

  ${({ $isOpen }) =>
    $isOpen &&
    `
    opacity: 1 !important;
  `}
`;

const SubToggle = styled(motion.div)`
  width: 30px;
  height: 30px;
  background-color: ${getStyledThemeColor('white')};
  border-radius: 50%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;
