import {
  PartnerFeatureFlagEnum,
  CustomerFeatureFlagEnum,
  PartnerFeatureFlagsType,
  PartnerFeatureFlagsKeys,
} from '@portals/types';

export function isPartnerFeatureOn(
  featureFlags: PartnerFeatureFlagsType | undefined,
  featureName: PartnerFeatureFlagsKeys
) {
  return featureFlags?.[featureName] === PartnerFeatureFlagEnum.On;
}

export function isPartnerFeatureOff(
  featureFlags: PartnerFeatureFlagsType,
  featureName: PartnerFeatureFlagsKeys
) {
  return featureFlags?.[featureName] === PartnerFeatureFlagEnum.Off;
}

export function isCustomerFeatureAll(
  featureFlagValue: CustomerFeatureFlagEnum | undefined
) {
  return featureFlagValue === CustomerFeatureFlagEnum.All;
}

export function isCustomerFeatureLabOnly(
  featureFlagValue: CustomerFeatureFlagEnum | undefined,
  isLabUser: boolean | undefined
) {
  if (!isLabUser) return false;

  return featureFlagValue === CustomerFeatureFlagEnum.LabOnly;
}

export function isCustomerFeatureOff(
  featureFlagValue: CustomerFeatureFlagEnum
) {
  return featureFlagValue === CustomerFeatureFlagEnum.Off;
}
