import { Group } from '@mantine/core';
import React, { FC } from 'react';

import { ReactComponent as MonitorMobbile } from '@portals/icons/linear/monitor-mobbile.svg';
import { ReactComponent as ToggleOnCircle } from '@portals/icons/linear/toggle-on-circle.svg';

import { DevicesBox } from './DevicesBox';
import { useCurrentSpace } from '../../../../../pages/overview/overview.hooks';

const BOXES_DATA = [
  {
    id: 'devices',
    local_id: 'local_devices',
    name: 'Claimed Devices',
    Icon: MonitorMobbile,
  },
  {
    id: 'online_devices',
    local_id: 'local_online_devices',
    name: 'Online Devices',
    Icon: ToggleOnCircle,
  },
];

interface DevicesBoxesProps {
  isLocal?: boolean;
}

const DevicesBoxes: FC<DevicesBoxesProps> = ({ isLocal }) => {
  const space = useCurrentSpace();

  if (!space) return null;

  return (
    <Group>
      {BOXES_DATA.map((boxData) => (
        <DevicesBox
          key={boxData.id}
          name={boxData.name}
          Icon={boxData.Icon}
          counter={space?.state?.[isLocal ? boxData.local_id : boxData.id] || 0}
        />
      ))}
    </Group>
  );
};

export default DevicesBoxes;
