import {
  BackgroundImage,
  Box,
  Center,
  createStyles,
  LoadingOverlay,
  Stack,
  Text,
} from '@mantine/core';
import React, { ReactNode } from 'react';

import { PortalConfigType, usePortalConfig } from '@portals/api/organizations';
import { PoweredByXyteIcon } from '@portals/assets';
import { useAppConfig } from '@portals/framework/context';

import { AuthDesktopLayoutCEC } from './AuthDesktopLayoutCEC';

interface AuthDesktopLayoutProps {
  children: ReactNode;
}

export function AuthDesktopLayout({ children }: AuthDesktopLayoutProps) {
  const { extraLayout } = useAppConfig();

  const portalConfig = usePortalConfig();

  const signInPageDesign = portalConfig.data?.design?.sign_in_page;

  const { classes, theme } = useStyles(signInPageDesign);

  if (!portalConfig.isFetched || !portalConfig.data) {
    return <LoadingOverlay visible />;
  }

  if (portalConfig.data?.cec_enabled) {
    return <AuthDesktopLayoutCEC>{children}</AuthDesktopLayoutCEC>;
  }

  const logoSrc = signInPageDesign?.logo;

  const backgroundColor =
    signInPageDesign?.background_color || theme.colors.blue_accent[4];

  const backgroundImage =
    signInPageDesign?.background_mode === 'image' &&
    signInPageDesign?.background_image
      ? signInPageDesign?.background_image
      : '';

  const loginImage = signInPageDesign?.login_image || 'logo_full_white.svg';
  const tagline = signInPageDesign?.tagline;
  const showXyteCredits = signInPageDesign?.powered_by_xyte_label !== false;

  return (
    <>
      {extraLayout?.globalLayout?.()}

      <div className={classes.container}>
        <Box bg={backgroundColor}>
          <BackgroundImage src={backgroundImage} h="100%">
            <Stack align="center" p="xxl" h="100%">
              {logoSrc && (
                <img
                  width={50}
                  height={50}
                  src={logoSrc}
                  alt="logo"
                  className={classes.logo}
                />
              )}

              <Stack
                justify="center"
                spacing="xxl"
                className={classes.centerContent}
              >
                <img width="100%" src={loginImage} alt="login logo" />

                {tagline && <Text className={classes.tagline}>{tagline}</Text>}
              </Stack>

              {showXyteCredits && (
                <PoweredByXyteIcon className={classes.xyteCredits} />
              )}
            </Stack>
          </BackgroundImage>
        </Box>

        <Center p="xxl">
          <Box maw={470}>{children}</Box>
        </Center>
      </div>
    </>
  );
}

const useStyles = createStyles(
  (theme, signInPageDesign: PortalConfigType['design']['sign_in_page']) => ({
    container: {
      display: 'grid',
      gridTemplateColumns: '1fr 2fr',
      minHeight: '100%',
      backgroundColor: theme.white,

      [theme.fn.smallerThan('md')]: {
        gridTemplateColumns: '1fr',
      },
    },
    logo: {
      alignSelf: 'flex-start',
    },
    centerContent: {
      flexGrow: 1,
      maxWidth: 305,
    },
    tagline: {
      fontSize: 25,
      fontWeight: 600,
      textAlign: 'center',
      wordBreak: 'break-word',
      color: signInPageDesign?.tagline_color || theme.white,
    },
    xyteCredits: {
      mixBlendMode: signInPageDesign?.background_color ? 'difference' : 'unset',
    },
  })
);
