import { ReactNode } from 'react';

export interface SettingPage {
  id: string;
  icon: ReactNode;
  title: string;
  description: string;
  path: string;
  component: ReactNode;
  visible?: boolean;
  isRouteBlocked?: boolean;
  ribbon?: ReactNode;
  badge?: ReactNode;
}

export const SETTING_PAGE_ID_URL_PARAM_NAME = 'settingPageId';
