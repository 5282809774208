import * as CURRENCIES from '@dinero.js/currencies';
import {
  dinero,
  Dinero,
  halfUp,
  toFormat,
  toSnapshot,
  toUnit,
  Transformer,
  trimScale,
} from 'dinero.js';

import { CurrencyCode } from '@portals/types';

export const intlFormat = (
  dineroObject: Dinero<number>,
  locale: string = 'en-US'
) => {
  const transformer: Transformer<number> = ({ amount, currency }) =>
    amount.toLocaleString(locale, {
      style: 'currency',
      currency: currency.code,
      maximumFractionDigits: 2,
    });

  return toFormat(dineroObject, transformer);
};

export const formatCurrency = (value: number = 0, currency: string = 'USD') => {
  try {
    const dineroObject = dinero({
      amount: value,
      currency: CURRENCIES[currency],
    });

    return intlFormat(dineroObject);
  } catch (err) {
    console.error(
      `Failed to parse currency - value: ${value}, currency: ${currency}`
    );

    return 'Invalid amount';
  }
};

export function getCurrencySign(currencyCode: CurrencyCode) {
  try {
    const numberFormat = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: currencyCode,
      currencyDisplay: 'narrowSymbol',
    });
    const parts = numberFormat.formatToParts();

    const currencyPart = parts.find(
      (part) => part.type === 'currency'
    ) as Intl.NumberFormatPart;

    return currencyPart.value;
  } catch (e) {
    console.error(e);
    return '';
  }
}

export const convertFromMajorToMinor = (
  amount: number,
  currency: CurrencyCode
) => {
  if (amount === 0) return amount;

  const { exponent, base } = CURRENCIES[currency];

  const d = dinero({
    amount: halfUp(amount * Math.pow(base, exponent)),
    currency: CURRENCIES[currency],
  });

  return toSnapshot(trimScale(d)).amount;
};

export const convertFromMinorToMajor = (
  amount: number,
  currency: CurrencyCode
) => {
  if (amount === 0) return amount;

  const d = dinero({
    amount,
    currency: CURRENCIES[currency],
  });

  return toUnit(d, { digits: 2, round: (value) => value });
};

export function formatNumber(
  value: number | undefined,
  options?: Intl.NumberFormatOptions & { fallbackValue?: string | number }
) {
  if (value === undefined) {
    return options?.fallbackValue ?? '--';
  }

  const formatter = new Intl.NumberFormat(undefined, options);

  return formatter.format(value);
}

interface FormatPriceParams {
  value: number | null | undefined;
  currencyCode: CurrencyCode;
  shouldConvertFromMinorToMajor?: boolean;
  withCurrencySign?: boolean;
}
export function formatPrice({
  value,
  currencyCode,
  shouldConvertFromMinorToMajor = true,
  withCurrencySign = true,
}: FormatPriceParams) {
  const currencySign = getCurrencySign(currencyCode);

  const emptyResult = withCurrencySign ? `${currencySign}--` : '--';

  if (value === undefined || value === null) {
    return emptyResult;
  }

  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: 'narrowSymbol',
  });

  let valueToFormat;

  if (shouldConvertFromMinorToMajor) {
    try {
      valueToFormat = convertFromMinorToMajor(value, currencyCode);
    } catch (e) {
      console.error(e);
      return emptyResult;
    }
  } else {
    valueToFormat = value;
  }

  const formattedValue = formatter.format(valueToFormat);

  if (withCurrencySign) {
    return formattedValue;
  } else {
    return formattedValue.replace(currencySign, '');
  }
}

export function abbreviateNumber(value: number) {
  return Intl.NumberFormat(undefined, {
    notation: 'compact',
    maximumFractionDigits: 1,
  }).format(value);
}
