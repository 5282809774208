import { Anchor, Stack } from '@mantine/core';
import React from 'react';
import { Link } from 'react-router-dom';

import { PurchasedProductType } from '@portals/api/organizations';
import { DetailsPanel, TextEditor, TextEditorProps } from '@portals/core';
import {
  DetailsList,
  DetailsListProps,
  ProductCategoryBadge,
} from '@portals/framework';
import { prettyTime, getProductTypeDisplayName } from '@portals/utils';

interface OverviewTabProps {
  purchasedProduct: PurchasedProductType;
}

export function OverviewTab({ purchasedProduct }: OverviewTabProps) {
  const infoDetailsListItems: DetailsListProps['items'] = [
    {
      label: 'Category',
      value: purchasedProduct.product.category ? (
        <ProductCategoryBadge category={purchasedProduct.product.category} />
      ) : (
        '--'
      ),
    },
    {
      label: 'Product type',
      value: getProductTypeDisplayName(purchasedProduct.product.product_type),
    },
    {
      label: 'Device',
      value: !purchasedProduct.license?.device_name ? (
        'No device'
      ) : (
        <Anchor
          component={Link}
          to={`/m/device/${purchasedProduct.license?.device_id}`}
        >
          {purchasedProduct.license?.device_name}
        </Anchor>
      ),
    },
    {
      label: 'Purchased at',
      value: prettyTime(purchasedProduct.created_at),
    },
    {
      label: 'Seller',
      value: purchasedProduct.seller.display_name,
    },
    {
      label: 'ID',
      value: purchasedProduct.id,
    },
  ];

  if (purchasedProduct.sn) {
    infoDetailsListItems.push({
      label: 'Serial number',
      value: purchasedProduct.sn,
    });
  }

  return (
    <Stack spacing={32}>
      <DetailsList title="Information" items={infoDetailsListItems} />

      <DetailsPanel.Section title="Description">
        <TextEditor
          styles={textEditorStyles}
          editorParams={{
            editable: false,
            content: purchasedProduct.product.description,
          }}
        />
      </DetailsPanel.Section>
    </Stack>
  );
}

const textEditorStyles: TextEditorProps['styles'] = (theme) => ({
  root: {
    border: 'none',
  },
  typographyStylesProvider: {
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[8],
  },
  content: {
    '.ProseMirror': {
      paddingBlock: 0,
      paddingLeft: 0,
    },
  },
});
