import React from 'react';

import { ReactComponent as Bold } from './bold.svg';
import { ReactComponent as BulletList } from './bullet-list.svg';
import { ReactComponent as Italic } from './italic.svg';
import { ReactComponent as Link } from './link.svg';
import { ReactComponent as OrderedList } from './ordered-list.svg';
import { ReactComponent as Strikethrough } from './strikethrough.svg';
import { ReactComponent as Underline } from './underline.svg';

export const ItalicIcon = () => <Italic />;
export const BoldIcon = () => <Bold />;
export const UnderlineIcon = () => <Underline />;
export const StrikethroughIcon = () => <Strikethrough />;
export const LinkIcon = () => <Link />;
export const BulletListIcon = () => <BulletList />;
export const OrderedListIcon = () => <OrderedList />;
