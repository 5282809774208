import { isEmpty } from 'lodash/fp';
import { createSelector, Selector } from 'reselect';

import { StateType } from '@portals/types';

export const getModals = (state: StateType): StateType['modals'] =>
  state.modals;

export const getIsModalOpen: Selector<StateType, boolean> = createSelector(
  getModals,
  (modals) => !isEmpty(modals)
);
