import { useMantineTheme } from '@mantine/core';
import React, { useMemo } from 'react';

import { WidgetColorType } from '../../widgets.types';

const BARS_ARRAY = new Array(100).fill(null);

export interface SegmentedRangeBarsProps {
  segmentsMax: Array<number>;
  segmentsColors: Array<WidgetColorType>;
  globalRange: { min: number; max: number };
}

export function SegmentedRangeBars({
  segmentsMax,
  segmentsColors,
  globalRange,
}: SegmentedRangeBarsProps) {
  const theme = useMantineTheme();

  const bars = useMemo(() => {
    const getBarColor = (adjustedPercentage: number) => {
      const relativePercentage =
        ((adjustedPercentage - globalRange.min) /
          (globalRange.max - globalRange.min)) *
        100;

      const barSegmentIndex = segmentsMax.findIndex((segmentMax, index) => {
        const prevSegmentMax = segmentsMax?.[index - 1] || 0;

        return (
          relativePercentage >= prevSegmentMax &&
          relativePercentage < segmentMax
        );
      });

      return barSegmentIndex !== -1
        ? theme.fn.themeColor(segmentsColors[barSegmentIndex])
        : theme.colors.gray[4];
    };

    const getAdjustedPercentage = (index: number) => {
      return (
        (index / BARS_ARRAY.length) * (globalRange.max - globalRange.min) +
        globalRange.min
      );
    };

    return BARS_ARRAY.map((_, index) => {
      const adjustedPercentage = getAdjustedPercentage(index);
      return (
        <rect
          key={index}
          x={index * 4}
          y="0"
          width="2"
          height="16"
          rx="1"
          fill={getBarColor(adjustedPercentage)}
        />
      );
    });
  }, [segmentsColors, segmentsMax, theme.colors.gray, theme.fn, globalRange]);

  return (
    <svg
      width="100%"
      height="17px"
      viewBox="0 0 400 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <g>
        <rect width="400" height="16" transform="translate(0 0)" fill="white" />

        {bars}
      </g>
    </svg>
  );
}
