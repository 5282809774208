import {
  createStyles,
  Input,
  NumberInput,
  NumberInputProps,
  Select,
  SelectProps,
  Stack,
  Textarea,
  TextareaProps,
  TextInput,
  TextInputProps,
} from '@mantine/core';
import { DropzoneProps } from '@mantine/dropzone';
import { isNumber } from 'lodash/fp';
import React, { ReactNode, useEffect, useRef, useState } from 'react';

import { FileUploaderDropzone, useFileUploaderDropzone } from '@portals/core';
import {
  FileFieldType,
  NumberFieldType,
  PostPurchaseParamsStepFieldType,
  SelectFieldType,
  TextareaFieldType,
  TextFieldType,
} from '@portals/types';

interface BaseFieldProps {
  field: PostPurchaseParamsStepFieldType;
  value: string | number;
  withErrors: boolean;
  inputProps?: Partial<
    | TextInputProps
    | TextareaProps
    | SelectProps
    | NumberInputProps
    | DropzoneProps
  >;
}

interface FieldWrapperProps {
  field: PostPurchaseParamsStepFieldType;
  value: string | number;
  withErrors: boolean;
  children: (params: { error: boolean }) => ReactNode;
  inFocus?: boolean;
  localError?: string;
}

export function FieldWrapper({
  withErrors,
  localError,
  field,
  children,
  value,
  inFocus,
}: FieldWrapperProps) {
  const error =
    !!localError ||
    (withErrors && field.required && !value && !isNumber(value));
  const ref = useRef<HTMLDivElement>(null);

  const onRef = (node: HTMLDivElement) => {
    if (ref.current) return;

    ref.current = node;

    if (node && inFocus) {
      setTimeout(() =>
        node.scrollIntoView({ behavior: 'smooth', block: 'center' })
      );
    }
  };

  const getLabel = () => {
    let label = field.label;

    if (field.type === 'number') {
      const { min, max } = field.configuration;

      if (isNumber(min) && isNumber(max)) {
        label += ` (${min} - ${max})`;
      } else if (isNumber(min)) {
        label += ` (min: ${min})`;
      } else if (isNumber(max)) {
        label += ` (max: ${max})`;
      }
    }

    return label;
  };

  return (
    <Input.Wrapper ref={onRef}>
      <Stack spacing="xs">
        <Input.Label
          required={field.required}
          mb={0}
          c={error ? 'red.4' : 'gray.7'}
        >
          {getLabel()}
        </Input.Label>

        {field.description ? (
          <Input.Description c={error ? 'red.4' : 'gray.7'}>
            {field.description}
          </Input.Description>
        ) : null}

        {children({ error: error })}

        {error ? (
          <Input.Error>{localError || 'This field is required'}</Input.Error>
        ) : null}
      </Stack>
    </Input.Wrapper>
  );
}

interface TextFieldProps extends BaseFieldProps {
  field: TextFieldType;
  value: string;
  onChange: (value: string) => void;
  inputProps: Partial<TextInputProps>;
}

export function TextField({
  field,
  value = '',
  onChange,
  withErrors,
  inputProps = {},
}: TextFieldProps) {
  return (
    <FieldWrapper
      field={field}
      withErrors={withErrors}
      value={value}
      inFocus={Boolean(inputProps?.autoFocus)}
    >
      {({ error }) => (
        <TextInput
          value={value}
          onChange={(event) => onChange(event.target.value)}
          required={field.required}
          error={error}
          {...inputProps}
        />
      )}
    </FieldWrapper>
  );
}

interface NumberFieldProps extends BaseFieldProps {
  field: NumberFieldType;
  value: number;
  onChange: (value: number) => void;
  inputProps: Partial<NumberInputProps>;
}

export function NumberField({
  field,
  value,
  onChange,
  withErrors,
  inputProps = {},
}: NumberFieldProps) {
  const [localError, setLocalError] = useState(null);

  const onBlur = () => {
    if (!isNumber(value)) {
      onChange(undefined);

      return;
    }

    const hasMin = isNumber(field.configuration.min);
    const hasMax = isNumber(field.configuration.max);

    if (!hasMin && !hasMax) return;

    if (hasMin && hasMax) {
      if (value < field.configuration.min || value > field.configuration.max) {
        onChange(undefined);

        setLocalError(
          `Value must be between ${field.configuration.min} and ${field.configuration.max}`
        );
      }
    } else if (hasMin) {
      if (value < field.configuration.min) {
        onChange(undefined);

        setLocalError(
          `Value must be greater than or equal to ${field.configuration.min}`
        );
      }
    } else {
      if (value > field.configuration.max) {
        onChange(undefined);

        setLocalError(
          `Value must be less than or equal to ${field.configuration.max}`
        );
      }
    }
  };

  return (
    <FieldWrapper
      field={field}
      withErrors={withErrors}
      localError={localError}
      value={value}
      inFocus={Boolean(inputProps?.autoFocus)}
    >
      {({ error }) => (
        <NumberInput
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={() => setLocalError(null)}
          min={field.configuration.min ?? undefined}
          max={field.configuration.max ?? undefined}
          error={error}
          type="number"
          {...inputProps}
        />
      )}
    </FieldWrapper>
  );
}

interface SelectFieldProps extends BaseFieldProps {
  field: SelectFieldType;
  value: string;
  onChange: (value: string) => void;
  inputProps?: Partial<SelectProps>;
}

export function SelectField({
  field,
  value = null,
  onChange,
  withErrors,
  inputProps = {},
}: SelectFieldProps) {
  return (
    <FieldWrapper
      field={field}
      withErrors={withErrors}
      value={value}
      inFocus={Boolean(inputProps?.autoFocus)}
    >
      {({ error }) => (
        <Select
          data={field.configuration.options}
          value={value}
          onChange={(value) => onChange(value)}
          withinPortal
          error={error}
          clearable={!field.required}
          {...inputProps}
        />
      )}
    </FieldWrapper>
  );
}

interface TextareaFieldProps extends BaseFieldProps {
  field: TextareaFieldType;
  value: string;
  onChange: (value: string) => void;
  inputProps?: Partial<TextareaProps>;
}

export function TextareaField({
  field,
  value = '',
  onChange,
  withErrors,
  inputProps = {},
}: TextareaFieldProps) {
  return (
    <FieldWrapper
      field={field}
      withErrors={withErrors}
      value={value}
      inFocus={Boolean(inputProps?.autoFocus)}
    >
      {({ error }) => (
        <Textarea
          value={value}
          onChange={(event) => onChange(event.target.value)}
          minRows={5}
          error={error}
          {...inputProps}
        />
      )}
    </FieldWrapper>
  );
}

export interface FileFieldProps extends BaseFieldProps {
  field: FileFieldType;
  value: string;
  onChange: (value: string) => void;
  inputProps?: Partial<DropzoneProps>;
}

export function FileField({
  field,
  value,
  onChange,
  withErrors,
  inputProps = {},
}: FileFieldProps) {
  const { classes, cx } = useFileFieldStyles();

  const filesDropzone = useFileUploaderDropzone({
    initialFileUrls: value ? [value] : undefined,
  });

  const onDeleteUploadedFile = () => {
    filesDropzone.setFiles([]);
    filesDropzone.setUploadedFileUrls([]);

    onChange(null);
  };

  useEffect(() => {
    const uploadedFile = filesDropzone.files?.[0]?.fileUrl;

    if (uploadedFile && value !== uploadedFile) {
      onChange(uploadedFile);
    }
  }, [filesDropzone.files, onChange, value]);

  return (
    <FieldWrapper
      field={field}
      withErrors={withErrors}
      value={value}
      inFocus={Boolean(inputProps?.autoFocus)}
    >
      {({ error }) => (
        <FileUploaderDropzone
          files={filesDropzone.files}
          setFiles={filesDropzone.setFiles}
          uploadedFileUrls={filesDropzone.uploadedFileUrls}
          onDeleteUploadedFile={onDeleteUploadedFile}
          onDeletePendingFile={onDeleteUploadedFile}
          dropzoneProps={{
            maxFiles: 1,
            maxSize: 10 * 1024 * 1024,
            className: cx({
              [classes.dropzoneError]: error,
            }),
            ...inputProps,
          }}
        />
      )}
    </FieldWrapper>
  );
}

const useFileFieldStyles = createStyles((theme) => ({
  dropzoneError: {
    borderColor: theme.colors.red[4],
  },
}));
