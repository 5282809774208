import { MultiSelect } from '@mantine/core';
import { isEmpty } from 'lodash/fp';
import React, { FC, useMemo } from 'react';

import { FieldRendererProps } from '@portals/types';

import { normalizeSelectFieldOptions } from './select-field.utils';

export const MultiSelectField: FC<FieldRendererProps> = ({
  error,
  field,
  value,
  handleBlur,
  clearable,
  disabled,
  setFieldValue,
  readOnly,
  required,
}) => {
  const { name, title, options, placeholder, inputProps = {} } = field;
  const { startAdornment, endAdornment, ...restInputProps } = inputProps || {};

  const adjustedOptions = useMemo(() => {
    return normalizeSelectFieldOptions(options);
  }, [options]);

  return (
    <MultiSelect
      searchable
      data-testid={'multi-select-' + name}
      error={error}
      withinPortal={true}
      data={adjustedOptions}
      required={required}
      readOnly={readOnly}
      clearable={clearable}
      onChange={(value) => {
        // mantine sets value to an empty array after selecting and clearing a multiselect
        // field, which makes the "required" functionality to pass although the value is "empty".
        // so, we're setting the value to `undefined` for the "required" constraint to work properly.
        const adjustedValue = required && isEmpty(value) ? undefined : value;
        setFieldValue?.(name, adjustedValue);
      }}
      disabled={disabled || readOnly}
      placeholder={placeholder || title}
      label={title}
      id={name}
      value={value}
      onBlur={handleBlur}
      icon={startAdornment}
      rightSection={endAdornment}
      dropdownPosition="flip"
      {...restInputProps}
    />
  );
};
