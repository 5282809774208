import {
  Box,
  createStyles,
  LoadingOverlay,
  ScrollArea,
  Stack,
} from '@mantine/core';
import { useDidUpdate } from '@mantine/hooks';
import { find } from 'lodash/fp';
import React, { useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import {
  useDevice,
  useNewDeviceWidgets,
  useSpaces,
} from '@portals/api/organizations';

import { DeviceHeader } from './device-header/DeviceHeader';
import { DeviceCommands } from './device-tabs-content/device-commands/DeviceCommands';
import { DeviceDetails } from './device-tabs-content/device-details/DeviceDetails';
import { DeviceOverview } from './device-tabs-content/device-overview/DeviceOverview';
import { DeviceStateWrapper } from './device-tabs-content/device-state/DeviceState';
import { DeviceTab } from './device.types';
import { PageSpinner } from '../../../../components/layout/Page';

export function Device() {
  const spaces = useSpaces();
  const params = useParams<{ device_id: string }>();
  const device = useDevice(params.device_id, {
    refetchInterval: 5000,
  });
  const deviceWidgets = useNewDeviceWidgets(device.data?.partner?.type_id);
  const [tab, setTab] = useState<DeviceTab>('general');

  const { classes } = useStyles();

  useDidUpdate(() => {
    setTab('general');
  }, [device.data?.id]);

  if (!device.isFetched || !spaces.isFetched || !deviceWidgets.isFetched)
    return <PageSpinner />;

  const space = find({ id: device?.data?.space_id }, spaces.data);

  if (!device) return <Navigate to="/devices" replace />;

  const getCurrentTab = () => {
    switch (tab) {
      case 'overview':
        return <DeviceOverview device={device.data} />;

      case 'commands':
        return <DeviceCommands device={device.data} />;

      case 'state':
        return <DeviceStateWrapper device={device.data} />;

      case 'general':
      default:
        return <DeviceDetails device={device.data} />;
    }
  };

  if (!device.data || !space) {
    return <LoadingOverlay visible />;
  }

  return (
    <Box h="100%">
      <ScrollArea>
        <Stack className={classes.container}>
          <DeviceHeader
            device={device.data}
            space={space}
            tab={tab}
            setTab={setTab}
          />

          {getCurrentTab()}
        </Stack>
      </ScrollArea>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
    gridTemplateColumns: '1fr',
    height: 'calc(100dvh - 64px)',
    gap: 0,
    position: 'relative',
  },
}));
