import { Text } from '@mantine/core';
import React from 'react';

import StatsBoxes from '../../../../../components/Dashboard/Widgets/ModernWidgets/StatsBoxes';
import { useOverviewContext } from '../../../overview.context';
import { WidgetCardWrapper } from '../common/WidgetCardWrapper';

export function LegacyStatsBoxes() {
  const overviewContext = useOverviewContext();

  return (
    <WidgetCardWrapper
      paperProps={{
        sx: {
          overflow: 'hidden',
        },
      }}
      header={
        <Text size="md" color="gray.5">
          Incidents Summary
        </Text>
      }
    >
      <StatsBoxes isLocal={overviewContext.isLocalDataLevel} />
    </WidgetCardWrapper>
  );
}
