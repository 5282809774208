import {
  Badge,
  BadgeProps,
  Button,
  createStyles,
  Group,
  HoverCard,
  HoverCardProps,
  List,
  ListProps,
  Paper,
  Stack,
  Text,
} from '@mantine/core';
import React from 'react';
import { Link } from 'react-router-dom';

import { OrganizationOrderItemType } from '@portals/api/organizations';
import { ReactComponent as QuestionCircle } from '@portals/icons/linear/question-circle.svg';

interface AssignLicenseToDeviceInfoProps {
  deviceModelName: OrganizationOrderItemType['product']['device_model_name'];
  withinPortal?: boolean;
}

export function AssignLicenseToDeviceInfo({
  deviceModelName,
  withinPortal = true,
}: AssignLicenseToDeviceInfoProps) {
  const { classes } = useStyles();

  return (
    <Badge styles={badgeStyles} size="xl" radius="md" px="sm">
      <HoverCard
        withinPortal={withinPortal}
        withArrow
        arrowSize={20}
        position="top"
        styles={hoverCardStyles}
      >
        <HoverCard.Target>
          <Group
            noWrap
            spacing={4}
            data-testid="how-to-assign-this-license-title"
          >
            <Text>How to assign this license to a device</Text>

            <QuestionCircle className={classes.badgeIcon} />
          </Group>
        </HoverCard.Target>

        <HoverCard.Dropdown p="xl">
          <Paper>
            <Stack align="flex-start">
              <Text color="blue_gray.9" fz="lg" fw={600}>
                How to assign this license to a device
              </Text>

              <List type="ordered" styles={listStyles}>
                <List.Item>Select a device.</List.Item>

                <List.Item>
                  In the device window select the "Licenses" tab.
                </List.Item>

                <List.Item>Click "Assign new license."</List.Item>

                <List.Item>
                  Select the license from the list and click "Assign".
                </List.Item>
              </List>

              <Button
                data-testid="go-to-your-devices-button"
                variant="default"
                component={Link}
                to={
                  deviceModelName
                    ? `/devices/?q[device_model_name_i_cont]=${deviceModelName}`
                    : '/devices'
                }
                className={classes.devicesButton}
              >
                Go to your devices
              </Button>
            </Stack>
          </Paper>
        </HoverCard.Dropdown>
      </HoverCard>
    </Badge>
  );
}

const useStyles = createStyles((theme) => ({
  devicesButton: {
    '&:hover': {
      textDecoration: 'none',
      color: theme.black,
    },
  },

  badgeIcon: {
    color: theme.colors.gray[5],
  },
}));

const badgeStyles: BadgeProps['styles'] = (theme) => ({
  root: {
    color: theme.colors.gray[9],
    fontWeight: 400,
    textTransform: 'none',
    alignSelf: 'flex-start',
  },

  inner: {
    fontSize: theme.fontSizes.xs,
  },
});

const hoverCardStyles: HoverCardProps['styles'] = (theme) => ({
  dropdown: {
    boxShadow: '0px 11px 23.9px 0px rgba(0, 0, 0, 0.19)',
    borderRadius: theme.radius.lg,
  },
});

const listStyles: ListProps['styles'] = (theme) => ({
  item: {
    color: theme.colors.blue_gray[7],
  },
});
