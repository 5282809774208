import { Group } from '@mantine/core';
import React, { FC } from 'react';

import { SpaceType } from '@portals/api/organizations';
import { ReactComponent as ArrowDown2 } from '@portals/icons/linear/arrow-down-2.svg';
import { ReactComponent as ArrowUp2 } from '@portals/icons/linear/arrow-up-2.svg';
import { ReactComponent as Clock2 } from '@portals/icons/linear/clock-2.svg';
import { ReactComponent as InfoCircle } from '@portals/icons/linear/info-circle.svg';
import { ReactComponent as Warning2 } from '@portals/icons/linear/warning-2.svg';

import StatisticsBox from './StatisticsBox';
import { useCurrentSpace } from '../../../../pages/overview/overview.hooks';

const STATS_MAP = (space: SpaceType) => [
  {
    color: 'danger',
    desc: 'Critical',
    Icon: Warning2,
    link: `/incidents/active?q[priority_in]=1&q[status_eq]=active&q[space_tree_path_name_i_cont]=${encodeURIComponent(
      space.tree_path_name
    )}`,
    priority: 'critical',
  },
  {
    color: 'warning',
    desc: 'High',
    Icon: ArrowUp2,
    link: `/incidents/active?q[priority_in]=2&q[status_eq]=active&q[space_tree_path_name_i_cont]=${encodeURIComponent(
      space.tree_path_name
    )}`,
    priority: 'high',
  },
  {
    color: 'info',
    desc: 'Moderate',
    Icon: InfoCircle,
    link: `/incidents/active?q[priority_in]=3&q[status_eq]=active&q[space_tree_path_name_i_cont]=${encodeURIComponent(
      space.tree_path_name
    )}`,
    priority: 'moderate',
  },
  {
    color: 'primary',
    desc: 'Low',
    Icon: ArrowDown2,
    link: `/incidents/active?q[priority_in]=4&q[status_eq]=active&q[space_tree_path_name_i_cont]=${encodeURIComponent(
      space.tree_path_name
    )}`,
    priority: 'low',
  },
  {
    color: 'secondary',
    desc: 'Planning',
    Icon: Clock2,
    link: `/incidents/active?q[priority_in]=5&q[status_eq]=active&q[space_tree_path_name_i_cont]=${encodeURIComponent(
      space.tree_path_name
    )}`,
    priority: 'planning',
  },
];

interface StatsBoxesProps {
  isLocal?: boolean;
}

const StatsBoxes: FC<StatsBoxesProps> = ({ isLocal }) => {
  const space = useCurrentSpace();
  const incidents =
    space?.state?.[isLocal ? 'local_incidents' : 'incidents'] || {};

  if (!space) return null;

  return (
    <Group align="center" spacing="md">
      {STATS_MAP(space).map((row) => (
        <StatisticsBox
          isGroup
          key={row.desc}
          {...row}
          value={incidents[row.priority] || 0}
        />
      ))}
    </Group>
  );
};

export default StatsBoxes;
