import {
  Badge,
  Box,
  Button,
  Container,
  createStyles,
  Group,
  Spoiler,
  Stack,
  Text,
} from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { get, isObject } from 'lodash/fp';
import React, { useState } from 'react';

import { DeviceType, useDevice } from '@portals/api/organizations';
import { CopyToClipboard } from '@portals/core';
import { DeviceType as CommonDeviceType } from '@portals/types';
import { prettyTime, timeAgo } from '@portals/utils';

import { RouteModalLink } from '../../../RouteModalLink';
import { getDeviceConfigStatusText } from '../utils';

interface DetailsProps<TDevice extends DeviceType | CommonDeviceType> {
  device: TDevice;
}

export function Details<TDevice extends DeviceType | CommonDeviceType>({
  device,
}: DetailsProps<TDevice>) {
  const { classes } = useStyles();
  const { width } = useViewportSize();
  const [isVisible, setIsVisible] = useState(false);

  const parent = useDevice(device.parent);

  if (!width) return null;

  return (
    <Spoiler
      showLabel="Show more"
      hideLabel="Show less"
      maxHeight={width < 1600 ? 285 : 215}
      styles={(theme) => ({
        control: {
          fontSize: theme.fontSizes.sm,
        },
      })}
    >
      <Stack pb="md" className={classes.rowsContainer}>
        <Text className={classes.title}>Device Details</Text>

        {parent?.data ? (
          <Box>
            <Box className={classes.detailsLabel}>Parent</Box>
            <Box className={classes.detailsValue}>
              <RouteModalLink modalId="device" pathParams={[parent.data.id]}>
                {parent.data.name || 'Unknown'}
              </RouteModalLink>
            </Box>
          </Box>
        ) : null}

        <Box>
          <Box className={classes.detailsLabel}>Vendor</Box>
          <Box
            className={classes.detailsValue}
            data-testid="device-details-vendor-value"
          >
            {device.partner.vendor}
          </Box>
        </Box>

        <Box>
          <Box className={classes.detailsLabel}>Model</Box>
          <Box
            className={classes.detailsValue}
            data-testid="device-details-model-value"
          >
            {device.partner.model}
          </Box>
        </Box>

        {device.partner.sub_model ? (
          <Box>
            <Box className={classes.detailsLabel}>Sub Model</Box>
            <Box
              className={classes.detailsValue}
              data-testid="device-details-sub-model-value"
            >
              {device.partner.sub_model}
            </Box>
          </Box>
        ) : null}

        {device.last_seen ? (
          <Box>
            <Box className={classes.detailsLabel}>Last Seen</Box>
            <Box
              className={classes.detailsValue}
              data-testid="device-details-last-seen-value"
            >
              {timeAgo(Date.parse(device.last_seen))}
            </Box>
          </Box>
        ) : null}

        <Box>
          <Box className={classes.detailsLabel}>Firmware Version</Box>
          <Box
            className={classes.detailsValue}
            data-testid="device-details-firmware-version-value"
          >
            {/* Partner & org devices have different structure  */}
            {isObject(device.firmware)
              ? get(['firmware', 'version'], device)
              : device.firmware}
          </Box>
        </Box>

        <Box>
          <Box className={classes.detailsLabel}>Serial Number</Box>
          <Box
            className={classes.detailsValue}
            data-testid="device-details-serial-number-value"
          >
            {device.partner.sn}
          </Box>
        </Box>

        <Box>
          <Box className={classes.detailsLabel}>Creation Date</Box>
          <Box
            className={classes.detailsValue}
            data-testid="device-details-creation-date-value"
          >
            {prettyTime(device.created_at)}
          </Box>
        </Box>

        {device.claimed_at ? (
          <Box>
            <Box className={classes.detailsLabel}>Claim Date</Box>
            <Box
              className={classes.detailsValue}
              data-testid="device-details-claim-date-value"
            >
              {prettyTime(device.claimed_at)}
            </Box>
          </Box>
        ) : null}

        {device.partner?.mac ? (
          <Box>
            <Box className={classes.detailsLabel}>MAC Address</Box>
            <Box
              className={classes.detailsValue}
              data-testid="device-details-mac-value"
            >
              <Group position="apart">
                {device.partner.mac}

                <CopyToClipboard value={device.partner.mac} />
              </Group>
            </Box>
          </Box>
        ) : null}

        {device.partner?.cloud_id ? (
          <Box>
            <Box className={classes.detailsLabel}>Cloud ID</Box>
            <Box className={classes.detailsValue}>
              <Group
                position="apart"
                data-testid="device-details-cloud-id-value"
              >
                {device.partner.cloud_id}

                <CopyToClipboard value={device.partner.cloud_id} />
              </Group>
            </Box>
          </Box>
        ) : null}

        <Box>
          <Box className={classes.detailsLabel}>Config</Box>
          <Box
            className={classes.detailsValue}
            data-testid="device-details-config-value"
          >
            {getDeviceConfigStatusText({
              configVersion: device?.config_version,
              appliedConfigVersion: device?.applied_config_version,
            })}
          </Box>
        </Box>

        <Box>
          <Box className={classes.detailsLabel}>UUID</Box>
          <Box className={classes.detailsValue}>
            <Group position="apart" data-testid="device-details-uuid-value">
              {device.id}

              <CopyToClipboard value={device.id} />
            </Group>
          </Box>
        </Box>

        <Box>
          <Box className={classes.detailsLabel}>Communication Protocol</Box>
          <Box
            className={classes.detailsValue}
            data-testid="device-details-communication-protocol"
          >
            {device.communication_protocol}
          </Box>
        </Box>

        {device?.connection_info?.access_key ? (
          <Box>
            <Box className={classes.detailsLabel}>Access Key</Box>
            <Box
              className={classes.detailsValue}
              data-testid="device-details-access-key-value"
            >
              {isVisible ? (
                <Group position="apart" align="center" spacing="xs">
                  {device.connection_info.access_key}

                  <Group spacing="xs" align="center" noWrap>
                    <Text
                      inherit
                      onClick={() => setIsVisible(false)}
                      color="blue_accent"
                      sx={{ cursor: 'pointer' }}
                    >
                      Hide
                    </Text>

                    <CopyToClipboard
                      value={device.connection_info.access_key}
                    />
                  </Group>
                </Group>
              ) : (
                <Group position="apart" align="center" noWrap>
                  <Text
                    inherit
                    onClick={() => setIsVisible(true)}
                    color="blue_accent"
                    sx={{ cursor: 'pointer' }}
                    data-testid="device-details-access-key-value-show"
                  >
                    Show
                  </Text>

                  <CopyToClipboard value={device.connection_info.access_key} />
                </Group>
              )}
            </Box>
          </Box>
        ) : null}

        {device?.connection_info?.hub_url ? (
          <Box>
            <Box className={classes.detailsLabel}>Assigned server</Box>
            <Box
              className={classes.detailsValue}
              data-testid="device-details-assigned-server-value"
            >
              {device.connection_info.hub_url}
            </Box>
          </Box>
        ) : null}

        {device?.connection_info?.hub_url_static_cert ? (
          <Box>
            <Box className={classes.detailsLabel}>
              Assigned static cert server
            </Box>
            <Box
              className={classes.detailsValue}
              data-testid="device-details-assigned-cert-value"
            >
              {device.connection_info.hub_url_static_cert}
            </Box>
          </Box>
        ) : null}

        {device?.connection_info?.mqtt_hub_url ? (
          <Box>
            <Box className={classes.detailsLabel}>Assigned MQTT server</Box>
            <Box
              className={classes.detailsValue}
              data-testid="device-details-assigned-mqtt-server-value"
            >
              {device.connection_info.mqtt_hub_url}
            </Box>
          </Box>
        ) : null}

        {process.env.NODE_ENV === 'development' ? (
          <Button
            onClick={() =>
              window.open(
                `http://localhost:3002/dev-center/?device_id=${device.id}&device_access_key=${device.connection_info.access_key}&hub_url=${process.env.NX_DEVICE_HUB_URL}`,
                '_blank'
              )
            }
            data-testid="integration-test-button"
          >
            Integration Test
          </Button>
        ) : null}

        {device?.test ? (
          <Container className={classes.container}>
            <Badge>Test Device</Badge>
          </Container>
        ) : null}
      </Stack>
    </Spoiler>
  );
}

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: theme.fontSizes.md,
    color: theme.colors.blue_gray[7],
    fontWeight: 500,
  },
  rowsContainer: {
    gap: 10,

    [theme.fn.largerThan(1600)]: {
      gap: theme.spacing.md,
    },
  },
  detailsLabel: {
    color: theme.colors.blue_gray[5],
    width: '100%',
    fontWeight: 400,
    fontSize: theme.fontSizes.sm,
  },
  detailsValue: {
    color: theme.colors.blue_gray[9],
    fontWeight: 400,
    fontSize: theme.fontSizes.sm,
  },
  container: {
    marginLeft: 'unset',
    paddingLeft: 'unset',
  },
}));
