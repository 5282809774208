import {
  Alert,
  Button,
  Group,
  LoadingOverlay,
  Stack,
  Text,
} from '@mantine/core';
import React, { ReactElement, useState } from 'react';

import {
  ModalCenteredMediaLayout,
  TextEditor,
  TextEditorProps,
} from '@portals/core';

import { ModalProps } from '../../components/Modals';

export interface TextEditorModalProps
  extends ModalProps<{
    title: string;
    editorContent: string | null | undefined;
    headerIcon: ReactElement;
    isEditable?: boolean;
    onSubmit?: (contentToSave: string) => Promise<any>;
  }> {}

export function TextEditorModal({
  closeMe,
  data: modalData,
}: TextEditorModalProps) {
  const [contentToSave, setContentToSave] = useState(
    modalData.editorContent || ''
  );
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onUpdate: TextEditorProps['editorParams']['onUpdate'] = ({
    editor,
  }) => {
    if (editor.isEditable) {
      const isEmpty = editor.isEmpty || editor.getText().trim() === '';

      setContentToSave(isEmpty ? '' : editor.getHTML());
    }
  };

  const onSubmit = async () => {
    setIsLoading(true);
    setError('');

    try {
      await modalData.onSubmit?.(contentToSave);

      closeMe();
    } catch ({ error }) {
      setError(`${error}`);
    }

    setIsLoading(false);
  };

  return (
    <ModalCenteredMediaLayout
      opened
      onClose={closeMe}
      media={modalData.headerIcon}
      title={modalData.title}
    >
      <LoadingOverlay visible={isLoading} />

      <Stack spacing="xl">
        <TextEditor
          styles={textEditorStyles(modalData.isEditable)}
          data-testid="text-editor"
          editorParams={{
            content: contentToSave,
            editable: Boolean(modalData.isEditable),
            onUpdate,
            autofocus: true,
          }}
        />

        {modalData.isEditable ? (
          <>
            {error ? (
              <Alert color="red">
                <Stack spacing="xs">
                  <Text size="sm">{error}</Text>
                </Stack>
              </Alert>
            ) : null}

            <Group grow spacing="md">
              <Button variant="default" onClick={closeMe}>
                Cancel
              </Button>

              <Button
                type="submit"
                onClick={onSubmit}
                disabled={!contentToSave}
                data-testid="text-editor-modal-save-button"
              >
                Save
              </Button>
            </Group>
          </>
        ) : null}
      </Stack>
    </ModalCenteredMediaLayout>
  );
}

const textEditorStyles =
  (isEditable: boolean): TextEditorProps['styles'] =>
  (theme) => ({
    root: {
      ...(!isEditable ? { border: 'none' } : {}),
    },
    content: {
      height: '100%',

      '.ProseMirror': {
        height: '100%',
        ...(!isEditable ? { padding: 0 } : {}),
      },
    },
    typographyStylesProvider: {
      [theme.fn.largerThan('md')]: {
        height: '45vh',
        overflow: 'auto',
      },
    },
  });
