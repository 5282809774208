import { FC } from 'react';

import { WidgetTypeEnum } from '@portals/device-widgets';

import GaugeWidget from './GaugeWidget';
import InsightsWidget from './InsightsWidget';
import LinerChartWidget from './LineChartWidget';
import MetricWidget from './MetricWidget';
import PieChartWidget from './PieChartWidget';
import StatusWidget from './StatusWidget';
import { WidgetProps } from './types';

const WIDGETS_MAP: Record<WidgetTypeEnum, FC<WidgetProps>> = {
  [WidgetTypeEnum.LineChart]: LinerChartWidget,
  [WidgetTypeEnum.PieChart]: PieChartWidget,
  [WidgetTypeEnum.GaugeWidget]: GaugeWidget,
  [WidgetTypeEnum.MetricWidget]: MetricWidget,
  [WidgetTypeEnum.StatusWidget]: StatusWidget,
  [WidgetTypeEnum.InsightsWidget]: InsightsWidget,
};

export const WIDGETS_WITH_STATE_HISTORY = [WidgetTypeEnum.LineChart];

export default WIDGETS_MAP;
