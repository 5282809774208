import {
  createStyles,
  Group,
  LoadingOverlay,
  Paper,
  Stack,
  Text,
} from '@mantine/core';
import { entries, isEmpty, toString } from 'lodash/fp';
import React from 'react';

import {
  DeviceDetailsType,
  DeviceType,
  useDeviceLastKnownState,
} from '@portals/api/organizations';
import { getDeviceStatusColor } from '@portals/framework/route-modals';
import { NoDataState } from '@portals/table';
import { formatDateTime } from '@portals/utils';

interface DeviceStateWrapperProps {
  device: DeviceDetailsType;
}

export function DeviceStateWrapper({ device }: DeviceStateWrapperProps) {
  const deviceLastKnownState = useDeviceLastKnownState(device?.id, {
    enabled: device.status !== 'online',
  });

  if (deviceLastKnownState.isInitialLoading) {
    return <LoadingOverlay visible />;
  }

  const deviceState =
    device.status === 'online'
      ? device.state
      : deviceLastKnownState.data?.state;

  return (
    <DeviceState
      deviceId={device.id}
      deviceState={deviceState}
      deviceStatus={device.status}
      deviceStateCreationDate={deviceLastKnownState.data?.created_at}
    />
  );
}

interface DeviceStateProps {
  deviceId: DeviceType['id'];
  deviceState: DeviceType['state'] | undefined;
  deviceStatus: DeviceType['status'];
  deviceStateCreationDate: DeviceType['created_at'];
}

function DeviceState({
  deviceState,
  deviceStatus,
  deviceStateCreationDate,
}: DeviceStateProps) {
  const { classes } = useStyles();

  const displayLastKnowStateInfo =
    deviceStatus === 'offline' || deviceStatus === 'unavailable';

  return (
    <Stack className={classes.wrapper}>
      {displayLastKnowStateInfo ? (
        <Paper className={classes.topPaper} radius="md" py="md" px="xl">
          <Group>
            <Text>
              Device went {deviceStatus} at{' '}
              {formatDateTime(
                deviceStateCreationDate,
                'M/DD/YYYY hh:mm A z',
                'an unknown timestamp',
                'UTC'
              )}
            </Text>
          </Group>
        </Paper>
      ) : null}

      <DeviceStateDetails deviceState={deviceState} />
    </Stack>
  );
}

interface DeviceStateDetailsProps {
  deviceState: DeviceType['state'] | undefined;
}

function DeviceStateDetails({ deviceState }: DeviceStateDetailsProps) {
  if (isEmpty(deviceState)) {
    return (
      <Paper radius="md" p="xl">
        <NoDataState title="Awaiting for device to start sending telemetries" />
      </Paper>
    );
  }

  const stateEntries = entries(deviceState);
  const filteredStateEntries = stateEntries.filter(([key]) => key !== 'status');
  const statusStateEntry = stateEntries.find(([key]) => key === 'status');

  return (
    <Stack>
      <Paper radius="md" p="xl">
        <Group position="apart">
          <Text>status</Text>

          <Text
            color={
              getDeviceStatusColor(
                statusStateEntry[1] as DeviceDetailsType['state']['status']
              )?.baseColor
            }
          >
            {statusStateEntry[1] as string}
          </Text>
        </Group>
      </Paper>

      {filteredStateEntries.map(([key, value]) => (
        <Paper radius="md" p="xl" key={key}>
          <Group position="apart">
            <Text>{key}</Text>

            <Text>{toString(value)}</Text>
          </Group>
        </Paper>
      ))}
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  wrapper: {
    height: '100%',
    background: theme.colors.gray[0],
    padding: theme.spacing.md,

    '.data-state-container': {
      position: 'relative',
      top: 'unset',
      left: 'unset',
      transform: 'unset',
    },
  },

  container: {
    height: '100%',
    paddingBlock: theme.spacing.md,
  },

  paper: {
    padding: 32,
    height: '100%',
    color: theme.colors.blue_gray[6],
  },

  topPaper: {
    padding: '32px 40px',
    marginTop: 10,
    color: theme.colors.blue_gray[6],
  },

  box: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.lg,
  },

  button: {
    alignSelf: 'flex-end',
  },
}));
