import {
  PostPurchaseParamsType,
  ProductTypeEnum,
  SupportedCommandType,
} from '@portals/types';

export interface PurchasedProductType {
  id: string;
  created_at: string;
  order_id: string;
  post_purchase_parameters_required: boolean;
  post_purchase_parameters: PostPurchaseParamsType | null;
  license: {
    device_id: string | null;
    device_name: string | null;
    state: StatusName;
  } | null;
  product: {
    id: string;
    name: string;
    image_url: string;
    category: string;
    description: string;
    subtitle: string | null;
    device_model_id: string | null;
    product_type: ProductTypeEnum;
    supported_commands: SupportedCommandType[];
    device_model_name: string | null;
  };
  seller: {
    id: string;
    name: string;
    display_name: string;
    logo: string | null;
  };
  sn: string | null;
  assigned: boolean;
}

export const LICENSES_STATUS_VALUES = {
  inuse: 'Assigned',
  available: 'Available',
  remove: 'Pending removal',
  disabled: 'Void',
  disable: 'Disable',
  error: 'Error',
  renew: 'Renew',
} as const;

type StatusName = keyof typeof LICENSES_STATUS_VALUES;
type StatusValue = (typeof LICENSES_STATUS_VALUES)[StatusName];

export const LICENSES_STATUS_NUMERIC_VALUES = {
  0: 'Available',
  1: 'Assigned',
  3: 'Pending removal',
  4: 'Error',
  7: 'Void',
  8: 'Renew',
  9: 'Disable',
} as const satisfies Record<number, StatusValue>;

export const LICENSES_STATUS_COLORS = {
  inuse: 'gray.1',
  available: 'teal.0',
  remove: 'gray.1',
  disabled: 'gray.1',
  disable: 'gray.1',
  error: 'gray.1',
  renew: 'gray.1',
} as const;
