import { createStyles, Group } from '@mantine/core';
import React from 'react';

import { ReactComponent as Scroll } from '@portals/icons/bold/scroll.svg';

export function TelemetryIcon() {
  const { classes, theme } = useStyles();

  return (
    <Group position="center" className={classes.container}>
      <Scroll color={theme.white} width={18} height={18} />
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    width: 36,
    height: 36,
    borderRadius: '50%',
    backgroundColor: theme.colors.cyan[4],
  },
}));
