import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export const useActionDispatch = <
  T extends (...args: Parameters<T>) => ReturnType<T>
>(
  action: T
) => {
  const dispatch = useDispatch();

  return useCallback(
    (...args: Parameters<T>) => dispatch(action(...args)),
    [action, dispatch]
  );
};
