import { createStyles } from '@mantine/core';

export const useCommonStyles = createStyles((theme) => ({
  container: {
    backgroundColor: theme.white,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
    alignItems: 'center',
    minHeight: 75,
    zIndex: 2,

    '.space-name-action-btn': {
      opacity: 0,
      transition: 'opacity 0.15s ease-in-out',
    },

    '&:hover': {
      '.space-name-action-btn': {
        opacity: 1,
      },
    },
  },
}));
