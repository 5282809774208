import { motion } from 'framer-motion';
import React, { FC } from 'react';
import { Spinner } from 'reactstrap';
import styled from 'styled-components';

import EmptyState, { EmptyStateProps } from './EmptyState';

export const PageContainer = styled(motion.div)<{ $isPending?: boolean }>`
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 64px);
  position: relative;

  ${({ $isPending }) =>
    $isPending &&
    `
    padding: 0 !important;
    overflow: hidden;
  `}

  >div {
    height: calc(100% - 64px);
    width: 100%;
    position: fixed;
    top: 64px;
    left: 0;
  }
`;

export const PageSpinner = () => (
  <PageWrapper>
    <Spinner color="primary" />
  </PageWrapper>
);

export const PageEmptyState: FC<EmptyStateProps> = (props) => (
  <PageWrapper>
    <EmptyState {...props} />
  </PageWrapper>
);

const PageWrapper = styled.div`
  height: calc(100% - 64px);
  display: flex;
  align-items: center;
  justify-content: center;
`;
