import { useSelector } from 'react-redux';

import { AuthType, StateType } from '@portals/types';

import { getIntegrations, getRuleById } from '../selectors/data';
import { getAuth } from '../selectors/ui';

export const useAuth = (): AuthType => useSelector(getAuth);

/* INTEGRATIONS */

export const useIntegrations = () => useSelector(getIntegrations);
export const useIntegrationByName = <
  IntegrationName extends keyof StateType['data']['integrations']
>(
  integrationName: IntegrationName
) => {
  const integrations = useSelector(getIntegrations);

  return integrations[integrationName];
};

/* RULES */
export const useRuleById = (ruleId: string) => useSelector(getRuleById(ruleId));
