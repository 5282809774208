import { useUser } from '@descope/react-sdk';
import {
  Button,
  createStyles,
  Grid,
  LoadingOverlay,
  Paper,
  Select,
  SelectProps,
  Stack,
  TextInput,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { AfterAuthRedirectManager } from '@lib/after-auth-redirect-manager';
import { useGroups } from '@portals/api/organizations';
import { useCreateUser, useUsers } from '@portals/api/ui';

import {
  useSignUpWizard,
  useTrackPageView,
} from '../../../pages/Sign-up/wizard/wizard.hooks';
import { StepSubtitle, StepTitle } from '../common';
import { UserCard } from '../UserCard';

interface InviteCoWorkersFormValues {
  email: string;
  role: string;
}

const schema = Yup.object().shape({
  email: Yup.string().required().email('Please enter a valid email address'),
  role: Yup.string().required('Please select a role'),
});

export function InviteCoWorkers() {
  const { classes } = useStyles();
  const { isNextDisabled } = useSignUpWizard();

  const navigate = useNavigate();

  const createUser = useCreateUser();
  const users = useUsers();
  const { user } = useUser();

  const groups = useGroups();

  const groupOptions = useMemo<SelectProps['data']>(() => {
    if (!groups.data) {
      return [];
    }

    return groups.data.map((group) => ({
      value: group.id,
      label: group.name,
    }));
  }, [groups.data]);

  const form = useForm<InviteCoWorkersFormValues>({
    initialValues: {
      email: '',
      role: '',
    },

    validate: yupResolver(schema),
  });

  const currentUser = {
    name: `${user.name} (You)`,
    email: user.email,
  };

  const adjustedUsers = users.data?.filter(
    (user) => user.email !== currentUser.email
  );

  const onAddUser = (values: typeof form.values) => {
    createUser.mutate({
      email: values.email,
      groupIds: [values.role],
    });
  };

  const handleFinish = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    const afterAuthPath = AfterAuthRedirectManager.getPathname();

    navigate(afterAuthPath, {
      state: { afterLogin: true },
      replace: true,
    });
  };

  useTrackPageView('Invite your teammates screen');

  return (
    <>
      <LoadingOverlay
        visible={users.isLoading || createUser.isLoading || groups.isLoading}
      />

      <Stack>
        <StepTitle>Time to invite your teammates</StepTitle>

        <StepSubtitle mb="xxl">
          Collaborate with your team to get the most out of Xyte.io
        </StepSubtitle>

        <Stack spacing="lg">
          <form onSubmit={form.onSubmit(onAddUser)}>
            <Stack spacing="md">
              <Paper withBorder p="xl" radius="lg">
                <Stack spacing="md">
                  <Grid grow>
                    <Grid.Col span={5}>
                      <TextInput
                        data-testid="email-input"
                        className={classes.textInput}
                        type="email"
                        placeholder="Teammate email address"
                        {...form.getInputProps('email')}
                      />
                    </Grid.Col>

                    <Grid.Col span={3}>
                      <Select
                        data-testid="role-select"
                        data={groupOptions}
                        placeholder="Select role"
                        {...form.getInputProps('role')}
                      />
                    </Grid.Col>
                  </Grid>

                  <Button
                    data-testid="send-invitation-button"
                    data-analyticsid="send-invitation"
                    variant="light"
                    type="submit"
                    disabled={form.values.email.trim() === ''}
                  >
                    Send Invitation
                  </Button>
                </Stack>
              </Paper>

              <UserCard email={currentUser.email ?? ''} name={user.name} />

              {adjustedUsers?.map(({ name, email }) => (
                <UserCard
                  email={email}
                  name={name}
                  key={email}
                  isInvitationSent
                />
              ))}

              <Button
                data-testid="finish-button"
                data-analyticsid="finish-inviting"
                fullWidth
                mt="xl"
                disabled={isNextDisabled || users.data?.length === 0}
                onClick={handleFinish}
              >
                Finish
              </Button>
            </Stack>
          </form>
        </Stack>
      </Stack>
    </>
  );
}

const useStyles = createStyles((theme) => ({
  textInput: {
    flexGrow: 1,
  },
}));
