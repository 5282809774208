import { useMantineTheme } from '@mantine/core';
import dayjs from 'dayjs';
import React from 'react';
import { LabelProps } from 'recharts';

import { WidgetDataRangeEnum } from './scatter-chart.types';

export interface XAxisLabelProps extends LabelProps {
  index: number;
  visibleTicksCount: number;
  payload: {
    value: string;
  };
  range?: WidgetDataRangeEnum;
}

export function XAxisLabel({
  x,
  y,
  payload,
  textAnchor,
  className,
  range,
}: XAxisLabelProps) {
  const theme = useMantineTheme();

  return (
    <g style={{ userSelect: 'none' }}>
      <text
        className={className}
        x={x}
        y={y}
        dy={10}
        fontWeight={400}
        textAnchor={textAnchor}
        fontSize={theme.fontSizes.xs}
        fill={theme.colors.gray[4]}
      >
        {dayjs(payload.value).format('MM/DD/YY')}
      </text>

      {range === WidgetDataRangeEnum.Day ? (
        <text
          className={className}
          x={x}
          y={y}
          dy={25}
          fontWeight={400}
          textAnchor={textAnchor}
          fontSize={theme.fontSizes.xs}
          fill={theme.colors.gray[4]}
        >
          {dayjs(payload.value).format('HH:mm:ss')}
        </text>
      ) : null}
    </g>
  );
}
