import { find } from 'lodash/fp';
import React, { useMemo, useState } from 'react';

import { useActiveDeviceCommands, useDevice } from '@portals/api/organizations';
import {
  BarControllerWidget,
  BarControllerWidgetFormType,
  useDebouncedTelemetryValue,
} from '@portals/device-widgets';
import { useSendCommandWithApproval } from '@portals/framework';
import { PremiumCommandLicenseModalProps } from '@portals/framework/modals';
import { useOpenModal } from '@portals/redux';

import { useDeviceCommand } from '../../../../../../../hooks/commands';
import { WidgetProps } from '../device-widgets.types';

export function BarControllerWidgetWrapper({
  widget,
  deviceState,
  deviceId,

  isDeviceOffline,
  lastUpdateTimestamp,
}: WidgetProps<BarControllerWidgetFormType>) {
  // Use localId to force re-render when command approval rejected by user, and command not sent
  const [localId, setLocalId] = useState(1);
  const fields = widget?.config?.fields;

  const {
    name,
    gradient,
    telemetry,
    max,
    min,
    unit,
    command_name,
    command_param_key,
    title_telemetry,
    telemetry_as_title,
  } = fields ?? {};

  const sendCommandWithApproval = useSendCommandWithApproval();
  const activeCommands = useActiveDeviceCommands(deviceId);

  const telemetryValue = deviceState?.[telemetry];
  const activeCommand = find({ name: command_name }, activeCommands.data);
  const isCommandActive = Boolean(activeCommand);
  const command = useDeviceCommand(deviceId, command_name);

  const sentCommandValue =
    activeCommand && activeCommand.params?.[command_param_key];

  const debouncedTelemetryValue = useDebouncedTelemetryValue(
    telemetryValue,
    sentCommandValue
  );

  const title = telemetry_as_title
    ? deviceState?.[title_telemetry] || 'N/A'
    : name;

  const openModal = useOpenModal();
  const device = useDevice(deviceId);

  const hasCommandLicense = useMemo(() => {
    return (device?.data?.supported_commands || []).find(
      (currentCommand) => currentCommand.id === command?.id
    )?.has_license;
  }, [command, device]);

  const onChange = async (value: number) => {
    if (command?.premium && !hasCommandLicense) {
      return;
    }

    if (command?.custom_fields?.length) {
      openModal('RunCommand', {
        deviceType: device.data?.partner?.type_id,
        command,
        deviceIds: [deviceId],
        prepopulatedParams: {
          [command_param_key]: value,
        },
        onError: () => setLocalId((prev) => prev + 1),
      });

      return;
    }

    try {
      await sendCommandWithApproval({
        command,
        deviceIds: [deviceId],
        extra_params: {
          [command_param_key]: value,
        },
      });
    } catch (err) {
      // Forcing re-rendering of the widget, to return to original value (before user changed it)
      setLocalId((prev) => prev + 1);
      console.error(err);
    }
  };

  const onClick = () => {
    if (command?.premium && !hasCommandLicense) {
      openModal<PremiumCommandLicenseModalProps['data']>(
        'PremiumCommandLicenseModal',
        { deviceId, commandId: command?.id, commandName: command?.name }
      );
    }
  };

  return (
    <BarControllerWidget
      key={localId}
      title={title}
      value={debouncedTelemetryValue}
      max={max}
      min={min}
      unit={unit}
      gradient={gradient}
      isLoading={isCommandActive}
      onChange={onChange}
      isDeviceOffline={isDeviceOffline}
      lastUpdateTimestamp={lastUpdateTimestamp}
      onClick={onClick}
      disabled={command?.premium && !hasCommandLicense}
    />
  );
}
