import { Modal } from '@mantine/core';
import React from 'react';

import {
  CreateDeviceModelFileParams,
  useCreateDeviceModelFile,
  useUpdateDeviceModelFile,
  useUpdateSpaceFile,
} from '@portals/api/organizations';
import { AutoFormik } from '@portals/autoformik';
import { ModalProps } from '@portals/framework';
import {
  FieldType,
  FieldTypeEnum,
  OrganizationFileResponseType,
} from '@portals/types';
import { ChecksumAdornment, SignatureAdornment } from '@portals/ui';

const FIELDS: Array<FieldType> = [
  {
    name: 'url',
    title: 'File',
    type: FieldTypeEnum.File,
    required: true,
    options: { fileNameField: 'name' },
  },
  { name: 'name', title: 'File name', required: true },
  { name: 'desc', title: 'Description' },
  { name: 'version', title: 'Version', type: FieldTypeEnum.String },
  {
    name: 'checksum',
    title: 'Checksum',
    type: FieldTypeEnum.String,
    inputProps: {
      endAdornment: <ChecksumAdornment docsUrl="https://docs.xyte.io/" />,
    },
  },
  {
    name: 'signature',
    title: 'Signature',
    type: FieldTypeEnum.Textarea,
    inputProps: {
      endAdornment: <SignatureAdornment docsUrl="https://docs.xyte.io/" />,
    },
  },
  { name: 'public_notes', title: 'Notes', type: FieldTypeEnum.Textarea },
];

export interface FileInfoUploaderProps
  extends ModalProps<{
    file?: Partial<OrganizationFileResponseType>;
    deviceType?: string;
    fileType?: string;
    onSuccess?: (id: string) => void;
  }> {}

export function FileInfoUploader({ data, closeMe }: FileInfoUploaderProps) {
  const { file, deviceType, fileType, onSuccess } = data;
  const createDeviceModelFile = useCreateDeviceModelFile(deviceType);
  const updateDeviceModelFile = useUpdateDeviceModelFile(file?.device_model);
  const updateSpaceFile = useUpdateSpaceFile();

  const isEdit = Boolean(file);

  const error =
    createDeviceModelFile.error?.error ||
    updateDeviceModelFile.error?.error ||
    updateSpaceFile.error?.error;

  const inProgress =
    createDeviceModelFile.isLoading ||
    updateDeviceModelFile.isLoading ||
    updateSpaceFile.isLoading;

  const handleAdd = async (
    formData: CreateDeviceModelFileParams['formData']
  ) => {
    createDeviceModelFile.mutate(
      {
        formData,
        file_type: fileType,
      },
      {
        onSuccess: (response) => {
          onSuccess?.(response.id);
          closeMe();
        },
      }
    );
  };

  const handleEdit = async (updatedFile: OrganizationFileResponseType) => {
    if (!file || !file.id) return;

    // If file is related to space, update via space files API
    if (file.space !== null && file.space !== undefined) {
      updateSpaceFile.mutate(
        { fileId: file.id, spaceId: file.space, fileData: updatedFile },
        {
          onSuccess: (response) => {
            onSuccess?.(response.id);
            closeMe();
          },
        }
      );
    }
    // If file is related to device model, update via device model files API
    else if (file.device_model !== null) {
      updateDeviceModelFile.mutate(updatedFile, {
        onSuccess: (response) => {
          onSuccess?.(response.id);
          closeMe();
        },
      });
    }
  };

  return (
    <Modal
      opened
      onClose={closeMe}
      title={isEdit ? 'Edit file' : 'Upload file'}
      padding="xl"
      sx={(theme) => ({
        '.modal-body': {
          padding: 0,
          marginBottom: theme.spacing.md,
        },
        '.modal-footer': {
          paddingRight: 0,
          paddingLeft: 0,
        },
      })}
    >
      <AutoFormik
        initialValues={file || {}}
        fields={FIELDS}
        submitTitle={isEdit ? 'Save' : 'Add'}
        serverError={error}
        inProgress={inProgress}
        handleSubmit={isEdit ? handleEdit : handleAdd}
        modal={closeMe}
      />
    </Modal>
  );
}
