import { Box } from '@mantine/core';
import { map } from 'lodash/fp';
import React from 'react';
import { Row, UseExpandedRowProps, UseRowSelectRowProps } from 'react-table';

import { RowType, SmartTableProps } from '@portals/types';

import { useTableInstance } from '../context';
import TRow from './TRow';

type TBodyProps<TData extends object, TKeyField extends keyof TData> = Pick<
  SmartTableProps<TData, TKeyField>,
  'expandRow' | 'rowMenu' | 'isCompact' | 'menuProps'
> & {
  data: Array<Row<any>>;
  onRowClick?: (row: RowType<TData>) => void;
  readOnly: boolean;
  clickedRowOriginalId: TData[TKeyField] | undefined;
  keyField: TKeyField;
};

function TBody<TData extends object, TKeyField extends keyof TData>({
  expandRow,
  rowMenu,
  isCompact,
  menuProps = {},
  data,
  onRowClick,
  readOnly,
  clickedRowOriginalId,
  keyField,
}: TBodyProps<TData, TKeyField>) {
  const instance = useTableInstance();
  const { prepareRow, getTableBodyProps } = instance;

  const rows = map(
    (row: Row<any> & UseExpandedRowProps<any> & UseRowSelectRowProps<any>) => {
      if (!row) return null;

      prepareRow(row);

      return (
        <TRow<TData, TKeyField>
          key={row.id}
          row={row}
          expandRow={expandRow}
          rowMenu={rowMenu}
          isCompact={isCompact}
          menuProps={menuProps}
          onRowClick={onRowClick}
          clickedRowOriginalId={clickedRowOriginalId}
          keyField={keyField}
          readOnly={readOnly}
        />
      );
    },
    data
  );

  return (
    <Box {...getTableBodyProps()} className="tbody" h="100%">
      {rows}
    </Box>
  );
}

export default TBody;
