import {
  Box,
  createStyles,
  Group,
  Stack,
  StackProps,
  Text,
} from '@mantine/core';
import React from 'react';

import { WIDGET_ICONS } from '../../widgets.constants';
import { WidgetColorType, WidgetIconNameType } from '../../widgets.types';

export interface SectionHeaderWidgetProps {
  title: string;
  textColor: WidgetColorType;
  backgroundColor: WidgetColorType;
  displayIcon: boolean;
  iconName: WidgetIconNameType | null;
  stackProps?: Partial<StackProps>;
}

export function SectionHeaderWidget({
  title,
  textColor,
  backgroundColor,
  displayIcon,
  iconName,
  stackProps = {},
}: SectionHeaderWidgetProps) {
  const { classes, theme } = useStyles();

  const widgetIcon = displayIcon
    ? WIDGET_ICONS.find((widgetIcon) => widgetIcon.iconName === iconName)
    : null;
  const Icon = widgetIcon ? widgetIcon?.Icon : null;
  const themeColor =
    textColor === 'white' ? 'white' : theme.fn.themeColor(textColor);

  return (
    <Stack
      className={classes.container}
      px="xl"
      h="100%"
      w="100%"
      bg={backgroundColor}
      justify="center"
      spacing={4}
      {...stackProps}
    >
      <Group align="center" spacing="sm" p={0}>
        {Icon ? (
          <Box
            sx={{
              color: themeColor,
            }}
          >
            <Icon height={16} width={16} />
          </Box>
        ) : null}

        <Text color={textColor} size="md">
          {title}
        </Text>
      </Group>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    borderRadius: theme.radius.lg,
    pointerEvents: 'none',
  },
}));
