import {
  createStyles,
  Divider,
  Group,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import React, { Dispatch, SetStateAction } from 'react';

import { usePortalConfig } from '@portals/api/organizations';

import { SelectShopCurrency } from '../../components/SelectCurrency';
import { SortBy, SortByEnum } from '../store/SortBy';

interface ShopHeaderProps {
  sortBy: SortByEnum;
  setSortBy: Dispatch<SetStateAction<SortByEnum>>;
}

export function ExternalStoreHeader({ sortBy, setSortBy }: ShopHeaderProps) {
  const { classes } = useStyles();
  const portalConfig = usePortalConfig();

  return (
    <Group className={classes.header}>
      <Group position="apart" w="100%">
        <Group spacing="xl">
          <img
            className={classes.logo}
            width={70}
            height={70}
            src={
              portalConfig.data?.design?.store?.logo ||
              portalConfig.data?.design?.navigation_menu?.logo ||
              'logo_full_dark.svg'
            }
          />

          <Stack
            justify="center"
            spacing="xs"
            mah={60}
            sx={{ overflow: 'hidden' }}
          >
            <Title order={3} color="blue_gray.8" weight={600} truncate>
              {portalConfig.data?.name}
            </Title>

            {portalConfig.data?.design?.store?.tagline ? (
              <Text size="md" color="blue_gray.4" truncate>
                {portalConfig.data?.design?.store?.tagline}
              </Text>
            ) : null}
          </Stack>
        </Group>

        <Group spacing="xl">
          <SortBy setSortBy={setSortBy} sortBy={sortBy} />

          <Divider orientation="vertical" />

          <SelectShopCurrency />
        </Group>
      </Group>
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  header: {
    padding: `${theme.spacing.xl} 30px`,
    borderBottom: `1px solid ${theme.colors.blue_gray[0]}`,
    width: '100%',
    backgroundColor: theme.white,
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.radius.md,
    overflow: 'hidden',
    border: '1px solid white',
    backgroundColor: theme.colors.gray[0],
  },
}));
