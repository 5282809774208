import { useFormikContext } from 'formik';
import { noop } from 'lodash/fp';
import { useEffect, useRef } from 'react';

type FormikOnChangeProps = {
  onChange: (values: any) => void;
};

export function FormikOnChange({ onChange = noop }: FormikOnChangeProps) {
  const { values } = useFormikContext();
  const onChangeRef = useRef(onChange);

  useEffect(
    function updateOnChangeReference() {
      onChangeRef.current = onChange;
    },
    [onChange]
  );

  useEffect(
    function useCallbackOnValuesChange() {
      onChangeRef.current(values);
    },
    [values]
  );

  return null;
}
