import { useDescope } from '@descope/react-sdk';
import { Box, Button, Modal, PasswordInput, Stack } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import React from 'react';
import { useDispatch } from 'react-redux';
import { object } from 'yup';

import { useCurrentUser } from '@portals/api/ui';
import { ModalBody, ModalFooter } from '@portals/core';
import { useIsPending } from '@portals/redux';
import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';

import { ModalProps } from '../components/Modals';
import {
  PasswordInputWithRequirements,
  yupPasswordConfirmValidator,
  yupPasswordValidator,
} from '../pages/auth/PasswordInputWithRequirements';

const schema = object({
  new_password: yupPasswordValidator,
  confirm: yupPasswordConfirmValidator('new_password'),
});

export function ChangePasswordModal({ closeMe }: ModalProps) {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const isPending = useIsPending('changePassword');

  const descope = useDescope();

  const form = useForm({
    initialValues: {
      old_password: '',
      new_password: '',
      confirm: '',
    },
    validate: yupResolver(schema),
  });

  const onSubmit = async (values: typeof form.values) => {
    try {
      const { error } = await descope.password.replace(
        currentUser.data?.email,
        values.old_password,
        values.new_password
      );

      //errorMessage is for new_password as errorDescription is undefined
      if (error?.errorMessage) {
        form.setFieldError('new_password', error.errorMessage);
        return;
      }

      //errorDescription is for old_password as errorMessage is undefined
      if (error?.errorDescription) {
        form.setFieldError('old_password', error.errorDescription);
        return;
      }

      dispatch(toastrSuccess('Password changed successfully'));
      closeMe();
    } catch (e) {
      dispatch(toastrError('Error changing password'));
    }
  };

  return (
    <Modal
      opened
      onClose={closeMe}
      closeOnEscape={false}
      title="Change Password"
    >
      <form noValidate onSubmit={form.onSubmit(onSubmit)}>
        <ModalBody>
          <Stack>
            <PasswordInput
              required
              data-autofocus
              label="Old password"
              data-testid="old-password-input"
              placeholder="Old password"
              {...form.getInputProps('old_password')}
            />

            <Box data-testid="new-password-input">
              <PasswordInputWithRequirements
                label="New Password"
                placeholder="Password"
                {...form.getInputProps('new_password')}
              />
            </Box>

            <PasswordInput
              placeholder="Confirm Password"
              label="Confirm Password"
              data-testid="confirm-password-input"
              {...form.getInputProps('confirm')}
            />
          </Stack>
        </ModalBody>

        <ModalFooter position="right">
          <Button variant="default" onClick={closeMe}>
            Cancel
          </Button>
          <Button
            type="submit"
            data-testid="change-password-button"
            loading={isPending}
          >
            Change
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
