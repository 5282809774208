import {
  Box,
  Button,
  ButtonProps,
  createStyles,
  Group,
  HoverCard,
  HoverCardProps,
  Stack,
  Text,
} from '@mantine/core';
import React, { ReactNode } from 'react';

import { CopyToClipboard } from '@portals/core';
import {
  InvoiceItem,
  PaymentMethodEnum,
  PaymentMethodType,
} from '@portals/types';

import { RouteModalLink } from '../../../route-modals/RouteModalLink';
import { ProductImage } from '../../ProductImage';
import { SubscriptionStatusBadge } from '../../SubscriptionStatusBadge';

interface SubscriptionPreviewTooltipProps {
  invoiceItem: InvoiceItem;
  children: ReactNode;
  isItemIdEmpty: boolean;
  paymentMethod: PaymentMethodType;
  orderId: string;
}

export function SubscriptionPreviewTooltip({
  invoiceItem,
  children,
  isItemIdEmpty,
  paymentMethod,
  orderId,
}: SubscriptionPreviewTooltipProps) {
  const { classes } = useStyles();

  return (
    <HoverCard position="bottom-start" openDelay={250} styles={hoverCardStyles}>
      <HoverCard.Target>{children}</HoverCard.Target>

      <HoverCard.Dropdown maw={275}>
        <Stack spacing="xs" align="center">
          <ProductImage
            src={invoiceItem.product_image_url}
            width={86}
            className={classes.imageWrapper}
          />

          <Text size="md" color="gray.9" align="center">
            {invoiceItem.product_name}
          </Text>

          <Text color="gray.5">{invoiceItem.device_model_name}</Text>

          <Group spacing="xs">
            <RouteModalLink
              modalId="order"
              pathParams={[orderId]}
              target="_blank"
            >
              {invoiceItem.initial_order_short_id}
            </RouteModalLink>
            <CopyToClipboard value={invoiceItem.initial_order_short_id} />
          </Group>

          <Box mb="sm">
            <SubscriptionStatusBadge status={invoiceItem.subscription_status} />
          </Box>

          <Button
            size="md"
            w="100%"
            variant="default"
            component="a"
            href={`/p/subscription/${invoiceItem.subscription_id}`}
            target="_blank"
            styles={buttonStyles}
            disabled={isItemIdEmpty}
          >
            Show Subscription
          </Button>

          {isItemIdEmpty ? (
            <Text color="gray.6" align="center" mt="xs">
              {paymentMethod === PaymentMethodEnum.CreditCard
                ? `There was an issue processing your payment and the subscription is currently inactive.`
                : `This subscription is currently inactive as we are awaiting the completion of your Purchase Order payment.`}
            </Text>
          ) : null}
        </Stack>
      </HoverCard.Dropdown>
    </HoverCard>
  );
}

const hoverCardStyles: HoverCardProps['styles'] = (theme) => ({
  dropdown: {
    borderRadius: theme.radius.lg,
    padding: theme.spacing.xxl,
    boxShadow: '0px 6px 27px 0px rgba(38, 50, 56, 0.1)',
    minWidth: 276,
  },
});

const buttonStyles: ButtonProps['styles'] = (theme) => ({
  root: {
    '&:hover': {
      color: theme.black,
      textDecoration: 'none',
    },
  },
});

const useStyles = createStyles((theme) => ({
  imageWrapper: {
    overflow: 'hidden',
    border: `1px solid ${theme.colors.gray[1]}`,
    borderRadius: theme.radius.lg,
    marginBottom: theme.spacing.xs,
  },
}));
