import { Box, createStyles, Image, UnstyledButton } from '@mantine/core';
import React, { useRef, useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { useNavigate, useParams } from 'react-router-dom';

import {
  useCheckQrCodeAccess,
  useClaimDeviceByQrCode,
  useIsCecEnabled,
} from '@portals/api/organizations';
import { useOpenRouteModal } from '@portals/framework/route-modals';
import { ReactComponent as CloseX } from '@portals/icons/linear/close-x.svg';
import { useOpenModal } from '@portals/redux';

import scanSrc from '../../../../assets/img/scan.svg';
import Notification from '../../../components/layout/Notification';
import { SetDeviceNameProps } from '../../../modals';

export function Scan() {
  const { classes, theme } = useStyles();
  const params = useParams<{ space_id: string }>();
  const navigate = useNavigate();
  const openModal = useOpenModal();
  const openRouteModal = useOpenRouteModal();

  const isCecEnabled = useIsCecEnabled();
  const checkQrCodeAccess = useCheckQrCodeAccess();
  const claimDeviceByQrCode = useClaimDeviceByQrCode();

  const [error, setError] = useState<string | null>(null);

  const currentQrCode = useRef<string | null>(null);

  const onClaim = (cloudId: string) => {
    if (isCecEnabled) {
      checkQrCodeAccess.mutate(
        { qr: cloudId },
        {
          onSuccess: (response) => {
            if (response.claim_allowed) {
              onClaimAllowedDevice(cloudId);
            } else {
              openRouteModal({
                modalId: 'connect',
                pathParams: [response.device_partner_name, cloudId],
                backgroundPath: `/devices/${response.device_id}`,
                replace: true,
              });
            }
          },
        }
      );
    } else {
      onClaimAllowedDevice(cloudId);
    }
  };

  const onClaimAllowedDevice = (cloudId: string) => {
    claimDeviceByQrCode.mutate(
      { qr: cloudId, space_id: params.space_id },
      {
        onSuccess: ({ device }) => {
          openModal<SetDeviceNameProps['data']>('SetDeviceName', {
            device,
          });
        },
        onError: () => {
          setError('Failed to claim device. Please try again');

          currentQrCode.current = null;
        },
      }
    );
  };

  const handleScan: React.ComponentProps<typeof QrReader>['onResult'] = (
    scanResult,
    error
  ) => {
    if (error && error.message) {
      setError(error.message);
      return;
    }

    if (!scanResult) return;

    const resultText = scanResult.getText();

    const cloudId = resultText.replace('https://xyte.io/c/', '');

    if (
      !cloudId ||
      claimDeviceByQrCode.isLoading ||
      cloudId === currentQrCode.current
    ) {
      return;
    } else {
      currentQrCode.current = cloudId;

      onClaim(currentQrCode.current);
    }
  };

  return (
    <>
      <Box className={classes.container}>
        <QrReader
          onResult={handleScan}
          constraints={{ facingMode: 'environment' }}
          videoContainerStyle={{
            backgroundColor: theme.colors.blue_gray[8],
            top: 0,
            position: 'fixed',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            zIndex: 10,
          }}
          videoStyle={{
            top: 0,
            position: 'fixed',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            zIndex: 10,
          }}
        />

        <Box className={classes.button}>
          <UnstyledButton
            onClick={() => navigate(`/overview/${params.space_id}`)}
          >
            <CloseX color="white" />
          </UnstyledButton>
        </Box>

        <Image src={scanSrc} className={classes.image} />
      </Box>

      <Notification error={error} onClear={() => setError(null)} />
    </>
  );
}

const useStyles = createStyles({
  container: {
    position: 'relative',
    display: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  button: {
    position: 'fixed',
    top: 20,
    left: 20,
    display: 'flex',
    justifyContent: 'center',
    zIndex: 11,
  },
  image: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    width: '80%',
    objectFit: 'contain',
    transform: 'translateX(-50%) translateY(-50%)',
    zIndex: 11,
  },
});
