import {
  Box,
  createStyles,
  Flex,
  Group,
  Input,
  Stack,
  TextInput,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { Identifier } from 'dnd-core';
import React, { MutableRefObject } from 'react';

import { ReactComponent as Drag } from '@portals/icons/linear/drag.svg';
import { ReactComponent as QuestionCircle } from '@portals/icons/linear/question-circle.svg';

import { InputLabelWithTooltip } from '../../../common/input-helpers';
import { PopoverIconSelector } from '../../../common/PopoverIconSelector';
import {
  StateControllerOptionCommandType,
  StateControllerWidgetFormType,
} from '../state-controller-form.types';

export interface StateControllerOptionFormProps {
  option: StateControllerOptionCommandType;
  index: number;
  form: UseFormReturnType<StateControllerWidgetFormType>;
  handlerId: Identifier | null;
  innerRef: MutableRefObject<HTMLDivElement>;
}

export function StateControllerOptionForm({
  option,
  index,
  form,
  handlerId,
  innerRef,
}: StateControllerOptionFormProps) {
  const numOfRows = form.values.options.length;
  const { classes } = useStyles({
    numOfRows: numOfRows || 0,
  });

  return (
    <Box
      className={classes.commandWrapper}
      w="100%"
      data-handler-id={handlerId}
      ref={innerRef}
      p="sm"
    >
      <Flex h="100%" align="center" justify="center">
        <Box className={classes.dragWrapper}>
          <Drag />
        </Box>
      </Flex>

      <Stack w="100%">
        <Group noWrap align="center" spacing="xl">
          <Input.Wrapper
            label="Icon"
            required={form.values.display_icons}
            sx={{ flex: 0 }}
          >
            <PopoverIconSelector
              selectedIconName={option.icon_name}
              onChange={(iconName) =>
                form.setFieldValue(`options.${index}.icon_name`, iconName)
              }
              color={form.values.color}
            />
          </Input.Wrapper>

          <TextInput
            w="100%"
            {...form.getInputProps(`options.${index}.label`)}
            label="Label"
            data-testid="label-input"
            required
            placeholder={`Option ${index + 1}`}
          />
        </Group>

        <Group noWrap align="center">
          <TextInput
            {...form.getInputProps(`options.${index}.value`)}
            label={
              <InputLabelWithTooltip
                label="Command Value"
                Icon={QuestionCircle}
                tooltipLabel="The command parameter value that indicates the value that the command will send to the device. This is derived from the command parameters defined by the device manufactuer."
              />
            }
            disabled
          />

          <TextInput
            {...form.getInputProps(`options.${index}.telemetry_value`)}
            defaultValue={form.getInputProps(`options.${index}.value`).value}
            label={
              <InputLabelWithTooltip
                label="Telemetry Value"
                Icon={QuestionCircle}
                tooltipLabel="The value that indicates the state of the device for the chosen telemetry key. Only change this if the telemetry value is not identical to the command value."
              />
            }
            required
            withAsterisk={false}
            data-testid="button-value-key"
            placeholder={`Option ${index + 1}`}
          />
        </Group>
      </Stack>
    </Box>
  );
}

const useStyles = createStyles(
  (theme, { numOfRows }: { numOfRows: number }) => ({
    commandWrapper: {
      display: 'grid',
      gridTemplateColumns: 'min-content 1fr',
      radius: theme.radius.md,
      gap: theme.spacing.md,
      border: `1px solid ${theme.colors.gray[2]}`,
    },
    dragWrapper: {
      transform: 'rotate(90deg)',
      color: theme.colors.gray[4],
      cursor: 'grab',

      '&:active': {
        cursor: 'grabbing',
      },
    },
  })
);
