import { TableColumn, TableState } from '@portals/types';

import { usePaginatedTableApiQuery } from '../../utils/paginated-table';
import {
  WARRANTIES_API_URL,
  warrantiesQueryKeys,
} from './warranties.constants';
import { WarrantyType } from './warranties.types';

export function useWarranties(
  tableState: Partial<Pick<TableState<WarrantyType>, 'filters' | 'sortBy'>>,
  columns: TableColumn<WarrantyType>[]
) {
  return usePaginatedTableApiQuery<WarrantyType>({
    baseUrl: WARRANTIES_API_URL,
    tableState,
    columns,
    queryKey: [...warrantiesQueryKeys.all(), tableState],
  });
}
