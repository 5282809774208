import {
  Avatar,
  Box,
  Button,
  createStyles,
  Group,
  Stack,
  Text,
} from '@mantine/core';
import { size } from 'lodash/fp';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as Gallery } from '@portals/icons/bold/gallery.svg';
import { OrganizationStoreListing, PaymentIntervalEnum } from '@portals/types';
import { formatCurrency } from '@portals/utils';

interface LicenseToPurchaseProps {
  licenseStoreListing: OrganizationStoreListing;
  closeModal: () => void;
}

export function LicenseToPurchase({
  licenseStoreListing,
  closeModal,
}: LicenseToPurchaseProps) {
  const { classes } = useStyles();

  const licenseStoreListingPrice = useMemo(() => {
    if (size(licenseStoreListing.prices[0]?.pricing_options) > 1) {
      return `${size(licenseStoreListing.prices[0].pricing_options)} prices`;
    }

    return formatCurrency(
      licenseStoreListing.prices[0]?.pricing_options[0]?.amount,
      licenseStoreListing.prices[0].currency
    );
  }, [licenseStoreListing]);

  return (
    <Group position="apart" className={classes.container} align="start" noWrap>
      <Stack spacing="sm" className={classes.content}>
        <Group spacing="sm">
          <Avatar
            src={licenseStoreListing?.product.image_url}
            radius="md"
            color="blue_gray"
            size={40}
          >
            <Gallery />
          </Avatar>

          <Box maw={250}>
            <Text
              color="gray.9"
              weight={500}
              truncate
              data-testid="license-name-title"
            >
              {licenseStoreListing?.product?.name}
            </Text>

            <Text color="gray.5" weight={500} size="xs">
              by {licenseStoreListing?.partner_name}
            </Text>
          </Box>
        </Group>

        {licenseStoreListing?.product?.subtitle ? (
          <Text color="gray.7" truncate>
            {licenseStoreListing?.product?.subtitle}
          </Text>
        ) : null}
      </Stack>

      <Group spacing="xs" align="center">
        <Text weight={500} color="gray.9">
          {licenseStoreListingPrice}
        </Text>

        <Text size="xs" color="gray.5">
          {getPeriodLabel(
            licenseStoreListing.prices[0].pricing_options[0].type
          )}
        </Text>

        <Button
          size="xs"
          component={Link}
          radius="md"
          to={`/store/${licenseStoreListing.slug}`}
          onClick={closeModal}
          className={classes.seeDetailsButton}
          data-testid="see-details-button"
        >
          See details
        </Button>
      </Group>
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    borderRadius: theme.radius.md,
    border: `1px solid ${theme.colors.gray[3]}`,
    padding: theme.spacing.md,
  },

  content: {
    overflow: 'hidden',
  },

  seeDetailsButton: {
    textDecoration: 'none',

    '&:hover': {
      textDecoration: 'none',
      color: 'white',
    },
  },
}));

function getPeriodLabel(period: PaymentIntervalEnum) {
  switch (period) {
    case PaymentIntervalEnum.Monthly:
      return ' / month';
    case PaymentIntervalEnum.Yearly:
      return ' / year';
    default:
      return '';
  }
}
