import React, { FC } from 'react';

import { AutoFormik } from '@portals/autoformik';
import { FieldType, FieldTypeEnum } from '@portals/types';

import { ServiceCardFormProps } from './types';

const FIELDS: Array<FieldType> = [
  { name: 'email', title: 'Email', type: FieldTypeEnum.Email },
];

const EditEmail: FC<ServiceCardFormProps> = ({ data, readOnly, onSubmit }) => (
  <AutoFormik
    fields={FIELDS}
    initialValues={data}
    handleSubmit={onSubmit}
    readOnly={readOnly}
    inProgress={false}
    submitTitle="Update"
  />
);

export default EditEmail;
