import { get } from 'lodash/fp';
import { useMemo } from 'react';
import { useDrag } from 'react-dnd';
import { useDispatch } from 'react-redux';

import { DeviceType } from '@portals/api/organizations';
import { openModal } from '@portals/redux/actions/modal';

import { StatusType } from './DeviceStatusIcons';

export const useDeviceState = (device) =>
  useMemo(() => {
    const status = get('state.status', device);

    if (!status || status === 'offline') {
      return 'unknown';
    }

    if (status === 'error' || device.incidents[0] > 0) {
      return 'error';
    }

    if (status === 'unavailable') {
      return 'warning';
    }

    if (device.incidents[1] > 0 || device.incidents[2] > 0) {
      return 'warning';
    }

    return 'success';
  }, [device]);

export const useDeviceDrag = (device) => {
  const dispatch = useDispatch();

  const [, dragRef] = useDrag(
    () => ({
      type: 'device',
      item: { device },
      end: (item, monitor) => {
        const space = monitor.getDropResult();

        if (space) {
          dispatch(openModal('MoveDevice', { device: device, space }));
        }
      },
    }),
    [device, dispatch]
  );

  return dragRef;
};

export const useDeviceStatusIcons = (device: DeviceType) => {
  return useMemo(() => {
    const status = [];

    if (device.snoozed_until) {
      status.push(StatusType.Snoozed);
    }

    if (device.parent) {
      status.push(StatusType.Child);
    }

    return status;
  }, [device]);
};
