import {
  Box,
  createStyles,
  Group,
  LoadingOverlay,
  SegmentedControl,
  Stack,
  StackProps,
  Text,
} from '@mantine/core';
import { find, isEmpty } from 'lodash/fp';
import React from 'react';

import { OfflineDeviceStateTooltip } from '../../common/OfflineDeviceStateTooltip';
import { WIDGET_ICONS } from '../../widgets.constants';
import { WidgetColorType } from '../../widgets.types';

type Option = {
  label: string;
  iconName: string;
  value: string;
  telemetryValue: string;
};

export interface StateControllerWidgetProps {
  color: WidgetColorType;
  title: string;
  value: string;
  options: Array<Option>;
  displayIcons: boolean;
  displayTitle: boolean;
  onToggle: (option: string) => void;
  isLoading: boolean;

  stackProps?: StackProps;

  isDeviceOffline?: boolean;
  lastUpdateTimestamp?: string;
}

interface LabelProps {
  option: Option;
  withIcon: boolean;
}

function Label({ option, withIcon }: LabelProps) {
  const widgetIcon =
    withIcon &&
    WIDGET_ICONS.find((widgetIcon) => widgetIcon.iconName === option.iconName);
  const Icon = widgetIcon ? widgetIcon?.Icon : null;
  const { classes } = useLabelStyles(withIcon);

  return (
    <Box h={45} className={classes.labelWrapper}>
      {Icon ? (
        <Box>
          <Icon height={24} width={24} />
        </Box>
      ) : null}
      <Text size="sm" inherit truncate title={option.label}>
        {option.label}
      </Text>
    </Box>
  );
}

export function StateControllerWidget({
  onToggle,
  color,
  title,
  value,
  options,
  displayIcons,
  displayTitle,
  isLoading,

  stackProps = {},

  isDeviceOffline,
  lastUpdateTimestamp,
}: StateControllerWidgetProps) {
  const { classes } = useWidgetStyles(color);

  const uniqueKey = options.map((option) => option.value).join('-');

  const onChange = (telemetryValue: string) => {
    const selectedOption = find({ telemetryValue }, options);

    if (selectedOption) {
      onToggle(selectedOption.value);
    }
  };

  return (
    <Stack
      className={classes.container}
      p="xl"
      spacing="lg"
      pos="relative"
      h="100%"
      w="100%"
      bg="white"
      justify="center"
      {...stackProps}
    >
      <LoadingOverlay visible={isLoading} />

      <Group align="center" spacing="sm" noWrap>
        {displayTitle ? (
          <Text
            size="md"
            data-testid="dashboard-state-controller-widget-name"
            color="gray.5"
            truncate
          >
            {title}
          </Text>
        ) : null}

        {isDeviceOffline ? (
          <OfflineDeviceStateTooltip
            lastUpdateTimestamp={lastUpdateTimestamp}
          />
        ) : null}
      </Group>

      {!isEmpty(options) ? (
        <SegmentedControl
          disabled={isLoading || isDeviceOffline}
          opacity={isDeviceOffline ? 0.8 : 1}
          key={uniqueKey}
          data={options.map((option, index) => ({
            label: <Label option={option} withIcon={displayIcons} />,
            value: option.telemetryValue,
          }))}
          value={value}
          onChange={onChange}
          size="xl"
          data-testid="button-preview-text"
          transitionDuration={0}
          color={color}
          classNames={{
            indicator: classes.active,
            label: classes.label,
          }}
        />
      ) : (
        <Stack h={81} justify="center">
          <Text size="sm" color="gray.5" w="100%" align="center">
            Select command & param
          </Text>
        </Stack>
      )}
    </Stack>
  );
}

const useWidgetStyles = createStyles((theme, color: WidgetColorType) => ({
  container: {
    borderRadius: theme.radius.lg,
  },
  label: {
    fontWeight: 400,
    fontSize: theme.fontSizes.sm,

    '&[data-active]': {
      color: 'white',
    },
  },
  active: {
    borderRadius: theme.radius.md,
  },
}));

const useLabelStyles = createStyles((theme, withIcon: boolean) => ({
  labelWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: withIcon ? 'min-content 1fr' : '1fr',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing.xs,
  },
}));
