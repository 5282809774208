import React from 'react';

import { ValueWidget, ValueWidgetFormType } from '@portals/device-widgets';

import { WidgetProps } from '../device-widgets.types';

export function ValueWidgetWrapper({
  widget,
  deviceState,

  isDeviceOffline,
  lastUpdateTimestamp,
}: WidgetProps<ValueWidgetFormType>) {
  const fields = widget?.config?.fields;

  const {
    name,
    telemetry,
    with_measurement_unit,
    measurement_unit,
    telemetry_as_title,
    title_telemetry,
  } = fields ?? {};

  const telemetryValue = deviceState?.[telemetry];
  const title = telemetry_as_title
    ? deviceState?.[title_telemetry] || 'N/A'
    : name;

  return (
    <ValueWidget
      title={title}
      value={telemetryValue}
      unit={with_measurement_unit ? measurement_unit : null}
      isDeviceOffline={isDeviceOffline}
      lastUpdateTimestamp={lastUpdateTimestamp}
    />
  );
}
