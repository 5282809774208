import { noop } from 'lodash/fp';
import React, { createContext, ReactNode, useContext } from 'react';

export interface DeviceLocationMapProviderProps {
  onCenterMap: () => void;
  children: ReactNode;
}

const DeviceLocationMapContext = createContext<
  Omit<DeviceLocationMapProviderProps, 'children'>
>({
  onCenterMap: noop,
});

export function DeviceLocationMapProvider({
  children,
  onCenterMap,
}: DeviceLocationMapProviderProps) {
  return (
    <DeviceLocationMapContext.Provider value={{ onCenterMap }}>
      {children}
    </DeviceLocationMapContext.Provider>
  );
}

export function useDeviceLocationMap() {
  const context = useContext(DeviceLocationMapContext);

  if (!context) {
    throw new Error(
      'useDeviceLocationMap must be used within a DeviceLocationMapProvider'
    );
  }

  return context;
}
