import { Button, Divider, Group, Text } from '@mantine/core';
import React from 'react';

import { ReactComponent as Add } from '@portals/icons/linear/add.svg';
import { ReactComponent as RotateLeft } from '@portals/icons/linear/rotate-left.svg';

import { useCommonStyles } from './overview-header-panel.common';
import { useOverviewContext } from '../../overview.context';

export function OverviewConfigPanel() {
  const commonStyles = useCommonStyles();
  const overview = useOverviewContext();

  return (
    <Group className={commonStyles.classes.container} position="apart" px="xxl">
      <Group position="left">
        <Button
          size="sm"
          onClick={overview.onOpenWidgetsPanel}
          variant="subtle"
          data-testid="add-widgets-button"
          leftIcon={<Add width={18} height={18} />}
        >
          <Text>Add Widgets</Text>
        </Button>

        <Divider orientation="vertical" color="gray.3" />

        <Button
          size="sm"
          leftIcon={<RotateLeft width={18} height={18} />}
          onClick={overview.onReset}
          variant="subtle"
          color="blue_gray"
        >
          Reset dashboard to default view
        </Button>
      </Group>

      <Group position="right">
        <Button variant="default" size="sm" onClick={overview.onDiscardChanges}>
          Discard
        </Button>

        <Button
          onClick={overview.onSaveChanges}
          size="sm"
          data-testid="save-changes-button"
        >
          Save Changes
        </Button>
      </Group>
    </Group>
  );
}
