import React, { useState } from 'react';

import { InfoTable } from '@portals/table';

import Charts from './Charts';

const MSolutions = (props) => {
  const {
    device: { state },
  } = props;
  const [channel, setChannel] = useState('local');

  return (
    <>
      <InfoTable.Row label={<b>MSolutions</b>} value={null} isHeader />

      <InfoTable.Row
        label="Vendor"
        value={state[`${channel}_hdbt_device_vendor_id`]}
      />

      <InfoTable.Row label="Chip Gender" value={state[`${channel}_gender`]} />
      <InfoTable.Row
        label="Chip FW version"
        value={state[`${channel}_hdbt_chip_fw_version`]}
      />
      <InfoTable.Row
        label="Pixel Clock"
        value={state[`${channel}_hdmi_in_tmds_clock_freq`]}
      />
      <InfoTable.Row label="Cable Length" value={state.port_hdbt_cablelength} />
      <InfoTable.Row label="Module Type" value={state.module_type} />
      <InfoTable.Row
        label="Operation Mode"
        value={state.port_hdbt_operationmode}
      />
      <InfoTable.Row
        label="Product Part Number"
        value={state[`${channel}_hdbt_product_part_number`]}
      />

      <Charts {...props} channel={channel} setChannel={setChannel} />
    </>
  );
};

export default MSolutions;
