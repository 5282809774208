import { Badge, Box, Indicator, Text } from '@mantine/core';
import React from 'react';

import { useUnresolvedTicketsCount } from '@portals/api/organizations';

export const TicketsCounter = () => {
  const unresolvedTicketsCount = useUnresolvedTicketsCount();

  return unresolvedTicketsCount.data ? (
    <Badge color="blue" radius="xl" size="sm" px="xs" variant="filled">
      {unresolvedTicketsCount.data}
    </Badge>
  ) : null;
};

export function TicketsIndicator({ children }: { children: React.ReactNode }) {
  const unresolvedTicketsCount = useUnresolvedTicketsCount();

  if (!unresolvedTicketsCount.data) return <>{children}</>;

  return (
    <Indicator
      label={
        <Box>
          <Text size="10px" color="gray.8">
            {unresolvedTicketsCount.data}
          </Text>
        </Box>
      }
      inline
      size={24}
      color="red.1"
      styles={{
        indicator: {
          right: '-13px !important',
        },
      }}
    >
      {children}
    </Indicator>
  );
}
