import { Button, Group } from '@mantine/core';
import { FormikProps } from 'formik';
import React, { FC } from 'react';

import { AutoFormikProps } from '@portals/types';

const Actions: FC<AutoFormikProps & FormikProps<any>> = ({
  values,
  dirty,
  isValid,
  wrapped,
  submitTitle,
  dismissTitle,
  modal,
  requireChange = true,
  inProgress = false,
  buttonsProps = {},
}) => (
  <Group position="right" style={{ width: '100%' }}>
    {modal && (
      <Button
        {...buttonsProps}
        variant="default"
        onClick={modal}
        data-testid="actions-cancel-button"
      >
        {dismissTitle || 'Close'}
      </Button>
    )}

    <Button
      type="submit"
      data-testid="actions-submit-button"
      disabled={(requireChange && !dirty) || !isValid || inProgress}
      loading={inProgress}
      {...buttonsProps}
    >
      {!inProgress && (submitTitle || 'Update')}
    </Button>

    {wrapped && wrapped(values, isValid, dirty)}
  </Group>
);

export default Actions;
