import { createStyles, Paper, Stack, Text } from '@mantine/core';
import React from 'react';

import { PartnerAvatar } from '@portals/framework';

interface BrandCardProps {
  name: string;
  logoUrl: string;
  onClick: () => void;
}

export function BrandCard({ name, logoUrl, onClick }: BrandCardProps) {
  const { classes } = useStyles();

  return (
    <Paper p="xxl" radius="lg" className={classes.container} onClick={onClick}>
      <Stack align="center">
        <PartnerAvatar src={logoUrl} partnerName={name} radius="lg" size={70} />

        <Text size="md" align="center" data-testid={`brand-name-${name}`}>
          {name}
        </Text>
      </Stack>
    </Paper>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    border: `2px solid ${theme.colors.gray[1]}`,
    cursor: 'pointer',

    '&:hover': {
      boxShadow: '0px 5px 12px 0px rgba(38, 50, 56, 0.13)',
    },
  },
}));
