import React from 'react';

import { SignUp } from '@portals/framework';

import { AuthWrapper } from './common';

export function SignUpPage() {
  return (
    <AuthWrapper>
      <SignUp />
    </AuthWrapper>
  );
}
