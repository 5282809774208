import {
  Alert,
  Button,
  createStyles,
  Divider,
  Group,
  LoadingOverlay,
  Select,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { castArray, includes } from 'lodash/fp';
import React, { FC, useState } from 'react';

import {
  useCreateAddress,
  useOrganizationConfig,
  usePortalCapabilities,
  useUpdateAddress,
} from '@portals/api/organizations';
import { ModalCenteredMediaLayout } from '@portals/core';
import { getCountryCodes, getCountryName } from '@portals/countries';
import {
  isCustomerFeatureAll,
  isCustomerFeatureLabOnly,
  ModalProps,
  useValidateTaxAddress,
} from '@portals/framework';
import { OrganizationAddressType } from '@portals/types';

import locationSrc from '../../assets/img/location.svg';
import { Note } from '../components/Products/Note';

const COUNTRIES_TO_VALIDATE = [
  'US',
  'CA',
  'AS',
  'FM',
  'GU',
  'MH',
  'MP',
  'PR',
  'PW',
  'VI',
  'UM',
];

export interface AddAddressProps {
  initialAddress?: OrganizationAddressType;
  isEdit: boolean;
  onFinish?: (address: OrganizationAddressType) => void;
}

export const AddAddress: FC<ModalProps<AddAddressProps>> = ({
  closeMe,
  data: { initialAddress, isEdit, onFinish },
}) => {
  const { classes } = useStyles();
  const organizationConfig = useOrganizationConfig();

  const portalCapabilities = usePortalCapabilities();
  const isB2C =
    isCustomerFeatureAll(portalCapabilities?.b2c_view) ||
    isCustomerFeatureLabOnly(
      portalCapabilities?.b2c_view,
      organizationConfig.lab
    );

  const updateAddress = useUpdateAddress();
  const createAddress = useCreateAddress();

  const validateTaxAddress = useValidateTaxAddress();
  const [errors, setErrors] = useState(null);

  const form = useForm<OrganizationAddressType>({
    initialValues: initialAddress,
  });

  const handleNotes = (notes: string) => {
    form.setFieldValue('note_to_shipper', notes);
  };

  const onSubmit = async (values: typeof form.values) => {
    const shouldValidate = includes(values.country, COUNTRIES_TO_VALIDATE);

    if (shouldValidate) {
      try {
        await validateTaxAddress.mutateAsync({
          ...values,
          address_1: values.line_1,
          address_2: values.line_2,
          address_3: values.line_3,
        });
      } catch (err) {
        setErrors(err?.errors);

        return;
      }
    }

    let address: OrganizationAddressType;

    try {
      if (isEdit) {
        address = await updateAddress.mutateAsync(values);
      } else {
        address = await createAddress.mutateAsync(values);
      }

      if (onFinish) {
        onFinish(address);
      }

      closeMe();
    } catch (e) {
      setErrors(e?.errors);
    }
  };

  return (
    <ModalCenteredMediaLayout
      opened
      onClose={closeMe}
      media={<img src={locationSrc} />}
      title={isEdit ? 'Edit Address' : 'Add Address'}
      classNames={{
        media: classes.media,
        headerContent: classes.headerContent,
      }}
    >
      <LoadingOverlay
        visible={
          validateTaxAddress.isLoading ||
          updateAddress.isLoading ||
          createAddress.isLoading
        }
      />

      <form onSubmit={form.onSubmit(onSubmit)} onChange={() => setErrors(null)}>
        <Stack spacing="xl">
          <Stack mb="lg">
            <TextInput
              label="Receiver Name"
              data-testid="add-address-receiver-name-input"
              {...form.getInputProps('receiver_name')}
            />

            {isB2C ? null : (
              <Group spacing="md" grow>
                <TextInput
                  label="Tax ID"
                  data-testid="add-address-tax-id-input"
                  {...form.getInputProps('receiver_tax_id')}
                />

                <TextInput
                  label="EORI"
                  data-testid="add-address-eroi-input"
                  {...form.getInputProps('eori')}
                />
              </Group>
            )}

            <TextInput
              required
              label="Display Name"
              data-testid="add-address-display-name-input"
              {...form.getInputProps('display_name')}
            />

            <Group grow className={classes.columnOnMobile}>
              <Select
                required
                searchable
                label="Country"
                data-testid="add-address-country-select"
                {...form.getInputProps('country')}
                data={getCountryCodes().map((countryCode) => ({
                  value: countryCode,
                  label: getCountryName(countryCode),
                }))}
              />

              <TextInput
                required
                label="State"
                data-testid="add-address-state-input"
                {...form.getInputProps('state')}
              />
            </Group>

            <TextInput
              required
              label="Address #1"
              data-testid="add-address-address-1-input"
              {...form.getInputProps('line_1')}
            />

            <TextInput
              label="Address #2"
              data-testid="add-address-address-2-input"
              {...form.getInputProps('line_2')}
            />

            <TextInput
              label="Address #3"
              data-testid="add-address-address-3-input"
              {...form.getInputProps('line_3')}
            />

            <Group grow className={classes.columnOnMobile}>
              <TextInput
                required
                label="City"
                data-testid="add-address-city-input"
                {...form.getInputProps('city')}
              />

              <TextInput
                required
                label="Zip"
                data-testid="add-address-zip-input"
                {...form.getInputProps('zip')}
              />
            </Group>

            <TextInput
              label="Phone Number"
              data-testid="add-address-phone-number-input"
              {...form.getInputProps('phone_number')}
            />

            <Divider my="xs" />

            <Note
              label="Additional notes to shipper"
              onChange={handleNotes}
              value={form.values.note_to_shipper}
              paperProps={{ p: 0 }}
            />
          </Stack>

          {errors ? (
            <Alert color="red">
              <Stack spacing="xs">
                {castArray(errors).map((error, index) => (
                  <Text key={index} size="sm">
                    - {error}
                  </Text>
                ))}
              </Stack>
            </Alert>
          ) : null}

          <Group grow spacing="md">
            <Button variant="default" onClick={closeMe}>
              Cancel
            </Button>

            <Button
              type="submit"
              disabled={!form.isValid || !!errors}
              data-testid="add-address-submit-button"
            >
              {isEdit ? 'Update Address' : 'Add Address'}
            </Button>
          </Group>
        </Stack>
      </form>
    </ModalCenteredMediaLayout>
  );
};

const useStyles = createStyles((theme) => ({
  columnOnMobile: {
    [theme.fn.smallerThan('md')]: {
      flexDirection: 'column',
      alignItems: 'stretch',

      '> *': {
        maxWidth: '100%',
      },
    },
  },
  media: {
    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },
  headerContent: {
    [theme.fn.smallerThan('md')]: {
      textAlign: 'start',
    },
  },
}));
