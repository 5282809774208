import {
  Anchor,
  Box,
  createStyles,
  Divider,
  Group,
  Paper,
  Stack,
  Text,
} from '@mantine/core';
import React from 'react';

import { NameAbbreviationAvatar } from '@portals/core';

import { OrderType } from './order.types';
import { useGenericContext } from '../../../components/GenericContext';

export function OrderCustomerCard<TOrder extends OrderType>() {
  const { cx, classes } = useStyles();

  const order = useGenericContext<TOrder>();
  const address = order?.shipping_address;

  return (
    <Paper withBorder p="xl" radius="lg">
      <Stack spacing="lg">
        <Text size="md" color="gray.8">
          Customer
        </Text>

        <Group align="center" spacing="xs">
          <NameAbbreviationAvatar
            name={order.organization.display_name}
            src={order.organization.logo_url}
            h={31}
            w={31}
            miw={31}
            mih={31}
          />

          <Text size="sm" color="gray.8">
            {order.organization.display_name}
          </Text>
        </Group>

        <Divider color="gray.3" />

        <Stack>
          <Text className={cx(classes.title, classes.ellipsis)} mb="xs">
            Contact Information
          </Text>

          <Stack spacing={0}>
            {order.contact_information.email ? (
              <Box>
                <Anchor
                  size="sm"
                  href={`mailto:${order.contact_information.email}`}
                  target="_blank"
                >
                  {order.contact_information.email}
                </Anchor>
              </Box>
            ) : null}

            {order.contact_information.name ? (
              <Text size="sm" truncate color="gray.6">
                {order.contact_information.name}
              </Text>
            ) : null}
          </Stack>
        </Stack>

        {order.shipping_address ? (
          <>
            <Divider color="gray.3" />

            <Stack spacing={0}>
              <Text className={cx(classes.title, classes.ellipsis)} mb="xs">
                {address?.display_name}
                <Text span size="sm" color="gray.6" truncate>
                  {' '}
                  {address?.eori}
                </Text>
              </Text>

              <Stack spacing={4}>
                <Text size="sm" color="gray.6" truncate>
                  {address?.receiver_name}
                </Text>

                <Text size="sm" color="gray.6" truncate>
                  {address?.phone_number}
                </Text>

                <Text size="sm" color="gray.6" truncate>
                  {address?.line_1}
                </Text>

                {address?.line_2 ? (
                  <Text size="sm" color="gray.6" truncate>
                    {address.line_2}
                  </Text>
                ) : null}

                {address?.line_3 ? (
                  <Text size="sm" color="gray.6" truncate>
                    {address.line_3}
                  </Text>
                ) : null}

                <Text size="sm" color="gray.6" truncate>
                  {address?.city}, {address?.state}, {address?.zip}
                </Text>

                {address?.note_to_shipper ? (
                  <Text size="sm" color="gray.6" truncate>
                    Notes: {address?.note_to_shipper}
                  </Text>
                ) : null}
              </Stack>
            </Stack>
          </>
        ) : null}
      </Stack>
    </Paper>
  );
}

const useStyles = createStyles((theme) => ({
  ellipsis: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  title: {
    fontSize: theme.fontSizes.sm,
    color: theme.colors.blue_gray[9],
    fontWeight: 600,
  },
}));
