import { Box, createStyles, Group, Stack, Text } from '@mantine/core';
import { Prism } from '@mantine/prism';
import React from 'react';

import { AwsDeviceTelemetryType } from '@portals/api/organizations';
import { DetailsList, DetailsListProps } from '@portals/framework';
import { VerticalScrollBar } from '@portals/scrollbar';
import { prettyTime } from '@portals/utils';

import { TelemetryIcon } from './TelemetryIcon';

interface AwsDeviceLogDetailsPanelProps {
  telemetry: AwsDeviceTelemetryType;
}

export function AwsDeviceTelemetriesDetailsPanel({
  telemetry,
}: AwsDeviceLogDetailsPanelProps) {
  const { classes } = useStyles();

  const detailsList: DetailsListProps['items'] = [
    { label: 'Created', value: prettyTime(telemetry.timestamp) },
    {
      label: 'Device timestamp',
      value: prettyTime(telemetry.timestamp),
    },
  ];

  return (
    <div className={classes.container}>
      <VerticalScrollBar renderView={(props) => <Box {...props} />}>
        <Stack spacing="xl" className={classes.contentContainer}>
          <Group>
            <TelemetryIcon />
            <Text size="lg">Device Telemetry</Text>
          </Group>

          <Prism language="json" withLineNumbers>
            {JSON.stringify(telemetry.telemetries, null, 2)}
          </Prism>

          <DetailsList title="Information" items={detailsList} />
        </Stack>
      </VerticalScrollBar>
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '40%',
    height: '100%',
    backgroundColor: theme.white,
    borderLeft: `1px solid ${theme.colors.gray[3]}`,
    borderTopRightRadius: theme.radius.md,
    borderBottomRightRadius: theme.radius.md,
  },
  closeButton: {
    position: 'absolute',
    zIndex: 1,
    top: theme.spacing.sm,
    right: theme.spacing.xs,
  },
  contentContainer: {
    paddingInline: 44,
    paddingBlock: 32,
  },
}));
