import { ActionIcon, createStyles, Group, TextInput } from '@mantine/core';
import { useClickOutside } from '@mantine/hooks';
import React, { useEffect, useState } from 'react';

import { DeviceType } from '@portals/api/organizations';
import { TextWithTooltip } from '@portals/core';
import { ReactComponent as CloseX } from '@portals/icons/linear/close-x.svg';
import { ReactComponent as Edit3 } from '@portals/icons/linear/edit-3.svg';
import { ReactComponent as TickSimple } from '@portals/icons/linear/tick-simple.svg';
import { DeviceType as CommonDeviceType } from '@portals/types';

import { useDeviceContext } from '../context';

interface DeviceNameProps {
  device: DeviceType | CommonDeviceType;
}

export function DeviceName({ device }: DeviceNameProps) {
  const { classes } = useStyles();
  const { onUpdateDevice, isUpdatingDevice } = useDeviceContext();

  const [isEditMode, setIsEditMode] = useState(false);
  const [inputValue, setInputValue] = useState(device.name || '');

  const ref = useClickOutside(() => setIsEditMode(false));

  useEffect(() => {
    if (!isUpdatingDevice) {
      setIsEditMode(false);
    }
  }, [isUpdatingDevice]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onUpdateDevice({ name: inputValue });
  };

  if (isEditMode) {
    return (
      <form ref={ref} onSubmit={onSubmit}>
        <Group spacing="xs" noWrap>
          <TextInput
            sx={{ flexGrow: 1 }}
            autoFocus
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
          <ActionIcon
            size="lg"
            type="button"
            onClick={() => setIsEditMode(false)}
          >
            <CloseX width={16} height={16} />
          </ActionIcon>
          <ActionIcon size="lg" variant="filled" color="primary" type="submit">
            <TickSimple width={16} height={16} />
          </ActionIcon>
        </Group>
      </form>
    );
  }

  return (
    <Group noWrap>
      <TextWithTooltip className={classes.name} data-testid="device-name">
        {device.name || 'Unknown'}
      </TextWithTooltip>
      <ActionIcon ml="auto" onClick={() => setIsEditMode(true)}>
        <Edit3 width={20} height={20} />
      </ActionIcon>
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  name: {
    color: theme.colors.blue_gray[8],
    fontWeight: 500,
    fontSize: theme.fontSizes.lg,

    [theme.fn.largerThan(1600)]: {
      fontSize: theme.fontSizes.xl,
    },
  },
}));
