import classnames from 'classnames';
import { get } from 'lodash/fp';
import React, { FC } from 'react';
import styled from 'styled-components';

import { DeviceType } from '@portals/api/organizations';
import { DeviceModelIcon } from '@portals/framework';
import { getStyledThemeColor } from '@portals/utils';

import DeviceName from './DeviceName';
import { DeviceStatusIcons } from './DeviceStatusIcons';
import { useDeviceDrag, useDeviceState, useDeviceStatusIcons } from './hooks';

type DeviceCardProps = {
  device: DeviceType;
  handleSelected: () => void;
  template?: DeviceType;
};

const DeviceCard: FC<DeviceCardProps> = ({
  device,
  handleSelected,
  template,
}) => {
  const ref = useDeviceDrag(device);
  const deviceState = useDeviceState(device);
  const statusIcons = useDeviceStatusIcons(device);

  return (
    <Wrapper onClick={handleSelected} className="device-card-wrapper">
      <Container
        key={device.id}
        className={classnames('device device-card-container', deviceState, {
          template,
        })}
        ref={ref}
      >
        <DeviceIconWrapper className="device-icon-wrapper">
          <DeviceModelIcon
            iconName={get('model_settings.icon', device)}
            size={18}
          />
        </DeviceIconWrapper>

        <ContentWrapper>
          <DeviceName device={device} />
        </ContentWrapper>
      </Container>
      <DeviceStatusIcons
        device={device}
        statusIcons={statusIcons}
        deviceState={deviceState}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 275px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const Container = styled.div`
  width: 100%;
  padding: 15px;
  border: 1px solid ${getStyledThemeColor('gray200')};
  position: relative;
  border-radius: 4px 4px 0 0;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;

  .name {
    font-size: 14px;
    color: ${getStyledThemeColor('black')};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 160px;
  }
`;

const DeviceIconWrapper = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${getStyledThemeColor('gray200')};
  border-radius: 50%;
  margin-right: 15px;
  flex-shrink: 0;
  flex-direction: column;
`;

export default DeviceCard;
