import {
  Divider,
  Group,
  HoverCard,
  HoverCardProps,
  Stack,
  Text,
} from '@mantine/core';
import React, { ReactNode } from 'react';

import { CurrencyCode, InvoiceItem, PaymentIntervalEnum } from '@portals/types';
import { formatCurrency } from '@portals/utils';

import { DetailsList, DetailsListProps } from '../../details-list/DetailsList';

interface InvoiceItemAmountTooltipPreviewProps {
  item: InvoiceItem;
  children: ReactNode;
  currency: CurrencyCode;
}

export function InvoiceItemAmountPreviewTooltip({
  item,
  children,
  currency,
}: InvoiceItemAmountTooltipPreviewProps) {
  const getInterval = (paymentInterval: PaymentIntervalEnum) => {
    if (paymentInterval === 'monthly') return ' / month';
    else if (paymentInterval === 'yearly') return ' / year';

    return '';
  };

  const detailsList: DetailsListProps['items'] = [
    {
      label: 'Full amount',
      value: (
        <Text weight={600}>
          <Text color="gray.9">
            {formatCurrency(item.price_in_scu, currency)}
            {getInterval(item.payment_interval)}
          </Text>
        </Text>
      ),
    },
    {
      label: 'Part Quantity',
      value: (
        <Text weight={600}>
          {item.payment_month_details.days_left_in_purchased_month} days
        </Text>
      ),
    },
    {
      label: 'Part Proportion',
      value: (
        <Text weight={600}>
          {item.payment_month_details.days_paid_percentage_month}%
        </Text>
      ),
    },
  ];

  return (
    <HoverCard withinPortal position="bottom" openDelay={250}>
      <HoverCard.Target>{children}</HoverCard.Target>

      <HoverCard.Dropdown maw={350} styles={hoverCardStyles}>
        <Stack>
          <Text size="md" color="gray.9">
            What is Pro-rated price?
          </Text>

          <Text size="xs" color="gray.7" mb="md">
            "Pro-rated Price" refers to the cost of a product calculated
            proportionally for a specific period of usage. If you're using the
            product for less than the standard billing cycle, the price is
            adjusted accordingly, ensuring you only pay for the time you're
            using it.
          </Text>

          <DetailsList items={detailsList} />

          <Divider color="gray.2" />

          <Group position="apart">
            <Text size="xs" color="gray.8" weight={600}>
              Pro Rated Price
            </Text>
            <Text size="lg" color="blue_gray.9" weight={600}>
              {formatCurrency(item.prorated_price_in_scu, currency)}
            </Text>
          </Group>
        </Stack>
      </HoverCard.Dropdown>
    </HoverCard>
  );
}

const hoverCardStyles: HoverCardProps['styles'] = (theme) => ({
  dropdown: {
    borderRadius: theme.radius.md,
    padding: theme.spacing.xxl,
  },
});
