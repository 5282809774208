import { Group, Select, SelectProps, Text } from '@mantine/core';
import React, { forwardRef } from 'react';

import { GroupIcon, GroupIconNames } from './groups';

export interface GroupsSelectProps
  extends Omit<SelectProps, 'styles' | 'itemComponent' | 'data'> {
  data: Array<{ label: string; value: string; iconName: GroupIconNames }>;
}

export function GroupsSelect({ data, ...selectProps }: GroupsSelectProps) {
  return (
    <Select
      searchable
      label="Group"
      placeholder="Select group"
      data={data}
      itemComponent={SelectItem}
      styles={selectStyles}
      {...selectProps}
    />
  );
}

const selectStyles: SelectProps['styles'] = {
  item: {
    paddingBlock: 10,
  },
};

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string;
  value: string;
  iconName: GroupIconNames;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, iconName, ...others }: ItemProps, ref) => {
    return (
      <div ref={ref} {...others}>
        <Group noWrap spacing="xs">
          <GroupIcon iconName={iconName} />

          <Text color="blue_gray.9">{label}</Text>
        </Group>
      </div>
    );
  }
);
