import { MutableRefObject, useEffect, useRef } from 'react';

import { observeChildren } from './utils';

export const useObserveChildrenIntersection = ({
  isActive,
  hiddenIndexesRef,
  childrenNodesRef,
  onUpdate,
  indexOffset = 0,
}: {
  isActive: boolean;
  childrenNodesRef: MutableRefObject<Array<HTMLElement>>;
  hiddenIndexesRef: MutableRefObject<Array<number>>;
  onUpdate: (numOfHidden: number) => void;
  indexOffset?: number;
}) => {
  const observerRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    if (isActive) {
      if (observerRef.current) {
        hiddenIndexesRef.current = [];
        observerRef.current.disconnect();
        observerRef.current = null;
      }

      const parentElement = childrenNodesRef.current[0]?.parentElement;

      if (!parentElement) return;

      observerRef.current = new IntersectionObserver(
        observeChildren({
          parentElement,
          hiddenIndexesRef,
          onUpdate,
          indexOffset,
        }),
        {
          root: parentElement,
          rootMargin: '0px',
          threshold: 1.0,
        }
      );

      childrenNodesRef.current.forEach((element) =>
        (observerRef.current as IntersectionObserver).observe(element)
      );
    }

    return () => {
      observerRef.current?.disconnect();
    };
  }, [
    childrenNodesRef,
    hiddenIndexesRef,
    indexOffset,
    isActive,
    observerRef,
    onUpdate,
  ]);
};
