import { Button, createStyles, Group, Modal, Text } from '@mantine/core';
import React, { useMemo, useState } from 'react';

import { ModalFooter } from '@portals/core';
import { useHubspotConversations } from '@portals/framework';
import { ReactComponent as Clock2 } from '@portals/icons/linear/clock-2.svg';
import { ReactComponent as InfoCircle } from '@portals/icons/linear/info-circle.svg';
import { LicenseState, LicenseType } from '@portals/types';

interface AssignedLicenseCardFooterProps {
  licenseState: LicenseType['state'];
  deviceErrorMessage: LicenseType['device_error_message'];
}

export function AssignedLicenseCardFooter({
  licenseState,
  deviceErrorMessage,
}: AssignedLicenseCardFooterProps) {
  const { classes, theme } = useStyles();
  const { showWidget } = useHubspotConversations();
  const [opened, setOpened] = useState(false);

  const onContactSupportClick = () => {
    showWidget();
    setOpened(false);
  };

  const color =
    licenseState === LicenseState.Error
      ? theme.colors.red[4]
      : theme.other.primaryColor;

  const icon =
    licenseState === LicenseState.Error ? (
      <InfoCircle width={18} height={18} />
    ) : (
      <Clock2 width={18} height={18} />
    );

  const contentText = useMemo<string | null>(() => {
    switch (licenseState) {
      case LicenseState.Available:
        return 'Awaiting device to confirm license activation';
      case LicenseState.Renew:
        return 'Awaiting device to confirm license renewal';
      case LicenseState.Remove:
        return 'Awaiting device to confirm license removal';
      case LicenseState.Disable:
        return 'Awaiting device to confirm license deactivation';
      case LicenseState.Disabled:
        return 'License is deactivated';
      case LicenseState.Error:
        return "Error message wasn't provided by the device";

      default:
        return null;
    }
  }, [licenseState]);

  if (!contentText) {
    return null;
  }

  return (
    <>
      <Group spacing="xs" noWrap className={classes.container} sx={{ color }}>
        <div className={classes.iconContainer}>{icon}</div>

        <Text size="xs" weight={600}>
          {licenseState === LicenseState.Error ? (
            <div>
              An error occurred.&nbsp;
              <Text
                inherit
                underline
                component="span"
                className={classes.errorAction}
                onClick={() => setOpened(true)}
              >
                Learn more
              </Text>
            </div>
          ) : (
            contentText
          )}
        </Text>
      </Group>

      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={
          <Group>
            <InfoCircle />
            An error occurred
          </Group>
        }
      >
        <Text size="sm">{deviceErrorMessage || contentText}</Text>
        <ModalFooter position="right">
          <Button variant="default" onClick={onContactSupportClick}>
            Contact Support
          </Button>
          <Button onClick={() => setOpened(false)}>Close</Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    marginTop: 'auto',
  },
  iconContainer: {
    display: 'inline-flex',
    alignSelf: 'flex-start',
  },
  errorAction: {
    cursor: 'pointer',
    color: theme.colors.blue_gray[9],
  },
}));
