import { createStyles, Group, Stack } from '@mantine/core';
import React, { ComponentProps, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ProductCard } from '@portals/framework';
import { OrganizationStoreListing } from '@portals/types';

import { StoreCategoriesNavbar } from './StoreCategoriesNavbar';
import { SelectCurrency } from '../../../desktop/components/SelectCurrency';
import { useFilteredAndSortedStoreListings } from '../../../desktop/pages/store/hooks';
import { PricePreview } from '../../../desktop/pages/store/price-preview/PricePreview';
import { SortBy, SortByEnum } from '../../../desktop/pages/store/SortBy';

interface StoreProductListProps {
  storeListings: Array<OrganizationStoreListing>;
  currency: ComponentProps<typeof SelectCurrency>['currency'];
}

export function StoreProductsList({
  storeListings,
  currency,
}: StoreProductListProps) {
  const { classes } = useStyles();

  const navigate = useNavigate();

  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [sortBy, setSortBy] = useState(SortByEnum.Featured);

  const { sortedStoreListings } = useFilteredAndSortedStoreListings({
    storeListings: storeListings,
    category: selectedCategory,
    searchValue: '',
    sortBy,
  });

  const categories = useMemo(() => {
    const categories = storeListings
      .map((storeListing) => storeListing.product.category)
      .filter(Boolean);

    return [...new Set(categories)] as Array<string>;
  }, [storeListings]);

  return (
    <div>
      <StoreCategoriesNavbar
        categories={categories}
        selectedCategory={selectedCategory}
        onSelectCategory={setSelectedCategory}
      />

      <Group position="apart" className={classes.filtersRow}>
        <SelectCurrency currency={currency} />

        <SortBy sortBy={sortBy} setSortBy={setSortBy} />
      </Group>

      <Stack className={classes.productsListContainer}>
        {sortedStoreListings.map((storeListing) => (
          <ProductCard
            key={storeListing.id}
            onClick={() => navigate(`/store/${storeListing.slug}`)}
          >
            <ProductCard.Image
              imageUrl={storeListing.product.image_url}
              category={storeListing.product.category}
            />

            <ProductCard.Details
              name={storeListing.product.name}
              subtitle={storeListing.product.subtitle}
            >
              <PricePreview
                storeListing={storeListing}
                currencyCode={currency.selected}
              />
            </ProductCard.Details>
          </ProductCard>
        ))}
      </Stack>
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  filtersRow: {
    paddingBlock: theme.spacing.md,
    paddingInline: theme.spacing.xl,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },
  productsListContainer: {
    paddingInline: theme.spacing.md,
    paddingBlock: theme.spacing.xl,
    backgroundColor: theme.colors.gray[0],
  },
}));
