import { Button, Group } from '@mantine/core';
import React from 'react';

import { ModalCenteredMediaLayout } from '@portals/core';
import { ModalProps } from '@portals/framework';

import { ReactComponent as Confetti } from '../../assets/img/confetti.svg';

export interface OnboardingToursCompletedModalProps extends ModalProps {}

export function OnboardingToursCompletedModal({
  closeMe,
}: OnboardingToursCompletedModalProps) {
  return (
    <ModalCenteredMediaLayout
      opened
      onClose={closeMe}
      media={<Confetti />}
      mediaSize={43}
      title="Congratulations! You’ve just completed your tours"
      footer={
        <Group grow>
          <Button
            variant="default"
            data-testid="close-congratulations-modal-button"
            onClick={closeMe}
            size="md"
          >
            Close
          </Button>
        </Group>
      }
    />
  );
}
