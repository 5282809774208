import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError } from '@portals/redux/actions/toastr';

import { PRICE_QUOTES_API_URL } from './price-quotes.constants';
import { fetchApiRequest, useRequestOptions } from '../../utils';

export const useGetPriceQuotePDF = () => {
  const dispatch = useDispatch();
  const { url: baseUrl, options } = useRequestOptions({
    url: PRICE_QUOTES_API_URL,
    method: 'GET',
  });

  return useMutation({
    mutationFn: (priceQuoteId: string) =>
      fetchApiRequest(`${baseUrl}/${priceQuoteId}`, options),
    onError: () => {
      dispatch(
        toastrError('Failed to generate a Price Quote. Please try again')
      );
    },
    meta: {
      mutationName: 'useGetPriceQuotePDF',
      baseUrl: `${PRICE_QUOTES_API_URL}/:id`,
      method: 'GET',
    },
  });
};
