import { Group, Stack, Title } from '@mantine/core';
import { FormikProps } from 'formik';
import { defaultTo } from 'lodash/fp';
import React, { FC } from 'react';

import { AutoFormikProps, FieldType, FieldTypeEnum } from '@portals/types';

import Field from './fields';

const readOrExecute = (readonly, values) =>
  typeof readonly === 'function' ? readonly(values) : readonly;

type FieldWrapperProps = {
  field: FieldType;
  horizontal?: boolean;
} & AutoFormikProps &
  FormikProps<any>;

const FieldMapper: FC<FieldWrapperProps> = ({
  field,
  values,
  handleChange,
  setFieldValue,
  touched,
  errors,
  handleBlur,
  readOnly,
}) => (
  <Field
    {...field.inputProps}
    field={field}
    disabled={readOrExecute(field.disabled, values)}
    readOnly={readOrExecute(field.readOnly, values) || readOnly}
    required={readOrExecute(field.required, values) || readOnly}
    value={defaultTo(field.default, values[field.name])}
    handleChange={handleChange}
    setFieldValue={setFieldValue}
    error={touched[field.name] && errors[field.name]}
    handleBlur={handleBlur}
  />
);

const FieldWrapper: FC<FieldWrapperProps> = (props) => {
  const { field, values } = props;

  if (readOrExecute(field.hidden, values)) {
    return null;
  }

  if (field.type !== FieldTypeEnum.Group) {
    return <FieldMapper {...props} />;
  }

  const WrapperComponent = field.horizontal ? Group : Stack;

  return (
    <Stack mb={10}>
      {field.title ? <Title order={5}>{field.title}</Title> : null}

      <WrapperComponent grow spacing="md">
        {field.children.map((subChild) => (
          <FieldWrapper
            {...props}
            key={subChild.name}
            field={subChild}
            horizontal={false}
          />
        ))}
      </WrapperComponent>
    </Stack>
  );
};

export default FieldWrapper;
