import { Box, createStyles, Stack, Text } from '@mantine/core';
import { filter } from 'lodash/fp';
import React, { Dispatch, SetStateAction } from 'react';

import { DeviceDetailsType, DeviceType } from '@portals/api/organizations';

import { ReactComponent as DeviceWithChildrenImage } from './assets/device-with-children.svg';
import { ReactComponent as DeviceWithoutChildrenImage } from './assets/device-without-children.svg';

export function IndependentDevices() {
  return (
    <Text color="gray.9">
      All historical information will be lost. Device deletion may take a few
      minutes and will occur in the background. You can continue working in the
      meantime.
    </Text>
  );
}

export function SingleParentDevice({
  device,
  isWithChildren,
  setIsWithChildren,
}: {
  device: DeviceType | DeviceDetailsType;
  isWithChildren: boolean;
  setIsWithChildren: Dispatch<SetStateAction<boolean>>;
}) {
  const { classes, cx } = useStyles();

  return (
    <Stack>
      <Box className={classes.optionsWrapper}>
        <Stack
          className={cx(classes.optionWrapper, { active: isWithChildren })}
          onClick={() => setIsWithChildren(true)}
          data-testid="delete-device-with-children-button"
        >
          <DeviceWithChildrenImage />

          <Text color="gray.9" align="center" maw={145}>
            Delete device and its{' '}
            {`${
              device.child_devices_count > 1
                ? `${device.child_devices_count} children`
                : '1 child'
            }`}
          </Text>
        </Stack>

        <Stack
          className={cx(classes.optionWrapper, { active: !isWithChildren })}
          onClick={() => setIsWithChildren(false)}
          data-testid="delete-device-keep-children-button"
        >
          <DeviceWithoutChildrenImage />

          <Text color="gray.9" align="center" maw={145}>
            Delete device but keep its{' '}
            {`${
              device.child_devices_count > 1
                ? `${device.child_devices_count} children`
                : '1 child'
            }`}
          </Text>
        </Stack>
      </Box>

      <Text color="gray.9">
        All historical information will be lost. Device deletion may take a few
        minutes and will be processed in the background. You can continue
        working in the meantime.
      </Text>
    </Stack>
  );
}

export function ParentAndIndependentDevices({
  devices,
  isWithChildren,
  setIsWithChildren,
}: {
  devices: Array<DeviceType | DeviceDetailsType>;
  isWithChildren: boolean;
  setIsWithChildren: Dispatch<SetStateAction<boolean>>;
}) {
  const { classes, cx } = useStyles();

  const numOfIndependentDevices = filter(
    { child_devices_count: 0 },
    devices
  ).length;
  const numOfParentDevices = devices.length - numOfIndependentDevices;

  return (
    <Stack>
      <Text color="gray.9">
        {`You have selected ${
          numOfParentDevices > 1
            ? `${numOfParentDevices} parent devices`
            : '1 parent device'
        } and ${
          numOfIndependentDevices > 1
            ? `${numOfIndependentDevices} independent devices`
            : '1 independent device'
        } for deletion.`}
      </Text>

      <Text color="gray.9">
        {`For the parent ${
          numOfParentDevices > 1 ? 'devices' : 'device'
        }, please select your preferred option:`}
      </Text>

      <Box className={classes.optionsWrapper}>
        <Stack
          className={cx(classes.optionWrapper, { active: isWithChildren })}
          onClick={() => setIsWithChildren(true)}
          data-testid="delete-device-with-children-button"
        >
          <DeviceWithChildrenImage />

          <Text color="gray.9" align="center" maw={145}>
            {numOfParentDevices > 1
              ? `Delete parent devices and their children`
              : `Delete parent device and its children`}
          </Text>
        </Stack>

        <Stack
          className={cx(classes.optionWrapper, { active: !isWithChildren })}
          onClick={() => setIsWithChildren(false)}
          data-testid="delete-device-keep-children-button"
        >
          <DeviceWithoutChildrenImage />

          <Text color="gray.9" align="center" maw={145}>
            {numOfParentDevices > 1
              ? `Delete parent devices but keep their children`
              : `Delete parent device but keep its children`}
          </Text>
        </Stack>
      </Box>

      <Text color="gray.9">
        All historical information will be lost. Device deletion may take a few
        minutes and will be processed in the background. You can continue
        working in the meantime.
      </Text>
    </Stack>
  );
}

export function MultipleParentDevices({
  devices,
  isWithChildren,
  setIsWithChildren,
}: {
  devices: Array<DeviceType | DeviceDetailsType>;
  isWithChildren: boolean;
  setIsWithChildren: Dispatch<SetStateAction<boolean>>;
}) {
  const { classes, cx } = useStyles();

  return (
    <Stack>
      <Text color="gray.9">
        You have selected {devices.length} parent devices for deletion.
      </Text>

      <Text color="gray.9">Please select your preferred option:</Text>

      <Box className={classes.optionsWrapper}>
        <Stack
          className={cx(classes.optionWrapper, { active: isWithChildren })}
          onClick={() => setIsWithChildren(true)}
          data-testid="delete-device-with-children-button"
        >
          <DeviceWithChildrenImage />

          <Text color="gray.9" align="center" maw={145}>
            Delete parent devices and their children
          </Text>
        </Stack>

        <Stack
          className={cx(classes.optionWrapper, { active: !isWithChildren })}
          onClick={() => setIsWithChildren(false)}
          data-testid="delete-device-keep-children-button"
        >
          <DeviceWithoutChildrenImage />

          <Text color="gray.9" align="center" maw={145}>
            Delete parent devices but keep their children
          </Text>
        </Stack>
      </Box>

      <Text color="gray.9">
        All historical information will be lost. Device deletion may take a few
        minutes and will be processed in the background. You can continue
        working in the meantime.
      </Text>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  optionsWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing.md,
  },
  optionWrapper: {
    borderRadius: theme.radius.md,
    padding: theme.spacing.md,
    border: `1px solid ${theme.colors.gray[3]}`,
    transition: 'border-color 150ms ease-in-out',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',

    '&.active': {
      borderColor: theme.colors.red[4],
      cursor: 'default',
    },
  },
}));
