import {
  ActionIcon,
  Alert,
  Box,
  Button,
  createStyles,
  Group,
  Modal,
  Stack,
  Text,
} from '@mantine/core';
import lottie, { AnimationItem } from 'lottie-web';
import React, { FC, useRef } from 'react';

import { ModalProps } from '@portals/framework';
import { ReactComponent as CloseX } from '@portals/icons/linear/close-x.svg';

import * as animation from './assets/animation.json';
import { ReactComponent as BoxIcon } from './assets/box.svg';
import { ReactComponent as ShopIcon } from './assets/shop.svg';

export const Connect: FC<ModalProps> = ({ closeMe }) => {
  const { classes } = useStyles();

  const animationRef = useRef<AnimationItem>();
  const onRef = (node: HTMLDivElement) => {
    if (!node || !!animationRef.current) return;

    animationRef.current = lottie.loadAnimation({
      container: node,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animation,
    });
  };

  return (
    <Modal
      opened
      onClose={closeMe}
      padding={0}
      classNames={{
        header: classes.modalHeader,
        content: classes.modalContent,
      }}
    >
      <Box className={classes.header}>
        <Box ref={onRef} className={classes.animationContainer} />

        <ActionIcon onClick={closeMe} className={classes.closeIcon}>
          <CloseX />
        </ActionIcon>
      </Box>

      <Stack className={classes.content}>
        <Text className={classes.title}>
          New connection, new possibilities!
        </Text>

        <Stack spacing="md" className={classes.alerts}>
          <Alert
            py={32}
            radius="md"
            icon={<ShopIcon />}
            title={
              <Text className={classes.alertTitle}>
                Explore your new connection's store
              </Text>
            }
            sx={(theme) => ({
              backgroundColor: theme.fn.rgba(theme.colors.blue_accent[1], 0.1),
            })}
            classNames={{
              icon: classes.alertIcon,
              wrapper: classes.alertWrapper,
            }}
          >
            <Text className={classes.alertLabel}>
              Order Products, Features, and services online now
            </Text>
          </Alert>

          <Alert
            py={32}
            radius="md"
            icon={<BoxIcon />}
            title={
              <Text className={classes.alertTitle}>
                Claim your new connection's devices
              </Text>
            }
            sx={(theme) => ({
              backgroundColor: theme.fn.rgba(theme.colors.purple[1], 0.1),
            })}
            classNames={{
              icon: classes.alertIcon,
              wrapper: classes.alertWrapper,
            }}
          >
            <Text className={classes.alertLabel}>
              Get access to the latest products and services
            </Text>
          </Alert>
        </Stack>
      </Stack>

      <Group position="center" mb="md">
        <Button variant="white" onClick={closeMe}>
          Got it
        </Button>
      </Group>
    </Modal>
  );
};

const useStyles = createStyles((theme) => ({
  modalHeader: {
    display: 'none',
  },
  modalContent: {
    borderTopRightRadius: theme.radius.lg,
    borderTopLeftRadius: theme.radius.lg,
  },
  header: {
    height: 227,
    position: 'relative',
    overflow: 'hidden',
    borderTopRightRadius: theme.radius.md,
    borderTopLeftRadius: theme.radius.md,
  },
  animationContainer: {
    position: 'absolute',
    overflow: 'hidden',
    left: 0,

    svg: {
      width: '100%',
    },
  },
  closeIcon: {
    position: 'absolute',
    top: 20,
    right: 20,
    color: theme.white,

    svg: {
      width: 16,
      height: 16,
    },
  },
  content: {
    width: '100%',
    padding: 32,
    gap: theme.spacing.xl,
    alignItems: 'center',
  },
  title: {
    fontSize: theme.fontSizes.xl,
    color: theme.colors.blue_gray[9],
  },
  alerts: {
    width: '100%',
  },
  alertWrapper: {
    alignItems: 'center',
  },
  alertTitle: {
    fontSize: theme.fontSizes.md,
    color: theme.colors.gray[9],
    fontWeight: 500,
  },
  alertIcon: {
    width: 38,
    height: 38,
  },
  alertLabel: {
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[9],
    fontWeight: 300,
  },
}));
