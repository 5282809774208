import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  root: {
    width: 300,

    '.search-input-icon': {
      color: theme.colors.gray[5],
      height: 14,
      width: 14,
    },
  },
}));
