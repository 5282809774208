import { flow, isObject, keys, map, sortBy, toString } from 'lodash/fp';

import { FieldType } from '@portals/types';

export function normalizeSelectFieldOptions(
  options: FieldType['options'] | string[] | any[]
) {
  if (Array.isArray(options)) {
    return map(toString, options);
  } else if (isObject(options)) {
    return flow([
      keys,
      map((optionKey: string) => {
        const optionValue = options[optionKey];

        return {
          // make sure we don't lose extra fields
          ...(isObject(optionValue) ? optionValue : {}),
          label: calculateLabel(optionKey, optionValue),
          value: optionKey,
        };
      }),
      sortBy('order'),
    ])(options);
  }

  return [];
}

function calculateLabel(
  optionKey: string,
  optionValue: Record<string, unknown> | string | null | undefined
): string {
  if (optionValue === null || optionValue === undefined) {
    return optionKey;
  }

  if (typeof optionValue === 'string') {
    return optionValue;
  }

  if (optionValue.label) {
    return optionValue.label as string;
  }

  return optionKey;
}
