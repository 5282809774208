import {
  ADD_PRODUCT_TO_CART,
  CHECK_CART_ITEMS,
  CHECK_SELECTED_ADDRESS,
  CHECK_SELECTED_CREDIT_CARD,
  CHECK_SELECTED_CURRENCY,
  CLEAR_CART,
  REMOVE_PRODUCT_FROM_CART,
  SET_BILLING_ADDRESS_ID,
  SET_CREDIT_CARD_ID,
  SET_IS_SAME_ADDRESS,
  SET_PAYMENT_METHOD,
  SET_PRODUCT_QUANTITY,
  SET_SELLER_NOTES,
  SET_SHIPPING_ADDRESS_ID,
  SET_STORE_CURRENCY,
  SET_SWITCH_NOTES,
} from '@portals/redux';
import {
  AddProductToCartPayload,
  CreditCardType,
  CurrencyCode,
  OrganizationAddressType,
  OrganizationStoreListing,
  PaymentMethodEnum,
} from '@portals/types';

export const setStoreCurrency = (currency: string) => ({
  type: SET_STORE_CURRENCY,
  payload: {
    currency,
  },
});

export const addProductToCart = ({
  id,
  period,
  quantity,
  price,
}: AddProductToCartPayload) => ({
  type: ADD_PRODUCT_TO_CART,
  payload: {
    id,
    period,
    quantity,
    price,
  },
});

export const removeProductFromCart = ({
  id,
  period,
}: Omit<AddProductToCartPayload, 'quantity'>) => ({
  type: REMOVE_PRODUCT_FROM_CART,
  payload: {
    id,
    period,
  },
});

export const setProductQuantity = ({
  id,
  period,
  quantity,
}: AddProductToCartPayload) => ({
  type: SET_PRODUCT_QUANTITY,
  payload: {
    id,
    period,
    quantity,
  },
});

export const clearCart = () => ({
  type: CLEAR_CART,
});

export const checkSelectedCurrency = (
  currencies: Array<CurrencyCode>,
  defaultCurrency: CurrencyCode
) => ({
  type: CHECK_SELECTED_CURRENCY,
  payload: {
    currencies,
    defaultCurrency,
  },
});

export const checkCartItems = (
  storeListings: Array<OrganizationStoreListing>
) => ({
  type: CHECK_CART_ITEMS,
  payload: {
    storeListings,
  },
});

export const checkSelectedAddress = (
  addresses: Array<OrganizationAddressType>
) => ({
  type: CHECK_SELECTED_ADDRESS,
  payload: {
    addresses,
  },
});

export const checkSelectedCreditCard = (
  creditCards: Array<CreditCardType>
) => ({
  type: CHECK_SELECTED_CREDIT_CARD,
  payload: {
    creditCards,
  },
});

export const setCreditCardId = (id: string | null) => ({
  type: SET_CREDIT_CARD_ID,
  payload: {
    id,
  },
});

export const setBillingAddressId = (id: string | null) => ({
  type: SET_BILLING_ADDRESS_ID,
  payload: {
    id,
  },
});

export const setShippingAddressId = (id: string | null) => ({
  type: SET_SHIPPING_ADDRESS_ID,
  payload: {
    id,
  },
});

export const setIsSameAddress = (isSameAddress: boolean) => ({
  type: SET_IS_SAME_ADDRESS,
  payload: {
    isSameAddress,
  },
});

export const setPaymentMethod = (paymentMethod: PaymentMethodEnum) => ({
  type: SET_PAYMENT_METHOD,
  payload: {
    paymentMethod,
  },
});

export const setSellerNotes = (notes: string) => ({
  type: SET_SELLER_NOTES,
  payload: {
    notes,
  },
});

export const setNotesSwitchStatus = (status: boolean) => ({
  type: SET_SWITCH_NOTES,
  payload: {
    status,
  },
});
