import { Button, Stack } from '@mantine/core';
import React from 'react';

import orgExists from '../../../../assets/img/org-exists.svg';
import { StepSubtitle, StepTitle } from '../common';
import { useSignUpWizard, useTrackPageView } from '../wizard/wizard.hooks';
import { StepIdEnum } from '../wizard/wizard.types';

export function OrganizationExists() {
  const { setStep, setContextData } = useSignUpWizard();

  const onCreateNewOrganization = () => {
    setContextData((curr) => ({
      ...curr,
      organizationExists: false,
    }));

    setStep(StepIdEnum.OrganizationDoesntExists);
  };

  useTrackPageView('Org already exists screen');

  return (
    <Stack>
      <img src={orgExists} height={270} />

      <StepTitle>Organization already exists</StepTitle>

      <StepSubtitle mb="xxl">
        You've entered an email domain that matches an existing organization in
        our database. Please contact your Xyte admin and request access to join
        it.
        <br />
        Alternatively, you may choose to create a new, separate organization.
      </StepSubtitle>

      <Button
        fullWidth
        data-analyticsid="create-new-organization-org-exists"
        data-testid="create-new-organization"
        onClick={() => onCreateNewOrganization()}
        variant="default"
        size="md"
      >
        Create New Organization
      </Button>
    </Stack>
  );
}
