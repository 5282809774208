import React, { FC } from 'react';

import {
  useFeatureFlags,
  useUpdateFeatureFlags,
} from '@portals/api/organizations';
import Modals from '@portals/framework/modals';
import { OrganizationFeatureFlagsType } from '@portals/types';

import { FEATURE_LIST } from './constants';

interface FeatureFlagsProps {
  closeMe: () => void;
}

const FeatureFlags: FC<FeatureFlagsProps> = ({ closeMe }) => {
  const updateFeatureFlags = useUpdateFeatureFlags();
  const featuresFlags = useFeatureFlags();

  const onSubmit = async (
    updatedFeatures: Partial<OrganizationFeatureFlagsType>
  ) => {
    try {
      await updateFeatureFlags.mutateAsync({ feature_flags: updatedFeatures });

      closeMe();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modals.FeatureFlagsModal<OrganizationFeatureFlagsType>
      closeMe={closeMe}
      features={featuresFlags}
      featuresToDisplay={FEATURE_LIST}
      onSubmit={(updatedFeatures) => onSubmit(updatedFeatures)}
    />
  );
};

export default FeatureFlags;
