import { motion } from 'framer-motion';
import React, { ReactNode, useMemo, useState, useRef, FC } from 'react';
import { Button } from 'reactstrap';
import styled from 'styled-components';

import { ReactComponent as ArrowLeft } from '@portals/icons/linear/arrow-left.svg';

type ContainerProps = {
  children: ReactNode;
  className?: string;
};

export type UseCollapsibleType = {
  Container: FC<any>;
  isInitiallyCollapsed?: boolean;
  toggleRenderer?: ({ isCollapsed, toggleCollapsed }) => ReactNode;
};

export type UseCollapsibleReturnType = [
  FC<any>,
  { isCollapsed: boolean; toggleCollapsed: () => void }
];

export const useCollapsible = ({
  Container,
  isInitiallyCollapsed = false,
  toggleRenderer,
}: UseCollapsibleType): UseCollapsibleReturnType => {
  const [isCollapsed, setIsCollapsed] = useState(isInitiallyCollapsed);
  const isInitial = useRef(true);

  const MotionContainer = useMemo(
    () => motion<ContainerProps>(Container, { forwardMotionProps: true }),
    [Container]
  );

  const initialWidth = useMemo(() => {
    if (isInitial.current) {
      isInitial.current = false;

      return isCollapsed ? '0%' : '100%';
    }

    return isCollapsed ? '100%' : '0%';
  }, [isCollapsed]);

  const CollapsibleContainer = useMemo(
    () =>
      ({ children, className = '' }) =>
        (
          <MotionContainer
            layout
            style={{ position: 'relative' }}
            initial={{ width: initialWidth }}
            animate={{ width: isCollapsed ? '0%' : '100%' }}
            transition={{ duration: 0.25 }}
            className={className}
          >
            <motion.div
              style={{ width: '100%' }}
              initial={{ opacity: 1 }}
              animate={{ opacity: isCollapsed ? 0 : 1 }}
              transition={{ duration: 0.1 }}
            >
              {children}
            </motion.div>

            <CollapseToggleWrapper $isCollapsed={isCollapsed}>
              {toggleRenderer ? (
                toggleRenderer({
                  isCollapsed,
                  toggleCollapsed: () => setIsCollapsed((curr) => !curr),
                })
              ) : (
                <Button
                  size="sm"
                  color="primary"
                  onClick={() => setIsCollapsed((curr) => !curr)}
                >
                  <ArrowLeft width={18} height={18} />
                </Button>
              )}
            </CollapseToggleWrapper>
          </MotionContainer>
        ),
    [MotionContainer, initialWidth, isCollapsed, toggleRenderer]
  );

  return [
    CollapsibleContainer,
    {
      isCollapsed,
      toggleCollapsed: () => setIsCollapsed((curr) => !curr),
    },
  ];
};

const CollapseToggleWrapper = styled(motion.div)<{ $isCollapsed: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  transition: transform 0.15s ease-in-out;

  svg {
    transition: transform 0.15s ease-in-out;
    transform: rotate(0deg);
    transform-origin: center;

    ${({ $isCollapsed }) =>
      $isCollapsed &&
      `
      transform: rotate(180deg);
    `}
  }
`;
