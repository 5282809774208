import { Badge, BadgeProps } from '@mantine/core';
import React from 'react';

import { IncidentStatus } from '@portals/types';

interface IncidentStatusBadgeProps extends BadgeProps {
  status: IncidentStatus;
}

export function IncidentStatusBadge({
  status,
  ...badgeProps
}: IncidentStatusBadgeProps) {
  return (
    <Badge
      size="lg"
      radius="xl"
      fz="xs"
      fw={400}
      tt="capitalize"
      color="gray.9"
      bg={status === 'active' ? 'red.0' : 'gray.1'}
      {...badgeProps}
    >
      {status}
    </Badge>
  );
}
