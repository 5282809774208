import { AuthType, TenantType } from '@portals/types';

import {
  API,
  SIGNED_IN,
  SIGNED_OUT,
  SWITCH_TENANT,
  UPDATE_AUTH_TENANT_NAME,
} from '../constants';

export const signedIn = (payload) => ({
  type: SIGNED_IN,
  payload,
});

export const signedOut = () => ({
  type: SIGNED_OUT,
});

export const updateAuthTenantName = (tenantName: string) => ({
  type: UPDATE_AUTH_TENANT_NAME,
  payload: { tenantName },
});

export const switchTenant = (
  tenant: Partial<AuthType['tenant']>,
  type: TenantType,
  redirectTo?: string
) => ({
  type: SWITCH_TENANT,
  payload: { ...tenant, type },
  meta: {
    redirectTo,
  },
});

export const getAuthHeaders = (auth: AuthType | undefined) => {
  return new Headers({
    client: auth?.client ?? '',
    'access-token': auth?.token ?? '',
    uid: auth?.uid ?? '',
    'token-type': 'Bearer',
    expiry: auth?.expiry ?? '',
    tenant: auth?.tenant?.id ?? '',
    'tenant-type': auth?.tenant?.type ?? '',
  });
};

export const changePasswordToken = (
  token: string,
  password: string,
  tenant: TenantType,
  extraActions: VoidFunction
) => ({
  type: API,
  payload: {
    url: 'ui/users/reset_password_token',
    data: { token, password, tenant },
    success: [signedIn, extraActions],
    withError: true,
    name: 'changePasswordToken',
    toastr: 'New password set',
  },
  meta: { log: false },
});

export const changePassword = (data, extraActions) => ({
  type: API,
  payload: {
    url: 'ui/users/change_password',
    data,
    success: [signedIn, extraActions],
    withError: true,
    name: 'changePassword',
    toastr: 'New password set',
  },
  meta: { log: false },
});
