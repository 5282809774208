import { Box, createStyles, LoadingOverlay, Tabs } from '@mantine/core';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useSpaceDetails } from '@portals/api/organizations';

import { GroupsAccessList } from './GroupsAccessList';
import { UsersAccessList } from './UsersAccessList';
import { OVERVIEW_SETTINGS_TABS } from '../../settings-drawer.constants';
import { OverviewSettingsTabEnum } from '../../settings-drawer.types';
import { SettingsTabContainer } from '../tabs.common';

export function Access() {
  const { classes } = useStyles();
  const params = useParams<{ spaceId?: string }>();

  const space = useSpaceDetails(Number(params.spaceId));
  const [activeTab, setActiveTab] = useState<'users' | 'groups'>('users');

  if (!space.isFetched) {
    return <LoadingOverlay visible />;
  }

  return (
    <SettingsTabContainer
      tab={OVERVIEW_SETTINGS_TABS[OverviewSettingsTabEnum.Access]}
    >
      <Box className={classes.container} p={42}>
        <Tabs
          keepMounted={false}
          value={activeTab}
          onTabChange={(tab: 'users' | 'groups') => setActiveTab(tab)}
          classNames={{
            root: classes.tabsRoot,
            panel: classes.tabPanel,
          }}
        >
          <Tabs.List>
            <Tabs.Tab value="users">Users</Tabs.Tab>
            <Tabs.Tab value="groups">Groups</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="users">
            <UsersAccessList space={space.data} />
          </Tabs.Panel>

          <Tabs.Panel value="groups">
            <GroupsAccessList space={space.data} />
          </Tabs.Panel>
        </Tabs>
      </Box>
    </SettingsTabContainer>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    height: '100%',
  },
  tabsRoot: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
  },
  tabPanel: {
    height: '100%',
    paddingTop: theme.spacing.xl,
  },
}));
