import {
  Button,
  createStyles,
  Grid,
  Group,
  Input,
  Paper,
  SegmentedControl,
  Select,
  Stack,
  Text,
  TextInput,
  UnstyledButton,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { motion } from 'framer-motion';
import React from 'react';
import { v4 as uuid } from 'uuid';

import { ReactComponent as CloseXIcon } from '@portals/icons/linear/close-x.svg';

import { DeviceEventsInfoHoverCard } from './DeviceEventsInfoHoverCard';
import { ScatterChartWidgetFormType } from './scatter-chart-form.types';
import { NumberFormatSelector } from '../../common/NumberFormatSelector';
import { PopoverColorSelector } from '../../common/PopoverColorSelector';
import { WIDGET_COLORS } from '../../widgets.constants';
import { OnAddCustomColorFn, WidgetColorType } from '../../widgets.types';

const MAX_NUM_OF_EVENTS = 8;

export interface ScatterChartWidgetFormProps {
  form: UseFormReturnType<ScatterChartWidgetFormType>;
  colors: Array<WidgetColorType> | undefined;
  onAddCustomColor: OnAddCustomColorFn | undefined;
}

function shouldDisableNumericParameter({
  currentEvent,
  index,
  events,
  numericParameter,
}: {
  currentEvent: ScatterChartWidgetFormType['events'][number];
  index: number;
  events: ScatterChartWidgetFormType['events'];
  numericParameter: ScatterChartWidgetFormType['events'][number]['numeric_parameter'];
}) {
  const eventsWithSameName = events.filter(
    (event, eventIndex) =>
      currentEvent.event_name === event.event_name && eventIndex !== index
  );

  return eventsWithSameName.some(
    (event) => event.numeric_parameter === numericParameter
  );
}

export function ScatterChartWidgetForm({
  form,
  colors,
  onAddCustomColor,
}: ScatterChartWidgetFormProps) {
  const canAddEvent = form.values.events.length < MAX_NUM_OF_EVENTS;

  const onAddEvent = () => {
    const color =
      colors?.[form.values.events.length % colors?.length] || WIDGET_COLORS[0];

    form.insertListItem('events', {
      id: uuid(),
      event_name: '',
      event_display_name: '',
      numeric_parameter: '',
      units: '',
      color,
    } satisfies ScatterChartWidgetFormType['events'][number]);
  };

  return (
    <Stack spacing="xl">
      <Stack>
        <Text size="sm" color="gray.9">
          General
        </Text>

        <TextInput
          required
          label="Title"
          autoFocus
          data-autofocus
          data-testid="scatter-chart-title-name-input"
          {...form.getInputProps('name')}
        />

        <Input.Wrapper>
          <Input.Label>Scale Type</Input.Label>

          <SegmentedControl
            fullWidth
            data={[
              { label: 'Linear', value: 'linear' },
              { label: 'Logarithmic', value: 'log' },
            ]}
            value={form.values.scale_type}
            onChange={(value: 'linear' | 'log') =>
              form.setFieldValue('scale_type', value)
            }
          />
        </Input.Wrapper>

        <NumberFormatSelector
          format={form.values.number_format}
          numOfDecimals={form.values.num_of_decimals}
          onFormatChange={(format) =>
            form.setFieldValue('number_format', format)
          }
          onNumOfDecimalsChange={(numOfDecimals) =>
            form.setFieldValue('num_of_decimals', numOfDecimals)
          }
        />
      </Stack>

      <motion.div layout="position">
        <Text size="sm" color="gray.9" mb="md">
          Data
        </Text>

        <DeviceEventsFormList
          form={form}
          colors={colors}
          onAddCustomColor={onAddCustomColor}
        />

        <Button
          fullWidth
          mt="xxl"
          variant="default"
          disabled={!canAddEvent}
          onClick={onAddEvent}
          data-testid="add-event-button"
        >
          {!canAddEvent
            ? `You can add up to ${MAX_NUM_OF_EVENTS} events`
            : 'Add Event'}
        </Button>
      </motion.div>
    </Stack>
  );
}

interface DeviceEventsFormListProps {
  form: ScatterChartWidgetFormProps['form'];
  colors: ScatterChartWidgetFormProps['colors'];
  onAddCustomColor: ScatterChartWidgetFormProps['onAddCustomColor'];
}

function DeviceEventsFormList({
  form,
  colors,
  onAddCustomColor,
}: DeviceEventsFormListProps) {
  const { classes } = useStyles();

  return (
    <Stack spacing="xl">
      {form.values.events.map((event, index) => (
        <Paper key={event.id} withBorder p="xl" radius="lg" pos="relative">
          {form.values.events.length > 1 && (
            <UnstyledButton
              className={classes.eventRemoveButton}
              onClick={() => form.removeListItem('events', index)}
            >
              <CloseXIcon width={16} height={16} />
            </UnstyledButton>
          )}

          <Grid>
            <Grid.Col span={7}>
              <Group align="flex-end">
                <PopoverColorSelector
                  selectedColor={form.values.events[index].color}
                  colors={colors}
                  onAddCustomColor={onAddCustomColor}
                  indicatorClassName={classes.colorIndicator}
                  onChange={(color) =>
                    form.setFieldValue(`events.${index}.color`, color)
                  }
                />

                <TextInput
                  required
                  withAsterisk={false}
                  label={
                    <Group spacing="xs" noWrap>
                      <Text>Event Name</Text>

                      <DeviceEventsInfoHoverCard />
                    </Group>
                  }
                  data-testid="text-input-event-name"
                  {...form.getInputProps(`events.${index}.event_name`)}
                />
              </Group>
            </Grid.Col>

            <Grid.Col span={5}>
              <Select
                required
                withAsterisk={false}
                label="Numeric Parameter"
                data={[
                  {
                    value: 'int_key_1',
                    label: 'Value 1',
                    disabled: shouldDisableNumericParameter({
                      currentEvent: event,
                      index,
                      events: form.values.events,
                      numericParameter: 'int_key_1',
                    }),
                  },
                  {
                    value: 'int_key_2',
                    label: 'Value 2',
                    disabled: shouldDisableNumericParameter({
                      currentEvent: event,
                      index,
                      events: form.values.events,
                      numericParameter: 'int_key_2',
                    }),
                  },
                ]}
                {...form.getInputProps(`events.${index}.numeric_parameter`)}
              />
            </Grid.Col>

            <Grid.Col span={7}>
              <TextInput
                required
                withAsterisk={false}
                label="Event Display Name"
                data-testid="text-input-event-display-name"
                {...form.getInputProps(`events.${index}.event_display_name`)}
              />
            </Grid.Col>

            <Grid.Col span={5}>
              <TextInput
                label="Unit (optional)"
                data-testid="unit-text-input"
                {...form.getInputProps(`events.${index}.units`)}
              />
            </Grid.Col>
          </Grid>
        </Paper>
      ))}
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  eventRemoveButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    transform: 'translate(50%, -50%)',
    display: 'grid',
    placeContent: 'center',
    width: 32,
    height: 32,
    borderRadius: '50%',
    border: `1px solid ${theme.colors.gray[3]}`,
    boxShadow: '0px 2px 8px 0px rgba(38, 50, 56, 0.13)',
    backgroundColor: theme.white,
    color: theme.colors.gray[5],
  },
  colorIndicator: {
    transform: 'translateY(-50%)',
  },
}));
