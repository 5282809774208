import { Box, createStyles, Stack, Text, useMantineTheme } from '@mantine/core';
import { get } from 'lodash/fp';
import moment from 'moment';
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { IncidentType } from '@portals/api/organizations';
import { DeviceModelIcon } from '@portals/framework';
import { ReactComponent as ArrowRight } from '@portals/icons/linear/arrow-right.svg';
import { ReactComponent as Danger } from '@portals/icons/linear/danger.svg';
import { INCIDENT_TYPE } from '@portals/utils';

import { SpacePath } from './SpacePath';

interface TicketOrIncidentItemProps {
  item: IncidentType;
}

export const IncidentItem: FC<TicketOrIncidentItemProps> = ({ item }) => {
  const { classes, cx, theme } = useStyles();
  const { colors } = useMantineTheme();
  const navigate = useNavigate();

  const [isItemHovered, setIsItemHovered] = useState(false);

  const organizationName = item.org_display_name;

  const getCreatedAt = moment(item.created_at).calendar(null, {
    lastWeek: '[Last] dddd LT',
    lastDay: '[Yesterday] LT',
    sameDay: '[Today] LT',
    sameElse: 'DD/MM/YYYY LT',
  });

  const getIcon = () => {
    if ('priority' in item) {
      const { color } = INCIDENT_TYPE[item.priority - 1];

      return <Danger style={{ color: colors[color][4] }} />;
    }

    return (
      <Box sx={{ color: theme.other.primaryColor }}>
        <DeviceModelIcon
          iconName={get('item.device_model_icon', item)}
          size={24}
        />
      </Box>
    );
  };

  const redirectToItemView = () => navigate(`/incident/${item.id}`);

  return (
    <Box
      className={cx(classes.container, { [classes.hovered]: isItemHovered })}
      onMouseEnter={() => setIsItemHovered(true)}
      onMouseLeave={() => setIsItemHovered(false)}
      onClick={redirectToItemView}
    >
      {getIcon()}
      <Stack spacing={2} sx={{ overflow: 'hidden' }}>
        <Text size="xs" sx={{ color: theme.colors.gray[4] }}>
          {getCreatedAt}
        </Text>
        <Text
          size="sm"
          weight={600}
          className={cx(classes.title, classes.longText)}
          data-testid="incident-item-title"
        >
          {item.title}
        </Text>
        <Text size="xs" className={classes.organizationDisplayName}>
          {organizationName}
        </Text>
        <Box className={classes.longText} sx={{ color: theme.colors.gray[5] }}>
          <SpacePath incident={item} />
        </Box>
      </Stack>

      {isItemHovered && <ArrowRight />}
    </Box>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    display: 'grid',
    flexDirection: 'column',
    gridTemplateColumns: '24px 1fr 24px',
    gap: theme.spacing.xs,
    alignItems: 'center',
    padding: `${theme.spacing.xl} ${theme.spacing.xs}`,
    borderBottom: `1px solid ${theme.colors.gray[3]}`,

    [theme.fn.largerThan('xl')]: {
      gap: theme.spacing.md,
      padding: `${theme.spacing.xl} ${theme.spacing.md}`,
    },
  },
  title: {
    color: theme.colors.gray[9],

    [theme.fn.largerThan('xl')]: {
      fontSize: theme.fontSizes.md,
    },
  },
  organizationDisplayName: {
    color: theme.colors.gray[7],

    [theme.fn.largerThan('xl')]: {
      fontSize: theme.fontSizes.sm,
    },
  },
  longText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },

  hovered: {
    cursor: 'pointer',
  },
}));
