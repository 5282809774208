import { Text } from '@mantine/core';
import React from 'react';

import { DeviceType, usePortalConfig } from '@portals/api/organizations';
import { DeviceDetailsType } from '@portals/api/organizations';
import { DeviceType as CommonDeviceType } from '@portals/types';

interface DeviceCecPartnerLogoOrNameProps {
  device: DeviceType | DeviceDetailsType | CommonDeviceType;
}
export function DeviceCecPartnerLogoOrName({
  device,
}: DeviceCecPartnerLogoOrNameProps) {
  const portalConfig = usePortalConfig();

  if (!portalConfig.data?.cec_enabled) {
    return null;
  }

  return device.partner.logo ? (
    <img
      src={device.partner.logo}
      alt={device.partner.vendor}
      width={74}
      height="auto"
    />
  ) : (
    <Text size="xl">{device.partner.vendor}</Text>
  );
}
