import { Box } from '@mantine/core';
import React from 'react';

import { ReactComponent as ArrowDown } from '@portals/icons/linear/arrow-down.svg';

const RowExpandToggle = ({ expanded }) => (
  <Box
    style={{ width: '40px' }}
    data-testid={
      expanded ? 'table-row-collapse-button' : 'table-row-expand-button'
    }
    sx={{
      svg: {
        transition: 'transform 0.15s ease-in-out',
        transformOrigin: 'center',
        ...(expanded
          ? {
              transform: 'rotate(180deg)',
            }
          : {}),
      },
    }}
  >
    <ArrowDown width={15} height={15} />
  </Box>
);

export default RowExpandToggle;
