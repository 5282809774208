import { Checkbox } from '@mantine/core';
import React from 'react';

import { useTableInstance } from '../../context/TableInstance';

interface ColumnOptionProps {
  option: any;
}

const ColumnOption = ({ option }: ColumnOptionProps) => {
  const { toggleHidden, isAction } = option;
  const { checked } = option.getToggleHiddenProps();

  const { columns } = useTableInstance();

  if (isAction) return null;

  const visibleColumnsCount = columns.filter(
    (column) => column?.isVisible
  )?.length;

  return (
    <Checkbox
      data-testid={`column-option-${option.id}`}
      disabled={checked && visibleColumnsCount === 1}
      checked={checked}
      onChange={() => toggleHidden(checked)}
      label={option.Header}
      styles={{
        inner: {
          cursor: 'pointer',
        },
        input: {
          cursor: 'pointer',
        },
        label: {
          width: '100%',
          cursor: 'pointer',
        },
      }}
    />
  );
};

export default ColumnOption;
