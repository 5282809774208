import { Text } from '@mantine/core';
import React from 'react';

import { WidgetCardWrapper } from './widgets/common/WidgetCardWrapper';

interface UnknownWidgetProps {
  itemId: string;
}

export function UnknownWidget({ itemId }: UnknownWidgetProps) {
  return (
    <WidgetCardWrapper
      paperProps={{
        sx: {
          height: '100%',
        },
      }}
      header={
        <Text
          sx={(theme) => ({ color: theme.colors.blue_gray[6] })}
          size="md"
          color="gray.5"
          weight={300}
        >
          Unknown widget
        </Text>
      }
    >
      <Text color="red">{itemId}</Text>
    </WidgetCardWrapper>
  );
}
