import React, { lazy, Suspense } from 'react';

import Loader from '../Loader';
import { JsonEditorLazyProps } from './JsonEditorLazy';

const WrappedJsonEditor = lazy(() => import('./JsonEditorLazy'));

const WrapperJsonEditor = (props: JsonEditorLazyProps) => (
  <Suspense fallback={<Loader />}>
    <WrappedJsonEditor {...props} />
  </Suspense>
);

export default WrapperJsonEditor;
