import React from 'react';

import {
  DashboardContentLayout,
  RoutedTabs,
  RoutedTabsProps,
} from '@portals/framework';

import {
  ACTIVE_SUBSCRIPTIONS_COLUMNS,
  CANCELED_SUBSCRIPTIONS_COLUMNS,
  SubscriptionsTable,
  SUSPENDED_SUBSCRIPTIONS_COLUMNS,
} from './SubscriptionsTable';

const ROUTES: RoutedTabsProps['routes'] = [
  {
    path: 'active',
    tabLabel: 'Active',
    element: (
      <SubscriptionsTable
        statuses={['active', 'pending_cancellation']}
        columns={ACTIVE_SUBSCRIPTIONS_COLUMNS}
      />
    ),
  },
  {
    path: 'suspended',
    tabLabel: 'Suspended',
    element: (
      <SubscriptionsTable
        statuses={['suspended']}
        columns={SUSPENDED_SUBSCRIPTIONS_COLUMNS}
      />
    ),
  },
  {
    path: 'canceled',
    tabLabel: 'Canceled',
    element: (
      <SubscriptionsTable
        statuses={['canceled']}
        columns={CANCELED_SUBSCRIPTIONS_COLUMNS}
      />
    ),
  },
];

export function Subscriptions() {
  return (
    <DashboardContentLayout
      pageTitle="Subscriptions"
      bg="gray.0"
      breadcrumbs={[
        { label: 'Products', to: '/products' },
        { label: 'Subscriptions' },
      ]}
    >
      <RoutedTabs routes={ROUTES} basePath="/products/subscriptions" />
    </DashboardContentLayout>
  );
}
