import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import {
  SkipToStepFn,
  WizardContextType,
  WizardProviderProps,
  WizardTransitionType,
} from './types';

export const WizardContext = createContext<WizardContextType | null>(null);

export function WizardProvider<
  ContextData = unknown,
  StepIds extends string = string
>({
  children,
  contextData,
  setContextData,
  steps,
  initialStep,
  onTransition = () => Promise.resolve(true),
}: WizardProviderProps<ContextData, StepIds>) {
  const [isInTransition, setIsInTransition] = useState(false);
  const [currentStep, setCurrentStep] = useState(steps[initialStep]);

  const isNextDisabled = useMemo(() => {
    return (
      currentStep[WizardTransitionType.Next]?.isDisabled?.({
        steps,
        contextData,
        currentStepId: currentStep.id,
      }) || isInTransition
    );
  }, [contextData, currentStep, isInTransition, steps]);

  const isPrevDisabled = useMemo(() => {
    return (
      currentStep[WizardTransitionType.Prev]?.isDisabled?.({
        steps,
        contextData,
        currentStepId: currentStep.id,
      }) || isInTransition
    );
  }, [contextData, currentStep, isInTransition, steps]);

  const isFinalStep = useMemo(() => {
    if (typeof currentStep.isFinalStep === 'function') {
      return currentStep.isFinalStep({ contextData });
    }

    return currentStep.isFinalStep;
  }, [contextData, currentStep]);

  const transitionToStep = useCallback(
    (transitionType: WizardTransitionType.Next | WizardTransitionType.Prev) => {
      const currentStepId = currentStep.id;
      const stepToTransition = currentStep[transitionType];

      if (!stepToTransition) {
        console.warn(`'${currentStepId}' step has no '${transitionType}' step`);
        return;
      }

      const { transition, isDisabled } = stepToTransition;

      if (!transition || isDisabled?.({ steps, currentStepId, contextData })) {
        return;
      }

      let transitionToId: StepIds;

      if (!Array.isArray(transition)) {
        transitionToId = transition.id;
      } else {
        const gotoStep = transition.find(({ condition }) =>
          condition({ steps, currentStepId, contextData })
        );

        if (!gotoStep) return;

        transitionToId = gotoStep.id;
      }

      const onTransitionHandler = currentStep.onTransition ?? onTransition;

      setIsInTransition(true);

      onTransitionHandler({
        contextData,
        setContextData,
        from: currentStepId,
        to: transitionToId,
        transitionType,
      })
        .then((result) => {
          if (result) {
            setIsInTransition(false);
            setCurrentStep(steps[transitionToId]);
          }
        })
        .finally(() => setIsInTransition(false));
    },
    [contextData, currentStep, onTransition, setContextData, steps]
  );

  const onPrev = useCallback(() => {
    transitionToStep(WizardTransitionType.Prev);
  }, [transitionToStep]);

  const onNext = useCallback(() => {
    if (isFinalStep) {
      setIsInTransition(true);

      const onTransitionHandler = currentStep.onTransition ?? onTransition;

      onTransitionHandler({
        contextData,
        setContextData,
        from: currentStep.id,
        transitionType: WizardTransitionType.Finish,
      }).finally(() => setIsInTransition(false));
    } else {
      transitionToStep(WizardTransitionType.Next);
    }
  }, [
    contextData,
    currentStep.id,
    currentStep.onTransition,
    isFinalStep,
    onTransition,
    setContextData,
    transitionToStep,
  ]);

  const skipToStep = useCallback<SkipToStepFn<StepIds>>(
    async (
      stepId,
      { runTransitionHandler, forceSkip } = {
        runTransitionHandler: true,
        forceSkip: false,
      }
    ) => {
      const onTransitionHandler = currentStep.onTransition ?? onTransition;

      if (!runTransitionHandler) {
        setCurrentStep(steps[stepId]);
        return;
      }

      setIsInTransition(true);

      onTransitionHandler({
        contextData,
        setContextData,
        from: currentStep.id,
        to: stepId,
        transitionType: WizardTransitionType.Skip,
      })
        .then((result) => {
          if (result || forceSkip) {
            setIsInTransition(false);
            setCurrentStep(steps[stepId]);
          }
        })
        .finally(() => setIsInTransition(false));
    },
    [
      contextData,
      currentStep.id,
      currentStep.onTransition,
      onTransition,
      setContextData,
      steps,
    ]
  );

  const setStep = useCallback(
    (stepId: StepIds) => {
      const step = steps[stepId];

      if (!step) return;

      setCurrentStep(step);
    },
    [steps]
  );

  return (
    <WizardContext.Provider
      value={
        {
          steps,
          currentStep,
          onNext,
          onPrev,
          setStep,
          isNextDisabled,
          isPrevDisabled,
          isFinalStep,
          isInTransition,
          contextData,
          setContextData,
          skipToStep,
        } as any
        // `as any` because there's no easy way to make the provider accept the generic types
      }
    >
      {children}
    </WizardContext.Provider>
  );
}

export function useWizard<
  ContextData = unknown,
  StepsIds extends string = string
>() {
  const context = useContext(WizardContext) as unknown as WizardContextType<
    ContextData,
    StepsIds
  >;

  if (context === null) {
    throw new Error('useWizard must be used inside a WizardProvider');
  }

  return context;
}
