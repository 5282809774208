import { Button, Group, LoadingOverlay, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useUpdateDevice } from '@portals/api/organizations';
import { ModalCenteredMediaLayout } from '@portals/core';
import { ModalProps } from '@portals/framework';
import { toastrSuccess } from '@portals/redux/actions/toastr';
import { DeviceType } from '@portals/types';

import roomSrc from '../../assets/img/room.svg';

export interface SetDeviceNameProps
  extends ModalProps<{ device: DeviceType }> {}

export function SetDeviceName({
  closeMe,
  data: { device },
}: SetDeviceNameProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const updateDevice = useUpdateDevice();

  const form = useForm<{ name: string }>({
    initialValues: {
      name: '',
    },
  });

  const onSubmit = (values: typeof form.values) => {
    updateDevice.mutate(
      {
        deviceId: device.id,
        spaceId: device.space_id,
        updatedDevice: {
          name: values.name,
        },
      },
      {
        onSuccess: () => {
          closeMe();

          dispatch(toastrSuccess('Device claimed'));
          navigate(`/overview/${device.space_id}`);
        },
      }
    );
  };

  return (
    <ModalCenteredMediaLayout
      opened
      title="Name your device"
      onClose={closeMe}
      media={<img src={roomSrc} />}
    >
      <LoadingOverlay visible={updateDevice.isLoading} />

      <form onSubmit={form.onSubmit(onSubmit)}>
        <Stack>
          <Group grow mb="md">
            <TextInput
              data-autofocus
              placeholder="Device Name"
              required
              {...form.getInputProps('name')}
            />
          </Group>

          <Group grow>
            <Button
              type="submit"
              disabled={!form.isValid || !form.values.name}
              loading={updateDevice.isLoading}
            >
              Submit
            </Button>
          </Group>
        </Stack>
      </form>
    </ModalCenteredMediaLayout>
  );
}
