import { Button, Stack, Title } from '@mantine/core';
import React from 'react';
import { Link } from 'react-router-dom';

export function Page500() {
  return (
    <Stack>
      <Title size="7.25rem">500</Title>

      <Title>Internal server error.</Title>

      <Title order={2} weight={400}>
        The server encountered something unexpected that didn't allow it to
        complete the request.
      </Title>

      <Link to="/">
        <Button color="dark" size="xl" pr={50} pl={50}>
          Return to main page
        </Button>
      </Link>
    </Stack>
  );
}
