import { Box, createStyles, Divider, LoadingOverlay } from '@mantine/core';
import { isEmpty, map } from 'lodash/fp';
import React, { FC, useMemo } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';

import { useDeviceWidgets } from '@portals/api/organizations';

import { WidgetWrapper } from '../../../../../components/DeviceWidgets/WidgetWrapper';
import { useDeviceStateContext } from '../../../DeviceStateProvider';

const ResponsiveGridLayout = WidthProvider(Responsive);

interface OverviewWidgetsProps {
  modelId: string;
  deviceId: string;
}

export const OverviewWidgets: FC<OverviewWidgetsProps> = ({
  modelId,
  deviceId,
}) => {
  const { classes } = useStyles();
  const deviceStates = useDeviceStateContext();
  const { data: widgets, isInitialLoading } = useDeviceWidgets(modelId);

  const layout = useMemo(
    () =>
      map(
        ({ id, config }) => ({
          x: 0,
          y: 0,
          h: 1,
          w: config.width,
          // `config.layout` holds the x & y coordinates of the widget
          ...config.layout,
          i: id,
          config,
          id,
        }),
        widgets
      ),
    [widgets]
  );

  const widgetsList = useMemo(
    () =>
      map(
        (widget) => (
          <div key={widget.id}>
            <WidgetWrapper
              widget={widget}
              deviceStates={deviceStates}
              deviceId={deviceId}
            />
          </div>
        ),
        widgets
      ),
    [widgets, deviceStates, deviceId]
  );

  if (!isInitialLoading && isEmpty(widgets)) {
    return null;
  }

  if (isInitialLoading)
    return (
      <>
        <Divider className={classes.divider} />

        <Box
          sx={{
            position: 'relative',
          }}
        >
          <LoadingOverlay visible />
        </Box>
      </>
    );

  return (
    <>
      <Divider className={classes.divider} />

      <Box>
        <ResponsiveGridLayout
          breakpoint="lg"
          cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
          layouts={{ lg: layout }}
          rowHeight={300}
          containerPadding={[0, 10]}
          className="layout"
          isResizable={false}
          isDraggable={false}
          measureBeforeMount
          useCSSTransforms={false}
        >
          {widgetsList}
        </ResponsiveGridLayout>
      </Box>
    </>
  );
};

const useStyles = createStyles((theme) => ({
  divider: {
    borderColor: theme.colors.gray[2],
    margin: `${theme.spacing.xl} 0 14px`,
  },
}));
