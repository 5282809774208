// Convert various server errors to string
export const errorsToString = (errors) => {
  if (typeof errors === 'string') {
    return errors;
  }

  if (Array.isArray(errors)) {
    return errors[0];
  }

  if (typeof errors === 'object') {
    return Object.keys(errors)
      .map((field) => `${field}: ${errors[field]} `)
      .join(',');
  }

  return null;
};
