import { find, map } from 'lodash/fp';
import React from 'react';

import {
  useActiveDeviceCommands,
  useDevice,
  useDeviceModels,
} from '@portals/api/organizations';
import {
  GroupedSwitchesWidget,
  GroupedSwitchesWidgetFormType,
} from '@portals/device-widgets';
import { useSendCommandWithApproval } from '@portals/framework';
import { PremiumCommandLicenseModalProps } from '@portals/framework/modals';
import { useOpenModal } from '@portals/redux';

import { WidgetProps } from '../device-widgets.types';

export function GroupedSwitchesWidgetWrapper({
  widget,
  deviceState,
  deviceId,

  isDeviceOffline,
}: WidgetProps<GroupedSwitchesWidgetFormType>) {
  const fields = widget?.config?.fields as GroupedSwitchesWidgetFormType;
  const models = useDeviceModels();

  const device = useDevice(deviceId);
  const model = find({ id: device.data.partner?.type_id }, models.data);

  const sendCommandWithApproval = useSendCommandWithApproval();
  const activeCommands = useActiveDeviceCommands(deviceId);

  const openModal = useOpenModal();

  const { display_title } = fields ?? {};

  const onToggle = async (
    commandName: string,
    commandParam: string,
    isChecked: boolean
  ) => {
    const command = find({ name: commandName }, model?.supported_commands);

    if (command?.custom_fields?.length) {
      openModal('RunCommand', {
        deviceType: device.data?.partner?.type_id,
        command,
        deviceIds: [deviceId],
        prepopulatedParams: {
          [commandParam]: isChecked,
        },
      });

      return;
    }

    try {
      await sendCommandWithApproval({
        deviceIds: [deviceId],
        command,
        extra_params: {
          [commandParam]: isChecked,
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  const onPremiumCommandClick = (commandId: string, commandName: string) => {
    openModal<PremiumCommandLicenseModalProps['data']>(
      'PremiumCommandLicenseModal',
      { deviceId, commandId, commandName }
    );
  };

  const switches = map(
    (field) => ({
      key: field.key,
      label: field.label,
      commandName: field.command_name,
      commandParamKey: field.command_param_key,
      iconName: field.icon_name,
      telemetry: field.telemetry,
      displayTitle: display_title,
      color: fields.color,
      disabled: isDeviceOffline,
    }),
    fields.commands
  );

  return (
    <GroupedSwitchesWidget
      switches={switches}
      onToggle={onToggle}
      activeCommands={activeCommands.data || []}
      deviceState={deviceState || {}}
      supportedCommands={device.data?.supported_commands}
      deviceId={deviceId}
      onPremiumCommandClick={onPremiumCommandClick}
    />
  );
}
