export const CONFIGURATION_API_URL = 'ui/organization/configuration';
export const CONTACTS_API_URL = `${CONFIGURATION_API_URL}/contacts`;
export const FEATURE_FLAGS_API_URL = `${CONFIGURATION_API_URL}/feature_flags`;
export const SPACE_DASHBOARD_API_URL = `${CONFIGURATION_API_URL}/space_dashboard`;

export const configurationQueryKeys = {
  config: [CONFIGURATION_API_URL, 'config'],
  contacts: {
    all: [CONFIGURATION_API_URL, 'contacts'],
  },
  space_dashboard: {
    all: [CONFIGURATION_API_URL, 'space_dashboard'],
  },
};
