import React, { FC, ReactNode, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, ListGroupItem, Row } from 'reactstrap';
import styled, { css } from 'styled-components';

type TableProps = {
  icon: ReactNode;
  title: string;
  message: string;
  link?: string;
  spacing?: boolean;
};
const NavbarDropdownItem: FC<TableProps> = ({
  icon,
  title,
  message,
  link,
  spacing,
}) => {
  const navigate = useNavigate();

  const navigateToRoute = useCallback(() => {
    if (link) {
      navigate(link);
    }
  }, [link, navigate]);

  return (
    <StyledListGroupItem $link={link}>
      <Row noGutters className="align-items-center">
        <Col xs={2}>{icon}</Col>
        <Col xs={10} className={spacing ? 'pl-2' : null}>
          <div className="text-dark">{title}</div>
          <div className="text-muted small mt-1" onClick={navigateToRoute}>
            {message}
          </div>
        </Col>
      </Row>
    </StyledListGroupItem>
  );
};

const StyledListGroupItem = styled(ListGroupItem)<{ $link: string }>`
  ${({ $link }) =>
    $link &&
    css`
      cursor: pointer;
    `}
`;

export default NavbarDropdownItem;
