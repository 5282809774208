import { Accordion, createStyles, Menu, Text } from '@mantine/core';
import React from 'react';

import { SuperClusterPoint } from '../../types/maps';

interface PointsAccordionProps {
  categorizedPoints: Record<string, Array<SuperClusterPoint>>;
}

export function PointsAccordion({ categorizedPoints }: PointsAccordionProps) {
  const { classes } = useStyles();

  const normalizedPoints = Object.entries(categorizedPoints).map(
    ([categoryName, pointsArr]) => {
      return (
        <Accordion.Item key={categoryName} value={categoryName} p={0}>
          <Menu.Label fz="sm" p={0}>
            <Accordion.Control px={0} py={10}>
              <Text span c="gray.8" p={0}>
                {categoryName}
              </Text>{' '}
              <Text span c="gray.5" p={0}>
                ({pointsArr.length})
              </Text>
            </Accordion.Control>
          </Menu.Label>

          <Accordion.Panel p={0}>
            {pointsArr.map(({ properties }) => {
              return properties?.renderClusterItem();
            })}
          </Accordion.Panel>
        </Accordion.Item>
      );
    }
  );

  return (
    <Accordion
      multiple
      p={0}
      m={0}
      classNames={classes}
      defaultValue={Object.keys(categorizedPoints)}
    >
      {normalizedPoints}
    </Accordion>
  );
}

const useStyles = createStyles(() => ({
  content: {
    padding: 0,
    margin: 0,
  },
  item: {
    '&[data-active]': {
      border: 'none',
    },
  },
  chevron: {
    width: 'auto',
    minWidth: 'auto',
    '&[data-rotate]': {
      transform: 'rotate(-90deg)',
    },
  },
}));
