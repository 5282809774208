import { createConsumer } from '@rails/actioncable';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { noop } from 'lodash/fp';
import { useCallback, useEffect } from 'react';

import { useAuth } from '@portals/redux';
import { Channel } from '@portals/types';
import { WS_URL } from '@portals/utils';

type MessageHandler = (oldData: any, newData: any) => any;

interface ChannelHookParams {
  channelName: Channel;
  subscriptionParams: any;
  messageHandler: MessageHandler;
}

let consumer: ReturnType<typeof createConsumer> | null;

function useConnection() {
  const auth = useAuth();

  const disconnect = useCallback(() => {
    if (!consumer) {
      return;
    }

    consumer.connection.close();

    // stopping, otherwise the monitor reopens a closed connection
    consumer.connection.monitor.stop();

    consumer = null;
  }, []);

  if (consumer) {
    return { connection: consumer, disconnect };
  }

  if (!auth || !auth.tenant) {
    return { disconnect };
  }

  const url = new URL(WS_URL);
  url.searchParams.set('access-token', auth.token);
  url.searchParams.set('client', auth.client);
  url.searchParams.set('user_id', auth.id);
  url.searchParams.set('tenant-id', auth.tenant.id);
  url.searchParams.set('tenant-type', auth.tenant.type);

  consumer = createConsumer(url.toString());

  return { connection: consumer, disconnect };
}

function useChannel({
  channelName,
  subscriptionParams,
  messageHandler,
}: ChannelHookParams) {
  const queryClient = useQueryClient();
  const { connection } = useConnection();

  useEffect(() => {
    const subscription = connection?.subscriptions.create(
      { channel: channelName, ...subscriptionParams },
      {
        received(newData) {
          // Save in react-query's cache
          queryClient.setQueryData(
            [channelName, subscriptionParams],
            (oldData) => messageHandler(oldData, newData)
          );
        },
      }
    );

    return () => {
      subscription?.unsubscribe();
      queryClient.resetQueries([channelName, subscriptionParams]);
    };
  }, [
    channelName,
    connection?.subscriptions,
    messageHandler,
    queryClient,
    subscriptionParams,
  ]);

  // Return the data from cache
  return useQuery([channelName, subscriptionParams], {
    // Always disable, so that `queryFn` won't be called since we're updating the cache manually
    // on websocket's callback
    enabled: false,
    queryFn: noop,
  });
}

function useDeviceCommandsChannel(args: {
  subscriptionParams: { device_id: string };
  messageHandler: MessageHandler;
}) {
  return useChannel({ ...args, channelName: Channel.DeviceCommandsChannel });
}

function useOrgIncidentsChannel(args: {
  subscriptionParams: { org_id: string };
  messageHandler: MessageHandler;
}) {
  return useChannel({ ...args, channelName: Channel.OrgIncidents });
}

function useOrgDeviceStateChannel(args: {
  subscriptionParams: { org_id: string; device_id: string };
  messageHandler: MessageHandler;
}) {
  return useChannel({ ...args, channelName: Channel.OrgDeviceState });
}

export {
  useConnection,
  useOrgIncidentsChannel,
  useOrgDeviceStateChannel,
  useDeviceCommandsChannel,
};
