import BooleanFilter from './BooleanFilter';
import NumberFilter from './NumberFilter';
import SelectFilter from './SelectFilter';
import SingleSelectFilter from './SingleSelectFilter';
import TextFilter from './TextFilter';

export default {
  SelectFilter,
  SingleSelectFilter,
  BooleanFilter,
  NumberFilter,
  TextFilter,
};
