import React from 'react';

import { MapFeature, SuperClusterType } from '../../types/maps';
import { Cluster } from './Cluster';

interface MarkerProps {
  key: string;
  item: MapFeature;
  superCluster?: SuperClusterType;
  zoomToCluster: (cluster: MapFeature) => void;
  zoom: number;
  lat: number;
  lng: number;
  onListScroll: (mapGestureHandling: 'none' | 'greedy') => void;
}

export function Marker({
  key,
  item,
  superCluster,
  zoomToCluster,
  zoom,
  lat,
  lng,
  onListScroll,
}: MarkerProps) {
  return item.properties?.cluster ? (
    <Cluster
      key={key}
      item={item}
      superCluster={superCluster}
      zoomToCluster={zoomToCluster}
      zoom={zoom}
      lat={lat}
      lng={lng}
      onListScroll={onListScroll}
    />
  ) : (
    item.properties?.renderSinglePoint()
  );
}
