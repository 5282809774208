import { Affix, Notification, useMantineTheme } from '@mantine/core';
import { useNetwork } from '@mantine/hooks';
import React, { useEffect, useState } from 'react';

import { ReactComponent as Wifi } from '@portals/icons/linear/wifi.svg';

export function NetworkStatusNotification() {
  const [isActive, setIsActive] = useState(false);

  const theme = useMantineTheme();
  const network = useNetwork();

  useEffect(() => {
    if (!network.online) setIsActive(true);
  }, [network.online]);

  if (network.online || !isActive) return null;

  return (
    <Affix position={{ top: 20, right: 20 }}>
      <Notification
        id="network-notification"
        title="You're currently offline"
        onClose={() => setIsActive(false)}
        icon={<Wifi color={theme.fn.themeColor('red')} />}
      >
        Your internet connection is having an issue reaching our server
      </Notification>
    </Affix>
  );
}
