import React, { FC } from 'react';
import styled from 'styled-components';

import { WidgetProps } from './types';

const PieChartWidget: FC<WidgetProps> = () => {
  return <Container>Pie Widget</Container>;
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default PieChartWidget;
