import { createStyles, Stack, Text, useMantineTheme } from '@mantine/core';
import React from 'react';

interface TreemapContentProps {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  depth?: number;
  index?: number;
}

export function TreemapContent({
  x,
  y,
  depth,
  width,
  index,
  height,
}: TreemapContentProps) {
  const theme = useMantineTheme();

  const COLORS = [
    '#536DFE',
    '#82B1FF',
    '#0D47A1',
    '#00BCD4',
    '#B2DFDB',
    '#00897B',
    '#4DB6AC',
    '#006D7D',
    '#FFBFD5',
    '#FF4081',
    '#C51162',
    '#EF5350',
    '#FF6D00',
    '#F9A825',
    '#FFCC80',
    '#8E24AA',
    '#BDBDBD',
    '#EEEEEE',
  ];

  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        style={{
          fill: depth < 2 ? COLORS[Math.floor(index / depth)] : '#ffffff00',
          stroke: theme.white,
          strokeWidth: 6 * Math.pow(0.6, depth),
          strokeOpacity: 1 / (depth + 1e-10),
        }}
      />
    </g>
  );
}

interface TreemapTooltipProps {
  payload?: any[];
}

export function TreemapTooltip({ payload }: TreemapTooltipProps) {
  const { classes } = useTooltipStyles();

  return (
    <Stack className={classes.container} spacing={0}>
      <Text weight={600} align="center">
        {payload[0]?.payload.name}
      </Text>
      <Text align="center">
        {payload[0]?.value} {payload[0]?.value === 1 ? 'device' : 'devices'}
      </Text>
    </Stack>
  );
}

const useTooltipStyles = createStyles((theme) => ({
  container: {
    backgroundColor: theme.colors.blue_gray[9],
    color: theme.white,
    paddingInline: theme.spacing.sm,
    paddingBlock: theme.spacing.xs,
    borderRadius: theme.radius.xs,
  },
}));
