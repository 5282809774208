const AFTER_AUTH = 'afterAuth';

export const AfterAuthRedirectManager = {
  getPathname: () => {
    return localStorage.getItem(AFTER_AUTH) || '/';
  },
  setPathname: (pathname: string) => {
    localStorage.setItem(AFTER_AUTH, pathname);
  },
  removePathname: () => {
    localStorage.removeItem(AFTER_AUTH);
  },
} as const;
