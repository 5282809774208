import { Badge } from '@mantine/core';
import React from 'react';

import {
  ORDERS_API_URL,
  OrderStatusType,
  OrderSummaryType,
  useFeatureFlags,
  usePaginatedOrders,
} from '@portals/api/organizations';
import {
  OrderItemsPreviewTooltip,
  OrderItemsPreviewTooltipProps,
} from '@portals/framework';
import {
  OrganizationOrderFulfillmentStatusBadge,
  OrganizationOrderPaymentStatusBadge,
  RouteModalLink,
} from '@portals/framework/route-modals';
import { DateCell, PaginatedTable } from '@portals/table';
import {
  PaginatedFilterTypeEnum,
  TableColumn,
  TableFilterTypeEnum,
} from '@portals/types';
import {
  formatCurrency,
  generateExportFilename,
  getOrderPaymentMethodLabel,
} from '@portals/utils';

import { ActiveOrdersRowMenu } from './ActiveOrdersRowMenu';

const COLUMNS: Array<TableColumn<OrderSummaryType>> = [
  {
    dataField: 'short_id',
    text: 'Order',
    minWidth: 200,
    sort: true,
    filter: {
      type: PaginatedFilterTypeEnum.Text,
    },
    isSticky: true,
    formatter: (_, { short_id, id }) => (
      <RouteModalLink modalId="order" pathParams={[id]}>
        {short_id}
      </RouteModalLink>
    ),
  },
  {
    dataField: 'items_count',
    text: 'Items',
    sort: true,
    filter: {
      type: TableFilterTypeEnum.Number,
    },
    minWidth: 200,
    formatter: (_, { items, items_count }) => {
      if (!items_count) return null;

      const itemsList: OrderItemsPreviewTooltipProps['items'] = items.map(
        (orderItem) => ({
          name: orderItem.name,
          quantity: orderItem.quantity,
          imageUrl: orderItem.image_url,
        })
      );

      return (
        <OrderItemsPreviewTooltip items={itemsList}>
          <Badge fw={400}>
            {items_count === 1 ? '1 item' : `${items_count} items`}
          </Badge>
        </OrderItemsPreviewTooltip>
      );
    },
  },
  {
    dataField: 'amount_in_scu',
    text: 'Charged Amount',
    sort: true,
    minWidth: 250,
    maxWidth: 250,
    formatter: (_, { amount_in_scu, tax_in_scu, currency }) =>
      `${formatCurrency(amount_in_scu, currency)}${
        tax_in_scu !== 0
          ? ` + Tax: ${formatCurrency(tax_in_scu, currency)}`
          : ''
      }`,
  },
  {
    dataField: 'payment_method',
    text: 'Payment Method',
    filter: {
      type: TableFilterTypeEnum.Select,
      options: {
        credit_card: 'Credit Card',
        purchase_order: 'Purchase Order',
        lab: 'Lab',
      },
    },
    minWidth: 300,
    maxWidth: 300,
    sort: true,
    formatter: (_, { payment_method }) =>
      getOrderPaymentMethodLabel(payment_method),
  },
  {
    dataField: 'created_at',
    text: 'Date Placed',
    sort: true,
    filter: { type: TableFilterTypeEnum.Date },
    minWidth: 250,
    maxWidth: 250,
    formatter: (cell, { created_at }) => (
      <DateCell date={created_at} withTimeAgo={false} />
    ),
  },
  {
    dataField: 'payment_status',
    text: 'Payment Status',
    sort: true,
    minWidth: 300,
    maxWidth: 300,
    filter: {
      type: TableFilterTypeEnum.Select,
      options: {
        awaiting_po_upload: 'Requires PO',
        awaiting_po_approval: 'Pending seller approval',
        pending: 'Payment in process',
        paid: 'Paid',
        failed: 'Payment failed',
        expired: 'Expired',
        archived: 'Archived',
        refunded: 'Payment refunded',
      },
    },
    formatter: (_, order) => (
      <OrganizationOrderPaymentStatusBadge order={order} />
    ),
  },
  {
    dataField: 'fulfillment_status',
    text: 'Fulfillment Status',
    sort: true,
    filter: {
      type: TableFilterTypeEnum.Select,
      options: {
        missing_information: 'Missing information',
        order_placed: 'Order placed',
        processing: 'Processing',
        ready: 'Shipment ready',
        shipped: 'Shipped',
        available: 'Available',
        delivered: 'Delivered',
      },
    },
    minWidth: 300,
    maxWidth: 300,
    formatter: (_, order) =>
      getShouldDisplayFulfillmentStatus(order.payment_status) ? (
        <OrganizationOrderFulfillmentStatusBadge order={order} />
      ) : null,
  },
];

interface OrdersTableProps {
  status: OrderStatusType;
}

export function OrdersTable({ status }: OrdersTableProps) {
  const featureFlags = useFeatureFlags();

  return (
    <PaginatedTable<OrderSummaryType>
      noHeader={!featureFlags?.orders_csv_export}
      columns={COLUMNS}
      isUrlSyncEnabled={false}
      dataHook={usePaginatedOrders}
      dataHookUrl={buildDataHookUrl(status)}
      noDataIndication={{ title: 'No orders' }}
      defaultSortBy={[{ id: 'created_at', desc: true }]}
      name="organizations.orders"
      keyField="id"
      rowMenu={({ row, wrapperProps }) => (
        <ActiveOrdersRowMenu order={row.original} wrapperProps={wrapperProps} />
      )}
      exportParams={{
        isEnabled: !!featureFlags?.orders_csv_export,
        remoteUrl: `${ORDERS_API_URL}.csv`,
        fileName: generateExportFilename('orders'),
      }}
    />
  );
}

function getShouldDisplayFulfillmentStatus(
  paymentStatus: OrderSummaryType['payment_status']
) {
  return !['failed', 'archived', 'expired'].includes(paymentStatus);
}

function buildDataHookUrl(status: OrderStatusType) {
  switch (status) {
    case 'pending':
      return `${ORDERS_API_URL}?status=pending`;

    case 'in_process':
      return `${ORDERS_API_URL}?status=in_process`;

    case 'fulfilled':
      return `${ORDERS_API_URL}?status=fulfilled`;

    case 'all':
    default:
      return ORDERS_API_URL;
  }
}
