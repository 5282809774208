import { createStyles, SimpleGrid, Stack, StackProps } from '@mantine/core';
import { min } from 'lodash/fp';
import React, { useCallback } from 'react';

import { SupportedCommandType } from '@portals/types';

import { GroupedSwitchesWidgetContextProvider } from './grouped-switches-widget.context';
import { GroupedSwitch, GroupedSwitchProps } from './GroupedSwitch';
import { ActiveCommandType } from '../../widgets.types';

export const DEVICE_MAX_GROUPED_SWITCHES_COUNT = 4;

export interface GroupedSwitchesWidgetProps {
  switches: Array<GroupedSwitchProps>;

  deviceState: Record<string, any>;
  activeCommands: Array<ActiveCommandType>;
  onToggle: (
    commandName: string,
    commandParamKey: string,
    isChecked: boolean
  ) => void;

  stackProps?: StackProps;
  supportedCommands?: Array<SupportedCommandType>;
  deviceId?: string;
  onPremiumCommandClick?: (commandId: string, commandName: string) => void;
}

export function GroupedSwitchesWidget({
  switches,

  deviceState,
  activeCommands,
  onToggle,
  stackProps = {},
  supportedCommands,
  deviceId,
  onPremiumCommandClick,
}: GroupedSwitchesWidgetProps) {
  const { classes, theme } = useStyles();

  const getIsPremiumCommand = useCallback(
    (commandName: string) =>
      supportedCommands?.some(
        (command: SupportedCommandType) =>
          command.name === commandName &&
          command.premium &&
          !command.has_license
      ),
    [supportedCommands]
  );

  const getCommandId = useCallback(
    (commandName: string) =>
      supportedCommands?.find((command) => command.name === commandName)?.id,
    [supportedCommands]
  );

  return (
    <GroupedSwitchesWidgetContextProvider
      onToggle={onToggle}
      activeCommands={activeCommands}
      deviceState={deviceState}
    >
      <Stack
        className={classes.container}
        p={2}
        spacing="lg"
        pos="relative"
        h="100%"
        w="100%"
        bg="white"
        justify="center"
        miw={deviceId ? 'fit-content' : undefined}
        {...stackProps}
      >
        <SimpleGrid
          cols={min([switches.length, DEVICE_MAX_GROUPED_SWITCHES_COUNT])}
          w="max-content"
          h="100%"
          className={classes.switchesWrapper}
          spacing={theme.spacing.sm}
          p="lg"
          m="auto"
        >
          {switches.map((command) => (
            <GroupedSwitch
              {...command}
              key={command.key}
              isPremiumCommand={getIsPremiumCommand(command.commandName)}
              deviceId={deviceId}
              commandId={getCommandId(command.commandName)}
              onPremiumCommandClick={onPremiumCommandClick}
            />
          ))}
        </SimpleGrid>
      </Stack>
    </GroupedSwitchesWidgetContextProvider>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    borderRadius: theme.radius.lg,
  },
  switchesWrapper: {
    overflow: 'auto',
    alignItems: 'center',
  },
}));
