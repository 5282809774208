import { Anchor, Text } from '@mantine/core';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { AuditLogType } from '@portals/types';

interface RemoveUserFromGroupProps {
  auditLog: AuditLogType;
}

export const RemoveUserFromGroup: FC<RemoveUserFromGroupProps> = ({
  auditLog,
}) => (
  <Text size="sm">
    Removed {auditLog?.params[0]?.model}:{' '}
    <Anchor size="sm" component={Link} to="/settings/users_and_groups/users">
      {auditLog?.params[0]?.display_name}
    </Anchor>{' '}
    from {auditLog?.params[1]?.model}: {auditLog?.params[1]?.display_name}
  </Text>
);
