import { LoadingOverlay } from '@mantine/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { useSignOut } from '@portals/api/auth';
import { useAuth } from '@portals/redux';

export function SignOut() {
  const auth = useAuth();
  const navigate = useNavigate();
  const signOut = useSignOut();

  useEffectOnce(() => {
    if (auth) {
      signOut.mutate(undefined, {
        onSettled: () => {
          navigate('/auth/sign-in', { replace: true });
        },
      });
    } else {
      navigate('/auth/sign-in', { replace: true });
    }
  });

  return <LoadingOverlay visible />;
}
