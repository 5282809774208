import { Group, Radio, Stack, Text } from '@mantine/core';
import { find, last } from 'lodash/fp';
import React, { useEffect, useMemo, useState } from 'react';

import { ReactComponent as InfoCircle } from '@portals/icons/linear/info-circle.svg';
import { OrganizationFileResponseType } from '@portals/types';

import FilesList from './FilesList';

const filterSupportedFiles = (
  files: OrganizationFileResponseType[],
  deviceType: string,
  fileType: string
) => {
  return files.filter(
    (file) => file.device_model === deviceType && file.type === fileType
  );
};

export const FileSelector = ({
  value,
  field,
  setFieldValue,
  files,
  file_type,
  deviceType,
  openModal,
}) => {
  const supportedFiles = useMemo(
    () => filterSupportedFiles(files, deviceType, file_type),
    [files, file_type, deviceType]
  );

  const officialFiles = useMemo(
    () =>
      supportedFiles
        .filter((file) => file.partner)
        .sort((a, b) => b.version?.localeCompare(a.version ?? '') || 0),
    [supportedFiles]
  );

  const userFiles = useMemo(
    () => supportedFiles.filter((file) => !file.partner),
    [supportedFiles]
  );

  const [filesType, setFilesType] = useState('official');
  const filesByType = filesType === 'official' ? officialFiles : userFiles;

  const openUploadFile = () =>
    openModal('FileInfoUploader', {
      deviceType,
      fileType: file_type,
      onSuccess: (id: string) => {
        if (filesType === 'custom') {
          setFieldValue(field.name, id);
        }
      },
    });

  useEffect(() => {
    if (officialFiles && officialFiles.length > 0 && !value) {
      setFieldValue(field.name, officialFiles[0].id);
    }
  }, [officialFiles, setFieldValue, value, filesType, field.name]);

  const setFilesTypeToOfficial = () => {
    setFilesType('official');

    setFieldValue(field.name, officialFiles[0]?.id || '');
  };

  const setFilesTypeToCustom = () => {
    setFilesType('custom');

    setFieldValue(field.name, last(userFiles)?.id || '');
  };

  const handleFileSelection = (fileId) => setFieldValue(field.name, fileId);

  const publicNotes = useMemo(() => {
    if (filesType !== 'official') return null;

    const file = find({ id: value }, supportedFiles);

    return file?.public_notes;
  }, [filesType, supportedFiles, value]);

  return (
    <Stack spacing="md">
      <Radio
        id="fileSource0"
        value={filesType}
        checked={filesType === 'official'}
        name="fileSource"
        label="Partner supplied"
        className="mb-1"
        onClick={setFilesTypeToOfficial}
        onChange={setFilesTypeToOfficial}
      />

      <Radio
        value={filesType}
        checked={filesType === 'custom'}
        id="fileSource1"
        name="fileSource"
        label="Custom file"
        className="mb-3"
        onClick={setFilesTypeToCustom}
        onChange={setFilesTypeToCustom}
      />

      {publicNotes ? (
        <Stack spacing="xs">
          <Text color="blue_gray">
            <Group align="center" spacing={5}>
              <InfoCircle width={14} height={14} />
              <Text inline size="xs" weight={600}>
                Manufacturer's note:
              </Text>
            </Group>
          </Text>

          <Text size="xs" color="blue_gray">
            {publicNotes}
          </Text>
        </Stack>
      ) : null}

      {filesByType && (
        <FilesList
          selected={value}
          files={filesByType}
          filesType={filesType}
          handleSelected={handleFileSelection}
          openUploadFile={openUploadFile}
        />
      )}
    </Stack>
  );
};
