import { useQuery } from '@tanstack/react-query';

import {
  getGoogleTimezoneApiUrl,
  googleTimeZoneQueryKeys,
} from './google-timezone.constants';
import { LocationType } from './google-timezone.types';
import { fetchApiRequest } from '../../utils/common';

export function useGoogleTimeZone(location: LocationType | null) {
  const timestamp = Math.round(Date.now() / 1000);

  return useQuery({
    enabled: Boolean(location),
    queryKey: googleTimeZoneQueryKeys.timezone(location),
    queryFn: () =>
      fetchApiRequest(getGoogleTimezoneApiUrl(location, timestamp)),
    meta: {
      baseUrl: 'https://maps.googleapis.com/maps/api/timezone/json',
      method: 'GET',
    },
  });
}
