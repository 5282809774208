import { Box, BoxProps } from '@mantine/core';
import React, { PropsWithChildren } from 'react';

interface ModalBodyProps extends BoxProps {}

export function ModalBody({
  children,
  ...boxProps
}: PropsWithChildren<ModalBodyProps>) {
  return (
    <Box mb="md" {...boxProps}>
      {children}
    </Box>
  );
}
