import { Badge, Box, Group, Paper, Stack, Text, Tooltip } from '@mantine/core';
import React, { useMemo } from 'react';

import {
  DeviceIncidentRateFormType,
  DeviceIncidentViewEnum,
  IncidentPriorityEnum,
  useDevicesBySpaceId,
  useSpace,
} from '@portals/api/organizations';

import { useDeviceIncidentRateWidgetCountAndPercentage } from './device-incident-rate-widget.hooks';
import { countIncidentsOnDeviceInSpace, getDevicesCount } from './utils';
import { DataLevelEnum } from '../../../overview.types';

export interface DeviceIncidentRateWidgetProps {
  title: string;
  incidentsPriorities: DeviceIncidentRateFormType['incidentPriorities'];
  spaceId: number | null;
  deviceIncidentView: DeviceIncidentViewEnum;
  dataLevel: DataLevelEnum;
}

export function DeviceIncidentRateWidget({
  title,
  incidentsPriorities,
  spaceId,
  deviceIncidentView,
  dataLevel,
}: DeviceIncidentRateWidgetProps) {
  const space = useSpace({ spaceId });
  const devices = useDevicesBySpaceId({ spaceId: spaceId });

  const devicesCount = useMemo(() => {
    return getDevicesCount(space, dataLevel);
  }, [space, dataLevel]);

  const devicesWithIncidentsCount = useMemo(() => {
    if (!incidentsPriorities || devicesCount === 0) {
      return 0;
    }

    return countIncidentsOnDeviceInSpace(
      incidentsPriorities,
      space?.id,
      devices.data,
      dataLevel
    );
  }, [devices.data, incidentsPriorities, devicesCount, dataLevel, space?.id]);

  const getPrioritiesLabel = () => {
    if (
      incidentsPriorities.length === Object.keys(IncidentPriorityEnum).length
    ) {
      return 'All Priorities';
    } else if (incidentsPriorities.length === 1) {
      return incidentsPriorities[0];
    }

    return `${incidentsPriorities.length} priorities`;
  };

  const {
    devicesWithoutIncidentsCount,
    percentageOfDevicesWithoutIncidents,
    percentageOfDevicesWithIncidents,
  } = useDeviceIncidentRateWidgetCountAndPercentage({
    devicesWithIncidentsCount,
    devicesCount,
  });

  return (
    <Paper radius="lg" w={300}>
      <Stack p="xl" spacing="xs" justify="center" pos="relative">
        <Text color="gray.5" truncate size="md">
          {title}
        </Text>

        <Tooltip
          label={
            <Group spacing={2}>
              <Text>Incident priorities:</Text>

              <Text transform="capitalize">
                {incidentsPriorities.join(', ')}
              </Text>
            </Group>
          }
        >
          <Badge color="gray" variant="outline" px="md" py="sm" w="fit-content">
            {getPrioritiesLabel()}
          </Badge>
        </Tooltip>

        <Box>
          <Group spacing="xs" align="baseline">
            <Text size={28} color="blue_gray.9" weight={700}>
              {deviceIncidentView ===
              DeviceIncidentViewEnum.DevicesWithIncidents
                ? percentageOfDevicesWithIncidents
                : percentageOfDevicesWithoutIncidents}
              %
            </Text>

            <Text size="sm" color="blue_gray.9">
              (
              {`${
                deviceIncidentView ===
                DeviceIncidentViewEnum.DevicesWithIncidents
                  ? devicesWithIncidentsCount
                  : devicesWithoutIncidentsCount
              } of ${devicesCount}`}
              )
            </Text>
          </Group>

          <Text size="sm" color="blue_gray.9">
            {deviceIncidentView === DeviceIncidentViewEnum.DevicesWithIncidents
              ? 'devices with incidents'
              : 'incident-free devices'}
          </Text>
        </Box>
      </Stack>
    </Paper>
  );
}
