import { createStyles, Group } from '@mantine/core';
import React from 'react';

import { DeviceModelIconName } from '@portals/types';

import { DEVICE_MODEL_ICONS } from './device-model-icons';

export interface DeviceModelIconProps {
  iconName?: DeviceModelIconName;
  size?: string | number;
  className?: string;
}

export function DeviceModelIcon({
  iconName,
  size = 24,
  className,
}: DeviceModelIconProps) {
  const { classes, cx } = useStyles(size);

  const iconComponent =
    iconName && DEVICE_MODEL_ICONS[iconName]
      ? DEVICE_MODEL_ICONS[iconName].component
      : DEVICE_MODEL_ICONS.Server.component;

  return (
    <Group className={cx(classes.container, className)}>{iconComponent}</Group>
  );
}

const useStyles = createStyles((theme, size: DeviceModelIconProps['size']) => ({
  container: {
    width: size,
    height: size,

    svg: {
      width: '100%',
      height: '100%',
    },
  },
}));
