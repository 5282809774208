import React, { FC, ReactNode, useState } from 'react';
import { IntlProvider } from 'react-intl';

import { Locale, LOCALES } from '../constants';
import { LocalizationContext } from '../context';

type LocalizationProps = {
  children: ReactNode;
};

const Localization: FC<LocalizationProps> = ({ children }) => {
  const [locale, setLocale] = useState(Locale.en);

  return (
    <IntlProvider messages={LOCALES[locale]} locale={locale}>
      <LocalizationContext.Provider
        value={{ locale, setLocale, locales: LOCALES }}
      >
        {children}
      </LocalizationContext.Provider>
    </IntlProvider>
  );
};

export default Localization;
