import { Text } from '@mantine/core';
import React from 'react';

import { CurrencyCode, PaymentIntervalEnum } from '@portals/types';
import { formatPrice } from '@portals/utils';

interface StandardPriceProps {
  price: number | undefined | null;
  currencyCode: CurrencyCode;
  period: PaymentIntervalEnum;
}

export function StandardPrice({
  price,
  period,
  currencyCode,
}: StandardPriceProps) {
  const periodLabel = getPeriodLabel(period);

  return (
    <div>
      <Text size="lg" weight={700} span data-testid="price-value">
        {formatPrice({ value: price, currencyCode })}
      </Text>

      {periodLabel ? (
        <Text size="md" weight={500} color="gray.5" span>
          {periodLabel}
        </Text>
      ) : null}
    </div>
  );
}

function getPeriodLabel(period: PaymentIntervalEnum) {
  switch (period) {
    case PaymentIntervalEnum.Monthly:
      return ' / month';
    case PaymentIntervalEnum.Yearly:
      return ' / year';
    default:
      return null;
  }
}
