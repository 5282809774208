import {
  Radio,
  RadioGroupProps,
  RadioProps,
  Stack,
  Textarea,
  TextareaProps,
} from '@mantine/core';
import React, { useState } from 'react';

export const SUBSCRIPTION_CANCELLATION_REASONS = [
  'I could not get the product to work',
  'The product did not deliver the expected value',
  'I found another product that is better for me',
  "I don't need the product anymore",
  'Other (free text)',
] as const;

export type SubscriptionCancellationReason =
  | (typeof SUBSCRIPTION_CANCELLATION_REASONS)[number]
  | string;

export interface SubscriptionCancellationFormProps {
  readOnly?: boolean;

  reason?: SubscriptionCancellationReason;
  onChangeReason?: (reason: SubscriptionCancellationReason) => void;

  freeText?: string;
  onChangeFreeText?: (freeText: string) => void;
}
export function SubscriptionCancellationForm({
  readOnly = false,
  reason,
  onChangeReason,
  freeText,
  onChangeFreeText,
}: SubscriptionCancellationFormProps) {
  return (
    <Stack spacing="xl">
      <Radio.Group
        styles={radioGroupStyles}
        value={reason}
        onChange={(e: SubscriptionCancellationReason) => onChangeReason?.(e)}
        label="Can you let us know why are you canceling?"
      >
        {SUBSCRIPTION_CANCELLATION_REASONS.map((reason) => (
          <Radio
            key={reason}
            size="xs"
            disabled={readOnly}
            value={reason}
            label={reason}
            data-testid="cancellation-reason-option"
            styles={radioStyles}
          />
        ))}
      </Radio.Group>

      <Textarea
        disabled={readOnly}
        styles={textareaStyles}
        label="Anything else you would like us to know?"
        placeholder="Share some feedback with us..."
        data-testid="cancellation-reason-free-text"
        value={freeText}
        radius="md"
        minRows={6}
        onChange={(e) => onChangeFreeText?.(e.target.value)}
      />
    </Stack>
  );
}

const radioGroupStyles: RadioGroupProps['styles'] = (theme) => ({
  label: {
    marginBottom: theme.spacing.xl,
    fontWeight: 600,
    color: theme.colors.gray[9],
  },
});

const radioStyles: RadioProps['styles'] = (theme) => ({
  root: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing.md,
    },
  },
  label: {
    marginBottom: 0,
    color: theme.colors.gray[9],
  },
});

const textareaStyles: TextareaProps['styles'] = (theme) => ({
  label: {
    marginBottom: theme.spacing.xl,
    fontWeight: 600,
    color: theme.colors.gray[9],
  },
});

export function useSubscriptionCancellationForm(params?: {
  initialReason?: SubscriptionCancellationReason;
  initialFreeText?: string;
}) {
  const [reason, setReason] = useState<
    SubscriptionCancellationReason | undefined
  >(params?.initialReason);
  const [freeText, setFreeText] = useState(params?.initialFreeText ?? '');

  return {
    reason,
    setReason,
    freeText,
    setFreeText,
  };
}
