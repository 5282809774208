import React from 'react';

import { purchasedProductsQueryKeys } from '@portals/api/organizations';
import {
  DashboardContentLayout,
  RoutedTabs,
  RoutedTabsProps,
} from '@portals/framework';

import { PurchasedLicenseList } from './PurchasedLicenseList';
import { PurchasedProductsList } from './PurchasedProductsList';

const ROUTES: RoutedTabsProps['routes'] = [
  {
    path: 'all',
    tabLabel: 'All',
    element: (
      <PurchasedProductsList
        dataHookUrl=""
        dataHookQueryKey={purchasedProductsQueryKeys.list()}
      />
    ),
  },
  {
    path: 'physical-products',
    tabLabel: 'Physical Products',
    element: (
      <PurchasedProductsList
        dataHookUrl="?q[product_product_type_eq]=physical"
        dataHookQueryKey={purchasedProductsQueryKeys.physical()}
      />
    ),
  },
  {
    path: 'licenses',
    tabLabel: 'Licenses',
    element: (
      <PurchasedLicenseList
        dataHookUrl="?q[product_product_type_not_eq]=physical"
        dataHookQueryKey={purchasedProductsQueryKeys.license()}
      />
    ),
  },
];

export const PurchasedProducts = () => {
  return (
    <DashboardContentLayout
      pageTitle="Purchased Products"
      bg="gray.0"
      breadcrumbs={[
        {
          label: 'Products',
          to: '/products',
        },
        {
          label: 'Purchased Products',
        },
      ]}
    >
      <RoutedTabs routes={ROUTES} basePath="/products/purchased-products" />
    </DashboardContentLayout>
  );
};
