import { createStyles } from '@mantine/core';
import React from 'react';

import { SettingCard } from './SettingCard';
import { SettingPage } from './settings-page.types';

export interface SettingsPageGridProps {
  basePath: string;
  pages: SettingPage[];
  searchTerm: string;
  highlightedPageIds: string[];
}

export function SettingsPageGrid({
  pages,
  basePath,
  searchTerm,
  highlightedPageIds,
}: SettingsPageGridProps) {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      {pages.map((page) => (
        <SettingCard
          key={page.id}
          icon={page.icon}
          title={page.title}
          description={page.description}
          path={`${basePath}${page.path}`}
          ribbon={page.ribbon}
          badge={page.badge}
          searchTerm={searchTerm}
          isHighlighted={highlightedPageIds.includes(page.id)}
        />
      ))}
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    height: 'fit-content',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(370px, 1fr))',
    gridTemplateRows: 225,
    gridAutoRows: 225,
    gap: theme.spacing.xl,

    [theme.fn.smallerThan('md')]: {
      gridTemplateColumns: '1fr',
    },
  },
}));
