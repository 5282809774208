import { globalQueryKeys } from '../global-query-keys';

export const INCIDENTS_API_URL = 'ui/organization/incidents';

export const incidentsQueryKeys = {
  base: [...globalQueryKeys.incidents, INCIDENTS_API_URL, 'incidents'],
  all: () => [...incidentsQueryKeys.base, 'all'],
  detail: (incidentId: string) => [...incidentsQueryKeys.base, incidentId],
  count: () => [...incidentsQueryKeys.base, 'count'],
};
