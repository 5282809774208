import { getOr } from 'lodash/fp';
import React from 'react';

import { DeviceDetailsType, DeviceType } from '@portals/api/organizations';
import { CrestronView, DetailsView } from '@portals/framework';
import {
  OrganizationFeatureFlagsType,
  OrganizationPortalCapabilities,
  TabType,
} from '@portals/types';

import { DeviceCommands } from './commands';
import { DeviceConfig } from './config';
import { TabVisibleFnExtraParams } from './device-tabs.types';
import { Events } from './events';
import { DeviceModelFileInfos } from './files';
import { Incidents } from './incidents';
import { Licenses } from './licenses';
import { OverviewWrapper } from './overview/OverviewWrapper';
import { DeviceStateWrapper } from './state';
import { DeviceTelemetries } from './telemetries';
import { Tickets } from './tickets/Tickets';
import { DeviceWarranties } from './warranties';
import {
  DeviceActiveTicketsCounter,
  DeviceCommandsCounter,
  DeviceCounters,
} from '../../../components/DeviceCounters';

export const TABS: Array<
  TabType<
    OrganizationFeatureFlagsType,
    OrganizationPortalCapabilities,
    any,
    TabVisibleFnExtraParams,
    DeviceType
  >
> = [
  {
    id: 'overview',
    title: 'Overview',
    Component: OverviewWrapper,
  },
  {
    id: 'config',
    title: 'Configuration',
    Component: DeviceConfig,
    visible: ({ device }) => device.tabs.configuration === true,
  },
  {
    id: 'licenses',
    title: 'Licenses',
    Component: Licenses,
    visible: ({ device }) => device.tabs.licenses === true,
  },
  {
    id: 'warranties',
    title: 'Warranties',
    Component: DeviceWarranties,
    visible: ({ portalCapabilities }) =>
      portalCapabilities?.self_managed_warranties === true,
  },
  {
    id: 'incidents',
    title: 'Incidents',
    Component: Incidents,
    Counter: ({ device }: { device: DeviceType }) => (
      <DeviceCounters device={device} />
    ),
    counterProps: (device) => ({ device }),
  },
  {
    id: 'tickets',
    title: 'Tickets',
    Component: Tickets,
    visible: ({ portalCapabilities, featureFlags }) =>
      Boolean(
        !featureFlags?.hide_new_tickets_ui && portalCapabilities?.support_center
      ),
    Counter: ({ device }: { device: DeviceDetailsType }) => (
      <DeviceActiveTicketsCounter device={device} />
    ),
    counterProps: (device) => ({ device }),
  },
  {
    id: 'details',
    title: 'Details',
    Component: DetailsView,
    visible: ({ device }) => device.tabs.details === true,
  },
  {
    id: 'commands',
    title: 'Commands',
    Component: DeviceCommands,
    Counter: ({ device }: { device: DeviceType }) => (
      <DeviceCommandsCounter device={device} />
    ),
    visible: ({ device }) => device.tabs.commands === true,
    counterProps: (device) => ({ device }),
  },
  {
    id: 'files',
    title: 'Files',
    Component: DeviceModelFileInfos,
    visible: ({ device }) => device.tabs.files === true,
  },
  {
    id: 'crestron',
    title: 'Crestron',
    Component: CrestronView,
    visible: ({ device, featureFlags }) =>
      getOr(false, 'crestron.manifest', device?.model_settings) &&
      featureFlags?.crestron,
  },
  {
    id: 'state',
    title: 'State',
    Component: DeviceStateWrapper,
    visible: ({ device }) => device.tabs.state === true,
  },
  {
    id: 'telemetry',
    title: 'Telemetries',
    Component: DeviceTelemetries,
    visible: ({ device }) => device.tabs.telemetries === true,
  },
  {
    id: 'events',
    title: 'Events',
    Component: Events,
    visible: ({ device }) => device.tabs.events === true,
  },
];
