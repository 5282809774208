import { Box, createStyles, Group, Tooltip } from '@mantine/core';
import React from 'react';

import { DashboardLayout } from '@portals/types';

import { ReactComponent as EditWidget } from './assets/edit-widget.svg';
import { ReactComponent as RemoveWidget } from './assets/remove-widget.svg';
import { UnknownWidget } from './UnknownWidget';
import { WIDGETS_MAP } from '../overview.constants';
import { useOverviewContext } from '../overview.context';
import { WidgetType } from '../overview.types';

const renderItem = (
  widget: (typeof WIDGETS_MAP)[keyof typeof WIDGETS_MAP],
  itemId: string
) => {
  if (widget.Component) {
    const Component = widget.Component;

    return <Component widgetId={itemId} />;
  }

  return <span className="text-danger text-center">{widget.id}</span>;
};

interface WidgetWrapperProps {
  item: DashboardLayout<WidgetType>['list'][number];
}

export function DashboardWidgetWrapper({ item }: WidgetWrapperProps) {
  const overview = useOverviewContext();

  const widgetSettings = WIDGETS_MAP[item.id];
  const styles = useStyles();

  return (
    <Box
      className={styles.cx(styles.classes.container, {
        draggable: overview.isConfigMode,
      })}
    >
      {overview.isConfigMode && overview.isAdmin ? (
        <Group className={styles.classes.actions} spacing="xs">
          {widgetSettings?.withSettings ? (
            <Tooltip label="Edit widget settings" withinPortal>
              <Box
                className={styles.classes.removeIcon}
                onClick={() => overview.onEditWidget(item.id, item.i)}
              >
                <EditWidget />
              </Box>
            </Tooltip>
          ) : null}

          <Tooltip label="Remove widget" withinPortal>
            <Box
              className={styles.classes.removeIcon}
              onClick={() => overview.onRemoveWidget(item.i)}
            >
              <RemoveWidget data-testid="remove-widget-button" />
            </Box>
          </Tooltip>
        </Group>
      ) : null}

      {!widgetSettings ? (
        <UnknownWidget itemId={item.id} />
      ) : (
        renderItem(WIDGETS_MAP[item.id], item.i)
      )}
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    position: 'relative',
    display: 'grid',
    gridTemplateRows: '1fr',
    height: '100%',

    '&.draggable': {
      cursor: 'move',
    },
  },
  actions: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    right: 0,
    transform: 'translate(15px, -50%)',
  },
  removeIcon: {
    cursor: 'pointer',
  },
}));
