export const ACTION_COLUMN = {
  width: 60,
  minWidth: 60,
  maxWidth: 60,
  canResize: false,
  canFilter: false,
  canExport: false,
  canSort: false,
  isAction: true,
};

export const ROW_HEIGHT = 56;
export const COMPACT_ROW_HEIGHT = 36;

export const CHECKBOX_CELL_ID = 'column-checkbox';

export const EXPANDED_ROW_HEIGHT = 500 + ROW_HEIGHT;
