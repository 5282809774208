import { useViewportSize } from '@mantine/hooks';
import isMobile from 'is-mobile';
import React, { createContext, ReactNode, useContext, useState } from 'react';
import { useUpdateEffect } from 'react-use';

const ResponsiveContext = createContext<{
  isMobile: boolean;
}>({
  isMobile: false,
});

interface ResponsiveProps {
  children: ReactNode;
}

export function Responsive({ children }: ResponsiveProps) {
  const { height, width } = useViewportSize();
  const [isMobileView, setIsMobileView] = useState(isMobile({ tablet: true }));

  useUpdateEffect(() => {
    setIsMobileView(isMobile({ tablet: true }));
  }, [height, width]);

  return (
    <ResponsiveContext.Provider value={{ isMobile: isMobileView }}>
      {children}
    </ResponsiveContext.Provider>
  );
}

function ResponsiveMobile({ children }: ResponsiveProps) {
  const context = useContext(ResponsiveContext);

  if (context.isMobile) {
    return <>{children}</>;
  }

  return null;
}

function ResponsiveDesktop({ children }: ResponsiveProps) {
  const context = useContext(ResponsiveContext);

  if (!context.isMobile) {
    return <>{children}</>;
  }

  return null;
}

Responsive.Mobile = ResponsiveMobile;
Responsive.Desktop = ResponsiveDesktop;
