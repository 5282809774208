import { Avatar, Badge, Box, createStyles, Group, Text } from '@mantine/core';
import React from 'react';

interface UserAvatar {
  isActive?: boolean;
  logoSrc?: string;
}

export function UserAvatar({ isActive = false, logoSrc }: UserAvatar) {
  return (
    <Avatar
      className="tenant-menu-avatar"
      radius="md"
      p={3}
      styles={(theme) => ({
        root: {
          width: 55,
          height: 55,
          background: 'transparent',
          transition: 'border 0.1s ease-in-out',
          border: `2px solid ${
            isActive ? theme.colors.blue_accent[4] : 'transparent'
          }`,
        },
        image: {
          width: '100%',
          height: '100%',
          objectFit: 'contain',
        },
      })}
      src={logoSrc || 'logo_full_dark.svg'}
    />
  );
}

interface UserName {
  name: string;
  isLab?: boolean;
  partner?: string;
}

export function UserName({ name, isLab = false, partner }: UserName) {
  const { cx, classes } = useStyles();

  return (
    <Box className={cx('tenant-user-menu', classes.container)}>
      <Group noWrap spacing="xs">
        <Text
          size="md"
          weight={700}
          title={name}
          className={cx('tenant-user-menu-name', classes.name)}
          truncate
        >
          {name}
        </Text>

        {isLab ? (
          <Badge
            size="xs"
            radius="sm"
            variant="filled"
            sx={{
              flexShrink: 0,
            }}
          >
            LAB
          </Badge>
        ) : null}
      </Group>

      {partner && (
        <Text className={classes.partner} truncate>
          by {partner}
        </Text>
      )}
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },

  name: {
    color: theme.colors.blue_gray[8],
    overflow: 'hidden',
  },

  partner: {
    fontSize: theme.fontSizes.xs,
    color: theme.fn.lighten(theme.colors.blue_gray[8], 0.4),
  },
}));
