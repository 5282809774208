import { Button, Text, TextInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import React from 'react';
import { InferType, object, string } from 'yup';

import { useAddExternalUser } from '@portals/api/organizations';
import { MembersSVG } from '@portals/assets';
import {
  ModalBody,
  ModalCenteredMediaLayout,
  ModalFooter,
} from '@portals/core';
import { ModalProps } from '@portals/framework';

const schema = object({
  email: string().email().required(),
});

export interface AddExternalUserModalProps
  extends ModalProps<{ groupId: string }> {}

export function AddExternalUserModal({
  closeMe,
  data: { groupId },
}: AddExternalUserModalProps) {
  const addExternalUser = useAddExternalUser();

  const form = useForm<InferType<typeof schema>>({
    validate: yupResolver(schema),
    initialValues: {
      email: '',
    },
  });

  const onSubmit = async (values: typeof form.values) => {
    try {
      await addExternalUser.mutateAsync({ groupId, email: values.email });

      closeMe();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ModalCenteredMediaLayout
      opened
      onClose={closeMe}
      title="Add External Member"
      media={<MembersSVG />}
    >
      <form noValidate onSubmit={form.onSubmit(onSubmit)}>
        <ModalBody>
          <TextInput
            data-autofocus
            required
            label="Email"
            placeholder="Email"
            {...form.getInputProps('email')}
          />

          {addExternalUser.isError && (
            <Text color="red" mt="sm">
              {addExternalUser.error.error}
            </Text>
          )}
        </ModalBody>

        <ModalFooter grow>
          <Button variant="default" onClick={closeMe}>
            Cancel
          </Button>
          <Button type="submit">Add Member</Button>
        </ModalFooter>
      </form>
    </ModalCenteredMediaLayout>
  );
}
