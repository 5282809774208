import { Descope, getSessionToken, useDescope } from '@descope/react-sdk';
import {
  Anchor,
  Center,
  Group,
  Loader,
  LoadingOverlay,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { noop } from 'lodash/fp';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, Link, useMatch, useNavigate } from 'react-router-dom';

import { AfterAuthRedirectManager } from '@lib/after-auth-redirect-manager';
import { Pendo } from '@portals/analytics';
import { useCheckUserDomain, useSignIn } from '@portals/api/auth';
import { usePortalConfig } from '@portals/api/organizations';
import {
  captureDescopeError,
  descopeErrorTransformer,
  sendDescopeErrorReport,
} from '@portals/framework';
import { RegisteredWithDifferentPortalModalProps } from '@portals/framework/modals';
import { useAuth, useOpenModal } from '@portals/redux';
import { toastrError } from '@portals/redux/actions/toastr';
import { TenantType } from '@portals/types';

import { CecMigrationWelcomeModalProps } from '../../modals';

export function Signin() {
  const navigate = useNavigate();
  const descope = useDescope();
  const dispatch = useDispatch();

  const [isDescopeFlowLoading, setIsDescopeFlowLoading] = useState(true);

  const match = useMatch('/auth/sign-in/:partner_name?');

  const auth = useAuth();
  const openModal = useOpenModal();

  const signIn = useSignIn();
  const portalConfig = usePortalConfig();
  const checkUserDomain = useCheckUserDomain();

  const withSignup = portalConfig.data?.signup || false;
  const isReferral = !!localStorage.getItem('referral');
  const isSignupVisible = isReferral || withSignup;

  const setRouteTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(
    function redirectedAuthenticatedUser() {
      if (!auth?.token) return;

      AfterAuthRedirectManager.removePathname();

      setRouteTimeout.current = setTimeout(() =>
        navigate(AfterAuthRedirectManager.getPathname(), { replace: true })
      );

      return () => {
        if (setRouteTimeout.current) {
          clearTimeout(setRouteTimeout.current);
          setRouteTimeout.current = null;
        }
      };
    },
    [auth?.token, navigate]
  );

  useEffect(
    function redirectToChangePassword() {
      if (!auth?.change || !!auth?.token) return;

      setRouteTimeout.current = setTimeout(() => {
        const pathname = generatePath('/auth/change-password/:partner_name?', {
          partner_name: match?.params?.partner_name || null,
        });

        navigate(pathname, { replace: true });
      });

      return () => {
        if (setRouteTimeout.current) {
          clearTimeout(setRouteTimeout.current);
          setRouteTimeout.current = null;
        }
      };
    },
    [auth?.change, auth?.token, match?.params?.partner_name, navigate]
  );

  const logger: React.ComponentProps<typeof Descope>['logger'] = {
    debug: noop,
    warn: noop,
    error: noop,
    info: (_title, _description, state) => {
      sendDescopeErrorReport(state, 'Organizations > Signin');

      if (!state.error) return;

      const email = state?.screen?.state?.form?.email;

      if (!email) {
        Pendo.track('Descope error - wrong login domain', {
          email: 'No email provided',
          domain: window.location.origin,
        });

        return;
      }

      Pendo.track('Descope error - wrong login domain', {
        email,
        domain: window.location.origin,
      });

      checkUserDomain.mutate(
        { email },
        {
          onSuccess: (response) => {
            const { domain, partner_name } = response;

            if (domain && domain !== window.location.origin) {
              openModal<RegisteredWithDifferentPortalModalProps['data']>(
                'RegisteredWithDifferentPortal',
                { domain, partnerName: partner_name }
              );
            } else if (response.requires_cec_migration) {
              openModal<CecMigrationWelcomeModalProps['data']>(
                'CecMigrationWelcomeModal',
                {
                  userName: response.name,
                  userEmail: email,
                }
              );
            }
          },
        }
      );
    },
  };

  return (
    <Stack>
      <Group mb="lg" align="baseline" position="center">
        <Title order={1} className="auth-page-title" align="center">
          Sign in
        </Title>

        {isSignupVisible ? (
          <Text color="dimmed" size="sm" align="center">
            <Group spacing="xs">
              Don't have an account yet?
              <Anchor
                data-testid="sign-up-button"
                size="sm"
                component={Link}
                to={generatePath('/auth/sign-up/:partner_name?', {
                  partner_name: match?.params?.partner_name || null,
                })}
              >
                Sign up
              </Anchor>
            </Group>
          </Text>
        ) : null}
      </Group>

      <LoadingOverlay visible={checkUserDomain.isLoading} />

      {isDescopeFlowLoading && (
        <Center>
          <Loader />
        </Center>
      )}

      <Descope
        flowId="sign-in-organization"
        onError={captureDescopeError}
        onReady={() => setIsDescopeFlowLoading(false)}
        logger={logger}
        errorTransformer={descopeErrorTransformer}
        onSuccess={async (e) => {
          const sessionToken = getSessionToken();

          try {
            const signInResponse = await signIn.mutateAsync({
              token: sessionToken,
              tenant_type: TenantType.Organization,
            });

            Pendo.track('Successful sign-in (v1)', {
              email: signInResponse?.email,
              tenantType: TenantType.Organization,
            });
          } catch (error) {
            await descope.logout();
            await descope.refresh();
            dispatch(
              toastrError(
                'Failed to sign in',
                error?.data?.error || error?.data
              )
            );
          } finally {
            navigate(AfterAuthRedirectManager.getPathname(), {
              state: { afterLogin: true },
              replace: true,
            });
          }
        }}
      />
    </Stack>
  );
}
