import {
  Box,
  Button,
  Center,
  createStyles,
  Group,
  LoadingOverlay,
  Stack,
  Text,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import React from 'react';
import { v4 as uuid } from 'uuid';
import { array, number, object, string } from 'yup';

import { useIncidentsAge } from '@portals/api/organizations';
import { useConfirmationModal } from '@portals/framework';

import { ActiveIncidentsAgeWidget } from './ActiveIncidentsAgeWidget';
import { ActiveIncidentsAgeWidgetForm } from './ActiveIncidentsAgeWidgetForm';
import { WidgetPreviewComponentProps } from '../../../../../modals/WidgetPreview/widget-preview.types';
import {
  ActiveIncidentsAgeWidgetFormType,
  DataLevelEnum,
  TierType,
  TimeUnit,
} from '../../../overview.types';
import { WidgetCardWrapper } from '../common/WidgetCardWrapper';

const DEFAULT_TIERS: TierType[] = [
  {
    displayName: '',
    firstValue: 0,
    firstValueTimeUnit: TimeUnit.Minutes,
    lastValueTimeUnit: TimeUnit.Minutes,
    lastValue: Infinity,
    id: uuid(),
  },
];

const schema = object().shape({
  title: string().required(),
  tiers: array().of(
    object().shape({
      displayName: string().required('Display name is required'),
      firstValue: number().required(),
    })
  ),
});

export function ActiveIncidentsAgeWidgetWrapper({
  onClose,
  space,
  onSave,
  type,
  dataLevel,
  widgetId,
  localDashboard,
}: WidgetPreviewComponentProps & { onClose: () => void }) {
  const { classes } = useStyles();

  const activeIncidentsWidgetExtraParams = localDashboard?.list.find(
    (item) => item.id === 'device_incidents_age' && item.i === widgetId
  )?.extraParams as ActiveIncidentsAgeWidgetFormType | undefined;

  const initialValues: ActiveIncidentsAgeWidgetFormType = {
    title: activeIncidentsWidgetExtraParams?.title ?? 'Active incidents by age',
    incidents_severity:
      activeIncidentsWidgetExtraParams?.incidents_severity ?? false,
    tiers: activeIncidentsWidgetExtraParams?.tiers ?? DEFAULT_TIERS,
  };

  const form = useForm<ActiveIncidentsAgeWidgetFormType>({
    initialValues,
    validate: yupResolver(schema),
  });

  const incidentsAge = useIncidentsAge({
    spaceId: space?.id,
    includeSubSpaces: dataLevel === DataLevelEnum.All,
  });

  const asyncConfirmationCheck = useConfirmationModal();

  const onCloseWrapper = async () => {
    if (!form.isDirty()) {
      onClose();
      return;
    }

    const isConfirmed = await asyncConfirmationCheck({
      title: 'Are you sure?',
      description: 'All your changes will be lost',
      confirmationLabel: 'Confirm',
      cancelLabel: 'Cancel',
    });

    if (isConfirmed) {
      onClose();
    }
  };

  const onSubmit = (values: typeof form.values) => {
    onSave?.({
      title: values.title,
      tiers: values.tiers,
      displayIncidentSeverity: values.incidents_severity,
      median: incidentsAge.data?.median_incident_age_in_seconds ?? 0,
    });

    onClose();
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <LoadingOverlay visible={incidentsAge.isLoading} />

      <Box className={classes.container}>
        <Stack className={classes.preview} p="xxl" spacing="xxl">
          <Text
            size="xl"
            color="blue_gray.9"
            data-testid="active-incidents-by-age-modal-title"
          >
            Active incidents by age
          </Text>

          <ActiveIncidentsAgeWidgetForm form={form} />
        </Stack>

        <Center className={classes.config}>
          <WidgetCardWrapper
            paperProps={{
              p: 'xl',
              shadow: 'xl',
              h: 400,
              w: 500,
            }}
            stackProps={{ spacing: 'xs' }}
            header={
              <Text size="md" color="gray.5">
                {form.values.title}
              </Text>
            }
          >
            <ActiveIncidentsAgeWidget
              tiers={form.values.tiers}
              dataLevel={dataLevel}
              space={space}
              displayIncidentSeverity={form.values.incidents_severity}
              median={incidentsAge.data?.median_incident_age_in_seconds ?? 0}
            />
          </WidgetCardWrapper>
        </Center>
      </Box>

      <Group className={classes.footer} p="xl" position="right">
        <Button
          variant="default"
          data-testid="active-incidents-modal-close-button"
          onClick={onCloseWrapper}
        >
          Close
        </Button>

        <Button type="submit" data-testid="modal-add-widget-button">
          {type === 'edit' ? 'Save' : 'Add'}
        </Button>
      </Group>
    </form>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr min-content',
    gridTemplateAreas: `
      "preview config"
      "footer footer"
    `,
    gap: 0,
    height: '65vh',
  },

  preview: {
    gridArea: 'preview',
  },

  config: {
    gridArea: 'config',
    backgroundColor: theme.colors.gray[0],
  },

  footer: {
    gridArea: 'footer',
    borderTop: `1px solid ${theme.colors.gray[2]}`,
  },
}));
