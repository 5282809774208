import {
  Box,
  Button,
  createStyles,
  Group,
  Menu,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import React, { useState } from 'react';

import { DeviceType, useReplaceDevice } from '@portals/api/organizations';
import { ReactComponent as ArrowRight1 } from '@portals/icons/linear/arrow-right-1.svg';
import { ReactComponent as Barcode } from '@portals/icons/linear/barcode.svg';
import { ReactComponent as Danger } from '@portals/icons/linear/danger.svg';
import { ReactComponent as Lifebuoy } from '@portals/icons/linear/lifebuoy.svg';
import { ReactComponent as More } from '@portals/icons/linear/more.svg';
import { ReactComponent as Refresh2 } from '@portals/icons/linear/refresh-2.svg';
import { ReactComponent as Snooze } from '@portals/icons/linear/snooze.svg';
import { ReactComponent as Ticket } from '@portals/icons/linear/ticket.svg';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
import { useOpenModal } from '@portals/redux';
import { DeviceType as CommonDeviceType } from '@portals/types';

import { TOUR_STEPS_IDS } from '../../../../constants/setup/setup.constants';
import { ReplaceDeviceProps } from '../../../../modals';
import { useDeviceContext } from '../context';

interface ActionsProps<TDevice extends DeviceType | CommonDeviceType> {
  device: TDevice;
}

const SNOOZE_OPTIONS = [
  {
    value: 60,
    label: '1 Hour',
  },
  {
    value: 60 * 24,
    label: '1 Day',
  },
  {
    value: 60 * 24 * 7,
    label: '7 Days',
  },
  {
    value: 60 * 24 * 30,
    label: '30 Days',
  },
];

export function Actions<TDevice extends DeviceType | CommonDeviceType>({
  device,
}: ActionsProps<TDevice>) {
  const { classes } = useStyles();
  const openModal = useOpenModal();
  const replaceDevice = useReplaceDevice();

  const deviceContext = useDeviceContext();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  return (
    <Group spacing="md" noWrap data-tour-step-id={TOUR_STEPS_IDS.deviceActions}>
      <Menu
        position="bottom-start"
        opened={isMenuOpen}
        onOpen={() => setIsMenuOpen(true)}
        closeOnClickOutside={!isTooltipOpen}
        onClose={() => {
          setIsMenuOpen(false);
          setIsTooltipOpen(false);
        }}
        closeOnItemClick={false}
      >
        <Menu.Target>
          <Button
            variant="default"
            p={0}
            classNames={{ label: classes.buttonLabel }}
            sx={{ width: '100%' }}
            data-testid="device-info-support-button"
          >
            <Group spacing={4}>
              <Lifebuoy />
              Support
            </Group>
          </Button>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item
            icon={<Danger />}
            data-testid="device-info-actions-report-incident-button"
            onClick={() => {
              setIsMenuOpen(false);
              openModal('ReportIncidentModal', { device });
            }}
          >
            Report Incident
          </Menu.Item>

          {deviceContext.withSupportCenter ? (
            <Menu.Item
              data-testid="device-info-actions-open-ticket-button"
              icon={<Ticket />}
              onClick={() => {
                setIsMenuOpen(false);
                openModal('CreateTicketModal', { deviceId: device.id });
              }}
            >
              Open Ticket
            </Menu.Item>
          ) : null}

          {!device?.snoozed_until ? (
            <Box
              onMouseEnter={() => setIsTooltipOpen(true)}
              onMouseLeave={() => setIsTooltipOpen(false)}
            >
              <Tooltip
                opened={isTooltipOpen}
                withinPortal
                position="right-start"
                classNames={{
                  tooltip: classes.tooltip,
                }}
                label={
                  <Stack spacing={4} className={classes.submenu} p="xs">
                    {SNOOZE_OPTIONS.map(({ value, label }) => (
                      <Text
                        data-testid={`device-info-actions-snooze-${label}`}
                        size="sm"
                        key={label}
                        onClick={() => {
                          deviceContext.onSnoozeDevice(value);
                          setIsMenuOpen(false);
                          setIsTooltipOpen(false);
                        }}
                      >
                        {label}
                      </Text>
                    ))}
                  </Stack>
                }
              >
                <Menu.Item
                  data-testid="device-info-actions-snooze-label"
                  icon={<Snooze />}
                  sx={(theme) =>
                    isTooltipOpen
                      ? { backgroundColor: theme.colors.gray[1] }
                      : {}
                  }
                >
                  <Group
                    position="apart"
                    noWrap
                    sx={{ svg: { width: 15, height: 15 } }}
                  >
                    Turn Off Incident Tracking
                    <ArrowRight1 />
                  </Group>
                </Menu.Item>
              </Tooltip>
            </Box>
          ) : null}
        </Menu.Dropdown>
      </Menu>

      <Button
        variant="default"
        p={0}
        classNames={{ label: classes.buttonLabel }}
        onClick={deviceContext.onRefresh}
        fullWidth
        data-testid="device-info-actions-refresh-button"
      >
        <Group spacing={4}>
          <Refresh2 />
          Refresh
        </Group>
      </Button>

      <Menu position="bottom-start">
        <Menu.Target>
          <Button
            data-testid="device-info-actions-more-button"
            variant="default"
            classNames={{ label: classes.buttonLabel }}
            sx={{ flexGrow: 0 }}
          >
            <More />
          </Button>
        </Menu.Target>

        <Menu.Dropdown miw={198}>
          {device.parent === null ? (
            <>
              <Menu.Item
                data-testid="device-info-actions-replace-device-button"
                icon={<Barcode />}
                onClick={() =>
                  openModal<ReplaceDeviceProps['data']>('ReplaceDevice', {
                    onReplace: replaceDevice,
                    deviceId: device.id,
                  })
                }
              >
                Replace Device
              </Menu.Item>
              <Menu.Divider />
            </>
          ) : null}

          <Menu.Item
            icon={<Trash />}
            onClick={deviceContext.onDeleteDevice}
            color="red.4"
            data-testid="device-info-actions-delete-device-button"
          >
            Delete
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  buttonLabel: {
    color: theme.colors.blue_gray[8],
    fontSize: theme.fontSizes.xs,

    svg: {
      width: 12,
      height: 12,
    },

    [theme.fn.largerThan(1600)]: {
      fontSize: theme.fontSizes.sm,

      svg: {
        width: 16,
        height: 16,
      },
    },
  },
  submenuWrapper: {
    '&:hover': {
      '.submenu-target': {
        backgroundColor: theme.colors.gray[1],
      },
    },
  },
  inner: {
    padding: theme.spacing.xs,
  },
  submenu: {
    width: 110,

    '> div': {
      padding: `${theme.spacing.xs} ${theme.spacing.md}`,
      display: 'flex',
      alignItems: 'center',
      color: theme.colors.gray[9],
      width: '100%',
      cursor: 'pointer',

      '&:hover': {
        backgroundColor: theme.colors.gray[1],
      },
    },
  },
  tooltip: {
    pointerEvents: 'all',
    background: 'white',
    padding: 0,
    filter: 'drop-shadow(-4px 4px 36px rgba(0, 2, 41, 0.1))',
  },
}));
