import { Anchor, Group, Text } from '@mantine/core';
import React from 'react';

export function TermsOfUse() {
  return (
    <Group spacing={4}>
      <Text>By continuing I agree with the</Text>

      <Anchor
        href="https://xyte.io/terms-of-service"
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms of Use
      </Anchor>

      <Text>and</Text>

      <Anchor
        href="https://xyte.io/privacy-policy"
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy Policy
      </Anchor>
    </Group>
  );
}
