import { Button, createStyles } from '@mantine/core';
import React from 'react';

import { ModalBody, ModalFooter } from '@portals/core';

import { ContactPartnerSupportButton } from '../../../../components/ContactPartnerSupportButton';
import { FormComponentCommonProps } from '../../add-device-modal.types';
import { VendorField } from '../fields/VendorField';

export function VendorForm({
  handleClose,
  selectedPartnerId,
  setSelectedPartnerId,
}: FormComponentCommonProps) {
  const { classes } = useStyles();

  return (
    <>
      <ModalBody px="xxl">
        <VendorField
          vendor={selectedPartnerId}
          setVendor={setSelectedPartnerId}
        />
      </ModalBody>

      <ModalFooter position="right" p="xxl" className={classes.footer}>
        <ContactPartnerSupportButton supportModalTitle="Device claiming" />
        <Button type="button" variant="default" onClick={handleClose}>
          Cancel
        </Button>

        <Button type="submit" disabled>
          Claim device
        </Button>
      </ModalFooter>
    </>
  );
}

const useStyles = createStyles((theme) => ({
  footer: {
    borderTop: `1px solid ${theme.colors.gray[2]}`,
  },
}));
