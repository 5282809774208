import {
  Button,
  createStyles,
  Group,
  HoverCard,
  HoverCardProps,
  Stack,
  Text,
} from '@mantine/core';
import { isUndefined } from 'lodash/fp';
import React, { FC, ReactNode } from 'react';

import { ReactComponent as Crown } from '@portals/icons/linear/crown-1.svg';

export interface UpgradeTierHoverCardProps {
  message?: ReactNode | null;
  onUpgrade?: () => void;
  children?: ReactNode;
  hoverCardProps?: HoverCardProps;
}

export const UpgradeTierHoverCard: FC<UpgradeTierHoverCardProps> = ({
  message,
  onUpgrade,
  children,
  hoverCardProps = {},
}) => {
  const { classes } = useHoverCardStyles();

  return (
    <HoverCard
      position="right"
      withArrow
      width={370}
      shadow="xl"
      withinPortal
      classNames={{
        dropdown: classes.dropdown,
      }}
      {...hoverCardProps}
    >
      <HoverCard.Target>{children}</HoverCard.Target>

      <HoverCard.Dropdown p="xl">
        <Stack classNames={{ root: classes.content }} spacing="xs">
          <Group spacing="xs" className="header">
            <Crown width={16} height={16} />

            <Text className="label" size="sm" weight={600}>
              {message ? 'Upgrade to Activate' : 'This is a Premium Feature'}
            </Text>
          </Group>

          <Stack spacing="md" p={0}>
            {isUndefined(message) ? (
              <Stack spacing={0}>
                <Text size="xs">This feature is currently in beta</Text>

                <Text size="xs">
                  Usage restrictions may be applied after beta period
                </Text>
              </Stack>
            ) : (
              <>
                {message}

                {onUpgrade ? (
                  <Group position="right">
                    <Button w={170} onClick={onUpgrade}>
                      Upgrade Plan
                    </Button>
                  </Group>
                ) : null}
              </>
            )}
          </Stack>
        </Stack>
      </HoverCard.Dropdown>
    </HoverCard>
  );
};

const useHoverCardStyles = createStyles((theme) => ({
  content: {
    '.header': {
      svg: {
        path: {
          stroke: theme.colors.blue_gray[9],
        },
      },
      color: theme.colors.blue_gray[9],
    },
    '.link': {
      cursor: 'pointer',
    },
  },
  dropdown: {
    borderRadius: theme.radius.md,
  },
}));
