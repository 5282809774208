import { Anchor, Group } from '@mantine/core';
import { last } from 'lodash/fp';
import React from 'react';

import { FileTypeIcon } from '@portals/core';
import { PostPurchaseParamsType } from '@portals/types';
import { extractFilenamePrefixedWithUuidV4 } from '@portals/utils';

import { DetailsList, DetailsListProps } from './details-list/DetailsList';

interface PostPurchaseParamsDetailsListProps {
  pppFields: PostPurchaseParamsType;
}

export function PostPurchaseParamsDetailsList({
  pppFields,
}: PostPurchaseParamsDetailsListProps) {
  const items: DetailsListProps['items'] = Object.entries(pppFields).map(
    ([label, field]) => ({
      label,
      // TODO: Remove when deployed, checking field.type for backwards compatibility before
      //  the structure was changed to include the type
      value: field.type ? <PPPFieldValue field={field} /> : field.value,
    })
  );

  return <DetailsList items={items} />;
}

interface PPPFieldValueProps {
  field: PostPurchaseParamsType[string];
}
function PPPFieldValue({ field }: PPPFieldValueProps) {
  if (field.type !== 'file') return <>{field.value}</>;

  return (
    <Group align="center">
      <FileTypeIcon fileUrl={field.value as string} height={20} />

      <Anchor
        truncate
        href={field.value as string}
        target="_blank"
        rel="noreferrer"
      >
        {extractFilenamePrefixedWithUuidV4(
          last((field.value as string).split('/'))
        )}
      </Anchor>
    </Group>
  );
}
