import { Box, createStyles } from '@mantine/core';
import React from 'react';

import { DeviceStatusType } from '@portals/types';

import { useDeviceOverviewContext } from '../../DeviceOverviewContext';

function getChildDeviceMarkerColor(status: DeviceStatusType | undefined) {
  switch (status) {
    case 'offline':
      return 'gray.5';
    case undefined:
      return 'amber.3';
    case 'error':
      return 'red.4';
    case 'online':
    default:
      return 'blue_accent.4';
  }
}

export interface ChildDeviceMarkerProps {
  deviceId: string;
  status: DeviceStatusType | undefined;
  lat?: number;
  lng?: number;
}

export function ChildDeviceMarker({
  status,
  deviceId,
}: ChildDeviceMarkerProps) {
  const { selectedDeviceId, setSelectedDeviceId, isFullscreen } =
    useDeviceOverviewContext();
  const { classes, theme } = useStyles();

  return (
    <Box
      className={classes.childWrapper}
      onClick={() => isFullscreen && setSelectedDeviceId(deviceId)}
      id="device-location-map-marker"
    >
      <Box
        className={classes.child}
        bg={getChildDeviceMarkerColor(status)}
        id="device-location-map-marker"
      />
      <Box
        className={classes.childBackground}
        id="device-location-map-marker"
      />

      <Box
        className={classes.selectedChildIndicator}
        bg={
          selectedDeviceId === deviceId
            ? theme.fn.rgba(theme.black, 0.25)
            : 'transparent'
        }
        id="device-location-map-marker"
      />
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  childWrapper: {
    cursor: 'pointer',
    width: 18,
    height: 18,
    boxShadow: theme.shadows.md,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    position: 'relative',
    zIndex: 999,
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  selectedChildIndicator: {
    width: 36,
    height: 36,
    borderRadius: '50%',
    position: 'absolute',
    zIndex: 1,
    transition: 'background-color 0.15s ease-in-out',
  },
  child: {
    width: 12,
    height: 12,
    borderRadius: '50%',
    zIndex: 3,
  },
  childBackground: {
    zIndex: 2,
    width: 18,
    height: 18,
    background: theme.white,
    borderRadius: '50%',
    position: 'absolute',
    borderStyle: 'solid',
    borderColor: theme.colors.blue_gray[1],
  },
}));
