import { createStyles, Group, Stack, StackProps, Text } from '@mantine/core';
import { isUndefined } from 'lodash/fp';
import React from 'react';

import { OfflineDeviceStateTooltip } from '../../common/OfflineDeviceStateTooltip';

export interface ValueWidgetProps {
  title: string;
  value: string | number;
  unit?: string | null;

  stackProps?: StackProps;

  isDeviceOffline?: boolean;
  lastUpdateTimestamp?: string;
}

export function ValueWidget({
  title,
  value,
  unit,

  stackProps = {},

  isDeviceOffline,
  lastUpdateTimestamp,
}: ValueWidgetProps) {
  const { classes } = useStyles();

  const getValue = () => {
    if (isUndefined(value)) return 'N/A';

    if (!unit) return value;

    return `${value} ${unit}`;
  };

  return (
    <Stack
      className={classes.container}
      p="xl"
      h="100%"
      w="100%"
      bg="white"
      justify="center"
      spacing={4}
      {...stackProps}
    >
      <Group noWrap align="center" spacing="sm">
        <Text
          size="md"
          data-testid="dashboard-value-widget-name"
          color="gray.5"
          truncate
        >
          {title}
        </Text>

        {isDeviceOffline ? (
          <OfflineDeviceStateTooltip
            lastUpdateTimestamp={lastUpdateTimestamp}
          />
        ) : null}
      </Group>

      <Text
        size={28}
        data-testid="value-track-measurement-unit-preview"
        color="gray.8"
        truncate
      >
        {getValue()}
      </Text>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    borderRadius: theme.radius.lg,
  },
}));
