import { FC } from 'react';

import { NoteWidgetPreview } from './preview/NoteWidgetPreview';
import { WidgetPreviewComponentProps } from './widget-preview.types';
import { ActiveIncidentsAgeWidgetWrapper } from '../../pages/overview/overview-dashboard/widgets/active-incidents-age/ActiveIncidentsAgeWidgetWrapper';
import { DeviceModelBreakdownWidgetWrapper } from '../../pages/overview/overview-dashboard/widgets/device-model-breakdown/DeviceModelBreakdownWidgetWrapper';
import { DeviceIncidentRateWidgetWrapper } from '../../pages/overview/overview-dashboard/widgets/devices-incidents-rank/DeviceIncidentRateWidgetWrapper';
import { DevicesPerSpaceWidgetWrapper } from '../../pages/overview/overview-dashboard/widgets/devices-per-space/DevicesPerSpaceWidgetWrapper';
import { SpaceIncidentRateWidgetWrapper } from '../../pages/overview/overview-dashboard/widgets/space-incident-rate/SpaceIncidentRateWidgetWrapper';
import { WidgetType } from '../../pages/overview/overview.types';

export const WIDGET_PREVIEW_COMPONENTS_MAP: Partial<
  Record<WidgetType, FC<WidgetPreviewComponentProps & { onClose: () => void }>>
> = {
  note: NoteWidgetPreview,
  space_incidents_rate: SpaceIncidentRateWidgetWrapper,
  device_incidents_rate: DeviceIncidentRateWidgetWrapper,
  devices_model_breakdown: DeviceModelBreakdownWidgetWrapper,
  device_incidents_age: ActiveIncidentsAgeWidgetWrapper,
  devices_per_space: DevicesPerSpaceWidgetWrapper,
};
