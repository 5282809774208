import {
  AccessLevelEnum,
  PaymentMethodEnum,
  UserPermissionNames,
} from '@portals/types';

export const INCIDENT_TYPE = [
  { id: 1, title: 'Critical', color: 'red' } as const,
  { id: 2, title: 'High', color: 'orange' } as const,
  { id: 3, title: 'Moderate', color: 'blue' } as const,
  { id: 4, title: 'Low', color: 'indigo' } as const,
  { id: 5, title: 'Planning', color: 'cyan' } as const,
] as const;

export const PRIORITY_OPTIONS = {
  1: 'Critical',
  2: 'High',
  3: 'Moderate',
  4: 'Low',
  5: 'Planning',
} as const;

export const PRIORITY_COLORS = {
  1: 'red',
  2: 'orange',
  3: 'blue',
  4: 'indigo',
  5: 'cyan',
} as const;

export const LICENSE_STATES = {
  available: 'Available',
  inuse: 'Inuse',
  used: 'Used',
  remove: 'Removing',
  error: 'Error',
  pending: 'Pending',
  removed: 'Removed',
} as const;

export const COLORS = {
  primary: '#47bac1',
  secondary: '#a180da',
  tertiary: '#5fc27e',
  success: '#5fc27e',
  info: '#5b7dff',
  warning: '#fcc100',
  danger: '#f44455',
} as const;

export const LICENSE_STATE_COLORS = {
  unassigned: 'blue',
  assigned: 'blue_accent',
  unclaimed: 'blue_accent',
  pending: 'blue_accent',
  inuse: 'green',
  used: 'orange',
  remove: 'blue_accent',
  error: 'red',
} as const;

export const SUBSCRIPTION_STATE_COLORS = {
  active: 'blue',
  suspended: 'orange',
  canceled: 'red',
} as const;

export const DEFAULT_MAP_CENTER = { lng: 2.154007, lat: 41.390205 };

export const CHART_COLORS = [
  '#47bac1',
  '#7bc9bb',
  '#a7d6bc',
  '#cde3c6',
  '#edf0d9',
  '#e5d29f',
  '#e9ad6f',
  '#f18054',
  '#f44455',
  '#fa7778',
] as const;

export const ACCESS_LEVEL = { 1: 'View', 2: 'Edit', 3: 'Admin' } as const;

export const ACCESS_LEVEL_DISPLAY_NAMES: Record<AccessLevelEnum, string> = {
  [AccessLevelEnum.None]: 'None',
  [AccessLevelEnum.View]: 'View Only',
  [AccessLevelEnum.Edit]: 'Edit',
  [AccessLevelEnum.Admin]: 'Admin',
  [AccessLevelEnum.Disabled]: 'Disabled',
};

export const PERMISSION_DISPLAY_NAMES: Record<UserPermissionNames, string> = {
  models: 'Models',
  customers: 'Customers',
  finance: 'Finance',
  fulfillment: 'Fulfillment',
  store_management: 'Store Management',
};

export const ACCESS_LEVEL_COLOR = {
  1: 'indigo_accent.0',
  2: 'deep_purple.0',
  3: 'teal.0',
} as const;

export const LEVEL_TO_ACCESS = { None: 0, View: 1, Edit: 2, Admin: 3 } as const;

export const PAYMENT_INTERVALS = {
  monthly: 'Monthly',
  yearly: 'Yearly',
} as const;

export const PAYMENT_INTERVAL_TO_STRING = {
  monthly: 'month',
  yearly: 'year',
} as const;

export const PAYMENT_METHOD_DISPLAY_NAMES = {
  [PaymentMethodEnum.CreditCard]: 'Credit Card',
  [PaymentMethodEnum.Lab]: 'Lab',
  [PaymentMethodEnum.PurchaseOrder]: 'Purchase Order',
  [PaymentMethodEnum.ACHTransfer]: 'ACH Transfer',
} as const;

// System Issues
export const MONITORING_LICENSES_LOW = 1;
export const NO_DEVICE_MONITORING_LICENSES = 2;

// Websocket
export const WS_URL = process.env.NX_SERVER_URL
  ? process.env.NX_SERVER_URL.replace(/http|https/, 'ws') + 'cable'
  : '';
