import { Avatar, Group, Select, SelectProps, Text } from '@mantine/core';
import { filter, includes, keys, map } from 'lodash/fp';
import React, { FC, forwardRef, useMemo } from 'react';

import { CURRENCIES, CURRENCIES_FLAGS } from '@portals/countries';

import { useShopCurrency, useStoreCurrency } from '../../redux/hooks/store';

interface CurrencyOptionType extends React.ComponentPropsWithoutRef<'div'> {
  label: string;
  value: string;
}

const CurrencyOption: FC<CurrencyOptionType> = forwardRef<
  HTMLDivElement,
  CurrencyOptionType
>(({ label, ...rest }, ref) => {
  return (
    <div {...rest} ref={ref}>
      <Group spacing="xs" px="sm">
        <Avatar size="xs" radius="xl" src={CURRENCIES_FLAGS[label]} />

        <Text size="sm">{label}</Text>
      </Group>
    </div>
  );
});

interface SelectCurrencyProps {
  currency: ReturnType<typeof useStoreCurrency>;
  selectProps?: Partial<SelectProps>;
}

export function SelectCurrency({
  currency,
  selectProps = {},
}: SelectCurrencyProps) {
  const filteredCurrencies = useMemo(() => {
    if (!currency.currencies) return null;

    const filteredKeys = filter(
      (currencyKey) => includes(currencyKey, currency.currencies),
      keys(CURRENCIES)
    );

    return map(
      (key) => ({
        label: key,
        value: key,
      }),
      filteredKeys
    );
  }, [currency.currencies]);

  return (
    <Select
      sx={{ width: 100 }}
      disabled={currency.isLoading}
      data={filteredCurrencies || [{ label: 'US Dollar (US$)', value: 'USD' }]}
      icon={
        <Avatar
          src={CURRENCIES_FLAGS[currency.selected]}
          radius="xl"
          size="xs"
        />
      }
      onChange={(selectedCurrency) => {
        currency.onSelect(selectedCurrency);
      }}
      itemComponent={CurrencyOption}
      styles={{
        item: {
          padding: 0,
        },
      }}
      value={currency.selected}
      {...selectProps}
    />
  );
}

interface SelectCurrencyWrapperProps {
  selectProps?: SelectCurrencyProps['selectProps'];
}

export function SelectStoreCurrency({
  selectProps,
}: SelectCurrencyWrapperProps) {
  const currency = useStoreCurrency();

  return <SelectCurrency currency={currency} selectProps={selectProps} />;
}

export function SelectShopCurrency({
  selectProps,
}: SelectCurrencyWrapperProps) {
  const currency = useShopCurrency();

  return <SelectCurrency currency={currency} selectProps={selectProps} />;
}
