import { Box, createStyles, Group, Text } from '@mantine/core';
import React from 'react';
import 'react-contexify/dist/ReactContexify.css';

import { SpaceTreeNodeType, useSpace } from '@portals/api/organizations';

import {
  useIsNodeExpanded,
  useIsNodeSelected,
} from '../../organization-tree.context';

export interface DisabledTreeNodeProps {
  node: SpaceTreeNodeType;
  itemIdPrefix?: string;
}

export function DisabledTreeNode({
  node,
  itemIdPrefix,
}: DisabledTreeNodeProps) {
  const space = useSpace({ spaceId: node?.id });
  const { classes, cx } = useStyles();
  const isSelected = useIsNodeSelected(node.id);
  const isExpanded = useIsNodeExpanded(node.id);

  if (!space) return null;

  return (
    <Box
      id={
        itemIdPrefix
          ? `${itemIdPrefix}-tree-item-${node.id}`
          : `tree-item-${node.id}`
      }
      className={cx(classes.container, 'disabled-tree-node')}
    >
      <Box className={classes.labelContainer}>
        <Group
          align="center"
          p={0}
          spacing={10}
          className={classes.labelWrapper}
        >
          <Text
            className={cx('node-label', classes.label, {
              selected: isSelected,
              expanded: isExpanded,
            })}
            truncate
          >
            {node.title}
          </Text>
        </Group>
      </Box>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    width: '100%',
    zIndex: 1,
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: '1fr',
    boxSizing: 'border-box',
    alignItems: 'center',
    cursor: 'not-allowed',
  },
  labelContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  labelWrapper: {
    flex: 1,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%',
  },
  label: {
    color: theme.colors.gray[4],
    fontWeight: 300,
    fontSize: theme.fontSizes.sm,

    '&.expanded': {
      fontWeight: 500,
    },

    '&.selected': {
      color: theme.fn.primaryColor(),
    },
  },
  // Displayed only for root space
  avatar: {
    padding: 2,
    borderRadius: theme.radius.sm,
    marginRight: theme.spacing.xs,
    border: `1px solid ${theme.colors.gray[2]}`,
  },
}));
