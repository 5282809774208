import * as React from 'react';

import { ReactComponent as Support } from '@portals/icons/linear/24-support.svg';
import { ReactComponent as Barcode } from '@portals/icons/linear/barcode.svg';
import { ReactComponent as BatteryFull } from '@portals/icons/linear/battery-full.svg';
import { ReactComponent as Bluetooth } from '@portals/icons/linear/bluetooth.svg';
import { ReactComponent as Blur } from '@portals/icons/linear/blur.svg';
import { ReactComponent as Box } from '@portals/icons/linear/box.svg';
import { ReactComponent as Calendar } from '@portals/icons/linear/calendar.svg';
import { ReactComponent as Call } from '@portals/icons/linear/call.svg';
import { ReactComponent as Clock } from '@portals/icons/linear/clock.svg';
import { ReactComponent as CloudConnection } from '@portals/icons/linear/cloud-connection.svg';
import { ReactComponent as Code } from '@portals/icons/linear/code.svg';
import { ReactComponent as CPU } from '@portals/icons/linear/cpu.svg';
import { ReactComponent as Danger } from '@portals/icons/linear/danger.svg';
import { ReactComponent as DocumentText } from '@portals/icons/linear/document-text.svg';
import { ReactComponent as Driver } from '@portals/icons/linear/driver.svg';
import { ReactComponent as Electricity } from '@portals/icons/linear/electricity.svg';
import { ReactComponent as Element3 } from '@portals/icons/linear/element-3.svg';
import { ReactComponent as EyeSlash } from '@portals/icons/linear/eye-slash.svg';
import { ReactComponent as Eye } from '@portals/icons/linear/eye.svg';
import { ReactComponent as Flag } from '@portals/icons/linear/flag.svg';
import { ReactComponent as Flash } from '@portals/icons/linear/flash.svg';
import { ReactComponent as Glass } from '@portals/icons/linear/glass.svg';
import { ReactComponent as Global } from '@portals/icons/linear/global.svg';
import { ReactComponent as GPS } from '@portals/icons/linear/gps.svg';
import { ReactComponent as Key } from '@portals/icons/linear/key.svg';
import { ReactComponent as LampCharge } from '@portals/icons/linear/lamp-charge.svg';
import { ReactComponent as Lifebuoy } from '@portals/icons/linear/lifebuoy.svg';
import { ReactComponent as Location } from '@portals/icons/linear/location.svg';
import { ReactComponent as Lock } from '@portals/icons/linear/lock.svg';
import { ReactComponent as Map } from '@portals/icons/linear/map.svg';
import { ReactComponent as Microphone } from '@portals/icons/linear/microphone-2.svg';
import { ReactComponent as MirroringScreen } from '@portals/icons/linear/mirroring-screen.svg';
import { ReactComponent as Monitor } from '@portals/icons/linear/monitor.svg';
import { ReactComponent as Music } from '@portals/icons/linear/music.svg';
import { ReactComponent as Note2 } from '@portals/icons/linear/note-2.svg';
import { ReactComponent as Notification } from '@portals/icons/linear/notification.svg';
import { ReactComponent as PasswordCheck } from '@portals/icons/linear/password-check.svg';
import { ReactComponent as Radar2 } from '@portals/icons/linear/radar-2.svg';
import { ReactComponent as Radar } from '@portals/icons/linear/radar.svg';
import { ReactComponent as Refresh } from '@portals/icons/linear/refresh-2.svg';
import { ReactComponent as ScanBarcode } from '@portals/icons/linear/scan-barcode.svg';
import { ReactComponent as Setting } from '@portals/icons/linear/setting-2.svg';
import { ReactComponent as Share } from '@portals/icons/linear/share.svg';
import { ReactComponent as ShieldCross } from '@portals/icons/linear/shield-cross.svg';
import { ReactComponent as ShieldTick } from '@portals/icons/linear/shield-tick.svg';
import { ReactComponent as Simcard } from '@portals/icons/linear/simcard.svg';
import { ReactComponent as SmartCar } from '@portals/icons/linear/smart-car.svg';
import { ReactComponent as Status } from '@portals/icons/linear/status.svg';
import { ReactComponent as TickCircle } from '@portals/icons/linear/tick-circle.svg';
import { ReactComponent as Video } from '@portals/icons/linear/video.svg';
import { ReactComponent as VolumeCross } from '@portals/icons/linear/volume-cross.svg';
import { ReactComponent as VolumeLow } from '@portals/icons/linear/volume-low.svg';
import { ReactComponent as VolumeSlash } from '@portals/icons/linear/volume-slash.svg';
import { ReactComponent as Wifi } from '@portals/icons/linear/wifi.svg';

import { WidgetColorType, WidgetIconNameType } from './widgets.types';

export const WIDGET_ICONS: Array<{
  Icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
  iconName: WidgetIconNameType;
}> = [
  {
    Icon: BatteryFull,
    iconName: 'battery_full',
  },
  {
    Icon: Blur,
    iconName: 'blur',
  },
  {
    Icon: Box,
    iconName: 'box',
  },
  {
    Icon: Call,
    iconName: 'call',
  },
  {
    Icon: CloudConnection,
    iconName: 'cloud_connection',
  },
  {
    Icon: Driver,
    iconName: 'driver',
  },
  {
    Icon: Electricity,
    iconName: 'electricity',
  },
  {
    Icon: EyeSlash,
    iconName: 'eye_slash',
  },
  {
    Icon: Eye,
    iconName: 'eye',
  },
  {
    Icon: Flash,
    iconName: 'flash',
  },
  {
    Icon: Global,
    iconName: 'global',
  },
  {
    Icon: GPS,
    iconName: 'gpf',
  },
  {
    Icon: Key,
    iconName: 'key',
  },
  {
    Icon: Lifebuoy,
    iconName: 'lifebuoy',
  },
  {
    Icon: Location,
    iconName: 'location',
  },
  {
    Icon: Lock,
    iconName: 'lock',
  },
  {
    Icon: Map,
    iconName: 'map',
  },
  {
    Icon: Microphone,
    iconName: 'microphone',
  },
  {
    Icon: MirroringScreen,
    iconName: 'mirroring_screen',
  },
  {
    Icon: Music,
    iconName: 'music',
  },
  {
    Icon: Notification,
    iconName: 'notification',
  },
  {
    Icon: Radar2,
    iconName: 'radar_2',
  },
  {
    Icon: Radar,
    iconName: 'radar',
  },
  {
    Icon: Refresh,
    iconName: 'refresh',
  },
  {
    Icon: Setting,
    iconName: 'setting',
  },
  {
    Icon: ShieldCross,
    iconName: 'shield_cross',
  },
  {
    Icon: ShieldTick,
    iconName: 'shield_tick',
  },
  {
    Icon: SmartCar,
    iconName: 'smart_car',
  },
  {
    Icon: Status,
    iconName: 'status',
  },
  {
    Icon: Video,
    iconName: 'video',
  },
  {
    Icon: VolumeCross,
    iconName: 'volume_cross',
  },
  {
    Icon: VolumeLow,
    iconName: 'volume_low',
  },
  {
    Icon: VolumeSlash,
    iconName: 'volume_slash',
  },
  {
    Icon: LampCharge,
    iconName: 'lamp_charge',
  },
  {
    Icon: Bluetooth,
    iconName: 'bluetooth',
  },
  {
    Icon: Wifi,
    iconName: 'wifi',
  },
  {
    Icon: Note2,
    iconName: 'note-2',
  },
  {
    Icon: ScanBarcode,
    iconName: 'scan-barcode',
  },
  {
    Icon: Barcode,
    iconName: 'barcode',
  },
  {
    Icon: PasswordCheck,
    iconName: 'password-check',
  },
  {
    Icon: Element3,
    iconName: 'element-3',
  },
  {
    Icon: Glass,
    iconName: 'glass',
  },
  {
    Icon: DocumentText,
    iconName: 'document-text',
  },
  {
    Icon: Code,
    iconName: 'code',
  },
  {
    Icon: Danger,
    iconName: 'danger',
  },
  {
    Icon: TickCircle,
    iconName: 'tick-circle',
  },
  {
    Icon: Flag,
    iconName: 'flag',
  },
  {
    Icon: Share,
    iconName: 'share',
  },
  {
    Icon: Support,
    iconName: '24-support',
  },
  {
    Icon: CPU,
    iconName: 'cpu',
  },
  {
    Icon: Monitor,
    iconName: 'monitor',
  },
  {
    Icon: Clock,
    iconName: 'clock',
  },
  {
    Icon: Calendar,
    iconName: 'calendar',
  },
  {
    Icon: Simcard,
    iconName: 'simcard',
  },
];

export const WIDGET_COLORS: Array<WidgetColorType> = [
  'deep_purple_accent.2',
  'indigo_accent.3',
  'blue_accent.4',
  'purple_accent.2',
  'pink_accent.2',
  'red.4',
  'orange.6',
  'yellow.6',
  'lime_accent.4',
  'teal_accent.3',
  'cyan.4',
  'cyan_accent.4',
  'teal_accent.4',
];

export enum DeviceLocationType {
  Main = 'main',
  Child = 'child',
}
