import { MenuProps } from '@mantine/core';
import React, { useImperativeHandle, useRef, useState } from 'react';

import {
  RowMenuRefType,
  RowMenuWrapperType,
  RowType,
  SmartTableProps,
} from '@portals/types';

interface TRowMenuWrapperProps<
  TData extends object,
  TKeyField extends keyof TData
> {
  row: RowType<TData>;
  menuProps?: Omit<MenuProps, 'children'>;
  rowMenu: SmartTableProps<TData, TKeyField>['rowMenu'];
  isHovered: RowMenuWrapperType['isHovered'];
}

export function TRowMenuWrapper<
  TData extends object,
  TKeyField extends keyof TData
>({
  row,
  rowMenu,
  isHovered,
  menuProps,
}: TRowMenuWrapperProps<TData, TKeyField>) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const rowMenuRef = useRef<RowMenuRefType>();

  useImperativeHandle(
    rowMenuRef,
    () => ({
      onClose: () => setIsMenuOpen(false),
      onOpen: () => setIsMenuOpen(true),
    }),
    []
  );

  return (
    <>
      {rowMenu({
        row,
        wrapperProps: {
          menuRef: rowMenuRef?.current,
          isMenuOpen,
          setIsMenuOpen,
          isHovered,
        },
        menuProps,
      })}
    </>
  );
}
