import { Badge, createStyles } from '@mantine/core';
import React from 'react';

import { OrganizationStoreListingPricingOption } from '@portals/types';

interface ExtraPricesBadgeProps {
  pricingOptions: Array<OrganizationStoreListingPricingOption>;
}

export function ExtraPricesBadge({ pricingOptions }: ExtraPricesBadgeProps) {
  const { classes } = useStyles();

  if (pricingOptions.length === 0) {
    return null;
  }

  const getBadgeText = () => {
    let text = `${pricingOptions.length} more`;
    text += pricingOptions.length > 1 ? ' prices' : ' price';

    return text;
  };

  return (
    <Badge color="blue_gray" size="md" className={classes.badge}>
      {getBadgeText()}
    </Badge>
  );
}

const useStyles = createStyles((theme) => ({
  badge: {
    alignSelf: 'flex-end',
    fontWeight: 500,
    color: theme.colors.gray[9],
  },
}));
