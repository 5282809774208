import { GeoJSON, Point } from 'geojson';
import GoogleMapReact from 'google-map-react';
import { isEmpty, isNumber } from 'lodash/fp';
import React, { FC, useMemo, useState } from 'react';
import SuperCluster from 'supercluster';

import { MapCoordinatesType } from '@portals/types';

import {
  GoogleMapsType,
  GoogleMapType,
  MapFeature,
  SuperClusterPoint,
  SuperClusterType,
} from '../../types/maps';
import { EmptyState } from './EmptyState';
import {
  BLACK_AND_WHITE_EMPTY_STATE_STYLE,
  BLACK_AND_WHITE_STYLE,
  DEFAULT_ZOOM,
  MAX_ZOOM,
} from './map.constants';
import { Marker } from './Marker';

const createClusterMap = (
  map: GoogleMapType | null,
  bounds: [number, number, number, number],
  points: Array<SuperClusterPoint>,
  zoom: number
): [[], undefined] | [Array<MapFeature>, SuperClusterType] => {
  if (!map || !bounds) {
    return [[], undefined];
  }

  const index = new SuperCluster({
    radius: 75,
    maxZoom: MAX_ZOOM,
    reduce: (accumulated, props) => {
      accumulated.priority = Math.min(accumulated.priority, props.priority);
    },
  });

  index.load(points);

  return [index.getClusters(bounds, zoom), index];
};

const getMapBounds = (
  map: GoogleMapType,
  maps: GoogleMapsType,
  points: Array<GeoJSON.Feature<Point>>,
  base: MapCoordinatesType
) => {
  const bounds = new maps.LatLngBounds();

  points.forEach((point) => {
    bounds.extend(
      new maps.LatLng(
        point.geometry.coordinates[1],
        point.geometry.coordinates[0]
      )
    );
  });
  bounds.extend(new maps.LatLng(base.lat, base.lng));

  return bounds;
};

interface MapProps {
  points: Array<GeoJSON.Feature<Point>>;
  base: MapCoordinatesType;
  emptyStateText: string;
}

export const Map: FC<MapProps> = ({ points, base, emptyStateText }) => {
  const [map, setMap] = useState<GoogleMapType | null>(null);
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const [bounds, setBounds] = useState<[number, number, number, number]>([
    0, 0, 0, 0,
  ]);

  const handleMapLoaded: GoogleMapReact.Props['onGoogleApiLoaded'] = ({
    map: gMap,
    maps,
  }: {
    map: GoogleMapType;
    maps: GoogleMapsType;
  }) => {
    const latLngBounds = getMapBounds(gMap, maps, points, base);

    gMap.setCenter(latLngBounds.getCenter());
    gMap.fitBounds(latLngBounds);

    const soWe = gMap.getBounds()?.getSouthWest();
    const noEa = gMap.getBounds()?.getNorthEast();

    setMap(gMap);

    setBounds([
      soWe?.lng() || 0,
      soWe?.lat() || 0,
      noEa?.lng() || 0,
      noEa?.lat() || 0,
    ]);
  };

  const [clusters, superCluster] = useMemo(
    () => createClusterMap(map, bounds, points, zoom),
    [map, points, bounds, zoom]
  );

  const handleMapMove = (mapData: GoogleMapReact.ChangeEventValue): void => {
    const newZoom = mapData.zoom;
    const newBounds = mapData.bounds;

    setZoom(newZoom);
    setBounds([
      newBounds.nw.lng,
      newBounds.se.lat,
      newBounds.se.lng,
      newBounds.nw.lat,
    ]);
  };

  const zoomToCluster = (cluster: MapFeature) => {
    const expansionZoom =
      superCluster && isNumber(cluster.id)
        ? Math.min(superCluster.getClusterExpansionZoom(cluster.id), MAX_ZOOM)
        : DEFAULT_ZOOM;

    map?.setZoom(expansionZoom);
    map?.panTo({
      lat: cluster.geometry.coordinates[1],
      lng: cluster.geometry.coordinates[0],
    });
  };

  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: process.env.NX_GOOGLE_KEY || 'MISSING_GOOGLE_MAPS_API_KEY',
      }}
      defaultZoom={DEFAULT_ZOOM}
      center={base}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={handleMapLoaded}
      onChange={handleMapMove}
      options={{
        maxZoom: MAX_ZOOM,
        styles: isEmpty(points)
          ? BLACK_AND_WHITE_EMPTY_STATE_STYLE
          : BLACK_AND_WHITE_STYLE,
      }}
    >
      {isEmpty(points) && clusters && isEmpty(clusters) ? (
        <EmptyState label={emptyStateText} />
      ) : (
        clusters.map((cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          return (
            <Marker
              key={`${longitude}-${latitude}`}
              item={cluster}
              superCluster={superCluster}
              zoomToCluster={zoomToCluster}
              zoom={zoom}
              lat={latitude}
              lng={longitude}
              onListScroll={(gestureHandling: 'none' | 'greedy') => {
                map?.setOptions({ gestureHandling });
              }}
            />
          );
        })
      )}
    </GoogleMapReact>
  );
};
