import { Group, Stack, Text } from '@mantine/core';
import React from 'react';

import { CurrencyCode } from '@portals/types';
import { formatPrice } from '@portals/utils';

interface UsageBasedPriceProps {
  usageBasedPrice: number | undefined;
  basePrice: number | undefined;
  currencyCode: CurrencyCode;
  unitName: string;
}

export function UsageBasedPrice({
  usageBasedPrice,
  basePrice,
  currencyCode,
  unitName,
}: UsageBasedPriceProps) {
  return (
    <Stack spacing="xs">
      <Group spacing="0.5ch" fz="xl" fw={700}>
        {basePrice ? (
          <Group spacing="0.5ch" noWrap>
            <Text>{formatPrice({ value: basePrice, currencyCode })}</Text>
            <Text color="gray.4">+</Text>
          </Group>
        ) : null}

        <Group spacing="0.5ch" noWrap>
          <Text>{formatPrice({ value: usageBasedPrice, currencyCode })}</Text>
          <Text color="gray.5">/</Text>
          <Text color="gray.5">{unitName}</Text>
        </Group>
      </Group>

      <Text weight={600} size="md" color="gray.5">
        Billed monthly
      </Text>
    </Stack>
  );
}
