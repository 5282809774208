import { CloseButton, createStyles, Group, Stack, Text } from '@mantine/core';
import React from 'react';
import { useDispatch } from 'react-redux';

import { QuantityInput } from '@portals/core';
import { ProductImage } from '@portals/framework';
import {
  CurrencyCode,
  OrderPreviewItemType,
  OrganizationStoreListing,
} from '@portals/types';

import { OrderRowPriceDetails } from './OrderRowPriceDetails';
import {
  removeProductFromCart,
  setProductQuantity,
} from '../../../../../../redux/actions/store';

interface OrderRowProps {
  orderPreview: OrderPreviewItemType;
  storeListing: OrganizationStoreListing;
  currency: CurrencyCode;
  isPreview?: boolean;
}

export function OrderRow({
  isPreview = false,
  orderPreview,
  storeListing,
  currency,
}: OrderRowProps) {
  const dispatch = useDispatch();

  const { product, id } = storeListing;
  const { classes } = useStyles(isPreview);
  const { selected_payment_interval } = orderPreview;

  const onQuantityChange = (quantity: number) => {
    dispatch(
      setProductQuantity({
        quantity,
        id,
        period: selected_payment_interval,
      })
    );
  };

  const onRemoveFromCart = () =>
    dispatch(
      removeProductFromCart({
        id,
        period: selected_payment_interval,
      })
    );

  return (
    <div className={classes.container}>
      <ProductImage
        src={product.image_url}
        width={isPreview ? 48 : 64}
        className={classes.imageWrapper}
      />

      <Stack className={classes.detailsWrapper}>
        <Group className={classes.nameAndPrice}>
          <Text className={classes.productName}>{product.name}</Text>

          <OrderRowPriceDetails
            orderPreview={orderPreview}
            storeListing={storeListing}
            currency={currency}
          />
        </Group>
        <QuantityInput
          min={0}
          value={orderPreview.quantity}
          onChange={onQuantityChange}
        />
      </Stack>

      {isPreview ? null : <CloseButton size="md" onClick={onRemoveFromCart} />}
    </div>
  );
}

const useStyles = createStyles((theme, isPreview: boolean) => ({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing.md,
    paddingBlock: theme.spacing.md,

    '&:not(:last-of-type)': {
      borderBottom: isPreview ? 'none' : `1px solid ${theme.colors.gray[2]}`,
    },
  },
  nameAndPrice: {
    justifyContent: 'space-between',

    [theme.fn.smallerThan('lg')]: {
      flexDirection: 'column-reverse',
      alignItems: 'flex-start',
      gap: theme.spacing.xs,
    },
  },
  productName: {
    fontSize: theme.fontSizes.sm,
    color: theme.colors.blue_gray[9],
    fontWeight: 600,
    wordBreak: 'break-word',
  },
  imageWrapper: {
    overflow: 'hidden',
    border: `1px solid ${theme.colors.gray[1]}`,
    borderRadius: theme.radius.md,
  },
  detailsWrapper: {
    flexGrow: 1,
  },
}));
