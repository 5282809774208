import { Stack } from '@mantine/core';
import { size, uniqBy } from 'lodash/fp';
import React, { useCallback, useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';

import { DeviceModelType } from '@portals/api/organizations';
import { useIsPending } from '@portals/redux';
import { EmptyState } from '@portals/table';

import { FORMS_MAP } from './add-device-form.constants';
import { useClaimableDevicesModels } from '../add-device-modal.hooks';
import { FormsMap, OnSubmitFormParams } from '../add-device-modal.types';

interface AddDeviceFormProps {
  handleClose: () => void;
  onSubmit: (params: OnSubmitFormParams) => void;
}

export function AddDeviceForm({ handleClose, onSubmit }: AddDeviceFormProps) {
  const claimableDevices = useClaimableDevicesModels();
  const isPending = useIsPending('addDevice');

  const [selectedPartnerId, setSelectedPartnerId] = useState('');
  const [activeForm, setActiveForm] = useState<keyof FormsMap>('vendor');

  const currentForm = FORMS_MAP[activeForm];

  const handleSubmit = (params: OnSubmitFormParams) => {
    onSubmit(params);
  };

  const setActiveFormByName = (name: string | undefined) => {
    switch (name) {
      case 'zoom':
        setActiveForm('zoom');
        break;

      default:
        setActiveForm('byModel');
    }
  };

  const setFormToRender = useCallback(() => {
    if (!selectedPartnerId) {
      return;
    }

    const modelInfo = getModelInfoByPartnerId(
      claimableDevices,
      selectedPartnerId
    );

    setActiveFormByName(modelInfo?.partner.name);
  }, [claimableDevices, selectedPartnerId]);

  useEffect(() => {
    setFormToRender();
  }, [setFormToRender, selectedPartnerId]);

  const partnerNames = claimableDevices.map((claimableDevice) => ({
    name: claimableDevice.partner.name,
    id: claimableDevice.partner.id,
  }));
  const uniquePartnerNames = uniqBy('name', partnerNames);

  useEffectOnce(() => {
    if (size(uniquePartnerNames) > 1) {
      setActiveForm('vendor');
    } else if (size(uniquePartnerNames) === 1) {
      setActiveFormByName(uniquePartnerNames[0]?.name);
      setSelectedPartnerId(uniquePartnerNames[0]?.id);
    }
  });

  if (!size(uniquePartnerNames)) {
    return (
      <Stack p={30}>
        <EmptyState label="No available models" />
      </Stack>
    );
  }

  return (
    <>
      {currentForm({
        selectedPartnerId,
        setSelectedPartnerId,
        handleClose,
        handleSubmit,
        isSubmitDisabled: isPending,
      })}
    </>
  );
}

function getModelInfoByPartnerId(
  deviceModels: DeviceModelType[],
  partnerId: string
) {
  return deviceModels.find((device) => device.partner.id === partnerId);
}
