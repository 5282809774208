import { ActionIcon, Box, createStyles, Menu } from '@mantine/core';
import React from 'react';

import { SearchInput } from '@portals/core';
import { ReactComponent as Collapse } from '@portals/icons/linear/collapse.svg';
import { ReactComponent as Expand } from '@portals/icons/linear/expand.svg';
import { ReactComponent as Maximize1 } from '@portals/icons/linear/maximize-1.svg';
import { ReactComponent as More } from '@portals/icons/linear/more.svg';

interface TreeHeaderProps {
  handleSearchOnChange: (value: string) => void;
  searchString: string;
  onExpandAll: () => void;
  onCollapseAll: () => void;
  onJumpToSelected: () => void;
  isReadonly: boolean;
}

export function TreeHeader({
  handleSearchOnChange,
  searchString,
  onExpandAll,
  onCollapseAll,
  onJumpToSelected,
  isReadonly,
}: TreeHeaderProps) {
  const { classes } = useStyles({ isReadonly });

  return (
    <Box className={classes.container} px="md">
      <SearchInput
        value={searchString}
        placeholder="Find space..."
        onClear={() => handleSearchOnChange('')}
        onChange={(event) => handleSearchOnChange(event.target.value)}
        size="md"
        classNames={{
          root: classes.inputRoot,
          input: classes.input,
        }}
      />

      {isReadonly ? null : (
        <Menu
          position="bottom-end"
          classNames={{
            dropdown: classes.menuDropdown,
            itemIcon: classes.menuItemIcon,
          }}
        >
          <Menu.Target>
            <ActionIcon color="blue_gray.4">
              <More />
            </ActionIcon>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item onClick={onExpandAll} icon={<Expand />}>
              Expand all
            </Menu.Item>

            <Menu.Item onClick={onCollapseAll} icon={<Collapse />}>
              Collapse all
            </Menu.Item>

            <Menu.Item onClick={onJumpToSelected} icon={<Maximize1 />}>
              Jump to selected
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      )}
    </Box>
  );
}

const useStyles = createStyles(
  (theme, { isReadonly }: { isReadonly: boolean }) => ({
    container: {
      display: 'grid',
      gridTemplateColumns: isReadonly ? '1fr' : '1fr min-content',
      alignItems: 'center',
      gap: theme.spacing.md,
    },
    inputRoot: {
      height: 40,
      width: '100% !important',
    },
    input: {
      minHeight: 40,
      height: 40,
      borderColor: theme.colors.gray[3],
    },
    menuDropdown: {
      boxShadow: '0px 4px 10px rgba(38, 50, 56, 0.13)',
    },
    menuItemIcon: {
      width: 16,
      height: 16,
      marginRight: theme.spacing.sm,
    },
  })
);
