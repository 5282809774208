import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

import { PaginationResponse, UsePaginatedTableApiQuery } from '@portals/types';
import { buildUrlFromTableState } from '@portals/utils';

import { fetchApiRequest, useRequestOptions } from './common';

export function usePaginatedTableApiQuery<TData extends object>({
  baseUrl,
  columns,
  tableState,
  queryKey,
  queryOptions = {},
}: UsePaginatedTableApiQuery<TData>) {
  // `useRequestOptions` is used to add:
  // 1. Host url
  // 2. Headers, based on auth status
  const { url, options } = useRequestOptions({ url: baseUrl });

  const queryFn = useCallback(() => {
    // Search params are based on `react-table`'s state of filters: sortBy, filters, ...
    // Returns `{server_url}{request_url}?filter=value&sort_by=value&...`
    const requestUrl = buildUrlFromTableState({
      url,
      tableState,
      columns,
    });

    return fetchApiRequest(requestUrl, options);
  }, [columns, options, tableState, url]);

  // `queryKey` is set to `[baseUrl, tableState]` by default to tell react-query when data needs
  // re-fetching
  // `tableState` changes when tables' filters change, meaning we need to entirely
  // replace previous data [opposed to only fetching next page, that keeps the data as is & only
  // adds new rows]
  return useQuery<
    typeof fetchApiRequest,
    { error: string },
    PaginationResponse<TData>
  >(queryKey || [baseUrl, tableState], queryFn, {
    keepPreviousData: true,
    meta: {
      baseUrl: url,
      method: options?.method || 'GET',
    },
    ...queryOptions,
  });
}
