import PropTypes from 'prop-types';
import React from 'react';

const XioConfig = ({ device }) => (
  <div>
    <p>This device can only be configured from the XiO portal.</p>

    <a
      href={`https://portal.crestron.io/customer/(list:groups//details:device/${device.details['device-cid']}/settings)`}
      rel="noopener noreferrer"
      target="_blank"
    >
      Access XiO portal for this device
    </a>
  </div>
);

XioConfig.propTypes = {
  device: PropTypes.object.isRequired,
};

export default XioConfig;
