import { Stack } from '@mantine/core';
import { find } from 'lodash/fp';
import React from 'react';

import { useShop } from '@portals/api/organizations';

import { OrderRow } from './OrderRow';
import { useCartProductsList } from '../../../../redux/hooks/store';

export const OrderItemsPreview = () => {
  const cartProductsList = useCartProductsList();
  const shop = useShop();

  return (
    <Stack spacing={0}>
      {cartProductsList.map((cartItem) => {
        const storeListing = find({ id: cartItem.id }, shop.data?.products);

        if (!storeListing) return null;

        return (
          <OrderRow
            key={`${cartItem.id}-${cartItem.period}`}
            storeListing={storeListing}
            period={cartItem.period}
            quantity={cartItem.quantity}
          />
        );
      })}
    </Stack>
  );
};
