import {
  Anchor,
  Box,
  createStyles,
  Divider,
  Group,
  Stack,
  Text,
} from '@mantine/core';
import dayjs from 'dayjs';
import React from 'react';

import { ReactComponent as QuestionCircle } from '@portals/icons/linear/question-circle.svg';
import {
  CurrencyCode,
  InvoiceItem,
  PaymentIntervalEnum,
  PaymentMethodType,
} from '@portals/types';
import { formatCurrency } from '@portals/utils';

import { InvoiceItemAmountPreviewTooltip } from './preview-tooltip/InvoiceItemAmountPreviewTooltip';
import { PurchasedProductPreviewTooltip } from './preview-tooltip/PurchasedProductPreviewTooltip';
import { SubscriptionPreviewTooltip } from './preview-tooltip/SubscriptionPreviewTooltip';
import { ProductImage } from '../ProductImage';
import { SubscriptionStatusBadge } from '../SubscriptionStatusBadge';

interface InvoiceItemRowProps {
  invoiceItem: InvoiceItem;
  currencyCode: CurrencyCode;
  paymentMethod: PaymentMethodType;
  orderId: string;
}

export function InvoiceItemRow({
  invoiceItem,
  currencyCode,
  paymentMethod,
  orderId,
}: InvoiceItemRowProps) {
  const isItemIdEmpty =
    !invoiceItem.purchased_product_id && !invoiceItem.subscription_id;

  const { classes, theme } = useStyles({
    isPurchasesItem:
      invoiceItem.payment_interval === PaymentIntervalEnum.OneTime,
    withoutLink: isItemIdEmpty,
  });

  const getPeriodDate = (date: string) => {
    return dayjs(date).format('MMMM D');
  };

  const getInterval = (paymentInterval: PaymentIntervalEnum) => {
    if (paymentInterval === 'monthly') return ' / month';
    else if (paymentInterval === 'yearly') return ' / year';

    return '';
  };

  const getItemUrl = () => {
    if (isItemIdEmpty) {
      return null;
    }

    if (invoiceItem.payment_interval === PaymentIntervalEnum.OneTime) {
      return `/p/purchased_product/${invoiceItem.purchased_product_id}`;
    } else {
      return `/p/subscription/${invoiceItem.subscription_id}`;
    }
  };

  return (
    <Stack key={invoiceItem.id}>
      <Box className={classes.rowItem}>
        {invoiceItem.payment_interval === PaymentIntervalEnum.OneTime ? (
          <PurchasedProductPreviewTooltip
            invoiceItem={invoiceItem}
            isItemIdEmpty={isItemIdEmpty}
            paymentMethod={paymentMethod}
            orderId={orderId}
          >
            <Group noWrap spacing="xl" className={classes.textOverflow}>
              <ProductImage
                src={invoiceItem.product_image_url}
                width={48}
                className={classes.imageWrapper}
              />

              <Stack
                h="100%"
                spacing="xs"
                className={classes.textOverflow}
                justify="center"
              >
                <Anchor
                  weight={600}
                  color={isItemIdEmpty && 'gray.8'}
                  href={getItemUrl()}
                  target="_blank"
                  className={classes.viewLink}
                  truncate
                >
                  {invoiceItem.product_name}
                </Anchor>
              </Stack>
            </Group>
          </PurchasedProductPreviewTooltip>
        ) : (
          <SubscriptionPreviewTooltip
            invoiceItem={invoiceItem}
            isItemIdEmpty={isItemIdEmpty}
            paymentMethod={paymentMethod}
            orderId={orderId}
          >
            <Group noWrap spacing="xl" className={classes.textOverflow}>
              <ProductImage
                src={invoiceItem.product_image_url}
                width={48}
                className={classes.imageWrapper}
              />

              <Stack
                h="100%"
                spacing="xs"
                className={classes.textOverflow}
                justify="center"
              >
                <Anchor
                  weight={600}
                  color={isItemIdEmpty && 'gray.8'}
                  href={getItemUrl()}
                  target="_blank"
                  className={classes.viewLink}
                  truncate
                >
                  {invoiceItem.product_name}
                </Anchor>

                <SubscriptionStatusBadge
                  status={invoiceItem.subscription_status}
                />
              </Stack>
            </Group>
          </SubscriptionPreviewTooltip>
        )}

        {invoiceItem.payment_interval === PaymentIntervalEnum.OneTime ? (
          <Stack spacing={5} justify="space-around">
            <Text weight={600}>Price</Text>
            <Text color="gray.5">
              {formatCurrency(invoiceItem.price_in_scu, currencyCode)}
            </Text>
          </Stack>
        ) : (
          <>
            <Stack spacing={5} justify="space-around">
              <Text weight={600}>Rate</Text>

              <Text color="gray.5">
                {invoiceItem.usage_based?.base_price_in_scu ? (
                  <>
                    {formatCurrency(
                      invoiceItem.usage_based.base_price_in_scu,
                      currencyCode
                    )}{' '}
                    / month +{' '}
                  </>
                ) : null}
                {invoiceItem.usage_based ? (
                  <>
                    {formatCurrency(
                      invoiceItem.usage_based.usage_price_in_scu,
                      currencyCode
                    )}{' '}
                    / {invoiceItem.usage_based.usage_unit_name}
                  </>
                ) : (
                  <>
                    {formatCurrency(invoiceItem.price_in_scu, currencyCode)}
                    {getInterval(invoiceItem.payment_interval)}
                  </>
                )}
              </Text>
            </Stack>

            <Stack spacing={5} justify="space-around">
              <Text weight={600}>Period</Text>
              {invoiceItem.paid_period.to && (
                <Text color="gray.5">
                  {getPeriodDate(invoiceItem.paid_period.from)} -{' '}
                  {getPeriodDate(invoiceItem.paid_period.to)}
                </Text>
              )}
            </Stack>
          </>
        )}

        <Stack spacing={5} justify="space-around">
          <Group spacing="xs" noWrap>
            <Text weight={600}>Charged amount</Text>
            <InvoiceItemAmountPreviewTooltip
              item={invoiceItem}
              currency={currencyCode}
            >
              <QuestionCircle
                width={16}
                height={16}
                color={theme.colors.gray[5]}
              />
            </InvoiceItemAmountPreviewTooltip>
          </Group>
          <Text color="gray.5">
            {formatCurrency(invoiceItem.prorated_price_in_scu, currencyCode)}
          </Text>
        </Stack>
      </Box>
      <Divider />
    </Stack>
  );
}

const useStyles = createStyles(
  (
    theme,
    {
      isPurchasesItem,
      withoutLink,
    }: { isPurchasesItem: boolean; withoutLink: boolean }
  ) => ({
    textOverflow: {
      overflow: 'hidden',
    },

    rowItem: {
      display: 'grid',
      gap: theme.spacing.xl,
      gridTemplateColumns: isPurchasesItem ? '2fr 1fr 1fr' : 'repeat(4, 1fr)',
    },

    imageWrapper: {
      overflow: 'hidden',
      border: `1px solid ${theme.colors.gray[1]}`,
      borderRadius: theme.radius.lg,
    },

    viewLink: {
      '&:hover': {
        textDecoration: 'none',
        color: withoutLink ? theme.colors.gray[8] : theme.colors.blue_accent[4],
      },
    },
  })
);
