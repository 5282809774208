import { Input, useMantineTheme } from '@mantine/core';
import * as JsSearch from 'js-search';
import { get, keys, min, size } from 'lodash/fp';
import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Select, {
  MenuListComponentProps,
  StylesConfig,
  Theme,
} from 'react-select';
import { useEffectOnce } from 'react-use';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';

import { FieldRendererProps } from '@portals/types';
import { hexToRgba } from '@portals/utils';

const Row: FC<ListChildComponentProps> = ({ index, data, style }) => {
  const listItem = get(['children', index], data);

  if (!listItem) {
    return null;
  }

  return <div style={style}>{listItem}</div>;
};

type OptionType = { label: string; value: string | number };

const MenuList = (
  props: MenuListComponentProps<OptionType, false> & {
    children: Array<ReactNode>;
  }
) => {
  const listHeight = useMemo(
    () => min([size(props.children) * 40, 300]),
    [props.children]
  );

  if (!size(props.options)) {
    return (
      <div className="d-flex align-items-center p-3 justify-content-center font-size-lg text-muted">
        No options available
      </div>
    );
  }

  return (
    <List
      height={listHeight}
      itemCount={size(props.children)}
      itemSize={35}
      itemData={props}
      width="100%"
    >
      {Row}
    </List>
  );
};

const VirtualizedSelectField: FC<FieldRendererProps> = ({
  field,
  value,
  error,
  readOnly,
  required,
  setFieldValue,
  disabled,
  className,
}) => {
  const theme = useMantineTheme();
  const { name, options, inputProps } = field;

  const [searchTerm, setSearchTerm] = useState('');
  const searchIndex = useRef(undefined);

  const parsedOptions = useMemo(
    () =>
      keys(options).map((key) => ({
        value: key,
        label: options[key],
      })),
    [options]
  );
  const parsedValue = useMemo(
    () => (value ? { value: value, label: options[value] } : null),
    [options, value]
  );

  useEffectOnce(function initSearchIndex() {
    if (!searchIndex.current) {
      searchIndex.current = new JsSearch.Search('value');
      searchIndex.current.indexStrategy =
        new JsSearch.AllSubstringsIndexStrategy();

      searchIndex.current.addIndex('label');
    }
  });

  useEffect(
    function setSearchDocuments() {
      searchIndex.current.addDocuments(parsedOptions);
    },
    [parsedOptions]
  );

  const filteredOptions = useMemo(() => {
    if (!searchTerm) return parsedOptions;

    return searchIndex.current.search(searchTerm);
  }, [parsedOptions, searchTerm]);

  const styles = useMemo<StylesConfig<OptionType, false>>(
    () => ({
      control: (base) => ({
        ...base,
        minHeight: 45,
        height: 45,
      }),
      placeholder: (base) => ({
        ...base,
        top: 'unset',
        transform: 'unset',
        height: 45,
        display: 'flex',
        alignItems: 'center',
      }),
      valueContainer: (base) => ({
        ...base,
        height: 45,
        padding: '0 0.7rem',
      }),
      input: (base) => ({
        ...base,
        paddingTop: 0,
        paddingBottom: 0,
        margin: 0,
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      indicatorsContainer: (base) => ({
        ...base,
        height: 45,
      }),
      menuPortal: (base) => ({
        ...base,
        zIndex: 2,
      }),
    }),
    []
  );

  const getSelectTheme = useCallback<(theme: Theme) => Theme>(
    ({ colors, spacing, ...rest }) => {
      return {
        ...rest,
        spacing: {
          ...spacing,
          controlHeight: 45,
        },
        colors: {
          ...colors,
          primary: theme.colors.primary[4],
          primary75: hexToRgba(theme.colors.primary[4], 75),
          primary50: hexToRgba(theme.colors.primary[4], 50),
          primary25: hexToRgba(theme.colors.primary[4], 25),
        },
      };
    },
    [theme]
  );

  return (
    <Input.Wrapper
      label={field.title}
      error={error}
      required={required}
      className={className}
    >
      <Select
        styles={styles}
        data-testid={'virtualized-select-' + name}
        theme={getSelectTheme}
        components={{ MenuList }}
        className="virtualized-select"
        classNamePrefix="virtualized-select"
        inputValue={searchTerm}
        onInputChange={(searchTerm) => setSearchTerm(searchTerm)}
        filterOption={() => true}
        placeholder={field?.placeholder || 'Select...'}
        options={filteredOptions}
        value={parsedValue}
        captureMenuScroll={false}
        onChange={(option) => setFieldValue(name, option.value)}
        isDisabled={disabled || readOnly}
        {...inputProps}
      />
    </Input.Wrapper>
  );
};

export default VirtualizedSelectField;
