import { Button, createStyles, Group, Text } from '@mantine/core';
import React from 'react';

import { useDismissOnboardingTours } from '@portals/api/organizations';
import { ModalCenteredMediaLayout } from '@portals/core';
import { ModalProps } from '@portals/framework';

import setupInUserSettingsSrc from '../../assets/img/setup-in-user-settings.svg';

export interface TourCompletedModalProps extends ModalProps {}

export function DismissSetupGuideModal({ closeMe }: TourCompletedModalProps) {
  const { classes } = useStyles();
  const dismissOnboardingTours = useDismissOnboardingTours();

  const onDismissClick = () => {
    dismissOnboardingTours.mutate();
    closeMe();
  };

  return (
    <ModalCenteredMediaLayout
      opened
      onClose={closeMe}
      withCloseButton={false}
      media={<img src={setupInUserSettingsSrc} />}
      mediaSize="100%"
      title="Dismiss Setup guide?"
      classNames={{ header: classes.modalHeader }}
      description={
        <Text px="xxl">
          Are you sure you want to dismiss the set-up guide? Upon confirmation,
          it will no longer be available in the navigation menu. You will then
          find it in the <b>User Menu</b> at the top right corner of your
          screen.
        </Text>
      }
      footer={
        <Group grow w="100%">
          <Button variant="default" onClick={closeMe} size="md">
            Cancel
          </Button>

          <Button onClick={onDismissClick} size="md">
            Dismiss Setup Guide
          </Button>
        </Group>
      }
    />
  );
}

const useStyles = createStyles((theme) => ({
  modalHeader: {
    padding: 0,
  },
}));
