import React, { createContext, ReactNode, useContext, useState } from 'react';

interface DetailsPanelScrollContextType {
  isScrolled: boolean;
  setIsScrolled: (isScrolled: boolean) => void;
  isScrollable: boolean;
  setIsScrollable: (isScrollable: boolean) => void;
  isScrollUiChangesEnabled: boolean;
}

const DetailsPanelScrollContext =
  createContext<DetailsPanelScrollContextType | null>(null);

interface DetailsPanelScrollContextProviderProps {
  children?: ReactNode;
  enableScrollUiChanges: boolean;
}

export function DetailsPanelScrollContextProvider({
  children,
  enableScrollUiChanges,
}: DetailsPanelScrollContextProviderProps) {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isScrollable, setIsScrollable] = useState(false);

  return (
    <DetailsPanelScrollContext.Provider
      value={{
        isScrolled,
        setIsScrolled,
        isScrollable,
        setIsScrollable,
        isScrollUiChangesEnabled: enableScrollUiChanges,
      }}
    >
      {children}
    </DetailsPanelScrollContext.Provider>
  );
}

export function useDetailsPanelScrollContext() {
  const context = useContext(DetailsPanelScrollContext);

  if (context === null) {
    throw new Error(
      'useDetailsPanelScrollContext must be used within a DetailsPanelScrollContextProvider'
    );
  }

  return context;
}
