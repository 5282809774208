import { createStyles, Group, GroupProps } from '@mantine/core';
import classNames from 'classnames';
import React from 'react';

interface ModalFooterProps extends GroupProps {}

export function ModalFooter({
  children,
  className,
  ...groupProps
}: ModalFooterProps) {
  const { classes } = useStyles();

  return (
    <Group className={classNames(classes.container, className)} {...groupProps}>
      {children}
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    paddingTop: theme.spacing.md,

    [theme.fn.smallerThan('md')]: {
      '> *': {
        flexGrow: 1,
      },
    },
  },
}));
