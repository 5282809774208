import { Box, createStyles } from '@mantine/core';
import React, { useMemo, useState } from 'react';

import { RouteModal } from '@portals/framework/route-modals';

import { CATEGORIES } from './integrations.constants';
import { useIncidentIntegrations } from './integrations.hooks';
import { IntegrationCategoryEnum } from './integrations.types';
import {
  filterIntegrationsByCategory,
  filterIntegrationsBySearchTerm,
  setIntegrationsWithEnabledCategory,
} from './integrations.utils';
import { IntegrationsListWrapper } from './IntegrationsListWrapper';
import { IntegrationsSidebar } from './IntegrationsSidebar';

export function IntegrationsRouteModal() {
  const { classes } = useStyles();

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(CATEGORIES[0]);

  const incidentIntegrations = useIncidentIntegrations();

  const filteredIntegrations = useMemo(() => {
    if (!searchTerm) {
      return setIntegrationsWithEnabledCategory(incidentIntegrations);
    }

    const loweredCaseSearchTerm = searchTerm.toLowerCase();

    return filterIntegrationsBySearchTerm({
      integrations: incidentIntegrations,
      searchTerm: loweredCaseSearchTerm,
    });
  }, [incidentIntegrations, searchTerm]);

  const selectedIntegrationsCategory = useMemo(() => {
    if (selectedCategory.id === IntegrationCategoryEnum.Ticketing) {
      return filterIntegrationsByCategory({
        integrations: filteredIntegrations,
        selectedCategory: IntegrationCategoryEnum.Ticketing,
      });
    }

    if (selectedCategory.id === IntegrationCategoryEnum.Messaging) {
      return filterIntegrationsByCategory({
        integrations: filteredIntegrations,
        selectedCategory: IntegrationCategoryEnum.Messaging,
      });
    }

    return filteredIntegrations;
  }, [filteredIntegrations, selectedCategory.id]);

  return (
    <RouteModal modalId="integrations">
      <Box className={classes.container}>
        <Box className={classes.header}>Integrations</Box>

        <Box className={classes.content}>
          <IntegrationsSidebar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            categories={CATEGORIES.filter(
              (category) => category.id !== IntegrationCategoryEnum.Enabled
            )}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />

          <Box className={classes.integrationsListContainer}>
            <IntegrationsListWrapper
              integrations={selectedIntegrationsCategory}
            />
          </Box>
        </Box>
      </Box>
    </RouteModal>
  );
}

const useStyles = createStyles((theme) => ({
  integrationsListContainer: {
    height: '100%',
    overflow: 'auto',
    paddingBlock: theme.spacing.xl,
    paddingInline: theme.spacing.xxl,
    backgroundColor: theme.colors.gray[0],
  },

  container: {
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
  },

  header: {
    padding: theme.spacing.xl,
    fontSize: theme.fontSizes.xl,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },

  content: {
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '237px 1fr',
    overflow: 'hidden',
  },
}));
