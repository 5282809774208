import {
  Box,
  LoadingOverlay,
  Overlay,
  SimpleGrid,
  Stack,
  Switch,
} from '@mantine/core';
import { join, set, toNumber } from 'lodash/fp';
import React from 'react';
import { Navigate, useParams } from 'react-router-dom';

import {
  SpaceDetailsType,
  useConfig,
  usePortalCapabilities,
  usePricingPlanFeatures,
  useSpaceDetails,
  useUpdateRoutingConfig,
} from '@portals/api/organizations';
import { useIntegrations } from '@portals/redux';
import { VerticalScrollBar } from '@portals/scrollbar';

import { INCIDENT_ROUTING_SCHEMA } from './incident-routing.constants';
import ServiceCard from './ServiceCard';
import { OVERVIEW_SETTINGS_TABS } from '../../settings-drawer.constants';
import { OverviewSettingsTabEnum } from '../../settings-drawer.types';
import { SettingsTabContainer } from '../tabs.common';

export function IncidentRoutingWrapper() {
  const params = useParams<{ spaceId?: string }>();
  const space = useSpaceDetails(toNumber(params.spaceId));

  if (!space.isFetched) {
    return <LoadingOverlay visible />;
  }

  return <IncidentRouting space={space.data} />;
}

interface IncidentRoutingProps {
  space: SpaceDetailsType;
}

export function IncidentRouting({ space }: IncidentRoutingProps) {
  const integrations = useIntegrations();
  const config = useConfig();
  const portalCapabilities = usePortalCapabilities();
  const pricingPlanFeatures = usePricingPlanFeatures();
  const updateIncidentRoutingConfig = useUpdateRoutingConfig();

  const isTrial = config.data?.pricing_plan?.name === 'trial';

  const incidentRoutingConfig = space?.incident_routing_config;

  const isRoot = !space?.parent_id;
  const isInherited = isRoot
    ? false
    : incidentRoutingConfig?.inherit_tree ?? true;

  const onToggleSubEvent = async (
    mainEventId: string,
    subEventId: string,
    isToggled: boolean
  ) => {
    const updatedConfig = set(
      ['tree', mainEventId, subEventId],
      isToggled,
      incidentRoutingConfig
    );

    try {
      await updateIncidentRoutingConfig.mutateAsync({
        spaceId: space.id,
        incidentRoutingConfig: updatedConfig,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const onSubEventSubmit = async (
    parentId: string,
    serviceId: string,
    updatedData: Record<string, any>
  ) => {
    const updatedConfig = set(
      [`${parentId}.${serviceId}`],
      updatedData,
      incidentRoutingConfig
    );

    try {
      await updateIncidentRoutingConfig.mutateAsync({
        spaceId: space.id,
        incidentRoutingConfig: updatedConfig,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const onToggleMainInheritance = async (isInherit: boolean) => {
    const updatedConfig = set('inherit_tree', isInherit, incidentRoutingConfig);

    try {
      await updateIncidentRoutingConfig.mutateAsync({
        spaceId: space.id,
        incidentRoutingConfig: updatedConfig,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const onToggleSubInheritance = async (
    parentId: string,
    serviceId: string,
    isInherit: boolean
  ) => {
    const eventDataPath = join('.', [parentId, serviceId]);
    const updatedConfig = set(
      ['inherit_' + eventDataPath],
      isInherit,
      incidentRoutingConfig
    );

    try {
      await updateIncidentRoutingConfig.mutateAsync({
        spaceId: space.id,
        incidentRoutingConfig: updatedConfig,
      });
    } catch (err) {
      console.error(err);
    }
  };

  if (!portalCapabilities?.paid_plans)
    return <Navigate to={`/overview/${space.id}/settings/space`} replace />;

  if (!integrations) {
    return <LoadingOverlay visible />;
  }

  return (
    <SettingsTabContainer
      tab={OVERVIEW_SETTINGS_TABS[OverviewSettingsTabEnum.IncidentRouting]}
    >
      <VerticalScrollBar
        renderView={(props) => <Stack {...props} p={42} spacing="xl" />}
      >
        {pricingPlanFeatures?.incident_routing === 0 ? (
          <Overlay zIndex={1000} opacity={0.5} />
        ) : null}

        {!isRoot && (
          <Switch
            size="xs"
            id="main_inheritance"
            name="main_inheritance"
            label="Inherit settings"
            checked={isInherited}
            onChange={(e) => onToggleMainInheritance(e.target.checked)}
          />
        )}

        <SimpleGrid cols={2} spacing="xl" sx={{ rowGap: 70 }}>
          {INCIDENT_ROUTING_SCHEMA.map((section) =>
            section.children.map((props) => (
              <ServiceCard
                key={`${section.id}-${props.id}`}
                parentId={section.id}
                disabled={
                  !pricingPlanFeatures?.premium_integrations && !isTrial
                }
                {...props}
                isRoot={isRoot}
                isInherited={isInherited}
                onSubEventSubmit={onSubEventSubmit}
                onToggleSubEvent={onToggleSubEvent}
                onToggleSubInheritance={onToggleSubInheritance}
                space={space}
                incidentRoutingConfig={incidentRoutingConfig}
              />
            ))
          )}
        </SimpleGrid>

        <Box sx={{ height: 40, flexShrink: 0 }} />
      </VerticalScrollBar>
    </SettingsTabContainer>
  );
}
