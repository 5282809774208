import { Modal } from '@mantine/core';
import React from 'react';

import {
  CreateSpaceFileMutationParams,
  SpaceType,
  useCreateSpaceFile,
} from '@portals/api/organizations';
import { AutoFormik } from '@portals/autoformik';
import { ModalProps } from '@portals/framework';
import { FieldType, FieldTypeEnum } from '@portals/types';

const FIELDS: Array<FieldType> = [
  {
    name: 'url',
    title: 'File',
    type: FieldTypeEnum.File,
    required: true,
    options: { fileNameField: 'name' },
  },
  { name: 'name', title: 'File name', required: true },
  { name: 'desc', title: 'Description' },
  { name: 'file_type', title: 'File Type' },
];

export function SpaceFileUploader({
  data,
  closeMe,
}: ModalProps<{ space: SpaceType }>) {
  const spaceId = data.space.id;
  const createSpaceFile = useCreateSpaceFile();

  const handleAdd = async (
    fileData: CreateSpaceFileMutationParams['fileData']
  ) => {
    try {
      await createSpaceFile.mutateAsync({ spaceId, fileData });

      closeMe();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal
      opened={true}
      onClose={closeMe}
      title="Upload new file"
      padding="xl"
      sx={(theme) => ({
        '.modal-body': {
          padding: 0,
          marginBottom: theme.spacing.md,
        },
        '.modal-footer': {
          paddingRight: 0,
          paddingLeft: 0,
        },
      })}
    >
      <AutoFormik
        fields={FIELDS}
        submitTitle="Add"
        serverError={createSpaceFile.error?.error}
        inProgress={createSpaceFile.isLoading}
        handleSubmit={handleAdd}
        modal={closeMe}
      />
    </Modal>
  );
}
