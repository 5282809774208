import { createStyles, Stack, Text } from '@mantine/core';
import React, { useMemo } from 'react';

import { useFeatureFlags, useIsCecEnabled } from '@portals/api/organizations';
import { usePermissionAccess } from '@portals/framework';
import { useOpenRouteModal } from '@portals/framework/route-modals';

import { ReactComponent as Connect } from '../../../assets/img/icons/connect.svg';

export function ConnectToggle() {
  const { classes } = useStyles();

  const openRouteModal = useOpenRouteModal();
  const canAccessConnectRouteModal = useCanAccessConnectRouteModal();

  if (!canAccessConnectRouteModal) {
    return null;
  }

  return (
    <Stack
      spacing="xs"
      align="center"
      className={classes.item}
      onClick={() => openRouteModal({ modalId: 'connect' })}
      data-testid="sidebar-item-sidebar.connect"
    >
      <Connect />

      <Text fz={10}>Connect</Text>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  item: {
    paddingBlock: theme.spacing.xs,
    borderRadius: theme.spacing.sm,
    transition: 'all 0.15s ease-in-out',
    cursor: 'pointer',
    color: theme.colors.blue_gray[6],

    svg: {
      flexShrink: 0,
    },

    ...theme.fn.hover({
      backgroundColor: theme.colors.gray[1],
    }),
  },
}));

export function useCanAccessConnectRouteModal() {
  const isCecEnabled = useIsCecEnabled();
  const featureFlags = useFeatureFlags();
  const { isAdmin } = usePermissionAccess();

  return useMemo(
    () => isAdmin && (isCecEnabled || featureFlags?.connect_plus),
    [featureFlags?.connect_plus, isAdmin, isCecEnabled]
  );
}
