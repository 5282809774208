import { Button, createStyles, Stack, StackProps, Text } from '@mantine/core';
import { find, noop } from 'lodash/fp';
import React from 'react';

import { WIDGET_ICONS } from '../../widgets.constants';
import { WidgetColorType, WidgetIconNameType } from '../../widgets.types';

export interface ButtonWidgetProps {
  title: string;
  iconName: WidgetIconNameType;
  showIcon: boolean;
  color: WidgetColorType;

  onClick?: () => void;
  isLoading?: boolean;

  stackProps?: StackProps;

  isDeviceOffline?: boolean;
  lastUpdateTimestamp?: string;
}

export function ButtonWidget({
  title,
  iconName,
  showIcon,
  color,

  isLoading = false,
  onClick = noop,

  stackProps = {},

  isDeviceOffline,
}: ButtonWidgetProps) {
  const { classes } = useStyles();

  const widgetIcon = find({ iconName }, WIDGET_ICONS);

  const Icon = widgetIcon?.Icon || WIDGET_ICONS[0].Icon;

  return (
    <Stack
      className={classes.container}
      p="xl"
      spacing="lg"
      pos="relative"
      h="100%"
      w="100%"
      bg="white"
      justify="center"
      {...stackProps}
    >
      <Button
        color={color}
        onClick={onClick}
        radius="md"
        size="md"
        disabled={isDeviceOffline}
        loading={isLoading}
        leftIcon={showIcon ? <Icon height={16} width={16} /> : null}
        data-testid="dashboard-button-widget-name"
      >
        <Text truncate>{title}</Text>
      </Button>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    borderRadius: theme.radius.lg,
  },
}));
