import { Anchor, Group, Text } from '@mantine/core';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { MenuItemType } from './incidents-widget.types';
import { SortMenu } from './SortMenu';

interface ListActionsProps {
  showSortBySeverity: boolean;
  onSortByChanged: (sortBy: string) => void;
  items: Array<MenuItemType>;
  selectedItem: string;
  closeModal?: () => void;
  viewAllLink?: string;
  numOfIncidents: number;
}

export const ListActions: FC<ListActionsProps> = ({
  showSortBySeverity,
  viewAllLink,
  onSortByChanged,
  items,
  selectedItem,
  closeModal,
  numOfIncidents,
}) => {
  return (
    <Group position="apart">
      <Group align="center" spacing={4}>
        <Text size="md" color="gray.5" data-testid="list-actions-incidents">
          Incidents
        </Text>

        <Text
          size="md"
          color="gray.5"
          data-testid="list-actions-incidents-count"
        >
          ({numOfIncidents})
        </Text>
      </Group>

      <Group>
        <Group spacing="xs">
          <Text
            size="xs"
            sx={(theme) => ({ color: theme.colors.blue_gray[3] })}
          >
            Sort by:
          </Text>
          <SortMenu
            showSortBySeverity={showSortBySeverity}
            onSortByChanged={onSortByChanged}
            items={items}
            selectedItemName={selectedItem}
          />
        </Group>

        {viewAllLink ? (
          <Anchor
            component={Link}
            to={viewAllLink}
            onClick={() => {
              closeModal?.();
            }}
            size="xs"
            sx={(theme) => ({
              color: theme.other.primaryColor,
            })}
          >
            View All
          </Anchor>
        ) : null}
      </Group>
    </Group>
  );
};
