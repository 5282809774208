export enum CardBrand {
  amex = 'amex',
  cartes_bancaires = 'cartes_bancaires',
  diners = 'diners',
  discover = 'discover',
  jcb = 'jcb',
  mastercard = 'mastercard',
  visa = 'visa',
  unionpay = 'unionpay',
  unknown = 'unknown',
}

export interface CreditCardType {
  id: string;
  brand: CardBrand;
  last_4_digits: string;
  exp_month: number;
  exp_year: number;
  last_payment_status: string;
  subscription_count: number;
  invoice_count: number;
  archived_at: string;
  has_failed_payments: boolean;
}
