import { get, getOr } from 'lodash/fp';
import { createSelector, Selector } from 'reselect';

import { RuleType, StateType } from '@portals/types';

export const getData = (state: StateType): StateType['data'] => state.data;

/* DEVICES */
export const getDevices: Selector<
  StateType,
  StateType['data']['devices'] | undefined
> = createSelector(getData, get('devices'));

/* INTEGRATIONS */

export const getIntegrations: Selector<
  StateType,
  StateType['data']['integrations'] | Record<string, never>
> = createSelector(getData, getOr({}, 'integrations'));

/* RULES */
export const getRules: Selector<
  StateType,
  StateType['data']['rules'] | undefined
> = createSelector(getData, get('rules'));

export const getRuleById: (
  ruleId: string
) => Selector<StateType, RuleType | undefined> = (ruleId: string) =>
  createSelector(getRules, get<StateType['data']['rules'], string>(ruleId));
