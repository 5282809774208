import { TableState, LicenseType } from '@portals/types';

export const LICENSES_API_URL = 'ui/organization/licenses';

export const licensesQueryKeys = {
  base: [LICENSES_API_URL],
  list: (tableState: Pick<TableState<LicenseType>, 'sortBy' | 'filters'>) => [
    ...licensesQueryKeys.base,
    'list',
    tableState,
  ],
};
