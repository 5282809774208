import { useDispatch } from 'react-redux';

import { useAddresses } from '@portals/api/organizations';

import { checkSelectedAddress } from '../redux/actions/store';

export const useAddressesAndCheckSelectedAddress = () => {
  const dispatch = useDispatch();

  return useAddresses({
    onSuccess: (data) => dispatch(checkSelectedAddress(data)),
  });
};
