import PropTypes from 'prop-types';
import React from 'react';
import { Progress } from 'reactstrap';

import { InfoTable } from '@portals/table';

const mseToPercentage = (mse: number): number => ((mse + 4) * -5) / 100; // -24..-4 to 0..100

const MSE = ({ mse, channel }) => {
  const normalized = mseToPercentage(mse);
  const color =
    normalized > 0.425 ? 'primary' : normalized < 0.375 ? 'danger' : 'warning';

  return (
    <InfoTable.Row
      label={`Channel ${channel}`}
      value={
        <Progress animated max={1} value={normalized} color={color}>
          {mse}
        </Progress>
      }
    />
  );
};

MSE.propTypes = {
  mse: PropTypes.number.isRequired,
  channel: PropTypes.string.isRequired,
};

export default MSE;
