import { Box, createStyles, MantineColor, px, Text } from '@mantine/core';
import React from 'react';

import { getFileTypeFromUrl } from '@portals/utils';

type WidthOrHeightOrNone =
  | { width?: number; height?: never }
  | { height?: number; width?: never };

type FileTypeOrFileUrl =
  | { fileType: string; fileUrl?: never }
  | { fileUrl: string; fileType?: never };

type FileTypeIconProps = {
  color?: MantineColor;
  className?: string;
} & WidthOrHeightOrNone &
  FileTypeOrFileUrl;

const FILE_TYPE_COLORS = {
  pdf: 'red',
  doc: 'blue',
  bin: 'purple',
  dfl: 'deep_purple',
} as const;

const DEFAULT_WIDTH = 50;
const DEFAULT_HEIGHT = 66;
const WIDTH_HEIGHT_RATIO = DEFAULT_WIDTH / DEFAULT_HEIGHT;

export function FileTypeIcon({
  color,
  width,
  height,
  fileType,
  className,
  fileUrl,
}: FileTypeIconProps) {
  const { calculatedWidth, calculatedHeight } = calculateIconRatio(
    width,
    height
  );
  const { classes, theme, cx } = useStyles({
    width: calculatedWidth,
    height: calculatedHeight,
  });

  const finalFileType = fileUrl ? getFileTypeFromUrl(fileUrl) : fileType;
  const finalColor = color || getFileColorByFileType(finalFileType);

  return (
    <Box className={cx(classes.container, className)}>
      <Text className={classes.type} maw={50} truncate>
        {finalFileType}
      </Text>

      <svg
        width={width}
        height={height}
        viewBox="0 0 50 66"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.3">
          <path
            opacity="0.3"
            d="M42.3548 15.2103C40.7022 15.2087 39.1177 14.5492 37.9491 13.3766C36.7806 12.204 36.1234 10.614 36.1218 8.95569V0.271484H8.26981C6.11246 0.271484 4.04347 1.13149 2.51799 2.66225C0.99251 4.19302 0.135498 6.26914 0.135498 8.43397V57.6396C0.138702 59.8023 0.997119 61.8754 2.52225 63.4035C4.04739 64.9317 6.11455 65.79 8.26981 65.79H41.7049C43.8601 65.79 45.9273 64.9317 47.4524 63.4035C48.9776 61.8754 49.836 59.8023 49.8392 57.6396V15.2103H42.3548Z"
            fill={theme.colors?.[finalColor]?.[6]}
          />
        </g>

        <path
          d="M49.8396 15.5386H42.2483C40.5721 15.537 38.965 14.863 37.7797 13.6646C36.5945 12.4662 35.9279 10.8413 35.9263 9.14655V0.271484L49.8396 15.5386Z"
          fill={theme.colors?.[finalColor]?.[6]}
        />

        <path
          d="M38.7445 26.0012H10.6919C8.702 26.0012 7.08887 27.6322 7.08887 29.6442V38.1005C7.08887 40.1125 8.702 41.7435 10.6919 41.7435H38.7445C40.7344 41.7435 42.3475 40.1125 42.3475 38.1005V29.6442C42.3475 27.6322 40.7344 26.0012 38.7445 26.0012Z"
          fill={theme.colors?.[finalColor]?.[6]}
        />
      </svg>
    </Box>
  );
}

const useStyles = createStyles(
  (theme, { height, width }: { width: number; height: number }) => ({
    container: {
      height: height,
      width: width,
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    type: {
      position: 'absolute',
      color: theme.white,
      fontSize: Math.floor(
        px(theme.fontSizes.md) * (width / DEFAULT_WIDTH) * WIDTH_HEIGHT_RATIO
      ),
      textTransform: 'uppercase',
      fontWeight: 700,
      zIndex: 1,
      transform: 'translateY(1px)',
    },
  })
);

function getFileColorByFileType(fileType: string | undefined): MantineColor {
  if (fileType && FILE_TYPE_COLORS[fileType as keyof typeof FILE_TYPE_COLORS]) {
    return FILE_TYPE_COLORS[fileType as keyof typeof FILE_TYPE_COLORS];
  }

  return 'blue_accent';
}

function calculateIconRatio(
  width: number | undefined,
  height: number | undefined
): { calculatedWidth: number; calculatedHeight: number } {
  if (width && !height) {
    return {
      calculatedWidth: width,
      calculatedHeight: width * (DEFAULT_HEIGHT / DEFAULT_WIDTH),
    };
  }

  if (height && !width) {
    return {
      calculatedWidth: height * (DEFAULT_WIDTH / DEFAULT_HEIGHT),
      calculatedHeight: height,
    };
  }

  return {
    calculatedWidth: DEFAULT_WIDTH,
    calculatedHeight: DEFAULT_HEIGHT,
  };
}
