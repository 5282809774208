import { AuthProvider } from '@descope/react-sdk';
import { LoadingOverlay } from '@mantine/core';
import React from 'react';
import { useMatch } from 'react-router-dom';

import { TenantType } from '@portals/types';

import { useAppConfig } from './context';
import { useCommonPortalConfig } from './hooks/portal-config';

export function DescopeAuthProvider({ children }: { children: JSX.Element }) {
  const portalConfig = useCommonPortalConfig();
  const { tenantType } = useAppConfig();

  const match = useMatch('/auth/auto-sign-in');

  if (tenantType === TenantType.Organization && !match) {
    if (
      portalConfig.isInitialLoading ||
      !portalConfig.data?.descope_project_id
    ) {
      return <LoadingOverlay visible />;
    }

    return (
      <AuthProvider projectId={portalConfig.data?.descope_project_id}>
        {children}
      </AuthProvider>
    );
  }

  return (
    <AuthProvider projectId={process.env.NX_DESCOPE_PROJECT_ID as string}>
      {children}
    </AuthProvider>
  );
}
