import { get } from 'lodash/fp';
import React, { FC, useMemo } from 'react';
import { AutoSizer } from 'react-virtualized';
import {
  CartesianGrid,
  Dot,
  Tooltip,
  XAxis,
  Line,
  LineChart as Chart,
  YAxis,
} from 'recharts';

import { useTheme } from '@portals/ui';

const LINES = (color) => [
  {
    key: 'a',
    label: 'A',
    color: color.danger,
  },
  {
    key: 'b',
    label: 'B',
    color: color.tertiary,
  },
  {
    key: 'c',
    label: 'C',
    color: color.warning,
  },
  {
    key: 'd',
    label: 'D',
    color: color.success,
  },
];

interface LineChartProps {
  labels: Array<string>;
  data: Array<Array<number | undefined>>;
}

const LineChart: FC<LineChartProps> = ({ labels = [], data }) => {
  const { color } = useTheme();

  const lines = useMemo(() => LINES(color), [color]);
  const chartData = useMemo(
    () =>
      labels.map((label, index) => ({
        timestamp: label,
        a: get([index, 0], data) || null,
        b: get([index, 1], data) || null,
        c: get([index, 2], data) || null,
        d: get([index, 3], data) || null,
      })),
    [data, labels]
  );

  return (
    <AutoSizer disableHeight>
      {({ width }) => (
        <Chart
          height={200}
          width={width}
          data={chartData}
          margin={{ top: 0, left: 0, bottom: 0, right: 0 }}
        >
          <CartesianGrid horizontal={false} stroke={color.gray300} />

          <Tooltip />

          <YAxis
            axisLine={false}
            width={40}
            tickMargin={20}
            tickSize={0}
            allowDecimals={false}
          />

          <XAxis
            dataKey="timestamp"
            interval="preserveStartEnd"
            tickMargin={20}
            tickSize={0}
            stroke={color.gray500}
          />

          {lines.map(({ key, color, label }) => (
            <Line
              key={key}
              name={label}
              strokeWidth={2}
              dataKey={key}
              isAnimationActive={false}
              stroke={color}
              dot={(props) => (
                <Dot
                  {...props}
                  fill={color}
                  r={3}
                  stroke="white"
                  strokeWidth={2}
                />
              )}
              activeDot={(props) => (
                <Dot
                  {...props}
                  fill={color}
                  r={4}
                  stroke="white"
                  strokeWidth={0}
                />
              )}
            />
          ))}
        </Chart>
      )}
    </AutoSizer>
  );
};

export default LineChart;
