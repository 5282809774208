import { Box, createStyles, Stack } from '@mantine/core';
import React from 'react';

import { Handle } from './Handle';
import { OnAddCustomColorFn, WidgetColorType } from '../../widgets.types';

interface GradientHandleProps {
  color: WidgetColorType;
  onChange: (color: WidgetColorType) => void;
  horizontalOffset: string;
  colors: Array<WidgetColorType> | undefined;
  onAddCustomColor: OnAddCustomColorFn | undefined;
}

export function GradientHandle({
  color,
  onChange,
  horizontalOffset,
  colors,
  onAddCustomColor,
}: GradientHandleProps) {
  const { classes } = useStyles();
  return (
    <Stack
      pos="relative"
      className={classes.container}
      w={40}
      sx={{
        transform: `translateX(${horizontalOffset})`,
      }}
    >
      <Handle
        color={color}
        onChangeColor={onChange}
        onAddCustomColor={onAddCustomColor}
        colors={colors}
      />

      <Box pos="absolute" bottom={0} className={classes.indicatorWrapper}>
        <Box w={16} h={16} className={classes.indicator} bg={color}>
          <Box
            w={6}
            h={6}
            sx={{
              borderRadius: '50%',
            }}
            bg="white"
          />
        </Box>
      </Box>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  indicatorWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transform: `translateY(75%)`,
  },
  indicator: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
  },
}));
