import {
  Box,
  Button,
  CloseButton,
  createStyles,
  Group,
  Modal,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import React from 'react';

import { useRequestPlanUpgrade } from '@portals/api/organizations';
import { SpaceshipSVG } from '@portals/assets';
import { ModalProps } from '@portals/framework';

import { StartFreeTrialButton } from '../components/StartFreeTrialButton';

export interface UpgradePricingPlanModalProps
  extends ModalProps<{
    source: string;
  }> {}

export function UpgradePricingPlanModal({
  closeMe,
  data: { source },
}: UpgradePricingPlanModalProps) {
  const { classes } = useStyles();

  const requestPlanUpgrade = useRequestPlanUpgrade();

  const onContact = () => {
    requestPlanUpgrade.mutate({ source });
    closeMe();
  };

  return (
    <Modal
      opened
      onClose={closeMe}
      withinPortal
      withCloseButton={false}
      padding={0}
      size={700}
      classNames={{
        content: classes.modalContent,
      }}
    >
      <Box className={classes.container}>
        <CloseButton
          onClick={closeMe}
          className={classes.closeButton}
          size="lg"
        />

        <SpaceshipSVG />

        <Stack className={classes.content} justify="space-between">
          <Stack>
            <Title order={4} weight={500} color="blue_gray.9">
              Upgrade to access this feature
            </Title>

            <Text size="sm" color="blue_gray.5">
              You have hit your current plan's limitation. Please upgrade to
              unlock this and a wide range of other powerful features offered by
              the Xyte platform.
              <br />
              <br />
              Contact us to learn more about upgrading your plan, or start your
              30 day free trial and unlock this feature.
            </Text>
          </Stack>

          <Group grow>
            <StartFreeTrialButton variant="default" />
            <Button onClick={onContact}>Contact Us</Button>
          </Group>
        </Stack>
      </Box>
    </Modal>
  );
}

const useStyles = createStyles((theme) => ({
  modalContent: {
    overflow: 'hidden',
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '261px 1fr',
    height: '100%',
    position: 'relative',
    gap: theme.spacing.xl,
  },
  closeButton: {
    position: 'absolute',
    top: 20,
    right: 20,
  },
  content: {
    padding: '72px 40px 32px 0',
    height: '100%',
  },
}));
