import { FC } from 'react';

export interface ServiceCardFormProps<T = Record<string, any>> {
  data: T;
  onChange: () => void;
  onSubmit: (data: T) => void;
  readOnly: boolean;
}

export enum ServiceStateEnum {
  On = 'on',
  Off = 'off',
  Inherit = 'inherit',
}

export interface ServiceSectionType {
  name: string;
  description: string;
  id: string;
  children: Array<ServiceCardType>;
}

export interface ServiceCardType {
  id: string;
  name: string;
  component: FC<ServiceCardFormProps>;
  logoSrc: string;
}
