import {
  Box,
  BoxProps,
  createStyles,
  Paper,
  PaperProps,
  Text,
} from '@mantine/core';
import React, { ReactNode } from 'react';

interface CheckoutCardProps {
  title: ReactNode;
  paperProps?: PaperProps;
  boxProps?: BoxProps;
  children: ReactNode;
}

export function CardContainer({
  title,
  paperProps = {},
  boxProps = {},
  children,
}: CheckoutCardProps) {
  const { classes, cx } = useStyles();
  const { className: paperClassName, ...otherPaperProps } = paperProps;

  return (
    <Paper
      p={0}
      radius="md"
      className={cx(classes.container, paperClassName)}
      {...otherPaperProps}
    >
      <Box p="xl" {...boxProps}>
        <Text className="title" size="md" color="gray.8">
          {title}
        </Text>

        {children}
      </Box>
    </Paper>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    border: `1px solid ${theme.colors.gray[2]}`,
    display: 'grid',
    overflow: 'hidden',
    whiteSpace: 'nowrap',

    '>div': {
      overflow: 'hidden',
      display: 'grid',
      gridTemplateRows: 'min-content minmax(0px, 1fr)',
      gap: theme.spacing.lg,

      '.title': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
}));
