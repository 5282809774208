import {
  StepperProps,
  StepperStylesNames,
  StepperStylesParams,
} from '@mantine/core';
import React from 'react';

import { ReactComponent as TickSimple } from '@portals/icons/linear/tick-simple.svg';

import { ThemeComponentOverrides } from '../common-types';

export const Stepper: ThemeComponentOverrides<
  StepperProps,
  StepperStylesNames,
  StepperStylesParams
> = {
  defaultProps: {
    size: 'xs',
    completedIcon: <TickSimple width={16} height={16} />,
  },
  styles: (theme, params) => {
    return {
      separator: {
        height: 1,
        backgroundColor: theme.colors.blue_gray[3],
      },
      verticalSeparator: {
        borderWidth: 1,
        borderColor: theme.colors.blue_gray[3],
      },
      stepIcon: {
        border: `1px solid ${theme.colors.blue_gray[3]}`,
        backgroundColor: theme.colors.blue_gray[0],
        color: theme.colors.blue_gray[8],
        fontSize: theme.fontSizes.sm,
        fontWeight: 400,

        '&[data-progress]': {
          border: `1px solid ${theme.fn.themeColor(params.color)}`,
          backgroundColor: theme.white,
          color: theme.fn.themeColor(params.color),
        },
      },
      stepLabel: {
        fontSize: theme.fontSizes.sm,
        fontWeight: 400,
        color: theme.colors.blue_gray[3],

        '&[data-progress]': {
          color: theme.fn.themeColor(params.color),
        },
      },
      step: {
        '&[data-progress] .mantine-Stepper-stepLabel': {
          color: theme.fn.themeColor(params.color),
        },
        '&[data-completed] .mantine-Stepper-stepLabel': {
          color: theme.colors.blue_gray[8],
        },
      },
    };
  },
};
