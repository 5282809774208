import { AnimatePresence, motion } from 'framer-motion';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { FADE_IN_OUT } from '@portals/ui';

export function FormWrapper({
  children,
  id,
}: {
  id: string;
  children: ReactNode;
}) {
  return (
    <AnimatePresence>
      <Container {...FADE_IN_OUT} key={id}>
        {children}
      </Container>
    </AnimatePresence>
  );
}

const Container = styled(motion.div)`
  .modal-body {
    margin: 0;
    padding: 0;
  }

  .modal-footer {
    padding: 0;
    border: none;

    * {
      margin: 0;
    }
  }
`;
