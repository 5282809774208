import { Box, createStyles, Group, Paper, Stack, Text } from '@mantine/core';
import { isNaN, isUndefined } from 'lodash/fp';
import moment from 'moment';
import React from 'react';
import { TooltipProps } from 'recharts';

import { MeasurementType } from '../form';

interface TooltipContentProps extends TooltipProps<number, string> {
  measurements: Array<MeasurementType>;
  customFormatter?: (value: number | undefined) => string | undefined;
}

export function TooltipContent({
  active,
  payload,
  measurements,
  customFormatter,
}: TooltipContentProps) {
  const { classes } = useStyles();

  if (!active) return null;

  const timestamp = payload?.[0]?.payload?.timestamp;

  return (
    <Paper p="md" radius="md" shadow="sm" withBorder>
      <Stack spacing="xs">
        <Text size="xs" color="gray.6">
          {moment(timestamp).format('MMMM Do YYYY, HH:mm:ss')}
        </Text>

        {(payload || []).map((entry, index) => {
          let adjustedValue;

          if (isNaN(entry.value)) {
            adjustedValue = 'N/A';
          } else if (customFormatter) {
            adjustedValue = customFormatter(entry.value);

            if (isUndefined(adjustedValue)) {
              adjustedValue = 'N/A';
            }
          } else {
            adjustedValue = entry.value;
          }

          return (
            <Group key={index} spacing="xs">
              <Box
                className={classes.indicator}
                bg={measurements[index].color}
              />

              <Text size="xs" inline>
                {measurements[index].label}:{' '}
                {`${adjustedValue} ${measurements[index].unit || ''}`}
              </Text>
            </Group>
          );
        })}
      </Stack>
    </Paper>
  );
}

const useStyles = createStyles((theme) => ({
  indicator: {
    width: 14,
    height: 14,
    borderRadius: theme.radius.sm,
  },
}));
