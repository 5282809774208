import React from 'react';
import Joyride, { CallBackProps, Step } from 'react-joyride';
import { useLocation, useNavigate } from 'react-router-dom';

import { useFirstClaimedDevice } from '@portals/api/organizations';
import { TOUR_STEPS_IDS } from '@portals/framework';
import { useOpenRouteModal } from '@portals/framework/route-modals';

import { useMonitoringTourContext } from '../MonitoringTourContextProvider';
import { TourStepTooltip } from '../TourStepTooltip';

export function MonitoringTourPartOne() {
  const openRouteModal = useOpenRouteModal();
  const firstClaimedDevice = useFirstClaimedDevice();
  const { setRunTourPartTwo } = useMonitoringTourContext();
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);

  const steps: Array<Step> = [
    {
      isFixed: true,
      target: `[data-tour-step-id=${TOUR_STEPS_IDS.device}]`,
      title: 'Select your device',
      content:
        'Select the space and then the device you would like to monitor or manage.',
      disableBeacon: true,
      floaterProps: {
        disableAnimation: true,
      },
      placement: 'right',
    },
  ];

  const onTransitionCallback = (data: CallBackProps) => {
    const { status, lifecycle } = data;

    if (status === 'finished' && lifecycle === 'complete') {
      openRouteModal({
        modalId: 'device',
        pathParams: [firstClaimedDevice.data?.id],
      });

      setRunTourPartTwo(true);
    }
  };

  const onSkipTour = () => {
    searchParams.delete('active_tour');
    navigate({ search: searchParams.toString() }, { replace: true });
  };

  return (
    <Joyride
      steps={steps}
      continuous
      disableOverlayClose
      callback={onTransitionCallback}
      tooltipComponent={(props) => (
        <TourStepTooltip
          tourName="Monitor and manage devices"
          totalSize={4}
          onSkip={onSkipTour}
          {...props}
        />
      )}
      styles={{
        options: {
          arrowColor: 'black',
        },
      }}
      scrollDuration={0}
    />
  );
}
