import { useQuery } from '@tanstack/react-query';

import {
  buildUrlFromFilters,
  fetchApiRequest,
  ServerError,
  useRequestOptions,
} from '@portals/api';
import {
  PaginationResponse,
  RelatedProductToCommandType,
} from '@portals/types';

import {
  SUPPORTED_COMMANDS_API_URL,
  supportedCommandsQueryKeys,
} from './supported-commands.constants';

export function useRelatedProductsToSupportedCommand(commandId: string) {
  const { url, options } = useRequestOptions({
    url: `${SUPPORTED_COMMANDS_API_URL}/${commandId}/licenses?q[has_assigned_device]=false&q[state_eq]=available`,
    method: 'GET',
  });

  const requestUrl = buildUrlFromFilters({
    url,
    pagination: {
      page: 0,
      pageSize: 1000,
    },
  });

  return useQuery<
    PaginationResponse<RelatedProductToCommandType>,
    ServerError,
    RelatedProductToCommandType[]
  >({
    queryKey:
      supportedCommandsQueryKeys.relatedProductsToSupportedByCommandId(
        commandId
      ),
    queryFn: () => fetchApiRequest(requestUrl, options),
    enabled: !!commandId,
    cacheTime: 0,
    meta: {
      baseUrl: `${SUPPORTED_COMMANDS_API_URL}/:id/licenses?q[has_assigned_device]=#{false}`,
      method: 'GET',
    },
  });
}
