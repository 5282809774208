import { createStyles, Paper, Stack, Text, Title } from '@mantine/core';
import React from 'react';

import { ReactComponent as MedalStar } from '@portals/icons/bulk/medal-star.svg';

import { UploadWarrantyButton } from './UploadWarrantyButton';

interface EmptyWarrantiesCardProps {
  deviceId: string;
}

export function EmptyWarrantiesCard({ deviceId }: EmptyWarrantiesCardProps) {
  const { classes, theme } = useStyles();

  return (
    <Paper radius="md" p="xl" withBorder>
      <Stack className={classes.content}>
        <MedalStar width={80} height={80} color={theme.colors.amber[6]} />

        <Stack spacing="xs">
          <Title className={classes.title} order={6}>
            Upload Warranty
          </Title>

          <Text className={classes.description}>
            Easily upload your documentation and protect your device with a
            warranty and coverage
          </Text>
        </Stack>

        <UploadWarrantyButton deviceId={deviceId} className={classes.button} />
      </Stack>
    </Paper>
  );
}

const useStyles = createStyles((theme) => ({
  content: {
    height: '100%',
  },
  title: {
    fontWeight: 600,
  },
  description: {
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[8],
  },
  button: {
    marginTop: 'auto',
  },
}));
