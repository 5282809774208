import { createStyles } from '@mantine/core';
import React from 'react';
import { useParams } from 'react-router-dom';

import { RoutePanel } from '@portals/framework/route-panels';

import { TicketDetailsPanel } from '../../components/tickets/ticket-details-panel/TicketDetailsPanel';

export function TicketRoutePanel() {
  const { classes } = useStyles();

  const params = useParams<{ ticket_id: string }>();

  return (
    <RoutePanel panelId="ticket" size={400}>
      {({ onClose }) => (
        <TicketDetailsPanel
          ticketId={params.ticket_id}
          onClose={onClose}
          chatWrapperProps={{
            className: classes.chatWrapper,
          }}
        />
      )}
    </RoutePanel>
  );
}

const useStyles = createStyles((theme) => ({
  chatWrapper: {
    position: 'fixed',
    top: 0,
    right: 400,
    width: 700,
    height: '100%',
    backgroundColor: theme.white,
    borderRight: `1px solid ${theme.colors.gray[3]}`,
  },
}));
