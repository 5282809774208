export const PORTAL_CONFIG_BASE_URL = 'ui/organization/portal_config';

export const portalConfigQueryKeys = {
  portalConfig: ['portalConfig'],
  byPartnerName: (partnerName: string | undefined) => [
    ...portalConfigQueryKeys.portalConfig,
    'partnerName',
    partnerName || '',
  ],
};
