import { ActionIcon, Tooltip } from '@mantine/core';
import { compact } from 'lodash/fp';
import React, { useMemo } from 'react';
import { CSVLink } from 'react-csv';
import { ColumnInstance } from 'react-table';

import { ExportParams, TableInstanceType } from '@portals/types';
import { useAuthDownloadFile } from '@portals/utils';

import { ReactComponent as CsvIcon } from '../../assets/csv.svg';

interface ExportProps<TData extends object> {
  instance: TableInstanceType<TData>;
  remoteUrl?: ExportParams['remoteUrl'];
  fileName?: ExportParams['fileName'];
  name: string;
}

export function Export<TData extends object>({
  instance,
  name,
  remoteUrl = '',
  fileName,
}: ExportProps<TData>) {
  const localFileName = fileName || `${name || 'xyte'}.csv`;
  const { rows, flatHeaders } = instance;
  const downloadFile = useAuthDownloadFile();

  const exportData = useMemo(() => {
    const headers = compact(
      flatHeaders.map(
        ({
          Header,
          canExport = true,
          isVisible,
        }: ColumnInstance<TData> & { canExport: boolean }) =>
          canExport && isVisible ? Header : null
      )
    );

    const visibleHeaderIds = flatHeaders
      .map(
        ({
          id,
          canExport = true,
          isVisible,
        }: ColumnInstance<TData> & { canExport: boolean }) =>
          canExport && isVisible && id ? id : null
      )
      .filter((id) => id !== null);

    const dataRows = rows.map((row) => {
      const values = [];

      visibleHeaderIds.forEach((id) => {
        values.push(row.values[id]);
      });

      return values;
    });

    return [headers, ...dataRows];
  }, [flatHeaders, rows]);

  return (
    <Tooltip label="Export" withinPortal>
      {remoteUrl ? (
        <ActionIcon
          data-testid="table-export-button"
          loading={downloadFile.isLoading}
          onClick={() =>
            downloadFile.mutate({ fileUrl: remoteUrl, fileName: localFileName })
          }
        >
          <CsvIcon />
        </ActionIcon>
      ) : (
        <ActionIcon
          loading={downloadFile.isLoading}
          data-testid="table-export-button"
        >
          <CSVLink filename={fileName} data={exportData}>
            <CsvIcon />
          </CSVLink>
        </ActionIcon>
      )}
    </Tooltip>
  );
}
