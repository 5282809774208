import { useCallback, useMemo } from 'react';
import { Theme } from 'react-select';

import { hexToRgba } from '@portals/utils';

import { useTheme } from '../lib/ThemeProvider/index';

export const useGetSelectTheme = () => {
  const theme = useTheme();

  return useCallback<(theme: Theme) => Theme>(
    ({ colors, spacing, ...rest }) => {
      return {
        ...rest,
        spacing: {
          ...spacing,
          controlHeight: 45,
        },
        colors: {
          ...colors,
          primary: theme.color.primary,
          primary75: hexToRgba(theme.color.primary, 75),
          primary50: hexToRgba(theme.color.primary, 50),
          primary25: hexToRgba(theme.color.primary, 25),
        },
      };
    },
    [theme]
  );
};

export const useSelectHeight = (height: number) =>
  useMemo(
    () => ({
      control: (base) => ({
        ...base,
        minHeight: height,
        height,
      }),
      valueContainer: (base) => ({
        ...base,
        height,
        padding: '0 0.7rem',
      }),
      placeholder: (base) => ({
        ...base,
        height,
        top: 'unset',
        transform: 'unset',
        display: 'flex',
        alignItems: 'center',
      }),
      input: (base) => ({
        ...base,
        height,
        paddingTop: 0,
        paddingBottom: 0,
        margin: 0,
        display: 'flex',
        alignItems: 'center',
      }),
      indicatorsContainer: (base) => ({
        ...base,
        height,
      }),
      menuPortal: (base) => ({
        ...base,
        zIndex: 999,
      }),
    }),
    [height]
  );
