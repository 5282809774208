import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';
import { OrganizationFileResponseType } from '@portals/types';

import { USER_FILES_API_URL, userFilesQueryKeys } from './user-files.constants';
import { useApiQuery } from '../../hooks';
import { fetchApiRequest, useRequestOptions } from '../../utils';

export function useUserFiles() {
  return useApiQuery<OrganizationFileResponseType[]>(
    USER_FILES_API_URL,
    userFilesQueryKeys.all()
  );
}

export function useDeleteUserFile() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: USER_FILES_API_URL,
    method: 'DELETE',
  });

  return useMutation({
    mutationFn: (fileId: string) =>
      fetchApiRequest(`${url}/${fileId}`, options),
    onSuccess: () => {
      dispatch(toastrSuccess('File deleted successfully'));

      queryClient.invalidateQueries(userFilesQueryKeys.global);
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useDeleteUserFile',
      baseUrl: `${USER_FILES_API_URL}/:id`,
      method: 'DELETE',
    },
  });
}
