import { createStyles, Group, NumberInputProps, Text } from '@mantine/core';
import React from 'react';

import { CURRENCIES_FLAGS } from '@portals/countries';
import { CurrencyCode } from '@portals/types';

import { CurrencyInput } from './CurrencyInput';

export interface CurrencyRowProps {
  value: NumberInputProps['value'];
  onChange: NumberInputProps['onChange'];
  error?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  currencyCode: CurrencyCode;
  isDefaultCurrency: boolean;
  className?: string;
}

export function CurrencyRow({
  value,
  onChange,
  error,
  disabled,
  readOnly,
  currencyCode,
  isDefaultCurrency,
  className,
}: CurrencyRowProps) {
  const { cx, classes } = useStyles();

  return (
    <Group noWrap className={cx(classes.container, className)} spacing="xs">
      <img
        width={30}
        height="auto"
        alt="country flag"
        className={classes.flag}
        src={CURRENCIES_FLAGS[currencyCode]}
      />

      <Text size="sm">
        <span>{currencyCode}</span>
        {isDefaultCurrency && (
          <Text component="span" color="blue_gray.3">
            &nbsp;(default)
          </Text>
        )}
      </Text>

      <Group noWrap className={classes.inputWrapper}>
        <CurrencyInput
          currencyCode={currencyCode}
          value={value}
          onChange={onChange}
          readOnly={readOnly}
          error={error}
          disabled={disabled}
        />
      </Group>
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    paddingBlock: theme.spacing.lg,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },
  flag: {
    borderRadius: theme.radius.xs,
  },
  inputWrapper: {
    marginLeft: 'auto',
  },
}));
