import { useDispatch } from 'react-redux';

import { useCreditCards } from '@portals/api/organizations';

import { checkSelectedCreditCard } from '../redux/actions/store';

export function useCreditCardsAndCheckSelectedCreditCard() {
  const dispatch = useDispatch();

  return useCreditCards({
    onSuccess: (data) => dispatch(checkSelectedCreditCard(data)),
  });
}
