import {
  Button,
  createStyles,
  Group,
  LoadingOverlay,
  Modal,
  Stack,
  Text,
} from '@mantine/core';
import React, { useState } from 'react';

import {
  DeviceType,
  SpaceType,
  useMoveDevice,
} from '@portals/api/organizations';
import { ModalProps } from '@portals/framework';
import { ReactComponent as GPS } from '@portals/icons/linear/gps.svg';

import { OrganizationTree } from './OrganizationTree';

export interface MoveDeviceProps extends ModalProps<{ device: DeviceType }> {}

export function MoveDevice({ closeMe, data: { device } }: MoveDeviceProps) {
  const { classes } = useStyles();
  const [selectedSpaceId, setSelectedSpaceId] = useState<
    SpaceType['id'] | null
  >(null);

  const moveDevice = useMoveDevice();

  if (!device) {
    closeMe();
  }

  const onMove = () => {
    if (selectedSpaceId === null) return;

    moveDevice.mutate(
      {
        device_id: device.id,
        space_id: selectedSpaceId,
      },
      { onSuccess: closeMe }
    );
  };

  return (
    <Modal
      opened
      size="lg"
      onClose={closeMe}
      padding={0}
      withCloseButton={false}
      classNames={{
        content: classes.modalBody,
      }}
    >
      <LoadingOverlay visible={moveDevice.isLoading} />

      <Stack className={classes.header} mb="md">
        <Text size="xl" color="blue_gray.9">
          Move Device
        </Text>

        <Text size="sm" color="blue_gray.9">
          Select a space to move this device to
        </Text>
      </Stack>

      <Stack className={classes.content}>
        <OrganizationTree
          selectedSpaceId={selectedSpaceId}
          setSelectedSpaceId={setSelectedSpaceId}
          customLabel={({ node, label }) => (
            <Group position="apart">
              <Text color="gray.9">{label}</Text>

              {node.id === device.space_id ? (
                <Group px="xs" c="gray.9" spacing={2}>
                  <GPS height={16} width={16} />

                  <Text>Current Space</Text>
                </Group>
              ) : null}
            </Group>
          )}
        />
      </Stack>

      <Group position="right" p="xl" className={classes.footer}>
        <Button variant="default" onClick={closeMe}>
          Cancel
        </Button>

        <Button disabled={!selectedSpaceId} onClick={onMove}>
          Move Device
        </Button>
      </Group>
    </Modal>
  );
}

const useStyles = createStyles((theme) => ({
  header: {
    padding: 32,
    paddingBottom: 0,
  },
  modalBody: {
    display: 'grid',
    gridTemplateRows: 'min-content 1fr min-content',
  },
  content: {
    padding: '0 32px',
    height: 420,
  },
  footer: {
    borderTop: `1px solid ${theme.colors.gray[2]}`,
  },
}));
