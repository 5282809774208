import { Descope } from '@descope/react-sdk';
import {
  Box,
  Button,
  ButtonProps,
  Group,
  Modal,
  SimpleGrid,
  Stack,
  Text,
  ModalProps as MantineModalProps,
  LoadingOverlay,
} from '@mantine/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  authySrc,
  googleAuthenticatorSrc,
  lastPassAuthenticatorSrc,
  microsoftAuthenticatorSrc,
  duoMobileSrc,
  onePasswordSrc,
} from '@portals/assets';
import { ModalFooter } from '@portals/core';
import { useOpenModal } from '@portals/redux';
import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';

import { EmailMFAModalProps } from './EmailMFAModal';
import { ModalProps } from '../../components/Modals';
import { captureDescopeError } from '../../utils/descope.utils';

const AUTHENTICATOR_APPS = [
  {
    name: 'Google Authenticator',
    subtitle: 'by Google',
    icon: googleAuthenticatorSrc,
  },
  {
    name: 'Microsoft Authenticator',
    subtitle: 'by Microsoft',
    icon: microsoftAuthenticatorSrc,
  },
  {
    name: 'LastPass Authenticator',
    subtitle: 'by LastPass',
    icon: lastPassAuthenticatorSrc,
  },
  {
    name: 'Duo Mobile',
    subtitle: 'by Duo Security',
    icon: duoMobileSrc,
  },
  {
    name: 'Authy',
    subtitle: 'by Twilio',
    icon: authySrc,
  },
  {
    name: '1Password',
    subtitle: 'by AgileBits',
    icon: onePasswordSrc,
  },
];

export interface AuthenticatorAppModalProps
  extends ModalProps<{
    isTotpEnabled: boolean;
  }> {}

export function AuthenticatorAppModal({
  data: { isTotpEnabled },
  closeMe,
}: AuthenticatorAppModalProps) {
  const dispatch = useDispatch();

  const [showQRCode, setShowQRCode] = useState(false);

  const [isDescopeFlowLoading, setIsDescopeFlowLoading] = useState(true);

  const openModal = useOpenModal();

  const onSuccess: React.ComponentProps<typeof Descope>['onSuccess'] = (e) => {
    if (!e.detail.user?.customAttributes?.isTotpEnabled && isTotpEnabled) {
      dispatch(
        toastrSuccess(
          'Two-Factor Authentication via TOTP has been successfully disabled'
        )
      );

      closeMe();
      return;
    }

    if (e.detail.user?.customAttributes?.isTotpEnabled && !isTotpEnabled) {
      dispatch(
        toastrSuccess(
          'Two-Factor Authentication via TOTP has been successfully set up'
        )
      );

      closeMe();
      return;
    }

    dispatch(toastrError('Something went wrong'));

    closeMe();
  };

  return (
    <Modal
      opened
      onClose={closeMe}
      padding="xxl"
      radius="lg"
      styles={modalStyles}
    >
      {showQRCode || isTotpEnabled ? (
        <>
          <LoadingOverlay visible={isDescopeFlowLoading} />

          <Descope
            flowId={isTotpEnabled ? 'cancel-mfa-totp' : 'mfa-totp'}
            onSuccess={onSuccess}
            onReady={() => setIsDescopeFlowLoading(false)}
            onError={captureDescopeError}
          />
        </>
      ) : (
        <Stack>
          <Text c="blue_gray.9" fz={24} ta="center">
            Install an authentication app on your phone
          </Text>

          <Text c="blue_gray.9" ta="center">
            You'll need to install an authentication app on your phone to set up
            Two-Factor Authentication. These are the most popular apps:{' '}
          </Text>

          <SimpleGrid cols={2}>
            {AUTHENTICATOR_APPS.map((app) => (
              <Group key={app.name} align="center" p="md" noWrap>
                <img src={app.icon} />

                <Stack spacing={4}>
                  <Text c="blue_gray.9">{app.name}</Text>

                  <Text c="blue_gray.5" fz="xs">
                    {app.subtitle}
                  </Text>
                </Stack>
              </Group>
            ))}
          </SimpleGrid>

          <Box ta="center">
            <Text>Can't use smartphone?</Text>

            <Button
              variant="subtle"
              styles={buttonStyles}
              onClick={() => {
                openModal<EmailMFAModalProps['data']>('EmailMFAModal', {
                  isOtpEnabled: false,
                });

                closeMe();
              }}
            >
              Use email
            </Button>
          </Box>
        </Stack>
      )}

      {!showQRCode && !isTotpEnabled ? (
        <ModalFooter position="center" grow>
          <Button onClick={closeMe} variant="outline">
            Back
          </Button>

          <Button onClick={() => setShowQRCode(true)}>I'm ready</Button>
        </ModalFooter>
      ) : null}
    </Modal>
  );
}

const buttonStyles: ButtonProps['styles'] = (theme) => ({
  root: {
    textDecoration: 'underline',

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

const modalStyles: MantineModalProps['styles'] = (theme) => ({
  body: {
    minHeight: 480,
  },
});
