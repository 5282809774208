import { lazy } from 'react';

import {
  isCustomerFeatureAll,
  isCustomerFeatureLabOnly,
  SETTING_PAGE_ID_URL_PARAM_NAME,
  SignOut,
} from '@portals/framework';
import {
  CategoryRouteItem,
  OrganizationConfigurationType,
  RoutesMap,
  RoutesType,
  TenantType,
} from '@portals/types';

import { ChangePasswordPage, ResetPasswordPage } from './pages/auth/Password';
import { SignIn } from './pages/auth/SignIn';
import { SignUpPage } from './pages/auth/SignUp';
import { WelcomeWrapper } from './pages/auth/Welcome';
import { DesktopDeviceRedirect } from './pages/dashboard/devices/device/DesktopDeviceRedirect';
import { Device } from './pages/dashboard/devices/device/Device';
import { Devices } from './pages/dashboard/devices/Devices';
import { Overview } from './pages/dashboard/overview/Overview';
import { Scan } from './pages/dashboard/overview/Scan';
import { Space } from './pages/dashboard/overview/space';
import { ExternalStoreListingPage } from './pages/external-store/ExternalStoreListingPage';
import { ExternalStorePage } from './pages/external-store/ExternalStorePage';
import { StoreListingPage } from './pages/store/StoreListingPage';
import { StorePage } from './pages/store/StorePage';
import {
  CLOUD_ID_SEARCH_PARAM,
  PARTNER_SEARCH_PARAM,
} from '../constants/global-search-params.constants';
import { Invoices } from '../desktop/pages/products/pages/invoices/Invoices';
import { Orders } from '../desktop/pages/products/pages/orders/Orders';
import { PurchasedProducts } from '../desktop/pages/products/pages/purchased-products/PurchasedProducts';
import { Subscriptions } from '../desktop/pages/products/pages/subscriptions/Subscriptions';
import { ConnectRouteModalSelector } from '../desktop/route-modals/Connect/ConnectRouteModalSelector';
import { Order } from '../desktop/route-modals/Order/Order';
import { SubscriptionRoutePanel } from '../desktop/route-panels/subscription/SubscriptionRoutePanel';

const authRoutes: RoutesType<OrganizationConfigurationType> = [
  {
    path: '/auth/sign-in/:partner_name?',
    id: 'signIn',
    component: SignIn,
  },
  {
    path: '/auth/sign-out',
    id: 'signIn',
    component: SignOut,
  },
  {
    path: '/auth/sign-up/:partner_name?',
    id: 'signUp',
    component: SignUpPage,
  },
  {
    path: '/auth/welcome/:partner_name?',
    id: 'welcome',
    component: WelcomeWrapper,
  },
  {
    path: '/auth/reset-password/:partner_name?',
    id: 'resetPassword',
    component: ResetPasswordPage,
  },
  {
    path: '/auth/change-password/:partner_name?',
    id: 'changePassword',
    component: ChangePasswordPage,
  },
];

const dashboard: CategoryRouteItem<OrganizationConfigurationType>[] = [
  {
    id: 'dashboard',
    headerId: '',
    canAccessRoute: ({ configuration }) =>
      !isCustomerFeatureAll(configuration?.portal_capabilities?.b2c_view) &&
      !isCustomerFeatureLabOnly(
        configuration?.portal_capabilities?.b2c_view,
        configuration?.[TenantType.Organization].lab
      ),
    childRoutes: [
      {
        path: ['/', '/overview', '/claim', '/c/:cloudId'],
        id: 'overview',
        component: Overview,
      },
      {
        path: ['/overview/:space_id'],
        id: 'space',
        component: Space,
      },
      {
        path: ['/overview/:space_id/scan'],
        id: 'scan',
        component: Scan,
      },
      {
        path: ['/devices'],
        id: 'devices',
        component: Devices,
      },
      {
        path: ['/devices/:device_id'],
        id: 'device',
        component: Device,
      },
    ],
  },
  {
    id: 'store',
    headerId: '',
    canAccessRoute: ({ configuration }) =>
      isCustomerFeatureAll(configuration?.portal_capabilities?.b2c_view) ||
      isCustomerFeatureLabOnly(
        configuration?.portal_capabilities?.b2c_view,
        configuration?.[TenantType.Organization].lab
      ),
    childRoutes: [
      {
        path: ({ configuration }) =>
          configuration?.portal_capabilities?.store === true
            ? ['/', '/store']
            : ['/store'],
        component: StorePage,
      },
      {
        path: '/store/:storeListingSlug',
        component: StoreListingPage,
      },
    ],
  },
  {
    id: 'settings',
    headerId: '',
    canAccessRoute: ({ isAdmin }) => isAdmin,
    childRoutes: [
      {
        path: ['/settings', `/settings/:${SETTING_PAGE_ID_URL_PARAM_NAME}/*`],
        hasBorder: true,
        id: 'sidebar.settings',
        component: lazy(() =>
          import('../desktop/pages/settings/Settings').then(({ Settings }) => ({
            default: Settings,
          }))
        ),
      },
    ],
  },
  {
    id: 'new_card_confirmation',
    headerId: '',
    canAccessRoute: ({ isAdmin }) => isAdmin,
    childRoutes: [
      {
        path: ['/new-card-confirmation'],
        component: lazy(
          () =>
            import('../desktop/pages/settings/credit-cards/card-confirmation')
        ),
      },
    ],
  },
  {
    id: 'products',
    headerId: '',
    canAccessRoute: ({ configuration }) =>
      (isCustomerFeatureAll(configuration?.portal_capabilities?.b2c_view) ||
        isCustomerFeatureLabOnly(
          configuration?.portal_capabilities?.b2c_view,
          configuration?.[TenantType.Organization].lab
        )) &&
      configuration?.portal_capabilities?.purchased_products === true,
    childRoutes: [
      {
        path: ({ configuration }) =>
          configuration?.portal_capabilities?.store !== true
            ? ['/', '/products', '/products/*']
            : ['/products', '/products/*'],
        id: 'sidebar.digitalProducts.products',
        component: lazy(() =>
          import('../desktop/pages/products/Products').then(({ Products }) => ({
            default: Products,
          }))
        ),
        children: [
          {
            path: ['/products/purchased-products/*'],
            id: 'purchasedProducts',
            component: PurchasedProducts,
          },
          {
            path: '/products/subscriptions/*',
            id: 'subscriptions',
            component: Subscriptions,
          },
          {
            path: '/products/orders/*',
            id: 'orders',
            component: Orders,
          },
          {
            path: '/products/invoices/*',
            id: 'invoices',
            component: Invoices,
          },
        ],
      },
    ],
  },
  {
    id: 'checkout',
    headerId: '',
    canAccessRoute: ({ configuration, isAdmin }) =>
      (isCustomerFeatureAll(configuration?.portal_capabilities?.b2c_view) ||
        isCustomerFeatureLabOnly(
          configuration?.portal_capabilities?.b2c_view,
          configuration?.[TenantType.Organization].lab
        )) &&
      isAdmin,
    childRoutes: [
      {
        path: ['/checkout/card-confirmation'],
        component: lazy(
          () => import('../desktop/pages/checkout/steps/card-confirmation')
        ),
        isVisibleInSidebar: () => false,
      },
      {
        path: ['/checkout', '/checkout/:id'],
        component: lazy(() => import('../desktop/pages/checkout')),
        isVisibleInSidebar: () => false,
      },
    ],
  },
];

const routeModals: RoutesMap<OrganizationConfigurationType>['routeModals'] = [
  {
    id: 'order',
    path: '/m/order/:order_id',
    component: Order,
    backgroundPath: '/products/orders',
    generatePath: ([orderId, queryParam]: [string, string | undefined]) =>
      queryParam ? `/m/order/${orderId}?${queryParam}` : `/m/order/${orderId}`,
  },
  {
    id: 'device',
    path: '/m/device/:device_id/*',
    component: DesktopDeviceRedirect,
    backgroundPath: '/devices',
    generatePath: ([deviceId]: [string]) => `/m/device/${deviceId}`,
  },
  {
    id: 'connect',
    path: '/m/connect',
    component: ConnectRouteModalSelector,
    backgroundPath: '/overview',
    generatePath: ([partnerName, cloudId]) => {
      const searchParams = new URLSearchParams();

      if (partnerName) {
        searchParams.set(PARTNER_SEARCH_PARAM, partnerName);
      }
      if (cloudId) {
        searchParams.set(CLOUD_ID_SEARCH_PARAM, cloudId);
      }

      if (searchParams.size) {
        return `/m/connect?${searchParams.toString()}`;
      }

      return '/m/connect';
    },
  },
];

const routePanels: RoutesMap<OrganizationConfigurationType>['routePanels'] = [
  {
    id: 'subscription',
    path: '/p/subscription/:subscription_id',
    component: SubscriptionRoutePanel,
    backgroundPath: '/products/subscriptions',
    canAccessRoute: ({ configuration }) =>
      configuration?.portal_capabilities?.purchased_products === true &&
      (isCustomerFeatureAll(configuration?.portal_capabilities?.b2c_view) ||
        isCustomerFeatureLabOnly(
          configuration?.portal_capabilities?.b2c_view,
          configuration?.[TenantType.Organization].lab
        )),
    generatePath: ([subscriptionId]: string[]) =>
      `/p/subscription/${subscriptionId}`,
  },
];

export const mobileRoutes: RoutesMap<OrganizationConfigurationType> = {
  externalStore: [
    {
      path: '/store',
      id: 'externalStore',
      component: ExternalStorePage,
    },
    {
      path: '/store/:storeListingSlug',
      id: 'externalStoreListing',
      component: ExternalStoreListingPage,
    },
  ],
  dashboard,
  routePanels,
  routeModals,
  auth: authRoutes,
};
