import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';
import { DeviceType } from '@portals/types';

import { QR_CODE_API_URL } from './qr-code.constants';
import { ServerError } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils';
import { globalQueryKeys } from '../global-query-keys';
import { spacesQueryKeys } from '../spaces';

interface UseCheckQrCodeAccessResponse {
  claim_allowed: boolean;
  device_partner_name: string;
  device_id: string;
}

export function useCheckQrCodeAccess() {
  const dispatch = useDispatch();

  const { url, options } = useRequestOptions({
    url: `${QR_CODE_API_URL}/check_access`,
    method: 'GET',
  });

  return useMutation<UseCheckQrCodeAccessResponse, ServerError, { qr: string }>(
    {
      mutationFn: ({ qr }) => {
        const composedUrl = new URL(url);
        composedUrl.searchParams.set('qr', qr);

        return fetchApiRequest(composedUrl.toString(), options);
      },
      onError: ({ error }) => {
        dispatch(toastrError(error));
      },
    }
  );
}

interface UseClaimDeviceByQrCodeResponse {
  status: 'device_already_claimed_to_requested_organization' | 'success';
  device: DeviceType;
}

interface UseClaimDeviceByQrCodeParams {
  qr: string;
  space_id?: string;
}

export function useClaimDeviceByQrCode() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { url, options } = useRequestOptions({
    url: QR_CODE_API_URL,
    method: 'POST',
  });

  return useMutation<
    UseClaimDeviceByQrCodeResponse,
    ServerError,
    UseClaimDeviceByQrCodeParams
  >({
    mutationFn: ({ qr, space_id }) => {
      return fetchApiRequest(url, {
        ...options,
        body: JSON.stringify({ qr, space_id }),
      });
    },
    onSuccess: async ({ status, device }) => {
      await queryClient.invalidateQueries(globalQueryKeys.devices);

      setTimeout(() => {
        queryClient.invalidateQueries(spacesQueryKeys.base);
      }, 2000);

      if (status === 'device_already_claimed_to_requested_organization') {
        dispatch(
          toastrSuccess(
            'This device is already claimed, you can review it in the dashboard'
          )
        );

        return device;
      }

      dispatch(toastrSuccess('Device claimed successfully'));

      return device;
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useClaimDeviceByQrCode',
      baseUrl: QR_CODE_API_URL,
      method: 'POST',
    },
  });
}
