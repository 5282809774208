import { UseQueryResult } from '@tanstack/react-query';
import React, { createContext, PropsWithChildren, useContext } from 'react';

export const PaginatedQueryContext = createContext(undefined);

export function PaginatedQueryProvider<TData>({
  children,
  query,
}: PropsWithChildren<{ query: UseQueryResult<TData> }>) {
  return (
    <PaginatedQueryContext.Provider value={query}>
      {children}
    </PaginatedQueryContext.Provider>
  );
}

export const usePaginatedQueryContext = () => useContext(PaginatedQueryContext);
