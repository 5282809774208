import {
  createStyles,
  Group,
  Stack,
  Text,
  Title,
  UnstyledButton,
} from '@mantine/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useOrganizationConfig } from '@portals/api/organizations';
import { StoreListingStatusBadge } from '@portals/framework';
import { ReactComponent as ArrowLeft } from '@portals/icons/linear/arrow-left.svg';
import { CurrencyCode, OrganizationStoreListing } from '@portals/types';

import { ProductPrice } from './ProductPrice';

interface ProductHeaderProps {
  storeListing: OrganizationStoreListing;
  currencyCode: CurrencyCode | null;
  baseStoreUrl: string;
}

export function ProductHeader({
  storeListing,
  currencyCode,
  baseStoreUrl,
}: ProductHeaderProps) {
  const navigate = useNavigate();
  const organization = useOrganizationConfig();
  const { classes } = useStyles();

  const modelName = storeListing.product?.device_model_name;

  return (
    <Stack spacing="xs">
      <UnstyledButton onClick={() => navigate(baseStoreUrl)}>
        <Group
          spacing="xs"
          align="center"
          position="left"
          className={classes.backButton}
        >
          <ArrowLeft style={{ width: 16, height: 16 }} />

          <Text size="sm" color="blue_gray">
            Back To Store
          </Text>

          {organization?.lab ? (
            <StoreListingStatusBadge status={storeListing.status} ml="auto" />
          ) : null}
        </Group>
      </UnstyledButton>

      {modelName ? (
        <Title order={3} className={classes.modelName}>
          {modelName}
        </Title>
      ) : null}

      <Title
        order={1}
        className={classes.productName}
        data-testid="product-name-title"
      >
        {storeListing.product.name}
      </Title>

      <ProductPrice storeListing={storeListing} currencyCode={currencyCode} />
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  backButton: {
    marginBottom: theme.spacing.md,

    [theme.fn.largerThan('xl')]: {
      marginBottom: 40,
    },
  },
  modelName: {
    color: theme.colors.blue_gray[6],
    fontWeight: 600,
    fontSize: theme.fontSizes.xl,
  },
  productName: {
    color: theme.colors.blue_gray[9],
    fontSize: 28,
    fontWeight: 400,
    width: '100%',
  },
}));
