import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getAuth } from '@portals/redux';
import { getAuthHeaders } from '@portals/redux/actions/auth';
import { MethodType, StateType } from '@portals/types';

import { OptionsType } from '../types';

export interface ApiRequest {
  url: string;
  method?: MethodType;
  data?: any;
  headers?: Headers;
}

export const buildUrl = (uri: string): string =>
  process.env.NX_SERVER_URL + uri;

export const getRequestOptions = (
  { method, data, headers, url }: ApiRequest,
  auth?: StateType['ui']['auth']
) => {
  // Set default headers and options
  const options: OptionsType = {
    headers: new Headers({ 'content-type': 'application/json' }),
    cache: 'no-store',
  };

  if (headers) {
    headers.forEach((value, key) => {
      options.headers.append(key, value);
    });
  }

  if (auth) {
    const authHeaders = getAuthHeaders(auth);

    authHeaders.forEach((value, key) => {
      options.headers.append(key, value);
    });
  }

  if (method) {
    options.method = method;
  }

  // Turn into POST (or other) request if data was passed
  if (data) {
    options.method = options.method || 'post';
    options.body = JSON.stringify(data);
  }

  return { options, url: buildUrl(url as string) };
};

export const useRequestOptions = (requestOptions: ApiRequest) => {
  const auth = useSelector(getAuth);

  return useMemo(
    () => getRequestOptions(requestOptions, auth),
    [requestOptions, auth]
  );
};

export const fetchApiRequest = async (url: string, options?: OptionsType) => {
  const response = await fetch(url, options);

  if (!response.ok) {
    const responseJson = await response.json();

    if (response.status === 401) {
      // @todo: hotfix auth endless loop in orgs portal
      localStorage.removeItem('auth');
      window.location.assign('/auth/sign-out');
      return Promise.reject({ type: 'NOT_AUTHORIZED', data: responseJson });
    }

    return Promise.reject(responseJson);
  }

  if (response.status === 204) {
    return;
  }

  if (response.text) {
    const responseText = await response.text();

    return responseText ? JSON.parse(responseText) : {};
  }
};
