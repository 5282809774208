import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAuthError } from '@portals/redux';
import { resetAuthError } from '@portals/redux/actions/ui';

import { AuthWrapper } from './common';
import { Signin } from '../../../desktop/pages/auth/Signin';
import Notification from '../../components/layout/Notification';

export const SignIn = () => {
  const dispatch = useDispatch();
  const authError = useSelector(getAuthError);

  return (
    <AuthWrapper>
      <Signin />

      <Notification
        error={authError}
        onClear={() => dispatch(resetAuthError())}
      />
    </AuthWrapper>
  );
};
