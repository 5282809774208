import {
  createStyles,
  Divider,
  Flex,
  Group,
  MediaQuery,
  Pagination,
  Text,
} from '@mantine/core';
import { ceil, min } from 'lodash/fp';
import React, { useMemo } from 'react';

import { useTableInstance } from '../context';

interface FooterProps {
  isCompact?: boolean;
  totalCount: number;
}

export function Footer({ isCompact, totalCount }: FooterProps) {
  const { state, gotoPage } = useTableInstance();
  const { classes, cx } = useTableFooterStyles({ isCompact });

  const label = useMemo(() => {
    const firstRowIndex = state.pageIndex * state.pageSize;
    const lastRowIndex = firstRowIndex + state.pageSize;

    if (!totalCount) return `0 items`;

    return `${firstRowIndex + 1} - ${min([
      lastRowIndex,
      totalCount,
    ])} of ${totalCount} items`;
  }, [totalCount, state.pageIndex, state.pageSize]);

  return (
    <Flex
      justify="end"
      gap="md"
      direction={{ base: 'column', md: 'row' }}
      align={{ base: 'end', md: 'center' }}
      className={cx('table-footer-container', classes.container)}
    >
      {totalCount > state.pageSize ? (
        <>
          <Pagination
            size="sm"
            withEdges
            data-testid="table-pagination"
            total={ceil(totalCount / state.pageSize)}
            value={state.pageIndex + 1}
            onChange={(pageIndex) => gotoPage(pageIndex - 1)}
          />

          <MediaQuery smallerThan="md" styles={{ display: 'none' }}>
            <Group>
              <Divider sx={{ height: 24 }} orientation="vertical" />
            </Group>
          </MediaQuery>
        </>
      ) : null}

      <Text
        pr="md"
        size="xs"
        sx={(theme) => ({
          color: theme.colors.gray[4],
        })}
      >
        {label}
      </Text>
    </Flex>
  );
}

export const useTableFooterStyles = createStyles<any, { isCompact: boolean }>(
  (theme, params) => {
    const common = {
      backgroundColor: theme.white,
      borderTop: 'none',
    };

    return params.isCompact
      ? {
          container: {
            ...common,
            border: 'none',
            borderBottom: `1px solid ${theme.colors.gray[3]}`,
            padding: theme.spacing.xs,
          },
        }
      : {
          container: {
            ...common,
            border: `1px solid ${theme.colors.gray[3]}`,
            paddingBlock: theme.spacing.md,
            paddingInline: theme.spacing.xl,
          },
        };
  }
);
