import {
  Button,
  createStyles,
  LoadingOverlay,
  Modal,
  ModalProps as MantineModalProps,
  Stack,
  Text,
} from '@mantine/core';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  SpaceType,
  useCecPartners,
  useDeviceModels,
} from '@portals/api/organizations';
import { ModalProps, usePermissionAccess } from '@portals/framework';
import { useOpenRouteModal } from '@portals/framework/route-modals';
import { useOpenModal } from '@portals/redux';

import { BrandCard } from './BrandCard';
import { CecPartnersEmptyState } from './CecPartnersEmptyState';
import { ClaimDeviceSearchInput } from './ClaimDeviceSearchInput';
import { useActiveCloudIntegrations } from '../../../../hooks/c2c-integrations';
import { AddDeviceModalProps } from '../AddDeviceModal';
import { SelectModelFromPartnerModalProps } from '../SelectModelFromPartnerModal';

export interface ClaimDeviceModalProps
  extends ModalProps<{ spaceId?: SpaceType['id'] }> {}

export function ClaimDeviceModal({
  closeMe,
  data: { spaceId },
}: ClaimDeviceModalProps) {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const cecPartners = useCecPartners();
  const openModal = useOpenModal();
  const openRouteModal = useOpenRouteModal();
  const { isAdmin } = usePermissionAccess();

  const deviceModels = useDeviceModels();
  const activeIntegrations = useActiveCloudIntegrations();

  const connectedPartners = useMemo(
    () => (cecPartners.data || []).filter((partner) => partner.connected),
    [cecPartners.data]
  );

  const showEmptyState =
    connectedPartners.length === 0 && activeIntegrations.length === 0;

  const handleSelectedPartner = (partnerId: string) => {
    closeMe();

    openModal<SelectModelFromPartnerModalProps['data']>(
      'SelectModelFromPartnerModal',
      {
        spaceId,
        partnerId: partnerId,
      }
    );
  };

  const handleSelectedDeviceModel = (modelId: string) => {
    closeMe();

    const selectedModel = deviceModels.data?.find(
      (model) => model.id === modelId
    );

    openModal<AddDeviceModalProps['data']>('AddDeviceModal', {
      spaceId,
      selectedModel,
    });
  };

  const handleSelectedC2cIntegration = (href?: string) => {
    closeMe();
    if (!href) return;
    navigate(href);
  };

  const onConnectClick = () => {
    closeMe();
    openRouteModal({ modalId: 'connect' });
  };

  return (
    <Modal
      opened
      onClose={closeMe}
      title="Add New Device"
      size="100%"
      padding={0}
      styles={modalStyles}
    >
      <div className={classes.bodyContent}>
        <LoadingOverlay visible={cecPartners.isInitialLoading} />

        {showEmptyState ? (
          <CecPartnersEmptyState handelConnectClick={onConnectClick} />
        ) : (
          <Stack spacing="xl" h="100%">
            <Stack align="center" h="100%">
              <Text size="md" color="gray.6" data-testid="brand-selection">
                Select the brand from which you would like to add the device
              </Text>

              <ClaimDeviceSearchInput
                handleSelectedPartner={handleSelectedPartner}
                handleSelectedDeviceModel={handleSelectedDeviceModel}
                handleSelectedC2cIntegration={handleSelectedC2cIntegration}
                activeIntegrations={activeIntegrations}
              />

              <div className={classes.grid}>
                {connectedPartners.map(({ id, display_name, logo }) => (
                  <BrandCard
                    key={id}
                    name={display_name}
                    logoUrl={logo || ''}
                    onClick={() => handleSelectedPartner(id)}
                  />
                ))}

                {activeIntegrations.map(({ id, name, logo, href }) => (
                  <BrandCard
                    key={id}
                    name={name}
                    logoUrl={logo}
                    onClick={() => handleSelectedC2cIntegration(href)}
                  />
                ))}
              </div>
            </Stack>

            {isAdmin ? (
              <Stack align="center">
                <Stack spacing={4} align="center">
                  <Text size="lg" weight={500}>
                    Can't find your brand?
                  </Text>

                  <Text ta="center">
                    Connect them now using Connect+ to seamlessly integrate your
                    devices!
                  </Text>
                </Stack>

                <Button
                  onClick={onConnectClick}
                  data-testid="go-to-connect-button"
                >
                  Explore brands
                </Button>
              </Stack>
            ) : null}
          </Stack>
        )}
      </div>
    </Modal>
  );
}

const modalStyles: MantineModalProps['styles'] = (theme) => ({
  content: {
    minWidth: '100%',
    minHeight: '100%',
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    backgroundColor: theme.colors.gray[0],
  },
  header: {
    padding: theme.spacing.xl,
  },
});

const useStyles = createStyles((theme) => ({
  bodyContent: {
    position: 'relative',
    height: '100%',
    maxWidth: 1280,
    marginInline: 'auto',
    padding: theme.spacing.xxl,
  },

  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(170px, 1fr))',
    gridAutoRows: 'minmax(170px, auto)',
    gap: theme.spacing.md,
    placeContent: 'start',
    alignSelf: 'flex-start',
    width: '100%',
  },
}));
