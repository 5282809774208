import {
  Button,
  LoadingOverlay,
  MultiSelect,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import React from 'react';
import { array, object, string } from 'yup';

import { useGroups } from '@portals/api/organizations';
import { useCreateUser } from '@portals/api/ui';
import { MembersSVG } from '@portals/assets';
import {
  ModalBody,
  ModalCenteredMediaLayout,
  ModalFooter,
} from '@portals/core';
import { ModalProps } from '@portals/framework';

const schema = object({
  email: string().required().email('Please enter a valid email address'),
  groupIds: array().required('Please select a group'),
});

export function AddUserModal({ closeMe }: ModalProps) {
  const groups = useGroups();
  const createUser = useCreateUser();

  const groupOptions = composeGroupOptions(groups.data);

  const form = useForm({
    validate: yupResolver(schema),
    initialValues: {
      email: '',
      name: '',
      groupIds: undefined,
    },
  });

  const onSubmit = async (values: typeof form.values) => {
    try {
      await createUser.mutateAsync({
        name: values.name,
        email: values.email,
        groupIds: values.groupIds,
      });

      closeMe();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <ModalCenteredMediaLayout
      opened
      onClose={closeMe}
      title="Add a new user"
      media={<MembersSVG />}
    >
      <form noValidate onSubmit={form.onSubmit(onSubmit)}>
        <ModalBody>
          <LoadingOverlay visible={groups.isFetching} />

          <Stack>
            <TextInput
              required
              data-autofocus
              label="Email"
              placeholder="Email"
              data-testid="add-user-modal-email-input"
              {...form.getInputProps('email')}
            />

            <TextInput
              required
              label="Name"
              placeholder="Name"
              data-testid="add-user-modal-name-input"
              {...form.getInputProps('name')}
            />

            <MultiSelect
              required
              label="User groups"
              placeholder="Choose groups"
              data={groupOptions}
              data-testid="add-user-modal-groups-select"
              {...form.getInputProps('groupIds')}
            />

            {createUser.error && (
              <Text color="red" data-testid="error-text">
                {createUser.error.error}
              </Text>
            )}
          </Stack>
        </ModalBody>
        <ModalFooter position="right">
          <Button
            variant="default"
            data-testid="add-user-modal-cancel-button"
            onClick={closeMe}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            data-testid="add-user-modal-create-button"
            loading={createUser.isLoading}
          >
            Create
          </Button>
        </ModalFooter>
      </form>
    </ModalCenteredMediaLayout>
  );
}

function composeGroupOptions(groups: ReturnType<typeof useGroups>['data']) {
  if (!groups) return [];

  return groups.map((group) => ({
    value: group.id,
    label: group.name,
  }));
}
