import {
  STORE_SETTINGS_API_URL,
  storeSettingsQueryKeys,
} from './store-settings.constants';
import { StoreSettings } from './store-settings.types';
import { useApiQuery } from '../../hooks';
import { QueryOptions } from '../../types';

export function useStoreSettings(queryOptions?: QueryOptions<StoreSettings>) {
  return useApiQuery<StoreSettings>(
    STORE_SETTINGS_API_URL,
    storeSettingsQueryKeys.all,
    queryOptions
  );
}
