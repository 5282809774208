import {
  BackgroundImage,
  Center,
  createStyles,
  LoadingOverlay,
  Stack,
  Text,
} from '@mantine/core';
import React, { ReactNode } from 'react';
import { useMatch } from 'react-router-dom';

import { usePortalConfigByPartnerName } from '@portals/api/organizations';
import { XyteLogo } from '@portals/assets';
import { useAppConfig } from '@portals/framework/context';

import authPageBackgroundImage from './cec-auth-page-bg.webp';

const DEFAULT_LOGO = 'logo_full_dark.svg';
const DEFAULT_TAGLINE = 'One cloud, endless opportunities';

export function AuthDesktopLayoutCEC({ children }: { children: ReactNode }) {
  const { classes } = useStyles();
  const { extraLayout } = useAppConfig();

  // This layout is used for various auth pages (sign-in, sign-up, welcome, change-password and reset-password),
  // hence making the middle segment dynamic.
  const match = useMatch('/auth/:auth_type/:partner_name?');

  const portalConfig = usePortalConfigByPartnerName(
    match?.params?.partner_name
  );

  if (portalConfig.isInitialLoading) {
    return <LoadingOverlay visible />;
  }

  const logoSrc =
    portalConfig.data?.cec_partner_config?.auth_page_logo || DEFAULT_LOGO;
  const tagline =
    portalConfig.data?.cec_partner_config?.auth_page_tagline || DEFAULT_TAGLINE;

  return (
    <>
      {extraLayout?.globalLayout?.()}

      <div className={classes.container}>
        <Stack align="center" justify="center" className={classes.formColumn}>
          <Center sx={{ flexGrow: 1, maxWidth: 470 }}>{children}</Center>

          <Stack spacing="xs" align="center">
            <Text color="gray.5">Powered by</Text>
            <XyteLogo width={80} />
          </Stack>
        </Stack>

        <BackgroundImage
          className={classes.brandColumn}
          src={authPageBackgroundImage}
        >
          <Stack align="center">
            <img width={150} height="auto" src={logoSrc} alt="logo" />

            <Text size="xl" color="gray.9">
              {tagline}
            </Text>
          </Stack>
        </BackgroundImage>
      </div>
    </>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '2fr 3fr',
    gridTemplateAreas: `"form brand"`,
    minHeight: '100%',
    backgroundColor: theme.white,

    [theme.fn.smallerThan('lg')]: {
      gridTemplateColumns: '1fr',
      gridTemplateAreas: `
        "brand"
        "form"
      `,
    },
  },
  formColumn: {
    gridArea: 'form',
    padding: 42,
  },
  brandColumn: {
    gridArea: 'brand',
    padding: 42,
    borderTopLeftRadius: theme.radius.xl,
    borderBottomLeftRadius: theme.radius.xl,

    [theme.fn.smallerThan('lg')]: {
      borderRadius: 0,
    },
  },
}));
