import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';

import { PRICING_PLANS_API_URL } from './pricing-plans.constants';
import { ServerError } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils';
import { configurationQueryKeys } from '../configuration';

export function useStartFreeTrial() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: `${PRICING_PLANS_API_URL}/start_trial`,
    method: 'POST',
  });

  return useMutation<void, ServerError, void>({
    mutationFn: () => {
      return fetchApiRequest(url, options);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(configurationQueryKeys.config);
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useStartFreeTrial',
      baseUrl: `${PRICING_PLANS_API_URL}/start_trial`,
      method: 'POST',
    },
  });
}

export function useRequestPlanUpgrade() {
  const dispatch = useDispatch();
  const { url, options } = useRequestOptions({
    url: `${PRICING_PLANS_API_URL}/request_plan_upgrade`,
    method: 'POST',
  });

  return useMutation<void, ServerError, { source: string }>({
    mutationFn: ({ source }) =>
      fetchApiRequest(url, { ...options, body: JSON.stringify({ source }) }),
    onSuccess: () => {
      dispatch(
        toastrSuccess("We'll contact you shortly to upgrade your plan!")
      );
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useRequestPlanUpgrade',
      baseUrl: `${PRICING_PLANS_API_URL}/request_plan_upgrade`,
      method: 'POST',
    },
  });
}
