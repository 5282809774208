import { without } from 'lodash/fp';
import React, { MutableRefObject, ReactElement } from 'react';

import { OverflowRenderer } from './types';

export const observeChildren =
  ({
    parentElement,
    hiddenIndexesRef,
    onUpdate,
    indexOffset,
  }: {
    parentElement: HTMLElement;
    hiddenIndexesRef: MutableRefObject<Array<number>>;
    onUpdate: (numOfHidden: number) => void;
    indexOffset: number;
  }) =>
  (observerEntry: Array<IntersectionObserverEntry>) => {
    observerEntry.forEach((observerEntry) => {
      let elementIndex = -1 + indexOffset;

      for (let i = indexOffset; i < parentElement.children.length; i++) {
        const currElement = parentElement.children[i];

        if (observerEntry.target === currElement) {
          elementIndex = i - indexOffset;
          break;
        }
      }

      if (
        observerEntry.isIntersecting &&
        hiddenIndexesRef.current.includes(elementIndex)
      ) {
        hiddenIndexesRef.current = [
          ...without([elementIndex], hiddenIndexesRef.current),
        ];
      } else if (
        !observerEntry.isIntersecting &&
        !hiddenIndexesRef.current.includes(elementIndex)
      ) {
        hiddenIndexesRef.current.push(elementIndex);
      }
    });

    onUpdate(hiddenIndexesRef.current.length);
  };

export const getOverflowComponent = ({
  overflowRenderer,
  amount,
  hiddenIndexes,
}: {
  overflowRenderer: OverflowRenderer;
  amount: number;
  hiddenIndexes: Array<number>;
}) => (
  <>
    {overflowRenderer({
      amount,
      hiddenIndexes,
    })}
  </>
);

export const getChildrenListWithRef = ({
  children,
  refsList,
  numOfChildren,
  onAllRefsSet,
}: {
  children: Array<ReactElement>;
  refsList: MutableRefObject<Array<HTMLElement>>;
  numOfChildren?: number;
  onAllRefsSet?: () => void;
}): Array<ReactElement> =>
  React.Children.map(children, (childElement: ReactElement, index: number) => {
    const isLast = numOfChildren === index + 1;

    return React.cloneElement(childElement, {
      ...childElement.props,
      ref: (node: HTMLElement) => {
        refsList.current[index] = node;

        if (isLast && onAllRefsSet) {
          onAllRefsSet();
        }
      },
    });
  });
