import { Button, Stack, Text } from '@mantine/core';
import moment from 'moment';
import React from 'react';

export function Message() {
  const notificationTime = moment().calendar(null, {
    lastWeek: '[Last] dddd LT',
    lastDay: '[Yesterday] LT',
    sameDay: '[Today] LT',
    sameElse: 'DD/MM/YYYY LT',
  });

  return (
    <Stack spacing="xl" className="notification-content">
      <Stack spacing="xs">
        <Text color="blue_gray">
          A new version was released.
          <br />
          Please click here to reload.
        </Text>
        <Text size="xs" c="blue_gray.3">
          {notificationTime}
        </Text>
      </Stack>

      <Button onClick={() => window.location.reload()}>Reload</Button>
    </Stack>
  );
}
