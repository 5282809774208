import { Box, createStyles, Group, Image, Stack, Text } from '@mantine/core';
import React, { Dispatch, SetStateAction } from 'react';

import { PostPurchaseParametersType } from '@portals/api/organizations';
import { ReactComponent as Gallery } from '@portals/icons/linear/gallery.svg';

import { getProductPPPStatus } from './post-purchase-parameters.utils';

interface PostPurchaseParametersProductsListProps {
  selectedProductIndex: number;
  setSelectedProductIndex: Dispatch<SetStateAction<number>>;
  postPurchaseParams: Array<PostPurchaseParametersType>;
}

export function PostPurchaseParametersProductsList({
  postPurchaseParams,
  setSelectedProductIndex,
  selectedProductIndex,
}: PostPurchaseParametersProductsListProps) {
  const { classes, cx } = useStyles();

  if (!postPurchaseParams?.length) return null;

  return (
    <>
      {postPurchaseParams.map((product, index) => {
        const status = getProductPPPStatus(product);

        return (
          <Group
            key={product.id}
            px="md"
            py="sm"
            spacing="xs"
            noWrap
            className={cx(classes.container, {
              selected: selectedProductIndex === index,
            })}
            onClick={() => setSelectedProductIndex(index)}
          >
            <Image
              height={48}
              width={48}
              src={product.image_url}
              alt={product.name}
              withPlaceholder
              placeholder={<Gallery />}
              radius="sm"
            />

            <Stack sx={{ overflow: 'hidden' }} justify="center" spacing={4}>
              <Text
                truncate
                size="sm"
                title={product.name}
                className="product-name"
                color={
                  selectedProductIndex === index
                    ? 'blue_accent.4'
                    : 'blue_gray.9'
                }
              >
                {product.name}
              </Text>

              {status ? (
                <Group spacing="xs">
                  <Box
                    w={8}
                    h={8}
                    bg={status.indicator}
                    sx={{ borderRadius: '50%' }}
                  />

                  <Text size="xs" color="blue_gray.4">
                    {status.label}
                  </Text>
                </Group>
              ) : null}
            </Stack>
          </Group>
        );
      })}
    </>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    borderRadius: theme.radius.md,
    transition: 'background-color 150ms ease-in-out',

    '.product-name': {
      transition: 'color 150ms ease-in-out',
    },

    '&.selected': {
      backgroundColor: theme.fn.rgba(theme.colors.blue[0], 0.5),
    },

    '&:not(.selected):hover': {
      backgroundColor: theme.colors.gray[0],
      cursor: 'pointer',
    },
  },
}));
