import { Badge, Group, Text, Tooltip } from '@mantine/core';
import { sum } from 'lodash/fp';
import React, { FC } from 'react';

import { DeviceType, useDeviceCommands } from '@portals/api/organizations';
import { IncidentsByDeviceTooltip } from '@portals/framework';
import { ReactComponent as Danger } from '@portals/icons/linear/danger.svg';
import { PaginatedFilterTypeEnum } from '@portals/types';
import { getDeviceHighestIncidentPriority } from '@portals/utils';

interface DeviceIncidentsCounterProps {
  device: DeviceType;
}

export const DeviceCounters: FC<DeviceIncidentsCounterProps> = ({ device }) => {
  const numOfIncidents = sum(device.incidents);

  if (!numOfIncidents) return null;

  return (
    <IncidentsByDeviceTooltip device={device}>
      <Group align="center" sx={{ height: '100%' }}>
        <Badge
          color={getDeviceHighestIncidentPriority(device.incidents)}
          radius="xl"
          px="xs"
          size="sm"
          variant="filled"
        >
          {numOfIncidents}
        </Badge>
      </Group>
    </IncidentsByDeviceTooltip>
  );
};

export const DeviceIncidentsCounterWithIcon: FC<
  DeviceIncidentsCounterProps
> = ({ device }) => {
  const numOfIncidents = sum(device.incidents);

  if (!numOfIncidents) return null;

  return (
    <IncidentsByDeviceTooltip device={device}>
      <Group align="center" spacing="xs">
        <Danger
          color={getDeviceHighestIncidentPriority(device.incidents)}
          width={18}
          height={18}
        />

        <Text>{numOfIncidents}</Text>
      </Group>
    </IncidentsByDeviceTooltip>
  );
};

export const DeviceCommandsCounter: FC<DeviceIncidentsCounterProps> = ({
  device,
}) => {
  const deviceCommands = useDeviceCommands(device.id, {
    pagination: {
      page: 0,
      pageSize: 1,
    },
    filters: [
      {
        id: 'status',
        value: 'pending',
        type: PaginatedFilterTypeEnum.Eq,
      },
    ],
  });

  const numOfPendingCommands = deviceCommands.data?.page_info?.total_count || 0;

  if (!numOfPendingCommands) return null;

  return (
    <Tooltip label={`Commands: ${numOfPendingCommands} pending`} withinPortal>
      <Group align="center" sx={{ height: '100%' }}>
        <Badge color="blue_gray" radius="xl" px="xs" size="sm" variant="filled">
          {numOfPendingCommands}
        </Badge>
      </Group>
    </Tooltip>
  );
};
