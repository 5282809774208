import { API } from '@portals/redux';
import {
  replaceNormalizedData,
  updateNormalizedData,
} from '@portals/redux/actions/data';
import { INTEGRATIONS_SCHEMA } from '@portals/redux/schemas';
import { upcaseFirst } from '@portals/utils';

export const testConnection = (type, params) => ({
  type: API,
  meta: {
    requiresAdminAccess: true,
  },
  payload: {
    method: 'POST',
    url: `ui/organization/integrations/${type}/test`,
    data: params,
    toastr: 'Test connection',
  },
});

export const addIntegration = (
  type,
  params,
  extraAction?: Array<any>,
  onError?: any
) => ({
  type: API,
  meta: {
    requiresAdminAccess: true,
  },
  payload: {
    method: 'POST',
    url: `ui/organization/integrations`,
    data: { id: type },
    normalize: INTEGRATIONS_SCHEMA,
    success: [updateNormalizedData('integrations'), extraAction],
    error: onError,
    name: `adding ${type}_integration`,
    withError: true,
  },
});

export const updateIntegration = (
  type,
  params,
  extraAction?: Array<any>,
  onError?: any
) => ({
  type: API,
  meta: {
    requiresAdminAccess: true,
  },
  payload: {
    method: 'PUT',
    url: `ui/organization/integrations/${type}`,
    data: params,
    normalize: INTEGRATIONS_SCHEMA,
    success: [updateNormalizedData('integrations'), extraAction],
    toastr: `${upcaseFirst(type)} integration updated`,
    error: onError,
    name: `integration_${type}`,
    withError: true,
  },
});

export const fetchIntegrations = () => ({
  type: API,
  meta: {
    requiresAdminAccess: true,
  },
  payload: {
    url: 'ui/organization/integrations',
    success: replaceNormalizedData('integrations'),
    normalize: [INTEGRATIONS_SCHEMA],
    name: 'fetchIntegrations',
  },
});

export const reloadIntegration = ({
  type,
  silent = false,
  withError = true,
}: {
  type: string;
  silent?: boolean;
  withError?: boolean;
}) => ({
  type: API,
  meta: {
    requiresAdminAccess: true,
  },
  payload: {
    method: 'POST',
    url: `ui/organization/integrations/${type}/reload`,
    success: updateNormalizedData('integrations'),
    normalize: INTEGRATIONS_SCHEMA,
    name: `reloadIntegration${type}`,
    withError,
    toastr: silent ? null : `${type} integration update`,
  },
});

export const getIntegrationParam = (
  type,
  key,
  subKey = null,
  quiet = true
) => ({
  type: API,
  meta: {
    requiresAdminAccess: true,
  },
  payload: {
    url: `ui/organization/integrations/${type}/param?key=${key}${
      subKey ? '&sub_key=' + subKey : ''
    }`,
    success: updateNormalizedData('integrations'),
    normalize: INTEGRATIONS_SCHEMA,
    name: `getIntegration_${type}_${key}_${subKey || ''}`,
    withError: true,
    toastr: quiet ? undefined : `Get ${type} info from server`,
  },
});

export const removeIntegration = (type) => ({
  type: API,
  meta: {
    requiresAdminAccess: true,
  },
  payload: {
    method: 'DELETE',
    url: `ui/organization/integrations/${type}`,
    success: replaceNormalizedData('integrations'),
    normalize: INTEGRATIONS_SCHEMA,
    name: `removeIntegration${type}`,
    withError: true,
    toastr: `${type} removed`,
  },
});
