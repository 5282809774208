import { createStyles, Stack, Text } from '@mantine/core';
import React, { useMemo } from 'react';

import {
  CurrencyCode,
  OrderPreviewItemType,
  OrganizationStoreListing,
  PaymentIntervalEnum,
  ProductPricingModel,
} from '@portals/types';
import { formatPrice } from '@portals/utils';

interface OrderRowPriceDetailsProps {
  orderPreview: OrderPreviewItemType;
  storeListing: OrganizationStoreListing;
  currency: CurrencyCode;
}

export function OrderRowPriceDetails({
  orderPreview,
  storeListing,
  currency,
}: OrderRowPriceDetailsProps) {
  const { classes } = useStyles();

  const isUsageBased =
    storeListing.product.pricing_model === ProductPricingModel.UsageBased;

  const usageBasedPrice = useMemo(() => {
    if (!isUsageBased) return;

    const pricingOptions = storeListing.prices.find(
      (price) => price.currency === currency
    )?.pricing_options;

    const usageBasedPrice = pricingOptions?.find(
      (option) => option.type === PaymentIntervalEnum.UsageBased
    )?.amount;

    return `${formatPrice({
      value: usageBasedPrice,
      currencyCode: currency,
    })} / ${storeListing.product.usage_based_unit_name}`;
  }, [
    currency,
    isUsageBased,
    storeListing.prices,
    storeListing.product.usage_based_unit_name,
  ]);

  const price = useMemo(() => {
    const { one_time, monthly, yearly } = orderPreview.charges;

    // When the `selected_payment_interval` is `UsageBased`, it means that there's no base price (monthly),
    // thus there's no charge for the product, and it's enough to just display the usage based price.
    if (
      orderPreview.selected_payment_interval === PaymentIntervalEnum.UsageBased
    ) {
      return;
    }

    if (monthly) {
      const monthlyPrice = formatPrice({
        value: monthly.amount_in_scu,
        currencyCode: currency,
      });

      return `${monthlyPrice} / month`;
    } else if (yearly) {
      return `${formatPrice({
        value: yearly.amount_in_scu,
        currencyCode: currency,
      })} / yearly`;
    } else if (one_time) {
      return formatPrice({
        value: one_time.amount_in_scu,
        currencyCode: currency,
      });
    }
  }, [currency, orderPreview.charges, orderPreview.selected_payment_interval]);

  return (
    <Stack spacing="xs" ta="end">
      {price && <Text className={classes.price}>{price}</Text>}

      {usageBasedPrice && (
        <Text className={classes.price}>{usageBasedPrice}</Text>
      )}
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  price: {
    color: theme.colors.blue_gray[9],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,
  },
}));
