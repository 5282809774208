import React, { FC } from 'react';
import { connect } from 'react-redux';

import { closeModal } from '@portals/redux/actions/modal';

import { useAppConfig } from '../context';

export interface ModalProps<TData = any> {
  name: string;
  data: TData;
  closeMe: () => void;
}

// Show component only if data is present
const WrappedModal: FC<ModalProps> = (props) => {
  const { modals } = useAppConfig();

  if (props.data) {
    const Modal = modals[props.name];

    return <Modal {...props} />;
  }

  return null;
};

// Get the data for modal by name
const mapStateToProps = (state, { name }) => ({ data: state.modals[name] });

// Add the close function that closes the modal
const mapDispatchToProps = (dispatch, ownProps) => ({
  closeMe: () => dispatch(closeModal(ownProps.name)),
});

// Show component only if its data is set
const ConnectedWrappedModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedModal);

const Modals = () => {
  const { modals } = useAppConfig();

  return (
    <>
      {Object.keys(modals).map((name) => (
        <ConnectedWrappedModal key={name} name={name} />
      ))}
    </>
  );
};

export default Modals;
