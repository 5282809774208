import { get } from 'lodash/fp';
import { createSelector, Selector } from 'reselect';

import { StateType } from '@portals/types';

export const getStore = (state: StateType): StateType['store'] => state.store;

export const getStoreCart: Selector<StateType, StateType['store']['cart']> =
  createSelector(getStore, get('cart'));

export const getSelectedStoreCurrency: Selector<
  StateType,
  StateType['store']['storeCurrency']
> = createSelector(getStore, get('storeCurrency'));

export const getSelectedShippingAddressId: Selector<
  StateType,
  StateType['store']['shippingAddressId']
> = createSelector(getStore, get('shippingAddressId'));

export const getSelectedBillingAddressId: Selector<
  StateType,
  StateType['store']['billingAddressId']
> = createSelector(getStore, get('billingAddressId'));

export const getSelectedCreditCardId: Selector<
  StateType,
  StateType['store']['creditCardId']
> = createSelector(getStore, get('creditCardId'));

export const getIsSameAddress: Selector<
  StateType,
  StateType['store']['isSameAddress']
> = createSelector(getStore, get('isSameAddress'));

export const getPaymentMethod: Selector<
  StateType,
  StateType['store']['paymentMethod']
> = createSelector(getStore, get('paymentMethod'));

export const getCheckoutNotes: Selector<
  StateType,
  StateType['store']['cart']['notes']
> = createSelector(getStore, get(['cart', 'notes']));

export const getNotesSwitchStatus: Selector<
  StateType,
  StateType['store']['cart']['notesSwitchStatus']
> = createSelector(getStore, get(['cart', 'notesSwitchStatus']));
