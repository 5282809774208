import { LocationType } from './google-timezone.types';

export function getGoogleTimezoneApiUrl(
  location: LocationType | null,
  timestamp: number
) {
  return `https://maps.googleapis.com/maps/api/timezone/json?location=${location?.lat},${location?.lng}&timestamp=${timestamp}&key=${process.env.NX_GOOGLE_TIMEZONE_API_KEY}`;
}

export const googleTimeZoneQueryKeys = {
  timezone: (location: LocationType | null) => [
    'google_timezone',
    `${location?.lat}-${location?.lng}`,
  ],
};
