import { Badge, Center, Text } from '@mantine/core';
import React from 'react';

import { DeviceModelBreakdownViewEnum } from '@portals/api/organizations';
import { DashboardLayoutListItem } from '@portals/types';

import { useChartData } from './device-model-breakdown-widget.hooks';
import { DeviceModelBreakdownChart } from './DeviceModelBreakdownChart';
import { DeviceModelBreakdownEmptyState } from './DeviceModelBreakdownEmptyState';
import { useOverviewContext } from '../../../overview.context';
import { useCurrentSpace } from '../../../overview.hooks';
import { WidgetType } from '../../../overview.types';
import { WidgetCardWrapper } from '../common/WidgetCardWrapper';

interface DeviceModelBreakdownDashboardWidgetParams {
  title: string;
  deviceModelBreakdownView: DeviceModelBreakdownViewEnum;
}

export function DeviceModelBreakdownDashboardWidgetWrapper({
  widgetId,
}: {
  widgetId: string;
}) {
  const { dashboard } = useOverviewContext();

  return dashboard.list.map((item) => {
    //As there is a type issue with the item, we need to cast it to the correct type
    const widget = item as DashboardLayoutListItem<
      WidgetType,
      DeviceModelBreakdownDashboardWidgetParams
    >;

    return item.id === 'devices_model_breakdown' && item.i === widgetId ? (
      <DeviceModelBreakdownDashboardWidget key={item.i} item={widget} />
    ) : null;
  });
}

export function DeviceModelBreakdownDashboardWidget({
  item,
}: {
  item: DashboardLayoutListItem<
    WidgetType,
    DeviceModelBreakdownDashboardWidgetParams
  >;
}) {
  const space = useCurrentSpace();

  const title = item.extraParams?.title;
  const deviceModelBreakdownView = item.extraParams?.deviceModelBreakdownView;

  const { chartDataItems, isChartDataEmpty, totalDevicesCount } = useChartData(
    space,
    deviceModelBreakdownView
  );

  return (
    <WidgetCardWrapper
      stackProps={{ spacing: 'sm' }}
      header={
        <Text size="md" color="gray.5">
          {title}
        </Text>
      }
    >
      <Badge color="gray" variant="outline" px="md" py="sm" w="fit-content">
        {deviceModelBreakdownView === DeviceModelBreakdownViewEnum.OnlineDevices
          ? 'Online Devices'
          : 'All Devices'}
      </Badge>

      <Center>
        {isChartDataEmpty ? (
          <DeviceModelBreakdownEmptyState />
        ) : (
          <DeviceModelBreakdownChart
            chartDataItems={chartDataItems}
            totalDevicesCount={totalDevicesCount}
          />
        )}
      </Center>
    </WidgetCardWrapper>
  );
}
