import React, { useContext } from 'react';
import { Button } from 'reactstrap';

import { IntegrationsContext } from './Integrations';

const EditActive = ({ id }) => {
  const { updateIntegration, integrations } = useContext(IntegrationsContext);
  const data = integrations[id];
  const active = data.state === 'active';

  return (
    <div className="mt-5 mb-5">
      <Button
        color={active ? 'danger' : 'secondary'}
        onClick={() =>
          updateIntegration(id, { ...data.params, enabled: !active })
        }
      >
        {active ? 'Disable' : 'Activate'}
      </Button>
    </div>
  );
};

export default EditActive;
