import React, { ReactNode } from 'react';

import { useConfig } from '@portals/api/organizations';
import { useCurrentUser } from '@portals/api/ui';
import { LiveAccountAlert } from '@portals/framework';
import { TenantType } from '@portals/types';

import { DashboardLayout } from './Dashboard';
import { ExternalUserAlert } from '../ExternalUserAlert';

interface OrganizationDashboardLayoutProps {
  children: ReactNode;
}

export function OrganizationDashboardLayout({
  children,
}: OrganizationDashboardLayoutProps) {
  const currentUser = useCurrentUser();
  const config = useConfig();

  const showExternalUserAlert =
    config.isFetched &&
    currentUser.isFetched &&
    !currentUser.data?.super_admin &&
    !config.data?.[TenantType.Organization]?.is_internal &&
    currentUser.data?.is_external;

  const showLiveAccountAlert =
    config.isFetched &&
    currentUser.isFetched &&
    currentUser.data?.super_admin &&
    !config.data?.[TenantType.Organization]?.is_internal;

  return (
    <>
      {showExternalUserAlert ? <ExternalUserAlert /> : null}

      {showLiveAccountAlert ? <LiveAccountAlert tenantType="customer" /> : null}

      <DashboardLayout>{children}</DashboardLayout>
    </>
  );
}
