import { TabsProps, TabsStylesNames, TabsStylesParams } from '@mantine/core';

import { ThemeComponentOverrides } from '../common-types';

export const Tabs: ThemeComponentOverrides<
  TabsProps,
  TabsStylesNames,
  TabsStylesParams
> = {
  styles: (theme) => ({
    tab: {
      '&[data-active] .mantine-Tabs-tabLabel': {
        color: theme.fn.primaryColor(),
      },
    },
    tabLabel: {
      fontWeight: 600,
      color: theme.colors.gray[6],
    },
  }),
};
