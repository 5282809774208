import React, { ReactNode, useContext } from 'react';

import {
  CommonConfigurationType,
  ExtraLayoutType,
  ModalsType,
  RoutesMap,
  TenantType,
} from '@portals/types';

interface ContextType<
  TCommonConfigurationType extends CommonConfigurationType
> {
  routes?: RoutesMap<TCommonConfigurationType>;
  modals?: ModalsType;
  tenantType?: TenantType;
  extraLayout?: ExtraLayoutType<TCommonConfigurationType>;
}

const AppConfigContext =
  React.createContext<ContextType<CommonConfigurationType> | null>(null);

interface AppConfigProviderProps<
  TCommonConfigurationType extends CommonConfigurationType
> {
  value: ContextType<TCommonConfigurationType>;
  children: ReactNode;
}

function AppConfigProvider<
  TCommonConfigurationType extends CommonConfigurationType
>({ children, value }: AppConfigProviderProps<TCommonConfigurationType>) {
  return (
    <AppConfigContext.Provider value={value}>
      {children}
    </AppConfigContext.Provider>
  );
}

function useAppConfig<
  TCommonConfigurationType extends CommonConfigurationType
>() {
  const context =
    useContext<ContextType<TCommonConfigurationType>>(AppConfigContext);

  if (context === undefined) {
    throw new Error('useAppConfig must be used within a AppConfigProvider');
  }

  return context;
}

export { AppConfigProvider, useAppConfig, AppConfigContext };
