import { Box, createStyles, Stack, StackProps } from '@mantine/core';
import GoogleMapReact, { MapOptions } from 'google-map-react';
import { isNumber } from 'lodash/fp';
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { MapCoordinatesType } from '@portals/types';

import {
  DEFAULT_ZOOM,
  MAP_STYLE,
  MAX_ZOOM,
} from './device-location-widget.constants';
import {
  GoogleMapsType,
  GoogleMapType,
  MapFeature,
  SuperClusterPoint,
} from './device-location-widget.types';
import { createClusterMap } from './device-location-widget.utils';
import { DeviceLocationCluster } from './DeviceLocationCluster';
import { DeviceLocationMapProvider } from './DeviceLocationMapContext';
import { DeviceLocationEmptyState } from './DeviceLocationWidget';

export interface MobileDeviceLocationWidgetProps {
  stackProps?: StackProps;
  points: Array<SuperClusterPoint>;
  base: MapCoordinatesType;
  mapOptions?: Partial<MapOptions>;
  onMapClick?: React.ComponentProps<typeof GoogleMapReact>['onClick'];
  mapActionsButtons?: ReactNode;
  selectedChildDeviceId: string | null;
  setIsFullscreen: Dispatch<SetStateAction<boolean>>;
  isFullscreen: boolean;
}

const MAP_OPTIONS: Partial<MapOptions> = {
  maxZoom: MAX_ZOOM,
  styles: MAP_STYLE,
};

export function MobileDeviceLocationWidget({
  points,
  base,
  stackProps,
  onMapClick,
  mapOptions = {},
  mapActionsButtons = null,
  selectedChildDeviceId,
  isFullscreen,
  setIsFullscreen,
}: MobileDeviceLocationWidgetProps) {
  const { classes, theme } = useStyles();
  const mapRef = useRef(null);
  const isInitial = useRef(true);

  const [{ map, maps, zoom, bounds }, setMapState] = useState<{
    map: GoogleMapType | null;
    maps: GoogleMapsType | null;
    zoom: number;
    bounds: [number, number, number, number];
  }>({
    map: null,
    maps: null,
    zoom: DEFAULT_ZOOM,
    bounds: [0, 0, 0, 0],
  });

  const onSetFullscreen = useCallback(() => {
    if (!isFullscreen) {
      setIsFullscreen(true);
    }
  }, [setIsFullscreen, isFullscreen]);

  const onCenterMap = useCallback(() => {
    if (!map || !maps) return;

    // Create a bounds object
    const bounds = new maps.LatLngBounds();

    // Extend the bounds with each point
    points.forEach((point) => {
      bounds.extend(
        new maps.LatLng(
          point.geometry.coordinates[1],
          point.geometry.coordinates[0]
        )
      );
    });

    // If we have a base location, include it in the bounds
    if (base && base.lat && base.lng) {
      bounds.extend(new maps.LatLng(base.lat, base.lng));
    }

    // Check if bounds are valid (not empty)
    if (!bounds.isEmpty()) {
      // Use fitBounds with a timeout to allow the map to load properly
      setTimeout(() => {
        map.fitBounds(bounds);
      }, 100);
    } else {
      // Fallback to default center and zoom if bounds are empty
      map.setCenter({ lat: base?.lat || 0, lng: base?.lng || 0 });
      map.setZoom(DEFAULT_ZOOM);
    }
  }, [map, maps, points, base]);

  const zoomToMarker = useCallback(
    (lat: number, lng: number) => {
      if (map && maps) {
        const latLng = new maps.LatLng(lat, lng);
        const projection = map.getProjection();

        if (projection) {
          map.setZoom(MAX_ZOOM);

          // Get the map's height
          const mapHeight = map.getDiv().clientHeight;

          // Calculate the pixel offset based on the markerOffsetPercentage
          const pixelOffset = mapHeight * 0.7 - mapHeight / 2;

          // Calculate the pixel coordinates of the marker
          const scale = Math.pow(2, MAX_ZOOM);
          const worldCoordinateCenter = projection.fromLatLngToPoint(latLng);

          if (worldCoordinateCenter === null) return;

          const offsetPoint = new maps.Point(0, pixelOffset / scale);

          // Calculate new center point
          const newWorldCoordinateCenter = new maps.Point(
            worldCoordinateCenter.x,
            worldCoordinateCenter.y + offsetPoint.y
          );

          const newCenter = projection.fromPointToLatLng(
            newWorldCoordinateCenter
          );

          if (newCenter === null) return;

          map.panTo(newCenter);
        }
      }
    },
    [map, maps]
  );

  useEffect(
    function cenetrOnSelectedMarker() {
      if (selectedChildDeviceId) {
        const selectedPoint = points.find(
          (clusterPoint) =>
            clusterPoint.properties?.id === selectedChildDeviceId
        );

        if (selectedPoint) {
          const [lng, lat] = selectedPoint.geometry.coordinates;

          zoomToMarker(lat, lng);
        }
      }
    },
    [selectedChildDeviceId, points, zoomToMarker, onCenterMap]
  );

  useEffect(
    function setInitialZoom() {
      if (!map || !maps) return;

      if (isInitial.current) {
        isInitial.current = false;

        onCenterMap();
      }
    },
    [map, maps, onCenterMap]
  );

  const handleMapLoaded = useCallback(
    ({ map, maps }: { map: GoogleMapType; maps: GoogleMapsType }) => {
      setMapState((state) => ({ ...state, map, maps }));
    },
    []
  );

  const [clusters, superCluster] = useMemo(
    () => createClusterMap(bounds, points, zoom),
    [bounds, points, zoom]
  );

  const handleMapMove = useCallback(
    (mapData: GoogleMapReact.ChangeEventValue): void => {
      setMapState((state) => ({
        ...state,
        zoom: mapData.zoom,
        bounds: [
          mapData.bounds.nw.lng,
          mapData.bounds.se.lat,
          mapData.bounds.se.lng,
          mapData.bounds.nw.lat,
        ],
      }));
    },
    []
  );

  const zoomToCluster = useCallback(
    (cluster: MapFeature) => {
      if (!map || !maps || !superCluster || !isNumber(cluster.id)) return;

      const clusterPoints = superCluster.getLeaves(cluster.id, Infinity);

      if (clusterPoints.length === 0) return;

      // Calculate the bounds of all points in the cluster
      const bounds = new maps.LatLngBounds();
      clusterPoints.forEach((point) => {
        bounds.extend(
          new maps.LatLng(
            point.geometry.coordinates[1],
            point.geometry.coordinates[0]
          )
        );
      });

      // Fit the map to these bounds
      map.fitBounds(bounds);

      // Ensure we don't zoom in too far
      const currentZoom = map.getZoom();

      if (currentZoom && currentZoom > MAX_ZOOM) {
        map.setZoom(MAX_ZOOM);
      }
    },
    [map, maps, superCluster]
  );

  const adjustedMapOptions = useMemo(
    () => ({ ...MAP_OPTIONS, ...mapOptions }),
    [mapOptions]
  );

  const markers = useMemo(() => {
    if (!clusters?.length) return null;

    return clusters.map((item) => {
      const [longitude, latitude] = item.geometry.coordinates;

      return item.properties?.cluster ? (
        <DeviceLocationCluster
          key={item.id}
          item={item}
          superCluster={superCluster}
          onClick={zoomToCluster}
          zoom={zoom || DEFAULT_ZOOM}
          lat={latitude}
          lng={longitude}
        />
      ) : (
        item.properties?.['renderSinglePoint']()
      );
    });
  }, [zoom, clusters, superCluster, zoomToCluster]);

  if (!points?.length) {
    return <DeviceLocationEmptyState stackProps={stackProps} />;
  }

  return (
    <Stack
      className={classes.container}
      p="xl"
      spacing="lg"
      pos="relative"
      h="100%"
      w="100%"
      bg="white"
      justify="center"
      {...stackProps}
    >
      <Box
        w="100%"
        h="100%"
        sx={{
          borderRadius: theme.radius.md,
        }}
        onPointerDown={onSetFullscreen}
      >
        <DeviceLocationMapProvider onCenterMap={onCenterMap}>
          <GoogleMapReact
            ref={mapRef}
            bootstrapURLKeys={{
              key: process.env.NX_GOOGLE_KEY || 'MISSING_GOOGLE_MAPS_API_KEY',
            }}
            defaultZoom={DEFAULT_ZOOM}
            defaultCenter={{
              lng: points?.[0]?.geometry.coordinates[0] || 0,
              lat: points?.[0]?.geometry.coordinates[1] || 0,
            }}
            resetBoundsOnResize
            yesIWantToUseGoogleMapApiInternals
            options={adjustedMapOptions}
            onGoogleApiLoaded={handleMapLoaded}
            onChange={handleMapMove}
            draggable={isFullscreen}
            onClick={onMapClick}
          >
            {markers}
          </GoogleMapReact>

          {mapActionsButtons}
        </DeviceLocationMapProvider>
      </Box>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    borderRadius: theme.radius.lg,
    zIndex: 1,

    '.gm-style': {
      '>div': {
        border: 'none !important',
      },
    },
  },
}));
