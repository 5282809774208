import { createStyles, Stack, Text, UnstyledButton } from '@mantine/core';
import React from 'react';

import { PaymentIntervalEnum } from '@portals/types';

import { useProductDetails } from './ProductDetailsProvider';
import { useStoreCurrency } from '../../../../redux/hooks/store';
import { StandardPrice } from '../price-preview/StandardPrice';

const PERIOD_LABEL: Record<
  Exclude<PaymentIntervalEnum, PaymentIntervalEnum.UsageBased>,
  string
> = {
  [PaymentIntervalEnum.OneTime]: 'One Time',
  [PaymentIntervalEnum.Monthly]: 'Monthly',
  [PaymentIntervalEnum.Yearly]: 'Yearly',
};

export function ProductStandardPrice() {
  const { classes, cx } = useStyles();

  const currency = useStoreCurrency();

  const {
    pricingOptions,
    selectedPricingOptionType,
    setSelectedPricingOptionType,
  } = useProductDetails();

  if (!selectedPricingOptionType || !pricingOptions) {
    return null;
  }

  return (
    <div className={classes.container}>
      {pricingOptions.map((pricingOption) => {
        const pricingOptionType =
          pricingOption.type as keyof typeof PERIOD_LABEL;

        return (
          <UnstyledButton
            key={pricingOption.type}
            onClick={() => setSelectedPricingOptionType(pricingOption.type)}
            className={cx(classes.priceCard, {
              [classes.activePriceCard]:
                selectedPricingOptionType === pricingOptionType,
            })}
          >
            <Stack data-testid={`product-price-stack-${pricingOptionType}`}>
              <Text
                size="lg"
                data-testid={`product-price-title-${pricingOptionType}`}
              >
                {PERIOD_LABEL[pricingOptionType]}
              </Text>
              <StandardPrice
                price={pricingOption.amount}
                currencyCode={currency.selected}
                period={pricingOptionType}
              />
            </Stack>
          </UnstyledButton>
        );
      })}
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: `repeat(2, 1fr)`,
    gap: theme.spacing.md,

    [theme.fn.smallerThan('md')]: {
      gridTemplateColumns: '1fr',
    },
  },
  priceCard: {
    padding: theme.spacing.xl,
    borderWidth: 1,
    borderStyle: 'solid',
    border: `1px solid ${theme.colors.gray[3]}`,
    borderRadius: theme.radius.md,
  },
  activePriceCard: {
    borderColor: theme.fn.primaryColor(),
  },
}));
