import { SegmentedControl, Stack } from '@mantine/core';
import React, { useState } from 'react';

import { DeviceDetailsType, DeviceModelType } from '@portals/api/organizations';

import { ActiveAndPendingCommands } from './ActiveAndPendingCommands';
import { AllCommands } from './AllCommands';

const FILTER_OPTIONS = {
  activeAndPending: 'Active & Pending',
  allCommands: 'All Commands',
} as const;

type FilterValues = (typeof FILTER_OPTIONS)[keyof typeof FILTER_OPTIONS];

interface DeviceCommandsProps {
  device: DeviceDetailsType;
  model: DeviceModelType;
}

export function DeviceCommands({ device }: DeviceCommandsProps) {
  const [filter, setFilter] = useState<FilterValues>(
    FILTER_OPTIONS.activeAndPending
  );

  return (
    <Stack spacing="xl" py="xl" h="100%" data-testid="device-commands-stack">
      <SegmentedControl
        data={[FILTER_OPTIONS.activeAndPending, FILTER_OPTIONS.allCommands]}
        onChange={(filter: FilterValues) => setFilter(filter)}
        value={filter}
      />

      {filter === FILTER_OPTIONS.activeAndPending ? (
        <ActiveAndPendingCommands device={device} />
      ) : (
        <AllCommands device={device} />
      )}
    </Stack>
  );
}
