import { ActionIcon, Menu, Text } from '@mantine/core';
import { map } from 'lodash/fp';
import React, { Fragment } from 'react';

import { DeviceType } from '@portals/api/organizations';
import { ReactComponent as More } from '@portals/icons/linear/more.svg';
import { suppressPropagation } from '@portals/utils';

import { useDeviceMenuItems } from '../hooks';

interface DeviceCardActionsMenuProps {
  device: DeviceType;
}

export function DeviceCardMenu({ device }: DeviceCardActionsMenuProps) {
  const menuItems = useDeviceMenuItems({ device });

  return (
    <Menu withinPortal position="right-start">
      <Menu.Target>
        <ActionIcon onClick={suppressPropagation()}>
          <More />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        {map(
          ({ id, Icon, color, onClick, label, withBottomDivider, Wrapper }) => (
            <Fragment key={id}>
              <Menu.Item
                key={id}
                color={color ? `${color}.4` : 'gray.9'}
                icon={<Icon />}
                onClick={suppressPropagation(onClick)}
              >
                {Wrapper ? (
                  <Wrapper>
                    <Text>{label}</Text>
                  </Wrapper>
                ) : (
                  label
                )}
              </Menu.Item>
              {withBottomDivider && <Menu.Divider />}
            </Fragment>
          ),
          menuItems
        )}
      </Menu.Dropdown>
    </Menu>
  );
}
