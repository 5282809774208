import { Badge, BadgeProps } from '@mantine/core';
import React from 'react';

import { TicketStatusType } from '@portals/types';

interface TicketStatusBadgeProps extends BadgeProps {
  status: TicketStatusType;
}

export function TicketStatusBadge({
  status,
  ...badgeProps
}: TicketStatusBadgeProps) {
  const { color, label } = getColorAndLabel(status);

  return (
    <Badge radius="sm" size="lg" color={color} {...badgeProps}>
      {label}
    </Badge>
  );
}

function getColorAndLabel(status: TicketStatusBadgeProps['status']) {
  switch (status) {
    case 'open': {
      return {
        label: 'Open',
        color: 'red',
      };
    }
    case 'review': {
      return {
        label: 'Review',
        color: 'deep_purple',
      };
    }
    case 'resolved': {
      return {
        label: 'Resolved',
        color: 'teal',
      };
    }
    case 'replied': {
      return {
        label: 'Replied',
        color: 'cyan',
      };
    }
  }
}
