import { Box, createStyles } from '@mantine/core';
import { motion } from 'framer-motion';
import React from 'react';

import { upcaseFirst } from '@portals/utils';

export function EnvName() {
  const { classes, theme } = useStyles();
  const env = process.env.NX_ENV || 'development';

  if (env === 'production' || env === 'demo') {
    return null;
  }

  return (
    <Box className={classes.container}>
      <motion.div
        className={classes.label}
        style={{
          y: '-75%',
          backgroundColor:
            env === 'staging'
              ? theme.colors.amber_accent[3]
              : theme.colors.green[3],
        }}
        whileHover={{
          y: 0,
          transition: { duration: 0.2 },
        }}
      >
        {upcaseFirst(env)}
      </motion.div>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    position: 'fixed',
    zIndex: 999,
    width: '100%',
    height: 0,
  },
  label: {
    padding: theme.spacing.xs,
    width: 200,
    textAlign: 'center',
    margin: 'auto',
    borderRadius: '0 0 20px 20px',
    color: theme.colors.blue_gray[7],
  },
}));
