import { Box, Button, createStyles, Group } from '@mantine/core';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { useSpace } from '@portals/api/organizations';
import { PageBreadcrumbs } from '@portals/core';
import { ReactComponent as Camera } from '@portals/icons/linear/camera.svg';

import { DevicesList } from './devices-list/DevicesList';
import { ClaimDeviceButton } from '../../../../desktop/components/ClaimDeviceButton';
import { canEdit, noAccess } from '../../../../lib/access';
import DevicesFiltersProvider from '../../../components/common/DevicesList/devices-list.context';
import { PageContainer } from '../../../components/layout/Page';

export function Space() {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const params = useParams<{ space_id: string }>();
  const space = useSpace({ spaceId: Number(params?.space_id) });

  useEffectOnce(function redirectIfNoSAccess() {
    if (noAccess(space)) {
      navigate('/overview', { replace: true });
    }
  });

  const onScan = () => navigate(`/overview/${params.space_id}/scan`);

  return (
    <PageContainer>
      <Box py="lg" px="md" className={classes.content}>
        <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <Group align="center" position="apart">
            <PageBreadcrumbs
              crumbs={[
                {
                  label: 'Spaces',
                  to: '/',
                },
                {
                  label: space?.name || 'Unknown',
                },
              ]}
            />

            {canEdit(space) ? (
              <ClaimDeviceButton
                spaceId={space?.id}
                data-testid="claim-device-button"
              >
                Claim device
              </ClaimDeviceButton>
            ) : null}
          </Group>
        </Box>

        <Box className={classes.list}>
          <DevicesFiltersProvider>
            <DevicesList space={space} />
          </DevicesFiltersProvider>
        </Box>

        {canEdit(space) ? (
          <Button size="lg" leftIcon={<Camera />} onClick={onScan}>
            Scan QR Code
          </Button>
        ) : null}
      </Box>
    </PageContainer>
  );
}

const useStyles = createStyles((theme) => ({
  content: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'min-content 1fr min-content',
    gridRowGap: theme.spacing.md,
  },
  list: {
    border: `1px solid ${theme.colors.gray[3]}`,
    overflow: 'hidden',
  },
}));
