import { LoadingOverlay } from '@mantine/core';
import React from 'react';

import { ProductPricingModel } from '@portals/types';

import { useStoreListingsAndCheckCartItems } from '../../../hooks/store';
import { useStoreCurrency } from '../../../redux/hooks/store';
import { StoreProductsList } from '../../components/store/StoreProductsList';

export function StorePage() {
  const storeCurrency = useStoreCurrency();
  const storeListings = useStoreListingsAndCheckCartItems();

  const filteredStoreListings = (storeListings.data || []).filter(
    (storeListing) =>
      storeListing.product.pricing_model !== ProductPricingModel.Personalized &&
      storeListing.status === 'published'
  );

  if (storeListings.isLoading) {
    return <LoadingOverlay visible />;
  }

  return (
    <StoreProductsList
      currency={storeCurrency}
      storeListings={filteredStoreListings}
    />
  );
}
