import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError } from '@portals/redux/actions/toastr';

import { ServerError } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils';

interface UseUnsubscribeParams {
  email: string | null;
  token: string | null;
  tenant_id: string | null;
}

export function useUnsubscribeEmail() {
  const dispatch = useDispatch();
  const { url, options } = useRequestOptions({
    url: `ui/emails/unsubscribe`,
    method: 'POST',
  });

  return useMutation<void, ServerError, UseUnsubscribeParams>({
    mutationFn: ({ email, token, tenant_id }) =>
      fetchApiRequest(url, {
        ...options,
        body: JSON.stringify({ email, token, tenant_id }),
      }),
    onError: ({ error }) => dispatch(toastrError(error)),
    meta: {
      mutationName: 'useUnsubscribeEmail',
      baseUrl: `ui/emails/unsubscribe`,
    },
  });
}
